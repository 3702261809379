import { makeCurrentUserQueryKeyPrefix } from 'features/currentUser/currentUserQueries';
import { WordsUsageHistory } from 'services/api/customer/types';
import { BaseSuccessDto } from 'services/backofficeIntegration/http/BaseSuccessDto';
import { backofficeClient } from 'services/backofficeIntegration/http/client/createBackofficeClient';
import { queryEndpoint } from 'services/backofficeIntegration/http/endpointDefinition';

type TeamUsageHistory = {
  usageHistory: WordsUsageHistory;
};
type ResponseDto = BaseSuccessDto<TeamUsageHistory>;

export const httpGetTeamUsageHistory = queryEndpoint({
  makeQueryKey: (teamId: number) => {
    return [...makeCurrentUserQueryKeyPrefix(), 'team-members', teamId] as const;
  },
  callEndpoint: (teamId: number) =>
    backofficeClient
      .get<ResponseDto>(`teams/${teamId}/words/usage-history`)
      .then(response => response.data)
});
