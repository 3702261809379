import { CustomerRole } from 'features/customer/store/types';
import { BaseSuccessDto } from 'services/backofficeIntegration/http/BaseSuccessDto';
import { backofficeClient } from 'services/backofficeIntegration/http/client/createBackofficeClient';
import { TeamMemberDto } from 'services/backofficeIntegration/http/dtos/TeamMemberDto';
import { mutationEndpoint } from 'services/backofficeIntegration/http/endpointDefinition';

// Using a custom type to allow extending the inner data property for "emails"
export type CustomErrorResponseAlreadyInTeam = {
  status: false;
  data: {
    emails: string[];
  };
  message: 'BE.team.customer_already_in_team';
};

export type CustomErrorResponse =
  | CustomErrorResponseAlreadyInTeam
  | {
      status: false;
      // This is an empty array because of PHP -> JSON conversion of an PHP array
      data: [];
      message: string;
    };

export type ResponseDto = BaseSuccessDto<TeamMemberDto> | CustomErrorResponse;

export type InviteUsersParams = {
  teamId: number;
  emails: string[];
  team_role: CustomerRole;
};

export const httpInviteUsers = mutationEndpoint({
  callEndpoint: ({ teamId, emails, team_role }: InviteUsersParams) =>
    backofficeClient
      .post<ResponseDto>(`/teams/${teamId}/invites`, {
        emails,
        team_role
      })
      .then(response => response.data)
});
