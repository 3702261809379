import { ActionDialog } from 'features/profilePage/ActionDialog';
import { FormattedMessage } from 'react-intl';

type Props = {
  onCancel: () => void;
  onConfirm: () => void;
  isDialogOpen: boolean;
  isShared: boolean;
};

export const RemoveFlashActionDialog = ({ isDialogOpen, isShared, onCancel, onConfirm }: Props) => {
  const content = isShared ? (
    <FormattedMessage
      id="aiWriter.inspirations.suggestions.flash_actions.dialog.delete.content.shared_template"
      values={{ br: <br /> }}
    />
  ) : (
    <FormattedMessage id="aiWriter.inspirations.suggestions.flash_actions.dialog.delete.content.own_template" />
  );
  return (
    <ActionDialog
      title={
        <FormattedMessage id="aiWriter.inspirations.suggestions.flash_actions.dialog.delete.title" />
      }
      content={content}
      confirmButtonText={<FormattedMessage id="common.continue" />}
      isDialogOpen={isDialogOpen}
      onCancel={onCancel}
      onConfirm={onConfirm}
      confirmButtonColor="primary"
    />
  );
};
