import { Typography } from '@mui/material';
import FlexContainer from 'components/FlexContainer';
import { AiWriterSidebarPanel } from 'features/aiWriter/AiWriterSidebar/sidebar/AiWriterSidebarPanel';
import { EditTextTypeButton } from 'features/aiWriter/AiWriterSidebar/steps/textInspirations/EditTextTypeButton';
import { RefreshTextTypeButton } from 'features/aiWriter/AiWriterSidebar/steps/textInspirations/RefreshTextTypeButton';
import { SortResultsButton } from 'features/aiWriter/AiWriterSidebar/steps/textInspirations/SortResultsButton';
import { TextInspirationsStepHeader } from 'features/aiWriter/AiWriterSidebar/steps/textInspirations/TextInspirationsStepHeader';
import { TextInspirationTileFlashScoreRating } from 'features/aiWriter/AiWriterSidebar/steps/textInspirations/TextInspirationTileFlashScoreRating';
import { useTextInspirationsStore } from 'features/aiWriter/AiWriterSidebar/steps/textInspirations/useTextInspirationsStore';
import { ActionsSpinner, BasicRewriteAction } from 'features/aiWriter/markdown/actions';
import {
  BasicMarkdownPreview,
  MarkdownTile,
  NeutralButtonSurface
} from 'features/aiWriter/markdown/MarkdownTile';
import { useCopyMarkdownToEditor } from 'features/aiWriter/markdown/useCopyMarkdownToEditor';
import { setInspirationsCurrentStep } from 'features/aiWriter/store/actions/config/actions';
import { changeOutputTypeThunk } from 'features/aiWriter/store/actions/config/thunks/changeOutputTypeThunk';
import { generateTextInspirationsThunk } from 'features/aiWriter/store/actions/inspirations/thunks/generateTextInspirationsThunk';
import { rewriteTextInspirationsThunk } from 'features/aiWriter/store/actions/inspirations/thunks/rewriteTextInspirationsThunk';
import { selectTextInspirationThunk } from 'features/aiWriter/store/actions/inspirations/thunks/selectTextInspirationThunk';
import {
  getActiveTab,
  getCurrentModelLanguageAndCountry,
  getGetIsAiWriterLoading,
  getTextInspirations
} from 'features/aiWriter/store/selectors';
import { AiWriterSidebarStep, TextInspiration } from 'features/aiWriter/store/types';
import { LoadingAnimationWithAlternatingMessages } from 'features/loading-spinner/LoadingAnimationWithAlternatingMessages';
import { useCompleteTask } from 'features/onboardingJourney/checklist/useOnboardingChecklist';
import { useCreateEmptyPage } from 'features/plate/components/plate-ui/useCreateEmptyPage';
import {
  useShouldShowWordsLimitReachedModal,
  useShowWordsLimitReachedModal
} from 'features/pricing/hook/useYouRunOutOfWordsModal';
import { EnhancedOutputTypeAutocomplete } from 'features/textGenerator/EnhancedOutputTypeAutocomplete';
import { TextTypesButton } from 'features/textGenerator/EnhancedOutputTypeAutocomplete/TextTypesButton';
import { useShowOutputTypeModal } from 'features/textGenerator/outputTypeModal/useShowOutputTypeModal';
import { trackingWrapper } from 'features/tracking/wrapper/TrackingWrapper';
import { useGetWordsCurrentUsage } from 'features/wordsUsage/useGetWordsCurrentUsage';
import { Form, Formik } from 'formik';
import { usePostHog } from 'posthog-js/react';
import { useMemo } from 'react';
import FlipMove from 'react-flip-move';
import { FormattedMessage } from 'react-intl';
import { TASK_TYPE } from 'services/backofficeIntegration/http/endpoints/onboardingChecklist/httpGetChecklist';
import gtmIds from 'services/tracking/GTMIds';
import { withGtmInteraction } from 'services/tracking/withGtmInteraction';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import styled from 'styled-components';
import useTr from 'utils/hooks/useTr';

const outputTypeField = 'outputType';

type AiWriterValues = {
  [outputTypeField]: string;
};

const MainSubStep = () => {
  const dispatch = useAppDispatch();
  const tr = useTr();
  const postHog = usePostHog();
  const { createEmptyPage } = useCreateEmptyPage();
  const showOutputTypeModal = useShowOutputTypeModal();

  const { currentModelLanguage, currentModelCountry } = useAppSelector(
    getCurrentModelLanguageAndCountry
  );

  const { generateTextConfig, id: tabId } = useAppSelector(getActiveTab);
  const textInspirations = useAppSelector(getTextInspirations);
  const areTextInspirationsLoading = useAppSelector(getGetIsAiWriterLoading)(
    'generateTextInspirations'
  );

  const initialValues = useMemo<AiWriterValues>(
    () => ({ [outputTypeField]: generateTextConfig?.outputType ?? 'slogans' }),
    [generateTextConfig?.outputType]
  );

  const completeTextTypeTask = useCompleteTask(TASK_TYPE.USE_TEXT_TYPE);

  const copyToEditor = useCopyMarkdownToEditor();

  const usageQuery = useGetWordsCurrentUsage();
  const { shouldShowRunOutOfWordsModal } = useShouldShowWordsLimitReachedModal();
  const onlyShowModal = useShowWordsLimitReachedModal();

  const textInspirationSorting = useTextInspirationsStore(state => state.sortOption);

  const sortedTextInspirations = useMemo(() => {
    if (!textInspirations.length || !textInspirations[0].flashScore) {
      return textInspirations;
    }

    return [...textInspirations].sort((a, b) => {
      switch (textInspirationSorting) {
        case 'readability_asc':
          return (a.flashScore?.readability.score ?? 0) - (b.flashScore?.readability.score ?? 0);
        case 'readability_desc':
          return (b.flashScore?.readability.score ?? 0) - (a.flashScore?.readability.score ?? 0);
        case 'emotionality_asc':
          return (a.flashScore?.emotionality.score ?? 0) - (b.flashScore?.emotionality.score ?? 0);
        case 'emotionality_desc':
          return (b.flashScore?.emotionality.score ?? 0) - (a.flashScore?.emotionality.score ?? 0);
        default:
          return 0;
      }
    });
  }, [textInspirations, textInspirationSorting]);

  const handleAddSuggestionClick = (inspiration: TextInspiration) => {
    const { text, generationId, textItemId } = inspiration;

    createEmptyPage();
    copyToEditor(text);

    trackingWrapper.track('aiWriterSidebarTextGeneratorSelectSuggestion', {
      documentId: tabId,
      text,
      generationId,
      textItemId,
      audienceModelId: generateTextConfig.audienceId
    });

    postHog?.capture('Writer - Selects copy suggestion', {
      documentId: tabId,
      text,
      generationId,
      textItemId,
      audienceModelId: generateTextConfig.audienceId
    });

    dispatch(selectTextInspirationThunk(inspiration));
  };

  const handleBasicRewriteAction = (inspiration: TextInspiration) => {
    if (shouldShowRunOutOfWordsModal()) {
      onlyShowModal();
      return;
    }
    dispatch(rewriteTextInspirationsThunk(inspiration));
  };

  const handleRefreshAction = () => {
    if (usageQuery.isSuccess && usageQuery.data.status) {
      if (usageQuery.data.data.words_available === 0) {
        onlyShowModal();
        return;
      }
    }

    dispatch(generateTextInspirationsThunk());
    completeTextTypeTask();
  };

  const handleModalOutputTypeButton = (
    setFieldValue: (field: string, value: string, shouldValidate?: boolean | undefined) => void
  ) =>
    showOutputTypeModal({
      modelCountry: currentModelCountry,
      modelLanguage: currentModelLanguage,
      onOutputTypeChange: value => {
        dispatch(changeOutputTypeThunk(value));
        setFieldValue(outputTypeField, value);
      }
    });

  const renderLeftTileActions = (inspiration: TextInspiration) => {
    return <TextInspirationTileFlashScoreRating inspiration={inspiration} />;
  };

  const renderRightTileActions = (inspiration: TextInspiration) =>
    inspiration.isLoading ? (
      <ActionsSpinner key="spinner" />
    ) : (
      <BasicRewriteAction
        key="rewrite"
        withDeprecatedGtm
        gtmId={gtmIds.aiWriter.refreshSelectedTextInspiration}
        onClick={() => handleBasicRewriteAction(inspiration)}
      />
    );

  return (
    <AiWriterSidebarPanel
      title="aiWriter.inspirations.aiwriter.title"
      infoButton={<TextInspirationsStepHeader />}
    >
      <Root gap="small">
        <FormBox gap="small">
          <Formik initialValues={initialValues} onSubmit={() => undefined}>
            {({ setFieldValue }) => (
              <Form>
                <EnhancedOutputTypeAutocomplete
                  name={outputTypeField}
                  modelCountry={currentModelCountry}
                  modelLanguage={currentModelLanguage}
                  onSelect={value => dispatch(changeOutputTypeThunk(value))}
                  onShowModal={() => handleModalOutputTypeButton(setFieldValue)}
                />
                <Actions>
                  <TextTypesButton onSelect={() => handleModalOutputTypeButton(setFieldValue)} />
                  <EditTextTypeButton
                    onSelect={() =>
                      dispatch(
                        setInspirationsCurrentStep({
                          step: AiWriterSidebarStep.aiWriter,
                          subStep: 'editBrief'
                        })
                      )
                    }
                  />
                  <RefreshTextTypeButton onSelect={handleRefreshAction} />
                  <SortResultsButton />
                </Actions>
                <Caption variant="caption">
                  <FormattedMessage
                    id={
                      !areTextInspirationsLoading && sortedTextInspirations.length === 0
                        ? 'aiWriter.inspirations.textInspirations.main.empty'
                        : 'aiWriter.inspirations.textInspirations.main.caption'
                    }
                  />
                </Caption>
              </Form>
            )}
          </Formik>
        </FormBox>
        {areTextInspirationsLoading ? (
          <LoadingAnimationWithAlternatingMessages />
        ) : (
          <Inspirations>
            {/* @ts-expect-error Package isn't updated for React 18 and misses the children prop */}
            <StyledFlipMove>
              {sortedTextInspirations.map(item => (
                <MarkdownTile
                  key={item.id}
                  surface={
                    <NeutralButtonSurface
                      disabled={item.isLoading}
                      onClick={() => handleAddSuggestionClick(item)}
                      aria-label={tr('aiWriter.copy_to_editor')}
                      {...withGtmInteraction(gtmIds.aiWriter.selectTextInspiration, {
                        withDeprecatedStyle: true
                      })}
                    />
                  }
                  markdown={<BasicMarkdownPreview>{item.text}</BasicMarkdownPreview>}
                  leftActions={renderLeftTileActions(item)}
                  rightActions={renderRightTileActions(item)}
                />
              ))}
            </StyledFlipMove>
          </Inspirations>
        )}
      </Root>
    </AiWriterSidebarPanel>
  );
};

const Root = styled(FlexContainer)`
  height: 100%;
`;

const FormBox = styled(FlexContainer)`
  flex: 0 0 auto;
`;

const Actions = styled.div`
  display: flex;
  align-items: center;

  > * {
    cursor: pointer;
  }
`;

const Caption = styled(Typography)`
  && {
    color: ${({ theme }) => theme.colors.blackMediumEmphasis};
  }
`;

const Inspirations = styled.div`
  flex: 1 1 auto;
  overflow-y: auto;
  min-height: ${({ theme }) => theme.spacings.xxxxlarge};
  padding: 0 var(--inspiration-panel-horizontal-padding);
  margin: 0 calc(-1 * var(--inspiration-panel-horizontal-padding));
  padding-bottom: ${({ theme }) => theme.spacings.three};
`;

const StyledFlipMove = styled(FlipMove)`
  display: grid;
  gap: ${({ theme }) => theme.spacings.four};
`;

export default MainSubStep;
