import { changeProjectSettings } from 'features/aiWriter/store/actions/config/actions';
import { updateCurrentProjectInBackgroundThunk } from 'features/aiWriter/store/actions/project/thunks/updateCurrentProjectInBackground';
import { getActiveTab } from 'features/aiWriter/store/selectors';
import { SharingPermission } from 'features/aiWriter/store/types';
import { trackingWrapper } from 'features/tracking/wrapper/TrackingWrapper';
import { useCallback } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import useToggle from 'utils/hooks/useToggle';

export const useToggleTeamSharing = () => {
  const dispatch = useAppDispatch();

  const activeTab = useAppSelector(getActiveTab);

  const [isTeamShared, toggleTeamSharing] = useToggle(
    activeTab.sharingPermission === SharingPermission.team
  );
  const shareProject = useCallback(() => {
    const newSharingPermission = isTeamShared ? SharingPermission.private : SharingPermission.team;

    trackingWrapper.track('aiWriterDocumentSharingPermissionChange', {
      documentId: activeTab.id,
      newSharingPermission: newSharingPermission,
      oldSharingPermission: activeTab.sharingPermission
    });

    dispatch(
      changeProjectSettings({
        sharingPermission: newSharingPermission,
        embeddingModelId: activeTab.embeddingModelId,
        audienceId: activeTab.generateTextConfig.audienceId
      })
    );
    dispatch(updateCurrentProjectInBackgroundThunk());

    toggleTeamSharing();
  }, [
    isTeamShared,
    activeTab.id,
    activeTab.sharingPermission,
    activeTab.embeddingModelId,
    activeTab.generateTextConfig.audienceId,
    dispatch,
    toggleTeamSharing
  ]);

  return {
    isShared: isTeamShared,
    toggle: shareProject
  };
};
