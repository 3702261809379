import { WordAttributeOptionValue } from 'types/ReduxStateTypes';

export const wordAttributeOptionIcon: Record<WordAttributeOptionValue, number> = {
  calm: 0x1f60c,
  complex: 0x1f914,
  easy: 0x1f642,
  exciting: 0x1f62e,
  gentle: 0x1f92b,
  happy: 0x1f60a,
  powerful: 0x1f60e,
  sad: 0x1f625
};
