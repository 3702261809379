import { Field, FieldProps } from 'formik';
import React from 'react';

import FormGroup from '../base/FormGroup';
import Radio from '../base/Radio';

type Props = FieldProps & React.ComponentPropsWithoutRef<typeof Radio>;

const RadioField = ({ field: { value: fieldValue, ...field }, value, ...props }: Props) => {
  return <Radio value={value} checked={fieldValue === value} {...field} {...props} />;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type GroupProps<T = any> = {
  name: string;
  label: string;
  options: Array<{
    value: T;
    label?: string;
    rawLabel?: string;
  }>;
  required?: boolean;
  isInline?: boolean;
};

function RadioGroupField<T>({ name, label, options, required, isInline }: GroupProps<T>) {
  return (
    <FormGroup label={label} required={required}>
      {options.map(option => (
        <Field
          component={RadioField}
          isInline={isInline}
          name={name}
          key={option.value}
          {...option}
        />
      ))}
    </FormGroup>
  );
}

export default RadioGroupField;
