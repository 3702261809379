import { ChevronLeft } from '@mui/icons-material';
import { IconButton } from '@mui/material';

type Props = {
  onClick: () => void;
};

const BackButton = ({ onClick }: Props) => {
  return (
    <IconButton onClick={onClick}>
      <ChevronLeft />
    </IconButton>
  );
};

export default BackButton;
