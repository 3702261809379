import cx from 'classnames';
import { FormattedMessage } from 'react-intl';

type Props<T> = {
  name: string;
  options: T[];
  active: T;
  setActive: (active: T) => void;
  trPrefix?: string;
};

const RadioButtonGroup = <T extends string>({
  options,
  active,
  setActive,
  name,
  trPrefix
}: Props<T>) => {
  return (
    <div className="btn-group btn-group-toggle">
      {options.map(value => (
        <label
          key={value}
          className={cx('btn btn-sm', active === value ? 'btn-primary' : 'btn-secondary')}
        >
          <input
            type="radio"
            name={name}
            autoComplete="off"
            checked={active === value}
            onChange={() => setActive(value)}
          />
          {trPrefix ? <FormattedMessage id={`${trPrefix}_${value}`} /> : <span>{value}</span>}
        </label>
      ))}
    </div>
  );
};

export default RadioButtonGroup;
