import { CloseModal } from 'components/modals/types';
import { EmbeddingModel } from 'features/embeddingModels/store/types';
import { getExplorerIsLoading } from 'features/explorer/store/selectors';
import { rateWordsThunk } from 'features/explorer/store/thunks';
import { useCallback } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { object, string } from 'yup';

import ModelSelect from '../../../../features/embeddingModels/ModelSelect';
import FieldError from '../../../forms/FieldError';
import FormikModal from '../../../modals/FormikModal';

type CallerProps = {
  listId: number;
  listLanguage: string;
};

type Props = CallerProps & CloseModal;

const ExploreListModal = ({ listId, listLanguage, closeModal }: Props) => {
  const dispatch = useAppDispatch();
  const isLoading = useAppSelector(getExplorerIsLoading);
  const countriesFilter = useCallback(
    (modelGroup: EmbeddingModel) => modelGroup.language === listLanguage,
    [listLanguage]
  );

  return (
    <FormikModal
      isLoading={isLoading}
      title="lists.explore_list"
      initialValues={{ embeddingModelId: '', modelMapperId: '' }}
      onSubmit={async ({ embeddingModelId, modelMapperId }) => {
        await dispatch(
          rateWordsThunk({
            listId,
            model: embeddingModelId,
            newTab: true,
            goalConfig: {
              seed_score: 0,
              model_rank_score: 1,
              dimensions: [],
              mappers: [
                { id: 'model_rank_score', weight: 1 },
                { id: 'valence', weight: 1 }
              ]
            },
            wordAttributes: [
              {
                value: 'model_rank_score',
                label: 'complexity',
                option: {
                  value: 'easy',
                  label: 'complexity.easy',
                  icon: 0x1f642
                }
              },
              {
                value: 'valence',
                option: {
                  label: 'goal_score.valence.happy',
                  value: 'happy',
                  icon: 0x1f60a
                }
              }
            ],
            modelMapperId
          })
        );
        closeModal();
      }}
      onCancelClick={closeModal}
      validationSchema={object().shape({
        embeddingModelId: string().required()
      })}
    >
      {({ setFieldValue }) => (
        <>
          <ModelSelect
            onChange={({ id }) => setFieldValue('embeddingModelId', id)}
            onMapperChange={modelMapperId => setFieldValue('modelMapperId', modelMapperId)}
            withMapper
            countriesFilterFn={countriesFilter}
          />
          <FieldError name="embeddingModelId" />
          <FieldError name="modelMapperId" />
        </>
      )}
    </FormikModal>
  );
};

export default ExploreListModal;
