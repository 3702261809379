import { memo, ReactElement } from 'react';
import ReactParticles from 'react-particles-js';
import styled from 'styled-components';

import particles from './particles.json';

export const SloganPanelWrapper = styled.div`
  position: relative;
  width: 100%;
  padding: 0;
  flex: 0 0 100%;

  @media (min-width: 992px) {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .particles-bg {
    width: 100%;
    height: 100%;
    background-color: ${({ theme }) => theme.colors.primary};
    z-index: -1;
    position: absolute;
  }
`;

const Particles = memo(ReactParticles);

const SloganPanel = (): ReactElement => (
  <SloganPanelWrapper>
    <Particles params={particles} className="particles-bg" />
  </SloganPanelWrapper>
);

export default SloganPanel;
