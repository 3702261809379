import { createAction, createReducer, createSelector } from '@reduxjs/toolkit';
import { RootState } from 'store/rootReducer';
import { getParsedStorageItem } from 'utils/getParsedStorageItem';

import LocalStorageKey from '../../../config/localStorageKey';

export const closeAllWidgets = createAction('toolbar/closeAllWidgets');
export const toggleDimensionsWidget = createAction<boolean | undefined>(
  'toolbar/toggleDimensionsWidget'
);
export const toggleListsWidget = createAction<boolean | undefined>('toolbar/toggleListsWidget');
export const setToolbarLocked = createAction<boolean | undefined>('toolbar/setToolbarLocked');

type ToolbarState = {
  dimensionsWidgetOpen: boolean;
  listsWidgetOpen: boolean;
  toolbarLocked: boolean;
};

const toolbarReducer = createReducer<ToolbarState>(
  {
    dimensionsWidgetOpen: false,
    listsWidgetOpen: false,
    toolbarLocked: getParsedStorageItem<boolean>(LocalStorageKey.ToolbarLocked, false, window.localStorage)
  },
  builder =>
    builder
      .addCase(closeAllWidgets, state => {
        if (!state.toolbarLocked) {
          state.dimensionsWidgetOpen = false;
          state.listsWidgetOpen = false;
        }
      })
      .addCase(toggleDimensionsWidget, (state, { payload }) => {
        state.dimensionsWidgetOpen = payload != null ? payload : !state.dimensionsWidgetOpen;
        state.listsWidgetOpen = false;
      })
      .addCase(toggleListsWidget, (state, { payload }) => {
        state.listsWidgetOpen = payload != null ? payload : !state.listsWidgetOpen;
        state.dimensionsWidgetOpen = false;
      })
      .addCase(setToolbarLocked, (state, { payload }) => {
        state.toolbarLocked = payload != null ? payload : !state.toolbarLocked;
      })
);

export const getIsToolbarLocked = (state: RootState) => state.toolbar.toolbarLocked;
export const getDimensionsWidgetOpen = (state: RootState) => state.toolbar.dimensionsWidgetOpen;
export const getListsWidgetOpen = (state: RootState) => state.toolbar.listsWidgetOpen;
export const getIsToolbarOpen = createSelector(
  getDimensionsWidgetOpen,
  getListsWidgetOpen,
  (dimensionsWidgetOpen, listsWidgetOpen) => dimensionsWidgetOpen || listsWidgetOpen
);

export default toolbarReducer;
