import { columnCategoriesDict } from 'features/explorer/store/utils';
import { FilterProps } from 'react-table';

import useTr from '../../../utils/hooks/useTr';
import { SelectFilter } from '../TableComponents';

type ColumnProps = Pick<FilterProps<object>['column'], 'filterValue' | 'setFilter'>;
type FilterPropsType = { column: ColumnProps };

type BaseProps = {
  columnName: string;
} & FilterPropsType['column'];

export const CategoryRangeFilterBase = ({ setFilter, columnName }: BaseProps) => {
  const tr = useTr();
  const options = columnCategoriesDict[columnName];

  return (
    <SelectFilter
      onChange={event => {
        const { value } = event.target;
        setFilter(() => {
          if (value) {
            const option = options.find(opt => opt.label === value);
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            return [option!.startValue, option!.endValue];
          } else {
            return [undefined, undefined];
          }
        });
      }}
    >
      <option value={undefined} />
      {options.map(option => (
        <option key={option.label} value={option.label}>
          {tr(option.label)}
        </option>
      ))}
    </SelectFilter>
  );
};

export const CategoryRangeFilterComplexity = ({
  column: { filterValue, setFilter }
}: FilterPropsType) => (
  <CategoryRangeFilterBase
    columnName="model_rank_score"
    filterValue={filterValue}
    setFilter={setFilter}
  />
);
