import { rgba } from 'polished';
import { colorPalette } from 'styles/new-theme/colorPalette';
import { NewThemeConfig, newThemeConfig } from 'styles/new-theme/newThemeConfig';
import { ThemeMode } from 'styles/new-theme/useAppThemeStore';

export const newThemeConfigDarkMode: NewThemeConfig = {
  ...newThemeConfig,

  mode: 'dark' as ThemeMode,
  colors: {
    palette: colorPalette,

    primary__50: colorPalette.blue__900,
    primary__100: colorPalette.blue__800,
    primary__200: colorPalette.blue__700,
    primary__300: colorPalette.blue__600,
    primary__400: colorPalette.blue__500main,
    primary__500main: colorPalette.blue__500main,
    primary__600: colorPalette.blue__400,
    primary__700: colorPalette.blue__300,
    primary__800: colorPalette.blue__200,
    primary__900: colorPalette.blue__100,

    secondary__50: colorPalette.green__900,
    secondary__100: colorPalette.green__800,
    secondary__200: colorPalette.green__700,
    secondary__300: colorPalette.green__600,
    secondary__400: colorPalette.green__500main,
    secondary__500main: colorPalette.green__500main,
    secondary__600: colorPalette.green__400,
    secondary__700: colorPalette.green__300,
    secondary__800: colorPalette.green__200,
    secondary__900: colorPalette.green__100,

    primaryColorContrast: colorPalette.black,
    primaryColorDark: colorPalette.blue__400,
    primaryColorFocus: rgba(colorPalette.blue__500main, 0.2),
    primaryColorFocusvisible: rgba(colorPalette.blue__500main, 0.3),
    primaryColorHover: rgba(colorPalette.blue__500main, 0.07),
    primaryColorLight: colorPalette.blue__300,
    primaryColorMain: colorPalette.blue__500main,
    primaryColorOutlinedborder: rgba(colorPalette.blue__500main, 0.5),
    primaryColorSelected: rgba(colorPalette.blue__500main, 0.15),

    secondaryColorContrast: rgba(colorPalette.black, 0.87),
    secondaryColorDark: colorPalette.green__400,
    secondaryColorFocus: rgba(colorPalette.green__200, 0.2),
    secondaryColorFocusvisible: rgba(colorPalette.green__200, 0.3),
    secondaryColorHover: rgba(colorPalette.green__200, 0.07),
    secondaryColorLight: colorPalette.green__50,
    secondaryColorMain: colorPalette.green__200,
    secondaryColorOutlinedborder: rgba(colorPalette.green__200, 0.5),
    secondaryColorSelected: rgba(colorPalette.green__200, 0.15),

    nativeScrollbarBg: colorPalette.dark__800, // Darker for better integration with dark mode

    accentOrangeContrast: colorPalette.black,
    accentOrangeDark: colorPalette.orange__400,
    accentOrangeFocus: rgba(colorPalette.orange__500main, 0.2),
    accentOrangeFocusvisible: rgba(colorPalette.orange__500main, 0.3),
    accentOrangeHover: rgba(colorPalette.orange__500main, 0.08),
    accentOrangeLight: colorPalette.orange__50,
    accentOrangeMain: colorPalette.orange__200,
    accentOrangeOutlinedborder: rgba(colorPalette.orange__500main, 0.5),
    accentOrangeSelected: rgba(colorPalette.orange__500main, 0.16),

    accentRedContrast: colorPalette.black,
    accentRedDark: colorPalette.red__400,
    accentRedFocus: rgba(colorPalette.red__500main, 0.2),
    accentRedFocusvisible: rgba(colorPalette.red__500main, 0.3),
    accentRedHover: rgba(colorPalette.red__500main, 0.08),
    accentRedLight: colorPalette.red__50,
    accentRedMain: colorPalette.red__200,
    accentRedOutlinedborder: rgba(colorPalette.red__500main, 0.5),
    accentRedSelected: rgba(colorPalette.red__500main, 0.16),

    accentRoseContrast: colorPalette.black,
    accentRoseDark: colorPalette.pinkRose__300,
    accentRoseFocus: rgba(colorPalette.pinkRose__500main, 0.2),
    accentRoseFocusvisible: rgba(colorPalette.pinkRose__500main, 0.3),
    accentRoseHover: rgba(colorPalette.pinkRose__500main, 0.2),
    accentRoseLight: colorPalette.pinkRose__50,
    accentRoseMain: colorPalette.pinkRose__500main,
    accentRoseOutlinedborder: rgba(colorPalette.pinkRose__500main, 0.5),
    accentRoseSelected: rgba(colorPalette.pinkRose__500main, 0.16),

    accentYellowContrast: colorPalette.white,
    accentYellowDark: colorPalette.yellow__500main,
    accentYellowFocus: rgba(colorPalette.yellow__500main, 0.2),
    accentYellowFocusvisible: rgba(colorPalette.yellow__500main, 0.3),
    accentYellowHover: rgba(colorPalette.yellow__500main, 0.08),
    accentYellowLight: colorPalette.yellow__50,
    accentYellowMain: colorPalette.yellow__300,
    accentYellowOutlinedborder: rgba(colorPalette.yellow__500main, 0.5),
    accentYellowSelected: rgba(colorPalette.yellow__500main, 0.16),

    actionActive: rgba(colorPalette.white, 0.56),
    actionAvailable: rgba(colorPalette.white, 0.5),
    actionDisabled: rgba(colorPalette.white, 0.38),
    actionDisabledbackground: rgba(colorPalette.white, 0.12),
    actionFocus: rgba(colorPalette.white, 0.12),
    actionHover: rgba(colorPalette.white, 0.08),
    actionSelected: rgba(colorPalette.white, 0.16),

    backgroundDefault: '#121212',
    backgroundPaperElevation0: '#121212',
    backgroundPaperElevation1: colorPalette.black,
    backgroundPaperElevation2: '#232323',
    backgroundPaperElevation3: '#252525',
    backgroundPaperElevation4: '#272727',
    backgroundPaperElevation5: '#2A2A2A',
    backgroundPaperElevation6: '#2C2C2C',
    backgroundPaperElevation7: '#2C2C2C',
    backgroundPaperElevation8: '#2E2E2E',
    backgroundPaperElevation9: '#2E2E2E',
    backgroundPaperElevation10: '#313131',
    backgroundPaperElevation11: '#313131',
    backgroundPaperElevation12: '#333333',
    backgroundPaperElevation13: '#333333',
    backgroundPaperElevation14: '#333333',
    backgroundPaperElevation15: '#333333',
    backgroundPaperElevation16: '#363636',
    backgroundPaperElevation17: '#363636',
    backgroundPaperElevation18: '#363636',
    backgroundPaperElevation19: '#363636',
    backgroundPaperElevation20: '#383838',
    backgroundPaperElevation21: '#383838',
    backgroundPaperElevation22: '#383838',
    backgroundPaperElevation23: '#383838',
    backgroundPaperElevation24: '#383838',

    commonBlackFocus: rgba(colorPalette.black, 0.12),
    commonBlackFocusvisible: rgba(colorPalette.black, 0.3),
    commonBlackHover: rgba(colorPalette.black, 0.08),
    commonBlackMain: colorPalette.black,
    commonBlackOutlinedborder: rgba(colorPalette.black, 0.5),
    commonBlackSelected: rgba(colorPalette.black, 0.16),

    commonBrandColorsBrandBeige: colorPalette.white,
    commonBrandColorsBrandBlue: colorPalette.white,
    commonBrandColorsBrandDark: colorPalette.white,
    commonBrandColorsBrandGreen: colorPalette.white,
    commonBrandColorsBrandOrange: colorPalette.white,
    commonBrandColorsBrandRed: colorPalette.white,
    commonBrandColorsBrandRose: colorPalette.white,

    commonWhiteFocus: rgba(colorPalette.white, 0.12),
    commonWhiteFocusvisible: rgba(colorPalette.white, 0.3),
    commonWhiteHover: rgba(colorPalette.white, 0.08),
    commonWhiteMain: colorPalette.white,
    commonWhiteOutlinedborder: rgba(colorPalette.white, 0.5),
    commonWhiteSelected: rgba(colorPalette.white, 0.16),

    componentsAlertErrorBackground: colorPalette.red__900,
    componentsAlertErrorColor: colorPalette.red__50,
    componentsAlertInfoBackground: colorPalette.pinkRose__900,
    componentsAlertInfoColor: colorPalette.pinkRose__50,
    componentsAlertSuccessBackground: colorPalette.pinkRose__500main,
    componentsAlertSuccessColor: colorPalette.dark__900main,
    componentsAlertWarningBackground: colorPalette.yellow__200,
    componentsAlertWarningColor: colorPalette.dark__900main,

    componentsAppbarDefaultfill: colorPalette.dark__500,
    componentsAvatarFill: colorPalette.orange__300,
    componentsBackdropFill: rgba('#000000', 0.5),
    componentsBreadcrumbsCollapsefill: colorPalette.dark__300,

    componentsChipDefaultclosefill: colorPalette.white,
    componentsChipDefaultenabledborder: colorPalette.beige__100main,
    componentsChipDefaultfocusfill: rgba('#ffffff', 0.7),
    componentsChipDefaulthoverfill: colorPalette.beige__100main,

    componentsIconDefault: colorPalette.white,
    componentsIconActive: colorPalette.blue__500main,
    componentsIconInactive: colorPalette.white,

    componentsInputfieldFilledEnabledfill: rgba(colorPalette.white, 0.09),
    componentsInputfieldFilledHoverfill: rgba(colorPalette.white, 0.12),
    componentsInputfieldOutlinedEnabledborder: rgba(colorPalette.white, 0.23),
    componentsInputfieldOutlinedHoverborder: colorPalette.white,
    componentsInputfieldStandardEnabledborder: colorPalette.white,
    componentsInputfieldStandardHoverborder: colorPalette.white,

    componentsRatingActivefill: colorPalette.orange__300,
    componentsRatingEnabledborder: rgba(colorPalette.white, 0.23),
    componentsSnackbarFill: colorPalette.pinkRose__200,
    componentsStepperConnector: colorPalette.dark__600,
    componentsSwitchKnobfillenabled: colorPalette.dark__600,
    componentsSwitchKnowfilldisabled: colorPalette.dark__500,
    componentsSwitchSlidefill: colorPalette.dark__900main,
    componentsTooltipFill: colorPalette.dark__600,

    contrast: colorPalette.white,
    dark: colorPalette.orange__900,
    divider: rgba(colorPalette.white, 0.12),
    elevationOutlined: colorPalette.dark__700,

    errorContrast: colorPalette.white,
    errorDark: colorPalette.red__700,
    errorFocusvisible: rgba(colorPalette.red__500main, 0.3),
    errorHover: rgba(colorPalette.red__500main, 0.04),
    errorLight: colorPalette.red__300,
    errorMain: colorPalette.red__500main,
    errorOutlinedborder: rgba(colorPalette.red__500main, 0.5),
    errorSelected: rgba(colorPalette.red__500main, 0.16),

    focus: colorPalette.orange__500main,
    focusvisible: colorPalette.orange__500main,
    hover: colorPalette.orange__500main,

    infoContrast: colorPalette.black,
    infoDark: colorPalette.pinkRose__700,
    infoFocusvisible: rgba(colorPalette.pinkRose__400, 0.3),
    infoHover: rgba(colorPalette.pinkRose__400, 0.08),
    infoLight: colorPalette.pinkRose__300,
    infoMain: colorPalette.pinkRose__500main,
    infoOutlinedborder: rgba(colorPalette.pinkRose__400, 0.5),
    infoSelected: rgba(colorPalette.pinkRose__400, 0.16),

    light: colorPalette.orange__100,
    main: colorPalette.orange__500main,

    outlinedborder: rgba(colorPalette.white, 0.7),

    selected: rgba(colorPalette.white, 0.08),

    successContrast: colorPalette.black,
    successDark: colorPalette.blue__700,
    successFocusvisible: rgba(colorPalette.blue__200, 0.3),
    successHover: rgba(colorPalette.blue__200, 0.04),
    successLight: colorPalette.blue__300,
    successMain: colorPalette.blue__200,
    successOutlinedborder: rgba(colorPalette.blue__200, 0.5),
    successSelected: rgba(colorPalette.blue__200, 0.16),

    textDisabled: rgba(colorPalette.dark__400, 0.38),
    textFocus: rgba(colorPalette.white, 0.12),
    textFocusvisible: rgba(colorPalette.white, 0.3),
    textHover: rgba(colorPalette.white, 0.08),
    textPrimary: colorPalette.nearWhite,
    textSecondary: colorPalette.nearWhite,
    textSelected: rgba(colorPalette.white, 0.16),
    textContrast: colorPalette.dark__900main,

    warningContrast: colorPalette.black,
    warningDark: colorPalette.orange__700,
    warningFocusvisible: rgba(colorPalette.orange__500main, 0.3),
    warningHover: rgba(colorPalette.orange__500main, 0.08),
    warningLight: colorPalette.orange__300,
    warningMain: colorPalette.orange__400,
    warningOutlinedborder: rgba(colorPalette.orange__500main, 0.5),
    warningSelected: rgba(colorPalette.orange__500main, 0.16)
  },

  shadow: {
    card: '0px 4px 4px 0px rgba(255, 255, 255, 0.25)', // Lighter shadows for dark mode
    popOver:
      '0 2px 4px rgba(255, 255, 255, 0.14), 0 4px 5px rgba(255, 255, 255, 0.12), 0 1px 10px rgba(255, 255, 255, 0.2)',
    textSuggestionHover:
      '0px 0px 2px rgba(255, 255, 255, 0.14), 0px 2px 2px rgba(255, 255, 255, 0.12), 0px 1px 3px rgba(255, 255, 255, 0.2)',
    elevation2:
      '0px 1px 5px 0px rgba(255, 255, 255, 0.12), 0px 2px 2px 0px rgba(255, 255, 255, 0.14), 0px 3px 1px -2px rgba(255, 255, 255, 0.20)',
    elevation6:
      '0px 1px 18px 0px rgba(255, 255, 255, 0.12), 0px 6px 10px 0px rgba(255, 255, 255, 0.14), 0px 3px 5px -1px rgba(255, 255, 255, 0.20)',
    inviteFriendCard: `0px 2px 4px 0px ${rgba(
      colorPalette.blue__500main,
      0.3
    )}, 0px 1px 20px 0px ${rgba(colorPalette.blue__500main, 0.3)}`
  }
};
