import { ClickAwayListener } from '@mui/base/ClickAwayListener';
import { RefreshRounded } from '@mui/icons-material';
import { Chip, CircularProgress, Divider, Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import FlexContainer from 'components/FlexContainer';
import { SmallerEmotionalityCircularProgress } from 'features/aiWriter/AiWriterSidebar/steps/flashScore/progress-circles';
import {
  getEmotionalityTranslationLabel,
  ReadabilityAndEmotionalityGrade
} from 'features/flashScore/getEmotionalityTranslationLabel';
import { useGetScoredSynonyms } from 'features/flashScore/useGetScoredSynonyms';
import { FlashScoreTextRating } from 'features/flashScore/useScoreTexts';
import FormattedMessage from 'features/i18n/FormattedMessage';
import gtmIds from 'services/tracking/GTMIds';
import { withGtmInteraction } from 'services/tracking/withGtmInteraction';
import styled from 'styled-components';

type Props = {
  nodeText: string;
  emotionalityRating: FlashScoreTextRating;
  onClose: () => void;
  onSynonymClick: (text: string) => void;
};

export const FlashScoreEmotionalitySynonymsOutput = ({
  onClose,
  nodeText,
  emotionalityRating,
  onSynonymClick
}: Props) => {
  const {
    data: generatedSynonyms,
    isFetching: isFetchingSynonyms,
    refetch: refetchSynonyms
  } = useGetScoredSynonyms(nodeText);

  return (
    <ClickAwayListener onClickAway={onClose}>
      <FakePopper>
        <Root contentEditable={false}>
          <Header>
            <Typography variant="subtitle2">
              <FormattedMessage id="flashScore.popper.emotionality.title" />
            </Typography>

            <FlexContainer alignItems="center" gap="small" direction="row">
              <Typography fontSize={12} color="dimgray">
                <FormattedMessage id={getEmotionalityTranslationLabel(emotionalityRating.grade)} />
              </Typography>

              <SmallerEmotionalityCircularProgress value={emotionalityRating.percentage} />
            </FlexContainer>
          </Header>

          <Divider />

          <Content>
            {isFetchingSynonyms ? (
              <CircularProgress size={20} />
            ) : (
              <>
                <FlexContainer alignItems="center" justifyContent="space-between" direction="row">
                  <Typography variant="subtitle2">
                    <FormattedMessage
                      id="flashScore.popper.emotionality.synonyms_title"
                      values={{
                        word: nodeText
                      }}
                    />
                  </Typography>

                  <IconButton
                    size="small"
                    disabled={isFetchingSynonyms}
                    onClick={() => refetchSynonyms()}
                    {...withGtmInteraction(gtmIds.aiWriter.flashScore.refreshSynonyms)}
                  >
                    <RefreshRounded />
                  </IconButton>
                </FlexContainer>

                {generatedSynonyms && generatedSynonyms.length > 0 ? (
                  <ChipList>
                    {generatedSynonyms?.map(({ emotionality, text }) => (
                      <EmotionalityChip
                        key={text}
                        label={text}
                        data-grade={emotionality.grade}
                        $grade={emotionality.grade}
                        onClick={() => onSynonymClick(text)}
                      />
                    ))}
                  </ChipList>
                ) : (
                  <GrayText variant="body2">No synonyms found.</GrayText>
                )}
              </>
            )}
          </Content>
        </Root>
      </FakePopper>
    </ClickAwayListener>
  );
};

const FakePopper = styled.div`
  width: 300px;
  border-radius: ${({ theme }) => theme.borderRadius.three};
  box-shadow: ${({ theme }) => theme.shadow.popOver};
`;

const Root = styled(FlexContainer).attrs({ gap: 'medium' })`
  width: 100%;
  padding: ${({ theme }) => theme.spacings.four};
  overflow: auto;
`;

const Content = styled(FlexContainer).attrs({ gap: 'two' })``;

const Header = styled(FlexContainer).attrs({
  direction: 'row',
  justifyContent: 'space-between',
  alignItems: 'center'
})``;

const ChipList = styled(FlexContainer).attrs({
  gap: 'two',
  direction: 'row',
  flexWrap: 'wrap'
})``;

const EmotionalityChip = styled(Chip)<{ $grade: ReadabilityAndEmotionalityGrade }>`
  // Setting colors for all states
  &,
  &:hover,
  &:focus,
  &.MuiChip-clickable:hover {
    background-color: ${({ theme, $grade }) => {
      if ($grade === 1) {
        return theme.colors.palette.pinkRose__700;
      }

      if ($grade === 2) {
        return theme.colors.palette.pinkRose__500main;
      }

      if ($grade === 3) {
        return theme.colors.palette.pinkRose__500main;
      }

      return theme.colors.actionHover;
    }};
    color: ${({ theme }) => theme.colors.black};

    border: none;
  }

  // Raise brightness on hover
  &:hover {
    filter: brightness(1.2);
  }
`;

const GrayText = styled(Typography)`
  color: ${({ theme }) => theme.colors.textMuted};
`;
