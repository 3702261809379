import { GoalConfigWeight, ScoreWordsGoalConfig } from 'services/api/wordEmbedding/types';
import {
  GoalConfigWithSeed,
  WordAttribute,
  WordAttributeWithOption
} from 'types/ReduxStateTypes';

export const defaultGoalConfig: ScoreWordsGoalConfig = {
  seed_score: 1,
  model_rank_score: 1,
  mappers: [],
  dimensions: []
};

export const filterGoalConfig = (goalConfig: Partial<GoalConfigWithSeed>) => {
  return Object.entries(goalConfig).reduce<WordAttributeWithOption[]>(
    (total, [key, value]) => (value ? total.concat({ value: key as WordAttribute }) : total),
    []
  );
};

export const validateGoalConfig = (goalConfig: ScoreWordsGoalConfig): boolean => {
  if (goalConfig.seed_score || goalConfig.model_rank_score) return true;
  const hasWeight = (i: GoalConfigWeight) => i.weight !== 0;
  return goalConfig.mappers.some(hasWeight) || goalConfig.dimensions.some(hasWeight);
};
