import { useInfiniteQuery } from '@tanstack/react-query';
import { httpGetPersonalities } from 'services/backofficeIntegration/http/endpoints/personalities/httpGetPersonalities';
import { getNextPageParam } from 'services/backofficeIntegration/http/paginatedListEndpoint';

// this value is chosen arbitrary by our UX team
const personalitiesLimit = 7;

export function usePersonalitySelectorInfiniteQuery(params: {
  language: string;
  country: string;
  isPaginated?: number;
}) {
  return useInfiniteQuery({
    queryKey: httpGetPersonalities.makeQueryKey(params),
    queryFn: ({ pageParam }) =>
      httpGetPersonalities.callEndpoint({
        offset: pageParam?.offset ?? 0,
        limit: pageParam?.limit ?? personalitiesLimit,
        sort: 'most_recent',
        ...params
      }),
    getNextPageParam: getNextPageParam
  });
}
