import { CircularProgress, Paper, Popover, PopoverProps, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import FlexContainer from 'components/FlexContainer';
import { BasicInfoButton } from 'components/iconButtons/BasicInfoButton';
import { getRoutePath } from 'config/routes';
import { getGetIsFeatureAvailable } from 'features/features/store/selectors';
import FormattedMessage from 'features/i18n/FormattedMessage';
import { UsageIndicatorType } from 'features/usageIndicator/UsageIndicatorType';
import { WideUsageIndicator } from 'features/usageIndicator/WideUsageIndicator';
import { Link } from 'react-router-dom';
import { httpGetAllLimitations } from 'services/backofficeIntegration/http/endpoints/customer/httpGetAllLimitations';
import gtmIds from 'services/tracking/GTMIds';
import { withGtmInteraction } from 'services/tracking/withGtmInteraction';
import { useAppSelector } from 'store/hooks';
import styled from 'styled-components';
import useTr from 'utils/hooks/useTr';

type Props = PopoverProps;

export const UsageIndicatorPopover = (props: Props) => {
  const translate = useTr();
  const isPlagiarismCheckerAvailable =
    useAppSelector(getGetIsFeatureAvailable)('plagiarismChecker');
  const isSeoAnalysisAvailable = useAppSelector(getGetIsFeatureAvailable)('seoAnalysis');

  const usageQuery = useQuery({
    queryKey: httpGetAllLimitations.makeQueryKey(),
    queryFn: () => httpGetAllLimitations.callEndpoint()
  });

  const hasAnyLimitReachedOrNoAccess =
    Object.values(usageQuery.data || {}).some(
      limitation => limitation && !limitation.is_unlimited && limitation.used >= limitation.limit
    ) ||
    !isPlagiarismCheckerAvailable ||
    !isSeoAnalysisAvailable;

  return (
    <Popover {...props}>
      <StyledPaper elevation={3}>
        <FlexContainer justifyContent="space-between" gap="two">
          <FlexContainer justifyContent="space-between" alignItems="center" direction="row">
            <Typography variant="h6">
              <FormattedMessage id="aiWriter.topbar.usage_indicator.popover.headline" />
            </Typography>
            <BasicInfoButton
              gtmId={gtmIds.aiWriter.limitDropdown.help}
              tooltip={translate('aiWriter.topbar.usage_indicator.popover.tooltip')}
              size="small"
            />
          </FlexContainer>

          <IndicatorContainer gap="medium">
            {usageQuery.isLoading ? (
              <CircularProgress />
            ) : (
              <>
                {usageQuery.data?.word && (
                  <WideUsageIndicator
                    type={UsageIndicatorType.textGeneration}
                    limitation={usageQuery.data?.word}
                    hasAccess={true}
                  />
                )}

                {usageQuery.data?.image_generation && (
                  <WideUsageIndicator
                    type={UsageIndicatorType.aiImages}
                    limitation={usageQuery.data?.image_generation}
                    hasAccess={true}
                  />
                )}

                {usageQuery.data?.unsplash && (
                  <WideUsageIndicator
                    type={UsageIndicatorType.unsplashImages}
                    limitation={usageQuery.data?.unsplash}
                    hasAccess={true}
                  />
                )}

                {usageQuery.data?.seobility && (
                  <WideUsageIndicator
                    type={UsageIndicatorType.seoAnalysis}
                    limitation={usageQuery.data?.seobility}
                    hasAccess={isSeoAnalysisAvailable}
                  />
                )}

                {usageQuery.data?.plagiarism && (
                  <WideUsageIndicator
                    type={UsageIndicatorType.plagiarismCheck}
                    limitation={usageQuery.data?.plagiarism}
                    hasAccess={isPlagiarismCheckerAvailable}
                  />
                )}
              </>
            )}
          </IndicatorContainer>
        </FlexContainer>
        {hasAnyLimitReachedOrNoAccess && (
          <FooterContainer>
            <Typography variant="caption">
              <FormattedMessage
                id="aiWriter.topbar.usage_indicator.popover.upgrade_label"
                values={{
                  a: (chunks: string) => (
                    <UpgradeLink
                      {...withGtmInteraction(gtmIds.aiWriter.limitDropdown.upgrade)}
                      to={getRoutePath('profileBilling')}
                    >
                      {chunks}
                    </UpgradeLink>
                  )
                }}
              />
            </Typography>
          </FooterContainer>
        )}
      </StyledPaper>
    </Popover>
  );
};

const StyledPaper = styled(Paper)`
  min-width: 400px;
  padding: ${({ theme }) => theme.spacings.five};
`;

const IndicatorContainer = styled(FlexContainer)`
  padding-inline: ${({ theme }) => theme.spacings.two};
`;

const FooterContainer = styled.div`
  margin-top: ${({ theme }) => theme.spacings.medium};

  color: ${({ theme }) => theme.colors.blackMediumEmphasis};
  text-align: right;
`;

const UpgradeLink = styled(Link)`
  color: inherit;
  text-decoration: underline;
`;
