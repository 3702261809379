import { Button, Menu, MenuItem } from '@mui/material';
import { useComposedRef } from '@udecode/plate-common';
import FormattedMessage from 'features/i18n/FormattedMessage';
import { ComponentProps, forwardRef, useRef, useState } from 'react';
import gtmIds from 'services/tracking/GTMIds';
import { withGtmInteraction } from 'services/tracking/withGtmInteraction';
import styled from 'styled-components';

export type SuggestionType =
  | 'easier_to_read'
  | 'complex_to_read'
  | 'less_emotional'
  | 'more_emotional'
  | 'improving_content_structure';

export const suggestionTypeToOutputTypeMapping: Record<SuggestionType, string> = {
  easier_to_read: 'flashscore_readability',
  complex_to_read: 'flashscore_complexity',
  less_emotional: 'flashscore_emotionality_down',
  more_emotional: 'flashscore_emotionality_up',
  improving_content_structure: 'flashscore_overall_structure'
};

export const outputTypeToSuggestionTypeMapping: Record<string, SuggestionType> = {
  [suggestionTypeToOutputTypeMapping.easier_to_read]: 'easier_to_read',
  [suggestionTypeToOutputTypeMapping.complex_to_read]: 'complex_to_read',
  [suggestionTypeToOutputTypeMapping.less_emotional]: 'less_emotional',
  [suggestionTypeToOutputTypeMapping.more_emotional]: 'more_emotional',
  [suggestionTypeToOutputTypeMapping.improving_content_structure]: 'improving_content_structure'
};

const getSuggestionOutputTypeOptions = () => Object.values(outputTypeToSuggestionTypeMapping);

type Props = ComponentProps<typeof Button> & {
  onSuggestionSelected: (suggestionType: SuggestionType) => void;
};

export const GetSuggestionsButton = forwardRef<HTMLButtonElement, Props>(
  ({ onSuggestionSelected, children, ...props }, ref) => {
    const localRef = useRef<HTMLButtonElement>(null);
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const suggestionOptions = getSuggestionOutputTypeOptions();

    const mergedRef = useComposedRef(ref, localRef);

    const handleButtonClick = () => setIsMenuOpen(true);

    const handleMenuItemClick = (suggestionType: SuggestionType) => {
      onSuggestionSelected(suggestionType);
      setIsMenuOpen(false);
    };

    const handleMenuClose = () => {
      setIsMenuOpen(false);
    };

    return (
      <>
        <StyledButton
          ref={mergedRef}
          variant="contained"
          color="primary"
          size="large"
          onClick={handleButtonClick}
          {...withGtmInteraction(gtmIds.aiWriter.flashScore.getSuggestionsCta)}
          {...props}
        >
          {children}
        </StyledButton>

        <Menu open={isMenuOpen} anchorEl={localRef.current} onClose={handleMenuClose}>
          {suggestionOptions.map(suggestionType => (
            <MenuItem
              key={suggestionType}
              {...withGtmInteraction(gtmIds.aiWriter.flashScore.selectSuggestionType)}
              onClick={() => handleMenuItemClick(suggestionType)}
            >
              <FormattedMessage
                id={`aiWriter.inspirations.flashScore.suggestion.${suggestionType}`}
              />
            </MenuItem>
          ))}
        </Menu>
      </>
    );
  }
);

const StyledButton = styled(Button)`
  min-width: 160px;
  white-space: nowrap;
`;
