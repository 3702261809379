import React, { useEffect, useRef, useState } from 'react';

import Tooltip from '../tooltips/Tooltip';

type Props = {
  children: React.ReactElement;
  tooltip?: React.ReactElement | React.ReactText;
};

const CellWithTooltip = ({ children, tooltip = children }: Props) => {
  const ref = useRef<HTMLDivElement>(null);
  const [tooltipDisabled, setTooltipDisabled] = useState(true);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    setTooltipDisabled(ref.current!.offsetWidth >= ref.current!.scrollWidth);
  }, [children, tooltip]);

  return (
    <div className="ellipsis" ref={ref}>
      <Tooltip content={tooltip} disabled={tooltipDisabled}>
        {children}
      </Tooltip>
    </div>
  );
};

export default CellWithTooltip;
