import { getPointBefore, PlateEditor, select } from '@udecode/plate';
import useEditor from 'features/aiWriter/AiWriterTextEditor/hooks/useEditor';
import { getRangeTextWithSeparatedBlocks } from 'features/aiWriter/AiWriterTextEditor/utils/getRangeTextWithSeparatedBlocks';
import { BaseOutput } from 'features/aiWriter/slashCommands/BaseOutput';
import { FlashActionsInputProps } from 'features/aiWriter/slashCommands/FlashActionsInput';
import {
  useFlashActionsSelectedText,
  useGetFlashActionsSelectedAction
} from 'features/aiWriter/slashCommands/hooks/useFlashActionsStore';
import { getCurrentModelLanguageAndCountry } from 'features/aiWriter/store/selectors';
import { GAEvents } from 'services/tracking/GAEvents';
import { useAppSelector } from 'store/hooks';
import isNotEmptyString from 'utils/isNotEmptyString';
import { assertNonNullable } from 'utils/typescript/nonNullable';

type Props = FlashActionsInputProps;

const OUTPUT_TYPE = 'quick_action';

export function CustomFlashActionsOutput(props: Props) {
  const editor = useEditor();
  const flashActionsSelectedText = useFlashActionsSelectedText();
  const selectedAction = useGetFlashActionsSelectedAction();

  const { currentModelCountry: country, currentModelLanguage: language } = useAppSelector(
    getCurrentModelLanguageAndCountry
  );

  assertNonNullable(selectedAction?.prePrompt, 'no prompt provided for custom action');
  const context = selectAndReturnCommand(editor, flashActionsSelectedText);
  const command = `${selectedAction.prePrompt}: ${context}`;

  const generateConfig = {
    outputType: OUTPUT_TYPE,
    text: command
  };

  const onGenerate = () => {
    GAEvents.flashActionExecuted({
      action: 'custom',
      language,
      country,
      prompt: command,
      isInline: true
    });
  };

  return (
    <BaseOutput generateConfig={generateConfig} onGenerate={onGenerate} onClose={props.onClose} />
  );
}

/**
 * Command actions are available under both the selection and the blank block, so decide which piece of text to use.
 */
export function selectAndReturnCommand(editor: PlateEditor, selectedText: string): string {
  let command = selectedText;

  if (isNotEmptyString(command)) {
    return command;
  }

  const selection = editor.selection;

  if (!selection) return '';

  const currentLocation = {
    anchor: selection.anchor,
    focus: selection.focus
  };

  const closestBlockEnd = getPointBefore(editor, currentLocation, { unit: 'block' });

  if (!closestBlockEnd) {
    return '';
  }

  const currentBlock = {
    anchor: currentLocation.anchor,
    focus: closestBlockEnd
  };

  command = getRangeTextWithSeparatedBlocks(editor, currentBlock);

  if (isNotEmptyString(command)) {
    select(editor, currentBlock);
    return command;
  }

  const previousBlock = {
    anchor: closestBlockEnd,
    focus: { path: closestBlockEnd.path, offset: 0 }
  };

  return getRangeTextWithSeparatedBlocks(editor, previousBlock);
}
