import { useModal } from 'components/modals';
import { CustomModalProps } from 'features/textGenerator/outputTypeModal/OutputTypeModal';

export function useShowOutputTypeModal() {
  const { showModal } = useModal();

  return (props: CustomModalProps) => {
    showModal('OUTPUT_TYPES', { ...props, size: 1100 });
  };
}
