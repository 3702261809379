import { FilterProps } from 'react-table';
import { useDebouncedCallback } from 'use-debounce';

import useTr from '../../../utils/hooks/useTr';
import { TableFilterInput } from '../TableComponents';

function TextFilter<Data extends object>({
  column: { filterValue, setFilter }
}: FilterProps<Data>) {
  const tr = useTr();
  const [debouncedSetFilter] = useDebouncedCallback((value: string | undefined) => {
    setFilter(value);
  }, 300);

  return (
    <TableFilterInput
      autoFocus
      defaultValue={filterValue}
      onChange={e => {
        const { value } = e.target;
        debouncedSetFilter(value || undefined);
      }}
      placeholder={tr('common.search_placeholder')}
    />
  );
}

export default TextFilter;
