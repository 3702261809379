import { getRoutePath } from 'config/routes';
import { AiImagesPreview } from 'features/aiImages/AiImagesPreviewPage/AiImagesPreview';
import { useParams } from 'react-router';
import { Navigate } from 'react-router-dom';

type PreviewParams = {
  hash: string;
};

export function AiImagesPreviewPage() {
  const params = useParams<PreviewParams>();

  if (params.hash) {
    return <AiImagesPreview hash={params.hash} />;
  }

  return <Navigate to={{ pathname: getRoutePath('login') }} />;
}
