import { Button, CircularProgress } from '@mui/material';
import FlexContainer from 'components/FlexContainer';
import { StatisticTile } from 'features/profilePage/wordsUsage/currentUsage/StatisticTile';
import { useGetCurrentTeamUsage } from 'features/profilePage/wordsUsage/useGetCurrentTeamUsage';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

export function CurrentTeamUsageSection() {
  const { data: teamCurrentUsage, isSuccess, isError, refetch } = useGetCurrentTeamUsage();

  return <Root>{selectContent()}</Root>;

  function selectContent() {
    if (isSuccess && teamCurrentUsage.status) {
      return (
        <FlexContainer direction="row" justifyContent="flex-start" gap="medium">
          <StatisticTile
            title="profile_page.usage_card.current_usage_section.words_count.team"
            statistic={teamCurrentUsage.data.total_words_count}
          />
          <StatisticTile
            title="profile_page.usage_card.current_usage_section.words_count.individual"
            statistic={teamCurrentUsage.data.words_count}
          />
        </FlexContainer>
      );
    }

    const beError = isSuccess && !teamCurrentUsage.status;

    if (isError || beError) {
      return (
        <CenteredItemsBox>
          <FormattedMessage id="profile_page.usage_card.current_usage_section.error" />
          <Button onClick={() => refetch()}>
            <FormattedMessage id="common.refresh" />
          </Button>
        </CenteredItemsBox>
      );
    }

    return (
      <CenteredItemsBox>
        <CircularProgress />
      </CenteredItemsBox>
    );
  }
}

const Root = styled.section``;

const CenteredItemsBox = styled.div`
  display: grid;
  place-items: center;
`;
