import { AiTesterDocumentPageTopBar } from 'features/aiTester/AiTesterDocumentPageTopBar';
import { getIsGenerateConfigValid, getIsViewExtended } from 'features/aiTester/store/selectors';
import TesterGraphSection from 'features/aiTester/TesterGraphSection';
import TesterSuggestions from 'features/aiTester/TesterSuggestions';
import TesterTextsSection from 'features/aiTester/TesterTextsSection';
import useStartTesterTour from 'features/aiTester/tour/hooks/useStartTesterTour';
import { useAppSelector } from 'store/hooks';
import styled from 'styled-components';

export const TesterDocumentPage = () => {
  const isViewExtended = useAppSelector(getIsViewExtended);
  const isGenerateConfigReady = useAppSelector(getIsGenerateConfigValid);

  useStartTesterTour();

  return (
    <Root>
      <AiTesterDocumentPageTopBar />

      <Content>
        <Texts $isExtended={isViewExtended}>
          <TesterTextsSection />

          {isGenerateConfigReady && <TesterSuggestions />}
        </Texts>

        <Graph $isExtended={isViewExtended}>
          <TesterGraphSection />
        </Graph>
      </Content>
    </Root>
  );
};

const Root = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${({ theme }) => theme.spacings.xmedium};
`;

const Content = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  gap: 5rem;
  width: 100%;
`;

const Texts = styled.div<{ $isExtended?: boolean }>`
  display: flex;
  flex: 6 0 0;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.large};

  max-width: ${({ $isExtended }) => ($isExtended ? 'auto' : '840px')};
`;

const Graph = styled.div<{ $isExtended?: boolean }>`
  display: ${({ $isExtended }) => ($isExtended ? 'block' : 'none')};
  flex: 4 0 0;
`;
