import { Table, TableBody } from '@mui/material';
import { FeatureTableRow } from 'components/profile/components/feature-comparison/FeatureTableRow';
import { FeatureTableRowData } from 'components/profile/components/feature-comparison/FeatureTableRowData';
import {
  SectionTitle,
  StyledTableContainer
} from 'components/profile/components/pricing-plan-card/Components';
import { FormattedMessage } from 'react-intl';

const supportData: FeatureTableRowData[] = [
  {
    nameTranslationKey: 'features_comparison.support.gdpr',
    tooltipTranslationKey: 'features_comparison.support.gdpr.tooltip',
    starterPlan: true,
    proPlan: true,
    businessPlan: true
  },
  {
    nameTranslationKey: 'features_comparison.support.guides',
    starterPlan: true,
    proPlan: true,
    businessPlan: true
  },
  {
    nameTranslationKey: 'features_comparison.support.certification',
    tooltipTranslationKey: 'features_comparison.support.certification.tooltip',
    starterPlan: true,
    proPlan: true,
    businessPlan: true
  },
  {
    nameTranslationKey: 'features_comparison.support.customer_success',
    tooltipTranslationKey: 'features_comparison.support.customer_success.tooltip',
    starterPlan: false,
    proPlan: false,
    businessPlan: true
  },
  {
    nameTranslationKey: 'features_comparison.support.webinars',
    starterPlan: false,
    proPlan: false,
    businessPlan: false,
    isNegotiable: true
  },
  {
    nameTranslationKey: 'features_comparison.support.prompt_workshops',
    starterPlan: false,
    proPlan: false,
    businessPlan: false,
    isNegotiable: true
  }
];

export const SupportTable = () => {
  return (
    <StyledTableContainer>
      <SectionTitle>
        <FormattedMessage id="features_comparison.support.label" />
      </SectionTitle>
      <Table>
        <TableBody>
          {supportData.map(row => (
            <FeatureTableRow key={row.nameTranslationKey} row={row} />
          ))}
        </TableBody>
      </Table>
    </StyledTableContainer>
  );
};
