import CodeIcon from '@mui/icons-material/Code';
import FormatBoldIcon from '@mui/icons-material/FormatBold';
import FormatItalicIcon from '@mui/icons-material/FormatItalic';
import FormatUnderlinedIcon from '@mui/icons-material/FormatUnderlined';

import { MarkFormat } from '../types';
import { getIconColor } from './getIconColor';

export const getMarkButtonIcon = (format: MarkFormat, isActive: boolean) => {
  switch (format) {
    case 'bold':
      return <FormatBoldIcon color={getIconColor(isActive)} fontSize="small" />;
    case 'code':
      return <CodeIcon color={getIconColor(isActive)} fontSize="small" />;
    case 'italic':
      return <FormatItalicIcon color={getIconColor(isActive)} fontSize="small" />;
    case 'underline':
      return <FormatUnderlinedIcon color={getIconColor(isActive)} fontSize="small" />;
  }
};
