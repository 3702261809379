import { collapseSelection, insertText, PlateEditor } from '@udecode/plate';
import Toast from 'components/toasts/Toast';
import useEditor from 'features/aiWriter/AiWriterTextEditor/hooks/useEditor';
import { useGenerateTextInDocument } from 'features/aiWriter/AiWriterTextEditor/hooks/useGenerateTextInDocument';
import { minAmountOfCharactersToBeUsedAsContext } from 'features/aiWriter/AiWriterTextEditor/utils/consts';
import { getSelectionTextWithSeparatedBlocks } from 'features/aiWriter/AiWriterTextEditor/utils/getSelectionTextWithSeparatedBlocks';
import { getTextFromCurrentOrPreviousBlock } from 'features/aiWriter/AiWriterTextEditor/utils/getTextFromCurrentOrPreviousBlock';
import { getActiveTab } from 'features/aiWriter/store/selectors';
import { useCompleteTask } from 'features/onboardingJourney/checklist/useOnboardingChecklist';
import { clearFakeSelection } from 'features/plate/customPlugins/createFakeSelectionPlugin';
import { trackingWrapper } from 'features/tracking/wrapper/TrackingWrapper';
import { FormattedMessage } from 'react-intl';
import { TASK_TYPE } from 'services/backofficeIntegration/http/endpoints/onboardingChecklist/httpGetChecklist';
import { GAEvents } from 'services/tracking/GAEvents';
import { useAppSelector } from 'store/hooks';

const maxAmountOfCharactersToSearchWith = 6000;

const getContinueWritingContext = (editor: PlateEditor): string => {
  const selectedText = getSelectionTextWithSeparatedBlocks(editor, { noTrim: true });
  const hasSelectedText = selectedText.length > 0;

  if (hasSelectedText) {
    return selectedText;
  }

  return getTextFromCurrentOrPreviousBlock(editor, maxAmountOfCharactersToSearchWith);
};

/**
 * Capturing group in this regexp is not a mistake!
 *
 * From MDN: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/split
 * > If separator is a regular expression with capturing groups, then each time
 * > separator matches, the captured groups are spliced into the output array.
 * > This behavior is specified by the regexp's Symbol.split method.
 *
 * Example:
 * 'bacad'.split(/a/)   // ['b', 'c', 'd']
 * 'bacad'.split(/(a)/) // ['b', 'a', 'c', 'a', 'd']
 */
const separator = /(\s+)/;

function cutText(text: string) {
  return recursion(text);

  function recursion(
    currentText: string,
    parts = text.split(separator),
    length = currentText.length
  ): string {
    if (length < maxAmountOfCharactersToSearchWith) {
      return currentText;
    }

    const [droppedWord = '', droppedSeparator = '', ...nextParts] = parts;
    const droppedLength = droppedWord.length + droppedSeparator.length;
    const nextLength = length - droppedLength;
    const nextText = currentText.slice(droppedLength);
    return recursion(nextText, nextParts, nextLength);
  }
}

const whitespaceRegex = /\s$/;

const useContinueWritingFromSelectionOrContextAction = () => {
  const editor = useEditor();
  const { generateTextConfig, id: tabId } = useAppSelector(getActiveTab);
  const generateMutation = useGenerateTextInDocument({
    outputType: 'continue',
    toasts: {
      loading: <FormattedMessage id="aiWriter.continue_writing.toast_start" />,
      success: <FormattedMessage id="aiWriter.continue_writing.toast_end" />,
      error: <FormattedMessage id="aiWriter.continue_writing.toast_end.failed" />
    }
  });

  const completeContinueWritingTask = useCompleteTask(TASK_TYPE.CLICK_CONTINUE_WRITING);

  return async ({ isHotKeyUsage = false }) => {
    if (!editor) {
      return;
    }

    const text = getContinueWritingContext(editor);
    const endsWithWhitespace = whitespaceRegex.test(text);
    const trimmedText = text.trim();

    if (trimmedText.length < minAmountOfCharactersToBeUsedAsContext) {
      Toast.warning('aiWriter.continue_writing.toast_not_enough_char', {
        minAmount: minAmountOfCharactersToBeUsedAsContext
      });

      return;
    }

    GAEvents.usedFeature({
      featureName: 'continue_writing',
      fromHotkey: isHotKeyUsage,
      text: trimmedText,
      textLength: trimmedText.length
    });

    // Clear selection before endpoint call to close toolbar
    if (editor.selection !== null) {
      collapseSelection(editor, { edge: 'end' });
    }

    // Clear fake selection mark
    clearFakeSelection(editor);

    if (!endsWithWhitespace) {
      insertText(editor, ' ');
    }

    // Only select the last X characters
    const truncatedSearchText = cutText(trimmedText);

    trackingWrapper.track('aiWriterToolbarContinue', {
      documentId: tabId,
      text: truncatedSearchText,
      audienceModelId: generateTextConfig.audienceId
    });

    await generateMutation.mutateAsync({ text: truncatedSearchText });

    completeContinueWritingTask();
  };
};

export default useContinueWritingFromSelectionOrContextAction;
