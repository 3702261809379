import { Button } from '@mui/material';
import { useModal } from 'components/modals';
import { GoalConfigValues } from 'features/aiTester/modals/goalConfig/GoalConfigModal';
import { changeGoalConfigThunk } from 'features/aiTester/store/actions/config/thunks/changeGoalConfigThunk';
import { getTesterActiveTab } from 'features/aiTester/store/selectors';
import mkTesterTourClassName from 'features/aiTester/tour/utils/mkTesterTourClassName';
import React, { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { useAppDispatch, useAppSelector } from 'store/hooks';

const GoalConfigButton = (): React.ReactElement => {
  const dispatch = useAppDispatch();
  const { showModal } = useModal();

  const { wordAttributes, scoreByOpenRate, manualDimensions, dimensions } =
    useAppSelector(getTesterActiveTab);

  const openGoalModal = useCallback(() => {
    showModal('TESTING_GOAL_MODAL', {
      size: 900,
      initialValues: { wordAttributes, scoreByOpenRate, manualDimensions, dimensions },
      onSubmit: (values: GoalConfigValues) => {
        dispatch(changeGoalConfigThunk(values));
      }
    });
  }, [wordAttributes, scoreByOpenRate, manualDimensions, dimensions, showModal, dispatch]);

  return (
    <Button
      className={mkTesterTourClassName('comparison', 0)}
      color="primary"
      onClick={() => openGoalModal()}
    >
      <FormattedMessage id="goal_score.goals" />
    </Button>
  );
};

export default GoalConfigButton;
