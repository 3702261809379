import { AiWriterSupportedLanguageAndCountryCode } from 'features/aiWriter/aiWriterSupportedLanguageAndCountryCodes';
import { verifySystemTonalityValues } from 'features/aiWriter/tonality/convertSystemTonalityValue';
import {
  makeTonalityOptions,
  TonalityOption
} from 'features/aiWriter/tonality/makeTonalityOptions';
import { storeUserTonality } from 'features/aiWriter/tonality/storeUserTonality';
import { usePostHog } from 'posthog-js/react';
import { useEffect, useMemo, useState } from 'react';
import { useStableCallback } from 'utils/react/useStableCallback';

type Props = {
  systemTonality: string[];
  userTonality: string[];
  maxLength: number;
  languageAndCountryCode: AiWriterSupportedLanguageAndCountryCode;
  onChange: (newSystemTonalities: string[], newUserTonalities: string[]) => void;
};

export const useTonalityHandlers = ({
  systemTonality: unsafeSystemTonalities,
  userTonality,
  maxLength,
  languageAndCountryCode,
  onChange
}: Props) => {
  const [userTonalities, setUserTonalities] = useState<string[]>(userTonality);
  const postHog = usePostHog();

  const handleTonalityChange = (newSystemTonalities: string[], newUserTonalities: string[]) => {
    const allTonalities = [...newSystemTonalities, ...newUserTonalities];

    postHog?.capture('No of selected tone of voice', {
      amount: allTonalities.length,
      tonalities: allTonalities
    });

    onChange(newSystemTonalities, newUserTonalities);
  };

  const systemTonalityOptions = makeTonalityOptions(languageAndCountryCode);

  // Filter matching tonalities based on current document language
  const safeSelectedSystemTonalities = useSafeSystemTonalityValues({
    languageAndCountryCode,
    unsafeValues: unsafeSystemTonalities,
    onChange: (values: string[]) => handleTonalityChange(values, userTonalities)
  });

  const totalAmountSelected = safeSelectedSystemTonalities.length + userTonalities.length;

  const handlePredefinedTonalityClick = ({ value: tonalityValue }: TonalityOption) => {
    const newSelectedSystemTonalities = [...safeSelectedSystemTonalities];
    // Remove or add
    const index = safeSelectedSystemTonalities.findIndex(v => v === tonalityValue);
    if (index < 0) {
      newSelectedSystemTonalities.push(tonalityValue);
    } else {
      newSelectedSystemTonalities.splice(index, 1);
    }

    handleTonalityChange(newSelectedSystemTonalities, userTonalities);
  };

  const handleCustomTonalityChange = (newUserTonalities: string[]) => {
    setUserTonalities(newUserTonalities);
    storeUserTonality(newUserTonalities);

    handleTonalityChange(safeSelectedSystemTonalities, newUserTonalities);
  };

  const isDisabled = (selectedTonality: TonalityOption) =>
    !isSelected(selectedTonality) && totalAmountSelected >= maxLength;

  const isSelected = (option: TonalityOption) =>
    safeSelectedSystemTonalities.includes(option.value);

  const maxAmount = maxLength - safeSelectedSystemTonalities.length;

  return {
    handlePredefinedTonalityClick,
    handleCustomTonalityChange,
    isDisabled,
    isSelected,
    systemTonalityOptions,
    totalAmountSelected,
    userTonalities,
    maxAmount
  };
};

function useSafeSystemTonalityValues(props: {
  unsafeValues: string[];
  languageAndCountryCode: AiWriterSupportedLanguageAndCountryCode;
  onChange: (values: string[]) => void;
}) {
  const { unsafeValues, languageAndCountryCode, onChange } = props;
  const { incorrectValues, safeValues } = useMemo(
    () =>
      verifySystemTonalityValues({
        targetLanguageAndCountry: languageAndCountryCode,
        unsafeValues
      }),
    [languageAndCountryCode, unsafeValues]
  );

  const onChangeStable = useStableCallback(onChange);

  useEffect(() => {
    if (incorrectValues.length > 0) {
      onChangeStable(safeValues);
    }
  }, [incorrectValues, safeValues, onChangeStable]);

  return safeValues;
}
