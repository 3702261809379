import { useModal } from 'components/modals';

export const useInviteUserModal = () => {
  const { showModal } = useModal();

  return () => {
    showModal('INVITE_USER', {
      size: 600
    });
  };
};
