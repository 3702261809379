import { Typography } from '@mui/material';
import { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import { retrieveRequestIdHeader } from 'services/backofficeIntegration/http/client/useRequestIdHeaderStore';

type Props = {
  children: ReactNode;
};

export const ToastErrorWithRequestId = ({ children }: Props) => {
  const requestId = retrieveRequestIdHeader();
  return (
    <>
      {children}
      {requestId && (
        <Typography variant="caption">
          <FormattedMessage id="error.requestId.value" values={{ requestId, br: <br /> }} />
        </Typography>
      )}
    </>
  );
};
