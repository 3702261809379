import { BaseSuccessDto } from 'services/backofficeIntegration/http/BaseSuccessDto';
import { backofficeClient } from 'services/backofficeIntegration/http/client/createBackofficeClient';
import { mutationEndpoint } from 'services/backofficeIntegration/http/endpointDefinition';

type SuccessDto = BaseSuccessDto<never>;

export const httpDeleteProjects = mutationEndpoint({
  callEndpoint: (projectIds: string[]) =>
    backofficeClient
      .delete<SuccessDto>('projects/ai-writer', { data: { ids: projectIds } })
      .then(response => response.data)
});
