import PricingAPI from 'services/api/pricing';

const getFirstFreePlanId = async () => {
  const pricingResponse = await PricingAPI.getPricings({});
  if (!pricingResponse.status) {
    return null;
  }

  const firstFreePlanId = pricingResponse.data
    .filter(p => p.allow_registration === 1 && p.cost === '0.00')
    .map(p => p.id)?.[0];

  return firstFreePlanId ?? null;
};

export default getFirstFreePlanId;
