import AxiosClient from '..';
import { IdentifyTextResponse } from './types';

const LanguageUtilityAPI = {
  identifyText: (text: string) =>
    AxiosClient.post<string, IdentifyTextResponse>('/utility-methods/identify_language', {
      text
    })
};

export default LanguageUtilityAPI;
