import { useChannel, useEvent } from '@harelpls/use-pusher';
import { getUserId } from 'features/customer/store/selectors';
import { useAppSelector } from 'store/hooks';

export type NewNotificationEventHandler = () => void;

const newNotificationEventName = 'Illuminate\\Notifications\\Events\\BroadcastNotificationCreated';

export function useNewNotificationEvent(handler: NewNotificationEventHandler) {
  const customerId = useAppSelector(getUserId);

  const channel = useChannel(`private-App.Customer.${customerId}`);
  useEvent(channel, newNotificationEventName, handler);
}
