import { Tooltip } from '@mui/material';
import {
  ListStyleType,
  useIndentListToolbarButton,
  useIndentListToolbarButtonState
} from '@udecode/plate-indent-list';
import { ELEMENT_UL } from '@udecode/plate-list';
import {
  EditorFormatButton,
  EditorFormatButtonProps,
  EditorIconFormatButton
} from 'features/aiWriter/AiWriterTextEditor/EditorIconFormatButton';
import { trackEditorFormatAction } from 'features/aiWriter/AiWriterTextEditor/trackEditorFormatAction';
import { PlateElementFormat } from 'features/aiWriter/AiWriterTextEditor/types';
import { getElementButtonIcon } from 'features/aiWriter/AiWriterTextEditor/utils/getElementButtonIcon';
import FormattedMessage from 'features/i18n/FormattedMessage';
import { MouseEvent } from 'react';

type EditorListButtonProps = {
  nodeType: PlateElementFormat;
  styleVariant?: EditorFormatButtonProps;
  onClose?: () => void;
};

export function EditorListButton({ nodeType, styleVariant, onClose }: EditorListButtonProps) {
  const state = useIndentListToolbarButtonState({
    nodeType: nodeType === ELEMENT_UL ? ListStyleType.Disc : ListStyleType.Decimal
  });
  const {
    // We do it like that to prevent passing "pressed" to other components which isn't a valid HTML property
    props: { pressed, ...props }
  } = useIndentListToolbarButton(state);

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();

    trackEditorFormatAction(nodeType);

    props.onClick();

    onClose && onClose();
  };

  if (styleVariant === 'icon') {
    return (
      <Tooltip
        arrow
        placement="bottom"
        title={<FormattedMessage id={`aiWriter.toolbar.${nodeType}`} />}
      >
        <EditorIconFormatButton {...props} onClick={handleClick} $isActive={pressed}>
          {getElementButtonIcon(nodeType, pressed)}
        </EditorIconFormatButton>
      </Tooltip>
    );
  }

  return (
    <EditorFormatButton onMouseDown={handleClick} variant="text" $isActive={pressed}>
      {getElementButtonIcon(nodeType, pressed)}
      <FormattedMessage id={`aiWriter.toolbar.${nodeType}`} />
    </EditorFormatButton>
  );
}
