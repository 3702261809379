import { backofficeClient } from 'services/backofficeIntegration/http/client/createBackofficeClient';
import { mutationEndpoint } from 'services/backofficeIntegration/http/endpointDefinition';

export type DeleteWorkflowParams = {
  id: number;
};

export const httpDeleteModularWorkflow = mutationEndpoint({
  callEndpoint: ({ id }: DeleteWorkflowParams) => backofficeClient.delete(`/workflows/${id}`)
});
