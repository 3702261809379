import { DimensionOwner } from 'features/dimensions/store/types';
import { FormattedMessage } from 'react-intl';
import { Dimension } from 'services/api/dimension/types';
import { useAppSelector } from 'store/hooks';
import { css } from 'styled-components';

import ComboBox from '../../components/Autocomplete/ComboBox';
import Button from '../../components/base/Button';
import { useModal } from '../../components/modals';
import Tooltip from '../../components/tooltips/Tooltip';
import useTr from '../../utils/hooks/useTr';

type Props = {
  selectedDimensions: Dimension[];
  belongsTo: DimensionOwner;
  setBelongsTo: (value: DimensionOwner) => void;
};

const belongsToOptions: DimensionOwner[] = ['me', 'group', 'everyone'];
const tooltipWrapperProps = { className: 'd-flex' };

const RowActions = ({ selectedDimensions, belongsTo, setBelongsTo }: Props) => {
  const tr = useTr();
  const { showModal } = useModal();
  const customerId = useAppSelector(state => state.customer.id);

  const ownSelectedDimensions = selectedDimensions.filter(dim => dim.author.id === customerId);
  const tooltipEnabled = ownSelectedDimensions.length === 0 && selectedDimensions.length > 0;

  return (
    <div className="d-flex justify-content-between flex-wrap mb-2">
      <ComboBox
        data={belongsToOptions}
        itemToString={(item: DimensionOwner | null) =>
          item ? tr(`dimensions_mgmt.belongs_to.${item}`) : ''
        }
        css={css`
          width: 18rem;

          @media (max-width: 576px) {
            width: 100%;
            margin-bottom: 0.25rem;
          }
        `}
        initialSelectedItem={belongsTo}
        onChange={item => item && setBelongsTo(item)}
      />
      <div className="d-flex">
        <Button
          color="primary"
          icon="plus"
          className="d-block d-sm-inline mr-2"
          onClick={() => showModal('ADD_DIMENSION_WITH_WORDS')}
        >
          <FormattedMessage id="dimensions.add_new_dimension" />
        </Button>
        <Tooltip
          isUntargetable={tooltipEnabled}
          wrapper="div"
          wrapperProps={tooltipWrapperProps}
          disabled={!tooltipEnabled}
          content={<FormattedMessage id="dimensions_mgmt.remove_unowned" />}
        >
          <Button
            color="secondary"
            icon="trash"
            className="d-block d-sm-inline"
            disabled={ownSelectedDimensions.length < 1}
            onClick={() => {
              if (ownSelectedDimensions.length > 1) {
                showModal({
                  modalType: 'REMOVE_MULTIPLE_DIMENSIONS',
                  modalProps: {
                    ids: ownSelectedDimensions.map(dim => dim.id),
                    unownedSelection: ownSelectedDimensions.length !== selectedDimensions.length
                  }
                });
              } else if (ownSelectedDimensions.length === 1) {
                const { id, label } = ownSelectedDimensions[0];

                showModal({
                  modalType: 'REMOVE_DIMENSION',
                  modalProps: {
                    id,
                    label,
                    unownedSelection: ownSelectedDimensions.length !== selectedDimensions.length
                  }
                });
              }
            }}
          >
            <FormattedMessage id="common.remove" />
          </Button>
        </Tooltip>
      </div>
    </div>
  );
};

export default RowActions;
