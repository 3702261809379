import useUpdateSubscriptionModal from 'features/updateSubscriptionModal/hook/useUpdateSubscriptionModal';
import { parse } from 'query-string';
import { useCallback } from 'react';
import { useLocation } from 'react-router';

export default function useUpgradeOnInit() {
  const location = useLocation();
  const showUpgradeSubscriptionModal = useUpdateSubscriptionModal();

  const parsed = parse(location.search);

  return useCallback(() => {
    if (parsed?.upgrade === 'true') {
      showUpgradeSubscriptionModal();
    }
  }, [parsed?.upgrade, showUpgradeSubscriptionModal]);
}
