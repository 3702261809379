import AssistantAward from 'assets/billing/assistant-award.png';
import ContentLeaderAward from 'assets/billing/content-leader-award.png';
import HighPerformerAward from 'assets/billing/high-performer-award.png';
import SeoLeaderAward from 'assets/billing/seo-leader-award.png';
import WinterAward from 'assets/billing/winter-award.png';
import FlexContainer from 'components/FlexContainer';

export const Badges = () => {
    return (
      <FlexContainer
        direction="row"
        gap="small"
        alignItems="center"
        style={{ marginTop: '1rem', marginBottom: '1rem' }}
      >
        <img src={SeoLeaderAward} height={100} alt="seo-leader-award" />
        <img src={WinterAward} height={100} alt="winter-award" />
        <img src={ContentLeaderAward} height={100} alt="content-leader-award" />
        <img src={HighPerformerAward} height={100} alt="high-performer-award" />
        <img src={AssistantAward} height={100} alt="assistant-award" />
      </FlexContainer>
    );
  };