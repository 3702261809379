import { FlashAction } from 'features/aiWriter/slashCommands/hooks/useGetDynamicBaseFlashActionOptions';
import { BaseSelection } from 'slate';
import { create } from 'zustand';

type FlashActionsState = {
  selection: BaseSelection | null;
  selectedText: string;

  selectedAction: Action | null;

  setSelection: (selection: BaseSelection | null) => void;
  setSelectedText: (text: string) => void;
  setSelectedAction: (action: Action | null) => void;

  reset: () => void;
};

type Action = {
  name: FlashAction;
  prePrompt?: string;
  languageModel?: string;
};

export const useFlashActionsStore = create<FlashActionsState>(set => ({
  selection: null,
  selectedText: '',

  selectedAction: null,

  setSelection: selection => set(state => ({ ...state, selection })),
  setSelectedText: text => set(state => ({ ...state, selectedText: text })),

  setSelectedAction: action => set(state => ({ ...state, selectedAction: action })),

  reset: () =>
    set(() => ({
      selection: null,
      selectedText: '',
      selectedAction: null
    }))
}));

export const useFlashActionsSelection = () => useFlashActionsStore(state => state.selection);

export const setFlashActionsSelection = (selection: BaseSelection) =>
  useFlashActionsStore.getState().setSelection(selection);

export const useFlashActionsSelectedText = () => useFlashActionsStore(state => state.selectedText);

export const setFlashActionsSelectedText = (text: string) =>
  useFlashActionsStore.getState().setSelectedText(text);

export const useGetFlashActionsSelectedAction = () =>
  useFlashActionsStore(state => state.selectedAction);

export const setFlashActionsSelectedAction = (action: Action) =>
  useFlashActionsStore.getState().setSelectedAction(action);

export const resetFlashActionsStore = () => useFlashActionsStore.getState().reset();
