import styled, { css } from 'styled-components';

export function Dots() {
  return (
    <StyledDots>
      <Dot1 />
      <Dot2 />
      <Dot3 />
    </StyledDots>
  );
}

const StyledDots = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacings.xxsmall};

  @keyframes dot-flashing {
    0% {
      opacity: 1;
    }
    50%,
    100% {
      opacity: 0.2;
    }
  }
`;

const dotStyle = css`
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: ${({ theme }) => theme.colors.secondaryColorMain};
  animation: dot-flashing 1s infinite alternate;
`;

const Dot1 = styled.div`
  ${dotStyle}
  animation-delay: 0s;
`;

const Dot2 = styled.div`
  ${dotStyle}
  animation-delay: 0.5s;
`;

const Dot3 = styled.div`
  ${dotStyle}
  animation-delay: 1s;
`;
