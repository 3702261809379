/* eslint-disable react/prop-types */
import { Delete } from '@mui/icons-material';
import { Button, Checkbox, CircularProgress, IconButton, Tooltip, Typography } from '@mui/material';
import { SearchField } from 'components/SearchField';
import { useEffect, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { CellProps, Column, Row, TableInstance } from 'react-table';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import styled from 'styled-components';
import { formatToPercentageWithHundredths } from 'utils/wordUtils';

import { useModal } from '../../components/modals';
import RelativeDate from '../../components/RelativeDate';
import CenteredContainer from '../../components/styled/CenteredContainer';
import MaterialTable from '../../components/Table/MaterialTable';
import useTr from '../../utils/hooks/useTr';
import { getGroupId, getUserId, getUserName } from '../customer/store/selectors';
import { getCampaigns, getSubjectLinesIsLoading } from './store/selectors';
import { deleteCampaigns, getAllCampaigns, updateCampaign } from './store/slice';
import { Campaign, CampaignStatus, CampaignSubjectLine } from './store/types';

const TableHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const TableActions = styled.div`
  display: flex;
  align-items: center;
  gap: 1em;
`;

const TableHeader = ({ selectedFlatRows, gotoPage }: TableInstance<Campaign>) => {
  const tr = useTr();
  const dispatch = useAppDispatch();

  return (
    <TableHeaderContainer>
      <Typography variant="subtitle2">
        <FormattedMessage id="subject_lines.campaigns" />
      </Typography>
      <TableActions>
        <Tooltip title={<FormattedMessage id="common.delete" />} placement="top">
          <span>
            <IconButton
              disabled={!selectedFlatRows.length}
              onClick={() => {
                dispatch(
                  deleteCampaigns.request(selectedFlatRows.map(({ original }) => original.id))
                );
                gotoPage(0);
              }}
              size="large"
            >
              <Delete color="action" />
            </IconButton>
          </span>
        </Tooltip>
        <SearchField placeholder={tr('subject_lines.campaigns.search')} />
      </TableActions>
    </TableHeaderContainer>
  );
};

const CampaignsContainer = styled.div`
  padding: 0 2em;
`;

const CampaignsTable = () => {
  const dispatch = useAppDispatch();
  const { showModal } = useModal();
  const campaigns = useAppSelector(getCampaigns);
  const isLoading = useAppSelector(getSubjectLinesIsLoading);
  const groupId = useAppSelector(getGroupId);
  const userId = useAppSelector(getUserId);
  const accountUser = useAppSelector(getUserName);
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getAllCampaigns.request());
  }, [dispatch]);

  const openPerformanceModal = ({ name, subjectLinesRatings, ...rest }: Campaign) => {
    showModal({
      modalProps: {
        size: 900,
        title: name,
        subjectLines: subjectLinesRatings,
        onSubmit: (subjectLines: CampaignSubjectLine[]) => {
          const isPending = subjectLines.some(subjectLine => !subjectLine.openRate);
          const campaignStatus: CampaignStatus = isPending ? 'pending' : 'completed';

          dispatch(
            updateCampaign.request({
              ...rest,
              name,
              groupId,
              subjectLinesRatings: subjectLines,
              status: campaignStatus
            })
          );
        }
      },
      modalType: 'PERFORMANCE'
    });
  };

  const rowEvents = useMemo(
    () => ({
      onClick: ({ original }: Row<Campaign>) =>
        userId === original.customerId ? navigate(`/subject-lines/${original.id}`) : null
    }),
    [userId, navigate]
  );

  const columns: Array<Column<Campaign>> = [
    {
      Header: ({ getToggleAllPageRowsSelectedProps }) => (
        <Checkbox {...getToggleAllPageRowsSelectedProps()} color="primary" />
      ),
      Cell: ({ row }: CellProps<Campaign>) => (
        <Checkbox
          color="primary"
          {...row.getToggleRowSelectedProps()}
          onClick={event => {
            event.stopPropagation();
          }}
        />
      ),
      id: 'selection',
      width: 50
    },
    {
      Header: () => <FormattedMessage id="subject_lines.campaigns.name" />,
      Cell: ({ cell: { value } }) => <span>{value}</span>,
      accessor: 'name'
    },
    {
      Header: () => <FormattedMessage id="subject_lines.campaigns.status" />,
      Cell: ({ cell: { value } }) => (
        <span>
          <FormattedMessage id={`subject_lines.campaigns.status.${value}`} />
        </span>
      ),
      accessor: 'status'
    },
    {
      Header: () => <FormattedMessage id="subject_lines.campaigns.author" />,
      Cell: ({ cell: { value } }) => <span>{value}</span>,
      accessor: 'user'
    },
    {
      Header: () => <FormattedMessage id="subject_lines.campaigns.createdOn" />,
      Cell: ({ cell: { value } }) => <RelativeDate date={value} />,
      accessor: 'createdAt'
    },
    {
      Header: () => <FormattedMessage id="subject_lines.campaigns.launchedOn" />,
      Cell: ({ cell: { value } }) => <span>{value}</span>,
      accessor: 'launchedAt'
    },
    {
      Header: () => <FormattedMessage id="subject_lines.campaigns.performance" />,
      Cell: ({ row: { original } }) => {
        if (original.status !== 'open' && original.user === accountUser) {
          return (
            <Button
              color="primary"
              onClick={event => {
                event.stopPropagation();
                openPerformanceModal(original);
              }}
            >
              {original.subjectLinesRatings.some(i => i.openRate) ? (
                <span>
                  {formatToPercentageWithHundredths(
                    Math.max(...original.subjectLinesRatings.map(({ openRate }) => openRate || 0))
                  )}
                </span>
              ) : (
                <FormattedMessage id="subject_lines.campaigns.add_open_rate" />
              )}
            </Button>
          );
        } else {
          return null;
        }
      },
      accessor: 'subjectLinesRatings'
    }
  ];

  return (
    <CampaignsContainer>
      {isLoading ? (
        <CenteredContainer>
          <CircularProgress />
        </CenteredContainer>
      ) : (
        <MaterialTable
          columns={columns}
          data={campaigns}
          rowEvents={rowEvents}
          topTableActions={TableHeader}
        />
      )}
    </CampaignsContainer>
  );
};

export default CampaignsTable;
