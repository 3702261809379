import { scaleLinear } from 'd3-scale';

export const palettes = {
  redYellowGreen: [
    // '#a50026',
    '#d73027',
    '#f46d43',
    '#fdae61',
    '#fee08b',
    '#ffffbf',
    '#d9ef8b',
    '#a6d96a',
    '#66bd63',
    '#1a9850'
    // '#006837'
  ],
  pinkGreen: [
    // '#8e0152',
    '#c51b7d',
    '#de77ae',
    '#f1b6da',
    '#fde0ef',
    '#f7f7f7',
    '#e6f5d0',
    '#b8e186',
    '#7fbc41',
    '#4d9221'
    // '#276419'
  ],
  redBlue: [
    // '#67001f',
    '#b2182b',
    '#d6604d',
    '#f4a582',
    '#fddbc7',
    '#f7f7f7',
    '#d1e5f0',
    '#92c5de',
    '#4393c3',
    '#2166ac'
    // '#053061'
  ],
  redGreen: [
    // '#67001f',
    '#b2182b',
    '#d6604d',
    '#f4a582',
    '#fddbc7',
    '#f7f7f7',
    '#e6f5d0',
    '#b8e186',
    '#7fbc41',
    '#4d9221'
    // '#276419'
  ],
  purpleDarkGreen: [
    // '#40004b',
    '#762a83',
    '#9970ab',
    '#c2a5cf',
    '#e7d4e8',
    '#f7f7f7',
    '#d9f0d3',
    '#a6dba0',
    '#5aae61',
    '#1b7837'
    // '#00441b'
  ],
  redGreenLegacy: [
    '#c71f47',
    '#d04c6c',
    '#d97891',
    '#e2a5b6',
    '#ebd2db',
    '#ffffff',
    '#d2ebd7',
    '#a5e2b3',
    '#78d98f',
    '#4cd06b',
    '#1fc747'
  ]
};

export type Palette = keyof typeof palettes;

export const graphColorScale = (palette: Palette) =>
  scaleLinear<string, string>()
    // generates scales -1 to 1
    .domain(palettes[palette].map((_, i, a) => ((200 / (a.length - 1)) * i - 100) / 100))
    .range(palettes[palette]);
