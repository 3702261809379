import { safeEnum } from 'utils/safeEnum';

export type ConversationMessageDto = {
  id: string;
  text: string;
  source: ConversationMessageSource;
  created_at: number;
  information_ids?: number[];
};

const ConversationMessageSource = safeEnum.make({
  user: 'user',
  assistant: 'assistant'
} as const);

type ConversationMessageSource = safeEnum.infer<typeof ConversationMessageSource>;

export { ConversationMessageSource };
