import { Search } from '@mui/icons-material';
import { Autocomplete, AutocompleteRenderInputParams, InputAdornment } from '@mui/material';
import { StyledSearchField } from 'components/SearchField';
import useTr from 'utils/hooks/useTr';

type Props<T extends { name: string }> = {
  options: T[];
  onChange: (value: T) => void;
};

function ProjectAutocomplete<T extends { name: string }>({ options, onChange }: Props<T>) {
  return (
    <Autocomplete
      options={options}
      getOptionLabel={option => option.name}
      renderOption={(props, option) => <li {...props}>{option.name}</li>}
      onChange={(_, value) => {
        if (value) {
          onChange(value);
        }
      }}
      renderInput={props => <Input {...props} />}
    />
  );
}

const Input = (props: AutocompleteRenderInputParams) => {
  const tr = useTr();

  return (
    <StyledSearchField
      {...props}
      name="project"
      variant="standard"
      placeholder={tr('aiWriter.project_overview.autocomplete.placeholder')}
      InputProps={{
        ...props.InputProps,
        disableUnderline: true,
        startAdornment: (
          <InputAdornment position="start">
            <Search />
          </InputAdornment>
        )
      }}
    />
  );
};

export default ProjectAutocomplete;
