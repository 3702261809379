import { EmbeddingModel } from 'features/embeddingModels/store/types';

export const sortModelsByLanguage = (models: EmbeddingModel[]) =>
  models.slice().sort((a, b) => {
    const firstPriority = getPriority(a.label);
    const secondPriority = getPriority(b.label);

    if (firstPriority === secondPriority) {
      return a.label > b.label ? 1 : -1;
    }

    return firstPriority - secondPriority;
  });

const getPriority = (language: string) => {
  if (language.includes('English')) {
    return 0;
  }
  if (language.includes('German')) {
    return 1;
  }
  return 2;
};

export const sortModelsByLanguageAlphabetically = (models: EmbeddingModel[]) =>
  models.slice().sort((a, b) => a.label.localeCompare(b.label));
