import { useModal } from 'components/modals';
import { ModalCommonProps } from 'components/modals/RootModal';
import { ChangePricingPlanModal } from 'features/pricing/modals/ChangePricingPlanModal';
import { usePostHog } from 'posthog-js/react';
import { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { GAEvents } from 'services/tracking/GAEvents';

type Props = ModalCommonProps;

export const FreeCreditsLimitReachedModal = ({ closeModal }: Props) => {
  const postHog = usePostHog();

  useEffect(() => {
    GAEvents.modalSeen({
      modal: 'Features upgrade modal'
    });

    postHog?.capture('Upgrade modal seen', {
      modal: 'Features upgrade modal'
    });
  }, [postHog]);

  return (
    <ChangePricingPlanModal
      headline={<FormattedMessage id="free_credits.limit_reached.headline" />}
      subHeadline={<FormattedMessage id="free_credits.limit_reached.sub_headline" />}
      onClose={closeModal}
    />
  );
};

export function useShowRunOutOfCreditsModal() {
  const { showModal } = useModal();

  return () => showModal('FREE_CREDITS_LIMIT_REACHED', { size: 1100 });
}
