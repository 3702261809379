import AxiosClient from '..';
import {
  CreateListResponse,
  DeleteListResponse,
  GetAllListsResponse,
  GetListResponse,
  NewList,
  UpdateListResponse
} from './types';

const ListAPI = {
  getAll: () => AxiosClient.get<unknown, GetAllListsResponse>('/settings/saved-list'),
  get: (id: number) => AxiosClient.get<unknown, GetListResponse>(`/settings/saved-list/${id}`),
  create: (payload: NewList) =>
    AxiosClient.post<unknown, CreateListResponse>('/settings/saved-list', payload),
  update: (id: number, payload: NewList) =>
    AxiosClient.put<unknown, UpdateListResponse>(`/settings/saved-list/${id}`, payload),
  delete: (id: number) =>
    AxiosClient.delete<unknown, DeleteListResponse>(`/settings/saved-list/${id}`)
};

export default ListAPI;
