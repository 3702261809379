import { Button, Divider, Typography } from '@mui/material';
import FlexContainer from 'components/FlexContainer';
import FormattedMessage from 'features/i18n/FormattedMessage';
import { GeneratedText } from 'features/textGenerator/store/types';
import { ReactElement } from 'react';
import styled from 'styled-components';

type Props = {
  suggestions: GeneratedText[];
  isButtonDisabled: boolean;

  onInsertClick: (text: string) => void;
  onReplaceClick: (text: string) => void;
};

/**
 * tech-debt: This component is a more less copy paste of BaseOutput.
 *            Since streamed generate text doesn't accept systemTonality and userTonality,
 *            we can't use it here, but should be used in the future.
 */
export const RewriteActionSuggestion = ({
  suggestions,
  isButtonDisabled,
  onInsertClick,
  onReplaceClick
}: Props): ReactElement => {
  const text = suggestions[0]?.text || '';
  return (
    <FlexContainer gap="medium">
      <FlexContainer gap="small">
        <FlexContainer direction="row" alignItems="center" justifyContent="space-between">
          <Typography variant="subtitle2">
            <FormattedMessage id="aiWriter.flashActions_input.output.title" />
          </Typography>
        </FlexContainer>
        <Divider />
        <TextBox>{text}</TextBox>
      </FlexContainer>
      <ButtonsBox>
        <Button variant="contained" onClick={() => onInsertClick(text)} disabled={isButtonDisabled}>
          <FormattedMessage id="aiWriter.flashActions_input.output.insert" />
        </Button>
        <Button variant="outlined" onClick={() => onReplaceClick(text)} disabled={isButtonDisabled}>
          <FormattedMessage id="aiWriter.flashActions_input.output.replace" />
        </Button>
      </ButtonsBox>
    </FlexContainer>
  );
};

const ButtonsBox = styled.div`
  display: flex;
  flex-direction: row-reverse;
  gap: ${({ theme }) => theme.spacings.small};
`;

const TextBox = styled(Typography).attrs({
  variant: 'body2'
})`
  min-height: 100px;
  max-height: 400px;

  overflow-y: scroll;
`;
