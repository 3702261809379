import { DropdownQuestion } from 'features/onboardingJourney/newUserSurvey/questionTypes/DropdownQuestion';
import { SelectableQuestion } from 'features/onboardingJourney/newUserSurvey/questionTypes/SelectableQuestion';
import { SingleChoiceQuestion } from 'features/onboardingJourney/newUserSurvey/questionTypes/SingleChoiceQuestion';
import { useMemo } from 'react';
import {
  SurveyQuestionDto,
  SurveyQuestionOptionDto,
  SurveyQuestionType
} from 'services/backofficeIntegration/http/dtos/SurveyQuestionsDto';

type Props = {
  question: SurveyQuestionDto;
  onChange: (option: SurveyQuestionOptionDto) => void;
};

export const NewUserSurveyQuestion = ({ question, onChange }: Props) => {
  return useMemo(() => {
    switch (question.question_type) {
      case SurveyQuestionType.single_choice:
        return <SingleChoiceQuestion key={question.id} question={question} onChange={onChange} />;
      case SurveyQuestionType.selectable_option:
        return <SelectableQuestion key={question.id} question={question} onChange={onChange} />;
      case SurveyQuestionType.dropdown:
        return <DropdownQuestion key={question.id} question={question} onChange={onChange} />;

      default:
        return null;
    }
  }, [question, onChange]);
};
