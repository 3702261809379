// noinspection JSUnusedGlobalSymbols

import * as locales from '@mui/material/locale';
import { createTheme } from '@mui/material/styles';
import { AppLanguage } from 'features/language/store/types';
import { localeMap } from 'styles/materialTheme';
import { NewThemeConfig } from 'styles/new-theme/newThemeConfig';
import { ThemeMode } from 'styles/new-theme/useAppThemeStore';

declare module '@mui/material/Alert' {
  interface AlertPropsVariantOverrides {
    standardFullWidth: true;
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    body1Bold: true;
  }
}

declare module '@mui/material/styles' {
  interface TypographyVariants {
    body1Bold: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    body1Bold?: React.CSSProperties;
  }
}

export const newMaterialTheme = (
  appLanguage: AppLanguage,
  themeMode: ThemeMode,
  themeConfig: NewThemeConfig
) =>
  createTheme(
    {
      palette: {
        mode: themeMode,
        text: {
          primary: themeConfig.colors.textPrimary,
          secondary: themeConfig.colors.textSecondary,
          disabled: themeConfig.colors.textDisabled
        },
        primary: {
          light: themeConfig.colors.primaryColorLight,
          main: themeConfig.colors.primaryColorMain,
          contrastText: themeConfig.colors.primaryColorContrast,
          dark: themeConfig.colors.primaryColorDark
        },
        secondary: {
          light: themeConfig.colors.secondaryColorLight,
          main: themeConfig.colors.secondaryColorMain,
          contrastText: themeConfig.colors.secondaryColorContrast,
          dark: themeConfig.colors.secondaryColorDark
        },
        warning: {
          main: themeConfig.colors.warningMain,
          light: themeConfig.colors.warningLight,
          contrastText: themeConfig.colors.warningContrast,
          dark: themeConfig.colors.warningDark
        },
        info: {
          main: themeConfig.colors.infoMain,
          light: themeConfig.colors.infoLight,
          contrastText: themeConfig.colors.infoContrast,
          dark: themeConfig.colors.infoDark
        },
        success: {
          main: themeConfig.colors.successMain,
          light: themeConfig.colors.successLight,
          contrastText: themeConfig.colors.successContrast,
          dark: themeConfig.colors.successDark
        },
        error: {
          main: themeConfig.colors.errorMain,
          light: themeConfig.colors.errorLight,
          contrastText: themeConfig.colors.errorContrast,
          dark: themeConfig.colors.errorDark
        },
        divider: themeConfig.colors.divider,
        background: {
          default: themeConfig.colors.backgroundDefault
        },
        common: {
          black: themeConfig.colors.palette.black,
          white: themeConfig.colors.palette.white
        }
      },
      typography: {
        fontFamily: themeConfig.fontFamily,
        allVariants: {
          color: themeConfig.colors.textPrimary
        },
        h1: {
          ...themeConfig.typography.h1
        },
        h2: {
          ...themeConfig.typography.h2
        },
        h3: {
          ...themeConfig.typography.h3
        },
        h4: {
          ...themeConfig.typography.h4
        },
        h5: {
          ...themeConfig.typography.h5
        },
        h6: {
          ...themeConfig.typography.h6
        },
        body1: {
          ...themeConfig.typography.body1
        },
        body1Bold: {
          ...themeConfig.typography.body1Bold
        },
        body2: {
          ...themeConfig.typography.body2
        },
        subtitle1: {
          ...themeConfig.typography.subtitle1
        },
        subtitle2: {
          ...themeConfig.typography.subtitle2
        },
        caption: {
          ...themeConfig.typography.caption
        },
        overline: {
          ...themeConfig.typography.overline
        }
      },
      components: {
        MuiTypography: {
          defaultProps: {
            variantMapping: {
              body1: 'div',
              body2: 'div'
            }
          },
          styleOverrides: {
            /**
             * Fix for: https://github.com/mui/material-ui/issues/32942
             */
            inherit: {
              fontFamily: 'inherit',
              fontWeight: 'inherit',
              fontSize: 'inherit',
              lineHeight: 'inherit',
              letterSpacing: 'inherit'
            }
          }
        },
        MuiToggleButton: {
          styleOverrides: {
            root: {
              textTransform: 'none'
            }
          }
        },
        MuiButton: {
          defaultProps: {
            size: 'large'
          },
          styleOverrides: {
            root: {
              borderRadius: themeConfig.borderRadius.componentButton,
              textTransform: 'none',
              transition: themeConfig.transition.create(
                themeConfig.transition.default,
                'background-color',
                'color',
                'box-shadow',
                'border-color'
              ),

              '&.MuiButton-sizeSmall': {
                ...themeConfig.typography.button.small
              },

              '&.MuiButton-sizeMedium': {
                ...themeConfig.typography.button.medium
              },

              '&.MuiButton-sizeLarge': {
                ...themeConfig.typography.button.large
              }
            },
            containedPrimary: ({ theme }) => ({
              color:
                theme.palette.mode === 'dark'
                  ? themeConfig.colors.textPrimary
                  : themeConfig.colors.primaryColorContrast
            }),
            containedSecondary: {
              color: themeConfig.colors.secondaryColorContrast
            },
            outlinedPrimary: {
              color: themeConfig.colors.primaryColorMain,
              borderColor: themeConfig.colors.primaryColorOutlinedborder
            }
          }
        },
        MuiButtonGroup: {
          styleOverrides: {
            root: {
              borderRadius: themeConfig.borderRadius.componentButton
            }
          }
        },
        MuiFab: {
          styleOverrides: {
            primary: {
              color: themeConfig.colors.primaryColorContrast
            },
            secondary: {
              color: themeConfig.colors.secondaryColorContrast
            },
            extended: {
              minWidth: '96px'
            }
          }
        },
        MuiTableCell: {
          styleOverrides: {
            head: {
              fontSize: 14,
              fontWeight: 500
            },
            root: {
              fontSize: 14,
              fontWeight: 400,
              padding: `${themeConfig.spacings.three} ${themeConfig.spacings.three} ${themeConfig.spacings.three} checkbox`
            },
            footer: {
              borderBottom: 'none'
            }
          }
        },
        MuiStepIcon: {
          styleOverrides: {
            text: {
              fill: themeConfig.colors.textPrimary
            }
          }
        },
        MuiAccordion: {
          styleOverrides: {
            root: ({ theme }) => ({
              '&, &:first-of-type, &:last-of-type': {
                borderRadius: themeConfig.borderRadius.three
              },

              '&:before': {
                display: 'none'
              },

              backgroundColor:
                theme.palette.mode === 'dark'
                  ? themeConfig.colors.backgroundPaperElevation2
                  : undefined
            })
          }
        },
        MuiPaper: {
          styleOverrides: {
            root: {
              borderRadius: themeConfig.borderRadius.three,
              backgroundColor: themeConfig.colors.backgroundPaperElevation1,

              '&.MuiPaper-elevation2': {
                backgroundColor: themeConfig.colors.backgroundPaperElevation2
              },
              '&.MuiPaper-elevation3': {
                backgroundColor: themeConfig.colors.backgroundPaperElevation3
              },
              '&.MuiPaper-elevation4': {
                backgroundColor: themeConfig.colors.backgroundPaperElevation4
              },
              '&.MuiPaper-elevation5': {
                backgroundColor: themeConfig.colors.backgroundPaperElevation5
              },
              '&.MuiPaper-elevation6': {
                backgroundColor: themeConfig.colors.backgroundPaperElevation6
              },
              '&.MuiPaper-elevation7': {
                backgroundColor: themeConfig.colors.backgroundPaperElevation7
              },
              '&.MuiPaper-elevation8': {
                backgroundColor: themeConfig.colors.backgroundPaperElevation8
              },
              '&.MuiPaper-elevation9': {
                backgroundColor: themeConfig.colors.backgroundPaperElevation9
              },
              '&.MuiPaper-elevation10': {
                backgroundColor: themeConfig.colors.backgroundPaperElevation10
              },
              '&.MuiPaper-elevation11': {
                backgroundColor: themeConfig.colors.backgroundPaperElevation11
              },
              '&.MuiPaper-elevation12': {
                backgroundColor: themeConfig.colors.backgroundPaperElevation12
              },
              '&.MuiPaper-elevation13': {
                backgroundColor: themeConfig.colors.backgroundPaperElevation13
              },
              '&.MuiPaper-elevation14': {
                backgroundColor: themeConfig.colors.backgroundPaperElevation14
              },
              '&.MuiPaper-elevation15': {
                backgroundColor: themeConfig.colors.backgroundPaperElevation15
              },
              '&.MuiPaper-elevation16': {
                backgroundColor: themeConfig.colors.backgroundPaperElevation16
              },
              '&.MuiPaper-elevation17': {
                backgroundColor: themeConfig.colors.backgroundPaperElevation17
              },
              '&.MuiPaper-elevation18': {
                backgroundColor: themeConfig.colors.backgroundPaperElevation18
              },
              '&.MuiPaper-elevation19': {
                backgroundColor: themeConfig.colors.backgroundPaperElevation19
              },
              '&.MuiPaper-elevation20': {
                backgroundColor: themeConfig.colors.backgroundPaperElevation20
              },
              '&.MuiPaper-elevation21': {
                backgroundColor: themeConfig.colors.backgroundPaperElevation21
              },
              '&.MuiPaper-elevation22': {
                backgroundColor: themeConfig.colors.backgroundPaperElevation22
              },
              '&.MuiPaper-elevation23': {
                backgroundColor: themeConfig.colors.backgroundPaperElevation23
              },
              '&.MuiPaper-elevation24': {
                backgroundColor: themeConfig.colors.backgroundPaperElevation24
              }
            }
          }
        },
        MuiCard: {
          styleOverrides: {
            root: {
              borderRadius: themeConfig.borderRadius.componentCard
            }
          }
        },
        MuiTextField: {
          styleOverrides: {
            root: {
              borderRadius: themeConfig.borderRadius.componentTextField
            }
          }
        },
        MuiInputBase: {
          styleOverrides: {
            root: ({ theme }) => ({
              backgroundColor:
                theme.palette.mode === 'dark'
                  ? themeConfig.colors.backgroundPaperElevation2
                  : undefined
            })
          }
        },
        MuiOutlinedInput: {
          styleOverrides: {
            root: {
              borderRadius: themeConfig.borderRadius.componentTextField
            }
          }
        },
        MuiSelect: {
          styleOverrides: {
            root: ({ theme }) => ({
              borderRadius: themeConfig.borderRadius.componentButton,
              backgroundColor:
                theme.palette.mode === 'dark'
                  ? themeConfig.colors.backgroundPaperElevation2
                  : undefined
            })
          }
        },
        MuiLink: {
          styleOverrides: {
            root: {
              color: themeConfig.colors.primaryColorMain,
              textDecoration: 'none',

              '&:hover': {
                textDecoration: 'underline'
              }
            }
          }
        },
        MuiAlert: {
          variants: [
            {
              props: { variant: 'standardFullWidth' },
              style: {
                width: '100%'
              }
            }
          ],
          styleOverrides: {
            root: {
              ...themeConfig.typography.alert.description,
              borderRadius: themeConfig.borderRadius.componentAlert,

              '&.MuiAlert-standardInfo': {
                backgroundColor: themeConfig.colors.componentsAlertInfoBackground,
                color: themeConfig.colors.componentsAlertInfoColor,

                '.MuiAlert-icon': {
                  color: themeConfig.colors.infoMain
                }
              },
              '&.MuiAlert-standardSuccess': {
                backgroundColor: themeConfig.colors.componentsAlertSuccessBackground,
                color: themeConfig.colors.componentsAlertSuccessColor,

                '.MuiAlert-icon': {
                  color: themeConfig.colors.successMain
                }
              },
              '&.MuiAlert-standardWarning': {
                backgroundColor: themeConfig.colors.componentsAlertWarningBackground,
                color: themeConfig.colors.componentsAlertWarningColor,

                '.MuiAlert-icon': {
                  color: themeConfig.colors.warningMain
                }
              },
              '&.MuiAlert-standardError, &.MuiAlert-standardFullWidthError': {
                backgroundColor: themeConfig.colors.componentsAlertErrorBackground,
                color: themeConfig.colors.componentsAlertErrorColor,

                '.MuiAlert-icon': {
                  color: themeConfig.colors.errorMain
                }
              },
              '&.MuiAlert-filledSuccess': {
                backgroundColor: themeConfig.colors.successMain,
                color: themeConfig.colors.successContrast,

                '.MuiAlert-icon': {
                  color: themeConfig.colors.successContrast
                }
              },
              '&.MuiAlert-filledWarning': {
                backgroundColor: themeConfig.colors.warningMain,
                color: themeConfig.colors.warningContrast,

                '.MuiAlert-icon': {
                  color: themeConfig.colors.warningContrast
                }
              },
              '&.MuiAlert-filledError': {
                backgroundColor: themeConfig.colors.errorMain,
                color: themeConfig.colors.errorContrast,

                '.MuiAlert-icon': {
                  color: themeConfig.colors.errorContrast
                }
              }
            }
          }
        },
        MuiAlertTitle: {
          styleOverrides: {
            root: {
              ...themeConfig.typography.alert.title,

              // If the title is the only child, we need to remove the margin
              '&:last-child': {
                marginBottom: 0
              }
            }
          }
        },
        MuiFormControlLabel: {
          styleOverrides: {
            root: {
              gap: themeConfig.spacings.two,

              // Checkbox has a outer padding of 9px, so we need to remove it to align on the gap
              '.MuiCheckbox-root': {
                paddingRight: 0
              }
            }
          }
        },
        MuiChip: {
          styleOverrides: {
            root: {
              borderRadius: themeConfig.borderRadius.componentChip,
              border: '1px solid transparent',

              '&.MuiChip-clickable:hover, &.MuiChip-deletable:hover': {
                backgroundColor: themeConfig.colors.primaryColorHover,
                color: themeConfig.colors.primaryColorMain,
                borderColor: themeConfig.colors.primaryColorMain
              },

              '&.MuiChip-deletable': {
                backgroundColor: themeConfig.colors.primaryColorMain,
                color: themeConfig.colors.commonWhiteMain
              }
            },
            label: {
              ...themeConfig.typography.chip.label
            },
            deleteIcon: {
              color: themeConfig.colors.primaryColorContrast,
              opacity: 0.6
            }
          }
        },
        MuiTableRow: {
          styleOverrides: {
            root: {
              '&.MuiTableRow-hover': {
                '&:hover': {
                  backgroundColor: themeConfig.colors.primaryColorHover
                }
              },
              '&.Mui-selected': {
                backgroundColor: themeConfig.colors.primaryColorSelected
              }
            }
          }
        },
        MuiTabs: {
          styleOverrides: {
            root: {
              minHeight: '42px'
            }
          }
        },
        MuiTab: {
          styleOverrides: {
            root: {
              ...themeConfig.typography.button.medium,
              minHeight: 'auto',
              padding: `9px ${themeConfig.spacings.three}`
            }
          }
        },
        MuiMenuItem: {
          styleOverrides: {
            root: {
              '&:hover': {
                backgroundColor: themeConfig.colors.primaryColorHover
              },
              '&.Mui-selected': {
                backgroundColor: themeConfig.colors.primaryColorSelected
              }
            }
          }
        },
        // Change color for tooltip in dark mode
        MuiTooltip: {
          styleOverrides: {
            tooltip: ({ theme }) => ({
              color:
                theme.palette.mode === 'dark'
                  ? theme.palette.text.primary
                  : theme.palette.common.white
            })
          }
        }
      }
    },
    locales[localeMap[appLanguage]]
  );
