import cx from 'classnames';
import React from 'react';

import classedComponent from '../classedComponent';
import CardOptionsItem from './CardOptionsItem';
import CardStatus from './CardStatus';

type Props = {
  children?: React.ReactNode;
  className?: string;
  title?: string;
  body?: React.ReactNode;
  options?: React.ReactNode;
  isCollapsible?: boolean;
  isCollapsed?: boolean;
  isClosable?: boolean;
  isClosed?: boolean;
  isFullscreenable?: boolean;
};

type State = {
  isClosed: boolean;
  isCollapsed: boolean;
  isFullscreen: boolean;
};

class Card extends React.PureComponent<Props, State> {
  state = {
    isClosed: this.props.isClosed || false,
    isCollapsed: this.props.isCollapsed || false,
    isFullscreen: false
  };

  static Header = classedComponent('card-header');

  static Body = classedComponent('card-body');

  static Title = classedComponent('card-title', 'h3');

  static Footer = classedComponent('card-footer');

  static Options = classedComponent('card-options');

  static Status = CardStatus;

  static OptionsItem = CardOptionsItem;

  close = (): void => {
    this.setState(s => ({
      isClosed: !s.isClosed
    }));
  };

  collapse = (): void => {
    this.setState(s => ({
      isCollapsed: !s.isCollapsed
    }));
  };

  fullscreen = (): void => {
    this.setState(s => ({
      isFullscreen: !s.isFullscreen
    }));
  };

  render(): React.ReactNode {
    const { className, children, title, options, isCollapsible, isClosable, isFullscreenable } =
      this.props;

    const { isClosed, isCollapsed, isFullscreen } = this.state;

    if (isClosed) {
      return null;
    }

    const classes = cx(
      isCollapsed && 'card-collapsed',
      isFullscreen && 'card-fullscreen',
      'card',
      className
    );

    const header = (title || options || isCollapsible || isClosable || isFullscreenable) && (
      <Card.Header>
        {title && <Card.Title>{title}</Card.Title>}
        {(options || isCollapsible || isClosable || isFullscreenable) && (
          <Card.Options>
            {options}
            {isCollapsible && <Card.OptionsItem onClick={this.collapse} type="collapse" />}
            {isFullscreenable && <Card.OptionsItem type="fullscreen" onClick={this.fullscreen} />}
            {isClosable && <Card.OptionsItem type="close" onClick={this.close} />}
          </Card.Options>
        )}
      </Card.Header>
    );

    if (header) {
      return (
        <div className={classes}>
          {header}
          {children}
        </div>
      );
    }

    return <div className={classes}>{children}</div>;
  }
}

export default Card;
