import { setIsTourRunning } from 'features/aiTester/store/actions/config/actions';
import { getIsTourFinished } from 'features/aiTester/store/selectors';
import { getIsFirstLogin } from 'features/customer/store/selectors';
import { useAppDispatch, useAppSelector } from 'store/hooks';

export default function useStartTesterTour() {
  const dispatch = useAppDispatch();

  const firstLogin = useAppSelector(getIsFirstLogin);
  const isTourFinished = useAppSelector(getIsTourFinished);

  if (firstLogin && !isTourFinished) {
    dispatch(setIsTourRunning(true));
  }
}
