import { RouteObject } from 'react-router';
import { matchRoutes } from 'react-router-dom';

/**
 * Helper function that uses results from {@link matchRoutes}
 * and builds up the path by navigating the route config tree
 */
export const getRouteMatchPath = (routes: RouteObject[], location: Partial<Location> | string) => {
  const matches = matchRoutes(routes, location);
  const getPath = (route: RouteObject) => {
    let path = route.path || '';
    if (route.children?.length) {
      path += getPath(route.children[0]);
    }

    return path;
  };

  if (matches?.length) {
    return getPath(matches[0].route);
  }

  return null;
};
