import { Checkbox, FormControlLabel } from '@mui/material';
import { BaseSetting } from 'features/modular-workflow/builder/settings/BaseSetting';
import { InputRendererContext } from 'features/modular-workflow/builder/settings/InputRenderer';
import { BuilderModularWorkflowStepInputWithValue } from 'features/modular-workflow/builder/types-builder';
import { useContext } from 'react';

export const DataTypeBoolean = () => {
  const {
    input,
    inputSettings: settings,
    onChange,
    inputErrorList
  } = useContext(InputRendererContext);
  const typedInput = input as BuilderModularWorkflowStepInputWithValue;

  return (
    <BaseSetting>
      {elementId => (
        <FormControlLabel
          control={
            <Checkbox
              id={elementId}
              placeholder={settings.description}
              defaultChecked={typedInput.value as boolean}
              onChange={(_event, checked) => onChange(typedInput, checked)}
            />
          }
          label="Default checked"
          color={inputErrorList.length > 0 ? 'error' : undefined}
        />
      )}
    </BaseSetting>
  );
};
