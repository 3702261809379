import { FormControlLabel, Radio, RadioGroup, SelectChangeEvent } from '@mui/material';
import { BaseSetting } from 'features/modular-workflow/builder/settings/BaseSetting';
import { InputRendererContext } from 'features/modular-workflow/builder/settings/InputRenderer';
import { ApiModularWorkflowStepSettingsDestinationFormat } from 'features/modular-workflow/builder/types-api';
import { BuilderModularWorkflowStepInputWithValue } from 'features/modular-workflow/builder/types-builder';
import { useContext } from 'react';

export type SupportedSettings = ApiModularWorkflowStepSettingsDestinationFormat;

export const BaseDataTypeRadioOptions = () => {
  const {
    input,
    inputSettings: settings,
    onChange,
    inputErrorList
  } = useContext(InputRendererContext);
  const typedInput = input as BuilderModularWorkflowStepInputWithValue;

  const handleChange = (event: SelectChangeEvent<string | number>) => {
    onChange(typedInput, event.target.value as string | number);
  };

  return (
    <BaseSetting>
      {elementId => (
        <RadioGroup id={elementId} defaultValue={typedInput.value} onChange={handleChange}>
          {(settings as SupportedSettings).options.map(option => (
            <FormControlLabel
              key={option.value}
              value={option.value}
              control={<Radio />}
              label={option.label}
              color={inputErrorList.length > 0 ? 'error' : undefined}
            />
          ))}
        </RadioGroup>
      )}
    </BaseSetting>
  );
};
