import { backofficeClient } from 'services/backofficeIntegration/http/client/createBackofficeClient';
import { mutationEndpoint } from 'services/backofficeIntegration/http/endpointDefinition';

export type CreateTemplateParams = {
  language: string;
  country: string;
  title: string;
  description: string;
  template: string;
  category_id: number;
  is_sharable: boolean;
  sharing_permission: string;
};

export const httpCreateTemplate = mutationEndpoint({
  callEndpoint: (params: CreateTemplateParams) =>
    backofficeClient.post('text-generation/command-templates', params)
});
