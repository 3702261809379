import { RootState } from 'store/rootReducer';

import { getTesterActiveTab } from '../../selectors';
import { outputTypesExtendableByOpenRate } from '../../types';

export const getExtendWithScoreByOpenRate = ({ state }: { state: RootState }) => {
  const { scoreByOpenRate, generateTextConfig } = getTesterActiveTab(state);
  const { audiences } = state.audiences;

  const currentOutputType = generateTextConfig?.outputType;
  const currentAudienceId = generateTextConfig?.audienceId;

  if (currentAudienceId && currentOutputType) {
    const isNotGenericAudience = !audiences.find(audience => audience.id === currentAudienceId)
      ?.is_generic;
    const isExtendableOutputTypes = outputTypesExtendableByOpenRate.includes(currentOutputType);

    return scoreByOpenRate && isNotGenericAudience && isExtendableOutputTypes;
  }

  return false;
};
