import { Typography } from '@mui/material';
import FlexContainer from 'components/FlexContainer';
import { ReactElement, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { SubscriptionPeriod } from 'services/api/customer/types';
import gtmIds from 'services/tracking/GTMIds';
import { withGtmInteraction } from 'services/tracking/withGtmInteraction';
import styled from 'styled-components';

type Props = {
  subscriptionPeriod: SubscriptionPeriod;
  onSubscriptionPeriodChange: (subscriptionPeriod: SubscriptionPeriod) => void;
};

export const PricingPeriodSelect = ({
  subscriptionPeriod,
  onSubscriptionPeriodChange
}: Props): ReactElement => {
  const handleNewSwitchChange = useCallback(() => {
    onSubscriptionPeriodChange(subscriptionPeriod === 'month' ? 'year' : 'month');
  }, [onSubscriptionPeriodChange, subscriptionPeriod]);

  return (
    <div {...withGtmInteraction(gtmIds.modals.periodSwitcher)} onClick={handleNewSwitchChange}>
      <Root direction="col" justifyContent="center" alignItems="center">
        <NewSwitchContent gap="medium" direction="row">
          <NewOption variant="body1" $isSelected={subscriptionPeriod === 'month'}>
            <FormattedMessage
              id="register.pricing.subscriptionPeriod.month_billing"
              values={{ b: (text: string) => <strong>{text}</strong> }}
            />
          </NewOption>

          <NewOptionRight variant="body1" $isSelected={subscriptionPeriod === 'year'}>
            <FormattedMessage id="register.pricing.subscriptionPeriod.year_billing" />
            &nbsp;
            <Typography variant="body1" fontWeight={700}>
              <FormattedMessage id="register.pricing.subscriptionPeriod.save_money" />
            </Typography>
          </NewOptionRight>

          <MovingBackground isLeft={subscriptionPeriod === 'month'} />
        </NewSwitchContent>
      </Root>
    </div>
  );
};

const Root = styled(FlexContainer)`
  flex: 1 0 0;
`;

const Content = styled(FlexContainer)`
  position: relative;

  margin-bottom: ${({ theme }) => theme.spacings.one};
`;

const NewSwitchContent = styled(Content)`
  width: 345px;
  height: 55px;
  border-radius: 50px;
  background-color: ${({ theme }) =>
    theme.mode === 'dark'
      ? theme.colors.backgroundPaperElevation2
      : theme.colors.palette.dark__400};
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const MovingBackground = styled.div<{ isLeft: boolean }>`
  position: absolute;
  width: 50%;
  background: ${({ theme }) =>
    theme.mode === 'dark' ? theme.colors.secondaryColorMain : theme.colors.commonWhiteMain};
  left: ${({ isLeft }) => (isLeft ? '4px' : '168px')};
  height: 40px;
  border-radius: 50px;
  transition: all 0.5s ease;
`;

const Option = styled(Typography)<{ $isSelected: boolean }>``;

const NewOption = styled(Option)`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.5s ease;
  position: relative;
  z-index: 1;
  color: ${({ theme, $isSelected }) =>
    $isSelected ? theme.colors.textPrimary : theme.colors.commonWhiteMain};
`;

const NewOptionRight = styled(NewOption)`
  position: relative;
  right: 10px;
  color: ${({ theme, $isSelected }) =>
    $isSelected ? theme.colors.textPrimary : theme.colors.commonWhiteMain};
`;
