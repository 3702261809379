import { Container } from 'components/Container';
import React from 'react';
import styled from 'styled-components';
import { mobileWindowWidthCSS } from 'utils/hooks/useMobileVersion';

type Props = { children: React.ReactNode };

export const AuthPanel = ({ children }: Props) => (
  <Root size="xl">
    <Content>{children}</Content>
  </Root>
);

const Root = styled(Container)`
  display: flex;

  width: 100%;
  margin: ${({ theme }) => `${theme.spacings.xxxxlarge} auto ${theme.spacings.xxxxlarge} auto`};

  @media (max-width: ${mobileWindowWidthCSS}) {
    padding: ${({ theme }) => `0 ${theme.spacings.large}`};
    margin: ${({ theme }) => `${theme.spacings.large} 0`};
  }
`;

const Content = styled.div`
  display: flex;
  justify-content: space-between;

  width: 100%;

  @media (max-width: ${mobileWindowWidthCSS}) {
    flex-direction: column;
  }
`;
