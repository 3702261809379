import { useQuery } from '@tanstack/react-query';
import { getHasToken } from 'features/customer/store/selectors';
import { httpGetPreferences } from 'services/backofficeIntegration/http/endpoints/customer/httpGetPreferences';
import { useAppSelector } from 'store/hooks';

export const useCustomerPreferences = () => {
  const userHasToken = useAppSelector(getHasToken);

  return useQuery({
    enabled: userHasToken,
    queryKey: httpGetPreferences.makeQueryKey(),
    queryFn: () => httpGetPreferences.callEndpoint()
  });
};
