import { Card, Typography } from '@mui/material';
import FlexContainer from 'components/FlexContainer';
import { StretchedCardContent } from 'features/aiWriter/AiWriterSidebar/steps/flashScore/FlashScoreAnalyzeView';
import useEditorText from 'features/aiWriter/AiWriterTextEditor/hooks/useEditorText';
import FormattedMessage from 'features/i18n/FormattedMessage';
import styled from 'styled-components';
import { getWordCount } from 'utils/getWordCount';

/**
 * Get the estimated reading time for a given word count
 *
 * Based on https://infusion.media/content-marketing/how-to-calculate-reading-time/#:~:text=Here's%20the%20formula%3A,the%20decimal%20is%20your%20minutes.
 */
const getTextReadingTimeInSeconds = (wordCount: number) => {
  // Expect 200 words per 60 seconds
  const averageReadingSpeed = 200 / 60;

  return Math.ceil(wordCount / averageReadingSpeed);
};

export const GeneralStatisticsCard = () => {
  const editorText = useEditorText();
  const wordCount = getWordCount(editorText);
  const readingTime = getTextReadingTimeInSeconds(wordCount);

  const characterCount = editorText.length;
  const sentenceCount = editorText.split(/[.!?]/).length - 1;

  // Get minutes and seconds
  const readingTimeMinutes = Math.floor(readingTime / 60);
  const readingTimeSeconds = readingTime % 60;

  return (
    <Card variant="outlined">
      <StretchedCardContent>
        <FlexContainer direction="column" alignItems="center" gap="small" fullHeight={true}>
          <Typography fontWeight="bold">
            <FormattedMessage id="flashScore.general_statistics.label" />
          </Typography>

          <GridWrapper>
            <StatisticsGridContainer>
              <Typography>
                <FormattedMessage id="flashScore.general_statistics.reading_time_label" />
              </Typography>
              <Typography>
                {readingTimeMinutes > 0 ? `${readingTimeMinutes}min ` : ''}
                {readingTimeSeconds > 0 ? `${readingTimeSeconds}s ` : ''}
              </Typography>

              <Typography>
                <FormattedMessage id="flashScore.general_statistics.sentences_label" />
              </Typography>
              <Typography>{sentenceCount}</Typography>

              <Typography>
                <FormattedMessage id="flashScore.general_statistics.words_label" />
              </Typography>
              <Typography>{wordCount}</Typography>

              <Typography>
                <FormattedMessage id="flashScore.general_statistics.characters_label" />
              </Typography>
              <Typography>{characterCount}</Typography>
            </StatisticsGridContainer>
          </GridWrapper>
        </FlexContainer>
      </StretchedCardContent>
    </Card>
  );
};

const GridWrapper = styled.div`
  height: 100%;
  width: 100%;

  display: grid;
  align-items: center;
  justify-items: center;
`;

const StatisticsGridContainer = styled.div`
  // Label on the left, stat on the right
  // Label is always left aligned, stat also left aligned
  // Minimum space between label and stat is 40px

  display: grid;
  grid-template-columns: auto auto;
  grid-template-rows: auto;
  column-gap: ${({ theme }) => theme.spacings.large};
  justify-content: space-between;
  align-items: center;
`;
