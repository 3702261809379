import { Button, CircularProgress } from '@mui/material';
import FlexContainer from 'components/FlexContainer';
import { getRoutePath } from 'config/routes';
import {
  getCustomerRole,
  getCustomerWordsShownMonthly,
  getIsApiCustomer
} from 'features/customer/store/selectors';
import { CustomerRole } from 'features/customer/store/types';
import { getCurrentLanguage } from 'features/language/store/selectors';
import { AppLanguage } from 'features/language/store/types';
import { getPricingPlan } from 'features/pricing/store/selectors';
import { PricingPlan } from 'features/pricing/types';
import { StatisticTile } from 'features/profilePage/wordsUsage/currentUsage/StatisticTile';
import { useGetWordsCurrentUsage } from 'features/wordsUsage/useGetWordsCurrentUsage';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { WordsCurrentUsage } from 'services/api/customer/types';
import { useAppSelector } from 'store/hooks';
import styled from 'styled-components';

const permittedPricingTypes: Array<PricingPlan> = [
  PricingPlan.pro,
  PricingPlan.business,
  PricingPlan.enterprise
];

export const CurrentUsageSection = () => {
  const wordsCurrentUsage = useGetWordsCurrentUsage();

  return <Root>{selectContent()}</Root>;

  function selectContent() {
    if (wordsCurrentUsage.isSuccess && wordsCurrentUsage.data.status) {
      return <CurrentUsageTiles currentUsage={wordsCurrentUsage.data.data} />;
    }

    const beError = wordsCurrentUsage.isSuccess && !wordsCurrentUsage.data.status;
    if (wordsCurrentUsage.isError || beError) {
      return (
        <CenteredItemsBox>
          <FormattedMessage id="profile_page.usage_card.current_usage_section.error" />
          <Button onClick={() => wordsCurrentUsage.refetch()}>
            <FormattedMessage id="common.refresh" />
          </Button>
        </CenteredItemsBox>
      );
    }

    return (
      <CenteredItemsBox>
        <CircularProgress />
      </CenteredItemsBox>
    );
  }
};

export function CurrentUsageTiles(props: { currentUsage: WordsCurrentUsage }) {
  const language = useAppSelector(getCurrentLanguage);
  const isApiCustomer = useAppSelector(getIsApiCustomer);
  const expiryDateFormatter = makeExpireDateFormatter(language);
  const wordsShownMonthly = useAppSelector(getCustomerWordsShownMonthly);
  const userRole = useAppSelector(getCustomerRole);

  const {
    words_available,
    bonus_words_available,
    words_used_current_period,
    text_generation,
    words_renewal_date
  } = props.currentUsage;

  const availableWordsWithoutBonus = words_available - bonus_words_available;

  const pricingPlan = useAppSelector(getPricingPlan);
  const isPermittedPlan = permittedPricingTypes.includes(pricingPlan);

  const individualUsage =
    userRole === CustomerRole.MEMBER ? wordsShownMonthly : words_used_current_period;

  return (
    <FlexContainer direction="row" gap="large">
      {isPermittedPlan ? (
        <StatisticTile
          title="profile_page.usage_card.current_usage_section.words_count.individual"
          statistic={individualUsage}
        />
      ) : (
        <StatisticTile
          title="profile_page.usage_card.current_usage_section.words_used"
          statistic={words_used_current_period}
        />
      )}

      {!isPermittedPlan && (
        <>
          <StatisticTile
            title="profile_page.usage_card.current_usage_section.available_words"
            statistic={parseAvailableWords(
              availableWordsWithoutBonus,
              text_generation?.is_unlimited ?? false
            )}
            additional={
              words_renewal_date && (
                <FormattedMessage
                  id="profile_page.usage_card.current_usage_section.renews"
                  values={{
                    date: expiryDateFormatter.format(new Date(words_renewal_date))
                  }}
                />
              )
            }
          />
          <StatisticTile
            title="profile_page.usage_card.current_usage_section.bonus_words"
            statistic={bonus_words_available}
            additional={
              !isApiCustomer && (
                <Link to={getRoutePath('bonusReferral')}>
                  <FormattedMessage id="profile_page.usage_card.current_usage_section.get_bonus" />
                </Link>
              )
            }
          />
        </>
      )}
    </FlexContainer>
  );
}

function makeExpireDateFormatter(language: AppLanguage) {
  return new Intl.DateTimeFormat(language, {
    month: 'short',
    day: '2-digit'
  });
}

function parseAvailableWords(availableWords: number, isUnlimited: boolean) {
  if (isUnlimited) {
    return <FormattedMessage id="common.unlimited" />;
  }

  return availableWords;
}

const Root = styled.section``;

const CenteredItemsBox = styled.div`
  display: grid;
  place-items: center;
`;
