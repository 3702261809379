import { differenceInDays } from 'date-fns';
import { useAppSelector } from 'store/hooks';

export function useShouldShowPulseIndicator(isSeen: boolean) {
  const customerRegistrationDate = useAppSelector(state => state.customer.registrationDate);
  const daysAfterRegistration = differenceInDays(Date.now(), Date.parse(customerRegistrationDate));
  const shouldShowPulseIndicator = daysAfterRegistration < 4 && !isSeen;

  return shouldShowPulseIndicator;
}
