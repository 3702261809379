/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import cx from 'classnames';
import { debounce } from 'lodash';
import React, { Component } from 'react';
import { WordTag } from 'types/WordTag';
import { prepareWord } from 'utils/wordUtils';

import WordEmbeddingAPI from '../../services/api/wordEmbedding';
import LwTagsInput from '../LwTagsInput';

type Props = {
  value: WordTag[];
  onChange: (value: WordTag[]) => void;
  model: string | string[];
  eventHandler?: React.EventHandler<React.KeyboardEvent>;
  className?: string;
  placeholder?: string;
  tagsInvalid?: (tagsInvalid: boolean) => void;
  inputProps?: any;
};

type State = {
  suggestions: string[];
};

class AutocompleteTagsInput extends Component<Props, State> {
  state: State = {
    suggestions: []
  };

  componentDidUpdate(prevProps: Props) {
    if (this.props.model !== prevProps.model) {
      this.checkWords(this.props.value, true);
    }
  }

  onChange = (tags: WordTag[]) => {
    const tagsWithState = tags.map(({ word, isAvailable, checked }) => ({
      word,
      isAvailable: isAvailable != null ? isAvailable : true,
      checked: checked != null ? checked : false
    }));

    this.props.onChange(tagsWithState);
    this.checkWords(tagsWithState);
  };

  checkWords = (tagsWithState: WordTag[], forceCheck?: boolean) => {
    const { onChange, tagsInvalid, model } = this.props;

    const tagInvalid = (tag: WordTag) => !tag.isAvailable;
    const wordsToCheck = forceCheck
      ? tagsWithState
      : tagsWithState.filter(({ checked }) => !checked);

    if (wordsToCheck.length) {
      const checkedTags: WordTag[] = JSON.parse(JSON.stringify(tagsWithState));
      const words = wordsToCheck.map(({ word }) => word);

      if (Array.isArray(model)) {
        WordEmbeddingAPI.wordsAvailableMultiModel({ words, embedding_model_ids: model }).then(
          response => {
            if (response.status) {
              response.data.forEach(({ word, availabilities }) => {
                const tagToUpdate = checkedTags.find(tag => prepareWord(tag.word) === word);
                if (tagToUpdate) {
                  tagToUpdate.isAvailable = availabilities.some(
                    availability => availability.is_available
                  );
                  tagToUpdate.checked = true;
                }
              });
            }

            onChange(checkedTags);

            if (tagsInvalid) {
              tagsInvalid(checkedTags.some(tagInvalid));
            }
          }
        );
      } else {
        WordEmbeddingAPI.wordsAvailable({ words, model }).then(response => {
          if (response.status) {
            response.data.forEach(({ word, is_available: isAvailable }) => {
              const tagToUpdate = checkedTags.find(tag => prepareWord(tag.word) === word);
              if (tagToUpdate) {
                tagToUpdate.isAvailable = isAvailable;
                tagToUpdate.checked = true;
              }
            });
          }

          onChange(checkedTags);

          if (tagsInvalid) {
            tagsInvalid(checkedTags.some(tagInvalid));
          }
        });
      }
    } else if (tagsInvalid) {
      tagsInvalid(tagsWithState.some(tagInvalid));
    }
  };

  renderTag = (props: any) => {
    const {
      tag,
      key,
      disabled,
      onRemove,
      classNameRemove,
      getTagDisplayValue,
      className,
      ...other
    } = props;

    const tagClass = cx(className, {
      'react-tagsinput-invalid-tag': !tag.isAvailable
    });

    return (
      <span key={key} className={tagClass} {...other}>
        {getTagDisplayValue(tag)}
        {!disabled && (
          <span className={classNameRemove} onClick={() => onRemove(key)} role="button" />
        )}
      </span>
    );
  };

  getSuggestions = async (word: string, model: string) => {
    try {
      const response = await WordEmbeddingAPI.wordAutocomplete({ word, model });
      if (response.status) {
        this.setState({ suggestions: response.data.suggestions });
      } else {
        this.resetSuggestions();
      }
    } catch (err) {
      this.resetSuggestions();
    }
  };

  getSuggestionsDebounced = debounce(this.getSuggestions, 500);

  resetSuggestions = () => {
    this.setState({ suggestions: [] });
  };

  render() {
    const { onChange, ...rest } = this.props;
    return (
      <LwTagsInput
        suggestions={this.state.suggestions}
        getSuggestions={this.getSuggestionsDebounced}
        resetSuggestions={this.resetSuggestions}
        onChange={this.onChange}
        renderTag={this.renderTag}
        tagDisplayProp="word"
        {...rest}
      />
    );
  }
}

export default AutocompleteTagsInput;
