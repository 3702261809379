import { getPricing } from 'features/customer/store/selectors';
import { shallowEqual } from 'react-redux';
import { useAppSelector } from 'store/hooks';
import { RootState } from 'store/store';

const useSubscriptionState = () => {
  const { subscription: currentSubscriptionState } = useAppSelector(
    (state: RootState) => state.customer,
    shallowEqual
  );
  const currentPricingPlan = useAppSelector(getPricing);


  return {
    currentSubscriptionState,
    currentPricingPlan,
  };
};

export default useSubscriptionState;
