import { createReducer } from '@reduxjs/toolkit';

import {
  updateSeoLimitations,
  updateUnsplashImagesLimitations
} from './actions/limitations/actions';
import { PricingState } from './types';
import { createPricingState } from './utils/createPricingState';

const pricingReducer = createReducer<PricingState>(createPricingState(), builder =>
  builder
    .addCase(updateSeoLimitations, (state, { payload }) => {
      state.seoLimitations = payload;
    })
    .addCase(updateUnsplashImagesLimitations, (state, { payload }) => {
      state.unsplashImagesLimitations = payload;
    })
);

export default pricingReducer;
