import { BaseSuccessDto } from 'services/backofficeIntegration/http/BaseSuccessDto';
import { backofficeClient } from 'services/backofficeIntegration/http/client/createBackofficeClient';
import { mutationEndpoint } from 'services/backofficeIntegration/http/endpointDefinition';

type CreatePersonalityFromInformationProps = {
  id: number;
};

type CreatePersonalityFromInformationDto = {
  error_message: null | string;
  is_successful: boolean;
  personality_text: string;
  status_code: number;
};

type SuccessDto = BaseSuccessDto<CreatePersonalityFromInformationDto>;

export const httpCreatePersonalityFromInformation = mutationEndpoint({
  callEndpoint: ({ id }: CreatePersonalityFromInformationProps) =>
    backofficeClient
      .get<SuccessDto>(`/informations/${id}/personalities`)
      .then(response => response.data.data)
});
