/* eslint-disable @typescript-eslint/no-explicit-any */
import hoistNonReactStatics from 'hoist-non-react-statics';
import React, { Component } from 'react';
import { IntlContext, IntlShape } from 'react-intl';
import { Subtract } from 'utility-types';

export type TrProps = {
  tr: (trKey: string, values?: Record<string, string | number>) => string;
};

export default function withTr<P extends TrProps>(WrappedComponent: React.ComponentType<P>) {
  class InjectedComponent extends Component<Subtract<P & { innerRef: React.Ref<any> }, TrProps>> {
    getTr = (intl: IntlShape) => (trKey: string, values: Record<string, string>) =>
      intl.formatMessage({ id: trKey }, values);

    render() {
      const { innerRef, ...props } = this.props;
      return (
        <IntlContext.Consumer>
          {intl => (
            <WrappedComponent ref={innerRef} {...(props as unknown as P)} tr={this.getTr(intl)} />
          )}
        </IntlContext.Consumer>
      );
    }
  }

  const ComponentWithTr = hoistNonReactStatics(InjectedComponent, WrappedComponent);

  return React.forwardRef<typeof ComponentWithTr, Subtract<P, TrProps>>((props, ref) => (
    <ComponentWithTr innerRef={ref} {...(props as P)} />
  ));
}
