import { BaseSuccessDto } from 'services/backofficeIntegration/http/BaseSuccessDto';
import { backofficeClient } from 'services/backofficeIntegration/http/client/createBackofficeClient';
import { mutationEndpoint } from 'services/backofficeIntegration/http/endpointDefinition';
import { InformationDto } from 'services/backofficeIntegration/http/endpoints/infomration/httpGetInformationList';

export type InformationType = 'text' | 'file';

export type CreateInformationParams =
  | {
      type: InformationType;
      name: string;
      description?: string;
      category_id: number;
      content: string;
    }
  | FormData;

type SuccessDto = BaseSuccessDto<InformationDto>;

export const httpCreateInformation = mutationEndpoint({
  callEndpoint: (params: CreateInformationParams) =>
    backofficeClient.post<SuccessDto>('/informations', params).then(response => response.data.data)
});
