import {
  AiWriterSupportedLanguageAndCountryCode,
  isAiWriterSupportedLanguageAndCountryCode
} from 'features/aiWriter/aiWriterSupportedLanguageAndCountryCodes';
import { SystemTonality } from 'features/aiWriter/tonality/SystemTonality';
import {
  TonalityValuesMap,
  translatedTonalities
} from 'features/aiWriter/tonality/translatedTonalities';

const invertedMaps = Object.fromEntries(
  Object.entries(translatedTonalities).map(([lang, valuesMap]) => [
    lang,
    invertValuesMap(valuesMap)
  ])
);

function invertValuesMap(valuesMap: TonalityValuesMap) {
  return Object.fromEntries(
    Object.entries(valuesMap).map(([tonality, value]) => [value, tonality as SystemTonality])
  );
}

/**
 * Tonalities are sent to text generation and saved in BO in their translated form
 * After we introduced a possibility to change document language this lead to
 * incorrect behavior described here: https://app.clickup.com/t/85zrt541e?comment=90050008601914
 * This function is responsible form mapping values from one language to another.
 */
export function verifySystemTonalityValues(props: {
  unsafeValues: string[];
  targetLanguageAndCountry: AiWriterSupportedLanguageAndCountryCode;
}) {
  const { unsafeValues, targetLanguageAndCountry } = props;

  if (!isAiWriterSupportedLanguageAndCountryCode(targetLanguageAndCountry)) {
    return {
      incorrectValues: unsafeValues,
      safeValues: []
    };
  }

  const incorrectValues = [];
  const safeValues = [];

  for (const unsafeValue of unsafeValues) {
    const safeValue = convertSystemTonalityValue({
      unsafeValue,
      targetLanguageAndCountry
    });

    if (safeValue) {
      safeValues.push(safeValue);
    }

    if (safeValue !== unsafeValue) {
      incorrectValues.push(unsafeValue);
    }
  }

  return {
    incorrectValues,
    safeValues
  };
}

function convertSystemTonalityValue(props: {
  unsafeValue: string;
  targetLanguageAndCountry: AiWriterSupportedLanguageAndCountryCode;
}): string | undefined {
  const { unsafeValue, targetLanguageAndCountry } = props;
  const { [targetLanguageAndCountry]: targetMap, ...otherMaps } = invertedMaps;

  const alreadyTranslated = !!targetMap[unsafeValue];
  if (alreadyTranslated) {
    return unsafeValue;
  }

  for (const invertedMap of Object.values(otherMaps)) {
    const matchingTonality = invertedMap[unsafeValue];
    if (matchingTonality) {
      return translatedTonalities[targetLanguageAndCountry][matchingTonality];
    }
  }

  return undefined;
}
