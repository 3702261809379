/* eslint-disable @typescript-eslint/no-non-null-assertion */
import Bar from 'components/ChartElements/Bar';
import GraphTooltip from 'components/tooltips/GraphTooltip';
import { ScaleBand, ScaleLinear } from 'd3-scale';
import { TestingChartEntry, TestingLegend } from 'features/aiTester/store/types';
import GraphContentTooltip from 'features/aiTester/TesterResultsGraph/GraphContentTooltip';
import { useState } from 'react';

type Props = {
  data: TestingChartEntry[];
  legend: TestingLegend;
  x: ScaleBand<string>;
  y: ScaleLinear<number, number>;
  onBarClick: (textId: string) => void;
  transform?: string;
};

const GraphContent = ({ data, legend, x, y, onBarClick, transform }: Props) => {
  const [hoveredField, setHover] = useState<string | null>(null);

  return (
    <g transform={transform}>
      {data.map(({ label, details, id }) => {
        let positiveStack = 0;
        let negativeStack = 0;
        const prevalenceMultiplier = 1;

        return (
          <GraphTooltip
            key={label}
            content={
              <GraphContentTooltip
                label={label}
                details={details}
                hoveredField={hoveredField}
                legend={legend}
              />
            }
          >
            <g onClick={() => onBarClick(id)}>
              {details.map(({ field, value }) => {
                let stackData: number[];
                if (
                  legend[field].active &&
                  (field === 'model_rank_score' ? details.length === 1 : true)
                ) {
                  if (value > 0) {
                    const newStackValue = positiveStack + value;
                    stackData = [positiveStack, newStackValue];
                    positiveStack = newStackValue;
                  } else {
                    const newStackValue = negativeStack + value;
                    stackData = [newStackValue, negativeStack];
                    negativeStack = newStackValue;
                  }
                } else {
                  stackData =
                    value > 0 ? [positiveStack, positiveStack] : [negativeStack, negativeStack];
                }

                const initial = {
                  x: x(label)! + (x.bandwidth() * (1 - prevalenceMultiplier)) / 2,
                  width: x.bandwidth() * prevalenceMultiplier,
                  height: 0,
                  y: y(0)
                };

                const final = {
                  x: x(label)! + (x.bandwidth() * (1 - prevalenceMultiplier)) / 2,
                  y: y(stackData[1]),
                  width: x.bandwidth() * prevalenceMultiplier,
                  height: y(stackData[0]) - y(stackData[1])
                };

                return (
                  <Bar
                    key={`${label}_${field}`}
                    field={field}
                    onHover={field => setHover(field as string)}
                    fill={legend[field].color}
                    initial={initial}
                    animate={final}
                  />
                );
              })}
            </g>
          </GraphTooltip>
        );
      })}
    </g>
  );
};

export default GraphContent;
