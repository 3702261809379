import { getTesterCurrentTabId } from 'features/aiTester/store/selectors';
import { TesterDocumentPage } from 'features/aiTester/TesterDocumentPage';
import TesterProjectOverview from 'features/aiTester/TesterProjectOverview';
import { useNavigateToOnboarding } from 'features/onboardingJourney/useNavigateToOnboarding';
import { useEnableSidebarOnMount } from 'features/pageSidebar/useSidebarStore';
import React from 'react';
import { useAppSelector } from 'store/hooks';

const AiTesterPage = (): React.ReactElement => {
  const currentTabId = useAppSelector(getTesterCurrentTabId);

  useEnableSidebarOnMount();

  useNavigateToOnboarding();

  const isTabSelected = currentTabId !== '';
  if (isTabSelected) {
    return <TesterDocumentPage />;
  }

  return <TesterProjectOverview key={currentTabId} />;
};

export default AiTesterPage;
