import { CloseModal } from 'components/modals/types';
import { ChangePricingPlanModal } from 'features/pricing/modals/ChangePricingPlanModal';
import { usePostHog } from 'posthog-js/react';
import { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { GAEvents } from 'services/tracking/GAEvents';

export const WordsLimitReachedModal = ({ closeModal }: CloseModal) => {
  const postHog = usePostHog();

  useEffect(() => {
    GAEvents.modalSeen({
      modal: 'Words upgrade modal'
    });

    postHog?.capture('Upgrade modal seen', {
      modal: 'Words upgrade modal'
    });
  }, [postHog]);

  return (
    <ChangePricingPlanModal
      headline={<FormattedMessage id="you_run_out_of_words_modal.headline" />}
      subHeadline={<FormattedMessage id="you_run_out_of_words_modal.sub_headline" />}
      onClose={closeModal}
    />
  );
};
