import { useModal } from 'components/modals';
import dayjs from 'dayjs';
import { useEffect } from 'react';
import { shallowEqual } from 'react-redux';
import { useAppSelector } from 'store/hooks';

const threeDaysAgo = dayjs().startOf('day').subtract(3, 'day');

const useEmailVerificationRequiredModal = (): void => {
  const { showModal } = useModal();
  const { registrationDate, isVerified } = useAppSelector(state => state.customer, shallowEqual);

  useEffect(() => {
    const wasRegisteredThreeDaysAgo = dayjs(registrationDate).isBefore(threeDaysAgo);

    if (wasRegisteredThreeDaysAgo && !isVerified) {
      showModal('EMAIl_VERIFICATION_REQUIRED', {
        size: 450,
        uncloseable: true
      });
    }
  }, [showModal, registrationDate, isVerified]);
};

export default useEmailVerificationRequiredModal;
