import LocalStorageKey from 'config/localStorageKey';
import { Audience } from 'services/api/audience/types';

type ReducedLocale = {
  language: string;
  country: string;
};

export function getPreferredAudience(props: {
  audiences: Audience[];
  locale: ReducedLocale;
}): Audience | undefined {
  const { audiences, locale } = props;

  const matchingAudiences = audiences.filter(
    audience => audience.language === locale.language && audience.country === locale.country
  );

  if (matchingAudiences.length === 1) {
    return matchingAudiences[0];
  }

  const lastAudienceId = localStorage.getItem(LocalStorageKey.AiWriterProject);
  const lastAudience = matchingAudiences.find(s => String(s.id) === lastAudienceId);

  if (lastAudience) {
    return lastAudience;
  }

  return matchingAudiences.find(a => a.is_generic);
}
