import { BaseSuccessDto } from 'services/backofficeIntegration/http/BaseSuccessDto';
import { backofficeClient } from 'services/backofficeIntegration/http/client/createBackofficeClient';
import { mutationEndpoint } from 'services/backofficeIntegration/http/endpointDefinition';
import { InformationCategoryDto } from 'services/backofficeIntegration/http/endpoints/infomration/httpGetInformationCategories';

export type CreateInformationCategoryParams = {
  name: string;
};

type SuccessDto = BaseSuccessDto<InformationCategoryDto>;

export const httpCreateInformationCategory = mutationEndpoint({
  callEndpoint: (params: CreateInformationCategoryParams) =>
    backofficeClient
      .post<SuccessDto>('/information-categories', params)
      .then(response => response.data.data)
});
