import useContinueWritingFromSelectionOrContextAction from 'features/aiWriter/AiWriterTextEditor/hooks/hotkey-actions/useContinueWritingFromSelectionOrContextAction';
import { useTriggerCommandAction } from 'features/aiWriter/AiWriterTextEditor/hooks/hotkey-actions/useTriggerCommandAction';
import { EditorHotkeyAction } from 'features/aiWriter/AiWriterTextEditor/types';
import { useClearEditorFormatting } from 'features/aiWriter/AiWriterTextEditor/utils/useClearFormatting';
import { GAEvents } from 'services/tracking/GAEvents';

export type HotkeyActionHandler = (action: EditorHotkeyAction, hotkey: string) => void;

export const useHotkeyActionHandler = (): HotkeyActionHandler => {
  const continueWritingAction = useContinueWritingFromSelectionOrContextAction();
  const triggerCommandAction = useTriggerCommandAction();
  const clearFormattingAction = useClearEditorFormatting();

  return (action: EditorHotkeyAction, hotkey: string) => {
    GAEvents.usedCustomShortcut(action, hotkey);

    if (action === 'continue_writing') {
      continueWritingAction({ isHotKeyUsage: true });
    }

    if (action === 'command') {
      triggerCommandAction({ isHotKeyUsage: true });
    }

    if (action === 'clearFormatting') {
      clearFormattingAction();
    }
  };
};
