import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';
import { httpGetRunningModularWorkflow } from 'services/backofficeIntegration/http/endpoints/modularWorkflow/httpGetRunningModularWorkflow';

/**
 * Get the document id from a running workflow
 *
 * Note: It always fetches the running workflow from API to ensure the latest data
 */
export const useDocumentIdFromRunningWorkflow = (runningWorkflowId: number): string | null => {
  const { data: runningWorkflowData } = useQuery({
    queryKey: ['getDocumentIdFromRunningWorkflow', runningWorkflowId],
    queryFn: () => httpGetRunningModularWorkflow.callEndpoint(runningWorkflowId)
  });
  const lastStep = runningWorkflowData
    ? runningWorkflowData.steps[runningWorkflowData.steps.length - 1]
    : null;

  return useMemo(() => {
    if (!lastStep || lastStep.outputs.length === 0) {
      return null;
    }

    const destinationOutput = lastStep.outputs.find(o => o.reference === 'destination_result');
    // The output value contains a JSON string with the document id
    // e.g. "{"ai_writer_project":{"id":4846543}}"
    if (
      !destinationOutput ||
      !destinationOutput.value ||
      typeof destinationOutput.value !== 'string' ||
      destinationOutput.value.length === 0
    ) {
      return null;
    }

    try {
      const jsonData = JSON.parse(destinationOutput.value);
      if (
        jsonData &&
        typeof jsonData === 'object' &&
        'ai_writer_project' in jsonData &&
        typeof jsonData.ai_writer_project === 'object' &&
        !!jsonData.ai_writer_project &&
        'id' in jsonData.ai_writer_project
      ) {
        return jsonData.ai_writer_project.id as string;
      }
    } catch {
      /* empty */
    }

    return null;
  }, [lastStep]);
};
