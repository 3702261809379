import { PostHogConfig } from 'posthog-js';
import { PostHogProvider } from 'posthog-js/react';
import { ReactNode } from 'react';

const providerOptions: Partial<PostHogConfig> = {
  api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST,
  autocapture: false
};

type Props = {
  children: ReactNode;
};

export const CustomPostHogProvider = ({ children }: Props) => (
  <PostHogProvider apiKey={process.env.REACT_APP_PUBLIC_POSTHOG_KEY} options={providerOptions}>
    {children}
  </PostHogProvider>
);
