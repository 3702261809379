import dayjs from 'dayjs';
import useFetchTesterProjects from 'features/aiTester/hooks/useFetchTesterProjects';
import useShowSetupModal from 'features/aiTester/modals/setup/useShowSetupModal';
import ProjectOverviewOld from 'features/aiTester/ProjectOverviewOld';
import { deleteProjectThunk } from 'features/aiTester/store/actions/project/thunks/deleteProjectThunks';
import { loadTabThunk } from 'features/aiTester/store/actions/tabs/thunks/loadTabThunk';
import { getTesterProjects } from 'features/aiTester/store/selectors';
import { TesterProject } from 'features/aiTester/store/types';
import { useEnableSidebarOnMount } from 'features/pageSidebar/useSidebarStore';
import CreateProjectButtonLarge from 'features/projects/CreateProjectButtonLarge';
import ProjectAutocomplete from 'features/projects/ProjectAutocomplete';
import { projectPreviewLength, ProjectTile } from 'features/projects/ProjectTile';
import gtmIds from 'services/tracking/GTMIds';
import GTMIds from 'services/tracking/GTMIds';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import useRunInTask from 'utils/hooks/useRunInTask';

const TesterProjectOverview = () => {
  const dispatch = useAppDispatch();
  const projects = useAppSelector(getTesterProjects);

  useEnableSidebarOnMount();

  const [isProjectLoading, runInTask] = useRunInTask();

  const { isLoading: areProjectsLoading } = useFetchTesterProjects();
  const showSetupModal = useShowSetupModal();

  const loadProject = async (project: TesterProject) => {
    await runInTask(() => dispatch(loadTabThunk(project)));
  };

  return (
    <ProjectOverviewOld
      title="testing.project_overview.title"
      isLoading={areProjectsLoading || isProjectLoading}
      autocomplete={
        <ProjectAutocomplete
          options={projects}
          onChange={(value: TesterProject) => loadProject(value)}
        />
      }
    >
      <CreateProjectButtonLarge
        id={GTMIds.tester.startNewProject}
        onClick={() => showSetupModal()}
      />

      {[...projects]
        .sort((a, b) => (dayjs(a.updatedAt).isAfter(dayjs(b.updatedAt)) ? -1 : 1))
        .map(project => (
          <ProjectTile
            key={project.id}
            embeddingModelId={project.embeddingModelId}
            isLoading={project.isLoading}
            title={project.name}
            contentPreview={project.texts
              .map(o => o.text)
              .join(' ')
              .substring(0, projectPreviewLength)}
            date={dayjs(project.updatedAt).format('DD.MM.YYYY')}
            onClick={() => loadProject(project)}
            onDelete={() => dispatch(deleteProjectThunk(project.id))}
            gtmId={gtmIds.tester.loadProject}
          />
        ))}
    </ProjectOverviewOld>
  );
};

export default TesterProjectOverview;
