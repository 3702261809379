import { getPricingPlan } from 'features/pricing/store/selectors';
import { useEffect, useRef } from 'react';
import { useAppSelector } from 'store/hooks';

export function useFetchOnPricingChange(fetch: () => void) {
  const lastPricingPlan = useRef<string | undefined>(undefined);
  const pricingPlan = useAppSelector(getPricingPlan);

  useEffect(() => {
    if (lastPricingPlan.current !== pricingPlan) {
      fetch();
      lastPricingPlan.current = pricingPlan;
    }
  }, [pricingPlan, fetch]);
}
