import { getCurrentTab } from 'store/utils/tabUtils';

import { AiWriterState } from '../../types';

export const getCurrentTextInspirationById = ({
  state,
  id
}: {
  state: AiWriterState;
  id?: string;
}) => {
  const tab = getCurrentTab(state);

  return tab?.textInspirations.find(i => i.id === id);
};
