/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from 'axios';
import { YTVideo } from 'types/YTVideo';

const axiosClient = axios.create({
  baseURL: 'https://www.googleapis.com/youtube/v3'
});

export const getYoutubePlaylistVideos = async (playlistId: string): Promise<YTVideo[]> => {
  const response = await axiosClient.get('/playlistItems', {
    params: {
      part: 'snippet',
      maxResults: 10,
      key: 'AIzaSyBKaFwSWhRAqlpr4XeEE9N8QX6_0sA44zI',
      playlistId
    }
  });

  return response.data.items.map(({ snippet: data }: any) => ({
    title: data.title,
    description: data.description,
    videoId: data.resourceId.videoId,
    date: new Date(data.publishedAt)
  }));
};
