import { CircularProgress } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import FlexContainer from 'components/FlexContainer';
import Toast from 'components/toasts/Toast';
import { getRoutePath } from 'config/routes';
import { getErrorCodeFromAxiosError } from 'features/teamInvitation/getErrorCodeFromAxiosError';
import { useNavigate, useParams } from 'react-router';
import {
  httpGetPricingCampaigns,
  PricingCampaignLink
} from 'services/backofficeIntegration/http/endpoints/customer/httpGetPricingCampaigns';
import { GAEvents } from 'services/tracking/GAEvents';
import styled from 'styled-components';
import isEmptyString from 'utils/isEmptyString';
import redirectToStripe from 'utils/redirectToStripe';

const createStripeCheckoutUrl = (data: PricingCampaignLink) => {
  const { billing_portal_url, price_id, subscription_id } = data;

  if (isEmptyString(subscription_id)) {
    return billing_portal_url;
  }

  return `${billing_portal_url}/subscriptions/${subscription_id}/preview/${price_id}`;
};

export const CampaignPage = () => {
  const navigate = useNavigate();
  const { campaignId } = useParams<{ campaignId: string }>();

  useQuery({
    enabled: !!campaignId,
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    queryKey: httpGetPricingCampaigns.makeQueryKey(campaignId!),
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    queryFn: () => httpGetPricingCampaigns.callEndpoint(campaignId!),
    onSuccess: data => {
      if (!data) {
        navigate(getRoutePath('pageNotFound'));
        return null;
      }

      const redirectUrl = createStripeCheckoutUrl(data);
      redirectToStripe(redirectUrl);
      GAEvents.navigatedToSpecialPricingLink();
    },
    onError: (error: unknown) => {
      navigate(getRoutePath('pageNotFound'));
      const errorCode = getErrorCodeFromAxiosError(error);
      if (errorCode) {
        Toast.error(`campaignPage.error.${errorCode}`);
      } else {
        Toast.apiError();
      }
    }
  });

  return (
    <Root alignItems="center" justifyContent="center">
      <CircularProgress size={100} />
    </Root>
  );
};

const Root = styled(FlexContainer)`
  width: 100%;
  height: 100%;
`;
