import { Button, Typography } from '@mui/material';
import { YellowCrownImage } from 'components/icons/YellowCrownImage';
import { GraphActionsButton } from 'features/aiTester/buttons/GraphActionsButton';
import { useAiTesterTab } from 'features/aiTester/hooks/useAiTesterTab';
import { setCurrentTab } from 'features/aiTester/store/actions/tabs/actions';
import { renameCurrentTabThunk } from 'features/aiTester/store/actions/tabs/thunks/renameCurrentTabThunk';
import {
  getIsSaving,
  getTesterActiveTab,
  getTesterTabsHeaders
} from 'features/aiTester/store/selectors';
import { DocumentPageTopBar } from 'features/document-top-bar/DocumentPageTopBar';
import { useEmptyDocumentStore } from 'features/plate/components/plate-ui/useEmptyDocumentStore';
import useUpdateSubscriptionModal from 'features/updateSubscriptionModal/hook/useUpdateSubscriptionModal';
import { useFeatureFlagVariantKey } from 'posthog-js/react';
import { FormattedMessage } from 'react-intl';
import { shallowEqual } from 'react-redux';
import gtmIds from 'services/tracking/GTMIds';
import { withGtmInteraction } from 'services/tracking/withGtmInteraction';
import { useAppDispatch, useAppSelector } from 'store/hooks';

export const AiTesterDocumentPageTopBar = () => {
  const dispatch = useAppDispatch();
  const activeDocument = useAppSelector(getTesterActiveTab);
  const documentHeaders = useAppSelector(getTesterTabsHeaders, shallowEqual);
  const isSaving = useAppSelector(getIsSaving);

  const setEnteredHeadline = useEmptyDocumentStore(state => state.setEnteredHeadline);

  const { onCreate, onSelect, onClose } = useAiTesterTab();

  const showUpgradeSubscriptionModal = useUpdateSubscriptionModal();
  const showUpgradeButton =
    useFeatureFlagVariantKey('contentflash-editor-upgrade-button-in-top-bar') === 'test';

  return (
    <DocumentPageTopBar
      activeDocumentId={activeDocument.id}
      documents={documentHeaders}
      isSaving={isSaving}
      withLanguageChange={false}
      onSelect={tabId => onSelect(tabId)}
      onClose={onClose}
      onCreate={onCreate}
      onBack={() => {
        // Make sure to clear a potential entered document title
        setEnteredHeadline('');
        dispatch(setCurrentTab(''));
      }}
      onRename={value => dispatch(renameCurrentTabThunk(value))}
      onLastClose={() => dispatch(setCurrentTab(''))}
    >
      <GraphActionsButton />

      {showUpgradeButton && (
        <Button
          {...withGtmInteraction(gtmIds.pricing.upgradePopUp)}
          variant="contained"
          startIcon={<YellowCrownImage />}
          onClick={showUpgradeSubscriptionModal}
        >
          <Typography variant="subtitle2">
            <FormattedMessage id="common.upgrade" />
          </Typography>
        </Button>
      )}
    </DocumentPageTopBar>
  );
};
