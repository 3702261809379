import { IconButton, Tooltip } from '@mui/material';
import { PulseDot } from 'components/PulseDot';
import { CSSProperties, MouseEvent, ReactNode } from 'react';
import { withGtmInteraction } from 'services/tracking/withGtmInteraction';
import styled from 'styled-components';

type Props = {
  id?: string;
  gtmId?: string;
  icon: ReactNode;
  isSelected: boolean;
  tooltip?: string;
  isDisabled?: boolean;
  isNewFeature?: boolean;
  style?: CSSProperties;

  onClick: (e: MouseEvent<HTMLButtonElement>) => void;
};

export const SelectableIconButton = ({
  id,
  gtmId,
  icon,
  isSelected,
  tooltip,
  isDisabled = false,
  isNewFeature = false,
  style,
  onClick
}: Props) => {
  const content = (
    <IconButton
      {...withGtmInteraction(gtmId)}
      id={id} //Deprecated way of gtm tag usage #tech-debt: https://app.clickup.com/t/862je7864
      onClick={onClick}
      disabled={isDisabled}
      style={style}
    >
      {isNewFeature && (
        <PulseDotContainer>
          <PulseDot />
        </PulseDotContainer>
      )}
      <IconContainer $isActive={isSelected}>{icon}</IconContainer>
    </IconButton>
  );

  return tooltip && !isDisabled ? (
    <Tooltip title={tooltip} placement="left" arrow>
      {content}
    </Tooltip>
  ) : (
    content
  );
};

const PulseDotContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 5%;

  transform: translate(0, -50%);
`;

const IconContainer = styled.div<{ $isActive: boolean }>`
  color: ${({ theme, $isActive }) =>
    $isActive ? theme.colors.componentsIconActive : theme.colors.componentsIconDefault};
`;
