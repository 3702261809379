import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from '@mui/material';
import { ColoredTypography } from 'components/ColoredTypography';
import { FileDropzone } from 'features/information/FileDropzone';
import { useField } from 'formik';
import styled from 'styled-components';

type Props = {
  name: string;
};

export function FileField(props: Props) {
  const [field, , helper] = useField(props.name);

  const handleFileSelect = (file: File[]) => {
    helper.setValue(file);
  };

  const handleFileRemove = (index: number) => {
    helper.setValue(field.value.filter((file: File, i: number) => i !== index));
  };

  const files = field.value;

  if (Array.isArray(files) && files.length > 0) {
    return (
      <>
        {files.map((file: File, index: number) => (
          <FileTile
            key={file.name}
            name={file.name}
            size={file.size}
            onRemove={() => handleFileRemove(index)}
          />
        ))}
      </>
    );
  }

  return <FileDropzone onFileSelect={handleFileSelect} name={props.name} />;
}

export function FileTile({
  name,
  size,
  onRemove
}: {
  name: string;
  size?: number;
  onRemove?: () => void;
}) {
  function formatFileSize(sizeInBytes: number): string {
    const sizeInMB = (sizeInBytes / 1048576).toFixed(2); // 1024 * 1024 = 1048576 bytes = 1MB
    return `(${sizeInMB}) MB`;
  }

  return (
    <FileTileBox>
      <TextBox>
        <ColoredTypography variant="body1">{name}</ColoredTypography>&nbsp;
        {size && (
          <ColoredTypography variant="body2" color="blackDisabled">
            {formatFileSize(size)}
          </ColoredTypography>
        )}
      </TextBox>
      {onRemove && (
        <IconButton onClick={() => onRemove()}>
          <CloseIcon fontSize="small" />
        </IconButton>
      )}
    </FileTileBox>
  );
}

const FileTileBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: ${({ theme }) => `${theme.spacings.small} ${theme.spacings.medium}`};
  border-radius: 6.25rem;
  border: 1px solid rgba(0, 0, 0, 0.12);

  background-color: ${({ theme }) =>
    theme.mode === 'dark' && theme.colors.backgroundPaperElevation2};
`;

const TextBox = styled.div`
  display: flex;
  align-items: center;
`;
