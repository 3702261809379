import { ExpandMore } from '@mui/icons-material';
import { Accordion, AccordionDetails, AccordionSummary, Button, Typography } from '@mui/material';
import { useInfiniteQuery } from '@tanstack/react-query';
import { InformationList } from 'features/information/apply-information/InformationList';
import { TagIndicator } from 'features/information/apply-information/TagIndicator';
import { useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { FormattedMessage } from 'react-intl';
import { InformationCategoryDto } from 'services/backofficeIntegration/http/endpoints/infomration/httpGetInformationCategories';
import {
  GetInformationListParams,
  httpGetInformationList,
  InformationDto
} from 'services/backofficeIntegration/http/endpoints/infomration/httpGetInformationList';
import {
  getNextPageParam,
  PaginatedListParams
} from 'services/backofficeIntegration/http/paginatedListEndpoint';
import styled from 'styled-components';

type Props = {
  tag: InformationCategoryDto;
  handleSelectInformation: (info: InformationDto) => void;
  filteredTag: InformationCategoryDto | undefined;
  searchQuery: string | undefined;
  isSingleChoiceSelection: boolean;
  value: InformationDto[] | undefined;
};

export const InformationAccordion = ({
  tag,
  filteredTag,
  searchQuery,
  isSingleChoiceSelection,
  handleSelectInformation,
  value
}: Props) => {
  const [isExpanded, setIsExpanded] = useState(searchQuery !== '' || !!filteredTag);

  const queryParams: GetInformationListParams = {
    sort: 'updated_at',
    direction: 'asc',
    category_id: tag.id,
    search: searchQuery || undefined,
    limit: 10
  };

  const {
    data: informationQuery,
    isLoading,
    isError,
    isFetching,
    hasNextPage,
    fetchNextPage
  } = useInfiniteQuery({
    queryKey: httpGetInformationList.makeQueryKey(queryParams),
    queryFn: (context: { pageParam?: PaginatedListParams }) => {
      const { pageParam = {} } = context;
      return httpGetInformationList.callEndpoint({
        ...queryParams,
        ...pageParam
      });
    },
    enabled: isExpanded || searchQuery !== '',
    getNextPageParam: getNextPageParam
  });
  const informationList = informationQuery?.pages.map(page => page.data).flat(1);

  const handleChange = () => {
    setIsExpanded(!isExpanded);
  };

  const isChecked = (id: number) => {
    return value?.some(i => i.id === id);
  };

  const { ref: loadButtonRef } = useInView({
    onChange: (inView: boolean) => {
      if (inView) {
        fetchNextPage();
      }
    }
  });

  const renderLoadMore = () => {
    if (hasNextPage && !isFetching) {
      return (
        <Button
          ref={loadButtonRef}
          onClick={() => {
            fetchNextPage();
          }}
        >
          <FormattedMessage id="common.moreButton" />
        </Button>
      );
    }
  };

  useEffect(() => {
    if (searchQuery !== '' && informationList && informationList?.length > 0) {
      setIsExpanded(true);
    }
  }, [informationList, informationList?.length, searchQuery]);

  if (!isFetching && !isError && !isLoading && informationList?.length === 0) {
    return null;
  }

  return (
    <Accordion
      key={tag.id}
      variant="outlined"
      elevation={0}
      expanded={isExpanded}
      onChange={handleChange}
    >
      <StyledAccordionSummary expandIcon={<ExpandMore />} style={{ minHeight: '3.5rem' }}>
        <TagTitle>
          <TagIndicator selectedInformationList={value} tag={tag} />
        </TagTitle>
      </StyledAccordionSummary>
      <StyledAccordionDetails>
        <InformationList
          handleSelectInformation={handleSelectInformation}
          informationList={informationList}
          isSingleChoiceSelection={isSingleChoiceSelection}
          isError={isError}
          isLoading={isLoading}
          isChecked={isChecked}
        />
        {renderLoadMore()}
      </StyledAccordionDetails>
    </Accordion>
  );
};

const TagTitle = styled(Typography).attrs({ variant: 'overline' })`
  font-size: 0.75rem;
`;

const StyledAccordionSummary = styled(AccordionSummary)`
  .MuiAccordionSummary-content.Mui-expanded {
    margin: 0;
  }
`;

const StyledAccordionDetails = styled(AccordionDetails)`
  padding: ${({ theme }) =>
    `0 ${theme.spacings.small} ${theme.spacings.small} ${theme.spacings.medium}`};
`;
