import { Typography } from '@mui/material';
import { ApiPlansCard } from 'features/homePage/apiPricing/ApiPricingCard';
import { AccessKeyCard } from 'features/profilePage/AccessKeyCard';
import { ProfileInformationCard } from 'features/profilePage/ProfileInformationCard';
import { SubscriptionCard } from 'features/profilePage/SubscriptionCard';
import { UsageCard } from 'features/profilePage/wordsUsage/UsageCard';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { shallowEqual } from 'react-redux';
import { useAppSelector } from 'store/hooks';
import styled from 'styled-components';

export const ApiDashboard = (): React.ReactElement => {
  const { group } = useAppSelector(state => state.group, shallowEqual);

  const { email, username, timezone, image } = useAppSelector(
    state => state.customer,
    shallowEqual
  );

  return (
    <ProfilePageContainer>
      <TopContainer>
        <Typography variant="h4">
          <FormattedMessage id="api_dashboard.headline" />
        </Typography>
        <Typography variant="body1">
          <FormattedMessage id="profile.subline" />
        </Typography>
      </TopContainer>

      <UsageCard />

      <ProfileInformationCard
        username={username}
        email={email}
        timezone={timezone}
        groupName={group?.name ?? '-'}
        avatarImage={image ?? undefined}
      />

      <AccessKeyCard />

      <ApiPlansCard />

      <SubscriptionCard />
    </ProfilePageContainer>
  );
};

const TopContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 3% 0;
`;

const ProfilePageContainer = styled.div`
  max-width: 100%;
`;
