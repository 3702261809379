import { getCurrentModelLanguageAndCountry } from 'features/aiWriter/store/selectors';
import { getEmbeddingModelsByLanguageAndAudience } from 'features/embeddingModels/store/selectors';
import { useAppSelector } from 'store/hooks';

export const useModelTranslation = (options?: { language: string; country: string }) => {
  const { currentModelCountry, currentModelLanguage } = useAppSelector(
    getCurrentModelLanguageAndCountry
  );
  const models = useAppSelector(getEmbeddingModelsByLanguageAndAudience);

  const searchedCountry = options?.country ?? currentModelCountry;
  const searchedLanguage = options?.language ?? currentModelLanguage;

  const model = models.find(m => m.country === searchedCountry && m.language === searchedLanguage);

  return {
    label: model?.label ?? `${searchedLanguage}_${searchedCountry}`,
    country: searchedCountry,
    language: searchedLanguage
  };
};
