import { Dispatch, SetStateAction, useCallback, useEffect, useRef, useState } from 'react';

// Determine the appropriate performance measure, fall back to Date if performance API is unavailable
const perf = typeof performance !== 'undefined' ? performance : Date;
const now = () => perf.now();

// Custom hook to store the latest value of a variable in a ref
const useLatest = <T>(current: T) => {
  const storedValue = useRef(current);

  // Update the ref whenever the value changes
  useEffect(() => {
    storedValue.current = current;
  });

  return storedValue;
};

type Options = {
  /**
   * The interval in milliseconds to throttle the callback
   */
  intervalMilliseconds?: number;
  /**
   * Whether to run the callback at the start of the interval
   */
  leading?: boolean;
};

const defaultOptions: Required<Options> = {
  intervalMilliseconds: 1000,
  leading: false
};

// Custom hook to create a throttled version of a callback function
const useCallbackThrottled = <CallbackArguments extends unknown[]>(
  callback: (...args: CallbackArguments) => void,
  options?: Options
): ((...args: CallbackArguments) => void) => {
  const storedCallback = useLatest(callback);
  const previousTime = useRef(0);
  const trailingTimeout = useRef<ReturnType<typeof setTimeout>>();

  const { intervalMilliseconds, leading } = { ...defaultOptions, ...options };

  // Helper function to clear the trailing timeout
  const clearTrailing = () => {
    if (trailingTimeout.current) {
      clearTimeout(trailingTimeout.current);
    }
  };

  // Reset the timer and clear any trailing timeouts when the component unmounts
  useEffect(() => {
    return () => {
      previousTime.current = 0;
      clearTrailing();
    };
  }, [intervalMilliseconds, leading, storedCallback]);

  return useCallback(
    function () {
      // eslint-disable-next-line prefer-rest-params
      const args = arguments;
      const rightNow = now();

      // Function to update the last execution time and execute the callback
      const updateRefAndTriggerHandler = () => {
        previousTime.current = rightNow;
        clearTrailing();
        storedCallback.current.apply(null, args as never);
      };

      const current = previousTime.current;

      // If 'leading' is true and this is the first call, execute immediately
      if (leading && current === 0) {
        return updateRefAndTriggerHandler();
      }

      // Execute the callback if the specified interval has passed
      if (rightNow - current > intervalMilliseconds) {
        if (current > 0) {
          return updateRefAndTriggerHandler();
        }

        previousTime.current = rightNow;
      }

      clearTrailing();

      trailingTimeout.current = setTimeout(() => {
        updateRefAndTriggerHandler();
        previousTime.current = 0;
      }, intervalMilliseconds);
    },
    [intervalMilliseconds, leading, storedCallback]
  );
};

export function useStateThrottled<State>(
  initialState: State | (() => State),
  options?: Options
): [State, Dispatch<SetStateAction<State>>] {
  const state = useState<State>(initialState);

  return [state[0], useCallbackThrottled(state[1], options)];
}
