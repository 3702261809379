import { Typography } from '@mui/material';
import { Restriction } from 'features/features/store/types';
import { getLabelForRestriction } from 'features/features/utils/getLabelForRestriction';
import { ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import styled, { css } from 'styled-components';

type Props = {
  restriction: Restriction;
  shortcut?: boolean;
};

export const FeatureRestriction = ({ restriction, shortcut }: Props): ReactElement => {
  return (
    <Root restriction={restriction} variant="caption">
      <FormattedMessage id={getLabelForRestriction(restriction, shortcut)} />
    </Root>
  );
};

const Root = styled(Typography)<{ restriction: Restriction }>`
  padding: ${({ theme }) => theme.spacings.xsmall};

  ${({ theme, restriction }) => {
    switch (restriction) {
      case 'premium':
      case 'pro':
      case 'enterprise':
        return css`
          background-color: ${theme.colors.secondary500};
          color: ${theme.colors.white};
        `;
      case 'basic':
      case 'standard':
        return css`
          background-color: ${theme.colors.warning};
          color: ${theme.colors.black};
        `;
      default:
        throw new Error('Unsupported restriction type');
    }
  }};
  border-radius: ${({ theme }) => theme.borderRadius.small};
`;

export default FeatureRestriction;
