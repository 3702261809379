import cuid from 'cuid';

import { TestingContent } from '../../types';

const createNewText = (overrides: Partial<TestingContent> = {}): TestingContent => ({
  id: cuid(),
  text: '',
  subSuggestions: [],
  goalScore: 0,
  prevalence: 0,
  tokenData: {},
  scoreDetails: [],
  words: [],
  isAvailable: true,
  isLoading: false,
  isEditing: true,
  ...overrides
});

export default createNewText;
