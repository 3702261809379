import { ArrowForward } from '@mui/icons-material';
import { IconButton } from 'components/iconButtons/IconButton';
import React from 'react';

type Props = {
  id?: string;
  tooltip?: React.ReactNode;

  onClick: () => void;
};

export const ForwardIconButton = ({ id, tooltip, onClick }: Props) => (
  <IconButton id={id} tooltip={tooltip} onClick={onClick} icon={<ArrowForward />} size="large" />
);
