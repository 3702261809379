import { Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import { Device } from '../../store/types';
import BattlegroundGridTitle from './BattlegroundGridTitle';
import DeviceSelection from './DeviceSelection';
import GridTitle from './GridTitle';
import SubjectLinesComparison from './SubjectLinesComparison';
import SubjectLinesSuggestions from './SubjectLinesSuggestions';

const useStyles = makeStyles({
  root: {
    paddingTop: '2rem'
  }
});

const SubjectLinesSelection = () => {
  const classes = useStyles();
  const [device, setDevice] = useState<Device>('gmail');

  return (
    <Grid container classes={classes} spacing={8}>
      <Grid item xs={6}>
        <GridTitleContainer>
          <GridTitle
            title={<FormattedMessage id="subject_lines.campaign_config.suggestions" />}
            tooltip={
              <FormattedMessage
                id="subject_lines.campaign_config.suggestions.tooltip"
                values={{ br: <br /> }}
              />
            }
          />
          <DeviceSelection device={device} setDevice={setDevice} />
        </GridTitleContainer>
        <SubjectLinesSuggestions selectedDevice={device} />
      </Grid>
      <Grid item xs={6}>
        <BattlegroundGridTitle
          title="subject_lines.campaign_config.subject_lines_comparison.selection"
          subtitle="subject_lines.campaign_config.subject_lines_comparison.add_subject_lines"
          tooltip="subject_lines.campaign_config.subject_lines_comparison.tooltip"
        />
        <SubjectLinesComparison />
      </Grid>
    </Grid>
  );
};

const GridTitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
`;

export default SubjectLinesSelection;
