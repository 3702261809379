import { GenerateTextConfig } from 'features/textGenerator/store/types';

type GenerateTextConfigWithText = Omit<GenerateTextConfig, 'text'> & {
  text: string;
};

type GenerateTextConfigWithKeywords = Omit<GenerateTextConfig, 'keywords'> & {
  keywords: string;
};

type GenerateTextConfigWithBrand = Omit<GenerateTextConfig, 'brand'> & {
  brand: string;
};

type GenerateTextConfigWithTextLength = Omit<GenerateTextConfig, 'length'> & {
  length: string;
};

type ValidGenerateTextConfig =
  | GenerateTextConfigWithText
  | GenerateTextConfigWithKeywords
  | GenerateTextConfigWithBrand
  | GenerateTextConfigWithTextLength;

const isValidGenerateTextConfig = (
  generateTextConfig: GenerateTextConfig | null | undefined
): generateTextConfig is ValidGenerateTextConfig => {
  if (generateTextConfig === null || generateTextConfig === undefined) {
    return false;
  }

  if (
    generateTextConfig.text === null &&
    generateTextConfig.keywords === null &&
    generateTextConfig.brand === null
  ) {
    return false;
  }

  return true;
};

export default isValidGenerateTextConfig;
