import { CustomCategories } from 'features/textGenerator/outputTypeModal/CategoriesList';
import { useGetOutputTypeOptionsByLanguageAndCountry } from 'features/textGenerator/utils/useGetPromptsOthers';
import { ApiOutputTypesSuggestionsIds } from 'services/api/wordEmbedding/types';

import { OutputTypeOption } from './OutputTypesList';

export function useOptions(params: {
  selectedCategory: string;
  query: string;
  modelLanguage: string;
  modelCountry: string;
  outputTypeSuggestionsIds: ApiOutputTypesSuggestionsIds;
}): OutputTypeOption[] {
  const { query, selectedCategory, modelLanguage, modelCountry, outputTypeSuggestionsIds } = params;

  const outputTypeOptions = useGetOutputTypeOptionsByLanguageAndCountry(
    modelLanguage,
    modelCountry
  );

  return convertOptions().filter(option => categoryPredicate(option) && queryPredicate(option));

  function convertOptions() {
    return outputTypeOptions.map(option => ({
      id: option.outputType.id,
      label: option.outputType.label,
      description: option.description,
      categoryLabel: option.category.label,
      categoryId: option.category.id,
      youTubeVideoId: option.outputType.youTubeVideoId
    }));
  }

  function queryPredicate(option: OutputTypeOption): boolean {
    return option.label.toLowerCase().includes(query.toLowerCase());
  }

  function categoryPredicate(option: OutputTypeOption): boolean {
    if (selectedCategory === CustomCategories.ALL_CATEGORY) {
      return true;
    }

    if (selectedCategory === CustomCategories.FAVORITES_CATEGORY) {
      return outputTypeSuggestionsIds.favorite.includes(option.id);
    }

    if (selectedCategory === CustomCategories.MOST_POPULAR_CATEGORY) {
      return outputTypeSuggestionsIds.mostPopular.includes(option.id);
    }

    if (selectedCategory === CustomCategories.MOST_RECENT_CATEGORY) {
      return outputTypeSuggestionsIds.mostRecent.includes(option.id);
    }

    return option.categoryId === selectedCategory;
  }
}
