import { makeCurrentUserQueryKeyPrefix } from 'features/currentUser/currentUserQueries';
import { BaseSuccessDto } from 'services/backofficeIntegration/http/BaseSuccessDto';
import { backofficeClient } from 'services/backofficeIntegration/http/client/createBackofficeClient';
import { queryEndpoint } from 'services/backofficeIntegration/http/endpointDefinition';
import { queryClient } from 'utils/ReactQueryClient';

type ActiveCouponDto = {
  amount_off: null;
  created: number;
  currency: null;
  duration: 'forever';
  duration_in_months: number | null;
  livemode: boolean;
  max_redemptions: null;
  metadata: [];
  name: string;
  percent_off: number;
  redeem_by: null;
  times_redeemed: number;
  valid: boolean;
};

type SuccessDto = BaseSuccessDto<ActiveCouponDto | null>;

export const httpGetActiveCoupon = queryEndpoint({
  makeQueryKey: () => {
    return [...makeCurrentUserQueryKeyPrefix(), 'active-coupon'] as const;
  },
  callEndpoint: () =>
    backofficeClient.get<SuccessDto>('/customer/coupon').then(response => {
      if (response.status === 204) {
        return null;
      }

      return response.data.data;
    })
});

export const invalidateActiveCouponQuery = () => {
  return queryClient.invalidateQueries(httpGetActiveCoupon.makeQueryKey());
};
