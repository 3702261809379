import Button from '@mui/material/Button';
import rewriteIcon from 'assets/rewrite.svg';
import FlexContainer from 'components/FlexContainer';
import { isAiWriterSupportedLanguageAndCountryCode } from 'features/aiWriter/aiWriterSupportedLanguageAndCountryCodes';
import { getCurrentModelLanguageAndCountry } from 'features/aiWriter/store/selectors';
import { getStoredUserTonality } from 'features/aiWriter/tonality/getStoredUserTonality';
import { RewriteTonalitySelect } from 'features/aiWriter/tonality/RewriteTonalitySelect';
import { SystemTonality } from 'features/aiWriter/tonality/SystemTonality';
import { tonalityMaxLength } from 'features/aiWriter/utils/tonalityMaxLength';
import { Form, Formik } from 'formik';
import { ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import { useAppSelector } from 'store/hooks';

const systemTonalityField = 'systemTonality';
const userTonalityField = 'userTonality';

export type RewriteFormValues = {
  [systemTonalityField]: SystemTonality[];
  [userTonalityField]: string[];
};

type Props = {
  onSubmit: (values: RewriteFormValues) => void;
};

const RewriteSetupStep = ({ onSubmit }: Props): ReactElement => {
  const { currentModelLanguage, currentModelCountry } = useAppSelector(
    getCurrentModelLanguageAndCountry
  );
  const languageAndCountryCode = `${currentModelLanguage}_${currentModelCountry}`;

  const tonalitySupportedInLanguage =
    isAiWriterSupportedLanguageAndCountryCode(languageAndCountryCode);

  const initialValues: RewriteFormValues = {
    [systemTonalityField]: [],
    [userTonalityField]: getStoredUserTonality()
  };

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit}>
      {({ values: { systemTonality, userTonality }, setFieldValue }) => (
        <Form>
          <FlexContainer gap="small">
            <Button
              startIcon={<img src={rewriteIcon} alt="rewrite" />}
              variant="contained"
              type="submit"
              fullWidth
              color="primary"
              size="small"
            >
              <FormattedMessage id="common.rewrite" />
            </Button>
            {tonalitySupportedInLanguage && (
              <RewriteTonalitySelect
                systemTonality={systemTonality}
                userTonality={userTonality}
                maxLength={tonalityMaxLength}
                languageAndCountryCode={languageAndCountryCode}
                onChange={(newSystemTonalities, newUserTonalities) => {
                  setFieldValue(systemTonalityField, newSystemTonalities);
                  setFieldValue(userTonalityField, newUserTonalities);
                }}
                size="small"
              />
            )}
          </FlexContainer>
        </Form>
      )}
    </Formik>
  );
};

export default RewriteSetupStep;
