import Toast from 'components/toasts/Toast';
import { getErrorCodeFromAxiosError } from 'features/teamInvitation/getErrorCodeFromAxiosError';
import { BaseSuccessDto } from 'services/backofficeIntegration/http/BaseSuccessDto';
import { backofficeClient } from 'services/backofficeIntegration/http/client/createBackofficeClient';
import { LimitationsDto } from 'services/backofficeIntegration/http/dtos/LimitationsDto';
import { mutationEndpoint } from 'services/backofficeIntegration/http/endpointDefinition';

export type PlagiarismResultDto = {
  url: string;
  title: string;
  match_snippet: string;
  n_words_matched: number;
  match_percentage: number;
};

type CreatePlagiarismCheckDto = {
  matches: PlagiarismResultDto[];
  n_words_total: number;
  limitations: LimitationsDto;
};

type SuccessDto = BaseSuccessDto<CreatePlagiarismCheckDto>;

export const httpCreatePlagiarismCheck = mutationEndpoint({
  callEndpoint: ({ text }: { text: string }) =>
    backofficeClient
      .post<SuccessDto>('/plagiarism-check', {
        text
      })
      .then(response => {
        return response.data.data;
      })
      .catch(error => {
        const errorCode = getErrorCodeFromAxiosError(error);
        if (errorCode === 'ERROR_PLAGIARISM_CHECK') {
          Toast.error(`plagiarism.error.${errorCode}`);
        }
      })
});
