import { PlateElement, PlateElementProps } from '@udecode/plate-common';
import { cn } from 'features/plate/lib/utils';
import { ElementRef, forwardRef } from 'react';

export interface PlateTableRowElementProps extends PlateElementProps {
  hideBorder?: boolean;
}

const TableRowElement = forwardRef<ElementRef<typeof PlateElement>, PlateTableRowElementProps>(
  ({ hideBorder, children, ...props }, ref) => {
    return (
      <PlateElement
        asChild
        ref={ref}
        className={cn('h-full', hideBorder && 'border-none')}
        {...props}
      >
        <tr>{children}</tr>
      </PlateElement>
    );
  }
);
TableRowElement.displayName = 'TableRowElement';

export { TableRowElement };
