import { create } from 'zustand';

type MessageStreamingIndicatorState = {
  isMessageBeingStreamed: boolean;
};

type MessageStreamingIndicatorStateActions = {
  setIsMessageBeingStreamed: (isMessageBeingStreamed: boolean) => void;
};

const initialState: MessageStreamingIndicatorState = {
  isMessageBeingStreamed: false
};

export const useMessageStreamingIndicatorStore = create<
  MessageStreamingIndicatorState & MessageStreamingIndicatorStateActions
>(set => ({
  ...initialState,
  setIsMessageBeingStreamed: (isMessageBeingStreamed: boolean) => set({ isMessageBeingStreamed })
}));
