import { AiWriterProject } from 'features/aiWriter/store/types';
import { aiWriterProjectToApi } from 'features/aiWriter/store/utils/mappers/aiWriterProjectMappers';
import { BaseSuccessDto } from 'services/backofficeIntegration/http/BaseSuccessDto';
import { backofficeClient } from 'services/backofficeIntegration/http/client/createBackofficeClient';
import { mutationEndpoint } from 'services/backofficeIntegration/http/endpointDefinition';

type SuccessDto = BaseSuccessDto<never>;

export const httpUpdateProject = mutationEndpoint({
  callEndpoint: (id: string, project: AiWriterProject) =>
    backofficeClient
      .put<SuccessDto>(`/projects/ai-writer/${id}`, aiWriterProjectToApi(project))
      .then(response => response.data)
});
