import Cookies from 'js-cookie';

const trackingCookieName = 'adcell_tracking_bid';

// Defined by Adcell for our use case, must be keep in sync with Adcell.
const trackingCookieLifetimeDays = 60;

const adcellClient = {
  saveBid: (bid: string) => {
    Cookies.set(trackingCookieName, bid, {
      expires: trackingCookieLifetimeDays
    });
  },
  getSavedBid: () => Cookies.get(trackingCookieName)
};

export default adcellClient;
