import { Autocomplete, TextField } from '@mui/material';
import { searchEnginesOptions } from 'features/aiWriter/AiWriterSidebar/steps/seoAnalysis/SeoAnalysisMainSubStep/searchEnginesOptions';
import { useField } from 'formik';
import React, { useCallback } from 'react';
import useTr from 'utils/hooks/useTr';

type SearchEngineOption = {
  value: string;
  label: string;
};

export const serachEngineOptions: SearchEngineOption[] = searchEnginesOptions.map(searchEngine => ({
  value: searchEngine,
  label: searchEngine
}));

type Props = {
  name: string;
  disabled?: boolean;
};

const SearchEngineAutocomplete = ({ name, disabled }: Props) => {
  const tr = useTr();

  const [field, meta, helper] = useField<string>(name);

  const handleChange = useCallback(
    (_: React.SyntheticEvent<Element, Event>, value: SearchEngineOption) => {
      if (value) {
        helper.setValue(value.value);
        helper.setTouched(true);
      }
    },
    [helper]
  );

  const value = serachEngineOptions.find(option => option.value === field.value);

  return (
    <Autocomplete
      id="model"
      disabled={disabled}
      fullWidth
      disableClearable
      options={serachEngineOptions}
      autoHighlight
      getOptionLabel={option => option.label}
      isOptionEqualToValue={(option, value) => option.value === value.value}
      value={value}
      onChange={handleChange}
      renderInput={params => (
        <TextField
          {...params}
          name={name}
          placeholder={tr('aiWriter.inspirations.seoAnalysis.country_autocomplete.placeholder')}
          variant="outlined"
          fullWidth
          error={meta.touched && !!meta.error}
          helperText={meta.touched && meta.error}
        />
      )}
    />
  );
};

export default SearchEngineAutocomplete;
