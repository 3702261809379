/* eslint-disable @typescript-eslint/no-explicit-any */
import { Dimension, ManualDimension } from 'services/api/dimension/types';
import {
  APIDimension,
  APIDimensionScore,
  APIMapperVariable,
  ScoreTextsGoalConfig
} from 'services/api/wordEmbedding/types';
import { WordAttribute, WordAttributeWithOption } from 'types/ReduxStateTypes';
import { WordTag } from 'types/WordTag';

type LightDimension = Pick<Dimension, 'id' | 'words' | 'label'>;

export function getDimensionWords(
  dimensions: LightDimension[],
  manualDimensions: ManualDimension[] = [],
  appendWords = true,
  simplifyLabel = false
): APIDimension[] {
  const getSimpleDimensionLabel = (label: string) => `dim_${label.toLowerCase()}`;
  const getSimpleManualDimensionLabel = (words: WordTag[]) =>
    `assoc_${words.map(({ word }) => word).join('__')}`;

  const processedDimensions: APIDimension[] = dimensions
    .map<APIDimension>(({ id, label, words }) => ({
      id,
      words: appendWords && words ? words : [],
      name: simplifyLabel ? getSimpleDimensionLabel(label) : label,
      type: 'dimension'
    }))
    .concat(
      manualDimensions.map(({ id, words, label }) => ({
        id,
        words: words.map(({ word }) => word),
        name: simplifyLabel ? getSimpleManualDimensionLabel(words) : label,
        type: 'manual'
      }))
    );

  return appendWords
    ? processedDimensions.filter(dimension => dimension.words?.length)
    : processedDimensions;
}

export function adjustDimensionIds(dimensionsScores: APIDimensionScore[]): Record<string, number> {
  return dimensionsScores.reduce<Record<string, number>>((scores, current) => {
    const key = current.type === 'manual' ? `${current.id}_manual` : `${current.id}`;
    scores[key] = current.score;

    return scores;
  }, {});
}

export function removeArrayItemFromObject<
  S extends { [key in F]: Array<{ id: number | string }> },
  F extends keyof S
>(state: S, field: F, valueToCompare: number | string): S {
  return {
    ...state,
    [field]: state[field].filter(i => i.id !== valueToCompare)
  };
}

export function sortInOrderSimple<T>(source: any[], reverse?: boolean) {
  if (reverse) {
    return (a: T, b: T) => source.indexOf(b) - source.indexOf(a);
  }
  return (a: T, b: T) => source.indexOf(a) - source.indexOf(b);
}

export function sortInOrder<T extends object, K extends keyof T>(
  source: any[],
  field: K,
  reverse?: boolean
) {
  if (reverse) {
    return (a: T, b: T) => source.indexOf(b[field]) - source.indexOf(a[field]);
  }
  return (a: T, b: T) => source.indexOf(a[field]) - source.indexOf(b[field]);
}

export const prepareMappers = (
  wordAttributes: WordAttribute[],
  modelMapper: string
): APIMapperVariable[] =>
  wordAttributes
    .filter(attr => !['model_rank_score', 'seed_score'].includes(attr))
    .map(variable => ({
      id: variable,
      name: variable === 'readability' ? 'readability' : modelMapper,
      variable
    }))
    .filter(mapper => mapper.name.length > 0);

export const prepareGoalConfig = (
  wordAttributes: WordAttributeWithOption[],
  mappers: APIMapperVariable[],
  dimensions: APIDimension[]
): ScoreTextsGoalConfig => {
  return {
    model_rank_score: wordAttributes.map(attr => attr.value).includes('model_rank_score') ? 1 : 0,
    mappers: mappers.map(({ id, variable }) => {
      const matchingAttribute = wordAttributes.find(attr => attr.value === variable);

      return { id, weight: matchingAttribute?.option?.oppositeValue ? -1 : 1 };
    }),
    dimensions: dimensions.map(({ id }) => ({ id, weight: 1 })),
    normalization: 'none'
  };
};
