import React from 'react';
import { FormattedMessage } from 'react-intl';

import Button from '../../../base/Button';
import Tooltip from '../../../tooltips/Tooltip';
import styles from './WordButton.module.scss';

type Props = {
  tooltipMessage: string;
  icon: string;
  disabled?: boolean;
  children?: React.ReactNode;

  onClick: () => void;
};

const WordButton = ({ tooltipMessage, disabled, onClick, icon, children }: Props) => (
  <Tooltip content={<FormattedMessage id={tooltipMessage} />}>
    <Button
      color="secondary"
      size="sm"
      className={styles.wordButton}
      disabled={disabled}
      onClick={onClick}
      icon={icon}
    >
      {children}
    </Button>
  </Tooltip>
);

export default WordButton;
