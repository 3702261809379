import { CloseModal } from 'components/modals/types';
import Toast from 'components/toasts/Toast';
import { TemplateCreateOrEdit } from 'features/aiWriter/commandTemplates/TemplateCreateOrEdit';
import { TemplateCustomization } from 'features/aiWriter/commandTemplates/TemplateCustomization';
import { TemplatesCategoryList } from 'features/aiWriter/commandTemplates/TemplatesCategoryList';
import { TemplatesList } from 'features/aiWriter/commandTemplates/TemplatesList';
import { useDeleteTemplateMutation } from 'features/aiWriter/commandTemplates/useDeleteTemplate';
import {
  MaxHeightOverflowContainer,
  ModalWithDividedHeaderLayout,
  TwoColumnModalBody
} from 'features/theme-2024/ModalWithDividedHeaderLayout';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  invalidateCommandTemplatesQuery,
  SpecialCategories,
  TemplateDto
} from 'services/backofficeIntegration/http/endpoints/textGeneration/commandTemplates/httpGetCommandTemplates';

type ModalProps = CloseModal & CustomModalProps;

export type TemplateModalView = 'list' | 'customization' | 'create' | 'edit';

export type CustomModalProps = {
  preselectedModelId?: string;
  preselectedView?: TemplateModalView;
  templateToEdit?: TemplateDto;
  closeOnBackClick?: boolean;

  onTemplateSelect?: (template: TemplateDto) => void;
};

export function TemplatesModal(props: ModalProps) {
  const {
    preselectedModelId,
    preselectedView = 'list',
    closeOnBackClick,
    templateToEdit,
    closeModal,
    onTemplateSelect
  } = props;
  const [view, setView] = useState<'list' | 'customization' | 'create' | 'edit'>(preselectedView);
  const [template, setTemplate] = useState(templateToEdit);

  const [selectedCategory, setSelectedCategory] = useState<SpecialCategories | number>(
    SpecialCategories.ALL_CATEGORY
  );

  const deleteTemplate = useDeleteTemplateMutation();

  function handleTemplateChoice(template: TemplateDto) {
    setTemplate(template);
    setView('customization');
  }

  function handleBackClick() {
    if (closeOnBackClick) {
      closeModal();
      return;
    }

    setView('list');
  }

  function handleCreateNew() {
    setView('create');
  }

  function handleTemplateSelect(template: TemplateDto) {
    onTemplateSelect?.(template);
    closeModal();
  }

  function handleEditTemplate(template: TemplateDto) {
    setTemplate(template);
    setView('edit');
  }

  function handleDeleteTemplate(template: TemplateDto) {
    deleteTemplate.mutate(
      { commandTemplateId: template.id },
      {
        onSuccess: () => {
          invalidateCommandTemplatesQuery();
        },
        onError: () => {
          Toast.apiError();
        }
      }
    );
  }

  const plainTemplate = {
    id: template?.id,
    title: template?.title,
    description: template?.description,
    body: template?.template,
    category: template?.category_id,
    isSharable: false,
    sharingPermission: template?.sharing_permission,
    language: template?.language,
    country: template?.country
  };

  return (
    <ModalWithDividedHeaderLayout
      title={<FormattedMessage id="aiWriter.inspirations.template.modal.title" />}
      height={810}
      closeModal={closeModal}
      slots={{
        bodyContainer: ['list', 'create', 'edit'].includes(view) ? TwoColumnModalBody : undefined
      }}
    >
      {view === 'list' && (
        <>
          <TemplatesCategoryList
            currentCategory={selectedCategory}
            onCategorySelect={setSelectedCategory}
          />

          <MaxHeightOverflowContainer>
            <TemplatesList
              preselectedModelId={preselectedModelId}
              selectedCategory={selectedCategory}
              onPickToEdit={handleTemplateChoice}
              onEditClicked={handleEditTemplate}
              onDeleteClicked={handleDeleteTemplate}
              onCreateNew={handleCreateNew}
            />
          </MaxHeightOverflowContainer>
        </>
      )}

      {view === 'customization' && template && (
        <TemplateCustomization
          template={template}
          onTemplateSelect={handleTemplateSelect}
          onBackClick={handleBackClick}
        />
      )}

      {view === 'create' && (
        <TemplateCreateOrEdit
          preselectedModelId={preselectedModelId}
          onBackClick={handleBackClick}
        />
      )}

      {view === 'edit' && (
        <TemplateCreateOrEdit template={plainTemplate} onBackClick={handleBackClick} />
      )}
    </ModalWithDividedHeaderLayout>
  );
}
