import { makeCurrentUserQueryKeyPrefix } from 'features/currentUser/currentUserQueries';
import { BaseSuccessDto } from 'services/backofficeIntegration/http/BaseSuccessDto';
import { backofficeClient } from 'services/backofficeIntegration/http/client/createBackofficeClient';
import {
  CursorPaginationDto,
  CursorPaginationParams
} from 'services/backofficeIntegration/http/cursorPaginationEndpoint';
import { ConversationMessageDto } from 'services/backofficeIntegration/http/dtos/ConversationMessageDto';
import { queryEndpoint } from 'services/backofficeIntegration/http/endpointDefinition';

type MessageDto = ConversationMessageDto;

export type PaginatedMessagesDto = CursorPaginationDto<MessageDto>;

type SuccessDto = BaseSuccessDto<PaginatedMessagesDto>;

export const httpGetConversationMessages = queryEndpoint({
  makeQueryKey: (props: { conversationId: string }) =>
    [
      makeCurrentUserQueryKeyPrefix(),
      'conversations',
      props.conversationId,
      'messages',
      'list'
    ] as const,
  callEndpoint: (props: { conversationId: string; page?: CursorPaginationParams }) => {
    const { conversationId, page } = props;
    return backofficeClient
      .get<SuccessDto>(`/conversations/${conversationId}/messages`, { params: page })
      .then(response => response.data.data);
  }
});
