import { BaseSuccessDto } from 'services/backofficeIntegration/http/BaseSuccessDto';
import { backofficeClient } from 'services/backofficeIntegration/http/client/createBackofficeClient';
import { TeamMemberDto } from 'services/backofficeIntegration/http/dtos/TeamMemberDto';
import { mutationEndpoint } from 'services/backofficeIntegration/http/endpointDefinition';

type SuccessDto = BaseSuccessDto<TeamMemberDto>;

export type ResendInvitationParams = {
  teamId: number;
  email: string;
};

export const httpResendInvitation = mutationEndpoint({
  callEndpoint: ({ teamId, email }: ResendInvitationParams) =>
    backofficeClient
      .post<SuccessDto>(`/teams/${teamId}/invites/resend`, {
        email,
      })
      .then(response => response.data)
});
