import { useInfiniteQuery } from '@tanstack/react-query';
import { httpGetCommandTemplates } from 'services/backofficeIntegration/http/endpoints/textGeneration/commandTemplates/httpGetCommandTemplates';
import { GetCommandTemplatesParams } from 'services/backofficeIntegration/http/endpoints/textGeneration/commandTemplates/httpGetCommandTemplates';
import {
  getNextPageParam,
  PaginatedListParams
} from 'services/backofficeIntegration/http/paginatedListEndpoint';
import { infiniteQueryKey } from 'utils/reactQuery/infiniteQueryKey';

export function useTemplatesQuery(params: GetCommandTemplatesParams) {
  return useInfiniteQuery({
    queryKey: infiniteQueryKey(httpGetCommandTemplates.makeQueryKey(params)),
    queryFn: (context: { pageParam?: PaginatedListParams }) => {
      const { pageParam = {} } = context;
      return httpGetCommandTemplates.callEndpoint({ ...params, ...pageParam });
    },
    getNextPageParam: getNextPageParam
  });
}
