import { useMutation } from '@tanstack/react-query';
import { invalidateCurrentPersonalityQuery } from 'features/aiWriter/AiWriterSidebar/steps/chat/useCurrentPersonalityQuery';
import { invalidatePersonalitiesQuery } from 'features/aiWriter/AiWriterSidebar/steps/chat/usePersonalitiesQuery';
import {
  httpUpdatePersonality,
  UpdatePersonalityParams
} from 'services/backofficeIntegration/http/endpoints/personalities/httpUpdatePersonality';

export const useUpdateChatPersonality = () =>
  useMutation({
    mutationFn: (params: UpdatePersonalityParams) => httpUpdatePersonality.callEndpoint(params),
    onSuccess: () => {
      invalidateCurrentPersonalityQuery();
      invalidatePersonalitiesQuery();
    }
  });
