import { Typography } from '@mui/material';
import FlexContainer from 'components/FlexContainer';
import ModalTitle from 'components/modals/ModalTitle';
import { CloseModal } from 'components/modals/types';
import FormattedMessage from 'features/i18n/FormattedMessage';
import styled from 'styled-components';

type Props = CloseModal;

const unsplashLicesePageLink = 'https://unsplash.com/license';

export const UnsplashLicenceModal = ({ closeModal }: Props) => {
  return (
    <Root>
      <FlexContainer gap="medium">
        <ModalTitle title="common.information" onCancel={closeModal} />
        <Typography variant="h6">
          <FormattedMessage id="common.license" />
        </Typography>
        <FormattedMessage id="unsplash_modal.body" />
        <div>
          <FormattedMessage
            id="unsplash_modal.footer"
            values={{
              a: (value: string) => (
                <Link href={unsplashLicesePageLink} rel="noreferrer" target="_blank">
                  {value}
                </Link>
              )
            }}
          />
        </div>
      </FlexContainer>
    </Root>
  );
};

const Root = styled(FlexContainer)`
  padding: ${({ theme }) => `${theme.spacings.medium} ${theme.spacings.xmedium}`};
`;

const Link = styled.a`
  color: ${({ theme }) => theme.colors.primary};
`;
