import { LinearProgress, Typography } from '@mui/material';
import FlexContainer from 'components/FlexContainer';
import { WordStatisticsColorVariant } from 'features/aiWriter/AiWriterSidebar/steps/seoAnalysis/common/WordStatisticsPill';
import {
  getWordStatisticsBackgroundColor,
  getWordStatisticsColor,
  getWordStatisticsProgressColor
} from 'features/aiWriter/AiWriterSidebar/steps/seoAnalysis/wordStatisticsColor';
import { getAverageWordCount, getSeoWordsAmount } from 'features/aiWriter/store/selectors';
import { FormattedMessage } from 'react-intl';
import { useAppSelector } from 'store/hooks';
import styled from 'styled-components';

const MainSubStepWordAmountSection = () => {
  const seoCharactersAmount = useAppSelector(getSeoWordsAmount);
  const averageWordCount = useAppSelector(getAverageWordCount);

  const countProgress = () => (seoCharactersAmount / averageWordCount) * 100;

  const countProgressIndicator = () => {
    const progress = countProgress();

    if (progress > 100) {
      return 100;
    }

    return progress;
  };

  const countProgressColor = (): WordStatisticsColorVariant => {
    const progress = countProgress();

    if (progress < 90) {
      return 'secondary';
    }

    if (progress >= 90 && progress <= 110) {
      return 'green';
    }

    return 'primary';
  };

  const countProgressLabel = () => {
    const progress = countProgress();

    if (progress < 90) {
      return 'aiWriter.inspirations.seoAnalysis.word_amount.low';
    }

    if (progress >= 90 && progress <= 110) {
      return 'aiWriter.inspirations.seoAnalysis.word_amount.good';
    }

    return 'aiWriter.inspirations.seoAnalysis.word_amount.high';
  };

  const progressColor = countProgressColor();

  return (
    <FlexContainer gap="small">
      <FlexContainer direction="row" justifyContent="space-between">
        <Typography variant="h6">
          <FormattedMessage id="aiWriter.inspirations.seoAnalysis.word_amount" />
          <Indicator $color={progressColor}>
            <FormattedMessage id={countProgressLabel()} />
          </Indicator>
        </Typography>
        <WordsIndicator variant="subtitle1">
          {seoCharactersAmount}/{averageWordCount}
        </WordsIndicator>
      </FlexContainer>
      <Progress variant="determinate" value={countProgressIndicator()} $color={progressColor} />
    </FlexContainer>
  );
};

const Indicator = styled.span<{ $color: WordStatisticsColorVariant }>`
  ${({ $color }) => getWordStatisticsColor($color)};
`;

const WordsIndicator = styled(Typography)`
  && {
    color: ${({ theme }) => theme.colors.blackDisabled};
  }
`;

const Progress = styled(LinearProgress)<{ $color: WordStatisticsColorVariant }>`
  && {
    ${({ $color }) => getWordStatisticsBackgroundColor($color)};

    > span {
      ${({ $color }) => getWordStatisticsProgressColor($color)};
    }
  }
`;

export default MainSubStepWordAmountSection;
