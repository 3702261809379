import DoneIcon from '@mui/icons-material/Done';
import { Autocomplete, Chip, Popper, TextField } from '@mui/material';
import FlexContainer from 'components/FlexContainer';
import { useGetAllTags } from 'features/aiWriter/AiWriterProjectOverview/tags/useGetAllTags';
import { truncateText } from 'features/aiWriter/AiWriterProjectOverview/TeamMemberFilter';
import { SyntheticEvent, useState } from 'react';
import { AiWriterProjectTagType } from 'services/api/aiWriter/types';
import { GAEvents } from 'services/tracking/GAEvents';
import styled from 'styled-components';
import useTr from 'utils/hooks/useTr';

type Props = {
  value: AiWriterProjectTagType | undefined;
  setValue: (value: AiWriterProjectTagType | undefined) => void;
};

export const AiWriterProjectTagFilter = ({ value, setValue }: Props) => {
  const translate = useTr();
  const [inputValue, setInputValue] = useState('');
  const { data, isLoading } = useGetAllTags({}, true);

  const handleChange = (
    event: SyntheticEvent<Element, Event>,
    newValue: AiWriterProjectTagType | null
  ) => {
    if (newValue) {
      setValue(newValue);
      GAEvents.tagFilterInProjectOverviewClicked();
    }
  };

  const handleClear = () => {
    setValue(undefined);
  };

  if (!data) {
    return null;
  }

  const allTags = data?.pages.map(page => page.data).flat(1);
  
  return (
    <FilterContainer>
      {value ? (
        <StyledChip
          label={value.name}
          icon={<DoneIcon />}
          onDelete={handleClear}
          color="primary"
          sx={{ color: 'white' }}
        />
      ) : (
        <Autocomplete
          sx={{ width: '8rem' }}
          value={value}
          inputValue={inputValue}
          options={allTags}
          onChange={handleChange}
          loading={isLoading}
          getOptionLabel={(option: AiWriterProjectTagType) => option.name}
          onInputChange={(event, newInputValue) => {
            setInputValue(newInputValue);
          }}
          renderInput={params => (
            <TextField
              {...params}
              label={translate('aiWriter.project_overview.new_projects.tags.filter')}
              size="small"
            />
          )}
          PopperComponent={params => (
            <Popper {...params} style={{ width: 240 }} placement="bottom-start" />
          )}
          renderOption={(props, value) => (
            <Option {...props}>
              <Chip label={truncateText(value.name, 18)} />
            </Option>
          )}
        />
      )}
    </FilterContainer>
  );
};

const FilterContainer = styled(FlexContainer).attrs({
  direction: 'row',
  alignItems: 'center'
})`
  height: 3rem;
`;

const StyledChip = styled(Chip)`
  color: ${({ theme }) => theme.colors.white};
  && .MuiChip-deleteIcon {
    color: ${({ theme }) => theme.colors.white};
    &:hover {
      color: ${({ theme }) => theme.colors.whiteMediumEmphasis};
    }
  }
`;

const Option = styled.li`
  display: flex;
  gap: ${({ theme }) => theme.spacings.small};
`;
