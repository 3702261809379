/* eslint-disable react-hooks/exhaustive-deps */
import { useMutation } from '@tanstack/react-query';
import { createProgressToast } from 'components/toasts/createProgressToast';
import Toast from 'components/toasts/Toast';
import useEditor from 'features/aiWriter/AiWriterTextEditor/hooks/useEditor';
import { updateCurrentProjectInBackgroundThunk } from 'features/aiWriter/store/actions/project/thunks/updateCurrentProjectInBackground';
import {
  getActiveTab,
  getCurrentTabId,
  getGenerateTextConfig
} from 'features/aiWriter/store/selectors';
import useAudienceConfig from 'features/audiences/hooks/useAudienceConfig';
import { setWordsLimitReached } from 'features/customer/store/actions';
import { invalidateWordsUsageQueries } from 'features/wordsUsage/invalidateWordsUsageQueries';
import { useEffect, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import { handleGenerateTextErrorsWithHyperlink } from 'services/api/wordEmbedding/errors';
import { invalidateCustomerAllLimitationsQueries } from 'services/backofficeIntegration/http/endpoints/customer/httpGetAllLimitations';
import {
  httpGenerateTextStream,
  StreamError
} from 'services/backofficeIntegration/http/endpoints/textGeneration/httpGenerateTextStream';
import { ResponseError } from 'services/backofficeIntegration/http/processEventStream';
import { useAppDispatch, useAppSelector } from 'store/hooks';

type GeneratorConfig = {
  outputType: string;
  toasts: {
    loading: JSX.Element;
    success: JSX.Element;
    error: JSX.Element;
  };

  handleGeneration?: (text: string) => void;
};
type GeneratorProps = { text: string; keywords?: string };

/**
 * This is a copy paste of `useGenerateTextInDocument.tsx`
 * with some modifications to current problem.
 * On some point it should be refactored to be more generic.
 */
export function useHandleStreamedGenerateText(config: GeneratorConfig) {
  const { toasts, outputType, handleGeneration } = config;
  const editor = useEditor();
  const dispatch = useAppDispatch();
  const currentTabId = useAppSelector(getCurrentTabId);
  const tab = useAppSelector(getActiveTab);
  const { audienceId } = useAppSelector(getGenerateTextConfig);
  const { audienceModelId } = useAudienceConfig({ audienceId });

  const abortControllerRef = useRef<AbortController>();

  function printText(text: string) {
    if (handleGeneration) {
      handleGeneration(text);
      return;
    }

    editor?.insertText(text);
  }

  /**
   * If we won't abort the streaming it will keep writing to a non existing editor.
   * When I last checked it did not caused any errors but still it would be a smell.
   * We also have to close the progress toast and aborting the stream takes care of
   * that.
   */
  useEffect(() => {
    return () => {
      const controller = abortControllerRef.current;
      if (controller) {
        controller.abort();
        abortControllerRef.current = undefined;
      }
    };
  }, [currentTabId]);

  return useMutation({
    mutationFn: async (props: GeneratorProps) => {
      const { text, keywords } = props;

      const abortController = new AbortController();
      const abortSignal = abortController.signal;
      let activeTextItemId: string | undefined = undefined;
      abortControllerRef.current = abortController;

      const progressToast = createProgressToast(toasts.loading);

      const informationIds = tab.generateTextConfig.informationList?.map(info => info.id);

      try {
        await httpGenerateTextStream.callStreamEndpoint({
          request: {
            text,
            keywords,
            audience_model_id: audienceModelId,
            output_type: outputType,
            n_text_items: 1,
            n_times: 1,
            personality_id: tab.generateTextConfig.personalityId ?? undefined,
            information_ids: informationIds
          },
          handlers: {
            onStart: e => {
              if (activeTextItemId) return;

              activeTextItemId = e.text_item_id;
            },
            onUpdate: e => {
              if (activeTextItemId !== e.text_item_id) return;

              printText(e.text_delta);
            }
          },
          abort: abortSignal
        });

        if (abortSignal.aborted) {
          progressToast.close();
        } else {
          invalidateWordsUsageQueries();
          invalidateCustomerAllLimitationsQueries();

          progressToast.success({
            render: toasts.success
          });
        }
      } catch (e: unknown) {
        if (e instanceof StreamError) {
          await editor?.undo();
          dispatch(updateCurrentProjectInBackgroundThunk());
          progressToast.error({
            render: (
              <FormattedMessage id="aiWriter.textGeneration.error.ERROR_CONVERSATION_STREAMING_FAILED" />
            )
          });
          return;
        }
        progressToast.error({
          render: toasts.error
        });

        if (e instanceof ResponseError) {
          const data: { message?: string } = await e.response.json().catch(() => ({}));
          if (data.message === 'ERROR_ALL_WORDS_USED') {
            dispatch(setWordsLimitReached(true));
            return;
          }
          if (data.message === 'ERROR_INVALID_STREAMING_TYPE') {
            Toast.error(`aiWriter.textGeneration.error.${data.message}`);
            return;
          }
          if (data.message === 'ERROR_RESPONSE_NOT_SUCCESS') {
            Toast.error(`aiWriter.textGeneration.error.${data.message}`);
            return;
          }
          if (data.message === 'ERROR_STREAMING') {
            Toast.error(`aiWriter.textGeneration.error.${data.message}`);
            return;
          }
          if (data.message) {
            Toast.backendError(...handleGenerateTextErrorsWithHyperlink(data.message));
          }
        }
      } finally {
        abortControllerRef.current = undefined;
      }
    }
  });
}
