import { ApiFavorite } from 'services/api/favorites/types';
import { keysToCamel, keysToSnake } from 'utils/utils';

import { Favorite } from '../../types';

export const aiWriterFavoriteToApi = (favorite: Favorite): ApiFavorite =>
  keysToSnake(favorite) as ApiFavorite;

export const aiWriterFavoriteFromApi = (favorite: ApiFavorite): Favorite =>
  keysToCamel(favorite) as Favorite;
