import Toast from 'components/toasts/Toast';
import { AppThunk } from 'store/store';
import { getCurrentTab } from 'store/utils/tabUtils';

export const checkIfTextLimitIsAchievedThunk = (): AppThunk<void> => async (_, getState) => {
  const state = getState();
  const currentTab = getCurrentTab(state.tester);
  if (!currentTab) {
    return;
  }

  const { texts } = currentTab;

  if (texts.length >= 10) {
    Toast.info('testing.info.text_limit_achieved');
  }
};
