import { InfoOutlined } from '@mui/icons-material';
import { Tooltip, Typography } from '@mui/material';
import React from 'react';
import styled from 'styled-components';

type Props = {
  title: NonNullable<React.ReactNode>;
  tooltip: NonNullable<React.ReactNode>;
};

const GridTitle = ({ title, tooltip }: Props) => {
  return (
    <TitleWithTooltipContainer>
      <Typography variant="h6">{title}</Typography>
      <Tooltip title={tooltip}>
        <InfoOutlined color="action" />
      </Tooltip>
    </TitleWithTooltipContainer>
  );
};

const TitleWithTooltipContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

export default GridTitle;
