import { TableCell, TableContainer, Typography } from '@mui/material';
import styled from 'styled-components';

export const StyledTableContainer = styled(TableContainer)``;

export const StyledCell = styled(TableCell)`
  && {
    border: 1px solid ${({ theme }) => theme.colors.commonBlackSelected};
  }

  text-align: center;
  width: 20%;

  ${({ theme }) => ({
    ...theme.typography.body1
  })}
`;

export const FirstCell = styled(StyledCell)`
  width: 40%;
  text-align: left;
`;

export const SectionTitle = styled(Typography).attrs({ variant: 'h6' })`
  margin-left: ${({ theme }) => theme.spacings.three};
`;
