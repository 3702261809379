import { AutoAwesomeRounded, CheckRounded, CloseRounded, EditRounded } from '@mui/icons-material';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import {
  Avatar,
  Button,
  Fab,
  IconButton,
  LinearProgress,
  Skeleton,
  TextareaAutosize,
  Tooltip,
  Typography
} from '@mui/material';
import { ReactComponent as ConversationAiIcon } from 'assets/icons/icon-chat-flash.svg';
import CopyToClipboardButton from 'components/CopyToClipboardButton';
import FlexContainer from 'components/FlexContainer';
import { useAiWriterExpandedSidebarStore } from 'features/aiWriter/AiWriterSidebar/sidebar/aiWriterExpandedSidebarStore';
import {
  chatScrollTargetProps,
  scrollToTarget,
  useScrollToTarget
} from 'features/aiWriter/AiWriterSidebar/steps/chat/chatScroll';
import {
  changeMessageDraft,
  getCurrentSuggestions,
  useIsWaitingForResponse,
  useMessageDraft,
  useStoredActiveConversationId,
  useStreamedResponse,
  useSyncedProjectId
} from 'features/aiWriter/AiWriterSidebar/steps/chat/chatStore';
import { welcomeMessage } from 'features/aiWriter/AiWriterSidebar/steps/chat/chatWelcomeMessage';
import { Dots } from 'features/aiWriter/AiWriterSidebar/steps/chat/Dots';
import { FlashAction } from 'features/aiWriter/AiWriterSidebar/steps/chat/FlashAction';
import { FlashActionsButtons } from 'features/aiWriter/AiWriterSidebar/steps/chat/FlashActionsButtons';
import { Message, MessageSource } from 'features/aiWriter/AiWriterSidebar/steps/chat/Message';
import { useMessageStreamingIndicatorStore } from 'features/aiWriter/AiWriterSidebar/steps/chat/messageStreamingIndicatorStore';
import { useCreateSuggestions } from 'features/aiWriter/AiWriterSidebar/steps/chat/useCreateSuggestions';
import { useMessages } from 'features/aiWriter/AiWriterSidebar/steps/chat/useMessages';
import { useSendMessage } from 'features/aiWriter/AiWriterSidebar/steps/chat/useSendMessage';
import { AiWriterSupportedLanguageAndCountryCode } from 'features/aiWriter/aiWriterSupportedLanguageAndCountryCodes';
import {
  BasicMarkdownPreview,
  MarkdownTile,
  NeutralButtonSurface,
  NeutralSurface,
  NoMessageSurface
} from 'features/aiWriter/markdown/MarkdownTile';
import { useCopyMarkdownToEditor } from 'features/aiWriter/markdown/useCopyMarkdownToEditor';
import { getCurrentModelLanguageAndCountry } from 'features/aiWriter/store/selectors';
import { getPreferredAudience } from 'features/aiWriter/utils/getPreferredAudience';
import { getUserAudiences } from 'features/audiences/store/selectors';
import { getUserImage, getUserName } from 'features/customer/store/selectors';
import FormattedMessage from 'features/i18n/FormattedMessage';
import { useCreateEmptyPage } from 'features/plate/components/plate-ui/useCreateEmptyPage';
import {
  useShouldShowWordsLimitReachedModal,
  useShowWordsLimitReachedModal
} from 'features/pricing/hook/useYouRunOutOfWordsModal';
import { trackingWrapper } from 'features/tracking/wrapper/TrackingWrapper';
import { usePostHog } from 'posthog-js/react';
import { ChangeEvent, KeyboardEvent, useEffect, useRef, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { ConversationMessageDto } from 'services/backofficeIntegration/http/dtos/ConversationMessageDto';
import { Suggestion } from 'services/backofficeIntegration/http/endpoints/aiWriter/httpCreateSuggestions';
import { GAEvents } from 'services/tracking/GAEvents';
import gtmIds from 'services/tracking/GTMIds';
import { withGtmInteraction } from 'services/tracking/withGtmInteraction';
import { useAppSelector } from 'store/hooks';
import styled, { css } from 'styled-components';
import useTr from 'utils/hooks/useTr';
import { useStableCallback } from 'utils/react/useStableCallback';
import { withTestId } from 'utils/utils';

const NoMessagesBox = styled.div`
  min-height: 100%;
  position: relative;
`;

const SmartSuggestion = ({ suggestion }: { suggestion: Suggestion }) => {
  const isSidebarExpanded = useAiWriterExpandedSidebarStore(state => state.isSidebarExpanded);
  const maxSuggestionTextLength = isSidebarExpanded ? 95 : 55;
  const [isTooltipShown, setIsTooltipShown] = useState(false);

  const { shouldShowRunOutOfWordsModal } = useShouldShowWordsLimitReachedModal();
  const onlyShowModal = useShowWordsLimitReachedModal();

  const sendMessage = useSendMessage();
  const messageDraft = useMessageDraft();

  const sendCurrentDraftMessage = useStableCallback(() => {
    if (shouldShowRunOutOfWordsModal()) {
      onlyShowModal();
      return;
    }
    sendMessage.mutate(messageDraft);
  });

  const handleShowingTooltip = () => {
    if (suggestion.text.length > maxSuggestionTextLength) return setIsTooltipShown(true);
    else return setIsTooltipShown(false);
  };

  const trimText = (text: string) => {
    return text.length > maxSuggestionTextLength
      ? `${text.slice(0, maxSuggestionTextLength)}...`
      : text;
  };

  const handleClickSuggestion = async () => {
    trackingWrapper.track('smartSuggestionChosen', {
      text: suggestion.text,
      generation_id: suggestion.generation_id
    });
    GAEvents.smartSuggestionChosen({
      text: suggestion.text,
      generation_id: suggestion.generation_id
    });
    await changeMessageDraft({
      text: suggestion.text
    });
    sendCurrentDraftMessage();
  };

  return (
    <SuggestionsMessageRoot onMouseEnter={handleShowingTooltip} onClick={handleClickSuggestion}>
      <Tooltip
        title={suggestion.text}
        open={isTooltipShown}
        onOpen={handleShowingTooltip}
        onClose={() => setIsTooltipShown(false)}
        placement="top-start"
      >
        <FlexContainer direction="row" gap="small" alignItems="center">
          <AutoAwesomeRounded fontSize="small" />
          <SuggestionsText>{trimText(suggestion.text)}</SuggestionsText>
        </FlexContainer>
      </Tooltip>
    </SuggestionsMessageRoot>
  );
};

interface SmartSuggestionsBoxProps {
  messages: ConversationMessageDto[];
  isFetching: boolean;
}

const SmartSuggestionsBox = ({
  messages,
  isFetching
}: SmartSuggestionsBoxProps): JSX.Element | null => {
  const projectId = useSyncedProjectId();
  const conversationId = useStoredActiveConversationId();
  const suggestionsCurrent = getCurrentSuggestions();
  const suggestionsQueryResult = useCreateSuggestions();
  const [propsMessages] = useState<ConversationMessageDto[]>(messages);
  const { currentModelCountry, currentModelLanguage } = useAppSelector(
    getCurrentModelLanguageAndCountry
  );
  const audiences = useAppSelector(getUserAudiences);
  const audience = getPreferredAudience({
    audiences,
    locale: { language: currentModelLanguage, country: currentModelCountry }
  });

  const shouldShowSuggestions =
    messages.length > 0 &&
    messages.length < 7 &&
    !isFetching &&
    messages[0].source === MessageSource.assistant;

  const shouldRefetchSuggestions = !suggestionsCurrent || suggestionsCurrent.length === 0;
  const chatMessagesUpdated = propsMessages.length !== messages.length;

  useEffect(() => {
    if (
      messages &&
      conversationId &&
      messages.length > 0 &&
      shouldShowSuggestions &&
      (shouldRefetchSuggestions || chatMessagesUpdated)
    ) {
      const keywords = messages.find(m => m.source === MessageSource.user)?.text || '';
      const keywords2 = messages.find(m => m.source === MessageSource.assistant)?.text || '';
      suggestionsQueryResult.mutate({
        keywords: keywords,
        keywords2: keywords2,
        audience_model_id: audience?.model_id ?? '',
        conversationId: conversationId,
        projectId,
        personality: '',
        n_text_items: 2,
        n_times: 1,
        output_type: 'smart_suggestions'
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [audience?.model_id, conversationId, projectId, shouldShowSuggestions]);

  const renderSuggestions = () => {
    if (
      suggestionsCurrent &&
      suggestionsCurrent.length > 0 &&
      shouldShowSuggestions &&
      // no messages updated in the chat so we can display stored suggestions
      propsMessages.length === messages.length
    ) {
      return (
        <SuggestionsBox>
          <Typography variant="caption" fontWeight={700}>
            <FormattedMessage id="aiWriter.smart_suggestions.subheading" />
          </Typography>
          <SuggestionsRoot>
            {suggestionsCurrent.map(suggestion => (
              <SmartSuggestion key={suggestion.text_item_id} suggestion={suggestion} />
            ))}
          </SuggestionsRoot>
        </SuggestionsBox>
      );
    } else if (suggestionsQueryResult.isLoading) {
      return (
        <FlexContainer alignItems="flex-start">
          <StyledSkeleton variant="text" animation="wave" width={220} />
          <StyledSkeleton variant="text" animation="wave" width={260} />
        </FlexContainer>
      );
    } else if (
      propsMessages.length !== messages.length &&
      suggestionsQueryResult.data &&
      !suggestionsQueryResult.isLoading &&
      shouldShowSuggestions
    ) {
      return (
        <SuggestionsBox>
          <Typography variant="caption" fontWeight={700} {...withTestId('smart-suggestion-title')}>
            <FormattedMessage id="aiWriter.smart_suggestions.subheading" />
          </Typography>
          <SuggestionsRoot {...withTestId('smart-suggestion-list')}>
            {suggestionsQueryResult.data.suggestions.map(suggestion => (
              <SmartSuggestion key={suggestion.text_item_id} suggestion={suggestion} />
            ))}
          </SuggestionsRoot>
        </SuggestionsBox>
      );
    } else return null;
  };
  return renderSuggestions();
};

function NoMessages() {
  const customerName = useAppSelector(state => state.customer.username);
  const { currentModelCountry: country, currentModelLanguage: language } = useAppSelector(
    getCurrentModelLanguageAndCountry
  );
  const languageCountryCode = `${language}_${country}` as AiWriterSupportedLanguageAndCountryCode;

  const messageArr = welcomeMessage[languageCountryCode];
  const message = `${messageArr?.title} ${customerName}, ${messageArr?.text}`;

  return (
    <NoMessagesBox>
      <MarkdownTile
        surface={<NoMessageSurface />}
        markdown={<NoMessageMarkdownPreview>{message}</NoMessageMarkdownPreview>}
      />
      <ConversationIconBox $isUserIcon={false}>
        <StyledConversationAiIcon />
      </ConversationIconBox>
    </NoMessagesBox>
  );
}

const StyledSkeleton = styled(Skeleton)`
  border-radius: ${({ theme }) => theme.borderRadius.one};
  height: 30px;
`;

const messageBase = css`
  position: relative;
  padding: ${({ theme }) =>
    `${theme.spacings.three} ${theme.spacings.three} ${theme.spacings.three} ${theme.spacings.four}`};
  border-radius: ${({ theme }) => theme.borderRadius.one};

  color: ${({ theme }) => theme.colors.textPrimary};
`;

const AssistantMessageRoot = styled.div`
  position: relative;
`;

const StyledMarkdownPreview = styled(BasicMarkdownPreview)`
  padding: ${({ theme }) => theme.spacings.medium};
  padding-left: ${({ theme }) => theme.spacings.four};
  padding-bottom: 0;
`;

const NoMessageMarkdownPreview = styled(BasicMarkdownPreview)`
  padding: ${({ theme }) => theme.spacings.medium};
  padding-left: ${({ theme }) => theme.spacings.xmedium};
`;

const DotsBox = styled(NeutralSurface)`
  padding: ${({ theme }) => theme.spacings.medium};
  padding-left: ${({ theme }) => theme.spacings.xmedium};
`;

const ConversationIconBox = styled.div<{ $isUserIcon: boolean }>`
  box-sizing: border-box;
  position: absolute;
  top: 4px;
  left: -15px;

  width: 32px;
  height: 32px;

  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: ${({ theme }) =>
    theme.mode === 'dark' ? 'none' : `4px solid ${theme.colors.commonWhiteMain}`};
  overflow: hidden;

  background-color: ${({ theme, $isUserIcon }) =>
    $isUserIcon ? theme.colors.primaryColorMain : theme.colors.secondaryColorMain};
  color: ${({ theme }) => theme.colors.textPrimary};
`;

const StyledConversationAiIcon = styled(ConversationAiIcon)`
  width: 16px;
  height: 16px;
`;

const PendingMessageContainer = styled.div`
  width: 15%;
`;

function PendingMessageTile() {
  return (
    <PendingMessageContainer>
      <AssistantMessageBox>
        <DotsBox>
          <Dots />
        </DotsBox>
      </AssistantMessageBox>
    </PendingMessageContainer>
  );
}

function StreamedResponseTile() {
  const message = useStreamedResponse();
  const isWaitingForResponse = useIsWaitingForResponse();
  if (isWaitingForResponse) return <PendingMessageTile />;
  if (message)
    return (
      <AssistantMessageTile message={message} streamedMessageId={message.id}></AssistantMessageTile>
    );
  return null;
}

function AssistantMessageTile(props: { message: Message; streamedMessageId?: string | null }) {
  const { message } = props;
  const copyToEditor = useCopyMarkdownToEditor();
  const translate = useTr();
  const activeConversationId = useStoredActiveConversationId();

  const rootRef = useRef<HTMLDivElement>(null);
  const postHog = usePostHog();
  const { createEmptyPage } = useCreateEmptyPage();

  useEffect(() => {
    // this condition is needed to ensure that we only follow the currently streamed message
    if (props.streamedMessageId && message.id === props.streamedMessageId) {
      rootRef.current?.scrollIntoView({ block: 'end', inline: 'nearest' });
    }
  }, [message, props.streamedMessageId]);

  function handleClick() {
    trackingWrapper.track('aiWriterChatMessageClick', {
      message_uuid: message.id,
      conversation_uuid: activeConversationId,
      clicked: 1
    });

    postHog?.capture('Chat message click');

    createEmptyPage();
    copyToEditor(message.text);
  }

  return (
    <AssistantMessageBox>
      <MarkdownTile
        surface={
          <NeutralButtonSurface
            onClick={handleClick}
            aria-label={translate('aiWriter.copy_to_editor')}
            {...withGtmInteraction(gtmIds.aiWriter.chat.scrollChat)}
          />
        }
        markdown={<StyledMarkdownPreview>{message.text}</StyledMarkdownPreview>}
        rightActions={
          <>
            <FlashAction key="flash" message={message} />

            <CopyToClipboardButton
              key="copy"
              text={message.text}
              gtmId={gtmIds.aiWriter.chat.copyToClipboard}
            />
          </>
        }
      >
        <ScrolledDiv
          ref={rootRef}
          {...chatScrollTargetProps(message.id)}
          $isMargin={props.streamedMessageId ? message.id === props.streamedMessageId : false}
        ></ScrolledDiv>
      </MarkdownTile>
    </AssistantMessageBox>
  );
}

const ScrolledDiv = styled.div<{ $isMargin: boolean }>`
  // this margin is needed to fit the streamed message in between assistant icon and action buttons
  // and it needs to be removed once the streaming is finished not to make the message tile look ugly
  margin: ${({ $isMargin }) => ($isMargin ? '160px 0 0 0' : 0)};
`;

function AssistantMessageBox(props: { children: JSX.Element }) {
  return (
    <AssistantMessageRoot>
      {props.children}
      <ConversationIconBox $isUserIcon={false}>
        <StyledConversationAiIcon />
      </ConversationIconBox>
    </AssistantMessageRoot>
  );
}

const StyledAvatar = styled(Avatar)`
  background-color: transparent;
  font-size: 12px;
  width: 100%;
  height: 100%;
`;

const UserMessageRoot = styled.div`
  ${messageBase};
  white-space: pre-wrap;
  word-break: break-word;
  background-color: ${({ theme }) =>
    theme.mode === 'dark' ? 'none' : theme.colors.commonWhiteMain};

  cursor: pointer;
  .editable {
    visibility: hidden;
  }
  &:hover {
    box-shadow: ${({ theme }) => theme.shadow.textSuggestionHover};

    .editable {
      visibility: visible;
    }
  }
`;

const UserMessageInput = styled(TextareaAutosize)`
  padding: 0;
  white-space: pre-wrap;
  word-break: break-word;
  background-color: ${({ theme }) => theme.colors.primary__50};
  border: none;
  resize: vertical;
  box-sizing: border-box;
  width: 100%;
  font-family: ${({ theme }) => theme.fontFamily};
  font-size: ${({ theme }) => theme.fontSizes.body2};
  font-weight: 400;
  line-height: 1.5;
  min-height: 1.4375em;

  &:hover {
    border: none;
  }

  &:focus {
    border: none;
  }

  // firefox
  &:focus-visible {
    outline: 0;
  }
`;

const BottomIcons = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const SuggestionsText = styled(Typography)`
  font-size: ${({ theme }) => theme.typography.button.small.fontSize};
  font-weight: ${({ theme }) => theme.typography.button.small.fontWeight};

  color: ${({ theme }) => theme.colors.textContrast};
`;

const SuggestionsMessageRoot = styled.div`
  padding: 4px 10px;
  border-radius: ${({ theme }) => theme.borderRadius.one};
  margin-top: ${({ theme }) => theme.spacings.two};

  color: ${({ theme }) => theme.colors.textContrast};

  background-color: ${({ theme }) => theme.colors.secondaryColorMain};
  cursor: pointer;
  width: fit-content;

  &:hover {
    background-color: ${({ theme }) => theme.colors.secondaryColorDark};
  }
`;

const SuggestionsRoot = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: sticky;
  bottom: 0;
`;

const SuggestionsBox = styled(FlexContainer)`
  margin-top: ${({ theme }) => theme.spacings.two};
`;

function UserMessageTile({ message }: { message: Message }) {
  const name = useAppSelector(getUserName);
  const image = useAppSelector(getUserImage);
  const [isEditMode, setIsEditMode] = useState(false);
  const sendMessage = useSendMessage();
  const messageDraft = useMessageDraft();
  const [updatedText, setUpdatedText] = useState(message.text);
  // used to cancel a dangling timeout for onBlur
  // which is used to allow a user to click on save button outside of the input without
  // onBlur event being triggered first
  const timeoutRef = useRef<number | null>(null);

  const sendCurrentDraftMessage = useStableCallback(() => {
    sendMessage.mutate(messageDraft);
  });

  const handleEditClick = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    setUpdatedText(message.text);
    setIsEditMode(true);
    GAEvents.editMessageInChatClicked();
  };

  const handleSaveMessage = async () => {
    setIsEditMode(false);
    await changeMessageDraft({
      text: updatedText
    });
    sendCurrentDraftMessage();
    GAEvents.editedMessageInChatSent();
  };

  const handleTextChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setUpdatedText(event.target.value);
  };

  const handleCancelClick = () => {
    setIsEditMode(false);
  };

  const handleOnBlur = async () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    timeoutRef.current = window.setTimeout(() => {
      handleCancelClick();
      // timeout used to allow a user to click on save button outside of the input
      // without onBlur event being triggered first
    }, 300);
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLTextAreaElement>) => {
    if (!e.shiftKey && e.key === 'Enter') {
      e.preventDefault();
      handleSaveMessage();
    }
    if (!e.shiftKey && e.key === 'Escape') {
      e.preventDefault();
      handleCancelClick();
    }
  };

  const icons = isEditMode ? (
    <>
      <Tooltip title={<FormattedMessage id="common.cancel" />}>
        <IconButton size="small" onClick={handleCancelClick}>
          <CloseRounded fontSize="small" />
        </IconButton>
      </Tooltip>
      <Tooltip title={<FormattedMessage id="aiWriter.chat.user_message.save_message.tooltip" />}>
        <IconButton size="small" onClick={handleSaveMessage}>
          <CheckRounded fontSize="small" />
        </IconButton>
      </Tooltip>
    </>
  ) : (
    <Tooltip title={<FormattedMessage id="aiWriter.chat.user_message.edit_message.tooltip" />}>
      <IconButton size="small" onClick={handleEditClick} className="editable">
        <EditRounded fontSize="small" />
      </IconButton>
    </Tooltip>
  );

  return (
    <UserMessageRoot>
      <ConversationIconBox $isUserIcon={true}>
        <StyledAvatar src={image ?? undefined} variant="rounded">
          {name[0]}
        </StyledAvatar>
      </ConversationIconBox>
      {isEditMode ? (
        <UserMessageInput
          maxRows={15}
          value={updatedText}
          onChange={handleTextChange}
          ref={(instance: HTMLTextAreaElement) => instance && instance.focus()}
          onFocus={e =>
            e.currentTarget.setSelectionRange(
              e.currentTarget.value.length,
              e.currentTarget.value.length
            )
          }
          onBlur={handleOnBlur}
          onKeyDown={handleKeyDown}
        />
      ) : (
        <Typography variant="body2">{message.text}</Typography>
      )}
      <BottomIcons>{icons}</BottomIcons>
    </UserMessageRoot>
  );
}

const MessagesBox = styled.div`
  position: relative;

  display: grid;
  gap: ${({ theme }) => theme.spacings.small};
  // This space allows us to place the message icons outside of the message boxes
  margin-left: ${({ theme }) => theme.spacings.small};
`;

function WithMessages(props: {
  messages: Message[];
  isFetching: boolean;
  hasMore: boolean;
  loadMore: () => void;
}) {
  const { messages, isFetching, hasMore, loadMore } = props;

  const rootRef = useRef<HTMLDivElement>(null);

  const { ref: loadButtonRef } = useInView({
    onChange: (inView: boolean) => {
      if (inView) {
        loadMore();
      }
    }
  });

  const [newestMessage] = messages;
  useScrollToTarget({ parentRef: rootRef, targetId: newestMessage.id, messages });

  const [showScrollButton, setShowScrollButton] = useState(false);

  const isMessageBeingStreamed = useMessageStreamingIndicatorStore(
    state => state.isMessageBeingStreamed
  );

  const { ref: lastVisibleElementRef } = useInView({
    onChange: (inView: boolean) => setShowScrollButton(!inView),
    skip: isMessageBeingStreamed || isFetching
  });

  function renderFloatingScrollButton() {
    const parent = rootRef.current;

    if (!parent || !showScrollButton) return null;

    return (
      <AbsoluteBox>
        <FloatingScrollButton
          {...withGtmInteraction(gtmIds.aiWriter.chat.scrollChat)}
          onClick={() => {
            scrollToTarget({ parent: parent, targetId: newestMessage.id });
          }}
        >
          <KeyboardDoubleArrowDownIcon />
        </FloatingScrollButton>
      </AbsoluteBox>
    );
  }

  function renderMessageTile(message: Message) {
    switch (message.source) {
      case MessageSource.user:
        return <UserMessageTile key={message.id} message={message} />;
      case MessageSource.assistant:
        return <AssistantMessageTile key={message.id} message={message} />;
    }
  }

  function renderLoadMore() {
    if (hasMore && !isFetching) {
      return (
        <Button
          ref={loadButtonRef}
          onClick={() => {
            loadMore();
          }}
        >
          <FormattedMessage id="aiWriter.inspirations.chat.load_more_button.label" />
        </Button>
      );
    }
  }

  function renderFetchingIndicator() {
    if (isFetching) {
      return <LinearProgress />;
    }
  }

  // Only show flash actions when we don't show the smart suggestions anymore
  const shouldShowFlashActions = messages.length > 7;

  return (
    <>
      <MessagesBox ref={rootRef}>
        {renderLoadMore()}
        {renderFloatingScrollButton()}
        {messages
          .slice()
          .reverse()
          .map(m => renderMessageTile(m))}
        <StreamedResponseTile />
        <div ref={lastVisibleElementRef}></div>
        {shouldShowFlashActions && <FlashActionsButtons message={newestMessage} />}
        <SmartSuggestionsBox messages={messages} isFetching={isFetching} />
      </MessagesBox>
      {renderFetchingIndicator()}
    </>
  );
}

export function MessagesArea() {
  const { messages, isLoading, isFetching, hasMore, loadMore } = useMessages();

  if (messages.length < 1) {
    if (isLoading) {
      return <LinearProgress />;
    }

    return <NoMessages />;
  }

  return (
    <WithMessages
      messages={messages}
      isFetching={isFetching}
      hasMore={hasMore}
      loadMore={loadMore}
    />
  );
}

const AbsoluteBox = styled.div`
  position: absolute;

  left: 50%;
  margin: auto;
  z-index: 9999;
`;

const FloatingScrollButton = styled(Fab).attrs({
  size: 'small'
})`
  position: fixed;
  bottom: 200px;

  background-color: ${({ theme }) =>
    theme.mode === 'dark' ? theme.colors.commonBlackMain : theme.colors.commonWhiteMain};
  color: ${({ theme }) => theme.colors.primary};
`;
