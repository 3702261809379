import ChecklistIcon from '@mui/icons-material/Checklist';
import { Tooltip } from '@mui/material';
import { focusEditor, toggleNodeType } from '@udecode/plate-common';
import { ELEMENT_TODO_LI } from '@udecode/plate-list';
import { ELEMENT_PARAGRAPH } from '@udecode/plate-paragraph';
import {
  EditorFormatButton,
  EditorFormatButtonProps,
  EditorIconFormatButton
} from 'features/aiWriter/AiWriterTextEditor/EditorIconFormatButton';
import useEditor from 'features/aiWriter/AiWriterTextEditor/hooks/useEditor';
import { trackEditorFormatAction } from 'features/aiWriter/AiWriterTextEditor/trackEditorFormatAction';
import { getIconColor } from 'features/aiWriter/AiWriterTextEditor/utils/getIconColor';
import { isBlockActive } from 'features/aiWriter/AiWriterTextEditor/utils/isBlockActive';
import FormattedMessage from 'features/i18n/FormattedMessage';

type TodoListButtonProps = {
  styleVariant?: EditorFormatButtonProps;
  onClose?: () => void;
};

export function TodoListButton({ onClose, styleVariant }: TodoListButtonProps) {
  const editor = useEditor();
  const isActive = isBlockActive(editor, ELEMENT_TODO_LI);

  const handleClick = () => {
    trackEditorFormatAction('checklist');

    toggleNodeType(editor, { activeType: ELEMENT_TODO_LI, inactiveType: ELEMENT_PARAGRAPH });

    focusEditor(editor);

    onClose && onClose();
  };

  if (styleVariant === 'icon') {
    return (
      <EditorIconFormatButton onClick={handleClick} $isActive={isActive}>
        <Tooltip
          arrow
          title={<FormattedMessage id="aiWriter.toolbar.action_item" />}
          placement="bottom"
        >
          <ChecklistIcon color={getIconColor(isActive)} fontSize="small" />
        </Tooltip>
      </EditorIconFormatButton>
    );
  }

  return (
    <EditorFormatButton onClick={handleClick} $isActive={isActive}>
      <ChecklistIcon color={getIconColor(isActive)} fontSize="small" />
      <FormattedMessage id="aiWriter.toolbar.action_item" />
    </EditorFormatButton>
  );
}
