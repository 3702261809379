import BorderColorIcon from '@mui/icons-material/BorderColor';
import { Tooltip } from '@mui/material';
import { focusEditor, isMarkActive, toggleMark } from '@udecode/plate-common';
import { MARK_HIGHLIGHT } from '@udecode/plate-highlight';
import {
  EditorFormatButton,
  EditorFormatButtonProps,
  EditorIconFormatButton
} from 'features/aiWriter/AiWriterTextEditor/EditorIconFormatButton';
import useEditor from 'features/aiWriter/AiWriterTextEditor/hooks/useEditor';
import { trackEditorFormatAction } from 'features/aiWriter/AiWriterTextEditor/trackEditorFormatAction';
import { getIconColor } from 'features/aiWriter/AiWriterTextEditor/utils/getIconColor';
import FormattedMessage from 'features/i18n/FormattedMessage';

type HighlightButtonProps = {
  styleVariant?: EditorFormatButtonProps;
  onClose?: () => void;
};

export function HighlightButton(props: HighlightButtonProps) {
  const editor = useEditor();

  const isActive = isMarkActive(editor, MARK_HIGHLIGHT);

  const handleClick = () => {
    trackEditorFormatAction('highlight');

    toggleMark(editor, {
      key: MARK_HIGHLIGHT,
      clear: MARK_HIGHLIGHT
    });
    focusEditor(editor);

    props.onClose && props.onClose();
  };

  if (props.styleVariant === 'icon') {
    return (
      <EditorIconFormatButton $isActive={isActive} onClick={handleClick}>
        <Tooltip arrow title={<FormattedMessage id="aiWriter.toolbar.highlight" />}>
          <BorderColorIcon color={getIconColor(isActive)} fontSize="small" />
        </Tooltip>
      </EditorIconFormatButton>
    );
  }

  return (
    <EditorFormatButton onClick={handleClick} variant="text" $isActive={isActive}>
      <BorderColorIcon color={getIconColor(isActive)} fontSize="small" />
      <FormattedMessage id="aiWriter.toolbar.highlight" />
    </EditorFormatButton>
  );
}
