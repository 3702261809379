import { StepContainer } from 'features/modular-workflow/builder/steps/StepContainer';
import { StepDeleteIcon } from 'features/modular-workflow/builder/steps/StepDeleteIcon';
import { BuilderModularWorkflowStep } from 'features/modular-workflow/builder/types-builder';
import { useGetModulareWorkflowStepStructureByReference } from 'features/modular-workflow/useModulareWorkflowStructureStore';
import { ComponentProps } from 'react';

type Props = {
  step: ComponentProps<typeof StepContainer>['step'];
  onSave: (changedStep: BuilderModularWorkflowStep) => void;
  onDelete: ComponentProps<typeof StepDeleteIcon>['onDelete'];
  onDirtyChange?: ComponentProps<typeof StepContainer>['onDirtyChange'];
  stepErrorList?: ComponentProps<typeof StepContainer>['stepErrorList'];
};

export const GenericDeletableStep = ({
  step,
  onSave,
  onDelete,
  onDirtyChange,
  stepErrorList
}: Props) => {
  const getStepStructure = useGetModulareWorkflowStepStructureByReference();
  const structure = getStepStructure(step.reference);
  if (!structure) {
    return null;
  }

  return (
    <StepContainer
      step={step}
      onSave={onSave}
      onDirtyChange={onDirtyChange}
      headerActions={<StepDeleteIcon onDelete={onDelete} />}
      stepErrorList={stepErrorList}
    />
  );
};
