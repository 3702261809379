import { EmojiEmotionsRounded } from '@mui/icons-material';
import { Popover, Tooltip } from '@mui/material';
import { Emoji, useEmojiDropdownMenuState } from '@udecode/plate-emoji';
import {
  EditorFormatButton,
  EditorFormatButtonProps,
  EditorIconFormatButton
} from 'features/aiWriter/AiWriterTextEditor/EditorIconFormatButton';
import { trackEditorFormatAction } from 'features/aiWriter/AiWriterTextEditor/trackEditorFormatAction';
import { getIconColor } from 'features/aiWriter/AiWriterTextEditor/utils/getIconColor';
import { topBarDropdownAlignmentConfig } from 'features/aiWriter/AiWriterTextEditor/utils/topBarDropdownAlignmentConfig';
import FormattedMessage from 'features/i18n/FormattedMessage';
import {
  emojiCategoryIcons,
  emojiSearchIcons
} from 'features/plate/components/plate-ui/emoji-icons';
import { EmojiPicker } from 'features/plate/components/plate-ui/emoji-picker';
import { useCreateEmptyPage } from 'features/plate/components/plate-ui/useCreateEmptyPage';
import { useRef } from 'react';
import useTr from 'utils/hooks/useTr';

type Props = {
  styleVariant?: EditorFormatButtonProps;
  onClose?: () => void;
};

export const EditorEmojiPickerButton = ({ styleVariant, onClose }: Props) => {
  const anchorRef = useRef<HTMLButtonElement>(null);
  const { emojiPickerState, setIsOpen, isOpen } = useEmojiDropdownMenuState();
  const { createEmptyPage } = useCreateEmptyPage();

  const tr = useTr();

  const handleClick = () => {
    setIsOpen(!isOpen);

    onClose?.();
  };

  const handleEmojiSelect = (emoji: Emoji) => {
    trackEditorFormatAction('emoji');

    createEmptyPage();
    emojiPickerState.onSelectEmoji(emoji);
  };

  return (
    <>
      {styleVariant === 'icon' ? (
        <EditorIconFormatButton ref={anchorRef} $isActive={false} onClick={handleClick}>
          <Tooltip
            arrow
            title={<FormattedMessage id="aiWriter.toolbar.emoji" />}
            placement="bottom"
          >
            <EmojiEmotionsRounded color={getIconColor(isOpen)} fontSize="small" />
          </Tooltip>
        </EditorIconFormatButton>
      ) : (
        <EditorFormatButton ref={anchorRef} $isActive={false} onClick={handleClick}>
          <EmojiEmotionsRounded color={getIconColor(isOpen)} fontSize="small" />
          <FormattedMessage id="aiWriter.toolbar.emoji" />
        </EditorFormatButton>
      )}

      <Popover
        open={isOpen}
        anchorEl={anchorRef.current}
        onClose={() => setIsOpen(false)}
        container={document.getElementById('portal')}
        style={{ zIndex: 100 }}
        anchorOrigin={topBarDropdownAlignmentConfig.leftAlignment.anchorOrigin}
        transformOrigin={topBarDropdownAlignmentConfig.leftAlignment.transformOrigin}
      >
        <EmojiPicker
          {...emojiPickerState}
          onSelectEmoji={handleEmojiSelect}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          icons={{
            categories: emojiCategoryIcons,
            search: emojiSearchIcons
          }}
          i18n={{
            ...emojiPickerState.i18n,
            search: tr('aiWriter.editor.emoji_picker.search'),
            clear: tr('aiWriter.editor.emoji_picker.clear'),
            searchNoResultsTitle: tr('aiWriter.editor.emoji_picker.searchNoResultsTitle'),
            searchNoResultsSubtitle: tr('aiWriter.editor.emoji_picker.searchNoResultsSubtitle'),
            pick: tr('aiWriter.editor.emoji_picker.pick'),
            searchResult: tr('aiWriter.editor.emoji_picker.searchResult'),
            categories: {
              activity: tr('aiWriter.editor.emoji_picker.categories.activity'),
              custom: tr('aiWriter.editor.emoji_picker.categories.custom'),
              flags: tr('aiWriter.editor.emoji_picker.categories.flags'),
              foods: tr('aiWriter.editor.emoji_picker.categories.foods'),
              frequent: tr('aiWriter.editor.emoji_picker.categories.frequent'),
              nature: tr('aiWriter.editor.emoji_picker.categories.nature'),
              objects: tr('aiWriter.editor.emoji_picker.categories.objects'),
              people: tr('aiWriter.editor.emoji_picker.categories.people'),
              places: tr('aiWriter.editor.emoji_picker.categories.places'),
              symbols: tr('aiWriter.editor.emoji_picker.categories.symbols')
            }
          }}
        />
      </Popover>
    </>
  );
};
