import { MaintenancePage } from 'features/maintenance/MaintenancePage';
import { isOngoingMaintenance } from 'features/maintenance/maintenanceSlice';
import { ReactElement } from 'react';
import { useSelector } from 'react-redux';

export function MaintenanceGuard(props: { children: ReactElement }) {
  const { children } = props;
  const showMaintenancePage = useSelector(isOngoingMaintenance);

  
  if (showMaintenancePage) {
    return <MaintenancePage />;
  }

  return children;
}
