import { AppThunk } from 'store/store';

import { updateCurrentProjectInBackgroundThunk } from '../../project/thunks/updateCurrentProjectInBackground';
import { renameCurrentTab } from '../actions';

export const renameCurrentTabThunk =
  (payload: string): AppThunk<void> =>
  async dispatch => {
    dispatch(renameCurrentTab(payload));
    dispatch(updateCurrentProjectInBackgroundThunk());
  };
