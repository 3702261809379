import { compose, createAction, createReducer } from '@reduxjs/toolkit';
import { TableState } from 'react-table';
import { switchLoadingDefault } from 'store/utils/genericReducers';
import {
  addTabReducer,
  closeTabReducer,
  getCurrentTab,
  setCurrentTabReducer
} from 'store/utils/tabUtils';
import { PickByValueExact } from 'utility-types';
import { getParsedStorageItem } from 'utils/getParsedStorageItem';
import { Palette } from 'utils/palettes';
import { createAsyncAction } from 'utils/reduxUtils';
import { createLookupTable } from 'utils/utils';

import LocalStorageKey from '../../../config/localStorageKey';
import { ConceptFlashTab, ExplorerState, I16s } from './types';

export const rateWords = createAsyncAction<void, ConceptFlashTab>('explorer/rateWords');
export const getInterconnectedness = createAsyncAction<void, I16s>('explorer/getI16s');
export const createTab = createAction<ConceptFlashTab>('explorer/createTab');
export const setCurrentTab = createAction<string>('explorer/setCurrentTab');
export const closeTab = createAction<string>('explorer/closeTab');
export const toggleLayout = createAction('explorer/setLayout');
export const removeWord = createAction<string>('explorer/removeWord');
export const selectRow = createAction<string>('explorer/selectRow');
export const selectMultipleRows = createAction<string[]>('explorer/selectMultipleRows');
export const deselectAllRows = createAction('explorer/deselectAllRows');
export const initializeTab = createAction<string>('explorer/initializeTab');
export const setTabModel = createAction<{
  embeddingModelId: string;
  modelMapperId: string;
  hasValence?: boolean;
}>('explorer/setTabModel');
export const setGraphColoring = createAction<string>('explorer/setGraphColoring');
export const setColorPalette = createAction<Palette>('explorer/setColorPalette');
export const setTableState = createAction<Partial<TableState>>('explorer/setTableState');
export const toggleTabProperty = createAction<
  Exclude<keyof PickByValueExact<ConceptFlashTab, boolean>, undefined>
>('explorer/toggleTabProperty');

const explorerReducer = createReducer<ExplorerState>(
  {
    isLoading: false,
    currentTab: '',
    tabs: {},
    pageLayoutVertical: getParsedStorageItem<boolean>(
      LocalStorageKey.ExplorerVerticalLayout,
      false,
      window.localStorage
    )
  },
  builder =>
    builder
      .addCase(rateWords.request, switchLoadingDefault(true))
      .addCase(rateWords.success, compose(switchLoadingDefault(false), addTabReducer))
      .addCase(rateWords.failure, switchLoadingDefault(false))
      .addCase(getInterconnectedness.request, switchLoadingDefault(true))
      .addCase(getInterconnectedness.success, (state, { payload }) => {
        state.isLoading = false;
        const tab = getCurrentTab(state);
        if (tab) tab.i16s = payload;
      })
      .addCase(getInterconnectedness.failure, switchLoadingDefault(false))
      .addCase(createTab, addTabReducer)
      .addCase(setCurrentTab, setCurrentTabReducer)
      .addCase(closeTab, closeTabReducer)
      .addCase(toggleLayout, state => {
        state.pageLayoutVertical = !state.pageLayoutVertical;
      })
      .addCase(removeWord, (state, { payload }) => {
        const currentTab = getCurrentTab(state);
        if (!currentTab) {
          return;
        }

        const selectedWords = currentTab.selectedRows.reduce<string[]>((acc, rowIndex) => {
          const wordData = currentTab.data[parseFloat(rowIndex)];
          return wordData && wordData.word !== payload ? acc.concat(wordData.word) : acc;
        }, []);

        currentTab.data = currentTab.data.filter(el => el.word !== payload);
        currentTab.lookupTable = createLookupTable(currentTab.data);
        currentTab.selectedRows = selectedWords.map(word => `${currentTab.lookupTable[word]}`);
      })
      .addCase(selectRow, (state, { payload }) => {
        const currentTab = getCurrentTab(state);
        if (!currentTab) {
          return;
        }

        const { selectedRows } = currentTab;

        const idIndex = selectedRows.indexOf(payload);
        if (idIndex !== -1) {
          selectedRows.splice(idIndex, 1);
        } else {
          selectedRows.push(payload);
        }
      })
      .addCase(selectMultipleRows, (state, { payload }) => {
        const currentTab = getCurrentTab(state);
        if (!currentTab) {
          return;
        }

        currentTab.selectedRows = payload;
      })
      .addCase(deselectAllRows, state => {
        const currentTab = getCurrentTab(state);
        if (!currentTab) {
          return;
        }

        currentTab.selectedRows = [];
      })
      .addCase(initializeTab, (state, { payload }) => {
        const currentTab = getCurrentTab(state);
        if (!currentTab) {
          return;
        }

        currentTab.isInitialized = true;
        currentTab.name = payload;
      })
      .addCase(setTabModel, (state, { payload }) => {
        const currentTab = getCurrentTab(state);
        if (!currentTab) {
          return;
        }

        currentTab.embeddingModelId = payload.embeddingModelId;
        currentTab.modelMapperId = payload.modelMapperId;
        if (payload.hasValence) {
          currentTab.graphColoring = 'valence';
        }
      })
      .addCase(setGraphColoring, (state, { payload }) => {
        const currentTab = getCurrentTab(state);
        if (!currentTab) {
          return;
        }

        currentTab.graphColoring = payload;
      })
      .addCase(setColorPalette, (state, { payload }) => {
        const currentTab = getCurrentTab(state);
        if (!currentTab) {
          return;
        }

        currentTab.palette = payload;
      })
      .addCase(setTableState, (state, { payload }) => {
        const currentTab = getCurrentTab(state);
        if (!currentTab) {
          return;
        }

        currentTab.tableState = payload;
      })
      .addCase(toggleTabProperty, (state, { payload }) => {
        const currentTab = getCurrentTab(state);
        if (!currentTab) {
          return;
        }

        currentTab[payload] = !currentTab[payload];
      })
);

export default explorerReducer;
