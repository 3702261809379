import { Button } from '@mui/material';
import { ReactComponent as TextTypesIcon } from 'assets/icons/text-types.svg';
import FlexContainer from 'components/FlexContainer';
import gtmIds from 'services/tracking/GTMIds';
import { withGtmInteraction } from 'services/tracking/withGtmInteraction';
import styled from 'styled-components';
import useTr from 'utils/hooks/useTr';

export type TextTypesButtonProps = {
  isDisabled?: boolean | undefined;
  onSelect: () => void;
};

export const TextTypesButton = (props: TextTypesButtonProps) => {
  const { isDisabled, onSelect } = props;
  const translate = useTr();

  return (
    <TextTypeActionButton
      variant="text"
      onClick={onSelect}
      {...withGtmInteraction(gtmIds.textTypesLibrary.openFromRightSidebar)}
      disabled={isDisabled}
    >
      <FlexContainer direction="row" gap="xsmall" alignItems="center">
        <TextTypesIcon />
        {translate('output_type_modal.show_modal_button')}
      </FlexContainer>
    </TextTypeActionButton>
  );
};

export const TextTypeActionButton = styled(Button)`
  width: fit-content;
  color: ${({ theme }) => theme.colors.componentsIconDefault};
`;
