import { useQuery } from '@tanstack/react-query';
import { getCurrentModelLanguageAndCountry } from 'features/aiWriter/store/selectors';
import { httpGetDefaultPersonality } from 'services/backofficeIntegration/http/endpoints/personalities/httpGetDefaultPersonality';
import { useAppSelector } from 'store/hooks';

type Options = {
  country: string;
  language: string;
};

export const useDefaultPersonalityQuery = (options?: Options) => {
  const { currentModelCountry, currentModelLanguage } = useAppSelector(
    getCurrentModelLanguageAndCountry
  );

  const params = {
    country: options?.country || currentModelCountry,
    language: options?.language || currentModelLanguage
  };

  return useQuery({
    queryKey: httpGetDefaultPersonality.makeQueryKey(params),
    queryFn: () => httpGetDefaultPersonality.callEndpoint(params)
  });
};
