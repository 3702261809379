import cx from 'classnames';
import React, { ReactNode } from 'react';

import Badge from '../base/Badge';
import CountryFlag from '../base/CountryFlag';
import EmptyButton from '../base/EmptyButton';
import Icon from '../base/Icon';

export type DropdownItemBaseComponent = React.ComponentType<{
  className?: string;
  to?: string;
  onClick?: (event: React.MouseEvent) => void;
  children?: ReactNode;
}>;

type Props = {
  className?: string;
  icon?: string;
  value?: React.ReactNode;
  flag?: string;
  disabled?: boolean;
  badge?: React.ReactNode;
  badgeType?: string;
  to?: string;
  children?: React.ReactNode;

  onClick?: (event: React.MouseEvent) => void;
  RootComponent?: DropdownItemBaseComponent;
};

export type DropdownItemProps = Props & { isDivider?: boolean };

const DropdownItem = ({
  className,
  icon,
  value,
  flag,
  disabled,
  badge,
  badgeType,
  to,
  RootComponent,
  children,
  onClick
}: Props) => {
  const classes = cx('dropdown-item', disabled && 'disabled', className);
  const childrenForAll = (
    <>
      {badge && (
        <span className="float-right">
          <Badge color={badgeType}>{badge}</Badge>
        </span>
      )}
      {icon && <Icon name={icon} className="dropdown-icon" />}
      {flag && <CountryFlag language={flag} className="dropdown-icon" />}
      {value}
      {children}
    </>
  );
  return RootComponent ? (
    <RootComponent className={classes} to={to} onClick={onClick}>
      {childrenForAll}
    </RootComponent>
  ) : (
    <EmptyButton className={classes} unsetPadding unsetColor onClick={onClick}>
      {childrenForAll}
    </EmptyButton>
  );
};

export default DropdownItem;
