import { useModal } from 'components/modals';
import { getIsPricingExpired } from 'features/customer/store/selectors';
import { useEffect } from 'react';
import { useAppSelector } from 'store/hooks';

const useShowAccessExpiredModal = () => {
  const { showModal } = useModal();
  const accessExpired = useAppSelector(getIsPricingExpired);

  useEffect(() => {
    if (accessExpired) {
      showModal('ACCESS_EXPIRED', { size: 1100 });
    }
  }, [accessExpired, showModal]);
};

export default useShowAccessExpiredModal;
