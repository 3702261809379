import { makeCurrentUserQueryKeyPrefix } from 'features/currentUser/currentUserQueries';
import { BaseSuccessDto } from 'services/backofficeIntegration/http/BaseSuccessDto';
import { backofficeClient } from 'services/backofficeIntegration/http/client/createBackofficeClient';
import { queryEndpoint } from 'services/backofficeIntegration/http/endpointDefinition';

type CustomerChurnKeyHashDto = {
  hashApiKey: string;
};

type SuccessDto = BaseSuccessDto<CustomerChurnKeyHashDto>;

export const httpGetChurnKeyHash = queryEndpoint({
  makeQueryKey: () => {
    return [...makeCurrentUserQueryKeyPrefix(), 'churn-key-hash'] as const;
  },
  callEndpoint: () => {
    return backofficeClient
      .get<SuccessDto>('/customer/churn-key-hash')
      .then(response => response.data.data.hashApiKey);
  }
});
