import { StepContainer } from 'features/modular-workflow/builder/steps/StepContainer';
import { BuilderModularWorkflowStep } from 'features/modular-workflow/builder/types-builder';
import { useGetModulareWorkflowStepStructureByReference } from 'features/modular-workflow/useModulareWorkflowStructureStore';
import { ComponentProps } from 'react';

type Props = {
  step: ComponentProps<typeof StepContainer>['step'];
  onSave: (changedStep: BuilderModularWorkflowStep) => void;
  onDirtyChange?: ComponentProps<typeof StepContainer>['onDirtyChange'];
  stepErrorList?: ComponentProps<typeof StepContainer>['stepErrorList'];
};

export const GenericStep = ({ step, onSave, onDirtyChange, stepErrorList }: Props) => {
  const getStepStructure = useGetModulareWorkflowStepStructureByReference();
  const structure = getStepStructure(step.reference);
  if (!structure) {
    return null;
  }

  return (
    <StepContainer
      step={step}
      onSave={onSave}
      onDirtyChange={onDirtyChange}
      stepErrorList={stepErrorList}
    />
  );
};
