import { Typography } from '@mui/material';
import FormattedMessage from 'features/i18n/FormattedMessage';
import { IntlMessageKeys } from 'features/i18n/IntlMessageKeys';
import { FormattedPricing } from 'features/pricing/FormattedPricing';
import { isTypeBusinessPlan, PricingPlan } from 'features/pricing/types';
import { ReactElement } from 'react';
import { ACLPricing, SubscriptionPeriod } from 'services/api/customer/types';
import styled from 'styled-components';

const periodTranslationMap: Record<SubscriptionPeriod | 'free', IntlMessageKeys> = {
  free: 'profile.subscription.subscription_free',
  month: 'profile.subscription.subscription_month',
  year: 'profile.subscription.subscription_year'
};

interface Props {
  pricingPlan: ACLPricing;
}

const ActiveSubscriptionCard = ({ pricingPlan }: Props): ReactElement => {
  const periodTranslation = pricingPlan.isFree
    ? periodTranslationMap.free
    : periodTranslationMap[pricingPlan.periodName];

  const isBusinessPlan = isTypeBusinessPlan(pricingPlan);

  return (
    <Container>
      <SubscriptionNameContainer>
        <Typography variant="h6">{pricingPlan.name}</Typography>
        <Typography variant="body2">
          <Cost>
            <FormattedPricing pricing={pricingPlan} />
          </Cost>

          {!pricingPlan.isFree && (
            <>
              / <FormattedMessage id={periodTranslation} />
            </>
          )}
        </Typography>
      </SubscriptionNameContainer>

      <Description variant="body2">
        {isBusinessPlan ? (
          <FormattedMessage id={`profile.plan.${PricingPlan.enterprise}_subline`} />
        ) : (
          <FormattedMessage id={`profile.plan.${pricingPlan.type}_subline`} />
        )}
      </Description>
    </Container>
  );
};

const Container = styled.div`
  padding: ${({ theme }) => theme.spacings.medium};
  margin-bottom: ${({ theme }) => theme.spacings.small};

  background: ${({ theme }) => theme.colors.textTile};
  border-radius: ${({ theme }) => theme.borderRadius.medium};

  width: 100%;
`;

const SubscriptionNameContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 100%;
`;

const Cost = styled.span`
  font-size: ${({ theme }) => theme.fontSizes.body1};
  font-weight: 500;
`;

const Description = styled(Typography)`
  && {
    margin-top: ${({ theme }) => theme.spacings.small};
  }
`;

export default ActiveSubscriptionCard;
