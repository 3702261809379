import { groupBy } from 'lodash';
import matchSorter, { rankings } from 'match-sorter';
import { useState } from 'react';
import BaseSelect, { mergeStyles, Options, Props, StylesConfig } from 'react-select';

import { selectStyles, selectTheme } from './selectStyles';

function groupOptions<T>(options: Options<T>, groupKey: string) {
  return Object.entries(groupBy(options, groupKey)).map(([projectLabel, values]) => ({
    label: projectLabel === 'null' ? 'None' : projectLabel,
    options: values
  }));
}

type SelectProps<T> = Props<T> & {
  options: Options<T>;
  matchingKeys?: string[];
  groupKey?: string;
};

function Select<T>({
  options,
  matchingKeys = ['label'],
  groupKey,
  styles = {},
  ...props
}: SelectProps<T>) {
  const [matchedOptions, setMatchedOptions] = useState(() =>
    groupKey ? groupOptions(options, groupKey) : options
  );

  return (
    <BaseSelect
      styles={mergeStyles(selectStyles as StylesConfig<T>, styles)}
      theme={selectTheme}
      filterOption={() => true} // rely only on match sorter
      onInputChange={inputValue => {
        if (groupKey) {
          setMatchedOptions(
            groupOptions(
              matchSorter(options as T[], inputValue, {
                keys: matchingKeys.concat(groupKey),
                threshold: rankings.STRING_CASE_ACRONYM
              }),
              groupKey
            )
          );
        } else {
          setMatchedOptions(
            matchSorter(options as T[], inputValue, {
              keys: matchingKeys,
              threshold: rankings.STRING_CASE_ACRONYM
            })
          );
        }
      }}
      options={matchedOptions}
      {...props}
    />
  );
}

export default Select;
