import { Link } from '@mui/material';
import Toast from 'components/toasts/Toast';

export const showNotSufficientPermissionsError = () => {
  Toast.backendError('billing_portal.error.ERROR_FORBIDDEN', {
    mail: (msg: string) => (
      <Link href={`mailto:${msg}`} target="_blank" rel="noreferrer">
        {msg}
      </Link>
    )
  });
};
