import { Fab, List, ListItem, ListItemText, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useFormikContext } from 'formik';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { formatToPercentageValue } from 'utils/wordUtils';

import CopyToClipboardButton from '../../../components/CopyToClipboardButton';
import MoneyInput from '../../../components/forms/MoneyInput';
import NumberInput from '../../../components/forms/NumberInput';
import ScoreChip from '../../../components/ScoreIndicators/ScoreChip';
import CenteredContainer from '../../../components/styled/CenteredContainer';
import useTr from '../../../utils/hooks/useTr';
import { CampaignSubjectLine } from '../store/types';

const useStyles = makeStyles({
  root: {
    backgroundColor: '#FAFAFA',
    borderRadius: '4px',
    textAlign: 'left'
  }
});

const SubjectLinesPerformance = () => {
  const { values } = useFormikContext<{ subjectLines: CampaignSubjectLine[] }>();
  const classes = useStyles();
  const tr = useTr();

  return (
    <CenteredContainer>
      <Typography variant="h5">
        <FormattedMessage id="subject_lines.performance.title" />
      </Typography>
      <SubjectLinesContainer>
        <List>
          {values.subjectLines.map(({ subjectLine, predictedOpenRate }, index) => (
            <SubjectLineListItem key={subjectLine}>
              <ScoresContainer>
                <ScoreChip
                  value={predictedOpenRate}
                  label={formatToPercentageValue(predictedOpenRate)}
                >
                  <Typography variant="body2">
                    <FormattedMessage id="subject_lines.socials.linkedin" />
                  </Typography>
                </ScoreChip>
                <NumberInput
                  suffix="%"
                  name={`subjectLines.${index}.openRate`}
                  label={<FormattedMessage id="common.open_rate" />}
                  placeholder={tr('subject_lines.modal.percent.placeholder')}
                />
                <NumberInput
                  suffix="%"
                  name={`subjectLines.${index}.clickToOpenRate`}
                  label={<FormattedMessage id="subject_lines.modal.click_rate.label" />}
                  placeholder={tr('subject_lines.modal.percent.placeholder')}
                />
                <MoneyInput
                  amount={{
                    name: `subjectLines.${index}.revenue.value`,
                    label: tr('subject_lines.modal.revenue.amount.label'),
                    placeholder: tr('subject_lines.modal.revenue.placeholder')
                  }}
                  currency={{
                    name: `subjectLines.${index}.revenue.currency`,
                    label: tr('subject_lines.modal.revenue.currency.label')
                  }}
                />
              </ScoresContainer>
              <SubjectLineWithAction>
                <ListItem classes={classes} component="div">
                  <ListItemText>{subjectLine}</ListItemText>
                </ListItem>
                <CopyToClipboardButton color="primary" text={subjectLine} />
              </SubjectLineWithAction>
            </SubjectLineListItem>
          ))}
        </List>
      </SubjectLinesContainer>
      <Fab variant="extended" color="primary" type="submit">
        <FormattedMessage id="common.save" />
      </Fab>
    </CenteredContainer>
  );
};

const SubjectLinesContainer = styled.div`
  padding: 1em 0;
  width: 100%;
`;

const SubjectLineListItem = styled.li`
  padding: 0.25em 0;
`;

const ScoresContainer = styled.div`
  display: flex;
  padding: 0.5em;
  gap: 0.5em;
`;

const SubjectLineWithAction = styled.div`
  display: flex;
  justify-content: flex-start;
`;

export default SubjectLinesPerformance;
