import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';
import { Box, CircularProgress, IconButton, Tooltip } from '@mui/material';
// import refreshIcon from 'assets/icon-refresh.svg';
import React, { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import { withGtmInteraction } from 'services/tracking/withGtmInteraction';

export function ActionsSpinner() {
  return (
    <Box
      sx={{
        /** This value must match padding of the IconButton so there is no layout shift */
        padding: '5px'
      }}
    >
      <CircularProgress
        sx={{
          /* without this spinner is inline and additional space below is added */
          display: 'block'
        }}
        key="spinner"
        size={24}
      />
    </Box>
  );
}

export function BasicFavoriteAction(props: {
  favorite: boolean;
  onFavorite: () => void;
  gtmId?: string;
  /**
   * #tech-debt: https://app.clickup.com/t/862je7864
   */
  withDeprecatedGtm?: boolean;
}) {
  const { favorite, onFavorite, gtmId, withDeprecatedGtm } = props;

  if (favorite) {
    return (
      <Box
        sx={{
          /** This value must match padding of the IconButton so there is no layout shift */
          padding: '5px'
        }}
      >
        <FavoriteIcon
          sx={{
            /* without this icon is inline and additional space below the icon is added */
            display: 'block'
          }}
          /* #color-tech-debt */
          htmlColor="#BA87FF"
        />
      </Box>
    );
  }

  return (
    <BasicAction
      icon={<FavoriteBorderIcon />}
      tooltip={<FormattedMessage id="aiWriter.inspirations.suggestions.favorite" />}
      gtmId={gtmId}
      withDeprecatedGtm={withDeprecatedGtm}
      onClick={onFavorite}
    />
  );
}

export const BasicFlashAction = (props: {
  onClick: (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  gtmId?: string;
}) => {
  const { onClick, gtmId } = props;
  return (
    <BasicAction
      icon={<AutoAwesomeIcon fontSize="small" />}
      tooltip={<FormattedMessage id="aiWriter.inspirations.suggestions.flash_actions" />}
      gtmId={gtmId}
      onClick={onClick}
    />
  );
};

export function BasicRewriteAction(props: {
  onClick: () => void;
  gtmId?: string;
  /**
   * #tech-debt: https://app.clickup.com/t/862je7864
   */
  withDeprecatedGtm?: boolean;
}) {
  const { onClick, gtmId, withDeprecatedGtm } = props;
  return (
    <BasicAction
      icon={<PublishedWithChangesIcon />}
      tooltip={<FormattedMessage id="aiWriter.inspirations.suggestions.rewrite" />}
      gtmId={gtmId}
      withDeprecatedGtm={withDeprecatedGtm}
      onClick={onClick}
    />
  );
}

export function BasicUnfavoriteAction(props: {
  onClick: () => void;
  gtmId?: string;
  /**
   * #tech-debt: https://app.clickup.com/t/862je7864
   */
  withDeprecatedGtm?: boolean;
}) {
  const { onClick, gtmId, withDeprecatedGtm } = props;
  return (
    <BasicAction
      icon={<FavoriteIcon color="primary" />}
      tooltip={<FormattedMessage id="aiWriter.inspirations.suggestions.unfavorite" />}
      gtmId={gtmId}
      withDeprecatedGtm={withDeprecatedGtm}
      onClick={onClick}
    />
  );
}

export function BasicAction(props: {
  icon: ReactNode;
  tooltip: ReactNode;
  gtmId?: string;
  /**
   * #tech-debt: https://app.clickup.com/t/862je7864
   */
  withDeprecatedGtm?: boolean;
  onClick?: (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}) {
  const { icon, tooltip, onClick, gtmId, withDeprecatedGtm } = props;
  return (
    <Tooltip title={tooltip}>
      <IconButton
        {...withGtmInteraction(gtmId, { withDeprecatedStyle: withDeprecatedGtm })}
        onClick={onClick}
        size="small"
      >
        {icon}
      </IconButton>
    </Tooltip>
  );
}
