import { motion } from 'framer-motion';
import React from 'react';

const formAnimationVariants = {
  visible: (i: number) => ({
    opacity: 1,
    transition: {
      delay: i * 0.2
    }
  }),
  hidden: { opacity: 0 }
};

type Props = { animationOrder: number; className?: string; children: React.ReactElement };

const AnimatedContainer = ({ animationOrder, className, children }: Props) => (
  <motion.div
    className={className}
    variants={formAnimationVariants}
    custom={animationOrder}
    initial="hidden"
    animate="visible"
  >
    {children}
  </motion.div>
);

export default AnimatedContainer;
