import { Typography } from '@mui/material';
import { PlateContent } from '@udecode/plate-common';
import useEditor from 'features/aiWriter/AiWriterTextEditor/hooks/useEditor';
import { useEditorSettingsStore } from 'features/aiWriter/AiWriterTextEditor/store/useEditorSettingsStore';
import { manipulateClipboardData } from 'features/aiWriter/AiWriterTextEditor/utils/manipulateClipboardData';
import { plateImageCaptionWorkaround } from 'features/aiWriter/plateImageCaptionWorkaround';
import { getGeneratingTextInEditor } from 'features/aiWriter/store/selectors';
import { CursorOverlay } from 'features/plate/components/plate-ui/cursor-overlay';
import { ReactElement, ReactEventHandler, useEffect, useRef } from 'react';
import { useAppSelector } from 'store/hooks';
import styled from 'styled-components';

type Props = {
  hasError: boolean;
  helperText: string;
};

export const DataTypeDocumentTextEditor = ({ hasError, helperText }: Props): ReactElement => {
  const containerRef = useRef(null);

  const editor = useEditor();
  const activeTextGeneration = useAppSelector(getGeneratingTextInEditor);

  const editorBackgroundColor = useEditorSettingsStore(state => state.backgroundColor);
  const hideEditorBorder = useEditorSettingsStore(state => state.hideBorder);

  // Identifies whether the user has used the hotkey without focusing the editor
  useEffect(() => {
    const copyHandler = (event: globalThis.ClipboardEvent) => {
      if (manipulateClipboardData(event.clipboardData)) {
        event.preventDefault();
      }
    };

    document.addEventListener('copy', copyHandler);

    return () => {
      document.removeEventListener('copy', copyHandler);
    };
  }, [editor]);

  /**
   * Preventing default behavior of mouse down event kills all events related
   * to selection. That way we can block user from changing the caret position
   * when we are generating text in the editor.
   */
  const preventSelectionChange: ReactEventHandler = event => {
    event.preventDefault();
  };

  return (
    <>
      <Container
        onMouseDown={activeTextGeneration ? preventSelectionChange : undefined}
        $hideBorder={hideEditorBorder}
        $backgroundColor={editorBackgroundColor}
        $hasError={hasError}
      >
        <div
          ref={containerRef}
          style={{
            // Required for the optionally rendered absolute positioned cursor in the CursorOverlay below
            // They are used after selecting something and dragging it around
            position: 'relative',
            height: '100%'
          }}
        >
          <Editor>
            <PlateContent autoFocus={true} readOnly={activeTextGeneration} />
          </Editor>

          <CursorOverlay containerRef={containerRef} />
        </div>
      </Container>

      {helperText && <ErrorText>{helperText}</ErrorText>}
    </>
  );
};

const Container = styled.div<{
  $hideBorder?: boolean;
  $backgroundColor: string;
  $hasError?: boolean;
}>`
  width: 100%;
  min-width: 400px;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;

  padding-block: ${({ theme }) => theme.spacings.large};
  padding-inline: ${({ theme }) => theme.spacings.medium};
  // More left padding to keep space for the drag handle
  padding-left: ${({ theme }) => theme.spacings.xlarge};
  padding-right: ${({ theme }) => theme.spacings.xlarge};
  margin: 0 auto auto;
  border: ${({ $hideBorder, $hasError }) => ($hideBorder && !$hasError ? 0 : 0.5)}px solid
    ${({ theme, $hasError }) =>
      $hasError ? theme.colors.error : theme.colors.aiWriterSidebarBorderColor};
  border-radius: ${({ theme }) => theme.borderRadius.medium};
`;

const Editor = styled.div`
  ${plateImageCaptionWorkaround};
  margin: 0 auto;
  height: 100%;

  .slate-SelectionArea {
    /* This is required to make sure plate selection area don't overlay the editor textarea */
    padding: 5px;
  }
`;

const ErrorText = styled(Typography)`
  color: ${({ theme }) => theme.colors.danger};
`;
