export type SocialLoginState = {
  uuid: string;
  newsletterSubscribed?: boolean;
  isPaid?: boolean;
  campaignUrl?: string;
};

export const encodeSocialLoginState = ({
  uuid,
  newsletterSubscribed,
  isPaid,
  campaignUrl
}: SocialLoginState) =>
  window.btoa(
    JSON.stringify({
      uuid,
      newsletterSubscribed,
      isPaid,
      campaignUrl
    })
  );

export const decodeSocialLoginState = (encoded: string): SocialLoginState =>
  JSON.parse(window.atob(encoded));
