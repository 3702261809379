import FlexContainer from 'components/FlexContainer';
import { LoadingButton } from 'components/LoadingButton';
import { IntlMessageKeys } from 'features/i18n/IntlMessageKeys';
import { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

export const Actions = {
  Layout,
  CancelButton,
  OkButton,
  ContinueButton
};

function Layout(props: { ok: ReactNode; cancel: ReactNode }) {
  return (
    <FlexContainer gap="medium" alignItems="center">
      {props.cancel}
      {props.ok}
    </FlexContainer>
  );
}

type WithOnClick<T = unknown> = T & {
  onClick: () => void;
};

function OkButton(props: WithOnClick<{ labelId: IntlMessageKeys; dangerous?: boolean }>) {
  return (
    <ModalButton onClick={props.onClick} color={props.dangerous ? 'error' : undefined}>
      <FormattedMessage id={props.labelId} />
    </ModalButton>
  );
}

function ContinueButton(props: WithOnClick<{ labelId: IntlMessageKeys; dangerous?: boolean }>) {
  return <OkButton onClick={props.onClick} dangerous={props.dangerous} labelId={props.labelId} />;
}

function CancelButton(props: WithOnClick<{ labelId: IntlMessageKeys; loading?: boolean }>) {
  return (
    <ModalButton variant="contained" loading={props.loading} onClick={props.onClick}>
      <FormattedMessage id={props.labelId} />
    </ModalButton>
  );
}

const ModalButton = styled(LoadingButton)`
  width: 50%;
`;
