import React from 'react';

import TextInputField from './TextInputField';

type Props = {
  name: string;
  label: React.ReactNode;
  placeholder: string;
  prefix?: string;
  suffix?: string;
};

const NumberInput = ({ name, label, placeholder, prefix, suffix }: Props) => {
  return (
    <TextInputField
      name={name}
      type="number"
      variant="outlined"
      size="small"
      label={label}
      InputProps={{ placeholder, startAdornment: prefix, endAdornment: suffix }}
    />
  );
};

export default NumberInput;
