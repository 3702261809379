import { createAction } from '@reduxjs/toolkit';
import { GenerateTextConfig } from 'features/textGenerator/store/types';

import { AiWriterProjectSettings, InspirationsExtendedStep, SeoConfig } from '../../types';

export const setInspirationsCurrentStep = createAction<InspirationsExtendedStep | undefined>(
  'aiWriter/setInspirationsCurrentStep'
);
export const changeOutputType = createAction<string>('aiWriter/changeOutputType');
export const changeKeywords = createAction<string>('aiWriter/changeKeywords');
export const changeConfigText = createAction<string>('aiWriter/changeConfigText');
export const changeGenerateTextConfig = createAction<Partial<GenerateTextConfig>>(
  'aiWriter/changeGenerateTextConfig'
);
export const changeProjectSettings = createAction<AiWriterProjectSettings>(
  'aiWriter/changeProjectSettings'
);
export const changeSeoConfig = createAction<SeoConfig>('aiWriter/changeSeoKeywords');

export const setInitTextLibraryCategory = createAction<string | undefined>(
  'aiWriter/setInitTextLibraryCategory'
);
