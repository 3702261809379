import { Button, TextField } from '@mui/material';
import backgroundDoodleOrnament from 'assets/background-doodle.png';
import { AuthCard } from 'components/auth/AuthCard';
import { AuthCenteredPanel } from 'components/auth/AuthCenteredPanel';
import { ColoredTypography } from 'components/ColoredTypography';
import FlexContainer from 'components/FlexContainer';
import { Ornament } from 'components/Ornament';
import { getRoutePath } from 'config/routes';
import { resendVerificationEmail } from 'features/authentication/resendVerificationEmail';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router';
import styled from 'styled-components';
import useTr from 'utils/hooks/useTr';

export const ResendMail = () => {
  const navigate = useNavigate();
  const tr = useTr();
  const [mail, setMail] = useState('');

  return (
    <AuthCenteredPanel
      slogan="login.slogan"
      ornaments={<Ornament src={backgroundDoodleOrnament} bottom="0%" right="0%" />}
    >
      <FlexContainer gap="large" alignItems="center">
        <AuthCard>
          <FlexContainer gap="xmedium">
            <ColoredTypography variant="h5">
              <FormattedMessage id="register.success.resend_email.header" />
            </ColoredTypography>
            <ColoredTypography variant="body1">
              <FormattedMessage
                id="register.success.resend_email.body"
                values={{ br: <br />, b: (text: string) => <strong>{text}</strong> }}
              />
            </ColoredTypography>

            <TextField
              id="outlined-basic"
              label={tr('common.email_address')}
              variant="outlined"
              onChange={e => setMail(e.target.value)}
            />

            <FlexContainer alignItems="center" gap="medium">
              <CardButton variant="contained" onClick={() => resendVerificationEmail(mail)}>
                <FormattedMessage id="common.send" />
              </CardButton>
              <CardButton onClick={() => navigate(getRoutePath('login'))}>
                <FormattedMessage id="common.back_to_login" />
              </CardButton>
            </FlexContainer>
          </FlexContainer>
        </AuthCard>
      </FlexContainer>
    </AuthCenteredPanel>
  );
};

const CardButton = styled(Button)`
  display: block;
`;
