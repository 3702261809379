import ErrorIcon from '@mui/icons-material/Error';
import { Typography } from '@mui/material';
import FormattedMessage from 'features/i18n/FormattedMessage';
import { BuilderError } from 'features/modular-workflow/builder/getBuilderErrorsFromResponse';
import { AddStepBox } from 'features/modular-workflow/builder/step-list/AddStepBox';
import { SortableStepBoxWrapper } from 'features/modular-workflow/builder/step-list/SortableStepBoxWrapper';
import { SortableStepList } from 'features/modular-workflow/builder/step-list/SortableStepList';
import { StepBox } from 'features/modular-workflow/builder/step-list/StepBox';
import {
  ApiModularWorkflowStepReference,
  ApiModularWorkflowStepReferenceIcons,
  createStepFromStructure,
  isNotDeletableStepReference
} from 'features/modular-workflow/builder/types-api';
import { useEnsureUniqueStepOutputAlias } from 'features/modular-workflow/builder/useEnsureUniqueStepOutputAlias';
import { useModulareWorkflowCreationStore } from 'features/modular-workflow/builder/useModulareWorkflowCreationStore';
import { useGetModulareWorkflowStepStructureByReference } from 'features/modular-workflow/useModulareWorkflowStructureStore';
import { Fragment } from 'react';
import styled from 'styled-components';

type Props = {
  activeStepIndex: number;
  onStepClick: (stepIndex: number) => void;
  stepErrorList: BuilderError[];
};

export const ModularWorkflowStepList = ({ activeStepIndex, onStepClick, stepErrorList }: Props) => {
  const workflow = useModulareWorkflowCreationStore(state => state.workflow);
  const addWorkflowStep = useModulareWorkflowCreationStore(state => state.addWorkflowStep);
  const getStepStructure = useGetModulareWorkflowStepStructureByReference();
  const ensureUniqueStepOutputAlias = useEnsureUniqueStepOutputAlias();

  const handleAddStepClick = (type: ApiModularWorkflowStepReference) => {
    const stepStructure = getStepStructure(type);
    if (!stepStructure) {
      return;
    }

    const newStep = ensureUniqueStepOutputAlias(createStepFromStructure(stepStructure));
    const addedStep = addWorkflowStep(newStep);
    // Auto-select the newly added step
    const newStepIndex = addedStep.order;
    // HACK: Postpone the selection to the next tick to avoid
    // timing issues
    setTimeout(() => onStepClick(newStepIndex), 0);
  };

  // It's zero based
  const humanStepIndex = activeStepIndex + 1;
  const humanStepCount = workflow.steps.length;

  return (
    <Root>
      <Typography variant="caption">
        <FormattedMessage
          id="modular_workflow.builder.step_list.current_step"
          values={{
            currentStep: humanStepIndex,
            totalSteps: humanStepCount
          }}
        />
      </Typography>

      <SortableStepList
        renderStep={(step, stepIndex) => {
          const key = `${step.reference}-${step.order}`;
          const currentStepErrorList = stepErrorList.filter(error => error.stepIndex === stepIndex);

          // Insert the "add step" box before the last 2 steps
          if (stepIndex === workflow.steps.length - 2) {
            return (
              <Fragment key={key}>
                <AddStepBox onStepClick={handleAddStepClick} />
                <StepBox
                  icon={ApiModularWorkflowStepReferenceIcons[step.reference] ?? ErrorIcon}
                  name={step.name}
                  isActive={activeStepIndex === stepIndex}
                  hasError={currentStepErrorList.length > 0}
                  onClick={() => onStepClick(stepIndex)}
                />
              </Fragment>
            );
          }

          // No dragging for default steps
          if (isNotDeletableStepReference(step.reference)) {
            return (
              <StepBox
                key={key}
                icon={ApiModularWorkflowStepReferenceIcons[step.reference] ?? ErrorIcon}
                name={step.name}
                isActive={activeStepIndex === stepIndex}
                hasError={currentStepErrorList.length > 0}
                onClick={() => onStepClick(stepIndex)}
              />
            );
          }

          return (
            <SortableStepBoxWrapper key={key} id={step.id}>
              {({ isDragging }) => (
                <StepBox
                  key={key}
                  icon={ApiModularWorkflowStepReferenceIcons[step.reference] ?? ErrorIcon}
                  name={step.name}
                  isActive={activeStepIndex === stepIndex}
                  hasError={currentStepErrorList.length > 0}
                  isDragging={isDragging}
                  onClick={() => onStepClick(stepIndex)}
                />
              )}
            </SortableStepBoxWrapper>
          );
        }}
      />
    </Root>
  );
};

const Root = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.small};
  background-color: ${({ theme }) => theme.colors.textTile};
  padding: ${({ theme }) => theme.spacings.small};
  border-radius: ${({ theme }) => theme.borderRadius.small};
`;
