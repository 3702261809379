import SelectedWordsContext, {
  SelectedWord
} from 'components/layout/toolbar/WordsManager/SelectedWordsContext';
import WordButton from 'components/layout/toolbar/WordsManager/WordButton';
import WordsList from 'components/layout/toolbar/WordsManager/WordsList';
import { useModal } from 'components/modals';
import { ModalType } from 'components/modals/modalComponents';
import { useEffect, useState } from 'react';
import { List } from 'services/api/list/types';

type Props = {
  currentList: List;
  removeWordsModalId: ModalType;
  addWordsModalId: ModalType;
  showActions?: boolean;
};

const WordsManager = ({
  currentList,
  addWordsModalId,
  removeWordsModalId,
  showActions = true
}: Props) => {
  const { showModal } = useModal();
  const [selectedWords, setSelectedWords] = useState<SelectedWord[]>([]);
  const [lastSelectedItemIndex, setLastSelectedItemIndex] = useState(0);

  useEffect(() => {
    setSelectedWords([]);
    setLastSelectedItemIndex(0);
  }, [currentList]);

  return (
    <>
      {showActions && (
        <div className="d-flex mt-2 justify-content-center">
          <WordButton
            tooltipMessage="lists.add_words"
            icon="plus"
            onClick={() =>
              showModal({
                modalType: addWordsModalId,
                modalProps: { activeList: currentList.id, listLanguage: currentList.language }
              })
            }
          />
          <WordButton
            tooltipMessage="lists.remove_words"
            icon="trash"
            disabled={!selectedWords.length}
            onClick={() =>
              showModal({
                modalType: removeWordsModalId,
                modalProps: { selectedWords: selectedWords.map(i => i.word) }
              })
            }
          />
          <WordButton
            tooltipMessage="lists.select_words"
            onClick={() =>
              setSelectedWords(
                selectedWords.length !== currentList.words.length
                  ? currentList.words.map((word, index) => ({ word, index }))
                  : []
              )
            }
            icon="check-circle"
          >
            {selectedWords.length} / {currentList.words.length}
          </WordButton>
        </div>
      )}
      <SelectedWordsContext.Provider
        value={{
          selectedWords,
          setSelectedWords,
          lastSelectedItemIndex,
          setLastSelectedItemIndex,
          showCheckboxes: showActions
        }}
      >
        <WordsList words={currentList.words} />
      </SelectedWordsContext.Provider>
    </>
  );
};

export default WordsManager;
