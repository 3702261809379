import { Button, Typography } from '@mui/material';
import ExpiryImage from 'assets/billing/expiry-popup-image.png';
import FlexContainer from 'components/FlexContainer';
import { useModal } from 'components/modals';
import { CloseModal } from 'components/modals/types';
import { usePostHog } from 'posthog-js/react';
import { FormattedMessage } from 'react-intl';
import gtmIds from 'services/tracking/GTMIds';
import { withGtmInteraction } from 'services/tracking/withGtmInteraction';
import styled from 'styled-components';

export const TrialExpiryReminderModal = (props: CloseModal) => {
  const { closeModal } = props;
  const postHog = usePostHog();
  const { showModal } = useModal();

  const handleButtonClick = () => {
    closeModal();
  };

  const handleCancellationButtonClick = () => {
    postHog?.capture('Cancel subscription click');

    showModal('CANCEL_SUBSCRIPTION', { size: 900 });
  };

  return (
    <Root>
      <img src={ExpiryImage} alt="expiry-image" height={362} width={276} />
      <Content>
        <Typography variant="h6">
          <FormattedMessage id="trial_expiry_popup.title" />
        </Typography>

        <Typography variant="body1">
          <FormattedMessage id="trial_expiry_popup.description.line1" values={{ br: <br /> }} />
        </Typography>

        <Typography variant="body1">
          <FormattedMessage id="trial_expiry_popup.description.line2" />
        </Typography>

        <FlexContainer gap="one" justifyContent="center">
          <Button onClick={handleButtonClick} variant="contained">
            <FormattedMessage id="common.continue" />
          </Button>
          <Button
            {...withGtmInteraction(gtmIds.pricing.cancelSubscription)}
            color="primary"
            onClick={handleCancellationButtonClick}
            size="medium"
            variant="text"
          >
            <FormattedMessage id="profile.subscription_cancel" />
          </Button>
        </FlexContainer>
      </Content>
    </Root>
  );
};

const Root = styled(FlexContainer).attrs({ direction: 'row' })`
  padding: ${({ theme }) => theme.spacings.four};
`;

const Content = styled(FlexContainer).attrs({ gap: 'three' })`
  padding: ${({ theme }) => `0 ${theme.spacings.four}`};
`;
