import { insertNode, useEditorState } from '@udecode/plate-common';
import { ELEMENT_PARAGRAPH } from '@udecode/plate-paragraph';
import { DataTypeDocumentEditorTopToolbar } from 'features/modular-workflow/builder/settings/DataTypeDocumentEditorTopToolbar';
import { DataTypeDocumentTextEditor } from 'features/modular-workflow/builder/settings/DataTypeDocumentTextEditor';
import { InputRendererContext } from 'features/modular-workflow/builder/settings/InputRenderer';
import { PlaceholderList } from 'features/modular-workflow/builder/settings/PlaceholderList';
import { useModulareWorkflowCreationStore } from 'features/modular-workflow/builder/useModulareWorkflowCreationStore';
import { useContext } from 'react';
import styled from 'styled-components';

type Props = {
  elementId: string;
};

export const DataTypeDocumentEditor = ({ elementId }: Props) => {
  const editor = useEditorState(elementId);
  const { inputErrorList, stepIndex } = useContext(InputRendererContext);
  const workflow = useModulareWorkflowCreationStore(state => state.workflow);

  const previousSteps = workflow.steps.slice(0, stepIndex);
  const outputsFromPreviousSteps = previousSteps.flatMap(step => step.outputs);

  const handlePlaceholderClick = (value: string) => {
    insertNode(editor, {
      type: ELEMENT_PARAGRAPH,
      children: [{ text: `{{${value}}}` }]
    });
  };

  return (
    <>
      <EditorContainer>
        <DataTypeDocumentEditorTopToolbar editorId={elementId} />

        <DataTypeDocumentTextEditor
          hasError={inputErrorList.length > 0}
          helperText={inputErrorList.map(error => error.errorMessage).join(', ')}
        />
      </EditorContainer>

      <PlaceholderList
        outputs={outputsFromPreviousSteps}
        onPlaceholderClick={handlePlaceholderClick}
      />
    </>
  );
};

const EditorContainer = styled.div`
  position: relative;

  width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.medium};
`;
