import { collapseSelection, insertNodes } from '@udecode/plate-common';
import useEditor from 'features/aiWriter/AiWriterTextEditor/hooks/useEditor';
import { markdownToSlate } from 'features/aiWriter/markdown/markdownToSlate';

export function useInsertTextWithMarkdown() {
  const editor = useEditor();

  return function copyToEditor(text: string) {
    if (!editor) return;

    collapseSelection(editor, { edge: 'end' });
    insertNodes(editor, markdownToSlate(text));
  };
}
