import { getRoutePath } from 'config/routes';
import useShowSetupModal from 'features/aiWriter/modals/setup/useSetupModal';
import { setInspirationsCurrentStep } from 'features/aiWriter/store/actions/config/actions';
import { changeOutputTypeThunk } from 'features/aiWriter/store/actions/config/thunks/changeOutputTypeThunk';
import { loadTabThunk } from 'features/aiWriter/store/actions/tabs/thunks/loadTabThunk';
import { getCurrentModelLanguageAndCountry } from 'features/aiWriter/store/selectors';
import { AiWriterSidebarStep } from 'features/aiWriter/store/types';
import { useGetProjectById } from 'features/aiWriter/useGetProjectById';
import { TaskPropsItem } from 'features/onboardingJourney/checklist/ChecklistButton';
import { collapseSidebar } from 'features/pageSidebar/useSidebarStore';
import {
  useShowPersonalityCreationModal,
  ViewMode
} from 'features/personality/creation/PersonalityCreationModal';
import { useShowOutputTypeModal } from 'features/textGenerator/outputTypeModal/useShowOutputTypeModal';
import { useNavigate } from 'react-router';
import { TaskType } from 'services/backofficeIntegration/http/endpoints/onboardingChecklist/httpGetChecklist';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { useCurrentRoutePath } from 'utils/hooks/useCurrentRoutePath';

export function useMkTaskProperties() {
  const dispatch = useAppDispatch();

  const writerProjects = useAppSelector(state => state.aiWriter.projects);
  const writerCurrentTab = useAppSelector(state => state.aiWriter.currentTab);
  const currentWriterModelLanguageAndCountry = useAppSelector(getCurrentModelLanguageAndCountry);

  const showWriterSetupModal = useShowSetupModal();
  const showOutputTypeModal = useShowOutputTypeModal();
  const showPersonalityCreationModal = useShowPersonalityCreationModal();
  const { fetchProject } = useGetProjectById();

  const navigate = useNavigate();
  const pathname = useCurrentRoutePath();

  const isAiWriterRoute = () => pathname === getRoutePath('aiWriter');
  const isAiImagesRoute = () => pathname === getRoutePath('aiImages');
  const isSocialPostWorkflowRoute = () => pathname === getRoutePath('socialPostWorkflow');

  const goToAiWriter = async () => {
    if (!isAiWriterRoute()) {
      await navigate(getRoutePath('aiWriter'));
    }
  };

  function openLastWriterProject() {
    fetchProject(
      { id: writerProjects[0].id },
      {
        onSuccess: project => {
          dispatch(loadTabThunk(project));
        }
      }
    );
  }

  const handleImagesAction = () => {
    if (isAiImagesRoute()) {
      return;
    }

    navigate(getRoutePath('aiImages'));
  };

  const handleSocialWorkflowAction = async () => {
    if (isSocialPostWorkflowRoute()) {
      return;
    }

    navigate(getRoutePath('socialPostWorkflow'));
    dispatch(setInspirationsCurrentStep(undefined));
    collapseSidebar();
  };

  const handleTextTypeAction = async () => {
    await goToAiWriter();

    const isAnyProjectOpened = writerCurrentTab !== '';

    if (isAnyProjectOpened) {
      const { currentModelCountry, currentModelLanguage } = currentWriterModelLanguageAndCountry;
      showOutputTypeModal({
        modelCountry: currentModelCountry,
        modelLanguage: currentModelLanguage,
        onOutputTypeChange: value => dispatch(changeOutputTypeThunk(value))
      });
      return;
    }

    const aiWriterLastProject = writerProjects[0];
    if (aiWriterLastProject) {
      openLastWriterProject();

      const { currentModelCountry, currentModelLanguage } = currentWriterModelLanguageAndCountry;
      showOutputTypeModal({
        modelCountry: currentModelCountry,
        modelLanguage: currentModelLanguage,
        onOutputTypeChange: value => dispatch(changeOutputTypeThunk(value))
      });
      return;
    }

    showWriterSetupModal({ createCustomEmptyDocument: false, showPromptLibrary: true });
    return;
  };

  const handleContinueWritingAction = async () => {
    const aiWriterLastProject = writerProjects[0];

    await goToAiWriter();

    const isProjectOpened = writerCurrentTab !== '';
    if (isProjectOpened) {
      return;
    }

    if (aiWriterLastProject) {
      openLastWriterProject();
      return;
    }

    showWriterSetupModal({ createCustomEmptyDocument: false });
    return;
  };

  const handlePersonalityAction = async () => {
    await goToAiWriter();

    const isProjectOpened = writerCurrentTab !== '';

    if (isProjectOpened) {
      dispatch(setInspirationsCurrentStep({ step: AiWriterSidebarStep.chat, subStep: 'main' }));

      showPersonalityCreationModal({
        initialViewMode: ViewMode.choice
      });
      return;
    }

    navigate('/aiWriter/new#noEmptyDocumentMenu&createPersonality');
  };

  const tasksProps: Record<TaskType, TaskPropsItem> = {
    use_text_type: {
      title: 'onboarding_checklist.use_text_type',
      tooltipMessage: 'onboarding_checklist.use_text_type.tooltip',
      handleAction: handleTextTypeAction
    },
    click_continue_writing: {
      title: 'onboarding_checklist.click_continue_writing',
      tooltipMessage: 'onboarding_checklist.click_continue_writing.tooltip',
      handleAction: handleContinueWritingAction
    },
    create_personality: {
      title: 'onboarding_checklist.create_personality',
      tooltipMessage: 'onboarding_checklist.create_personality.tooltip',
      handleAction: handlePersonalityAction
    },
    complete_social_media_workflow: {
      title: 'onboarding_checklist.complete_social_media_workflow',
      tooltipMessage: 'onboarding_checklist.complete_social_media_workflow.tooltip',
      handleAction: handleSocialWorkflowAction
    },
    create_image: {
      title: 'onboarding_checklist.create_image',
      tooltipMessage: 'onboarding_checklist.create_image.tooltip',
      handleAction: handleImagesAction
    }
  };

  return tasksProps;
}
