import { Button, Divider, Step, StepLabel, Stepper } from '@mui/material';
import { Form, Formik, FormikConfig, FormikHelpers, FormikState } from 'formik';
import React, { useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

export type FormStep = {
  label: string;
  optional?: React.ReactNode;
  completed?: boolean;
};

export type StepHelpers = {
  step: number;

  next: () => void;
  previous: () => void;
  goToStep: (step: number) => void;
  resetStepperForm: () => void;
};

export type StepNextButtonProps = {
  step: number;
};

type Props<Values> = {
  steps: FormStep[];
  initialStep?: number;
  backButton?: boolean;
  nextButton?: boolean;
  summaryScreen?: boolean;

  stepComponent: (props: StepHelpers) => React.ReactNode;
  stepNextButton?: (props: StepNextButtonProps) => React.ReactNode;
  onReturn?: () => void;
} & FormikConfig<Values>;

function StepperForm<Values>({
  steps,
  initialStep,
  backButton,
  summaryScreen,
  initialValues,
  validationSchema,

  stepComponent,
  stepNextButton,
  onReturn,
  onSubmit,
  ...formikProps
}: Props<Values>) {
  const [activeStep, setActiveStep] = useState(initialStep || 0);
  const [valuesSnapshot, setValuesSnapshot] = useState(initialValues);
  const [initialValuesSnapshot] = useState(initialValues);

  const totalSteps = summaryScreen ? steps.length + 1 : steps.length;
  const isSummaryStep = summaryScreen && activeStep === totalSteps - 1;
  const isSubmitStep = summaryScreen
    ? activeStep === totalSteps - 2
    : activeStep === totalSteps - 1;

  const isFirstStep = activeStep === 0;

  const showBackButton = useMemo(() => {
    if (backButton) {
      if (isFirstStep) {
        return !!onReturn;
      } else {
        return !isSummaryStep;
      }
    } else {
      return false;
    }
  }, [backButton, isFirstStep, isSummaryStep, onReturn]);

  const next = (values: Values) => () => {
    setValuesSnapshot(values);
    setActiveStep(prevActiveStep => Math.min(prevActiveStep + 1, totalSteps - 1));
  };

  const previous = (values: Values) => () => {
    setValuesSnapshot(values);
    setActiveStep(prevActiveStep => Math.max(prevActiveStep - 1, 0));
  };

  const goToStep = (step: number) => {
    setActiveStep(step);
  };

  const resetStepperForm =
    (resetForm: (nextState?: Partial<FormikState<Values>>) => void) => () => {
      setValuesSnapshot(initialValuesSnapshot);
      resetForm();
    };

  const handleSubmit = (values: Values, bag: FormikHelpers<Values>) => {
    if (isSubmitStep) {
      onSubmit(values, bag);
      if (summaryScreen) {
        next(values)();
      }
    } else {
      next(values)();
      bag.setSubmitting(false);
      bag.setTouched({});
    }
  };

  const currentValidationSchemat = validationSchema[activeStep];

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={valuesSnapshot}
      validationSchema={currentValidationSchemat}
      enableReinitialize
      {...formikProps}
    >
      {({ values, resetForm }) => (
        <FullPageForm>
          <Header>
            <Stepper activeStep={activeStep}>
              {steps.map(({ label, completed, optional }, index) => {
                const stepCompleted = completed || activeStep > index;
                return (
                  <Step key={label} completed={stepCompleted}>
                    <StepLabel optional={optional}>
                      <FormattedMessage id={label} />
                    </StepLabel>
                  </Step>
                );
              })}
            </Stepper>
          </Header>
          <Divider />
          <Content>
            {stepComponent({
              step: activeStep,
              next: next(values),
              previous: previous(values),
              goToStep,
              resetStepperForm: resetStepperForm(resetForm)
            })}
          </Content>
          <Footer>
            {showBackButton && (
              <Button
                onClick={isFirstStep && onReturn ? onReturn : previous(values)}
                color="primary"
              >
                <FormattedMessage id="common.back" />
              </Button>
            )}
            {stepNextButton?.({ step: activeStep })}
          </Footer>
        </FullPageForm>
      )}
    </Formik>
  );
}

const FullPageForm = styled(Form)`
  display: flex;
  flex-direction: column;

  width: 100%;
`;

const Header = styled.div`
  flex: 0 0 auto;

  padding: 0 0 2rem;
`;

const Content = styled.div`
  display: flex;
  flex: 1 1 0;
  flex-direction: column;
  justify-content: space-between;

  margin: 2rem 0;
`;

const Footer = styled.div`
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;

  padding: 2rem 0;
`;

export default StepperForm;
