import { IntlMessageKeys } from 'features/i18n/IntlMessageKeys';
import { ReactNode } from 'react';
import { FormattedMessage as ReactIntlFormattedMessage } from 'react-intl';
import type { Props as ReactIntlFormattedMessageProps } from 'react-intl/dist/components/message';
import { isMacOsUser } from 'utils/isMacOsUser';

type TranslationValue = ReactNode | ((value: string) => ReactNode);

type Props = ReactIntlFormattedMessageProps<Record<string, TranslationValue>> & {
  id: IntlMessageKeys;
  macId?: IntlMessageKeys;
};

const FormattedMessage = ({ id, macId, ...rest }: Props) => {
  const translationId = isMacOsUser() ? macId ?? id : id;

  return <ReactIntlFormattedMessage id={translationId} {...rest} />;
};

export default FormattedMessage;
