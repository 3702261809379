import { useQuery } from '@tanstack/react-query';
import LogoAnimation from 'assets/loadingAnimation/logo-animation.lottie';
import { HalfMask } from 'components/AppLoader';
import FullMask from 'components/FullMask';
import Toast from 'components/toasts/Toast';
import LocalStorageKey from 'config/localStorageKey';
import { getRoutePath } from 'config/routes';
import { login } from 'features/customer/store/actions';
import { getIsAuthenticated } from 'features/customer/store/selectors';
import { CustomerDataPayload } from 'features/customer/store/types';
import { getUserData, saveUserDataToStorage } from 'features/customer/store/utils';
import { logWarn } from 'features/logging/logWarn';
import { LottieComponent } from 'features/lottie/LottieComponent';
import getFirstFreePlanId from 'features/pricing/utils/getFirstFreePlanId';
import { decodeSocialLoginState, SocialLoginState } from 'features/social/encodeSocialLoginState';
import { AnimatePresence } from 'framer-motion';
import posthog from 'posthog-js';
import { ReactElement } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useLocation, useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';
import adcellClient from 'services/adcell';
import { handleCustomerError } from 'services/api/customer/errors';
import { CustomerData } from 'services/api/customer/types';
import SocialAPI, {
  getSocialProviderRedirectUri,
  isSocialProviderSupported
} from 'services/api/social';
import { handleSocialErrors } from 'services/api/social/errors';
import firstPromoterClient from 'services/firstPromoterClient';
import { GAEvents } from 'services/tracking/GAEvents';
import {
  TrackingEventLoginAttributes,
  TrackingEventProviderType,
  TrackingEventRegistrationAttributes
} from 'services/tracking/types';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import getTimezone from 'utils/getTimezone';
import { parseUrlQuery } from 'utils/urlUtils';

type RouteParams = {
  provider: string;
};

type UrlQuery = {
  code?: string;
  state?: string;
};

const trackRegistrationAndLogin = (
  userData: CustomerDataPayload,
  statusCode: number,
  type: TrackingEventProviderType
) => {
  const eventData: TrackingEventLoginAttributes | TrackingEventRegistrationAttributes = {
    userId: userData.id,
    userEmail: userData.email,
    type,
    pricingId: null
  };

  if (statusCode === 201) {
    GAEvents.userRegistration(eventData);

    posthog?.capture('Sign-up', eventData);

    // Honor promotions
    firstPromoterClient.sendReferralEmail(userData.email);
  }

  // YES, this fall through is correct because after registration the user will be logged in,
  // and we want to track the login event as well
  GAEvents.userLogin(eventData);
};

const recaptchaAction = 'register';

export const HandleSocialLoginCallback = (): ReactElement => {
  const { provider } = useParams<RouteParams>();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const isAuthenticated = useAppSelector(getIsAuthenticated);

  // reCAPTCHA can't be executed multiple times, therefore we're using a query to handle the social login
  useQuery({
    enabled: !isAuthenticated && !!executeRecaptcha,
    staleTime: Infinity,
    queryKey: ['socialLogin', provider],
    queryFn: async () => {
      if (!location.search || location.search.length === 0) {
        logWarn('could not login: location search is missing');
        navigate(getRoutePath('login'));
        return false;
      }

      if (!provider || !isSocialProviderSupported(provider)) {
        logWarn(`could not login: social provider ${provider} is empty or not supported`);
        navigate(getRoutePath('login'));
        return false;
      }

      // Getting the code from the query
      const parsedUrlQuery = parseUrlQuery<UrlQuery>(location.search);

      function verifyCsrfState(query: UrlQuery): boolean {
        const state = localStorage.getItem(LocalStorageKey.OauthCsrfState);
        // This solution is not ideal because it works only once
        // however we need to remove the state to make sure that we do not break other social login providers
        // that do not use state
        localStorage.removeItem(LocalStorageKey.OauthCsrfState);
        return !state ? true : query.state === state;
      }

      if (!parsedUrlQuery || !parsedUrlQuery.code || !verifyCsrfState(parsedUrlQuery)) {
        logWarn('could not login: invalid csrf state');
        navigate(getRoutePath('login'));
        return false;
      }

      // TODO: Should be done in the API as a fallback if no pricing was given
      const firstFreePlanId = await getFirstFreePlanId();
      if (!firstFreePlanId) {
        logWarn('could not login: not first free plan');
        navigate(getRoutePath('login'));
        return false;
      }

      const passedState = parsedUrlQuery.state;
      let decodedSocialLoginState: SocialLoginState | null = null;
      if (passedState && passedState.length > 0) {
        try {
          decodedSocialLoginState = decodeSocialLoginState(passedState);
        } catch {
          // eslint-disable-next-line no-console
          console.error('Invalid state', passedState);
        }
      }

      const recaptchaResult = await executeRecaptcha?.(recaptchaAction);
      if (!recaptchaResult) {
        Toast.backendError(handleCustomerError('ERROR_INITIALIZATION_ACCOUNT_ACTIVITY'));
        return false;
      }

      // Let API resolve the code to a token and create a user
      const response = await SocialAPI.loginWithSocialProvider(provider, parsedUrlQuery.code, {
        timezone: getTimezone(),
        pricing: firstFreePlanId,
        bid: adcellClient.getSavedBid(),
        newsletter_subscribed: decodedSocialLoginState?.newsletterSubscribed ?? false,
        campaign_url: decodedSocialLoginState?.campaignUrl,
        redirectUrl: getSocialProviderRedirectUri(provider),
        token: recaptchaResult,
        expected_action: recaptchaAction
      });
      if (!response || !response.status) {
        Toast.backendError(handleSocialErrors(response.message));
        navigate(getRoutePath('login'));
        return false;
      }

      // TODO: Move into saga
      // Create our customer object
      const userData = getUserData({ ...response.data, tax_id: '', tax_type: '' } as CustomerData);

      // Track registration and or login
      trackRegistrationAndLogin(userData, response.statusCode, provider);

      // Required for tracking
      navigate({ search: `?ref=${firstFreePlanId}` });

      // Store data in local storage & redux (this triggers the redirect to our app)
      saveUserDataToStorage(userData, response.data.is_first_login);
      dispatch(login.success(userData));

      if (decodedSocialLoginState?.isPaid) {
        navigate(getRoutePath('socialRegister'));
        return true;
      }

      if (decodedSocialLoginState?.campaignUrl) {
        navigate(decodedSocialLoginState.campaignUrl);
        return true;
      }

      navigate(getRoutePath('home'));
      return true;
    }
  });

  return (
    <AnimatePresence>
      <FullMask style={{ opacity: 1 }} initial={{ opacity: 1 }} exit={{ opacity: 0 }}>
        <HalfMask>
          <LottieComponent src={LogoAnimation} options={{ speed: 1.5 }} />
        </HalfMask>
      </FullMask>
    </AnimatePresence>
  );
};
