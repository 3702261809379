import { SessionStorageKey } from 'config/sessionStorageKey';
import { SetStateAction } from 'react';
import { useStorage } from 'utils/hooks/useStorage';

export function useSessionStorage<V>(
  key: SessionStorageKey | string,
  initialValue: V,
  encodeValue = true
): [V, (newValue: SetStateAction<V>) => void] {
  return useStorage(key, initialValue, encodeValue, window.sessionStorage);
}
