import { createSelector } from '@reduxjs/toolkit';
import { memoize } from 'lodash';
import { RootState } from 'store/rootReducer';

import { GroupMember } from './types';

export const getGroupName = (state: RootState) => state.group?.group?.name;
export const getGroupId = (state: RootState) => state.group?.group?.id;

export const getGroupMembersList = createSelector(
  (state: RootState) => state.group.group?.members ?? {},
  members =>
    Object.values(members).sort(
      (a: GroupMember, b: GroupMember) =>
        new Date(b.lastLoggedIn).getTime() - new Date(a.lastLoggedIn).getTime()
    )
);

export const getGroupMemberByIdSelector = createSelector(
  (state: RootState) => state.group.group?.members ?? {},
  members => memoize((id: number) => members?.[id] || {})
);

export const getGetMappersForCountry = createSelector(
  (state: RootState) => state.group?.group?.weMappers ?? [],
  mappers =>
    ({ country, language }: { country?: string; language?: string }) => {
      if (country && language) {
        return mappers.filter(mapper => {
          if (mapper.country) {
            return mapper.country === country;
          } else {
            return mapper.language === language;
          }
        });
      }

      return [];
    }
);
