import FormattedMessage from 'features/i18n/FormattedMessage';
import { AppLanguage } from 'features/language/store/types';
import { BuilderError } from 'features/modular-workflow/builder/getBuilderErrorsFromResponse';
import { BuilderModularWorkflow } from 'features/modular-workflow/builder/types-builder';
import { useModulareWorkflowCreationStore } from 'features/modular-workflow/builder/useModulareWorkflowCreationStore';
import { useGetModulareWorkflowStepStructureByReference } from 'features/modular-workflow/useModulareWorkflowStructureStore';
import { MouseEvent } from 'react';
import styled from 'styled-components';

type Props = {
  workflow: BuilderModularWorkflow;
  errorList: BuilderError[];
};

export const ModularWorkflowUpdateError = ({ errorList, workflow }: Props) => {
  const setSelectedStepIndex = useModulareWorkflowCreationStore(
    state => state.setSelectedStepIndex
  );
  const getStepStructure = useGetModulareWorkflowStepStructureByReference();

  const createStepChangeHandler = (stepIndex: number) => (event: MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();

    setSelectedStepIndex(stepIndex);
  };

  return (
    <div>
      <strong>
        <FormattedMessage id="modular_workflow.builder.error.custom.title" />
      </strong>
      <p>
        <FormattedMessage id="modular_workflow.builder.error.custom.content" />
      </p>
      <ul>
        {errorList.map(error => {
          const stepName =
            workflow.steps[error.stepIndex]?.name_localized[AppLanguage.English] ??
            workflow.steps[error.stepIndex]?.name ??
            'Unknown Step';
          const stepReference = workflow.steps[error.stepIndex]?.reference;
          const inputReference =
            workflow.steps[error.stepIndex]?.inputs[error.inputIndex]?.reference;
          const stepStructure = getStepStructure(stepReference);
          const inputStructure = stepStructure?.settings.find(
            setting => setting.reference === inputReference
          );
          const inputName = inputStructure?.name ?? 'Unknown Input';

          return (
            <li key={Math.random()}>
              <StyledLink onClick={createStepChangeHandler(error.stepIndex)}>{stepName}</StyledLink>
              , <strong>{inputName}</strong>: <code>{error.errorMessage}</code>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

const StyledLink = styled.a`
  border-bottom: 1px dotted ${({ theme }) => theme.colors.primary};

  &:hover {
    border-bottom-style: solid;
  }
`;
