import { DefaultTheme } from '@mui/styles/defaultTheme';
import makeStyles from '@mui/styles/makeStyles';
import {
  isCollapsed,
  useEditorState,
  useElement,
  useRemoveNodeButton
} from '@udecode/plate-common';
import {
  FloatingMedia as FloatingMediaPrimitive,
  floatingMediaActions,
  useFloatingMediaSelectors
} from '@udecode/plate-media';
import { clsx } from 'clsx';
import { Icons } from 'features/plate/components/icons';
import { ReactNode, useEffect } from 'react';
import { useReadOnly, useSelected } from 'slate-react';

import { Button, buttonVariants } from './button';
import { inputVariants } from './input';
import { Popover, PopoverAnchor, PopoverContent } from './popover';
import { Separator } from './separator';

const useStyles = makeStyles<DefaultTheme>({
  editButton: {
    backgroundColor: 'transparent',
    border: 'none'
  }
});

export interface MediaPopoverProps {
  pluginKey?: string;
  children: ReactNode;
}

export function MediaPopover({ pluginKey, children }: MediaPopoverProps) {
  const classes = useStyles();
  const readOnly = useReadOnly();
  const selected = useSelected();
  const editor = useEditorState();

  const isOpen = !readOnly && selected && isCollapsed(editor.selection);
  const isEditing = useFloatingMediaSelectors().isEditing();

  useEffect(() => {
    if (!isOpen && isEditing) {
      floatingMediaActions.isEditing(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const element = useElement();
  const { props: buttonProps } = useRemoveNodeButton({ element });

  if (readOnly) return <>{children}</>;

  return (
    <Popover open={isOpen} modal={false}>
      <PopoverAnchor>{children}</PopoverAnchor>

      <PopoverContent className="w-auto p-1" onOpenAutoFocus={e => e.preventDefault()}>
        {isEditing ? (
          <div className="flex w-[330px] flex-col">
            <div className="flex items-center">
              <div className="flex items-center pl-3 text-muted-foreground">
                <Icons.link className="h-4 w-4" />
              </div>

              <FloatingMediaPrimitive.UrlInput
                className={inputVariants({ variant: 'ghost', h: 'sm' })}
                placeholder="Paste the embed link..."
                options={{
                  pluginKey
                }}
              />
            </div>
          </div>
        ) : (
          <div className="box-content flex items-center gap-1">
            <FloatingMediaPrimitive.EditButton
              className={clsx(buttonVariants({ variant: 'ghost', size: 'sm' }), classes.editButton)}
            >
              Edit link
            </FloatingMediaPrimitive.EditButton>

            <Separator orientation="vertical" className="my-1" />

            <Button variant="ghost" size="sms" {...buttonProps}>
              <Icons.delete className="h-4 w-4" />
            </Button>
          </div>
        )}
      </PopoverContent>
    </Popover>
  );
}
