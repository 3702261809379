import { Tune } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import { Box, CircularProgress, Divider, InputAdornment, ListItemText } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent, SelectProps } from '@mui/material/Select';
import FlexContainer from 'components/FlexContainer';
import { InfiniteDropdownIntersectionObserver } from 'components/personality/InfiniteDropdownIntersectionObserver';
import { MissingOption } from 'components/personality/MissingOption';
import { PersonalityWrapper } from 'components/personality/PersonalityWrapper';
import {
  createOption,
  manageOption,
  noPersonalityOption,
  OptionValue
} from 'components/personality/types';
import { mkPersonalityLabel } from 'components/personality/utils/mkPerosnalityLabel';
import { personalityMenuMaxHeight } from 'features/document-top-bar/PersonalityButton';
import FormattedMessage from 'features/i18n/FormattedMessage';
import { useState } from 'react';
import { PersonalityDto } from 'services/backofficeIntegration/http/dtos/PersonalityDto';
import gtmIds from 'services/tracking/GTMIds';
import { withGtmInteraction } from 'services/tracking/withGtmInteraction';
import styled from 'styled-components';
import useTr from 'utils/hooks/useTr';

export type PersonalityId = number | null;

type Props = {
  value: PersonalityDto | undefined | null;
  onChange: (personalityId: PersonalityDto | null | undefined) => void;
  onClick?: () => void;
  preselectedModelId: string;
  withWiggle?: boolean;
  isChatInput?: boolean;
  isPropsLoading: boolean;
  fullWidth?: boolean;
} & Omit<SelectProps, 'value' | 'onChange' | 'onClick'>;

export const PersonalityCommonSelector = ({
  value,
  preselectedModelId,
  isPropsLoading,
  fullWidth = false,
  onChange,
  onClick,
  ...rest
}: Props) => {
  const translate = useTr();

  const [isSelectOpen, setIsSelectOpen] = useState(false);

  const getSelectValue = (e: SelectChangeEvent<unknown>) => {
    return e.target.value as OptionValue;
  };

  return (
    <PersonalityWrapper languageModelId={preselectedModelId} value={value} onChange={onChange}>
      {({
        personalities,
        isLoading,
        currentPersonality,
        handleMenuOptionChange,
        fetchNextPage,
        hasNextPage,
        isFetchingNextPage
      }) => (
        <Select
          value={currentPersonality?.id ?? noPersonalityOption}
          open={isSelectOpen}
          fullWidth={fullWidth}
          onClose={() => setIsSelectOpen(false)}
          onOpen={() => setIsSelectOpen(true)}
          onChange={e => handleMenuOptionChange(getSelectValue(e))}
          onClick={onClick}
          renderValue={value => mkPersonalityLabel(value as number, personalities)}
          placeholder={translate('aiWriter.inspirations.chat.personality_selection_placeholder')}
          startAdornment={
            <InputAdornment
              position="start"
              onClick={() => setIsSelectOpen(prevState => !prevState)}
            >
              {isPropsLoading ||
                (isLoading && (
                  <Box sx={{ display: 'grid' }}>
                    <CircularProgress size={16} />
                  </Box>
                ))}
            </InputAdornment>
          }
          disabled={personalities === undefined}
          MenuProps={{
            style: {
              maxHeight: personalityMenuMaxHeight
            }
          }}
          {...rest}
        >
          <SelectItem
            {...withGtmInteraction(gtmIds.aiWriter.chat.openPersonalitiesLibrary)}
            value={manageOption}
          >
            <Tune />
            <ListItemText>
              <FormattedMessage id="aiWriter.inspirations.chat.personality_selection_manage_option" />
            </ListItemText>
          </SelectItem>
          <SelectItem
            {...withGtmInteraction(gtmIds.aiWriter.chat.openPersonalitiesLibrary)}
            value={createOption}
          >
            <AddIcon />
            <ListItemText>
              <FormattedMessage id="aiWriter.inspirations.chat.personality_selection_create_option" />
            </ListItemText>
          </SelectItem>
          <Divider />
          {personalities?.map(personality => (
            <SelectItem
              {...withGtmInteraction(gtmIds.aiWriter.chat.selectPersonalityViaDropdown)}
              key={personality.id}
              value={personality.id}
            >
              <FlexContainer direction="row">
                <ListItemText>{personality.label}</ListItemText>
              </FlexContainer>
            </SelectItem>
          ))}
          <SelectItem
            {...withGtmInteraction(gtmIds.aiWriter.chat.selectPersonalityViaDropdown)}
            value={noPersonalityOption}
            selected={currentPersonality ? currentPersonality.id === null : false}
          >
            <ListItemText>
              <FormattedMessage id="aiWriter.inspirations.chat.personality_selection_no_personality_option" />
            </ListItemText>
          </SelectItem>
          <MissingOption
            currentModelId={preselectedModelId}
            currentPersonality={currentPersonality}
            personalities={personalities}
          />
          <InfiniteDropdownIntersectionObserver
            onIsInView={() => fetchNextPage()}
            isAlreadyFetching={isFetchingNextPage}
            enabled={hasNextPage}
          />
        </Select>
      )}
    </PersonalityWrapper>
  );
};

const SelectItem = styled(MenuItem)`
  gap: ${({ theme }) => theme.spacings.small};
`;
