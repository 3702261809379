import { getPluginType, PlateEditor, TNode, Value } from '@udecode/plate-common';
import { ELEMENT_FLASH_ACTIONS_INPUT } from 'features/plate/customPlugins/createflashActionsPlugin';
import { TFlashActionsInputElement } from 'features/plate/customPlugins/types';

export const isNodeFlashActionsInput = <V extends Value>(
  editor: PlateEditor<V>,
  node: TNode
): node is TFlashActionsInputElement => {
  return node.type === getPluginType(editor, ELEMENT_FLASH_ACTIONS_INPUT);
};
