import { useModal } from 'components/modals';
import { CustomModalProps } from 'features/aiWriter/commandTemplates/TemplatesModal';

export function useShowTemplatesModal() {
  const { showModal } = useModal();

  return (props: CustomModalProps) => {
    showModal('COMMAND_TEMPLATES', { ...props, size: 1100 });
  };
}
