import FormatSizeIcon from '@mui/icons-material/FormatSize';
import { Tooltip } from '@mui/material';
import { ELEMENT_H1, ELEMENT_H2, ELEMENT_H3 } from '@udecode/plate-heading';
import { ELEMENT_PARAGRAPH } from '@udecode/plate-paragraph';
import FlexContainer from 'components/FlexContainer';
import { MenuDropdown } from 'components/MenuDropdown/MenuDropdown';
import { EditorBlockButton } from 'features/aiWriter/AiWriterTextEditor/EditorBlockButton';
import { EditorIconFormatButton } from 'features/aiWriter/AiWriterTextEditor/EditorIconFormatButton';
import { topBarDropdownAlignmentConfig } from 'features/aiWriter/AiWriterTextEditor/utils/topBarDropdownAlignmentConfig';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import useDropdown from 'utils/hooks/useDropdown';

import { getIconColor } from './utils/getIconColor';

export const EditorTransformButton = () => {
  const { anchorElement, isOpen, open, close } = useDropdown();

  return (
    <MenuDropdown
      trigger={
        <Tooltip
          arrow
          title={<FormattedMessage id="aiWriter.toolbar.turn_into" />}
          placement="bottom"
        >
          <EditorIconFormatButton onClick={open}>
            <FormatSizeIcon color={getIconColor(isOpen)} fontSize="small" />
          </EditorIconFormatButton>
        </Tooltip>
      }
      isOpen={isOpen}
      anchorElement={anchorElement}
      close={close}
      anchorOrigin={topBarDropdownAlignmentConfig.leftAlignment.anchorOrigin}
      transformOrigin={topBarDropdownAlignmentConfig.leftAlignment.transformOrigin}
      width="auto"
    >
      <IconsWrapper gap="small">
        <EditorBlockButton format={ELEMENT_H1} onClose={close} />
        <EditorBlockButton format={ELEMENT_H2} onClose={close} />
        <EditorBlockButton format={ELEMENT_H3} onClose={close} />
        <EditorBlockButton format={ELEMENT_PARAGRAPH} onClose={close} />
      </IconsWrapper>
    </MenuDropdown>
  );
};

const IconsWrapper = styled(FlexContainer)`
  padding: ${({ theme }) => `0 ${theme.spacings.medium}`};
`;
