import { saveChart } from 'utils/svgUtils';

import useTr from '../utils/hooks/useTr';
import Dropdown from './Dropdown/Dropdown';
import { DropdownItemProps } from './Dropdown/DropdownItem';

type Props = {
  filename: string;
  svgElement: Element | string;
  width?: number;
  height?: number;
  className?: string;
  additionalItems?: DropdownItemProps[];
};

const GraphExportDropdown = ({
  filename,
  width,
  height,
  svgElement,
  className,
  additionalItems = [],
  ...props
}: Props) => {
  const tr = useTr();
  const dropdownItems = ['jpg', 'png', 'svg']
    .map<DropdownItemProps>(extension => ({
      value: tr(`exploring.bubble_chart.save_chart_to_${extension}`),
      onClick: () => saveChart({ extension, svgElement, width, height, filename })
    }))
    .concat(additionalItems);

  return (
    <div className={className} {...props}>
      <Dropdown type="icon" icon="save" itemsObject={dropdownItems} />
    </div>
  );
};

export default GraphExportDropdown;
