import { CustomCategories } from 'features/textGenerator/outputTypeModal/CategoriesList';
import { OutputTypeOption } from 'features/textGenerator/outputTypeModal/OutputTypesList';
import { useOptions } from 'features/textGenerator/outputTypeModal/useOptions';

export type AdvancedOutputTypeOption = OutputTypeOption & {
  isFavorite: boolean;
};

export const useAdvancedOptions = (params: Parameters<typeof useOptions>[0]) => {
  const options = useOptions({ ...params });

  const returnedOptions: AdvancedOutputTypeOption[] = options.map(option => ({
    ...option,
    isFavorite: params.outputTypeSuggestionsIds.favorite.includes(option.id)
  }));

  // All text types apart from favorites are sorted alphabetically
  // Favorites should be sorted according to the order of the favorite list
  // But basis of order for `options` is whatever order of `outputTypeOptions` is
  // So, we need to sort `returnedOptions` based on `outputTypeSuggestionsIds.favorite`
  if (params.selectedCategory === CustomCategories.FAVORITES_CATEGORY) {
    return params.outputTypeSuggestionsIds.favorite
      .map(outputTypeId => {
        const optionIndex = returnedOptions.findIndex(option => option.id === outputTypeId);
        if (optionIndex !== -1) {
          return {
            ...returnedOptions[optionIndex],
            isFavorite: true
          };
        }
        return null;
      })
      .filter(
        (value: AdvancedOutputTypeOption | null): value is AdvancedOutputTypeOption =>
          value !== null
      );
  }

  return returnedOptions.sort((a, b) => {
    if (a.isFavorite && !b.isFavorite) {
      return -1;
    } else if (!a.isFavorite && b.isFavorite) {
      return 1;
    } else {
      return a.label.localeCompare(b.label);
    }
  });
};
