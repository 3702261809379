import { useEffect } from 'react';
import { useAppDispatch } from 'store/hooks';

import { checkIfTextLimitIsAchievedThunk } from '../store/actions/texts/thunks/checkIfTextLimitIsAchieved';

export default function useCheckIfTextLimitIsAchieved() {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(checkIfTextLimitIsAchievedThunk());
  }, [dispatch]);
}
