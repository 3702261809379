import { useQuery } from '@tanstack/react-query';
import { useNewNotificationEvent } from 'features/userNotifications/useNewNotificationEvent';
import {
  getUnreadNotifications,
  NotificationListDto
} from 'services/backofficeIntegration/http/endpoints/notifications/getUnreadNotifications';

type UnreadNotificationsEventHandler = (notifications: NotificationListDto) => void;

export function useUnreadNotificationsEvent(handler: UnreadNotificationsEventHandler) {
  const notificationsQuery = useQuery({
    queryKey: getUnreadNotifications.makeQueryKey(),
    queryFn: () => getUnreadNotifications.callEndpoint(),
    onSuccess(notifications) {
      handler(notifications);
    },
    onError() {
      /**
       * For now I see no reasonable way of handling this error. I think
       * that showing another toast about error makes no sense here and
       * may add confusion because there is no user action here. If
       * this fails then user will simply not see his new notification.
       */
    }
  });

  const { refetch: refetchNotifications } = notificationsQuery;

  useNewNotificationEvent(refetchNotifications);
}
