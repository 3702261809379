import { ScoreTextsData } from 'services/api/wordEmbedding/types';
import { sortInOrder } from 'store/utils/dataUtils';
import { WordAttribute } from 'types/ReduxStateTypes';

import { TextScoreDetail, WordAttributesValue, wordAttributesValue } from '../../types';

const mkMapScoreTextData = (
  projectModelId: string | undefined,
  wordAttributesOrder: WordAttribute[],
  dimensionsOrder: number[]
) => {
  return (data: ScoreTextsData) => {
    const mapperAndCommonValues: TextScoreDetail[] = data.goal_score_factors
      .filter(g => wordAttributesValue.includes(g.field as WordAttributesValue))
      .map(({ field, value }) => ({
        field,
        label: null,
        value
      }));

    mapperAndCommonValues.sort(sortInOrder(wordAttributesOrder, 'field', true));

    const dimensionsValues = data.goal_score_factors
      .filter(
        g =>
          !wordAttributesValue.includes(g.field as WordAttributesValue) &&
          g.field !== projectModelId
      )
      .map(({ field, value }) => ({
        field,
        label: data.dimension_scores.find(d => String(d.id) === field)?.name ?? '-',
        value
      }))
      .sort(sortInOrder(dimensionsOrder, 'field'));

    const openRateMapperValues: TextScoreDetail[] = data.goal_score_factors
      .filter(g => g.field === projectModelId)
      .map(({ value }) => ({
        field: 'open_rate',
        label: 'common.open_rate',
        value
      }));

    return [...mapperAndCommonValues, ...dimensionsValues, ...openRateMapperValues];
  };
};

export default mkMapScoreTextData;
