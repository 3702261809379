import { MoreVert } from '@mui/icons-material';
import { MenuDropdown } from 'components/MenuDropdown/MenuDropdown';
import DownloadDataAction from 'features/aiTester/buttons/DownloadDataAction';
import ExtendViewAction from 'features/aiTester/buttons/ExtendViewAction';
import SaveDataAction from 'features/aiTester/buttons/SaveDataAction';
import SortByRankingAction from 'features/aiTester/buttons/SortByRankingAction';
import { getCurrentTabChartData } from 'features/aiTester/store/selectors';
import { StyledIconButton } from 'features/document-top-bar/DocumentPageTopBar';
import React from 'react';
import { useAppSelector } from 'store/hooks';
import useDropdown from 'utils/hooks/useDropdown';

export type CommonAction = {
  onClose: () => void;
};

export const GraphActionsButton = (): React.ReactElement => {
  const { anchorElement, isOpen, open, close } = useDropdown();

  const data = useAppSelector(getCurrentTabChartData);

  return (
    <MenuDropdown
      trigger={
        <StyledIconButton onClick={open}>
          <MoreVert />
        </StyledIconButton>
      }
      isOpen={isOpen}
      anchorElement={anchorElement}
      close={close}
      anchorOrigin={{ vertical: 'center', horizontal: 'left' }}
      transformOrigin={{ vertical: 'center', horizontal: 'right' }}
    >
      <ExtendViewAction onClose={close} />
      <SortByRankingAction onClose={close} />
      {data.length > 0 && <DownloadDataAction isParentMenuOpen={isOpen} onClose={close} />}
      {data.length > 0 && <SaveDataAction isParentMenuOpen={isOpen} onClose={close} />}
    </MenuDropdown>
  );
};
