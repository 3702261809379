import { makeCurrentUserQueryKeyPrefix } from 'features/currentUser/currentUserQueries';
import { RunnerModularWorkflow } from 'features/modular-workflow/runner/types-runner';
import { BaseSuccessDto } from 'services/backofficeIntegration/http/BaseSuccessDto';
import { backofficeClient } from 'services/backofficeIntegration/http/client/createBackofficeClient';
import { queryEndpoint } from 'services/backofficeIntegration/http/endpointDefinition';

type SuccessDto = BaseSuccessDto<RunnerModularWorkflow>;

export const httpGetRunningModularWorkflow = queryEndpoint({
  makeQueryKey: (id: number) => {
    return [...makeCurrentUserQueryKeyPrefix(), 'modular-workflow', 'run', id] as const;
  },
  callEndpoint: (id: number) =>
    backofficeClient.get<SuccessDto>(`/workflow-runs/${id}`).then(response => response.data.data)
});
