import { Switch } from '@mui/material';
import { MenuDropdownItem } from 'components/MenuDropdown/MenuDropdownItem';
import { setSortByScoreThunk } from 'features/aiTester/store/actions/config/thunks/setSortByScoreThunk';
import { getSortByScore } from 'features/aiTester/store/selectors';
import { ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import { useAppDispatch, useAppSelector } from 'store/hooks';

type Props = {
  onClose: () => void;
};

const SortByRankingAction = ({ onClose }: Props): ReactElement => {
  const dispatch = useAppDispatch();

  const sortByScore = useAppSelector(getSortByScore);

  return (
    <MenuDropdownItem
      icon={<Switch checked={sortByScore} color="primary" />}
      onClose={onClose}
      onClick={() => dispatch(setSortByScoreThunk(!sortByScore))}
    >
      <FormattedMessage id="testing.sort_by_score" />
    </MenuDropdownItem>
  );
};

export default SortByRankingAction;
