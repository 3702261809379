import { setIsViewExtended } from 'features/aiTester/store/actions/config/actions';
import {
  removeSuggestion,
  selectSuggestion
} from 'features/aiTester/store/actions/suggestions/actions';
import { SelectSuggestionPayload } from 'features/aiTester/store/actions/suggestions/types';
import { checkIfTextLimitIsAchievedThunk } from 'features/aiTester/store/actions/texts/thunks/checkIfTextLimitIsAchieved';
import { updateTextThunk } from 'features/aiTester/store/actions/texts/thunks/updateTextThunk';
import { AppThunk } from 'store/store';

export const selectSuggestionThunk =
  (payload: SelectSuggestionPayload): AppThunk<void> =>
  async dispatch => {
    dispatch(selectSuggestion(payload));
    dispatch(removeSuggestion({ id: payload.id, triggerSource: 'system' }));
    dispatch(updateTextThunk(payload));
    dispatch(checkIfTextLimitIsAchievedThunk());
    dispatch(setIsViewExtended(true));
  };
