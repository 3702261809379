import { GeneratedText, GenerateTextConfig, ScoredText } from 'features/textGenerator/store/types';
import { BaseDimension, Dimension, ManualDimension } from 'services/api/dimension/types';
import {
  BaseTab,
  Loading,
  OpenRate,
  Tabs,
  WordAttribute,
  WordAttributeWithOption
} from 'types/ReduxStateTypes';

export type TesterState = Tabs<TesterTab> &
  TesterConfig & {
    projects: TesterProject[];
  };

export type TesterTab = Omit<
  TesterProject,
  'texts' | 'manualDimensions' | 'isLoading' | 'suggestions'
> &
  BaseTab & {
    texts: TestingContent[];
    manualDimensions: ManualDimension[];

    isSuggestionsLoading: boolean;
    suggestions: Suggestion[];

    legend: TestingLegend;
    legendOrder: LegendTestingOrder;

    sortByScore: boolean;

    isSaving: boolean;
  };

export type LegendTestingOrder = Array<WordAttribute | OpenRate | number>;

type TesterConfig = Loading & {
  isViewExtended: boolean;
  isTourRunning: boolean;
  isTourFinished: boolean;
};

export type TesterTabSettings = {
  wordAttributes: WordAttributeWithOption[];
  scoreByOpenRate: boolean;
  dimensions: Dimension[];
  manualDimensions: ManualDimension[];
};

export type FieldType = string | number;

export type IntroFormState = {
  prevalence: boolean;
  valence: boolean;
  dimensions: Dimension[];
  manualDimensions: ManualDimension[];
};

/**
 * Status of a checked word:
 * - AVAILABLE: tokenized and available in the model
 * - FAULTY: tokenized, but missing in the model - probably garbage word
 * - STOP_WORD: token has is_stop === true => stop-word
 */

export type TokenizedWordStatus = 'AVAILABLE' | 'FAULTY' | 'STOP_WORD';

export interface TokenizedWord {
  id: string;
  word: string;
  token: string;
  status?: TokenizedWordStatus;
  indexStart: number;
  indexEnd: number;
  synonyms: string[];
  isLoading?: boolean;
}

export interface TokenData {
  id: string | number;
  value: number;
  label: string | null;
}

export interface TestingContent {
  id: string;
  text: string;
  textItemId?: string;
  generationId?: number;
  
  goalScore: number;
  prevalence: number;

  scoreDetails: TextScoreDetail[];
  words: TokenizedWord[];
  tokenData: Record<string, TokenData[]>;
  subSuggestions: ScoredText[];

  isAvailable: boolean;
  isLoading: boolean;
  isEditing: boolean;
}

export interface TestingLegendEntry {
  field: FieldType;
  label: string | null;
  color: string;
  active: boolean;
}

export type TestingLegend = Record<FieldType, TestingLegendEntry>;

export interface TextScoreDetail {
  field: FieldType;
  label: string | null;
  value: number;
}

export type Suggestion = ScoredText & {
  subSuggestions: ScoredText[];
  isLoading?: boolean;
};

export const wordAttributesValue = [
  'model_rank_score',
  'readability',
  'success',
  'seed_score',
  'valence',
  'arousal',
  'dominance'
] as const;

export type WordAttributesValue = typeof wordAttributesValue[number];

export interface TestingChartEntry {
  id: string;
  label: string;
  goalScore: number;
  prevalence: number;
  details: TextScoreDetail[];
}

export type TesterFeature = 'initialization' | 'comparison';

export const outputTypesExtendableByOpenRate = ['subject_lines', 'cta_button', 'headlines'];

export type TesterProject = {
  id: string;
  name: string;
  embeddingModelId: string;
  mapperId: string;
  generateTextConfig?: GenerateTextConfig;
  texts: GeneratedText[];
  suggestions: GeneratedText[];
  scoreByOpenRate: boolean;
  wordAttributes: WordAttributeWithOption[];
  manualDimensions: BaseDimension<string>[];
  dimensions: Dimension[];
  updatedAt: string;
  isLoading: boolean;
};
