import { Menu } from '@mui/material';
import { ReactElement, ReactNode } from 'react';
import styled from 'styled-components';

type Props = {
  anchorElement: HTMLElement | null;
  isOpen: boolean;
  onClose: () => void;
  children: ReactNode;
};

export const FlashScorePopperSuggestionRoot = ({
  anchorElement,
  isOpen,
  onClose,
  children
}: Props): ReactElement => (
  <Menu
    open={isOpen}
    anchorEl={anchorElement}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'left'
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'left'
    }}
    PaperProps={{
      // Mimic same style like the anchor toolbar
      elevation: 4,
      style: {
        borderRadius: 8,
        // Add some space between toolbar and the paper
        marginTop: 5
      }
    }}
    onClose={onClose}
  >
    <Root>
      <Content>{children}</Content>
    </Root>
  </Menu>
);

const Root = styled.div`
  padding: ${({ theme }) => `${theme.spacings.small} ${theme.spacings.medium}`};
  max-height: 400px;
  overflow: auto;
  // Random value
  width: 580px;
`;

const Content = styled.div``;
