import { Done as DoneIcon } from '@mui/icons-material';
import { Box, Chip } from '@mui/material';
import { useOnboardingContext } from 'features/onboardingJourney/newUserSurvey/OnboardingContextProvider';
import {
  SurveyQuestionDto,
  SurveyQuestionOptionDto
} from 'services/backofficeIntegration/http/dtos/SurveyQuestionsDto';
import styled from 'styled-components';

type Props = {
  question: SurveyQuestionDto;
  onChange: (option: SurveyQuestionOptionDto) => void;
};

export const SelectableQuestion = ({ question, onChange }: Props) => {
  const { isAnswerOptionChecked } = useOnboardingContext();

  if (question.slug === 'purpose') {
    return (
      <OverflowingRoot>
        {question.options.map(option => {
          const isActive = isAnswerOptionChecked(question, option);

          return (
            <StyledChip
              key={option.id}
              label={option.option_text}
              color={isActive ? 'info' : 'default'}
              deleteIcon={isActive ? <DoneIcon /> : undefined}
              onClick={() => onChange(option)}
            />
          );
        })}
      </OverflowingRoot>
    );
  }
  return (
    <Root>
      {question.options.map(option => {
        const isActive = isAnswerOptionChecked(question, option);

        return (
          <StyledChip
            key={option.id}
            label={option.option_text}
            color={isActive ? 'info' : 'default'}
            deleteIcon={isActive ? <DoneIcon /> : undefined}
            onClick={() => onChange(option)}
          />
        );
      })}
    </Root>
  );
};

const Root = styled(Box)`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: ${({ theme }) => theme.spacings.two};
  justify-content: center;
`;

const OverflowingRoot = styled(Box)`
  width: 100%;
  display: inline-block;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
`;

const StyledChip = styled(Chip)`
  &&.MuiChip-clickableColorInfo {
    color: ${({ theme }) => theme.colors.primary500};
    background-color: ${({ theme }) => theme.colors.primary50};
  }

  &&.MuiChip-clickableColorDefault {
    color: ${({ theme }) => theme.mode === 'dark' && theme.colors.textPrimary};
    background-color: ${({ theme }) =>
      theme.mode === 'dark' && theme.colors.backgroundPaperElevation2};
  }

  height: 40px;
  border-radius: 100px;
  border: 1px solid #cfd8dc;
  background-color: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.blackHighEmphasis};
  margin-right: 4px;
  min-width: 72px;
  padding: 4px;
`;
