import { Button } from '@mui/material';
import LocalStorageKey from 'config/localStorageKey';
import { useStoredPersonalityId } from 'features/aiWriter/AiWriterSidebar/steps/chat/chatStore';
import { useMessages } from 'features/aiWriter/AiWriterSidebar/steps/chat/useMessages';
import { useResetConversationMutation } from 'features/aiWriter/AiWriterSidebar/steps/chat/useResetConversationMutation';
import FormattedMessage from 'features/i18n/FormattedMessage';
import {
  GPT_MODELS,
  GptModel
} from 'services/backofficeIntegration/http/endpoints/aiWriter/httpCreateConversation';
import gtmIds from 'services/tracking/GTMIds';
import { withGtmInteraction } from 'services/tracking/withGtmInteraction';
import styled from 'styled-components';
import useTr from 'utils/hooks/useTr';
import { withTestId } from 'utils/utils';

export const ChatResetButton = () => {
  const translate = useTr();
  const { messages, isLoading } = useMessages();
  const personalityId = useStoredPersonalityId();
  const { mutateAsync: resetConversation } = useResetConversationMutation();

  const storedGptModel = localStorage.getItem(LocalStorageKey.DefaultGptModel) as GptModel | null;

  const gptModel = storedGptModel ?? GPT_MODELS.GPT_3_5;

  const isDisabled = isLoading || messages.length === 0;

  const handleClick = () => resetConversation({ personalityId, gptModel });

  return (
    <StyledButton
      {...withGtmInteraction(gtmIds.aiWriter.chat.reset)}
      variant="text"
      onClick={handleClick}
      disabled={isDisabled}
      title={translate('aiWriter.inspirations.chat.reset_button')}
      {...withTestId('chat-reset-button')}
    >
      <FormattedMessage id="aiWriter.inspirations.chat.reset_button" />
    </StyledButton>
  );
};

const StyledButton = styled(Button)`
  // With a longer personality name, in german interface, the button text (current "Zurücksetzen")
  // enlarges the sidebar width.
  word-break: break-word;
`;
