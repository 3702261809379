import Toast from 'components/toasts/Toast';
import { getErrorCodeFromAxiosError } from 'features/teamInvitation/getErrorCodeFromAxiosError';
import { BaseSuccessDto } from 'services/backofficeIntegration/http/BaseSuccessDto';
import { backofficeClient } from 'services/backofficeIntegration/http/client/createBackofficeClient';
import { mutationEndpoint } from 'services/backofficeIntegration/http/endpointDefinition';

type CreateSuggestionDto = {
  suggestions: Suggestion[];
};

type SuccessDto = BaseSuccessDto<CreateSuggestionDto>;

export type Suggestion = {
  id: null;
  text_item_id: number;
  generation_id: number;
  conversation_id: number | null;
  text: string;
  created_at: string | null;
  updated_at: string | null;
};

export type CreateSuggesionParams = {
  keywords: string;
  keywords2: string;
  output_type: 'smart_suggestions';
  audience_model_id: string;
  n_times: 1;
  n_text_items: 2;
  personality: string;
  projectId: string;
  conversationId: string;
};

export const httpCreateSuggestions = mutationEndpoint({
  callEndpoint: (props: CreateSuggesionParams) => {
    const {
      keywords,
      keywords2,
      output_type,
      audience_model_id,
      n_text_items,
      n_times,
      personality,
      projectId,
      conversationId
    } = props;
    return backofficeClient
      .post<SuccessDto>(
        `/projects/ai-writer/${projectId}/conversations/${conversationId}/suggestions`,
        {
          keywords: keywords,
          keywords2: keywords2,
          output_type: output_type,
          audience_model_id: audience_model_id ?? '',
          n_text_items: n_text_items,
          n_times: n_times,
          personality: personality ?? ''
        }
      )
      .then(response => {
        return response.data.data;
      })
      .catch(error => {
        const errorCode = getErrorCodeFromAxiosError(error);
        if (errorCode) {
          Toast.error(`aiWriter.chat.error.${errorCode}`);
        }
      });
  }
});
