import { Card, Typography } from '@mui/material';
import FlexContainer from 'components/FlexContainer';
import { getRoutePath } from 'config/routes';
import FormattedMessage from 'features/i18n/FormattedMessage';
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const AiWriterPreviewWrongHash = (): React.ReactElement => {
  return (
    <Root>
      <Card>
        <Content gap="xmedium">
          <Title variant="h6">
            <FormattedMessage id="aiWriter.share.preview.wrong_hash.title" />
          </Title>
          <Description variant="body1">
            <FormattedMessage id="aiWriter.share.preview.wrong_hash.description" />
            <LoginLink to={getRoutePath('login')}>
              <FormattedMessage id="aiWriter.share.preview.wrong_hash.link" />
            </LoginLink>
          </Description>
        </Content>
      </Card>
    </Root>
  );
};

const Root = styled.div`
  margin: ${({ theme }) => theme.spacings.xxxxlarge} auto 0 auto;
`;

const Content = styled(FlexContainer)`
  align-items: center;

  padding: ${({ theme }) => theme.spacings.large};
`;

const Title = styled(Typography)`
  && {
    color: ${({ theme }) => theme.colors.primary};
  }
`;

const Description = styled(Typography)`
  && {
    color: ${({ theme }) => theme.colors.blackMediumEmphasis};
  }
`;

const LoginLink = styled(Link)`
  && {
    color: ${({ theme }) => theme.colors.blackMediumEmphasis};
  }
`;
