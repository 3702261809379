import { CircularProgress, Typography } from '@mui/material';
import { Container } from 'components/Container';
import FlexContainer from 'components/FlexContainer';
import React, { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import useTr from 'utils/hooks/useTr';

type Props = {
  title: string;
  isLoading?: boolean;
  autocomplete?: ReactNode;
  tutorialButton?: ReactNode;
  children?: ReactNode;
};

const ProjectOverviewOld = ({
  title,
  isLoading,
  autocomplete,
  tutorialButton,
  children
}: Props): React.ReactElement => {
  const tr = useTr();

  return (
    <>
      {tutorialButton}

      <Root>
        <Content gap="six" alignItems="center" justifyContent="center">
          <Typography variant="h4" fontWeight={400}>
            <FormattedMessage id={title} values={{ b: (chunks: string) => <b>{chunks}</b> }} />
          </Typography>

          <Body>
            <SectionHeader direction="row" alignItems="center">
              <Typography variant="subtitle2">
                {tr('testing.project_overview.documents')}
              </Typography>
              {autocomplete && <AutocompleteFieldWrapper>{autocomplete}</AutocompleteFieldWrapper>}
            </SectionHeader>
            {isLoading ? (
              <LoaderBox>
                <CircularProgress />
              </LoaderBox>
            ) : (
              <ProjectsGrid>{children}</ProjectsGrid>
            )}
          </Body>
        </Content>
      </Root>
    </>
  );
};

const Root = styled(Container).attrs({ size: 'lg' })`
  width: 100%;
`;

const Content = styled(FlexContainer)`
  margin: ${({ theme }) => `${theme.spacings.xlarge} auto`};
`;

const ProjectsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: ${({ theme }) => theme.spacings.four};
  row-gap: ${({ theme }) => theme.spacings.four};
`;

const AutocompleteFieldWrapper = styled.div`
  margin-left: auto;
  min-width: 200px;
`;

const SectionHeader = styled(FlexContainer)`
  width: 100%;
  margin: 0 auto;
`;

const LoaderBox = styled.div`
  margin: auto;
`;

const Body = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.three};
`;

export default ProjectOverviewOld;
