import { useQuery } from '@tanstack/react-query';
import { getCustomerRole } from 'features/customer/store/selectors';
import { CustomerRole } from 'features/customer/store/types';
import useSubscriptionState from 'features/pricing/hook/useSubscriptionState';
import { PricingPlan } from 'features/pricing/types';
import { httpGetMembersCount } from 'services/backofficeIntegration/http/endpoints/teamMembers/httpGetMembersCount';
import { useAppSelector } from 'store/hooks';

export const useCanDeleteAccount = () => {
  const customerRole = useAppSelector(getCustomerRole);
  const { currentPricingPlan } = useSubscriptionState();

  const { data, isLoading } = useQuery({
    queryKey: httpGetMembersCount.makeQueryKey(),
    queryFn: () => httpGetMembersCount.callEndpoint()
  });

  const totalUsers = data?.members_count || 0;

  const isTeamOwner = customerRole === CustomerRole.OWNER;

  const isProPlan = currentPricingPlan?.type === PricingPlan.pro;
  const isEnterprisePlan = currentPricingPlan?.type === PricingPlan.enterprise;

  const hasAppropriatePlan = isEnterprisePlan || isProPlan;

  if (!isTeamOwner || !hasAppropriatePlan) {
    return { isLoading, canDeleteAccount: true };
  }

  // The count doesn't include the owner, so we need to check if there is only one user
  const canDeleteAccount = totalUsers < 1;

  return { isLoading, canDeleteAccount };
};
