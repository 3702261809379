import { getTesterActiveTab } from 'features/aiTester/store/selectors';
import { getTokensFromText } from 'features/aiTester/utils/getTokensFromText';
import { getEmbeddingModelDataById } from 'features/embeddingModels/store/selectors';
import { AppThunk } from 'store/store';

import { updateText } from '../actions';
import { UpdateTextRequestPayload } from '../types';
import { scoreSingleTextThunk } from './scoreSingleTextThunk';

export const updateTextThunk =
  (payload: UpdateTextRequestPayload): AppThunk<void> =>
  async (dispatch, getState) => {
    dispatch(updateText.request(payload));

    const state = getState();
    const { id: tabId, embeddingModelId } = getTesterActiveTab(state);

    const words = await getTokensFromText(
      payload.text,
      getEmbeddingModelDataById(embeddingModelId, state)
    );

    if (words.length > 0) {
      dispatch(
        updateText.success({
          tabId,
          id: payload.id,
          text: payload.text,
          textItemId: payload.textItemId,
          generationId: payload.generationId,
          words
        })
      );
      dispatch(scoreSingleTextThunk({ id: payload.id }));
    } else {
      dispatch(updateText.failure({ tabId, id: payload.id }));
    }
  };
