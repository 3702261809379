import { Alert } from '@mui/material';
import { AuthCenteredPanel } from 'components/auth/AuthCenteredPanel';
import FlexContainer from 'components/FlexContainer';
import { ResendVerificationEmailButton } from 'components/register/ResendVerificationEmailButton';
import { RegisterSuccessCard } from 'features/authentication/RegisterSuccessCard';
import { resendVerificationEmail } from 'features/authentication/resendVerificationEmail';
import { FormattedMessage } from 'react-intl';
import { useAppSelector } from 'store/hooks';

type Props = {
  email: string;
  password: string;
};

export const RegistrationSuccess = ({ email }: Props) => {
  const error = useAppSelector(state => state.customer.error);

  return (
    <AuthCenteredPanel slogan="register.success.slogan">
      <FlexContainer gap="large" alignItems="center">
        <RegisterSuccessCard
          email={email}
          resendButton={
            <RegisterSuccessCard.ResendButton onClick={() => resendVerificationEmail(email)} />
          }
        />
        {error && (
          <Alert severity="error">
            <FormattedMessage
              id={error}
              values={{
                a: (message: string) => (
                  <ResendVerificationEmailButton email={email}>
                    {message}
                  </ResendVerificationEmailButton>
                )
              }}
            />
          </Alert>
        )}
      </FlexContainer>
    </AuthCenteredPanel>
  );
};
