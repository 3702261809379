import { isAxiosError } from 'axios';
import {
  RunnerModularWorkflow,
  RunnerModularWorkflowStep
} from 'features/modular-workflow/runner/types-runner';
import { ApiResponseCommon, ApiResponseSuccess } from 'services/api/types';
import { backofficeClient } from 'services/backofficeIntegration/http/client/createBackofficeClient';
import { mutationEndpoint } from 'services/backofficeIntegration/http/endpointDefinition';

export type UpdateRunningModularWorkflowStepsApiResponseError = ApiResponseCommon & {
  status: false;
  data?: Record<string, string>;
};

type ResponseDto =
  | ApiResponseSuccess<RunnerModularWorkflow>
  | UpdateRunningModularWorkflowStepsApiResponseError;

export type UpdateRunningModularWorkflowStepsParams = {
  workflowRunId: number;
  steps: RunnerModularWorkflowStep[];
};

export const httpUpdateRunningModularWorkflowSteps = mutationEndpoint({
  callEndpoint: ({ workflowRunId, steps }: UpdateRunningModularWorkflowStepsParams) => {
    return backofficeClient
      .put<ResponseDto>(`/workflow-runs/${workflowRunId}/steps`, {
        steps
      })
      .then(response => response.data)
      .catch(error => {
        if (isAxiosError<UpdateRunningModularWorkflowStepsApiResponseError>(error)) {
          return error.response?.data;
        }

        throw error;
      });
  }
});
