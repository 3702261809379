import useEditorText from 'features/aiWriter/AiWriterTextEditor/hooks/useEditorText';
import {
  useFlashActionsSelectedText,
  useFlashActionsSelection
} from 'features/aiWriter/slashCommands/hooks/useFlashActionsStore';

export function useGetEditorsNodeState() {
  const editorText = useEditorText();
  const isEditorEmpty = editorText.length === 0;

  const flashActionsSelection = useFlashActionsSelection();
  const flashActionsSelectedText = useFlashActionsSelectedText();

  const isCurrentNodeEmpty = flashActionsSelection
    ? flashActionsSelectedText.trim().length === 0
    : true;

  const isCurrentNodeEmptyAndDocumentFilled = isCurrentNodeEmpty && !isEditorEmpty;

  return {
    isEditorEmpty,
    isCurrentNodeEmptyAndDocumentFilled
  };
}
