import { useRef } from 'react';

type ResultBox<T> = { value: T };

const useConstant = <T>(initializer: () => T): T => {
  const ref = useRef<ResultBox<T>>();

  if (!ref.current) {
    ref.current = { value: initializer() };
  }

  return ref.current.value;
};

export default useConstant;
