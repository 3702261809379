import { Typography } from '@mui/material';
import DeleteIconButton from 'components/iconButtons/DeleteIconButton';
import { CloseModal } from 'components/modals/types';
import { LearningCenter } from 'features/learningCenter/LearningCenter';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import useTr from 'utils/hooks/useTr';

type Props = CloseModal;

export const HelpAndInfoModal = ({ closeModal }: Props) => {
  const translate = useTr();

  return (
    <ModalRoot>
      <ModalTitle>
        <Typography variant="h5">
          <FormattedMessage id="layout.sidebar.actions.help.header" />
        </Typography>
        <DeleteIconButton onClick={closeModal} tooltip={translate('common.close')} />
      </ModalTitle>
      <Separator />

      <LearningCenter onClose={closeModal} />
    </ModalRoot>
  );
};

const ModalRoot = styled.div`
  height: 800px;
`;

const ModalTitle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: ${({ theme }) =>
    `${theme.spacings.xmedium} ${theme.spacings.xmedium} ${theme.spacings.medium} ${theme.spacings.xmedium}`};
`;

const Separator = styled.div`
  display: flex;
  border-bottom: 0.5px solid ${({ theme }) => theme.colors.aiWriterSidebarBorderColor};
  width: 1100px;
`;
