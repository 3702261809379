import { AppRoutes } from 'config/routes';
import { useEnableSidebarOnMount } from 'features/pageSidebar/useSidebarStore';
import Campaigns from 'features/subjectLines/Campaigns';
import EditCampaignConfigForm from 'features/subjectLines/EditCampaignConfigForm';
import NewCampaignConfigForm from 'features/subjectLines/NewCampaignConfigForm';
import { Route } from 'react-router-dom';
import styled from 'styled-components';

const SubjectLines = () => {
  useEnableSidebarOnMount();

  return (
    <SubjectLinesWrapper>
      <SubjectLinesContent>
        <AppRoutes>
          <Route path="/" element={<Campaigns />} />
          <Route path="new" element={<NewCampaignConfigForm />} />
          <Route path=":id" element={<EditCampaignConfigForm />} />
        </AppRoutes>
      </SubjectLinesContent>
    </SubjectLinesWrapper>
  );
};

const SubjectLinesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const SubjectLinesContent = styled.div`
  display: flex;
  padding-bottom: 1rem;
  flex: 1 1 auto;
`;

export default SubjectLines;
