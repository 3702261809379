export const isAutomaticPageTranslationActive = (): boolean => {
  // Detection picked from several answers in
  // https://stackoverflow.com/questions/4887156/detecting-google-chrome-translation

  const generalTranslatorToolFound =
    document.querySelector(
      [
        'html.translated-ltr',
        'head.translated-ltr',
        'html.translated-rtl',
        'head.translated-rtl',
        'ya-tr-span',
        '*[_msttexthash]',
        'meta[http-equiv="X-Translated-To"]',
        'meta[http-equiv="X-Translated-By"]'
      ].join(', ')
    ) !== null;

  if (generalTranslatorToolFound) {
    return true;
  }

  return false;
};
