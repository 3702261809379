import { useQuery } from '@tanstack/react-query';
import {
  GetInvitationInfoParams,
  httpGetTeamInvitationInfo
} from 'services/backofficeIntegration/http/endpoints/teamMembers/httpGetTeamInvitationInfo';

export const useTeamInvitationInfo = (params: GetInvitationInfoParams) =>
  useQuery({
    queryKey: httpGetTeamInvitationInfo.makeQueryKey(params),
    queryFn: () => httpGetTeamInvitationInfo.callEndpoint(params)
  });
