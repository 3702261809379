import { FormControlLabel, Switch } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { ReactComponent as SettingsIcon } from 'assets/icons/icon-cog.svg';
import FlexContainer from 'components/FlexContainer';
import { useModal } from 'components/modals';
import { getRoutePath } from 'config/routes';
import { unnamed } from 'features/aiWriter/utils/unnamed';
import { DocumentPageTopBar } from 'features/document-top-bar/DocumentPageTopBar';
import FormattedMessage from 'features/i18n/FormattedMessage';
import { useCreateWorkflowMutation } from 'features/modular-workflow/builder/useCreateWorkflowMutation';
import { useModulareWorkflowCreationStore } from 'features/modular-workflow/builder/useModulareWorkflowCreationStore';
import { useUpdateWorkflowMutation } from 'features/modular-workflow/builder/useUpdateWorkflowMutation';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

const useShowSettingsModal = () => {
  const { showModal } = useModal();

  return () =>
    showModal({
      modalType: 'MODULAR_WORKFLOW_SETTINGS'
    });
};

export const ModularWorkflowCreationPageTopBar = () => {
  const navigate = useNavigate();
  const workflow = useModulareWorkflowCreationStore(state => state.workflow);
  const isSaving = useModulareWorkflowCreationStore(state => state.isSaving);
  const creationStep = useModulareWorkflowCreationStore(state => state.currentView);
  const currentLanguage = useModulareWorkflowCreationStore(state => state.currentLanguage);
  const updateWorkflow = useModulareWorkflowCreationStore(state => state.updateWorkflow);
  const showSettingsModal = useShowSettingsModal();

  const { mutateAsync: updateWorkflowMutation, isLoading: isUpdatingWorkflow } =
    useUpdateWorkflowMutation();

  const { mutateAsync: createWorkflowMutation, isLoading: isCreatingWorkflow } =
    useCreateWorkflowMutation();

  const isMutatingWorkflow = isUpdatingWorkflow || isCreatingWorkflow;

  const handleWorkflowEnabledChange = async (enabled: boolean) => {
    const updatedWorkflow = updateWorkflow({ is_enabled: enabled });

    if (updatedWorkflow.id === null) {
      await createWorkflowMutation(updatedWorkflow);
      return;
    }

    await updateWorkflowMutation(updatedWorkflow);
  };

  return (
    <DocumentPageTopBar
      activeDocumentId={workflow.id}
      documents={[
        {
          ...workflow,
          name: workflow.name_localized[currentLanguage]
        }
      ]}
      isSaving={isSaving}
      placeholderName={unnamed}
      withSaveIndicator={false}
      withNameChange={false}
      withLanguageChange={false}
      withUsageIndicator={false}
      onBack={() => navigate(getRoutePath('brandHubOverviewWorkflows'))}
      onRename={value => updateWorkflow({ name: value })}
      renderName={name => (workflow.name_localized[currentLanguage].length > 0 ? name : unnamed)}
    >
      {creationStep !== 'setup' && (
        <FlexContainer gap="medium" direction="row">
          <FormControlLabel
            control={
              <Switch
                size="small"
                checked={workflow.is_enabled}
                disabled={workflow.id === null || isMutatingWorkflow}
              />
            }
            label={<FormattedMessage id="modular_workflow.builder.top_bar.enable" />}
            labelPlacement="start"
            onChange={(_, checked) => handleWorkflowEnabledChange(checked)}
          />

          <IconButton onClick={showSettingsModal} size="small">
            <StyledSettingsIcon />
          </IconButton>
        </FlexContainer>
      )}
    </DocumentPageTopBar>
  );
};

const StyledSettingsIcon = styled(SettingsIcon)`
  color: ${({ theme }) => theme.colors.componentsIconDefault};
`;
