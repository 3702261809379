import { Typography } from '@mui/material';
import getHistoryLimit from 'features/aiWriter/AiWriterSidebar/steps/history/getHistoryLimit';
import FormattedMessage from 'features/i18n/FormattedMessage';
import { getPricingPlan } from 'features/pricing/store/selectors';
import useUpdateSubscriptionModal from 'features/updateSubscriptionModal/hook/useUpdateSubscriptionModal';
import { useAppSelector } from 'store/hooks';
import styled from 'styled-components';

export const lowerHistoryLimit = '10';
export const higherHistoryLimit = '100';

const HistoryPlaceholder = () => {
  const pricingPlan = useAppSelector(getPricingPlan);
  const showUpgradeSubscriptionModal = useUpdateSubscriptionModal();

  const showPremiumHint = ['free', 'basic'].includes(pricingPlan);

  return (
    <Root variant="body1">
      <FormattedMessage
        id="aiWriter.inspirations.history.placeholder.title"
        values={{ b: (text: string) => <strong>{text}</strong> }}
      />

      <FormattedMessage
        id="aiWriter.inspirations.history.placeholder.subtitle"
        values={{ x: getHistoryLimit(pricingPlan) }}
      />

      {showPremiumHint && (
        <div>
          <FormattedMessage
            id="aiWriter.inspirations.history.placeholder.basic_body"
            values={{
              x: getHistoryLimit('premium'),
              b: (text: string) => <strong>{text}</strong>,
              button: (text: string) => (
                <UpgradeButton onClick={showUpgradeSubscriptionModal}>{text}</UpgradeButton>
              )
            }}
          />
        </div>
      )}
    </Root>
  );
};

const Root = styled(Typography)`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.medium};
  text-align: center;
  padding-top: ${({ theme }) => theme.spacings.xlarge};
  color: ${({ theme }) => theme.colors.blackInactive};
`;

const UpgradeButton = styled.button`
  background: none;
  text-decoration: underline;
  border: none;
  padding: 0;
  color: ${({ theme }) => theme.colors.primary};
`;

export default HistoryPlaceholder;
