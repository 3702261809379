import {
  autoformatArrow,
  autoformatLegal,
  autoformatLegalHtml,
  autoformatMath,
  autoformatPunctuation,
  autoformatSmartQuotes
} from '@udecode/plate-autoformat';
import { autoformatBlocks } from 'features/plate/lib/plate/autoformatBlocks';
import { autoformatIndentLists } from 'features/plate/lib/plate/autoformatIndentLists';
import { autoformatMarks } from 'features/plate/lib/plate/autoformatMarks';
import { MyAutoformatRule } from 'features/plate/lib/plate/plate-types';

export const autoformatRules = [
  ...autoformatBlocks,
  ...autoformatIndentLists,
  ...autoformatMarks,
  ...(autoformatSmartQuotes as MyAutoformatRule[]),
  ...(autoformatPunctuation as MyAutoformatRule[]),
  ...(autoformatLegal as MyAutoformatRule[]),
  ...(autoformatLegalHtml as MyAutoformatRule[]),
  ...(autoformatArrow as MyAutoformatRule[]),
  ...(autoformatMath as MyAutoformatRule[])
];
