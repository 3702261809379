import cx from 'classnames';
import { FormattedMessage } from 'react-intl';
import styled, { css } from 'styled-components';

import EmptyButton from '../../base/EmptyButton';
import Icon from '../../base/Icon';
import Tooltip from '../../tooltips/Tooltip';
import styles from './ToolbarDangle.module.scss';

type Props = {
  opened: boolean;
  active: boolean;
  onClick: () => void;
  unseenItems: number;
  icon: string;
  tooltip: string;
  order: number;
  isLoading?: boolean;
};

const ToolbarDangle = ({
  opened,
  onClick,
  unseenItems,
  icon,
  tooltip,
  active,
  order,
  isLoading
}: Props) => (
  <Tooltip
    content={<FormattedMessage id={active ? `${tooltip}_hide` : `${tooltip}_show`} />}
    offset={opened ? (active ? [0, 10] : [0, 26]) : [0, 30]}
    placement="left"
  >
    <EmptyButton
      className={cx(styles.dangle, { [styles.opened]: opened, [styles.active]: active })}
      onClick={onClick}
      style={{ bottom: order * 64 }}
    >
      <InnerContainer className={styles.arrowContainer}>
        <Icon className={styles.icon} name={icon} />
        {unseenItems ? (
          <div className="d-flex position-relative">
            <i className={styles.notification}>
              <span>{unseenItems}</span>
            </i>
          </div>
        ) : null}
        {isLoading && !opened ? (
          <Icon
            name="loader"
            css={css`
              position: absolute;
              font-size: 0.875rem;
              bottom: 0.25rem;
              right: 2.125rem;
              animation: loading 1000ms linear infinite;
              color: #cd201f;
            `}
          />
        ) : null}
      </InnerContainer>
    </EmptyButton>
  </Tooltip>
);

const InnerContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.backgroundDefault};
`;

export default ToolbarDangle;
