import { Chip, Typography } from '@mui/material';
import FlexContainer from 'components/FlexContainer';
import FormattedMessage from 'features/i18n/FormattedMessage';
import { BuilderModularWorkflowStepOutput } from 'features/modular-workflow/builder/types-builder';

type Props = {
  outputs: BuilderModularWorkflowStepOutput[];
  onPlaceholderClick: (value: string) => void;
};

export const PlaceholderList = ({ outputs, onPlaceholderClick }: Props) => {
  const handleClick = (output: BuilderModularWorkflowStepOutput) => {
    onPlaceholderClick(output.alias);
  };

  if (outputs.length === 0) {
    return null;
  }

  return (
    <>
      <Typography variant="caption">
        <FormattedMessage id="modular_workflow.builder.common.available_inputs" />
      </Typography>

      <FlexContainer direction="row" gap="medium" flexWrap="wrap">
        {outputs.map(output => (
          <Chip key={output.alias} label={output.alias} onClick={() => handleClick(output)} />
        ))}
      </FlexContainer>
    </>
  );
};
