import { createAction } from '@reduxjs/toolkit';
import { createAsyncAction } from 'utils/reduxUtils';

import { TesterProject } from '../../types';
import {
  DeleteProjectPayload,
  UpdateCurrentProjectInBackgroundPayload,
  UpdateCurrentProjectInBackgroundSuccessPayload
} from './types';

export const updateCurrentProjectInBackground = createAsyncAction<
  UpdateCurrentProjectInBackgroundPayload,
  UpdateCurrentProjectInBackgroundSuccessPayload,
  UpdateCurrentProjectInBackgroundPayload
>('tester/updateCurrentProjectInBackground');

export const deleteProject = createAsyncAction<
  DeleteProjectPayload,
  DeleteProjectPayload,
  DeleteProjectPayload
>('aiWriter/deleteProject');

export const updateProjects = createAction<TesterProject[]>('tester/updateProjects');

export const insertProject = createAction<TesterProject>('tester/insertProject');
