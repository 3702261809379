import { AutoformatPlugin } from '@udecode/plate-autoformat';
import { PlatePlugin } from '@udecode/plate-common';
import { autoformatRules } from 'features/plate/lib/plate/autoformatRules';

export const autoformatPlugin: Partial<PlatePlugin<AutoformatPlugin>> = {
  options: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    rules: autoformatRules as any,
    enableUndoOnDelete: true
  }
};
