import { createAction } from '@reduxjs/toolkit';
import { createAsyncAction } from 'utils/reduxUtils';

import { TesterTab } from '../../types';

export const setCurrentTab = createAction<string>('tester/setCurrentTab');
export const renameCurrentTab = createAction<string>('tester/renameCurrentTab');
export const closeTab = createAction<string>('tester/closeTab');
export const loadTab = createAsyncAction<void, TesterTab>('tester/loadTab');
export const initializeTab = createAction<TesterTab>('tester/initializeTab');
