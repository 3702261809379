import { CircularProgress, TextField } from '@mui/material';
import React from 'react';
import styled from 'styled-components';
import useTr from 'utils/hooks/useTr';

type Props = {
  value?: string;
  error?: string;
  placeholder?: string;
  isLoading?: boolean;

  onEnterPress?: () => void;
  onBlur?: () => void;
  onChange: (value: string) => void;
};

const SubjectLineInput = ({
  value,
  isLoading,
  error,
  placeholder,

  onEnterPress,
  onBlur,
  onChange
}: Props) => {
  const tr = useTr();

  const onKeyPress = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      onEnterPress?.();
    }
  };

  return (
    <InputContainer>
      {isLoading ? <CircularProgress size={24} /> : null}
      <TextField
        value={value}
        error={!!error}
        disabled={isLoading}
        placeholder={placeholder}
        helperText={error ?? tr('subject_lines.campaign_config.confirm_entry')}
        fullWidth
        onBlur={onBlur}
        onChange={e => onChange(e.target.value)}
        onKeyPress={onKeyPress}
      />
    </InputContainer>
  );
};

const InputContainer = styled.div`
  display: flex;
  flex-grow: 2;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
`;

export default SubjectLineInput;
