const sendReferralEmail = (email: string) => {
  if (window.fpr) {
    window.fpr('referral', { email });
  }
};

const firstPromoterClient = {
  sendReferralEmail
};

export default firstPromoterClient;
