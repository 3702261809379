import { Favorite, FavoriteBorder } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import {
  Button,
  CircularProgress,
  Divider,
  LinearProgress,
  ListItemIcon,
  Menu,
  MenuItem,
  Tooltip,
  Typography
} from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import FlexContainer from 'components/FlexContainer';
import useModal from 'components/modals/useModal';
import {
  ExecuteHint,
  FlashTitle
} from 'features/aiWriter/AiWriterSidebar/steps/chat/FlashActionsMenu';
import {
  flashActionsCategoryAlias,
  useTemplateCategoriesQuery
} from 'features/aiWriter/commandTemplates/useTemplateCategoriesQuery';
import { useTemplatesQuery } from 'features/aiWriter/commandTemplates/useTemplatesQuery';
import { setFlashActionsSelectedAction } from 'features/aiWriter/slashCommands/hooks/useFlashActionsStore';
import FormattedMessage from 'features/i18n/FormattedMessage';
import {
  useShouldShowWordsLimitReachedModal,
  useShowWordsLimitReachedModal
} from 'features/pricing/hook/useYouRunOutOfWordsModal';
import { SyntheticEvent } from 'react';
import { httpFavoriteTemplate } from 'services/backofficeIntegration/http/endpoints/textGeneration/commandTemplates/favorities/httpFavoriteTemplate';
import { httpUnfavoriteTemplate } from 'services/backofficeIntegration/http/endpoints/textGeneration/commandTemplates/favorities/httpUnfavoriteTemplate';
import {
  SortingTypes,
  TemplateDto
} from 'services/backofficeIntegration/http/endpoints/textGeneration/commandTemplates/httpGetCommandTemplates';
import { GAEvents } from 'services/tracking/GAEvents';
import gtmIds from 'services/tracking/GTMIds';
import { withGtmInteraction } from 'services/tracking/withGtmInteraction';
import styled from 'styled-components';

type Props = {
  anchorElement: null | HTMLElement;
  isOpen: boolean;
  close: () => void;

  onSelect: () => void;
};

export function CustomFlashActionsSubMenu(props: Props) {
  const { showModal } = useModal();

  const { shouldShowRunOutOfWordsModal } = useShouldShowWordsLimitReachedModal();
  const showWordsLimitReachedModal = useShowWordsLimitReachedModal();

  const { data: categories } = useTemplateCategoriesQuery();
  const flashActionsId = categories?.filter(
    category => category.alias === flashActionsCategoryAlias
  )[0].id;
  const flashActions = useTemplatesQuery({
    category: flashActionsId,
    sorting: SortingTypes.FAVORITES
  });

  const { mutate: favoriteTemplate, isLoading: isFavoriteLoading } = useMutation({
    mutationFn: httpFavoriteTemplate.callEndpoint,
    onSuccess: () => refetch()
  });
  const { mutate: unfavoriteTemplate, isLoading: isUnfavoriteLoading } = useMutation({
    mutationFn: httpUnfavoriteTemplate.callEndpoint,
    onSuccess: () => refetch()
  });

  const refetch = () => {
    flashActions.refetch();
  };

  const isFavoriteActionLoading = isFavoriteLoading || isUnfavoriteLoading;

  const handleCustomActionClick = (e: SyntheticEvent, prompt: string) => {
    e.stopPropagation();
    GAEvents.flashActionInEditorExecuted({
      action: 'custom',
      prompt
    });

    if (shouldShowRunOutOfWordsModal()) {
      showWordsLimitReachedModal();
      return;
    }

    props.close();

    setFlashActionsSelectedAction({
      name: 'customActions',
      prePrompt: prompt
    });
    props.onSelect();
  };

  function handleCheckboxChange(checked: number, template: TemplateDto) {
    if (checked) {
      unfavoriteTemplate({ templateId: template.id });
      return;
    }

    favoriteTemplate({ templateId: template.id });
  }

  const handleCreateNewAction = () => {
    props.close();
    showModal('FLASH_ACTIONS', {
      modalMode: 'create',
      categoryId: flashActionsId,
      size: 600
    });
  };

  const renderItems = () => {
    if (!flashActions.data) {
      return null;
    }

    const favoritesList = flashActions.data.pages.map(page => page.data).flat(1);

    if (favoritesList.length === 0) {
      return (
        <ExecuteHint>
          <Typography variant="body2">
            <FormattedMessage id="aiWriter.flash_actions.search.no_results.label" />
          </Typography>
        </ExecuteHint>
      );
    }

    return favoritesList?.map(action => (
      <MenuItem
        // we need to provide unique key for each item to allow react to identify properly
        // which elements have changed and re-render only them
        key={Math.random()}
        onClick={e => handleCustomActionClick(e, action.template)}
        disabled={isFavoriteActionLoading || flashActions.isFetching}
      >
        <ListItemIcon
          onClick={e => {
            e.stopPropagation();
            handleCheckboxChange(action.is_liked, action);
          }}
        >
          {action.is_liked ? (
            <Tooltip title={<FormattedMessage id="aiWriter.inspirations.suggestions.unfavorite" />}>
              <Favorite fontSize="small" />
            </Tooltip>
          ) : (
            <Tooltip title={<FormattedMessage id="aiWriter.inspirations.suggestions.favorite" />}>
              <FavoriteBorder fontSize="small" />
            </Tooltip>
          )}
        </ListItemIcon>
        <FlexContainer
          justifyContent="space-between"
          direction="row"
          alignItems="center"
          style={{ width: '100%' }}
        >
          <FlashTitle>{action.title}</FlashTitle>
        </FlexContainer>
      </MenuItem>
    ));
  };

  function renderCustomFlashActionsQueryControls() {
    if (flashActions.isFetching && flashActions.data) {
      return (
        <FlexContainer>
          <LinearProgress />
        </FlexContainer>
      );
    }

    if (flashActions.hasNextPage) {
      return (
        <Button
          fullWidth
          onClick={() => {
            flashActions.fetchNextPage();
          }}
        >
          <FormattedMessage id="common.moreButton" />
        </Button>
      );
    }
  }

  return (
    <Menu
      anchorEl={props.anchorElement}
      open={props.isOpen}
      onClose={props.close}
      anchorOrigin={{
        vertical: 'center',
        horizontal: 'right'
      }}
      transformOrigin={{
        vertical: 'center',
        horizontal: 'left'
      }}
    >
      {flashActions.isLoading && (
        <FlexContainer alignItems="center">
          <CircularProgress size={24} />
        </FlexContainer>
      )}
      {renderItems()}
      {renderCustomFlashActionsQueryControls()}
      <Divider />
      <MenuItem
        {...withGtmInteraction(gtmIds.aiWriter.editor.selectionToolbar.createFlashAction)}
        onClick={handleCreateNewAction}
      >
        <ListItemIcon>
          <AddIcon />
        </ListItemIcon>
        <FlashTitle>
          <FormattedMessage id="common.new" />
        </FlashTitle>
      </MenuItem>
    </Menu>
  );
}

export const StyledMenuItem = styled(MenuItem)<{ $showHover?: boolean }>`
  background-color: ${({ $showHover }) => ($showHover ? 'rgba(0, 0, 0, 0.04)' : 'transparent')};
`;
