import { css } from 'styled-components';

export const card = css`
  border: ${({ theme }) => `1px solid ${theme.colors.divider}`};
  border-radius: ${({ theme }) => theme.borderRadius.two};
`;

export const actionableCard = css`
  ${card};

  transition: ${({ theme }) =>
    `background-color ${theme.transition.default}, box-shadow ${theme.transition.default}`};
  background-color: ${({ theme }) =>
    theme.mode === 'dark' ? theme.colors.backgroundPaperElevation2 : theme.colors.commonWhiteMain};

  cursor: pointer;

  &:hover,
  &[data-active='true'] {
    border-color: ${({ theme }) => theme.colors.primaryColorOutlinedborder};
    background-color: ${({ theme }) => theme.colors.primaryColorHover};
    box-shadow: ${({ theme }) => theme.shadow.card};
  }
`;
