import { sendLogToApi } from 'features/logging/sendLogToApi';

export type ErrorScope = 'unknown' | 'http' | 'react' | 'component' | 'saga' | 'modular_workflow';

type LogOptions = Parameters<typeof sendLogToApi>[0];

export const logError = async (
  errorOrMessage: LogOptions['errorOrMessage'],
  errorScope: ErrorScope | undefined = 'unknown',
  context: LogOptions['context'] = {}
) =>
  sendLogToApi({
    errorOrMessage,
    // TODO: We currently log everything as a warning to keep focus on the new app
    severity: 'WARNING',
    context: {
      'meta.errorScope': errorScope,
      ...context
    }
  });
