import { CloseModal } from 'components/modals/types';
import { addList, updateList } from 'features/lists/store/actions';
import { useMemo } from 'react';
import { connect } from 'react-redux';
import { RootState } from 'store/rootReducer';
import { object, string } from 'yup';

import useTr from '../../../../utils/hooks/useTr';
import InputField from '../../../forms/InputField';
import TextareaField from '../../../forms/TextareaField';
import FormikModal from '../../../modals/FormikModal';

type CallerProps = {
  modalTitle?: string;
  listName?: string;
  description?: string;
};

type ConnectedProps = {
  isLoading: boolean;
  onSubmit: (label: string, description: string) => void;
};

type Props = CallerProps & ConnectedProps & CloseModal;

const ListNameModal = ({
  isLoading,
  onSubmit,
  closeModal,
  description,
  listName,
  modalTitle
}: Props) => {
  const tr = useTr();
  const validationSchema = useMemo(
    () =>
      object().shape({
        listName: string().required(tr('lists.list_name_required'))
      }),
    [tr]
  );

  return (
    <FormikModal
      isLoading={isLoading}
      onSubmit={({ listName, description }) => {
        // TODO try to resolve this
        (onSubmit(listName, description) as unknown as Promise<unknown>).then(() => closeModal());
      }}
      initialValues={{
        listName: listName || '',
        description: description || ''
      }}
      validationSchema={validationSchema}
      title={modalTitle || 'lists.add_new_list'}
      onCancelClick={closeModal}
    >
      {() => (
        <>
          <InputField name="listName" label="common.name" autoFocus required />
          <TextareaField name="common.description" label="description" />
        </>
      )}
    </FormikModal>
  );
};

export const RenameListModal = connect(
  ({ lists: { isLoading } }: RootState) => ({ isLoading }),
  (dispatch, { id }: { id?: number }) => ({
    onSubmit: (listName: string, description: string) =>
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      dispatch(updateList.request({ id: id!, label: listName, description }, { thunk: true }))
  })
)(ListNameModal);

export const AddNewListModal = connect(
  ({ lists: { isLoading } }: RootState) => ({ isLoading }),
  (dispatch, { language, words }: { language?: string; words?: string[] }) => ({
    onSubmit: (label: string, description: string) =>
      dispatch(
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        addList.request({ label, description, language: language!, words: words! }, { thunk: true })
      )
  })
)(ListNameModal);
