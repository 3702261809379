import { Tooltip, Typography } from '@mui/material';
import { SmallerEmotionalityCircularProgress } from 'features/aiWriter/AiWriterSidebar/steps/flashScore/progress-circles';
import { DisabledProgressCircle } from 'features/aiWriter/AiWriterTextEditor/EditorTopToolbar/flashScorePopper/DisabledProgressCircle';
import { FlashScoreTextRatingResult } from 'features/flashScore/useScoreTexts';
import FormattedMessage from 'features/i18n/FormattedMessage';
import styled from 'styled-components';
import useTr from 'utils/hooks/useTr';

type Props = {
  scoringData: FlashScoreTextRatingResult | undefined;
  isSupportedLanguage: boolean;
};

export const FlashScoreEmotionalityProgress = ({ scoringData, isSupportedLanguage }: Props) => {
  const translate = useTr();
  if (!scoringData || !isSupportedLanguage) {
    return <DisabledProgressCircle />;
  }

  return (
    <Tooltip
      title={
        <FormattedMessage
          id="flashScore.tooltip.emotionality"
          values={{
            emotionality: scoringData.emotionality.percentage,
            emotionalityLabel: translate(scoringData.emotionality.label)
          }}
        />
      }
    >
      <ProgressIconWrapper>
        <SmallerEmotionalityCircularProgress
          value={scoringData.emotionality.percentage}
          label={<Typography fontSize={9}>{scoringData.emotionality.percentage}</Typography>}
        />
      </ProgressIconWrapper>
    </Tooltip>
  );
};

const ProgressIconWrapper = styled.div`
  display: grid;
  place-items: center;
`;
