import { Tune } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import {
  Box,
  CircularProgress,
  Divider,
  InputAdornment,
  ListItemText,
  MenuItem
} from '@mui/material';
import { MenuProps } from '@mui/material/Menu';
import { default as MuiSelect, SelectChangeEvent } from '@mui/material/Select';
import FlexContainer from 'components/FlexContainer';
import { InfiniteDropdownIntersectionObserver } from 'components/personality/InfiniteDropdownIntersectionObserver';
import { MissingOption } from 'components/personality/MissingOption';
import { PersonalityIcon } from 'components/personality/PersonalityIcon';
import { PersonalityWrapper } from 'components/personality/PersonalityWrapper';
import {
  createOption,
  manageOption,
  noPersonalityOption,
  OptionValue
} from 'components/personality/types';
import { mkPersonalityLabel } from 'components/personality/utils/mkPerosnalityLabel';
import { wiggleAnimation } from 'components/tour/utils/wiggleAnimation';
import { personalityMenuMaxHeight } from 'features/document-top-bar/PersonalityButton';
import FormattedMessage from 'features/i18n/FormattedMessage';
import { MouseEvent, useId, useState } from 'react';
import { PersonalityDto } from 'services/backofficeIntegration/http/dtos/PersonalityDto';
import gtmIds from 'services/tracking/GTMIds';
import { withGtmInteraction } from 'services/tracking/withGtmInteraction';
import styled from 'styled-components';
import useTr from 'utils/hooks/useTr';
import { withTestId } from 'utils/utils';

const menuOpeningTopLeftProps: Pick<MenuProps, 'anchorOrigin' | 'transformOrigin'> = {
  anchorOrigin: {
    vertical: 'top',
    horizontal: 'left'
  },
  transformOrigin: {
    vertical: 'bottom',
    horizontal: 'left'
  }
};

const menuOpeningBottomLeftProps: Pick<MenuProps, 'anchorOrigin' | 'transformOrigin'> = {
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'left'
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'left'
  }
};

type Props = {
  languageModelId: string;
  value: PersonalityDto | undefined | null;
  withWiggle?: boolean;
  menuOpeningDirection?: 'top' | 'bottom';

  onChange: (personalityId: PersonalityDto | undefined | null) => void;
  onClick?: () => void;
  onMouseDown?: (e: MouseEvent) => void;
  onBlur?: () => void;
};

export function PersonalityChatSelector({
  onChange,
  value,
  onClick,
  languageModelId,
  withWiggle,
  onMouseDown,
  onBlur,
  menuOpeningDirection = 'top'
}: Props) {
  const selectId = useId();

  const translate = useTr();

  const [isSelectOpen, setIsSelectOpen] = useState(false);

  const getSelectValue = (e: SelectChangeEvent<unknown>) => {
    return e.target.value as OptionValue;
  };

  return (
    <PersonalityWrapper languageModelId={languageModelId} value={value} onChange={onChange}>
      {({
        personalities,
        isLoading,
        currentPersonality,
        handleMenuOptionChange,
        fetchNextPage,
        hasNextPage,
        isFetchingNextPage
      }) => (
        <StyledSelect
          {...withTestId('styled-select-button')}
          labelId={selectId}
          value={currentPersonality?.id ?? noPersonalityOption}
          open={isSelectOpen}
          onClose={() => {
            setIsSelectOpen(false);
            onBlur?.();
          }}
          onOpen={() => setIsSelectOpen(true)}
          onChange={e => handleMenuOptionChange(getSelectValue(e))}
          onClick={onClick}
          onMouseDown={onMouseDown}
          sx={{
            '& fieldset': {
              border: 'none'
            }
          }}
          inputProps={{
            IconComponent: () => null
          }}
          MenuProps={{
            ...(menuOpeningDirection === 'top'
              ? menuOpeningTopLeftProps
              : menuOpeningBottomLeftProps),
            style: {
              maxHeight: personalityMenuMaxHeight
            }
          }}
          renderValue={value => mkPersonalityLabel(value as number, personalities)}
          placeholder={translate('aiWriter.inspirations.chat.personality_selection_placeholder')}
          label={translate('aiWriter.inspirations.chat.personality_selection_placeholder')}
          startAdornment={
            <InputAdornment
              position="start"
              onClick={() => setIsSelectOpen(prevState => !prevState)}
            >
              {isLoading ? (
                <Box sx={{ display: 'grid' }}>
                  <CircularProgress size={16} />
                </Box>
              ) : (
                <PersonalityIcon />
              )}
            </InputAdornment>
          }
          disabled={personalities === undefined}
          $withWiggleEffect={withWiggle ?? false}
        >
          <SelectItem
            {...withGtmInteraction(gtmIds.aiWriter.chat.openPersonalitiesLibrary)}
            value={manageOption}
          >
            <Tune />
            <ListItemText>
              <FormattedMessage id="aiWriter.inspirations.chat.personality_selection_manage_option" />
            </ListItemText>
          </SelectItem>
          <SelectItem
            {...withGtmInteraction(gtmIds.aiWriter.chat.openPersonalitiesLibrary)}
            value={createOption}
          >
            <AddIcon />
            <ListItemText>
              <FormattedMessage id="aiWriter.inspirations.chat.personality_selection_create_option" />
            </ListItemText>
          </SelectItem>

          <Divider />

          {Array.isArray(personalities) && personalities.length > 0 ? (
            personalities.map(personality => (
              <SelectItem
                {...withGtmInteraction(gtmIds.aiWriter.chat.selectPersonalityViaDropdown)}
                key={personality.id}
                value={personality.id}
              >
                <FlexContainer direction="row">
                  <ListItemText>{personality.label}</ListItemText>
                </FlexContainer>
              </SelectItem>
            ))
          ) : // This case prevents a warning for providing a selected value which isn't in the list
          // Usually only happens on first page visit while we're fetching the personalities but
          // already got the default personality from the API
          currentPersonality ? (
            <SelectItem
              {...withGtmInteraction(gtmIds.aiWriter.chat.selectPersonalityViaDropdown)}
              key={currentPersonality.id}
              value={currentPersonality.id}
            >
              <FlexContainer direction="row">
                <ListItemText>{currentPersonality.label}</ListItemText>
              </FlexContainer>
            </SelectItem>
          ) : null}

          <SelectItem
            {...withGtmInteraction(gtmIds.aiWriter.chat.selectPersonalityViaDropdown)}
            value={noPersonalityOption}
            selected={currentPersonality ? currentPersonality.id === null : false}
          >
            <ListItemText>
              <FormattedMessage id="aiWriter.inspirations.chat.personality_selection_no_personality_option" />
            </ListItemText>
          </SelectItem>

          <MissingOption
            currentModelId={languageModelId}
            currentPersonality={currentPersonality}
            personalities={personalities}
          />

          <InfiniteDropdownIntersectionObserver
            onIsInView={() => fetchNextPage()}
            isAlreadyFetching={isFetchingNextPage}
            enabled={hasNextPage}
          />
        </StyledSelect>
      )}
    </PersonalityWrapper>
  );
}

const StyledSelect = styled(MuiSelect)<{ $withWiggleEffect: boolean }>`
  max-width: 160px;

  background-color: transparent;

  ${({ $withWiggleEffect }) => $withWiggleEffect && wiggleAnimation};
  animation-iteration-count: 3;

  cursor: pointer;
  padding-left: ${({ theme }) => theme.spacings.two};

  & .MuiInputBase-input {
    display: flex;
    align-items: center;
    gap: ${({ theme }) => theme.spacings.small};
    // Aligns the height to the current template button style
    padding: 2px 0px;
  }

  & .MuiListItemText-primary {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  && .MuiSelect-outlined {
    padding: 5px;
  }

  & .MuiInputAdornment-root {
    height: auto;
  }
`;

const SelectItem = styled(MenuItem)`
  gap: ${({ theme }) => theme.spacings.small};
`;
