import { Field, FieldConfig, FieldProps } from 'formik';
import { upperFirst } from 'lodash';
import React from 'react';

import useTr from '../../utils/hooks/useTr';
import Input from '../base/Input';

type AdditionalProps = {
  showValidStatus?: boolean;
  hideFeedback?: boolean;
  translateFeedback?: boolean;
};

type Props = FieldProps & React.ComponentPropsWithoutRef<typeof Input> & AdditionalProps;

const FormikInput = ({
  field,
  form: { touched, errors },
  type,
  label,
  placeholder,
  showValidStatus,
  hideFeedback,
  translateFeedback,
  ...props
}: Props) => {
  const tr = useTr();

  return (
    <Input
      label={label}
      type={type}
      invalid={!hideFeedback && !!(errors[field.name] && touched[field.name])}
      valid={!hideFeedback && showValidStatus && !errors[field.name] && !!touched[field.name]}
      feedback={
        !hideFeedback &&
        errors[field.name] &&
        touched[field.name] &&
        (translateFeedback
          ? tr(errors[field.name] as string)
          : upperFirst(errors[field.name] as string))
      }
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      placeholder={placeholder || label ? tr((placeholder || label)!) : undefined}
      {...field}
      {...props}
    />
  );
};

type InputFieldProps = React.ComponentPropsWithoutRef<typeof Input> & FieldConfig & AdditionalProps;

const InputField = (props: InputFieldProps) => <Field component={FormikInput} {...props} />;

export default InputField;
