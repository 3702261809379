import { AuthHeader } from 'components/auth/AuthHeader';
import FlexContainer from 'components/FlexContainer';
import React from 'react';
import styled from 'styled-components';
import { mobileWindowWidthCSS } from 'utils/hooks/useMobileVersion';

type Props = { slogan?: string; children?: React.ReactNode };

export const AuthSummary = ({ slogan, children }: Props) => (
  <Root>
    <Header slogan={slogan} align="flex-start" />

    <FlexContainer gap="seven">{children}</FlexContainer>
  </Root>
);

const Root = styled.div``;

const Header = styled(AuthHeader)`
  margin-bottom: ${({ theme }) => theme.spacings.xxxxlarge};

  @media (max-width: ${mobileWindowWidthCSS}) {
    margin-bottom: ${({ theme }) => theme.spacings.medium};
  }
`;
