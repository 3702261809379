import { makeCurrentUserQueryKeyPrefix } from 'features/currentUser/currentUserQueries';
import { BaseSuccessDto } from 'services/backofficeIntegration/http/BaseSuccessDto';
import { backofficeClient } from 'services/backofficeIntegration/http/client/createBackofficeClient';
import { queryEndpoint } from 'services/backofficeIntegration/http/endpointDefinition';

export type PricingCampaignLink = {
  billing_portal_url: string;
  subscription_id: string;
  price_id: string;
  payment_method_change_url: string;
  billing_address_change_url: string;
};

type SuccessDto = BaseSuccessDto<PricingCampaignLink>;

export const httpGetPricingCampaigns = queryEndpoint({
  makeQueryKey: (campaignId: string) => {
    return [...makeCurrentUserQueryKeyPrefix(), 'pricing-campaigns', campaignId] as const;
  },
  callEndpoint: (campaignId: string) => {
    return backofficeClient.get<SuccessDto>(`/pricing-campaigns/${campaignId}`).then(response => {
      return response.data.data;
    });
  }
});
