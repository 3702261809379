import { useMutation } from '@tanstack/react-query';
import { httpDeleteProjects } from 'services/backofficeIntegration/http/endpoints/aiWriter/httpDeleteProjects';
import { invalidateProjectsQuery } from 'services/backofficeIntegration/http/endpoints/aiWriter/httpGetProjects';

export const useDeleteAiWriterProjects = () =>
  useMutation({
    mutationFn: (projectIds: string[]) => httpDeleteProjects.callEndpoint(projectIds),
    onSuccess: () => {
      invalidateProjectsQuery();
    }
  });
