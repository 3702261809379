import { MenuProps } from '@mui/material/Menu';
import { default as MuiSelect, SelectChangeEvent } from '@mui/material/Select';
import { ReactComponent as GptModelIcon } from 'assets/icons/gpt-select.svg';
import {
  PotentialDisabledMenuItem,
  PotentialDisabledMenuItemText,
  UpgradeButton
} from 'features/aiImages/AiImagesPage/ImageQualitySelect';
import FormattedMessage from 'features/i18n/FormattedMessage';
import { PricingPlan } from 'features/pricing/types';
import useUpdateSubscriptionModal from 'features/updateSubscriptionModal/hook/useUpdateSubscriptionModal';
import { MouseEvent, useState } from 'react';
import {
  GPT_MODELS,
  GptModel
} from 'services/backofficeIntegration/http/endpoints/aiWriter/httpCreateConversation';
import gtmIds from 'services/tracking/GTMIds';
import { withGtmInteraction } from 'services/tracking/withGtmInteraction';
import styled from 'styled-components';
import { withTestId } from 'utils/utils';

export const allowedPricingPlans: Array<PricingPlan> = [
  PricingPlan.pro,
  PricingPlan.premium,
  PricingPlan.business,
  PricingPlan.enterprise
];

const menuOpeningTopLeftProps: Pick<MenuProps, 'anchorOrigin' | 'transformOrigin'> = {
  anchorOrigin: {
    vertical: 'top',
    horizontal: 'left'
  },
  transformOrigin: {
    vertical: 'bottom',
    horizontal: 'left'
  }
};

const menuOpeningBottomLeftProps: Pick<MenuProps, 'anchorOrigin' | 'transformOrigin'> = {
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'left'
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'left'
  }
};

type Props = {
  value: GptModel;
  onChange: (event: SelectChangeEvent<unknown>) => void;
  onClick?: (e: MouseEvent) => void;
  menuOpeningDirection?: 'top' | 'bottom';
  onMouseDown?: (e: MouseEvent) => void;
  onBlur?: () => void;
  isGpt4Disabled: boolean;
};

export function GptSelect({
  value,
  onChange,
  onClick,
  onMouseDown,
  onBlur,
  isGpt4Disabled,
  menuOpeningDirection = 'top'
}: Props) {
  const [isSelectOpen, setIsSelectOpen] = useState(false);
  const showUpgradeSubscriptionModal = useUpdateSubscriptionModal();

  const handleUpgradeClick = () => {
    showUpgradeSubscriptionModal();
  };

  return (
    <StyledSelect
      size="small"
      value={value}
      open={isSelectOpen}
      onClose={() => {
        setIsSelectOpen(false);
        onBlur?.();
      }}
      onOpen={() => setIsSelectOpen(true)}
      onMouseDown={onMouseDown}
      onClick={onClick}
      onChange={e => onChange(e)}
      sx={{
        '& fieldset': {
          border: 'none'
        }
      }}
      MenuProps={{
        ...(menuOpeningDirection === 'top' ? menuOpeningTopLeftProps : menuOpeningBottomLeftProps)
      }}
      inputProps={{ IconComponent: () => null }}
      renderValue={() => <GptModelIcon fontSize={20} />}
      {...withTestId('gptselect-button')}
    >
      <PotentialDisabledMenuItem
        {...withGtmInteraction(gtmIds.aiWriter.chat.selectGptModel)}
        selected={value === GPT_MODELS.GPT_3_5}
        value={GPT_MODELS.GPT_3_5}
      >
        <FormattedMessage id="aiWriter.inspirations.chat.change_GPT_model.GPT_3_5" />
      </PotentialDisabledMenuItem>
      <PotentialDisabledMenuItem
        {...withGtmInteraction(gtmIds.aiWriter.chat.selectGptModel)}
        selected={value === GPT_MODELS.GPT_4}
        value={GPT_MODELS.GPT_4}
      >
        <PotentialDisabledMenuItemText>
          <FormattedMessage id="aiWriter.inspirations.chat.change_GPT_model.GPT_4" />
          {isGpt4Disabled && <UpgradeButton onClick={handleUpgradeClick} />}
        </PotentialDisabledMenuItemText>
      </PotentialDisabledMenuItem>
    </StyledSelect>
  );
}

const StyledSelect = styled(MuiSelect)`
  max-width: 160px;

  & .MuiInputBase-input {
    display: flex;
    align-items: center;
    gap: ${({ theme }) => theme.spacings.small};
    // Aligns the height to the current template button style
    padding: 2px 0px;
  }

  & .MuiListItemText-primary {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  && .MuiSelect-outlined {
    padding: 5px;
  }
`;
