import {
  EText,
  getNode,
  PlateEditor,
  replaceNode,
  Value,
  withoutNormalizing
} from '@udecode/plate-common';
import { setFlashActionsSelection } from 'features/aiWriter/slashCommands/hooks/useFlashActionsStore';
import { TFlashActionsInputElement } from 'features/plate/customPlugins/types';
import { Path } from 'slate';

export const removeSlashCommandsInput = <V extends Value>(editor: PlateEditor<V>, path: Path) =>
  withoutNormalizing(editor, () => {
    const node = getNode<TFlashActionsInputElement>(editor, path);
    if (!node) return;

    setFlashActionsSelection(null);

    replaceNode(editor, {
      at: path,
      nodes: { text: '' } as EText<V>
    });
  });
