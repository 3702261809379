import { AuthCenteredPanel } from 'components/auth/AuthCenteredPanel';
import AuthForm from 'components/auth/AuthForm/AuthForm';
import Dimmer from 'components/base/Dimmer';
import FlexContainer from 'components/FlexContainer';
import AlertError from 'components/forms/AlertError';
import { Form } from 'formik';
import { ReactNode } from 'react';
import styled from 'styled-components';

type Props = {
  header: string;
  actions?: ReactNode;
  extra?: ReactNode;
  socials?: ReactNode;
  ornaments?: ReactNode;
  status?: string;
  isLoading?: boolean;
  children: ReactNode;
};

export const CentredRegistrationFormContent = ({
  header,
  actions,
  extra,
  socials,
  ornaments,
  status,
  isLoading,
  children
}: Props) => {
  return (
    <AuthCenteredPanel slogan="register.slogan" ornaments={ornaments}>
      <AuthForm header={header} extra={extra} socials={socials}>
        <Form id="register-form">
          <Loader active={isLoading} loader>
            {status && <AlertError>{status}</AlertError>}

            <Content gap="small">{children}</Content>
          </Loader>

          {actions && <Actions>{actions}</Actions>}
        </Form>
      </AuthForm>
    </AuthCenteredPanel>
  );
};

const Loader = styled(Dimmer)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const Content = styled(FlexContainer)``;

const Actions = styled.div`
  display: flex;
  justify-content: space-between;

  margin-top: ${({ theme }) => theme.spacings.large};
`;
