import EmbedContainer from 'components/EmbedContainer';
import Modal from 'components/modals/Modal';
import { CloseModal } from 'components/modals/types';
import { FormattedMessage } from 'react-intl';
import { useLocation } from 'react-router-dom';
import { YTVideoName } from 'services/youtube/types';
import useGetYoutubeVideo from 'services/youtube/useYoutubeVideo';

export const VideoGuideModal = ({ closeModal }: CloseModal) => {
  const { pathname } = useLocation();
  const getYoutubeVideo = useGetYoutubeVideo();

  const videoId = getVideoForPath(pathname, getYoutubeVideo);

  return (
    <Modal
      title="video_guide.title"
      buttons={[{ color: 'primary', onClick: closeModal, text: 'common.close' }]}
      onCancel={closeModal}
    >
      <FormattedMessage id="video_guide.description" tagName="p" />
      {videoId && (
        <EmbedContainer embedSrc={`https://www.youtube.com/embed/${videoId}`} resolution="16by9" />
      )}
    </Modal>
  );
};

const getVideoForPath = (
  pathname: string,
  getYoutubeVideo: (name: YTVideoName) => string | undefined
) => {
  switch (pathname) {
    case '/exploring': {
      return getYoutubeVideo('exploring');
    }
    case '/testing': {
      return getYoutubeVideo('testing');
    }
  }

  return undefined;
};

export default VideoGuideModal;
