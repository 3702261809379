
import SmallSVGText from '../../../components/ChartElements/SmallSVGText';
import SVGText from '../../../components/ChartElements/SVGText';
import useTr from '../../../utils/hooks/useTr';

type Props = { scale?: number; x?: number; y?: number; stroke?: string };

const GoalLegend = ({ scale = 1.2, x = 0, y = 0, stroke = '#425369' }: Props) => {
  const tr = useTr();

  return (
    <g id="goal-legend" transform={`scale(${scale}) translate(${x}, ${y})`}>
      <g transform="translate(-80, -30)">
        <line x1="0" y1="0" x2="0" y2="16" style={{ stroke, strokeWidth: 2 }} />
        <line x1="58" y1="0" x2="58" y2="16" style={{ stroke, strokeWidth: 2 }} />
        <line
          x1="4"
          y1="8"
          x2="58"
          y2="8"
          style={{ stroke, strokeWidth: 2, strokeDasharray: '2, 4' }}
        />
        <SVGText textAnchor="middle" x="28" y="-10">
          {tr('goal_score.goal_score')}
        </SVGText>
      </g>
      <SmallSVGText textAnchor="end" x="0" y="0">
        {tr('exploring.bubble_chart.legend.distance')}
      </SmallSVGText>
    </g>
  );
};

export default GoalLegend;
