import { safeEnum } from 'utils/safeEnum';

export const SurveyQuestionType = safeEnum.make({
  single_choice: 'single_choice',
  dropdown: 'dropdown',
  selectable_option: 'selectable_option'
} as const);

type SurveyQuestionType = safeEnum.infer<typeof SurveyQuestionType>;

export type SurveyQuestionOptionDto = {
  id: number;
  question_id: number;
  option_text: string;
  option_key: string;
};

export type SurveyQuestionDto = {
  id: number;
  slug: string;
  alias: string;
  question_type: SurveyQuestionType;
  question_text: string;
  options: SurveyQuestionOptionDto[];
  survey: string;
  queue: number;
};

export type SurveyQuestionsDto = SurveyQuestionDto[];
