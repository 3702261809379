import { APIMapperVariable, ScoreWordsResponse } from 'services/api/wordEmbedding/types';
import { backofficeClient } from 'services/backofficeIntegration/http/client/createBackofficeClient';
import { mutationEndpoint } from 'services/backofficeIntegration/http/endpointDefinition';

type ScoreWordsPayload = {
  seed_words: string[];
  whitelist_words?: string[];
  embedding_model_id: string;
  mappers: APIMapperVariable[];
  limit?: number;
};

export const httpFlashScoreWords = mutationEndpoint({
  callEndpoint: (params: ScoreWordsPayload) =>
    backofficeClient
      .post<ScoreWordsResponse>('/word-embedding/score_words', params)
      .then(response => response.data)
});
