import { useQuery } from '@tanstack/react-query';
import { ProjectLocale } from 'features/projects/ProjectLocale';
import { CustomerPreferencesDto } from 'services/backofficeIntegration/http/dtos/CustomerPreferencesDto';
import { httpGetPreferences } from 'services/backofficeIntegration/http/endpoints/customer/httpGetPreferences';
import { queryClient } from 'utils/ReactQueryClient';

function extractPreferredLocale(
  preferences: CustomerPreferencesDto | undefined
): ProjectLocale | undefined {
  const { preferredLanguage, preferredLanguageCountry } = preferences ?? {};
  return preferredLanguageCountry && preferredLanguage
    ? {
        country: preferredLanguageCountry,
        language: preferredLanguage
      }
    : undefined;
}

function makeQueryKey() {
  return [...httpGetPreferences.makeQueryKey(), 'preferredLocale'] as const;
}

/**
 * We make a separate query to ensure that we fetch fresh data the first time
 * we need preferred locale. This is important because preferred locale changes
 * after new user survey is filled. If we would use preferences query there is very
 * high chance that it would be stale and locale data would not be there yet.
 */
export function usePreferredLocaleQuery<Result = ProjectLocale | undefined>(
  props: {
    select?: (data: ProjectLocale | undefined) => Result;
    onSuccess?: (data: Result) => void;
  } = {}
) {
  const { select, onSuccess } = props;
  return useQuery({
    queryKey: makeQueryKey(),
    queryFn: async () => {
      const data = await httpGetPreferences.callEndpoint();
      return extractPreferredLocale(data);
    },
    select,
    onSuccess
  });
}

export function clearPreferredLocaleQuery() {
  queryClient.removeQueries(makeQueryKey());
}
