import { Favorite, FavoriteBorder } from '@mui/icons-material';
import { Checkbox, CircularProgress } from '@mui/material';
import { ColoredTypography } from 'components/ColoredTypography';
import FlexContainer from 'components/FlexContainer';
import { SearchField } from 'components/SearchField';
import { useAdvancedOptions } from 'features/textGenerator/EnhancedOutputTypeAutocomplete/useAdvancedOptions';
import { CustomCategories } from 'features/textGenerator/outputTypeModal/CategoriesList';
import { useFavoriteOutputType } from 'features/textGenerator/outputTypeModal/useFavoriteOutputType';
import { useOutputTypesSuggestionIds } from 'features/textGenerator/outputTypeModal/useGetOutputTypesSuggestions';
import { ModalListCard } from 'features/theme-2024/modal-list-card/ModalListCard';
import { ModalListCardGrid } from 'features/theme-2024/modal-list-card/ModalListCardGrid';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { GAEvents } from 'services/tracking/GAEvents';
import gtmIds from 'services/tracking/GTMIds';
import { withGtmInteraction } from 'services/tracking/withGtmInteraction';
import styled from 'styled-components';

export type OutputTypeOption = {
  id: string;
  label: string;
  description?: string;
  categoryLabel: string;
  categoryId: string;
  youTubeVideoId: string | undefined;
};

export type OutputTypeChangeHandler = (x: string) => void;

const customCategoriesList: string[] = Object.values(CustomCategories);

export function OutputTypesList(props: {
  selectedCategory: string;
  initQuery?: string;
  modelLanguage: string;
  modelCountry: string;
  onOutputTypeChange: OutputTypeChangeHandler;
}) {
  const {
    selectedCategory,
    initQuery: searchQuery,
    modelLanguage,
    modelCountry,
    onOutputTypeChange
  } = props;
  const [query, setQuery] = useState<string>(searchQuery ?? '');
  const suggestedIdsResult = useOutputTypesSuggestionIds();
  const favoriteOutputType = useFavoriteOutputType();

  // The lack of error handling was considered acceptable here
  const outputTypeSuggestionsIds = suggestedIdsResult.data ?? {
    frequentlyUsed: [],
    mostPopular: [],
    favorite: [],
    mostRecent: []
  };

  const options = useAdvancedOptions({
    selectedCategory,
    query,
    modelLanguage,
    modelCountry,
    outputTypeSuggestionsIds
  });

  const handleOutputTypeClick = (outputType: string) => {
    onOutputTypeChange(outputType);
  };

  const handleFavoriteOutputType = (outputType: string, value: boolean) => {
    favoriteOutputType.mutate(
      {
        outputType: outputType,
        is_favorite: value
      },
      {
        onSuccess: () => {
          suggestedIdsResult.refetch();
        }
      }
    );
  };

  return (
    <Root>
      <FlexContainer alignItems="flex-end">
        <SearchField
          value={query}
          onChange={e => {
            setQuery(e.target.value);
            GAEvents.startedSearchInTextTypesModal({ query: e.target.value });
          }}
        />
      </FlexContainer>

      {options.length === 0 ? (
        <NoOptionsPlaceholder
          selectedCategory={selectedCategory}
          isSuggestionsLoading={suggestedIdsResult.isLoading}
        />
      ) : (
        <ModalListCardGrid columns={3}>
          {options.map(option => (
            <ModalListCard
              {...withGtmInteraction(gtmIds.textTypesLibrary.selectTextType)}
              key={option.id}
              onClick={() => handleOutputTypeClick(option.id)}
              caption={option.categoryLabel}
              title={option.label}
              footer={
                <Checkbox
                  icon={<FavoriteBorder />}
                  checkedIcon={<Favorite />}
                  size="small"
                  checked={option.isFavorite}
                  onClick={e => {
                    // There is a checkbox inside button configurations, so bubbling must be prevented
                    e.stopPropagation();
                  }}
                  onChange={() => {
                    handleFavoriteOutputType(option.id, !option.isFavorite);
                  }}
                />
              }
            >
              {option.description}
            </ModalListCard>
          ))}
        </ModalListCardGrid>
      )}
    </Root>
  );
}

function NoOptionsPlaceholder(props: { selectedCategory: string; isSuggestionsLoading: boolean }) {
  const { selectedCategory, isSuggestionsLoading } = props;

  if (isSuggestionsLoading && selectedCategory === CustomCategories.MOST_POPULAR_CATEGORY) {
    return (
      <Placeholder>
        <CircularProgress />
      </Placeholder>
    );
  }

  return (
    <Placeholder>
      <ColoredTypography variant="body2">
        <FormattedMessage
          id={selectMessage()}
          values={{
            br: <br />
          }}
        />
      </ColoredTypography>
    </Placeholder>
  );

  function selectMessage() {
    if (customCategoriesList.includes(selectedCategory)) {
      return `output_type_modal.no_options_placeholder.${selectedCategory}`;
    }
    return 'common.autocomplete.no_results';
  }
}

const Root = styled.div`
  /* This is required to shrink the output grid  */
  min-height: 0;

  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.small};
`;

const Placeholder = styled.div`
  min-height: 70%;
  display: flex;
  justify-content: center;
  align-items: center;

  text-align: center;
`;
