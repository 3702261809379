import { Table, TableBody } from '@mui/material';
import { FeatureTableRow } from 'components/profile/components/feature-comparison/FeatureTableRow';
import { FeatureTableRowData } from 'components/profile/components/feature-comparison/FeatureTableRowData';
import {
  SectionTitle,
  StyledTableContainer
} from 'components/profile/components/pricing-plan-card/Components';
import { FormattedMessage } from 'react-intl';

const contentGenerationCapTableData: FeatureTableRowData[] = [
  {
    nameTranslationKey: 'features_comparison.content_generation_capabilities.document_editor',
    starterPlan: true,
    proPlan: true,
    businessPlan: true
  },
  {
    nameTranslationKey:
      'features_comparison.content_generation_capabilities.pre-trained_text-types',
    starterPlan: true,
    proPlan: true,
    businessPlan: true
  },
  {
    nameTranslationKey: 'features_comparison.content_generation_capabilities.template_library',
    starterPlan: true,
    proPlan: true,
    businessPlan: true
  },
  {
    nameTranslationKey: 'features_comparison.content_generation_capabilities.flash_actions',
    tooltipTranslationKey:
      'features_comparison.content_generation_capabilities.flash_actions.tooltip',
    starterPlan: true,
    proPlan: true,
    businessPlan: true
  },
  {
    nameTranslationKey: 'features_comparison.content_generation_capabilities.standard_workflows',
    tooltipTranslationKey:
      'features_comparison.content_generation_capabilities.standard_workflows.tooltip',
    starterPlan: 'features_comparison.content_generation_capabilities.standard_workflows_standard',
    proPlan: 'features_comparison.content_generation_capabilities.standard_workflows_pro',
    businessPlan: 'features_comparison.content_generation_capabilities.standard_workflows_business'
  },
  {
    nameTranslationKey: 'features_comparison.content_generation_capabilities.seo_workflow',
    tooltipTranslationKey:
      'features_comparison.content_generation_capabilities.seo_workflow.tooltip',
    starterPlan: false,
    proPlan: 'features_comparison.content_generation_capabilities.seo_workflow_pro',
    businessPlan: 'features_comparison.content_generation_capabilities.seo_workflow_business'
  },
  {
    nameTranslationKey: 'features_comparison.content_generation_capabilities.tonalities',
    starterPlan: true,
    proPlan: true,
    businessPlan: true
  },
  {
    nameTranslationKey: 'features_comparison.content_generation_capabilities.custom_workflows',
    starterPlan: false,
    proPlan: false,
    businessPlan: true
  }
];

export const ContentGenerationCapabilitiesTable = () => {
  return (
    <StyledTableContainer>
      <SectionTitle>
        <FormattedMessage id="features_comparison.content_generation_capabilities.label" />
      </SectionTitle>
      <Table>
        <TableBody>
          {contentGenerationCapTableData.map(row => (
            <FeatureTableRow key={row.nameTranslationKey} row={row} />
          ))}
        </TableBody>
      </Table>
    </StyledTableContainer>
  );
};
