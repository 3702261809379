import { useQuery } from '@tanstack/react-query';
import { makeCurrentUserQueryKeyPrefix } from 'features/currentUser/currentUserQueries';
import WordEmbeddingAPI from 'services/api/wordEmbedding';

export function useOutputTypesSuggestionIds() {
  const result = useQuery({
    queryKey: [...makeCurrentUserQueryKeyPrefix(), 'outputTypes', 'suggestions'],
    queryFn: async () => {
      const response = await WordEmbeddingAPI.getOutputTypesSuggestions();
      if (response.status) {
        return response.data;
      }

      throw response;
    }
  });

  return result;
}
