import { CloseModal } from 'components/modals/types';
import { useChurnKeyModal } from 'features/churnKey/useChurnKeyModal';
import { Actions } from 'features/churnModal/actions';
import { ChurnModal } from 'features/churnModal/churnModal';
import { ChurnType, StatisticsStep } from 'features/churnModal/statisticsStep';
import { WithNextStep } from 'features/churnModal/steps';
import useDeleteUser from 'features/customer/hooks/useDeleteUser';
import useSubscriptionState from 'features/pricing/hook/useSubscriptionState';
import { SubscriptionStatus } from 'services/api/customer/types';

const activeSubscriptionStatus: SubscriptionStatus[] = ['on trial', 'on trial grace', 'subscribed'];

const reloadPage = () => window.location.reload();

export function DeleteAccountModal({ closeModal }: CloseModal) {
  const { currentSubscriptionState } = useSubscriptionState();
  const { deleteUser } = useDeleteUser();

  const hasActiveOrTrailSubscription = activeSubscriptionStatus.includes(
    currentSubscriptionState?.status ?? 'not subscribed'
  );

  const showChurnKeyModal = useChurnKeyModal({
    customerAttributes: { churnType: 'delete' },
    listeners: {
      onCancel: async () => {
        await deleteUser();
        reloadPage();
      },
      onPause: reloadPage,
      onPlanChange: reloadPage
    }
  });

  const handleContinueClick = async () => {
    closeModal();

    // If the user doesn't have an active subscription, we can delete him right away
    // Note: Without an active subscription, ChurnKey won't trigger any flow
    if (!hasActiveOrTrailSubscription) {
      await deleteUser();
      reloadPage();
      return;
    }

    showChurnKeyModal();
  };

  return (
    <ChurnModal.Layout>
      <Step1 key="s1" closeModal={closeModal} nextStep={handleContinueClick} />
    </ChurnModal.Layout>
  );
}

function Step1({ closeModal, nextStep }: CloseModal & WithNextStep) {
  return (
    <StatisticsStep.Layout
      heading={<StatisticsStep.Heading />}
      explanation={<StatisticsStep.Explanation churnType={ChurnType.DeleteAccount} />}
      statistics={<StatisticsStep.DeleteStatistics />}
      actions={
        <Actions.Layout
          ok={
            <Actions.ContinueButton
              dangerous={true}
              onClick={nextStep}
              labelId="churn_modal.delete_account.continue_button"
            />
          }
          cancel={
            <Actions.CancelButton onClick={closeModal} labelId="churn_modal.keep_account_button" />
          }
        />
      }
      nextStep={nextStep}
    />
  );
}
