import { rgba } from 'polished';
import { OldTheme } from 'styled-components';

const theme: OldTheme = {
  fontFamily:
    "'Roboto', 'Source Sans Pro', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Helvetica Neue', Arial, sans-serif",
  colors: {
    mainColor: '#2196F3',

    primary: rgba(3, 169, 244, 1),

    primary700: rgba(2, 136, 209, 1),
    primary600: rgba(3, 169, 244, 1),
    primary500: rgba(3, 155, 229, 1),
    primary300: rgba(79, 195, 247, 1),
    primary200: rgba(129, 212, 250, 1),
    primary100: rgba(179, 229, 252, 1),
    primary50: rgba(225, 245, 254, 1),

    primaryHover: '#02A8E3',

    secondary: rgba(186, 135, 255, 1),

    secondary700: rgba(162, 92, 255, 1),
    secondary600: rgba(179, 122, 255, 1),
    secondary500: rgba(186, 135, 255, 1),
    secondary200: rgba(206, 170, 255, 1),
    secondary100: rgba(212, 181, 255, 1),
    secondary50: rgba(221, 195, 255, 1),

    secondaryDark: 'rgb(162,92,255)',
    secondaryDark200: 'rgba(197,154,255,0.50)',
    secondaryDark400: 'rgba(197, 154, 255, 0.29)',

    // TODO This pallete is not defined in the style guide and colors are directly taken from the mockups.
    // It should be unified with the style guide.
    // Please use it with caution

    lightGreen: rgba(111, 214, 11, 0.2),

    lightGreen900: rgba(111, 214, 11, 0.9),
    lightGreen800: rgba(111, 214, 11, 0.8),
    lightGreen700: rgba(111, 214, 11, 0.7),
    lightGreen600: rgba(111, 214, 11, 0.6),
    lightGreen500: rgba(111, 214, 11, 0.5),
    lightGreen400: rgba(111, 214, 11, 0.4),
    lightGreen300: rgba(111, 214, 11, 0.3),
    lightGreen200: rgba(111, 214, 11, 0.2),
    lightGreen100: rgba(111, 214, 11, 0.1),
    lightGreen50: rgba(111, 214, 11, 0.05),

    textMuted: '#9aa0ac',

    success: '#5eba00',
    warning: '#f1c40f',
    danger: '#cd201f',
    error: '#D32F2F',

    orange: '#fd9644',
    link: '#0086FF',
    white: '#FFFFFF',
    black: '#000000',
    backgroundBlue: '#E1F5FE',
    violet: '#a25cff',
    lightViolet: rgba(162, 92, 255, 0.17),

    whiteMediumEmphasis: rgba(255, 255, 255, 0.7),
    whiteDisabled: rgba(255, 255, 255, 0.5),

    blackHighEmphasis: rgba(0, 0, 0, 0.87),
    blackMediumEmphasis: rgba(0, 0, 0, 0.6),
    blackDisabled: rgba(0, 0, 0, 0.38),
    blackInactive: rgba(0, 0, 0, 0.54),

    surfaceCard: rgba(250, 250, 250, 1),
    surfaceCardHover: rgba(240, 240, 240, 1),

    inputBackground: '#E8E8E8',
    textTile: '#FAFAFA',

    blackHoverOverlay: rgba(0, 0, 0, 0.08),
    blackFocusOverlay: rgba(3, 169, 244, 0.12),
    blackPressedOverlay: rgba(0, 0, 0, 0.16),

    emailNotificationToolbarBackground: '#A25CFF',
    conversationAiIconBackground: '#A25CFF',
    infoToolbarBackground: '#cbeeff',
    aiWriterSidebarBorderColor: '#d9d9d9',

    warningRed: rgba(231, 99, 7, 1),
    warningYellow: rgba(255, 242, 177, 1)
  },

  fontSizes: {
    h1: '2rem', // 32px
    h2: '1.75rem', // 28px
    h3: '1.5rem', // 24px
    h4: '1.125rem', // 18px
    h5: '1rem', // 16px
    h6: '0.875rem', // 14px

    body1: '0.875rem',
    body2: '0.75rem',

    caption: '0.75rem'
  },
  spacings: {
    xxsmall: '0.125rem',
    xsmall: '0.25rem',
    small: '0.5rem',
    medium: '1rem',
    xmedium: '1.5rem',
    large: '2rem',
    xlarge: '2.5rem',
    xxlarge: '3rem',
    xxxlarge: '4rem',
    xxxxlarge: '5rem'
  },
  borderRadius: {
    small: '4px',
    medium: '8px'
  },
  container: {
    sm: '540px',
    md: ' 720px',
    lg: '960px',
    xl: '1200px'
  },
  shadow: {
    card: '0 0 3px rgba(0, 0, 0, 0.25)',
    popOver:
      '0 2px 4px rgba(0, 0, 0, 0.14), 0 4px 5px rgba(0, 0, 0, 0.12), 0 1px 10px rgba(0, 0, 0, 0.2)',
    textSuggestionHover:
      '0px 0px 2px rgba(0, 0, 0, 0.14), 0px 2px 2px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2)'
  },
  transition: {
    default: '0.2s ease-in-out',
    long: '0.5s ease-in-out'
  },
  sizes: {
    sideBar: '80px',
    authForm: '496px',
    topToolbarHeight: '56px'
  }
} as const;

export default theme;
