import { IntlMessageKeys } from 'features/i18n/IntlMessageKeys';
import { useCallback } from 'react';
import { useIntl } from 'react-intl';

type TranslationValues = Record<string, string | number> | undefined;

export type TranslationFunction = (id: IntlMessageKeys, values?: TranslationValues) => string;

const useTr = (): TranslationFunction => {
  const intl = useIntl();

  return useCallback(
    (id: IntlMessageKeys, values: TranslationValues) => intl.formatMessage({ id }, values),
    [intl]
  );
};

export default useTr;
