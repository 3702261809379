import { makeCurrentUserQueryKeyPrefix } from 'features/currentUser/currentUserQueries';
import { BaseSuccessDto } from 'services/backofficeIntegration/http/BaseSuccessDto';
import { backofficeClient } from 'services/backofficeIntegration/http/client/createBackofficeClient';
import { SurveyQuestionsDto } from 'services/backofficeIntegration/http/dtos/SurveyQuestionsDto';
import { queryEndpoint } from 'services/backofficeIntegration/http/endpointDefinition';

type SuccessDto = BaseSuccessDto<SurveyQuestionsDto>;

type QueryParams = {
  language?: string;
};

export const getSurveyQuestions = queryEndpoint({
  makeQueryKey: (params?: QueryParams) => {
    return [...makeCurrentUserQueryKeyPrefix(), 'survey-questions', params] as const;
  },
  callEndpoint: (params?: QueryParams) =>
    backofficeClient
      .get<SuccessDto>('/survey/questions', {
        params: params || {}
      })
      .then(response => response.data.data)
});
