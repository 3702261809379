import HelpIcon from '@mui/icons-material/Help';
import { Autocomplete, TextField, Tooltip, Typography } from '@mui/material';
import FlexContainer from 'components/FlexContainer';
import { OutputTypeOptionComponent } from 'features/textGenerator/OutputTypeAutocomplete/OutputTypeOptionComponent';
import { useGetOutputTypeOptionsByLanguageAndCountry } from 'features/textGenerator/utils/useGetPromptsOthers';
import { useField } from 'formik';
import { useMemo, useState } from 'react';
import styled from 'styled-components';
import useTr from 'utils/hooks/useTr';

export type OutputTypeOption = {
  value: string;
  label: string;
  category: string;
  youTubeVideoId: string | undefined;
};

type CategorizedOutputTypeOptions = Record<string, OutputTypeOption[]>;

export type OutputTypeAutocompleteProps = {
  name: string;
  modelLanguage: string;
  modelCountry: string;
  isHidden?: boolean;
  hideSubHeader?: boolean;
  isDisabled?: boolean;

  onSelect?: (value: string) => void;
};

const OutputTypeAutocomplete = ({
  name,
  modelLanguage,
  modelCountry,
  isHidden,
  hideSubHeader,
  isDisabled,
  onSelect
}: OutputTypeAutocompleteProps) => {
  const translate = useTr();
  const [field, meta, helper] = useField<string>(name);

  const outputTypeOptions = useGetOutputTypeOptionsByLanguageAndCountry(
    modelLanguage,
    modelCountry
  );

  const [temporaryValue, setTemporaryValue] = useState('');

  const handleOnFocus = () => {
    setTemporaryValue(field.value);
    helper.setValue('');
  };

  const handleOnBlur = () => {
    if (field.value === '') {
      helper.setValue(temporaryValue);
    }
  };

  const options = useMemo(() => {
    const outputTypes = outputTypeOptions.reduce((prev, curr) => {
      return {
        ...prev,
        [curr.category.label]: [
          ...(prev[curr.category.label] ?? []),
          {
            value: curr.outputType.id,
            label: curr.outputType.label,
            category: curr.category.label,
            youTubeVideoId: curr.outputType.youTubeVideoId ?? undefined
          }
        ]
      };
    }, {} as CategorizedOutputTypeOptions);

    return Object.values(outputTypes).reduce(
      (prev, value) => [...prev, ...value],
      [] as OutputTypeOption[]
    );
  }, [outputTypeOptions]);

  if (isHidden) {
    return null;
  }

  return (
    <FlexContainer gap="one">
      {!hideSubHeader && (
        <Container>
          <Typography variant="subtitle1">
            {translate('common.initialization.content_type.subHeader')}
          </Typography>
          <Tooltip title={translate('common.initialization.brief.text_types_info')}>
            <Link
              href={translate('external_links.app_help_output_types')}
              target="_blank"
              rel="noopener noreferrer"
            >
              <HelpIcon fontSize="small" />
            </Link>
          </Tooltip>
        </Container>
      )}
      <Autocomplete
        id="country"
        fullWidth={true}
        disableClearable={true}
        options={options}
        autoHighlight={true}
        disabled={isDisabled}
        getOptionLabel={option => option.label}
        renderOption={(props, option) => (
          // tech-debt: https://app.clickup.com/t/862jf8qza
          <OutputTypeOptionComponent key={Math.random()} props={props} option={option} />
        )}
        value={options.find(v => v.value === field.value)}
        onChange={(_, value) => {
          if (value) {
            helper.setValue(value.value);
            helper.setTouched(true);
            onSelect?.(value.value);
          }
        }}
        groupBy={(option: OutputTypeOption) => option.category}
        renderInput={params => (
          <TextField
            {...params}
            name={name}
            placeholder={translate('generate_text.output_type.placeholder')}
            variant="outlined"
            fullWidth
            error={meta.touched && !!meta.error}
            helperText={meta.touched && meta.error}
            onFocus={handleOnFocus}
            onBlur={handleOnBlur}
          />
        )}
      />
    </FlexContainer>
  );
};

const Container = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Link = styled.a`
  && {
    color: ${({ theme }) => theme.colors.blackMediumEmphasis};
  }
`;

export default OutputTypeAutocomplete;
