import DoneIcon from '@mui/icons-material/Done';
import SearchIcon from '@mui/icons-material/Search';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import { CircularProgress, Divider, TextField, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { ELEMENT_IMAGE } from '@udecode/plate-media';
import { ELEMENT_PARAGRAPH } from '@udecode/plate-paragraph';
import FlexContainer from 'components/FlexContainer';
import Toast from 'components/toasts/Toast';
import { getRoutePath } from 'config/routes';
import { AddToDocumentDialog } from 'features/aiImages/AiImagesPage/AddToDocumentDialog';
import { AddToExistingDocumentConfirmation } from 'features/aiImages/AiImagesPage/AddToExistingDocumentConfirmation';
import { useHasTooManyImagesInDocument } from 'features/aiWriter/hooks/useHasTooManyImagesInDocument';
import { updateCurrentProjectInBackgroundThunk } from 'features/aiWriter/store/actions/project/thunks/updateCurrentProjectInBackground';
import { loadTabThunk } from 'features/aiWriter/store/actions/tabs/thunks/loadTabThunk';
import { AiWriterProject, AiWriterProjectWithShortText } from 'features/aiWriter/store/types';
import { useGetProjectById } from 'features/aiWriter/useGetProjectById';
import { ChangeEvent, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router';
import {
  httpGetProjects,
  PaginatedListParams
} from 'services/backofficeIntegration/http/endpoints/aiWriter/httpGetProjects';
import { GAEvents } from 'services/tracking/GAEvents';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
import { useDebounce } from 'use-debounce/lib';
import useDialog from 'utils/hooks/useDialog';

type Props = {
  onCancel: () => void;
  onConfirm: () => void;
  isDialogOpen: boolean;
  imageUrl: string;
};

export const AddToExistingDocumentDialog = ({
  isDialogOpen,
  onCancel,
  onConfirm,
  imageUrl
}: Props) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [debouncedSearchQuery] = useDebounce(searchQuery, 300);
  const [selectedProject, setSelectedProject] = useState<AiWriterProject | null>(null);

  const { hasTooManyImagesInDocument, handleTooManyImages } = useHasTooManyImagesInDocument();

  function handleSearchChange(e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    setSearchQuery(e.target.value);
  }

  const handleProjectSelection = (project: AiWriterProject) => {
    setSelectedProject(project);
  };
  const handleCancel = () => {
    setSelectedProject(null);
    onCancel();
  };

  const [
    addToExistingConfirmationDialogOpen,
    openExistingDocumentConfirmationDialog,
    closeAddToExistingConfirmationDialog
  ] = useDialog();

  const handleConfirm = () => {
    if (selectedProject && hasTooManyImagesInDocument(selectedProject)) {
      handleTooManyImages();
      return;
    }

    onConfirm();
    openExistingDocumentConfirmationDialog();
  };

  const handleAddToExistingConfirmationDialogConfirm = async () => {
    // Here we need to update the project in the background with the generated image
    // and then load the tab but first we need to navigate to the project page to obtain the context for the project
    // we also need to make sure that in case of an empty project we update its text to an empty array
    if (selectedProject) {
      selectedProject.text = [
        {
          type: ELEMENT_IMAGE,
          url: imageUrl,
          children: [{ text: '' }]
        },
        {
          type: ELEMENT_PARAGRAPH,
          children: [{ text: '' }]
        },
        ...(selectedProject.text ?? [])
      ];
      await navigate(getRoutePath('aiWriter'));
      dispatch(loadTabThunk(selectedProject));
      dispatch(updateCurrentProjectInBackgroundThunk());
      GAEvents.imageAdded();
      Toast.success('ai_images.generator_page.images.added.success');
    }

    closeAddToExistingConfirmationDialog();
  };

  const modalContent = (
    <>
      <Typography variant="body1">
        <FormattedMessage
          id="ai_images.generator_page.add_to_document_modal.existing_document.content"
          values={{
            b: (text: string) => <strong>{text}</strong>
          }}
        />
      </Typography>
      <StyledTextField
        variant="outlined"
        label={<FormattedMessage id="common.search" />}
        value={searchQuery}
        onChange={handleSearchChange}
        fullWidth
        InputProps={{
          endAdornment: <MagnifyingGlassIcon />
        }}
      />
      <Typography variant="subtitle1">
        <FormattedMessage id="ai_images.generator_page.add_to_document_modal.existing_document.suggested_documents" />
      </Typography>
      <SuggestedDocuments
        handleProjectSelection={handleProjectSelection}
        selectedProject={selectedProject}
        query={debouncedSearchQuery}
      />
    </>
  );
  return (
    <>
      <AddToExistingDocumentConfirmation
        isDialogOpen={addToExistingConfirmationDialogOpen}
        onCancel={closeAddToExistingConfirmationDialog}
        onConfirm={() => handleAddToExistingConfirmationDialogConfirm()}
      />
      <AddToDocumentDialog
        title={
          <FormattedMessage id="ai_images.generator_page.add_to_document_modal.existing_document.title" />
        }
        content={modalContent}
        isDialogOpen={isDialogOpen}
        onCancel={handleCancel}
        onConfirm={handleConfirm}
        hasAddDisabled={!selectedProject}
      />
    </>
  );
};

const SuggestedDocuments = ({
  handleProjectSelection,
  selectedProject,
  query
}: {
  handleProjectSelection: (project: AiWriterProject) => void;
  selectedProject: AiWriterProject | null;
  query?: string;
}) => {
  const { fetchProject } = useGetProjectById();
  const queryParams: PaginatedListParams = {
    offset: 0,
    limit: 3,
    sort: 'updated_at',
    direction: 'desc',
    q: query
  };

  const {
    isLoading: areProjectsFetching,
    data,
    isError
  } = useQuery({
    queryKey: httpGetProjects.makeQueryKey(queryParams),
    queryFn: () => httpGetProjects.callEndpoint(queryParams),
    keepPreviousData: true
  });

  const handleProjectClick = (project: AiWriterProjectWithShortText) => {
    fetchProject(
      { id: project.id },
      {
        onSuccess: data => {
          handleProjectSelection(data);
        }
      }
    );
  };

  if (areProjectsFetching) {
    return (
      <FlexContainer justifyContent="center" alignItems="center">
        <CircularProgress />
      </FlexContainer>
    );
  }

  if (isError) {
    return null;
  }

  const projects = data.data;

  if (projects.length === 0) {
    return null;
  }

  return (
    <FlexContainer style={{ marginTop: '16px' }}>
      {projects.map(project => (
        <div key={project.id}>
          <Project onClick={() => handleProjectClick(project)}>
            {selectedProject?.id === project.id ? (
              <DoneIcon color="primary" />
            ) : (
              <TextSnippetIcon color="action" />
            )}
            <Typography variant="body1">{project.name}</Typography>
          </Project>
          <Divider />
        </div>
      ))}
    </FlexContainer>
  );
};

const Project = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: ${({ theme }) => theme.spacings.large};
  padding: 6px 16px;
  cursor: pointer;
`;

const MagnifyingGlassIcon = styled(SearchIcon)`
  color: ${({ theme }) => theme.colors.blackDisabled};
`;

const StyledTextField = styled(TextField)`
  margin-bottom: ${({ theme }) => theme.spacings.medium};
  margin-top: ${({ theme }) => theme.spacings.medium};
`;
