import { Button } from '@mui/material';
import { ColoredTypography } from 'components/ColoredTypography';
import FlexContainer from 'components/FlexContainer';
import TextInputField from 'components/forms/TextInputField';
import { YellowCrownImage } from 'components/icons/YellowCrownImage';
import { useModal } from 'components/modals';
import { getGetIsFeatureAvailable } from 'features/features/store/selectors';
import { getIsSeoLimitReached } from 'features/pricing/store/selectors';
import useUpdateSubscriptionModal from 'features/updateSubscriptionModal/hook/useUpdateSubscriptionModal';
import { Form, useFormikContext } from 'formik';
import { MouseEvent } from 'react';
import { FormattedMessage } from 'react-intl';
import gtmIds from 'services/tracking/GTMIds';
import { useAppSelector } from 'store/hooks';
import styled from 'styled-components';
import useTr from 'utils/hooks/useTr';

import SearchEngineAutocomplete from './SearchEngineAutocomplete';

export const searchEngineField = 'searchEngine';
export const keywordField = 'keyword';

export type SeoAnalysisFormValues = {
  [searchEngineField]: string;
  [keywordField]: string;
};

type Props = {
  isLoading?: boolean;
};

const MainSubStepFormSection = ({ isLoading }: Props) => {
  const tr = useTr();

  const isSeoAnalysisAvailable = useAppSelector(getGetIsFeatureAvailable)('seoAnalysis');
  const isLimitReached = useAppSelector(getIsSeoLimitReached);

  const showUpgradeSubscriptionModal = useUpdateSubscriptionModal();

  const { isValid } = useFormikContext<SeoAnalysisFormValues>();

  const isInputDisabled = !isSeoAnalysisAvailable || isLimitReached;
  const isPlanUpgradeRequired = !isSeoAnalysisAvailable || !isValid || isLimitReached;

  const handleSubmitButtonClick = (event: MouseEvent<HTMLButtonElement>) => {
    if (!isPlanUpgradeRequired) {
      return;
    }

    event.preventDefault();
    event.stopPropagation();

    showUpgradeSubscriptionModal();
  };
  return (
    <Form>
      <Box>
        {isSeoAnalysisAvailable && (
          <>
            <SearchEngineAutocomplete name={searchEngineField} disabled={isInputDisabled} />
            <TextInputField
              name={keywordField}
              disabled={isInputDisabled}
              variant="outlined"
              label={tr('aiWriter.inspirations.seoAnalysis.keywords_field.placeholder')}
              placeholder={tr('aiWriter.inspirations.seoAnalysis.keywords_field.placeholder')}
            />
            <PoweredBy />
          </>
        )}
        <Button
          id={gtmIds.aiWriter.seo.analyse}
          variant="contained"
          color="primary"
          type="submit"
          disabled={isLoading}
          fullWidth={true}
          startIcon={isPlanUpgradeRequired && <YellowCrownImage />}
          onClick={handleSubmitButtonClick}
        >
          <FormattedMessage id={isPlanUpgradeRequired ? 'common.upgrade' : 'common.analyse'} />
        </Button>
      </Box>
    </Form>
  );
};

const Box = styled(FlexContainer).attrs({ gap: 'four' })``;

const PoweredBy = () => {
  const { showModal } = useModal();

  return (
    <ColoredTypography variant="body1" color="blackMediumEmphasis" textAlign="right">
      <FormattedMessage
        id="aiWriter.inspirations.seoAnalysis.powered_by_seo"
        values={{
          button: (text: string) => (
            <SeoButton type="button" onClick={() => showModal('SEO', { size: 480 })}>
              {text}
            </SeoButton>
          )
        }}
      />
    </ColoredTypography>
  );
};

const SeoButton = styled.button`
  border: 0;
  background: none;
  cursor: pointer;
  font: inherit;
  font-weight: bold;

  color: ${({ theme }) => theme.mode === 'dark' && theme.colors.commonWhiteMain};
`;

export default MainSubStepFormSection;
