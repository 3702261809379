import { Tooltip } from '@mui/material';
import { ReactComponent as RewriteIcon } from 'assets/icons/icon-rewrite-primary.svg';
import { LoadingButton } from 'components/LoadingButton';
import useCountWordOccurrence from 'features/aiWriter/AiWriterTextEditor/hooks/useCountWordOccurrence';
import useEditorText from 'features/aiWriter/AiWriterTextEditor/hooks/useEditorText';
import { useEditorWordsCount } from 'features/aiWriter/AiWriterTextEditor/hooks/useEditorWordsCount';
import { updateTermSuggestionsThunk } from 'features/aiWriter/store/actions/seoAnalysis/thunks/updateTermSuggestionsThunk';
import { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import gtmIds from 'services/tracking/GTMIds';
import { useAppDispatch } from 'store/hooks';
import useRunInTask from 'utils/hooks/useRunInTask';

type Props = { keyword: string; searchEngine: string };

const SeoRefreshTermSuggestionsButton = ({ keyword, searchEngine }: Props) => {
  const dispatch = useAppDispatch();

  const [isLoading, runInTask] = useRunInTask();
  const editorText = useEditorText();
  const countWordOccurrence = useCountWordOccurrence();
  const editorWordsCount = useEditorWordsCount();

  const isEditorTextEmpty = editorText.length === 0;

  const refreshTermSuggestions = useCallback(async () => {
    await runInTask(() =>
      dispatch(
        updateTermSuggestionsThunk({
          text: editorText,
          keyword,
          searchEngine,
          wordsAmount: editorWordsCount,
          countWordOccurrence
        })
      )
    );
  }, [
    editorText,
    editorWordsCount,
    keyword,
    searchEngine,
    runInTask,
    dispatch,
    countWordOccurrence
  ]);

  const content = (
    <LoadingButton
      id={gtmIds.aiWriter.seo.refresh}
      startIcon={<RewriteIcon />}
      loading={isLoading}
      disabled={isEditorTextEmpty}
      type="button"
      onClick={refreshTermSuggestions}
    >
      <FormattedMessage id="common.refresh" />
    </LoadingButton>
  );

  return isEditorTextEmpty ? (
    <Tooltip
      title={<FormattedMessage id="aiWriter.inspirations.seoAnalysis.statistics.refresh.tooltip" />}
      placement="left"
      arrow
    >
      <div>{content}</div>
    </Tooltip>
  ) : (
    content
  );
};

export default SeoRefreshTermSuggestionsButton;
