import { useQuery } from '@tanstack/react-query';
import LocalStorageKey from 'config/localStorageKey';
import { getCustomerTeamId } from 'features/customer/store/selectors';
import {
  GetCurrentTeamUsageParams,
  httpGetCurrentTeamUsage
} from 'services/backofficeIntegration/http/endpoints/teamMembers/httpGetCurrentTeamUsage';
import { PaginatedListParams } from 'services/backofficeIntegration/http/paginatedListEndpoint';
import { useAppSelector } from 'store/hooks';

export function useGetCurrentTeamUsage(paginatedListParams?: PaginatedListParams) {
  const apiToken = localStorage.getItem(LocalStorageKey.APIToken);
  const teamId = useAppSelector(getCustomerTeamId);

  const queryParams: GetCurrentTeamUsageParams = {
    teamId: teamId || 0,
    ...paginatedListParams
  };

  return useQuery({
    enabled: !!apiToken && !!teamId,
    queryKey: httpGetCurrentTeamUsage.makeQueryKey(queryParams),
    queryFn: () => httpGetCurrentTeamUsage.callEndpoint(queryParams)
  });
}
