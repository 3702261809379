import { AiWriterTab } from 'features/aiWriter/store/types';
import { unnamed } from 'features/aiWriter/utils/unnamed';

export const createDownloadFilename = ({ name }: AiWriterTab, extension = 'html') => {
  const documentName = name || unnamed;

  const documentFileName = `document-${documentName.replace(/[^a-z0-9]/gi, '_')}-${Date.now()}`;

  return {
    withExtension: `${documentFileName}.${extension}`,
    withoutExtension: documentFileName
  };
};
