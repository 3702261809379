import { CheckCircleOutlineRounded, PlayArrow } from '@mui/icons-material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ToggleOffIcon from '@mui/icons-material/ToggleOff';
import ToggleOnIcon from '@mui/icons-material/ToggleOn';
import {
  Button,
  CircularProgress,
  Divider,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow
} from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { SearchField } from 'components/SearchField';
import { getRoutePath } from 'config/routes';
import dayjs from 'dayjs';
import { getUserId } from 'features/customer/store/selectors';
import FormattedMessage from 'features/i18n/FormattedMessage';
import { RunnerModularWorkflow } from 'features/modular-workflow/runner/types-runner';
import { trackingWrapper } from 'features/tracking/wrapper/TrackingWrapper';
import { ChangeEvent } from 'react';
import { useNavigate } from 'react-router';
import { httpDeleteModularWorkflow } from 'services/backofficeIntegration/http/endpoints/modularWorkflow/httpDeleteModularWorkflow';
import { httpEnableModularWorkflow } from 'services/backofficeIntegration/http/endpoints/modularWorkflow/httpEnableModularWorkflow';
import { invalidateWorkflowsQuery } from 'services/backofficeIntegration/http/endpoints/modularWorkflow/httpGetModularWorkflows';
import gtmIds from 'services/tracking/GTMIds';
import { withGtmInteraction } from 'services/tracking/withGtmInteraction';
import { useAppSelector } from 'store/hooks';
import styled from 'styled-components';
import useDropdown from 'utils/hooks/useDropdown';

type Props = {
  workflows: RunnerModularWorkflow[];
  page: number;
  limit: number;
  total: number;
  onChangePage: (_event: unknown | null, page: number) => void;
  onChangeRowsPerPage: (event: ChangeEvent<HTMLInputElement>) => void;
};

export function WorkflowsTable({
  workflows,
  page,
  limit,
  total,
  onChangePage,
  onChangeRowsPerPage
}: Props) {
  const customerId = useAppSelector(getUserId);

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell align="left">
              <FormattedMessage id="flashHub.overview.tab.workflows.table.name" />
            </TableCell>
            <TableCell align="left">
              <FormattedMessage id="flashHub.overview.tab.workflows.table.description" />
            </TableCell>
            <TableCell align="left">
              <FormattedMessage id="flashHub.overview.tab.workflows.table.permission" />
            </TableCell>
            <TableCell align="left">
              <FormattedMessage id="flashHub.overview.tab.workflows.table.owner" />
            </TableCell>
            <TableCell align="left">
              <FormattedMessage id="flashHub.overview.tab.workflows.table.date" />
            </TableCell>
            <TableCell align="left">
              <FormattedMessage id="flashHub.overview.tab.workflows.table.enabled" />
            </TableCell>
            <TableCell align="left"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {workflows.map(workflow => {
            const isOwner = workflow.customer_id === customerId;

            return (
              <ClickableRow hover key={workflow.id}>
                <TableCell align="left">{workflow.name}</TableCell>
                <TableCell align="left">{workflow.description}</TableCell>
                <TableCell align="left">
                  <FormattedMessage
                    id={`flashHub.overview.tab.workflows.table.permission.${workflow.sharing_permission}`}
                  />
                </TableCell>
                <TableCell align="left">
                  {isOwner ? (
                    <FormattedMessage id="flashHub.overview.tab.workflows.table.owner.you" />
                  ) : (
                    workflow.owner_name
                  )}
                </TableCell>
                <TableCell align="left">
                  {dayjs(workflow.created_at).format('DD.MM.YYYY')}
                </TableCell>
                <TableCell align="left">
                  {workflow.is_enabled ? <GreenCheckIcon /> : null}
                </TableCell>
                <TableCell align="left">
                  <WorkflowMenu workflow={workflow} isOwner={isOwner} />
                </TableCell>
              </ClickableRow>
            );
          })}
        </TableBody>
      </Table>
      <TablePagination
        count={total}
        page={page}
        rowsPerPage={limit}
        onPageChange={onChangePage}
        rowsPerPageOptions={[5, 10, 25]}
        onRowsPerPageChange={onChangeRowsPerPage}
        component="div"
      />
    </TableContainer>
  );
}

const ClickableRow = styled(TableRow)`
  cursor: pointer;
`;

type TableControlsProps = {
  searchQuery: string;
  onSearchChange: (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
};

export function TableControls(props: TableControlsProps) {
  const handleCreateClick = () => {
    trackingWrapper.track('workflowCreateClick');
  };

  return (
    <ControlsBox>
      <SearchField value={props.searchQuery} onChange={props.onSearchChange} />

      <Button
        {...withGtmInteraction(gtmIds.workflows.overview.create)}
        startIcon={<AddCircleIcon fontSize="small" />}
        variant="contained"
        href={getRoutePath('modularWorkflowCreateNew')}
        onClick={handleCreateClick}
      >
        <FormattedMessage id="common.create" />
      </Button>
    </ControlsBox>
  );
}

const ControlsBox = styled.div`
  display: flex;
  justify-content: space-between;
`;

type WorkflowMenuProps = {
  workflow: RunnerModularWorkflow;
  isOwner: boolean;
};

function WorkflowMenu({ workflow, isOwner }: WorkflowMenuProps) {
  const { anchorElement, isOpen, open, close } = useDropdown();
  const navigate = useNavigate();

  const { mutate: toggleWorkflow, isLoading: isToggling } = useMutation({
    mutationFn: () =>
      httpEnableModularWorkflow.callEndpoint({ id: workflow.id, is_enabled: !workflow.is_enabled }),
    onSuccess: () => invalidateWorkflowsQuery()
  });

  const { mutate: deleteWorkflow, isLoading: isDeleting } = useMutation({
    mutationFn: () => httpDeleteModularWorkflow.callEndpoint({ id: workflow.id }),
    onSuccess: () => invalidateWorkflowsQuery()
  });

  const handleToggleClick = () => {
    toggleWorkflow();
    close();

    trackingWrapper.track('workflowToggle', {
      isEnabled: !workflow.is_enabled
    });
  };

  const handleDeleteClick = () => {
    deleteWorkflow();
    close();

    trackingWrapper.track('workflowDelete');
  };

  const handleRunWorkflowClick = () => {
    navigate(getRoutePath('modularWorkflowRun', { workflowId: workflow.id }));
  };

  const handleEditWorkflowClick = () => {
    navigate(getRoutePath('modularWorkflowEdit', { workflowId: workflow.id }));
  };

  const isLoading = isToggling || isDeleting;

  if (isLoading) {
    return <CircularProgress />;
  }

  return (
    <>
      <IconButton onClick={open}>
        <MoreVertIcon />
      </IconButton>

      <StyledMenu anchorEl={anchorElement} open={isOpen} onClose={close}>
        <MenuItem onClick={handleRunWorkflowClick}>
          <ListItemIcon>
            <PlayArrow fontSize="small" />
          </ListItemIcon>
          <FormattedMessage id="flashHub.overview.tab.workflows.table.run" />
        </MenuItem>
        {isOwner && [
          <MenuItem key="edit" onClick={handleEditWorkflowClick}>
            <ListItemIcon>
              <EditIcon fontSize="small" />
            </ListItemIcon>
            <FormattedMessage id="flashHub.overview.tab.workflows.table.edit" />
          </MenuItem>,
          <MenuItem
            key="enable"
            {...withGtmInteraction(gtmIds.workflows.overview.toggle)}
            onClick={handleToggleClick}
          >
            <ListItemIcon>
              {workflow.is_enabled ? (
                <StyledToggleOnIcon fontSize="small" />
              ) : (
                <ToggleOffIcon fontSize="small" />
              )}
            </ListItemIcon>
            {workflow.is_enabled ? 'Disable' : 'Enable'}
          </MenuItem>,
          <Divider key="divider-1" />,
          <MenuItem
            key="delete"
            {...withGtmInteraction(gtmIds.workflows.overview.delete)}
            onClick={handleDeleteClick}
          >
            <ListItemIcon>
              <DeleteIcon fontSize="small" />
            </ListItemIcon>
            <FormattedMessage id="common.delete" />
          </MenuItem>
        ]}
      </StyledMenu>
    </>
  );
}

const StyledToggleOnIcon = styled(ToggleOnIcon)`
  color: ${({ theme }) => theme.colors.primary};
`;

const StyledMenu = styled(Menu)`
  & .MuiPaper-root {
    width: 13rem;
  }
`;

const GreenCheckIcon = styled(CheckCircleOutlineRounded)`
  fill: ${({ theme }) => theme.colors.success};
  color: ${({ theme }) => theme.colors.white};
`;
