import {
  InputRounded,
  MergeOutlined,
  SettingsOutlined,
  ShareRounded,
  SvgIconComponent,
  WebhookOutlined
} from '@mui/icons-material';
import { createSvgIcon } from '@mui/material';
import { ELEMENT_PARAGRAPH } from '@udecode/plate-paragraph';
import { ReactComponent as MagicFeatherSparklesIcon } from 'assets/icons/icon-magic-feather-sparkle.svg';
import { ReactComponent as AiImagesIcon } from 'assets/sidebar/icon-ai-images.svg';
import { AppLanguage } from 'features/language/store/types';
import {
  BuilderModularWorkflowStep,
  BuilderModularWorkflowStepInput,
  BuilderModularWorkflowStepInputWithLocalizedValue,
  BuilderModularWorkflowStepInputWithValue
} from 'features/modular-workflow/builder/types-builder';
import { createUuidV4 } from 'utils/createUuidV4';

export const notDeletableStepReferences = ['destination', 'put_it_all_together'] as const;

export const mutableStepReferences = [
  'text_input',
  'text_type',
  'text_generation_settings',
  'ai_images',
  'ai_images_settings',
  'url_scrape'
] as const;

type DefaultStepReference = typeof notDeletableStepReferences[number];
type MutableStepReference = typeof mutableStepReferences[number];

export type ApiModularWorkflowStepReference = DefaultStepReference | MutableStepReference;

export type ApiModularWorkflowStepReferenceExtended =
  `${ApiModularWorkflowStepReference}_${string}`;

const MagicFeatherSparklesSvgIcon = createSvgIcon(
  <MagicFeatherSparklesIcon />,
  'MagicFeatherSparklesSvgIcon'
);

const AiImagesSvgIcon = createSvgIcon(<AiImagesIcon />, 'AiImagesSvgIcon');

export const ApiModularWorkflowStepReferenceIcons: Record<
  ApiModularWorkflowStepReference,
  SvgIconComponent
> = {
  text_input: InputRounded,
  destination: ShareRounded,
  put_it_all_together: MergeOutlined,
  text_type: MagicFeatherSparklesSvgIcon,
  text_generation_settings: SettingsOutlined,
  ai_images: AiImagesSvgIcon,
  ai_images_settings: SettingsOutlined,
  url_scrape: WebhookOutlined
};

export type ApiModularWorkflowStep = {
  reference: ApiModularWorkflowStepReference;
  name: string;
  description: string;
  settings: ApiModularWorkflowStepSettings[];
  outputs: ApiModularWorkflowStepOutput[];
  fields: unknown[];
};

export type ApiModularWorkflowStepSettingsTypeText = 'text';
export type ApiModularWorkflowStepSettingsTypeLocalizableText = 'localizable_text';
export type ApiModularWorkflowStepSettingsTypeLargeText = 'large_text';
export type ApiModularWorkflowStepSettingsTypeBoolean = 'boolean';
export type ApiModularWorkflowStepSettingsTypeCheckbox = 'checkbox';
export type ApiModularWorkflowStepSettingsTypeImageCount = 'image_count';
export type ApiModularWorkflowStepSettingsTypePrompts = 'prompts';
export type ApiModularWorkflowStepSettingsTypeTonality = 'tonality';
export type ApiModularWorkflowStepSettingsTypeBrandVoice = 'brand_voice';
export type ApiModularWorkflowStepSettingsTypeLengthOfOutput = 'length_of_output';
export type ApiModularWorkflowStepSettingsTypeTextGenerationModel = 'text_generation_model';
export type ApiModularWorkflowStepSettingsTypeLanguage = 'language';
export type ApiModularWorkflowStepSettingsTypeImageRatio = 'image_ratio';
export type ApiModularWorkflowStepSettingsTypeImageQuality = 'image_quality';
export type ApiModularWorkflowStepSettingsTypeDestinationFormat = 'destination_format';
export type ApiModularWorkflowStepSettingsTypeEditorDocument = 'document';
export type ApiModularWorkflowStepSettingsTypeInformationCollection = 'information_collection';

export type ApiModularWorkflowStepSettingsType =
  | ApiModularWorkflowStepSettingsTypeText
  | ApiModularWorkflowStepSettingsTypeLocalizableText
  | ApiModularWorkflowStepSettingsTypeLargeText
  | ApiModularWorkflowStepSettingsTypeBoolean
  | ApiModularWorkflowStepSettingsTypeCheckbox
  | ApiModularWorkflowStepSettingsTypeImageCount
  | ApiModularWorkflowStepSettingsTypePrompts
  | ApiModularWorkflowStepSettingsTypeTonality
  | ApiModularWorkflowStepSettingsTypeBrandVoice
  | ApiModularWorkflowStepSettingsTypeLengthOfOutput
  | ApiModularWorkflowStepSettingsTypeTextGenerationModel
  | ApiModularWorkflowStepSettingsTypeLanguage
  | ApiModularWorkflowStepSettingsTypeImageRatio
  | ApiModularWorkflowStepSettingsTypeImageQuality
  | ApiModularWorkflowStepSettingsTypeDestinationFormat
  | ApiModularWorkflowStepSettingsTypeEditorDocument
  | ApiModularWorkflowStepSettingsTypeInformationCollection;

export type ApiModularWorkflowStepSettings =
  | ApiModularWorkflowStepSettingsText
  | ApiModularWorkflowStepSettingsLocalizableText
  | ApiModularWorkflowStepSettingsLargeText
  | ApiModularWorkflowStepSettingsBoolean
  | ApiModularWorkflowStepSettingsCheckbox
  | ApiModularWorkflowStepSettingsImageCount
  | ApiModularWorkflowStepSettingsImagePrompts
  | ApiModularWorkflowStepSettingsTonality
  | ApiModularWorkflowStepSettingsBrandVoice
  | ApiModularWorkflowStepSettingsLengthOfOutput
  | ApiModularWorkflowStepSettingsTextGenerationModel
  | ApiModularWorkflowStepSettingsLanguage
  | ApiModularWorkflowStepSettingsImageRatio
  | ApiModularWorkflowStepSettingsImageQuality
  | ApiModularWorkflowStepSettingsDestinationFormat
  | ApiModularWorkflowStepSettingsPutItAllTogetherField
  | ApiModularWorkflowStepSettingsInformationCollection;

export const localizedApiModularWorkflowStepSettings: ApiModularWorkflowStepSettingsType[] = [
  'localizable_text'
];

export type ApiModularWorkflowStepSettingsBase<T = ApiModularWorkflowStepSettingsType> = {
  data_type: { type: T };
  name: string;
  description: string;
  reference: ApiModularWorkflowStepReferenceExtended;
  default_value: ApiModularWorkflowStepSettingsValue;
  is_required: boolean;
  is_editable_in_workflow: boolean;
};

export type ApiModularWorkflowStepSettingsText =
  ApiModularWorkflowStepSettingsBase<ApiModularWorkflowStepSettingsTypeText> & {
    options: null;
  };

export type ApiModularWorkflowStepSettingsLocalizableText =
  ApiModularWorkflowStepSettingsBase<ApiModularWorkflowStepSettingsTypeLocalizableText> & {
    options: null;
  };

export type ApiModularWorkflowStepSettingsLargeText =
  ApiModularWorkflowStepSettingsBase<ApiModularWorkflowStepSettingsTypeLargeText> & {
    options: null;
  };

export type ApiModularWorkflowStepSettingsBoolean =
  ApiModularWorkflowStepSettingsBase<ApiModularWorkflowStepSettingsTypeBoolean> & {
    default_value: boolean;
    options: null;
  };

export type ApiModularWorkflowStepSettingsCheckbox =
  ApiModularWorkflowStepSettingsBase<ApiModularWorkflowStepSettingsTypeCheckbox> & {
    default_value: string;
    options: ApiModularWorkflowStepSettingsOption<string>[];
  };

export type ApiModularWorkflowStepSettingsImageCount =
  ApiModularWorkflowStepSettingsBase<ApiModularWorkflowStepSettingsTypeImageCount> & {
    default_value: number;
    options: ApiModularWorkflowStepSettingsOption<number>[];
  };

export type ApiModularWorkflowStepSettingsImagePrompts =
  ApiModularWorkflowStepSettingsBase<ApiModularWorkflowStepSettingsTypePrompts> & {
    default_value: string;
    options: ApiModularWorkflowStepSettingsOption<string>[];
  };

export type ApiModularWorkflowStepSettingsTonality =
  ApiModularWorkflowStepSettingsBase<ApiModularWorkflowStepSettingsTypeTonality> & {
    // TODO: Use local tonality options?
    default_value: string;
    options: ApiModularWorkflowStepSettingsOption<string>[];
  };

export type ApiModularWorkflowStepSettingsBrandVoice =
  ApiModularWorkflowStepSettingsBase<ApiModularWorkflowStepSettingsTypeBrandVoice> & {
    default_value: number;
    options: ApiModularWorkflowStepSettingsOption<number>[];
  };

export type ApiModularWorkflowStepSettingsLengthOfOutput =
  ApiModularWorkflowStepSettingsBase<ApiModularWorkflowStepSettingsTypeLengthOfOutput> & {
    default_value: string;
    options: ApiModularWorkflowStepSettingsOption<'short' | 'medium' | 'long'>[];
  };

export type ApiModularWorkflowStepSettingsTextGenerationModel =
  ApiModularWorkflowStepSettingsBase<ApiModularWorkflowStepSettingsTypeTextGenerationModel> & {
    default_value: string;
    options: ApiModularWorkflowStepSettingsOption<'gpt-3.5-turbo' | 'gpt-4'>[];
  };

export type ApiModularWorkflowStepSettingsLanguage =
  ApiModularWorkflowStepSettingsBase<ApiModularWorkflowStepSettingsTypeLanguage> & {
    default_value: string;
    // TODO: Replace with audience options
    options: ApiModularWorkflowStepSettingsOption<string>[];
  };

export type ApiModularWorkflowStepSettingsImageRatio =
  ApiModularWorkflowStepSettingsBase<ApiModularWorkflowStepSettingsTypeImageRatio> & {
    default_value: string;
    options: ApiModularWorkflowStepSettingsOption<string>[];
  };

export type ApiModularWorkflowStepSettingsImageQuality =
  ApiModularWorkflowStepSettingsBase<ApiModularWorkflowStepSettingsTypeImageQuality> & {
    default_value: string;
    options: ApiModularWorkflowStepSettingsOption<string>[];
  };

export type ApiModularWorkflowStepSettingsDestinationFormat =
  ApiModularWorkflowStepSettingsBase<ApiModularWorkflowStepSettingsTypeDestinationFormat> & {
    default_value: string;
    options: ApiModularWorkflowStepSettingsOption<'document'>[];
  };

export type ApiModularWorkflowStepSettingsPutItAllTogetherField =
  ApiModularWorkflowStepSettingsBase<ApiModularWorkflowStepSettingsTypeEditorDocument> & {
    default_value: string;
    options: null;
  };

export type ApiModularWorkflowStepSettingsInformationCollection =
  ApiModularWorkflowStepSettingsBase<ApiModularWorkflowStepSettingsTypeInformationCollection> & {
    default_value: number[];
    options: ApiModularWorkflowStepSettingsOption<number>[];
  };

export type ApiModularWorkflowStepSettingsOption<TValue = ApiModularWorkflowStepSettingsValue> = {
  value: TValue;
  label: string;
};

export type ApiModularWorkflowStepSettingsValue =
  | string
  | string[]
  | number
  | number[]
  | boolean
  | null;

export type ApiModularWorkflowStepOutputType =
  | 'text'
  | 'localizable_text'
  | 'length_of_output'
  | 'text_generation_model'
  | 'language'
  | 'brand_voice'
  | 'tonality'
  | 'url_collection'
  | 'image_count'
  | 'image_ratio'
  | 'image_quality'
  | 'destination_format'
  | 'document'
  | 'information_collection';

export type ApiModularWorkflowStepOutput = {
  default_alias: string;
  data_type: ApiModularWorkflowStepOutputType;
  reference: ApiModularWorkflowStepReferenceExtended;
  related_input_reference: ApiModularWorkflowStepReferenceExtended | null;
  name: string;
};

export const ApiModularWorkflowSharingPermissions = {
  workspace: 'workspace',
  public: 'public',
  my: 'my',
  other_workspaces: 'other_workspaces'
} as const;
export type ApiModularWorkflowSharingPermission = keyof typeof ApiModularWorkflowSharingPermissions;

export const isLocalizableSetting = (dataType: string): boolean =>
  localizedApiModularWorkflowStepSettings.includes(dataType as ApiModularWorkflowStepSettingsType);

export const isLocalizableInput = (
  input: BuilderModularWorkflowStepInput
): input is BuilderModularWorkflowStepInputWithLocalizedValue => input.is_localized;

export const createStepFromStructure = (
  stepStructure: ApiModularWorkflowStep,
  order = 1
): BuilderModularWorkflowStep => ({
  id: createUuidV4(),
  name: stepStructure.name,
  name_localized: {
    [AppLanguage.English]: stepStructure.name,
    [AppLanguage.German]: stepStructure.name,
    [AppLanguage.Spanish]: stepStructure.name
  },
  reference: stepStructure.reference,
  order,
  inputs: stepStructure.settings.map(s => {
    let patchedValue = s.default_value;

    // TODO: This must be fixed by API!
    //       Hardcoded patch to fix default value for tonality selection which is null by default
    //       but must be an array during workflow creation
    if (s.reference === 'text_generation_settings_tonality') {
      patchedValue = s.default_value ?? [];
    }

    // More extensive default value to prevent a damaged editor document
    if (s.reference === 'put_it_all_together_field') {
      patchedValue =
        s.default_value ||
        JSON.stringify([
          {
            type: ELEMENT_PARAGRAPH,
            children: [{ text: '' }]
          }
        ]);
    }

    if (isLocalizableSetting(s.data_type.type)) {
      const input: BuilderModularWorkflowStepInputWithLocalizedValue = {
        reference: s.reference,
        value: {
          [AppLanguage.English]: patchedValue,
          [AppLanguage.German]: patchedValue,
          [AppLanguage.Spanish]: patchedValue
        },
        is_editable: s.is_editable_in_workflow,
        is_localized: true
      };

      return input;
    }

    const input: BuilderModularWorkflowStepInputWithValue = {
      reference: s.reference,
      value: patchedValue,
      is_editable: s.is_editable_in_workflow,
      is_localized: false
    };

    return input;
  }),
  outputs: stepStructure.outputs.map(o => ({
    reference: o.reference,
    related_input_reference: o.related_input_reference,
    alias: o.default_alias
  }))
});

export const isNotDeletableStepReference = (reference: string): reference is DefaultStepReference =>
  notDeletableStepReferences.includes(reference as DefaultStepReference);

export const isMutableStepReference = (reference: string): reference is MutableStepReference =>
  mutableStepReferences.includes(reference as MutableStepReference);

export const outputAliasRegex = /^[a-zA-Z0-9\-_]+$/;
