import { ComponentPropsWithoutRef, forwardRef, Ref } from 'react';
import styled from 'styled-components';

const countryFlagsDirectory = require.context('../../assets/flags');
const flagAssetNames = countryFlagsDirectory.keys();

type LanguageAndCountryParam = {
  /**
   * Note: This can also be some non-iso code, like "usuk",
   *       if a flag asset named like this exists in the folder
   */
  language?: string;
  country?: string;
};

const getFlagPath = (code: LanguageAndCountryParam) => {
  let path = `./${code.language}-${code.country}.svg`;
  if (!flagAssetNames.includes(path)) {
    path = `./${code.language}.svg`;
  }
  if (!flagAssetNames.includes(path)) {
    path = `./${code.country}.svg`;
  }
  if (!flagAssetNames.includes(path)) {
    path = './null.svg';
  }

  // Note: Since we used mixed imports in SVGR, it returns both, the path and the react component
  //       This must be changed once we remove named imports
  const iconModule = countryFlagsDirectory(path);
  if (typeof iconModule === 'object' && iconModule.default) {
    return iconModule.default;
  }

  // Expect to be a string import
  return iconModule;
};

type FlagProps = LanguageAndCountryParam;

type Props = FlagProps & ComponentPropsWithoutRef<'img'>;

const CountryFlag = forwardRef(
  ({ language, country, ...rest }: Props, ref: Ref<HTMLImageElement>) => {
    const altText = country && language ? `${language}-${country}` : language ?? '';

    return (
      <StyledImage src={getFlagPath({ language, country })} alt={altText} ref={ref} {...rest} />
    );
  }
);

const StyledImage = styled('img')`
  width: 24px;
  height: 24px;

  border-radius: ${({ theme }) => theme.borderRadius.medium};
`;

export default CountryFlag;
