import { FileCopyOutlined } from '@mui/icons-material';
import { IconButton, IconButtonSize } from 'components/iconButtons/IconButton';
import Toast from 'components/toasts/Toast';
import React from 'react';

type Props = {
  text: string;
  id?: string;
  isLoading?: boolean;
  tooltip?: React.ReactNode;
  size: IconButtonSize;

  onClick?: () => void;
};

export const CopyButton = ({ id, tooltip, isLoading, text, size = 'large', onClick }: Props) => (
  <IconButton
    id={id}
    onClick={() => {
      navigator.clipboard.writeText(text);
      Toast.success('common.copied');

      onClick?.();
    }}
    disabled={isLoading}
    isLoading={isLoading}
    tooltip={tooltip}
    icon={<FileCopyOutlined fontSize={size} />}
    size={size}
  />
);

export default CopyButton;
