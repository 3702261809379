
type Props = { embedSrc: string; resolution?: '16by9' | '21by9' | '4by3' | '1by1' };

const EmbedContainer = ({ embedSrc, resolution = '16by9' }: Props) => (
  <div className={`embed-responsive embed-responsive-${resolution}`}>
    <iframe src={embedSrc} title={embedSrc} frameBorder="0" allowFullScreen allow="autoplay" />
  </div>
);

export default EmbedContainer;
