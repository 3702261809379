import SpellcheckIcon from '@mui/icons-material/Spellcheck';
import { Tooltip } from '@mui/material';
import { focusEditor } from '@udecode/plate-common';
import { PulseDot } from 'components/PulseDot';
import LocalStorageKey from 'config/localStorageKey';
import {
  EditorFormatButton,
  EditorFormatButtonProps,
  EditorIconFormatButton
} from 'features/aiWriter/AiWriterTextEditor/EditorIconFormatButton';
import useEditor from 'features/aiWriter/AiWriterTextEditor/hooks/useEditor';
import { useShouldShowPulseIndicator } from 'features/aiWriter/AiWriterTextEditor/languageToolButton/useShouldShowPulseIndicator';
import { trackEditorFormatAction } from 'features/aiWriter/AiWriterTextEditor/trackEditorFormatAction';
import { getIconColor } from 'features/aiWriter/AiWriterTextEditor/utils/getIconColor';
import { useCustomerPreferences } from 'features/customerPreferences/useCustomerPreferences';
import { useUpdateCustomerPreferences } from 'features/customerPreferences/useUpdateCustomerPreferences';
import FormattedMessage from 'features/i18n/FormattedMessage';
import { useEmbeddedLanguageToolWidget } from 'features/languageToolIntegration/useEmbeddedLanguageToolWidget';
import { useState } from 'react';
import styled from 'styled-components';
import { useLocalStorage } from 'utils/hooks/useLocalStorage';

type LanguageToolsButtonProps = {
  styleVariant?: EditorFormatButtonProps;
  onClose?: () => void;
};

export function LanguageToolsButton({ onClose, styleVariant }: LanguageToolsButtonProps) {
  const { data: preferences } = useCustomerPreferences();
  const [isActive, setIsActive] = useState(preferences?.languageToolEnabled ?? false);

  const enableLanguageTools = useEmbeddedLanguageToolWidget();
  const { mutateAsync: updatePreferences } = useUpdateCustomerPreferences();

  const editor = useEditor();

  const [indicatorSeen, isIndicatorSeen] = useLocalStorage(
    LocalStorageKey.AiWriterEditorLanguageToolsIndicatorSeen,
    false
  );
  const shouldShowPulseIndicator = useShouldShowPulseIndicator(indicatorSeen);

  const handleClick = () => {
    enableLanguageTools(!isActive);
    setIsActive(!isActive);
    isIndicatorSeen(true);

    updatePreferences({ languageToolEnabled: !isActive });
    trackEditorFormatAction('languageTools');
    focusEditor(editor);

    onClose && onClose();
  };

  if (styleVariant === 'icon') {
    return (
      <EditorIconFormatButton onClick={handleClick} $isActive={isActive}>
        <Tooltip
          arrow
          title={<FormattedMessage id="aiWriter.toolbar.languagetools" />}
          placement="bottom"
        >
          <SpellcheckIcon color={getIconColor(isActive)} fontSize="small" />
        </Tooltip>

        {shouldShowPulseIndicator && (
          <PulseDotContainer>
            <PulseDot $duration="infinite" />
          </PulseDotContainer>
        )}
      </EditorIconFormatButton>
    );
  }

  return (
    <EditorFormatButton onClick={handleClick} $isActive={isActive}>
      <SpellcheckIcon color={getIconColor(isActive)} fontSize="small" />
      <FormattedMessage id="aiWriter.toolbar.languagetools" />
    </EditorFormatButton>
  );
}

const PulseDotContainer = styled.div`
  position: absolute;
  bottom: 2px;
  right: 2px;
`;
