import { Dimension, NewDimension } from 'services/api/dimension/types';
import { ThunkMeta } from 'types/ReduxStateTypes';
import { createStandardAction } from 'typesafe-actions';
import { createStandardAsyncAction } from 'utils/reduxUtils';

import { DimensionUpdate } from './types';

export const getDimensions = createStandardAsyncAction(
  'dimensions/GET_DIMENSIONS_REQUEST',
  'dimensions/GET_DIMENSIONS_SUCCESS',
  'dimensions/GET_DIMENSIONS_FAILURE'
)<undefined, Dimension[], undefined>();

export const addDimension = createStandardAsyncAction(
  'dimensions/ADD_DIMENSION_REQUEST',
  'dimensions/ADD_DIMENSION_SUCCESS',
  'dimensions/ADD_DIMENSION_FAILURE'
)<NewDimension, number, Record<string, string> | undefined, ThunkMeta>();

export const addToExistingDimension = createStandardAsyncAction(
  'dimensions/ADD_TO_EXISTING_DIMENSION_REQUEST',
  'dimensions/ADD_TO_EXISTING_DIMENSION_SUCCESS',
  'dimensions/ADD_TO_EXISTING_DIMENSION_FAILURE'
)<{ id: number; words: string[] }, number, undefined, ThunkMeta>();

export const updateDimension = createStandardAsyncAction(
  'dimensions/UPDATE_DIMENSION_REQUEST',
  'dimensions/UPDATE_DIMENSION_SUCCESS',
  'dimensions/UPDATE_DIMENSION_FAILURE'
)<DimensionUpdate, number, undefined, ThunkMeta>();

export const removeDimension = createStandardAsyncAction(
  'dimensions/REMOVE_DIMENSION_REQUEST',
  'dimensions/REMOVE_DIMENSION_SUCCESS',
  'dimensions/REMOVE_DIMENSION_FAILURE'
)<number, undefined, undefined, ThunkMeta>();

export const combineDimensions = createStandardAsyncAction(
  'dimensions/COMBINE_DIMENSIONS_REQUEST',
  'dimensions/COMBINE_DIMENSIONS_SUCCESS',
  'dimensions/COMBINE_DIMENSIONS_FAILURE'
)<{ sourceId: number; targetId: number; deleteSource: boolean }, number, undefined, ThunkMeta>();

export const removeMultipleDimensions = createStandardAsyncAction(
  'dimensions/REMOVE_MULTIPLE_DIMENSIONS_REQUEST',
  'dimensions/REMOVE_MULTIPLE_DIMENSIONS_SUCCESS',
  'dimensions/REMOVE_MULTIPLE_DIMENSIONS_FAILURE'
)<number[], undefined, undefined, ThunkMeta>();

export const selectDimension = createStandardAction('dimensions/SELECT_DIMENSION')<number>();

export const showDimensionsWords = createStandardAction(
  'dimensions/SHOW_DIMENSIONS_WORDS'
)<number>();
