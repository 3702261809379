import React, { ElementType, forwardRef } from 'react';
import { css } from 'styled-components';

import EmptyButton from '../../base/EmptyButton';

type Props<T extends ElementType> = { active: boolean } & React.ComponentPropsWithRef<T>;

const PinImage = ({ active, ...props }: Props<'svg'>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    css={css`
      width: 1rem;
      height: 1rem;
      vertical-align: -3px;
      stroke: ${active ? '#495057' : '#9aa0ac'};
      transition: transform 0.5s, stroke 0.5s;
      transform: ${active ? 'rotate(0deg)' : 'rotate(90deg)'};

      &:hover {
        stroke: #495057;
      }
    `}
    {...props}
  >
    <path d="M6 1 18 1z M8 1 16 1 16 8 8 8z M16 8z M8 8z M8 8 16 8 19 14 5 14z M12 14 12 14 12 22 12 22z" />
  </svg>
);

// eslint-disable-next-line react/prop-types
const Pin = forwardRef<HTMLButtonElement, Props<'button'>>(({ active, onClick, ...props }, ref) => (
  <EmptyButton className="ml-1" onClick={onClick} ref={ref} {...props}>
    <PinImage active={active} />
  </EmptyButton>
));

export default Pin;
