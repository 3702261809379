import { BaseSuccessDto } from 'services/backofficeIntegration/http/BaseSuccessDto';
import { backofficeClient } from 'services/backofficeIntegration/http/client/createBackofficeClient';
import { mutationEndpoint } from 'services/backofficeIntegration/http/endpointDefinition';

type SuccessDto = BaseSuccessDto<never>;

export type StopStreamMessageRequestParams = {
  conversationId: string | null;
};

export const httpStopStreamMessage = mutationEndpoint({
  callEndpoint: (params: StopStreamMessageRequestParams) =>
    backofficeClient
      .put<SuccessDto>(`/conversations/${params.conversationId}/messages/stop`, params)
      .then(response => response.data)
});
