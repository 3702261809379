import { ModelAutocomplete } from 'features/embeddingModels/ModelAutocomplete';
import {
  getEmbeddingModelsByCountry,
  getGetEmbeddingModelsForCountry
} from 'features/embeddingModels/store/selectors';
import { APIModel } from 'services/api/embeddingModels/types';
import { useAppSelector } from 'store/hooks';

type Props = {
  onChange: (latestModel: APIModel) => void;
};

const CountryPicker = ({ onChange }: Props) => {
  const modelsByCountry = useAppSelector(getEmbeddingModelsByCountry);
  const getModelsForCountry = useAppSelector(getGetEmbeddingModelsForCountry);

  const handleChange = (newValue: string) => {
    const newModel = modelsByCountry.find(model => model.id === newValue);
    if (!newModel) {
      return;
    }

    const latestModel = getModelsForCountry(newModel.country, newModel.language)[0];
    if (!latestModel) {
      return;
    }

    onChange(latestModel);
  };

  return <ModelAutocomplete name="language" models={modelsByCountry} onSelect={handleChange} />;
};

export default CountryPicker;
