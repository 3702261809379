import { Switch, SwitchProps } from '@mui/material';
import { useField } from 'formik';

type Props = {
  name: string;
} & SwitchProps;

const SwitchField = ({ name, ...rest }: Props) => {
  const [field, , helper] = useField<boolean>({ name });

  return (
    <Switch
      checked={field.value}
      color="primary"
      onChange={(_, checked) => helper.setValue(checked)}
      {...rest}
    />
  );
};

export default SwitchField;
