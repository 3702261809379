import {
  closestCenter,
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors
} from '@dnd-kit/core';
import { restrictToFirstScrollableAncestor, restrictToHorizontalAxis } from '@dnd-kit/modifiers';
import {
  horizontalListSortingStrategy,
  SortableContext,
  sortableKeyboardCoordinates
} from '@dnd-kit/sortable';
import { BuilderModularWorkflowStep } from 'features/modular-workflow/builder/types-builder';
import { useModulareWorkflowCreationStore } from 'features/modular-workflow/builder/useModulareWorkflowCreationStore';
import { ReactNode } from 'react';
import styled from 'styled-components';

type Props = {
  renderStep: (step: BuilderModularWorkflowStep, index: number) => ReactNode;
};

export const SortableStepList = ({ renderStep }: Props) => {
  const workflow = useModulareWorkflowCreationStore(state => state.workflow);
  const updateWorkflowStepOrder = useModulareWorkflowCreationStore(
    state => state.updateWorkflowStepOrder
  );

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        // Amount of pixels the pointer needs to move to start the drag
        // It's important to be > 0 to still receive click events
        distance: 10
      }
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates
    })
  );

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    if (active.id === over?.id) {
      return;
    }

    const activeStep = workflow.steps.find(step => step.id === active.id);
    const overStep = workflow.steps.find(step => step.id === over?.id);
    if (!activeStep || !overStep) {
      return;
    }

    updateWorkflowStepOrder(activeStep.order, overStep.order);
  };

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      modifiers={[restrictToFirstScrollableAncestor, restrictToHorizontalAxis]}
      onDragEnd={handleDragEnd}
    >
      <SortableContext items={workflow.steps} strategy={horizontalListSortingStrategy}>
        <Root>{workflow.steps.map((step, stepIndex) => renderStep(step, stepIndex))}</Root>
      </SortableContext>
    </DndContext>
  );
};

const Root = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${({ theme }) => theme.spacings.medium};
  width: 100%;
  overflow-x: auto;
  // Provide some space for the potential scrollbar
  padding-bottom: ${({ theme }) => theme.spacings.medium};
`;
