import { selectEditor } from '@udecode/plate';
import useEditor from 'features/aiWriter/AiWriterTextEditor/hooks/useEditor';
import { useCallback } from 'react';
import { useAppDispatch } from 'store/hooks';

import useShowSetupModal from '../modals/setup/useSetupModal';
import { setInspirationsCurrentStep } from '../store/actions/config/actions';
import { closeTab, setCurrentTab } from '../store/actions/tabs/actions';

export const useAiWriterTab = () => {
  const editor = useEditor();
  const dispatch = useAppDispatch();
  const showSetupModal = useShowSetupModal();

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const resetSelection = useCallback(() => selectEditor(editor!, { at: [0] }), [editor]);

  const onSelect = useCallback(
    (tabId: string) => {
      resetSelection();
      dispatch(setInspirationsCurrentStep(undefined));
      dispatch(setCurrentTab(tabId));
    },
    [resetSelection, dispatch]
  );

  const onCreate = useCallback(() => {
    resetSelection();
    dispatch(setInspirationsCurrentStep(undefined));
    showSetupModal();
  }, [resetSelection, showSetupModal, dispatch]);

  const onClose = useCallback(
    (tabId: string) => {
      resetSelection();
      dispatch(setInspirationsCurrentStep(undefined));
      dispatch(closeTab(tabId));
    },
    [resetSelection, dispatch]
  );

  return { onCreate, onSelect, onClose };
};
