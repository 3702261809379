import { removeSuggestion } from 'features/aiTester/store/actions/suggestions/actions';
import { AppThunk } from 'store/store';

import { updateTesterProjectInBackgroundThunk } from '../../project/thunks/updateTesterProjectInBackgroundThunk';
import { RemoveSuggestionPayload } from '../types';

export const removeSuggestionAndUpdateProjectThunk =
  (payload: RemoveSuggestionPayload): AppThunk<void> =>
  async dispatch => {
    dispatch(removeSuggestion(payload));
    dispatch(updateTesterProjectInBackgroundThunk());
  };
