import { TextField } from '@mui/material';
import CountryFlag from 'components/base/CountryFlag';
import {
  mapModelToOption,
  ModelOption,
  StyledAutocomplete
} from 'features/embeddingModels/ModelAutocomplete';
import { getEmbeddingModelsByLanguageAndAudience } from 'features/embeddingModels/store/selectors';
import { sortModelsByLanguageAlphabetically } from 'features/embeddingModels/store/utils/sortModelsByLanguage';
import { BaseSetting } from 'features/modular-workflow/builder/settings/BaseSetting';
import { InputRendererContext } from 'features/modular-workflow/builder/settings/InputRenderer';
import { BuilderModularWorkflowStepInputWithValue } from 'features/modular-workflow/builder/types-builder';
import { HTMLAttributes, SyntheticEvent, useContext, useMemo, useState } from 'react';
import { useAppSelector } from 'store/hooks';
import styled from 'styled-components';
import useTr from 'utils/hooks/useTr';

export const DataTypeLanguage = () => {
  const { input, onChange, inputErrorList } = useContext(InputRendererContext);
  const tr = useTr();
  const models = useAppSelector(getEmbeddingModelsByLanguageAndAudience);

  const typedInput = input as BuilderModularWorkflowStepInputWithValue;

  const options = useMemo(
    () => sortModelsByLanguageAlphabetically(models).map(mapModelToOption),
    [models]
  );
  const [localSelectedValue, setLocalSelectedValue] = useState<ModelOption | null>(
    options.find(v => v.value === typedInput.value) ?? null
  );

  const handleChange = (_: SyntheticEvent<Element, Event>, value: ModelOption | null) => {
    if (value) {
      setLocalSelectedValue(value);
      onChange(typedInput, value.value);
    }
  };

  const renderOption = (props: HTMLAttributes<HTMLLIElement>, option: ModelOption) => {
    return (
      <Option {...props}>
        <CountryFlag country={option.country} language={option.language} />

        {option.label}
      </Option>
    );
  };

  // FYI This is copy & paste from ModelAutocomplete instead of re-using to
  // getting rid of the formik stuff inside
  return (
    <BaseSetting>
      {elementId => (
        <StyledAutocomplete
          id={elementId}
          fullWidth={true}
          disableClearable={true}
          options={options}
          autoHighlight={true}
          getOptionLabel={option => option.label}
          renderOption={renderOption}
          isOptionEqualToValue={(option, value) => option.value === value.value}
          value={localSelectedValue || undefined}
          onChange={handleChange}
          ListboxProps={{
            style: {
              /**
               * Key object here was to increase the dropdown "height" so that 1440x838res
               * has all options without scroll. I choose that value experimentally
               */
              maxHeight: '60vh'
            }
          }}
          renderInput={params => (
            <TextField
              {...params}
              name="language"
              placeholder={tr('common.language')}
              variant="outlined"
              fullWidth
              InputProps={{
                ...params.InputProps,
                startAdornment: (
                  <CountryFlag
                    country={localSelectedValue?.country}
                    language={localSelectedValue?.language}
                  />
                )
              }}
              error={inputErrorList.length > 0}
              helperText={inputErrorList.map(error => error.errorMessage).join(', ')}
            />
          )}
          size="medium"
        />
      )}
    </BaseSetting>
  );
};

const Option = styled.span`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${({ theme }) => theme.spacings.small};
`;
