import {
  getEmotionalityTranslationLabel,
  getReadabilityAndEmotionalityGrade
} from 'features/flashScore/getEmotionalityTranslationLabel';
import { getReadabilityTranslationLabel } from 'features/flashScore/getReadabilityTranslationLabel';
import { getScoringFromMappersResult } from 'features/flashScore/getScoringFromMappersResult';
import { httpFlashScoreGeneratedTexts } from 'services/backofficeIntegration/http/endpoints/flashScore/httpFlashScoreGeneratedTexts';

export const scoreTextInspirations = async (
  embeddingModelId: string,
  emotionalityMapperName: string,
  inspirations: Array<{ text: string }>
) => {
  return httpFlashScoreGeneratedTexts
    .callEndpoint({
      embedding_model_id: embeddingModelId,
      mappers: [
        { id: 'readability', name: 'readability', variable: 'readability' },
        { id: 'emotionality', name: emotionalityMapperName, variable: 'emotionality' }
      ],
      texts: inspirations.map(inspiration => inspiration.text)
    })
    .then(response => {
      if (!response || !response.status) {
        return [];
      }

      return response.data.map((result, index) => {
        const { readability, emotionality } = getScoringFromMappersResult(result);
        const readabilityGrade = getReadabilityAndEmotionalityGrade(readability);
        const emotionalityGrade = getReadabilityAndEmotionalityGrade(emotionality);

        return {
          text: inspirations[index].text,
          readability: {
            grade: readabilityGrade,
            score: readability,
            percentage: Math.round(readability * 100),
            label: getReadabilityTranslationLabel(readabilityGrade)
          },
          emotionality: {
            grade: emotionalityGrade,
            score: emotionality,
            percentage: Math.round(emotionality * 100),
            label: getEmotionalityTranslationLabel(emotionalityGrade)
          }
        };
      });
    });
};
