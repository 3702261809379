import { EElementOrText, ELEMENT_PARAGRAPH, insertNodes, Value } from '@udecode/plate';
import useEditor from 'features/aiWriter/AiWriterTextEditor/hooks/useEditor';
import { useCreateEmptyPage } from 'features/plate/components/plate-ui/useCreateEmptyPage';

export function useAddCommandTemplateToEditor() {
  const editor = useEditor();
  const { createEmptyPage } = useCreateEmptyPage();

  function addTemplate(template: string) {
    if (!editor) {
      return;
    }

    createEmptyPage();
    const content = extractContent(template);
    content.map(line => insertNodes(editor, line));
  }

  function extractContent(template: string): EElementOrText<Value>[] {
    return template
      .split('\n')
      .map(line => ({ type: ELEMENT_PARAGRAPH, children: [{ text: line }] }));
  }

  return { addTemplate };
}
