import { Button, TextField } from '@mui/material';
import FlexContainer from 'components/FlexContainer';
import Toast from 'components/toasts/Toast';
import FormattedMessage from 'features/i18n/FormattedMessage';
import { useField } from 'formik';
import { ChangeEvent, useState } from 'react';
import styled from 'styled-components';

const maxFileSizeInMegaBytes = 7;
const maxFileSizeInBytes = maxFileSizeInMegaBytes * 1024 * 1024;

type Props = {
  fieldName: string;
};

export const ImageUploadFieldInput = ({ fieldName }: Props) => {
  const [, , helpers] = useField(fieldName);

  const [fileName, setFileName] = useState<string>('');

  const handleImageChange = ({ target: { files } }: ChangeEvent<HTMLInputElement>) => {
    const uploadedFile = files?.[0];
    if (!uploadedFile) {
      return;
    }

    if (uploadedFile.size > maxFileSizeInBytes) {
      Toast.error('profile.upload_image.size_error', { fileSizeLimitInMB: maxFileSizeInMegaBytes });
      return;
    }

    const reader = new FileReader();
    reader.readAsDataURL(uploadedFile);
    reader.onload = () => {
      setFileName(uploadedFile.name);
      helpers.setValue(reader.result);
    };
  };

  return (
    <FlexContainer direction="row" gap="two">
      <FileIndicator disabled={true} value={fileName} fullWidth={true} />

      <Button role={undefined} component="label" size="large" variant="outlined" tabIndex={-1}>
        <FormattedMessage id="common.browse" />

        <VisuallyHiddenInput
          type="file"
          accept="image/png, image/jpeg, image/png, image/jpg"
          onChange={handleImageChange}
        />
      </Button>
    </FlexContainer>
  );
};

const FileIndicator = styled(TextField)`
  & .Mui-disabled {
    color: ${({ theme }) => theme.colors.textPrimary};
  }
`;

const VisuallyHiddenInput = styled.input`
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  bottom: 0;
  left: 0;
  white-space: nowrap;
  width: 1px;
`;
