import { TesterState } from 'features/aiTester/store/types';
import { getCurrentTab, getTabById } from 'store/utils/tabUtils';

export const getCurrentTabTextWord = ({
  state,
  tabId,
  textId,
  wordId
}: {
  state: TesterState;
  tabId?: string;
  textId: string;
  wordId: string;
}) => {
  const tab = tabId ? getTabById(tabId, state) : getCurrentTab(state);
  const text = tab?.texts.find(t => t.id === textId);

  if (text) {
    return text.words.find(w => w.id === wordId);
  }

  return undefined;
};
