import { useCallback } from 'react';
import { YTVideoName } from 'services/youtube/types';
import useTr from 'utils/hooks/useTr';

function useGetYoutubeVideo() {
  const translate = useTr();

  return useCallback(
    (name: YTVideoName) => translate(`video_guide.youtube_video_id.${name}`),
    [translate]
  );
}

export default useGetYoutubeVideo;
