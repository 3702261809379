import { CloseModal } from 'components/modals/types';
import { useChurnKeyModal } from 'features/churnKey/useChurnKeyModal';
import { Actions } from 'features/churnModal/actions';
import { ChurnModal } from 'features/churnModal/churnModal';
import { ChurnType, StatisticsStep } from 'features/churnModal/statisticsStep';
import { WithNextStep } from 'features/churnModal/steps';

const reloadPage = () => window.location.reload();

export function CancelSubscriptionModal({ closeModal }: CloseModal) {
  const showChurnKeyModal = useChurnKeyModal({
    customerAttributes: { churnType: 'cancel' },
    listeners: {
      onCancel: reloadPage,
      onPause: reloadPage,
      onPlanChange: reloadPage
    }
  });

  const handleContinueClick = () => {
    closeModal();
    showChurnKeyModal();
  };

  return (
    <ChurnModal.Layout>
      <Step1 key="s1" closeModal={closeModal} nextStep={handleContinueClick} />
    </ChurnModal.Layout>
  );
}

function Step1({ closeModal, nextStep }: CloseModal & WithNextStep) {
  return (
    <StatisticsStep.Layout
      heading={<StatisticsStep.Heading />}
      explanation={<StatisticsStep.Explanation churnType={ChurnType.CancelSubscription} />}
      statistics={<StatisticsStep.LeavingStatistics />}
      actions={
        <Actions.Layout
          ok={
            <Actions.ContinueButton
              onClick={nextStep}
              labelId="churn_modal.cancel_subscription.continue_button"
            />
          }
          cancel={
            <Actions.CancelButton
              onClick={closeModal}
              labelId="churn_modal.keep_subscription_button"
            />
          }
        />
      }
      nextStep={nextStep}
    />
  );
}
