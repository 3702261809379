import { TextInspiration } from 'features/aiWriter/store/types';
import { AppThunk } from 'store/store';

import { updateCurrentProjectInBackgroundThunk } from '../../project/thunks/updateCurrentProjectInBackground';
import { removeTextInspiration } from '../actions';

export const selectTextInspirationThunk =
  (inspiration: TextInspiration): AppThunk<void> =>
  async dispatch => {
    dispatch(removeTextInspiration({ id: inspiration.id }));
    dispatch(updateCurrentProjectInBackgroundThunk());
  };
