import { Tooltip } from '@mui/material';
import Typography from '@mui/material/Typography';
import { ReactNode, useMemo } from 'react';
import styled from 'styled-components';

const createProgressBackgroundColor = (value: number) => {
  // Value from 0 to 1 with 2 decimal places
  const percentage = Number((value / 100).toFixed(2));
  // Based value of 10 (red) and max 90 (green)
  const hueValue = (10 + percentage * 80).toString(10);

  return `hsl(${hueValue},60%,50%)`;
};

type Props = {
  value: number;
  tooltip?: ReactNode;
  className?: string;

  onClick?: () => void;
};

const PercentageIndicator = ({ value, tooltip, className, onClick }: Props) => {
  const content = useMemo(
    () => (
      <Loader>
        <Content $isClickable={!!onClick} $value={value} onClick={onClick}>
          <Typography variant="caption" component="div" color="inherit">
            {value}
          </Typography>
        </Content>
      </Loader>
    ),
    [onClick, value]
  );

  return tooltip ? (
    <Tooltip className={className} title={tooltip}>
      {content}
    </Tooltip>
  ) : (
    content
  );
};

const Loader = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: ${({ theme }) => theme.spacings.small};
`;

const Content = styled.button<{ $isClickable?: boolean; $value: number }>`
  all: unset;

  width: 32px;
  height: 32px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  color: ${({ theme }) => theme.colors.black};
  border-radius: 50%;
  background-color: ${({ $value }) => createProgressBackgroundColor($value)};

  cursor: ${({ $isClickable }) => ($isClickable ? 'pointer' : 'auto')};

  > * {
    line-height: 1;
  }
`;

export default PercentageIndicator;
