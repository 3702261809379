import { CloseModal } from 'components/modals/types';
import { addDimension } from 'features/dimensions/store/actions';
import {
  getCurrentTabModelData,
  getSelectedWords
} from 'features/explorer/store/selectors';
import { addList, addToExistingList } from 'features/lists/store/actions';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { RootState } from 'store/rootReducer';
import { promisifySagaThunk } from 'utils/utils';
import { bool, number, object, string } from 'yup';

import Checkbox from '../../../base/Checkbox';
import InputField from '../../../forms/InputField';
import RadioGroupField from '../../../forms/RadioGroupField';
import TextareaField from '../../../forms/TextareaField';
import FormikModal from '../../../modals/FormikModal';
import AvailableListsCombobox from './components/AvailableListsCombobox';

type Props = CloseModal;

const validationSchema = object().shape({
  addOrMerge: string().oneOf(['new', 'existing']).required(),
  name: string().when('addOrMerge', {
    is: 'new',
    then: string().required(),
    otherwise: string()
  }),
  description: string(),
  createDimension: bool(),
  listId: number().when('addOrMerge', {
    is: 'existing',
    then: number().required().moreThan(0),
    otherwise: number().notRequired().nullable()
  })
});

const options = [
  {
    value: 'new',
    label: 'new_list.save_new_list'
  },
  {
    value: 'existing',
    label: 'new_list.add_words_to_existing'
  }
];

const ListFromTableModal = ({ closeModal }: Props) => {
  const dispatch = useAppDispatch();
  const isLoading = useAppSelector((state: RootState) => state.lists.isLoading);
  const { language } = useAppSelector(getCurrentTabModelData);
  const selectedWords = useAppSelector(getSelectedWords);

  return (
    <FormikModal
      isLoading={isLoading}
      onSubmit={({ name, description, listId, createDimension, addOrMerge }) => {
        const words = selectedWords.map(item => item.word);
        if (addOrMerge === 'new') {
          (async () => {
            const payload = {
              label: name,
              description,
              language,
              words
            };

            await dispatch(promisifySagaThunk(addList.request, payload));
            if (createDimension) {
              await dispatch(promisifySagaThunk(addDimension.request, payload));
            }
            closeModal();
          })();
        } else {
          dispatch(promisifySagaThunk(addToExistingList.request, { id: listId, words })).then(
            closeModal
          );
        }
      }}
      initialValues={{
        name: '',
        listId: 0,
        description: '',
        createDimension: false,
        addOrMerge: 'new'
      }}
      validationSchema={validationSchema}
      title="lists.add_new_list"
      onCancelClick={closeModal}
    >
      {({ values, setFieldValue }) => (
        <>
          <RadioGroupField
            label="new_list.choose_action"
            name="addOrMerge"
            options={options}
            required
            isInline
          />
          {values.addOrMerge === 'new' ? (
            <>
              <InputField name="name" label="common.name" autoFocus required />
              <TextareaField name="common.description" label="description" />
              <Checkbox
                label="new_list.create_dimensions"
                checked={values.createDimension}
                onChange={event => setFieldValue('createDimension', event.target.checked)}
              />
            </>
          ) : (
            <AvailableListsCombobox name="listId" label="new_list.select_list" />
          )}
        </>
      )}
    </FormikModal>
  );
};

export default ListFromTableModal;
