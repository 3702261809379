import Toast from 'components/toasts/Toast';
import { getErrorCodeFromAxiosError } from 'features/teamInvitation/getErrorCodeFromAxiosError';
import { BaseSuccessDto } from 'services/backofficeIntegration/http/BaseSuccessDto';
import { backofficeClient } from 'services/backofficeIntegration/http/client/createBackofficeClient';
import { mutationEndpoint } from 'services/backofficeIntegration/http/endpointDefinition';
import { invalidatePersonalitiesLimitationsQuery } from 'services/backofficeIntegration/http/endpoints/personalities/httpGetPersonalityLimitations';

type SuccessDto = BaseSuccessDto<never>;

export const httpDeletePersonality = mutationEndpoint({
  callEndpoint: (personalityId: number) =>
    backofficeClient
      .delete<SuccessDto>(`/chat/personalities/${personalityId}`)
      .then(response => {
        invalidatePersonalitiesLimitationsQuery();
        return response.data;
      })
      .catch(error => {
        const errorCode = getErrorCodeFromAxiosError(error);
        if (errorCode) {
          Toast.error(`chat.personalities.error.${errorCode}`);
        }
      })
});
