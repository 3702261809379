import { Button, Typography } from '@mui/material';
import backgroundDoodleOrnament from 'assets/background-doodle.png';
import IconNeuroflashLogoSVG from 'assets/neuroflash-logo.svg';
import IconDarkNeuroflashLogoSVG from 'assets/neuroflash-mix-logo-dark-mode.svg';
import FlexContainer from 'components/FlexContainer';
import { Ornament } from 'components/Ornament';
import { getRoutePath } from 'config/routes';
import { useDisableSidebarOnMount } from 'features/pageSidebar/useSidebarStore';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router';
import gtmIds from 'services/tracking/GTMIds';
import { withGtmInteraction } from 'services/tracking/withGtmInteraction';
import styled from 'styled-components';
import { useIsDarkMode } from 'styles/new-theme/useAppThemeStore';

export function OnboardingWelcome() {
  const navigate = useNavigate();

  useDisableSidebarOnMount();

  const isDarkMode = useIsDarkMode();

  const neuroflashLogo = isDarkMode ? IconDarkNeuroflashLogoSVG : IconNeuroflashLogoSVG;

  const handleClick = () => {
    navigate(getRoutePath('onboardingSurvey'));
  };

  return (
    <Root>
      <LogoImage src={neuroflashLogo} />

      <Content>
        <FlexContainer gap="small" justifyContent="center" alignItems="center">
          <Typography variant="h6">
            <FormattedMessage id="aiWriter.onboarding_journey.welcome_view.header" />
          </Typography>
        </FlexContainer>
        <Typography variant="body1">
          <FlexContainer alignItems="center">
            <FormattedMessage
              id="aiWriter.onboarding_journey.welcome_view.body_2"
              values={{ span: (chunks: string) => <span>{chunks}</span> }}
            />
          </FlexContainer>
        </Typography>
        <Button
          {...withGtmInteraction(gtmIds.onboardingJourney.openView.choice)}
          variant="contained"
          onClick={handleClick}
        >
          <FormattedMessage id="aiWriter.onboarding_journey.welcome_view.button" />
        </Button>
      </Content>
      <Ornament src={backgroundDoodleOrnament} bottom="0%" right="0%" />
    </Root>
  );
}

const Root = styled(FlexContainer).attrs({
  alignItems: 'center',
  justifyContent: 'center'
})`
  margin-top: 88px;
`;

const Content = styled(FlexContainer).attrs({
  gap: 'four',
  alignItems: 'center',
  justifyContent: 'center'
})`
  padding: ${({ theme }) => theme.spacings.five};
`;

const LogoImage = styled.img`
  width: 460px;
`;
