import { MenuItem } from '@mui/material';
import SelectField from 'components/forms/SelectField';
import LocalStorageKey from 'config/localStorageKey';
import { mapperField, TesterSetupValues } from 'features/aiTester/modals/setup/SetupModal';
import { getGetEmbeddingModelMappersWithFallbackById } from 'features/embeddingModels/store/selectors';
import { useFormikContext } from 'formik';
import React, { useCallback } from 'react';
import { useAppSelector } from 'store/hooks';
import useTr from 'utils/hooks/useTr';

// TODO: refactor and simplify model mappers
const SetupMapperSelect = (): React.ReactElement | null => {
  const {
    values: { model }
  } = useFormikContext<TesterSetupValues>();
  const tr = useTr();

  const modelMappersWithFallback = useAppSelector(getGetEmbeddingModelMappersWithFallbackById)(
    model
  );

  const onSelect = useCallback((value: unknown) => {
    localStorage.setItem(LocalStorageKey.TesterMapper, String(value));
  }, []);

  const hidden = modelMappersWithFallback.length <= 1;

  if (hidden) {
    return null;
  }

  return (
    <SelectField
      disabled={hidden}
      variant="outlined"
      name={mapperField}
      fullWidth
      placeholder={tr('testing.initialization.mapper.label')}
      onSelect={onSelect}
    >
      {modelMappersWithFallback.map(mapper => (
        <MenuItem key={mapper.name} value={mapper.name}>
          {mapper.friendly_name}
        </MenuItem>
      ))}
    </SelectField>
  );
};

export default SetupMapperSelect;
