import { FileCopyOutlined } from '@mui/icons-material';
import { IconButton, Tooltip } from '@mui/material';
import { trackingWrapper } from 'features/tracking/wrapper/TrackingWrapper';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { withGtmInteraction } from 'services/tracking/withGtmInteraction';

interface Props {
  color?: 'inherit' | 'primary' | 'secondary' | 'default';
  text: string;
  gtmId?: string;
}

const CopyToClipboardButton = ({ color, text, gtmId }: Props) => {
  const [open, setOpen] = React.useState(false);
  const [copied, setCopied] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setCopied(false);
  };

  const handleCopy = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    trackingWrapper.track('aiWriterChatMessageCopyClick');
    navigator.clipboard.writeText(text);
    setCopied(true);
  };

  return (
    <Tooltip
      open={open}
      onOpen={handleOpen}
      onClose={handleClose}
      leaveDelay={copied ? 500 : 0}
      TransitionProps={{ timeout: 0 }}
      title={<FormattedMessage id={copied ? 'common.copy_to_clipboard' : 'common.copy'} />}
    >
      <IconButton {...withGtmInteraction(gtmId)} color={color} onClick={handleCopy} size="small">
        <FileCopyOutlined fontSize="small" />
      </IconButton>
    </Tooltip>
  );
};

export default CopyToClipboardButton;
