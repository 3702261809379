import { ApiUnsplashResponse } from 'services/api/unsplash/types';

import { AiWriterImage } from '../../types';

export const aiWriterImagesFromApi = (response: ApiUnsplashResponse): AiWriterImage[] => {
  return response.results.map(image => ({
    id: image.id,
    author: image.user.name,
    authorPage: image.user.links.html,
    thumb: image.urls.thumb,
    image: image.urls.full,
    downloadLink: image.links.html,
    trackDownloadLink: image.links.download_location
  }));
};
