import AutocompleteTagsInput from 'components/AutocompleteInput/AutocompleteTagsInput';
import { CloseModal } from 'components/modals/types';
import { addToExistingDimension } from 'features/dimensions/store/actions';
import { getFilterAndSortEmbeddingModels } from 'features/embeddingModels/store/selectors';
import { addToExistingList } from 'features/lists/store/actions';
import { ErrorMessage } from 'formik';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { useAppSelector } from 'store/hooks';
import { RootState } from 'store/rootReducer';
import { WordTag } from 'types/WordTag';
import { promisifySagaThunk } from 'utils/utils';
import { wordTagsSchema } from 'utils/validationRules';
import { object } from 'yup';

import AlertError from '../../../forms/AlertError';
import FormikModal from '../../../modals/FormikModal';

type ConnectedProps = {
  isLoading: boolean;
  addWords: (targetId: number, words: WordTag[]) => Promise<void>;
};

type CallerProps = {
  activeList: number;
  listLanguage: string;
  title: string;
};

type Props = CloseModal & CallerProps & ConnectedProps;

const validationSchema = object().shape({
  words: wordTagsSchema
});

const AddWordsModal = ({
  isLoading,
  closeModal,
  activeList,
  addWords,
  title,
  listLanguage
}: Props) => {
  const [latestModelForLanguage] = useAppSelector(getFilterAndSortEmbeddingModels)(
    model => model.language === listLanguage,
    'desc'
  );

  return (
    <FormikModal
      isLoading={isLoading}
      onSubmit={({ words }) => addWords(activeList, words).then(closeModal)}
      initialValues={{ words: [] as WordTag[] }}
      validationSchema={validationSchema}
      title={title || 'lists.add_words'}
      onCancelClick={closeModal}
    >
      {({ setFieldValue, values, submitForm }) => (
        <>
          <ErrorMessage name="words">
            {error => (
              <AlertError>
                <FormattedMessage id={error} />
              </AlertError>
            )}
          </ErrorMessage>
          <AutocompleteTagsInput
            value={values.words}
            onChange={words => setFieldValue('words', words)}
            model={latestModelForLanguage.id}
            eventHandler={submitForm}
          />
        </>
      )}
    </FormikModal>
  );
};

export const AddWordsToList = connect(
  (state: RootState) => ({ isLoading: state.lists.isLoading }),
  {
    addWords: (id: number, words: WordTag[]) =>
      promisifySagaThunk(addToExistingList.request, { id, words: words.map(i => i.word) })
  }
)(AddWordsModal);

export const AddWordsToDimension = connect(
  (state: RootState) => ({ isLoading: state.lists.isLoading }),
  {
    addWords: (id: number, words: WordTag[]) =>
      promisifySagaThunk(addToExistingDimension.request, {
        id,
        words: words.map(i => i.word)
      })
  }
)(AddWordsModal);
