import { Field, FieldProps } from 'formik';
import { upperFirst } from 'lodash';
import React from 'react';

import useTr from '../../utils/hooks/useTr';
import Textarea from '../base/Textarea';

type Props = FieldProps & React.ComponentPropsWithoutRef<typeof Textarea>;

const FormikTextarea = ({
  field,
  form: { touched, errors },
  label,
  placeholder,
  ...props
}: Props) => {
  const tr = useTr();
  return (
    <Textarea
      label={label}
      error={
        errors[field.name] && touched[field.name]
          ? upperFirst(errors[field.name] as string)
          : undefined
      }
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      placeholder={placeholder || label ? tr((placeholder || label)!) : undefined}
      {...field}
      {...props}
    />
  );
};

type InputFieldProps = React.ComponentPropsWithoutRef<typeof Textarea> & { name: string };

const TextareaField = (props: InputFieldProps) => <Field component={FormikTextarea} {...props} />;

export default TextareaField;
