import { Chip, Tooltip } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import makeStyles from '@mui/styles/makeStyles';
import { useInfiniteQuery, UseQueryOptions } from '@tanstack/react-query';
import { makeCurrentUserQueryKeyPrefix } from 'features/currentUser/currentUserQueries';
import React, { createContext, forwardRef, ReactElement, useContext, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { FormattedMessage } from 'react-intl';
import DimensionAPI from 'services/api/dimension';
import { Dimension } from 'services/api/dimension/types';
import { DimensionParameters } from 'services/api/wordEmbedding/types';
import { secondaryText } from 'styles/styleUtils';
import { useDebounce } from 'use-debounce/lib';
import useTr from 'utils/hooks/useTr';
import { infiniteQueryKey } from 'utils/reactQuery/infiniteQueryKey';
import { forceNonNullable } from 'utils/typescript/nonNullable';

import DimensionChipTooltip from './DimensionChipTooltip';

type PublicQueryParams = Pick<DimensionParameters, 'belongsTo' | 'language'>;

type InternalQueryParams = PublicQueryParams & Pick<DimensionParameters, 'label'>;

type PaginationParams = {
  pageIndex: number;
};

type Props = {
  params: PublicQueryParams;
  formResults: Dimension[];
  disabled?: boolean;

  onChange: (value: Dimension) => void;
  onRemove?: (index: number, id: number) => void;
};

type DimensionsResult = ReturnType<typeof useDimensionsQuery>;

const Context = createContext<DimensionsResult | null>(null);

const CustomListbox = forwardRef<HTMLUListElement, React.HTMLAttributes<HTMLElement>>(
  (props, ref) => {
    const { children, ...rest } = props;
    const { fetchNextPage, isFetching, hasNextPage } = forceNonNullable(
      useContext(Context),
      'DimensionsResult not provided'
    );

    const { ref: loaderRef } = useInView({
      onChange: (inView: boolean) => {
        if (inView) {
          fetchNextPage();
        }
      }
    });

    const loaderActive = !isFetching && hasNextPage;

    return (
      <ul ref={ref} {...rest}>
        {children}
        {loaderActive && <li ref={loaderRef}></li>}
      </ul>
    );
  }
);

const itemsPerPage = 20;

type QueryParams = {
  enabled?: UseQueryOptions['enabled'];
};

function useDimensionsQuery(params: InternalQueryParams, options?: QueryParams) {
  return useInfiniteQuery({
    enabled: options?.enabled,
    queryKey: infiniteQueryKey([...makeCurrentUserQueryKeyPrefix(), 'dimensions', params]),
    queryFn: async (context: { pageParam?: PaginationParams }) => {
      const { pageParam } = context;
      const { pageIndex = 0 } = pageParam ?? {};
      const response = await DimensionAPI.getAll({
        ...params,
        page: pageIndex + 1,
        itemsPerPage
      });
      if (response.status) {
        return response.data;
      }

      // #tech-debt https://app.clickup.com/t/862jqaphw
      // Currently we ignore any errors that this query throws.
      throw Error('Failed to fetch dimensions data');
    },
    getNextPageParam: (lastLoadedPage, allPages): PaginationParams | undefined => {
      const { total } = lastLoadedPage;
      const fetched = allPages.reduce((s, p) => s + p.data.length, 0);
      if (fetched < total) {
        return { pageIndex: allPages.length };
      }
      return undefined;
    }
  });
}

function DimensionsSelect({
  params,
  formResults,
  disabled,
  onChange,
  onRemove
}: Props): ReactElement {
  const [values, setValues] = useState<Dimension[]>(formResults);
  const classes = useChipStyles();
  const tr = useTr();

  const [searchPhrase, setSearchPhrase] = useState('');
  const [debouncedSearchPhrase] = useDebounce(searchPhrase, 300);
  const dimensionsResult = useDimensionsQuery(
    { ...params, label: debouncedSearchPhrase },
    { enabled: !disabled }
  );

  const options =
    dimensionsResult.data?.pages.reduce((acc: Dimension[], p) => (acc.push(...p.data), acc), []) ??
    [];

  const loading = dimensionsResult.isLoading;

  const handleDelete = (valueToDelete: Dimension) => {
    setValues(values => values.filter(value => value.id !== valueToDelete.id));
  };

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setSearchPhrase(event.target.value);
  };

  return (
    <Context.Provider value={dimensionsResult}>
      <Autocomplete
        disabled={disabled}
        multiple
        fullWidth
        filterSelectedOptions
        disableCloseOnSelect
        disableClearable
        value={values}
        onChange={(_, value, reason) => {
          if (reason === 'selectOption') {
            onChange(value[value.length - 1]);
            setValues(value);
          }
        }}
        options={options}
        getOptionLabel={option => option.label}
        /**
         * Autocomplete uses getOptionLabel result as a key for option.
         * It is bad from UX perspective to have multiple different
         * items with exactly the same label, but it is possible in our
         * system, so I override the key.
         */
        renderOption={(props, option) => (
          <li {...props} key={option.id}>
            {option.label}
          </li>
        )}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        filterOptions={x => x}
        loading={loading}
        renderInput={params => (
          <TextField
            {...params}
            variant="outlined"
            value={searchPhrase}
            onChange={handleChange}
            label={<FormattedMessage id="dimensions.select.label" />}
            placeholder={tr('dimensions.select.placeholder')}
          />
        )}
        renderTags={(value, getTagProps) =>
          value.map((dimension, index) => (
            <Tooltip
              title={<DimensionChipTooltip dimension={dimension} wordsCutAmount={5} />}
              key={dimension.id}
            >
              <Chip
                {...getTagProps({ index })}
                classes={classes}
                label={dimension.label}
                onDelete={() => {
                  onRemove?.(
                    formResults.findIndex(value => value.id === dimension.id),
                    dimension.id
                  );
                  handleDelete(dimension);
                }}
              />
            </Tooltip>
          ))
        }
        ListboxComponent={CustomListbox}
      />
    </Context.Provider>
  );
}

const useChipStyles = makeStyles({
  deleteIcon: {
    color: secondaryText
  }
});

export default DimensionsSelect;
