import FormGroup from 'components/base/FormGroup';
import AsyncCreatable from 'components/selects/AsyncCreatable';
import { ActionMeta, PropsValue, SingleValue } from 'react-select/dist/declarations/src/types';
import { Audience } from 'services/api/audience/types';
import useTr from 'utils/hooks/useTr';

import useLoadProjects from './useLoadAudience';

type Props = {
  onChange: (value: SingleValue<Audience>, actionMeta: ActionMeta<Audience>) => void;
  value?: PropsValue<Audience>;
};

const ProjectsComboBox = (props: Props) => {
  const tr = useTr();
  const loadProjects = useLoadProjects();

  return (
    <FormGroup label="dimensions.project">
      <AsyncCreatable
        loadOptions={loadProjects}
        cacheOptions
        defaultOptions
        isClearable
        getOptionValue={option => `${option.id}`}
        getOptionLabel={option => option.name}
        getNewOptionData={(_, optionLabel) => ({
          id: 0,
          name: optionLabel as string,
          is_generic: false
        })}
        formatCreateLabel={inputValue => tr('dimensions.create_project', { project: inputValue })}
        placeholder={tr('dimensions.project.placeholder')}
        {...props}
      />
    </FormGroup>
  );
};

export default ProjectsComboBox;
