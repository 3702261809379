import AddIcon from '@mui/icons-material/Add';
import { Button, Chip, Typography } from '@mui/material';
import FlexContainer from 'components/FlexContainer';
import { UserTonalityInput } from 'features/aiWriter/tonality/UserTonalityInput';
import { useTonalityHandlers } from 'features/aiWriter/tonality/useTonalityHandlers';
import FormattedMessage from 'features/i18n/FormattedMessage';
import { BaseSetting } from 'features/modular-workflow/builder/settings/BaseSetting';
import { InputRendererContext } from 'features/modular-workflow/builder/settings/InputRenderer';
import { useGetTonalityLanguageAndCountryCode } from 'features/modular-workflow/builder/settings/useGetTonalityLanguageAndCountryCode';
import { BuilderModularWorkflowStepInputWithValue } from 'features/modular-workflow/builder/types-builder';
import { useContext, useState } from 'react';
import styled from 'styled-components';

const maxLength = 2;

export const DataTypeTonality = () => {
  const { input, onChange } = useContext(InputRendererContext);
  const languageAndCountryCode = useGetTonalityLanguageAndCountryCode();
  const typedInput = input as BuilderModularWorkflowStepInputWithValue;

  const [showCustomTonalityInput, setShowCustomTonalityInput] = useState(false);

  const [localUserTonalities, setLocalUserTonalities] = useState<string[]>([]);
  const [localSystemTonalities, setLocalSystemTonalities] = useState<string[]>(
    (typedInput.value as string[]) ?? []
  );

  const {
    handleCustomTonalityChange,
    handlePredefinedTonalityClick,
    isDisabled,
    isSelected,
    systemTonalityOptions,
    totalAmountSelected,
    userTonalities,
    maxAmount
  } = useTonalityHandlers({
    systemTonality: localSystemTonalities,
    userTonality: localUserTonalities,
    languageAndCountryCode,
    maxLength,
    onChange: (newSystemTonalities, newUserTonalities) => {
      setLocalSystemTonalities(newSystemTonalities);
      setLocalUserTonalities(newUserTonalities);

      onChange(typedInput, [...newSystemTonalities, ...newUserTonalities]);
    }
  });

  return (
    <BaseSetting
      endAdornment={
        <Counter variant="caption">
          {totalAmountSelected ?? 0} / {maxLength}
        </Counter>
      }
    >
      {elementId => (
        <TonalityContainer>
          <ChipContainer gap="small" direction="row">
            {systemTonalityOptions.map(option => (
              <PrimaryWhiteChip
                key={option.value}
                label={option.label}
                $selected={isSelected(option)}
                disabled={isDisabled(option)}
                onClick={
                  isDisabled(option) ? undefined : () => handlePredefinedTonalityClick(option)
                }
                data-tonality={option.value}
              />
            ))}
          </ChipContainer>

          {showCustomTonalityInput ? (
            <>
              <UserTonalityInput
                maxAmount={maxAmount}
                userTonalities={userTonalities}
                onChange={handleCustomTonalityChange}
                size="medium"
                textFieldProps={{ id: elementId }}
              />
              <Typography variant="caption">
                <FormattedMessage
                  id={
                    totalAmountSelected >= maxLength
                      ? 'common.tonality.custom.hint_using_max_amount'
                      : 'common.tonality.custom.hint_press_enter'
                  }
                  values={{ maxLength }}
                />
              </Typography>
            </>
          ) : (
            <AddCustomTonalitiesButton
              startIcon={<AddIcon />}
              variant="text"
              onClick={() => setShowCustomTonalityInput(true)}
            >
              <FormattedMessage id="common.tonality.custom.cta" />
            </AddCustomTonalitiesButton>
          )}
        </TonalityContainer>
      )}
    </BaseSetting>
  );
};

const TonalityContainer = styled(FlexContainer).attrs({ direction: 'column', gap: 'small' })``;

const ChipContainer = styled(FlexContainer)`
  flex-wrap: wrap;
`;

const AddCustomTonalitiesButton = styled(Button)`
  align-self: flex-start;
`;

const PrimaryWhiteChip = styled(Chip)<{ $selected: boolean }>`
  color: ${({ $selected, theme }) => $selected && theme.colors.white};
  background-color: ${({ $selected, theme }) => $selected && theme.colors.primary};

  &:hover {
    background-color: ${({ $selected, theme }) =>
      !$selected ? theme.colors.primaryHover : theme.colors.primary300};
    color: ${({ theme }) => theme.colors.white};
  }
`;

const Counter = styled(Typography)`
  color: ${({ theme }) => theme.colors.blackDisabled};
`;
