import {
  updateStoredMessages,
  useStoredMessages
} from 'features/aiWriter/AiWriterSidebar/steps/chat/chatStore';
import {
  extractMessages,
  useMessagesQuery
} from 'features/aiWriter/AiWriterSidebar/steps/chat/useMessagesQuery';
import { useLayoutEffect } from 'react';

export function useMessages() {
  const storedMessages = useStoredMessages();
  const query = useMessagesQuery();
  const queryMessages = query.data ? extractMessages(query.data) : [];
  const messages =
    storedMessages.length < 1 && queryMessages.length > 0 ? queryMessages : storedMessages;
  const loadMore = query.fetchNextPage;

  // The query might be not enabled, in this case isLoading would be true until it's enabled.
  // Therefore, we also use the fetchStatus which is 'idle' when the query is not enabled.
  const isLoading = query.isLoading && query.fetchStatus !== 'idle';

  useLayoutEffect(() => {
    if (messages !== storedMessages) {
      updateStoredMessages({ messages });
    }
  }, [storedMessages, messages]);

  return {
    loadMore,
    isFetching: query.isFetching,
    isLoading,
    hasMore: query.hasNextPage ?? false,
    messages
  };
}
