import { SortRounded } from '@mui/icons-material';
import { Menu, MenuItem, Typography } from '@mui/material';
import FlexContainer from 'components/FlexContainer';
import {
  SortOption,
  sortOptions,
  useTextInspirationsStore
} from 'features/aiWriter/AiWriterSidebar/steps/textInspirations/useTextInspirationsStore';
import { TextTypeActionButton } from 'features/textGenerator/EnhancedOutputTypeAutocomplete/TextTypesButton';
import { useRef, useState } from 'react';
import { GAEvents } from 'services/tracking/GAEvents';
import gtmIds from 'services/tracking/GTMIds';
import useTr from 'utils/hooks/useTr';

export type Props = {
  isDisabled?: boolean;
};

export const SortResultsButton = ({ isDisabled }: Props) => {
  const translate = useTr();

  const buttonRef = useRef<HTMLButtonElement>(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const sortOption = useTextInspirationsStore(state => state.sortOption);
  const setSortOption = useTextInspirationsStore(state => state.setSortOption);

  const handleClose = () => {
    setIsMenuOpen(false);
  };

  const handleMenuItemClick = (newSortOption: SortOption) => {
    setSortOption(newSortOption);
    setIsMenuOpen(false);

    GAEvents.textInspirationsSort({ sort: newSortOption });
  };

  return (
    <>
      <TextTypeActionButton
        ref={buttonRef}
        variant="text"
        onClick={() => setIsMenuOpen(true)}
        disabled={isDisabled}
      >
        <FlexContainer direction="row" gap="xsmall" alignItems="center">
          <SortRounded fontSize="small" />

          <Typography id={gtmIds.aiWriter.sortTextInspirations} variant="subtitle2">
            {translate('aiWriter.inspirations.textInspirations.main.sort')}
          </Typography>
        </FlexContainer>
      </TextTypeActionButton>

      <Menu anchorEl={buttonRef.current} open={isMenuOpen} onClose={handleClose}>
        {sortOptions.map(option => (
          <MenuItem
            key={option}
            onClick={() => handleMenuItemClick(option)}
            selected={sortOption === option}
          >
            {translate(`aiWriter.inspirations.textInspirations.main.sorting.${option}`)}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};
