import { FormattedMessage } from 'react-intl';
import { FilterProps } from 'react-table';
import { useDebouncedCallback } from 'use-debounce';

import useTr from '../../../utils/hooks/useTr';
import { TableFilterNumericInput } from '../TableComponents';

type ColumnProps = Pick<FilterProps<object>['column'], 'filterValue' | 'setFilter'>;
type FilterPropsType = { column: ColumnProps };

type BaseProps = {
  min?: string;
  step?: string;
  max?: string;
  parseValue?: (value: number | undefined) => number | undefined;
} & FilterPropsType['column'];

const NumberRangeFilterBase = ({
  filterValue = [undefined, undefined],
  setFilter,
  step,
  min,
  max,
  parseValue
}: BaseProps) => {
  const tr = useTr();
  const [debouncedSetFilter] = useDebouncedCallback(setFilter, 300);

  return (
    <div className="d-flex align-items-center">
      <TableFilterNumericInput
        defaultValue={`${filterValue[0]}`}
        autoFocus
        step={step}
        min={min}
        max={max}
        type="number"
        onChange={e => {
          const val = e.target.value;
          debouncedSetFilter((old = [undefined, undefined]) => [
            val ? (parseValue ? parseValue(parseFloat(val)) : parseFloat(val)) : undefined,
            old[1]
          ]);
        }}
        placeholder={tr('min')}
      />
      <span className="mx-1">
        <FormattedMessage id="common.to" />
      </span>
      <TableFilterNumericInput
        defaultValue={`${filterValue[1]}`}
        step={step}
        min={min}
        max={max}
        type="number"
        onChange={e => {
          const val = e.target.value;
          debouncedSetFilter((old = [undefined, undefined]) => [
            old[0],
            val ? (parseValue ? parseValue(parseFloat(val)) : parseFloat(val)) : undefined
          ]);
        }}
        placeholder={tr('max')}
      />
    </div>
  );
};

export const NumberRangeFilterDecimal = ({
  column: { filterValue = [undefined, undefined], setFilter }
}: FilterPropsType) => (
  <NumberRangeFilterBase
    filterValue={filterValue}
    setFilter={setFilter}
    step="0.01"
    min="-1"
    max="1"
  />
);

export const NumberRangeFilterAny = ({
  column: { filterValue = [undefined, undefined], setFilter }
}: FilterPropsType) => <NumberRangeFilterBase filterValue={filterValue} setFilter={setFilter} />;

export const NumberRangeFilterPercentage = ({
  column: { filterValue = [undefined, undefined], setFilter }
}: FilterPropsType) => (
  <NumberRangeFilterBase
    filterValue={filterValue}
    setFilter={setFilter}
    step="1"
    min="-100"
    max="100"
    parseValue={value => (value ? value / 100 : value)}
  />
);
