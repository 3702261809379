import LogoAnimation from 'assets/loadingAnimation/logo-animation.lottie';
import { HalfMask } from 'components/AppLoader';
import FullMask from 'components/FullMask';
import { LottieComponent } from 'features/lottie/LottieComponent';
import { useModulareWorkflowRunnerStore } from 'features/modular-workflow/runner/useModulareWorkflowRunnerStore';
import { useWorkflowRunData } from 'features/modular-workflow/runner/useWorkflowRunData';
import { useModulareWorkflowStructureStore } from 'features/modular-workflow/useModulareWorkflowStructureStore';
import { useModularWorkflowStructure } from 'features/modular-workflow/useModularWorkflowStructure';
import { ReactNode, useEffect } from 'react';
import { useParams } from 'react-router';

type Props = {
  children: ReactNode;
};

/**
 * Provides data for both, the {@link useModulareWorkflowStructureStore} and {@link useModulareWorkflowRunnerStore}
 */
export const ModularWorkflowDataAndStructureProvider = ({ children }: Props) => {
  const setStructure = useModulareWorkflowStructureStore(state => state.setStructure);
  const workflowStructure = useModulareWorkflowStructureStore(state => state.structure);
  const setWorkflow = useModulareWorkflowRunnerStore(state => state.setWorkflow);
  const workflow = useModulareWorkflowRunnerStore(state => state.workflow);
  const resetWorkflowData = useModulareWorkflowRunnerStore(state => state.reset);

  const { workflowId } = useParams<{ workflowId: string }>();
  const { data: fetchedWorkflowStructure } = useModularWorkflowStructure();
  const workflowIdAsNumber = Number(workflowId);
  const { data: fetchedWorkflow } = useWorkflowRunData(workflowIdAsNumber);

  // On Onmount, reset data to ensure on next visit the data is loaded again
  useEffect(() => {
    return () => resetWorkflowData();
  }, [resetWorkflowData]);

  useEffect(() => {
    if (fetchedWorkflow) {
      setWorkflow(fetchedWorkflow);
    }
  }, [fetchedWorkflow, setWorkflow]);

  useEffect(() => {
    if (fetchedWorkflowStructure) {
      setStructure(fetchedWorkflowStructure);
    }
  }, [fetchedWorkflowStructure, setStructure]);

  // Show loader until all is loaded
  // Note: We're using the store values here instead of the fetched values, because the underlying
  // components are using the store values as well. This way we can ensure that the loader is shown
  // until the store values are set.
  if (!workflow || !workflowStructure) {
    return (
      <FullMask style={{ opacity: 1 }} initial={{ opacity: 1 }} exit={{ opacity: 0 }}>
        <HalfMask>
          <LottieComponent src={LogoAnimation} options={{ speed: 1.5 }} />
        </HalfMask>
      </FullMask>
    );
  }

  return <>{children}</>;
};
