/* eslint-disable react/prop-types */
import cx from 'classnames';
import React from 'react';

const classedComponent =
  (
    baseClassName: string,
    BaseComponent: React.ElementType = 'div'
  ): React.FC<React.ComponentPropsWithoutRef<typeof BaseComponent>> =>
  ({ className, children, ...props }) =>
    (
      <BaseComponent className={cx(baseClassName, className)} {...props}>
        {children}
      </BaseComponent>
    );

export default classedComponent;
