import { Button } from '@mui/material';
import { ColoredTypography } from 'components/ColoredTypography';
import FlexContainer from 'components/FlexContainer';
import { useModal } from 'components/modals';
import { CloseModal } from 'components/modals/types';
import { setCanceledSubscriptionForTeamsSeen } from 'features/customer/store/actions';
import {
  getCustomerRole,
  getHasSeenCanceledSubscriptionForTeams,
  getIsAppReady,
  getIsAuthenticated
} from 'features/customer/store/selectors';
import { CustomerRole } from 'features/customer/store/types';
import { hasSubscriptionEnded } from 'features/pricing/utils/isSubscriptionEnded';
import { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import styled from 'styled-components';

export function TeamSubscriptionCanceledWarningModal(props: CloseModal) {
  const { closeModal } = props;

  const dispatch = useAppDispatch();

  const handleButtonClick = () => {
    dispatch(setCanceledSubscriptionForTeamsSeen());
    closeModal();
  };

  return (
    <Root>
      <ColoredTypography variant="h5">
        <FormattedMessage id="team.subscription_canceled_warning.title" />
      </ColoredTypography>

      <ColoredTypography variant="body1">
        <FormattedMessage id="team.subscription_canceled_warning.body" />
      </ColoredTypography>

      <FlexContainer direction="row" gap="medium" justifyContent="flex-end">
        <Button onClick={handleButtonClick} variant="contained">
          <FormattedMessage id="common.close" />
        </Button>
      </FlexContainer>
    </Root>
  );
}

const Root = styled(FlexContainer).attrs({ gap: 'medium' })`
  padding: ${({ theme }) => theme.spacings.xmedium};
`;

export function TeamSubscriptionCanceledWarning() {
  const { showModal } = useModal();

  const isAuthenticated = useAppSelector(getIsAuthenticated);
  const isAppReady = useAppSelector(getIsAppReady);
  const hasSeenCanceledSubscriptionForTeams = useAppSelector(
    getHasSeenCanceledSubscriptionForTeams
  );
  const { subscription: currentSubscriptionState } = useAppSelector(state => state.customer);

  const isSubscriptionEnded =
    currentSubscriptionState && hasSubscriptionEnded(currentSubscriptionState);

  const customerRole = useAppSelector(getCustomerRole);
  const isNotOwner = customerRole !== CustomerRole.OWNER;

  const shouldDisplayWarning =
    isSubscriptionEnded && isNotOwner && !hasSeenCanceledSubscriptionForTeams;

  useEffect(() => {
    if (!isAuthenticated || !isAppReady) {
      return;
    }

    if (shouldDisplayWarning) {
      showModal('TEAM_SUBSCRIPTION_CANCELED_WARNING', {
        size: 480
      });
    }
  }, [
    isAppReady,
    isAuthenticated,
    hasSeenCanceledSubscriptionForTeams,
    shouldDisplayWarning,
    showModal
  ]);

  return null;
}
