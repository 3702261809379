import { Button } from '@mui/material';
import { ColoredTypography } from 'components/ColoredTypography';
import FlexContainer from 'components/FlexContainer';
import FormattedMessage from 'features/i18n/FormattedMessage';
import styled from 'styled-components';

export function TeamMemberNeedsRefreshModal() {
  const handleRefreshClick = () => {
    location.reload();
  };

  return (
    <Root>
      <FlexContainer direction="row" justifyContent="space-between" alignItems="center">
        <ColoredTypography variant="h5">
          <FormattedMessage id="team.needs_refresh_modal.title" />
        </ColoredTypography>
      </FlexContainer>

      <ColoredTypography variant="body1">
        <FormattedMessage
          id="team.needs_refresh_modal.body"
          values={{
            br: <br />
          }}
        />
      </ColoredTypography>

      <FlexContainer direction="row" gap="medium" justifyContent="flex-end">
        <Button onClick={handleRefreshClick} variant="contained">
          <FormattedMessage id="team.needs_refresh_modal.button_refresh" />
        </Button>
      </FlexContainer>
    </Root>
  );
}

const Root = styled(FlexContainer).attrs({ gap: 'medium' })`
  padding: ${({ theme }) => theme.spacings.xmedium};
`;
