import { BaseSuccessDto } from 'services/backofficeIntegration/http/BaseSuccessDto';
import { backofficeClient } from 'services/backofficeIntegration/http/client/createBackofficeClient';
import { queryEndpoint } from 'services/backofficeIntegration/http/endpointDefinition';
import { personalitiesQueryKeys } from 'services/backofficeIntegration/http/queryKeyFactories/personalitiesQueryKeys';
import { queryClient } from 'utils/ReactQueryClient';

export type GetPersonalityLimitationsSuccessDto = BaseSuccessDto<PersonalityLimitationDto>;

type PersonalityLimitationDto = {
  limitName: string;
  limit: number;
  limitWithoutBonus: number;
  bonus: number;
  used: number;
  available: number;
  isUnlimited: boolean;
  availability: {
    [key: string]: {
      isAvailable: boolean;
      isUnlimited: boolean;
      availableRequestCount: number;
    };
  };
};

export const httpGetPersonalityLimitations = queryEndpoint({
  makeQueryKey: () => {
    return [...personalitiesQueryKeys.forLimitations()] as const;
  },
  callEndpoint: () => {
    return backofficeClient
      .get<GetPersonalityLimitationsSuccessDto>('/chat/personalities/limitations')
      .then(response => {
        return response.data;
      });
  }
});

export function invalidatePersonalitiesLimitationsQuery() {
  return queryClient.invalidateQueries({
    queryKey: [...personalitiesQueryKeys.forLimitations()]
  });
}
