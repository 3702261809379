import { MenuItem } from '@mui/material';
import { LanguageMenu } from 'features/aiWriter/AiWriterWorkspace/header/LanguageMenu';
import { setFlashActionsSelectedAction } from 'features/aiWriter/slashCommands/hooks/useFlashActionsStore';
import {
  useShouldShowWordsLimitReachedModal,
  useShowWordsLimitReachedModal
} from 'features/pricing/hook/useYouRunOutOfWordsModal';
import { GAEvents } from 'services/tracking/GAEvents';
import styled from 'styled-components';
type Props = {
  anchorElement: null | HTMLElement;
  isOpen: boolean;
  close: () => void;

  onSelect: () => void;
};

export function TranslateToSubMenu(props: Props) {
  const { shouldShowRunOutOfWordsModal } = useShouldShowWordsLimitReachedModal();
  const showWordsLimitReachedModal = useShowWordsLimitReachedModal();

  const handleTranslate = (selectedLanguage: string) => {
    GAEvents.flashActionInEditorExecuted({
      action: 'translate',
      prompt,
      language: selectedLanguage
    });

    if (shouldShowRunOutOfWordsModal()) {
      showWordsLimitReachedModal();
      return;
    }

    setFlashActionsSelectedAction({
      name: 'translate',
      languageModel: selectedLanguage
    });
    props.onSelect();
  };

  return (
    <LanguageMenu
      anchorEl={props.anchorElement}
      open={props.isOpen}
      onClose={props.close}
      handleLanguageCustomClick={handleTranslate}
      anchorOrigin={{
        vertical: 'center',
        horizontal: 'right'
      }}
      transformOrigin={{
        vertical: 'center',
        horizontal: 'left'
      }}
    />
  );
}

export const StyledMenuItem = styled(MenuItem)<{ $showHover?: boolean }>`
  background-color: ${({ $showHover }) => ($showHover ? 'rgba(0, 0, 0, 0.04)' : 'transparent')};
`;
