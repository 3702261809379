import { useQuery } from '@tanstack/react-query';
import { PricingCouponField } from 'components/profile/components/subscription/PricingCouponField';
import FormattedMessage from 'features/i18n/FormattedMessage';
import { ReactElement } from 'react';
import { httpGetActiveCoupon } from 'services/backofficeIntegration/http/endpoints/customer/httpGetActiveCoupon';

const ApplyPromotionCode = (): ReactElement => {
  const { data: activeCoupon } = useQuery({
    queryKey: httpGetActiveCoupon.makeQueryKey(),
    queryFn: () => httpGetActiveCoupon.callEndpoint()
  });

  if (activeCoupon) {
    return (
      <FormattedMessage
        id="profile.subscription.subscription_coupon_active"
        values={{ coupon: activeCoupon.name }}
      />
    );
  }

  return <PricingCouponField />;
};

export default ApplyPromotionCode;
