import { createPluginFactory, HotkeyPlugin } from '@udecode/plate';
import { useHotkeys } from '@udecode/plate-common';
import { useHotkeyActionHandler } from 'features/aiWriter/AiWriterTextEditor/hooks/useHotkeyActionHandler';
import { ACTION_HOTKEYS } from 'features/aiWriter/AiWriterTextEditor/utils/consts';
import { Hotkey } from 'react-hotkeys-hook/src/types';

export const KEY_TYPE_HOTKEY_HANDLER = 'NF - hotkey handler';

const parsedHotkeyToString = (hotkey: Hotkey) => {
  const keys = [];

  if (hotkey.alt) {
    keys.push('alt');
  }
  if (hotkey.ctrl) {
    keys.push('ctrl');
  }
  if (hotkey.shift) {
    keys.push('shift');
  }
  if (hotkey.meta) {
    keys.push('meta');
  }
  if (hotkey.mod) {
    keys.push('mod');
  }

  if (hotkey.keys) {
    keys.push(...hotkey.keys);
  }

  return keys.join('+');
};

export const createCustomHotkeyPlugin = createPluginFactory<HotkeyPlugin>({
  key: KEY_TYPE_HOTKEY_HANDLER,
  useHooks: () => {
    const actionHandler = useHotkeyActionHandler();

    // TODO: Find a way to do this in a forEach

    useHotkeys(
      ACTION_HOTKEYS.continue_writing,
      (_event, hotkey) => {
        actionHandler('continue_writing', parsedHotkeyToString(hotkey));
      },
      {
        enableOnContentEditable: true
      }
    );

    useHotkeys(
      ACTION_HOTKEYS.command,
      (_event, hotkey) => {
        actionHandler('command', parsedHotkeyToString(hotkey));
      },
      {
        enableOnContentEditable: true
      }
    );

    useHotkeys(
      ACTION_HOTKEYS.clearFormatting,
      (_event, hotkey) => {
        actionHandler('clearFormatting', parsedHotkeyToString(hotkey));
      },
      {
        enableOnContentEditable: true
      }
    );
  }
});
