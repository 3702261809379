/* eslint-disable @typescript-eslint/no-explicit-any */
import { SpinnerDimmer } from 'components/base/SpinnerDimmer';
import React, { useEffect, useMemo } from 'react';
import {
  Column,
  Filters,
  Row,
  SortingRule,
  TableInstance,
  TableState,
  useFilters,
  useFlexLayout,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable
} from 'react-table';

import TextFilter from './filterComponents/TextFilter';
import { filterTypesDict as filterTypes } from './filters';
import useStickyTableHeader from './hooks/useStickyTableHeader';
import Pagination from './Pagination';
import { sortTypesDict as sortTypes } from './sorting';
import {
  ActionsBottom,
  ActionsBottomSection,
  FlexTable,
  FlexTableWrapper,
  HeaderRowContainer,
  RowsContainer,
  TableRowElement
} from './TableComponents';
import TableHeaderCell from './TableHeaderCell';
import TableRow from './TableRow';

export type FetchDataProps<TData extends object> = {
  pageIndex: number;
  pageSize: number;
  sortBy: SortingRule<TData>[];
  filters: Filters<TData>;
};

type Props<TData extends object> = {
  data: TData[];
  columns: Array<Column<TData>>;
  loading?: boolean;
  fetchData?: (props: FetchDataProps<TData>) => void;
  forceRefetch?: any;
  remotePageCount?: number;
  manualPagination?: boolean;
  pagination?: boolean;
  defaultPageSize?: number;
  height?: string | number;
  minHeight?: string | number;
  maxHeight?: string | number;
  rowEvents?: {
    onClick?: (row: Row<TData>) => void;
    onMouseEnter?: (row: Row<TData>) => void;
    onMouseLeave?: (row: Row<TData>) => void;
  };
  rowClasses?: (row: Row<TData>) => string | undefined;
  tableActions?: (instance: TableInstance<TData>) => React.ReactElement;
  topTableActions?: (instance: TableInstance<TData>) => React.ReactElement;
  manualRowSelectedKey?: string;
  initialState?: Partial<TableState>;
  persistState?: (tableState: Partial<TableState>) => void;
  stateOverrides?: Partial<TableState>;
  disableMultiSort?: boolean;
};

const returnUndefined = () => undefined;

function Table<TData extends object>({
  data,
  columns,
  loading,
  fetchData,
  forceRefetch,
  remotePageCount,
  manualPagination = false,
  pagination = manualPagination,
  height,
  minHeight,
  maxHeight,
  rowEvents,
  tableActions,
  topTableActions,
  rowClasses = returnUndefined,
  manualRowSelectedKey = 'selected',
  initialState,
  persistState,
  stateOverrides,
  disableMultiSort
}: Props<TData>) {
  const { headerContainerRef, headerRowRef, tableBodyRef } = useStickyTableHeader();

  const defaultColumn = React.useMemo(
    (): Partial<Column<TData>> => ({
      Filter: TextFilter,
      Cell: ({ cell: { value } }) => (value ? <span>{value}</span> : null)
    }),
    []
  );

  const useControlledState = (state: TableState) => {
    return useMemo(() => {
      return {
        ...state,
        ...stateOverrides
      };
      // There is a false positive here:
      // > React Hook useMemo has an unnecessary dependency: 'stateOverrides'
      // Please be cautious when modifying this dependencies array
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state, stateOverrides]);
  };

  /**
   * There is a difference in behavior of react-table if
   * `{ pageCount: undefined }` vs `{}` (no pageCount at all).
   * When `pageCount` was set explicitly to undefined pagination
   * stopped working.
   */
  const manualPaginationConfig = manualPagination
    ? {
        manualPagination: true,
        manualSortBy: true,
        manualFilters: true,
        pageCount: remotePageCount
      }
    : {};

  const instance = useTable<TData>(
    {
      data,
      columns,
      // Using these filterTypes is unsafe, but it worked before types where upgraded so I leave it as is.
      // @ts-expect-error #tech-debt https://app.clickup.com/t/862jpmqgy
      filterTypes,
      // Using these sortTypes is unsafe, but it worked before types where upgraded so I leave it as is.
      // @ts-expect-error #tech-debt https://app.clickup.com/t/862jpmqgy
      sortTypes,
      defaultColumn,
      initialState: { ...initialState, pageSize: 50 },
      useControlledState,
      manualRowSelectedKey,
      disableMultiSort,
      ...manualPaginationConfig
    },
    useFilters,
    useSortBy,
    usePagination,
    useRowSelect,
    useFlexLayout
  );

  const {
    getTableProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    toggleAllRowsSelected,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    totalColumnsWidth
  } = instance;

  const { pageIndex, pageSize, filters, sortBy } = state;

  useEffect(() => {
    if (persistState) {
      persistState({ filters, sortBy });
    }
  }, [filters, persistState, sortBy]);

  useEffect(() => {
    if (fetchData) {
      toggleAllRowsSelected(false);
      fetchData({ pageIndex, pageSize, filters, sortBy });
    }
  }, [fetchData, pageIndex, pageSize, filters, sortBy, forceRefetch, toggleAllRowsSelected]);

  return (
    <SpinnerDimmer loader active={loading}>
      <FlexTableWrapper>
        {topTableActions?.(instance)}
        <FlexTable {...getTableProps()}>
          <HeaderRowContainer minWidth={totalColumnsWidth} ref={headerContainerRef}>
            {headerGroups.map(headerGroup => (
              // eslint-disable-next-line react/jsx-key
              <TableRowElement {...headerGroup.getHeaderGroupProps()} ref={headerRowRef}>
                {headerGroup.headers.map(column => (
                  <TableHeaderCell column={column} key={column.id} />
                ))}
              </TableRowElement>
            ))}
          </HeaderRowContainer>
          <RowsContainer
            minHeight={minHeight}
            height={height}
            maxHeight={maxHeight}
            minWidth={totalColumnsWidth}
            ref={tableBodyRef}
          >
            {(pagination ? page : rows).map(row => {
              prepareRow(row);
              return (
                // eslint-disable-next-line react/jsx-key
                <TableRow
                  {...row.getRowProps({ className: rowClasses(row) })}
                  row={row}
                  rowEvents={rowEvents}
                />
              );
            })}
          </RowsContainer>
        </FlexTable>
      </FlexTableWrapper>
      {pagination || tableActions ? (
        <ActionsBottom>
          {pagination && (
            <Pagination
              pageCount={pageCount}
              pageOptions={pageOptions}
              canPreviousPage={canPreviousPage}
              canNextPage={canNextPage}
              gotoPage={gotoPage}
              previousPage={previousPage}
              nextPage={nextPage}
              setPageSize={setPageSize}
              pageIndex={pageIndex}
              pageSize={pageSize}
            />
          )}
          {tableActions && <ActionsBottomSection>{tableActions(instance)}</ActionsBottomSection>}
        </ActionsBottom>
      ) : null}
    </SpinnerDimmer>
  );
}

export default Table;
