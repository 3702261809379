import { focusEditor, PlateEditor, Value } from '@udecode/plate-common';
import { resetFlashActionsStore } from 'features/aiWriter/slashCommands/hooks/useFlashActionsStore';
import { flashActionsInput } from 'features/plate/customPlugins/queries/findFlashActionsInput';
import { removeSlashCommandsInput as removeFlashActionsInput } from 'features/plate/customPlugins/transforms/removeSlashCommandsInput';

export function findAndCloseFlashActionInput(editor: PlateEditor<Value>) {
  const currentFlashActionsInput = flashActionsInput(editor);

  return () => {
    if (currentFlashActionsInput) {
      removeFlashActionsInput(editor, currentFlashActionsInput[1]);
      resetFlashActionsStore(); // this is a side effect, so maybe a Zustand-Context combo would be better
      focusEditor(editor);
    }
  };
}
