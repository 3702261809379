import { motion } from 'framer-motion';
import styled from 'styled-components';

const FullMask = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  background: ${({ theme }) =>
    theme.mode === 'dark' ? theme.colors.commonBlackMain : theme.colors.commonWhiteMain};
  z-index: 40000;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default FullMask;
