import {
  ELEMENT_BLOCKQUOTE,
  ELEMENT_H1,
  ELEMENT_H2,
  ELEMENT_H3,
  ELEMENT_LI,
  ELEMENT_OL,
  ELEMENT_PARAGRAPH,
  ELEMENT_UL
} from '@udecode/plate';

const migrationMap = {
  paragraph: ELEMENT_PARAGRAPH,
  'heading-one': ELEMENT_H1,
  'heading-two': ELEMENT_H2,
  'heading-three': ELEMENT_H3,
  'bulleted-list': ELEMENT_UL,
  'numbered-list': ELEMENT_OL,
  'list-item': ELEMENT_LI,
  'block-quote': ELEMENT_BLOCKQUOTE
} as const;

type DeprecatedType = keyof typeof migrationMap;

const typesToMigrate = new Set(Object.keys(migrationMap));

export function isDeprecatedNodeType(type: string): type is keyof typeof migrationMap {
  return typesToMigrate.has(type);
}

export function convertDeprecatedNodeType(type: DeprecatedType) {
  return migrationMap[type];
}
