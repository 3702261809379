import SearchIcon from '@mui/icons-material/Search';
import { TextField } from '@mui/material';
import FormattedMessage from 'features/i18n/FormattedMessage';
import { ChangeEvent } from 'react';
import styled from 'styled-components';

type Props = {
  value: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
};

export function SearchControlled(props: Props) {
  return (
    <TextField
      size="small"
      label={<FormattedMessage id="common.search" />}
      value={props.value}
      onChange={props.onChange}
      InputProps={{
        endAdornment: <MagnifyingGlassIcon />
      }}
    />
  );
}

const MagnifyingGlassIcon = styled(SearchIcon)`
  color: ${({ theme }) => theme.colors.blackDisabled};
`;
