import WarningAmberOutlined from '@mui/icons-material/WarningAmberOutlined';
import { LoadingButton } from '@mui/lab';
import { Alert, AlertTitle } from '@mui/material';
import FormattedMessage from 'features/i18n/FormattedMessage';
import gtmIds from 'services/tracking/GTMIds';
import { withGtmInteraction } from 'services/tracking/withGtmInteraction';

type Props = {
  isRefreshing: boolean;
  wordCount: number;
  onRefreshClick: () => void;
};

export const RefreshScoringCard = ({ isRefreshing, wordCount, onRefreshClick }: Props) => {
  return (
    <Alert
      severity="warning"
      variant="filled"
      icon={<WarningAmberOutlined />}
      action={
        <LoadingButton
          size="small"
          variant="outlined"
          color="inherit"
          loading={isRefreshing}
          onClick={onRefreshClick}
          {...withGtmInteraction(gtmIds.aiWriter.flashScore.refreshScoring)}
        >
          <FormattedMessage id="aiWriter.inspirations.flashScore.refresh.cta" />
        </LoadingButton>
      }
    >
      <AlertTitle>
        <FormattedMessage id="aiWriter.inspirations.flashScore.refresh.title" />
      </AlertTitle>
      <FormattedMessage
        id="aiWriter.inspirations.flashScore.refresh.message"
        values={{ wordCount }}
      />
    </Alert>
  );
};
