const countPricingWithDiscount = ({
  pricing,
  amountOff,
  percentOff
}: {
  pricing: number;
  amountOff?: number;
  percentOff?: number;
}) => {
  if (amountOff) {
    return pricing - amountOff;
  }

  if (percentOff) {
    return pricing * ((100 - percentOff) / 100);
  }

  return pricing;
};

export default countPricingWithDiscount;
