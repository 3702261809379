import cx from 'classnames';

import Icon from '../base/Icon';
import styles from './ColumnSortArrows.module.scss';

type Props = {
  onClick?: React.DOMAttributes<HTMLDivElement>['onClick'];
  isSorted: boolean;
  isSortedDesc: boolean;
};

const ColumnSortArrows = ({ onClick, isSorted, isSortedDesc }: Props) => {
  const icons = [
    ['chevron-up', 'asc'],
    ['chevron-down', 'desc']
  ];

  return (
    <div className={styles.sortingArrows} onClick={onClick}>
      {icons.map(([icon, sortOrder]) => {
        let isSortedValue = false;
        if (isSorted) {
          isSortedValue = isSortedDesc ? sortOrder === 'desc' : sortOrder === 'asc';
        }

        return (
          <Icon
            name={icon}
            key={icon}
            className={cx(styles.arrow, isSortedValue && styles.visible)}
          />
        );
      })}
    </div>
  );
};

export default ColumnSortArrows;
