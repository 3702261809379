import { useLocation } from 'react-router-dom';

import { parseUrlQuery } from '../urlUtils';

function useURLQuery() {
  const { search } = useLocation();
  return parseUrlQuery(search);
}

export default useURLQuery;
