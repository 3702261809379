import { LinearProgress, Typography } from '@mui/material';
import { getUserName } from 'features/customer/store/selectors';
import { TaskList } from 'features/onboardingJourney/checklist/Checklist';
import { TaskItem } from 'features/onboardingJourney/checklist/TaskItem';
import { useMkTaskProperties } from 'features/onboardingJourney/checklist/useMkTaskProperties';
import { useOnboardingChecklistStore } from 'features/onboardingJourney/checklist/useOnboardingChecklist';
import { FormattedMessage } from 'react-intl';
import { useAppSelector } from 'store/hooks';
import styled from 'styled-components';
import { card } from 'styles/styledComponents/mixins';
import { resetLink } from 'styles/styledComponents/resets';

type Props = {
  onClose?: () => void;
};

export const HomeOnboardingFeatureCard = ({ onClose }: Props) => {
  const username = useAppSelector(getUserName);

  const tasks = useOnboardingChecklistStore(state => state.tasks);
  const finishedTasksCount = useOnboardingChecklistStore(state => state.finishedTaskCount);
  const taskListCount = useOnboardingChecklistStore(state => state.taskCount);

  const tasksProps = useMkTaskProperties();
  const progress = Math.round((finishedTasksCount / taskListCount) * 100);

  return (
    <Root>
      <Typography variant="h6">
        <FormattedMessage id="onboarding_checklist.home.title" values={{ username }} />
      </Typography>

      <Content>
        <Typography variant="body1">
          <FormattedMessage
            id="onboarding_checklist.home.description"
            values={{
              br: <br />,
              b: (text: string) => <strong>{text}</strong>
            }}
          />
        </Typography>

        <TaskList>
          {tasks.map(task => (
            <TaskItem
              key={task.id}
              isCompleted={task.isCompleted}
              title={tasksProps[task.alias].title}
              tooltipMessage={tasksProps[task.alias].tooltipMessage}
              onClick={() => {
                onClose && onClose();
                tasksProps[task.alias].handleAction();
              }}
            />
          ))}
        </TaskList>
      </Content>

      <Footer>
        <ProgressBar variant="determinate" value={progress} />
        <Typography>{progress}%</Typography>
      </Footer>
    </Root>
  );
};

const Root = styled.div`
  ${resetLink};
  ${card};

  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.medium};

  padding: ${({ theme }) => `${theme.spacings.small} ${theme.spacings.medium}`};
`;

const Content = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: ${({ theme }) => theme.spacings.medium};
`;

const Footer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${({ theme }) => theme.spacings.three};
`;

const ProgressBar = styled(LinearProgress)`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.secondaryColorFocus};

  .MuiLinearProgress-bar1Determinate {
    background-color: ${({ theme }) => theme.colors.secondaryColorMain};
  }
`;
