import { Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { Dimension } from 'services/api/dimension/types';

type Props = {
  dimension: Dimension;
  wordsCutAmount: number;
};

const DimensionChipTooltip = ({ dimension, wordsCutAmount }: Props) => {
  const wordsTooltip = dimension.words?.slice(0, wordsCutAmount).join(', ') ?? [];
  const wordCount = dimension.words ? dimension.words.length - wordsCutAmount : 0;
  return (
    <>
      <Typography>
        <FormattedMessage id="associations.words_tooltip" values={{ words: wordsTooltip }} />{' '}
        {wordCount > 0 && (
          <FormattedMessage id="associations.word_count_and_more" values={{ wordCount }} />
        )}
      </Typography>
      {dimension.description && (
        <Typography>
          <FormattedMessage
            id="dimensions_dropdown.description"
            values={{ desc: dimension.description }}
          />
        </Typography>
      )}
    </>
  );
};

export default DimensionChipTooltip;
