import {
  findNode,
  FindNodeOptions,
  getPluginType,
  PlateEditor,
  Value
} from '@udecode/plate-common';
import {
  ELEMENT_FLASH_ACTIONS_INPUT,
  TFlashActionsInputElement
} from 'features/plate/customPlugins/createflashActionsPlugin';

export const flashActionsInput = <V extends Value>(
  editor: PlateEditor<V>,
  options?: Omit<FindNodeOptions<V>, 'match'>
) =>
  findNode<TFlashActionsInputElement>(editor, {
    ...options,
    match: { type: getPluginType(editor, ELEMENT_FLASH_ACTIONS_INPUT) }
  });
