/* eslint-disable @typescript-eslint/no-explicit-any */
import { Placement } from 'popper.js';
import React, { useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { Manager, Popper, Reference, ReferenceChildrenProps } from 'react-popper';

import useOnClickOutside from '../utils/hooks/useOnClickOutside';
import DropdownArrow from './Dropdown/DropdownArrow';

export type PopupProps = {
  children: (props: ReferenceChildrenProps & { setOpen: () => void }) => React.ReactNode;
  placement?: Placement;
  offset?: number;
  arrow?: boolean;
  blockOnMobile?: boolean;
};

type Props<C extends React.ComponentType<any> = any> = PopupProps & {
  component: C;
} & React.ComponentPropsWithoutRef<C>;

const Popup = ({
  placement = 'bottom-start',
  offset = 2,
  arrow = true,
  blockOnMobile,
  children,
  component: Component,
  ...componentProps
}: Props) => {
  const contentRef = useRef<HTMLDivElement>(null);
  const [isOpen, setOpen] = useState(false);
  useOnClickOutside(contentRef, () => setOpen(false));

  return (
    <Manager>
      <Reference>
        {({ ref }) =>
          children({
            ref,
            setOpen: () => {
              if ((blockOnMobile && window.innerWidth > 900) || !blockOnMobile) {
                setOpen(true);
              }
            }
          })
        }
      </Reference>
      {isOpen
        ? createPortal(
            <Popper
              placement={placement}
              modifiers={[
                {
                  name: 'offset',
                  options: {
                    offset: [0, offset]
                  }
                }
              ]}
            >
              {({ placement, ref, style, arrowProps }) => (
                <div ref={ref} style={style} data-placement={placement}>
                  {arrow && <DropdownArrow placement={placement} offset={1} {...arrowProps} />}
                  <Component ref={contentRef} setOpen={setOpen} {...componentProps} />
                </div>
              )}
            </Popper>,
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            document.querySelector('#portal')!
          )
        : null}
    </Manager>
  );
};

export default Popup;
