import { useMutation } from '@tanstack/react-query';
import { getActiveTab } from 'features/aiWriter/store/selectors';
import { createScoreTextsResult } from 'features/flashScore/createScoreTextsResult';
import { useGetEmotionalityMapperName } from 'features/flashScore/useGetEmotionalityMapperName';
import { FlashScoreTextRatingResult } from 'features/flashScore/useScoreTexts';
import { httpFlashScoreGeneratedTexts } from 'services/backofficeIntegration/http/endpoints/flashScore/httpFlashScoreGeneratedTexts';
import { useAppSelector } from 'store/hooks';

type Options = {
  texts: string[];
};

export const useScoreTextsMutation = () => {
  const { embeddingModelId } = useAppSelector(getActiveTab);
  const mapperName = useGetEmotionalityMapperName(embeddingModelId);

  return useMutation<FlashScoreTextRatingResult[], Error, Options>({
    mutationFn: options => {
      return httpFlashScoreGeneratedTexts
        .callEndpoint({
          embedding_model_id: embeddingModelId,
          mappers: [
            { id: 'readability', name: 'readability', variable: 'readability' },
            { id: 'emotionality', name: mapperName, variable: 'emotionality' }
          ],
          texts: options.texts
        })
        .then(response => {
          if (!response || !response.status) {
            throw new Error('Failed to fetch data');
          }

          return response.data.map((result, index) =>
            createScoreTextsResult(options.texts[index], result)
          );
        });
    }
  });
};
