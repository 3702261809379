import FormattedMessage from 'features/i18n/FormattedMessage';
import { ApiModularWorkflowStepReference } from 'features/modular-workflow/builder/types-api';
import { ModalListCard } from 'features/theme-2024/modal-list-card/ModalListCard';

type Props = {
  name: string;
  type: ApiModularWorkflowStepReference;
  onClick: (type: ApiModularWorkflowStepReference) => void;
};

export const ModularWorkflowStepsModalStepCard = ({ name, type, onClick }: Props) => {
  return (
    <ModalListCard title={name} onClick={() => onClick(type)}>
      <FormattedMessage id={`modular_workflow.builder.step.${type}`} />
    </ModalListCard>
  );
};
