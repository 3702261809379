import {
  Alert,
  Autocomplete,
  AutocompleteChangeDetails,
  AutocompleteChangeReason,
  Button,
  Checkbox,
  Chip,
  MenuItem,
  Stack,
  TextField,
  Typography
} from '@mui/material';
import Box from '@mui/material/Box';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Dimmer from 'components/base/Dimmer';
import FlexContainer from 'components/FlexContainer';
import DeleteIconButton from 'components/iconButtons/DeleteIconButton';
import { CloseModal } from 'components/modals/types';
import { getCustomerTeamId } from 'features/customer/store/selectors';
import { CustomerRole } from 'features/customer/store/types';
import FormattedMessage from 'features/i18n/FormattedMessage';
import { useInvitedUserMutation } from 'features/profilePage/useInvitedUserMutation';
import { trackingWrapper } from 'features/tracking/wrapper/TrackingWrapper';
import { ReactElement, SyntheticEvent, useState } from 'react';
import { GAEvents } from 'services/tracking/GAEvents';
import { useAppSelector } from 'store/hooks';
import styled from 'styled-components';
import useTr from 'utils/hooks/useTr';
import isValidEmail from 'utils/isValidEmail';

type Props = CloseModal;

export const InviteUserModal = ({ closeModal }: Props) => {
  const translate = useTr();
  const [role, setRole] = useState<CustomerRole>(CustomerRole.MEMBER);
  const [checkboxChecked, setCheckboxChecked] = useState(false);

  const handleRoleChange = (event: SelectChangeEvent) => {
    setRole(event.target.value as CustomerRole);
  };

  const [enteredEmails, setEnteredEmails] = useState<string[]>([]);
  const [error, setError] = useState<ReactElement | null>(null);

  const teamId = useAppSelector(getCustomerTeamId);

  const inviteUsers = useInvitedUserMutation({ setError });

  const handleInviteUsers = () => {
    if (!teamId) {
      return;
    }

    inviteUsers.mutate(
      {
        teamId,
        emails: enteredEmails,
        team_role: role
      },
      {
        onSuccess: () => {
          GAEvents.teamInvitationSent({ teamId, role, emails: enteredEmails });

          trackingWrapper.track('teamInvitationSent', { teamId, role, emails: enteredEmails });

          closeModal();
        }
      }
    );
  };

  const handleAddChip = (chipValues: string[]) => {
    if (isCustomerEmailValid(chipValues[chipValues.length - 1])) {
      setEnteredEmails(chipValues);
      setError(null);
    }
  };

  const handleDeleteChip = (chipValue: string) => {
    const chipIndex = enteredEmails.indexOf(chipValue);
    setEnteredEmails(enteredEmails.filter((_, index) => index !== chipIndex));
  };

  const handleChange = (
    event: SyntheticEvent<Element>,
    value: string[],
    reason: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<string>
  ) => {
    if (reason === 'createOption') {
      handleAddChip(value);
    } else if (reason === 'removeOption' && details?.option) {
      handleDeleteChip(details.option);
    } else if (reason === 'clear') {
      setEnteredEmails([]);
    }
  };

  const isCustomerEmailValid = (email: string) => {
    if (!isValidEmail(email)) {
      setError(
        <FormattedMessage id="profile.users.invite_user.error.invalid_email" values={{ email }} />
      );
      return false;
    }

    return true;
  };

  const handleCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCheckboxChecked(event.target.checked);
  };

  return (
    <ModalBody className="modal-body">
      <Dimmer active={inviteUsers.isLoading}>
        <Header direction="row" alignItems="center" justifyContent="space-between">
          <Headline variant="h5">
            <FormattedMessage id="profile.users.invite_user" />
          </Headline>
          {closeModal && <DeleteIconButton onClick={closeModal} />}
        </Header>
        <FlexContainer
          direction="row"
          alignItems="flex-start"
          justifyContent="space-between"
          style={{ marginTop: 16, marginBottom: 4 }}
          gap="small"
        >
          <Autocomplete<string, true, false, true>
            multiple={true}
            freeSolo={true}
            options={[]}
            value={enteredEmails}
            key={Math.random()}
            onChange={handleChange}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <StyledChip
                  {...getTagProps({ index })}
                  key={Math.random()}
                  label={option}
                  variant="filled"
                />
              ))
            }
            renderInput={params => (
              <TextField
                {...params}
                variant="outlined"
                placeholder={translate('profile.users.invite_user.input_placeholder')}
                autoFocus={true}
                helperText={<FormattedMessage id="profile.users.invite_user.input_help_text" />}
              />
            )}
            size="small"
            sx={{
              width: '100%',
              // Prevent long emails from overflowing
              maxWidth: 450,
              '.MuiOutlinedInput-root.MuiInputBase-sizeSmall': {
                padding: '4px 32px 4px 6px',
                minHeight: '40px'
              }
            }}
          />

          <Select
            value={role}
            onChange={handleRoleChange}
            sx={theme => ({
              '.MuiInputBase-input': {
                paddingTop: theme.spacing(1),
                paddingBottom: theme.spacing(1)
              }
            })}
            // We don't want to render the info text also be shown after selection
            renderValue={value => (
              <Typography>
                {value === CustomerRole.ADMIN ? (
                  <FormattedMessage id="customer.role.admin" />
                ) : (
                  <FormattedMessage id="customer.role.member" />
                )}
              </Typography>
            )}
          >
            <MenuItem value={CustomerRole.ADMIN}>
              <FormattedMessage id="customer.role.admin" />
            </MenuItem>
            <MenuItem value={CustomerRole.MEMBER}>
              <FormattedMessage id="customer.role.member" />
            </MenuItem>
          </Select>
        </FlexContainer>
        <FlexContainer justifyContent="flex-start" style={{ marginBottom: 16 }}>
          {error && (
            <Typography variant="caption" color="error">
              {error}
            </Typography>
          )}
        </FlexContainer>
        <StyledInfoAlert>
          <Stack spacing={2}>
            <Box>
              <Typography variant="subtitle1">
                <FormattedMessage id="profile.users.invite_user.info_message_roles_headline" />
              </Typography>
              <Typography variant="body1">
                <FormattedMessage
                  id="profile.users.invite_user.info_message_roles_text"
                  values={{ br: <br /> }}
                />
              </Typography>
            </Box>
            <Box>
              <Typography variant="subtitle1">
                <FormattedMessage id="profile.users.invite_user.info_message_billing_headline" />
              </Typography>
              <Typography variant="body1">
                <FormattedMessage
                  id="profile.users.invite_user.info_message_billing_text"
                  values={{
                    a: (msg: string) => (
                      <StyledLink
                        href={translate('external_links.app_landingpage_pricing_plans')}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {msg}
                      </StyledLink>
                    )
                  }}
                />
              </Typography>
            </Box>
          </Stack>
        </StyledInfoAlert>
        <CheckboxContainer>
          <StyledCheckbox onChange={handleCheckbox} checked={checkboxChecked} />
          <Typography variant="body1">
            <FormattedMessage id="profile.users.invite_user.invite_checkbox_label" />
          </Typography>
        </CheckboxContainer>
        <Footer direction="row" alignItems="center" justifyContent="flex-end">
          <Button
            variant="contained"
            onClick={handleInviteUsers}
            disabled={inviteUsers.isLoading || enteredEmails.length === 0 || !checkboxChecked}
          >
            <FormattedMessage id="profile.users.invite_user.invite_button_label" />
          </Button>
        </Footer>
      </Dimmer>
    </ModalBody>
  );
};

const ModalBody = styled.div`
  position: relative;
  padding: ${({ theme }) => theme.spacings.xmedium};
`;

const Header = styled(FlexContainer)`
  width: 100%;
`;

const Footer = styled(FlexContainer)`
  width: 100%;
  margin-top: ${({ theme }) => theme.spacings.medium};
`;

const Headline = styled(Typography)`
  && {
    color: ${({ theme }) => theme.colors.blackHighEmphasis};
  }
`;

const StyledLink = styled.a`
  color: ${({ theme }) => theme.colors.black};
  text-decoration: underline;

  &:hover {
    color: inherit;
  }
`;

const StyledChip = styled(Chip)`
  color: ${({ theme }) => theme.colors.black};

  &.MuiAutocomplete-tag {
    height: 28px;
    margin: 0;
  }

  .MuiChip-deleteIcon {
    color: rgba(255, 255, 255, 0.7);
  }
`;

const StyledInfoAlert = styled(Alert).attrs({ severity: 'info' })`
  color: ${({ theme }) => theme.colors.black};

  .MuiAlert-message {
    padding: 5px 0;
  }
`;

const CheckboxContainer = styled.div`
  margin: 1rem 0;
  display: flex;
  align-items: flex-start;
`;

const StyledCheckbox = styled(Checkbox)`
  // The checkbox is not aligned with the text without this
  margin-top: -9px;
`;
