import InputField from 'components/forms/InputField';
import TextareaField from 'components/forms/TextareaField';
import FormikModal from 'components/modals/FormikModal';
import { CloseModal } from 'components/modals/types';
import { updateDimension } from 'features/dimensions/store/actions';
import { DimensionUpdate, ExportToDimension } from 'features/dimensions/store/types';
import { exportToDimension } from 'features/lists/store/actions';
import { connect } from 'react-redux';
import { Audience } from 'services/api/audience/types';
import { RootState } from 'store/rootReducer';
import { promisifySagaThunk } from 'utils/utils';
import { object, string } from 'yup';

import ProjectsComboBox from './components/ProjectsComboBox';
import { getNewAudienceId } from './components/useLoadAudience';

type ConnectedProps = {
  isLoading: boolean;
  title: string;
  onSubmit: <T extends ExportToDimension>(payload: T) => Promise<void>;
};

type CallerProps = {
  id: number;
  label: string;
  project: Audience | null;
  description?: string;
};

type Props = CloseModal & ConnectedProps & CallerProps;

const validationSchema = object().shape({
  label: string().required(),
  description: string()
});

type FormSchema = {
  label: string;
  description: string;
  project: Audience | null;
};

const DimensionsWithDesc = ({
  isLoading,
  closeModal,
  onSubmit,
  id,
  label,
  title,
  description,
  project
}: Props) => {
  const initialValues: FormSchema = {
    label,
    description: description || '',
    project
  };

  return (
    <FormikModal
      isLoading={isLoading}
      onSubmit={async ({ label, description, project }) => {
        const projectId = await getNewAudienceId(project);
        await onSubmit({
          id,
          label,
          description,
          projectId
        });
        closeModal();
      }}
      initialValues={initialValues}
      validationSchema={validationSchema}
      title={title}
      onCancelClick={closeModal}
    >
      {({ setFieldValue, values }) => (
        <>
          <InputField name="label" label="lists.dimension_name" required autoFocus />
          <TextareaField name="common.description" label="description" />
          <ProjectsComboBox
            onChange={value => setFieldValue('project', value)}
            value={values.project}
          />
        </>
      )}
    </FormikModal>
  );
};

export const ExportListToDimensionModal = connect(
  (state: RootState) => ({
    isLoading: state.dimensions.isLoading,
    title: 'lists.export_to_dimension'
  }),
  {
    onSubmit: (payload: ExportToDimension) => promisifySagaThunk(exportToDimension.request, payload)
  }
)(DimensionsWithDesc);

export const RenameDimensionModal = connect(
  ({ dimensions: { isLoading } }: RootState) => ({
    isLoading,
    title: 'dimensions.rename_dimension'
  }),
  {
    onSubmit: (payload: DimensionUpdate) => promisifySagaThunk(updateDimension.request, payload)
  }
)(DimensionsWithDesc);
