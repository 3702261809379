import { Typography } from '@mui/material';
import { getCurrentTabChartData } from 'features/aiTester/store/selectors';
import ResultsGraph from 'features/aiTester/TesterResultsGraph';
import TesterSection from 'features/aiTester/TesterSection';
import { FormattedMessage } from 'react-intl';
import { useAppSelector } from 'store/hooks';
import styled from 'styled-components';
import useTr from 'utils/hooks/useTr';

const TesterGraphSection = () => {
  const tr = useTr();

  const data = useAppSelector(getCurrentTabChartData);

  return (
    <TesterSection title={tr('testing.statistics')}>
      {data.length ? (
        <ResultsGraph manualScaleEnabled={false} scale={[-10, 10]} />
      ) : (
        <PlaceholderContainer>
          <Typography variant="subtitle2" color="textSecondary" align="center">
            <FormattedMessage id="testing.graph_placeholder" values={{ br: <br /> }} />
          </Typography>
        </PlaceholderContainer>
      )}
    </TesterSection>
  );
};

const PlaceholderContainer = styled.div`
  height: 18rem;
  padding: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: #fafafa;
  backdrop-filter: blur(0.25rem);
  border-radius: 0.25rem;
`;

export default TesterGraphSection;
