import { Dispatch, SetStateAction, useCallback, useState } from 'react';

type ToggleHandler = () => void;

const useToggle = (
  initialState = false
): [boolean, ToggleHandler, Dispatch<SetStateAction<boolean>>] => {
  const [state, setState] = useState<boolean>(initialState);
  const toggleState = useCallback((): void => setState(prevState => !prevState), []);

  return [state, toggleState, setState];
};

export default useToggle;
