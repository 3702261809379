import { TranslationFunction } from 'utils/hooks/useTr';
import { object, string } from 'yup';

export const makeSeoAnalysisFormValidation = ({
  searchEngineField,
  keywordField,
  tr
}: {
  searchEngineField: string;
  keywordField: string;
  tr: TranslationFunction;
}) =>
  object().shape(
    {
      [searchEngineField]: string().required(tr('validation.field_required')),
      [keywordField]: string().required(tr('validation.field_required'))
    },
    []
  );
