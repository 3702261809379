import { FormControl, SelectChangeEvent } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectProps } from '@mui/material/Select';
import { useGetTeamMembers } from 'features/profilePage/useGetTeamMembers';
import { useId } from 'react';
import { FormattedMessage } from 'react-intl';
import { FilteringTypes } from 'services/backofficeIntegration/http/endpoints/textGeneration/commandTemplates/httpGetCommandTemplates';
import styled from 'styled-components';

type Props = {
  value: FilteringTypes;
  onChange: (e: FilteringTypes) => void;
} & Omit<SelectProps, 'value' | 'onChange'>;

export function TemplatesFiltering({ label, value, onChange, ...rest }: Props) {
  const selectFilterId = useId();

  const { data: teamMembers } = useGetTeamMembers({ offset: 0, limit: 0 });

  const totalMembersCount = teamMembers?.total;

  function onFilteringChange(e: SelectChangeEvent) {
    onChange(e.target.value as FilteringTypes);
  }
  return (
    <SelectFormControl variant="outlined">
      {label && <InputLabel id={selectFilterId}>{label}</InputLabel>}
      <Select
        size={rest?.size}
        labelId={selectFilterId}
        label={label}
        value={value}
        onChange={onFilteringChange}
      >
        <MenuItem value={FilteringTypes.ANY}>
          <FormattedMessage id="aiWriter.inspirations.templates.filtering.any" />
        </MenuItem>
        <MenuItem value={FilteringTypes.FAVORITE}>
          <FormattedMessage id="aiWriter.inspirations.templates.filtering.favorites" />
        </MenuItem>
        <MenuItem value={FilteringTypes.BY_ME}>
          <FormattedMessage id="aiWriter.inspirations.templates.filtering.by_me" />
        </MenuItem>
        {totalMembersCount && totalMembersCount > 1 && (
          <MenuItem value={FilteringTypes.BY_TEAM}>
            <FormattedMessage id="aiWriter.inspirations.templates.filtering.by_team" />
          </MenuItem>
        )}
        <MenuItem value={FilteringTypes.PUBLIC}>
          <FormattedMessage id="aiWriter.inspirations.templates.filtering.public" />
        </MenuItem>
      </Select>
    </SelectFormControl>
  );
}

const SelectFormControl = styled(FormControl)`
  min-width: 200px;
`;
