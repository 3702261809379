import { Edit } from '@mui/icons-material';
import { IconButton, IconButtonSize } from 'components/iconButtons/IconButton';
import React from 'react';


type Props = {
  id?: string;
  tooltip?: React.ReactNode;
  size?: IconButtonSize;

  onClick: () => void;
};

export const EditIconButton = ({ id, tooltip, size = 'large', onClick }: Props) => (
  <IconButton
    id={id}
    tooltip={tooltip}
    onClick={onClick}
    icon={<Edit fontSize={size} />}
    size={size}
  />
);

