import cx from 'classnames';
import Downshift, { DownshiftProps } from 'downshift';
import { has } from 'lodash';
import matchSorter, { rankings } from 'match-sorter';
import React, { useRef } from 'react';
import { FormattedMessage } from 'react-intl';

import Icon from '../base/Icon';
import Input from '../base/Input';
import styles from './BaseAutocomplete.module.scss';
import ControllerButton from './components/ControllerButton';
import Item from './components/Item';
import Menu from './components/Menu';

type Props<Item> = Omit<DownshiftProps<Item>, 'children'> & {
  data: Item[];
  itemId?: keyof Item;
  itemToString: (item: Item | null) => string;
  children?: (item: Item) => React.ReactNode;
  placeholder?: string;
  className?: string;
  flag?: string;
  icon?: string;
  disabled?: boolean;
};

function ComboBox<Item>({
  data,
  itemId,
  icon,
  flag,
  placeholder,
  className,
  itemToString,
  children,
  disabled,
  ...downshiftProps
}: Props<Item>) {
  const itemRenderer = children || itemToString;
  const inputRef = useRef<HTMLInputElement>(null);

  return (
    <Downshift
      itemToString={itemToString}
      stateReducer={(state, changes) => {
        // https://github.com/downshift-js/downshift/issues/645
        if (has(changes, 'isOpen')) {
          return {
            ...changes,
            highlightedIndex:
              changes.isOpen && state.selectedItem ? data.indexOf(state.selectedItem) : null
          };
        }
        return changes;
      }}
      {...downshiftProps}
    >
      {({
        isOpen,
        inputValue,
        getItemProps,
        highlightedIndex,
        selectedItem,
        getInputProps,
        getToggleButtonProps,
        getMenuProps,
        openMenu
      }) => {
        let filteredData = data;
        let menuItems;

        if (inputValue && inputValue !== itemToString(selectedItem)) {
          filteredData = matchSorter(data, inputValue, {
            keys: [itemToString],
            threshold: rankings.STRING_CASE_ACRONYM
          });
        }

        if (!filteredData.length) {
          menuItems = (
            <Item disabled>
              <FormattedMessage id="common.autocomplete.no_results" />
            </Item>
          );
        } else {
          menuItems = filteredData.map((item, index) => (
            <Item
              key={itemId ? item[itemId] : item}
              isActive={highlightedIndex === index}
              {...getItemProps({
                item,
                index,
                isSelected:
                  selectedItem && itemId
                    ? selectedItem[itemId] === item[itemId]
                    : selectedItem === item
              })}
            >
              {itemRenderer(item)}
            </Item>
          ));
        }

        return (
          <div className={cx('position-relative', className)}>
            <div className="position-relative">
              <Input
                ref={inputRef}
                icon={icon}
                flag={flag}
                style={!isOpen ? { cursor: 'default' } : {}}
                className={styles.input}
                placeholder={placeholder}
                onKeyDown={event => {
                  if (event.key === 'Escape' && isOpen) {
                    event.stopPropagation();
                  }
                }}
                onClick={() => {
                  if (!isOpen && inputRef.current) {
                    inputRef.current.select();
                  }
                  openMenu();
                }}
                disabled={disabled}
                {...getInputProps()}
              />

              <ControllerButton {...getToggleButtonProps()} disabled={disabled}>
                {isOpen ? <Icon name="chevron-up" /> : <Icon name="chevron-down" />}
              </ControllerButton>
            </div>
            <Menu isOpen={isOpen} {...getMenuProps()} data-test-id="combobox-menu">
              {isOpen && menuItems}
            </Menu>
          </div>
        );
      }}
    </Downshift>
  );
}

export default ComboBox;
