import {
  CustomerDataPayload,
  CustomerLogInRequest,
  CustomerLogInWithSessionRequest,
  CustomerSubscriptionData,
  CustomerUpdateRequest
} from 'features/customer/store/types';
import { createMessage } from 'features/customer/store/utils';
import { Message } from 'types/Message';
import { Payload, ThunkMeta } from 'types/ReduxStateTypes';
import { createStandardAction } from 'typesafe-actions';
import { createStandardAsyncAction } from 'utils/reduxUtils';

export const login = createStandardAsyncAction(
  'customer/LOGIN_REQUEST',
  'customer/LOGIN_SUCCESS',
  'customer/LOGIN_FAILURE'
)<CustomerLogInRequest, CustomerDataPayload, string>();

export const emailVerified = createStandardAction('customer/EMAIL_VERIFIED')<CustomerDataPayload>();

export const updateApiCustomerToken = createStandardAction(
  'customer/UPDATE_API_CUSTOMER_TOKEN'
)<string>();

export const loginWithSession = createStandardAsyncAction(
  'customer/LOGIN_WITH_SESSION_REQUEST',
  'customer/LOGIN_WITH_SESSION_SUCCESS',
  'customer/LOGIN_WITH_SESSION_FAILURE'
)<CustomerLogInWithSessionRequest, CustomerDataPayload, string>();

export const getCurrentUser = createStandardAsyncAction(
  'customer/GET_CURRENT_USER_REQUEST',
  'customer/GET_CURRENT_USER_SUCCESS',
  'customer/GET_CURRENT_USER_FAILURE'
)<undefined, CustomerDataPayload, undefined>();

export const unsubscribeFromPlan = createStandardAsyncAction(
  'customer/UNSUBSCRIBE_FROM_PLAN_REQUEST',
  'customer/UNSUBSCRIBE_FROM_PLAN_SUCCESS',
  'customer/UNSUBSCRIBE_FROM_PLAN_FAILURE'
)<undefined, CustomerDataPayload, undefined, ThunkMeta>();

export const updateCurrentUser = createStandardAsyncAction(
  'customer/UPDATE_CURRENT_USER_REQUEST',
  'customer/UPDATE_CURRENT_USER_SUCCESS',
  'customer/UPDATE_CURRENT_USER_FAILURE'
)<CustomerUpdateRequest, CustomerDataPayload, undefined, ThunkMeta>();

export const logout = createStandardAsyncAction(
  'customer/LOGOUT_REQUEST',
  'customer/LOGOUT_SUCCESS',
  'customer/LOGOUT_FAILURE'
)<undefined, undefined, undefined>();

export const forcedLogout = createStandardAction('customer/FORCED_LOGOUT')<undefined>();

export const passwordRecoverySuccess = createStandardAction(
  'customer/PASSWORD_RECOVERY_SUCCESS'
).map<Payload<Message>, string>(payload => ({
  payload: createMessage('success', payload)
}));

export const removeError = createStandardAction('customer/REMOVE_ERROR_MESSAGE')();
export const welcomeMessageClosed = createStandardAction('customer/WELCOME_MESSAGE_CLOSED')();

export const setCanceledSubscriptionForTeamsSeen = createStandardAction(
  'customer/SET_CANCELED_SUBSCRIPTION_FOR_TEAMS_SEEN'
)<undefined>();

export const setWordsLimitReached = createStandardAction(
  'customer/SET_WORDS_LIMIT_REACHED'
)<boolean>();

export const setReferralLink = createStandardAction('customer/SET_REFERRAL_LINK')<string>();

export const setSubscription = createStandardAction(
  'customer/SET_SUBSCRIPTION'
)<CustomerSubscriptionData>();

export const setHasTeam = createStandardAction('customer/SET_HAS_TEAM')<boolean>();
