import LoadingButton from '@mui/lab/LoadingButton';
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography
} from '@mui/material';
import FlexContainer from 'components/FlexContainer';
import { HelpLinkingButton } from 'components/HelpLinkingButton';
import { StyledTextareaAutosize } from 'components/StyledTextareaAutosize';
import Toast from 'components/toasts/Toast';
import LocalStorageKey from 'config/localStorageKey';
import { useCreateTemplateMutation } from 'features/aiWriter/commandTemplates/useCreateTemplateMutation';
import { useEditTemplateMutation } from 'features/aiWriter/commandTemplates/useEditTemplate';
import {
  flashActionsCategoryAlias,
  useTemplateCategoriesQuery
} from 'features/aiWriter/commandTemplates/useTemplateCategoriesQuery';
import { ModelAutocomplete } from 'features/embeddingModels/ModelAutocomplete';
import { getEmbeddingModelsByLanguageAndAudience } from 'features/embeddingModels/store/selectors';
import { EmbeddingModel } from 'features/embeddingModels/store/types';
import getModelInitValue from 'features/embeddingModels/store/utils/getModelInitValue';
import { MaxHeightOverflowContainer } from 'features/theme-2024/ModalWithDividedHeaderLayout';
import { trackingWrapper } from 'features/tracking/wrapper/TrackingWrapper';
import { Form, Formik, useField } from 'formik';
import { useId } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  invalidateCommandTemplatesQuery,
  TemplateSharingPermission
} from 'services/backofficeIntegration/http/endpoints/textGeneration/commandTemplates/httpGetCommandTemplates';
import gtmIds from 'services/tracking/GTMIds';
import { withGtmInteraction } from 'services/tracking/withGtmInteraction';
import { useAppSelector } from 'store/hooks';
import styled from 'styled-components';
import useTr from 'utils/hooks/useTr';
import { assertNonNullable } from 'utils/typescript/nonNullable';
import { number, object, string } from 'yup';

type Template = {
  id: number;
  title: string;
  description: string;
  body: string;
  category: number;
  isSharable: boolean;
  sharingPermission: TemplateSharingPermission;
  model: string;
};

type NullableTemplate = {
  id: number | undefined;
  title: string | undefined;
  description: string | undefined;
  body: string | undefined;
  category: number | undefined;
  isSharable: boolean | undefined;
  sharingPermission: TemplateSharingPermission | undefined;
  language: string | undefined;
  country: string | undefined;
};

const TITLE_MAX_CHAR = 100;
const BODY_MAX_CHAR = 3500;

const validationSchema = object().shape({
  title: string().max(TITLE_MAX_CHAR).required(),
  description: string().required(),
  body: string().max(BODY_MAX_CHAR).required(),
  category: number().required(),
  model: string().required()
});

export const sharePermissionsOptions = [
  {
    value: TemplateSharingPermission.private,
    id: TemplateSharingPermission.private,
    name: <FormattedMessage id="common.sharing_permission.private" />
  },
  {
    value: TemplateSharingPermission.team,
    id: TemplateSharingPermission.team,
    name: <FormattedMessage id="common.sharing_permission.team" />
  },
  {
    value: TemplateSharingPermission.public,
    id: TemplateSharingPermission.public,
    name: <FormattedMessage id="common.sharing_permission.public" />
  }
];

type Props = {
  template?: NullableTemplate;
  preselectedModelId?: string;

  onBackClick: () => void;
};

export function TemplateCreateOrEdit({ template, preselectedModelId, onBackClick }: Props) {
  const isEditing = !!template;

  const models = useAppSelector(getEmbeddingModelsByLanguageAndAudience);
  const preselectedModel = models.find(model => model.id === preselectedModelId);
  const defaultModel = getModelInitValue(models, LocalStorageKey.AiWriterModel) ?? models[0];
  const editedModel = models.find(
    model => model.language === template?.language && model.country === template?.country
  );

  const model = editedModel ?? preselectedModel ?? defaultModel;

  const { mutate: createTemplate, isLoading } = useCreateTemplateMutation();

  const editTemplate = useEditTemplateMutation();

  const initialValues: Template = {
    id: template?.id || 0,
    title: template?.title || '',
    description: template?.description || '',
    body: template?.body || '',
    category: template?.category || 1,
    isSharable: false,
    sharingPermission: template?.sharingPermission || TemplateSharingPermission.private,
    model: model.id
  };

  function handleEditTemplate(values: Template) {
    if (values.sharingPermission !== template?.sharingPermission) {
      trackingWrapper.track('commandTemplateSharingPermissionChange', {
        commandTemplateId: values.id,
        newSharingPermission: values.sharingPermission,
        oldSharingPermission: template?.sharingPermission || TemplateSharingPermission.private
      });
    }

    const model = models.find(model => model.id === values.model);
    assertNonNullable(model, 'Model not found');

    editTemplate.mutate(
      {
        commandTemplateId: values.id,
        title: values.title,
        description: values.description,
        template: values.body,
        sharing_permission: values.sharingPermission,
        category_id: values.category,
        country: model.country,
        language: model.language
      },
      {
        onSuccess: () => {
          onBackClick();
          invalidateCommandTemplatesQuery();
        },
        onError: () => Toast.apiError()
      }
    );
  }

  function handleCreateTemplate(values: Template) {
    const model = models.find(model => model.id === values.model) as EmbeddingModel;
    createTemplate(
      {
        title: values.title,
        description: values.description,
        template: values.body,
        category_id: values.category,
        is_sharable: values.isSharable,
        sharing_permission: values.sharingPermission,
        country: model.country,
        language: model.language
      },
      {
        onError: () => Toast.apiError(),
        onSuccess: () => {
          onBackClick();
          invalidateCommandTemplatesQuery();
        }
      }
    );
  }

  function handleSubmit(values: Template) {
    if (template) {
      handleEditTemplate(values);
    } else {
      handleCreateTemplate(values);
    }
  }

  return (
    <>
      <LeftColumn>
        <FlexContainer direction="row" alignItems="center" gap="xsmall">
          <Typography variant="h6">
            <FormattedMessage id="aiWriter.inspirations.templates.modal.create.instruction.title" />
          </Typography>

          <HelpLinkingButton link="aiWriter.inspirations.templates.modal.edit_create.help_link" />
        </FlexContainer>

        <Typography variant="body2">
          <FormattedMessage
            id="aiWriter.inspirations.templates.modal.create.instruction.body"
            values={{
              br: <br />
            }}
          />
        </Typography>
      </LeftColumn>

      <MaxHeightOverflowContainer>
        <StyledHeadline variant="h6">
          <FormattedMessage
            id={
              template
                ? 'aiWriter.inspirations.templates.modal.edit.title'
                : 'aiWriter.inspirations.templates.modal.create.title'
            }
          />
        </StyledHeadline>

        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
        >
          <Form>
            <FlexContainer gap="xmedium">
              <TitleField name="title" />

              <DescriptionField name="description" />

              <BodyField name="body" />

              <CategoryField name="category" />

              <SharePermissionsField name="sharingPermission" />

              <FlexContainer direction="row" justifyContent="space-between">
                <ModelField name="model" />
                <FlexContainer direction="row" gap="two" alignItems="center">
                  <Button onClick={onBackClick} variant="outlined">
                    <FormattedMessage id="common.cancel" />
                  </Button>
                  <LoadingButton
                    {...withGtmInteraction(
                      isEditing
                        ? gtmIds.templatesLibrary.updated
                        : gtmIds.templatesLibrary.saveTemplate
                    )}
                    type="submit"
                    variant="contained"
                    loading={isLoading}
                  >
                    <FormattedMessage id="common.save" />
                  </LoadingButton>
                </FlexContainer>
              </FlexContainer>
            </FlexContainer>
          </Form>
        </Formik>
      </MaxHeightOverflowContainer>
    </>
  );
}

function BodyField(props: { name: string }) {
  const { name } = props;
  const [field] = useField(name);
  const translate = useTr();

  return (
    <div>
      <StyledTextareaAutosize
        {...field}
        {...props}
        placeholder={translate('common.template')}
        maxRows={15}
      />
      <Caption>
        <FormattedMessage id="aiWriter.inspirations.templates.modal.create.template_field.caption" />
        <span>
          {field.value?.length ?? 0}/{BODY_MAX_CHAR}
        </span>
      </Caption>
    </div>
  );
}

function DescriptionField(props: { name: string }) {
  const { name } = props;
  const [field, meta] = useField(name);

  return (
    <TextField
      {...field}
      label={<FormattedMessage id="common.description" />}
      fullWidth
      error={!!meta.error && meta.touched}
    />
  );
}

function TitleField(props: { name: string }) {
  const { name } = props;
  const [field, meta] = useField(name);

  return (
    <ThreeQuartersContainer>
      <TextField
        {...field}
        {...props}
        label={<FormattedMessage id="common.title" />}
        fullWidth
        error={!!meta.error && meta.touched}
        helperText={
          <Caption>
            <FormattedMessage
              id="aiWriter.inspirations.templates.modal.create.title_field.caption"
              values={{
                value: TITLE_MAX_CHAR
              }}
            />
          </Caption>
        }
      />
    </ThreeQuartersContainer>
  );
}

function CategoryField(props: { name: string }) {
  const { name } = props;
  const [field, meta] = useField(name);
  const selectId = useId();

  const { data: categories, isLoading } = useTemplateCategoriesQuery();
  // category.alias === 'flash_actions' is a special kind of category that we don't want to show to the user in prompts library
  const usableCategories = categories?.filter(
    category => category.alias !== flashActionsCategoryAlias
  );

  return (
    <ThirdContainer>
      <FormControl variant="outlined" fullWidth>
        <InputLabel id={selectId}>
          <FormattedMessage id="common.category" />
        </InputLabel>
        <Select
          {...field}
          labelId={selectId}
          label={<FormattedMessage id="common.category" />}
          error={!!meta.error && meta.touched}
          startAdornment={
            isLoading ? (
              <Box sx={{ display: 'grid' }}>
                <CircularProgress size={16} />
              </Box>
            ) : undefined
          }
        >
          {usableCategories?.map(category => (
            <MenuItem key={category.id} value={category.id ?? 0}>
              {category.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </ThirdContainer>
  );
}

function SharePermissionsField(props: { name: string }) {
  const { name } = props;
  const [field, meta] = useField(name);
  const selectId = useId();

  return (
    <ThirdContainer>
      <FormControl variant="outlined" fullWidth>
        <InputLabel id={selectId}>
          <FormattedMessage id="aiWriter.inspirations.templates.modal.create.sharing_permission.label" />
        </InputLabel>
        <Select
          {...field}
          labelId={selectId}
          label={
            <FormattedMessage id="aiWriter.inspirations.templates.modal.create.sharing_permission.label" />
          }
          error={!!meta.error && meta.touched}
        >
          {sharePermissionsOptions.map(option => (
            <MenuItem key={option.id} value={option.value}>
              {option.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </ThirdContainer>
  );
}

function ModelField(props: { name: string }) {
  const models = useAppSelector(getEmbeddingModelsByLanguageAndAudience);

  return (
    <ThirdContainer>
      <ModelAutocomplete name={props.name} models={models} />
    </ThirdContainer>
  );
}

const LeftColumn = styled(FlexContainer).attrs({ direction: 'column', gap: 'four' })`
  min-width: 240px;
  padding: ${({ theme }) => theme.spacings.three};
  overflow: auto;

  background: ${({ theme }) => theme.colors.primaryColorHover};
  border-radius: ${({ theme }) => theme.borderRadius.one};
`;

const StyledHeadline = styled(Typography)`
  margin-bottom: ${({ theme }) => theme.spacings.four};
`;

const Caption = styled(Typography).attrs({
  variant: 'caption'
})`
  display: flex;
  justify-content: space-between;
`;

const ThirdContainer = styled.div`
  width: 33.33%;
`;

const ThreeQuartersContainer = styled.div`
  width: 75%;
`;
