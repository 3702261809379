import { Typography } from '@mui/material';
import { PlateContent } from '@udecode/plate-common';
import { ReactComponent as CircleUserIcon } from 'assets/icons/icon-user-circle.svg';
import FlexContainer from 'components/FlexContainer';
import dayjs from 'dayjs';
import { getActiveTab } from 'features/aiWriter/store/selectors';
import { useAppSelector } from 'store/hooks';
import styled from 'styled-components';

export function TeamPreview() {
  return <Document />;
}

function Document() {
  const tab = useAppSelector(getActiveTab);
  const { name, updatedAt, authorName } = tab;

  return (
    <Root>
      <Typography variant="h2">{name}</Typography>
      <FlexContainer gap="medium" direction="row" alignItems="center">
        {/* TODO: add team mate image */}
        <CircleUserIcon />
        <Typography variant="subtitle1">{authorName}</Typography>
        <LastUpdate variant="subtitle1">{dayjs(updatedAt).format('DD.MM.YYYY')}</LastUpdate>
      </FlexContainer>
      <PlateContent readOnly />
    </Root>
  );
}

const Root = styled.div`
  max-width: ${({ theme }) => theme.container.md};
  margin: ${({ theme }) => theme.spacings.xlarge} auto;
  overflow: visible scroll;
`;

// const Avatar = styled.img`
//   width: 20px;
//   height: 20px;

//   border-radius: 50%;
// `;

const LastUpdate = styled(Typography)`
  && {
    color: ${({ theme }) => theme.colors.blackMediumEmphasis};
  }
`;
