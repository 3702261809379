import { BaseOutput } from 'features/aiWriter/slashCommands/BaseOutput';
import { FlashActionsInputProps } from 'features/aiWriter/slashCommands/FlashActionsInput';
import {
  useFlashActionsSelectedText,
  useGetFlashActionsSelectedAction
} from 'features/aiWriter/slashCommands/hooks/useFlashActionsStore';
import { getCurrentModelLanguageAndCountry } from 'features/aiWriter/store/selectors';
import { GAEvents } from 'services/tracking/GAEvents';
import { useAppSelector } from 'store/hooks';

type Props = FlashActionsInputProps;

const OUTPUT_TYPE = 'quick_action';

const minAmountOfCharactersToBeUsedAsContext = 10;
const maxAmountOfCharactersToBeUsedAsContext = 18000;

export function CommandOutput(props: Props) {
  const flashActionsSelectedText = useFlashActionsSelectedText();
  const selectedAction = useGetFlashActionsSelectedAction();

  const command = selectedAction?.prePrompt
    ? `${selectedAction.prePrompt}: ${flashActionsSelectedText}`
    : flashActionsSelectedText;

  const { currentModelCountry: country, currentModelLanguage: language } = useAppSelector(
    getCurrentModelLanguageAndCountry
  );

  const generateConfig = {
    outputType: OUTPUT_TYPE,
    // Command in output panel is only available for selected text.
    text: command
  };

  const onGenerate = () => {
    GAEvents.flashActionExecuted({
      action: 'quick_action',
      language,
      country,
      isInline: true
    });
  };

  return (
    <BaseOutput
      generateConfig={generateConfig}
      onGenerate={onGenerate}
      onClose={props.onClose}
      maxAmountOfCharactersToBeUsedAsContext={maxAmountOfCharactersToBeUsedAsContext}
      minAmountOfCharactersToBeUsedAsContext={minAmountOfCharactersToBeUsedAsContext}
    />
  );
}
