import { Button, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import inviteUser from 'assets/invite-user.svg';
import FlexContainer from 'components/FlexContainer';
import { getCustomerRole } from 'features/customer/store/selectors';
import { CustomerRole } from 'features/customer/store/types';
import FormattedMessage from 'features/i18n/FormattedMessage';
import { useInviteUserModal } from 'features/profilePage/useInviteUserModal';
import { UserTableHead } from 'features/profilePage/users/UserTableHead';
import { UserTableRow } from 'features/profilePage/users/UserTableRow';
import { ChangeEvent, useEffect, useState } from 'react';
import { TeamMemberDto } from 'services/backofficeIntegration/http/dtos/TeamMemberDto';
import { useAppSelector } from 'store/hooks';
import styled from 'styled-components';
import useTr from 'utils/hooks/useTr';

type UserTableProps = {
  handleChangePage: (event: unknown, newPage: number) => void;
  handleChangeRowsPerPage: (event: ChangeEvent<HTMLInputElement>) => void;
  page: number;
  rowsPerPage: number;
  members: TeamMemberDto[];
  totalUsers: number;
  loadingMembers: boolean;
};

export const UserTable = ({
  rowsPerPage,
  page,
  handleChangePage,
  handleChangeRowsPerPage,
  members,
  loadingMembers,
  totalUsers
}: UserTableProps) => {
  const translate = useTr();
  const userRole = useAppSelector(getCustomerRole);

  const showInviteUserModal = useInviteUserModal();

  // Creating a local state to allow manipulation after API calls
  // This allows us to hide rows faster, even if we're still waiting on an updated member list
  const [teamMemberList, setTeamMemberList] = useState<TeamMemberDto[]>([...members]);

  const removeCustomerFromLocalList = (teamCustomerId: number) => {
    const index = teamMemberList.findIndex(member => member.id === teamCustomerId);
    if (index !== -1) {
      setTeamMemberList(prevState => {
        const newState = [...prevState];
        newState.splice(index, 1);
        return newState;
      });
    }
  };

  // Refresh the local state when the prop changes
  useEffect(() => {
    setTeamMemberList(members);
  }, [members]);

  return (
    <Box sx={{ width: '100%' }}>
      <StyledPaper>
        <FlexContainer
          direction="row"
          alignItems="center"
          justifyContent="flex-end"
          style={{ padding: 16 }}
        >
          <Button
            variant="contained"
            color="primary"
            startIcon={<img src={inviteUser} alt={translate('profile.users.invite_user')} />}
            onClick={showInviteUserModal}
            disabled={userRole === CustomerRole.MEMBER}
          >
            <Typography variant="subtitle2">
              <FormattedMessage id="profile.users.invite_user" />
            </Typography>
          </Button>
        </FlexContainer>
        <TableContainer>
          <Table sx={{ minWidth: 750 }} size="medium">
            <UserTableHead />
            <TableBody>
              {teamMemberList.map(row => (
                <UserTableRow
                  key={row.id}
                  row={row}
                  isLoading={loadingMembers}
                  onRowRemove={() => removeCustomerFromLocalList(row.id)}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={totalUsers}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </StyledPaper>
    </Box>
  );
};

const StyledPaper = styled(Paper)`
  width: 100%;
  box-shadow: none;
  margin-bottom: 2rem;
`;
