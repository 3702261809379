import AddIcon from '@mui/icons-material/Add';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import RemoveIcon from '@mui/icons-material/Remove';
import TableChartIcon from '@mui/icons-material/TableChart';
import TableRowsIcon from '@mui/icons-material/TableRows';
import ViewColumnIcon from '@mui/icons-material/ViewColumn';
import { ListItemText, MenuItem, Tooltip } from '@mui/material';
import ListItemIcon from '@mui/material/ListItemIcon';
import { focusEditor, someNode, useEditorState } from '@udecode/plate-common';
import {
  deleteColumn,
  deleteRow,
  deleteTable,
  ELEMENT_TABLE,
  insertTable,
  insertTableColumn,
  insertTableRow
} from '@udecode/plate-table';
import { MenuDropdown } from 'components/MenuDropdown/MenuDropdown';
import {
  EditorFormatButton,
  EditorFormatButtonProps,
  EditorIconFormatButton
} from 'features/aiWriter/AiWriterTextEditor/EditorIconFormatButton';
import useEditor from 'features/aiWriter/AiWriterTextEditor/hooks/useEditor';
import { trackEditorFormatAction } from 'features/aiWriter/AiWriterTextEditor/trackEditorFormatAction';
import { topBarDropdownAlignmentConfig } from 'features/aiWriter/AiWriterTextEditor/utils/topBarDropdownAlignmentConfig';
import FormattedMessage from 'features/i18n/FormattedMessage';
import { useCreateEmptyPage } from 'features/plate/components/plate-ui/useCreateEmptyPage';
import useDropdown from 'utils/hooks/useDropdown';

type TableDropdownProps = {
  styleVariant?: EditorFormatButtonProps;
  onClose?: () => void;
};

export function TableDropdown({ onClose, styleVariant }: TableDropdownProps) {
  const editor = useEditorState();

  const { anchorElement, close, isOpen, open } = useDropdown();

  const tableSelected = someNode(editor, {
    match: { type: ELEMENT_TABLE }
  });

  const handleClose = () => {
    close();
    onClose && onClose();
  };

  const trigger =
    styleVariant === 'icon' ? (
      <EditorIconFormatButton $isActive={false} onClick={open}>
        <Tooltip arrow title={<FormattedMessage id="aiWriter.toolbar.table" />} placement="bottom">
          <TableChartIcon fontSize="small" />
        </Tooltip>
      </EditorIconFormatButton>
    ) : (
      <EditorFormatButton $isActive={false} onClick={open}>
        <TableChartIcon fontSize="small" />
        <FormattedMessage id="aiWriter.toolbar.table" />
      </EditorFormatButton>
    );

  return (
    <MenuDropdown
      trigger={trigger}
      anchorElement={anchorElement}
      close={handleClose}
      isOpen={isOpen}
      width="fit-content"
      anchorOrigin={topBarDropdownAlignmentConfig.leftAlignment.anchorOrigin}
      transformOrigin={topBarDropdownAlignmentConfig.leftAlignment.transformOrigin}
    >
      <TableItem closeParent={handleClose} />
      <ColumnItem closeParent={handleClose} disabled={!tableSelected} />
      <RowItem closeParent={handleClose} disabled={!tableSelected} />
    </MenuDropdown>
  );
}

function TableItem({ closeParent }: { closeParent: () => void }) {
  const { anchorElement, close, isOpen, open } = useDropdown();
  const { createEmptyPage } = useCreateEmptyPage();

  const editor = useEditor();

  const handleClose = () => {
    closeParent();
    close();
  };

  const handleInsertClick = async () => {
    trackEditorFormatAction('add_table');

    createEmptyPage();
    insertTable(editor);
    focusEditor(editor);

    handleClose();
  };

  const handleDeleteClick = async () => {
    trackEditorFormatAction('remove_table');

    deleteTable(editor);
    focusEditor(editor);

    handleClose();
  };

  return (
    <MenuDropdown
      anchorElement={anchorElement}
      close={handleClose}
      isOpen={isOpen}
      trigger={
        <MenuItem onClick={open}>
          <ListItemIcon>
            <TableChartIcon />
          </ListItemIcon>
          <ListItemText>
            <FormattedMessage id="aiWriter.toolbar.table" />
          </ListItemText>
          <ChevronRightIcon fontSize="small" />
        </MenuItem>
      }
      width="fit-content"
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left'
      }}
    >
      <MenuItem onClick={handleInsertClick}>
        <ListItemIcon>
          <AddIcon />
        </ListItemIcon>
        <ListItemText>
          <FormattedMessage id="aiWriter.toolbar.table_insert" />
        </ListItemText>
      </MenuItem>
      <MenuItem onClick={handleDeleteClick}>
        <ListItemIcon>
          <RemoveIcon />
        </ListItemIcon>
        <ListItemText>
          <FormattedMessage id="aiWriter.toolbar.table_delete" />
        </ListItemText>
      </MenuItem>
    </MenuDropdown>
  );
}

function ColumnItem({ closeParent, disabled }: { closeParent: () => void; disabled: boolean }) {
  const { anchorElement, close, isOpen, open } = useDropdown();

  const editor = useEditor();

  const handleClose = () => {
    closeParent();
    close();
  };

  const handleInsertClick = async () => {
    trackEditorFormatAction('add_column');

    insertTableColumn(editor);
    focusEditor(editor);

    handleClose();
  };

  const handleDeleteClick = async () => {
    trackEditorFormatAction('remove_column');

    deleteColumn(editor);
    focusEditor(editor);

    handleClose();
  };

  return (
    <MenuDropdown
      anchorElement={anchorElement}
      close={handleClose}
      isOpen={isOpen}
      width="fit-content"
      trigger={
        <MenuItem disabled={disabled} onClick={open}>
          <ListItemIcon>
            <ViewColumnIcon />
          </ListItemIcon>
          <ListItemText>
            <FormattedMessage id="aiWriter.toolbar.table_column" />
          </ListItemText>
          <ChevronRightIcon fontSize="small" />
        </MenuItem>
      }
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left'
      }}
    >
      <MenuItem onClick={handleInsertClick}>
        <ListItemIcon>
          <AddIcon />
        </ListItemIcon>
        <ListItemText>
          <FormattedMessage id="aiWriter.toolbar.table_column.insert" />
        </ListItemText>
      </MenuItem>
      <MenuItem onClick={handleDeleteClick}>
        <ListItemIcon>
          <RemoveIcon />
        </ListItemIcon>
        <ListItemText>
          <FormattedMessage id="aiWriter.toolbar.table_column.delete" />
        </ListItemText>
      </MenuItem>
    </MenuDropdown>
  );
}

function RowItem({ closeParent, disabled }: { closeParent: () => void; disabled: boolean }) {
  const { anchorElement, close, isOpen, open } = useDropdown();

  const editor = useEditor();

  const handleClose = () => {
    closeParent();
    close();
  };

  const handleInsertClick = async () => {
    trackEditorFormatAction('add_row');

    insertTableRow(editor);
    focusEditor(editor);

    handleClose();
  };

  const handleDeleteClick = async () => {
    trackEditorFormatAction('remove_row');

    deleteRow(editor);
    focusEditor(editor);

    handleClose();
  };

  return (
    <MenuDropdown
      anchorElement={anchorElement}
      close={handleClose}
      isOpen={isOpen}
      trigger={
        <MenuItem disabled={disabled} onClick={open}>
          <ListItemIcon>
            <TableRowsIcon />
          </ListItemIcon>
          <ListItemText>
            <FormattedMessage id="aiWriter.toolbar.table_row" />
          </ListItemText>
          <ChevronRightIcon fontSize="small" />
        </MenuItem>
      }
      width="fit-content"
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left'
      }}
    >
      <MenuItem onClick={handleInsertClick}>
        <ListItemIcon>
          <AddIcon />
        </ListItemIcon>
        <ListItemText>
          <FormattedMessage id="aiWriter.toolbar.table_row.insert" />
        </ListItemText>
      </MenuItem>
      <MenuItem onClick={handleDeleteClick}>
        <ListItemIcon>
          <RemoveIcon />
        </ListItemIcon>
        <ListItemText>
          <FormattedMessage id="aiWriter.toolbar.table_row.delete" />
        </ListItemText>
      </MenuItem>
    </MenuDropdown>
  );
}
