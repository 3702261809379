import { BaseSuccessDto } from 'services/backofficeIntegration/http/BaseSuccessDto';
import { backofficeClient } from 'services/backofficeIntegration/http/client/createBackofficeClient';
import { mutationEndpoint } from 'services/backofficeIntegration/http/endpointDefinition';

export type ResponseDto = BaseSuccessDto<never>;

export type UpdateTeamNameParams = {
  teamId: number;
  name: string;
};

export const MAX_WORKSPACE_NAME_LENGTH = 50;

export const httpUpdateTeamName = mutationEndpoint({
  callEndpoint: ({ teamId, name }: UpdateTeamNameParams) =>
    backofficeClient
      .patch<ResponseDto>(`/teams/${teamId}`, {
        name
      })
      .then(response => response.data)
});
