/* eslint-disable react/no-array-index-key */
import cx from 'classnames';
import { Placement } from 'popper.js';
import React, { ReactElement, useRef, useState } from 'react';
import { Manager } from 'react-popper';

import useOnClickOutside from '../../utils/hooks/useOnClickOutside';
import DropdownItem, { DropdownItemBaseComponent, DropdownItemProps } from './DropdownItem';
import DropdownItemDivider from './DropdownItemDivider';
import DropdownMenu from './DropdownMenu';
import DropdownTrigger from './DropdownTrigger';

type FlexSize = 'xs' | 'sm' | 'md' | 'lg' | 'xl';

type BaseProps = {
  className?: string;
  desktopOnly?: boolean;
  isOption?: boolean;
  flex?: boolean | FlexSize;
  noWrapper?: boolean;
};

type WithTriggerProps = {
  triggerClassName?: string;
  buttonSize?: 'sm' | 'lg';
  triggerDisabled?: boolean;
  isNavLink?: boolean;
  type?: 'link' | 'button' | 'icon';
  icon?: string;
  hasActiveState?: boolean;
  triggerContent?: React.ReactNode;
  color?: string;
  toggle?: boolean;
};

type WithItemsProps = {
  items?: React.ReactNode;
  itemsObject?: DropdownItemProps[];
  dropdownMenuClassName?: string;
  position?: Placement;
  arrow?: boolean;
  itemsRootComponent?: DropdownItemBaseComponent;
};

type WrapperProps = { children: React.ReactElement } & BaseProps &
  WithTriggerProps &
  WithItemsProps;

const DropdownWrapper = ({ noWrapper, children, ...props }: WrapperProps): ReactElement =>
  noWrapper ? children : <div {...props}>{children}</div>;

type Props = Omit<WrapperProps, 'children'> & { children?: React.ReactElement };

const Dropdown = ({
  className,
  desktopOnly,
  isOption,
  noWrapper,
  flex = true,
  children,
  ...props
}: Props) => {
  const [isOpen, setOpen] = useState(false);
  const clickOutsideRef = useRef<HTMLDivElement>(null);

  useOnClickOutside(clickOutsideRef, () => setOpen(false));

  const classes = cx(
    {
      dropdown: true,
      'd-none': desktopOnly,
      'd-md-flex': desktopOnly || flex === 'md',
      [`d-${flex}-flex`]: ['xs', 'sm', 'lg', 'xl'].includes(flex as FlexSize),
      'd-flex': flex === true,
      'card-options-dropdown': isOption,
      show: isOpen
    },
    className
  );

  const trigger = (() => {
    if (props.icon || props.triggerContent || props.toggle) {
      const {
        icon,
        triggerContent,
        isNavLink,
        type,
        triggerClassName,
        color,
        toggle,
        buttonSize,
        triggerDisabled,
        hasActiveState
      } = props;

      return (
        <DropdownTrigger
          isNavLink={isNavLink}
          icon={icon}
          type={type}
          isOpen={isOpen}
          disabled={triggerDisabled}
          size={buttonSize}
          className={triggerClassName}
          hasActiveState={hasActiveState}
          isOption={isOption}
          color={color}
          toggle={toggle}
          onClick={e => {
            e.preventDefault();
            setOpen(!isOpen);
          }}
        >
          {triggerContent}
        </DropdownTrigger>
      );
    }
    return null;
  })();

  const items = (() => {
    if (props.items) return props.items;
    if (props.itemsObject) {
      const { itemsObject, itemsRootComponent } = props;
      return itemsObject.map((item, i) =>
        item.isDivider ? (
          <DropdownItemDivider key={i} />
        ) : (
          <DropdownItem
            icon={item.icon}
            flag={item.flag}
            badge={item.badge}
            badgeType={item.badgeType}
            disabled={item.disabled}
            value={item.value}
            key={i}
            to={item.to}
            RootComponent={item.RootComponent || itemsRootComponent}
            onClick={e => {
              setOpen(false);
              if (item.onClick) {
                item.onClick(e);
              }
            }}
          />
        )
      );
    }
    return null;
  })();

  const menu = (() => {
    if (props.items || props.itemsObject) {
      const { position, type, arrow, dropdownMenuClassName } = props;
      return (
        <DropdownMenu
          position={position}
          arrow={arrow != null || type !== 'button'}
          className={dropdownMenuClassName}
          show={isOpen}
          clickOutsideRef={clickOutsideRef}
        >
          {items}
        </DropdownMenu>
      );
    }
    return null;
  })();

  return (
    <Manager>
      <DropdownWrapper noWrapper={noWrapper} className={classes}>
        <>
          {trigger}
          {menu || children}
        </>
      </DropdownWrapper>
    </Manager>
  );
};

export default Dropdown;
