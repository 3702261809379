import { getCurrentLanguage } from 'features/language/store/selectors';
import { AppLanguage } from 'features/language/store/types';
import { sample } from 'lodash';
import { useAppSelector } from 'store/hooks';

export const useSelectRandomImageExample = () => {
  const appLanguage = useAppSelector(getCurrentLanguage);

  return () => sample(PromptExampleList[appLanguage]) as string;
};

type PromptExample = Record<AppLanguage, Array<string>>;

const PromptExampleList: PromptExample = {
  [AppLanguage.English]: [
    'logo, two foxes drinking coffee, foxes in flames, orange characters, white background,simple background, positive and negative space masterpiece, best quality, high quality, highres',
    'A candid close-up shot of a satisfied middle-aged customer, taken with a Canon EOS R5 camera. The image is brightly lit with natural sunlight streaming from the side. Subject: The subject is a Hispanic man in his 50s, wearing a white shirt with rolled-up sleeves and a warm smile. He is holding a handwritten review on a clipboard. Foreground: The wooden surface of a coffee shop counter with coffee stains and a couple of loyalty cards. Background: A blurred coffee shop interior with exposed brick walls, a chalkboard menu, and hanging plants.',
    'A wide-angle shot of a young couple using a picnic blanket in a park, captured with a Nikon D850 camera. The sunlight creates a golden-hour glow. Subject: The couple, an African American woman and a Caucasian man in their late 20s, are casually dressed, laughing, and enjoying a sandwich together. Foreground: The checkered picnic blanket with a basket of fruits and a bottle of wine. Background: A soft-focus backdrop of tall oak trees and distant joggers in the park.',
    'A split-screen image showing a fitness transformation, shot with a Sony A7 III camera. The lighting is even on both sides to ensure consistency. Subject: The subject is a fit, toned woman in her 30s, first in loose clothing and then in athletic wear, smiling in both images. Foreground: A clean, white floor with a yoga mat and dumbbells on one side. Background: A minimalist home gym with mirrored walls and exercise equipment in the background, subtly blurred.',
    'A dynamic, action shot taken by a customer with a GoPro Hero 11 camera. The image is high-energy and slightly angled, adding to its spontaneity. Subject: A young, adventurous man in his 20s riding a mountain bike down a trail, wearing a helmet and sunglasses. Foreground: A dirt path with rocks and fallen leaves scattering as he rides over them. Background: A lush, green forest with sunbeams filtering through the trees, the motion blur enhancing the sense of speed.',
    'A close-up macro shot of a freshly made cappuccino, taken with a Leica Q2 camera. The focus is sharp, capturing intricate details. Subject: A beautifully crafted cappuccino in a white ceramic cup, topped with intricate latte art in the shape of a heart. The rich, velvety foam contrasts with the deep brown espresso below. Foreground: The glossy surface of a wooden coffee table, reflecting the cappuccino cup and the delicate spoon resting beside it. Background: A blurred office interior with abstract art on the wall and soft lighting from a desk lamp, creating a cozy, warm atmosphere.',
    'A wide shot of a small team brainstorming in an office, captured with a Panasonic Lumix GH5 camera. The lighting is warm and inviting. Subject: Four team members of diverse backgrounds are gathered around a whiteboard, discussing ideas. They are casually dressed and smiling. Foreground: A round table cluttered with notebooks, pens, and coffee mugs. Background: An open office space with glass walls, potted plants, and a whiteboard covered in colorful sticky notes.',
    'A vibrant shot of a cozy winter scene, taken with an Olympus OM-D E-M1 camera. The image features warm, festive lighting. Subject: A family of four, bundled up in winter clothes, decorating a Christmas tree outdoors. Snow is lightly falling. Foreground: A small wooden crate filled with holiday decorations like ornaments and garlands. Background: A snow-covered cabin with twinkling lights, smoke curling from the chimney, and tall pine trees in soft focus.',
    'A high-contrast image highlighting a sale, shot with a Canon EOS Rebel T8i camera. The lighting is dramatic with a spotlight effect. Subject: A bold “50% Off” sign displayed on a sleek, modern pedestal, with the product beside it. Foreground: A reflective, black surface that accentuates the product’s polished look. Background: A gradient background fading from dark to light, with faint, abstract shapes suggesting movement.',
    'Advertising photo showing a solid Dark Gray background and Futuristic Looking cappuccino with latte art  in the middle of the photo lit In a Dramatic dim Light a text in white says "Coffee up"'
  ],
  [AppLanguage.German]: [
    'Logo, zwei Füchse, die Kaffee trinken, Füchse in Flammen, orangefarbene Zeichen, weißer Hintergrund, einfacher Hintergrund, positives und negatives Raummeisterwerk, beste Qualität, hohe Qualität, hochauflösend',
    'Eine authentische Nahaufnahme eines zufriedenen Kunden mittleren Alters, aufgenommen mit einer Canon EOS R5-Kamera. Das Bild ist hell erleuchtet und das natürliche Sonnenlicht strömt von der Seite ein. Motiv: Das Subjekt ist ein hispanischer Mann in den Fünfzigern, der ein weißes Hemd mit hochgekrempelten Ärmeln und einem warmen Lächeln trägt. Er hält eine handschriftliche Rezension auf einem Klemmbrett. Vordergrund: Die Holzoberfläche einer Kaffeehaustheke mit Kaffeeflecken und ein paar Kundenkarten. Hintergrund: Ein verschwommenes Café-Interieur mit freiliegenden Ziegelwänden, einer Tafel-Speisekarte und hängenden Pflanzen.',
    'Eine Weitwinkelaufnahme eines jungen Paares mit einer Picknickdecke in einem Park, aufgenommen mit einer Nikon D850-Kamera. Das Sonnenlicht erzeugt ein goldenes Stundenlicht. Motiv: Eine Afroamerikanerin und ein kaukasischer Mann Ende 20, sind lässig gekleidet, lachen und genießen gemeinsam ein Sandwich. Vordergrund: Die karierte Picknickdecke mit einem Obstkorb und einer Flasche Wein. Hintergrund: Ein weichgezeichneter Hintergrund aus hohen Eichen und entfernten Joggern im Park.',
    'Ein geteiltes Bild, das eine Fitnesstransformation zeigt, aufgenommen mit einer Sony A7 III-Kamera. Die Beleuchtung ist auf beiden Seiten gleichmäßig, um eine gleichmäßige Beleuchtung zu gewährleisten. Motiv: Fitte, durchtrainierte Frau in den Dreißigern, zunächst in lockerer Kleidung und dann in Sportkleidung, die auf beiden Bildern lächelt. Vordergrund: Ein sauberer, weißer Boden mit einer Yogamatte und Hanteln auf einer Seite. Hintergrund: Ein minimalistisches Heim-Fitnessstudio mit verspiegelten Wänden und Trainingsgeräten im Hintergrund, dezent unscharf.',
    'Eine dynamische Actionaufnahme, aufgenommen von einem Kunden mit einer GoPro Hero 11-Kamera. Das Bild ist energiegeladen und leicht geneigt, was zu seiner Spontaneität beiträgt. Motiv: Ein junger, abenteuerlustiger Mann in den Zwanzigern, der mit Helm und Sonnenbrille mit dem Mountainbike einen Trail hinunterfährt. Vordergrund: Ein unbefestigter Weg mit Steinen und heruntergefallenen Blättern, die verstreut sind, während er darüber reitet. Hintergrund: Ein üppiger, grüner Wald mit Sonnenstrahlen, die durch die Bäume dringen, wobei die Bewegungsunschärfe das Gefühl von Geschwindigkeit verstärkt.',
    'Eine Nahaufnahme eines frisch zubereiteten Cappuccinos, aufgenommen mit einer Leica Q2-Kamera. Der Fokus ist scharf und erfasst komplizierte Details. Motiv: Ein Cappuccino in einer weißen Keramiktasse, garniert mit aufwendiger Latte Art in Herzform. Der reichhaltige, samtige Schaum steht im Kontrast zum tiefbraunen Espresso darunter. Vordergrund: Die glänzende Oberfläche eines hölzernen Couchtisches, auf der sich die Cappuccino-Tasse und der daneben liegende zarte Löffel spiegeln. Hintergrund: Eine verschwommene Büroeinrichtung mit abstrakter Kunst an der Wand und sanfter Beleuchtung durch eine Schreibtischlampe, die eine gemütliche, warme Atmosphäre schafft.',
    'Eine Totalaufnahme eines kleinen Teams beim Brainstorming in einem Büro, aufgenommen mit einer Panasonic Lumix GH5-Kamera. Die Beleuchtung ist warm und einladend. Motiv: Vier Teammitglieder mit unterschiedlichem Hintergrund sind um ein Whiteboard versammelt und diskutieren Ideen. Sie sind leger gekleidet und lächeln. Vordergrund: Ein runder Tisch voller Notizbücher, Stifte und Kaffeetassen. Hintergrund: Ein offener Büroraum mit Glaswänden, Topfpflanzen und einem Whiteboard voller bunter Haftnotizen.',
    'Eine lebendige Aufnahme einer gemütlichen Winterszene, aufgenommen mit einer Olympus OM-D E-M1-Kamera. Das Bild zeichnet sich durch eine warme, festliche Beleuchtung aus. Motiv: Eine vierköpfige Familie, eingepackt in Winterkleidung, schmückt draußen einen Weihnachtsbaum. Es schneit leicht. Vordergrund: Eine kleine Holzkiste, gefüllt mit Weihnachtsdekorationen wie Ornamenten und Girlanden. Hintergrund: Eine schneebedeckte Hütte mit funkelnden Lichtern, aus dem Schornstein aufsteigendem Rauch und hohen Kiefern in Weichzeichnung.',
    'Ein kontrastreiches Bild, das einen Verkauf hervorhebt, aufgenommen mit einer Canon EOS Rebel T8i-Kamera. Die Beleuchtung ist dramatisch mit einem Spotlight-Effekt. Motiv: Ein auffälliges Schild mit dem Text ""50% Rabatt""auf einem eleganten, modernen Sockel, daneben das Produkt. Vordergrund: Eine reflektierende, schwarze Oberfläche, die den polierten Look des Produkts betont. Hintergrund: Ein Hintergrund mit Farbverlauf, der von dunkel nach hell übergeht, mit schwachen, abstrakten Formen, die Bewegung suggerieren.',
    'Werbefoto mit einfarbigem dunkelgrauem Hintergrund und futuristisch anmutendem Cappuccino mit Latte Art in der Mitte des Fotos. In einem dramatischen Dämmerlicht steht ein weisser Text: "Coffee up"'
  ],
  [AppLanguage.Spanish]: [
    'logotipo, dos zorros bebiendo café, zorros en llamas, caracteres naranjas, fondo blanco, fondo sencillo, obra maestra del espacio positivo y negativo, mejor calidad, alta calidad, highres',
    'Un primer plano sincero de un cliente satisfecho de mediana edad, tomado con una cámara Canon EOS R5. La imagen está muy iluminada con la luz natural del sol que entra por el lateral. Sujeto: El sujeto es un hombre hispano de unos 50 años, con camisa blanca remangada y una sonrisa afectuosa. Sostiene una reseña manuscrita en un portapapeles. Primer plano: La superficie de madera del mostrador de una cafetería con manchas de café y un par de tarjetas de fidelidad. Fondo: El interior borroso de una cafetería con paredes de ladrillo visto, un menú de pizarra y plantas colgantes.',
    'Una toma gran angular de una joven pareja utilizando una manta de picnic en un parque, captada con una cámara Nikon D850.La luz del sol crea un resplandor de hora dorada.Sujeto:La pareja, una mujer afroamericana y un hombre caucásico de unos 20 años, están vestidos de forma informal, riendo y disfrutando juntos de un bocadillo. Primer plano: La manta de picnic a cuadros con una cesta de frutas y una botella de vino. Fondo: Un fondo desenfocado de altos robles y corredores distantes en el parque.',
    'Una imagen de pantalla dividida que muestra una transformación de fitness, tomada con una cámara Sony A7 III. La iluminación es uniforme en ambos lados para garantizar la coherencia.Sujeto:El sujeto es una mujer en forma y tonificada de unos 30 años, primero con ropa holgada y luego con ropa deportiva, sonriendo en ambas imágenes.Primer plano: Un suelo blanco y limpio con una esterilla de yoga y unas mancuernas a un lado. Fondo: Un gimnasio doméstico minimalista con paredes de espejo y aparatos de ejercicio al fondo, sutilmente difuminados.',
    'Una foto dinámica de acción tomada por un cliente con una cámara GoPro Hero 11. La imagen es enérgica y está ligeramente inclinada, lo que aumenta su espontaneidad. Sujeto:Un joven aventurero de unos 20 años que va en bicicleta de montaña por un sendero, con casco y gafas de sol.Primer plano:Un camino de tierra con rocas y hojas caídas que se desparraman al pasar sobre ellas. Fondo: Un bosque frondoso y verde con rayos de sol que se filtran entre los árboles, el desenfoque de movimiento realza la sensación de velocidad.',
    'Un primer plano macro de un capuchino recién hecho, tomado con una cámara Leica Q2. El enfoque es nítido, captando detalles intrincados. Objeto: Un capuchino bellamente elaborado en una taza de cerámica blanca, coronado con un intrincado arte latte en forma de corazón. La espuma rica y aterciopelada contrasta con el espresso marrón intenso que hay debajo. Primer plano: La brillante superficie de una mesa de café de madera, que refleja la taza de capuchino y la delicada cuchara que descansa junto a ella. Fondo: Un interior de oficina difuminado, con arte abstracto en la pared y la suave iluminación de una lámpara de escritorio, que crea un ambiente acogedor y cálido.',
    'Un plano general de un pequeño equipo en una oficina, capturado con una cámara Panasonic Lumix GH5. La iluminación es cálida y acogedora.Sujeto: Cuatro miembros del equipo, de procedencias diversas, están reunidos en torno a una pizarra, discutiendo ideas.Visten informalmente y sonríen. Primer plano: Una mesa redonda llena de cuadernos, bolígrafos y tazas de café. Fondo: Una oficina abierta con paredes de cristal, plantas en macetas y una pizarra blanca cubierta de notas adhesivas de colores.',
    'Una vibrante instantánea de una acogedora escena invernal, tomada con una cámara Olympus OM-D E-M1. La imagen presenta una iluminación cálida y festiva. Sujeto:Una familia de cuatro miembros, abrigados con ropa de invierno, decorando un árbol de Navidad al aire libre.Cae una ligera nevada.Primer plano: Una pequeña caja de madera llena de adornos navideños, como ornamentos y guirnaldas. Fondo: Una cabaña cubierta de nieve con luces parpadeantes, humo saliendo de la chimenea y altos pinos enfocados suavemente.',
    'Una imagen de alto contraste que destaca una venta, tomada con una cámara Canon EOS Rebel T8i. La iluminación es espectacular, con un efecto de foco. Objeto: Un llamativo cartel de «50% de descuento» colocado sobre un elegante y moderno pedestal, con el producto a su lado. Primer plano: Una superficie negra reflectante que acentúa el aspecto pulido del producto. Fondo: Un fondo degradado que va de oscuro a claro, con formas tenues y abstractas que sugieren movimiento.',
    'Foto publicitaria que muestra un fondo gris oscuro sólido y un capuchino de aspecto futurista con arte latte en el centro de la foto iluminada con una luz tenue y dramática, un texto en blanco que dice «Coffee up».'
  ]
};
