import { createAction } from '@reduxjs/toolkit';
import { createAsyncAction } from 'utils/reduxUtils';

import { AiWriterTab } from '../../types';
import { InitializeTabSuccessPayload } from './types';

export const loadTab = createAction<AiWriterTab>('aiWriter/loadTab');
export const setCurrentTab = createAction<string>('aiWriter/setCurrentTab');
export const closeTab = createAction<string>('aiWriter/closeTab');
export const initializeTab = createAsyncAction<void, InitializeTabSuccessPayload, void>(
  'aiWriter/initializeTab'
);
export const renameCurrentTab = createAction<string>('aiWriter/renameCurrentTab');
