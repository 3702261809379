import { keysToSnake } from 'utils/utils';

import AxiosClient from '..';
import {
  CreateCampaignResponse,
  DeleteCampaignResponse,
  GetAllCampaignsResponse,
  NewCampaignRequestPayload,
  UpdateCampaignRequestPayload,
  UpdateCampaignResponse
} from './types';

const CampaignAPI = {
  getAll: () => AxiosClient.get<unknown, GetAllCampaignsResponse>('settings/campaign'),
  get: (id: number) => AxiosClient.get(`settings/campaign/${id}`),
  update: (id: number, payload: UpdateCampaignRequestPayload) =>
    AxiosClient.put<UpdateCampaignRequestPayload, UpdateCampaignResponse>(
      `settings/campaign/${id}`,
      keysToSnake(payload)
    ),
  create: (payload: NewCampaignRequestPayload) =>
    AxiosClient.post<NewCampaignRequestPayload, CreateCampaignResponse>(
      'settings/campaign',
      keysToSnake(payload)
    ),
  delete: (id: number) =>
    AxiosClient.delete<unknown, DeleteCampaignResponse>(`settings/campaign/${id}`)
};

export default CampaignAPI;
