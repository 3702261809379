import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography
} from '@mui/material';
import { formatDistanceToNow } from 'date-fns';
import FormattedMessage from 'features/i18n/FormattedMessage';
import { useShowInformationEditModal } from 'features/information/useShowInformationModal';
import { languageToDateFnsLocaleMapper } from 'features/language/languageToDateFnsLocaleMapper';
import { getCurrentLanguage } from 'features/language/store/selectors';
import { CategoryChip } from 'pages/brand-hub/components/CategoryChip';
import { InformationMenu } from 'pages/brand-hub/InformationMenu';
import { ChangeEvent, MouseEvent } from 'react';
import { InformationDto } from 'services/backofficeIntegration/http/endpoints/infomration/httpGetInformationList';
import { useAppSelector } from 'store/hooks';
import styled from 'styled-components';

type InformationListTableProps = {
  informationList: InformationDto[];
  page: number;
  limit: number;
  total: number;
  onChangePage: (_event: unknown | null, page: number) => void;
  onChangeRowsPerPage: (event: ChangeEvent<HTMLInputElement>) => void;
};

export function InformationListTable({
  informationList,
  page,
  limit,
  total,
  onChangePage,
  onChangeRowsPerPage
}: InformationListTableProps) {
  const appLanguage = useAppSelector(getCurrentLanguage);
  const customerId = useAppSelector(state => state.customer.id);

  const [, showInformationEditModal] = useShowInformationEditModal();

  const isOwnedByCustomer = (information: InformationDto) => {
    return information.customer_id === customerId;
  };

  const handleRowClick = (event: MouseEvent, information: InformationDto) => {
    event.preventDefault();
    event.stopPropagation();

    if (!isOwnedByCustomer(information)) {
      return;
    }

    showInformationEditModal(information);
  };

  const isCategoryEditable = (categoryAuthorId: number) => categoryAuthorId === customerId;

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              <Typography variant="subtitle1">
                <FormattedMessage id="flashHub.information.table.name" />
              </Typography>
            </TableCell>
            <TableCell>
              <Typography variant="subtitle1">
                <FormattedMessage id="flashHub.information.table.author" />
              </Typography>
            </TableCell>
            <TableCell>
              <Typography variant="subtitle1">
                <FormattedMessage id="flashHub.information.table.description" />
              </Typography>
            </TableCell>
            <TableCell>
              <Typography variant="subtitle1">
                <FormattedMessage id="flashHub.information.table.tag" />
              </Typography>
            </TableCell>
            <TableCell>
              <Typography variant="subtitle1">
                <FormattedMessage id="flashHub.information.table.date" />
              </Typography>
            </TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {informationList.map(information => {
            const isOwner = isOwnedByCustomer(information);

            return (
              <ClickableRow
                key={information.id}
                hover={isOwner}
                $canBeHovered={isOwner}
                onClick={event => handleRowClick(event, information)}
              >
                <TableCell>{information.name}</TableCell>
                <TableCell>{information.author_name}</TableCell>
                <TableCell>{information.description}</TableCell>
                <TableCell>
                  {information.category_id && (
                    <CategoryChip
                      id={information.category_id}
                      name={information.category_name}
                      editable={isCategoryEditable(information.category_author_id)}
                      information={information}
                    />
                  )}
                </TableCell>
                <TableCell>
                  {formatDistanceToNow(new Date(information.updated_at), {
                    addSuffix: true,
                    locale: languageToDateFnsLocaleMapper(appLanguage)
                  })}
                </TableCell>
                <TableCell>{isOwner && <InformationMenu information={information} />}</TableCell>
              </ClickableRow>
            );
          })}
        </TableBody>
      </Table>
      <TablePagination
        count={total}
        page={page}
        rowsPerPage={limit}
        onPageChange={onChangePage}
        rowsPerPageOptions={[5, 10, 25]}
        onRowsPerPageChange={onChangeRowsPerPage}
        component="div"
      />
    </TableContainer>
  );
}

const ClickableRow = styled(TableRow)<{ $canBeHovered: boolean }>`
  cursor: ${({ $canBeHovered }) => ($canBeHovered ? 'pointer' : 'default')};
`;
