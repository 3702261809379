import { Typography } from '@mui/material';
import FlexContainer from 'components/FlexContainer';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { mobileWindowWidthCSS } from 'utils/hooks/useMobileVersion';

type Props = { title?: string; items?: string[] };

export const AuthSummarySection = ({ title, items }: Props) => (
  <SummaryList gap={0}>
    {title && (
      <Typography variant="h6">
        <FormattedMessage id={title} />
      </Typography>
    )}

    {items?.map(messageKey => (
      <Typography key={messageKey} variant="body1">
        ✓ &nbsp;
        <FormattedMessage
          id={messageKey}
          values={{ b: (text: string) => <strong>{text}</strong> }}
        />
      </Typography>
    ))}
  </SummaryList>
);

const SummaryList = styled(FlexContainer)`
  @media (max-width: ${mobileWindowWidthCSS}) {
    display: none;
  }
`;
