import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import Toast from 'components/toasts/Toast';
import { ApiModularWorkflowStep } from 'features/modular-workflow/builder/types-api';
import { BuilderModularWorkflow } from 'features/modular-workflow/builder/types-builder';
import { httpUpdateModularWorkflow } from 'services/backofficeIntegration/http/endpoints/modularWorkflow/httpUpdateModularWorkflow';

export const useUpdateWorkflowMutation = (
  options?: UseMutationOptions<ApiModularWorkflowStep[], unknown, BuilderModularWorkflow, unknown>
) =>
  useMutation({
    mutationFn: httpUpdateModularWorkflow.callEndpoint,
    onSuccess: () => {
      Toast.success('modular_workflow.builder.update_successful');
    },
    ...options
  });
