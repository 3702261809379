import { FormattedMessage } from 'react-intl';

import { ReactComponent as SadIcon } from '../../assets/sad-icon.inline.svg';
import Icon from '../base/Icon';
import CenteredContainer from '../styled/CenteredContainer';

const NoTabsPlaceholder = ({ message }: { message: string }) => {
  return (
    <CenteredContainer>
      <SadIcon width="128" height="128" display="block" />
      <div className="mt-4">
        <FormattedMessage id={message} values={{ plus: <Icon name="plus" /> }} />
      </div>
    </CenteredContainer>
  );
};

export default NoTabsPlaceholder;
