import { CircularProgress, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { ColoredTypography } from 'components/ColoredTypography';
import FlexContainer from 'components/FlexContainer';
import dayjs from 'dayjs';
import { CustomerAvatar } from 'features/customer/CustomerAvatar';
import { getCustomerRole } from 'features/customer/store/selectors';
import { CustomerRole, CustomerStatus } from 'features/customer/store/types';
import { ChangeRoleDialog } from 'features/profilePage/ChangeRoleDialog';
import { RemoveUserDialog } from 'features/profilePage/RemoveUserDialog';
import { useCancelInvitation } from 'features/profilePage/useCancelInvitaton';
import { useChangeUserStatus } from 'features/profilePage/useChangeUserStatus';
import { UserActivationDialog } from 'features/profilePage/UserActivationDialog';
import { useRemoveUser } from 'features/profilePage/useRemoveUser';
import { useResendInvitation } from 'features/profilePage/useResendInvitaton';
import { UsersListMenu } from 'features/profilePage/UsersListMenu';
import { useUpdateUserRole } from 'features/profilePage/useUpdateUserRole';
import { trackingWrapper } from 'features/tracking/wrapper/TrackingWrapper';
import { useState } from 'react';
import { TeamMemberDto } from 'services/backofficeIntegration/http/dtos/TeamMemberDto';
import { CancelInvitationParams } from 'services/backofficeIntegration/http/endpoints/teamMembers/httpCancelInvitation';
import { ResendInvitationParams } from 'services/backofficeIntegration/http/endpoints/teamMembers/httpResendInvitation';
import { GAEvents } from 'services/tracking/GAEvents';
import { useAppSelector } from 'store/hooks';
import styled from 'styled-components';
import useDialog from 'utils/hooks/useDialog';
import useTr from 'utils/hooks/useTr';

const canChangeRole = (row: TeamMemberDto, userRole: CustomerRole) =>
  // Can't change the owner role
  row.team_role !== CustomerRole.OWNER &&
  // Can change role of deactivated members
  row.status !== CustomerStatus.DEACTIVATED &&
  // Only admin or owner can change roles
  userRole !== CustomerRole.MEMBER;

type RoleChangeInformation = {
  role: CustomerRole;
  teamCustomerId: number;
  teamId: number;
};

type StatusChangeInformation = {
  status: boolean;
  teamCustomerId: number;
  teamId: number;
};

type RemovedUserInformation = {
  teamId: number;
  teamCustomerId: number;
};

export const UserTableRow = ({
  row,
  isLoading,
  onRowRemove
}: {
  row: TeamMemberDto;
  isLoading: boolean;
  onRowRemove: () => void;
}) => {
  const translate = useTr();

  const userRole = useAppSelector(getCustomerRole);

  const [targetRoleChange, setTargetRoleChange] = useState<RoleChangeInformation | null>();
  const [isChangeRoleDialogOpen, openDialog, closeChangeRoleDialog] = useDialog();

  const [targetStatusChange, setTargetStatusChange] = useState<StatusChangeInformation | null>();
  const [isUserActivationDialogOpen, openStatusDialog, closeUserActivationDialog] = useDialog();

  const [targetRemovedUser, setTargetRemovedUser] = useState<RemovedUserInformation | null>();
  const [isRemoveUserDialogOpen, openRemoveUserDialog, closeRemoveUserDialog] = useDialog();

  const updateUserRole = useUpdateUserRole();
  const removeUser = useRemoveUser();
  const changeUserStatus = useChangeUserStatus();
  const resendInvitation = useResendInvitation();
  const cancelInvitation = useCancelInvitation();

  const handleChangeRoleDialogConfirm = () => {
    if (!targetRoleChange) {
      closeChangeRoleDialog();
      return;
    }

    updateUserRole.mutate(
      {
        teamCustomerId: targetRoleChange.teamCustomerId,
        teamId: targetRoleChange.teamId,
        team_role: targetRoleChange.role
      },
      {
        onSuccess: () => {
          GAEvents.teamUserRoleChange({
            teamCustomerId: targetRoleChange.teamCustomerId,
            teamId: targetRoleChange.teamId,
            teamRole: targetRoleChange.role
          });

          trackingWrapper.track('teamUserRoleChange', {
            teamCustomerId: targetRoleChange.teamCustomerId,
            teamId: targetRoleChange.teamId,
            teamRole: targetRoleChange.role
          });
        }
      }
    );

    closeChangeRoleDialog();
  };

  const handleRemoveUserDialogConfirm = () => {
    if (!targetRemovedUser) {
      closeRemoveUserDialog();
      return;
    }

    removeUser.mutate(
      { teamCustomerId: targetRemovedUser.teamCustomerId },
      {
        onSuccess: () => {
          GAEvents.teamUserDeleted({
            teamId: targetRemovedUser.teamId,
            teamCustomerId: targetRemovedUser.teamCustomerId
          });

          trackingWrapper.track('teamUserDeleted', {
            teamId: targetRemovedUser.teamId,
            teamCustomerId: targetRemovedUser.teamCustomerId
          });

          onRowRemove();
        }
      }
    );

    closeRemoveUserDialog();
  };

  const handleUserActivationDialogConfirm = () => {
    if (!targetStatusChange) {
      closeUserActivationDialog();
      return;
    }

    changeUserStatus.mutate(
      {
        teamCustomerId: targetStatusChange.teamCustomerId,
        teamId: targetStatusChange.teamId,
        is_active: targetStatusChange.status
      },
      {
        onSuccess: () => {
          if (targetStatusChange.status) {
            GAEvents.teamUserReactivated({
              teamId: targetStatusChange.teamId,
              teamCustomerId: targetStatusChange.teamCustomerId
            });

            trackingWrapper.track('teamUserReactivated', {
              teamId: targetStatusChange.teamId,
              teamCustomerId: targetStatusChange.teamCustomerId
            });
          } else {
            GAEvents.teamUserDeactivated({
              teamId: targetStatusChange.teamId,
              teamCustomerId: targetStatusChange.teamCustomerId
            });

            trackingWrapper.track('teamUserDeactivated', {
              teamId: targetStatusChange.teamId,
              teamCustomerId: targetStatusChange.teamCustomerId
            });
          }
        }
      }
    );

    closeUserActivationDialog();
  };

  const handleResendInvitation = ({ teamId, email }: ResendInvitationParams) => {
    resendInvitation.mutate(
      { teamId, email },
      {
        onSuccess: () => {
          GAEvents.teamInvitationResent({
            teamId,
            email
          });

          trackingWrapper.track('teamInvitationResent', {
            teamId,
            email
          });
        }
      }
    );
  };

  const handleCancelInvitation = ({ teamId, teamCustomerId }: CancelInvitationParams) => {
    cancelInvitation.mutate(
      { teamId, teamCustomerId },
      {
        onSuccess: () => {
          onRowRemove();
        }
      }
    );
  };

  const handleRoleChange = (event: SelectChangeEvent<unknown>, id: number, teamId: number) => {
    setTargetRoleChange({
      role: event.target.value as CustomerRole,
      teamCustomerId: id,
      teamId: teamId
    });

    openDialog();
  };

  const handleRemoveUser = (teamCustomerId: number, teamId: number) => {
    setTargetRemovedUser({
      teamId,
      teamCustomerId
    });

    openRemoveUserDialog();
  };

  const handleStatusChange = (is_active: boolean, id: number, teamId: number) => {
    setTargetStatusChange({ status: is_active, teamCustomerId: id, teamId: teamId });
    openStatusDialog();
  };

  const labelId = `enhanced-table-checkbox-${row.id}`;

  return (
    <>
      <ChangeRoleDialog
        isDialogOpen={isChangeRoleDialogOpen}
        onCancel={closeChangeRoleDialog}
        onConfirm={handleChangeRoleDialogConfirm}
      />
      <UserActivationDialog
        isDialogOpen={isUserActivationDialogOpen}
        onCancel={closeUserActivationDialog}
        onConfirm={handleUserActivationDialogConfirm}
        deactivateUser={!targetStatusChange?.status}
      />
      <RemoveUserDialog
        isDialogOpen={isRemoveUserDialogOpen}
        onCancel={closeRemoveUserDialog}
        onConfirm={handleRemoveUserDialogConfirm}
      />
      <TableRow hover={true} tabIndex={-1}>
        <TableCell component="th" id={labelId} scope="row">
          <FlexContainer direction="row" alignItems="center" gap="small">
            <CustomerAvatar name={row.name} image={row.image_url} email={row.email} size="2rem" />
            <FlexContainer direction="col">
              <ColoredTypography variant="subtitle2">{row.name}</ColoredTypography>
              <ColoredTypography color="blackMediumEmphasis" variant="caption">
                {row.email}
              </ColoredTypography>
            </FlexContainer>
          </FlexContainer>
        </TableCell>
        <StyledTableCell align="left" sx={{ paddingLeft: 0 }}>
          {!canChangeRole(row, userRole) ? (
            <ColoredTypography variant="subtitle2" sx={{ paddingLeft: '14px' }}>
              {row.team_role}
            </ColoredTypography>
          ) : (
            <StyledSelect
              value={row.team_role}
              label={translate('profile.users.users_list.role')}
              onChange={event => handleRoleChange(event, row.id, row.team_id)}
              disabled={updateUserRole.isLoading}
              endAdornment={updateUserRole.isLoading ? <CircularProgress size={16} /> : undefined}
            >
              <MenuItem value={CustomerRole.ADMIN}>{translate('customer.role.admin')}</MenuItem>
              <MenuItem value={CustomerRole.MEMBER}>{translate('customer.role.member')}</MenuItem>
            </StyledSelect>
          )}
        </StyledTableCell>
        <StyledTableCell align="left">
          <FlexContainer direction="row" alignItems="center" justifyContent="start" gap="small">
            <ActivityIndicator $status={row.status} />{' '}
            {translate(`customer.team.status.${row.status}`)}
          </FlexContainer>
        </StyledTableCell>
        <TableCell align="left">
          <ColoredTypography variant="body2" color="blackMediumEmphasis">
            {row.last_active && dayjs(row.last_active).format('YYYY-MM-DD')}
          </ColoredTypography>
        </TableCell>
        <TableCell align="left">
          {userRole !== CustomerRole.MEMBER && row.team_role !== CustomerRole.OWNER && (
            <UsersListMenu
              row={row}
              userRole={userRole}
              onResendInvitation={handleResendInvitation}
              onCancelInvitation={handleCancelInvitation}
              onStatusChange={() => handleStatusChange(!row.is_active, row.id, row.team_id)}
              onRemoveUser={() => handleRemoveUser(row.id, row.team_id)}
              disabled={isLoading}
              loading={
                resendInvitation.isLoading ||
                cancelInvitation.isLoading ||
                changeUserStatus.isLoading ||
                removeUser.isLoading ||
                updateUserRole.isLoading
              }
            />
          )}
        </TableCell>
      </TableRow>
    </>
  );
};
const ActivityIndicator = styled.div<{ $status: string }>`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 0.75rem;
  height: 0.75rem;

  background-color: ${({ $status }) =>
    $status === 'Active' ? '#6FD60B' : $status === 'Pending' ? '#FF7900' : '#FD173E'};
  border-radius: 50%;
`;
const StyledSelect = styled(Select)`
  .MuiOutlinedInput-notchedOutline {
    border: none !important;
  }
  box-shadow: none;
  height: 1.75rem;
  font-size: 0.875rem;
  font-weight: 500;
  min-width: 8rem;
`;
const StyledTableCell = styled(TableCell)`
  font-weight: 500;
`;
