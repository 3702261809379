import { create } from 'zustand';

type GlobalConfettiState = {
  sourceElement: HTMLElement | null;
  isActive: boolean;
};

type GlobalConfettiStateActions = {
  toggle: () => void;
  reset: () => void;
  setIsActive: (isActive: boolean) => void;
  setSourceElement: (sourceElement: HTMLElement | null) => void;
};

const initialState: GlobalConfettiState = {
  sourceElement: null,
  isActive: false
};

export const useGlobalConfettiStore = create<GlobalConfettiState & GlobalConfettiStateActions>(
  set => ({
    ...initialState,

    toggle: () => set(state => ({ isActive: !state.isActive })),
    reset: () => set(initialState),
    setIsActive: (isActive: boolean) => set({ isActive }),
    setSourceElement: (sourceElement: HTMLElement | null) => set({ sourceElement })
  })
);
