import { getPaths } from 'config/routes';
import { useMemo, useRef } from 'react';
import { useCurrentRoutePath } from 'utils/hooks/useCurrentRoutePath';

export default function useIsAuthRoute() {
  const pathname = useCurrentRoutePath();

  const pathNamesWithAuthentication = useRef(getPaths(config => config.auth));

  return useMemo(() => pathNamesWithAuthentication.current.includes(pathname), [pathname]);
}
