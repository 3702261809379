import { CustomerActiveSubscriptionState } from 'services/api/customer/types';
import { useAppSelector } from 'store/hooks';

type Options = {
  currentSubscriptionState?: CustomerActiveSubscriptionState | null;
};

export const useIsOnTrial = (options?: Options) => {
  const subscriptionState = useAppSelector(state => state.customer.subscription)?.status;

  const currentSubscriptionState = options?.currentSubscriptionState;
  if (currentSubscriptionState) {
    return currentSubscriptionState.status === 'on trial';
  }

  return subscriptionState === 'on trial';
};
