import { QueryClientProvider } from '@tanstack/react-query';
import App from 'App';
import { Provider } from 'react-redux';
import store from 'store/store';
import { ThemeProvider as StyledComponentsThemeProvider } from 'styled-components';
import {
  getThemeConfig,
  useThemedStyledComponentsConfig
} from 'utils/hooks/useThemedStyledComponentsConfig';
import { queryClient } from 'utils/ReactQueryClient';

export const Root = () => {
  const [styledComponentsConfig, themeMode] = useThemedStyledComponentsConfig();

  return (
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <StyledComponentsThemeProvider theme={styledComponentsConfig}>
          <App themeMode={themeMode} themeConfig={getThemeConfig(themeMode)} />
        </StyledComponentsThemeProvider>
      </Provider>
    </QueryClientProvider>
  );
};
