import { EditorHotkeyAction } from 'features/aiWriter/AiWriterTextEditor/types';

export const LIST_TYPES = ['li', 'ol', 'ul', 'numbered-list', 'bulleted-list'];

/**
 * Describes hotkeys which execute custom define actions.
 *
 * note: "meta" is cmd on mac
 */
export const ACTION_HOTKEYS: Record<EditorHotkeyAction, string[]> = {
  continue_writing: ['alt+w', 'meta+j'],
  command: ['alt+enter'],
  clearFormatting: ['ctrl+\\', 'cmd+\\']
};

export const minAmountOfCharactersToBeUsedAsContext = 10;
