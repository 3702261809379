import { WordAttributeOptionValue } from 'types/ReduxStateTypes';

export const wordAttributeOppositeValue: Record<WordAttributeOptionValue, boolean> = {
  easy: false,
  complex: true,
  happy: false,
  sad: true,
  calm: false,
  exciting: true,
  powerful: false,
  gentle: true
};
