import { create } from 'zustand';

export enum MenuItemType {
  EmptyPage = 0,
  AiAction = 1,
  TextTypes = 2,
  Chat = 3,
  AiImage = 4,
  BlogPostWorkflow = 5,
  SocialMediaWorkflow = 6
}

type EmptyDocumentState = {
  enteredHeadline: string;
  selectedMenuItem: MenuItemType;
};

type EmptyDocumentActions = {
  setEnteredHeadline: (enteredHeadline: string) => void;
  setSelectedMenuItem: (selectedMenuItem: MenuItemType) => void;
  reset: () => void;
};

const initialState: EmptyDocumentState = {
  enteredHeadline: '',
  selectedMenuItem: MenuItemType.EmptyPage
};

export const useEmptyDocumentStore = create<EmptyDocumentState & EmptyDocumentActions>(set => ({
  ...initialState,
  setEnteredHeadline: enteredHeadline => set({ enteredHeadline }),
  setSelectedMenuItem: selectedMenuItem => set({ selectedMenuItem }),
  reset: () => set({ ...initialState })
}));
