import { AddCircleOutline, CheckCircleOutlined } from '@mui/icons-material';
import { Chip, Typography } from '@mui/material';
import FlexContainer from 'components/FlexContainer';
import { PricingPlan } from 'features/pricing/types';
import { range, uniqueId } from 'lodash';
import { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import useTr from 'utils/hooks/useTr';

type Props = {
  planType: PricingPlan;
  header?: ReactNode;
  listType: 'feature' | 'request' | 'add_ons';
  perksAmount: number | undefined;
};

const PerkSublistIcon = ({ listType }: { listType: Props['listType'] }) => {
  const icon =
    listType === 'add_ons' ? (
      <AddCircleOutline color="primary" />
    ) : (
      <StyledIconCheckCircle width={24} height={24} color="primary" />
    );

  return icon;
};

const PlanPerkSublist = ({ planType, header, listType, perksAmount }: Props) => {
  const tr = useTr();

  const getLinkForMessageByPerkIndex = (index: number) =>
    tr(`profile.plan.${planType}_${listType}_perk_${index}_link`);

  if (!perksAmount) {
    return null;
  }

  return (
    <div>
      {perksAmount > 0 && (
        <FlexContainer
          direction="col"
          justifyContent="space-between"
          alignItems="flex-start"
          gap="three"
        >
          {header && <Typography variant="subtitle2">{header}</Typography>}
          {range(perksAmount).map((_, index) => {
            return (
              <FlexContainer key={uniqueId()} gap="one" direction="row" alignItems="center">
                <PerkSublistIcon listType={listType} />
                <FeatureText variant="body2">
                  <FormattedMessage
                    id={`profile.plan.${planType}_${listType}_perk_${index}`}
                    values={{
                      br: <br />,
                      b: (text: string) => <strong>{text}</strong>,
                      u: (text: string) => <u>{text}</u>,
                      i: (text: string) => <i>{text}</i>,
                      small: (text: string) => <Typography variant="caption">{text}</Typography>,
                      a: (text: string) => (
                        <a
                          target="_blank"
                          rel="noreferrer"
                          href={getLinkForMessageByPerkIndex(index)}
                        >
                          {text}
                        </a>
                      ),
                      badge: (text: string) => (
                        <StyledBadge size="small" color="secondary" label={text} />
                      )
                    }}
                  />
                </FeatureText>
              </FlexContainer>
            );
          })}
        </FlexContainer>
      )}
    </div>
  );
};

const StyledIconCheckCircle = styled(CheckCircleOutlined)`
  flex: 0 0 20px;
`;

const StyledBadge = styled(Chip)`
  background-color: #ba87ff; /* #color-tech-debt */
  color: ${({ theme }) => theme.colors.palette.white};
  border-radius: ${({ theme }) => theme.borderRadius.four};
  margin-left: ${({ theme }) => theme.spacings.one};
`;

const FeatureText = styled(Typography)`
  text-align: left;
`;

export default PlanPerkSublist;
