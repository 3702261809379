import Card from 'components/Card/Card';
import CardWithTitle from 'components/CardWithTitle/CardWithTitle';
import { getIsAuthenticated } from 'features/customer/store/selectors';
import DimensionsTable from 'features/dimensions/DimensionsTable';
import { getDimensions } from 'features/dimensions/store/actions';
import { useEnableSidebarOnMount } from 'features/pageSidebar/useSidebarStore';
import { ErrorBoundary } from 'features/react-error-boundary/ErrorBoundary';
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';

const DimensionsManagement = () => {
  const isAuthenticated = useAppSelector(getIsAuthenticated);
  const dispatch = useAppDispatch();

  useEnableSidebarOnMount();

  useEffect(() => {
    if (isAuthenticated) dispatch(getDimensions.request());
  }, [dispatch, isAuthenticated]);

  return (
    <CardWithTitle trPrefix="dimensions_mgmt">
      <Card.Body>
        <ErrorBoundary>
          <DimensionsTable />
        </ErrorBoundary>
      </Card.Body>
    </CardWithTitle>
  );
};

export default DimensionsManagement;
