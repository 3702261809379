import Masonry from '@mui/lab/Masonry';
import { Skeleton } from '@mui/material';
import { imageWidth } from 'features/aiWriter/AiWriterSidebar/steps/images/ImagesInspirations';
import styled from 'styled-components';

const ImagesLoadingState = () => (
  <Root>
    <Masonry columns={2} spacing={2}>
      {Array(5)
        .fill([220, 153])
        .flat()
        .map((value: number) => (
          <Skeleton key={Math.random()} variant="rectangular" width={imageWidth} height={value} />
        ))}
    </Masonry>
  </Root>
);

const Root = styled.div``;

export default ImagesLoadingState;
