import React from 'react';
import styled from 'styled-components';
import { helpText } from 'styles/styleUtils';

const HelpTextWrapper = styled.div`
  color: ${helpText};
  padding: 0 1em;
`;

type Props = {
  children: React.ReactElement;
};

const HelpText = ({ children }: Props) => <HelpTextWrapper>{children}</HelpTextWrapper>;

export default HelpText;
