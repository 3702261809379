import { ActionType, getType } from 'typesafe-actions';
import { arrayToDictionary } from 'utils/utils';

import * as actions from './actions';
import { DimensionRow, DimensionsState } from './types';

export type DimensionsAction = ActionType<typeof actions>;

const initialState: DimensionsState = {
  dimensions: {},
  currentDimension: 0,
  isLoading: false,
  unseenDimensions: 0,
  dimensionsPageRefetchCounter: 0
};

export function dimensions(state = initialState, action: DimensionsAction): DimensionsState {
  switch (action.type) {
    case getType(actions.combineDimensions.request):
    case getType(actions.addToExistingDimension.request):
    case getType(actions.addDimension.request):
    case getType(actions.getDimensions.request):
    case getType(actions.updateDimension.request):
    case getType(actions.removeDimension.request):
    case getType(actions.removeMultipleDimensions.request):
      return { ...state, isLoading: true };

    case getType(actions.combineDimensions.failure):
    case getType(actions.addToExistingDimension.failure):
    case getType(actions.addDimension.failure):
    case getType(actions.getDimensions.failure):
    case getType(actions.updateDimension.failure):
    case getType(actions.removeDimension.failure):
    case getType(actions.removeMultipleDimensions.failure):
      return { ...state, isLoading: false };

    case getType(actions.combineDimensions.success):
    case getType(actions.addToExistingDimension.success):
    case getType(actions.addDimension.success):
    case getType(actions.updateDimension.success):
      return {
        ...state,
        currentDimension: action.payload,
        dimensionsPageRefetchCounter: state.dimensionsPageRefetchCounter + 1
      };

    case getType(actions.removeDimension.success):
    case getType(actions.removeMultipleDimensions.success):
      return {
        ...state,
        dimensionsPageRefetchCounter: state.dimensionsPageRefetchCounter + 1
      };

    case getType(actions.getDimensions.success): {
      const dataWithTableProps: DimensionRow[] = action.payload.map(dim => ({
        ...dim,
        selected: false,
        opened: false
      }));
      const dimensions = arrayToDictionary(dataWithTableProps, 'id');
      let { currentDimension } = state;
      if (!currentDimension || !dimensions[currentDimension]) {
        currentDimension = action.payload.length ? action.payload[0].id : 0;
      }
      return {
        ...state,
        isLoading: false,
        currentDimension,
        dimensions
      };
    }

    case getType(actions.selectDimension):
      return {
        ...state,
        currentDimension: action.payload
      };

    case getType(actions.showDimensionsWords):
      return {
        ...state,
        currentDimension: action.payload,
        unseenDimensions: 0
      };

    default:
      return state;
  }
}
