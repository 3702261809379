import { ArrowDropDown } from '@mui/icons-material';
import { IconButton, Tooltip, Typography } from '@mui/material';
import FlexContainer from 'components/FlexContainer';
import { FlashActionsMenu } from 'features/aiWriter/AiWriterSidebar/steps/chat/FlashActionsMenu';
import { Message } from 'features/aiWriter/AiWriterSidebar/steps/chat/Message';
import { usePredefinedFlashActions } from 'features/aiWriter/AiWriterSidebar/steps/chat/usePredefinedFlashActions';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import gtmIds from 'services/tracking/GTMIds';
import { withGtmInteraction } from 'services/tracking/withGtmInteraction';
import styled from 'styled-components';

export const FlashActionsButtons = ({ message }: { message: Message }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isOptionsOpen = Boolean(anchorEl);

  const handleClickOption = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const { handleRewrite, handleContinue, handleSummarize, handleImprove, handleCustomAction } =
    usePredefinedFlashActions({ message, isInline: true });

  return (
    <>
      <FlashActionsBox>
        <ChipStyledButton
          {...withGtmInteraction(gtmIds.aiWriter.chat.continueFlashAction)}
          onClick={handleContinue}
        >
          <ChipText>
            <FormattedMessage id="common.continue" />
          </ChipText>
        </ChipStyledButton>
        <ChipStyledButton
          {...withGtmInteraction(gtmIds.aiWriter.chat.rewriteFlashAction)}
          onClick={handleRewrite}
        >
          <ChipText>
            <FormattedMessage id="common.rewrite" />
          </ChipText>
        </ChipStyledButton>
        <ChipStyledButton
          {...withGtmInteraction(gtmIds.aiWriter.chat.improveFlashAction)}
          onClick={handleImprove}
        >
          <ChipText>
            <FormattedMessage id="common.improve" />
          </ChipText>
        </ChipStyledButton>
        <Tooltip title={<FormattedMessage id="aiWriter.inspirations.suggestions.flash_actions" />}>
          <IconButton
            {...withGtmInteraction(gtmIds.aiWriter.chat.openFlashActionsButton)}
            onClick={e => {
              e.stopPropagation();
              handleClickOption(e);
            }}
          >
            <ArrowDropDown />
          </IconButton>
        </Tooltip>
      </FlashActionsBox>
      <FlashActionsMenu
        message={message}
        anchorEl={anchorEl}
        isOptionsOpen={isOptionsOpen}
        onCloseMenu={handleCloseMenu}
        onRewrite={handleRewrite}
        onContinue={handleContinue}
        onSummarize={handleSummarize}
        onImprove={handleImprove}
        onCustomAction={handleCustomAction}
      ></FlashActionsMenu>
    </>
  );
};

const ChipStyledButton = styled.button`
  padding: 0 8px;
  height: 2rem;
  color: ${({ theme }) => theme.colors.secondaryColorContrast};
  background-color: ${({ theme }) => theme.colors.commonBrandColorsBrandGreen};
  border: none;
  border-radius: 100px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-around;
`;

const ChipText = styled(Typography)`
  font-size: ${({ theme }) => theme.typography.button.small.fontSize};
  font-weight: ${({ theme }) => theme.typography.button.small.fontWeight};
`;

const FlashActionsBox = styled(FlexContainer).attrs({
  gap: 'one',
  direction: 'row',
  justifyContent: 'flex-end'
})`
  margin-top: ${({ theme }) => `-${theme.spacings.three}`};
`;
