import { getCurrentTabModelData } from 'features/explorer/store/selectors';
import { Field, FieldProps } from 'formik';
import { FormattedMessage } from 'react-intl';
import { List } from 'services/api/list/types';
import { useAppSelector } from 'store/hooks';

import ComboBox from '../../../../Autocomplete/ComboBox';
import FormGroup from '../../../../base/FormGroup';

const AvailableListsComboboxBase = ({
  field: { name, value },
  form: { setFieldValue, errors, touched },
  label
}: FieldProps & { label: string }) => {
  const allLists = useAppSelector(state => state.lists.lists);
  const tabModelData = useAppSelector(getCurrentTabModelData);

  const listOptions = Object.values(allLists).filter(
    list => list.language === tabModelData.language
  );

  return (
    <FormGroup label={label} required>
      <ComboBox
        data={listOptions}
        itemId="id"
        itemToString={(item: List | null) => (item ? item.label : '')}
        initialSelectedItem={listOptions.find(item => item.id === value)}
        onChange={item => item && setFieldValue(name, item.id)}
      />
      {errors[name] && touched[name] && (
        <span className="invalid-feedback">
          <FormattedMessage id="validation.field_required" />
        </span>
      )}
    </FormGroup>
  );
};

type Props = { name: string; label: string };

const AvailableListsCombobox = (props: Props) => (
  <Field component={AvailableListsComboboxBase} {...props} />
);

export default AvailableListsCombobox;
