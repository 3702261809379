import { Loading } from 'types/ReduxStateTypes';
import { ActionType, getType } from 'typesafe-actions';

import * as actions from './actions';
import { Group } from './types';

export type GroupActions = ActionType<typeof actions>;

export interface GroupState extends Loading {
  group?: Group;
}

const initialState: GroupState = {
  group: undefined,
  isLoading: false
};

export function group(state: GroupState = initialState, action: GroupActions): GroupState {
  switch (action.type) {
    case getType(actions.getGroup.request):
      return { ...state, isLoading: true };
    case getType(actions.getGroup.failure):
      return { ...state, isLoading: false };
    case getType(actions.getGroup.success):
      return { ...state, group: action.payload, isLoading: false };
    default:
      return state;
  }
}
