import { Typography } from '@mui/material';
import React from 'react';
import styled from 'styled-components';

type HeadingLevel = '1' | '2' | '3';

type Props = {
  level: HeadingLevel;
  isActive?: boolean;
};

export const EditorHeaderIcon = ({ level, isActive }: Props): React.ReactElement => (
  <Root variant="body1" $isActive={isActive}>
    {`H${level}`}
  </Root>
);

const Root = styled(Typography)<{ $isActive?: boolean }>`
  display: inherit;
  && {
    color: ${({ theme, $isActive }) => ($isActive ? theme.colors.primary : 'inherit')};
  }
`;
