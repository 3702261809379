import Toast from 'components/toasts/Toast';
import { getErrorCodeFromAxiosError } from 'features/teamInvitation/getErrorCodeFromAxiosError';
import { BaseSuccessDto } from 'services/backofficeIntegration/http/BaseSuccessDto';
import { backofficeClient } from 'services/backofficeIntegration/http/client/createBackofficeClient';
import { queryEndpoint } from 'services/backofficeIntegration/http/endpointDefinition';

type SharedImageDto = {
  result: string;
};

type SuccessDto = BaseSuccessDto<SharedImageDto>;

export const httpGetImage = queryEndpoint({
  makeQueryKey: () => {
    return ['shared-image'] as const;
  },
  callEndpoint: (generationId: string) => {
    return backofficeClient
      .get<SuccessDto>(`/image-generations/${generationId}`)
      .then(response => {
        return response.data.data.result;
      })
      .catch(err => {
        const errorCode = getErrorCodeFromAxiosError(err);
        if (errorCode) {
          Toast.error(`ai_images.error..${errorCode}`);
        }
      });
  }
});
