import HelpIcon from '@mui/icons-material/Help';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { Button, IconButton, Link, Tooltip, Typography } from '@mui/material';
import FlexContainer from 'components/FlexContainer';
import { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import gtmIds from 'services/tracking/GTMIds';
import { withGtmInteraction } from 'services/tracking/withGtmInteraction';
import styled from 'styled-components';
import useTr from 'utils/hooks/useTr';

export const SocialBuilder = {
  Schema,
  Label,
  BackButton,
  NextButton
};

function Schema(props: {
  label?: ReactNode;
  input: ReactNode;
  backButton: ReactNode;
  nextButton?: ReactNode;
}) {
  const { label, input, backButton, nextButton } = props;

  return (
    <Root>
      <BackButtonContainer>{backButton}</BackButtonContainer>

      <InputContainer>
        {label && label}
        {input}
        {nextButton && <div>{nextButton}</div>}
      </InputContainer>
    </Root>
  );
}
const Root = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${({ theme }) => theme.spacings.medium};
`;
const BackButtonContainer = styled.div`
  margin-top: auto;
`;
const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.medium};

  width: 100%;
`;

function Label(props: { labelId: string; tooltipId?: string; linkId?: string }) {
  const { labelId, tooltipId, linkId } = props;

  const translate = useTr();

  const button = linkId ? (
    <Link component="a" href={translate(linkId)} target="_blank" rel="noreferrer">
      <HelpIcon color="action" fontSize="small" />
    </Link>
  ) : (
    <HelpIcon color="action" fontSize="small" />
  );

  return (
    <FlexContainer direction="row" gap="small">
      <Typography variant="subtitle1">
        <FormattedMessage id={labelId} />
      </Typography>
      {tooltipId && (
        <Tooltip placement="top" title={<FormattedMessage id={tooltipId} />}>
          {button}
        </Tooltip>
      )}
    </FlexContainer>
  );
}

function BackButton(props: { onBack: () => void }) {
  const { onBack } = props;

  const handleBack = () => {
    onBack();
  };

  return (
    <Tooltip title={<FormattedMessage id="common.back" />}>
      <IconButton onClick={handleBack} {...withGtmInteraction(gtmIds.socialPostBuilder.backButton)}>
        <KeyboardBackspaceIcon />
      </IconButton>
    </Tooltip>
  );
}

function NextButton(props: { disabled?: boolean; onNext: () => void }) {
  const { disabled, onNext } = props;

  const handleNext = () => onNext();

  return (
    <Button
      variant="contained"
      onClick={handleNext}
      disabled={disabled}
      {...withGtmInteraction(gtmIds.socialPostBuilder.nextButton)}
    >
      <FormattedMessage id="common.confirm" />
    </Button>
  );
}
