import { BaseSuccessDto } from 'services/backofficeIntegration/http/BaseSuccessDto';
import { backofficeClient } from 'services/backofficeIntegration/http/client/createBackofficeClient';
import { mutationEndpoint } from 'services/backofficeIntegration/http/endpointDefinition';

type SuccessDto = BaseSuccessDto<never>;

export const httpDeleteInformationCategory = mutationEndpoint({
  callEndpoint: (id: number) =>
    backofficeClient.delete<SuccessDto>(`/information-categories/${id}`).then(response => {
      return response.data;
    })
});
