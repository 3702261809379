import { Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

type Props = {
  title: string;
};

const ModalHeader = ({ title }: Props) => (
  <Root className="modal-header">
    <Headline variant="h5" className="modal-title">
      <FormattedMessage id={title} />
    </Headline>
  </Root>
);

const Root = styled.div`
  padding: ${({ theme }) => theme.spacings.xmedium};
  border: 0 none;
  padding-bottom: 0;
  padding-left: ${({ theme }) => theme.spacings.medium};
`;

const Headline = styled(Typography)`
  color: ${({ theme }) => theme.colors.primary};
`;

export default ModalHeader;
