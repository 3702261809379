import { Typography } from '@mui/material';
import { CloseModal } from 'components/modals/types';
import useModal from 'components/modals/useModal';
import FormattedMessage from 'features/i18n/FormattedMessage';
import { ModularWorkflowStepsModalStepCard } from 'features/modular-workflow/builder/ModularWorkflowStepsModalStepCard';
import {
  ApiModularWorkflowStepReference,
  isMutableStepReference
} from 'features/modular-workflow/builder/types-api';
import { useModulareWorkflowStructureStore } from 'features/modular-workflow/useModulareWorkflowStructureStore';
import { ThemedCategoryList } from 'features/theme-2024/category-list/ThemedCategoryList';
import { ModalListCardGrid } from 'features/theme-2024/modal-list-card/ModalListCardGrid';
import {
  ModalWithDividedHeaderLayout,
  TwoColumnModalBody
} from 'features/theme-2024/ModalWithDividedHeaderLayout';

export const useShowModularWorkflowStepsModal = (props: ModalProps) => {
  const { showModal } = useModal();

  return () => {
    showModal('MODULAR_WORKFLOW_BUILDER_STEPS', {
      size: 1100,
      ...props
    });
  };
};

type ModalProps = {
  onStepClick: (type: ApiModularWorkflowStepReference) => void;
};

type Props = CloseModal & ModalProps;

export const ModularWorkflowStepsModal = ({ closeModal, onStepClick }: Props) => {
  const structure = useModulareWorkflowStructureStore(state => state.structure);

  const handleStepClick = (type: ApiModularWorkflowStepReference) => {
    onStepClick(type);
    closeModal();
  };

  return (
    <ModalWithDividedHeaderLayout
      title={<FormattedMessage id="modular_workflow.builder.steps_modal.title" />}
      closeModal={closeModal}
      slots={{
        bodyContainer: TwoColumnModalBody
      }}
    >
      <ThemedCategoryList>
        <Typography variant="caption">
          <FormattedMessage id="modular_workflow.builder.steps_modal.explainer" />
        </Typography>
      </ThemedCategoryList>

      <ModalListCardGrid columns={3}>
        {structure
          .filter(step => isMutableStepReference(step.reference))
          .map(step => (
            <ModularWorkflowStepsModalStepCard
              key={step.name}
              name={step.name}
              type={step.reference}
              onClick={handleStepClick}
            />
          ))}
      </ModalListCardGrid>
    </ModalWithDividedHeaderLayout>
  );
};
