import { Chip, Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import { blue } from 'styles/styleUtils';

const useStyles = makeStyles<Theme, { size?: number; color?: string }>(() => ({
  root: {
    width: ({ size }) => size || '32px',
    height: ({ size }) => size || '32px',
    borderRadius: ({ size }) => (size ? size / 2 : '16px'),
    backgroundColor: ({ color }) => color || blue,
    color: 'white'
  },
  label: {
    padding: 0
  }
}));

type Props = {
  label?: React.ReactNode;
  size?: number;
  color?: string;
};

const RoundChip = ({ label, size, color }: Props) => {
  const classes = useStyles({ size, color });

  return <Chip label={label} classes={classes} />;
};

export default RoundChip;
