import { useMutation } from '@tanstack/react-query';
import { clearPreferredLocaleQuery } from 'features/customerPreferences/usePreferredLocaleQuery';
import { httpGetPreferences } from 'services/backofficeIntegration/http/endpoints/customer/httpGetPreferences';
import {
  CustomerPreferencesPayload,
  httpUpdatePreferences
} from 'services/backofficeIntegration/http/endpoints/customer/httpUpdatePreferences';
import { queryClient } from 'utils/ReactQueryClient';

export const useUpdateCustomerPreferences = () =>
  useMutation({
    mutationFn: async (payload: CustomerPreferencesPayload) =>
      httpUpdatePreferences.callEndpoint(payload),
    onSuccess: () => {
      invalidateGetPreferences();
      clearPreferredLocaleQuery();
    }
  });

const invalidateGetPreferences = () =>
  queryClient.invalidateQueries(httpGetPreferences.makeQueryKey());
