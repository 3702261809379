import { useQuery } from '@tanstack/react-query';
import { httpGetCommandTemplateCategories } from 'services/backofficeIntegration/http/endpoints/textGeneration/commandTemplates/httpGetCommandTemplateCategories';

export function useTemplateCategoriesQuery() {
  return useQuery({
    queryKey: httpGetCommandTemplateCategories.makeQueryKey(),
    queryFn: () => httpGetCommandTemplateCategories.callEndpoint()
  });
}

export const flashActionsCategoryAlias = 'flash_actions';
