import { Add } from '@mui/icons-material';
import { Fab, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useEnableSidebarOnMount } from 'features/pageSidebar/useSidebarStore';
import CampaignsTable from 'features/subjectLines/CampaignsTable';
import { ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import gtmIds from 'services/tracking/GTMIds';
import styled from 'styled-components';

const SubjectLinesContainer = styled.div`
  width: 100%;
  padding: 4rem 3rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;

const SubjectLinesHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
`;

const useStyles = makeStyles({
  root: {
    marginRight: '0.25rem'
  }
});

const Campaigns = (): ReactElement => {
  const classes = useStyles();
  const navigate = useNavigate();

  useEnableSidebarOnMount();

  return (
    <SubjectLinesContainer>
      <SubjectLinesHeader>
        <Typography variant="h4">
          <FormattedMessage id="subject_lines.title" />
        </Typography>
      </SubjectLinesHeader>
      <div>
        <Fab
          variant="extended"
          size="large"
          color="primary"
          onClick={() => navigate('new')}
          id={gtmIds.subjectLines.newCampaignCreated}
        >
          <Add classes={classes} />
          <FormattedMessage id="common.new" />
        </Fab>
      </div>
      <CampaignsTable />
    </SubjectLinesContainer>
  );
};

export default Campaigns;
