import { useQuery } from '@tanstack/react-query';
import LocalStorageKey from 'config/localStorageKey';
import { getCustomerTeamId } from 'features/customer/store/selectors';
import { httpGetTeamUsageHistory } from 'services/backofficeIntegration/http/endpoints/teamMembers/httpGetTeamUsageHistory';
import { useAppSelector } from 'store/hooks';

export function useGetTeamUsageHistory() {
  const teamId = useAppSelector(getCustomerTeamId);
  const apiToken = localStorage.getItem(LocalStorageKey.APIToken);

  return useQuery({
    enabled: !!apiToken && !!teamId,
    queryKey: httpGetTeamUsageHistory.makeQueryKey(teamId || 0),
    queryFn: () => httpGetTeamUsageHistory.callEndpoint(teamId || 0)
  });
}
