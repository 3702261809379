import AxiosClient from 'services/api';

import {
  GetUnsplashImagesRequest,
  GetUnsplashImagesResponse,
  GetUnsplashLimitationsResponse,
  TrackDownloadImageRequest
} from './types';

const UnsplashAPI = {
  get: (payload: GetUnsplashImagesRequest) =>
    AxiosClient.get<unknown, GetUnsplashImagesResponse>('photos/search', { params: payload }),
  trackDownloadImage: (payload: TrackDownloadImageRequest) =>
    AxiosClient.post<TrackDownloadImageRequest, GetUnsplashImagesResponse>('photos/track-download', payload),
  limitations: () =>
    AxiosClient.get<unknown, GetUnsplashLimitationsResponse>('photos/get-limitations')
};

export default UnsplashAPI;
