import styled from 'styled-components';
import { mobileWindowWidthCSS } from 'utils/hooks/useMobileVersion';

type Props = {
  src: string;
  top?: string;
  bottom?: string;
  right?: string;
  left?: string;
  none?: boolean;
};

export const Ornament = ({ src, top, bottom, right, left, none }: Props) => {
  if (none) {
    return null;
  }
  return <Root src={src} top={top} bottom={bottom} right={right} left={left} />;
};

const Root = styled.img<{ top?: string; bottom?: string; right?: string; left?: string }>`
  position: absolute;
  top: ${({ top }) => top};
  bottom: ${({ bottom }) => bottom};
  right: ${({ right }) => right};
  left: ${({ left }) => left};
  z-index: -100;

  @media (max-width: ${mobileWindowWidthCSS}) {
    display: none;
  }
`;
