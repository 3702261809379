import LocalStorageKey from 'config/localStorageKey';
import { create } from 'zustand';

type BrowserExtensionHintStoreState = {
  hasRemovedBrowserExtensionHint: boolean;
};

type BrowserExtensionHintStoreActions = {
  setHasRemovedBrowserExtensionHint: (hasRemovedBrowserExtensionHint: boolean) => void;
};

const initialState: BrowserExtensionHintStoreState = {
  hasRemovedBrowserExtensionHint:
    localStorage.getItem(LocalStorageKey.BrowserExtensionHintSeen) === 'true'
};

export const useBrowserExtensionHintStore = create<
  BrowserExtensionHintStoreState & BrowserExtensionHintStoreActions
>(set => ({
  ...initialState,
  setHasRemovedBrowserExtensionHint: hasRemovedBrowserExtensionHint => {
    set({ hasRemovedBrowserExtensionHint });
    localStorage.setItem(
      LocalStorageKey.BrowserExtensionHintSeen,
      String(hasRemovedBrowserExtensionHint)
    );
  }
}));
