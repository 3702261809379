import SearchIcon from '@mui/icons-material/Search';
import { IconButton } from '@mui/material';
import styled from 'styled-components';

type Props = {
  onClick: () => void;
};

export const TextFieldEndAdorment = ({ onClick }: Props) => {
  return (
    <IconButton onClick={onClick} size="large">
      <SearchIcon />
    </IconButton>
  );
};

export const HoverAction = styled.div`
  visibility: hidden;
`;
