import { getEditorString, getPointBefore, PlateEditor } from '@udecode/plate';
import getTextFromLocation from 'features/aiWriter/AiWriterTextEditor/utils/getTextFromLocation';
import { BasePoint, BaseRange } from 'slate';

export const getTextFromCurrentOrPreviousBlock = (
  editor: PlateEditor,
  minCharactersToExpect: number
): string => {
  const editorLocation = editor.selection;
  if (editorLocation === null) {
    return '';
  }

  // Get text from the block before the cursor
  const currentNodeText = getEditorString(editor, {
    anchor: editorLocation.anchor,
    focus: { path: editorLocation.focus.path, offset: 0 }
  });

  // Check context from current node
  const currentNodeContext = getTextFromLocation(editor, editorLocation).join(' ').trim();

  if (currentNodeText.length >= minCharactersToExpect) {
    return currentNodeContext;
  }

  // Get previous block and text until we have enough
  let searchedLocation: BaseRange | BasePoint | undefined = editorLocation;
  const normalizedTextParts = [currentNodeText];
  do {
    if (searchedLocation === undefined) {
      break;
    }

    // TODO: Doesn't work like before
    searchedLocation = getPointBefore(editor, searchedLocation, { unit: 'block' });

    if (searchedLocation && searchedLocation.offset !== 0) {
      const previousNormalizedText = getTextFromLocation(editor, searchedLocation).join(' ').trim();

      normalizedTextParts.push(previousNormalizedText);
      if (normalizedTextParts.join('').length >= minCharactersToExpect) {
        break;
      }
    }

    // eslint-disable-next-line no-constant-condition
  } while (true);

  // We're going backwards in the block order, so the array must be reversed to get the text in the correct order
  return normalizedTextParts.reverse().join(' ');
};
