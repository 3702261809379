import { useQuery } from '@tanstack/react-query';
import { setHasTeam } from 'features/customer/store/actions';
import { getIsAuthenticated } from 'features/customer/store/selectors';
import { httpGetTeamMembers } from 'services/backofficeIntegration/http/endpoints/teamMembers/httpGetTeamMembers';
import { useAppDispatch, useAppSelector } from 'store/hooks';

// Check if customer is part of a team by expecting more than 1 member in his team
export function useFetchHasCustomerTeam() {
  const dispatch = useAppDispatch();
  const isAuthenticated = useAppSelector(getIsAuthenticated);

  useQuery({
    enabled: isAuthenticated,
    queryKey: httpGetTeamMembers.makeQueryKey({}),
    queryFn: () => httpGetTeamMembers.callEndpoint({}),
    onSuccess(data) {
      dispatch(setHasTeam(data.total > 1));
    }
  });
}
