import { ELEMENT_PARAGRAPH } from '@udecode/plate-paragraph';
import { ApiModularWorkflowStepSettingsValue } from 'features/modular-workflow/builder/types-api';

export const parseValueAsDocumentJson = (value: ApiModularWorkflowStepSettingsValue) => {
  if (typeof value === 'string') {
    try {
      return JSON.parse(value);
    } catch (e) {
      return [{ type: ELEMENT_PARAGRAPH, children: [{ text: value }] }];
    }
  }

  return [{ type: ELEMENT_PARAGRAPH, children: [{ text: '' }] }];
};
