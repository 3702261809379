import { CloseModal } from 'components/modals/types';
import { CopyPasteView } from 'features/information/CopyPasteView';
import { VIEW_MODE, ViewMode } from 'features/information/InformationModal';
import { ShareUrlView } from 'features/information/ShareUrlView';
import { useState } from 'react';

type Props = {
  onCancel: () => void;
} & CloseModal;

export function ShareUrlFlow(props: Props) {
  const [url, setUrl] = useState('');
  const [extractedContent, setExtractedContent] = useState<string>();
  const [viewMode, setViewMode] = useState<ViewMode>(VIEW_MODE.SHARE_URL);

  const handleCancelClick = () => {
    props.onCancel();
  };

  const handleSetInfoContent = (value: string) => {
    setExtractedContent(value);
    setViewMode(VIEW_MODE.COPY_PASTE);
  };

  const handleBackClick = () => {
    setViewMode(VIEW_MODE.SHARE_URL);
  };

  const renderView = () => {
    switch (viewMode) {
      case VIEW_MODE.SHARE_URL:
        return (
          <ShareUrlView
            url={url}
            setUrl={setUrl}
            closeModal={props.closeModal}
            onBackClick={handleCancelClick}
            onSubmit={handleSetInfoContent}
          />
        );
      case VIEW_MODE.COPY_PASTE:
        return (
          <CopyPasteView
            preselectedContent={extractedContent}
            onBackClick={handleBackClick}
            closeModal={props.closeModal}
          />
        );

      default:
        return null;
    }
  };

  return renderView();
}
