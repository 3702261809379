import { TextareaAutosize } from '@mui/material';
import { BaseSetting } from 'features/modular-workflow/runner/settings/BaseSetting';
import { InputRendererContext } from 'features/modular-workflow/runner/settings/InputRenderer';
import { useContext } from 'react';

export const DataTypeText = () => {
  const { input, inputSettings: settings, onChange } = useContext(InputRendererContext);

  return (
    <BaseSetting>
      {elementId => (
        <TextareaAutosize
          id={elementId}
          placeholder={settings.description}
          maxRows={5}
          defaultValue={input.value as string}
          onChange={event => onChange(input, event.target.value)}
        />
      )}
    </BaseSetting>
  );
};
