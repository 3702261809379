import { Done as DoneIcon } from '@mui/icons-material';
import { Box, Chip } from '@mui/material';
import { useOnboardingContext } from 'features/onboardingJourney/newUserSurvey/OnboardingContextProvider';
import {
  SurveyQuestionDto,
  SurveyQuestionOptionDto
} from 'services/backofficeIntegration/http/dtos/SurveyQuestionsDto';
import styled from 'styled-components';

type Props = {
  question: SurveyQuestionDto;
  onChange: (option: SurveyQuestionOptionDto) => void;
};

export const SingleChoiceQuestion = ({ question, onChange }: Props) => {
  const { isAnswerOptionChecked } = useOnboardingContext();

  return (
    <Root>
      {question.options.map(option => {
        const isActive = isAnswerOptionChecked(question, option);

        return (
          <StyledChip
            key={option.id}
            label={option.option_text}
            color={isActive ? 'info' : 'default'}
            deleteIcon={isActive ? <DoneIcon /> : undefined}
            onClick={() => onChange(option)}
          />
        );
      })}
    </Root>
  );
};

const Root = styled(Box)`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: ${({ theme }) => theme.spacings.small};
`;

const StyledChip = styled(Chip)`
  &&.MuiChip-clickableColorInfo {
    color: ${({ theme }) => theme.colors.primary500};
    background-color: ${({ theme }) => theme.colors.primary50};
  }
`;
