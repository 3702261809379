import { Tooltip } from '@mui/material';
import { toggleNodeType } from '@udecode/plate-common';
import { ELEMENT_PARAGRAPH } from '@udecode/plate-paragraph';
import {
  EditorFormatButton,
  EditorFormatButtonProps,
  EditorIconFormatButton
} from 'features/aiWriter/AiWriterTextEditor/EditorIconFormatButton';
import useEditor from 'features/aiWriter/AiWriterTextEditor/hooks/useEditor';
import { trackEditorFormatAction } from 'features/aiWriter/AiWriterTextEditor/trackEditorFormatAction';
import { PlateElementFormat } from 'features/aiWriter/AiWriterTextEditor/types';
import { getElementButtonIcon } from 'features/aiWriter/AiWriterTextEditor/utils/getElementButtonIcon';
import { isBlockActive } from 'features/aiWriter/AiWriterTextEditor/utils/isBlockActive';
import React from 'react';
import { FormattedMessage } from 'react-intl';

type EditorBlockButtonProps = {
  format: PlateElementFormat;
  styleVariant?: EditorFormatButtonProps;
  onClose?: () => void;
};

export const EditorBlockButton = ({
  format,
  styleVariant = '',
  onClose
}: EditorBlockButtonProps) => {
  const editor = useEditor();
  if (!editor) {
    return null;
  }

  const isActive = isBlockActive(editor, format);

  const handleMouseDown = (event: React.MouseEvent) => {
    event.preventDefault();

    trackEditorFormatAction(format);

    toggleNodeType(editor, { activeType: format, inactiveType: ELEMENT_PARAGRAPH });
    onClose && onClose();
  };

  if (styleVariant === 'icon') {
    return (
      <Tooltip arrow placement="top" title={<FormattedMessage id={`aiWriter.toolbar.${format}`} />}>
        <EditorIconFormatButton onMouseDown={handleMouseDown} $isActive={isActive}>
          {getElementButtonIcon(format, isActive)}
        </EditorIconFormatButton>
      </Tooltip>
    );
  }

  return (
    <EditorFormatButton onMouseDown={handleMouseDown} variant="text" $isActive={isActive}>
      {getElementButtonIcon(format, isActive)}
      <FormattedMessage id={`aiWriter.toolbar.${format}`} />
    </EditorFormatButton>
  );
};
