import { createAction } from '@reduxjs/toolkit';
import { createAsyncAction } from 'utils/reduxUtils';

import { fetchImagesFailurePayload, fetchImagesSuccessPayload } from './thunks/types';

export const fetchImageInspirations = createAsyncAction<
  void,
  fetchImagesSuccessPayload,
  fetchImagesFailurePayload
>('aiWriter/fetchImageInspirations');

export const clearImagesInspirations = createAction('aiWriter/clearImagesInspirations');
