import { LogoWithSlogan } from 'components/LogoWithSlogan';
import { ComponentProps } from 'react';

type Props = {
  slogan?: ComponentProps<typeof LogoWithSlogan>['sloganKey'];
  align?: ComponentProps<typeof LogoWithSlogan>['align'];
  className?: ComponentProps<typeof LogoWithSlogan>['className'];
};

export const AuthHeader = ({ slogan, align = 'flex-start', className }: Props) => (
  <LogoWithSlogan sloganKey={slogan} align={align} className={className} />
);
