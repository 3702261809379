import { useQuery } from '@tanstack/react-query';
import Toast from 'components/toasts/Toast';
import {
  getActiveTab,
  getGenerateTextConfig,
  getGeneratingTextInEditor
} from 'features/aiWriter/store/selectors';
import useAudienceConfig from 'features/audiences/hooks/useAudienceConfig';
import { makeCurrentUserQueryKeyPrefix } from 'features/currentUser/currentUserQueries';
import { setWordsLimitReached } from 'features/customer/store/actions';
import { invalidateWordsUsageQueries } from 'features/wordsUsage/invalidateWordsUsageQueries';
import { useRef } from 'react';
import { handleGenerateTextErrorsWithHyperlink } from 'services/api/wordEmbedding/errors';
import { invalidateCustomerAllLimitationsQueries } from 'services/backofficeIntegration/http/endpoints/customer/httpGetAllLimitations';
import {
  httpGenerateTextStream,
  StreamError
} from 'services/backofficeIntegration/http/endpoints/textGeneration/httpGenerateTextStream';
import { ResponseError } from 'services/backofficeIntegration/http/processEventStream';
import { useAppDispatch, useAppSelector } from 'store/hooks';

type Options = {
  text: string;
  onChunk: (text: string) => void;
  onEnd?: () => void;
  onStart?: () => void;
};

export const useGenerateTextStreamReplacementForSelection = (options: Options) => {
  const dispatch = useAppDispatch();
  const { generateTextConfig } = useAppSelector(getActiveTab);
  const textGenerationActive = useAppSelector(getGeneratingTextInEditor);
  const { audienceId } = useAppSelector(getGenerateTextConfig);
  const { audienceModelId } = useAudienceConfig({ audienceId });

  const abortControllerRef = useRef<AbortController>();

  return useQuery({
    queryKey: [...makeCurrentUserQueryKeyPrefix(), 'generateTextStreamReplacementForSelection'],
    queryFn: async () => {
      // Starting another generation if one is already active may lead to unexpected behavior
      if (textGenerationActive) {
        return null;
      }

      const abortController = new AbortController();
      const abortSignal = abortController.signal;
      let activeTextItemId: string | undefined = undefined;

      const informationIds = generateTextConfig.informationList?.map(info => info.id);

      try {
        await httpGenerateTextStream.callStreamEndpoint({
          request: {
            text: options.text,
            audience_model_id: audienceModelId,
            output_type: 'flashscore_optimize',
            n_text_items: 1,
            n_times: 1,
            personality_id: generateTextConfig.personalityId ?? undefined,
            information_ids: informationIds
          },
          handlers: {
            onStart: e => {
              if (activeTextItemId) {
                return;
              }

              activeTextItemId = e.text_item_id;
              options.onStart?.();
            },
            onUpdate: e => {
              if (activeTextItemId !== e.text_item_id) {
                return;
              }

              options.onChunk(e.text_delta);
            },
            onEnd: () => {
              options.onEnd?.();
            }
          },
          abort: abortSignal
        });

        // Is it wasn't aborted, reset queries
        if (!abortSignal.aborted) {
          invalidateWordsUsageQueries();
          invalidateCustomerAllLimitationsQueries();
        }
      } catch (e: unknown) {
        if (e instanceof StreamError) {
          Toast.error('aiWriter.textGeneration.error.ERROR_CONVERSATION_STREAMING_FAILED');
          return;
        }

        Toast.error('aiWriter.textGeneration.error.ERROR_STREAMING');

        if (e instanceof ResponseError) {
          const data: { message?: string } = await e.response.json().catch(() => ({}));

          if (data.message === 'ERROR_ALL_WORDS_USED') {
            dispatch(setWordsLimitReached(true));
            return;
          }

          if (
            data.message &&
            [
              'ERROR_INVALID_STREAMING_TYPE',
              'ERROR_RESPONSE_NOT_SUCCESS',
              'ERROR_STREAMING'
            ].includes(data.message)
          ) {
            Toast.error(`aiWriter.textGeneration.error.${data.message}`);
            return;
          }

          if (data.message) {
            Toast.backendError(...handleGenerateTextErrorsWithHyperlink(data.message));
          }
        }
      } finally {
        abortControllerRef.current = undefined;
      }

      return null;
    }
  });
};
