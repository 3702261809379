import cx from 'classnames';
import Dot from 'components/base/Dot';
import useGetGraphLabel from 'features/aiTester/hooks/getGraphLabel';
import { getTesterActiveTab } from 'features/aiTester/store/selectors';
import { TestingLegend, TextScoreDetail } from 'features/aiTester/store/types';
import { FormattedMessage } from 'react-intl';
import { useAppSelector } from 'store/hooks';
import { formatToPercentage } from 'utils/wordUtils';

type Props = {
  label: string;
  details: TextScoreDetail[];
  hoveredField: string | null;
  legend: TestingLegend;
};

const GraphContentTooltip = ({ label, details, hoveredField, legend }: Props) => {
  const prevalenceItem = details.find(({ field }) => field === 'model_rank_score');
  let filteredDetails = details.slice().reverse();
  const { wordAttributes } = useAppSelector(getTesterActiveTab);

  const getGraphLabel = useGetGraphLabel();

  if (prevalenceItem) {
    filteredDetails = filteredDetails.filter(({ field }) => field !== 'model_rank_score');
  }

  return (
    <div>
      <strong>{label}:</strong>
      {filteredDetails.map(({ field, label, value }) => {
        return (
          <div
            className={cx(!value && 'text-strike', hoveredField === field && 'font-weight-bold')}
            key={field}
          >
            <Dot color={legend[field].color} /> {getGraphLabel(field, label)}:{' '}
            {formatToPercentage(value)}
          </div>
        );
      })}
      {prevalenceItem && (
        <div className="mt-2">
          <FormattedMessage
            id={`${
              wordAttributes.find(option => option.value === 'model_rank_score')?.option?.label
            }`}
          />{' '}
          {formatToPercentage(prevalenceItem.value)}
        </div>
      )}
    </div>
  );
};

export default GraphContentTooltip;
