import { Elements } from '@stripe/react-stripe-js';
import { loadStripe, Stripe } from '@stripe/stripe-js';
import React, { useEffect, useState } from 'react';
import useScript from 'utils/hooks/useScript';

type Props = {
  children: React.ReactElement;
};

export const StripeProvider = ({ children }: Props) => {
  const [loaded] = useScript('https://js.stripe.com/v3/');
  const [stripePromise, setStripePromise] = useState<Stripe | PromiseLike<Stripe | null> | null>(
    null
  );

  useEffect(() => {
    if (loaded) {
      setStripePromise(loadStripe(process.env.REACT_APP_STRIPE_API_KEY));
    }
  }, [loaded]);

  return <Elements stripe={stripePromise}>{children}</Elements>;
};
