import { useQuery } from '@tanstack/react-query';
import { getCurrentModelLanguageAndCountry } from 'features/aiWriter/store/selectors';
import { httpGetPersonalities } from 'services/backofficeIntegration/http/endpoints/personalities/httpGetPersonalities';
import { personalitiesQueryKeys } from 'services/backofficeIntegration/http/queryKeyFactories/personalitiesQueryKeys';
import { useAppSelector } from 'store/hooks';
import { queryClient } from 'utils/ReactQueryClient';

export function usePersonalitiesQuery(search?: string, limit?: number) {
  const { currentModelCountry: country, currentModelLanguage: language } = useAppSelector(
    getCurrentModelLanguageAndCountry
  );

  const params = {
    country,
    language,
    search,
    limit
  };

  return useQuery({
    queryKey: httpGetPersonalities.makeQueryKey(params),
    queryFn: () => httpGetPersonalities.callEndpoint(params)
  });
}

export function invalidatePersonalitiesQuery() {
  return queryClient.invalidateQueries(personalitiesQueryKeys.forList());
}
