/* eslint-disable @typescript-eslint/no-explicit-any */
import { AxisDomain, AxisScale } from 'd3-axis';
import { select as d3select, Selection } from 'd3-selection';
import { Transition } from 'd3-transition';
import { memo, useEffect, useRef } from 'react';
import { defaultGraphFont } from 'styles/styleUtils';

import buildAxis from './buildAxis';

type Props = {
  xScale: AxisScale<AxisDomain>;
  beforeDraw?: (g: Transition<SVGGElement, Record<string, unknown>, null, undefined>) => any;
  tickArguments?: unknown;
  tickValues?: unknown;
  tickFormat?: unknown;
  tickSize?: number;
  tickSizeInner?: number;
  tickSizeOuter?: number;
  tickPadding?: number;
  transform?: string;
  removeDomain?: boolean;
};

const AxisBottom = ({
  xScale,
  beforeDraw,
  tickSize,
  tickSizeOuter = 0,
  tickArguments,
  tickFormat,
  tickPadding,
  tickSizeInner,
  tickValues,
  transform = '',
  removeDomain = true
}: Props) => {
  const groupRef = useRef<SVGGElement>(null);
  const selection = useRef<Selection<SVGGElement, Record<string, unknown>, null, undefined>>();

  useEffect(() => {
    if (!selection.current) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      selection.current = d3select(groupRef.current!);
    }

    const axis = buildAxis({
      orientation: 'bottom',
      scale: xScale,
      tickSizeOuter,
      tickSizeInner,
      tickSize,
      tickPadding,
      tickFormat,
      tickArguments,
      tickValues
    });

    selection.current.transition().call(group =>
      group
        .call(axis)
        .call(g => removeDomain && g.select('.domain').remove())
        .attr('transform', transform)
        .attr('font-family', defaultGraphFont)
        .call(beforeDraw || (g => g.selectAll('.tick text').style('font-size', '16')))
    );
  });

  return <g ref={groupRef} />;
};

export default memo(AxisBottom);
