import cx from 'classnames';
import React from 'react';

type Props = {
  children?: React.ReactNode;
  className?: string;
  color?: string;
};

const Badge = ({ className, children, color = 'primary' }: Props) => {
  const classes = cx(
    {
      badge: true,
      [`badge-${color}`]: color
    },
    className
  );
  return <span className={classes}>{children}</span>;
};

export default Badge;
