/* eslint-disable react/prop-types */
import cx from 'classnames';
import React, { forwardRef } from 'react';
import styled, { css } from 'styled-components';

import CountryFlag from './CountryFlag';
import FormGroup from './FormGroup';
import Icon from './Icon';

const SmallFlag = styled(CountryFlag)`
  width: 14px;
  height: 14px;
  vertical-align: -2px;
`;

type Props = {
  position?: 'prepend' | 'append';
  icon?: string;
  flag?: string;
  classname?: string;
  valid?: boolean;
  tick?: boolean;
  invalid?: boolean;
  cross?: boolean;
  feedback?: React.ReactNode;
  label?: string;
  required?: boolean;
  clearButton?: () => void;
  labelClassName?: string;
  formGroup?: boolean; // add padding without label
} & React.ComponentPropsWithoutRef<'input'>;

const Input = forwardRef<HTMLInputElement, Props>(
  (
    {
      type = 'text',
      position = 'prepend',
      icon,
      flag,
      className,
      valid,
      tick,
      invalid,
      cross,
      feedback,
      label,
      required,
      clearButton,
      formGroup,
      labelClassName,
      ...props
    },
    ref
  ) => {
    const hasIcon = icon || flag;
    const classes = cx(
      {
        'is-valid': valid,
        'state-valid': tick,
        'is-invalid': invalid,
        'state-invalid': cross
      },
      'form-control',
      className
    );

    const cleanOnEsc = clearButton
      ? (event: React.KeyboardEvent) => event.key === 'Escape' && clearButton()
      : undefined;
    const inputElement = (
      <input type={type} ref={ref} className={classes} onKeyDown={cleanOnEsc} {...props} />
    );
    const feedbackField = feedback && <span className="invalid-feedback">{feedback}</span>;
    const inputIcon = hasIcon && (
      <span className="input-icon-addon">
        {icon && <Icon name={icon} />}
        {flag && <SmallFlag language={flag} />}
      </span>
    );

    const contents = !hasIcon ? (
      <>
        {inputElement}
        {feedbackField}
      </>
    ) : (
      <>
        <div className="input-icon">
          {position === 'prepend' && inputIcon}
          {inputElement}
          {position === 'append' && inputIcon}
          {clearButton && props.value && (
            <span
              className="input-icon-addon"
              css={css`
                pointer-events: all;
              `}
            >
              <Icon vAlign name="x" onClick={clearButton} />
            </span>
          )}
        </div>
        {feedbackField}
      </>
    );

    return label || formGroup ? (
      <FormGroup label={label} required={required} className={labelClassName}>
        {contents}
      </FormGroup>
    ) : (
      contents
    );
  }
);

export default Input;
