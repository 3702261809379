import { DotLottieReact, DotLottieReactProps } from '@lottiefiles/dotlottie-react';

type LottiePropsWithoutSrc = Omit<DotLottieReactProps, 'src'>;
type Props = {
  src: DotLottieReactProps['src'];
  options?: LottiePropsWithoutSrc;
};

export const LottieComponent = ({ src, ...rest }: Props) => {
  const defaultOptions = {
    src,
    loop: true,
    autoplay: true,
    ...(rest?.options ?? {}),
  };
  return <DotLottieReact {...defaultOptions} />;
};