import { createSelector } from '@reduxjs/toolkit';
import { memoize } from 'lodash';
import { Audience } from 'services/api/audience/types';
import { RootState } from 'store/rootReducer';

export const getUserAudiences = (state: RootState) => state.audiences.audiences;

export const getGetAudienceById = createSelector(getUserAudiences, audiences =>
  memoize((audienceId: number) => audiences.find(audience => audience.id === audienceId))
);

export const getGetAudienceByLanguage = createSelector(
  getUserAudiences,
  audiences => (language: string | undefined) =>
    audiences.filter(audience => audience.language === language)
);

export const getGetAudienceByLanguageAndCountry = createSelector(
  getUserAudiences,
  audiences =>
    (language: string | undefined, country: string | undefined): Audience[] =>
      audiences.filter(audience => audience.language === language && audience.country === country)
);
