import { createReducer } from '@reduxjs/toolkit';
import { getEditorText } from 'features/aiWriter/AiWriterTextEditor/utils/getEditorText';
import {
  changeConfigText,
  changeGenerateTextConfig,
  changeKeywords,
  changeOutputType,
  changeProjectSettings,
  changeSeoConfig,
  setInitTextLibraryCategory,
  setInspirationsCurrentStep
} from 'features/aiWriter/store/actions/config/actions';
import {
  insertEditorBlock,
  setGeneratingTextInEditor,
  setSelection,
  updateText
} from 'features/aiWriter/store/actions/editor/actions';
import {
  createFavorite,
  deleteFavorite,
  fetchFavorites
} from 'features/aiWriter/store/actions/favorites/actions';
import {
  generateTextInspirations,
  removeTextInspiration,
  rewriteTextInspirations
} from 'features/aiWriter/store/actions/inspirations/actions';
import {
  deleteProject,
  setIsSaving,
  updateProjectInBackground,
  updateProjects
} from 'features/aiWriter/store/actions/project/actions';
import {
  closeTab,
  initializeTab,
  loadTab,
  renameCurrentTab,
  setCurrentTab
} from 'features/aiWriter/store/actions/tabs/actions';
import { AiWriterState } from 'features/aiWriter/store/types';
import { defaultAiWriterState } from 'features/aiWriter/store/utils/defaults/defaultAiWriterState';
import { getCurrentTextInspirationById } from 'features/aiWriter/store/utils/getters/getCurrentTextInspirationById';
import { switchLoadingDefault } from 'store/utils/genericReducers';
import {
  closeTabReducer,
  getCurrentTab,
  getTabById,
  renameCurrentTabReducer,
  setCurrentTabReducer
} from 'store/utils/tabUtils';

import {
  disableInspirationsPanel,
  enableInspirationsPanel,
  setBlogPostOutline,
  setBlogPostTitle,
  setBuilderInitLanguage
} from './actions/builder/actions';
import { fetchHistory } from './actions/history/actions';
import { updateIsProjectPublic } from './actions/preview/actions';
import {
  _updateSeoHighlightedKeyword,
  rewriteSeoSearchResult,
  updateSeoAnalysis,
  updateSeoConfig,
  updateTermSuggestions
} from './actions/seoAnalysis/actions';
import { setIsAiWriterTourFinished, setIsUserOnboarded } from './actions/tour/actions';
import { clearImagesInspirations, fetchImageInspirations } from './actions/unsplashImages/actions';
import { aiWriterTabToProject } from './utils/mappers/aiWriterTabMappers';

const aiWriterReducer = createReducer<AiWriterState>(defaultAiWriterState, builder =>
  builder
    .addCase(setCurrentTab, setCurrentTabReducer)
    .addCase(closeTab, closeTabReducer)
    .addCase(renameCurrentTab, renameCurrentTabReducer)
    .addCase(initializeTab.request, state => state)
    .addCase(initializeTab.success, (state, { payload }) => {
      state.tabs = { ...state.tabs, [payload.id]: payload };
      state.projects = [aiWriterTabToProject(payload), ...state.projects];
      state.currentTab = payload.id;
    })
    .addCase(initializeTab.failure, state => state)
    .addCase(loadTab, (state, { payload }) => {
      state.tabs[payload.id] = payload;
      state.currentTab = payload.id;
      const tab = getTabById(payload.id, state);
      if (tab) {
        tab.tags = payload.tags;
      }
    })
    .addCase(setInspirationsCurrentStep, (state, { payload }) => {
      state.inspirationsCurrentStep = payload;
    })
    .addCase(updateText, (state, { payload: { tabId, value } }) => {
      const tab = getTabById(tabId, state);
      if (!tab) {
        return;
      }

      tab.text = value;
    })
    .addCase(insertEditorBlock, (state, { payload }) => {
      const currentTab = getCurrentTab(state);
      if (!currentTab) {
        return;
      }

      if (getEditorText(currentTab.text) === '') {
        currentTab.text = [payload];
      } else {
        currentTab.text = [...currentTab.text, payload];
      }
    })
    .addCase(generateTextInspirations.request, state => {
      const currentTab = getCurrentTab(state);
      if (!currentTab) {
        return;
      }

      currentTab.isLoading.generateTextInspirations = true;
    })
    .addCase(generateTextInspirations.success, (state, { payload }) => {
      const tab = getTabById(payload.tabId, state);
      if (!tab) {
        return;
      }

      tab.textInspirations = payload.inspirations;
      tab.isLoading.generateTextInspirations = false;
    })
    .addCase(generateTextInspirations.failure, (state, { payload }) => {
      const tab = getTabById(payload.tabId, state);
      if (!tab) {
        return;
      }

      tab.isLoading.generateTextInspirations = false;
    })
    .addCase(rewriteTextInspirations.request, (state, { payload }) => {
      const currentTab = getCurrentTab(state);
      if (!currentTab) {
        return;
      }

      const { textInspirations } = currentTab;
      const index = textInspirations.findIndex(t => t.id === payload.id);
      if (index !== -1) {
        textInspirations[index].isLoading = true;
      }
    })
    .addCase(rewriteTextInspirations.success, (state, { payload }) => {
      const currentTab = getCurrentTab(state);
      if (!currentTab) {
        return;
      }

      const { textInspirations } = currentTab;
      const index = textInspirations.findIndex(t => t.id === payload.inspirationId);

      if (index !== -1) {
        textInspirations[index].isLoading = false;
      }
      currentTab.textInspirations = [...currentTab.textInspirations, ...payload.inspirations];
    })
    .addCase(rewriteTextInspirations.failure, (state, { payload }) => {
      const currentTab = getCurrentTab(state);
      if (!currentTab) {
        return;
      }

      const { textInspirations } = currentTab;
      const index = textInspirations.findIndex(t => t.id === payload.inspirationId);
      if (index !== -1) {
        textInspirations[index].isLoading = false;
      }
    })
    .addCase(removeTextInspiration, (state, { payload: { id } }) => {
      const currentTab = getCurrentTab(state);
      if (!currentTab) {
        return;
      }

      const { textInspirations } = currentTab;
      const index = textInspirations.findIndex(t => t.id === id);

      if (index !== -1) {
        textInspirations.splice(index, 1);
      }
    })
    .addCase(changeProjectSettings, (state, { payload }) => {
      const currentTab = getCurrentTab(state);
      if (!currentTab || !currentTab.generateTextConfig) {
        return;
      }
      currentTab.embeddingModelId = payload.embeddingModelId;
      currentTab.generateTextConfig.audienceId = payload.audienceId;
      currentTab.sharingPermission = payload.sharingPermission;
    })
    .addCase(changeOutputType, (state, { payload }) => {
      const currentTab = getCurrentTab(state);
      if (!currentTab || !currentTab.generateTextConfig) {
        return;
      }

      currentTab.generateTextConfig.outputType = payload;
    })
    .addCase(changeKeywords, (state, { payload }) => {
      const currentTab = getCurrentTab(state);
      if (!currentTab || !currentTab.generateTextConfig) {
        return;
      }

      currentTab.generateTextConfig.keywords = payload;
    })
    .addCase(changeSeoConfig, (state, { payload }) => {
      const currentTab = getCurrentTab(state);
      if (!currentTab || !currentTab.generateTextConfig) {
        return;
      }

      currentTab.seoConfig = payload;
    })
    .addCase(changeConfigText, (state, { payload }) => {
      const currentTab = getCurrentTab(state);
      if (!currentTab || !currentTab.generateTextConfig) {
        return;
      }

      currentTab.generateTextConfig.text = payload;
    })
    .addCase(changeGenerateTextConfig, (state, { payload }) => {
      const currentTab = getCurrentTab(state);
      if (!currentTab || !currentTab.generateTextConfig) {
        return;
      }

      const { generateTextConfig } = currentTab;
      currentTab.generateTextConfig = {
        ...generateTextConfig,
        ...payload,
        brand: payload?.keywords2, // #tech-debt https://app.clickup.com/t/37t6awm
        audienceId: currentTab.generateTextConfig.audienceId
      };
    })
    .addCase(updateProjectInBackground.request, switchLoadingDefault(true))
    .addCase(updateProjectInBackground.success, (state, { payload }) => {
      const index = state.projects.findIndex(f => f.id === payload.id);
      if (index !== -1) {
        state.projects[index] = payload;
      }
    })
    .addCase(updateProjectInBackground.failure, switchLoadingDefault(false))
    .addCase(updateProjects, (state, { payload }) => {
      state.projects = payload;
    })
    .addCase(deleteProject.request, (state, { payload }) => {
      const index = state.projects.findIndex(f => f.id === payload.id);
      if (index !== -1) {
        state.projects[index].isLoading = true;
      }
    })
    .addCase(deleteProject.success, (state, { payload }) => {
      const index = state.projects.findIndex(f => f.id === payload.id);

      if (index !== -1) {
        delete state.tabs[payload.id];
        state.projects.splice(index, 1);
      }
    })
    .addCase(deleteProject.failure, (state, { payload }) => {
      const index = state.projects.findIndex(f => f.id === payload.id);
      if (index !== -1) {
        state.projects[index].isLoading = false;
      }
    })
    .addCase(createFavorite.request, (state, { payload: { id } }) => {
      const inspiration = getCurrentTextInspirationById({ state, id });

      if (inspiration) {
        inspiration.isLoading = true;
      }
    })
    .addCase(createFavorite.success, (state, { payload: { favorite, textInspirationId } }) => {
      state.favorites = [favorite, ...state.favorites];
      const inspiration = getCurrentTextInspirationById({ state, id: textInspirationId });

      if (inspiration) {
        inspiration.isLoading = false;
      }
    })
    .addCase(createFavorite.failure, (state, { payload: { id } }) => {
      const inspiration = getCurrentTextInspirationById({ state, id });

      if (inspiration) {
        inspiration.isLoading = false;
      }
    })
    .addCase(fetchFavorites.request, state => {
      state.areFavoritesLoading = true;
    })
    .addCase(fetchFavorites.success, (state, { payload }) => {
      state.areFavoritesLoading = false;
      state.favorites = payload;
    })
    .addCase(fetchFavorites.failure, state => {
      state.areFavoritesLoading = false;
    })
    .addCase(deleteFavorite.request, (state, { payload: { id } }) => {
      const index = state.favorites.findIndex(f => f.id === id);
      if (index !== -1) {
        state.favorites[index].isLoading = true;
      }
    })
    .addCase(deleteFavorite.success, (state, { payload: { id } }) => {
      const index = state.favorites.findIndex(f => f.id === id);
      if (index !== -1) {
        state.favorites.splice(index, 1);
      }
    })
    .addCase(deleteFavorite.failure, (state, { payload: { id } }) => {
      const index = state.favorites.findIndex(f => f.id === id);
      if (index !== -1) {
        state.favorites[index].isLoading = false;
      }
    })
    .addCase(setIsAiWriterTourFinished, (state, { payload }) => {
      state.isAiWriterTourFinished = payload;
    })
    // #tech-debt https://app.clickup.com/t/862jjp0bb
    .addCase(setIsUserOnboarded, (state, { payload }) => {
      state.isUserOnboarded = payload;
    })
    .addCase(setIsSaving, (state, { payload }) => {
      const currentTab = getCurrentTab(state);
      if (!currentTab) {
        return;
      }

      currentTab.isSaving = payload;
    })

    .addCase(fetchHistory.request, state => {
      state.isHistoryLoading = true;
    })
    .addCase(fetchHistory.success, (state, { payload }) => {
      state.isHistoryLoading = false;
      state.history = payload;
    })
    .addCase(fetchHistory.failure, state => {
      state.isHistoryLoading = false;
    })
    .addCase(updateIsProjectPublic, (state, { payload }) => {
      const tab = getTabById(payload.tabId, state);
      if (!tab) {
        return;
      }

      tab.isPublic = payload.isPublic;
    })
    .addCase(fetchImageInspirations.request, state => {
      const currentTab = getCurrentTab(state);
      if (!currentTab) {
        return;
      }

      currentTab.isLoading.fetchImages = true;
    })
    .addCase(fetchImageInspirations.success, (state, { payload }) => {
      const tab = getTabById(payload.tabId, state);
      if (!tab) {
        return;
      }

      tab.images = payload.images;
      tab.imagesNotFound = payload.imagesNotFound;
      tab.isLoading.fetchImages = false;
    })

    .addCase(fetchImageInspirations.failure, (state, { payload }) => {
      const tab = getTabById(payload.tabId, state);
      if (!tab) {
        return;
      }

      tab.isLoading.fetchImages = false;
    })
    .addCase(clearImagesInspirations, state => {
      const currentTab = getCurrentTab(state);
      if (!currentTab) {
        return;
      }

      currentTab.images = [];
    })
    .addCase(setBuilderInitLanguage, (state, { payload }) => {
      state.builder[payload.builder].language = payload.language;
    })
    .addCase(enableInspirationsPanel, state => {
      state.isInspirationsPanelDisabled = false;
    })
    .addCase(disableInspirationsPanel, state => {
      state.isInspirationsPanelDisabled = true;
    })
    .addCase(setBlogPostTitle, (state, { payload }) => {
      state.builder.blogPost.title = payload;
    })
    .addCase(setBlogPostOutline, (state, { payload }) => {
      state.builder.blogPost.outline = payload;
    })
    .addCase(updateSeoAnalysis, (state, { payload }) => {
      const tab = getCurrentTab(state);
      if (!tab) {
        return;
      }

      tab.seoAnalysis = payload;
    })
    .addCase(updateSeoConfig, (state, { payload }) => {
      const tab = getCurrentTab(state);
      if (!tab) {
        return;
      }

      tab.seoConfig = payload;
    })
    .addCase(updateTermSuggestions, (state, { payload: { termSuggestions, wordsAmount } }) => {
      const tab = getCurrentTab(state);
      if (!tab) {
        return;
      }

      if (tab.seoAnalysis) {
        tab.seoAnalysis.termSuggestions = termSuggestions;
        tab.seoAnalysis.wordsAmount = wordsAmount;
      }
    })
    .addCase(_updateSeoHighlightedKeyword, (state, { payload }) => {
      const tab = getCurrentTab(state);
      if (!tab) {
        return;
      }

      tab.seoHighlightedKeyword = payload;
    })
    .addCase(setGeneratingTextInEditor, (state, { payload }) => {
      const tab = getTabById(payload.tabId, state);
      if (!tab) {
        return;
      }
      tab.generatingTextInEditor = payload.active;
    })
    .addCase(
      rewriteSeoSearchResult,
      (state, { payload: { tabId, searchResultId, rewrittenDescription, rewrittenText } }) => {
        const tab = getTabById(tabId, state);
        if (!tab) {
          return;
        }

        const seoAnalysis = tab.seoAnalysis;
        if (!seoAnalysis) {
          return;
        }

        const searchResult = seoAnalysis.results[searchResultId];
        if (!searchResult) {
          return;
        }

        searchResult.subResult = {
          title: rewrittenText,
          description: rewrittenDescription
        };
      }
    )
    .addCase(setSelection, (state, { payload }) => {
      state.selection = payload;
    })
    .addCase(setInitTextLibraryCategory, (state, { payload }) => {
      state.initTextLibraryCategory = payload;
    })
);

export default aiWriterReducer;
