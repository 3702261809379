import { useEffect } from 'react';
import { useNavigate } from 'react-router';

const CHILD_ORIGIN = 'http://localhost:3000'; //todo move to env with proper value

export const IFRAME_ID = 'brand-voice-iframe';

type NavMessage = {
  type: 'nav';
  path: string;
};

type SizeMessage = {
  type: 'size';
  width: number;
  height: number;
};

type IframeMessage = NavMessage | SizeMessage;

export function useIframeMessageListener() {
  const navigate = useNavigate();

  useEffect(() => {
    const listener = (evt: MessageEvent) => {
      if (evt.origin !== CHILD_ORIGIN) return;

      const data: IframeMessage = evt.data;

      if (data.type === 'nav') {
        navigate(data.path);
      }

      if (data.type === 'size') {
        // if needed, set the size of the iframe
      }
    };
    window.addEventListener('message', listener);
    return () => {
      window.removeEventListener('message', listener);
    };
  }, [navigate]);
}

export function routeIframe(path: string) {
  const iFrame = document.getElementById(IFRAME_ID) as HTMLIFrameElement;
  iFrame?.contentWindow?.postMessage({ type: 'nav', path }, CHILD_ORIGIN);
}
