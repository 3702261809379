import AddButton from 'components/buttons/AddButton';
import { addTextThunk } from 'features/aiTester/store/actions/texts/thunks/addTextThunk';
import { getIsTextLimitAchieved } from 'features/aiTester/store/selectors';
import mkTesterTourClassName from 'features/aiTester/tour/utils/mkTesterTourClassName';
import React, { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { useAppDispatch, useAppSelector } from 'store/hooks';

const AddFieldButton = (): React.ReactElement => {
  const dispatch = useAppDispatch();

  const isTextLimitAchieved = useAppSelector(getIsTextLimitAchieved);

  const addContent = useCallback(() => {
    dispatch(addTextThunk());
  }, [dispatch]);

  return (
    <AddButton
      className={mkTesterTourClassName('comparison', 6)}
      disabled={isTextLimitAchieved}
      onClick={() => addContent()}
    >
      <FormattedMessage id="testing.add_field" />
    </AddButton>
  );
};

export default AddFieldButton;
