import FormGroup from 'components/base/FormGroup';
import FlexContainer from 'components/FlexContainer';
import HideableFieldTitle from 'components/forms/HideableFieldTitle';
import ManualDimensionsField from 'components/forms/ManualDimensionsField';
import SwitchField from 'components/forms/SwitchField';
import DimensionsSelect from 'components/GoalConfig/DimensionsSelect';
import WordAttributesConfig from 'components/GoalConfig/WordAttributesSelect';
import StepperModal, { ModalStep } from 'components/modals/StepperModal';
import { CloseModal } from 'components/modals/types';
import {
  getIsScoreByOpenRateAvailable,
  getTesterCurrentTabModelData,
  getTSVsForTab
} from 'features/aiTester/store/selectors';
import { testerWordAttributeOptions } from 'features/aiTester/utils/testerWordAttributeOptions';
import { FeatureEnterpriseRestriction } from 'features/features/FeatureEnterpriseRestriction';
import { getGetIsFeatureAvailable } from 'features/features/store/selectors';
import { getPricingPlan } from 'features/pricing/store/selectors';
import { FieldArray, useFormikContext } from 'formik';
import React, { ReactElement } from 'react';
import { Dimension, ManualDimension } from 'services/api/dimension/types';
import gtmIds from 'services/tracking/GTMIds';
import { useAppSelector } from 'store/hooks';
import { WordAttributeWithOption } from 'types/ReduxStateTypes';
import useTr from 'utils/hooks/useTr';

const wordAttributesField = 'wordAttributes';
const scoreByOpenRateField = 'scoreByOpenRate';
const manualDimensionsField = 'manualDimensions';
const dimensionsField = 'dimensions';

export type GoalConfigValues = {
  [wordAttributesField]: WordAttributeWithOption[];
  [scoreByOpenRateField]: boolean;
  [manualDimensionsField]: ManualDimension[];
  [dimensionsField]: Dimension[];
};

const modelStep: ModalStep[] = [
  {
    title: 'goal_score.setup.title',
    description: 'aiWriter.setup.goal.description'
  }
];

type Props = {
  initialValues: GoalConfigValues;
  onSubmit: (values: GoalConfigValues) => void;
} & CloseModal;

const GoalConfigModal = ({ closeModal, onSubmit, initialValues }: Props): ReactElement => (
  <StepperModal
    steps={modelStep}
    stepComponent={() => <GoalConfigStep />}
    initialValues={initialValues}
    tracking={{ saveButtonId: gtmIds.tester.editGoalConfig }}
    onCancel={() => {
      closeModal();
    }}
    onSubmit={values => {
      onSubmit(values);
      closeModal();
    }}
  />
);

const GoalConfigStep = (): React.ReactElement => {
  const {
    values: { wordAttributes }
  } = useFormikContext();

  const translate = useTr();

  const embeddingModelId = useAppSelector(getTesterCurrentTabModelData).id;
  const availableVariables = useAppSelector(getTSVsForTab);
  const isScoreByOpenRateAvailable = useAppSelector(getIsScoreByOpenRateAvailable);
  const pricingPlan = useAppSelector(getPricingPlan);
  const getIsFeatureAvailable = useAppSelector(getGetIsFeatureAvailable);

  const isSentimentMappersScoreAvailable = getIsFeatureAvailable('sentimentMappersScore');
  const isManualDimensionsScoreAvailable = getIsFeatureAvailable('manualDimensionsScore');
  const isDimensionsScoreAvailable = getIsFeatureAvailable('dimensionsScore');

  return (
    <FlexContainer direction="col" gap="small">
      <FieldArray name={wordAttributesField}>
        {({ push, remove }) => (
          <WordAttributesConfig
            wordAttributesOptions={testerWordAttributeOptions}
            wordAttributes={wordAttributes}
            availableVariables={availableVariables}
            disabled={!isSentimentMappersScoreAvailable}
            remove={remove}
            push={push}
          />
        )}
      </FieldArray>
      {isScoreByOpenRateAvailable && isSentimentMappersScoreAvailable && (
        <HideableFieldTitle title="testing.goal_modal.rate_by_goal_score.title">
          <FormGroup label="common.open_rate" horizontal>
            <SwitchField name={scoreByOpenRateField} />
          </FormGroup>
        </HideableFieldTitle>
      )}
      <HideableFieldTitle
        title="testing.setup.goal.manual_dimensions.title"
        description="testing.setup.goal.manual_dimensions.subtitle"
      >
        <ManualDimensionsField
          name={manualDimensionsField}
          embeddingModelId={embeddingModelId}
          disabled={!isManualDimensionsScoreAvailable}
          textFieldProps={{
            helperText: translate('testing.setup.goal.manual_dimensions.helperText')
          }}
        />
      </HideableFieldTitle>
      <HideableFieldTitle
        title="testing.setup.goal.dimensions_select.title"
        description="testing.setup.goal.dimensions_select.subtitle"
        isHidden={!isManualDimensionsScoreAvailable}
        extra={pricingPlan !== 'enterprise' && <FeatureEnterpriseRestriction />}
      >
        <DimensionsSelect
          embeddingModelId={embeddingModelId}
          name={dimensionsField}
          disabled={!isDimensionsScoreAvailable}
        />
      </HideableFieldTitle>
    </FlexContainer>
  );
};

export default GoalConfigModal;
