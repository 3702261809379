import CreateIcon from '@mui/icons-material/Create';
import DeleteIcon from '@mui/icons-material/Delete';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { Divider, IconButton, ListItemIcon, ListItemText, MenuItem } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { MenuDropdown } from 'components/MenuDropdown/MenuDropdown';
import Toast from 'components/toasts/Toast';
import FormattedMessage from 'features/i18n/FormattedMessage';
import { useCategoryRenameModal } from 'pages/brand-hub/components/CategoryRenameModal';
import { useConfirmCategoryDeletionModal } from 'pages/brand-hub/components/ConfirmCategoryDeletionModal';
import { MouseEvent } from 'react';
import { httpDeleteInformationCategory } from 'services/backofficeIntegration/http/endpoints/infomration/httpDeleteInformationCategory';
import {
  InformationDto,
  invalidateInformationListQuery
} from 'services/backofficeIntegration/http/endpoints/infomration/httpGetInformationList';
import { httpUpdateInformation } from 'services/backofficeIntegration/http/endpoints/infomration/httpUpdateInformation';
import { httpUpdateInformationCategory } from 'services/backofficeIntegration/http/endpoints/infomration/httpUpdateInformationCategory';
import styled from 'styled-components';
import { typographyPalette } from 'styles/new-theme/typographyPalette';
import { useMenu } from 'utils/hooks/useMenu';

type Props = {
  id: number;
  name: string;
  editable: boolean;

  information: InformationDto;
};

export function CategoryChip(props: Props) {
  const { anchorEl, isOpen, onTriggerClick, onMenuClose } = useMenu();

  const { mutate: removeCategory } = useMutation({
    mutationFn: () =>
      httpUpdateInformation.callEndpoint({
        ...props.information,
        informationId: props.information.id,
        category_id: null
      }),
    onSuccess: () => {
      Toast.success('flashHub.information.table.tag.menu.remove.success_message');
      invalidateInformationListQuery();
    }
  });

  const { mutate: deleteCategory, isLoading: isDeleteLoading } = useMutation({
    mutationFn: () => httpDeleteInformationCategory.callEndpoint(props.id),
    onSuccess: () => {
      Toast.success('flashHub.information.table.tag.menu.delete.success_message');
      invalidateInformationListQuery();
    }
  });

  const { mutate: updateCategory, isLoading: isUpdateLoading } = useMutation({
    mutationFn: (newName: string) =>
      httpUpdateInformationCategory.callEndpoint({ categoryId: props.id, name: newName }),
    onSuccess: () => {
      Toast.success('flashHub.information.table.tag.menu.update.success_message');
      invalidateInformationListQuery();
    }
  });

  const showConfirmCategoryDeletionModal = useConfirmCategoryDeletionModal();
  const showCategoryRenameModal = useCategoryRenameModal();

  const handleBadgeClick = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();

    onTriggerClick(e);
  };

  const handleEditClick = (e: MouseEvent<HTMLLIElement>) => {
    e.stopPropagation();
    showCategoryRenameModal({
      categoryName: props.name,
      isLoading: isUpdateLoading,
      onRename: updateCategory
    });
    onMenuClose();
  };
  const handleRemoveClick = (e: MouseEvent<HTMLLIElement>) => {
    e.stopPropagation();
    removeCategory();
    onMenuClose();
  };
  const handleDeleteClick = (e: MouseEvent<HTMLLIElement>) => {
    e.stopPropagation();
    showConfirmCategoryDeletionModal({
      onDelete: deleteCategory,
      categoryName: props.name,
      isLoading: isDeleteLoading
    });
    onMenuClose();
  };

  const trigger = (
    <CategoryBadge $editable={props.editable}>
      {props.name}
      {props.editable && (
        <MoreButton size="small" onClick={handleBadgeClick}>
          <MoreVertIcon fontSize="small" />
        </MoreButton>
      )}
    </CategoryBadge>
  );

  return (
    <MenuDropdown trigger={trigger} anchorElement={anchorEl} isOpen={isOpen} close={onMenuClose}>
      <MenuItem onClick={handleEditClick}>
        <ListItemIcon>
          <CreateIcon />
        </ListItemIcon>
        <ListItemText>
          <FormattedMessage id="flashHub.information.table.tag.menu.rename" />
        </ListItemText>
      </MenuItem>
      <MenuItem onClick={handleRemoveClick}>
        <ListItemIcon>
          <RemoveCircleOutlineIcon />
        </ListItemIcon>
        <ListItemText>
          <FormattedMessage id="flashHub.information.table.tag.menu.remove" />
        </ListItemText>
      </MenuItem>
      <Divider />
      <MenuItem onClick={handleDeleteClick}>
        <ListItemIcon>
          <StyledDeleteIcon />
        </ListItemIcon>
        <ListItemText>
          <FormattedMessage id="flashHub.information.table.tag.menu.delete" />
        </ListItemText>
      </MenuItem>
    </MenuDropdown>
  );
}

const CategoryBadge = styled.div<{ $editable: boolean }>`
  border-radius: ${({ theme }) => theme.borderRadius.one};
  background: ${({ theme }) => theme.colors.actionSelected};

  height: 32px;

  padding: ${({ theme, $editable }) =>
    `0 ${$editable ? theme.spacings.two : theme.spacings.three}`};

  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: ${({ theme }) => theme.spacings.one};
  white-space: nowrap;

  ${typographyPalette.chip.label};
`;

const MoreButton = styled(IconButton)`
  padding: 3px;
`;

const StyledDeleteIcon = styled(DeleteIcon)`
  color: ${({ theme }) => theme.colors.errorMain};
`;
