import { makeCurrentUserQueryKeyPrefix } from 'features/currentUser/currentUserQueries';
import { backofficeClient } from 'services/backofficeIntegration/http/client/createBackofficeClient';
import { queryEndpoint } from 'services/backofficeIntegration/http/endpointDefinition';
import {
  PaginatedListParams,
  PaginatedListSuccessDto
} from 'services/backofficeIntegration/http/paginatedListEndpoint';
import { queryClient } from 'utils/ReactQueryClient';
import { safeEnum } from 'utils/safeEnum';

export const enum SpecialCategories {
  ALL_CATEGORY = 'all'
}

export const enum SortingTypes {
  ALPHABETICAL_ASC = 'a_to_z',
  ALPHABETICAL_DESC = 'z_to_a',
  USAGE = 'usage',
  FAVORITES = 'favorites',
  RECENT = 'updated_at'
}

export const enum FilteringTypes {
  ANY = 'any',
  FAVORITE = 'favorite',
  BY_ME = 'by_me',
  BY_TEAM = 'by_team',
  PUBLIC = 'public'
}

export type TemplateDto = {
  author_id: number;
  id: number;
  category_id: number;
  category_name: string | null;
  is_active: boolean;
  country: string;
  language: string;
  source: string;
  title: string;
  description: string;
  template: string;
  usage_count: number;
  like_count: number;
  is_liked: 0 | 1;
  sharing_permission: 'Private';
  updated_at: string;
};

export const TemplateSharingPermission = safeEnum.make({
  private: 'private',
  team: 'team',
  public: 'public'
});

export type TemplateSharingPermission = safeEnum.infer<typeof TemplateSharingPermission>;

export type GetCommandTemplatesSuccessDto = PaginatedListSuccessDto<TemplateDto>;

export type GetCommandTemplatesParams = PaginatedListParams & {
  language?: string;
  country?: string;
  title?: string;
  category?: SpecialCategories | number;
  sorting?: SortingTypes;
  filtering?: FilteringTypes;
};

export const httpGetCommandTemplates = queryEndpoint({
  makeQueryKey: (params: GetCommandTemplatesParams) => {
    return [
      ...makeCurrentUserQueryKeyPrefix(),
      'textGeneration',
      'commandTemplates',
      'list',
      params
    ] as const;
  },
  callEndpoint: (params: GetCommandTemplatesParams) => {
    const category =
      params.category === SpecialCategories.ALL_CATEGORY ? undefined : params.category;
    // The API uses a combination of "filter" and "direction" to sort the results
    // Therefore we have to map them here
    const { direction, sort } = getSorting(params.sorting);
    const filter = getFiltering(params.filtering);

    return backofficeClient
      .get<GetCommandTemplatesSuccessDto>('/text-generation/command-templates', {
        params: {
          language: params.language,
          country: params.country,
          title: params.title,
          offset: params.offset,
          limit: params.limit,
          category,
          direction,
          filter,
          sort
        }
      })
      .then(response => replaceGbCountryCode(response.data));
  }
});

export function invalidateCommandTemplatesQuery() {
  return queryClient.invalidateQueries({
    queryKey: [...makeCurrentUserQueryKeyPrefix(), 'textGeneration', 'commandTemplates', 'list']
  });
}

function getSorting(sorting: SortingTypes | undefined): {
  direction?: string;
  sort?: string;
} {
  if (sorting === SortingTypes.ALPHABETICAL_ASC) {
    return { direction: 'asc' };
  }
  if (sorting === SortingTypes.ALPHABETICAL_DESC) {
    return { direction: 'desc' };
  }
  if (sorting === SortingTypes.USAGE) {
    return { sort: 'most_popular' };
  }
  if (sorting === SortingTypes.FAVORITES) {
    return { sort: 'favorites' };
  }
  if (sorting === SortingTypes.RECENT) {
    return { sort: 'updated_at' };
  }

  return {};
}

function getFiltering(filtering: FilteringTypes | undefined) {
  if (filtering === FilteringTypes.ANY) {
    return undefined;
  }

  return filtering;
}

/**
 * The API returns "gb" as the country code for the UK. This function replaces it with "uk".
 */
function replaceGbCountryCode(
  response: GetCommandTemplatesSuccessDto
): GetCommandTemplatesSuccessDto {
  const newData = response.data.map(item => {
    if (item.country === 'gb') {
      return { ...item, country: 'uk' };
    }
    return item;
  });

  return { ...response, data: newData };
}
