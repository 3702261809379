import { useMutation } from '@tanstack/react-query';
import { useModal } from 'components/modals';
import { ModalProps } from 'components/modals/types';
import { CustomModalProps, VIEW_MODE } from 'features/information/InformationModal';
import { httpGetInformationCategoryById } from 'services/backofficeIntegration/http/endpoints/infomration/httpGetInformationCategoryById';
import { InformationDto } from 'services/backofficeIntegration/http/endpoints/infomration/httpGetInformationList';

export const useShowInformationModal = () => {
  const { showModal } = useModal();

  return (props: CustomModalProps & ModalProps) => {
    showModal('INFORMATION', { ...props, size: 600 });
  };
};

export const useShowInformationEditModal = (): [
  boolean,
  (information: InformationDto) => Promise<void>
] => {
  const { showModal } = useModal();

  const { mutateAsync: getTag, isLoading: isTagFetching } = useMutation({
    mutationFn: httpGetInformationCategoryById.callEndpoint
  });

  return [
    isTagFetching,
    async (information: InformationDto) => {
      const tag = information.category_id
        ? await getTag({ id: information.category_id })
        : undefined;

      let initialViewMode;

      switch (information.type) {
        case 'text':
          initialViewMode = VIEW_MODE.COPY_PASTE;
          break;
        case 'file':
          initialViewMode = VIEW_MODE.UPLOAD;
          break;
        default:
          initialViewMode = VIEW_MODE.COPY_PASTE;
      }

      showModal('INFORMATION', {
        size: 600,
        initialViewMode,
        closeOnBack: true,
        preselectedInformation: information,
        preselectedTag: tag
      });
    }
  ];
};
