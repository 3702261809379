import { unnamed } from 'features/aiWriter/utils/unnamed';

export const getProjectName = (name: string, description: string) => {
  if (name === unnamed) {
    if (description === '') {
      return unnamed;
    } else {
      return description.slice(0, 20);
    }
  }

  return name;
};
