import { QueryKey } from '@tanstack/react-query';

/**
 * Please be aware that for some reason it makes a difference for TS how method
 * is defined:
 * ```
 * { callEndpoint: (params: Parameters) => Promise<Result>; }
 * ```
 * vs
 * ```
 * { callEndpoint(params: Parameters) Promise<Result>; }
 * ```
 * I have raised the issue here https://github.com/microsoft/TypeScript/issues/52306
 */
type EndpointBase<Parameters extends unknown[], Result> = {
  callEndpoint: (...params: Parameters) => Promise<Result>;
};

type QueryEndpoint<
  Parameters extends unknown[],
  Result,
  ConcreteQueryKey extends QueryKey
> = EndpointBase<Parameters, Result> & {
  makeQueryKey: (...params: Parameters) => ConcreteQueryKey;
};

/**
 * Purpose of this function is to ensure consistent endpoint definition and
 * proper type inference
 */
export function queryEndpoint<
  Parameters extends unknown[],
  Result,
  ConcreteQueryKey extends QueryKey
>(definition: QueryEndpoint<Parameters, Result, ConcreteQueryKey>) {
  return definition;
}

type MutationEndpoint<Parameters extends unknown[], Result> = EndpointBase<Parameters, Result>;

/**
 * Purpose of this function is to ensure consistent endpoint definition and
 * proper type inference
 */
export function mutationEndpoint<Parameters extends unknown[], Result>(
  definition: MutationEndpoint<Parameters, Result>
) {
  return definition;
}
