import { BaseSuccessDto } from 'services/backofficeIntegration/http/BaseSuccessDto';
import { backofficeClient } from 'services/backofficeIntegration/http/client/createBackofficeClient';
import { mutationEndpoint } from 'services/backofficeIntegration/http/endpointDefinition';

type SuccessDto = BaseSuccessDto<never>;

export const httpDeleteTag = mutationEndpoint({
  callEndpoint: (tagIds: number[]) =>
    backofficeClient
      .delete<SuccessDto>('/tags/', { data: { ids: tagIds } })
      .then(response => response.data)
});
