import { ELEMENT_DEFAULT, focusEditorEdge, PlateEditor, removeNodes } from '@udecode/plate-common';
import { ELEMENT_H1 } from '@udecode/plate-heading';
import { ELEMENT_PARAGRAPH } from '@udecode/plate-paragraph';
import { insertNodes } from '@udecode/slate';
import useEditor from 'features/aiWriter/AiWriterTextEditor/hooks/useEditor';
import { useEmptyDocumentStore } from 'features/plate/components/plate-ui/useEmptyDocumentStore';
import { ELEMENT_EMPTY_DOCUMENT } from 'features/plate/customPlugins/createEmptyDocumentPlugin';

const editorContainsOnlyEmptyDocument = (editor: PlateEditor) => {
  return editor.children.some(({ type }) => type === ELEMENT_EMPTY_DOCUMENT);
};

export const useCreateEmptyPage = () => {
  const resetEmptyDocumentStore = useEmptyDocumentStore(state => state.reset);
  const editor = useEditor();

  const createEmptyPage = () => {
    // if we already have some different content than the empty document, do nothing
    if (!editorContainsOnlyEmptyDocument(editor)) {
      return;
    }

    // Add a headline and a paragraph to the document
    // Note: We need a different node than a paragraph to prevent a positiv "is empty editor" state
    //       And if the editor is empty, we're showing the empty document element again
    insertNodes(editor, [
      {
        type: ELEMENT_H1,
        children: [{ text: '' }]
      },
      {
        type: ELEMENT_PARAGRAPH,
        children: [{ text: '' }]
      }
    ]);

    // Remove empty document node
    removeNodes(editor, {
      at: [],
      match: ({ type }) => [ELEMENT_EMPTY_DOCUMENT, ELEMENT_DEFAULT].includes(type as string)
    });

    // Wait until the node structure has been updated and focus the las node in the editor
    setTimeout(() => {
      focusEditorEdge(editor, { edge: 'end' });
    }, 100);

    resetEmptyDocumentStore();
  };

  return { createEmptyPage };
};
