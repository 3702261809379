import Toast from 'components/toasts/Toast';
import { handleScoreWordsErrors } from 'services/api/wordEmbedding/errors';
import {
  APIDimension,
  APIMapperVariable,
  ScoreTextsGoalConfig
} from 'services/api/wordEmbedding/types';

import WordEmbeddingAPI from '../../../services/api/wordEmbedding';
import { GeneratedText, ScoredText } from '../store/types';
import mkScoreTextsPayload from '../utils/mkScoreTextsPayload';

export const scoreTexts = async (
  {
    embeddingModelId,
    audienceModelId,
    inspirations
  }: {
    inspirations: GeneratedText[];
    embeddingModelId: string;
    audienceModelId?: string;
  },
  config?: {
    goalConfig: ScoreTextsGoalConfig;
    dimensions: APIDimension[];
    mappers: APIMapperVariable[];
  }
): Promise<ScoredText[]> => {
  const scoreTextPayload = mkScoreTextsPayload(
    { embeddingModelId, audienceModelId, texts: inspirations.map(i => i.text) },
    config
  );

  const response = await WordEmbeddingAPI.scoreTexts(scoreTextPayload);

  if (response.status) {
    const data = response.data
      .map((d, index) => {
        const inspiration = inspirations[index];
        return {
          id: inspiration.id,
          text: inspiration.text,
          generationId: inspiration.generationId,
          textItemId: inspiration.textItemId,
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          score: d.goal_score!
        };
      })
      .filter(s => s.score !== undefined);

    return Promise.resolve(data);
  } else {
    Toast.backendError(handleScoreWordsErrors(response.data.message));
    return Promise.reject(response.message);
  }
};
