import { createAction } from '@reduxjs/toolkit';
import { createAsyncAction } from 'utils/reduxUtils';

import {
  GenerateTextInspirationsFailurePayload,
  GenerateTextInspirationsSuccessPayload,
  RewriteTextInspirationsFailurePayload,
  RewriteTextInspirationsRequestPayload,
  RewriteTextInspirationsSuccessPayload
} from './types';

export const generateTextInspirations = createAsyncAction<
  void,
  GenerateTextInspirationsSuccessPayload,
  GenerateTextInspirationsFailurePayload
>('aiWriter/generateTextInspirations');

export const rewriteTextInspirations = createAsyncAction<
  RewriteTextInspirationsRequestPayload,
  RewriteTextInspirationsSuccessPayload,
  RewriteTextInspirationsFailurePayload
>('aiWriter/rewriteTextInspirations');

export const removeTextInspiration = createAction<{ id: string }>('aiWriter/removeTextInspiration');
