import { Button } from '@mui/material';
import { ColoredTypography } from 'components/ColoredTypography';
import FlexContainer from 'components/FlexContainer';
import FormattedMessage from 'features/i18n/FormattedMessage';
import InformationBlock from 'features/profilePage/InformationBlock';
import { ReactNode } from 'react';
import { useAppSelector } from 'store/hooks';

type Props = {
  stripePortalUrl: string;
};

const EditButton = ({ stripePortalUrl }: { stripePortalUrl: string }) => (
  <Button component="a" color="primary" href={stripePortalUrl} target="_blank">
    <FormattedMessage id="common.edit" />
  </Button>
);

const BillingInfoStatus = ({ stripePortalUrl }: Props) => {
  const { address, postalCode, city, country } = useAppSelector(
    state => state.customer.billingInfo
  );

  const InformationSection = ({
    labelKey,
    children
  }: {
    labelKey: string;
    children: ReactNode;
  }) => (
    <FlexContainer direction="row" gap="medium" alignItems="flex-start">
      <ColoredTypography variant="body1" color="blackMediumEmphasis">
        <FormattedMessage id={labelKey} />
      </ColoredTypography>
      <ColoredTypography variant="body1">{children}</ColoredTypography>
    </FlexContainer>
  );

  return (
    <InformationBlock
      headline="billing.information"
      button={<EditButton stripePortalUrl={stripePortalUrl} />}
    >
      <FlexContainer gap="medium">
        <InformationSection labelKey="common.address.address">
          <FlexContainer gap="small">
            <div>{address}</div>
            <div>
              {postalCode},&nbsp;{city}
            </div>
            {country}
          </FlexContainer>
        </InformationSection>
      </FlexContainer>
    </InformationBlock>
  );
};

export default BillingInfoStatus;
