import { getUserStripeId } from 'features/customer/store/selectors';
import { useCallback } from 'react';
import profitwellClient from 'services/profitwellClient';
import { useAppSelector } from 'store/hooks';

export default function useStartProfitwell() {
  const userStripeId = useAppSelector(getUserStripeId);
  const isProfitWellConfig = !!process.env.REACT_APP_PROFITWELL_TOKEN;

  return useCallback(() => {
    if (userStripeId && isProfitWellConfig) {
      profitwellClient.start({ userStripeId });
    }
  }, [userStripeId, isProfitWellConfig]);
}
