import { Button, Typography } from '@mui/material';
import FlexContainer from 'components/FlexContainer';
import { useModal } from 'components/modals';
import { CloseModal } from 'components/modals/types';
import FormattedMessage from 'features/i18n/FormattedMessage';
import gtmIds from 'services/tracking/GTMIds';
import { withGtmInteraction } from 'services/tracking/withGtmInteraction';
import styled from 'styled-components';

export function GptLimitReachedModal({ closeModal }: CloseModal) {
  const handleContinueClick = () => {
    closeModal();
  };

  return (
    <Root>
      <Typography variant="h6">
        <FormattedMessage id="aiWriter.limit_reached_modal.title" />
      </Typography>
      <Typography variant="body1">
        <FormattedMessage id="aiWriter.limit_reached_modal.body" />
      </Typography>

      <Footer>
        <Button
          {...withGtmInteraction(gtmIds.aiWriter.chat.gptLimitReachedContinue)}
          type="submit"
          color="primary"
          variant="contained"
          onClick={handleContinueClick}
        >
          <FormattedMessage id="common.continue" />
        </Button>
      </Footer>
    </Root>
  );
}

export const useShowGptLimitReachedModal = () => {
  const { showModal } = useModal();

  return () => {
    showModal('GPT_LIMIT_REACHED', {
      size: 480
    });
  };
};

const Root = styled(FlexContainer).attrs({ gap: 'medium' })`
  padding: ${({ theme }) => `${theme.spacings.medium} ${theme.spacings.xmedium}`};
`;

const Footer = styled.div`
  margin-top: ${({ theme }) => theme.spacings.medium};

  display: flex;
  justify-content: flex-end;
  gap: ${({ theme }) => theme.spacings.xmedium};
`;
