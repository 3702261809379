import { SeoTermSuggestions } from '../../types';

export const defaultSeoTextLength = 2000;

type SeoCommonPayload = {
  text: string;
  keyword: string;
  searchEngine: string;
  wordsAmount: number;
  countWordOccurrence: (word: string) => number;
};

export type AnalyseTextPayload = SeoCommonPayload;

export type UpdateTermSuggestionsThunkPayload = SeoCommonPayload;

export type UpdateTermSuggestionsPayload = {
  wordsAmount: number;
  termSuggestions: SeoTermSuggestions;
};

export type RewriteSeoSearchResultThunkPayload = { searchResultId: number };

export type RewriteSeoSearchResult = {
  tabId: string;
  searchResultId: number;
  rewrittenText: string;
  rewrittenDescription: string;
};
