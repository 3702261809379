import { Severity } from 'features/logging/sendLogToApi';

export const logDuringDevelopment = (logData: { severity: Severity } & Record<string, unknown>) => {
  type ConsoleLogMethod = 'info' | 'warn' | 'error';
  const defaultMethod: ConsoleLogMethod = 'info';

  const { message, severity, ...rest } = logData;
  const lowerSeverity = severity?.toLowerCase() || defaultMethod;

  let logMethod: ConsoleLogMethod = defaultMethod;
  switch (lowerSeverity) {
    case 'warn':
    case 'error':
    case 'info':
      logMethod = lowerSeverity;
      break;
    default:
      break;
  }

  // eslint-disable-next-line no-console
  console[logMethod](`[LOG][${severity}] ${message}`, rest);
};
