import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';
import LinkIcon from '@mui/icons-material/Link';
import { EditorHeaderIcon } from 'features/aiWriter/AiWriterTextEditor/EditorHeaderIcon';

import { PlateElementFormat } from '../types';
import { getIconColor } from './getIconColor';

export const getElementButtonIcon = (format: PlateElementFormat, isActive: boolean) => {
  switch (format) {
    case 'block-quote':
      return <FormatQuoteIcon color={getIconColor(isActive)} fontSize="small" />;
    case 'ul':
      return <FormatListBulletedIcon color={getIconColor(isActive)} fontSize="small" />;
    case 'h1':
      return <EditorHeaderIcon level="1" isActive={isActive} />;
    case 'h2':
      return <EditorHeaderIcon level="2" isActive={isActive} />;
    case 'h3':
      return <EditorHeaderIcon level="3" isActive={isActive} />;
    case 'ol':
      return <FormatListNumberedIcon color={getIconColor(isActive)} fontSize="small" />;
    case 'a':
      return <LinkIcon color={getIconColor(isActive)} fontSize="small" />;
  }
};
