import BlogPostSubStep from 'features/aiWriter/AiWriterSidebar/steps/textInspirations/BlogPostSubStep';
import { EditBriefSubStep } from 'features/aiWriter/AiWriterSidebar/steps/textInspirations/EditBriefSubStep';
import { FavoritesSubStep } from 'features/aiWriter/AiWriterSidebar/steps/textInspirations/FavoritesSubStep';
import { HistorySubStep } from 'features/aiWriter/AiWriterSidebar/steps/textInspirations/HistorySubStep';
import MainSubStep from 'features/aiWriter/AiWriterSidebar/steps/textInspirations/MainSubStep';
import { getInspirationsCurrentSubStep } from 'features/aiWriter/store/selectors';
import { useAppSelector } from 'store/hooks';

export const TextInspirationsStep = () => {
  const subStep = useAppSelector(getInspirationsCurrentSubStep);

  switch (subStep) {
    case 'main':
      return <MainSubStep />;
    case 'editBrief':
      return <EditBriefSubStep />;
    case 'blogPost':
      return <BlogPostSubStep />;
    case 'history':
      return <HistorySubStep />;
    case 'favorites':
      return <FavoritesSubStep />;
  }

  return null;
};
