import { ToastContainer } from 'react-toastify';
import styled, { css } from 'styled-components';

const baseToastStyle = css`
  font-family: inherit;
  border-radius: 30px;
  color: ${({ theme }) => theme.colors.commonWhiteMain};
`;

export const StyledToastContainer = styled(ToastContainer)`
  .Toastify__toast {
    &--default {
      ${baseToastStyle}
      background-color: ${({ theme }) => theme.colors.palette.pinkRose__500main};
      color: ${({ theme }) => theme.colors.textContrast};
    }

    &--info {
      ${baseToastStyle}
      background-color: ${({ theme }) => theme.colors.componentsAlertInfoBackground};
      color: ${({ theme }) => theme.colors.componentsAlertInfoColor};
    }

    &--success {
      ${baseToastStyle}
      background-color: ${({ theme }) => theme.colors.componentsAlertSuccessBackground};
      color: ${({ theme }) => theme.colors.componentsAlertSuccessColor};
    }

    &--warning {
      ${baseToastStyle}
      background-color: ${({ theme }) => theme.colors.componentsAlertWarningBackground};
      color: ${({ theme }) => theme.colors.componentsAlertWarningColor};
    }

    &--error {
      ${baseToastStyle}
      background-color: ${({ theme }) => theme.colors.componentsAlertErrorBackground};
      color: ${({ theme }) => theme.colors.componentsAlertErrorColor};
    }
  }
`;
