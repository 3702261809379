import { Button, TextField, Typography } from '@mui/material';
import { ColoredTypography } from 'components/ColoredTypography';
import FlexContainer from 'components/FlexContainer';
import { TwoColumnModalBody } from 'features/theme-2024/ModalWithDividedHeaderLayout';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { TemplateDto } from 'services/backofficeIntegration/http/endpoints/textGeneration/commandTemplates/httpGetCommandTemplates';
import styled from 'styled-components';

export function TemplateCustomization(props: {
  template: TemplateDto;
  onTemplateSelect: (template: TemplateDto) => void;
  onBackClick: () => void;
}) {
  const { template: templateBase, onTemplateSelect, onBackClick } = props;
  const [template, setTemplate] = useState(templateBase);

  function handleSend() {
    onTemplateSelect(template);
  }

  return (
    <Root>
      <div>
        <Typography variant="h6">{template.title}</Typography>
        <Typography variant="body1">
          <FormattedMessage id="aiWriter.inspirations.templates.details.subtitle" />
        </Typography>
      </div>

      <SmallColumnRightTwoColumnModalBody>
        <TextareaContainer>
          <TextField
            value={template.template}
            onChange={e => setTemplate({ ...template, template: e.target.value })}
            rows={17}
            fullWidth
            multiline
          />
        </TextareaContainer>

        <InstructionsContainer>
          <ColoredTypography variant="body2" color="blackMediumEmphasis">
            <FormattedMessage
              id="aiWriter.inspirations.templates.details.instructions"
              values={{
                br: <br />
              }}
            />
          </ColoredTypography>
        </InstructionsContainer>
      </SmallColumnRightTwoColumnModalBody>

      <FlexContainer gap="three" justifyContent="flex-end" direction="row">
        <Button variant="outlined" onClick={onBackClick}>
          <FormattedMessage id="common.back" />
        </Button>
        <Button variant="contained" onClick={handleSend}>
          <FormattedMessage id="common.send" />
        </Button>
      </FlexContainer>
    </Root>
  );
}

const Root = styled(FlexContainer).attrs({ gap: 'four' })`
  width: 100%;
`;

const TextareaContainer = styled.div`
  width: 100%;
  flex: 3 1 0;
`;

const InstructionsContainer = styled.div`
  flex: 1 0 0;
`;

const SmallColumnRightTwoColumnModalBody = styled(TwoColumnModalBody)`
  grid-template-columns: 1fr 240px;
`;
