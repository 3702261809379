import { Checkbox, FormControlLabel, FormGroup } from '@mui/material';
import { ApiModularWorkflowStepSettingsCheckbox } from 'features/modular-workflow/builder/types-api';
import { BaseSetting } from 'features/modular-workflow/runner/settings/BaseSetting';
import { InputRendererContext } from 'features/modular-workflow/runner/settings/InputRenderer';
import { uniq } from 'lodash';
import { ChangeEvent, useContext, useState } from 'react';

export const DataTypeCheckbox = () => {
  const {
    input,
    inputSettings: settings,
    onChange,
    inputErrorList
  } = useContext(InputRendererContext);
  const [selectedOptions, setSelectedOptions] = useState<string[]>(input.value as string[]);

  const handleChange = (event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
    const newSelectedOptions = checked
      ? [...selectedOptions, event.target.name]
      : selectedOptions.filter(option => option !== event.target.name);

    setSelectedOptions(newSelectedOptions);
    onChange(input, uniq(newSelectedOptions));
  };

  return (
    <BaseSetting>
      {() => (
        <FormGroup>
          {(settings as ApiModularWorkflowStepSettingsCheckbox).options.map(option => (
            <FormControlLabel
              key={option.value}
              control={
                <Checkbox
                  id={`checkbox-${option.value}`}
                  defaultChecked={(input.value as string[]).includes(option.value)}
                  name={option.value}
                  onChange={handleChange}
                />
              }
              label={option.label}
              color={inputErrorList.length > 0 ? 'error' : undefined}
            />
          ))}
        </FormGroup>
      )}
    </BaseSetting>
  );
};
