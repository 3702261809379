import { DimensionParameters } from 'services/api/wordEmbedding/types';
import { APIResponse } from 'types/APIResponse';
import { keysToSnake } from 'utils/utils';

import AxiosClient from '..';
import {
  CreateDimensionResponse,
  DeleteDimensionResponse,
  Dimension,
  GetAllDimensionsResponse,
  NewDimension,
  UpdateDimensionResponse
} from './types';

const DimensionAPI = {
  getAll: ({
    language = '',
    belongsTo = 'me',
    page = 1,
    itemsPerPage = 50,
    label,
    description,
    project,
    words,
    author,
    fetchAllLabels = false,
    sortBy,
    sortOrder
  }: DimensionParameters) =>
    AxiosClient.get<unknown, GetAllDimensionsResponse>('/settings/dimension', {
      params: keysToSnake({
        language,
        belongsTo,
        page,
        itemsPerPage,
        label,
        description,
        project,
        words,
        author,
        fetchAllLabels,
        sortBy,
        sortOrder
      })
    }),
  get: (id: number) =>
    AxiosClient.get<unknown, APIResponse<Dimension>>(`/settings/dimension/${id}`),
  create: (payload: NewDimension) =>
    AxiosClient.post<unknown, CreateDimensionResponse>('/settings/dimension', keysToSnake(payload)),
  update: (id: number, payload: Partial<NewDimension>) =>
    AxiosClient.put<unknown, UpdateDimensionResponse>(
      `/settings/dimension/${id}`,
      keysToSnake(payload)
    ),
  delete: (id: number) =>
    AxiosClient.delete<unknown, DeleteDimensionResponse>(`/settings/dimension/${id}`)
};

export default DimensionAPI;
