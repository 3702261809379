import { FormGroup } from '@mui/material';
import { TextLengthChip } from 'features/aiWriter/AiWriterSidebar/steps/textInspirations/TextLengthChip';
import { BaseSetting } from 'features/modular-workflow/builder/settings/BaseSetting';
import { InputRendererContext } from 'features/modular-workflow/builder/settings/InputRenderer';
import { ApiModularWorkflowStepSettingsLengthOfOutput } from 'features/modular-workflow/builder/types-api';
import { BuilderModularWorkflowStepInputWithValue } from 'features/modular-workflow/builder/types-builder';
import { useContext, useState } from 'react';

export const DataTypeLengthOfOutput = () => {
  const { input, inputSettings: settings, onChange } = useContext(InputRendererContext);
  const typedInput = input as BuilderModularWorkflowStepInputWithValue;

  const [selectedOption, setSelectedOption] = useState(typedInput.value as string | null);

  const handleClick = (value: string) => {
    // Remove option, if clicked on the selected option again
    if (selectedOption === value) {
      setSelectedOption(null);
      onChange(typedInput, null);
      return;
    }

    setSelectedOption(value);
    onChange(typedInput, value);
  };

  return (
    <BaseSetting>
      {() => (
        <FormGroup row={true}>
          {(settings as ApiModularWorkflowStepSettingsLengthOfOutput).options.map(option => (
            <TextLengthChip
              label={option.label}
              key={option.value}
              color={selectedOption === option.value ? 'info' : 'default'}
              onClick={() => handleClick(option.value)}
            />
          ))}
        </FormGroup>
      )}
    </BaseSetting>
  );
};
