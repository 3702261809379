import { ToggleButton, Typography } from '@mui/material';
import FlexContainer from 'components/FlexContainer';
import { CharacterLimiterWithLabel } from 'components/inputs/CharacterLimiterWithLabel';
import { AiWriterSupportedLanguageAndCountryCode } from 'features/aiWriter/aiWriterSupportedLanguageAndCountryCodes';
import { UserTonalityInput } from 'features/aiWriter/tonality/UserTonalityInput';
import { useTonalityHandlers } from 'features/aiWriter/tonality/useTonalityHandlers';
import { ComponentProps, ReactNode } from 'react';
import { GtmAttributes } from 'services/tracking/withGtmInteraction';
import styled from 'styled-components';
import useTr from 'utils/hooks/useTr';

type Props = {
  systemTonality: string[];
  userTonality: string[];
  maxLength: number;
  languageAndCountryCode: AiWriterSupportedLanguageAndCountryCode;
  label?: string;
  icon?: ReactNode;
  gtmAttributes?: GtmAttributes;
  onChange: (newSystemTonalities: string[], newUserTonalities: string[]) => void;
  onInputChange?: ComponentProps<typeof UserTonalityInput>['onInputChange'];
  size?: 'small' | 'medium';
};

export function RewriteTonalitySelect({
  systemTonality,
  userTonality,
  maxLength,
  languageAndCountryCode,
  label,
  icon,
  gtmAttributes,
  onChange,
  onInputChange,
  size = 'medium'
}: Props) {
  const translate = useTr();
  const {
    handleCustomTonalityChange,
    handlePredefinedTonalityClick,
    isDisabled,
    isSelected,
    systemTonalityOptions,
    totalAmountSelected,
    userTonalities,
    maxAmount
  } = useTonalityHandlers({
    systemTonality,
    userTonality,
    languageAndCountryCode,
    maxLength,
    onChange
  });

  return (
    <CharacterLimiterWithLabel
      label={label ?? translate('common.tonality')}
      icon={icon}
      titleVariant="subtitle1"
      length={totalAmountSelected}
      maxLength={maxLength}
    >
      <OptionsContainer gap="small" direction="row">
        {systemTonalityOptions.map(option => (
          <Option
            key={option.value}
            value={option.value}
            selected={isSelected(option)}
            disabled={isDisabled(option)}
            onClick={() => handlePredefinedTonalityClick(option)}
            {...gtmAttributes}
            data-tonality={option.value}
          >
            <OptionText variant="body2">{option.label}</OptionText>
          </Option>
        ))}
      </OptionsContainer>

      <UserTonalityInput
        maxAmount={maxAmount}
        userTonalities={userTonalities}
        onChange={handleCustomTonalityChange}
        onInputChange={onInputChange}
        size={size}
      />
    </CharacterLimiterWithLabel>
  );
}

const OptionText = styled(Typography)`
  width: 126px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
`;

const OptionsContainer = styled(FlexContainer)`
  flex-wrap: wrap;
`;

const Option = styled(ToggleButton)`
  .MuiToggleButton-label {
    text-transform: none;
  }

  &&& {
    &.Mui-selected {
      background-color: ${({ theme }) => theme.colors.primary};
      color: ${({ theme }) => theme.colors.white};
    }
    border: 0;
  }
  height: 28px;
  width: 48%;
`;
