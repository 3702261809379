import { Divider, Typography } from '@mui/material';
import securityImage from 'assets/brandHub/security.png';
import FormattedMessage from 'features/i18n/FormattedMessage';
import { GermanFlag } from 'features/information/GermanFlag';
import styled from 'styled-components';
import useTr from 'utils/hooks/useTr';

export function TermOfServiceModalFooter() {
  const translate = useTr();

  return (
    <Root>
      <Divider />

      <FooterBox>
        <TilesGrid>
          <SecurityTileBox>
            <img src={securityImage} alt="Security" />

            <TruncatedSmallText $maxLines={3} title={translate('term_of_service.claim_1')}>
              <FormattedMessage id="term_of_service.claim_1" />
            </TruncatedSmallText>
          </SecurityTileBox>

          <TileBox>
            <TruncatedSmallText $maxLines={2} title={translate('term_of_service.claim_2')}>
              <FormattedMessage id="term_of_service.claim_2" />
            </TruncatedSmallText>

            <GermanFlag />
          </TileBox>

          <TileBox>
            <TruncatedSmallText $maxLines={2} title={translate('term_of_service.claim_3')}>
              <FormattedMessage id="term_of_service.claim_3" />
            </TruncatedSmallText>

            <GermanFlag />
          </TileBox>
        </TilesGrid>

        <NoteBox>
          <FormattedMessage id="term_of_service.claim_4" />
        </NoteBox>
      </FooterBox>
    </Root>
  );
}

const Root = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.two};
  margin-top: auto;
`;

const FooterBox = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: ${({ theme }) => theme.spacings.two};
  padding-inline: ${({ theme }) => theme.spacings.two};
`;

const TilesGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: ${({ theme }) => theme.spacings.one};
  align-items: center;
`;

const TileBox = styled.div`
  display: flex;
  padding: ${({ theme }) => theme.spacings.one} ${({ theme }) => theme.spacings.two};
  height: 40px;

  border: 1px solid ${({ theme }) => theme.colors.commonBlackFocusvisible};
  border-radius: ${({ theme }) => theme.borderRadius.four};

  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  gap: ${({ theme }) => theme.spacings.one};
`;

const SecurityTileBox = styled(TileBox)`
  flex-direction: row;
  justify-content: space-between;
`;

const TruncatedSmallText = styled(Typography)<{ $maxLines: number }>`
  font-size: 6px;
  line-height: 166%;
  color: ${({ theme }) => theme.colors.textSecondary};

  display: -webkit-box;
  -webkit-line-clamp: ${({ $maxLines }) => $maxLines};
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

const NoteBox = styled(Typography)`
  font-size: 10px;
  line-height: 14px;
  color: ${({ theme }) => theme.colors.textDisabled};
`;
