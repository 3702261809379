import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { useMutation } from '@tanstack/react-query';
import CountryFlag from 'components/base/CountryFlag';
import { MenuDropdown } from 'components/MenuDropdown/MenuDropdown';
import { MenuDropdownItem } from 'components/MenuDropdown/MenuDropdownItem';
import { NewAppCookiesKey } from 'config/NewAppCookiesKey';
import { getIsApiCustomer } from 'features/customer/store/selectors';
import FormattedMessage from 'features/i18n/FormattedMessage';
import { getSelectableLanguages } from 'features/i18n/localizations';
import { setLanguage } from 'features/language/store/reducer';
import { getCurrentLanguage } from 'features/language/store/selectors';
import { AppLanguage } from 'features/language/store/types';
import Cookies from 'js-cookie';
import { useCallback } from 'react';
import CustomerAPI from 'services/api/customer';
import gtmIds from 'services/tracking/GTMIds';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import styled from 'styled-components';
import useDropdown from 'utils/hooks/useDropdown';

export const LanguageButton = (props: { onClose: () => void }) => {
  const { anchorElement, isOpen, open, close } = useDropdown();
  const dispatch = useAppDispatch();
  const currentLanguage = useAppSelector(getCurrentLanguage);
  const isApiCustomer = useAppSelector(getIsApiCustomer);

  const selectableLanguages = getSelectableLanguages();

  const { mutate: updateCustomer } = useMutation(async (locale: AppLanguage) => {
    await CustomerAPI.update({ payload: { locale } });
  });

  const handleClick = useCallback(
    (language: AppLanguage) => {
      dispatch(setLanguage(language));
      Cookies.set(NewAppCookiesKey.appLanguage, language);

      if (!isApiCustomer) {
        updateCustomer(language);
      }
    },
    [dispatch, isApiCustomer, updateCustomer]
  );

  const handleCLose = () => {
    close();
    props.onClose();
  };

  return (
    <MenuDropdown
      trigger={
        <MenuDropdownItem
          gtmId={gtmIds.sidebar.accountMenuItem}
          icon={<StyledIcon fontSize="small" />}
          onClick={open}
        >
          <FormattedMessage id="layout.sidebar.actions.language" />
        </MenuDropdownItem>
      }
      isOpen={isOpen}
      anchorElement={anchorElement}
      close={handleCLose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right'
      }}
      transformOrigin={{
        vertical: 'bottom',
        horizontal: 'left'
      }}
    >
      {selectableLanguages.map(language => (
        <MenuDropdownItem
          key={language}
          onClick={() => handleClick(language)}
          onClose={handleCLose}
          icon={<CountryFlag country={language} language={language} />}
          selected={currentLanguage === language}
        >
          <FormattedMessage id={`layout.sidebar.actions.language.${language}`} />
        </MenuDropdownItem>
      ))}
    </MenuDropdown>
  );
};

const StyledIcon = styled(ArrowRightIcon)`
  color: ${({ theme }) => theme.colors.componentsIconInactive};
`;
