import { Typography } from '@mui/material';
import { resendVerificationEmail } from 'features/authentication/resendVerificationEmail';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { resetButton } from 'styles/styledComponents/resets';

type Props = {
  email: string;
  children?: React.ReactNode;
};

export const ResendVerificationEmailButton = ({ email, children }: Props) => {
  return (
    <Root onClick={() => resendVerificationEmail(email)}>
      <Content>
        {children ?? <FormattedMessage id="register.success.verificationEmail.resend" />}
      </Content>
    </Root>
  );
};

const Root = styled.button`
  ${resetButton};
  padding: 0;
`;

const Content = styled(Typography)`
  && {
    color: ${({ theme }) => theme.colors.primary};
  }

  &:hover {
    color: ${({ theme }) => theme.colors.primaryHover};
  }
`;
