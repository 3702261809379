
import SVGText from '../../../components/ChartElements/SVGText';
import useTr from '../../../utils/hooks/useTr';
import { GoalConfigStatusGraph } from '../store/types';

const getDescription = (
  { goalConfig, dimensions, manualDimensions }: GoalConfigStatusGraph,
  tr: ReturnType<typeof useTr>
) => {
  const results = [];
  goalConfig.forEach(param => {
    if (param.value) {
      const sign = param.value > 0 ? '+' : '-';
      results.push(`${sign + tr(param.key)}`);
    }
  });

  if (manualDimensions.key) {
    results.push(`${tr(manualDimensions.key)}: ${manualDimensions.value}`);
  }

  if (dimensions.key) {
    results.push(`${tr(dimensions.key)}: ${dimensions.value}`);
  }

  return results.sort((a, b) => a.length - b.length);
};

type Props = {
  goalConfig: GoalConfigStatusGraph;
  scale: number;
};

const GoalStatus = ({ goalConfig, scale = 1.2 }: Props) => {
  const tr = useTr();

  return (
    <g id="goal-status" transform={`scale(${scale})`}>
      <SVGText textAnchor="end" x="0" dy="0">
        {getDescription(goalConfig, tr).map(text => (
          <tspan x="0" dy="1.25em" key={text.split(':')[0]}>
            {text}
          </tspan>
        ))}
      </SVGText>
    </g>
  );
};

export default GoalStatus;
