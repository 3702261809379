import { APIResponse } from 'types/APIResponse';

import AxiosClient from '..';
import { Audience, GetAllAudiencesResponse, NewAudience } from './types';

const AudienceAPI = {
  getAll: (name?: string) =>
    AxiosClient.get<unknown, GetAllAudiencesResponse>('/settings/audience', {
      params: { name }
    }),
  get: (id: number) => AxiosClient.get<unknown, APIResponse<Audience>>(`/settings/audience/${id}`),
  create: (payload: NewAudience) =>
    AxiosClient.post<unknown, APIResponse<Audience>>('/settings/audience', payload),
  update: (id: number, payload: NewAudience) =>
    AxiosClient.put<unknown, APIResponse<Audience>>(`/settings/audience/${id}`, payload),
  delete: (id: number) => AxiosClient.delete(`/settings/audience/${id}`)
};

export default AudienceAPI;
