import styled from 'styled-components';

const UnauthPanel = styled.div`
  width: auto;
  height: auto;
  flex-direction: column-reverse;
  margin: auto;
  border-radius: 3px;
  box-shadow: 4px 4px 4px 0 rgba(0, 0, 0, 0.075);
  display: flex;

  @media (min-width: 992px) {
    width: 940px;
    min-height: 624px;
    flex-direction: row;
  }
`;

export default UnauthPanel;
