import { Button, ButtonProps, CircularProgress } from '@mui/material';

type Props = ButtonProps & {
  loading?: boolean;
};

/**
 * @deprecated Please use MUI LoadingButton instead
 */
export const LoadingButton = ({ loading, disabled, startIcon, children, ...rest }: Props) => {
  return (
    // Start icon breaks for loading and disabled state, so it's better to hide it for those states
    <Button {...rest} disabled={loading || disabled} startIcon={!loading && !disabled && startIcon}>
      {loading ? <CircularProgress size={20} /> : children}
    </Button>
  );
};
