import { TypographyPalette } from 'styled-components';

export const typographyPalette: TypographyPalette = {
  h1: {
    fontSize: '96px',
    textDecoration: 'none',
    fontFamily: 'Metropolis',
    fontWeight: 700,
    fontStyle: 'normal',
    fontStretch: 'normal',
    letterSpacing: '-1.5px',
    lineHeight: '112.032px',
    textTransform: 'none'
  },
  h2: {
    fontSize: '60px',
    textDecoration: 'none',
    fontFamily: 'Metropolis',
    fontWeight: 700,
    fontStyle: 'normal',
    fontStretch: 'normal',
    letterSpacing: '-0.5px',
    lineHeight: '72px',
    textTransform: 'none'
  },
  h3: {
    fontSize: '48px',
    textDecoration: 'none',
    fontFamily: 'Metropolis',
    fontWeight: 700,
    fontStyle: 'normal',
    fontStretch: 'normal',
    letterSpacing: '0px',
    lineHeight: '56.016px',
    textTransform: 'none'
  },
  h4: {
    fontSize: '34px',
    textDecoration: 'none',
    fontFamily: 'Metropolis',
    fontWeight: 700,
    fontStyle: 'normal',
    fontStretch: 'normal',
    letterSpacing: '0.25px',
    lineHeight: '41.99px',
    textTransform: 'none'
  },
  h5: {
    fontSize: '24px',
    textDecoration: 'none',
    fontFamily: 'Metropolis',
    fontWeight: 700,
    fontStyle: 'normal',
    fontStretch: 'normal',
    letterSpacing: '0px',
    lineHeight: '32.016px',
    textTransform: 'none'
  },
  h6: {
    fontSize: '20px',
    textDecoration: 'none',
    fontFamily: 'Metropolis',
    fontWeight: 700,
    fontStyle: 'normal',
    fontStretch: 'normal',
    letterSpacing: '0.15px',
    lineHeight: '32px',
    textTransform: 'none'
  },
  body1: {
    fontSize: '16px',
    textDecoration: 'none',
    fontFamily: 'Metropolis',
    fontWeight: 400,
    fontStyle: 'normal',
    fontStretch: 'normal',
    letterSpacing: '0.15px',
    lineHeight: '24px',
    textTransform: 'none'
  },
  body1Bold: {
    fontSize: '16px',
    textDecoration: 'none',
    fontFamily: 'Metropolis',
    fontWeight: 700,
    fontStyle: 'normal',
    fontStretch: 'normal',
    letterSpacing: '0.15px',
    lineHeight: '24px',
    textTransform: 'none'
  },
  body2: {
    fontSize: '14px',
    textDecoration: 'none',
    fontFamily: 'Metropolis',
    fontWeight: 400,
    fontStyle: 'normal',
    fontStretch: 'normal',
    letterSpacing: '0.17px',
    lineHeight: '20.02px',
    textTransform: 'none'
  },
  subtitle1: {
    fontSize: '16px',
    textDecoration: 'none',
    fontFamily: 'Metropolis',
    fontWeight: 400,
    fontStyle: 'normal',
    fontStretch: 'normal',
    letterSpacing: '0.15px',
    lineHeight: '28px',
    textTransform: 'none'
  },
  subtitle2: {
    fontSize: '14px',
    textDecoration: 'none',
    fontFamily: 'Metropolis',
    fontWeight: 500,
    fontStyle: 'normal',
    fontStretch: 'normal',
    letterSpacing: '0.1px',
    lineHeight: '21.98px',
    textTransform: 'none'
  },
  overline: {
    fontSize: '12px',
    textDecoration: 'none',
    fontFamily: 'Metropolis',
    fontWeight: 400,
    fontStyle: 'normal',
    fontStretch: 'normal',
    letterSpacing: '1px',
    lineHeight: '31.92px',
    textTransform: 'uppercase'
  },
  caption: {
    fontSize: '12px',
    textDecoration: 'none',
    fontFamily: 'Metropolis',
    fontWeight: 400,
    fontStyle: 'normal',
    fontStretch: 'normal',
    letterSpacing: '0.4px',
    lineHeight: '19.92px',
    textTransform: 'none'
  },
  alert: {
    title: {
      fontSize: '16px',
      textDecoration: 'none',
      fontFamily: 'Metropolis',
      fontWeight: 500,
      fontStyle: 'normal',
      fontStretch: 'normal',
      letterSpacing: '0.15px',
      lineHeight: '24px',
      textTransform: 'none'
    },
    description: {
      fontSize: '14px',
      textDecoration: 'none',
      fontFamily: 'Metropolis',
      fontWeight: 400,
      fontStyle: 'normal',
      fontStretch: 'normal',
      letterSpacing: '0.15px',
      lineHeight: '20.02px',
      textTransform: 'none'
    }
  },
  avatar: {
    initials: {
      fontSize: '16px',
      textDecoration: 'none',
      fontFamily: 'Metropolis',
      fontWeight: 500,
      fontStyle: 'normal',
      fontStretch: 'normal',
      letterSpacing: '0.14px',
      lineHeight: '20px',
      textTransform: 'none'
    }
  },
  badge: {
    label: {
      fontSize: '12px',
      textDecoration: 'none',
      fontFamily: 'Metropolis',
      fontWeight: 500,
      fontStyle: 'normal',
      fontStretch: 'normal',
      letterSpacing: '0.14px',
      lineHeight: '20px',
      textTransform: 'none'
    }
  },
  bottomNavigation: {
    activeLabel: {
      fontSize: '14px',
      textDecoration: 'none',
      fontFamily: 'Metropolis',
      fontWeight: 400,
      fontStyle: 'normal',
      fontStretch: 'normal',
      letterSpacing: '0.4px',
      lineHeight: '23.24px',
      textTransform: 'none'
    }
  },
  button: {
    large: {
      fontSize: '15px',
      textDecoration: 'none',
      fontFamily: 'Metropolis',
      fontWeight: 500,
      fontStyle: 'normal',
      fontStretch: 'normal',
      letterSpacing: '0.46px',
      lineHeight: '26px',
      textTransform: 'none'
    },
    medium: {
      fontSize: '14px',
      textDecoration: 'none',
      fontFamily: 'Metropolis',
      fontWeight: 500,
      fontStyle: 'normal',
      fontStretch: 'normal',
      letterSpacing: '0.4px',
      lineHeight: '24px',
      textTransform: 'none'
    },
    small: {
      fontSize: '13px',
      textDecoration: 'none',
      fontFamily: 'Metropolis',
      fontWeight: 500,
      fontStyle: 'normal',
      fontStretch: 'normal',
      letterSpacing: '0.46px',
      lineHeight: '22px',
      textTransform: 'none'
    }
  },
  chip: {
    label: {
      fontSize: '13px',
      textDecoration: 'none',
      fontFamily: 'Metropolis',
      fontWeight: 500,
      fontStyle: 'normal',
      fontStretch: 'normal',
      letterSpacing: '0.16px',
      lineHeight: '18px',
      textTransform: 'none'
    }
  },
  datepicker: {
    currentMonth: {
      fontSize: '16px',
      textDecoration: 'none',
      fontFamily: 'Metropolis',
      fontWeight: 400,
      fontStyle: 'normal',
      fontStretch: 'normal',
      letterSpacing: '0.15px',
      lineHeight: '24px',
      textTransform: 'none'
    }
  },
  input: {
    label: {
      fontSize: '12px',
      textDecoration: 'none',
      fontFamily: 'Metropolis',
      fontWeight: 400,
      fontStyle: 'normal',
      fontStretch: 'normal',
      letterSpacing: '0.15px',
      lineHeight: '12px',
      textTransform: 'none'
    },
    value: {
      fontSize: '16px',
      textDecoration: 'none',
      fontFamily: 'Metropolis',
      fontWeight: 400,
      fontStyle: 'normal',
      fontStretch: 'normal',
      letterSpacing: '0.15px',
      lineHeight: '24px',
      textTransform: 'none'
    },
    helper: {
      fontSize: '12px',
      textDecoration: 'none',
      fontFamily: 'Metropolis',
      fontWeight: 400,
      fontStyle: 'normal',
      fontStretch: 'normal',
      letterSpacing: '0.4px',
      lineHeight: '19.92px',
      textTransform: 'none'
    }
  },
  list: {
    subheader: {
      fontSize: '14px',
      textDecoration: 'none',
      fontFamily: 'Metropolis',
      fontWeight: 500,
      fontStyle: 'normal',
      fontStretch: 'normal',
      letterSpacing: '0.1px',
      lineHeight: '48px',
      textTransform: 'none'
    }
  },
  menu: {
    itemDefault: {
      fontSize: '16px',
      textDecoration: 'none',
      fontFamily: 'Metropolis',
      fontWeight: 400,
      fontStyle: 'normal',
      fontStretch: 'normal',
      letterSpacing: '0.15px',
      lineHeight: '24px',
      textTransform: 'none'
    },
    itemDense: {
      fontSize: '14px',
      textDecoration: 'none',
      fontFamily: 'Metropolis',
      fontWeight: 400,
      fontStyle: 'normal',
      fontStretch: 'normal',
      letterSpacing: '0.17px',
      lineHeight: '24px',
      textTransform: 'none'
    }
  },
  table: {
    header: {
      fontSize: '14px',
      textDecoration: 'none',
      fontFamily: 'Metropolis',
      fontWeight: 500,
      fontStyle: 'normal',
      fontStretch: 'normal',
      letterSpacing: '0.17px',
      lineHeight: '24px',
      textTransform: 'none'
    }
  },
  tooltip: {
    label: {
      fontSize: '10px',
      textDecoration: 'none',
      fontFamily: 'Metropolis',
      fontWeight: 500,
      fontStyle: 'normal',
      fontStretch: 'normal',
      letterSpacing: '0px',
      lineHeight: '14px',
      textTransform: 'none'
    }
  },
  dataGrid: {
    aggregationColumnHeaderLabel: {
      fontSize: '12px',
      textDecoration: 'none',
      fontFamily: 'Metropolis',
      fontWeight: 500,
      fontStyle: 'normal',
      fontStretch: 'normal',
      letterSpacing: '0.15px',
      lineHeight: '12px',
      textTransform: 'uppercase'
    }
  }
};
