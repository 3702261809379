import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Menu, { MenuProps } from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { alpha, styled } from '@mui/material/styles';
import FormattedMessage from 'features/i18n/FormattedMessage';

type Props = {
  onDelete: () => void;
  onEdit: () => void;
  disabled?: boolean;
  anchorEl: HTMLElement | null;
  isOptionsOpen: boolean;
  handleCloseOption: () => void;
};

export default function TemplateMenu({
  anchorEl,
  isOptionsOpen,
  handleCloseOption,
  onDelete,
  onEdit,
  disabled
}: Props) {
  const handleDelete = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    event.stopPropagation();
    onDelete();
    handleCloseOption();
  };

  const handleEdit = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    event.stopPropagation();
    onEdit();
    handleCloseOption();
  };

  return (
    <StyledMenu
      id="demo-customized-menu"
      anchorEl={anchorEl}
      open={isOptionsOpen}
      onClose={handleCloseOption}
    >
      <MenuItem onClick={handleEdit} disabled={disabled} disableRipple>
        <EditIcon />
        <FormattedMessage id="common.edit" />
      </MenuItem>

      <MenuItem onClick={handleDelete} disableRipple>
        <DeleteIcon />
        <FormattedMessage id="common.delete" />
      </MenuItem>
    </StyledMenu>
  );
}
const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right'
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right'
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0'
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        marginRight: theme.spacing(1.5)
      },
      '&:active': {
        backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity)
      }
    }
  }
}));
