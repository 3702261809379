import cuid from 'cuid';

import { SavedTextInspiration, TextInspiration } from '../../types';

export const aiWriterTextInspirationToApi = (
  inspiration: TextInspiration
): SavedTextInspiration => ({
  id: inspiration.id,
  text: inspiration.text,
  generationId: inspiration.generationId,
  textItemId: inspiration.textItemId
});

export const aiWriterTextInspirationFromApi = (
  inspiration: string | SavedTextInspiration
): TextInspiration => {
  // #tech-debt: https://app.clickup.com/t/862jvgcy4
  if (typeof inspiration === 'string') {
    return {
      id: cuid(),
      text: inspiration,
      isLoading: false
    };
  } else {
    return {
      id: inspiration.id ?? cuid(),
      text: inspiration.text,
      isLoading: false,
      generationId: inspiration.generationId,
      textItemId: inspiration.textItemId
    };
  }
};
