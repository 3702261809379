import FlexContainer from 'components/FlexContainer';
import {
  SeoSearchResultOriginalTile,
  SeoSearchResultRewrittenTile
} from 'features/aiWriter/AiWriterSidebar/steps/seoAnalysis/common/SeoSearchResult/SeoSearchResultTile';
import { SeoTextAnalysisResult } from 'features/aiWriter/store/types';

type Props = {
  result: SeoTextAnalysisResult;
};

export function SeoSearchResult({ result }: Props) {
  return (
    <FlexContainer gap="medium">
      <SeoSearchResultOriginalTile
        title={result.title}
        description={result.description}
        rank={result.rank}
        wordCount={result.wordcount}
        link={result.link}
        domain={result.domain}
      />
      {result.subResult && (
        <SeoSearchResultRewrittenTile
          title={result.subResult.title}
          description={result.subResult.description}
        />
      )}
    </FlexContainer>
  );
}
