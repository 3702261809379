/* eslint-disable react/prop-types */
import cx from 'classnames';
import React, { forwardRef } from 'react';

import Icon from './Icon';

export interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  size?: 'sm' | 'lg';
  outline?: boolean;
  link?: boolean;
  block?: boolean;
  color?: string;
  square?: boolean;
  pill?: boolean;
  icon?: string;
  loading?: boolean;
  isDropdownToggle?: boolean;
  isOption?: boolean;
  selected?: boolean;
  uppercase?: boolean;
  hidden?: boolean;
  text?: boolean;
}

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      size = '',
      outline,
      link,
      block,
      className,
      children,
      disabled,
      color = !link ? 'secondary' : '',
      square,
      pill,
      icon,
      loading,
      isDropdownToggle,
      isOption,
      type = 'button',
      selected,
      uppercase,
      hidden,
      text,
      ...props
    },
    ref
  ) => {
    const classes = cx(
      {
        btn: true,
        [`btn-${size}`]: !!size,
        ['btn-block']: block,
        [`btn-outline-${color}`]: outline && !!color,
        ['btn-link']: link,
        disabled,
        [`btn-${color}`]: !!color && !outline,
        'btn-square': square,
        'btn-pill': pill,
        'btn-icon': !children,
        'btn-loading': loading,
        'dropdown-toggle': isDropdownToggle,
        'btn-option': isOption,
        'btn-selected': selected,
        'btn-uppercase': uppercase,
        'btn-hidden': hidden,
        'btn-text': text
      },
      className
    );

    return (
      // eslint-disable-next-line react/button-has-type
      <button {...props} className={classes} disabled={disabled} type={type} ref={ref}>
        {icon && <Icon name={icon} className={children != null ? 'mr-2' : ''} />}
        {children}
      </button>
    );
  }
);

export default Button;
