import { Button, MenuItem, Select, TextField, Typography } from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select/SelectInput';
import FlexContainer from 'components/FlexContainer';
import { AppLanguage } from 'features/language/store/types';
import { ModularWorkflowCreationViewSetupTeamsSelect } from 'features/modular-workflow/builder/ModularWorkflowCreationViewSetupTeamsSelect';
import { ModularWorkflowOrderInputField } from 'features/modular-workflow/builder/ModularWorkflowOrderInputField';
import {
  ImageResult,
  ModularWorkflowThumbnailUploadField
} from 'features/modular-workflow/builder/ModularWorkflowThumbnailUploadField';
import { ModularWorkflowTranslationSwitch } from 'features/modular-workflow/builder/ModularWorkflowTranslationSwitch';
import {
  ApiModularWorkflowSharingPermission,
  ApiModularWorkflowSharingPermissions,
  createStepFromStructure
} from 'features/modular-workflow/builder/types-api';
import { useEnsureUniqueStepOutputAlias } from 'features/modular-workflow/builder/useEnsureUniqueStepOutputAlias';
import { useModulareWorkflowCreationStore } from 'features/modular-workflow/builder/useModulareWorkflowCreationStore';
import { useGetModulareWorkflowStepStructureByReference } from 'features/modular-workflow/useModulareWorkflowStructureStore';
import { ChangeEvent, SyntheticEvent, useEffect, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import useTr from 'utils/hooks/useTr';

export const maxWorkflowNameLength = 255;

export const ModularWorkflowCreationViewSetup = () => {
  const translate = useTr();
  const workflow = useModulareWorkflowCreationStore(state => state.workflow);
  const updateWorkflow = useModulareWorkflowCreationStore(state => state.updateWorkflow);
  const setCurrentView = useModulareWorkflowCreationStore(state => state.setCurrentView);
  const currentLanguage = useModulareWorkflowCreationStore(state => state.currentLanguage);
  const setCurrentLanguage = useModulareWorkflowCreationStore(state => state.setCurrentLanguage);
  const getStepStructure = useGetModulareWorkflowStepStructureByReference();
  const ensureUniqueStepOutputAlias = useEnsureUniqueStepOutputAlias();

  const stepTextInput = getStepStructure('text_input');
  const stepPutItAllTogether = getStepStructure('put_it_all_together');
  const stepDestination = getStepStructure('destination');

  const isValid = useMemo(
    () =>
      workflow.name_localized[AppLanguage.English].length > 0 &&
      workflow.description_localized[AppLanguage.English].length > 0,
    [workflow.description_localized, workflow.name_localized]
  );

  const canSwitchLanguage = useMemo(
    () =>
      workflow.name_localized[AppLanguage.English].length > 0 &&
      workflow.description_localized[AppLanguage.English].length > 0,
    [workflow.description_localized, workflow.name_localized]
  );

  const isSharingWithTeamsActive = useMemo(() => {
    return workflow.sharing_permission === ApiModularWorkflowSharingPermissions.other_workspaces;
  }, [workflow.sharing_permission]);

  const handleWorkflowNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    updateWorkflow({
      name_localized: {
        ...workflow.name_localized,
        [currentLanguage]: event.target.value
      }
    });
  };

  const handleWorkflowDescriptionChange = (event: ChangeEvent<HTMLInputElement>) => {
    updateWorkflow({
      description_localized: {
        ...workflow.description_localized,
        [currentLanguage]: event.target.value
      }
    });
  };

  const handleWorkflowSharingChange = (event: SelectChangeEvent) => {
    const sharingValue = event.target.value as ApiModularWorkflowSharingPermission;

    updateWorkflow({
      sharing_permission: sharingValue
    });
  };

  const handleSelectTeam = (event: SyntheticEvent, value: number) => {
    updateWorkflow({
      sharing_permission_team_ids: [value]
    });
  };

  const handleWorkflowThumbnailChange = (thumbnail: ImageResult) => {
    if (!thumbnail) {
      updateWorkflow({
        thumbnail: null
      });
      return;
    }

    const textDecoder = new TextDecoder();
    const thumbnailString =
      typeof thumbnail === 'string' ? thumbnail : textDecoder.decode(thumbnail);

    updateWorkflow({
      thumbnail: thumbnailString
    });
  };

  const handleWorkflowOrderChange = (event: ChangeEvent<HTMLInputElement>) => {
    const orderValue = Number(event.target.value);

    updateWorkflow({
      order: isNaN(orderValue) || orderValue === 0 ? null : orderValue
    });
  };

  const handleBeforeSwitchLanguage = (language: AppLanguage) => {
    // If we switch from english to another, prepare the languages as a copy of english
    if (language === AppLanguage.English) {
      return;
    }

    updateWorkflow({
      name_localized: {
        ...workflow.name_localized,
        [language]:
          workflow.name_localized[language] || workflow.name_localized[AppLanguage.English]
      },
      description_localized: {
        ...workflow.description_localized,
        [language]:
          workflow.description_localized[language] ||
          workflow.description_localized[AppLanguage.English]
      }
    });
  };

  const handleStartClick = () => {
    // Ensure to copy over the english values to the other languages
    updateWorkflow({
      name_localized: {
        ...workflow.name_localized,
        [AppLanguage.German]:
          workflow.name_localized[AppLanguage.German] ||
          workflow.name_localized[AppLanguage.English],
        [AppLanguage.Spanish]:
          workflow.name_localized[AppLanguage.Spanish] ||
          workflow.name_localized[AppLanguage.English]
      },
      description_localized: {
        ...workflow.description_localized,
        [AppLanguage.German]:
          workflow.description_localized[AppLanguage.German] ||
          workflow.description_localized[AppLanguage.English],
        [AppLanguage.Spanish]:
          workflow.description_localized[AppLanguage.Spanish] ||
          workflow.description_localized[AppLanguage.English]
      }
    });

    setCurrentView('edit');
    setCurrentLanguage(AppLanguage.English);
  };

  // Create initial steps
  useEffect(() => {
    if (workflow.steps.length > 0 || !stepTextInput || !stepDestination || !stepPutItAllTogether) {
      return;
    }

    // We use direct update here to define the order by ourself
    updateWorkflow({
      steps: [
        ensureUniqueStepOutputAlias(createStepFromStructure(stepTextInput, 1)),
        ensureUniqueStepOutputAlias(createStepFromStructure(stepPutItAllTogether, 2)),
        ensureUniqueStepOutputAlias(createStepFromStructure(stepDestination, 3))
      ]
    });
  }, [
    ensureUniqueStepOutputAlias,
    stepDestination,
    stepPutItAllTogether,
    stepTextInput,
    updateWorkflow,
    workflow.steps.length
  ]);

  return (
    <Content>
      <Typography variant="h5">
        <FormattedMessage id="flashHub.overview.tab.workflows.create.title" />
      </Typography>

      <ModularWorkflowTranslationSwitch
        canSwitchLanguage={canSwitchLanguage}
        onBeforeSwitchLanguage={handleBeforeSwitchLanguage}
      />

      <FieldContainer>
        <Typography variant="subtitle2">
          <FormattedMessage id="flashHub.overview.tab.workflows.create.name.label" />
        </Typography>

        <TextField
          id="name-input"
          placeholder={translate('flashHub.overview.tab.workflows.create.name.placeholder')}
          required={true}
          fullWidth={true}
          inputProps={{ maxLength: maxWorkflowNameLength }}
          value={workflow.name_localized[currentLanguage]}
          onChange={handleWorkflowNameChange}
        />
      </FieldContainer>

      <FieldContainer>
        <Typography variant="subtitle2">
          <FormattedMessage id="flashHub.overview.tab.workflows.create.description.label" />
        </Typography>

        <TextField
          id="description-input"
          placeholder={translate('flashHub.overview.tab.workflows.create.description.placeholder')}
          multiline={true}
          minRows={2}
          required={true}
          fullWidth={true}
          value={workflow.description_localized[currentLanguage]}
          onChange={handleWorkflowDescriptionChange}
        />
      </FieldContainer>

      <FieldContainer>
        <Typography variant="subtitle2">
          <FormattedMessage id="flashHub.overview.tab.workflows.create.sharing.label" />
        </Typography>

        <Select
          defaultValue={ApiModularWorkflowSharingPermissions.workspace}
          fullWidth={true}
          onChange={handleWorkflowSharingChange}
        >
          <MenuItem value={ApiModularWorkflowSharingPermissions.workspace}>
            <FormattedMessage id="flashHub.overview.tab.workflows.create.sharing.workspace" />
          </MenuItem>
          <MenuItem value={ApiModularWorkflowSharingPermissions.my}>
            <FormattedMessage id="flashHub.overview.tab.workflows.create.sharing.private" />
          </MenuItem>
          <MenuItem value={ApiModularWorkflowSharingPermissions.public}>
            <FormattedMessage id="flashHub.overview.tab.workflows.create.sharing.public" />
          </MenuItem>
          <MenuItem value={ApiModularWorkflowSharingPermissions.other_workspaces}>
            <FormattedMessage id="flashHub.overview.tab.workflows.create.sharing.other_workspaces" />
          </MenuItem>
        </Select>
      </FieldContainer>

      {isSharingWithTeamsActive && (
        <FieldContainer>
          <ModularWorkflowCreationViewSetupTeamsSelect
            enabled={isSharingWithTeamsActive}
            handleSelectTeam={handleSelectTeam}
          />
        </FieldContainer>
      )}

      <FieldContainer>
        <Typography variant="subtitle2">
          <FormattedMessage id="flashHub.overview.tab.workflows.create.thumbnail.label" />
        </Typography>

        <ModularWorkflowThumbnailUploadField
          value={workflow.thumbnail}
          onThumbnailChange={handleWorkflowThumbnailChange}
        />
      </FieldContainer>

      <FieldContainer>
        <Typography variant="subtitle2">
          <FormattedMessage id="flashHub.overview.tab.workflows.create.order.label" />
        </Typography>

        <ModularWorkflowOrderInputField
          id="description-input"
          value={workflow.order || undefined}
          onChange={handleWorkflowOrderChange}
        />
      </FieldContainer>

      <Button
        variant="contained"
        color="primary"
        fullWidth={true}
        disabled={!isValid}
        onClick={handleStartClick}
      >
        <FormattedMessage id="common.start" />
      </Button>
    </Content>
  );
};

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: ${({ theme }) => theme.spacings.medium};
  width: 100%;
  max-width: 400px;
`;

const FieldContainer = styled(FlexContainer).attrs({ gap: 'small' })`
  width: 100%;
`;
