import { getTokensFromText } from 'features/aiTester/utils/getTokensFromText';
import useAudienceConfig from 'features/audiences/hooks/useAudienceConfig';

export default function useGetIsSubjectLineAvailable({ audienceId }: { audienceId: number }) {
  const { model } = useAudienceConfig({ audienceId });

  return async (subjectLine: string) => {
    const tokenizedSubjectLines = await getTokensFromText(subjectLine, model);

    return tokenizedSubjectLines.some(token => token.status === 'AVAILABLE');
  };
}
