import { AuthHeader } from 'components/auth/AuthHeader';
import FlexContainer from 'components/FlexContainer';
import { ComponentProps, ReactElement, ReactNode } from 'react';
import styled from 'styled-components';
import { mobileWindowWidthCSS } from 'utils/hooks/useMobileVersion';

type Props = {
  slogan?: ComponentProps<typeof AuthHeader>['slogan'];
  ornaments?: ReactNode;
  children: ReactElement;
};

export const AuthCenteredPanel = ({ slogan, ornaments, children }: Props) => (
  <Root>
    <FlexContainer gap="three">
      <AuthHeader align="center" slogan={slogan} />

      {children}
    </FlexContainer>

    {ornaments}
  </Root>
);

const Root = styled.div`
  margin: auto;

  @media (max-width: ${mobileWindowWidthCSS}) {
    margin: ${({ theme }) => `${theme.spacings.large} ${theme.spacings.large}`};
  }
`;
