import { Close, RemoveCircleOutline } from '@mui/icons-material';
import React from 'react';
import styled from 'styled-components';
import { NewThemeConfig } from 'styles/new-theme/newThemeConfig';

import { IconButton, IconButtonSize } from '../IconButton';

type Props = {
  id?: string;
  className?: string;
  circle?: boolean;
  tooltip?: React.ReactNode;
  size?: IconButtonSize;
  color?: keyof NewThemeConfig['colors'];

  onClick: () => void;
};

const DeleteIconButton = ({
  id,
  className,
  circle,
  tooltip,
  size = 'small',
  color,
  onClick
}: Props) => (
  <StyledIconButton
    id={id}
    className={className}
    size={size}
    icon={circle ? <RemoveCircleOutline fontSize={size} /> : <Close fontSize={size} />}
    tooltip={tooltip}
    onClick={onClick}
    $color={color}
  />
);

const StyledIconButton = styled(IconButton)<{ $color?: Props['color'] }>`
  color: ${({ theme, $color }) =>
    $color ? theme.colors[$color] : theme.colors.componentsIconDefault};
`;

export default DeleteIconButton;
