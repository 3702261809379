import { ColoredTypography } from 'components/ColoredTypography';
import FlexContainer from 'components/FlexContainer';
import WordStatisticsPill, {
  WordStatisticsColorVariant
} from 'features/aiWriter/AiWriterSidebar/steps/seoAnalysis/common/WordStatisticsPill';
import {
  getWordStatisticsBackgroundColor,
  getWordStatisticsBorder
} from 'features/aiWriter/AiWriterSidebar/steps/seoAnalysis/wordStatisticsColor';
import { TermSuggestion } from 'features/aiWriter/store/types';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

type Props = {
  less: TermSuggestion[];
  more: TermSuggestion[];
  perfect: TermSuggestion[];
};

const SeoStatisticsSection = ({ less, more, perfect }: Props) => {
  return (
    <FlexContainer gap="medium">
      <FlexContainer gap="large" direction="row">
        <FlexContainer gap="small" direction="row" alignItems="center">
          <Dot $color="primary" />
          <ColoredTypography color="blackMediumEmphasis">
            <FormattedMessage id="aiWriter.inspirations.seoAnalysis.statistics.more_often" />
          </ColoredTypography>
        </FlexContainer>
        <FlexContainer gap="small" direction="row" alignItems="center">
          <Dot $color="secondary" />
          <ColoredTypography color="blackMediumEmphasis">
            <FormattedMessage id="aiWriter.inspirations.seoAnalysis.statistics.less_often" />
          </ColoredTypography>
        </FlexContainer>
        <FlexContainer gap="small" direction="row" alignItems="center">
          <Dot $color="green" />
          <ColoredTypography color="blackMediumEmphasis">
            <FormattedMessage id="aiWriter.inspirations.seoAnalysis.statistics.perfect" />
          </ColoredTypography>
        </FlexContainer>
      </FlexContainer>
      <FlexContainer gap="small" flexWrap="wrap" direction="row">
        {more.map(suggestion => (
          <WordStatisticsPill key={suggestion.word} suggestion={suggestion} color="primary" />
        ))}
        {less.map(suggestion => (
          <WordStatisticsPill key={suggestion.word} suggestion={suggestion} color="secondary" />
        ))}
        {perfect.map(suggestion => (
          <WordStatisticsPill key={suggestion.word} suggestion={suggestion} color="green" />
        ))}
      </FlexContainer>
    </FlexContainer>
  );
};

const Dot = styled.div<{ $color: WordStatisticsColorVariant }>`
  width: 12px;
  height: 12px;

  ${({ $color }) => getWordStatisticsBackgroundColor($color)};
  ${({ $color }) => getWordStatisticsBorder($color)};

  border-radius: 50%;
`;

export default SeoStatisticsSection;
