import { Button, TextField } from '@mui/material';
import FlexContainer from 'components/FlexContainer';
import { useModal } from 'components/modals';
import ModalHeader from 'components/modals/ModalHeader';
import { CloseModal } from 'components/modals/types';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { maxProjectNameLength } from 'store/utils/tabUtils';
import styled from 'styled-components';

type CustomProps = {
  oldName: string;
  onUpdate: (name: string) => void;
};

type Props = CloseModal & CustomProps;

export function RenameProjectModal({ oldName, closeModal, onUpdate }: Props) {
  const [projectName, setProjectName] = useState(oldName);
  const [error, setError] = useState(false);

  const isDisabled = projectName.length === 0 || error || projectName === oldName;

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.currentTarget.value;
    if (value.length > maxProjectNameLength) {
      setError(true);
      return;
    }

    if (error && value.length <= maxProjectNameLength) {
      setError(false);
    }

    setProjectName(value);
  };

  const handleUpdate = () => {
    onUpdate(projectName);
    closeModal();
  };

  return (
    <FlexContainer gap="medium">
      <ModalHeader title="common.rename" />
      <Body>
        <TextField
          fullWidth
          value={projectName}
          onChange={handleChange}
          error={error}
          helperText={
            error && (
              <FormattedMessage
                id="aiWriter.project_overview.projects_table.rename_modal.error"
                values={{
                  count: maxProjectNameLength
                }}
              />
            )
          }
        />
        <ButtonContainer>
          <Button onClick={closeModal}>
            <FormattedMessage id="common.cancel" />
          </Button>
          <Button variant="contained" color="primary" onClick={handleUpdate} disabled={isDisabled}>
            <FormattedMessage id="common.ok" />
          </Button>
        </ButtonContainer>
      </Body>
    </FlexContainer>
  );
}

const Body = styled(FlexContainer).attrs({
  gap: 'medium'
})`
  padding: ${({ theme }) => theme.spacings.medium};
  padding-top: 0;
`;

const ButtonContainer = styled(FlexContainer).attrs({
  gap: 'medium',
  direction: 'row'
})`
  margin-left: auto;
`;

export const useRenameProjectModal = () => {
  const { showModal } = useModal();

  return (props: CustomProps) => {
    showModal('RENAME_PROJECT', {
      ...props,
      size: 480
    });
  };
};
