import React, { useCallback } from 'react';
import { Row as RowType } from 'react-table';

import CellWithTooltip from './CellWithTooltip';
import { Cell, TableRowElement } from './TableComponents';

type Props<TData extends object> = {
  row: RowType<TData>;
  style?: React.CSSProperties;
  selected?: boolean;
  className?: string;
  rowEvents?: {
    onClick?: (row: RowType<TData>) => void;
    onMouseEnter?: (row: RowType<TData>) => void;
    onMouseLeave?: (row: RowType<TData>) => void;
  };
};

function TableRow<TData extends object>({ row, style, rowEvents = {}, className }: Props<TData>) {
  const onClick = useCallback(() => rowEvents.onClick && rowEvents.onClick(row), [rowEvents, row]);

  const onMouseEnter = useCallback(
    () => rowEvents.onMouseEnter && rowEvents.onMouseEnter(row),
    [row, rowEvents]
  );

  const onMouseLeave = useCallback(
    () => rowEvents.onMouseLeave && rowEvents.onMouseLeave(row),
    [row, rowEvents]
  );

  return (
    <TableRowElement
      className={className}
      style={style}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      selected={row.isSelected}
    >
      {row.cells.map(cell => (
        // eslint-disable-next-line react/jsx-key
        <Cell
          {...cell.getCellProps({
            style: cell.column.cellStyles && cell.column.cellStyles(cell)
          })}
        >
          {cell.column.ellipsis ? (
            <CellWithTooltip>
              <span>{cell.render('Cell')}</span>
            </CellWithTooltip>
          ) : (
            cell.render('Cell')
          )}
        </Cell>
      ))}
    </TableRowElement>
  );
}

export default TableRow;
