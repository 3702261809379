import AutocompleteTagsInput from 'components/AutocompleteInput/AutocompleteTagsInput';
import Dimmer from 'components/base/Dimmer';
import FormGroup from 'components/base/FormGroup';
import AlertError from 'components/forms/AlertError';
import InputField from 'components/forms/InputField';
import TextareaField from 'components/forms/TextareaField';
import ProjectsComboBox from 'components/layout/toolbar/modals/components/ProjectsComboBox';
import { getNewAudienceId } from 'components/layout/toolbar/modals/components/useLoadAudience';
import FormikModal from 'components/modals/FormikModal';
import { CloseModal } from 'components/modals/types';
import { addDimension } from 'features/dimensions/store/actions';
import CountryPicker from 'features/embeddingModels/CountryPicker';
import { addList } from 'features/lists/store/actions';
import { ErrorMessage } from 'formik';
import { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { Audience } from 'services/api/audience/types';
import { NewDimension } from 'services/api/dimension/types';
import { APIModel } from 'services/api/embeddingModels/types';
import { NewList } from 'services/api/list/types';
import { RootState } from 'store/rootReducer';
import { WordTag } from 'types/WordTag';
import useTr from 'utils/hooks/useTr';
import { promisifySagaThunk } from 'utils/utils';
import { wordTagsSchema } from 'utils/validationRules';
import { object, string } from 'yup';

const initialValues = {
  words: [] as WordTag[],
  label: '',
  description: '',
  project: null as Audience | null,
  model: null as APIModel | null
};

type ConnectedProps = {
  title: string;
  isLoading: boolean;
  hideProject: boolean;
  addElement: (newElement: NewList | NewDimension) => Promise<number | Record<string, string>>;
};

type Props = CloseModal & ConnectedProps;

const AddWithWordsModal = ({ title, isLoading, closeModal, addElement, hideProject }: Props) => {
  const tr = useTr();
  const validationSchema = useMemo(
    () =>
      object().shape({
        words: wordTagsSchema,
        label: string().required(tr('lists.list_name_required')),
        model: object().required()
      }),
    [tr]
  );

  return (
    <FormikModal
      isLoading={isLoading}
      onSubmit={async (
        { description, words, label, project, model },
        { setErrors, setSubmitting }
      ) => {
        if (model) {
          const projectId = await getNewAudienceId(project);
          const idOrError = await addElement({
            words: words.map(({ word }) => word),
            label,
            language: model.language,
            description,
            projectId
          });

          if (typeof idOrError !== 'number') {
            setErrors(idOrError);
            setSubmitting(false);
          } else {
            closeModal();
          }
        }
      }}
      initialValues={initialValues}
      validationSchema={validationSchema}
      title={title}
      onCancelClick={closeModal}
    >
      {({ setFieldValue, values, submitForm }) => (
        <>
          <ErrorMessage name="words">
            {error => (
              <AlertError>
                <FormattedMessage id={error} />
              </AlertError>
            )}
          </ErrorMessage>
          <InputField name="label" label="common.name" autoFocus required />
          <FormGroup label="common.country" required>
            <CountryPicker onChange={model => setFieldValue('model', model)} />
            <ErrorMessage name="model">
              {error => <span className="invalid-feedback">{error}</span>}
            </ErrorMessage>
          </FormGroup>
          <FormGroup required label="common.words">
            <Dimmer active={!values.model}>
              <AutocompleteTagsInput
                value={values.words}
                onChange={words => setFieldValue('words', words)}
                model={values.model ? values.model.id : ''}
                eventHandler={submitForm}
              />
            </Dimmer>
          </FormGroup>
          <TextareaField name="common.description" label="common.description" />
          {!hideProject ? (
            <ProjectsComboBox onChange={value => setFieldValue('project', value)} />
          ) : null}
        </>
      )}
    </FormikModal>
  );
};

export const AddListWithWordsModal = connect(
  (state: RootState) => ({
    isLoading: state.lists.isLoading,
    title: 'lists.add_new_list',
    hideProject: true
  }),
  {
    addElement: (newList: NewList) => promisifySagaThunk(addList.request, newList)
  }
)(AddWithWordsModal);

export const AddDimensionWithWordsModal = connect(
  (state: RootState) => ({
    isLoading: state.dimensions.isLoading,
    title: 'dimensions.add_new_dimension',
    hideProject: true
  }),
  {
    addElement: (newDimension: NewDimension) =>
      promisifySagaThunk(addDimension.request, newDimension)
  }
)(AddWithWordsModal);
