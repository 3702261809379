import { aiWriterProjectFromApi } from 'features/aiWriter/store/utils/mappers/aiWriterProjectMappers';
import { makeCurrentUserQueryKeyPrefix } from 'features/currentUser/currentUserQueries';
import { ApiAiWriterProject } from 'services/api/aiWriter/types';
import { BaseSuccessDto } from 'services/backofficeIntegration/http/BaseSuccessDto';
import { backofficeClient } from 'services/backofficeIntegration/http/client/createBackofficeClient';
import { queryEndpoint } from 'services/backofficeIntegration/http/endpointDefinition';

type SuccessDto = BaseSuccessDto<ApiAiWriterProject[]>;

export const httpGetRecentProjects = queryEndpoint({
  makeQueryKey: () => {
    return [...makeCurrentUserQueryKeyPrefix(), 'projects', 'ai-writer', 'recent'] as const;
  },
  callEndpoint: () => {
    return backofficeClient.get<SuccessDto>('/projects/ai-writer/recent').then(response => {
      return response.data.data.map(aiWriterProjectFromApi);
    });
  }
});
