import { FieldArray } from 'formik';
import { Dimension } from 'services/api/dimension/types';

import FormGroup from '../base/FormGroup';
import DimensionsAutocomplete from '../GoalConfig/DimensionsAutocomplete';

type Props = {
  name?: string;
  modelLanguage: string;
  disabled?: boolean;
};

const DimensionsField = ({ name = 'dimensions', modelLanguage, disabled }: Props) => {
  return (
    <FieldArray name={name}>
      {({ push, remove, form }) => (
        <div>
          <FormGroup>
            <DimensionsAutocomplete
              disabled={disabled}
              language={modelLanguage}
              onChange={(value: Dimension | null) => {
                if (value) {
                  push(value);
                }
              }}
              onRemove={remove}
              formResults={form.values.dimensions}
            />
          </FormGroup>
        </div>
      )}
    </FieldArray>
  );
};

export default DimensionsField;
