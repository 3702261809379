import { getErrorCodeFromAxiosError } from 'features/teamInvitation/getErrorCodeFromAxiosError';
import { useLoginAfterTeamInvitation } from 'features/teamInvitation/useLoginAfterTeamInvitation';
import { useTeamInvitationInfo } from 'features/teamInvitation/useTeamInvitationInfo';
import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

const useQueryParameters = () => {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
};

export const useTeamInvitation = () => {
  const params = useQueryParameters();

  const emailParam = params.get('email');
  const tokenParam = params.get('token');

  const handleLogin = useLoginAfterTeamInvitation();

  const {
    data: teamInvitationInfo,
    isLoading: isLoadingTeamInvitation,
    error: teamInvitationError
  } = useTeamInvitationInfo({
    token: tokenParam || ''
  });

  const teamInvitationErrorCode = getErrorCodeFromAxiosError(teamInvitationError);

  return {
    tokenParam,
    emailParam,

    handleLogin,

    teamInvitationInfo: {
      data: teamInvitationInfo?.data,
      isLoading: isLoadingTeamInvitation,
      hasError: !!teamInvitationError,
      errorCode: teamInvitationErrorCode
    }
  };
};
