type Field =
  | 'name'
  | 'email'
  | 'password'
  | 'confirm_password'
  | 'tos'
  | 'card_owner'
  | 'country'
  | 'model'
  | 'output_type'
  | 'text'
  | 'audience';

export const getValidationForField = (field: Field) => `validation.${field}.required`;
