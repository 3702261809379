import { RunnerModularWorkflow } from 'features/modular-workflow/runner/types-runner';
import { ApiExtendedResponse } from 'services/api/types';
import { backofficeClient } from 'services/backofficeIntegration/http/client/createBackofficeClient';
import { mutationEndpoint } from 'services/backofficeIntegration/http/endpointDefinition';

type ResponseDto = ApiExtendedResponse<RunnerModularWorkflow>;

export const httpRunModularWorkflow = mutationEndpoint({
  callEndpoint: (workflowId: number) => {
    return backofficeClient.post<ResponseDto>('/workflow-runs', {
      workflow: {
        id: workflowId
      }
    });
  }
});
