import { SidebarItem } from 'features/pageSidebar/sidebarItems/SidebarItem';
import { useSidebarStore } from 'features/pageSidebar/useSidebarStore';
import { ComponentProps, MouseEvent } from 'react';
import { useLocation } from 'react-router';

type SidebarItemProps = ComponentProps<typeof SidebarItem>;

type Props = SidebarItemProps & {
  to: NonNullable<SidebarItemProps['to']>;
};

export const SidebarNavItem = ({ to, ...props }: Props) => {
  const location = useLocation();
  const isActive = to.startsWith(location.pathname);
  const toggleSidebar = useSidebarStore(state => state.toggleSidebar);

  const handleOnClick = (event: MouseEvent<HTMLElement>) => {
    toggleSidebar();

    props.onClick?.(event);
  };

  return <SidebarItem isActive={isActive} to={to} onClick={handleOnClick} {...props} />;
};
