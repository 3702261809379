import { Typography } from '@mui/material';
import { ColoredTypography } from 'components/ColoredTypography';
import { forwardRef, HTMLAttributes, ReactNode } from 'react';
import styled from 'styled-components';
import { actionableCard } from 'styles/styledComponents/mixins';
import { objectToCss } from 'utils/objectToCss';

type Props = HTMLAttributes<HTMLDivElement> & {
  isActive?: boolean;
  children: ReactNode;
  title: ReactNode;
  titleActions?: ReactNode;
  subtitle?: ReactNode;
  caption?: ReactNode;
  footer?: ReactNode;
};

export const ModalListCard = forwardRef<HTMLDivElement, Props>(
  (
    { children, title, titleActions, subtitle, caption, footer, isActive = false, ...rest },
    ref
  ) => (
    <Root ref={ref} data-active={isActive} {...rest}>
      <Body>
        <TitleRoot>
          <div>
            {caption && <CaptionText>{caption}</CaptionText>}

            <LineClampedTitle variant="h6">{title}</LineClampedTitle>

            {subtitle && (
              <ColoredTypography variant="caption" color="textSecondary">
                {subtitle}
              </ColoredTypography>
            )}
          </div>

          {titleActions && <TitleActionsContainer>{titleActions}</TitleActionsContainer>}
        </TitleRoot>

        {children}
      </Body>
      <Footer>{footer}</Footer>
    </Root>
  )
);

ModalListCard.displayName = 'ModalListCard';

const Root = styled.div`
  ${actionableCard};

  display: flex;
  flex-direction: column;
`;

const Body = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.small};
  flex: 1 1 auto;

  position: relative;
  padding: ${({ theme }) => theme.spacings.three};
  padding-bottom: ${({ theme }) => theme.spacings.two};

  ${({ theme }) => objectToCss(theme.typography.body2)};
`;

const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: ${({ theme }) => theme.spacings.one};
  padding: ${({ theme }) => `${theme.spacings.two} ${theme.spacings.three}`};

  margin-top: auto;
  min-height: 40px;
`;

const TitleRoot = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
  gap: ${({ theme }) => theme.spacings.one};
`;

const TitleActionsContainer = styled.div`
  // Usually, we'll use IconButton here, which has a outer padding
  // To align it centered with the title, we use an negative margin
  margin-top: -4px;
`;

const CaptionText = styled(ColoredTypography).attrs({ variant: 'caption', color: 'textDisabled' })`
  margin-bottom: ${({ theme }) => theme.spacings.two};
`;

const LineClampedTitle = styled(Typography)`
  // Break long words
  word-break: break-word;
  // Max 2 lines of words
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;
