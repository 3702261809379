import { Button } from '@mui/material';
import styled from 'styled-components';

export const LinkButton = styled(Button)`
  && {
    color: ${({ theme }) => theme.colors.primary};
  }

  &:hover {
    color: ${({ theme }) => theme.colors.primaryHover};
  }
`;
