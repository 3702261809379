import { queryEndpoint } from 'services/backofficeIntegration/http/endpointDefinition';
import { httpGetPersonalities } from 'services/backofficeIntegration/http/endpoints/personalities/httpGetPersonalities';
import { queryClient } from 'utils/ReactQueryClient';

export type GetDefaultPersonalityParams = {
  language: string;
  country: string;
};

// default personality is always the first one, but there may be no default personality for locale
export const httpGetDefaultPersonality = queryEndpoint({
  makeQueryKey: (params: GetDefaultPersonalityParams) => {
    return [httpGetPersonalities.makeQueryKey({ ...params, limit: 1 }), 'default-personality'];
  },
  callEndpoint: (params: GetDefaultPersonalityParams) => {
    return httpGetPersonalities
      .callEndpoint({ ...params, limit: 1 })
      .then(
        response =>
          response.data.find(p => p.is_customer_default) ??
          response.data.find(p => p.is_default) ??
          null
      );
  }
});

export const invalidateDefaultPersonalityQueries = (params: GetDefaultPersonalityParams) =>
  queryClient.invalidateQueries([
    httpGetPersonalities.makeQueryKey({ ...params, limit: 1 }),
    'default-personality'
  ]);
