import RefreshIcon from '@mui/icons-material/Refresh';
import { Button, Divider, IconButton, Paper, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { collapseSelection } from '@udecode/plate-common';
import FlexContainer from 'components/FlexContainer';
import Toast from 'components/toasts/Toast';
import useEditor from 'features/aiWriter/AiWriterTextEditor/hooks/useEditor';
import { BasicMarkdownPreview, MarkdownTile } from 'features/aiWriter/markdown/MarkdownTile';
import { FlashActionsInputProps } from 'features/aiWriter/slashCommands/FlashActionsInput';
import {
  useFlashActionsSelection,
  useGetFlashActionsSelectedAction
} from 'features/aiWriter/slashCommands/hooks/useFlashActionsStore';
import { useHandleStreamedGenerateText } from 'features/aiWriter/slashCommands/hooks/useHandleStreamedGenerateText';
import { useInsertTextWithMarkdown } from 'features/aiWriter/slashCommands/hooks/useInsertTextWithMarkdown';
import { useReplaceTextWithMarkdown } from 'features/aiWriter/slashCommands/hooks/useReplaceTextWithMarkdown';
import FormattedMessage from 'features/i18n/FormattedMessage';
import { useState } from 'react';
import styled from 'styled-components';

type BaseOutputProps = {
  minAmountOfCharactersToBeUsedAsContext?: number;
  maxAmountOfCharactersToBeUsedAsContext?: number;

  generateConfig: { outputType: string; text: string; keywords?: string; tonality?: string[] };
  onGenerate?: () => void;
} & FlashActionsInputProps;

export function BaseOutput(props: BaseOutputProps) {
  const { generateConfig, onGenerate } = props;

  const [text, setText] = useState('');

  const replaceTextWithMarkdown = useReplaceTextWithMarkdown();
  const insertTextWithMarkdown = useInsertTextWithMarkdown();

  const selectedAction = useGetFlashActionsSelectedAction();

  const editor = useEditor();
  const flashActionsSelection = useFlashActionsSelection();

  const generateMutation = useHandleStreamedGenerateText({
    outputType: generateConfig.outputType,
    toasts: {
      loading: <FormattedMessage id="aiWriter.expand.toast_start" />,
      success: <FormattedMessage id="aiWriter.expand.toast_end" />,
      error: <FormattedMessage id="aiWriter.expand.toast_end.failed" />
    },
    handleGeneration: chunk => {
      setText(text => text + chunk);
    }
  });

  const { minAmountOfCharactersToBeUsedAsContext, maxAmountOfCharactersToBeUsedAsContext } = props;

  const { refetch, isLoading } = useQuery({
    queryKey: ['outputPanel', JSON.stringify(selectedAction)],
    queryFn: async () => {
      if (
        minAmountOfCharactersToBeUsedAsContext &&
        generateConfig.text.length < minAmountOfCharactersToBeUsedAsContext
      ) {
        Toast.warning('aiWriter.expand.toast_not_enough_char', {
          minAmount: minAmountOfCharactersToBeUsedAsContext
        });
        props.onClose();
        return null;
      }

      if (
        maxAmountOfCharactersToBeUsedAsContext &&
        generateConfig.text.length > maxAmountOfCharactersToBeUsedAsContext
      ) {
        Toast.warning('aiWriter.expand.toast_too_many_char', {
          amount: maxAmountOfCharactersToBeUsedAsContext
        });
        props.onClose();
        return null;
      }

      onGenerate?.();
      await generateMutation.mutateAsync({
        text: generateConfig.text,
        keywords: generateConfig.keywords
      });

      return true;
    }
  });

  const handleInsertClick = () => {
    props.onClose();
    collapseSelection(editor, { edge: 'end' });
    insertTextWithMarkdown(text);
  };

  const handleReplaceClick = () => {
    props.onClose();

    replaceTextWithMarkdown(text, flashActionsSelection);
  };

  const handleRegenerateClick = () => {
    setText('');
    refetch();
  };

  const isButtonDisabled = isLoading || !text;

  const isSelection = !!flashActionsSelection;

  /**
   * TabIndex is a hack to prevent the loss of a
   * `fake selection` in the editor.
   */
  return (
    <Root tabIndex={-1}>
      <FlexContainer gap="medium">
        <FlexContainer gap="small">
          <FlexContainer direction="row" alignItems="center" justifyContent="space-between">
            <Typography variant="subtitle2">
              <FormattedMessage id="aiWriter.flashActions_input.output.title" />
            </Typography>
            <IconButton disabled={isButtonDisabled} onClick={handleRegenerateClick}>
              <RefreshIcon />
            </IconButton>
          </FlexContainer>
          <Divider />
          <TextBox>
            <MarkdownTile
              markdown={<BasicMarkdownPreview>{text}</BasicMarkdownPreview>}
              surface={undefined}
            />
          </TextBox>
        </FlexContainer>
        <ButtonsBox>
          <Button variant="contained" onClick={handleInsertClick} disabled={isButtonDisabled}>
            <FormattedMessage id="aiWriter.flashActions_input.output.insert" />
          </Button>
          {isSelection && (
            <Button variant="outlined" onClick={handleReplaceClick} disabled={isButtonDisabled}>
              <FormattedMessage id="aiWriter.flashActions_input.output.replace" />
            </Button>
          )}
        </ButtonsBox>
      </FlexContainer>
    </Root>
  );
}

const Root = styled(Paper).attrs({
  elevation: 1
})`
  margin-top: 1rem;
  padding: 1rem;
`;

const ButtonsBox = styled.div`
  display: flex;
  flex-direction: row-reverse;
  gap: ${({ theme }) => theme.spacings.small};
`;

const TextBox = styled(Typography).attrs({
  variant: 'body2'
})`
  min-height: 100px;
  max-height: 400px;

  overflow-y: scroll;
`;
