import { getIsAuthenticated } from 'features/customer/store/selectors';
import { useNotificationsManager } from 'features/userNotifications/useNotificationsManager';
import { useAppSelector } from 'store/hooks';

function OnlyWhenAuthenticated() {
  useNotificationsManager();
  return null;
}

export function UserNotificationsManager() {
  const isAuthenticated = useAppSelector(getIsAuthenticated);
  return isAuthenticated ? <OnlyWhenAuthenticated /> : null;
}
