import { BaseSuccessDto } from 'services/backofficeIntegration/http/BaseSuccessDto';
import { backofficeClient } from 'services/backofficeIntegration/http/client/createBackofficeClient';
import { TeamMemberDto } from 'services/backofficeIntegration/http/dtos/TeamMemberDto';
import { mutationEndpoint } from 'services/backofficeIntegration/http/endpointDefinition';

type SuccessDto = BaseSuccessDto<TeamMemberDto>;

export type ChangeUserStatusParams = {
  teamCustomerId: number;
  teamId: number;
  is_active: boolean;
};

export const httpChangeUserStatus = mutationEndpoint({
  callEndpoint: ({ teamCustomerId, teamId, is_active }: ChangeUserStatusParams) =>
    backofficeClient
      .put<SuccessDto>(`/teams/${teamId}/members/${teamCustomerId}/status`, {
        is_active
      })
      .then(response => response.data.data)
});
