import { createSelector } from '@reduxjs/toolkit';
import { memoize } from 'lodash';
import { RootState } from 'store/rootReducer';

export const getCampaigns = (state: RootState) => state.subjectLines.campaigns;
export const getSuggestions = (state: RootState) => state.subjectLines.suggestions;
export const getSubjectLinesIsLoading = (state: RootState) => state.subjectLines.isLoading;
export const getSuggestionsIsLoading = (state: RootState) =>
  state.subjectLines.isSuggestionsLoading;

export const getCampaignById = createSelector(getCampaigns, campaigns =>
  memoize((campaignId: number) => campaigns.find(campaign => campaign.id === campaignId))
);
