import { NestedMenuItem } from 'mui-nested-menu';
import React from 'react';
import styled from 'styled-components';

type Props = {
  icon: React.ReactNode;
  label: string;
  isParentMenuOpen: boolean;
  children: React.ReactNode;
};

export const MenuDropdownSubItem = ({ icon, label, isParentMenuOpen, children }: Props) => {
  return (
    <Root
      label={label}
      parentMenuOpen={isParentMenuOpen}
      rightIcon={null}
      leftIcon={<Icon>{icon}</Icon>}
    >
      {children}
    </Root>
  );
};

const Root = styled(NestedMenuItem)`
  && {
    padding: ${({ theme }) => `${theme.spacings.small} ${theme.spacings.medium}`};

    color: ${({ theme }) => theme.colors.blackHighEmphasis};
  }

  > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

const Icon = styled.div`
  display: flex;
  align-items: center;

  padding-right: 12px;
  height: 38px;
`;
