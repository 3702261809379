import {
  AiWriterSupportedLanguageAndCountryCode,
  aiWriterSupportedLanguageAndCountryCodes
} from 'features/aiWriter/aiWriterSupportedLanguageAndCountryCodes';
import { SystemTonality } from 'features/aiWriter/tonality/SystemTonality';

export type TonalityValuesMap = Record<SystemTonality, string>;

export const translatedTonalities: Record<
  AiWriterSupportedLanguageAndCountryCode,
  TonalityValuesMap
> = {
  [aiWriterSupportedLanguageAndCountryCodes.de_de]: {
    calm: 'Ruhig',
    casual: 'Du-Form',
    encouraging: 'Ermutigend',
    excited: 'Aufgeregt',
    formal: 'Formal / Sie-Form',
    gentle: 'Sanft',
    polite: 'Höflich',
    positive: 'Positiv',
    powerful: 'Kraftvoll',
    urgent: 'Dringend',
    witty: 'Witzig'
  },
  [aiWriterSupportedLanguageAndCountryCodes.de_ch]: {
    calm: 'Ruhig',
    casual: 'Du-Form',
    encouraging: 'Ermutigend',
    excited: 'Aufgeregt',
    formal: 'Formal / Sie-Form',
    gentle: 'Sanft',
    polite: 'Höflich',
    positive: 'Positiv',
    powerful: 'Kraftvoll',
    urgent: 'Dringend',
    witty: 'Witzig'
  },
  [aiWriterSupportedLanguageAndCountryCodes.de_at]: {
    calm: 'Ruhig',
    casual: 'Du-Form',
    encouraging: 'Ermutigend',
    excited: 'Aufgeregt',
    formal: 'Formal / Sie-Form',
    gentle: 'Sanft',
    polite: 'Höflich',
    positive: 'Positiv',
    powerful: 'Kraftvoll',
    urgent: 'Dringend',
    witty: 'Witzig'
  },
  [aiWriterSupportedLanguageAndCountryCodes.en_us]: {
    calm: 'Calm',
    casual: 'Casual',
    encouraging: 'Encouraging',
    excited: 'Excited',
    formal: 'Formal',
    gentle: 'Gentle',
    polite: 'Polite',
    positive: 'Positive',
    powerful: 'Powerful',
    urgent: 'Urgent',
    witty: 'Witty'
  },
  [aiWriterSupportedLanguageAndCountryCodes.en_uk]: {
    calm: 'Calm',
    casual: 'Casual',
    encouraging: 'Encouraging',
    excited: 'Excited',
    formal: 'Formal',
    gentle: 'Gentle',
    polite: 'Polite',
    positive: 'Positive',
    powerful: 'Powerful',
    urgent: 'Urgent',
    witty: 'Witty'
  },
  [aiWriterSupportedLanguageAndCountryCodes.es_es]: {
    calm: 'calmado',
    casual: 'casual',
    encouraging: 'alentador',
    excited: 'entusiasmado',
    formal: 'formal',
    gentle: 'suave',
    polite: 'educado',
    positive: 'positivo',
    powerful: 'poderoso',
    urgent: 'urgente',
    witty: 'gracioso'
  },
  [aiWriterSupportedLanguageAndCountryCodes.ca_es]: {
    positive: 'Positiu',
    excited: 'Emocionat',
    powerful: 'Potent',
    witty: 'Enginyós',
    calm: 'Calmat',
    urgent: 'Urgent',
    gentle: 'Suau',
    polite: 'Educat',
    encouraging: 'Encoratjador',
    formal: 'Formal',
    casual: 'Casual'
  },
  [aiWriterSupportedLanguageAndCountryCodes.fr_fr]: {
    calm: 'calme',
    casual: 'informel',
    encouraging: 'encourageant',
    excited: 'excité',
    formal: 'formel',
    gentle: 'doux',
    polite: 'poli',
    positive: 'positif',
    powerful: 'puissant',
    urgent: 'urgent',
    witty: 'drôle'
  },
  [aiWriterSupportedLanguageAndCountryCodes.fr_ch]: {
    calm: 'calme',
    casual: 'informel',
    encouraging: 'encourageant',
    excited: 'excité',
    formal: 'formel',
    gentle: 'doux',
    polite: 'poli',
    positive: 'positif',
    powerful: 'puissant',
    urgent: 'urgent',
    witty: 'drôle'
  },
  [aiWriterSupportedLanguageAndCountryCodes.it_it]: {
    calm: 'calma',
    casual: 'casuale',
    encouraging: 'incoraggiante',
    excited: 'emozionato',
    formal: 'formale',
    gentle: 'gentile',
    polite: 'gentile',
    positive: 'positivo',
    powerful: 'potente',
    urgent: 'urgente',
    witty: 'spiritoso'
  },
  [aiWriterSupportedLanguageAndCountryCodes.nl_nl]: {
    calm: 'kalm',
    casual: 'casual',
    encouraging: 'bemoedigend',
    excited: 'opgewonden',
    formal: 'formeel',
    gentle: 'zacht',
    polite: 'beleefd',
    positive: 'positief',
    powerful: 'krachtig',
    urgent: 'dringend',
    witty: 'geestig'
  },
  [aiWriterSupportedLanguageAndCountryCodes.pl_pl]: {
    calm: 'spokojna',
    casual: 'zwykły',
    encouraging: 'zachęcający',
    excited: 'podekscytowany',
    formal: 'formalny',
    gentle: 'delikatny',
    polite: 'grzeczny',
    positive: 'pozytywny',
    powerful: 'potężny',
    urgent: 'pilne',
    witty: 'dowcipny'
  },
  [aiWriterSupportedLanguageAndCountryCodes.pt_pt]: {
    calm: 'Calmo',
    casual: 'Descontraído',
    encouraging: 'Incentivador',
    excited: 'Animado',
    formal: 'Formal',
    gentle: 'Gentil',
    polite: 'Educado',
    positive: 'Positivo',
    powerful: 'Poderoso',
    urgent: 'Urgente',
    witty: 'Engenhoso'
  },
  [aiWriterSupportedLanguageAndCountryCodes.pt_br]: {
    calm: 'Calmo',
    casual: 'Informal',
    encouraging: 'Incentivador',
    excited: 'Animado',
    formal: 'Formal',
    gentle: 'Gentil',
    polite: 'Educado',
    positive: 'Positivo',
    powerful: 'Poderoso',
    urgent: 'Urgente',
    witty: 'Engenhoso'
  },
  [aiWriterSupportedLanguageAndCountryCodes.es_mx]: {
    calm: 'Calmado',
    casual: 'Informal',
    encouraging: 'Alentador',
    excited: 'Emocionado',
    formal: 'Formal',
    gentle: 'Amable',
    polite: 'Educado',
    positive: 'Positivo',
    powerful: 'Poderoso',
    urgent: 'Urgente',
    witty: 'Agudo'
  },
  [aiWriterSupportedLanguageAndCountryCodes.sv_se]: {
    calm: 'Lugn',
    casual: 'Avslappnad',
    encouraging: 'Uppmuntrande',
    excited: 'Spänd',
    formal: 'Formell',
    gentle: 'Mild',
    polite: 'Artig',
    positive: 'Positiv',
    powerful: 'Kraftfull',
    urgent: 'Brådskande',
    witty: 'Smart'
  },
  [aiWriterSupportedLanguageAndCountryCodes.cs_cz]: {
    calm: 'Klidný',
    casual: 'Neformální',
    encouraging: 'Podporující',
    excited: 'Vzrušený',
    formal: 'Formální',
    gentle: 'Jemný',
    polite: 'Slušný',
    positive: 'Pozitivní',
    powerful: 'Silný',
    urgent: 'Naléhavý',
    witty: 'Vtipný'
  },
  [aiWriterSupportedLanguageAndCountryCodes.hu_hu]: {
    calm: 'Nyugodt',
    casual: 'Lazább',
    encouraging: 'Ösztönző',
    excited: 'Izgatott',
    formal: 'Formális',
    gentle: 'Kedves',
    polite: 'Udvarias',
    positive: 'Pozitív',
    powerful: 'Erdős',
    urgent: 'Sürgős',
    witty: 'Okos'
  },
  [aiWriterSupportedLanguageAndCountryCodes.hr_hr]: {
    calm: 'Mirno',
    casual: 'Opušteno',
    encouraging: 'Ohrabrujuće',
    excited: 'Uzbudljivo',
    formal: 'Formalno',
    gentle: 'Blago',
    polite: 'Pristojno',
    positive: 'Pozitivno',
    powerful: 'Moćno',
    urgent: 'Hitno',
    witty: 'Duhovito'
  },
  [aiWriterSupportedLanguageAndCountryCodes.zh_cn]: {
    calm: '宁静',
    casual: '随意',
    encouraging: '鼓舞人心',
    excited: '兴奋',
    formal: '正式',
    gentle: '温柔',
    polite: '有礼貌',
    positive: '积极',
    powerful: '强大',
    urgent: '紧急',
    witty: '机智'
  },
  [aiWriterSupportedLanguageAndCountryCodes.tr_tr]: {
    calm: 'Sakin',
    casual: 'Rahat',
    encouraging: 'Tevvik Edici',
    excited: 'Heyecanlı',
    formal: 'Resmi',
    gentle: 'Nazik',
    polite: 'Kibar',
    positive: 'Olumlu',
    powerful: 'Güçlü',
    urgent: 'Acil',
    witty: 'Esprili'
  }
};
