import { createAction, createReducer } from '@reduxjs/toolkit';

import { AppLanguage, LanguageState } from './types';
import { getInitLanguage } from './utils';

export const setLanguage = createAction<AppLanguage>('language/SET_LANGUAGE');

const languageReducer = createReducer<LanguageState>(
  /**
   * This must be called lazy to make sure that each time we destroy the state
   * a correct language is used. At the moment of writing the state is destroyed
   * after user logs out.
   * If we call this eagerly it leads to a bug:
   * 1) open the app with language A
   * 2) login (optional if your session is up)
   * 3) change language to B
   * 4) logout
   * 5) the language of the page is A again
   */
  getInitLanguage,
  builder =>
    builder.addCase(setLanguage, (_, { payload }) => {
      return payload;
    })
);

export default languageReducer;
