import { Typography } from '@mui/material';
import { NextFabButton } from 'components/fabButtons/NextFabButton';
import { SaveFabButton } from 'components/fabButtons/SaveFabButton';
import StepperForm, {
  FormStep,
  StepHelpers,
  StepNextButtonProps
} from 'components/forms/StepperForm';
import { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import gtmIds from 'services/tracking/GTMIds';
import styled from 'styled-components';
import useTr from 'utils/hooks/useTr';
import { array, number, object, string } from 'yup';

import { ScoredSubjectLine } from '../store/types';
import ApproveSubjectLines from './ApproveSubjectLines';
import CampaignInformation from './CampaignInformation';
import SubjectLinesSelection from './SubjectLinesSelection/SubjectLinesSelection';
import SubjectLinesSuccess from './SubjectLinesSelection/SubjectLinesSuccess';

export const nameField = 'name';
export const projectIdField = 'projectId';
export const emailBodyField = 'emailBody';
export const keywordsField = 'keywords';
export const suggestionsField = 'suggestions';
export const subjectLinesField = 'subjectLines';
export const deletedSuggestionsField = 'deletedSuggestions';

export type CampaignConfigFormPayload = {
  [nameField]: string;
  [projectIdField]: number;
  [emailBodyField]: string;
  [keywordsField]: string;
  [suggestionsField]: ScoredSubjectLine[];
  [subjectLinesField]: ScoredSubjectLine[];
  [deletedSuggestionsField]: string[];
};

const steps: FormStep[] = [
  {
    label: 'subject_lines.campaign_config.created_step'
  },
  {
    label: 'subject_lines.campaign_config.select_step',
    optional: (
      <Typography variant="caption" color="textSecondary">
        <FormattedMessage id="subject_lines.campaign_config.select_step.edit" />
      </Typography>
    )
  },
  {
    label: 'subject_lines.campaign_config.save_step'
  }
];

const renderStepComponent = (helpers: StepHelpers) => {
  switch (helpers.step) {
    case 0:
      return <CampaignInformation />;
    case 1:
      return <SubjectLinesSelection />;
    case 2:
      return <ApproveSubjectLines />;
    case 3:
      return <SubjectLinesSuccess {...helpers} />;
    default:
      return null;
  }
};

const { goToThirdStep, saveAndConfirm } = gtmIds.subjectLines;

const renderNextStepButton = (props: StepNextButtonProps) => {
  switch (props.step) {
    case 0:
      return <NextFabButton />;
    case 1:
      return <NextFabButton id={goToThirdStep} />;
    case 2:
      return <SaveFabButton id={saveAndConfirm} />;
    default:
      return undefined;
  }
};

type Props = {
  initialValues: CampaignConfigFormPayload;
  handleSubmit: (values: CampaignConfigFormPayload) => void;
};

const CampaignConfigForm = ({ initialValues, handleSubmit }: Props) => {
  const navigate = useNavigate();
  const tr = useTr();

  const goToCampaignsTable = () => {
    navigate('/subject-lines/');
  };

  const validationSchema = useMemo(
    () => [
      object().shape(
        {
          [nameField]: string().required(),
          [projectIdField]: number().required().positive('project is a required field'),
          [emailBodyField]: string().when(keywordsField, {
            is: (email: string) => !email || email.length === 0,
            then: string().required(tr('subject_lines.new_campaign.input_error')),
            otherwise: string()
          }),
          [keywordsField]: string().when(emailBodyField, {
            is: (keywords: string) => !keywords || keywords.length === 0,
            then: string().required(tr('subject_lines.new_campaign.input_error')),
            otherwise: string()
          })
        },
        [[keywordsField, emailBodyField]]
      ),
      object().shape({
        subjectLines: array().min(1)
      })
    ],
    [tr]
  );

  return (
    <ConfigFormContainer>
      <StepperForm
        steps={steps}
        stepComponent={renderStepComponent}
        stepNextButton={renderNextStepButton}
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
        validateOnBlur
        backButton
        nextButton
        onReturn={goToCampaignsTable}
        summaryScreen
      />
    </ConfigFormContainer>
  );
};

const ConfigFormContainer = styled.div`
  display: flex;
  flex: 1 1 auto;

  width: 100%;
  padding: 2rem 4rem;
`;

export default CampaignConfigForm;
