import { MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { BaseSetting } from 'features/modular-workflow/builder/settings/BaseSetting';
import { InputRendererContext } from 'features/modular-workflow/builder/settings/InputRenderer';
import {
  ApiModularWorkflowStepSettingsBrandVoice,
  ApiModularWorkflowStepSettingsImageCount,
  ApiModularWorkflowStepSettingsImageQuality,
  ApiModularWorkflowStepSettingsImageRatio,
  ApiModularWorkflowStepSettingsTextGenerationModel
} from 'features/modular-workflow/builder/types-api';
import { BuilderModularWorkflowStepInputWithValue } from 'features/modular-workflow/builder/types-builder';
import { useContext, useRef } from 'react';

export type SupportedSettings =
  | ApiModularWorkflowStepSettingsImageCount
  | ApiModularWorkflowStepSettingsImageRatio
  | ApiModularWorkflowStepSettingsImageQuality
  | ApiModularWorkflowStepSettingsBrandVoice
  | ApiModularWorkflowStepSettingsTextGenerationModel;

export const BaseDataTypeSelectable = () => {
  const {
    input,
    inputSettings: settings,
    onChange,
    inputErrorList
  } = useContext(InputRendererContext);
  const typedInput = input as BuilderModularWorkflowStepInputWithValue;

  // We use a ref to store initial value, cuz the Select default_value should never change
  // As soon as the user changes the selected value and saves the changes,
  // React updates the value and therefore the default_value would change.
  // Using the ref prevents that change as the Select will preserve the changed option anyway
  const defaultValueRef = useRef(typedInput.value as string | number);

  const handleChange = (event: SelectChangeEvent<string | number>) => {
    onChange(typedInput, event.target.value as string | number);
  };

  return (
    <BaseSetting>
      {elementId => (
        <Select
          id={elementId}
          defaultValue={defaultValueRef.current}
          onChange={handleChange}
          fullWidth={true}
          error={inputErrorList.length > 0}
        >
          {(settings as SupportedSettings).options.map(option => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      )}
    </BaseSetting>
  );
};
