import { WarningAmber } from '@mui/icons-material';
import FormattedMessage from 'features/i18n/FormattedMessage';
import styled from 'styled-components';
import theme from 'styles/styledComponents/theme';

export function LanguageMismatchWarning() {
  return (
    <Root>
      <Icon></Icon>
      <FormattedMessage id="common.initialization.input.error" tagName="span" />
    </Root>
  );
}

const Root = styled.span`
  display: flex;
  align-items: center;
  gap: ${theme.spacings.small};
`;

const Icon = styled(WarningAmber)`
  color: ${theme.colors.warning};
`;
