import { Typography } from '@mui/material';
import {
  flashActionsCategoryAlias,
  useTemplateCategoriesQuery
} from 'features/aiWriter/commandTemplates/useTemplateCategoriesQuery';
import FormattedMessage from 'features/i18n/FormattedMessage';
import { ThemedCategoryList } from 'features/theme-2024/category-list/ThemedCategoryList';
import { ThemedCategoryListItem } from 'features/theme-2024/category-list/ThemedCategoryListItem';
import { SpecialCategories } from 'services/backofficeIntegration/http/endpoints/textGeneration/commandTemplates/httpGetCommandTemplates';
import styled from 'styled-components';

type Props = {
  currentCategory: SpecialCategories | number;
  onCategorySelect: (categoryId: SpecialCategories | number) => void;
};

export const TemplatesCategoryList = ({ currentCategory, onCategorySelect }: Props) => {
  const { data: categories } = useTemplateCategoriesQuery();
  // category.alias === 'flash_actions' is a special kind of category that we don't want to show to the user in prompts library
  const usableCategories = categories?.filter(
    category => category.alias !== flashActionsCategoryAlias
  );

  return (
    <Root>
      <Typography variant="h6">
        <FormattedMessage id="aiWriter.inspirations.templates.headline.categories" />
      </Typography>

      <ThemedCategoryList>
        <ThemedCategoryListItem
          selected={SpecialCategories.ALL_CATEGORY === currentCategory}
          onClick={() => onCategorySelect(SpecialCategories.ALL_CATEGORY)}
        >
          <FormattedMessage id="common.all" />
        </ThemedCategoryListItem>

        {usableCategories?.map(category => (
          <ThemedCategoryListItem
            key={category.id}
            selected={category.id === currentCategory}
            onClick={() => onCategorySelect(category.id)}
          >
            {category.name}{' '}
          </ThemedCategoryListItem>
        ))}
      </ThemedCategoryList>
    </Root>
  );
};

const Root = styled.div`
  display: grid;
  gap: ${({ theme }) => theme.spacings.four};
  grid-template-rows: min-content 1fr;
`;
