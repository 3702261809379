import { GenerateTextResponse, GenerateTextsRequest } from 'services/api/wordEmbedding/types';
import { backofficeClient } from 'services/backofficeIntegration/http/client/createBackofficeClient';
import { mutationEndpoint } from 'services/backofficeIntegration/http/endpointDefinition';

export type GeneratePersonalityProps = Omit<GenerateTextsRequest, 'output_type' | 'length'>;

export const httpGeneratePersonality = mutationEndpoint({
  callEndpoint: (params: GeneratePersonalityProps) => {
    const endpointBody = { ...params, output_type: 'example_text_to_personality' };

    return backofficeClient.post<GenerateTextResponse>(
      'word-embedding/generate_personality ',
      endpointBody
    );
  }
});
