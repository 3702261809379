import { Typography } from '@mui/material';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import FormattedMessage from 'features/i18n/FormattedMessage';
import { IntlMessageKeys } from 'features/i18n/IntlMessageKeys';

type HeadCell = {
  disablePadding: boolean;
  id: string;
  label: IntlMessageKeys;
  numeric: boolean;
  ellipsisMenu?: boolean;
};

const headCells: readonly HeadCell[] = [
  {
    id: 'user',
    numeric: false,
    disablePadding: false,
    label: 'profile.users.users_list.user'
  },
  {
    id: 'role',
    numeric: true,
    disablePadding: false,
    label: 'profile.users.users_list.role'
  },
  {
    id: 'status',
    numeric: true,
    disablePadding: false,
    label: 'profile.users.users_list.status'
  },
  {
    id: 'lastActive',
    numeric: true,
    disablePadding: false,
    label: 'profile.users.users_list.last_active'
  },
  {
    id: 'ellipsisMenu',
    numeric: false,
    disablePadding: false,
    label: ''
  }
];

export const UserTableHead = () => (
  <TableHead>
    <TableRow>
      {headCells.map(headCell => (
        <TableCell
          key={headCell.id}
          align="left"
          padding={headCell.disablePadding ? 'none' : 'normal'}
        >
          {headCell.label && (
            <Typography variant="body2" fontWeight="bold">
              <FormattedMessage id={headCell.label} />
            </Typography>
          )}
        </TableCell>
      ))}
    </TableRow>
  </TableHead>
);
