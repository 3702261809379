import { memoize } from 'lodash';
import CustomerAPI from 'services/api/customer';
import { TranslationFunction } from 'utils/hooks/useTr';
import isValidEmail from 'utils/isValidEmail';
import { debouncePromise } from 'utils/utils';

const debouncedValidateEmail = debouncePromise(CustomerAPI.validateEmail, 500);

const emailValidationCheck = memoize((email: string, tr: TranslationFunction) => {
  if (isValidEmail(email)) {
    return debouncedValidateEmail(email).then(i => (i ? tr(i as unknown as string) : i));
  }
});

export default emailValidationCheck;
