import { css } from 'styled-components';

import { WordStatisticsColorVariant } from './common/WordStatisticsPill';

export const getWordStatisticsProgressColor = ($color: WordStatisticsColorVariant) => css`
  background-color: ${({ theme }) => {
    switch ($color) {
      case 'primary':
        return theme.colors.primary600;
      case 'secondary':
        return theme.colors.secondary600;
      case 'green':
        return theme.colors.lightGreen;
    }
  }};
`;

export const getWordStatisticsBackgroundColor = (
  $color: WordStatisticsColorVariant,
  $isHighlighted?: boolean
) => css`
  background-color: ${({ theme }) => {
    switch ($color) {
      case 'primary':
        return $isHighlighted ? theme.colors.primary200 : theme.colors.primary50;
      case 'secondary':
        return $isHighlighted ? theme.colors.secondary200 : theme.colors.secondary50;
      case 'green':
        return $isHighlighted ? theme.colors.lightGreen500 : theme.colors.lightGreen300;
    }
  }};
`;

export const getWordStatisticsBorder = (
  $color: WordStatisticsColorVariant,
  $isHighlighted?: boolean
) => css`
  border: 1px solid
    ${({ theme }) => {
      switch ($color) {
        case 'primary':
          return $isHighlighted ? theme.colors.primary200 : theme.colors.primary100;
        case 'secondary':
          return $isHighlighted ? theme.colors.secondary200 : theme.colors.secondary100;
        case 'green':
          return $isHighlighted ? theme.colors.lightGreen500 : theme.colors.lightGreen200;
      }
    }};
`;

export const getWordStatisticsColor = ($color: WordStatisticsColorVariant) => css`
  color: ${({ theme }) => {
    switch ($color) {
      case 'primary':
        return theme.colors.primary500;
      case 'secondary':
        return theme.colors.secondary700;
      case 'green':
        return theme.colors.lightGreen700;
    }
  }};
`;
