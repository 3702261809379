/* eslint-disable @typescript-eslint/no-explicit-any */
import AxiosClient from '..';
import { GetWhitelistsParams, GetWhitelistsResponse } from './types';

const WhitelistAPI = {
  getAll: ({ label, language }: GetWhitelistsParams = {}) =>
    AxiosClient.get<any, GetWhitelistsResponse>('/settings/whitelists', {
      params: { label, language }
    })
};

export default WhitelistAPI;
