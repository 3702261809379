import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useAppDispatch } from 'store/hooks';
import { reportErrors } from 'utils/reportErrors';
import { defaultGoalConfig, filterGoalConfig } from 'utils/tabUtils';
import { parseUrlQuery } from 'utils/urlUtils';
import { createManualDimension, wordsToTags } from 'utils/wordUtils';

import Toast from '../../components/toasts/Toast';
import { rateWordsThunk } from './store/thunks';

const useCXQuery = () => {
  const location = useLocation();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (location.search) {
      const parsedUrlQuery = parseUrlQuery(location.search);
      if (parsedUrlQuery.request) {
        try {
          const queryRequestConfig = JSON.parse(parsedUrlQuery.request);
          if (queryRequestConfig) {
            const {
              words = [],
              model_id: model,
              goalConfig = defaultGoalConfig,
              dimension_words: dimensionWords = []
            } = queryRequestConfig;

            dispatch(
              rateWordsThunk({
                newTab: true,
                wordAttributes: filterGoalConfig(goalConfig),
                dimensions: [],
                manualDimensions: dimensionWords.length
                  ? [createManualDimension(wordsToTags(dimensionWords), 1)]
                  : [],
                filters: [],
                seoParams: [],
                goalConfig: {
                  ...goalConfig,
                  dimensions: dimensionWords.length ? [{ id: 1, weight: 1 }] : []
                },
                seedWords: wordsToTags(words),
                model
              })
            );
          }
        } catch (e) {
          Toast.error('error.cx.parsing_error');
          reportErrors('component', e as Error);
        }
      }
    }
  }, [dispatch, location.search]);
};

export default useCXQuery;
