import { Dimension, ManualDimension } from 'services/api/dimension/types';
import { sortInOrderSimple } from 'store/utils/dataUtils';
import { OpenRate, WordAttribute } from 'types/ReduxStateTypes';

const prepareOrdering = ({
  dimensions,
  manualDimensions,
  extendWithScoreByOpenRate,
  wordAttributes
}: {
  dimensions: Dimension[];
  manualDimensions: ManualDimension[];
  extendWithScoreByOpenRate: boolean;
  wordAttributes: WordAttribute[];
}) => {
  const wordAttributesOrder: WordAttribute[] = [
    'valence',
    'arousal',
    'dominance',
    'model_rank_score'
  ];
  const dimensionIdsOrder = dimensions.map(i => i.id);
  const manualDimensionsIdsOrder = manualDimensions.map(i => i.id);
  const openRateOrder: OpenRate[] = extendWithScoreByOpenRate ? ['open_rate'] : [];

  return {
    wordAttributesOrder,
    dimensionsOrder: dimensionIdsOrder
      .slice()
      .reverse()
      .concat(manualDimensionsIdsOrder.slice().reverse()),
    legendOrder: [
      ...manualDimensionsIdsOrder,
      ...dimensionIdsOrder,
      ...wordAttributes.slice().sort(sortInOrderSimple(wordAttributesOrder)),
      ...openRateOrder
    ]
  };
};

export default prepareOrdering;
