import {
  ELEMENT_DEFAULT,
  getLastNodeByLevel,
  getPluginType,
  insertElements,
  isElement,
  PlateEditor,
  queryNode,
  Value,
  WithPlatePlugin
} from '@udecode/plate-common';
import { TrailingBlockPlugin } from 'features/plate/customPlugins/createCustomTrailingBlockPlugin';
import { Path } from 'slate';

/**
 * Add a trailing block when the last node type is not `type` and when the editor has .
 */
export const withTrailingBlock = <
  V extends Value = Value,
  E extends PlateEditor<V> = PlateEditor<V>
>(
  editor: E,
  {
    options: { types = [getPluginType(editor, ELEMENT_DEFAULT)], level, ...query }
  }: WithPlatePlugin<TrailingBlockPlugin, V, E>
) => {
  const { normalizeNode } = editor;

  editor.normalizeNode = ([currentNode, currentPath]) => {
    if (currentPath.length !== 0) {
      return normalizeNode([currentNode, currentPath]);
    }

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const lastChild = getLastNodeByLevel(editor, level!);
    const lastChildNode = lastChild?.[0];

    if (
      !lastChildNode ||
      !isElement(lastChildNode) ||
      (!types.includes(lastChildNode.type) && queryNode(lastChild, query))
    ) {
      const at = lastChild ? Path.next(lastChild[1]) : [0];

      insertElements(editor, editor.blockFactory({}, at), { at });
      return;
    }

    return normalizeNode([currentNode, currentPath]);
  };

  return editor;
};
