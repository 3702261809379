import { FormControlLabel, Typography } from '@mui/material';
import FlexContainer from 'components/FlexContainer';
import { YellowCrownImageWithClick } from 'components/icons/YellowCrownImage';
import FormattedMessage from 'features/i18n/FormattedMessage';
import { useShowRunOutOfCreditsModal } from 'features/pricing/modals/FreeCreditsLimitReachedModal';
import { FieldCheckbox } from 'features/workflowBlogPostBuilder/steps/FieldCheckbox';
import {
  CheckboxGroup,
  ProLabelContainer
} from 'features/workflowBlogPostBuilder/steps/Step3';
import { MouseEvent } from 'react';

type Props = {
  isFeatureDisabled?: boolean;
  upgradeRedirect?: boolean;
  isFreePlan: boolean;
};

export const Step3ReferencesSection = ({ isFeatureDisabled, upgradeRedirect, isFreePlan }: Props) => {
  const showRunOutOfCreditsModal = useShowRunOutOfCreditsModal();

  const handleRunOutOfCredits = (event: MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
    return showRunOutOfCreditsModal();
  };

  return (
    <section>
      <FlexContainer gap="small" direction="row">
        <Typography variant="subtitle1">
          <FormattedMessage id="blog_post_builder.steps.elements.references" />
        </Typography>
      </FlexContainer>
      <CheckboxGroup>
        <FormControlLabel
          control={
            <FieldCheckbox 
            fieldName="elements" 
            value="external_links"
            disabled={isFeatureDisabled}
            onClick={upgradeRedirect ? handleRunOutOfCredits : undefined}
            />
          }
          label={
            <ProLabelContainer>
              <Typography variant="body1">
                <FormattedMessage id="blog_post_builder.steps.elements.references.ref_check" />
              </Typography>
              {isFreePlan && <YellowCrownImageWithClick variant="h6" />}
            </ProLabelContainer>
          }
        />
      </CheckboxGroup>
    </section>
  );
};
