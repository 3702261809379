import axios from 'axios';
import axiosBetterStacktrace from 'axios-better-stacktrace';
import { backofficeClientStore } from 'services/backofficeIntegration/http/client/backofficeClientStore';
import { withApiKey } from 'services/backofficeIntegration/http/client/withApiKey';
import { withApiToken } from 'services/backofficeIntegration/http/client/withApiToken';
import { withAuthenticationRedirect } from 'services/backofficeIntegration/http/client/withAuthenticationRedirect';
import { withChurnKeyPauseWall } from 'services/backofficeIntegration/http/client/withChurnKeyPauseWall';
import { withErrorLogging } from 'services/backofficeIntegration/http/client/withErrorLogging';
import { withMaintenanceMode } from 'services/backofficeIntegration/http/client/withMaintenanceMode';
import { withRateLimitSupport } from 'services/backofficeIntegration/http/client/withRateLimitSupport';
import { withRequestIdSupport } from 'services/backofficeIntegration/http/client/withRequestIdSupport';
import { getBackofficeBaseUrl } from 'services/backofficeIntegration/http/getBackofficeBaseUrl';

/**
 * This is exported temporary so we could reuse this in
 * `/src/services/api` until we get rid of it.
 */
export function createBackofficeClient() {
  const client = axios.create({
    baseURL: getBackofficeBaseUrl()
  });

  axiosBetterStacktrace(client);

  backofficeClientStore.setState({ client });

  withErrorLogging(client);
  withApiToken(client);
  withApiKey(client);
  withMaintenanceMode(client);
  withAuthenticationRedirect(client);
  withRateLimitSupport(client);
  withChurnKeyPauseWall(client);
  withRequestIdSupport(client);

  return client;
}

export const backofficeClient = createBackofficeClient();
