import { createAction } from '@reduxjs/toolkit';
import { createAsyncAction } from 'utils/reduxUtils';

import {
  DuplicateTextPayload,
  RemoveTextPayload,
  ScoreSingleTextFailurePayload,
  ScoreSingleTextRequestPayload,
  ScoreSingleTextSuccessPayload,
  ScoreTextsSuccessPayload,
  SetIsTextEditingPayload,
  UpdateTextFailurePayload,
  UpdateTextRequestPayload,
  UpdateTextSuccessPayload
} from './types';

export const addText = createAction<void>('tester/addText');
export const setIsTextEditing = createAction<SetIsTextEditingPayload>('tester/setIsTextEditing');
export const removeText = createAction<RemoveTextPayload>('tester/removeText');
export const duplicateText = createAction<DuplicateTextPayload>('tester/duplicateText');
export const updateText = createAsyncAction<
  UpdateTextRequestPayload,
  UpdateTextSuccessPayload,
  UpdateTextFailurePayload
>('tester/updateText');

export const scoreSingleText = createAsyncAction<
  ScoreSingleTextRequestPayload,
  ScoreSingleTextSuccessPayload,
  ScoreSingleTextFailurePayload
>('tester/scoreSingleText');

export const scoreTexts = createAsyncAction<void, ScoreTextsSuccessPayload>('tester/scoreTexts');
