import AxisBottom from 'components/ChartElements/AxisBottom';
import AxisLeft from 'components/ChartElements/AxisLeft';
import DiagonalHatch from 'components/ChartElements/DiagonalHatch';
import SVGText from 'components/ChartElements/SVGText';
import { AxisDomain, AxisScale } from 'd3-axis';
import { setLegendParameter } from 'features/aiTester/store/actions/general/actions';
import { getCurrentTabChartData, getTesterActiveTab } from 'features/aiTester/store/selectors';
import { FieldType } from 'features/aiTester/store/types';
import AllValuesGraph from 'features/aiTester/TesterResultsGraph/AllValuesGraph';
import GraphContent from 'features/aiTester/TesterResultsGraph/GraphContent';
import GraphLegend from 'features/aiTester/TesterResultsGraph/GraphLegend';
import mkTesterTourClassName from 'features/aiTester/tour/utils/mkTesterTourClassName';
import {
  createXScale,
  createYScale,
  getXScaleDomainGrouped,
  getYScaleDomain,
  getYScaleDomainGrouped,
  GroupedContentTestingData,
  prepareDetailsGraph
} from 'features/aiTester/utils/graphData';
import { PureComponent } from 'react';
import isEqual from 'react-fast-compare';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { RootState } from 'store/rootReducer';
import withTr, { TrProps } from 'utils/hocs/withTr';

const mapStateToProps = (state: RootState) => {
  const { legend, legendOrder } = getTesterActiveTab(state);
  const data = getCurrentTabChartData(state);

  return { legend, legendOrder, data };
};

const mapDispatchToProps = {
  onColorChange: (field: FieldType, color: string) => setLegendParameter({ field, color }),
  onLegendSwitch: (field: FieldType, active: boolean) => setLegendParameter({ field, active })
};

type OwnProps = {
  manualScaleEnabled: boolean;
  scale: [number, number];
};

type Props = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps & OwnProps & TrProps;

type State = {
  activeDetailsGraph: string;
  detailsData: {
    data: GroupedContentTestingData[];
    texts: string[];
  };
};

class TesterResultsGraph extends PureComponent<Props, State> {
  static defaultProps = {
    data: []
  };

  state: State = {
    activeDetailsGraph: '',
    detailsData: {
      data: [],
      texts: []
    }
  };

  margin = { top: 20, right: 230, bottom: 30, left: 40 };

  width = 700;

  height = 460;

  componentDidUpdate(prevProps: Props, prevState: State) {
    const isDataEqual = isEqual(prevProps.data, this.props.data);

    if (!isDataEqual || this.state.activeDetailsGraph !== prevState.activeDetailsGraph) {
      this.setState(state => ({
        detailsData: prepareDetailsGraph(this.props.data, state.activeDetailsGraph)
      }));
    }
  }

  goToStackedBarGraph = () => {
    this.setState({ activeDetailsGraph: '' });
  };

  onBarClick = (id: string) => {
    this.setState({ activeDetailsGraph: id });
  };

  generateScales = () => {
    const { data, tr, manualScaleEnabled, scale } = this.props;
    const {
      activeDetailsGraph,
      detailsData: { data: detailsData }
    } = this.state;
    const { margin, width, height } = this;

    const xDomain = activeDetailsGraph
      ? getXScaleDomainGrouped(detailsData, tr)
      : data.map(d => d.label);
    let yDomain;

    if (manualScaleEnabled) {
      yDomain = scale.map(i => i / 10);
    } else {
      yDomain = activeDetailsGraph ? getYScaleDomainGrouped(detailsData) : getYScaleDomain(data);
    }

    return {
      x: createXScale(xDomain, [margin.left, width - margin.right], activeDetailsGraph ? 0.1 : 0.4),
      y: createYScale(yDomain, [height - margin.bottom, margin.top])
    };
  };

  render() {
    const { data, legend, legendOrder, onColorChange, onLegendSwitch } = this.props;
    const {
      activeDetailsGraph,
      detailsData: { data: detailsData, texts }
    } = this.state;
    const { margin, width, height } = this;

    const { x, y } = this.generateScales();

    return (
      <div className={mkTesterTourClassName('comparison', 5)}>
        <svg className="content-flash-svg" viewBox={`0 0 ${this.width} ${this.height}`}>
          <defs>
            <DiagonalHatch />
          </defs>
          {activeDetailsGraph && (
            <g>
              <SVGText x={width / 2} y={15} textAnchor="middle">
                <FormattedMessage
                  id="testing.graph.details_for"
                  values={{
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    label: data.find(i => i.id === activeDetailsGraph)!.label
                  }}
                />
              </SVGText>
            </g>
          )}
          {activeDetailsGraph ? (
            <AllValuesGraph
              data={detailsData}
              height={height}
              width={width}
              texts={texts}
              legend={legend}
              x={x}
              y={y}
              goToStacked={this.goToStackedBarGraph}
            />
          ) : (
            <GraphContent data={data} legend={legend} x={x} y={y} onBarClick={this.onBarClick} />
          )}
          <AxisBottom
            xScale={x as AxisScale<AxisDomain>}
            transform={`translate(0, ${y(0)})`}
            removeDomain={false}
            beforeDraw={activeDetailsGraph ? g => g.selectAll('.tick text').remove() : undefined}
          />
          <AxisLeft
            yScale={y as AxisScale<AxisDomain>}
            ticks={10}
            tickFormat=",.0%"
            noDomain={false}
            transform={`translate(${margin.left}, 0)`}
          />
          <GraphLegend
            legendOrder={legendOrder}
            legendData={legend}
            prevalenceAsWidth={!activeDetailsGraph}
            onColorChange={onColorChange}
            onActiveChange={onLegendSwitch}
            transform={`translate(${this.margin.left + this.width - this.margin.right + 15}, ${
              this.margin.top
            })`}
          />
        </svg>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTr(TesterResultsGraph));
