import cx from 'classnames';
import { uniqueId } from 'lodash';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import Autosuggest from 'react-autosuggest';
import TagsInput from 'react-tagsinput';
import withTr from '../../utils/hocs/withTr';
import Icon from '../base/Icon';
import './index.scss';

class LwTagsInput extends PureComponent {
  static propTypes = {
    value: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired,
    disableAutosuggestion: PropTypes.bool,
    suggestions: PropTypes.array,
    model: PropTypes.any,
    getSuggestions: PropTypes.func,
    resetSuggestions: PropTypes.func,
    elementToFocus: PropTypes.any,
    eventHandler: PropTypes.func,
    className: PropTypes.string,
    tagDisplayProp: PropTypes.string,
    renderTag: PropTypes.func,
    intl: PropTypes.any,
    renderInput: PropTypes.func,
    placeholder: PropTypes.string,
    icon: PropTypes.string
  };

  id = `autocomplete_${uniqueId()}`;

  setValue = tags => this.props.onChange(tags);

  clearInput = () => this.setValue([]);

  changeQuery = ({ value, reason }) => {
    if (value && reason === 'input-changed') {
      const model = Array.isArray(this.props.model) ? this.props.model[0] : this.props.model;
      this.props.getSuggestions(value, model);
    }
  };

  keyDownHandler = (eventHandler, clearInput, defaultHandler, value) => event => {
    const key = event.keyCode || event.which;

    if (eventHandler && key === 13 && (value === '' || event.ctrlKey)) {
      eventHandler(event);
    } else if (key === 27) {
      clearInput();
    } else {
      defaultHandler(event);
    }
  };

  focusInput = () => {
    if (this.inputElement) {
      this.inputElement.focus();
    } else if (this.inputRawElement) {
      this.inputRawElement.focus();
    }
  };

  renderTagsInput =
    (eventHandler, clearInput) =>
    ({ ...inputProps }) => {
      const { value, onKeyDown, placeholder, ...rest } = inputProps;

      return (
        <input
          type="text"
          id={this.id}
          value={value}
          onKeyDown={this.keyDownHandler(eventHandler, clearInput, onKeyDown, value)}
          placeholder={
            // eslint-disable-next-line no-undef
            document.activeElement && document.activeElement.id === this.id ? '' : placeholder
          }
          {...rest}
        />
      );
    };

  autosuggestRenderInput =
    (eventHandler, clearInput) =>
    ({ addTag, ...props }) => {
      const handleOnChange = (e, { method }) =>
        method === 'enter' ? e.preventDefault() : props.onChange(e);

      return (
        <Autosuggest
          ref={ref => {
            this.inputElement = ref?.input;
          }}
          suggestions={this.props.suggestions}
          getSuggestionValue={suggestion => suggestion}
          renderSuggestion={suggestion => <span>{suggestion}</span>}
          inputProps={{ ...props, onChange: handleOnChange }}
          onSuggestionSelected={(e, { suggestion }) => {
            addTag({ word: suggestion });
          }}
          renderInputComponent={this.renderTagsInput(eventHandler, clearInput)}
          onSuggestionsClearRequested={this.props.resetSuggestions}
          onSuggestionsFetchRequested={this.changeQuery}
        />
      );
    };

  renderLayout = (tagComponents, inputComponent) => (
    <>
      {this.props.icon && (
        <span className="react-tagsinput__icon-container">
          <Icon name={this.props.icon} className="react-tagsinput__icon" />
        </span>
      )}
      <span className="react-tagsinput__content" onClick={this.focusInput}>
        {tagComponents}
        {inputComponent}
      </span>
      {this.props.value.length ? (
        <span onClick={this.clearInput} className="react-tagsinput__clear-button">
          <Icon name="x" />
        </span>
      ) : null}
    </>
  );

  renderTag = props => {
    const {
      tag,
      key,
      disabled,
      onRemove,
      classNameRemove,
      getTagDisplayValue,
      className,
      ...other
    } = props;

    return (
      <span key={key} className={className} {...other}>
        {getTagDisplayValue(tag)}
        {!disabled && (
          <span className={classNameRemove} onClick={() => onRemove(key)} role="button" />
        )}
      </span>
    );
  };

  render() {
    const {
      eventHandler,
      className,
      value,
      tagDisplayProp,
      renderTag,
      disableAutosuggestion,
      placeholder,
      // eslint-disable-next-line react/prop-types
      tr,
      // eslint-disable-next-line react/prop-types
      inputProps
    } = this.props;

    return (
      <TagsInput
        onlyUnique
        value={value}
        tagDisplayProp={tagDisplayProp}
        renderTag={renderTag || this.renderTag}
        ref={ref => {
          this.inputRawElement = ref;
        }}
        inputProps={{
          placeholder: tr(
            value.length > 0
              ? 'common.autocomplete.tags_input.placeholder.new_word'
              : placeholder || 'common.autocomplete.tags_input.placeholder'
          ),
          ...inputProps
        }}
        onChange={this.setValue}
        renderLayout={this.renderLayout}
        addKeys={[9, 13, 188]}
        addOnPaste
        pasteSplit={data => data.split(',').map(d => d.trim())}
        renderInput={
          disableAutosuggestion
            ? this.renderTagsInput(eventHandler, this.clearInput)
            : this.autosuggestRenderInput(eventHandler, this.clearInput)
        }
        className={cx('react-tagsinput', { 'react-tagsinput--empty': !value.length }, className)}
      />
    );
  }
}

export default withTr(LwTagsInput);
