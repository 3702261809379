import {
  changeMessageDraft,
  useIsLastMessage,
  useMessageDraft
} from 'features/aiWriter/AiWriterSidebar/steps/chat/chatStore';
import { Message } from 'features/aiWriter/AiWriterSidebar/steps/chat/Message';
import {
  FlashActionType,
  predefinedFlashActions
} from 'features/aiWriter/AiWriterSidebar/steps/chat/predefinedFlashActions';
import { useSendMessage } from 'features/aiWriter/AiWriterSidebar/steps/chat/useSendMessage';
import { AiWriterSupportedLanguageAndCountryCode } from 'features/aiWriter/aiWriterSupportedLanguageAndCountryCodes';
import { getCurrentModelLanguageAndCountry } from 'features/aiWriter/store/selectors';
import {
  useShouldShowWordsLimitReachedModal,
  useShowWordsLimitReachedModal
} from 'features/pricing/hook/useYouRunOutOfWordsModal';
import { GAEvents } from 'services/tracking/GAEvents';
import { useAppSelector } from 'store/hooks';
import { useStableCallback } from 'utils/react/useStableCallback';

export const usePredefinedFlashActions = ({
  message,
  isInline
}: {
  message: Message;
  isInline?: boolean;
}) => {
  const { currentModelCountry: country, currentModelLanguage: language } = useAppSelector(
    getCurrentModelLanguageAndCountry
  );
  const languageCountryCode = `${language}_${country}` as AiWriterSupportedLanguageAndCountryCode;

  const { shouldShowRunOutOfWordsModal } = useShouldShowWordsLimitReachedModal();
  const onlyShowModal = useShowWordsLimitReachedModal();

  const sendMessage = useSendMessage();
  const messageDraft = useMessageDraft();
  const isLastMessage = useIsLastMessage(message);

  const sendCurrentDraftMessage = useStableCallback(() => {
    if (shouldShowRunOutOfWordsModal()) {
      onlyShowModal();
      return;
    }
    sendMessage.mutate(messageDraft);
  });

  const flashActions = predefinedFlashActions[languageCountryCode] ?? [];

  const handleRewrite = async () => {
    const rewriteText = flashActions.find(f => f.type === FlashActionType.rewrite)?.text ?? '';
    const content = isLastMessage ? rewriteText : `${rewriteText}: ${message.text}`;
    GAEvents.flashActionExecuted({
      action: 'rewrite',
      language,
      country,
      isInline
    });
    await changeMessageDraft({
      text: content
    });
    sendCurrentDraftMessage();
  };

  const handleContinue = async () => {
    const continueText = flashActions.find(f => f.type === FlashActionType.continue)?.text ?? '';
    const content = isLastMessage ? continueText : `${continueText}: ${message.text}`;
    GAEvents.flashActionExecuted({
      action: 'continue',
      language,
      country,
      isInline
    });
    await changeMessageDraft({
      text: content
    });
    sendCurrentDraftMessage();
  };

  const handleImprove = async () => {
    const improveText = flashActions.find(f => f.type === FlashActionType.improve)?.text ?? '';
    const content = isLastMessage ? improveText : `${improveText}: ${message.text}`;
    GAEvents.flashActionExecuted({
      action: 'improve',
      language,
      country,
      isInline
    });
    await changeMessageDraft({
      text: content
    });
    sendCurrentDraftMessage();
  };

  const handleSummarize = async () => {
    const summarizeText = flashActions.find(f => f.type === FlashActionType.summarize)?.text ?? '';
    const content = isLastMessage ? summarizeText : `${summarizeText}: ${message.text}`;
    GAEvents.flashActionExecuted({
      action: 'summarize',
      language,
      country,
      isInline
    });
    await changeMessageDraft({
      text: content
    });
    sendCurrentDraftMessage();
  };

  const handleCustomAction = async (command: string) => {
    const content = isLastMessage ? command : `${command}: ${message.text}`;
    GAEvents.flashActionExecuted({
      action: 'custom',
      language,
      country,
      prompt: command,
      isInline
    });
    await changeMessageDraft({
      text: content
    });
    sendCurrentDraftMessage();
  };
  return { handleRewrite, handleContinue, handleImprove, handleSummarize, handleCustomAction };
};
