import {
  testerGeneratedTextFromApi,
  testerGeneratedTextToApi
} from 'features/aiTester/store/utils/mappers/testerGeneratedTextMapper';
import { SystemTonality } from 'features/aiWriter/tonality/SystemTonality';
import { ApiAiTesterProject } from 'services/api/aiTester/types';

import { TesterProject } from '../../types';
import {
  wordAttributeOptionsFromWordAttributesConfig,
  wordAttributesConfigFromWordAttributeOptions
} from './wordAttributesMappers';

export const testerProjectFromApi = (project: ApiAiTesterProject): TesterProject => ({
  id: String(project.id),
  name: project.name,
  dimensions: project.dimensions,
  texts: project.texts.map(testerGeneratedTextFromApi) ?? [],
  suggestions: project.text_inspirations.map(testerGeneratedTextFromApi) ?? [],
  embeddingModelId: project.embedding_model_id,
  mapperId: project.mapper_id ?? '',
  generateTextConfig:
    project.output_type && project.audience_id
      ? {
          audienceId: Number(project.audience_id),
          outputType: project.output_type,
          // TODO rename to keywords2
          text: project.brief,
          keywords: project.keywords,
          brand: project.keywords2,
          systemTonality: project.system_tonality as SystemTonality[],
          userTonality: project.user_tonality,
          nTextItems: project.n_text_items,
          personalityId: project.personality_id ?? undefined,
          informationList: project.informations ?? undefined
        }
      : undefined,
  manualDimensions: project.manual_dimensions,
  scoreByOpenRate: project.score_by_open_rate,
  wordAttributes: wordAttributeOptionsFromWordAttributesConfig(project.word_attribute_config),
  updatedAt: project.updated_at,
  isLoading: false
});

export const testerProjectToApi = (project: TesterProject): ApiAiTesterProject => ({
  id: Number(project.id),
  name: project.name,
  texts: project.texts.map(testerGeneratedTextToApi) ?? null,
  text_inspirations: project.suggestions.map(testerGeneratedTextToApi) ?? null,
  embedding_model_id: project.embeddingModelId,
  mapper_id: project.mapperId,
  output_type: project.generateTextConfig?.outputType ?? null,
  audience_id: project.generateTextConfig?.audienceId
    ? Number(project.generateTextConfig?.audienceId)
    : null,
  keywords: project.generateTextConfig?.keywords ?? '',
  keywords2: project.generateTextConfig?.brand ?? '',
  brief: project.generateTextConfig?.text ?? '',
  updated_at: project.updatedAt,
  dimensions: project.dimensions,
  manual_dimensions: project.manualDimensions,
  score_by_open_rate: project.scoreByOpenRate,
  word_attribute_config: wordAttributesConfigFromWordAttributeOptions(project.wordAttributes),
  user_tonality: project.generateTextConfig?.userTonality ?? [],
  system_tonality: (project.generateTextConfig?.systemTonality ?? []) as SystemTonality[],
  n_text_items: project.generateTextConfig?.nTextItems ?? 1,
  personality_id: project.generateTextConfig?.personalityId ?? null,
  information_ids: project.generateTextConfig?.informationList?.map(info => info.id) ?? undefined
});
