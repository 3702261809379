import { Placement } from 'popper.js';
import React, { forwardRef } from 'react';
import { css } from 'styled-components';

const opposites = {
  left: 'right',
  right: 'left',
  top: 'bottom',
  bottom: 'top'
};

type SimplePlacement = keyof typeof opposites;

const arrow = (placement: SimplePlacement, size: number, pseudo: 'before' | 'after') => {
  const margin = pseudo === 'before' ? size + 1 : size;
  const borderColor = pseudo === 'before' ? 'rgba(0, 0, 0, 0.2)' : '#fff';
  const sides = ['top', 'bottom'].includes(placement) ? ['right', 'left'] : ['top', 'bottom'];

  return css`
      ${opposites[placement]}: -${margin}px;
      border-${placement}: ${size}px solid ${borderColor};
      ${sides.map(side => css`border-${side}: ${size}px solid transparent;`)}
    `;
};

type Props = {
  placement?: Placement;
  size?: number;
  style?: React.CSSProperties;
  offset?: number;
};

const DropdownArrow = forwardRef<HTMLSpanElement, Props>(
  ({ placement = 'bottom', size = 5, style = {}, offset = 0 }, ref) => {
    const position = placement.split('-')[0] as SimplePlacement & 'auto';
    if (position === 'auto') return null;

    const opposite = opposites[position];
    const orientation = position === 'left' || position === 'right' ? 'horizontal' : 'vertical';

    return (
      <span
        css={css`
          position: absolute;
          z-index: 1;
          transform: ${orientation === 'vertical'
            ? `translateX(${-size}px)`
            : `translateY(${-size}px)`};
          ${opposite}: ${offset}px;

          &:before {
            position: absolute;
            display: inline-block;
            content: '';
            ${arrow(position, size, 'before')}
          }

          &:after {
            position: absolute;
            display: inline-block;
            content: '';
            ${arrow(position, size, 'after')}
          }
        `}
        style={style}
        ref={ref}
      />
    );
  }
);

export default DropdownArrow;
