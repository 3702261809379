import cuid from 'cuid';
import { GeneratedText } from 'features/textGenerator/store/types';

export const testerGeneratedTextToApi = (generatedText: GeneratedText) => ({
  id: generatedText.id,
  text: generatedText.text,
  generationId: generatedText.generationId,
  textItemId: generatedText.textItemId
});

export const testerGeneratedTextFromApi = (generatedText: string | GeneratedText) => {
  // #tech-debt: https://app.clickup.com/t/862jvgcy4
  if (typeof generatedText === 'string') {
    return {
      id: cuid(),
      text: generatedText
    };
  } else {
    return {
      id: generatedText.id ?? cuid(),
      text: generatedText.text,
      generationId: generatedText.generationId,
      textItemId: generatedText.textItemId
    };
  }
};
