import AddIcon from '@mui/icons-material/Add';
import { Button } from '@mui/material';
import { FormattedMessage } from 'react-intl';

export type WordStatisticsColorVariant = 'primary' | 'secondary' | 'green';

type Props = {
  onClick: () => void;
};

const SeoAddAllButton = ({ onClick }: Props) => {
  return (
    <Button startIcon={<AddIcon />} onClick={onClick}>
      <FormattedMessage id="aiWriter.inspirations.seoAnalysis.add_all" />
    </Button>
  );
};

export default SeoAddAllButton;
