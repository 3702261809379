import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import Toast from 'components/toasts/Toast';
import { BuilderModularWorkflow } from 'features/modular-workflow/builder/types-builder';
import { httpCreateModularWorkflow } from 'services/backofficeIntegration/http/endpoints/modularWorkflow/httpCreateModularWorkflow';

export const useCreateWorkflowMutation = (
  options?: UseMutationOptions<BuilderModularWorkflow, unknown, BuilderModularWorkflow, unknown>
) =>
  useMutation({
    mutationFn: httpCreateModularWorkflow.callEndpoint,
    onSuccess: () => {
      Toast.success('modular_workflow.builder.update_successful');
    },
    ...options
  });
