import Button from '@mui/material/Button';
import FormattedMessage from 'features/i18n/FormattedMessage';
import { IntlMessageKeys } from 'features/i18n/IntlMessageKeys';

export type ModalButton = {
  text: IntlMessageKeys;
  onClick: () => void;
  type?: 'button' | 'submit' | 'reset';
  color?: 'inherit' | 'primary' | 'secondary';
};

type Props = {
  buttons?: ModalButton[];
  defaultOnCancel?: () => void;
  defaultOnOK?: () => void;
};

const ModalButtons = ({ buttons = [], defaultOnCancel, defaultOnOK }: Props) => {
  if (!buttons.length) {
    return (
      <>
        <Button color="secondary" onClick={defaultOnCancel}>
          <FormattedMessage id="common.discard" />
        </Button>
        <Button type="submit" color="primary" onClick={defaultOnOK}>
          <FormattedMessage id="common.confirm" />
        </Button>
      </>
    );
  }

  return (
    <>
      {buttons.map(({ type, color, text, onClick }) => (
        <Button type={type || 'button'} key={text} color={color} onClick={onClick}>
          <FormattedMessage id={text} />
        </Button>
      ))}
    </>
  );
};

export default ModalButtons;
