import { ListItemText, MenuItem } from '@mui/material';
import FlexContainer from 'components/FlexContainer';
import { useGetProjectOrPreselectedLanguageAndCountry } from 'features/aiWriter/hooks/useGetProjectOrPreselectedLanguageAndCountry';
import { PersonalityDto } from 'services/backofficeIntegration/http/dtos/PersonalityDto';
import styled from 'styled-components';

type Props = {
  currentPersonality?: PersonalityDto | null;
  personalities?: PersonalityDto[];
  currentModelId: string;
};

/**
 * This component renders a missing option (missing in the list of personalities received from the API)
 * in the dropdown.
 * The personality could be missing if the user has used it in a chat but
 * deleted it from the list of personalities. In this case we get it from the
 * chat conversation endpoint, but it's missing the list of personalities.
 */
export const MissingOption = ({ currentPersonality, personalities, currentModelId }: Props) => {
  const { language: modelLanguage, country: modelCountry } =
    useGetProjectOrPreselectedLanguageAndCountry(currentModelId);

  // Only show the current personality if the language and country match
  // with the current document/selected language and country
  if (
    currentPersonality &&
    (modelLanguage !== currentPersonality.language || modelCountry !== currentPersonality.country)
  ) {
    return null;
  }

  const isMissingCurrentPersonalityInList =
    !!currentPersonality && !personalities?.find(p => p.id === currentPersonality.id);

  if (!isMissingCurrentPersonalityInList) {
    return null;
  }

  return (
    <SelectItem>
      <FlexContainer direction="row">
        <ListItemText>{currentPersonality.label}</ListItemText>
      </FlexContainer>
    </SelectItem>
  );
};

const SelectItem = styled(MenuItem)`
  gap: ${({ theme }) => theme.spacings.small};
`;
