import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import SendIcon from '@mui/icons-material/Send';
import {
  IconButton,
  InputBase,
  ListItemIcon,
  MenuItem,
  MenuList,
  Paper,
  Typography
} from '@mui/material';
import useEditor from 'features/aiWriter/AiWriterTextEditor/hooks/useEditor';
import {
  flashActionsCategoryAlias,
  useTemplateCategoriesQuery
} from 'features/aiWriter/commandTemplates/useTemplateCategoriesQuery';
import { useTemplatesQuery } from 'features/aiWriter/commandTemplates/useTemplatesQuery';
import { selectAndReturnCommand } from 'features/aiWriter/slashCommands/CustomFlashActionsOutput';
import { CustomFlashActionsSearch } from 'features/aiWriter/slashCommands/CustomFlashActionsSearch';
import {
  setFlashActionsSelectedAction,
  useFlashActionsSelectedText
} from 'features/aiWriter/slashCommands/hooks/useFlashActionsStore';
import {
  Option,
  SubMenuType,
  useGetDynamicBaseFlashActionOptions
} from 'features/aiWriter/slashCommands/hooks/useGetDynamicBaseFlashActionOptions';
import { useGetEditorsNodeState } from 'features/aiWriter/slashCommands/hooks/useGetEditorsNodeState';
import useQuickActionInEditor from 'features/aiWriter/slashCommands/hooks/useQuickActionInEditor';
import { OutputPanel } from 'features/aiWriter/slashCommands/OutputPanel';
import { calculateTotalOptions } from 'features/aiWriter/slashCommands/utils/calculateTotalOptions';
import { getCurrentModelLanguage } from 'features/aiWriter/store/selectors';
import {
  useShouldShowWordsLimitReachedModal,
  useShowWordsLimitReachedModal
} from 'features/pricing/hook/useYouRunOutOfWordsModal';
import { useEffect, useMemo, useRef, useState } from 'react';
import { SortingTypes } from 'services/backofficeIntegration/http/endpoints/textGeneration/commandTemplates/httpGetCommandTemplates';
import { GAEvents } from 'services/tracking/GAEvents';
import gtmIds from 'services/tracking/GTMIds';
import { withGtmInteraction } from 'services/tracking/withGtmInteraction';
import { useAppSelector } from 'store/hooks';
import styled from 'styled-components';
import { useDebounce } from 'use-debounce/lib';
import useTr from 'utils/hooks/useTr';
import { assertNonNullable } from 'utils/typescript/nonNullable';

const DEBOUNCE_DELAY = 300;

export type FlashActionsInputProps = {
  onClose: () => void;
};

export const FlashActionsInput = (props: FlashActionsInputProps) => {
  const { onClose } = props;

  const editor = useEditor();

  const inputRef = useRef<HTMLInputElement>(null);
  const [inputValue, setInputValue] = useState('');
  const [debouncedSearchQuery] = useDebounce(inputValue, DEBOUNCE_DELAY);

  const [showMenu, setShowMenu] = useState(true);
  const [highlightedMenuIndex, setHighlightedMenuIndex] = useState(0);
  const { options: baseOptions, subMenuList: baseOptionsSubmenuList } =
    useGetDynamicBaseFlashActionOptions();

  const [showOutputPanel, setShowOutputPanel] = useState(false);

  const translate = useTr();
  const documentLanguage = useAppSelector(getCurrentModelLanguage);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  const { data: categories } = useTemplateCategoriesQuery();
  const flashActionsId = categories?.filter(
    category => category.alias === flashActionsCategoryAlias
  )[0].id;
  const customFlashActions = useTemplatesQuery({
    category: flashActionsId,
    title: debouncedSearchQuery,
    sorting: SortingTypes.FAVORITES
  });

  const switchToGenerateView = () => {
    setShowOutputPanel(true);
    setShowMenu(false);
  };

  const handleCustomFlashActions = (prompt: string) => {
    setFlashActionsSelectedAction({
      name: 'customActions',
      prePrompt: prompt
    });
    switchToGenerateView();
  };

  const handleCommandFlashAction = () => {
    setFlashActionsSelectedAction({
      name: 'command',
      prePrompt: inputValue
    });
    switchToGenerateView();
  };

  const totalBaseOptions = useMemo(() => calculateTotalOptions(baseOptions), [baseOptions]);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    let action;
    const totalOptions =
      inputValue === '' ? totalBaseOptions : customFlashActions.data?.pages[0].data.length ?? 0; //mk `customFlashActions.data?.pages[0].data.length` a const

    // Prevent default action for the keys we handle
    if (['ArrowUp', 'ArrowDown', 'Enter', 'Escape'].includes(event.key)) {
      event.preventDefault();
    }

    switch (event.key) {
      case 'ArrowUp':
        if (showMenu) {
          setHighlightedMenuIndex(oldIndex => (oldIndex > 0 ? oldIndex - 1 : 0));
        }
        break;
      case 'ArrowDown':
        if (showMenu) {
          setHighlightedMenuIndex(oldIndex =>
            oldIndex < totalOptions - 1 ? oldIndex + 1 : totalOptions - 1
          );
        }
        break;
      case 'Enter':
        if (inputValue === '') {
          // Flatten the options into an array to navigate by index
          const flattenedOptions: Option[] = Array.from(baseOptions.values()).flat(); //maybe lift it up and memoize
          action = flattenedOptions[highlightedMenuIndex];
          handleBaseOptionSelection(action);
        } else if (inputValue !== '' && totalOptions === 0 && flashActionsSelectedText) {
          handleCommandFlashAction();
        } else if (inputValue !== '' && totalOptions === 0) {
          handleInputCommand(inputValue);
        } else {
          action = customFlashActions.data?.pages[0].data[highlightedMenuIndex];
          assertNonNullable(action, 'No action found for the selected index'); // on this level we should always have an action
          handleCustomFlashActions(action.template);
        }

        break;
      case 'Escape':
        onClose();
        break;
      case 'Backspace':
        if (event.key === 'Backspace' && inputValue === '') {
          onClose();
          break;
        }

        if (event.key === 'Backspace' && inputValue.length === 1 && !showMenu) {
          setShowMenu(true);
          break;
        }
        break;
      default:
        break;
    }
  };

  const handleMouseOptionHover = (index: number) => {
    setHighlightedMenuIndex(index);
  };

  const subMenuRefList = useRef<Record<SubMenuType, HTMLLIElement | null>>({
    translate: null,
    customActions: null
  });

  const [isSubMenuOpen, setIsSubMenuOpen] = useState<Record<SubMenuType, boolean>>({
    translate: false,
    customActions: false
  });

  const handleCloseSubMenu = (menuType: string) => {
    setIsSubMenuOpen({ ...isSubMenuOpen, [menuType]: false });
  };

  const handleBaseOptionSelection = (action: Option) => {
    if (action.isSubmenu && action.subMenuType) {
      setIsSubMenuOpen({ ...isSubMenuOpen, [action.subMenuType]: true });

      return;
    }

    if (action.isImmediateAction) {
      onClose();
      action.handler?.(inputValue);

      return;
    }

    if (action.isInputAction && action.prompt && showMenu) {
      selectInputAction(action.prompt);

      return;
    } else if (!showMenu) {
      onClose();
      action.handler?.(inputValue);

      return;
    }

    if (action.name === 'command') {
      setFlashActionsSelectedAction({
        name: action.name
      });
      switchToGenerateView();

      return;
    }

    setFlashActionsSelectedAction({
      name: action.name
    });
    switchToGenerateView();

    function selectInputAction(prompt: string) {
      setInputValue(`${translate(prompt)} `);
      setShowMenu(false);
    }
  };

  const { shouldShowRunOutOfWordsModal } = useShouldShowWordsLimitReachedModal();
  const showWordsLimitReachedModal = useShowWordsLimitReachedModal();
  const flashActionsSelectedText = useFlashActionsSelectedText();
  const handleGenerateTextStream = useQuickActionInEditor();

  const { isEditorEmpty, isCurrentNodeEmptyAndDocumentFilled } = useGetEditorsNodeState();

  const handleInputCommand = (prompt: string) => {
    GAEvents.flashActionInEditorExecuted({
      action: 'quick_action',
      prompt
    });

    if (shouldShowRunOutOfWordsModal()) {
      showWordsLimitReachedModal();
      return;
    }

    const command =
      isEditorEmpty || isCurrentNodeEmptyAndDocumentFilled
        ? inputValue
        : `${prompt}: ${selectAndReturnCommand(editor, flashActionsSelectedText)}`;

    onClose();
    handleGenerateTextStream({ text: command });
  };

  const inputPlaceholderText = () => {
    if (isEditorEmpty || isCurrentNodeEmptyAndDocumentFilled) {
      return translate('aiWriter.flashActions_input.placeholder');
    }

    return translate('aiWriter.flashActions_input.placeholder.selection');
  };

  return (
    <>
      {!showOutputPanel && (
        <StyledInputBase
          startAdornment={<SecondaryAwesomeIcon />}
          endAdornment={
            <IconButton
              {...withGtmInteraction(gtmIds.aiWriter.editor.selectionToolbar.executeFlashAction)}
              onClick={() => handleInputCommand(inputValue)}
            >
              <SendIcon />
            </IconButton>
          }
          placeholder={inputPlaceholderText()}
          inputRef={inputRef}
          value={inputValue}
          onChange={e => setInputValue(e.currentTarget.value)}
          onKeyDown={handleKeyDown}
        />
      )}
      {showOutputPanel && <OutputPanel onClose={onClose} />}

      {showMenu && inputValue !== '' && (
        <CustomFlashActionsSearch
          searchQuery={debouncedSearchQuery}
          highlightedMenuIndex={highlightedMenuIndex}
          onClose={onClose}
          handleMouseOptionHover={handleMouseOptionHover}
          onOptionSelect={switchToGenerateView}
        />
      )}

      {showMenu && inputValue === '' && (
        <StyledPaper elevation={1}>
          <MenuList>
            {[...baseOptions].map(([category, actions]) => (
              <div key={category.name}>
                <MenuCategoryLabel>{translate(category.label)}</MenuCategoryLabel>
                {actions.map(action => {
                  if (
                    action.restrictedForLanguage &&
                    action.restrictedForLanguage !== documentLanguage
                  ) {
                    return null;
                  }

                  return (
                    <StyledMenuItem
                      ref={element => {
                        // we need to check if ref is not null and assign ref to our subMenuRefList if it is a submenu
                        if (element && action.isSubmenu && action.subMenuType) {
                          subMenuRefList.current[action.subMenuType] = element;
                        }
                      }}
                      key={action.name}
                      $showHover={action.index === highlightedMenuIndex}
                      onMouseEnter={() => handleMouseOptionHover(action.index)}
                      onClick={() => handleBaseOptionSelection(action)}
                    >
                      <ListItemIcon>{action.icon}</ListItemIcon>
                      <MenuContentBox>
                        <Typography variant="inherit">{translate(action.label)}</Typography>
                        {action.isSubmenu && <ChevronRightIcon />}
                      </MenuContentBox>
                    </StyledMenuItem>
                  );
                })}
              </div>
            ))}
          </MenuList>

          {baseOptionsSubmenuList.map(({ type: menuType, menuComponent: MenuComponent }) => {
            return (
              <MenuComponent
                key={menuType}
                anchorElement={subMenuRefList.current[menuType]}
                close={() => handleCloseSubMenu(menuType)}
                isOpen={isSubMenuOpen[menuType] ?? false}
                onSelect={switchToGenerateView}
              />
            );
          })}
        </StyledPaper>
      )}
    </>
  );
};

export const PrimaryAwesomeIcon = styled(AutoAwesomeIcon)`
  color: ${({ theme }) => theme.colors.primary};
`;

export const SecondaryAwesomeIcon = styled(AutoAwesomeIcon)`
  color: ${({ theme }) => theme.colors.secondaryColorMain};
`;

export const StyledMenuItem = styled(MenuItem)<{ $showHover?: boolean }>`
  background-color: ${({ $showHover, theme }) =>
    $showHover ? theme.colors.secondaryColorSelected : 'transparent'};

  &:hover {
    background-color: ${({ theme }) => theme.colors.secondaryColorHover};
  }
`;

const StyledInputBase = styled(InputBase)`
  width: 100%;
  border-radius: ${({ theme }) => theme.borderRadius.one};
  margin-top: 0.25rem;
  padding: 6px 12px;
  vertical-align: baseline;
  border: 2px solid ${({ theme }) => theme.colors.secondaryColorMain};
  background-color: ${({ theme }) =>
    theme.mode === 'dark' ? theme.colors.backgroundPaperElevation2 : 'white'};
  display: inline-flex;
  gap: ${({ theme }) => theme.spacings.two};
`;

export const StyledPaper = styled(Paper)`
  width: 100%;
  border-radius: ${({ theme }) => theme.borderRadius.small};

  margin: 0.5rem 0;
`;

const MenuCategoryLabel = styled.div`
  padding: 0.25rem 1rem;
  font-family: Roboto;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.5rem;
  letter-spacing: 0.01063rem;
`;

const MenuContentBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
