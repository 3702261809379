import { List } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import useGetIsSubjectLineAvailable from 'features/subjectLines/hooks/useGetIsSubjectLineAvailable';
import { useRewriteSubjectLine } from 'features/subjectLines/hooks/useRewriteSubjectLine';
import { FieldArray, useFormikContext } from 'formik';
import { createRef } from 'react';
import FlipMove from 'react-flip-move';
import styled from 'styled-components';

import useGetScoredSubjectLine from '../../hooks/useGetScoredSubjectLine';
import { ScoredSubjectLine } from '../../store/types';
import { compareOpenRates } from '../../store/utils';
import {
  CampaignConfigFormPayload,
  projectIdField,
  subjectLinesField,
  suggestionsField
} from '../CampaignConfigForm';
import SelectedSubjectLine from './SelectedSubjectLine';
import UserSubjectLine from './UserSubjectLine';

const useListStyles = makeStyles({
  root: {
    width: '100%'
  }
});

const SubjectLinesComparison = () => {
  const listClasses = useListStyles();

  const { values, setFieldValue } = useFormikContext<CampaignConfigFormPayload>();

  const getIsSubjectLineAvailable = useGetIsSubjectLineAvailable({
    audienceId: values[projectIdField]
  });
  const getScoredSubjectLine = useGetScoredSubjectLine({ audienceId: values[projectIdField] });
  const rewriteSubjectLine = useRewriteSubjectLine({ audienceId: values[projectIdField] });

  const sortAndSetSubjectLines = (subjectLines: ScoredSubjectLine[]) =>
    setFieldValue(subjectLinesField, [...subjectLines].sort(compareOpenRates));

  const sortAndSetSuggestions = (suggestions: ScoredSubjectLine[]) =>
    setFieldValue(suggestionsField, [...suggestions].sort(compareOpenRates));

  const handleRemove = (
    index: number,
    remove: (index: number) => ScoredSubjectLine | undefined
  ) => {
    const deletedSubjectLine = values.subjectLines[index];

    if (!deletedSubjectLine.userGenerated) {
      sortAndSetSuggestions([...values.suggestions, deletedSubjectLine]);
    }

    remove(index);
  };

  const handleEdit = async (id: string, text: string) => {
    const isSubjectLineAvailable = await getIsSubjectLineAvailable(text);

    if (isSubjectLineAvailable) {
      const scoredSubjectLine = await getScoredSubjectLine(text);

      if (scoredSubjectLine) {
        const newScoredSubjectLine: ScoredSubjectLine = {
          ...scoredSubjectLine,
          id,
          userGenerated: true
        };

        const subjectLines = values.subjectLines.map(s => (s.id === id ? newScoredSubjectLine : s));
        sortAndSetSubjectLines(subjectLines);

        return true;
      }
    }

    return false;
  };

  const handleAdd = async (subjectLine: string) => {
    const isSubjectLineAvailable = await getIsSubjectLineAvailable(subjectLine);

    if (isSubjectLineAvailable) {
      const scoredSubjectLine = await getScoredSubjectLine(subjectLine);

      if (scoredSubjectLine) {
        const newScoredSubjectLine: ScoredSubjectLine = {
          ...scoredSubjectLine,
          userGenerated: true
        };

        sortAndSetSubjectLines([...values[subjectLinesField], newScoredSubjectLine]);

        return true;
      }
    }

    return false;
  };

  const handleRewrite = async (text: string) => {
    const rewritedSubjectLines = await rewriteSubjectLine({ existingSubjectLine: text });

    const newSubjectLines: ScoredSubjectLine[] = rewritedSubjectLines.map(s => ({
      ...s,
      userGenerated: true
    }));

    sortAndSetSubjectLines([...values[subjectLinesField], ...newSubjectLines]);
  };

  return (
    <SubjectLinesComparisonContainer>
      <FieldArray name={subjectLinesField}>
        {({ remove }) => (
          <Content>
            <SelectedSubjectLinesList classes={listClasses}>
              {/* @ts-expect-error Package doesn't yet define a children prop (React 18) */}
              <FlipMove duration={750}>
                {values.subjectLines.map((subjectLine, index) => (
                  <SelectedSubjectLine
                    ref={createRef()}
                    key={subjectLine.id}
                    name={`${subjectLinesField}.${index}`}
                    projectId={values.projectId}
                    onRemove={() => handleRemove(index, remove)}
                    onEdit={() => handleEdit(subjectLine.id, subjectLine.subjectLine)}
                    onRewrite={() => handleRewrite(subjectLine.subjectLine)}
                  />
                ))}
              </FlipMove>
            </SelectedSubjectLinesList>
            <UserSubjectLine onAdd={handleAdd} />
          </Content>
        )}
      </FieldArray>
    </SubjectLinesComparisonContainer>
  );
};

const SubjectLinesComparisonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
`;

const Content = styled.div`
  width: 100%;
`;

const SelectedSubjectLinesList = styled(List)`
  max-height: 420px;
  overflow: auto;
`;

export default SubjectLinesComparison;
