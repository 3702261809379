import { AiWriterSidebarPanel } from 'features/aiWriter/AiWriterSidebar/sidebar/AiWriterSidebarPanel';
import { TextInspirationsStepHeader } from 'features/aiWriter/AiWriterSidebar/steps/textInspirations/TextInspirationsStepHeader';
import { ActionsSpinner, BasicUnfavoriteAction } from 'features/aiWriter/markdown/actions';
import {
  BasicMarkdownPreview,
  MarkdownTile,
  NeutralButtonSurface
} from 'features/aiWriter/markdown/MarkdownTile';
import { useCopyMarkdownToEditor } from 'features/aiWriter/markdown/useCopyMarkdownToEditor';
import { setInspirationsCurrentStep } from 'features/aiWriter/store/actions/config/actions';
import { deleteFavoriteThunk } from 'features/aiWriter/store/actions/favorites/thunks/deleteFavoriteThunk';
import {
  getActiveTab,
  getAreFavoritesLoading,
  getFavorites
} from 'features/aiWriter/store/selectors';
import { AiWriterSidebarStep, Favorite } from 'features/aiWriter/store/types';
import { Loader } from 'features/churnModal/loader';
import { trackingWrapper } from 'features/tracking/wrapper/TrackingWrapper';
import FlipMove from 'react-flip-move';
import gtmIds from 'services/tracking/GTMIds';
import { withGtmInteraction } from 'services/tracking/withGtmInteraction';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import styled from 'styled-components';
import useTr from 'utils/hooks/useTr';

export const FavoritesSubStep = () => {
  const dispatch = useAppDispatch();
  const tr = useTr();

  const copyToEditor = useCopyMarkdownToEditor();

  const favorites = useAppSelector(getFavorites);
  const areFavoritesLoading = useAppSelector(getAreFavoritesLoading);
  const { generateTextConfig, id: tabId } = useAppSelector(getActiveTab);

  const addFavoriteHandler = (favorite: Favorite) => {
    const { text, generationId, textItemId } = favorite;
    copyToEditor(text);

    trackingWrapper.track('aiWriterSidebarFavoritesSelectFavorite', {
      documentId: tabId,
      text,
      generationId,
      textItemId,
      audienceModelId: generateTextConfig.audienceId
    });
  };

  const renderTileActions = (favorite: Favorite) =>
    favorite.isLoading ? (
      <ActionsSpinner key="spinner" />
    ) : (
      <BasicUnfavoriteAction
        key="unfavorite"
        withDeprecatedGtm
        gtmId={gtmIds.aiWriter.deleteFavorite}
        onClick={() => dispatch(deleteFavoriteThunk(favorite))}
      />
    );

  return (
    <AiWriterSidebarPanel
      title="aiWriter.inspirations.aiwriter.title"
      infoButton={<TextInspirationsStepHeader />}
      onBack={() => {
        dispatch(
          setInspirationsCurrentStep({ step: AiWriterSidebarStep.aiWriter, subStep: 'main' })
        );
      }}
    >
      {/* @ts-expect-error Package doesn't yet define a children prop (React 18) */}
      <CardList>
        {!areFavoritesLoading ? (
          favorites.map(favorite => (
            <MarkdownTile
              key={favorite.id}
              surface={
                <NeutralButtonSurface
                  disabled={favorite.isLoading}
                  onClick={() => addFavoriteHandler(favorite)}
                  aria-label={tr('aiWriter.copy_to_editor')}
                  {...withGtmInteraction(gtmIds.aiWriter.selectFavorite, {
                    withDeprecatedStyle: true
                  })}
                />
              }
              markdown={<BasicMarkdownPreview>{favorite.text}</BasicMarkdownPreview>}
              rightActions={renderTileActions(favorite)}
            />
          ))
        ) : (
          <Loader />
        )}
      </CardList>
    </AiWriterSidebarPanel>
  );
};

const CardList = styled(FlipMove)`
  display: grid;
  gap: ${({ theme }) => theme.spacings.small};
  padding-block: ${({ theme }) => theme.spacings.medium};
`;
