import { Search } from '@mui/icons-material';
import { InputAdornment, TextField } from '@mui/material';
import { ChangeEvent } from 'react';
import styled from 'styled-components';
import useTr from 'utils/hooks/useTr';

type Props = {
  placeholder?: string;
  onChange?: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  value?: string;
};

export const SearchField = ({ placeholder, onChange, value }: Props) => {
  const translate = useTr();

  return (
    <StyledSearchField
      variant="standard"
      value={value}
      onChange={onChange}
      placeholder={placeholder ?? translate('common.search')}
      InputProps={{
        disableUnderline: true,
        startAdornment: (
          <InputAdornment position="start">
            <Search />
          </InputAdornment>
        )
      }}
    />
  );
};

export const StyledSearchField = styled(TextField)`
  padding: ${({ theme }) => `${theme.spacings.two} ${theme.spacings.three}`};
  border-radius: ${({ theme }) => theme.borderRadius.one};
  justify-content: center;
`;
