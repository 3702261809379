import { Typography } from '@mui/material';
import FlexContainer from 'components/FlexContainer';
import { FormattedMessage } from 'react-intl';

type Props = {
  imagesNotFound: boolean;
};

const ImagesEmptyState = ({ imagesNotFound }: Props) => {
  const content = imagesNotFound ? (
    <FlexContainer alignItems="center">
      <Typography variant="body1">
        <FormattedMessage id="aiWriter.inspirations.images.error" />
      </Typography>
    </FlexContainer>
  ) : null;
  return content;
};

export default ImagesEmptyState;
