// #tech-debt: https://app.clickup.com/t/38vnp8r

import ErrorIcon from '@mui/icons-material/Error';
import FlexContainer from 'components/FlexContainer';
import { ReactElement, ReactNode } from 'react';
import styled from 'styled-components';

type Props = {
  children: ReactNode;
};

const NotificationHint = ({ children }: Props): ReactElement => (
  <Root direction="row" alignItems="center">
    <StyledInfoIcon width={24} height={24} />
    <Body>{children}</Body>
  </Root>
);

const Root = styled(FlexContainer)`
  padding: ${({ theme }) => `${theme.spacings.two} ${theme.spacings.three}`};
  margin-bottom: ${({ theme }) => theme.spacings.three};

  background: ${({ theme }) => theme.colors.infoMain};
  border-radius: ${({ theme }) => theme.borderRadius.one};
  color: ${({ theme }) => theme.colors.textContrast};
`;

const Body = styled.div`
  width: 100%;
`;

const StyledInfoIcon = styled(ErrorIcon)`
  margin-right: ${({ theme }) => theme.spacings.one};
`;

export default NotificationHint;
