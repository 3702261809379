import {
  collapseSelection,
  getPointBefore,
  insertBreak,
  PlateEditor,
  select
} from '@udecode/plate';
import Toast from 'components/toasts/Toast';
import useEditor from 'features/aiWriter/AiWriterTextEditor/hooks/useEditor';
import { useGenerateTextInDocument } from 'features/aiWriter/AiWriterTextEditor/hooks/useGenerateTextInDocument';
import { minAmountOfCharactersToBeUsedAsContext } from 'features/aiWriter/AiWriterTextEditor/utils/consts';
import { getRangeTextWithSeparatedBlocks } from 'features/aiWriter/AiWriterTextEditor/utils/getRangeTextWithSeparatedBlocks';
import { getActiveTab } from 'features/aiWriter/store/selectors';
import { clearFakeSelection } from 'features/plate/customPlugins/createFakeSelectionPlugin';
import { trackingWrapper } from 'features/tracking/wrapper/TrackingWrapper';
import { FormattedMessage } from 'react-intl';
import { useAppSelector } from 'store/hooks';
import isNotEmptyString from 'utils/isNotEmptyString';

const maxAmountOfCharactersToBeUsedAsContext = 18000;

export function useTriggerCommandAction() {
  const editor = useEditor();
  const generateMutation = useGenerateTextInDocument({
    outputType: 'command',
    toasts: {
      loading: <FormattedMessage id="aiWriter.command.toast_start" />,
      success: <FormattedMessage id="aiWriter.command.toast_end" />,
      error: <FormattedMessage id="aiWriter.command.toast_end.failed" />
    }
  });
  const { generateTextConfig, id: tabId } = useAppSelector(getActiveTab);

  return async ({ isHotKeyUsage = false }) => {
    if (!editor) {
      return;
    }

    const command = selectAndReturnCommand(editor, isHotKeyUsage);

    if (!command || command.trim().length < minAmountOfCharactersToBeUsedAsContext) {
      Toast.warning('aiWriter.expand.toast_not_enough_char', {
        minAmount: minAmountOfCharactersToBeUsedAsContext
      });
      return;
    }

    if (command.trim().length > maxAmountOfCharactersToBeUsedAsContext) {
      Toast.warning('aiWriter.expand.toast_too_many_char', {
        amount: maxAmountOfCharactersToBeUsedAsContext
      });
      return;
    }

    // Clear selection before endpoint call to close floating toolbar
    collapseSelection(editor, { edge: 'end' });

    clearFakeSelection(editor);

    trackingWrapper.track('aiWriterToolbarCommand', {
      documentId: tabId,
      fromHotkey: isHotKeyUsage,
      text: command,
      audienceModelId: generateTextConfig.audienceId
    });

    // TODO: If the selection was on a list element, the list must be closed
    //       insertBreakList() exists, but doesn't work/help/do the job
    //       gl hf

    insertBreak(editor);

    await generateMutation.mutateAsync({ text: command });
  };
}

function selectAndReturnCommand(editor: PlateEditor, isHotKeyUsage: boolean): string {
  const selection = editor.selection;

  if (!selection) {
    return '';
  }

  let command = getRangeTextWithSeparatedBlocks(editor, selection);
  if (!isHotKeyUsage || isNotEmptyString(command)) {
    return command;
  }

  const closestBlockEnd = getPointBefore(editor, selection, { unit: 'block' });
  if (!closestBlockEnd) {
    return '';
  }

  const currentBlock = {
    anchor: selection.anchor,
    focus: closestBlockEnd
  };

  command = getRangeTextWithSeparatedBlocks(editor, currentBlock);

  if (isNotEmptyString(command)) {
    select(editor, currentBlock);
    return command;
  }

  const previousBlock = {
    anchor: closestBlockEnd,
    focus: { path: closestBlockEnd.path, offset: 0 }
  };
  select(editor, previousBlock);

  return getRangeTextWithSeparatedBlocks(editor, previousBlock);
}
