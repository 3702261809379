import { Button, LinearProgress } from '@mui/material';
import { ImagesInspirations } from 'features/aiWriter/AiWriterSidebar/steps/images/ImagesInspirations';
import { getIsUnsplashImagesLimitReached } from 'features/pricing/store/selectors';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import gtmIds from 'services/tracking/GTMIds';
import { useAppSelector } from 'store/hooks';
import styled from 'styled-components';

type Props = {
  loadingMore: boolean;
  disableLoadMore: boolean;

  onClick: React.MouseEventHandler<HTMLButtonElement>;
};

const ImagesInspirationsState = ({ loadingMore, disableLoadMore, onClick }: Props) => {
  const isUnsplashImagesLimitReached = useAppSelector(getIsUnsplashImagesLimitReached);

  const displayLoadMoreButton = !loadingMore && !isUnsplashImagesLimitReached;

  return (
    <Root>
      <ImagesInspirations />
      {loadingMore && <LinearProgress />}
      {displayLoadMoreButton && (
        <Button
          id={gtmIds.aiWriter.images.loadMore}
          variant="contained"
          onClick={onClick}
          disabled={disableLoadMore}
          fullWidth={true}
          sx={theme => ({
            marginBottom: theme.spacing(2)
          })}
        >
          <FormattedMessage id="aiWriter.inspirations.images.load_more" />
        </Button>
      )}
    </Root>
  );
};

const Root = styled.div``;

export default ImagesInspirationsState;
