import cx from 'classnames';
import React from 'react';

type OwnProps = {
  size?: number;
  sm?: number;
  md?: number;
  lg?: number;
  xl?: number;
  className?: string;
  children?: React.ReactNode;
};

type Props = OwnProps;

const Col = ({ size, sm, md, lg, xl, className, ...props }: Props) => {
  let responsiveClasses = Object.entries({ sm, md, lg, xl })
    .map(([key, value]) => (value ? `col-${key}-${value}` : null))
    .filter(Boolean);

  if (!size && !responsiveClasses.length) {
    responsiveClasses = ['col'];
  }
  const classes = cx(size && `col-${size}`, responsiveClasses, className);

  return <div className={classes} {...props} />;
};

export default Col;
