import { useModal } from 'components/modals';
import { SetupModalProps } from 'features/aiTester/modals/setup/SetupModal';
import { useCallback } from 'react';

export default function useShowSetupModal() {
  const { showModal } = useModal();

  return useCallback(
    (props?: SetupModalProps) => {
      showModal('TESTING_SETUP_MODAL', { size: 472, ...props });
    },
    [showModal]
  );
}
