import FlexContainer from 'components/FlexContainer';
import { AIModelsTable } from 'components/profile/components/feature-comparison/AIModelsTable';
import { BrandVoiceTable } from 'components/profile/components/feature-comparison/BrandVoiceTable';
import { CollaborationTable } from 'components/profile/components/feature-comparison/CollaborationTable';
import { ContentGenerationCapabilitiesTable } from 'components/profile/components/feature-comparison/ContentGenerationCapabilitiesTable';
import { IntegrationsTable } from 'components/profile/components/feature-comparison/IntegrationsTable';
import { NeuroflashSuiteTable } from 'components/profile/components/feature-comparison/NeuroflashSuiteTable';
import { PricingPlanTable } from 'components/profile/components/feature-comparison/PricingPlanTable';
import { SupportTable } from 'components/profile/components/feature-comparison/SupportTable';
import { UsageCreditsTable } from 'components/profile/components/feature-comparison/UsageCreditsTable';
import gtmIds from 'services/tracking/GTMIds';
import styled from 'styled-components';

export const FeatureComparisonSection = () => (
  <Root id={gtmIds.modals.featuresComparisonTable}>
    <PricingPlanTable />
    <UsageCreditsTable />
    <NeuroflashSuiteTable />
    <AIModelsTable />
    <ContentGenerationCapabilitiesTable />
    <BrandVoiceTable />
    <CollaborationTable />
    <IntegrationsTable />
    <SupportTable />
  </Root>
);

const Root = styled(FlexContainer)`
  margin-top: 1rem;
  gap: ${({ theme }) => theme.spacings.four};
`;
