import {
  AiWriterSupportedLanguageAndCountryCode,
  aiWriterSupportedLanguageAndCountryCodes
} from 'features/aiWriter/aiWriterSupportedLanguageAndCountryCodes';

export const FlashActionType = {
  rewrite: 'rewrite',
  continue: 'continue',
  improve: 'improve',
  summarize: 'summarize'
} as const;

type FlashActionCommands = {
  type: string;
  text: string;
};

export const predefinedFlashActions: Record<
  AiWriterSupportedLanguageAndCountryCode,
  Array<FlashActionCommands>
> = {
  [aiWriterSupportedLanguageAndCountryCodes.de_de]: [
    { type: FlashActionType.rewrite, text: 'Schreibe diesen Text um' },
    { type: FlashActionType.continue, text: 'Schreibe diesen Text weiter' },
    { type: FlashActionType.improve, text: 'Verbessere diesen Text' },
    { type: FlashActionType.summarize, text: 'Fasse diesen Text zusammen' }
  ],
  [aiWriterSupportedLanguageAndCountryCodes.de_at]: [
    { type: FlashActionType.rewrite, text: 'Schreibe diesen Text um' },
    { type: FlashActionType.continue, text: 'Schreibe diesen Text weiter' },
    { type: FlashActionType.improve, text: 'Verbessere diesen Text' },
    { type: FlashActionType.summarize, text: 'Fasse diesen Text zusammen' }
  ],
  [aiWriterSupportedLanguageAndCountryCodes.de_ch]: [
    { type: FlashActionType.rewrite, text: 'Schreibe diesen Text um' },
    { type: FlashActionType.continue, text: 'Schreibe diesen Text weiter' },
    { type: FlashActionType.improve, text: 'Verbessere diesen Text' },
    { type: FlashActionType.summarize, text: 'Fasse diesen Text zusammen' }
  ],
  [aiWriterSupportedLanguageAndCountryCodes.en_us]: [
    { type: FlashActionType.rewrite, text: 'Rewrite this text' },
    { type: FlashActionType.continue, text: 'Continue this text' },
    { type: FlashActionType.improve, text: 'Improve this text' },
    { type: FlashActionType.summarize, text: 'Summarize this text' }
  ],
  [aiWriterSupportedLanguageAndCountryCodes.en_uk]: [
    { type: FlashActionType.rewrite, text: 'Rewrite this text' },
    { type: FlashActionType.continue, text: 'Continue this text' },
    { type: FlashActionType.improve, text: 'Improve this text' },
    { type: FlashActionType.summarize, text: 'Summarize this text' }
  ],
  [aiWriterSupportedLanguageAndCountryCodes.fr_fr]: [
    { type: FlashActionType.rewrite, text: 'Réécrire ce texte' },
    { type: FlashActionType.continue, text: 'Poursuivre ce texte' },
    { type: FlashActionType.improve, text: 'Améliorer ce texte' },
    { type: FlashActionType.summarize, text: 'Résumer ce texte' }
  ],
  [aiWriterSupportedLanguageAndCountryCodes.fr_ch]: [
    { type: FlashActionType.rewrite, text: 'Réécrire ce texte' },
    { type: FlashActionType.continue, text: 'Poursuivre ce texte' },
    { type: FlashActionType.improve, text: 'Améliorer ce texte' },
    { type: FlashActionType.summarize, text: 'Résumer ce texte' }
  ],
  [aiWriterSupportedLanguageAndCountryCodes.es_es]: [
    { type: FlashActionType.rewrite, text: 'Reescribir este texto' },
    { type: FlashActionType.continue, text: 'Continuar este texto' },
    { type: FlashActionType.improve, text: 'Mejorar este texto' },
    { type: FlashActionType.summarize, text: 'Resumir este texto' }
  ],
  [aiWriterSupportedLanguageAndCountryCodes.ca_es]: [
    { type: FlashActionType.rewrite, text: 'Reescribir este texto' },
    { type: FlashActionType.continue, text: 'Continuar este texto' },
    { type: FlashActionType.improve, text: 'Mejorar este texto' },
    { type: FlashActionType.summarize, text: 'Resumir este texto' }
  ],
  [aiWriterSupportedLanguageAndCountryCodes.nl_nl]: [
    { type: FlashActionType.rewrite, text: 'Herschrijf deze tekst' },
    { type: FlashActionType.continue, text: 'Deze tekst voortzetten' },
    { type: FlashActionType.improve, text: 'Verbeter deze tekst' },
    { type: FlashActionType.summarize, text: 'Deze tekst samenvatten' }
  ],
  [aiWriterSupportedLanguageAndCountryCodes.it_it]: [
    { type: FlashActionType.rewrite, text: 'Riscrivere il testo' },
    { type: FlashActionType.continue, text: 'Continuare questo testo' },
    { type: FlashActionType.improve, text: 'Migliorare il testo' },
    { type: FlashActionType.summarize, text: 'Riassumere il testo' }
  ],
  [aiWriterSupportedLanguageAndCountryCodes.pl_pl]: [
    { type: FlashActionType.rewrite, text: 'Przepisz ten tekst' },
    { type: FlashActionType.continue, text: 'Kontynuuj ten tekst' },
    { type: FlashActionType.improve, text: 'Popraw ten tekst' },
    { type: FlashActionType.summarize, text: 'Podsumuj ten tekst' }
  ],
  [aiWriterSupportedLanguageAndCountryCodes.pt_pt]: [
    { type: FlashActionType.rewrite, text: 'Reescrever este texto' },
    { type: FlashActionType.continue, text: 'Continuar este texto' },
    { type: FlashActionType.improve, text: 'Melhorar este texto' },
    { type: FlashActionType.summarize, text: 'Resumir este texto' }
  ],
  [aiWriterSupportedLanguageAndCountryCodes.pt_br]: [
    { type: FlashActionType.rewrite, text: 'Reescrever este texto' },
    { type: FlashActionType.continue, text: 'Continuar este texto' },
    { type: FlashActionType.improve, text: 'Melhorar este texto' },
    { type: FlashActionType.summarize, text: 'Resumir este texto' }
  ],
  [aiWriterSupportedLanguageAndCountryCodes.es_mx]: [
    { type: FlashActionType.rewrite, text: 'Reescribir este texto' },
    { type: FlashActionType.continue, text: 'Continuar este texto' },
    { type: FlashActionType.improve, text: 'Mejorar este texto' },
    { type: FlashActionType.summarize, text: 'Resumir este texto' }
  ],
  [aiWriterSupportedLanguageAndCountryCodes.sv_se]: [
    { type: FlashActionType.rewrite, text: 'Skriv om denna text' },
    { type: FlashActionType.continue, text: 'Fortsätt denna text' },
    { type: FlashActionType.improve, text: 'Förbättra denna text' },
    { type: FlashActionType.summarize, text: 'Summera denna text' }
  ],
  [aiWriterSupportedLanguageAndCountryCodes.cs_cz]: [
    { type: FlashActionType.rewrite, text: 'Přepište tento text' },
    { type: FlashActionType.continue, text: 'Pokračujte v tomto textu' },
    { type: FlashActionType.improve, text: 'Vylepšete tento text' },
    { type: FlashActionType.summarize, text: 'Shrňte tento text' }
  ],
  [aiWriterSupportedLanguageAndCountryCodes.hu_hu]: [
    { type: FlashActionType.rewrite, text: 'Írja át ezt a szöveget' },
    { type: FlashActionType.continue, text: 'Folytassa ezt a szöveget' },
    { type: FlashActionType.improve, text: 'Javítsa ezt a szöveget' },
    { type: FlashActionType.summarize, text: 'Összefoglalja ezt a szöveget' }
  ],
  [aiWriterSupportedLanguageAndCountryCodes.hr_hr]: [
    { type: FlashActionType.rewrite, text: 'Prepisati ovaj tekst' },
    { type: FlashActionType.continue, text: 'Nastavite ovaj tekst' },
    { type: FlashActionType.improve, text: 'Poboljšati ovaj tekst' },
    { type: FlashActionType.summarize, text: 'Sažeti ovaj tekst' }
  ],
  [aiWriterSupportedLanguageAndCountryCodes.zh_cn]: [
    { type: FlashActionType.rewrite, text: '重写这段文字' },
    { type: FlashActionType.continue, text: '继续这段文字' },
    { type: FlashActionType.improve, text: '改进这段文字' },
    { type: FlashActionType.summarize, text: '总结这段文字' }
  ],
  [aiWriterSupportedLanguageAndCountryCodes.tr_tr]: [
    { type: FlashActionType.rewrite, text: 'Bu metni yeniden yaz' },
    { type: FlashActionType.continue, text: 'Bu metni devam ettir' },
    { type: FlashActionType.improve, text: 'Bu metni iyileştir' },
    { type: FlashActionType.summarize, text: 'Bu metni özetle' }
  ]
};
