import { ComponentPropsWithoutRef, forwardRef, ReactNode, Ref } from 'react';
import styled, { CSSProperties, DefaultTheme } from 'styled-components';

type Gap = keyof DefaultTheme['spacings'];
type Direction = 'col' | CSSProperties['flexDirection'];
type JustifyContent = CSSProperties['justifyContent'];
type AlignItems = CSSProperties['alignItems'];
type FlexWrap = CSSProperties['flexWrap'];

export type Props = ComponentPropsWithoutRef<'div'> & {
  className?: string;
  direction?: Direction;
  gap?: Gap | number;
  justifyContent?: JustifyContent;
  alignItems?: AlignItems;
  flexWrap?: FlexWrap;
  children: ReactNode;
  fullHeight?: boolean;
  fullWidth?: boolean;
};

const FlexContainer = forwardRef(
  (
    {
      direction = 'col',
      gap,
      justifyContent,
      alignItems,
      flexWrap,
      className,
      children,
      fullHeight,
      fullWidth,
      ...rest
    }: Props,
    ref: Ref<HTMLDivElement>
  ) => (
    <Root
      ref={ref}
      $gap={gap}
      $justifyContent={justifyContent}
      $alignItems={alignItems}
      $direction={direction}
      $flexWrap={flexWrap}
      $fullHeight={fullHeight}
      $fullWidth={fullWidth}
      className={className}
      {...rest}
    >
      {children}
    </Root>
  )
);

const Root = styled.div<{
  $gap?: Props['gap'];
  $direction: Props['direction'];
  $justifyContent?: Props['justifyContent'];
  $alignItems?: Props['alignItems'];
  $flexWrap?: Props['flexWrap'];
  $fullHeight?: Props['fullHeight'];
  $fullWidth?: Props['fullWidth'];
}>`
  display: flex;
  justify-content: ${({ $justifyContent: justifyContent }) => justifyContent};
  align-items: ${({ $alignItems: alignItems }) => alignItems};
  flex-direction: ${({ $direction: direction }) => (direction === 'col' ? 'column' : direction)};
  gap: ${({ theme, $gap: gap }) => {
    if (!gap) {
      return 0;
    }

    if (typeof gap === 'string' && gap in theme.spacings) {
      return theme.spacings[gap];
    }

    return `${gap as number}px`;
  }};
  flex-wrap: ${({ $flexWrap: flexWrap }) => flexWrap};

  height: ${({ $fullHeight: fullHeight }) => (fullHeight ? '100%' : 'auto')};
  width: ${({ $fullWidth: fullWidth }) => (fullWidth ? '100%' : 'auto')};
`;

export default FlexContainer;
