import { EditRounded } from '@mui/icons-material';
import { Typography } from '@mui/material';
import FlexContainer from 'components/FlexContainer';
import { TextTypeActionButton } from 'features/textGenerator/EnhancedOutputTypeAutocomplete/TextTypesButton';
import gtmIds from 'services/tracking/GTMIds';
import useTr from 'utils/hooks/useTr';

export type TextTypesButtonProps = {
  isDisabled?: boolean | undefined;
  onSelect: () => void;
};

export const EditTextTypeButton = (props: TextTypesButtonProps) => {
  const { isDisabled, onSelect } = props;
  const translate = useTr();

  return (
    <TextTypeActionButton variant="text" onClick={onSelect} disabled={isDisabled}>
      <FlexContainer direction="row" gap="xsmall" alignItems="center">
        <EditRounded fontSize="small" />
        <Typography id={gtmIds.aiWriter.editBrief} variant="subtitle2">
          {translate('aiWriter.inspirations.aiwriter.main.edit_brief')}
        </Typography>
      </FlexContainer>
    </TextTypeActionButton>
  );
};
