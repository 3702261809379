import { useShowChatResetModal } from 'features/aiWriter/AiWriterSidebar/steps/chat/ChatResetModal';
import FormattedMessage from 'features/i18n/FormattedMessage';

type CustomModalProps = {
  onAccept: () => void;
};

export const useShowChatPersonalityResetChatModal = () => {
  const showModal = useShowChatResetModal();

  return (props: CustomModalProps) => {
    showModal({
      headingMessage: (
        <FormattedMessage id="aiWriter.inspirations.chat.personalities.reset_modal.title" />
      ),
      explanationMessage: (
        <FormattedMessage id="aiWriter.inspirations.chat.personalities.reset_modal.message" />
      ),
      onAccept: props.onAccept
    });
  };
};
