import { useQuery } from '@tanstack/react-query';
import { getCustomerRole } from 'features/customer/store/selectors';
import { CustomerRole } from 'features/customer/store/types';
import useSubscriptionState from 'features/pricing/hook/useSubscriptionState';
import { PricingPlan } from 'features/pricing/types';
import { ACLPricing } from 'services/api/customer/types';
import { httpGetMembersCount } from 'services/backofficeIntegration/http/endpoints/teamMembers/httpGetMembersCount';
import { useAppSelector } from 'store/hooks';

export const useTeamDowngradePrevention = (selectedPricingPlan: ACLPricing) => {
  const customerRole = useAppSelector(getCustomerRole);
  const { currentPricingPlan } = useSubscriptionState();

  const { data, isLoading } = useQuery({
    queryKey: httpGetMembersCount.makeQueryKey(),
    queryFn: () => httpGetMembersCount.callEndpoint()
  });

  const isTeamOwner = customerRole === CustomerRole.OWNER;

  const isProPlan = currentPricingPlan?.type === PricingPlan.pro;
  const isEnterprisePlan = currentPricingPlan?.type === PricingPlan.enterprise;

  const canDowngrade =
    (isEnterprisePlan &&
      (selectedPricingPlan.type === PricingPlan.pro ||
        selectedPricingPlan.type === PricingPlan.standard)) ||
    (isProPlan && selectedPricingPlan.type === PricingPlan.standard);

  const totalUsers = data?.members_count;

  const isDowngradePrevented = canDowngrade && isTeamOwner && totalUsers && totalUsers >= 1;

  return { isLoading, isDowngradePrevented };
};
