import { Dimension, ManualDimension } from 'services/api/dimension/types';
import { getDimensionWords, prepareGoalConfig, prepareMappers } from 'store/utils/dataUtils';
import { WordAttributeWithOption } from 'types/ReduxStateTypes';

const getScoreTextConfig = ({
  mapperId,
  wordAttributes,
  dimensions,
  manualDimensions
}: {
  mapperId: string;
  wordAttributes: WordAttributeWithOption[];
  dimensions: Dimension[];
  manualDimensions: ManualDimension[];
}) => {
  const wordAttributesValues = wordAttributes.map(attr => attr.value);

  const apiDimensions = getDimensionWords(dimensions, manualDimensions);
  const mappers = prepareMappers(wordAttributesValues, mapperId);
  const goalConfig = prepareGoalConfig(wordAttributes, mappers, apiDimensions);

  return { goalConfig, apiDimensions, mappers };
};

export default getScoreTextConfig;
