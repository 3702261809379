import { makeCurrentUserQueryKeyPrefix } from 'features/currentUser/currentUserQueries';
import { queryClient } from 'utils/ReactQueryClient';

export function invalidateUnsplashImagesUsageQueries() {
  return queryClient.invalidateQueries(makeUnsplashImagesUsageQueryKeyPrefix());
}

export function makeUnsplashImagesUsageQueryKeyPrefix() {
  return [...makeCurrentUserQueryKeyPrefix(), 'unsplash', 'usage'];
}
