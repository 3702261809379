import { Tooltip } from '@mui/material';
import { isMarkActive, toggleMark } from '@udecode/plate';
import {
  EditorFormatButton,
  EditorFormatButtonProps,
  EditorIconFormatButton
} from 'features/aiWriter/AiWriterTextEditor/EditorIconFormatButton';
import useEditor from 'features/aiWriter/AiWriterTextEditor/hooks/useEditor';
import { trackEditorFormatAction } from 'features/aiWriter/AiWriterTextEditor/trackEditorFormatAction';
import { MarkFormat } from 'features/aiWriter/AiWriterTextEditor/types';
import { getMarkButtonIcon } from 'features/aiWriter/AiWriterTextEditor/utils/getMarkButtonIcon';
import React, { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';

export type Props = {
  format: MarkFormat;
  styleVariant?: EditorFormatButtonProps;
};

export const EditorMarkButton = ({ format, styleVariant = '' }: Props) => {
  const editor = useEditor();

  const handleMouseDown = useCallback(
    (event: React.MouseEvent) => {
      event.preventDefault();
      trackEditorFormatAction(format);

      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      toggleMark(editor!, { key: format });
    },
    [editor, format]
  );

  if (!editor) {
    return null;
  }

  const isActive = isMarkActive(editor, format);

  const renderedFormatButton = (
    <EditorIconFormatButton onMouseDown={handleMouseDown} $isActive={isActive}>
      {getMarkButtonIcon(format, isActive)}
    </EditorIconFormatButton>
  );

  if (styleVariant === 'icon') {
    return (
      <Tooltip
        arrow
        title={<FormattedMessage id={`aiWriter.toolbar.${format}`} />}
        placement="bottom"
      >
        {renderedFormatButton}
      </Tooltip>
    );
  }

  return (
    <EditorFormatButton onMouseDown={handleMouseDown} variant="text" $isActive={isActive}>
      {getMarkButtonIcon(format, isActive)}
      <FormattedMessage id={`aiWriter.toolbar.${format}`} />
    </EditorFormatButton>
  );
};
