import Toast from 'components/toasts/Toast';
import { makeCurrentUserQueryKeyPrefix } from 'features/currentUser/currentUserQueries';
import { getErrorCodeFromAxiosError } from 'features/teamInvitation/getErrorCodeFromAxiosError';
import { BaseSuccessDto } from 'services/backofficeIntegration/http/BaseSuccessDto';
import { backofficeClient } from 'services/backofficeIntegration/http/client/createBackofficeClient';
import { queryEndpoint } from 'services/backofficeIntegration/http/endpointDefinition';

type BillingPortalUrls = {
  billing_portal_url: string;
  payment_method_change_url: string;
  billing_address_change_url: string;
};

type SuccessDto = BaseSuccessDto<BillingPortalUrls>;

export const httpGetBillingPortalUrls = queryEndpoint({
  makeQueryKey: () => {
    return [...makeCurrentUserQueryKeyPrefix(), 'billing-portal-urls'] as const;
  },
  callEndpoint: () => {
    return (
      backofficeClient
        // #tech-debt https://app.clickup.com/t/862jtwd0g
        .post<SuccessDto>('/customer/billing-portal')
        .then(response => {
          return response.data.data;
        })
        .catch(err => {
          const errorCode = getErrorCodeFromAxiosError(err);
          if (errorCode === 'ERROR_CUSTOMER_HAS_NO_STRIPE_ACCOUNT') {
            Toast.error(`billing_portal.error.${errorCode}`);
          }
        })
    );
  }
});
