import { CircularProgress, InputAdornment, TextField } from '@mui/material';
import {
  ChangeEvent,
  ComponentProps,
  forwardRef,
  HTMLAttributes,
  KeyboardEvent,
  ReactNode,
  useState
} from 'react';
import styled from 'styled-components';
import { resetButton } from 'styles/styledComponents/resets';

type Props = Omit<HTMLAttributes<HTMLButtonElement>, 'onClick'> & {
  value: string;
  isLoading?: boolean;
  children: ReactNode;
  onSave: (value: string) => void;
  TextFieldProps?: Omit<
    ComponentProps<typeof TextField>,
    'value' | 'onChange' | 'onBlur' | 'onKeyDown'
  >;
  validationRegex?: RegExp;
};

export const EditableComponent = forwardRef<HTMLButtonElement, Props>(
  (
    { value, isLoading, children, onSave, TextFieldProps, validationRegex, ...rest }: Props,
    ref
  ) => {
    const [inputValue, setInputValue] = useState(value);
    const [isEditing, setIsEditing] = useState(false);

    const handleRootClick = () => {
      setInputValue(value);
      setIsEditing(true);
    };

    const handleInputBlur = () => {
      onSave(inputValue);
      setIsEditing(false);
    };

    const handleInputKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
      if (event.key === 'Enter' && !event.shiftKey) {
        handleInputBlur();
      }
      if (event.key === 'Escape') {
        setIsEditing(false);
      }
    };

    const handleInputChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      if (validationRegex && !validationRegex.test(event.target.value)) {
        return;
      }

      setInputValue(event.target.value);
    };

    if (isEditing) {
      return (
        <TextField
          autoFocus={true}
          fullWidth={true}
          variant="outlined"
          size="small"
          value={inputValue}
          onChange={handleInputChange}
          onBlur={handleInputBlur}
          onKeyDown={handleInputKeyDown}
          {...TextFieldProps}
          InputProps={{
            ...TextFieldProps?.InputProps,
            endAdornment: (
              <InputAdornment position="start">
                {isLoading && <CircularProgress size={24} />}
              </InputAdornment>
            )
          }}
        />
      );
    }

    return (
      <Root ref={ref} {...rest} onClick={handleRootClick}>
        {children}
      </Root>
    );
  }
);

const Root = styled.button`
  ${resetButton};
  padding: 0;

  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${({ theme }) => theme.spacings.small};
`;
