import { ActionDialog } from 'features/profilePage/ActionDialog';
import { FormattedMessage } from 'react-intl';

type Props = {
  onCancel: () => void;
  onConfirm: () => void;
  isDialogOpen: boolean;
};

export const ChangeRoleDialog = ({ isDialogOpen, onCancel, onConfirm }: Props) => {
  return (
    <ActionDialog
      title={<FormattedMessage id="profile.users.users_list.change_role.dialog.title" />}
      content={<FormattedMessage id="profile.users.users_list.change_role.dialog.content" />}
      confirmButtonText={<FormattedMessage id="common.confirm" />}
      isDialogOpen={isDialogOpen}
      onCancel={onCancel}
      onConfirm={onConfirm}
      confirmButtonColor="primary"
    />
  );
};
