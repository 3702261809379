import DimensionsSelect from 'features/dimensions/DimensionsSelect';
import { ErrorBoundary } from 'features/react-error-boundary/ErrorBoundary';
import { Dimension } from 'services/api/dimension/types';

type Props = {
  formResults: Dimension[];
  language: string;
  disabled?: boolean;

  onChange: (selectedItem: Dimension) => void;
  onRemove?: (index: number, id: number) => void;
};

const DimensionsAutocomplete = ({ language, disabled, onChange, onRemove, ...props }: Props) => {
  return (
    <ErrorBoundary>
      <DimensionsSelect
        disabled={disabled}
        params={{ language, belongsTo: 'everyone' }}
        onChange={value => onChange(value)}
        onRemove={onRemove}
        {...props}
      />
    </ErrorBoundary>
  );
};

export default DimensionsAutocomplete;
