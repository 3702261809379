import DoneIcon from '@mui/icons-material/Done';
import { Autocomplete, Chip, ListItemIcon, MenuItem, Popper, TextField } from '@mui/material';
import CountryFlag from 'components/base/CountryFlag';
import FlexContainer from 'components/FlexContainer';
import { getEmbeddingModelsByLanguageAndAudience } from 'features/embeddingModels/store/selectors';
import { EmbeddingModel } from 'features/embeddingModels/store/types';
import { sortModelsByLanguageAlphabetically } from 'features/embeddingModels/store/utils/sortModelsByLanguage';
import { SyntheticEvent, useMemo, useState } from 'react';
import { useAppSelector } from 'store/hooks';
import styled from 'styled-components';
import useTr from 'utils/hooks/useTr';

type Props = {
  value: EmbeddingModel | undefined;
  setValue: (value: EmbeddingModel | undefined) => void;
};

export function LanguageFilter({ value, setValue }: Props) {
  const [inputValue, setInputValue] = useState('');

  const tr = useTr();

  const handleChange = (event: SyntheticEvent<Element, Event>, newValue: EmbeddingModel | null) => {
    if (newValue) {
      setValue(newValue);
    }
  };

  const handleClear = () => {
    setValue(undefined);
  };

  const models = useAppSelector(getEmbeddingModelsByLanguageAndAudience);

  const sortedModels = useMemo(() => sortModelsByLanguageAlphabetically(models), [models]);

  return (
    <FilterContainer>
      {value ? (
        <StyledChip
          label={value.label}
          icon={<DoneIcon />}
          onDelete={handleClear}
          color="primary"
          sx={{ color: 'white' }}
        />
      ) : (
        <Autocomplete
          sx={{ width: '8rem' }}
          value={value}
          inputValue={inputValue}
          options={sortedModels}
          onChange={handleChange}
          getOptionLabel={(option: EmbeddingModel) => option.label}
          onInputChange={(event, newInputValue) => {
            setInputValue(newInputValue);
          }}
          renderInput={params => (
            <TextField {...params} label={tr('common.language')} size="small" />
          )}
          PopperComponent={params => (
            // all other styling methods do not work for this component
            <Popper {...params} style={{ width: 240 }} placement="bottom-start" />
          )}
          renderOption={(props, value) => (
            <MenuItem {...props}>
              <ListItemIcon>
                <CountryFlag country={value.country} language={value.language} />
              </ListItemIcon>

              {value.label}
            </MenuItem>
          )}
        />
      )}
    </FilterContainer>
  );
}

const FilterContainer = styled(FlexContainer).attrs({
  direction: 'row',
  alignItems: 'center'
})`
  height: 3rem;
`;

const StyledChip = styled(Chip)`
  color: ${({ theme }) => theme.colors.white};

  && .MuiChip-deleteIcon {
    color: ${({ theme }) => theme.colors.white};

    &:hover {
      color: ${({ theme }) => theme.colors.whiteMediumEmphasis};
    }
  }
`;
