import { RefreshRounded } from '@mui/icons-material';
import { Button, CircularProgress, Divider, Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { insertText } from '@udecode/plate';
import { collapseSelection } from '@udecode/plate-common';
import FlexContainer from 'components/FlexContainer';
import { FlashScorePopperSuggestionRoot } from 'features/aiWriter/AiWriterTextEditor/EditorTopToolbar/flashScorePopper/FlashScorePopperSuggestionRoot';
import { TextReplacementScoringProgressCircles } from 'features/aiWriter/AiWriterTextEditor/EditorTopToolbar/flashScorePopper/TextReplacementScoringProgressCircles';
import { useGenerateTextStreamReplacementForSelection } from 'features/aiWriter/AiWriterTextEditor/EditorTopToolbar/flashScorePopper/useGenerateTextStreamReplacementForSelection';
import useEditor from 'features/aiWriter/AiWriterTextEditor/hooks/useEditor';
import { useScoreProvidedText } from 'features/flashScore/useScoreProvidedText';
import FormattedMessage from 'features/i18n/FormattedMessage';
import { clearFakeSelection } from 'features/plate/customPlugins/createFakeSelectionPlugin';
import { useState } from 'react';
import gtmIds from 'services/tracking/GTMIds';
import { withGtmInteraction } from 'services/tracking/withGtmInteraction';
import styled from 'styled-components';

type Props = {
  anchorElement: HTMLElement | null;
  isOpen: boolean;
  selectedText: string;
  onClose: () => void;
};

export const FlashScorePopperTextReplacementView = ({
  anchorElement,
  isOpen,
  selectedText,
  onClose
}: Props) => {
  const editor = useEditor();

  const [generatedSuggestion, setGeneratedSuggestion] = useState<string>('');

  const {
    isLoading: isLoadingSuggestion,
    isFetching: isFetchingSuggestion,
    refetch: regenerateSuggestion
  } = useGenerateTextStreamReplacementForSelection({
    text: selectedText,
    onStart: () => setGeneratedSuggestion(''),
    onChunk: chunk => setGeneratedSuggestion(prev => `${prev}${chunk}`)
  });

  const isGeneratingSuggestion = isLoadingSuggestion || isFetchingSuggestion;

  const { isFetching: isAnalyzingSuggestion, data: suggestionScoringData } = useScoreProvidedText({
    enabled: !!generatedSuggestion && !isGeneratingSuggestion,
    text: generatedSuggestion ?? '',
    cacheKeys: [generatedSuggestion ?? '']
  });

  const isGeneratingOrScoring = isGeneratingSuggestion || isAnalyzingSuggestion;

  const handleReplaceClick = () => {
    if (!editor || !generatedSuggestion || !editor.selection) {
      return;
    }

    clearFakeSelection(editor);

    insertText(editor, generatedSuggestion);
    onClose();
  };

  const handleInsertClick = () => {
    if (!editor || !generatedSuggestion || !editor.selection) {
      return;
    }

    clearFakeSelection(editor);

    // Remove selection and place cursor at the end of it
    collapseSelection(editor, { edge: 'end' });

    // Instead of clearing the selecting, we simply replace it with same text + improvement
    insertText(editor, generatedSuggestion);
    onClose();
  };

  return (
    <FlashScorePopperSuggestionRoot isOpen={isOpen} anchorElement={anchorElement} onClose={onClose}>
      <FlexContainer direction="column" gap="medium">
        <FlexContainer direction="column" gap="small">
          <FlexContainer
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            gap="small"
          >
            <Typography variant="subtitle2">
              <FormattedMessage id="flashScore.popper.suggestion.title" />
            </Typography>

            <IconButton
              size="small"
              disabled={isGeneratingOrScoring}
              onClick={() => regenerateSuggestion()}
              {...withGtmInteraction(gtmIds.aiWriter.flashScore.oneClickOptimizeRefreshCta)}
            >
              <RefreshRounded />
            </IconButton>
          </FlexContainer>

          <Divider />

          {!generatedSuggestion ? (
            <ProgressRootContainer>
              <CircularProgress size={40} thickness={1} />
            </ProgressRootContainer>
          ) : (
            <Typography variant="body2">{generatedSuggestion}</Typography>
          )}
        </FlexContainer>

        <FlexContainer direction="row" justifyContent="space-between">
          <FlexContainer direction="row" gap="small">
            {!isGeneratingSuggestion && (
              <TextReplacementScoringProgressCircles
                scoringData={suggestionScoringData}
                isAnalyzing={isAnalyzingSuggestion}
              />
            )}
          </FlexContainer>

          <FlexContainer direction="row" gap="small">
            <Button
              {...withGtmInteraction(gtmIds.aiWriter.flashScore.oneClickOptimizeReplaceCta)}
              variant="outlined"
              color="primary"
              disabled={isGeneratingOrScoring}
              onClick={handleReplaceClick}
            >
              <FormattedMessage id="flashScore.popper.suggestion.replace_cta" />
            </Button>
            <Button
              {...withGtmInteraction(gtmIds.aiWriter.flashScore.oneClickOptimizeInsertCta)}
              variant="contained"
              color="primary"
              disabled={isGeneratingOrScoring}
              onClick={handleInsertClick}
            >
              <FormattedMessage id="flashScore.popper.suggestion.insert_cta" />
            </Button>
          </FlexContainer>
        </FlexContainer>
      </FlexContainer>
    </FlashScorePopperSuggestionRoot>
  );
};

const ProgressRootContainer = styled(FlexContainer).attrs({
  direction: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  gap: 'large'
})`
  padding: ${({ theme }) => theme.spacings.medium};
  width: 100%;
`;
