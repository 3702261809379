import { WordAttributesValue } from 'features/aiTester/store/types';

export const countPrevalence = (
  modelRankScore: number | undefined,
  wordAttributesValues: WordAttributesValue[]
) => {
  if (wordAttributesValues.includes('model_rank_score')) {
    if (modelRankScore && modelRankScore > 0.1) {
      return modelRankScore;
    }
    return 0.1;
  }
  return 1;
};
