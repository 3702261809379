import { useQuery } from '@tanstack/react-query';
import { getCurrentLanguage } from 'features/language/store/selectors';
import { shuffle } from 'lodash';
import { getSurveyQuestions } from 'services/backofficeIntegration/http/endpoints/surveyQuestions/getSurveyQuestions';
import { useAppSelector } from 'store/hooks';
import { getBrowserLangAndCountry } from 'utils/langUtils';

export const useSurveyQuestions = () => {
  const appLanguage = useAppSelector(getCurrentLanguage);

  return useQuery({
    queryKey: getSurveyQuestions.makeQueryKey(),
    queryFn: async () => {
      const [browserLanguage] = getBrowserLangAndCountry();

      const response = await getSurveyQuestions.callEndpoint({
        language: appLanguage || browserLanguage
      });
      // Special handling for content_type question
      // Shuffle the options but move the "Other" option to the end
      if (response && Array.isArray(response)) {
        return response.map(item => {
          if (item.slug === 'content_type') {
            const shuffledOptions = shuffle(item.options);
            const otherOptionIndex = shuffledOptions.findIndex(
              option => option.option_text === 'Other'
            );
            const otherOption = shuffledOptions.splice(otherOptionIndex, 1)[0];
            shuffledOptions.push(otherOption);

            return {
              ...item,
              options: shuffledOptions
            };
          }

          return item;
        });
      }

      return response;
    }
  });
};
