import BorderAllIcon from '@mui/icons-material/BorderAll';
import BorderBottomIcon from '@mui/icons-material/BorderBottom';
import BorderClearIcon from '@mui/icons-material/BorderClear';
import BorderLeftIcon from '@mui/icons-material/BorderLeft';
import BorderRightIcon from '@mui/icons-material/BorderRight';
import BorderTopIcon from '@mui/icons-material/BorderTop';
import DeleteIcon from '@mui/icons-material/Delete';
import { IconButton, ListItemIcon, MenuItem, Tooltip } from '@mui/material';
import { PopoverAnchor, PopoverContentProps } from '@radix-ui/react-popover';
import {
  isCollapsed,
  PlateElement,
  PlateElementProps,
  useEditorState,
  useElement,
  useRemoveNodeButton
} from '@udecode/plate-common';
import {
  insertTableColumn,
  insertTableRow,
  TTableElement,
  useTableBordersDropdownMenuContentState,
  useTableElement,
  useTableElementState
} from '@udecode/plate-table';
import { ReactComponent as AddColumnIcon } from 'assets/icons/icon-add-column-right.svg';
import { ReactComponent as AddRowIcon } from 'assets/icons/icon-add-row-below.svg';
import { MenuDropdown } from 'components/MenuDropdown/MenuDropdown';
import { EditorIconFormatButton } from 'features/aiWriter/AiWriterTextEditor/EditorIconFormatButton';
import { cn } from 'features/plate/lib/utils';
import { ElementRef, forwardRef } from 'react';
import { FormattedMessage } from 'react-intl';
import { useReadOnly, useSelected } from 'slate-react';
import styled from 'styled-components';
import useDropdown from 'utils/hooks/useDropdown';

import { DropdownMenu } from './dropdown-menu';
import { Popover, PopoverContent, popoverVariants } from './popover';
import { Separator } from './separator';

const TableBordersDropdownMenuContent = () => {
  const {
    getOnSelectTableBorder,
    hasOuterBorders,
    hasBottomBorder,
    hasLeftBorder,
    hasNoBorders,
    hasRightBorder,
    hasTopBorder
  } = useTableBordersDropdownMenuContentState();

  const { anchorElement, close, isOpen, open } = useDropdown();

  return (
    <MenuDropdown
      anchorElement={anchorElement}
      close={close}
      isOpen={isOpen}
      trigger={
        <Tooltip title={<FormattedMessage id="aiWriter.editor.selection_toolbar.table_borders" />}>
          <EditorIconFormatButton onClick={open}>
            <BorderAllIcon fontSize="small" />
          </EditorIconFormatButton>
        </Tooltip>
      }
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right'
      }}
      transformOrigin={{
        vertical: 'bottom',
        horizontal: 'left'
      }}
    >
      <MenuItem onClick={getOnSelectTableBorder('outer')} selected={hasOuterBorders}>
        <ListItemIcon>
          <BorderAllIcon fontSize="small" />
        </ListItemIcon>
        <FormattedMessage id="aiWriter.toolbar.table_border.all" />
      </MenuItem>

      <MenuItem onClick={getOnSelectTableBorder('bottom')} selected={hasBottomBorder}>
        <ListItemIcon>
          <BorderBottomIcon fontSize="small" />
        </ListItemIcon>
        <FormattedMessage id="aiWriter.toolbar.table_border.bottom" />
      </MenuItem>

      <MenuItem onClick={getOnSelectTableBorder('top')} selected={hasTopBorder}>
        <ListItemIcon>
          <BorderTopIcon fontSize="small" />
        </ListItemIcon>
        <FormattedMessage id="aiWriter.toolbar.table_border.top" />
      </MenuItem>

      <MenuItem onClick={getOnSelectTableBorder('left')} selected={hasLeftBorder}>
        <ListItemIcon>
          <BorderLeftIcon fontSize="small" />
        </ListItemIcon>
        <FormattedMessage id="aiWriter.toolbar.table_border.left" />
      </MenuItem>

      <MenuItem onClick={getOnSelectTableBorder('right')} selected={hasRightBorder}>
        <ListItemIcon>
          <BorderRightIcon fontSize="small" />
        </ListItemIcon>
        <FormattedMessage id="aiWriter.toolbar.table_border.right" />
      </MenuItem>

      <Separator />

      <MenuItem onClick={getOnSelectTableBorder('none')} selected={hasNoBorders}>
        <ListItemIcon>
          <BorderClearIcon fontSize="small" />
        </ListItemIcon>
        <FormattedMessage id="aiWriter.toolbar.table_border.no" />
      </MenuItem>
    </MenuDropdown>
  );
};
TableBordersDropdownMenuContent.displayName = 'TableBordersDropdownMenuContent';

const ActionButton = styled(IconButton)`
  border-radius: ${({ theme }) => theme.borderRadius.small};
  color: ${({ theme }) => theme.colors.black};

  padding: 5px;
`;

const AddColumnButton = () => {
  const editor = useEditorState();

  const handleClick = () => {
    insertTableColumn(editor);
  };

  return (
    <Tooltip title={<FormattedMessage id="aiWriter.editor.selection_toolbar.table_column" />}>
      <ActionButton onClick={handleClick}>
        <AddColumnIcon width={20} height={20} />
      </ActionButton>
    </Tooltip>
  );
};

const AddRowButton = () => {
  const editor = useEditorState();

  const handleClick = () => {
    insertTableRow(editor);
  };

  return (
    <Tooltip title={<FormattedMessage id="aiWriter.editor.selection_toolbar.table_row" />}>
      <ActionButton onClick={handleClick}>
        <AddRowIcon width={20} height={20} />
      </ActionButton>
    </Tooltip>
  );
};

const TableFloatingToolbar = forwardRef<ElementRef<typeof PopoverContent>, PopoverContentProps>(
  ({ children, ...props }, ref) => {
    const element = useElement<TTableElement>();
    const { props: buttonProps } = useRemoveNodeButton({ element });

    const readOnly = useReadOnly();
    const selected = useSelected();
    const editor = useEditorState();
    const open = !readOnly && selected && isCollapsed(editor.selection);

    return (
      <Popover open={open} modal={false}>
        <PopoverAnchor asChild>{children}</PopoverAnchor>
        <PopoverContent
          ref={ref}
          className={cn(popoverVariants(), 'flex w-[160px] gap-1 p-1 justify-center')}
          onOpenAutoFocus={e => e.preventDefault()}
          {...props}
        >
          <AddColumnButton />
          <AddRowButton />

          <DropdownMenu modal={false}>
            <TableBordersDropdownMenuContent />
          </DropdownMenu>

          <Tooltip title={<FormattedMessage id="aiWriter.editor.selection_toolbar.table_delete" />}>
            <EditorIconFormatButton {...buttonProps}>
              <DeleteIcon fontSize="small" />
            </EditorIconFormatButton>
          </Tooltip>
        </PopoverContent>
      </Popover>
    );
  }
);
TableFloatingToolbar.displayName = 'TableFloatingToolbar';

const TableElement = forwardRef<ElementRef<typeof PlateElement>, PlateElementProps>(
  ({ className, children, ...props }, ref) => {
    const { colSizes, isSelectingCell, minColumnWidth, marginLeft } = useTableElementState();
    const { props: tableProps, colGroupProps } = useTableElement();

    return (
      <TableFloatingToolbar>
        <div style={{ paddingLeft: marginLeft }}>
          <PlateElement
            asChild
            ref={ref}
            className={cn(
              'my-4 ml-px mr-0 table h-px w-full table-fixed border-collapse',
              isSelectingCell && '[&_*::selection]:bg-none',
              className
            )}
            {...tableProps}
            {...props}
          >
            <table>
              <colgroup {...colGroupProps}>
                {colSizes.map(width => (
                  <col
                    key={Math.random()}
                    style={{
                      minWidth: minColumnWidth,
                      width: width || undefined
                    }}
                  />
                ))}
              </colgroup>

              <tbody className="min-w-full">{children}</tbody>
            </table>
          </PlateElement>
        </div>
      </TableFloatingToolbar>
    );
  }
);
TableElement.displayName = 'TableElement';

export { TableBordersDropdownMenuContent, TableElement, TableFloatingToolbar };
