import backgroundDoodleOrnament from 'assets/background-doodle.png';
import IconNeuroflashLogoSVG from 'assets/neuroflash-logo.svg';
import FlexContainer from 'components/FlexContainer';
import { Ornament } from 'components/Ornament';
import { getRoutePath } from 'config/routes';
import { RegisterSuccessCard } from 'features/authentication/RegisterSuccessCard';
import { ReactElement } from 'react';
import Confetti from 'react-confetti';
import { useNavigate } from 'react-router';
import styled from 'styled-components';
import useWindowSize from 'utils/hooks/useWindowSize';

export const RegisterSuccessPage = (): ReactElement => {
  const { width, height } = useWindowSize();
  const navigate = useNavigate();

  return (
    <Root>
      <LogoImage src={IconNeuroflashLogoSVG} />
      <RegisterSuccessCard
        resendButton={
          <RegisterSuccessCard.ResendButton
            onClick={() => {
              navigate(getRoutePath('resendMail'));
            }}
          />
        }
      />
      <Ornament src={backgroundDoodleOrnament} bottom="0%" right="0%" />

      <Confetti
        width={width}
        height={height}
        numberOfPieces={1000}
        tweenDuration={40000}
        recycle={false}
      />
    </Root>
  );
};

const Root = styled(FlexContainer).attrs({
  gap: 'ten',
  alignItems: 'center',
  justifyContent: 'center'
})`
  margin-top: 88px;
`;

const LogoImage = styled.img`
  width: 460px;
`;
