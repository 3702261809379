import { createPluginFactory, isEditorEmpty } from '@udecode/plate';
import { setNodes } from '@udecode/plate-common';
import { trackingWrapper } from 'features/tracking/wrapper/TrackingWrapper';
import { GAEvents } from 'services/tracking/GAEvents';

export const ELEMENT_EMPTY_DOCUMENT = 'neuroflash-empty-document';

export const createEmptyDocumentPlugin = createPluginFactory({
  key: ELEMENT_EMPTY_DOCUMENT,
  isElement: true,
  isVoid: true,
  handlers: {
    onChange: editor => () => {
      if (!isEditorEmpty(editor)) {
        return;
      }

      GAEvents.editorEmptyDocumentShownAfterEmptying();
      trackingWrapper.track('aiWriterEditorEmptyDocumentShownAfterEmptying');

      setNodes(editor, {
        type: ELEMENT_EMPTY_DOCUMENT,
        children: [{ text: '' }]
      });
    },
    onEmptied: editor => () => {
      if (!isEditorEmpty(editor)) {
        return;
      }

      GAEvents.editorEmptyDocumentShownAfterEmptying();
      trackingWrapper.track('aiWriterEditorEmptyDocumentShownAfterEmptying');

      setNodes(editor, {
        type: ELEMENT_EMPTY_DOCUMENT,
        children: [{ text: '' }]
      });
    }
  }
});
