import useTr from 'utils/hooks/useTr';

import TextInputField from '../TextInputField';

type Props = {
  name: string;
};

const NameField = ({ name }: Props) => {
  const tr = useTr();

  return (
    <TextInputField
      name={name}
      variant="outlined"
      label={tr('common.form.field.name.label')}
      placeholder={tr('common.form.field.name.placeholder')}
      fullWidth
    />
  );
};

export default NameField;
