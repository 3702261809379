import { BuilderModularWorkflow } from 'features/modular-workflow/builder/types-builder';
import { BaseSuccessDto } from 'services/backofficeIntegration/http/BaseSuccessDto';
import { backofficeClient } from 'services/backofficeIntegration/http/client/createBackofficeClient';
import { mutationEndpoint } from 'services/backofficeIntegration/http/endpointDefinition';

type ResponseDto = BaseSuccessDto<BuilderModularWorkflow>;

export const httpCreateModularWorkflow = mutationEndpoint({
  callEndpoint: (workflow: BuilderModularWorkflow) => {
    return backofficeClient
      .post<ResponseDto>('/workflows', workflow)
      .then(response => response.data.data);
  }
});
