import InsertPhotoIcon from '@mui/icons-material/InsertPhoto';
import { Tooltip } from '@mui/material';
import {
  EditorFormatButton,
  EditorFormatButtonProps,
  EditorIconFormatButton
} from 'features/aiWriter/AiWriterTextEditor/EditorIconFormatButton';
import { trackEditorFormatAction } from 'features/aiWriter/AiWriterTextEditor/trackEditorFormatAction';
import { getIconColor } from 'features/aiWriter/AiWriterTextEditor/utils/getIconColor';
import { enableInspirationsPanel } from 'features/aiWriter/store/actions/builder/actions';
import { setInspirationsCurrentStep } from 'features/aiWriter/store/actions/config/actions';
import { AiWriterSidebarStep } from 'features/aiWriter/store/types';
import FormattedMessage from 'features/i18n/FormattedMessage';
import { isEqual } from 'lodash';
import { useAppDispatch, useAppSelector } from 'store/hooks';

type AiImagesButtonProps = {
  styleVariant?: EditorFormatButtonProps;
  onClose?: () => void;
};

export function AiImagesButton({ onClose, styleVariant }: AiImagesButtonProps) {
  const dispatch = useAppDispatch();

  const inspirationsCurrentStep = useAppSelector(state => state.aiWriter.inspirationsCurrentStep);

  const isActive = isEqual(inspirationsCurrentStep, {
    step: AiWriterSidebarStep.images,
    subStep: 'aiImages'
  });

  const handleClick = () => {
    trackEditorFormatAction('imageflash');

    dispatch(enableInspirationsPanel());
    dispatch(setInspirationsCurrentStep({ step: AiWriterSidebarStep.images, subStep: 'aiImages' }));

    onClose && onClose();
  };

  if (styleVariant === 'icon') {
    return (
      <EditorIconFormatButton onClick={handleClick} $isActive={isActive}>
        <Tooltip
          arrow
          title={<FormattedMessage id="aiWriter.toolbar.aiImages" />}
          placement="bottom"
        >
          <InsertPhotoIcon color={getIconColor(isActive)} fontSize="small" />
        </Tooltip>
      </EditorIconFormatButton>
    );
  }

  return (
    <EditorFormatButton onClick={handleClick} $isActive={isActive}>
      <InsertPhotoIcon color={getIconColor(isActive)} fontSize="small" />
      <FormattedMessage id="aiWriter.toolbar.aiImages" />
    </EditorFormatButton>
  );
}
