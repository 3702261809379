import { ColoredTypography } from 'components/ColoredTypography';
import { IntlMessageKeys } from 'features/i18n/IntlMessageKeys';
import { FormattedMessage } from 'react-intl';
import { DefaultTheme } from 'styled-components';

type Props = {
  title: IntlMessageKeys;
  color?: keyof DefaultTheme['colors'];
};

const Title = ({ title, color }: Props) => {
  return (
    <ColoredTypography color={color} variant="h6">
      <FormattedMessage id={title} />
    </ColoredTypography>
  );
};

export default Title;
