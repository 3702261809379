import { Link, Typography } from '@mui/material';
import { ToastErrorWithLink } from 'components/toasts/ToastErrorWithLink';
import { useShowAiImagesLimitReachedModal } from 'features/aiImages/AiImagesPage/AiImagesLimitReachedModal';
import { ImageGeneratorErrors } from 'features/aiImages/AiImagesPage/useImageGenerator';
import useUpdateSubscriptionModal from 'features/updateSubscriptionModal/hook/useUpdateSubscriptionModal';
import { ReactElement, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { termsOfUse } from 'services/api/utils/errorHyperlinks';
import useTr from 'utils/hooks/useTr';
import { isObject } from 'utils/isObject';

type Props = {
  error: unknown;
};

export function ErrorMessage({ error }: Props): ReactElement {
  const translate = useTr();
  const showAiImagesLimitReachedModal = useShowAiImagesLimitReachedModal();

  const errorCode = isObject(error) ? error?.message : error;

  const isPricingError = errorCode === ImageGeneratorErrors.ERROR_PRICING_REQUESTS_LIMIT_ACHIEVED;

  useEffect(() => {
    if (isPricingError) {
      showAiImagesLimitReachedModal();
    }
  }, [isPricingError, showAiImagesLimitReachedModal]);

  switch (errorCode) {
    case ImageGeneratorErrors.ERROR_PRICING_REQUESTS_LIMIT_ACHIEVED:
      return <LimitErrorMessage />;
    case ImageGeneratorErrors.ERROR_IMAGE_GENERATION_FAIR_USAGE_REACHED:
      return (
        <ToastErrorWithLink
          message={translate(`ai_images.generator_page.error.${errorCode}`)}
          link={termsOfUse}
        />
      );
    case ImageGeneratorErrors.ERROR_GENERATE_IMAGE_PROMPT_REJECTED:
    case ImageGeneratorErrors.ERROR_GENERATE_IMAGE_IMAGE_NOT_AVAILABLE:
    case ImageGeneratorErrors.ERROR_GENERATE_IMAGE_GENERATION_FAILED:
    case ImageGeneratorErrors.UPLOAD_ERROR_CANT_WRITE:
      return <Typography>{translate(`ai_images.generator_page.error.${errorCode}`)}</Typography>;
    default:
      return <Typography>{translate('ai_images.generator_page.result.failed.other')}</Typography>;
  }
}

function LimitErrorMessage(): ReactElement {
  const showUpgradeSubscriptionModal = useUpdateSubscriptionModal();

  return (
    <Typography align="center">
      <FormattedMessage
        id="ai_images.generator_page.result.failed.limit"
        values={{
          br: <br />,
          a: (content: string) => (
            <Link onClick={() => showUpgradeSubscriptionModal()}>{content}</Link>
          )
        }}
      />
    </Typography>
  );
}
