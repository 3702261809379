import { Typography } from '@mui/material';

type OwnProps = {
  children: React.ReactNode;
  required?: boolean;
  className?: string;
};

type Props = OwnProps & React.HTMLProps<HTMLLabelElement>;

const Label = ({ required, children }: Props) => (
  <Typography variant="subtitle2">
    {children}
    {required && <span className="form-required">*</span>}
  </Typography>
);

export default Label;
