import { createReducer } from '@reduxjs/toolkit';
import { APIModel } from 'services/api/embeddingModels/types';
import { switchLoadingDefault } from 'store/utils/genericReducers';
import { Loading } from 'types/ReduxStateTypes';
import { createAsyncAction } from 'utils/reduxUtils';
import { createLookupTable } from 'utils/utils';

export const getEmbeddingModels = createAsyncAction<undefined, APIModel[]>(
  'models/getEmbeddingModels'
);

export interface ModelsState extends Loading {
  models: APIModel[];
  modelsLookup: Record<string, number>;
  isReady: boolean;
}

const models = createReducer<ModelsState>(
  {
    models: [],
    modelsLookup: {},
    isLoading: false,
    isReady: false
  },
  builder =>
    builder
      .addCase(getEmbeddingModels.request, switchLoadingDefault(true))
      .addCase(getEmbeddingModels.success, (state, { payload }) => ({
        models: payload,
        modelsLookup: createLookupTable(payload, 'id'),
        isLoading: false,
        isReady: true
      }))
      .addCase(getEmbeddingModels.failure, switchLoadingDefault(false))
);

export default models;
