import AddCircleIcon from '@mui/icons-material/AddCircle';
import { IconButton } from 'components/iconButtons/IconButton';
import { ComponentProps } from 'react';
import styled from 'styled-components';

type Props = Omit<ComponentProps<typeof IconButton>, 'icon'>;

export const AddIconButton = (props: Props) => <IconButton icon={<Icon />} {...props} />;

const Icon = styled(AddCircleIcon)`
  color: ${({ theme }) => theme.colors.primary};
`;
