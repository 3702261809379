import React, { HTMLAttributes } from 'react';
import styled from 'styled-components';

import { getScoreColor } from './utils';

type Props = {
  value: number;
};

const ScoreBar = React.forwardRef<HTMLDivElement, Props & HTMLAttributes<HTMLDivElement>>(
  ({ value, ...props }, ref) => (
    <ScoreBarFiller {...props} ref={ref} backgroundColor={getScoreColor(value)} />
  )
);

const ScoreBarFiller = styled.div<{ backgroundColor: string }>`
  width: 24px;
  height: 24px;

  border-radius: 50%;
  background-color: ${({ backgroundColor }) => backgroundColor};
`;

export default ScoreBar;
