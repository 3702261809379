import { BaseSuccessDto } from 'services/backofficeIntegration/http/BaseSuccessDto';
import { backofficeClient } from 'services/backofficeIntegration/http/client/createBackofficeClient';
import { mutationEndpoint } from 'services/backofficeIntegration/http/endpointDefinition';
import { InformationDto } from 'services/backofficeIntegration/http/endpoints/infomration/httpGetInformationList';

type BaseInformationParams = {
  informationId: number;
  name: string;
  description: string;
  category_id: number | null;
};

export type UpdateInformationParams =
  | (BaseInformationParams & { type: 'text'; content: string })
  | (BaseInformationParams & { type: 'file' });

type SuccessDto = BaseSuccessDto<InformationDto>;

export const httpUpdateInformation = mutationEndpoint({
  callEndpoint: ({ informationId, ...params }: UpdateInformationParams) =>
    backofficeClient.put<SuccessDto>(`/informations/${informationId}`, params).then(response => {
      return response.data.data;
    })
});
