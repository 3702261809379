import { getNodeEntries, isEditor, isElement, PlateEditor } from '@udecode/plate-common';
import { PlateElementFormat } from 'features/aiWriter/AiWriterTextEditor/types';
import { Editor } from 'slate';

export const isBlockActive = (editor: PlateEditor, format: PlateElementFormat) => {
  const { selection } = editor;
  if (!selection) return false;

  const [match] = Array.from(
    getNodeEntries(editor, {
      // Hack: the Plate unhangRange() function doesn't return the new unhanged range
      at: Editor.unhangRange(editor as never, selection),
      match: (n: { type: string }) => !isEditor(n) && isElement(n) && n.type === format
    })
  );

  return !!match;
};
