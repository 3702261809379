import { Typography } from '@mui/material';
import FlexContainer from 'components/FlexContainer';
import dayjs from 'dayjs';
import { AiWriterSidebarPanel } from 'features/aiWriter/AiWriterSidebar/sidebar/AiWriterSidebarPanel';
import HistoryPlaceholder from 'features/aiWriter/AiWriterSidebar/steps/textInspirations/history/HistoryPlaceholder';
import { TextInspirationsStepHeader } from 'features/aiWriter/AiWriterSidebar/steps/textInspirations/TextInspirationsStepHeader';
import {
  BasicMarkdownPreview,
  MarkdownTile,
  NeutralButtonSurface
} from 'features/aiWriter/markdown/MarkdownTile';
import { useCopyMarkdownToEditor } from 'features/aiWriter/markdown/useCopyMarkdownToEditor';
import { setInspirationsCurrentStep } from 'features/aiWriter/store/actions/config/actions';
import { fetchHistoryThunk } from 'features/aiWriter/store/actions/history/thunks/fetchHistoryThunk';
import { getHistory, getIsHistoryIsLoading } from 'features/aiWriter/store/selectors';
import { AiWriterSidebarStep } from 'features/aiWriter/store/types';
import FormattedMessage from 'features/i18n/FormattedMessage';
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import styled from 'styled-components';
import useTr from 'utils/hooks/useTr';

export const HistorySubStep = () => {
  const history = useAppSelector(getHistory);
  const isHistoryIsLoading = useAppSelector(getIsHistoryIsLoading);
  const translate = useTr();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchHistoryThunk());
  }, [dispatch]);

  const copyToEditor = useCopyMarkdownToEditor();

  return (
    <AiWriterSidebarPanel
      title="aiWriter.inspirations.aiwriter.title"
      infoButton={<TextInspirationsStepHeader />}
      onBack={() => {
        dispatch(
          setInspirationsCurrentStep({ step: AiWriterSidebarStep.aiWriter, subStep: 'main' })
        );
      }}
    >
      <CardList>
        {!isHistoryIsLoading && history.length > 0 ? (
          history.map(option => (
            <MarkdownTile
              key={option.id}
              surface={
                <NeutralButtonSurface
                  onClick={() => copyToEditor(option.text)}
                  aria-label={translate('aiWriter.copy_to_editor')}
                />
              }
              markdown={
                <FlexContainer gap="small">
                  <BasicMarkdownPreview>{option.text}</BasicMarkdownPreview>
                  <DateIndicator variant="caption">
                    <FormattedMessage
                      id="aiWriter.inspirations.history.date"
                      values={{
                        date: dayjs(option.createdAt).format('DD.MM.YYYY'),
                        time: dayjs(option.createdAt).format('h:mm a')
                      }}
                    />
                  </DateIndicator>
                </FlexContainer>
              }
            />
          ))
        ) : (
          <HistoryPlaceholder />
        )}
      </CardList>
    </AiWriterSidebarPanel>
  );
};

const CardList = styled.div`
  display: grid;
  gap: ${({ theme }) => theme.spacings.small};
  padding-block: ${({ theme }) => theme.spacings.medium};
`;

const DateIndicator = styled(Typography)`
  display: flex;
  justify-content: flex-end;
  color: ${({ theme }) => theme.colors.blackMediumEmphasis};
  padding: ${({ theme }) => theme.spacings.medium};
  padding-top: 0;
`;
