import {
  Card,
  CardActionArea,
  CardContent,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Typography
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import DeleteIconButton from 'components/iconButtons/DeleteIconButton';
import { ForwardIconButton } from 'components/iconButtons/ForwardIconButton';
import { RefreshIconButton } from 'components/iconButtons/RefreshIconButton';
import { getUserName } from 'features/customer/store/selectors';
import EllipsisTooltip from 'features/subjectLines/CampaignConfigForm/SubjectLinesSelection/EllipsisTooltip';
import { useCallback, useMemo } from 'react';
import { useAppSelector } from 'store/hooks';
import styled from 'styled-components';
import { lightGreyBackground } from 'styles/styleUtils';
import useRunInTask from 'utils/hooks/useRunInTask';

import ScoreChip from '../../../../components/ScoreIndicators/ScoreChip';
import gtmIds from '../../../../services/tracking/GTMIds';
import { Device, MobileDevice, ScoredSubjectLine } from '../../store/types';
import { mobileDevicesConfiguration } from '../../store/utils';

// TODO divide into three files

type CommonProps = {
  value: ScoredSubjectLine;
  onSelect: () => void;
};

type DesktopProps = CommonProps & {
  onRemove: () => void;
  onRewrite: () => void;
};

type SuggestionProps = CommonProps & {
  device: Device;
  index: number;
  onRemove: () => void;
  onRewrite: () => void;
};

type MobileProps = CommonProps & {
  device: MobileDevice;
};

const MobileSuggestion = ({
  value: { subjectLine, predictedOpenRate },
  onSelect,
  device
}: MobileProps) => {
  const { cardWidth, firstContentPlaceholderWidth, secondContentPlaceholderWidth } =
    mobileDevicesConfiguration[device];

  const username = useAppSelector(getUserName);

  return (
    <MobileSuggestionContainer width={cardWidth}>
      <Card>
        <CardActionArea onClick={() => onSelect()}>
          <CardContent>
            <CardContainer>
              {device === 'iPhone' ? (
                <ScoreChip value={predictedOpenRate} size={16} />
              ) : (
                <ScoreChip
                  value={predictedOpenRate}
                  size={41}
                  label={
                    <Typography variant="h5" color="textPrimary">
                      {username.charAt(0).toUpperCase()}
                    </Typography>
                  }
                />
              )}
              <FlexColumnContainer>
                {username}
                <EllipsisTooltip text={subjectLine} />
                <PlaceholderRect width={firstContentPlaceholderWidth} />
                <PlaceholderRect width={secondContentPlaceholderWidth} />
              </FlexColumnContainer>
            </CardContainer>
          </CardContent>
        </CardActionArea>
      </Card>
    </MobileSuggestionContainer>
  );
};

const useStyleDesktop = makeStyles({
  secondaryAction: {
    paddingRight: 160
  }
});

const DesktopSuggestion = ({
  value: { subjectLine, predictedOpenRate },
  onSelect,
  onRemove,
  onRewrite
}: DesktopProps) => {
  const { selectSuggestionListItem, selectSuggestionArrowButton, deleteSuggestion } =
    gtmIds.subjectLines;

  const classes = useStyleDesktop();
  const [isLoading, runInTask] = useRunInTask();

  const rewrite = useCallback(async () => runInTask(() => onRewrite()), [runInTask, onRewrite]);

  const actions = useMemo(
    () => (
      <ButtonsContainer isLoading={isLoading}>
        <RefreshIconButton onClick={() => rewrite()} isLoading={isLoading} />
        <DeleteIconButton onClick={() => onRemove()} id={deleteSuggestion} />
        <ForwardIconButton onClick={() => onSelect()} id={selectSuggestionArrowButton} />
      </ButtonsContainer>
    ),
    [isLoading, deleteSuggestion, selectSuggestionArrowButton, rewrite, onRemove, onSelect]
  );

  return (
    <SelectedSubjectLineContainer>
      <ListItem
        button
        onClick={() => onSelect()}
        id={selectSuggestionListItem}
        classes={{
          secondaryAction: classes.secondaryAction
        }}
      >
        <ListItemAvatar>
          <ScoreChip value={predictedOpenRate} size={24} />
        </ListItemAvatar>
        <ListItemText>
          <EllipsisTooltip text={subjectLine} />
        </ListItemText>
        <ListItemSecondaryAction>{actions}</ListItemSecondaryAction>
      </ListItem>
    </SelectedSubjectLineContainer>
  );
};

const Suggestion = ({ device, ...rest }: SuggestionProps) => {
  return device === 'gmail' ? (
    <DesktopSuggestion {...rest} />
  ) : (
    <MobileSuggestion {...rest} device={device} />
  );
};

const MobileSuggestionContainer = styled.li<{ width: number }>`
  width: ${({ width }) => width}px;
  margin-bottom: 0.75rem;
`;

const PlaceholderRect = styled.div<{ width: number }>`
  height: 16px;
  width: ${({ width }) => width}px;
  border-radius: 10px;
  background-color: ${lightGreyBackground};
`;

const CardContainer = styled.div`
  display: flex;
  flex-wrap: nowrap;
  gap: 0.625rem;
  align-items: flex-start;
`;

const FlexColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  gap: 0.5rem;
  width: 85%;
`;

const ButtonsContainer = styled.div<{ isLoading: boolean }>`
  visibility: ${({ isLoading }) => (isLoading ? 'visible' : 'hidden')};
`;

const SelectedSubjectLineContainer = styled.li`
  &:hover ${ButtonsContainer} {
    visibility: inherit;
  }
`;

export default Suggestion;
