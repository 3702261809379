import cx from 'classnames';
import { css } from 'styled-components';

import Icon from '../base/Icon';

type Props = {
  className?: string;
  icon?: string;
  type?: 'collapse' | 'close' | 'fullscreen';
  onClick?: () => void;
};

const CardOptionsItem = ({ className, icon, type, onClick }: Props) => {
  const classes = cx(
    {
      'card-options-collapse': type === 'collapse',
      'card-options-remove': type === 'close',
      'card-options-fullscreen': type === 'fullscreen'
    },
    className
  );

  const getIconName = () => {
    if (icon) {
      return icon;
    }
    switch (type) {
      case 'collapse':
        return 'chevron-up';
      case 'close':
        return 'x';
      case 'fullscreen':
        return 'maximize';
      default:
        return '';
    }
  };

  return (
    <span className={classes}>
      <Icon
        name={getIconName()}
        size={1.25}
        onClick={onClick}
        css={css`
          vertical-align: -0.25rem;
        `}
      />
    </span>
  );
};

export default CardOptionsItem;
