import cx from 'classnames';
import React from 'react';
import { Reference } from 'react-popper';

import Button from '../base/Button';
import EmptyButton from '../base/EmptyButton';
import Icon from '../base/Icon';

type Props = {
  className?: string;
  toggle?: boolean;
  type?: 'link' | 'button' | 'icon';
  icon?: string;
  size?: 'sm' | 'lg';
  color?: string;
  disabled?: boolean;
  isNavLink?: boolean;
  hasActiveState?: boolean;
  isOption?: boolean;
  isOpen?: boolean;
  children?: React.ReactNode;

  onClick?: (event: React.MouseEvent) => void;
};

const DropdownTrigger = ({
  className,
  toggle = false,
  type = 'link',
  size,
  icon,
  color,
  disabled,
  isNavLink,
  hasActiveState,
  isOption,
  isOpen,
  children,

  onClick
}: Props) => {
  const classes = cx({ 'dropdown-toggle': toggle, 'nav-link': isNavLink }, className);

  if (type === 'link') {
    return (
      <Reference>
        {({ ref }) => (
          <EmptyButton className={classes} onClick={onClick} ref={ref}>
            {children}
          </EmptyButton>
        )}
      </Reference>
    );
  }

  if (type === 'button') {
    return (
      <Reference>
        {({ ref }) => (
          <Button
            className={classes}
            disabled={disabled}
            color={color}
            icon={icon}
            size={size}
            isDropdownToggle
            isOption={isOption}
            onClick={onClick}
            ref={ref}
          >
            {children}
          </Button>
        )}
      </Reference>
    );
  }

  if (type === 'icon') {
    return (
      <Reference>
        {({ ref }) => (
          <Icon
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            name={icon!}
            active={isOpen || hasActiveState}
            size={1.25}
            vAlign
            className={cx(classes, 'mx-2')}
            onClick={onClick}
            ref={ref}
          />
        )}
      </Reference>
    );
  }

  return null;
};

export default DropdownTrigger;
