import { InfoOutlined } from '@mui/icons-material';
import { Tooltip, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

const GridTitleContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1em;
  gap: 1em;
`;

const HeadingContainer = styled.span`
  display: flex;
  align-items: flex-end;
  gap: 0.5em;
`;

type Props = {
  title: string;
  subtitle: string;
  tooltip: string;
};

const BattlegroundGridTitle = ({ title, subtitle, tooltip }: Props) => {
  return (
    <GridTitleContainer>
      <HeadingContainer>
        <Typography variant="h6">
          <FormattedMessage id={title} />
        </Typography>
        <Typography variant="subtitle1" display="inline">
          <FormattedMessage id={subtitle} />
        </Typography>
      </HeadingContainer>
      <Tooltip title={<FormattedMessage id={tooltip} values={{ br: <br /> }} />}>
        <InfoOutlined color="action" />
      </Tooltip>
    </GridTitleContainer>
  );
};

export default BattlegroundGridTitle;
