import { BaseDimension, ManualDimension } from 'services/api/dimension/types';
import { createManualDimension, wordsToTags } from 'utils/wordUtils';

export const manualDimensionsFromWords = (words: string[]) =>
  wordsToTags(words).map(tag => createManualDimension([tag]));

export const manualDimensionFromBaseDimension = (
  baseDimension: BaseDimension<string>
): ManualDimension => ({
  ...baseDimension,
  words: wordsToTags(baseDimension.words)
});

export const baseDimensionFromManualDimension = (
  manualDimension: ManualDimension
): BaseDimension<string> => ({
  ...manualDimension,
  words: manualDimension.words.map(w => w.word)
});
