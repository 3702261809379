import FlexContainer from 'components/FlexContainer';
import { YellowCrownImage } from 'components/icons/YellowCrownImage';
import { planConfigs } from 'features/pricing/planConfigs';
import { PricingPlan } from 'features/pricing/types';
import { ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import PlanPerkSublist from './PlanPerkSublist';

interface Props {
  planType: PricingPlan;
}

const Root = styled(FlexContainer)`
  margin: 1rem 0;
`;

const PlanPerkList = ({ planType }: Props): ReactElement => {
  const { featurePerksAmount, requestPerksAmount, addOnsPerksAmount } = planConfigs[planType];

  return (
    <Root gap="four">
      <PlanPerkSublist planType={planType} listType="request" perksAmount={requestPerksAmount} />

      <PlanPerkSublist
        planType={planType}
        header={
          <FlexContainer direction="row" gap="one" alignItems="flex-start">
            <FormattedMessage
              id="profile.plan.perk_list"
              values={{
                b: (text: string) => <strong>{text}</strong>
              }}
            />
            <YellowCrownImage />
          </FlexContainer>
        }
        listType="feature"
        perksAmount={featurePerksAmount}
      />

      <PlanPerkSublist
        planType={planType}
        header={
          <FlexContainer direction="row" gap="one" alignItems="flex-start">
            <FormattedMessage
              id="profile.plan.perk_list.add_ons"
              values={{
                b: (text: string) => <strong>{text}</strong>
              }}
            />
          </FlexContainer>
        }
        listType="add_ons"
        perksAmount={addOnsPerksAmount}
      />
    </Root>
  );
};

export default PlanPerkList;
