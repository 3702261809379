import { Card, CardContent, Typography } from '@mui/material';
import FlexContainer from 'components/FlexContainer';
import { ClickableScoreLabel } from 'features/aiWriter/AiWriterSidebar/steps/flashScore/ClickableScoreLabel';
import {
  EmotionalityCircularProgress,
  ReadabilityCircularProgress
} from 'features/aiWriter/AiWriterSidebar/steps/flashScore/progress-circles';
import { getReadabilityAndEmotionalityGrade } from 'features/flashScore/getEmotionalityTranslationLabel';
import { FlashScoreTextRating } from 'features/flashScore/useScoreTexts';
import FormattedMessage from 'features/i18n/FormattedMessage';
import styled from 'styled-components';

type Props = {
  emotionality: FlashScoreTextRating;
  readability: FlashScoreTextRating;
};

export const CombinedEmotionalityAndReadabilityCard = ({ emotionality, readability }: Props) => {
  const combinedPercentage = (emotionality.percentage + readability.percentage) / 2;
  const combinedScore = (emotionality.score + readability.score) / 2;
  const combinedGrade = getReadabilityAndEmotionalityGrade(combinedScore);

  return (
    <Card variant="outlined">
      <CardContent>
        <FlexContainer direction="column" alignItems="center" gap="small">
          <Typography fontWeight="bold">
            <FormattedMessage id="flashScore.emotionality_and_readability.label" />
          </Typography>

          <StackedProgressRootContainer>
            <OuterReadabilityCircularProgress value={readability.percentage} />
            <InnerEmotionalityCircularProgress value={emotionality.percentage} />

            <CombinedLabel
              rating={{
                grade: combinedGrade,
                label: `flashScore.emotionality_and_readability.score_${combinedGrade}`,
                percentage: combinedPercentage,
                score: combinedScore
              }}
            />
          </StackedProgressRootContainer>
        </FlexContainer>
      </CardContent>
    </Card>
  );
};

const StackedProgressRootContainer = styled.div`
  position: relative;
  width: 100%;
  // Min height to fit all the progress circles
  min-height: 120px;
`;

const OuterReadabilityCircularProgress = styled(ReadabilityCircularProgress).attrs({
  withBackground: false,
  CircularProgressProps: {
    size: 120,
    thickness: 4
  }
})`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const InnerEmotionalityCircularProgress = styled(EmotionalityCircularProgress).attrs({
  withBackground: false,
  CircularProgressProps: {
    size: 92,
    thickness: 5
  }
})`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const CombinedLabel = styled(ClickableScoreLabel)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
