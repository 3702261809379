import { scoreTexts } from 'features/textGenerator/actions/scoreTexts';
import { GeneratedText } from 'features/textGenerator/store/types';

import { ScoredSubjectLine } from '../store/types';

export const scoreSubjectLines = async ({
  embeddingModelId,
  inspirations,
  audienceModelId
}: {
  embeddingModelId: string;
  inspirations: GeneratedText[];
  audienceModelId: string;
}): Promise<ScoredSubjectLine[]> => {
  const scoredTexts = await scoreTexts({
    embeddingModelId,
    audienceModelId,
    inspirations
  });

  return scoredTexts.map(({ id, score, text }) => ({
    id,
    subjectLine: text,
    predictedOpenRate: score
  }));
};
