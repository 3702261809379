import { makeCurrentUserQueryKeyPrefix } from 'features/currentUser/currentUserQueries';
import { BaseSuccessDto } from 'services/backofficeIntegration/http/BaseSuccessDto';
import { backofficeClient } from 'services/backofficeIntegration/http/client/createBackofficeClient';
import { queryEndpoint } from 'services/backofficeIntegration/http/endpointDefinition';
import { queryClient } from 'utils/ReactQueryClient';

export type LimitationDto = {
  limit: number;
  used: number;
  available: number;
  is_unlimited: boolean;
};

export type AllLimitationsDto = {
  gpt4_word: null | LimitationDto;
  image_generation: null | LimitationDto;
  plagiarism: null | LimitationDto;
  seobility: null | LimitationDto;
  unsplash: null | LimitationDto;
  word: null | LimitationDto;
};

type SuccessDto = BaseSuccessDto<AllLimitationsDto>;

export const httpGetAllLimitations = queryEndpoint({
  makeQueryKey: () => {
    return [...makeCustomerAllLimitationsQueryKeyPrefix()] as const;
  },
  callEndpoint: () =>
    backofficeClient
      .get<SuccessDto>('/customers/get-limitations')
      .then(response => response.data.data)
});

export function makeCustomerAllLimitationsQueryKeyPrefix() {
  return [...makeCurrentUserQueryKeyPrefix(), 'customer-limitations'];
}

export const invalidateCustomerAllLimitationsQueries = () =>
  queryClient.invalidateQueries(makeCustomerAllLimitationsQueryKeyPrefix());
