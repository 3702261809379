import { createAction, createReducer } from '@reduxjs/toolkit';
import { switchLoading, switchLoadingDefault } from 'store/utils/genericReducers';
import { createAsyncAction } from 'utils/reduxUtils';

import { Campaign, NewCampaign, ScoredSubjectLine, SubjectLinesState } from './types';

export const createCampaign = createAsyncAction<NewCampaign, Campaign>(
  'subjectLines/createCampaign'
);
export const getAllCampaigns = createAsyncAction<void, Campaign[]>('subjectLines/getAllCampaigns');
export const generateSubjectLines = createAsyncAction<void, ScoredSubjectLine[]>(
  'subjectLines/generateSubjectLines'
);
export const clearSubjectLines = createAction('subjectLines/clearSubjectLines');
export const updateCampaign = createAsyncAction<Campaign, Campaign>('subjectLines/updateCampaign');
export const deleteCampaigns = createAsyncAction<number[], number[]>('subjectLines/deleteCampaign');

const subjectLinesReducer = createReducer<SubjectLinesState>(
  {
    campaigns: [],
    suggestions: [],
    isLoading: false,
    isSuggestionsLoading: false
  },
  builder =>
    builder
      .addCase(createCampaign.request, switchLoadingDefault(true))
      .addCase(createCampaign.failure, switchLoadingDefault(false))
      .addCase(createCampaign.success, (state, { payload }) => {
        state.isLoading = false;
        state.campaigns.unshift(payload);
      })
      .addCase(getAllCampaigns.request, switchLoadingDefault(true))
      .addCase(getAllCampaigns.success, (state, { payload }) => {
        state.isLoading = false;
        state.campaigns = [...payload].reverse();
      })
      .addCase(getAllCampaigns.failure, switchLoadingDefault(false))
      .addCase(generateSubjectLines.request, switchLoading(true, 'isSuggestionsLoading'))
      .addCase(generateSubjectLines.success, (state, { payload }) => {
        state.isSuggestionsLoading = false;
        state.suggestions = payload;
      })
      .addCase(generateSubjectLines.failure, switchLoading(false, 'isSuggestionsLoading'))
      .addCase(clearSubjectLines, state => {
        state.suggestions = [];
      })
      .addCase(updateCampaign.success, (state, { payload }) => {
        const index = state.campaigns.findIndex(campaign => campaign.id === payload.id);
        state.campaigns[index] = payload;
        state.isLoading = false;
      })
      .addCase(deleteCampaigns.success, (state, { payload }) => {
        state.campaigns = state.campaigns.filter(campaign => !payload.includes(campaign.id));
      })
);

export default subjectLinesReducer;
