const adwordsLocation: Record<string, string> = {
  us: '2840',
  pl: '2616',
  de: '2276',
  it: '2380',
  in: '2356',
  cn: '2156',
  es: '2724',
  fr: '2250',
  se: '2752',
  uk: '2826',
  at: '2040',
  ch: '2756',
  sg: '2702',
  nl: '2528',
  ca: '2124',
  jp: '2392',
  br: '2076',
  ro: '2642',
  usnews: '2840',
  dach: '2276'
};

export const getAdwordsLocation = (modelCountry: string) => adwordsLocation[modelCountry];
