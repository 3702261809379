import styled, { css } from 'styled-components';

import useTr from '../../utils/hooks/useTr';
import Button from '../base/Button';
import Dropdown from '../Dropdown/Dropdown';
import { ActionsBottomSection } from './TableComponents';

type Props = {
  pageCount: number;
  pageOptions: number[];
  canPreviousPage: boolean;
  canNextPage: boolean;
  gotoPage: (pageIndex: number) => void;
  previousPage: () => void;
  nextPage: () => void;
  setPageSize: (pageSize: number) => void;
  pageIndex: number;
  pageSize: number;
};

const CurrentPageIndicator = styled.div.attrs(() => ({
  className: 'btn btn-primary'
}))`
  &&&:hover,
  &&&:focus,
  &&&:active {
    background-color: #03a9f4;
    border-color: #03a9f4;
  }
`;

const RowsDropdown = styled(Dropdown)`
  @media (max-width: 576px) {
    button {
      display: block;
      width: 100%;
    }

    display: block;
    width: 100%;
    margin: 0 1rem 0.5rem 0;
  }
`;

const Pagination = ({
  pageCount,
  pageOptions,
  canPreviousPage,
  canNextPage,
  gotoPage,
  previousPage,
  nextPage,
  setPageSize,
  pageIndex,
  pageSize
}: Props) => {
  const tr = useTr();

  return (
    <ActionsBottomSection>
      <RowsDropdown
        type="button"
        className="mr-2"
        color="secondary"
        itemsObject={[10, 25, 50, 75, 100].map(rows => ({
          value: tr('common.table.rows_per_page', { rows }),
          onClick: () => setPageSize(rows)
        }))}
        triggerContent={pageSize}
      />

      <div
        className="btn-group"
        css={css`
          @media (max-width: 576px) {
            width: 100%;
            .btn {
              width: 100%;
            }
          }
        `}
      >
        <Button onClick={() => gotoPage(0)} disabled={!canPreviousPage} icon="chevrons-left" />
        <Button onClick={previousPage} disabled={!canPreviousPage} icon="chevron-left" />
        <CurrentPageIndicator>
          {pageIndex + 1} / {pageOptions.length}
        </CurrentPageIndicator>
        <Button onClick={nextPage} disabled={!canNextPage} icon="chevron-right" />
        <Button
          onClick={() => gotoPage(pageCount - 1)}
          disabled={!canNextPage}
          icon="chevrons-right"
        />
      </div>
    </ActionsBottomSection>
  );
};

export default Pagination;
