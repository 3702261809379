import { useInfiniteQuery, useQuery } from '@tanstack/react-query';
import {
  httpGetAllTags,
  TagsPaginatedListParams
} from 'services/backofficeIntegration/http/endpoints/aiWriter/httpGetAllTags';
import {
  getNextPageParam,
  PaginatedListParams
} from 'services/backofficeIntegration/http/paginatedListEndpoint';
import { infiniteQueryKey } from 'utils/reactQuery/infiniteQueryKey';

export const useGetAllTags = (params: TagsPaginatedListParams, enabled: boolean) => {
  return useInfiniteQuery({
    queryKey: infiniteQueryKey(httpGetAllTags.makeQueryKey(params)),
    queryFn: (context: { pageParam?: PaginatedListParams }) => {
      const { pageParam = {} } = context;
      return httpGetAllTags.callEndpoint({ ...params, ...pageParam });
    },
    enabled,
    getNextPageParam: getNextPageParam
  });
};

export const useGetAllTagsPaginated = (params: TagsPaginatedListParams) => {
  return useQuery({
    queryKey: httpGetAllTags.makeQueryKey(params),
    queryFn: () => httpGetAllTags.callEndpoint(params),
    keepPreviousData: true
  });
};
