import { useModal } from 'components/modals';
import ConfirmModal from 'components/modals/ConfirmModal';
import { CloseModal } from 'components/modals/types';
import gtmIds from 'services/tracking/GTMIds';
import { withGtmInteraction } from 'services/tracking/withGtmInteraction';

type CustomProps = {
  categoryName: string;
  isLoading?: boolean;

  onDelete: () => void;
};

type Props = CloseModal & CustomProps;

export function ConfirmCategoryDeletionModal(props: Props) {
  const handleDelete = () => {
    props.onDelete();
    props.closeModal();
  };

  return (
    <ConfirmModal
      title="flashHub.information.table.tag.menu.delete_modal.title"
      message="flashHub.information.table.tag.menu.delete_modal.description"
      messageValues={{ name: props.categoryName }}
      onCancel={props.closeModal}
      cancelText="common.cancel"
      onOK={handleDelete}
      okText="common.delete"
      isLoading={props.isLoading}
      okButtonProps={{
        ...withGtmInteraction(gtmIds.brandHub.information.deletedInformationCategory)
      }}
    />
  );
}

export const useConfirmCategoryDeletionModal = () => {
  const { showModal } = useModal();

  return (props: CustomProps) => {
    showModal('CONFIRM_CATEGORY_DELETION', {
      ...props,
      size: 480
    });
  };
};
