/* eslint-disable @typescript-eslint/no-explicit-any */
import { AxisDomain, axisLeft, axisRight, AxisScale } from 'd3-axis';
import { format } from 'd3-format';
import { select as d3select, Selection } from 'd3-selection';
import { Transition } from 'd3-transition';
import { memo, useEffect, useRef } from 'react';
import { defaultGraphFont } from 'styles/styleUtils';
import { identity } from 'utils/utils';

type Props = {
  yScale: AxisScale<AxisDomain>;
  beforeDraw?: (g: Transition<SVGGElement, Record<string, unknown>, null, undefined>) => any;
  tickSize?: number;
  ticks?: number;
  tickPadding?: number;
  tickFormat?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  tickValues?: any;
  transform?: string;
  noDomain?: boolean;
  inverse?: boolean;
};

const AxisLeft = ({
  yScale,
  tickValues,
  ticks,
  tickSize = 6,
  tickFormat,
  tickPadding = 10,
  beforeDraw,
  transform = '',
  noDomain = true,
  inverse = false
}: Props) => {
  const groupRef = useRef<SVGGElement>(null);
  const selection = useRef<Selection<SVGGElement, Record<string, unknown>, null, undefined>>();

  useEffect(() => {
    if (!selection.current) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      selection.current = d3select(groupRef.current!);
    }

    selection.current.transition().call(group =>
      group
        .call(
          (inverse ? axisRight(yScale) : axisLeft(yScale))
            .ticks(ticks || null)
            .tickValues(tickValues)
            .tickFormat((tickFormat ? format(tickFormat) : null) as any)
            .tickSize(tickSize)
            .tickPadding(tickPadding)
            .tickSizeOuter(0)
        )
        .call(g => {
          if (noDomain) {
            g.select('.domain').remove();
          }
        })
        .call(beforeDraw || identity)
        .attr('font-family', defaultGraphFont)
        .attr('transform', transform)
    );
  });

  return <g ref={groupRef} />;
};

export default memo(AxisLeft);
