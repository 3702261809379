import cx from 'classnames';
import { IntlMessageKeys } from 'features/i18n/IntlMessageKeys';
import { uniqueId } from 'lodash';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import useConstant from '../../utils/hooks/useConstant';
import Tooltip from '../tooltips/Tooltip';

type Props = {
  className?: string;
  isInline?: boolean;
  label?: IntlMessageKeys;
  rawLabel?: React.ReactElement | string;
  tooltip?: React.ReactElement;
  required?: boolean;
} & React.ComponentPropsWithoutRef<'input'>;

const Checkbox = ({ className, isInline, label, rawLabel, tooltip, required, ...props }: Props) => {
  const id = useConstant(uniqueId);

  const classes = cx(
    'custom-control custom-checkbox mb-2',
    { 'custom-control-inline': isInline },
    className
  );

  const labelElement = (
    <label className="custom-control-label" htmlFor={id}>
      {label ? <FormattedMessage id={label} /> : <span>{rawLabel}</span>}
      {required && <span className="form-required">*</span>}
    </label>
  );
  const labelWithTooltip = (
    <Tooltip content={tooltip || ''} disabled={!tooltip}>
      {labelElement}
    </Tooltip>
  );

  return (
    <div className={classes}>
      <input type="checkbox" className="custom-control-input" {...props} id={id} />
      {tooltip ? labelWithTooltip : labelElement}
    </div>
  );
};

export default Checkbox;
