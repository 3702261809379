import { CircularProgress, Typography } from '@mui/material';
import { LinkButton } from 'components/buttons/LinkButton';
import FlexContainer from 'components/FlexContainer';
import { resendVerificationEmail } from 'features/authentication/resendVerificationEmail';
import FormattedMessage from 'features/i18n/FormattedMessage';
import { useCallback } from 'react';
import { shallowEqual } from 'react-redux';
import { useAppSelector } from 'store/hooks';
import styled from 'styled-components';
import useRunInTask from 'utils/hooks/useRunInTask';
import useToggle from 'utils/hooks/useToggle';

export const EmailVerificationRequiredModal = () => {
  const [hasResend, toggleHasResend] = useToggle();
  const [isLoading, runInTask] = useRunInTask();
  const { email } = useAppSelector(state => state.customer, shallowEqual);

  const handleResendClick = useCallback(async () => {
    await runInTask(() => resendVerificationEmail(email));

    toggleHasResend();
  }, [email, runInTask, toggleHasResend]);

  return (
    <ModalBody className="modal-body">
      <div>
        <Typography>
          <FormattedMessage id="email_verification.modal.headline" />
        </Typography>
        <ContentText>
          <FormattedMessage
            id="email_verification.modal.content"
            values={{ email: <strong>{email}</strong> }}
          />
        </ContentText>
      </div>

      <Footer>
        <LinkButton
          onClick={handleResendClick}
          disabled={isLoading || hasResend}
          endIcon={isLoading && <CircularProgress size={16} color="inherit" />}
        >
          <FormattedMessage id="email_verification.modal.resend_button" />
        </LinkButton>
      </Footer>
    </ModalBody>
  );
};

const ModalBody = styled.div`
  padding: ${({ theme }) => theme.spacings.xmedium};
`;

const ContentText = styled(Typography)`
  && {
    margin-top: ${({ theme }) => theme.spacings.medium};
  }
`;

const Footer = styled(FlexContainer)`
  width: 100%;
  margin-top: ${({ theme }) => theme.spacings.medium};
`;
