import { GenerateTextsInput } from 'features/textGenerator/actions/generateTexts';
import { getTrackingClient } from 'features/tracking/segment/getTrackingClient';
import { MessageInput } from 'services/backofficeIntegration/http/endpoints/aiWriter/httpSendMessage';
import { RequestParams } from 'services/backofficeIntegration/http/endpoints/textGeneration/httpGenerateTextStream';

type EventProperties = Record<
  string,
  | string
  | number
  | boolean
  | null
  | undefined
  | RequestParams
  | MessageInput
  | GenerateTextsInput
  | string[]
>;

interface TrackingProvider {
  track: (name: string, properties?: EventProperties) => void;
}

class TrackingWrapper {
  private trackingProvider: TrackingProvider;

  constructor(trackingProvider: TrackingProvider) {
    this.trackingProvider = trackingProvider;
  }

  track(name: string, properties?: EventProperties) {
    this.trackingProvider.track(name, properties);
  }
}

type SegmentType = ReturnType<typeof getTrackingClient>;

class SegmentProvider implements TrackingProvider {
  segment: SegmentType;

  constructor(segment: SegmentType) {
    this.segment = segment;
  }

  track(name: string, properties?: EventProperties) {
    this.segment.track(name, properties);
  }
}

const segment = getTrackingClient();
const segmentProvider = new SegmentProvider(segment);
const trackingWrapper = new TrackingWrapper(segmentProvider);

export { trackingWrapper };
