import cuid from 'cuid';

import Toast from '../../../components/toasts/Toast';
import useAudienceConfig from '../../audiences/hooks/useAudienceConfig';
import { scoreSubjectLines } from '../utils/scoreSubjectLines';

export default function useScoredSubjectLine({ audienceId }: { audienceId: number }) {
  const { model, audienceModelId } = useAudienceConfig({ audienceId });

  return (subjectLine: string) =>
    scoreSubjectLines({
      inspirations: [{ id: cuid(), text: subjectLine }],
      embeddingModelId: model.id,
      audienceModelId
    })
      .then(scoredSubjectLine => scoredSubjectLine[0])
      .catch(message => {
        Toast.backendErrorHTMLMessage(message);
      });
}
