import { audiencesSagas } from 'features/audiences/store/sagas';
import { customerSagas } from 'features/customer/store/sagas';
import { dimensionsSagas } from 'features/dimensions/store/sagas';
import { modelsSagas } from 'features/embeddingModels/store/sagas';
import { explorerSagas } from 'features/explorer/store/sagas';
import { teamSagas } from 'features/group/store/sagas';
import { listsSagas } from 'features/lists/store/sagas';
import { subjectLinesSagas } from 'features/subjectLines/store/sagas';
import { all } from 'typed-redux-saga';

function* rootSaga() {
  yield* all([
    ...explorerSagas,
    ...customerSagas,
    ...dimensionsSagas,
    ...listsSagas,
    ...modelsSagas,
    ...teamSagas,
    ...subjectLinesSagas,
    ...audiencesSagas
  ]);
}

export default rootSaga;
