import { deleteText, insertNodes } from '@udecode/plate-common';
import useEditor from 'features/aiWriter/AiWriterTextEditor/hooks/useEditor';
import { markdownToSlate } from 'features/aiWriter/markdown/markdownToSlate';
import { BaseSelection } from 'slate';

export function useReplaceTextWithMarkdown() {
  const editor = useEditor();

  return function copyToEditor(text: string, at: BaseSelection) {
    if (!editor) return;

    deleteText(editor, { at });
    insertNodes(editor, markdownToSlate(text));
  };
}
