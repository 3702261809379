import { Button, Typography } from '@mui/material';
import { Plate, PlateContent } from '@udecode/plate-common';
import { ReactComponent as CircleUserIcon } from 'assets/icons/icon-user-circle.svg';
import { ReactComponent as LogoIcon } from 'assets/neuroflash-logo.svg';
import Dimmer from 'components/base/Dimmer';
import FlexContainer from 'components/FlexContainer';
import { getRoutePath } from 'config/routes';
import dayjs from 'dayjs';
import { AiWriterPreviewWrongHash } from 'features/aiWriter/AiWriterPreview/AiWriterPreviewWrongHash';
import useAiWriterDocPreview from 'features/aiWriter/AiWriterPreview/hooks/useAiWriterDocPreview';
import { plateImageCaptionWorkaround } from 'features/aiWriter/plateImageCaptionWorkaround';
import { getHasToken } from 'features/customer/store/selectors';
import FormattedMessage from 'features/i18n/FormattedMessage';
import { usePlatePluginConfiguration } from 'features/plate/lib/plate/plate-plugins';
import React from 'react';
import { useNavigate } from 'react-router';
import { useAppSelector } from 'store/hooks';
import styled from 'styled-components';

type Props = {
  hash: string;
};

export const AiWriterPreview = ({ hash }: Props): React.ReactElement => {
  const navigate = useNavigate();

  const plugins = usePlatePluginConfiguration();

  const userHasToken = useAppSelector(getHasToken);

  const [project, isLoading] = useAiWriterDocPreview(hash);

  return (
    <Root>
      <Dimmer loader className="h-100" active={isLoading}>
        <Header justifyContent="space-between" direction="row" alignItems="center">
          <LogoIcon width={230} height={42} />
          <FlexContainer direction="row" gap="medium" alignItems="center">
            <CTAExplanation>
              <FormattedMessage id="aiWriter.share.cta.explanation" />
            </CTAExplanation>
            <Button
              variant="contained"
              onClick={() => navigate(getRoutePath(userHasToken ? 'aiWriter' : 'free'))}
            >
              <FormattedMessage
                id={
                  userHasToken ? 'aiWriter.share.cta.return_to_app' : 'aiWriter.share.cta.register'
                }
              />
            </Button>
          </FlexContainer>
        </Header>
        <Content>
          {project && (
            <>
              <Typography variant="h2">{project.name}</Typography>
              <FlexContainer gap="medium" direction="row" alignItems="center">
                {project.authorImage ? <Avatar src={project.authorImage} /> : <CircleUserIcon />}
                <Typography variant="subtitle1">{project.authorName}</Typography>
                <LastUpdate variant="subtitle1">
                  {dayjs(project.updatedAt).format('DD.MM.YYYY')}
                </LastUpdate>
              </FlexContainer>
              <Editor>
                <Plate plugins={plugins} initialValue={project.text}>
                  <PlateContent readOnly />
                </Plate>
              </Editor>
            </>
          )}
          {!project && !isLoading && <AiWriterPreviewWrongHash />}
        </Content>
      </Dimmer>
    </Root>
  );
};

const Root = styled.div`
  width: 100%;
  padding: ${({ theme }) => theme.spacings.medium};
`;

const Header = styled(FlexContainer)`
  width: 100%;
`;

const CTAExplanation = styled(Typography)`
  && {
    color: ${({ theme }) => theme.colors.primary};
  }
`;

const Content = styled(FlexContainer)`
  max-width: ${({ theme }) => theme.container.md};
  margin: ${({ theme }) => theme.spacings.xlarge} auto;
`;

const Avatar = styled.img`
  width: 20px;
  height: 20px;

  border-radius: 50%;
`;

const LastUpdate = styled(Typography)`
  && {
    color: ${({ theme }) => theme.colors.blackMediumEmphasis};
  }
`;

const Editor = styled.div`
  flex: 1 0 0;

  ${plateImageCaptionWorkaround};
`;
