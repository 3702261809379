export const createUuidV4 = () => {
  if (typeof window.crypto.randomUUID === 'function') {
    return crypto.randomUUID();
  }

  // Simplified polyfill implementation based on https://github.com/ungap/random-uuid/blob/main/index.js

  const getRandomValue = () => crypto.getRandomValues(new Uint8Array(1))[0];

  // The string is a better readable version of ([1e7] + -1e3 + -4e3 + -8e3 + -1e11)
  // Note: The regex simply replaces the number 0, 1 and 8; it's not some binary stuff
  return '10000000-1000-4000-8000-100000000000'.replace(/[018]/g, char => {
    // The base implementation uses "char" directly, which is better in terms of speed
    // But we don't require that nanosecond here, so it's fine to work more safely
    const number = Number(char);
    const shiftedNumber = 15 >> (number / 4);

    return (number ^ (getRandomValue() & shiftedNumber)).toString(16);
  });
};
