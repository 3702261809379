import { CircularProgress, InputAdornment, TextField, TextFieldProps } from '@mui/material';
import { FieldValidator, useField } from 'formik';
import { upperFirst } from 'lodash';
import React, { isValidElement } from 'react';

import useTr from '../../utils/hooks/useTr';

type Props = {
  name: string;
  value?: string;
  translateError?: boolean;
  isLoading?: boolean;
  endAdornment?: React.ReactNode;
  validate?: FieldValidator;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange?: (e: React.ChangeEvent<any>) => void;
} & TextFieldProps;

const TextInputField = ({
  name,
  value,
  helperText,
  translateError,
  type = 'text',
  endAdornment,
  isLoading,
  variant = 'outlined',
  validate,
  onChange,
  onBlur,
  ...rest
}: Props) => {
  const [field, meta] = useField({ name, type, validate });
  const { error, touched } = meta;

  const tr = useTr();

  const errorMessage = error && translateError ? tr(error) : upperFirst(error);
  const loadingSpinner = isLoading ? <CircularProgress size={24} /> : null;
  const hasEndAdornment = isValidElement(endAdornment) || isValidElement(loadingSpinner);

  return (
    <TextField
      {...rest}
      {...field}
      variant={variant}
      value={value ?? field.value}
      onChange={onChange ?? field.onChange}
      onBlur={onBlur ?? field.onBlur}
      type={type}
      error={!!error && touched}
      helperText={touched && error ? errorMessage : helperText}
      InputProps={{
        ...rest.InputProps,
        endAdornment: hasEndAdornment ? (
          <InputAdornment position="start">{endAdornment ?? loadingSpinner}</InputAdornment>
        ) : undefined
      }}
    />
  );
};

export default TextInputField;
