import Tippy, { TippyProps } from '@tippyjs/react';
import { ElementType } from 'react';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Props = TippyProps & { isUntargetable?: boolean; wrapper?: ElementType; wrapperProps?: any };

const Tooltip = ({
  children,
  isUntargetable,
  wrapper: Wrapper = 'span',
  wrapperProps = {},
  ...props
}: Props) => {
  if (isUntargetable) {
    return (
      <Tippy {...props}>
        <Wrapper {...wrapperProps}>{children}</Wrapper>
      </Tippy>
    );
  } else {
    return <Tippy {...props}>{children}</Tippy>;
  }
};

Tooltip.defaultProps = {
  arrow: true
};

export default Tooltip;
