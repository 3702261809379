import { Typography } from '@mui/material';
import backgroundDoodleOrnament from 'assets/background-doodle.png';
import { AuthCenteredPanel } from 'components/auth/AuthCenteredPanel';
import AuthForm from 'components/auth/AuthForm/AuthForm';
import useRedirectWhenAuthenticated from 'components/auth/hooks/useRedirectWhenAuthenticated';
import Alert from 'components/base/Alert';
import { Ornament } from 'components/Ornament';
import { ResetPasswordForm } from 'components/ResetPasswordForm';
import { getRoutePath } from 'config/routes';
import { removeError } from 'features/customer/store/actions';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import styled from 'styled-components';

export const ResetPassword = () => {
  useRedirectWhenAuthenticated();
  const dispatch = useAppDispatch();
  const error = useAppSelector(state => state.customer.error);

  return (
    <AuthCenteredPanel slogan="register.slogan">
      <AuthForm
        header="reset_password.title"
        extra={
          <Extra>
            <BackButton to={getRoutePath('login')}>
              <Typography variant="button">
                <FormattedMessage id="register.back_to_login" />
              </Typography>
            </BackButton>
          </Extra>
        }
        ornaments={<Ornament src={backgroundDoodleOrnament} bottom="0%" right="0%" />}
        error={
          error && (
            <Alert
              type="danger"
              icon="alert-circle"
              isDismissible
              onDismissClick={() => dispatch(removeError())}
            >
              <FormattedMessage id={error} />
            </Alert>
          )
        }
      >
        <Typography variant="body1">
          <FormattedMessage id="reset_password.body" values={{ br: <br /> }} />
        </Typography>
        <ResetPasswordForm />
      </AuthForm>
    </AuthCenteredPanel>
  );
};

const Extra = styled.div`
  text-align: center;
`;

const BackButton = styled(Link)`
  color: ${({ theme }) => theme.colors.primary};
  text-decoration: none;
  text-transform: uppercase;
`;
