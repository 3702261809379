import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import Toast from 'components/toasts/Toast';
import { delayExecution } from 'utils/utils';

const isRateLimitError = (response: AxiosResponse<unknown> | undefined) => response?.status === 429;

const getRetryAfterSeconds = (response: AxiosResponse<unknown> | undefined) => {
  const delay = response?.headers['retry-after'];
  if (!delay) {
    return null;
  }

  const delayInSeconds = Number(delay);
  if (Number.isNaN(delayInSeconds)) {
    return null;
  }

  return delayInSeconds;
};

export const withRateLimitSupport = (client: AxiosInstance) => {
  client.interceptors.response.use(undefined, async (error: unknown) => {
    if (!axios.isAxiosError(error) || !isRateLimitError(error.response)) {
      throw error;
    }

    // In some cases GCP also sends a 429, but it's not from our backend API
    // To prevent showing the user a rate limit error in this case, we expect a JSON response type,
    // because GCP seems to send a plaintext or HTML response, backend API always sends JSON
    if (error.response?.headers['content-type']?.includes('json') === false) {
      throw error;
    }

    Toast.error('rate_limit.error_general');

    const delayInSeconds = getRetryAfterSeconds(error.response);
    if (delayInSeconds === null) {
      throw error;
    }

    // Wait for the rate limit to reset
    await delayExecution(delayInSeconds * 1000);

    // Retry request
    return client(error.config as AxiosRequestConfig);
  });
};
