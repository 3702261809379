import { TableRow } from '@mui/material';
import { FeatureTableHeadCell } from 'components/profile/components/feature-comparison/FeatureTableHeadCell';
import { FeatureTableNegotiablePresenceCell } from 'components/profile/components/feature-comparison/FeatureTableNegotiablePresenceCell';
import { FeatureTablePresenceCell } from 'components/profile/components/feature-comparison/FeatureTablePresenceCell';
import { FeatureTableRowData } from 'components/profile/components/feature-comparison/FeatureTableRowData';

type Props = {
  row: FeatureTableRowData;
};

export const FeatureTableRow = ({ row }: Props) => (
  <TableRow>
    <FeatureTableHeadCell
      nameTranslationKey={row.nameTranslationKey}
      tooltipTranslationKey={row.tooltipTranslationKey}
    />

    <FeatureTablePresenceCell activeFlag={row.starterPlan} />
    <FeatureTablePresenceCell activeFlag={row.proPlan} />
    <FeatureTableNegotiablePresenceCell
      activeFlag={row.businessPlan}
      isNegotiable={row.isNegotiable}
    />
  </TableRow>
);
