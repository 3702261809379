import useRedirectWhenAuthenticated from 'components/auth/hooks/useRedirectWhenAuthenticated';
import { RecaptchaProvider } from 'components/RecaptchaProvider';
import { PaidRegistrationPanel } from 'components/register/PaidRegistrationPanel';
import { StripeProvider } from 'features/pricing/StripeProvider';

export const PaidRegistrationPage = () => {
  useRedirectWhenAuthenticated();

  return (
    <RecaptchaProvider>
      <StripeProvider>
        <PaidRegistrationPanel />
      </StripeProvider>
    </RecaptchaProvider>
  );
};
