import { SvgIconComponent } from '@mui/icons-material';
import { Typography } from '@mui/material';
import { CSSProperties, forwardRef, HTMLAttributes } from 'react';
import styled from 'styled-components';
import { newThemeConfig } from 'styles/new-theme/newThemeConfig';

export type BoxVariant = 'active' | 'completed' | 'error' | 'progress';
export const variantBackgroundMap: Record<BoxVariant, CSSProperties['background']> = {
  active: newThemeConfig.colors.primaryColorMain,
  progress: `linear-gradient(270deg, ${newThemeConfig.colors.primaryColorHover} 0%, #FFF 100%);`,
  completed: newThemeConfig.colors.componentsAlertSuccessBackground,
  error: newThemeConfig.colors.componentsAlertErrorBackground
} as const;

type Props = Omit<HTMLAttributes<HTMLDivElement>, 'children'> & {
  icon: SvgIconComponent;
  name: string;
  onMove?: (dragIndex: number, hoverIndex: number) => void;
  variant: BoxVariant | null;
  isInProgress?: boolean;
};

export const StepBox = forwardRef<HTMLDivElement, Props>(
  ({ icon: Icon, name, variant = null, isInProgress = false, ...rest }, ref) => (
    <Root ref={ref} $variant={variant} $inProgress={isInProgress} {...rest}>
      <Icon
        fontSize="small"
        color="inherit"
        style={{
          animation: isInProgress ? 'loading 1s linear infinite' : undefined
        }}
      />
      <TruncatedLabel $isActive={variant === 'active'}>{name}</TruncatedLabel>
    </Root>
  )
);

const Root = styled.div<{ $variant: Props['variant']; $inProgress: Props['isInProgress'] }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: ${({ theme }) => theme.spacings.four};
  padding: ${({ theme, $variant }) =>
    `${theme.spacings.two} ${$variant === 'active' ? theme.spacings.four : theme.spacings.three}`};

  border-radius: ${({ theme }) => theme.borderRadius.one};
  box-shadow: 1px 1px 3px ${({ theme }) => theme.shadow.card};
  background: ${({ theme, $variant }) =>
    $variant
      ? variantBackgroundMap[$variant]
      : theme.mode === 'dark'
      ? theme.colors.commonBlackMain
      : theme.colors.commonWhiteMain};
  color: ${({ theme, $variant }) =>
    $variant === 'active'
      ? theme.mode === 'dark'
        ? theme.colors.commonBlackMain
        : theme.colors.commonWhiteMain
      : 'inherit'};
  white-space: nowrap;

  transition: ${({ theme }) =>
    ['background-color', 'color', 'box-shadow', 'border-color', 'min-width']
      .map(property => `${property} ${theme.transition.default}`)
      .join(', ')};
`;

const TruncatedLabel = styled(Typography)<{ $isActive: boolean }>`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  max-width: ${({ $isActive }) => ($isActive ? '240px' : '64px')};
`;
