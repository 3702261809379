import { PlateElement, PlateElementProps } from '@udecode/plate-common';
import { cn } from 'features/plate/lib/utils';
import { ElementRef, forwardRef } from 'react';

const ParagraphElement = forwardRef<ElementRef<typeof PlateElement>, PlateElementProps>(
  ({ className, children, ...props }: PlateElementProps, ref) => {
    return (
      <PlateElement ref={ref} className={cn('m-0 px-0 py-1', className)} {...props}>
        {children}
      </PlateElement>
    );
  }
);
ParagraphElement.displayName = 'ParagraphElement';

export { ParagraphElement };
