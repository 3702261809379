import { getDimensions } from 'features/dimensions/store/actions';
import { selectList } from 'features/lists/store/actions';
import { getCurrentList } from 'features/lists/store/selectors';
import { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { RootState } from 'store/rootReducer';

import Icon from '../../../base/Icon';
import { useModal } from '../../../modals';
import Tooltip from '../../../tooltips/Tooltip';
import ListsDropdown from '../ListsDropdown';
import styles from './SavedListsDropdown.module.scss';

const mapStateToProps = (state: RootState) => ({
  lists: state.lists.lists,
  currentList: getCurrentList(state)
});

const mapDispatchToProps = {
  getDimensions: getDimensions.request,
  selectList
};

type Props = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps;

const SavedListsDropdown = ({ currentList, lists, selectList }: Props) => {
  const { showModal } = useModal();
  const renameList = useCallback(() => {
    showModal('RENAME_LIST', {
      modalTitle: 'lists.rename_list',
      id: currentList.id,
      listName: currentList.label,
      description: currentList.description
    });
  }, [currentList, showModal]);

  const listToDimension = useCallback(() => {
    showModal('LIST_TO_DIMENSION', {
      label: currentList.label,
      id: currentList.id
    });
  }, [currentList, showModal]);

  return (
    <ListsDropdown lists={lists} currentList={currentList} selectList={selectList}>
      {currentList.label && (
        <div className={styles.dropdownActions}>
          {currentList.id && (
            <Tooltip content={<FormattedMessage id="lists.export_to_dimension" />}>
              <Icon name="external-link" onClick={listToDimension} />
            </Tooltip>
          )}
          <Tooltip content={<FormattedMessage id="common.rename" />}>
            <Icon name="edit-3" onClick={renameList} />
          </Tooltip>
          <Tooltip content={<FormattedMessage id="common.remove" />}>
            <Icon name="trash-2" onClick={() => showModal('REMOVE_LIST')} />
          </Tooltip>
        </div>
      )}
    </ListsDropdown>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(SavedListsDropdown);
