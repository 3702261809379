import { backofficeClient } from 'services/backofficeIntegration/http/client/createBackofficeClient';
import { mutationEndpoint } from 'services/backofficeIntegration/http/endpointDefinition';

type SuccessDto = Blob;

const formats = ['docx', 'pdf', 'html'] as const;
type DocumentFormats = typeof formats[number];

export type DownloadDocParams = {
  content: string;
  format: DocumentFormats;
  title: string;
};

export const httpDownloadDocument = mutationEndpoint({
  callEndpoint: (params: DownloadDocParams) =>
    backofficeClient
      .post<SuccessDto>('/projects/ai-writer/download', params, {
        responseType: 'blob'
      })
      .then(response => response.data)
});
