import { RecordValues } from 'utils/typescript/RecordValues';

import { SafeEnum, SafeEnumDefinition } from './SafeEnum';

/**
 * Built-in typescript enums have some issues. So it's not recommended to use them.
 * You can see explanation here: https://youtu.be/jjMbPt_H3RQ
 */
export function make<Definition extends SafeEnumDefinition>(
  definition: Definition
): SafeEnum<Definition> {
  type Value = RecordValues<Definition>;
  const values = Object.values(definition) as Value[];

  return {
    ...definition,
    [Symbol.iterator]: () => values[Symbol.iterator]()
  };
}
