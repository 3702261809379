import Toast from 'components/toasts/Toast';
import LocalStorageKey from 'config/localStorageKey';
import { SessionStorageKey } from 'config/sessionStorageKey';
import { SetStateAction, useCallback, useEffect, useLayoutEffect, useState } from 'react';
import { getParsedStorageItem } from 'utils/getParsedStorageItem';

export function useStorage<V>(
  key: LocalStorageKey | SessionStorageKey | string,
  initialValue: V,
  encodeValue = true,
  storage: Storage = localStorage
): [V, (newValue: SetStateAction<V>) => void] {
  const [storedValue, setStoredValue] = useState(() =>
    getParsedStorageItem(key, initialValue, storage)
  );

  const setValue = useCallback(
    (value: SetStateAction<V>) => {
      try {
        const valueToStore = value instanceof Function ? value(storedValue) : value;
        setStoredValue(valueToStore);
        encodeValue
          ? storage.setItem(key, JSON.stringify(valueToStore))
          : storage.setItem(key, String(valueToStore));
      } catch (error) {
        Toast.error('error.local_storage');
      }
    },
    [encodeValue, key, storage, storedValue]
  );

  const handleStorageChange = useCallback(
    (event: StorageEvent) => {
      if (event.key !== key) {
        return;
      }

      const parsedNewValue = event.newValue ? (JSON.parse(event.newValue) as V) : initialValue;
      if (parsedNewValue !== storedValue) {
        setStoredValue(parsedNewValue);
      }
    },
    [initialValue, key, storedValue]
  );

  // Gets initial value from localStorage if available
  useLayoutEffect(() => {
    const newParsedValue = getParsedStorageItem(key, initialValue, storage);
    if (storedValue !== newParsedValue) {
      setStoredValue(newParsedValue);
    }
  }, [initialValue, key, storage, storedValue]);

  // React on direct storage changes in other tabs
  useEffect(() => {
    window.addEventListener('storage', handleStorageChange);

    return () => window.removeEventListener('storage', handleStorageChange);
  }, [handleStorageChange]);

  return [storedValue, setValue];
}
