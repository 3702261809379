import { getNodeString, PlateEditor } from '@udecode/plate';
import { CustomText } from 'features/aiWriter/AiWriterTextEditor/types';
import { Editor, Node, Path, Range } from 'slate';

export function getRangeTextWithSeparatedBlocks(
  editor: PlateEditor,
  range: Range,
  config: { noTrim?: boolean } = {}
) {
  const { noTrim = false } = config;
  const text = getSelectedBlocks()
    .map(([blockNode, blockPath]) => getBlockText(blockNode, blockPath))
    .join('\n');

  return noTrim ? text : text.trim();

  function getSelectedBlocks() {
    makePlateEditorCompatibleWithSlate(editor);

    return Array.from(
      Editor.nodes(editor, {
        at: range,
        match: node => Editor.isBlock(editor, node) && !Editor.isVoid(editor, node),
        mode: 'lowest'
      })
    );
  }

  function getBlockText(blockNode: Node, blockPath: Path) {
    return Array.from(Node.texts(blockNode))
      .map(([textNode, relativeTextPath]) =>
        getOnlySelectedText(blockPath, textNode, relativeTextPath)
      )
      .join('');
  }

  function getOnlySelectedText(blockPath: Path, textNode: CustomText, relativeTextPath: Path) {
    const textPath = [...blockPath, ...relativeTextPath];

    if (!Range.includes(range, textPath)) return '';

    const [start, end] = Range.edges(range);

    let text = getNodeString(textNode);

    /**
     * We are cutting off the end first because if we would cut off the start first
     * then we would have to adjust the end offset. This is relevant if the selection
     * range includes single text node.
     */
    if (Path.equals(textPath, end.path)) {
      text = text.slice(0, end.offset);
    }

    if (Path.equals(textPath, start.path)) {
      text = text.slice(start.offset);
    }

    return text;
  }

  function makePlateEditorCompatibleWithSlate(plateEditor: unknown): asserts plateEditor is Editor {
    // PlateEditor can be used in slate functions but types are not compatible so we need this nasty hack
  }
}
