import {
  createNodesHOC,
  isElementEmpty,
  PlaceholderProps,
  usePlaceholderState
} from '@udecode/plate-common';
import { ELEMENT_H1, ELEMENT_H2, ELEMENT_H3 } from '@udecode/plate-heading';
import { ELEMENT_PARAGRAPH } from '@udecode/plate-paragraph';
import { createPlateUI } from 'features/plate/create-plate-ui';
import { cn } from 'features/plate/lib/utils';
import { Children, cloneElement } from 'react';

export const Placeholder = (props: PlaceholderProps) => {
  const { children, placeholder, nodeProps, editor, element } = props;

  const isEmptyBlock = isElementEmpty(editor, element);
  const { enabled } = usePlaceholderState(props);

  const baseClass =
    'before:absolute before:cursor-text before:opacity-30 before:content-[attr(placeholder)]';
  const hoverClass =
    'hover:before:absolute hover:before:cursor-text hover:before:opacity-30 hover:before:content-[attr(placeholder)]';
  /**
   *  If 'enabled' is true, use the base class.
   *  If the block is empty, add a 'hover:' prefix to each class in the base class.
   * */

  const placeholderClassName = enabled ? cn(baseClass) : isEmptyBlock ? hoverClass : '';

  return Children.map(children, child => {
    return cloneElement(child, {
      className: child.props.className,
      nodeProps: {
        ...nodeProps,
        className: placeholderClassName,
        placeholder
      }
    });
  });
};

export const withPlaceholdersPrimitive = createNodesHOC(Placeholder);

export const withCustomPlaceholders = (
  components: ReturnType<typeof createPlateUI>,
  translate: (text: string) => string
) =>
  withPlaceholdersPrimitive(components, [
    {
      key: ELEMENT_PARAGRAPH,
      placeholder: translate('aiWriter.editor.placeholder.paragraph')
    },
    {
      key: ELEMENT_H1,
      placeholder: translate('aiWriter.editor.placeholder.h1')
    },
    {
      key: ELEMENT_H2,
      placeholder: translate('aiWriter.editor.placeholder.h2')
    },
    {
      key: ELEMENT_H3,
      placeholder: translate('aiWriter.editor.placeholder.h3')
    }
  ]);
