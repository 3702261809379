import LocalStorageKey from 'config/localStorageKey';
import { AppLanguage } from 'features/language/store/types';
import getBrowserLanguage from 'utils/getBrowserLanguage';

const isValidAppLanguage = (language: string): language is AppLanguage =>
  Object.values(AppLanguage).includes(language as AppLanguage);

export const getInitLanguage = () => {
  const localStorageLanguage = localStorage.getItem(LocalStorageKey.AppLanguage);

  if (localStorageLanguage) {
    return localStorageLanguage as AppLanguage;
  }

  const language = getBrowserLanguage();
  if (isValidAppLanguage(language)) {
    return language;
  }

  return AppLanguage.English;
};
