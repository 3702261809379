import { TextField } from '@mui/material';
import { BaseSetting } from 'features/modular-workflow/builder/settings/BaseSetting';
import { InputRendererContext } from 'features/modular-workflow/builder/settings/InputRenderer';
import { BuilderModularWorkflowStepInputWithValue } from 'features/modular-workflow/builder/types-builder';
import { useContext } from 'react';

export const DataTypeText = () => {
  const {
    input,
    inputSettings: settings,
    onChange,
    inputErrorList
  } = useContext(InputRendererContext);
  const typedInput = input as BuilderModularWorkflowStepInputWithValue;

  return (
    <BaseSetting hasEditableCheckbox={false}>
      {elementId => (
        <TextField
          id={elementId}
          placeholder={settings.description}
          variant="outlined"
          fullWidth={true}
          defaultValue={typedInput.value}
          error={inputErrorList.length > 0}
          helperText={inputErrorList.map(error => error.errorMessage).join(', ')}
          onChange={event => onChange(typedInput, event.target.value)}
        />
      )}
    </BaseSetting>
  );
};
