type Props = {
  language: string;
};

export function getPersonalityTemplateByLanguage(props: Props) {
  return templates[props.language] || [];
}

const templates: Record<string, Array<string>> = {
  de: [
    'Markenstimme Definition',
    'Markenname: _________________',
    ' ',
    'Definition der Markenstimme - Eine prägnante Darstellung der Persönlichkeit der Marke, wie sie durch ihren Ton gezeigt wird. Enthält Attribute wie professionell, freundlich, humorvoll, direkt, inspirierend, etc.:',
    '_________________',
    ' ',
    'Sprachliche Richtlinien',
    'Wortwahl: _________________',
    'Grammatik: _________________',
    'Satzstruktur: _________________',
    'Branchenspezifischer Jargon vs. Umgangssprache: _________________',
    'Perspektive (erste Person, dritte Person): _________________',
    'Aktiv vs. Passiv: _________________',
    'Informell (Du-Form-Ansprache) vs. Formell (Sie-Form-Ansprache): _________________',
    ' ',
    "Dos und Don'ts:",
    '_________________',
    ' ',
    'Glossar',
    'Branchenspezifische Begriffe und korrekte Verwendung: _________________',
    'Wichtige Markenbegriffe und korrekte Verwendung: _________________',
    ' ',
    'Markenidentitätsüberblick',
    'Firmenname: _________________',
    'Hintergrundinformation: _________________',
    'Mission: _________________',
    'Vision: _________________',
    'Werte: _________________',
    ' ',
    'Zielgruppen-Persona - Detaillierte Beschreibungen der Zielgruppen, einschließlich Bedürfnisse, Vorlieben und bevorzugte Sprachstile: _________________'
  ],
  en: [
    'Brand Voice Definition',
    'Brand Name: _________________',
    ' ',
    "Definition of Brand Voice - A concise depiction of the brand's personality, as shown through its tone. Include attributes like professional, friendly, humorous, straightforward, inspiring, etc.:",
    '_________________',
    ' ',
    'Linguistic Guidelines:',
    'Word Choice: _________________',
    'Grammar: _________________',
    'Sentence Structure: _________________',
    'Industry-specific Jargon vs. Colloquial Language: _________________',
    'Perspective (first-person, third-person): _________________',
    'Active vs. Passive Voice: _________________',
    'Informality vs. Formality: _________________',
    "Do's and Don'ts:",
    '_________________',
    ' ',
    'Glossary',
    'Industry-specific Terms and Correct Usage: _________________',
    'Key Brand Terms and Correct Usage: _________________',
    ' ',
    'Brand Identity Overview:',
    'Company Name: _________________',
    'Background Information: _________________',
    'Mission: _________________',
    'Vision: _________________',
    'Values: _________________',
    ' ',
    'Audience Persona - In-depth descriptions of target audiences, including needs, preferences, and preferred language styles: _________________'
  ],
  es: [
    'Definición de Voz de Marca',
    'Nombre de la Marca: _________________',
    ' ',
    'Definición de Voz de Marca - Una representación concisa de la personalidad de la marca, tal como se muestra a través de su tono. Incluir atributos como profesional, amigable, humorístico, directo, inspirador, etc.:',
    '_________________',
    ' ',
    'Directrices Lingüísticas:',
    'Elección de Palabras: _________________',
    'Gramática: _________________',
    'Estructura de Oraciones: _________________',
    'Jerga Específica de la Industria vs Lenguaje Coloquial: _________________',
    'Perspectiva (primera persona, tercera persona): _________________',
    'Voz Activa vs. Voz Pasiva: _________________',
    'Informalidad vs. Formalidad: _________________',
    'Consejos y Prohibiciones:',
    '_________________',
    ' ',
    'Glosario',
    'Términos Específicos de la Industria y Uso Correcto: _________________',
    'Términos Clave de la Marca y Uso Correcto: _________________',
    ' ',
    'Resumen de Identidad de Marca:',
    'Nombre de la Empresa: _________________',
    'Información de Fondo: _________________',
    'Misión: _________________',
    'Visión: _________________',
    'Valores: _________________',
    ' ',
    'Persona del Público - Descripciones detalladas de las audiencias objetivo, incluyendo necesidades, preferencias y estilos de lenguaje preferidos: _________________'
  ],
  ca: [
    'Definició de la Veu de la Marca',
    'Nom de la Marca: _________________',
    ' ',
    'Definició de la Veu de la Marca - Una representació concisa de la personalitat de la marca, com es mostra a través del seu to. Incloure atributs com professional, amigable, humorístic, directe, inspirador, etc.:',
    '_________________',
    ' ',
    'Directrius Lingüístiques:',
    'Elecció de Paraules: _________________',
    'Gramàtica: _________________',
    'Estructura de Frases: _________________',
    'Jerga Específica de la Indústria vs Llenguatge Coloquial: _________________',
    'Perspectiva (primera persona, tercera persona): _________________',
    'Veu Activa vs. Veu Passiva: _________________',
    'Informalitat vs. Formalitat: _________________',
    'Consells i Prohibicions:',
    '_________________',
    ' ',
    'Glossari',
    'Termes Específics de la Indústria i Ús Correcte: _________________',
    'Termes Clau de la Marca i Ús Correcte: _________________',
    ' ',
    'Resum de la Identitat de la Marca:',
    "Nom de l'Empresa: _________________",
    'Informació de Fons: _________________',
    'Missió: _________________',
    'Visió: _________________',
    'Valors: _________________',
    ' ',
    "Persona de l'Audiència - Descripcions detallades dels públics objectiu, incloent necessitats, preferències i estils de llenguatge preferits: _________________"
  ],
  it: [
    'Definizione della Voce del Brand',
    'Nome del Brand: _________________',
    ' ',
    'Definizione della Voce del Brand - Una rappresentazione concisa della personalità del brand, come mostrato attraverso il suo tono. Includere attributi come professionale, amichevole, umoristico, diretto, ispiratore, ecc.:',
    '_________________',
    ' ',
    'Linee Guida Linguistiche:',
    'Scelta delle Parole: _________________',
    'Grammatica: _________________',
    'Struttura delle Frasi: _________________',
    'Gergo Specifico del Settore vs Linguaggio Colloquiale: _________________',
    'Prospettiva (prima persona, terza persona): _________________',
    'Voce Attiva vs. Voce Passiva: _________________',
    'Informalità vs. Formalità: _________________',
    "Do's e Don'ts: _________________",
    '',
    'Glossario',
    'Termini Specifici del Settore e Uso Corretto: _________________',
    'Termini Chiave del Brand e Uso Corretto: _________________',
    '',
    "Panoramica dell'Identità del Brand:",
    "Nome dell'Azienda: _________________",
    'Informazioni di Base: _________________',
    'Missione: _________________',
    'Visione: _________________',
    'Valori: _________________',
    ' ',
    "Persona dell'Audience - Descrizioni dettagliate dei target di riferimento, incluse esigenze, preferenze e stili di linguaggio preferiti: _________________"
  ],
  fr: [
    'Définition de la Voix de la Marque',
    'Nom de la Marque : _________________',
    '',
    "Définition de la Voix de la Marque - Une représentation concise de la personnalité de la marque, telle qu'elle se manifeste à travers son ton. Inclure des attributs tels que professionnel, amical, humoristique, direct, inspirant, etc. :",
    '_________________',
    '',
    'Directives Linguistiques :',
    'Choix des Mots : _________________',
    'Grammaire : _________________',
    'Structure des Phrases : _________________',
    "Jargon Spécifique à l'Industrie vs Langage Familier : _________________",
    'Perspective (première personne, troisième personne) : _________________',
    'Voix Active vs Voix Passive : _________________',
    'Informalité vs Formalité : _________________',
    'Conseils et Interdictions :',
    '_________________',
    '',
    'Glossaire',
    "Termes Spécifiques à l'Industrie et Usage Correct : _________________",
    'Termes Clés de la Marque et Usage Correct : _________________',
    '',
    "Présentation de l'Identité de la Marque :",
    "Nom de l'Entreprise : _________________",
    'Informations de Fond : _________________',
    'Mission : _________________',
    'Vision : _________________',
    'Valeurs : _________________',
    '',
    "Persona de l'Audience - Descriptions détaillées des publics cibles, y compris les besoins, les préférences et les styles de langue préférés : _________________"
  ],
  pl: [
    'Definicja Głosu Marki',
    'Nazwa Marki: _________________',
    '',
    'Definicja Głosu Marki - Zwięzłe przedstawienie osobowości marki, wyrażone poprzez jej ton. Zawiera atrybuty takie jak profesjonalizm, przyjazność, dowcip, prostota, inspiracja, itp.:',
    '_________________',
    '',
    'Wytyczne Językowe:',
    'Wybór Słów: _________________',
    'Gramatyka: _________________',
    'Struktura Zdań: _________________',
    'Język Branżowy vs Potoczny Język: _________________',
    'Perspektywa (pierwsza osoba, trzecia osoba): _________________',
    'Czas Czynny vs Czas Bierny: _________________',
    'Formalność vs Nieformalność: _________________',
    'Rady i Zakazy:',
    '_________________',
    '',
    'Glosariusz',
    'Specyficzne dla Branży Terminy i Prawidłowe Użycie: _________________',
    'Kluczowe Terminy Marki i Prawidłowe Użycie: _________________',
    '',
    'Przegląd Tożsamości Marki:',
    'Nazwa Firmy: _________________',
    'Informacje Ogólne: _________________',
    'Misja: _________________',
    'Wizja: _________________',
    'Wartości: _________________',
    '',
    'Persona Audiencji - Szczegółowe opisy grupy docelowej, w tym potrzeby, preferencje i preferowane style językowe: _________________'
  ],
  nl: [
    'Definitie van Merkstem',
    'Merknaam: _________________',
    '',
    'Definitie van Merkstem - Een beknopte weergave van de persoonlijkheid van het merk, zoals weergegeven door de toon ervan. Inclusief eigenschappen zoals professioneel, vriendelijk, humoristisch, direct, inspirerend, etc.:',
    '_________________',
    '',
    'Linguïstische Richtlijnen:',
    'Woordkeuze: _________________',
    'Grammatica: _________________',
    'Zinsstructuur: _________________',
    'Branchespecifieke Jargon vs. Informele Taal: _________________',
    'Perspectief (eerste persoon, derde persoon): _________________',
    'Actieve vs. Passieve Stem: _________________',
    'Informaliteit vs. Formaliteit: _________________',
    "Do's en Don'ts: _________________",
    '',
    'Woordenlijst',
    'Branchespecifieke Termen en Correct Gebruik: _________________',
    'Belangrijke Merktermen en Correct Gebruik: _________________',
    '',
    'Overzicht van Merkidentiteit:',
    'Bedrijfsnaam: _________________',
    'Achtergrondinformatie: _________________',
    'Missie: _________________',
    'Visie: _________________',
    'Waarden: _________________',
    '',
    'Doelgroep Persona - Gedetailleerde beschrijvingen van de doelgroep, inclusief behoeften, voorkeuren en gewenste taalstijlen: _________________'
  ]
};
