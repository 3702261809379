import { getRoutePath } from 'config/routes';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

export const RegistrationReturnToLogin = () => (
  <FormattedMessage
    id="register.already_have_account"
    tagName="div"
    values={{
      login: (
        <Link to={getRoutePath('login')}>
          <FormattedMessage id="common.login" />
        </Link>
      )
    }}
  />
);
