import { getRoutePath } from 'config/routes';
import { AiWriterPreview } from 'features/aiWriter/AiWriterPreview/AiWriterPreview';
import React from 'react';
import { useParams } from 'react-router';
import { Navigate } from 'react-router-dom';

type AiWriterPreviewPageParams = {
  hash: string;
};

export const AiWriterPreviewPage = (): React.ReactElement => {
  const params = useParams<AiWriterPreviewPageParams>();

  if (params.hash) {
    return <AiWriterPreview hash={params.hash} />;
  }

  return <Navigate to={{ pathname: getRoutePath('login') }} />;
};
