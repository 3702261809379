import FlexContainer from 'components/FlexContainer';
import { PrimaryChip } from 'features/aiWriter/AiWriterSidebar/steps/textInspirations/NumberOfSuggestionsChips';
import { useState } from 'react';
import { GAEvents } from 'services/tracking/GAEvents';
import useTr from 'utils/hooks/useTr';

type Props = {
  onClick: (val: TextLength | null) => void;
};

const lengths: Record<string, string> = {
  short: 'compact (short)',
  medium: 'moderate',
  long: 'very detailed (long)'
};
export type TextLength = typeof lengths[keyof typeof lengths];

export const TextLengthChips = ({ onClick }: Props) => {
  const [selected, setSelected] = useState<string | null>(null);
  const tr = useTr();

  const handleClick = (val: TextLength) => {
    GAEvents.textLengthChipClicked({ textLength: val });
    if (val === selected) {
      setSelected(null);
      onClick(null);
    } else {
      setSelected(val);
      onClick(val);
    }
  };

  const isActive = (val: string) => {
    return selected === val;
  };

  return (
    <FlexContainer direction="row" gap="small">
      {Object.keys(lengths).map(key => (
        <PrimaryChip
          key={lengths[key]}
          label={tr(`aiWriter.inspirations.aiwriter.main.text_length.${key}`)}
          $selected={isActive(lengths[key])}
          onClick={() => handleClick(lengths[key])}
        />
      ))}
    </FlexContainer>
  );
};
