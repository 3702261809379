import { BaseSuccessDto } from 'services/backofficeIntegration/http/BaseSuccessDto';
import { backofficeClient } from 'services/backofficeIntegration/http/client/createBackofficeClient';
import { PersonalityDto } from 'services/backofficeIntegration/http/dtos/PersonalityDto';
import { mutationEndpoint } from 'services/backofficeIntegration/http/endpointDefinition';

export type SetDefaultPersonalityParams = {
  personalityId: number;
  country: string;
  language: string;
};

type SuccessDto = BaseSuccessDto<PersonalityDto>;

export const httpSetDefaultPersonality = mutationEndpoint({
  callEndpoint: ({ personalityId, ...params }: SetDefaultPersonalityParams) =>
    backofficeClient.put<SuccessDto>(`/chat/personalities/${personalityId}/set-default`, params)
});
