import { AiWriterSupportedLanguageAndCountryCode } from 'features/aiWriter/aiWriterSupportedLanguageAndCountryCodes';
import { getCurrentLanguage } from 'features/language/store/selectors';
import { AppLanguage } from 'features/language/store/types';
import { useAppSelector } from 'store/hooks';

export const useGetTonalityLanguageAndCountryCode = (): AiWriterSupportedLanguageAndCountryCode => {
  const appLanguage = useAppSelector(getCurrentLanguage);

  switch (appLanguage) {
    case AppLanguage.English:
      return 'en_us';
    case AppLanguage.German:
      return 'de_de';
    case AppLanguage.Spanish:
      return 'es_es';
  }
};
