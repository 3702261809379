import dayjs from 'dayjs';
import { AiWriterTab, SharingPermission } from 'features/aiWriter/store/types';

export const defaultTab: AiWriterTab = {
  id: '',
  hash: '',
  name: '',
  embeddingModelId: '',
  text: [],
  short_text: '',
  textInspirations: [],
  images: [],
  imagesNotFound: false,
  isLoading: {},
  generateTextConfig: {
    audienceId: 0,
    outputType: 'slogans'
  },
  updatedAt: dayjs().toString(),
  seoAnalysis: undefined,
  seoConfig: {
    searchEngine: '',
    keyword: ''
  },
  seoHighlightedKeyword: undefined,
  isSaving: false,
  isPublic: false,
  sharingPermission: SharingPermission.private,
  authorId: 0,
  authorName: ''
};
