import { Link } from '@mui/material';
import FormattedMessage from 'features/i18n/FormattedMessage';
import { IntlMessageKeys } from 'features/i18n/IntlMessageKeys';
import styled from 'styled-components';

type Props = {
  message: IntlMessageKeys;
};

export const ErrorFallback = ({ message }: Props) => (
  <Root>
    <FormattedMessage
      id={message}
      values={{
        b: (value: string) => <strong>{value}</strong>,
        br: <br />,
        mail: (msg: string) => (
          <Link href={`mailto:${msg}`} target="_blank" rel="noreferrer">
            {msg}
          </Link>
        )
      }}
    />
  </Root>
);

const Root = styled.div`
  // Important is used to overwrite potential parent styles
  padding: ${({ theme }) => theme.spacings.medium} !important;
  margin: ${({ theme }) => theme.spacings.medium} !important;
  background-color: ${({ theme }) => theme.colors.white} !important;
  text-align: center;
`;
