import { Button, Typography } from '@mui/material';
import linkedin from 'assets/icons/linkedin-logo.svg';
import LocalStorageKey from 'config/localStorageKey';
import FormattedMessage from 'features/i18n/FormattedMessage';
import { encodeSocialLoginState } from 'features/social/encodeSocialLoginState';
import { socialButtonStyles } from 'features/social/SocialButtonStyles';
import { SocialProviderComponentProps } from 'features/social/SocialRegisterOrLoginForm';
import { useRef } from 'react';
import { getSocialProviderRedirectUri } from 'services/api/social';
import { createUuidV4 } from 'utils/createUuidV4';
import { withTestId } from 'utils/utils';

type Props = SocialProviderComponentProps;

export function LinkedInLoginButton({
  formName,
  newsletterSubscribed,
  isPaid,
  campaignUrl
}: Props) {
  const stateRef = useRef(createUuidV4());

  const state = encodeSocialLoginState({
    uuid: stateRef.current,
    newsletterSubscribed,
    isPaid,
    campaignUrl
  });

  const messageId = formName === 'login' ? 'social.login.linkedin' : 'social.signup.linkedin';

  const redirectUri = getSocialProviderRedirectUri('linkedin');
  const linkedinAuthLink = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${process.env.REACT_APP_LINKEDIN_SOCIAL_LOGIN_CLIENT_ID}&redirect_uri=${redirectUri}&state=${state}&scope=r_liteprofile%20r_emailaddress`;
  return (
    <Button
      css={socialButtonStyles}
      component="a"
      href={linkedinAuthLink}
      startIcon={<img src={linkedin} />}
      onClick={() => localStorage.setItem(LocalStorageKey.OauthCsrfState, state)}
      {...withTestId('linkedin-login-button')}
    >
      <Typography variant="subtitle1">
        <FormattedMessage id={messageId} />
      </Typography>
    </Button>
  );
}
