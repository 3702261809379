import {
  AddCircleRounded,
  ArticleRounded,
  LightbulbRounded,
  ThumbUpAltRounded
} from '@mui/icons-material';
import { Avatar } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { ReactComponent as AiImagesIcon } from 'assets/icons/icon-home-aiImages-tile.svg';
import { getRoutePath } from 'config/routes';
import { setInspirationsCurrentStep } from 'features/aiWriter/store/actions/config/actions';
import FormattedMessage from 'features/i18n/FormattedMessage';
import { ModularWorkflowThumbnailBox } from 'features/modular-workflow/builder/ModularWorkflowThumbnailBox';
import { ApiModularWorkflowSharingPermissions } from 'features/modular-workflow/builder/types-api';
import { trackingWrapper } from 'features/tracking/wrapper/TrackingWrapper';
import { ReactNode } from 'react';
import { useNavigate } from 'react-router';
import {
  httpGetModularWorkflows,
  PaginatedListParams
} from 'services/backofficeIntegration/http/endpoints/modularWorkflow/httpGetModularWorkflows';
import gtmIds from 'services/tracking/GTMIds';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
import { withTestId } from 'utils/utils';

export type QuickActionCardProps = {
  name: string;
  title: ReactNode;
  subtitle: ReactNode;
  gtmId?: string;
  onClick: () => void;
  icon?: ReactNode;
};

export const QuickActionSteps = {
  newDocument: 'newDocument',
  socialMediaPostBuilder: 'socialMediaPostBuilder',
  blogPostBuilder: 'blogPostBuilder',
  imageFlash: 'imageFlash'
};

export const useQuickActions = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const workflowQueryParams: PaginatedListParams = {
    view_mode: 'run',
    sharing_permissions: [
      ApiModularWorkflowSharingPermissions.public,
      ApiModularWorkflowSharingPermissions.workspace,
      ApiModularWorkflowSharingPermissions.other_workspaces,
      ApiModularWorkflowSharingPermissions.my
    ],
    sort: 'order',
    // We've 4 hardcoded actions, + 5 workflows for page 1 and request enough for 2 additional pages
    limit: 5 + 9 * 2
  };

  const { data: modularWorkflowsData } = useQuery({
    queryKey: httpGetModularWorkflows.makeQueryKey(workflowQueryParams),
    queryFn: () => httpGetModularWorkflows.callEndpoint(workflowQueryParams),
    keepPreviousData: true
  });
  const modularWorkflows = modularWorkflowsData?.workflows ?? [];

  const handleNewDocumentClick = async () => {
    await navigate(getRoutePath('aiWriter'));
    trackingWrapper.track('aiWriterOverviewNewDocumentStarted');

    navigate(getRoutePath('newDocument'));
  };

  const handleSocialMediaPostBuilderClick = async () => {
    trackingWrapper.track('aiWriterOverviewSocialMediaBuilderStarted');

    navigate(getRoutePath('socialPostWorkflow'));
    dispatch(setInspirationsCurrentStep(undefined));
  };

  const handleBlogPostBuilderClick = async () => {
    trackingWrapper.track('aiWriterOverviewBlogPostBuilderStarted');

    navigate(getRoutePath('blogPostWorkflow'));
  };

  const handleImageFlashClick = () => {
    navigate(getRoutePath('aiImages'));
  };

  const handleModularWorkflowClick = (workflowId: number) => {
    navigate(getRoutePath('modularWorkflowRun', { workflowId }));
  };

  const quickActions: QuickActionCardProps[] = [
    {
      name: QuickActionSteps.newDocument,
      title: <FormattedMessage id="common.new_document" {...withTestId('blank-document-card')} />,
      subtitle: <FormattedMessage id="aiWriter.project_overview.blank_document_description" />,
      onClick: handleNewDocumentClick,
      gtmId: gtmIds.aiWriter.createNewProject,
      icon: (
        <StyledAvatar>
          <AddCircleRounded />
        </StyledAvatar>
      )
    },
    {
      name: QuickActionSteps.blogPostBuilder,
      title: <FormattedMessage id="aiWriter.project_overview.blog_post_builder" />,
      subtitle: <FormattedMessage id="aiWriter.project_overview.blog_post_builder_description" />,
      onClick: handleBlogPostBuilderClick,
      gtmId: gtmIds.aiWriter.newBlogPostBuilder,
      icon: (
        <StyledAvatar>
          <ArticleRounded />
        </StyledAvatar>
      )
    },
    {
      name: QuickActionSteps.socialMediaPostBuilder,
      title: <FormattedMessage id="aiWriter.project_overview.social_post_builder.title" />,
      subtitle: <FormattedMessage id="aiWriter.project_overview.social_post_builder.subtitle" />,
      onClick: handleSocialMediaPostBuilderClick,
      gtmId: gtmIds.aiWriter.newSocialPostBuilder,
      icon: (
        <StyledAvatar>
          <ThumbUpAltRounded />
        </StyledAvatar>
      )
    },
    {
      name: QuickActionSteps.imageFlash,
      title: <FormattedMessage id="aiWriter.project_overview.ai_images" />,
      subtitle: <FormattedMessage id="aiWriter.project_overview.ai_images_description" />,
      onClick: handleImageFlashClick,
      gtmId: gtmIds.aiWriter.newAIImages,
      icon: (
        <StyledAvatar>
          <AiImagesIcon />
        </StyledAvatar>
      )
    }
  ];

  // Add modular workflows to quick actions
  modularWorkflows.forEach(workflow => {
    quickActions.push({
      name: workflow.name,
      title: workflow.name,
      subtitle: workflow.description,
      onClick: () => handleModularWorkflowClick(workflow.id),
      icon: workflow.thumbnail ? (
        <StyledCustomThumbnailContainer>
          <ModularWorkflowThumbnailBox thumbnail={workflow.thumbnail} insideAvatar={true} />
        </StyledCustomThumbnailContainer>
      ) : (
        <StyledAvatar>
          <LightbulbRounded />
        </StyledAvatar>
      )
    });
  });

  return quickActions;
};

const StyledAvatar = styled(Avatar)`
  && {
    background-color: ${({ theme }) => theme.colors.primaryColorMain};
    width: 56px;
    height: 56px;

    color: ${({ theme }) => theme.colors.commonWhiteMain};

    svg {
      font-size: 35px;
    }
  }
`;

const StyledCustomThumbnailContainer = styled(Avatar)`
  && {
    background-color: transparent;
    width: 56px;
    height: 56px;

    color: ${({ theme }) => theme.colors.commonWhiteMain};

    svg {
      font-size: 35px;
    }
  }
`;
