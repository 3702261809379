import { CustomerData } from 'services/api/customer/types';
import { ApiExtendedResponse } from 'services/api/types';
import { backofficeClient } from 'services/backofficeIntegration/http/client/createBackofficeClient';
import { mutationEndpoint } from 'services/backofficeIntegration/http/endpointDefinition';
import { InvitationErrorCode } from 'services/backofficeIntegration/http/endpoints/teamMembers/httpTeamInvitationRegister';

type ResponseDto = ApiExtendedResponse<CustomerData, InvitationErrorCode>;

export type InvitationAcceptanceParams = {
  token: string;
  locale: string;
  timezone: string;
};

export const httpTeamInvitationAccept = mutationEndpoint({
  callEndpoint: (params: InvitationAcceptanceParams) =>
    backofficeClient
      .post<ResponseDto>('/teams/invites/accept', params)
      .then(response => response.data)
});
