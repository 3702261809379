import { Menu, MenuItem } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import gtmIds from 'services/tracking/GTMIds';
import { withGtmInteraction } from 'services/tracking/withGtmInteraction';

export const AddImageToDocumentMenu = (props: {
  anchorEl: HTMLElement | null;
  isOptionsOpen: boolean;
  handleCloseOption: () => void;
  handleNewDocument: () => void;
  handleExistingDocument: () => void;
}) => {
  const { anchorEl, isOptionsOpen, handleCloseOption, handleExistingDocument, handleNewDocument } =
    props;

  const handleAddToExisting = (e: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
    e.stopPropagation();
    handleExistingDocument();
    handleCloseOption();
  };

  const handleAddToNew = (e: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
    e.stopPropagation();
    handleNewDocument();
    handleCloseOption();
  };

  return (
    <Menu
      anchorEl={anchorEl}
      open={isOptionsOpen}
      onClose={handleCloseOption}
      sx={{ zIndex: 10000 }}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
    >
      <MenuItem
        onClick={handleAddToExisting}
        {...withGtmInteraction(gtmIds.aiImages.generatorPage.addToExistingDocumentButton)}
      >
        <FormattedMessage id="ai_images.generator_page.add_to_existing_document" />
      </MenuItem>

      <MenuItem
        onClick={handleAddToNew}
        {...withGtmInteraction(gtmIds.aiImages.generatorPage.addToNewDocumentButton)}
      >
        <FormattedMessage id="ai_images.generator_page.add_to_new_document" />
      </MenuItem>
    </Menu>
  );
};
