import { Button, TextField, Typography } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { isAxiosError } from 'axios';
import FlexContainer from 'components/FlexContainer';
import Toast from 'components/toasts/Toast';
import { TagChipColors } from 'features/aiWriter/AiWriterProjectOverview/tags/AiWriterProjectTag';
import { TagsColorPicker } from 'features/aiWriter/AiWriterProjectOverview/tags/TagsColorPicker';
import { useTagsModalViewStore,ViewOptions } from 'features/aiWriter/AiWriterProjectOverview/tags/useTagsModalViewStore';
import { ChangeEvent, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  httpCreateTag,
  MAX_TAG_NAME_LENGTH
} from 'services/backofficeIntegration/http/endpoints/aiWriter/httpCreateTag';
import { invalidateGetAllTagsQuery } from 'services/backofficeIntegration/http/endpoints/aiWriter/httpGetAllTags';
import { GAEvents } from 'services/tracking/GAEvents';
import styled from 'styled-components';
import { isObject } from 'utils/isObject';

type Props = {
  name?: string;
  color?: string;
  usage?: number;
};

export const CreateNewTagView = ({ name, color, usage }: Props) => {
  const [newName, setNewName] = useState(name ?? '');
  const [newColor, setNewColor] = useState(color ?? 'primary');
  const setView = useTagsModalViewStore(state => state.setView);

  const handleSetName = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setNewName(e.target.value);
  };

  const handleSetColor = (c: string) => {
    setNewColor(c);
  };

  const { mutate: createTag } = useMutation({
    mutationFn: httpCreateTag.callEndpoint,
    onSuccess: () => {
      Toast.success('aiWriter.document.tags.creation.success');
      invalidateGetAllTagsQuery();
      GAEvents.tagCreatedInTagsLibrary();
    },
    onError: (error: unknown) => {
      const statusCode =
        isAxiosError(error) && isObject(error.response?.data) && error.response?.data.statusCode;
      if (statusCode === 422) {
        Toast.error('aiWriter.document.tags.creation.error');
      }
    }
  });

  const handleCreateTag = async () => {
    if (newName.length >= MAX_TAG_NAME_LENGTH) {
      return;
    }
    await createTag({ color: TagChipColors[newColor], name: newName });
    setView(ViewOptions.ALL_TAGS);
  };

  return (
    <Root>
      <FlexContainer>
        <Typography variant="body2">
          <FormattedMessage id="common.name" />
        </Typography>

        <NameField
          variant="outlined"
          value={newName}
          onChange={handleSetName}
          size="small"
          onKeyDown={async e => {
            if (e.key === 'Enter') {
              handleCreateTag();
            }
          }}
        />
        {newName.length > MAX_TAG_NAME_LENGTH && (
          <Typography color="error" variant="caption">
            <FormattedMessage id="aiWriter.document.tags.creation.error.too_long_name" />
          </Typography>
        )}
      </FlexContainer>
      <FlexContainer>
        <Typography variant="body2">
          <FormattedMessage id="tags.modal.color_picker.label" />
        </Typography>
        <TagsColorPicker color={newColor} onClick={handleSetColor} />
      </FlexContainer>
      {usage && (
        <FlexContainer>
          <Typography variant="body2">
            <FormattedMessage id="tags.modal.usage.label" />
          </Typography>
          <Typography>
            <FormattedMessage id="tags.modal.usage.text" values={{ number: usage }} />
          </Typography>
        </FlexContainer>
      )}
      <CreateButton variant="contained" onClick={handleCreateTag}>
        <FormattedMessage id="common.create" />
      </CreateButton>
    </Root>
  );
};

const Root = styled(FlexContainer)`
  gap: ${({ theme }) => theme.spacings.xmedium};
  height: 100%;
  padding-left: ${({ theme }) => theme.spacings.medium};
`;

const NameField = styled(TextField)`
  width: 300px;
`;

const CreateButton = styled(Button)`
  position: absolute;
  right: 48px;
  bottom: 24px;
`;
