import { CloseModal } from 'components/modals/types';
import { getActiveTab } from 'features/explorer/store/selectors';
import { combineLists } from 'features/lists/store/actions';
import { FormattedMessage } from 'react-intl';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { promisifySagaThunk } from 'utils/utils';
import { bool, number, object } from 'yup';

import Alert from '../../../base/Alert';
import Checkbox from '../../../base/Checkbox';
import FormikModal from '../../../modals/FormikModal';
import AvailableListsCombobox from './components/AvailableListsCombobox';

const validationSchema = object().shape({
  listId: number().required().moreThan(0),
  deleteSource: bool()
});

const MergeListsModal = ({ closeModal }: CloseModal) => {
  const dispatch = useAppDispatch();
  const isLoading = useAppSelector(state => state.lists.isLoading);
  const tab = useAppSelector(getActiveTab);

  const sourceListId = tab.listId;
  if (!sourceListId) {
    return null;
  }

  return (
    <FormikModal
      isLoading={isLoading}
      onSubmit={({ listId, deleteSource }) => {
        dispatch(
          promisifySagaThunk(combineLists.request, {
            deleteSource,
            sourceId: sourceListId,
            targetId: listId
          })
        ).then(closeModal);
      }}
      initialValues={{
        listId: 0,
        deleteSource: false
      }}
      validationSchema={validationSchema}
      title="merge_lists.title"
      onCancelClick={closeModal}
    >
      {({ values, setFieldValue }) => (
        <>
          {values.deleteSource && (
            <Alert icon="info" type="info">
              <FormattedMessage id="merge_lists.warning" />
            </Alert>
          )}
          <AvailableListsCombobox name="listId" label="merge_lists.destination_list" />
          <Checkbox
            label="merge_lists.remove_source"
            checked={values.deleteSource}
            onChange={event => setFieldValue('deleteSource', event.target.checked)}
          />
        </>
      )}
    </FormikModal>
  );
};

export default MergeListsModal;
