import { ClickAwayListener } from '@mui/base';
import { PlateElement, PlateElementProps, Value } from '@udecode/plate-common';
import { FlashActionsInput } from 'features/aiWriter/slashCommands/FlashActionsInput';
import { findAndCloseFlashActionInput } from 'features/aiWriter/slashCommands/utils/findAndCloseFlashActionInput';
import { TFlashActionsElement } from 'features/plate/customPlugins/types';
import { ElementRef, forwardRef } from 'react';

type FlashActionsInputElementProps = PlateElementProps<Value, TFlashActionsElement>;

export const FlashActionsEditorElement = forwardRef<
  ElementRef<typeof PlateElement>,
  FlashActionsInputElementProps
>(({ children, ...props }, ref) => {
  const { editor } = props;

  const handleCloseInputElement = findAndCloseFlashActionInput(editor);

  return (
    <ClickAwayListener onClickAway={handleCloseInputElement}>
      <PlateElement ref={ref} asChild {...props}>
        <span contentEditable={false}>
          <FlashActionsInput onClose={handleCloseInputElement} />
          {children}
        </span>
      </PlateElement>
    </ClickAwayListener>
  );
});
