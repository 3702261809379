import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import MailIcon from '@mui/icons-material/Mail';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import PauseCircleIcon from '@mui/icons-material/PauseCircle';
import PersonRemoveIcon from '@mui/icons-material/PersonRemove';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import { CircularProgress, IconButton } from '@mui/material';
import Divider from '@mui/material/Divider';
import Menu, { MenuProps } from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { alpha, styled } from '@mui/material/styles';
import { CustomerRole, CustomerStatus } from 'features/customer/store/types';
import FormattedMessage from 'features/i18n/FormattedMessage';
import { MouseEvent, useState } from 'react';
import { TeamMemberDto } from 'services/backofficeIntegration/http/dtos/TeamMemberDto';
import { CancelInvitationParams } from 'services/backofficeIntegration/http/endpoints/teamMembers/httpCancelInvitation';
import { ChangeUserStatusParams } from 'services/backofficeIntegration/http/endpoints/teamMembers/httpChangeUserStatus';
import { RemoveUserParams } from 'services/backofficeIntegration/http/endpoints/teamMembers/httpRemoveTeamMember';
import { ResendInvitationParams } from 'services/backofficeIntegration/http/endpoints/teamMembers/httpResendInvitation';

const isFeatureHidden = false;

type Props = {
  row: TeamMemberDto;
  userRole: CustomerRole;
  onResendInvitation: ({ teamId, email }: ResendInvitationParams) => void;
  onCancelInvitation: ({ teamId, teamCustomerId }: CancelInvitationParams) => void;
  onStatusChange: ({ teamId, teamCustomerId, is_active }: ChangeUserStatusParams) => void;
  onRemoveUser: ({ teamCustomerId }: RemoveUserParams) => void;
  loading?: boolean;
  disabled?: boolean;
};

export const UsersListMenu = ({
  row,
  userRole,
  onResendInvitation,
  onCancelInvitation,
  onStatusChange,
  onRemoveUser,
  loading,
  disabled
}: Props) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleResendInvitation = () => {
    onResendInvitation({ teamId: row.team_id, email: row.email });
    handleClose();
  };

  const handleCancelInvitation = () => {
    onCancelInvitation({ teamId: row.team_id, teamCustomerId: row.id });
    handleClose();
  };

  const handleStatusChange = () => {
    onStatusChange({ teamId: row.team_id, teamCustomerId: row.id, is_active: !row.is_active });
    handleClose();
  };

  const handleRemoveUser = () => {
    onRemoveUser({ teamCustomerId: row.id });
    handleClose();
  };

  return (
    <div>
      <IconButton
        onClick={handleClick}
        disabled={disabled || loading}
        size="small"
        // Fixes size to prevent jumping when switching between loading and not loading
        sx={{ width: 34, height: 34 }}
      >
        {loading ? <CircularProgress size={23} /> : <MoreVertIcon />}
      </IconButton>
      <StyledMenu id="demo-customized-menu" anchorEl={anchorEl} open={open} onClose={handleClose}>
        {row.status === CustomerStatus.PENDING && (
          <div>
            <MenuItem onClick={handleResendInvitation} disableRipple={true}>
              <MailIcon />
              <FormattedMessage id="profile.users.users_list.user_menu.resend" />
            </MenuItem>
            <MenuItem
              onClick={handleCancelInvitation}
              disableRipple={true}
              sx={{ color: '#D32F2F' }}
            >
              <CloseIcon />
              <FormattedMessage id="profile.users.users_list.user_menu.cancel" />
            </MenuItem>
          </div>
        )}
        {userRole !== CustomerRole.MEMBER && row.status !== CustomerStatus.PENDING && (
          <MenuItem
            onClick={handleStatusChange}
            disabled={row.team_role === CustomerRole.OWNER}
            disableRipple
          >
            {!row.is_active ? (
              <>
                <PlayCircleIcon />
                <FormattedMessage id="profile.users.users_list.user_menu.activate" />
              </>
            ) : (
              <>
                <PauseCircleIcon />
                <FormattedMessage id="profile.users.users_list.user_menu.deactivate" />
              </>
            )}
          </MenuItem>
        )}
        {userRole === CustomerRole.OWNER && row.status !== CustomerStatus.PENDING && (
          <div>
            <Divider sx={{ my: 0.5 }} />
            <MenuItem onClick={handleRemoveUser} disableRipple={true}>
              <PersonRemoveIcon />
              <FormattedMessage id="profile.users.users_list.user_menu.remove" />
            </MenuItem>
            {isFeatureHidden && (
              <MenuItem sx={{ color: '#D32F2F' }} onClick={handleClose} disableRipple={true}>
                <DeleteIcon />
                <FormattedMessage id="profile.users.users_list.user_menu.delete" />
              </MenuItem>
            )}
          </div>
        )}
      </StyledMenu>
    </div>
  );
};

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right'
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right'
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0'
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        marginRight: theme.spacing(1.5)
      },
      '&:active': {
        backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity)
      }
    }
  }
}));
