import { useModal } from 'components/modals';
import { NotificationToastContent } from 'features/userNotifications/NotificationToastContent';
import { useHandleSubscriptionPlanChangedNotification } from 'features/userNotifications/useHandleSubscriptionPlanChangedNotification';
import { useUnreadNotificationsEvent } from 'features/userNotifications/useUnreadNotificationsEvent';
import { useEffect } from 'react';
import { Id as ToastId, toast, ToastItem, ToastOptions } from 'react-toastify';
import { TypeOptions } from 'react-toastify/dist/types';
import {
  NotificationDto,
  NotificationType
} from 'services/backofficeIntegration/http/endpoints/notifications/getUnreadNotifications';
import { markNotificationAsRead } from 'services/backofficeIntegration/http/endpoints/notifications/markNotificationAsRead';

const toastIdPrefix = 'user-notification-toast-';

const makeToastOptions = (notification: NotificationDto): ToastOptions => {
  const { type } = notification.data;
  return {
    // TODO: We filter out special types before, so this is safe here but the typecast shouldn't be needed
    type: type as TypeOptions,
    /**
     * We need a consistent way of generating toastId to make sure we will
     * not show duplicates. `react-toastify` will not display a toast if
     * another toast with same ID is already visible. There is a chance that
     * a same format is used in other  part of application. This could lead to
     * id collisions and unexpected behaviors. I have tried some alternatives
     * to make it more robust but felt like overkill and had other flaws.
     */
    toastId: `${toastIdPrefix}${notification.id}`
  };
};

const extractNotificationId = (toastId: ToastId) => {
  if (typeof toastId === 'string' && toastId.startsWith(toastIdPrefix)) {
    return toastId.slice(toastIdPrefix.length);
  }

  return undefined;
};

const showToast = (notification: NotificationDto) => {
  toast(<NotificationToastContent notification={notification} />, makeToastOptions(notification));
};

const handleToastRemoved = (item: ToastItem) => {
  if (item.status !== 'removed') {
    return;
  }

  const notificationId = extractNotificationId(item.id);
  if (!notificationId) {
    return;
  }

  markNotificationAsRead.callEndpoint({ notificationId }).catch(() => {
    /**
     * For now I see no reasonable way of handling this error. I think
     * that showing another toast about error makes no sense here. We
     * could enforce some kind of retry strategy but this gives no
     * guarantee anyway. If this fails then the notification will
     * appear again to the user next time we fetch them.
     */
  });
};

export function useNotificationsManager() {
  const { showModal } = useModal();

  const handleSubscriptionPlanChangedNotification = useHandleSubscriptionPlanChangedNotification();

  const handleTeamMemberNeedRefreshNotification = (notification: NotificationDto) => {
    showModal('TEAM_MEMBER_NEEDS_REFRESH', { size: 600, uncloseable: true });
    markNotificationAsRead.callEndpoint({ notificationId: notification.id });
  };

  useUnreadNotificationsEvent(notifications => {
    notifications.forEach(notification => {
      if (notification.data.type === NotificationType.teamMemberNeedRefresh) {
        handleTeamMemberNeedRefreshNotification(notification);
        return;
      }

      if (notification.data.type === NotificationType.customerSubscriptionPlanChanged) {
        handleSubscriptionPlanChangedNotification.mutate();

        markNotificationAsRead.callEndpoint({ notificationId: notification.id });
      }

      showToast(notification);
    });
  });

  useEffect(() => {
    // Return the unsubscribe event to cleanup on unmount
    return toast.onChange(handleToastRemoved);
  });
}
