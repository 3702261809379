import { useMutation } from '@tanstack/react-query';
import { invalidateCustomerAllLimitationsQueries } from 'services/backofficeIntegration/http/endpoints/customer/httpGetAllLimitations';
import { httpCreatePlagiarismCheck } from 'services/backofficeIntegration/http/endpoints/plagrism/httpCreatePlagiarismCheck';
import { httpGetPlagiarismCheckLimitations } from 'services/backofficeIntegration/http/endpoints/plagrism/httpGetPlagiarismCheckLimitations';
import { queryClient } from 'utils/ReactQueryClient';

export const usePlagiarismCheck = () => {
  return useMutation({
    mutationFn: (text: string) => httpCreatePlagiarismCheck.callEndpoint({ text }),
    onSuccess: () => {
      invalidatePlagiarismCheckLimitations();
      invalidateCustomerAllLimitationsQueries();
    }
  });
};

function invalidatePlagiarismCheckLimitations() {
  const queryKey = httpGetPlagiarismCheckLimitations.makeQueryKey();

  return queryClient.invalidateQueries(queryKey);
}
