import { WarningHint } from 'components/profile/components/pricing-plan-card/WarningHint';
import FormattedMessage from 'features/i18n/FormattedMessage';
import { useStripePortalUrl } from 'features/stripe-portal/useStripePortalUrl';
import { ReactElement } from 'react';

const SepaPaymentFailedHint = (): ReactElement => {
  const { stripePortalUrl } = useStripePortalUrl();

  return (
    <WarningHint>
      <FormattedMessage
        id="profile.upgrade_plan.sepa_payment_failed"
        values={{
          a: (value: string) => (
            <a href={stripePortalUrl} rel="noreferrer">
              {value}
            </a>
          )
        }}
      />
    </WarningHint>
  );
};
export default SepaPaymentFailedHint;
