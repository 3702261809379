type ScriptLoadedHandler = () => void;

export const addTrackingScriptToToPage = (
  url: string,
  onScriptLoaded: ScriptLoadedHandler | null = null
) => {
  const script = document.createElement('script');
  script.type = 'text/javascript';
  script.onload = onScriptLoaded;
  script.src = url;
  script.defer = true;

  document.getElementsByTagName('body')[0].appendChild(script);
};

export const addTrackingScriptDelayedToToPage = (
  url: string,
  delay: number,
  onScriptLoaded: ScriptLoadedHandler | null = null
) => {
  window.setTimeout(() => {
    addTrackingScriptToToPage(url, onScriptLoaded);
  }, delay);
};
