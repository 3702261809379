import { useModal } from 'components/modals';
import ConfirmModal from 'components/modals/ConfirmModal';
import { CloseModal } from 'components/modals/types';

type CustomProps = {
  onDelete: () => void;
};

type Props = CloseModal & CustomProps;

const ConfirmDeletionModal = ({ closeModal, onDelete }: Props) => {
  const handleDelete = () => {
    onDelete();
    closeModal();
  };

  return (
    <ConfirmModal
      title="aiWriter.project_overview.confirm_deletion.title"
      message="aiWriter.project_overview.confirm_deletion.body"
      onCancel={closeModal}
      cancelText="common.cancel"
      onOK={handleDelete}
      okText="common.delete"
    />
  );
};

export const useConfirmDeletionModal = () => {
  const { showModal } = useModal();

  return (props: CustomProps) => {
    showModal('CONFIRM_DELETION', {
      ...props,
      size: 480
    });
  };
};

export default ConfirmDeletionModal;
