export enum SearchEngines {
  google_com = 'google.com',
  google_ad = 'google.ad',
  google_ae = 'google.ae',
  google_com_af = 'google.com.af',
  google_com_ag = 'google.com.ag',
  google_com_ai = 'google.com.ai ',
  google_al = 'google.al',
  google_am = 'google.am',
  google_co_ao = 'google.co.ao',
  google_com_ar = 'google.com.ar',
  google_as = 'google.as',
  google_at = 'google.at',
  google_com_au = 'google.com.au',
  google_az = 'google.az',
  google_ba = 'google.ba',
  google_com_bd = 'google.com.bd',
  google_be = 'google.be',
  google_bf = 'google.bf',
  google_bg = 'google.bg',
  google_com_bh = 'google.com.bh',
  google_bi = 'google.bi',
  google_bj = 'google.bj',
  google_com_bn = 'google.com.bn',
  google_com_bo = 'google.com.bo',
  google_com_br = 'google.com.br',
  google_bs = 'google.bs',
  google_bt = 'google.bt',
  google_co_bw = 'google.co.bw',
  google_by = 'google.by',
  google_com_bz = 'google.com.bz',
  google_ca = 'google.ca',
  google_cd = 'google.cd',
  google_cf = 'google.cf',
  google_cg = 'google.cg',
  google_ch = 'google.ch',
  google_ci = 'google.ci',
  google_co_ck = 'google.co.ck',
  google_cl = 'google.cl',
  google_cm = 'google.cm',
  google_cn = 'google.cn',
  google_com_co = 'google.com.co',
  google_co_cr = 'google.co.cr',
  google_com_cu = 'google.com.cu',
  google_cv = 'google.cv',
  google_com_cy = 'google.com.cy',
  google_cz = 'google.cz',
  google_de = 'google.de',
  google_dj = 'google.dj',
  google_dk = 'google.dk',
  google_dm = 'google.dm',
  google_com_do = 'google.com.do',
  google_dz = 'google.dz',
  google_com_ec = 'google.com.ec',
  google_ee = 'google.ee',
  google_com_eg = 'google.com.eg',
  google_es = 'google.es',
  google_com_et = 'google.com.et',
  google_fi = 'google.fi',
  google_com_fj = 'google.com.fj',
  google_fm = 'google.fm',
  google_fr = 'google.fr',
  google_ga = 'google.ga',
  google_ge = 'google.ge',
  google_gg = 'google.gg',
  google_com_gh = 'google.com.gh',
  google_com_gi = 'google.com.gi',
  google_gl = 'google.gl',
  google_gm = 'google.gm',
  google_gr = 'google.gr',
  google_com_gt = 'google.com.gt',
  google_gy = 'google.gy',
  google_com_hk = 'google.com.hk',
  google_hn = 'google.hn',
  google_hr = 'google.hr',
  google_ht = 'google.ht',
  google_hu = 'google.hu',
  google_co_id = 'google.co.id',
  google_ie = 'google.ie',
  google_co_il = 'google.co.il',
  google_im = 'google.im',
  google_co_in = 'google.co.in',
  google_iq = 'google.iq',
  google_is = 'google.is',
  google_it = 'google.it',
  google_je = 'google.je',
  google_com_jm = 'google.com.jm',
  google_jo = 'google.jo',
  google_co_jp = 'google.co.jp',
  google_co_ke = 'google.co.ke',
  google_com_kh = 'google.com.kh',
  google_ki = 'google.ki',
  google_kg = 'google.kg',
  google_co_kr = 'google.co.kr',
  google_com_kw = 'google.com.kw',
  google_kz = 'google.kz',
  google_la = 'google.la',
  google_com_lb = 'google.com.lb',
  google_li = 'google.li',
  google_lk = 'google.lk',
  google_co_ls = 'google.co.ls',
  google_lt = 'google.lt',
  google_lu = 'google.lu',
  google_com_ly = 'google.com.ly',
  google_co_ma = 'google.co.ma',
  google_md = 'google.md',
  google_me = 'google.me',
  google_mg = 'google.mg',
  google_mk = 'google.mk',
  google_ml = 'google.ml',
  google_com_mm = 'google.com.mm',
  google_mn = 'google.mn',
  google_ms = 'google.ms',
  google_com_mt = 'google.com.mt',
  google_mu = 'google.mu',
  google_mv = 'google.mv',
  google_mw = 'google.mw',
  google_com_mx = 'google.com.mx',
  google_com_my = 'google.com.my',
  google_co_mz = 'google.co.mz',
  google_com_na = 'google.com.na',
  google_com_ng = 'google.com.ng',
  google_com_ni = 'google.com.ni',
  google_ne = 'google.ne',
  google_nl = 'google.nl',
  google_no = 'google.no',
  google_com_np = 'google.com.np',
  google_nr = 'google.nr',
  google_nu = 'google.nu',
  google_co_nz = 'google.co.nz',
  google_com_om = 'google.com.om',
  google_com_pa = 'google.com.pa',
  google_com_pe = 'google.com.pe',
  google_com_pg = 'google.com.pg',
  google_com_ph = 'google.com.ph',
  google_com_pk = 'google.com.pk',
  google_pl = 'google.pl',
  google_pn = 'google.pn',
  google_com_pr = 'google.com.pr',
  google_ps = 'google.ps',
  google_pt = 'google.pt',
  google_com_py = 'google.com.py',
  google_com_qa = 'google.com.qa',
  google_ro = 'google.ro',
  google_ru = 'google.ru',
  google_rw = 'google.rw',
  google_com_sa = 'google.com.sa',
  google_com_sb = 'google.com.sb',
  google_sc = 'google.sc',
  google_se = 'google.se',
  google_com_sg = 'google.com.sg',
  google_sh = 'google.sh',
  google_si = 'google.si',
  google_sk = 'google.sk',
  google_com_sl = 'google.com.sl',
  google_sn = 'google.sn',
  google_so = 'google.so',
  google_sm = 'google.sm',
  google_sr = 'google.sr',
  google_st = 'google.st',
  google_com_sv = 'google.com.sv',
  google_td = 'google.td',
  google_tg = 'google.tg',
  google_co_th = 'google.co.th',
  google_com_tj = 'google.com.tj',
  google_tl = 'google.tl',
  google_tm = 'google.tm',
  google_tn = 'google.tn',
  google_to = 'google.to',
  google_com_tr = 'google.com.tr',
  google_tt = 'google.tt',
  google_com_tw = 'google.com.tw',
  google_co_tz = 'google.co.tz',
  google_com_ua = 'google.com.ua',
  google_co_ug = 'google.co.ug',
  google_co_uk = 'google.co.uk',
  google_com_uy = 'google.com.uy',
  google_co_uz = 'google.co.uz',
  google_com_vc = 'google.com.vc',
  google_co_ve = 'google.co.ve',
  google_vg = 'google.vg',
  google_co_vi = 'google.co.vi',
  google_com_vn = 'google.com.vn',
  google_vu = 'google.vu',
  google_ws = 'google.ws',
  google_rs = 'google.rs',
  google_co_za = 'google.co.za',
  google_co_zm = 'google.co.zm',
  google_co_zw = 'google.co.zw',
  google_cat = 'google.cat'
}
