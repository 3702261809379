import { safeEnum } from 'utils/safeEnum';

const SystemTonality = safeEnum.make({
  positive: 'positive',
  excited: 'excited',
  powerful: 'powerful',
  witty: 'witty',
  calm: 'calm',
  urgent: 'urgent',
  gentle: 'gentle',
  polite: 'polite',
  encouraging: 'encouraging',
  formal: 'formal',
  casual: 'casual'
} as const);

type SystemTonality = safeEnum.infer<typeof SystemTonality>;

export { SystemTonality };
