import {
  Category,
  Option
} from 'features/aiWriter/slashCommands/hooks/useGetDynamicBaseFlashActionOptions';

export function calculateTotalOptions(optionsMap: Map<Category, Option[]>): number {
  let totalOptions = 0;
  for (const options of optionsMap.values()) {
    totalOptions += options.length;
  }
  return totalOptions;
}
