import { makeCurrentUserQueryKeyPrefix } from 'features/currentUser/currentUserQueries';
import { queryClient } from 'utils/ReactQueryClient';

export function invalidateSeoAnalysisUsageQueries() {
  return queryClient.invalidateQueries(makeSeoAnalysisUsageQueryKeyPrefix());
}

export function makeSeoAnalysisUsageQueryKeyPrefix() {
  return [...makeCurrentUserQueryKeyPrefix(), 'seo-analysis', 'usage'];
}
