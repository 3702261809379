import { useField } from 'formik';
import React from 'react';

import Checkbox from '../base/Checkbox';

type CheckboxFieldProps<T = string> = React.ComponentPropsWithoutRef<typeof Checkbox> & {
  name: string;
  value: T;
};

function CheckboxField<T = string>(props: CheckboxFieldProps<T>) {
  const [field] = useField({ name: props.name, type: 'checkbox', value: props.value });

  return <Checkbox {...field} {...props} />;
}
export default CheckboxField;
