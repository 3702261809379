import { ErrorMessage } from 'formik';

const FieldError = ({ name }: { name: string }) => {
  return (
    <ErrorMessage name={name}>
      {msg => <span className="invalid-feedback">{msg}</span>}
    </ErrorMessage>
  );
};

export default FieldError;
