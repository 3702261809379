import { useMutation } from '@tanstack/react-query';
import {
  DownloadDocParams,
  httpDownloadDocument
} from 'services/backofficeIntegration/http/endpoints/aiWriter/httpDownloadDocument';

export const useDownloadDocument = () => {
  return useMutation({
    mutationFn: (params: DownloadDocParams) => httpDownloadDocument.callEndpoint(params)
  });
};
