import { createSelector } from '@reduxjs/toolkit';
import { List } from 'services/api/list/types';
import { RootState } from 'store/rootReducer';

export const getCurrentList = createSelector(
  (s: RootState) => s.lists,
  ({ currentList, lists }): List =>
    currentList && lists[currentList]
      ? lists[currentList]
      : { id: 0, label: '', words: [], language: '', description: '' }
);
