
import happy from '../../../assets/happy-icon.inline.svg';
import sad from '../../../assets/sad-icon.inline.svg';
import SmallSVGText from '../../../components/ChartElements/SmallSVGText';
import SVGText from '../../../components/ChartElements/SVGText';
import useTr from '../../../utils/hooks/useTr';

const getPos = (trigFunc: 'cos' | 'sin', center: number, radius: number) =>
  center + 0.85 * radius * Math[trigFunc]((3 * Math.PI) / 4);

type Props = {
  graphColoring: string;
  label: string;
  scale?: number;
  circleRadius?: number;
  circleCenter?: number;
  stroke?: string;
};

const MainLegend = ({
  graphColoring,
  label,
  scale = 1.2,
  circleRadius = 30,
  circleCenter = 60,
  stroke = '#425369'
}: Props) => {
  const tr = useTr();
  const cos = getPos('cos', circleCenter, circleRadius);
  const sin = getPos('sin', circleCenter, circleRadius);

  return (
    <g id="graph-legend" transform={`scale(${scale})`}>
      <circle cx={circleCenter} cy={circleCenter} r={circleRadius} fill="#ccc" />
      <line
        x1={cos}
        y1={sin}
        x2={sin}
        y2={cos}
        style={{ stroke, strokeDasharray: '3, 9', strokeWidth: 3 }}
        markerEnd="url(#arrow)"
        markerStart="url(#arrow-start)"
      />
      <SVGText textAnchor="start" dx="120" dy="68">
        {tr('word_param.model_rank_score')}
      </SVGText>
      <SmallSVGText textAnchor="start" dx="50" dy="105">
        {tr('exploring.bubble_chart.legend.size')}
      </SmallSVGText>

      <rect
        x="20"
        y="120"
        width="82"
        height="24"
        fill="url(#gradient)"
        style={{ stroke, strokeWidth: 1 }}
      />
      {graphColoring === 'valence' && (
        <>
          <image width="16" height="16" x="24" y="124" xlinkHref={sad} />
          <image width="16" height="16" x="82" y="124" xlinkHref={happy} />
        </>
      )}
      <SVGText textAnchor="start" dx="120" dy="138">
        {label}
      </SVGText>
      <SmallSVGText textAnchor="start" fontSize="12" dx="45" dy="160">
        {tr('exploring.bubble_chart.legend.color')}
      </SmallSVGText>
    </g>
  );
};

export default MainLegend;
