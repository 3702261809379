import { Add, ArrowDropDown, Close } from '@mui/icons-material';
import { Tooltip, Typography } from '@mui/material';
import FlexContainer from 'components/FlexContainer';
import { IconButton } from 'components/iconButtons/IconButton';
import { MenuDropdown } from 'components/MenuDropdown/MenuDropdown';
import { MenuDropdownItem } from 'components/MenuDropdown/MenuDropdownItem';
import NoTabsPlaceholder from 'components/TabbedCard/NoTabsPlaceholder';
import { FlagIcon } from 'features/aiWriter/AiWriterProjectOverview/ProjectsTable';
import { ErrorBoundary } from 'features/react-error-boundary/ErrorBoundary';
import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { resetButton } from 'styles/styledComponents/resets';
import { TabHeader } from 'types/ReduxStateTypes';
import useDropdown from 'utils/hooks/useDropdown';

const getProjectName = (name?: string) => name || '<Unnamed>';

type Props = {
  activeTabId: string | null;
  className?: string;
  showHeader?: boolean;
  tabsHeaders: TabHeader[];
  noTabsMessage: string;
  customContentContainer?: boolean;
  extra?: React.ReactNode;
  createButtonId?: string;
  children: React.ReactNode;

  onClose: (id: string) => void;
  onCreate?: () => void;
  onSelect: (id: string) => void;
  onLastClose?: () => void;
};

export const TabbedCard = ({
  activeTabId,
  className,
  tabsHeaders,
  noTabsMessage,
  customContentContainer,
  extra,
  createButtonId,
  children,
  showHeader = true,

  onClose,
  onCreate,
  onSelect,
  onLastClose
}: Props) => {
  const { anchorElement, isOpen, close, open } = useDropdown();

  const header = useMemo(() => {
    const currentTab = tabsHeaders.find(t => t.id === activeTabId);
    if (currentTab) {
      return (
        <FlexContainer direction="row" alignItems="center" gap="one">
          {currentTab.modelData && <FlagIcon embeddingModelId={currentTab.modelData.id} />}

          <Name variant="subtitle1">{getProjectName(currentTab.name)}</Name>
        </FlexContainer>
      );
    }

    return null;
  }, [activeTabId, tabsHeaders]);

  const content = useMemo(() => {
    const body = (
      <ErrorBoundary>
        {activeTabId ? children : <NoTabsPlaceholder message={noTabsMessage} />}
      </ErrorBoundary>
    );

    return customContentContainer ? body : <Content>{body}</Content>;
  }, [customContentContainer, activeTabId, noTabsMessage, children]);

  const closeTab = (id: string) => {
    if (tabsHeaders.length === 1) {
      onLastClose?.();
    }
    onClose?.(id);
  };

  return (
    <Root className={className}>
      {showHeader && (
        <Header>
          <HeaderContent gap="xmedium" direction="row">
            <Project gap="small" direction="row">
              <MenuDropdown
                trigger={
                  <Action onClick={open}>
                    {header}

                    <Tooltip title={<FormattedMessage id="common.project_breadcrumbs.dropdown" />}>
                      <ArrowDropDownIcon />
                    </Tooltip>
                  </Action>
                }
                isOpen={isOpen}
                anchorElement={anchorElement}
                close={close}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                transformOrigin={{ vertical: 'top', horizontal: 'center' }}
              >
                {tabsHeaders.map(tab => (
                  <MenuDropdownItem
                    key={tab.id}
                    onClick={() => onSelect(tab.id)}
                    onClose={close}
                    icon={
                      // You're not allowed to close the last open tab
                      tabsHeaders.length > 1 && (
                        <IconButton
                          onClick={() => closeTab(tab.id)}
                          icon={<CloseIcon />}
                          size="large"
                        />
                      )
                    }
                    selected={tab.id === activeTabId}
                  >
                    <FlexContainer direction="row" alignItems="center" gap="one">
                      {tab.modelData && <FlagIcon embeddingModelId={tab.modelData.id} />}

                      {getProjectName(tab.name)}
                    </FlexContainer>
                  </MenuDropdownItem>
                ))}
              </MenuDropdown>
            </Project>

            {onCreate && (
              <IconButton
                id={createButtonId}
                onClick={() => onCreate?.()}
                icon={<CreateIcon />}
                tooltip={<FormattedMessage id="common.new_document" />}
                size="large"
              />
            )}
          </HeaderContent>

          {extra}
        </Header>
      )}

      {content}
    </Root>
  );
};

const Root = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;
  padding-right: ${({ theme }) => theme.spacings.medium};
`;

const HeaderContent = styled(FlexContainer)`
  align-items: center;
  height: 30px;
`;

const Project = styled(FlexContainer)`
  align-items: center;
`;

const Action = styled.button`
  ${resetButton};

  display: flex;
  align-self: center;
`;

const Name = styled(Typography)`
  cursor: pointer;

  && {
    color: ${({ theme }) => theme.colors.componentsIconDefault};
  }
`;

const CreateIcon = styled(Add)`
  color: ${({ theme }) => theme.colors.componentsIconDefault};
`;

const CloseIcon = styled(Close)`
  color: ${({ theme }) => theme.colors.componentsIconDefault};
`;

const ArrowDropDownIcon = styled(ArrowDropDown)`
  color: ${({ theme }) => theme.colors.componentsIconDefault};
`;

const Content = styled.div`
  background: ${({ theme }) => theme.colors.backgroundDefault};
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 0.5rem;
  margin-bottom: 1.5rem;
  flex: 1 1 auto;

  @media (min-width: 576px) {
    padding: 1rem;
  }

  @media (min-width: 992px) {
    padding: 1.5rem;
  }
`;
