import { CircularProgress } from '@mui/material';
import FlexContainer from 'components/FlexContainer';
import DeleteIconButton from 'components/iconButtons/DeleteIconButton';
import BackButton from 'features/aiWriter/AiWriterSidebar/AiWriterSidebarPanel/BackButton';
import Title from 'features/aiWriter/AiWriterSidebar/AiWriterSidebarPanel/Title';
import { setInspirationsCurrentStep } from 'features/aiWriter/store/actions/config/actions';
import { ComponentProps, ReactNode } from 'react';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
import useTr from 'utils/hooks/useTr';

type BaseProps = {
  isLoading?: boolean;
  customButton?: ReactNode;
  infoButton?: ReactNode;
  children: ReactNode;
  onBack?: () => void;
  onClose?: () => void;
};

type PropsWithTitle = BaseProps & {
  title: ComponentProps<typeof Title>['title'];
  renderTitle?: never;
};

type PropsWithRenderTitle = BaseProps & {
  title?: never;
  renderTitle: () => ReactNode;
};

type Props = PropsWithTitle | PropsWithRenderTitle;

export const AiWriterSidebarPanel = ({
  title,
  renderTitle,
  isLoading,
  customButton,
  infoButton,
  children,
  onBack,
  onClose
}: Props) => {
  const dispatch = useAppDispatch();
  const tr = useTr();

  const handleOnClose = () => {
    if (onClose) {
      onClose();
      return;
    }

    dispatch(setInspirationsCurrentStep(undefined));
  };

  return (
    <Root>
      <Header>
        <HeaderFirstRow>
          <FlexContainer direction="row" alignItems="center" gap="small">
            {onBack && <BackButton onClick={onBack} />}
            {renderTitle ? renderTitle() : <Title title={title} />}
          </FlexContainer>

          <Extra>
            {customButton}

            {infoButton}

            <DeleteIconButton
              onClick={handleOnClose}
              tooltip={tr('aiWriter.inspirations.hide_sidebar')}
            />
          </Extra>
        </HeaderFirstRow>
      </Header>

      <Content>
        {isLoading ? (
          <Loader>
            <CircularProgress size={24} />
          </Loader>
        ) : (
          children
        )}
      </Content>
    </Root>
  );
};

const Root = styled.div`
  --inspiration-panel-horizontal-padding: ${({ theme }) => theme.spacings.three};

  width: 100%;
  display: flex;
  flex-direction: column;
  border-left: 0.5px solid ${({ theme }) => theme.colors.aiWriterSidebarBorderColor};
`;

const Header = styled.div`
  flex: 0 0 auto;
  padding: ${({ theme }) => theme.spacings.three} var(--inspiration-panel-horizontal-padding);
`;

const HeaderFirstRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;
  height: 32px;
`;

const Extra = styled.div`
  display: flex;
  align-items: center;
`;

const Content = styled.div`
  flex: 1 1 auto;
  overflow-y: auto;
  padding: 0 var(--inspiration-panel-horizontal-padding);
`;

const Loader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 80%;
`;
