/* eslint-disable @typescript-eslint/no-explicit-any */
import { TestingChartEntry, TestingContent } from 'features/aiTester/store/types';
import { getSelectedWords } from 'features/explorer/store/selectors';
import { ConceptFlashTab } from 'features/explorer/store/types';
import { getTranslation } from 'features/i18n/getTranslation';
import { cloneDeep, get, isNumber } from 'lodash';
import zipcelx, { ZipCelXRow } from 'zipcelx';

export enum CellType {
  String = 'string',
  Number = 'number'
}

const SEOParamsDict = {
  averagecpc: 'average_cpc',
  competition: 'competition',
  searchvolume: 'search_volume'
};

const prepareHeaders = (fields: string[], translationPrefix: string): ZipCelXRow => {
  const getTranslatedHeader = (field: string) => getTranslation(`${translationPrefix}.${field}`);
  return fields.map(field => ({ value: getTranslatedHeader(field), type: CellType.String }));
};

const prepareRow = (
  fields: string[],
  dataObject: Record<string, any>,
  oppositeValueFields?: string[]
): ZipCelXRow => {
  return fields.map(fieldName => {
    const value = get(dataObject, fieldName);
    return {
      value: isNumber(value) && oppositeValueFields?.includes(fieldName) ? -value : value,
      type: isNumber(value) ? CellType.Number : CellType.String
    };
  });
};

const exportDataToFile = (
  data: Array<Record<string, any>>,
  name: string,
  fields: string[],
  exportAll: boolean,
  headers: ZipCelXRow,
  oppositeValueFields?: string[]
) => {
  const dataReadyToExport = [
    headers,
    ...data.map(dataObj => prepareRow(fields, dataObj, oppositeValueFields))
  ];
  const filename = `${name}${exportAll ? '_all' : ''}`;

  return zipcelx({
    filename,
    sheet: { data: dataReadyToExport }
  });
};

export const exportConceptTableToFile = (
  tab: ConceptFlashTab,
  exportAll: boolean,
  filename?: string
) => {
  const tabCopy = cloneDeep(tab);

  let fields = ['word', 'goal_score'].concat(tabCopy.wordAttributes.map(attr => attr.value));
  const oppositeValueFields = tabCopy.wordAttributes
    .filter(attr => attr.option?.oppositeValue)
    .map(attr => attr.value);
  const headerNames = ['word', 'goal_score'].concat(
    tabCopy.wordAttributes.map(attr => (attr.option ? attr.option.value : attr.value))
  );
  let headers = prepareHeaders(headerNames, 'word_param');

  fields = fields.concat(tabCopy.seoParams.map(param => SEOParamsDict[param]));
  headers = headers.concat(prepareHeaders(tabCopy.seoParams, 'seo'));

  tabCopy.dimensions.forEach(item => {
    fields.push(`dimension_scores.${item.id}`);
    headers.push({ type: CellType.String, value: item.label });
  });

  tabCopy.manualDimensions.forEach(item => {
    fields.push(`dimension_scores.${item.id}_manual`);
    headers.push({ type: CellType.String, value: item.label });
  });

  Object.keys(tabCopy.i16s).forEach(word => {
    tabCopy.data[tabCopy.lookupTable[word]].i16s = tabCopy.i16s[word];
    headers.push({ type: CellType.String, value: word });
    fields.push(`i16s.${word}`);
  });

  return exportDataToFile(
    exportAll ? tabCopy.data : getSelectedWords.resultFunc(tabCopy),
    filename || tabCopy.name,
    fields,
    exportAll,
    headers,
    oppositeValueFields
  );
};

export const exportRawContentFlashDataToFile = ({
  chartData,
  texts
}: {
  chartData: TestingChartEntry[];
  texts: TestingContent[];
}) => {
  const dataWithTexts = chartData.map(data => ({
    ...data,
    text: (texts.find(text => text.id === data.id) || {}).text
  }));
  const fieldsStatic = ['label', 'text'];
  const headerKeys = ['label', 'text'];

  const fields = fieldsStatic.concat(chartData[0].details.map((_, i) => `details.${i}.value`));
  const headers = headerKeys
    .map(name => getTranslation(`goal_score.export.${name}`))
    .concat(
      chartData[0].details.map(
        detail => detail.label || getTranslation(`goal_score.export.${detail.field}`)
      )
    )
    .map(value => ({ value, type: CellType.String }));

  return exportDataToFile(dataWithTexts, 'content_flash_data', fields, true, headers);
};
