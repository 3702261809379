import { makeCurrentUserQueryKeyPrefix } from 'features/currentUser/currentUserQueries';
import { BaseSuccessDto } from 'services/backofficeIntegration/http/BaseSuccessDto';
import { backofficeClient } from 'services/backofficeIntegration/http/client/createBackofficeClient';
import { queryEndpoint } from 'services/backofficeIntegration/http/endpointDefinition';

export type ChecklistDto = {
  task_lists_count: number;
  finished_lists_count: number;
  task_lists: Task[];
  is_viewable: boolean;
};

type Task = {
  id: number;
  alias: TaskType;
  is_checked: boolean;
};

export const TASK_TYPE = {
  USE_TEXT_TYPE: 'use_text_type',
  CLICK_CONTINUE_WRITING: 'click_continue_writing',
  CREATE_PERSONALITY: 'create_personality',
  COMPLETE_SOCIAL_MEDIA_WORKFLOW: 'complete_social_media_workflow',
  CREATE_IMAGE: 'create_image'
} as const;

export type TaskType = typeof TASK_TYPE[keyof typeof TASK_TYPE];

type SuccessDto = BaseSuccessDto<ChecklistDto>;

type Checklist = 'onboarding';

export const httpGetChecklist = queryEndpoint({
  makeQueryKey: (checklist: Checklist) => {
    return [...makeCurrentUserQueryKeyPrefix(), 'checklist', checklist] as const;
  },
  callEndpoint: (checklist: Checklist) => {
    return backofficeClient
      .get<SuccessDto>('/customer/task-lists', { params: { task: checklist } })
      .then(response => {
        return response.data;
      });
  }
});
