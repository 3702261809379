import { useState } from 'react';
import { ColumnInstance } from 'react-table';

import ColumnSortArrows from './ColumnSortArrows';
import {
  FilterIcon,
  HeaderActions,
  HeaderCellContainer,
  HeaderFilter,
  HeaderMain,
  HeaderText
} from './TableComponents';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const TableHeaderCell = ({ column }: { column: ColumnInstance<any> }) => {
  const [filterVisible, setFilterVisible] = useState(!!column.filterValue);
  const toggleProps = column.getSortByToggleProps();

  return (
    <HeaderCellContainer {...column.getHeaderProps({ style: column.headerStyles })} key={column.id}>
      <HeaderMain>
        <HeaderText noEllipsis={column.noHeaderEllipsis} {...toggleProps}>
          {column.render('Header')}
        </HeaderText>
        <HeaderActions>
          {column.canFilter && (
            <FilterIcon
              active={filterVisible}
              onClick={() => {
                if (filterVisible && column.filterValue) {
                  column.setFilter(column.filterValue);
                }
                setFilterVisible(curr => !curr);
              }}
            />
          )}
          {column.canSort && (
            <ColumnSortArrows
              {...toggleProps}
              isSorted={column.isSorted}
              isSortedDesc={!!column.isSortedDesc}
            />
          )}
        </HeaderActions>
      </HeaderMain>
      {filterVisible && (
        <HeaderFilter>{column.canFilter ? column.render('Filter') : null}</HeaderFilter>
      )}
    </HeaderCellContainer>
  );
};

export default TableHeaderCell;
