import refreshIcon from 'assets/icons/icon-refresh-magic-feather-gray.svg';
import refreshDarkIcon from 'assets/icons/icon-refresh-magic-feather-white.svg';
import { IconButton, IconButtonSize } from 'components/iconButtons/IconButton';
import React from 'react';
import { useIsDarkMode } from 'styles/new-theme/useAppThemeStore';

type Props = {
  id?: string;
  isLoading?: boolean;
  tooltip?: React.ReactNode;
  size?: IconButtonSize;

  onClick: () => void;
};

export const RefreshIconButton = ({ id, tooltip, isLoading, size = 'large', onClick }: Props) => {
  const isDarkMode = useIsDarkMode();

  return (
    <IconButton
      id={id}
      onClick={onClick}
      disabled={isLoading}
      isLoading={isLoading}
      tooltip={tooltip}
      icon={<img src={isDarkMode ? refreshDarkIcon : refreshIcon} alt="refresh" />}
      size={size}
    />
  );
};
