import InsertLinkIcon from '@mui/icons-material/InsertLink';
import NotesIcon from '@mui/icons-material/Notes';
import UploadIcon from '@mui/icons-material/Upload';
import { Chip } from '@mui/material';
import { ColoredTypography } from 'components/ColoredTypography';
import FlexContainer from 'components/FlexContainer';
import { CloseModal } from 'components/modals/types';
import FormattedMessage from 'features/i18n/FormattedMessage';
import { informationModalHeight } from 'features/information/InformationModal';
import { TermOfServiceModalFooter } from 'features/information/TermOfServiceModalFooter';
import {
  ModalBodyWithColumnDirectionAndSpaceBetween,
  ModalWithDividedHeaderLayout
} from 'features/theme-2024/ModalWithDividedHeaderLayout';
import styled from 'styled-components';
import { actionableCard, card } from 'styles/styledComponents/mixins';
import useTr from 'utils/hooks/useTr';

type Props = {
  onCopyPasteClick: () => void;
  onUploadClick: () => void;
  onShareUrlClick: () => void;
} & CloseModal;

export function ChoiceView(props: Props) {
  const tr = useTr();

  return (
    <ModalWithDividedHeaderLayout
      title={<FormattedMessage id="information.modal.title" />}
      helpLink="aiWriter.inspirations.aiwriter.main.information.help_link"
      closeModal={props.closeModal}
      height={informationModalHeight}
      slots={{
        bodyContainer: ModalBodyWithColumnDirectionAndSpaceBetween
      }}
    >
      <Content>
        <TilesBox>
          <Tile $clickable={true} onClick={props.onCopyPasteClick}>
            <PopularBadge label={tr('common.popular')} color="secondary" />

            <FlexContainer direction="column" alignItems="center" gap="two">
              <ColoredTypography variant="h6">
                <FormattedMessage id="information.modal.choice.copy_paste.title" />
              </ColoredTypography>
              <NotesIcon color="primary" />
            </FlexContainer>
            <ColoredTypography variant="body2" color="blackMediumEmphasis">
              <FormattedMessage id="information.modal.choice.copy_paste.body" />
            </ColoredTypography>
          </Tile>

          <Tile $clickable={true} onClick={props.onUploadClick}>
            <FlexContainer direction="column" alignItems="center" gap="two">
              <ColoredTypography variant="h6">
                <FormattedMessage id="information.modal.choice.upload.title" />
              </ColoredTypography>
              <UploadIcon color="primary" />
            </FlexContainer>

            <ColoredTypography variant="body2" color="blackMediumEmphasis">
              <FormattedMessage id="information.modal.choice.upload.body" />
            </ColoredTypography>
          </Tile>

          <Tile $clickable={true} onClick={props.onShareUrlClick}>
            <FlexContainer direction="column" alignItems="center" gap="two">
              <ColoredTypography variant="h6">
                <FormattedMessage id="information.modal.choice.share_url.title" />
              </ColoredTypography>
              <InsertLinkIcon color="primary" />
            </FlexContainer>

            <ColoredTypography variant="body2" color="blackMediumEmphasis">
              <FormattedMessage id="information.modal.choice.share_url.body" />
            </ColoredTypography>
          </Tile>
        </TilesBox>
      </Content>

      <TermOfServiceModalFooter />
    </ModalWithDividedHeaderLayout>
  );
}

const Content = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
`;

const TilesBox = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: ${({ theme }) => theme.spacings.four};
`;

const Tile = styled.div<{ $clickable: boolean }>`
  ${({ $clickable }) => ($clickable ? actionableCard : card)};

  position: relative;
  cursor: ${({ $clickable }) => ($clickable ? 'pointer' : 'default')};

  padding: ${({ theme }) => theme.spacings.four};

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${({ theme }) => theme.spacings.two};

  text-align: center;
`;

const PopularBadge = styled(Chip)`
  position: absolute;
  top: -15px;
`;
