import { Typography } from '@mui/material';
import { UsageIndicatorType } from 'features/usageIndicator/UsageIndicatorType';
import { UsageResult } from 'features/usageIndicator/useGetCurrentUsageByType';
import WordsIndicatorLabel from 'features/usageIndicator/WordsIndicatorLabel';
import { ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { resetButton } from 'styles/styledComponents/resets';
import theme from 'styles/styledComponents/theme';

export const barColorError = theme.colors.danger;
export const barColorLoading = theme.colors.blackInactive;
export const barColorNoAccess = 'rgba(30, 30, 30, 0.12)';

type IndicatorColorOptions = {
  available: number;
  isUnlimited: boolean;
};

// #color-tech-debt
export const getIndicatorColor = ({ available, isUnlimited }: IndicatorColorOptions) => {
  const isExhausted = available <= 0;

  if (isUnlimited) {
    return 'linear-gradient(270deg, #0EAEC1 -0.19%, #8A93D5 34.97%, #E4B7E4 98.85%)';
  }

  if (isExhausted) {
    return '#DA2223';
  }

  return '#0EAEC1';
};

type ReturnType = {
  label: ReactElement;
  barColor: string;
};

export const getIndicatorConfiguration = (
  usageResult: UsageResult,
  type: UsageIndicatorType,
  hasAccess = true
): ReturnType => {
  if (!hasAccess) {
    return {
      label: (
        <BlackLabel variant="caption">
          <FormattedMessage id="common.no_access" />
        </BlackLabel>
      ),
      barColor: barColorNoAccess
    };
  }

  if (usageResult.isError) {
    return {
      label: (
        <ErrorButton
          onClick={event => {
            event.preventDefault();
            event.stopPropagation();

            usageResult.refetch();
          }}
        >
          <FormattedMessage id="common.try_again" />
        </ErrorButton>
      ),
      barColor: barColorError
    };
  }

  if (usageResult.isSuccess && usageResult.data) {
    return {
      label: <WordsIndicatorLabel usage={usageResult.data} type={type} />,
      barColor: getIndicatorColor({
        available: usageResult.data.available,
        isUnlimited: usageResult.data.isUnlimited
      })
    };
  }

  return {
    label: (
      <BlackLabel variant="caption">
        <FormattedMessage id="common.loading" />
      </BlackLabel>
    ),
    barColor: barColorLoading
  };
};

type ProgressPercentageOptions = {
  hasAccess: boolean;
  available: number;
  used: number;
  limit: number;
  isUnlimited: boolean;
};

export const getAvailablePercentage = ({
  hasAccess,
  isUnlimited,
  available,
  limit
}: ProgressPercentageOptions) => {
  if (isUnlimited || !hasAccess) {
    return 100;
  }

  if (available <= 0) {
    return 100;
  }

  return (available / limit) * 100;
};

const ErrorButton = styled.button`
  ${resetButton};

  color: ${({ theme }) => theme.colors.danger};
`;

const BlackLabel = styled(Typography)`
  && {
    color: ${({ theme }) => theme.colors.blackInactive};
  }
`;
