import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Button } from '@mui/material';
import FlexContainer from 'components/FlexContainer';
import { CloseModal } from 'components/modals/types';
import { SearchControlled } from 'components/SearchControlled';
import { getRoutePath } from 'config/routes';
import FormattedMessage from 'features/i18n/FormattedMessage';
import { CategoryFilter } from 'features/information/apply-information/CategoryFilter';
import { InformationSelection } from 'features/information/apply-information/InformationSelection';
import {
  ContentToAnalyze,
  personalityCreationModalHeight,
  ViewMode
} from 'features/personality/creation/PersonalityCreationModal';
import {
  ModalBodyWithColumnDirectionAndSpaceBetween,
  ModalWithDividedHeaderLayout
} from 'features/theme-2024/ModalWithDividedHeaderLayout';
import { ChangeEvent, useState } from 'react';
import { Link } from 'react-router-dom';
import { InformationCategoryDto } from 'services/backofficeIntegration/http/endpoints/infomration/httpGetInformationCategories';
import { InformationDto } from 'services/backofficeIntegration/http/endpoints/infomration/httpGetInformationList';
import styled from 'styled-components';
import useDebounce from 'use-debounce/lib/useDebounce';
import { assertNonNullable } from 'utils/typescript/nonNullable';

type Props = {
  setView: (value: ViewMode) => void;
  contentToAnalyze: ContentToAnalyze;
  setShouldAnalyze: (value: boolean) => void;
  setContentToAnalyze: (value: ContentToAnalyze) => void;
  onBackClick: () => void;
} & CloseModal;

export function CreateFromInformationView(props: Props) {
  const [information, setInformation] = useState<InformationDto[]>([]);

  const [searchQuery, setSearchQuery] = useState<string>('');
  const [debouncedSearchQuery] = useDebounce(searchQuery, 300);

  const [filteredTag, setFilteredTag] = useState<InformationCategoryDto | undefined>();

  const handleSearchChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setSearchQuery(e.target.value);
  };

  const handleAnalyzeClick = () => {
    const informationId = information[0]?.id;
    assertNonNullable(informationId, 'Information ID is not defined');
    props.setContentToAnalyze(informationId);
    props.setShouldAnalyze(true);
    props.setView(ViewMode.analysis_completed);
  };

  const handleBackClick = () => {
    props.onBackClick();
    props.setView(ViewMode.choice);
  };

  return (
    <ModalWithDividedHeaderLayout
      title={
        <FormattedMessage id="aiWriter.inspirations.chat.personalities.library_modal.create_personality" />
      }
      //subtitle="personality.creation_modal.createFromInformationView.subtitle"
      helpLink="aiWriter.inspirations.aiwriter.main.personality.help_link"
      closeModal={props.closeModal}
      height={personalityCreationModalHeight}
      slots={{
        bodyContainer: ModalBodyWithColumnDirectionAndSpaceBetween
      }}
      footer={
        <>
          <Button onClick={handleBackClick} variant="text">
            <FormattedMessage id="common.cancel" />
          </Button>
          <RightButtonsBox>
            <Button
              component={Link}
              target="_blank"
              to={getRoutePath('brandHubOverviewInformation')}
              startIcon={<OpenInNewIcon />}
              variant="text"
            >
              <FormattedMessage id="personality.creation_modal.createFromInformationView.new" />
            </Button>
            <Button
              onClick={handleAnalyzeClick}
              variant="contained"
              color="primary"
              disabled={information.length === 0}
            >
              <FormattedMessage id="personality.creation_modal.createFromInformationView.analyze" />
            </Button>
          </RightButtonsBox>
        </>
      }
    >
      <FlexContainer gap="four">
        <FlexContainer gap="two">
          <SearchControlled value={searchQuery} onChange={handleSearchChange} />
          <CategoryFilter
            value={filteredTag}
            setValue={setFilteredTag}
            selectedInformationList={information}
          />
        </FlexContainer>

        <InformationSelection
          value={information}
          onChange={setInformation}
          searchQuery={debouncedSearchQuery}
          filteredTag={filteredTag}
          selectionLimit={1}
        />
      </FlexContainer>
    </ModalWithDividedHeaderLayout>
  );
}

const RightButtonsBox = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacings.three};
`;
