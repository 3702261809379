import { Slot } from '@radix-ui/react-slot';
import { cva, VariantProps } from 'class-variance-authority';
import { cn } from 'features/plate/lib/utils';
import { ButtonHTMLAttributes, forwardRef } from 'react';
import styled from 'styled-components';

const buttonVariants = cva(
  'inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50',
  {
    variants: {
      variant: {
        default: 'bg-primary text-primary-foreground hover:bg-primary/90',
        destructive: 'bg-destructive text-destructive-foreground hover:bg-destructive/90',
        outline: 'border border-input bg-background hover:bg-accent hover:text-accent-foreground',
        secondary: 'bg-secondary text-secondary-foreground hover:bg-secondary/80',
        ghost: 'hover:bg-accent hover:text-accent-foreground',
        link: 'text-primary underline-offset-4 hover:underline',
        inlineLink: 'text-base text-primary underline underline-offset-4'
      },
      size: {
        default: 'px-4 py-2',
        xs: 'rounded-md px-3',
        sm: 'rounded-md px-3',
        sms: 'w-9 rounded-md px-0',
        lg: 'rounded-md px-8',
        icon: 'w-10',
        none: ''
      },
      isMenu: {
        true: 'h-auto w-full cursor-pointer justify-start'
      }
    },
    defaultVariants: {
      variant: 'default',
      size: 'default'
    }
  }
);

export interface ButtonProps
  extends ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
}

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, isMenu, variant, size, asChild = false, ...props }, ref) => {
    const Comp = asChild ? Slot : StyledBaseButton;
    return (
      <Comp
        className={cn(buttonVariants({ isMenu, variant, size, className }))}
        ref={ref}
        {...props}
      />
    );
  }
);
Button.displayName = 'Button';

const StyledBaseButton = styled.button`
  background-color: transparent;
  border: 0 none;
`;

export { Button, buttonVariants };
