import RefreshIcon from '@mui/icons-material/Refresh';
import { Button, ButtonTypeMap } from '@mui/material';
import { FormattedMessage } from 'react-intl';

type Props = {
  id?: string;
  isLoading?: boolean;
  className?: string;
  type?: 'button' | 'submit' | 'reset';

  onClick?: () => void;
} & ButtonTypeMap<Record<string, unknown>, 'button'>['props'];

const RefreshButton = ({ isLoading, ...rest }: Props) => {
  return (
    <Button color="primary" startIcon={<RefreshIcon />} disabled={isLoading} {...rest}>
      <FormattedMessage id="common.refresh" />
    </Button>
  );
};

export default RefreshButton;
