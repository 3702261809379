import makeStyles from '@mui/styles/makeStyles';
import { PlateElement, PlateElementProps, Value } from '@udecode/plate-common';
import {
  TTableCellElement,
  useTableCellElement,
  useTableCellElementResizable,
  useTableCellElementResizableState,
  useTableCellElementState
} from '@udecode/plate-table';
import { clsx } from 'clsx';
import { cn } from 'features/plate/lib/utils';
import { CSSProperties, ElementRef, forwardRef } from 'react';

import { ResizeHandle } from './resizable';

const useStyles = makeStyles({
  cellBorder: {
    '&:before': {
      borderColor: '#e5e7eb'
    }
  },
  cellBorderRight: {
    '&:before': {
      borderRightStyle: 'solid'
    }
  },
  cellBorderLeft: {
    '&:before': {
      borderLeftStyle: 'solid'
    }
  },
  cellBorderTop: {
    '&:before': {
      borderTopStyle: 'solid'
    }
  },
  cellBorderBottom: {
    '&:before': {
      borderBottomStyle: 'solid'
    }
  }
});

export interface TableCellElementProps extends PlateElementProps<Value, TTableCellElement> {
  hideBorder?: boolean;
  isHeader?: boolean;
}

const TableCellElement = forwardRef<ElementRef<typeof PlateElement>, TableCellElementProps>(
  ({ children, className, style, hideBorder, isHeader, ...props }, ref) => {
    const { element } = props;

    const classes = useStyles();

    const {
      colIndex,
      rowIndex,
      readOnly,
      selected,
      hovered,
      hoveredLeft,
      rowSize,
      borders,
      isSelectingCell
    } = useTableCellElementState();
    const { props: cellProps } = useTableCellElement({ element: props.element });
    const resizableState = useTableCellElementResizableState({
      colIndex,
      rowIndex
    });
    const { rightProps, bottomProps, leftProps, hiddenLeft } =
      useTableCellElementResizable(resizableState);

    const Cell = isHeader ? 'th' : 'td';

    return (
      <PlateElement
        asChild
        ref={ref}
        className={cn(
          'relative overflow-visible border-none bg-background p-0',
          hideBorder && 'before:border-none',
          element.background ? 'bg-[--cellBackground]' : 'bg-background',
          !hideBorder &&
            cn(
              isHeader && 'text-left [&_>_*]:m-0',
              'before:h-full before:w-full',
              selected && 'before:z-10 before:bg-muted',
              "before:absolute before:box-border before:select-none before:content-['']",
              borders &&
                cn(
                  borders.bottom?.size && 'before:border-b before:border-b-border',
                  borders.right?.size && 'before:border-r before:border-r-border',
                  borders.left?.size && 'before:border-l before:border-l-border',
                  borders.top?.size && 'before:border-t before:border-t-border'
                )
            ),
          // HACK: Custom added to bring back borders
          !hideBorder &&
            borders &&
            clsx(classes.cellBorder, {
              [classes.cellBorderRight]: !!borders.right?.size,
              [classes.cellBorderLeft]: !!borders.left?.size,
              [classes.cellBorderTop]: !!borders.top?.size,
              [classes.cellBorderBottom]: !!borders.bottom?.size
            }),
          className
        )}
        {...cellProps}
        {...props}
        style={
          {
            '--cellBackground': element.background,
            ...style
          } as CSSProperties
        }
      >
        <Cell>
          <div
            className="relative z-20 box-border h-full px-3 py-2"
            style={{
              minHeight: rowSize
            }}
          >
            {children}
          </div>

          {!isSelectingCell && (
            <div
              className="group absolute top-0 h-full w-full select-none"
              contentEditable={false}
              suppressContentEditableWarning={true}
            >
              {!readOnly && (
                <>
                  <ResizeHandle {...rightProps} className="-top-3 right-[-5px] w-[10px]" />
                  <ResizeHandle {...bottomProps} className="bottom-[-5px] h-[10px]" />
                  {!hiddenLeft && (
                    <ResizeHandle {...leftProps} className="-top-3 left-[-5px] w-[10px]" />
                  )}

                  {hovered && (
                    <div
                      className={cn(
                        'absolute -top-3 z-30 h-[calc(100%_+_12px)] w-1 bg-ring',
                        'right-[-1.5px]'
                      )}
                    />
                  )}
                  {hoveredLeft && (
                    <div
                      className={cn(
                        'absolute -top-3 z-30 h-[calc(100%_+_12px)] w-1 bg-ring',
                        'left-[-1.5px]'
                      )}
                    />
                  )}
                </>
              )}
            </div>
          )}
        </Cell>
      </PlateElement>
    );
  }
);
TableCellElement.displayName = 'TableCellElement';

const TableCellHeaderElement = forwardRef<
  ElementRef<typeof TableCellElement>,
  TableCellElementProps
>((props, ref) => {
  return <TableCellElement ref={ref} {...props} isHeader />;
});
TableCellHeaderElement.displayName = 'TableCellHeaderElement';

export { TableCellElement, TableCellHeaderElement };
