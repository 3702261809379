import { scaleLinear } from 'd3-scale';
import { newThemeConfig } from 'styles/new-theme/newThemeConfig';

export interface RGBColor {
  r: number;
  g: number;
  b: number;
}

export const positiveScale = (extreme = 1) =>
  scaleLinear<string, string>().domain([0, extreme]).range(['#c9f2d3', '#1fc747']);

export const negativeScale = (extreme = -1) =>
  scaleLinear<string, string>().domain([extreme, 0]).range(['#d40000', '#f5c4c4']);

export const brandsScale = scaleLinear<string, string>()
  .domain([30, 100, 170])
  .range(['#ca4b4c', '#ffffff', '#0066c0']);

export const hexToRgb = (hex: string): RGBColor | null => {
  const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  hex = hex.replace(shorthandRegex, (m, r, g, b) => r + r + g + g + b + b);

  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
      }
    : null;
};

export const decColorToHex = (color: string | number) => {
  let hex = Number(color).toString(16);
  if (hex.length < 2) {
    hex = `0${hex}`;
  }
  return hex;
};

export const rgbToHex = ({ r, g, b }: RGBColor): string =>
  `#${[r, g, b].map(decColorToHex).join('')}`;

export const randomColor = (): string =>
  rgbToHex({
    r: Math.floor(Math.random() * 256),
    g: Math.floor(Math.random() * 256),
    b: Math.floor(Math.random() * 256)
  });

export const colorsList = [
  newThemeConfig.colors.primaryColorMain,
  newThemeConfig.colors.secondaryColorMain,
  newThemeConfig.colors.accentOrangeMain,
  newThemeConfig.colors.accentRedMain,
  newThemeConfig.colors.accentRoseMain,
  newThemeConfig.colors.accentYellowMain,
  '#911eb4',
  '#bcf60c',
  '#f032e6',
  '#fabebe',
  '#008080',
  '#e6beff',
  '#9a6324',
  '#fffac8',
  '#800000',
  '#aaffc3',
  '#808000',
  '#ffd8b1',
  '#000075',
  '#808080',
  '#000000'
];

export const lineChartColors = [
  '#007dc1',
  '#b7225c',
  '#e05b05',
  '#3cb44b',
  '#3a506c',
  '#eea528',
  '#3e2723',
  '#bcf60c',
  '#9C27B0',
  '#F44336',
  '#212121'
];
