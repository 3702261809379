import LocalStorageKey from 'config/localStorageKey';

export const getStoredUserTonality = (): string[] => {
  const userTonalities = localStorage.getItem(LocalStorageKey.UserTonalities);
  if (userTonalities) {
    try {
      const parsedUserTonalities = JSON.parse(userTonalities);
      if (
        Array.isArray(parsedUserTonalities) &&
        !parsedUserTonalities.some(tonality => typeof tonality !== 'string')
      ) {
        return parsedUserTonalities;
      }
    } catch {
      return [];
    }
  }

  return [];
};
