import { AppLanguage } from 'features/language/store/types';

export type IsoLanguageCode = `${IsoLanguages}`;

/**
 * Based on the values below
 */
export const enum IsoLanguages {
  Afar = 'aa',
  Abkhazian = 'ab',
  Avestan = 'ae',
  Afrikaans = 'af',
  Akan = 'ak',
  Amharic = 'am',
  Aragonese = 'an',
  Arabic = 'ar',
  Assamese = 'as',
  Avaric = 'av',
  Aymara = 'ay',
  Azerbaijani = 'az',
  Bashkir = 'ba',
  Belarusian = 'be',
  Bulgarian = 'bg',
  BihariLanguages = 'bh',
  Bislama = 'bi',
  Bambara = 'bm',
  Bengali = 'bn',
  Tibetan = 'bo',
  Breton = 'br',
  Bosnian = 'bs',
  Catalan = 'ca',
  Chechen = 'ce',
  Chamorro = 'ch',
  Corsican = 'co',
  Cree = 'cr',
  Czech = 'cs',
  ChurchSlavic = 'cu',
  Chuvash = 'cv',
  Welsh = 'cy',
  Danish = 'da',
  German = 'de',
  DivehiMaldivian = 'dv',
  Dzongkha = 'dz',
  Ewe = 'ee',
  GreekModern = 'el',
  English = 'en',
  Esperanto = 'eo',
  Spanish = 'es',
  Estonian = 'et',
  Basque = 'eu',
  Persian = 'fa',
  Fulah = 'ff',
  Finnish = 'fi',
  Fijian = 'fj',
  Faroese = 'fo',
  French = 'fr',
  WesternFrisian = 'fy',
  Irish = 'ga',
  GaelicScomttish = 'gd',
  Galician = 'gl',
  Guarani = 'gn',
  Gujarati = 'gu',
  Manx = 'gv',
  Hausa = 'ha',
  Hebrew = 'he',
  Hindi = 'hi',
  HiriMotu = 'ho',
  Croatian = 'hr',
  HaitianCreole = 'ht',
  Hungarian = 'hu',
  Armenian = 'hy',
  Herero = 'hz',
  Interlingua = 'ia',
  Indonesian = 'id',
  InterlingueOccidental = 'ie',
  Igbo = 'ig',
  SichuanYiNuosu = 'ii',
  Inupiaq = 'ik',
  Ido = 'io',
  Icelandic = 'is',
  Italian = 'it',
  Inuktitut = 'iu',
  Japanese = 'ja',
  Javanese = 'jv',
  Georgian = 'ka',
  Kongo = 'kg',
  KikuyuGikuyu = 'ki',
  Kuanyama = 'kj',
  Kazakh = 'kk',
  KalaallisutGreenlandic = 'kl',
  CentralKhmer = 'km',
  Kannada = 'kn',
  Korean = 'ko',
  Kanuri = 'kr',
  Kashmiri = 'ks',
  Kurdish = 'ku',
  Komi = 'kv',
  Cornish = 'kw',
  KirghizKyrgyz = 'ky',
  Latin = 'la',
  LuxembourgishLetzeburgesch = 'lb',
  Ganda = 'lg',
  LimburganLimburgish = 'li',
  Lingala = 'ln',
  Lao = 'lo',
  Lithuanian = 'lt',
  LubaKatanga = 'lu',
  Latvian = 'lv',
  Malagasy = 'mg',
  Marshallese = 'mh',
  Maori = 'mi',
  Macedonian = 'mk',
  Malayalam = 'ml',
  Mongolian = 'mn',
  Marathi = 'mr',
  Malay = 'ms',
  Maltese = 'mt',
  Burmese = 'my',
  Nauru = 'na',
  BokmalNorwegian = 'nb',
  NdebeleNorth = 'nd',
  Nepali = 'ne',
  Ndonga = 'ng',
  DutchFlemish = 'nl',
  NorwegianNynorsk = 'nn',
  Norwegian = 'no',
  NdebeleSouth = 'nr',
  NavajoNavaho = 'nv',
  ChichewaChewaNyanja = 'ny',
  Occitan = 'oc',
  Ojibwa = 'oj',
  Oromo = 'om',
  Oriya = 'or',
  OssetianOssetic = 'os',
  Panjabi = 'pa',
  Pali = 'pi',
  Polish = 'pl',
  Pushto = 'ps',
  Portuguese = 'pt',
  Quechua = 'qu',
  Romansh = 'rm',
  Rundi = 'rn',
  RomanianMoldavianMoldovan = 'ro',
  Russian = 'ru',
  Kinyarwanda = 'rw',
  Sanskrit = 'sa',
  Sardinian = 'sc',
  Sindhi = 'sd',
  NorthernSami = 'se',
  Sango = 'sg',
  SinhalaSinhalese = 'si',
  Slovak = 'sk',
  Slovenian = 'sl',
  Samoan = 'sm',
  Shona = 'sn',
  Somali = 'so',
  Albanian = 'sq',
  Serbian = 'sr',
  Swati = 'ss',
  SothoSouthern = 'st',
  Sundanese = 'su',
  Swedish = 'sv',
  Swahili = 'sw',
  Tamil = 'ta',
  Telugu = 'te',
  Tajik = 'tg',
  Thai = 'th',
  Tigrinya = 'ti',
  Turkmen = 'tk',
  Tagalog = 'tl',
  Tswana = 'tn',
  Tonga = 'to',
  Turkish = 'tr',
  Tsonga = 'ts',
  Tatar = 'tt',
  Twi = 'tw',
  Tahitian = 'ty',
  UighurUyghur = 'ug',
  Ukrainian = 'uk',
  Urdu = 'ur',
  Uzbek = 'uz',
  Venda = 've',
  Vietnamese = 'vi',
  Volapük = 'vo',
  Walloon = 'wa',
  Wolof = 'wo',
  Xhosa = 'xh',
  Yiddish = 'yi',
  Yoruba = 'yo',
  ZhuangChuang = 'za',
  Chinese = 'zh',
  Zulu = 'zu'
}

type LanguageConfig = {
  code: string;
  labels: Record<AppLanguage, string>;
};

/**
 * @deprecated Use useTr() hook instead
 */
export const isoLanguages = new Map<string, LanguageConfig>([
  [
    'aa',
    {
      code: 'aa',
      labels: {
        [AppLanguage.English]: 'Afar',
        [AppLanguage.Spanish]: 'Afar',
        [AppLanguage.German]: 'Afar'
      }
    }
  ],
  [
    'ab',
    {
      code: 'ab',
      labels: {
        [AppLanguage.English]: 'Abkhazian',
        [AppLanguage.Spanish]: 'Abkhazian',
        [AppLanguage.German]: 'Abchasisch'
      }
    }
  ],
  [
    'ae',
    {
      code: 'ae',
      labels: {
        [AppLanguage.English]: 'Avestan',
        [AppLanguage.Spanish]: 'Avestan',
        [AppLanguage.German]: 'Avestisch'
      }
    }
  ],
  [
    'af',
    {
      code: 'af',
      labels: {
        [AppLanguage.English]: 'Afrikaans',
        [AppLanguage.Spanish]: 'Afrikaans',
        [AppLanguage.German]: 'Afrikaans'
      }
    }
  ],
  [
    'ak',
    {
      code: 'ak',
      labels: {
        [AppLanguage.English]: 'Akan',
        [AppLanguage.Spanish]: 'Akan',
        [AppLanguage.German]: 'Akan'
      }
    }
  ],
  [
    'am',
    {
      code: 'am',
      labels: {
        [AppLanguage.English]: 'Amharic',
        [AppLanguage.Spanish]: 'Amharic',
        [AppLanguage.German]: 'Amharisch'
      }
    }
  ],
  [
    'an',
    {
      code: 'an',
      labels: {
        [AppLanguage.English]: 'Aragonese',
        [AppLanguage.Spanish]: 'Aragonese',
        [AppLanguage.German]: 'Aragonesisch'
      }
    }
  ],
  [
    'ar',
    {
      code: 'ar',
      labels: {
        [AppLanguage.English]: 'Arabic',
        [AppLanguage.Spanish]: 'Arabic',
        [AppLanguage.German]: 'Arabisch'
      }
    }
  ],
  [
    'as',
    {
      code: 'as',
      labels: {
        [AppLanguage.English]: 'Assamese',
        [AppLanguage.Spanish]: 'Assamese',
        [AppLanguage.German]: 'Assamesisch'
      }
    }
  ],
  [
    'av',
    {
      code: 'av',
      labels: {
        [AppLanguage.English]: 'Avaric',
        [AppLanguage.Spanish]: 'Avaric',
        [AppLanguage.German]: 'Avarisch'
      }
    }
  ],
  [
    'ay',
    {
      code: 'ay',
      labels: {
        [AppLanguage.English]: 'Aymara',
        [AppLanguage.Spanish]: 'Aymara',
        [AppLanguage.German]: 'Aymara'
      }
    }
  ],
  [
    'az',
    {
      code: 'az',
      labels: {
        [AppLanguage.English]: 'Azerbaijani',
        [AppLanguage.Spanish]: 'Azerbaijani',
        [AppLanguage.German]: 'Aserbaidschanisch'
      }
    }
  ],
  [
    'ba',
    {
      code: 'ba',
      labels: {
        [AppLanguage.English]: 'Bashkir',
        [AppLanguage.Spanish]: 'Bashkir',
        [AppLanguage.German]: 'Baschkirisch'
      }
    }
  ],
  [
    'be',
    {
      code: 'be',
      labels: {
        [AppLanguage.English]: 'Belarusian',
        [AppLanguage.Spanish]: 'Belarusian',
        [AppLanguage.German]: 'Belarussisch'
      }
    }
  ],
  [
    'bg',
    {
      code: 'bg',
      labels: {
        [AppLanguage.English]: 'Bulgarian',
        [AppLanguage.Spanish]: 'Bulgarian',
        [AppLanguage.German]: 'Bulgarisch'
      }
    }
  ],
  [
    'bh',
    {
      code: 'bh',
      labels: {
        [AppLanguage.English]: 'Bihari languages',
        [AppLanguage.Spanish]: 'Bihari languages',
        [AppLanguage.German]: 'Bihari'
      }
    }
  ],
  [
    'bi',
    {
      code: 'bi',
      labels: {
        [AppLanguage.English]: 'Bislama',
        [AppLanguage.Spanish]: 'Bislama',
        [AppLanguage.German]: 'Bislama'
      }
    }
  ],
  [
    'bm',
    {
      code: 'bm',
      labels: {
        [AppLanguage.English]: 'Bambara',
        [AppLanguage.Spanish]: 'Bambara',
        [AppLanguage.German]: 'Bambara'
      }
    }
  ],
  [
    'bn',
    {
      code: 'bn',
      labels: {
        [AppLanguage.English]: 'Bengali',
        [AppLanguage.Spanish]: 'Bengali',
        [AppLanguage.German]: 'Bengalisch'
      }
    }
  ],
  [
    'bo',
    {
      code: 'bo',
      labels: {
        [AppLanguage.English]: 'Tibetan',
        [AppLanguage.Spanish]: 'Tibetan',
        [AppLanguage.German]: 'Tibetisch'
      }
    }
  ],
  [
    'br',
    {
      code: 'br',
      labels: {
        [AppLanguage.English]: 'Breton',
        [AppLanguage.Spanish]: 'Breton',
        [AppLanguage.German]: 'Bretonisch'
      }
    }
  ],
  [
    'bs',
    {
      code: 'bs',
      labels: {
        [AppLanguage.English]: 'Bosnian',
        [AppLanguage.Spanish]: 'Bosnian',
        [AppLanguage.German]: 'Bosnisch'
      }
    }
  ],
  [
    'ca',
    {
      code: 'ca',
      labels: {
        [AppLanguage.English]: 'Català',
        [AppLanguage.Spanish]: 'Català',
        [AppLanguage.German]: 'Català'
      }
    }
  ],
  [
    'ce',
    {
      code: 'ce',
      labels: {
        [AppLanguage.English]: 'Chechen',
        [AppLanguage.Spanish]: 'Chechen',
        [AppLanguage.German]: 'Tschetschenisch'
      }
    }
  ],
  [
    'ch',
    {
      code: 'ch',
      labels: {
        [AppLanguage.English]: 'Chamorro',
        [AppLanguage.Spanish]: 'Chamorro',
        [AppLanguage.German]: 'Chamorro'
      }
    }
  ],
  [
    'co',
    {
      code: 'co',
      labels: {
        [AppLanguage.English]: 'Corsican',
        [AppLanguage.Spanish]: 'Corsican',
        [AppLanguage.German]: 'Korsisch'
      }
    }
  ],
  [
    'cr',
    {
      code: 'cr',
      labels: {
        [AppLanguage.English]: 'Cree',
        [AppLanguage.Spanish]: 'Cree',
        [AppLanguage.German]: 'Cree'
      }
    }
  ],
  [
    'cs',
    {
      code: 'cs',
      labels: {
        [AppLanguage.English]: 'Čeština',
        [AppLanguage.Spanish]: 'Čeština',
        [AppLanguage.German]: 'Čeština'
      }
    }
  ],
  [
    'cu',
    {
      code: 'cu',
      labels: {
        [AppLanguage.English]:
          'Church Slavic; Old Slavonic; Church Slavonic; Old Bulgarian; Old Church Slavonic',
        [AppLanguage.Spanish]:
          'Church Slavic; Old Slavonic; Church Slavonic; Old Bulgarian; Old Church Slavonic',
        [AppLanguage.German]: 'Kirchenslawisch'
      }
    }
  ],
  [
    'cv',
    {
      code: 'cv',
      labels: {
        [AppLanguage.English]: 'Chuvash',
        [AppLanguage.Spanish]: 'Chuvash',
        [AppLanguage.German]: 'Tschuwaschisch'
      }
    }
  ],
  [
    'cy',
    {
      code: 'cy',
      labels: {
        [AppLanguage.English]: 'Welsh',
        [AppLanguage.Spanish]: 'Welsh',
        [AppLanguage.German]: 'Walisisch'
      }
    }
  ],
  [
    'da',
    {
      code: 'da',
      labels: {
        [AppLanguage.English]: 'Danish',
        [AppLanguage.Spanish]: 'Danish',
        [AppLanguage.German]: 'Dänisch'
      }
    }
  ],
  [
    'de',
    {
      code: 'de',
      labels: {
        [AppLanguage.English]: 'Deutsch',
        [AppLanguage.Spanish]: 'Deutsch',
        [AppLanguage.German]: 'Deutsch'
      }
    }
  ],
  [
    'dv',
    {
      code: 'dv',
      labels: {
        [AppLanguage.English]: 'Divehi; Dhivehi; Maldivian',
        [AppLanguage.Spanish]: 'Divehi; Dhivehi; Maldivian',
        [AppLanguage.German]: 'Dhivehi'
      }
    }
  ],
  [
    'dz',
    {
      code: 'dz',
      labels: {
        [AppLanguage.English]: 'Dzongkha',
        [AppLanguage.Spanish]: 'Dzongkha',
        [AppLanguage.German]: 'Dzongkha'
      }
    }
  ],
  [
    'ee',
    {
      code: 'ee',
      labels: {
        [AppLanguage.English]: 'Ewe',
        [AppLanguage.Spanish]: 'Ewe',
        [AppLanguage.German]: 'Ewe'
      }
    }
  ],
  [
    'el',
    {
      code: 'el',
      labels: {
        [AppLanguage.English]: 'Greek, Modern',
        [AppLanguage.Spanish]: 'Greek, Modern',
        [AppLanguage.German]: 'Griechisch'
      }
    }
  ],
  [
    'en',
    {
      code: 'en',
      labels: {
        [AppLanguage.English]: 'English',
        [AppLanguage.Spanish]: 'English',
        [AppLanguage.German]: 'English'
      }
    }
  ],
  [
    'eo',
    {
      code: 'eo',
      labels: {
        [AppLanguage.English]: 'Esperanto',
        [AppLanguage.Spanish]: 'Esperanto',
        [AppLanguage.German]: 'Esperanto'
      }
    }
  ],
  [
    'es',
    {
      code: 'es',
      labels: {
        [AppLanguage.English]: 'Español',
        [AppLanguage.Spanish]: 'Español',
        [AppLanguage.German]: 'Español'
      }
    }
  ],
  [
    'et',
    {
      code: 'et',
      labels: {
        [AppLanguage.English]: 'Estonian',
        [AppLanguage.Spanish]: 'Estonian',
        [AppLanguage.German]: 'Estnisch'
      }
    }
  ],
  [
    'eu',
    {
      code: 'eu',
      labels: {
        [AppLanguage.English]: 'Basque',
        [AppLanguage.Spanish]: 'Basque',
        [AppLanguage.German]: 'Baskisch'
      }
    }
  ],
  [
    'fa',
    {
      code: 'fa',
      labels: {
        [AppLanguage.English]: 'Persian',
        [AppLanguage.Spanish]: 'Persian',
        [AppLanguage.German]: 'Persisch'
      }
    }
  ],
  [
    'ff',
    {
      code: 'ff',
      labels: {
        [AppLanguage.English]: 'Fulah',
        [AppLanguage.Spanish]: 'Fulah',
        [AppLanguage.German]: 'Fulfulde'
      }
    }
  ],
  [
    'fi',
    {
      code: 'fi',
      labels: {
        [AppLanguage.English]: 'Finnish',
        [AppLanguage.Spanish]: 'Finnish',
        [AppLanguage.German]: 'Finnisch'
      }
    }
  ],
  [
    'fj',
    {
      code: 'fj',
      labels: {
        [AppLanguage.English]: 'Fijian',
        [AppLanguage.Spanish]: 'Fijian',
        [AppLanguage.German]: 'Fidschi'
      }
    }
  ],
  [
    'fo',
    {
      code: 'fo',
      labels: {
        [AppLanguage.English]: 'Faroese',
        [AppLanguage.Spanish]: 'Faroese',
        [AppLanguage.German]: 'Färöisch'
      }
    }
  ],
  [
    'fr',
    {
      code: 'fr',
      labels: {
        [AppLanguage.English]: 'Français',
        [AppLanguage.Spanish]: 'Français',
        [AppLanguage.German]: 'Français'
      }
    }
  ],
  [
    'fy',
    {
      code: 'fy',
      labels: {
        [AppLanguage.English]: 'Western Frisian',
        [AppLanguage.Spanish]: 'Western Frisian',
        [AppLanguage.German]: 'Westfriesisch'
      }
    }
  ],
  [
    'ga',
    {
      code: 'ga',
      labels: {
        [AppLanguage.English]: 'Irish',
        [AppLanguage.Spanish]: 'Irish',
        [AppLanguage.German]: 'Irisch'
      }
    }
  ],
  [
    'gd',
    {
      code: 'gd',
      labels: {
        [AppLanguage.English]: 'Gaelic; Scomttish Gaelic',
        [AppLanguage.Spanish]: 'Gaelic; Scomttish Gaelic',
        [AppLanguage.German]: 'Schottisch-gälisch'
      }
    }
  ],
  [
    'gl',
    {
      code: 'gl',
      labels: {
        [AppLanguage.English]: 'Galician',
        [AppLanguage.Spanish]: 'Galician',
        [AppLanguage.German]: 'Galicisch'
      }
    }
  ],
  [
    'gn',
    {
      code: 'gn',
      labels: {
        [AppLanguage.English]: 'Guarani',
        [AppLanguage.Spanish]: 'Guarani',
        [AppLanguage.German]: 'Guaraní'
      }
    }
  ],
  [
    'gu',
    {
      code: 'gu',
      labels: {
        [AppLanguage.English]: 'Gujarati',
        [AppLanguage.Spanish]: 'Gujarati',
        [AppLanguage.German]: 'Gujarati'
      }
    }
  ],
  [
    'gv',
    {
      code: 'gv',
      labels: {
        [AppLanguage.English]: 'Manx',
        [AppLanguage.Spanish]: 'Manx',
        [AppLanguage.German]: 'Manx'
      }
    }
  ],
  [
    'ha',
    {
      code: 'ha',
      labels: {
        [AppLanguage.English]: 'Hausa',
        [AppLanguage.Spanish]: 'Hausa',
        [AppLanguage.German]: 'Hausa'
      }
    }
  ],
  [
    'he',
    {
      code: 'he',
      labels: {
        [AppLanguage.English]: 'Hebrew',
        [AppLanguage.Spanish]: 'Hebrew',
        [AppLanguage.German]: 'Hebräisch'
      }
    }
  ],
  [
    'hi',
    {
      code: 'hi',
      labels: {
        [AppLanguage.English]: 'Hindi',
        [AppLanguage.Spanish]: 'Hindi',
        [AppLanguage.German]: 'Hindi'
      }
    }
  ],
  [
    'ho',
    {
      code: 'ho',
      labels: {
        [AppLanguage.English]: 'Hiri Motu',
        [AppLanguage.Spanish]: 'Hiri Motu',
        [AppLanguage.German]: 'Hiri Motu'
      }
    }
  ],
  [
    'hr',
    {
      code: 'hr',
      labels: {
        [AppLanguage.English]: 'Hrvatski',
        [AppLanguage.Spanish]: 'Hrvatski',
        [AppLanguage.German]: 'Hrvatski'
      }
    }
  ],
  [
    'ht',
    {
      code: 'ht',
      labels: {
        [AppLanguage.English]: 'Haitian; Haitian Creole',
        [AppLanguage.Spanish]: 'Haitian; Haitian Creole',
        [AppLanguage.German]: 'Haitianisch'
      }
    }
  ],
  [
    'hu',
    {
      code: 'hu',
      labels: {
        [AppLanguage.English]: 'Magyar',
        [AppLanguage.Spanish]: 'Magyar',
        [AppLanguage.German]: 'Magyar'
      }
    }
  ],
  [
    'hy',
    {
      code: 'hy',
      labels: {
        [AppLanguage.English]: 'Armenian',
        [AppLanguage.Spanish]: 'Armenian',
        [AppLanguage.German]: 'Armenisch'
      }
    }
  ],
  [
    'hz',
    {
      code: 'hz',
      labels: {
        [AppLanguage.English]: 'Herero',
        [AppLanguage.Spanish]: 'Herero',
        [AppLanguage.German]: 'Otjiherero'
      }
    }
  ],
  [
    'ia',
    {
      code: 'ia',
      labels: {
        [AppLanguage.English]: 'Interlingua',
        [AppLanguage.Spanish]: 'Interlingua',
        [AppLanguage.German]: 'Interlingua'
      }
    }
  ],
  [
    'id',
    {
      code: 'id',
      labels: {
        [AppLanguage.English]: 'Indonesian',
        [AppLanguage.Spanish]: 'Indonesian',
        [AppLanguage.German]: 'Indonesisch'
      }
    }
  ],
  [
    'ie',
    {
      code: 'ie',
      labels: {
        [AppLanguage.English]: 'Interlingue; Occidental',
        [AppLanguage.Spanish]: 'Interlingue; Occidental',
        [AppLanguage.German]: 'Interlingue'
      }
    }
  ],
  [
    'ig',
    {
      code: 'ig',
      labels: {
        [AppLanguage.English]: 'Igbo',
        [AppLanguage.Spanish]: 'Igbo',
        [AppLanguage.German]: 'Igbo'
      }
    }
  ],
  [
    'ii',
    {
      code: 'ii',
      labels: {
        [AppLanguage.English]: 'Sichuan Yi; Nuosu',
        [AppLanguage.Spanish]: 'Sichuan Yi; Nuosu',
        [AppLanguage.German]: 'Yi'
      }
    }
  ],
  [
    'ik',
    {
      code: 'ik',
      labels: {
        [AppLanguage.English]: 'Inupiaq',
        [AppLanguage.Spanish]: 'Inupiaq',
        [AppLanguage.German]: 'Inupiaq'
      }
    }
  ],
  [
    'io',
    {
      code: 'io',
      labels: {
        [AppLanguage.English]: 'Ido',
        [AppLanguage.Spanish]: 'Ido',
        [AppLanguage.German]: 'Ido'
      }
    }
  ],
  [
    'is',
    {
      code: 'is',
      labels: {
        [AppLanguage.English]: 'Icelandic',
        [AppLanguage.Spanish]: 'Icelandic',
        [AppLanguage.German]: 'Isländisch'
      }
    }
  ],
  [
    'it',
    {
      code: 'it',
      labels: {
        [AppLanguage.English]: 'Italiano',
        [AppLanguage.Spanish]: 'Italiano',
        [AppLanguage.German]: 'Italiano'
      }
    }
  ],
  [
    'iu',
    {
      code: 'iu',
      labels: {
        [AppLanguage.English]: 'Inuktitut',
        [AppLanguage.Spanish]: 'Inuktitut',
        [AppLanguage.German]: 'Inuktitut'
      }
    }
  ],
  [
    'ja',
    {
      code: 'ja',
      labels: {
        [AppLanguage.English]: 'Japanese',
        [AppLanguage.Spanish]: 'Japanese',
        [AppLanguage.German]: 'Japanisch'
      }
    }
  ],
  [
    'jv',
    {
      code: 'jv',
      labels: {
        [AppLanguage.English]: 'Javanese',
        [AppLanguage.Spanish]: 'Javanese',
        [AppLanguage.German]: 'Javanisch'
      }
    }
  ],
  [
    'ka',
    {
      code: 'ka',
      labels: {
        [AppLanguage.English]: 'Georgian',
        [AppLanguage.Spanish]: 'Georgian',
        [AppLanguage.German]: 'Georgisch'
      }
    }
  ],
  [
    'kg',
    {
      code: 'kg',
      labels: {
        [AppLanguage.English]: 'Kongo',
        [AppLanguage.Spanish]: 'Kongo',
        [AppLanguage.German]: 'Kikongo'
      }
    }
  ],
  [
    'ki',
    {
      code: 'ki',
      labels: {
        [AppLanguage.English]: 'Kikuyu; Gikuyu',
        [AppLanguage.Spanish]: 'Kikuyu; Gikuyu',
        [AppLanguage.German]: 'Kikuyu'
      }
    }
  ],
  [
    'kj',
    {
      code: 'kj',
      labels: {
        [AppLanguage.English]: 'Kuanyama; Kwanyama',
        [AppLanguage.Spanish]: 'Kuanyama; Kwanyama',
        [AppLanguage.German]: 'oshiKwanyama'
      }
    }
  ],
  [
    'kk',
    {
      code: 'kk',
      labels: {
        [AppLanguage.English]: 'Kazakh',
        [AppLanguage.Spanish]: 'Kazakh',
        [AppLanguage.German]: 'Kasachisch'
      }
    }
  ],
  [
    'kl',
    {
      code: 'kl',
      labels: {
        [AppLanguage.English]: 'Kalaallisut; Greenlandic',
        [AppLanguage.Spanish]: 'Kalaallisut; Greenlandic',
        [AppLanguage.German]: 'Grönländisch'
      }
    }
  ],
  [
    'km',
    {
      code: 'km',
      labels: {
        [AppLanguage.English]: 'Central Khmer',
        [AppLanguage.Spanish]: 'Central Khmer',
        [AppLanguage.German]: 'Khmer'
      }
    }
  ],
  [
    'kn',
    {
      code: 'kn',
      labels: {
        [AppLanguage.English]: 'Kannada',
        [AppLanguage.Spanish]: 'Kannada',
        [AppLanguage.German]: 'Kannada'
      }
    }
  ],
  [
    'ko',
    {
      code: 'ko',
      labels: {
        [AppLanguage.English]: 'Korean',
        [AppLanguage.Spanish]: 'Korean',
        [AppLanguage.German]: 'Koreanisch'
      }
    }
  ],
  [
    'kr',
    {
      code: 'kr',
      labels: {
        [AppLanguage.English]: 'Kanuri',
        [AppLanguage.Spanish]: 'Kanuri',
        [AppLanguage.German]: 'Kanuri'
      }
    }
  ],
  [
    'ks',
    {
      code: 'ks',
      labels: {
        [AppLanguage.English]: 'Kashmiri',
        [AppLanguage.Spanish]: 'Kashmiri',
        [AppLanguage.German]: 'Kashmiri'
      }
    }
  ],
  [
    'ku',
    {
      code: 'ku',
      labels: {
        [AppLanguage.English]: 'Kurdish',
        [AppLanguage.Spanish]: 'Kurdish',
        [AppLanguage.German]: 'Kurdisch'
      }
    }
  ],
  [
    'kv',
    {
      code: 'kv',
      labels: {
        [AppLanguage.English]: 'Komi',
        [AppLanguage.Spanish]: 'Komi',
        [AppLanguage.German]: 'Komi'
      }
    }
  ],
  [
    'kw',
    {
      code: 'kw',
      labels: {
        [AppLanguage.English]: 'Cornish',
        [AppLanguage.Spanish]: 'Cornish',
        [AppLanguage.German]: 'Kornisch'
      }
    }
  ],
  [
    'ky',
    {
      code: 'ky',
      labels: {
        [AppLanguage.English]: 'Kirghiz; Kyrgyz',
        [AppLanguage.Spanish]: 'Kirghiz; Kyrgyz',
        [AppLanguage.German]: 'Kirgisisch'
      }
    }
  ],
  [
    'la',
    {
      code: 'la',
      labels: {
        [AppLanguage.English]: 'Latin',
        [AppLanguage.Spanish]: 'Latin',
        [AppLanguage.German]: 'Latein'
      }
    }
  ],
  [
    'lb',
    {
      code: 'lb',
      labels: {
        [AppLanguage.English]: 'Luxembourgish; Letzeburgesch',
        [AppLanguage.Spanish]: 'Luxembourgish; Letzeburgesch',
        [AppLanguage.German]: 'Luxemburgisch'
      }
    }
  ],
  [
    'lg',
    {
      code: 'lg',
      labels: {
        [AppLanguage.English]: 'Ganda',
        [AppLanguage.Spanish]: 'Ganda',
        [AppLanguage.German]: 'Luganda'
      }
    }
  ],
  [
    'li',
    {
      code: 'li',
      labels: {
        [AppLanguage.English]: 'Limburgan; Limburger; Limburgish',
        [AppLanguage.Spanish]: 'Limburgan; Limburger; Limburgish',
        [AppLanguage.German]: 'Limburgisch'
      }
    }
  ],
  [
    'ln',
    {
      code: 'ln',
      labels: {
        [AppLanguage.English]: 'Lingala',
        [AppLanguage.Spanish]: 'Lingala',
        [AppLanguage.German]: 'Lingála'
      }
    }
  ],
  [
    'lo',
    {
      code: 'lo',
      labels: {
        [AppLanguage.English]: 'Lao',
        [AppLanguage.Spanish]: 'Lao',
        [AppLanguage.German]: 'Laotisch'
      }
    }
  ],
  [
    'lt',
    {
      code: 'lt',
      labels: {
        [AppLanguage.English]: 'Lithuanian',
        [AppLanguage.Spanish]: 'Lithuanian',
        [AppLanguage.German]: 'Litauisch'
      }
    }
  ],
  [
    'lu',
    {
      code: 'lu',
      labels: {
        [AppLanguage.English]: 'Luba-Katanga',
        [AppLanguage.Spanish]: 'Luba-Katanga',
        [AppLanguage.German]: 'Kiluba'
      }
    }
  ],
  [
    'lv',
    {
      code: 'lv',
      labels: {
        [AppLanguage.English]: 'Latvian',
        [AppLanguage.Spanish]: 'Latvian',
        [AppLanguage.German]: 'Lettisch'
      }
    }
  ],
  [
    'mg',
    {
      code: 'mg',
      labels: {
        [AppLanguage.English]: 'Malagasy',
        [AppLanguage.Spanish]: 'Malagasy',
        [AppLanguage.German]: 'Malagasy'
      }
    }
  ],
  [
    'mh',
    {
      code: 'mh',
      labels: {
        [AppLanguage.English]: 'Marshallese',
        [AppLanguage.Spanish]: 'Marshallese',
        [AppLanguage.German]: 'Marshallesisch'
      }
    }
  ],
  [
    'mi',
    {
      code: 'mi',
      labels: {
        [AppLanguage.English]: 'Maori',
        [AppLanguage.Spanish]: 'Maori',
        [AppLanguage.German]: 'Maori'
      }
    }
  ],
  [
    'mk',
    {
      code: 'mk',
      labels: {
        [AppLanguage.English]: 'Macedonian',
        [AppLanguage.Spanish]: 'Macedonian',
        [AppLanguage.German]: 'Mazedonisch'
      }
    }
  ],
  [
    'ml',
    {
      code: 'ml',
      labels: {
        [AppLanguage.English]: 'Malayalam',
        [AppLanguage.Spanish]: 'Malayalam',
        [AppLanguage.German]: 'Malayalam'
      }
    }
  ],
  [
    'mn',
    {
      code: 'mn',
      labels: {
        [AppLanguage.English]: 'Mongolian',
        [AppLanguage.Spanish]: 'Mongolian',
        [AppLanguage.German]: 'Mongolisch'
      }
    }
  ],
  [
    'mr',
    {
      code: 'mr',
      labels: {
        [AppLanguage.English]: 'Marathi',
        [AppLanguage.Spanish]: 'Marathi',
        [AppLanguage.German]: 'Marathi'
      }
    }
  ],
  [
    'ms',
    {
      code: 'ms',
      labels: {
        [AppLanguage.English]: 'Malay',
        [AppLanguage.Spanish]: 'Malay',
        [AppLanguage.German]: 'Malaiisch'
      }
    }
  ],
  [
    'mt',
    {
      code: 'mt',
      labels: {
        [AppLanguage.English]: 'Maltese',
        [AppLanguage.Spanish]: 'Maltese',
        [AppLanguage.German]: 'Maltesisch'
      }
    }
  ],
  [
    'my',
    {
      code: 'my',
      labels: {
        [AppLanguage.English]: 'Burmese',
        [AppLanguage.Spanish]: 'Burmese',
        [AppLanguage.German]: 'Birmanisch'
      }
    }
  ],
  [
    'na',
    {
      code: 'na',
      labels: {
        [AppLanguage.English]: 'Nauru',
        [AppLanguage.Spanish]: 'Nauru',
        [AppLanguage.German]: 'Nauruisch'
      }
    }
  ],
  [
    'nb',
    {
      code: 'nb',
      labels: {
        [AppLanguage.English]: 'Bokmål, Norwegian; Norwegian Bokmål',
        [AppLanguage.Spanish]: 'Bokmål, Norwegian; Norwegian Bokmål',
        [AppLanguage.German]: 'Bokmål'
      }
    }
  ],
  [
    'nd',
    {
      code: 'nd',
      labels: {
        [AppLanguage.English]: 'Ndebele, North; North Ndebele',
        [AppLanguage.Spanish]: 'Ndebele, North; North Ndebele',
        [AppLanguage.German]: 'Nord-Ndebele'
      }
    }
  ],
  [
    'ne',
    {
      code: 'ne',
      labels: {
        [AppLanguage.English]: 'Nepali',
        [AppLanguage.Spanish]: 'Nepali',
        [AppLanguage.German]: 'Nepali'
      }
    }
  ],
  [
    'ng',
    {
      code: 'ng',
      labels: {
        [AppLanguage.English]: 'Ndonga',
        [AppLanguage.Spanish]: 'Ndonga',
        [AppLanguage.German]: 'Ndonga'
      }
    }
  ],
  [
    'nl',
    {
      code: 'nl',
      labels: {
        [AppLanguage.English]: 'Nederlands',
        [AppLanguage.Spanish]: 'Nederlands',
        [AppLanguage.German]: 'Nederlands'
      }
    }
  ],
  [
    'nn',
    {
      code: 'nn',
      labels: {
        [AppLanguage.English]: 'Norwegian Nynorsk; Nynorsk, Norwegian',
        [AppLanguage.Spanish]: 'Norwegian Nynorsk; Nynorsk, Norwegian',
        [AppLanguage.German]: 'Nynorsk'
      }
    }
  ],
  [
    'no',
    {
      code: 'no',
      labels: {
        [AppLanguage.English]: 'Norwegian',
        [AppLanguage.Spanish]: 'Norwegian',
        [AppLanguage.German]: 'Norwegisch'
      }
    }
  ],
  [
    'nr',
    {
      code: 'nr',
      labels: {
        [AppLanguage.English]: 'Ndebele, South; South Ndebele',
        [AppLanguage.Spanish]: 'Ndebele, South; South Ndebele',
        [AppLanguage.German]: 'Süd-Ndebele'
      }
    }
  ],
  [
    'nv',
    {
      code: 'nv',
      labels: {
        [AppLanguage.English]: 'Navajo; Navaho',
        [AppLanguage.Spanish]: 'Navajo; Navaho',
        [AppLanguage.German]: 'Navajo'
      }
    }
  ],
  [
    'ny',
    {
      code: 'ny',
      labels: {
        [AppLanguage.English]: 'Chichewa; Chewa; Nyanja',
        [AppLanguage.Spanish]: 'Chichewa; Chewa; Nyanja',
        [AppLanguage.German]: 'Chichewa'
      }
    }
  ],
  [
    'oc',
    {
      code: 'oc',
      labels: {
        [AppLanguage.English]: 'Occitan',
        [AppLanguage.Spanish]: 'Occitan',
        [AppLanguage.German]: 'Okzitanisch'
      }
    }
  ],
  [
    'oj',
    {
      code: 'oj',
      labels: {
        [AppLanguage.English]: 'Ojibwa',
        [AppLanguage.Spanish]: 'Ojibwa',
        [AppLanguage.German]: 'Ojibwe'
      }
    }
  ],
  [
    'om',
    {
      code: 'om',
      labels: {
        [AppLanguage.English]: 'Oromo',
        [AppLanguage.Spanish]: 'Oromo',
        [AppLanguage.German]: 'Oromo'
      }
    }
  ],
  [
    'or',
    {
      code: 'or',
      labels: {
        [AppLanguage.English]: 'Oriya',
        [AppLanguage.Spanish]: 'Oriya',
        [AppLanguage.German]: 'Oriya'
      }
    }
  ],
  [
    'os',
    {
      code: 'os',
      labels: {
        [AppLanguage.English]: 'Ossetian; Ossetic',
        [AppLanguage.Spanish]: 'Ossetian; Ossetic',
        [AppLanguage.German]: 'Ossetisch'
      }
    }
  ],
  [
    'pa',
    {
      code: 'pa',
      labels: {
        [AppLanguage.English]: 'Panjabi; Punjabi',
        [AppLanguage.Spanish]: 'Panjabi; Punjabi',
        [AppLanguage.German]: 'Panjabi'
      }
    }
  ],
  [
    'pi',
    {
      code: 'pi',
      labels: {
        [AppLanguage.English]: 'Pali',
        [AppLanguage.Spanish]: 'Pali',
        [AppLanguage.German]: 'Pali'
      }
    }
  ],
  [
    'pl',
    {
      code: 'pl',
      labels: {
        [AppLanguage.English]: 'Polski',
        [AppLanguage.Spanish]: 'Polski',
        [AppLanguage.German]: 'Polski'
      }
    }
  ],
  [
    'ps',
    {
      code: 'ps',
      labels: {
        [AppLanguage.English]: 'Pushto; Pashto',
        [AppLanguage.Spanish]: 'Pushto; Pashto',
        [AppLanguage.German]: 'Paschtunisch'
      }
    }
  ],
  [
    'pt',
    {
      code: 'pt',
      labels: {
        [AppLanguage.English]: 'Português',
        [AppLanguage.Spanish]: 'Português',
        [AppLanguage.German]: 'Português'
      }
    }
  ],
  [
    'qu',
    {
      code: 'qu',
      labels: {
        [AppLanguage.English]: 'Quechua',
        [AppLanguage.Spanish]: 'Quechua',
        [AppLanguage.German]: 'Quechua'
      }
    }
  ],
  [
    'rm',
    {
      code: 'rm',
      labels: {
        [AppLanguage.English]: 'Romansh',
        [AppLanguage.Spanish]: 'Romansh',
        [AppLanguage.German]: 'Bündnerromanisch'
      }
    }
  ],
  [
    'rn',
    {
      code: 'rn',
      labels: {
        [AppLanguage.English]: 'Rundi',
        [AppLanguage.Spanish]: 'Rundi',
        [AppLanguage.German]: 'Kirundi'
      }
    }
  ],
  [
    'ro',
    {
      code: 'ro',
      labels: {
        [AppLanguage.English]: 'Romanian; Moldavian; Moldovan',
        [AppLanguage.Spanish]: 'Romanian; Moldavian; Moldovan',
        [AppLanguage.German]: 'Rumänisch'
      }
    }
  ],
  [
    'ru',
    {
      code: 'ru',
      labels: {
        [AppLanguage.English]: 'Russian',
        [AppLanguage.Spanish]: 'Russian',
        [AppLanguage.German]: 'Russisch'
      }
    }
  ],
  [
    'rw',
    {
      code: 'rw',
      labels: {
        [AppLanguage.English]: 'Kinyarwanda',
        [AppLanguage.Spanish]: 'Kinyarwanda',
        [AppLanguage.German]: 'Kinyarwanda'
      }
    }
  ],
  [
    'sa',
    {
      code: 'sa',
      labels: {
        [AppLanguage.English]: 'Sanskrit',
        [AppLanguage.Spanish]: 'Sanskrit',
        [AppLanguage.German]: 'Sanskrit'
      }
    }
  ],
  [
    'sc',
    {
      code: 'sc',
      labels: {
        [AppLanguage.English]: 'Sardinian',
        [AppLanguage.Spanish]: 'Sardinian',
        [AppLanguage.German]: 'Sardisch'
      }
    }
  ],
  [
    'sd',
    {
      code: 'sd',
      labels: {
        [AppLanguage.English]: 'Sindhi',
        [AppLanguage.Spanish]: 'Sindhi',
        [AppLanguage.German]: 'Sindhi'
      }
    }
  ],
  [
    'se',
    {
      code: 'se',
      labels: {
        [AppLanguage.English]: 'Northern Sami',
        [AppLanguage.Spanish]: 'Northern Sami',
        [AppLanguage.German]: 'Nordsamisch'
      }
    }
  ],
  [
    'sg',
    {
      code: 'sg',
      labels: {
        [AppLanguage.English]: 'Sango',
        [AppLanguage.Spanish]: 'Sango',
        [AppLanguage.German]: 'Sango'
      }
    }
  ],
  [
    'si',
    {
      code: 'si',
      labels: {
        [AppLanguage.English]: 'Sinhala; Sinhalese',
        [AppLanguage.Spanish]: 'Sinhala; Sinhalese',
        [AppLanguage.German]: 'Singhalesisch'
      }
    }
  ],
  [
    'sk',
    {
      code: 'sk',
      labels: {
        [AppLanguage.English]: 'Slovak',
        [AppLanguage.Spanish]: 'Slovak',
        [AppLanguage.German]: 'Slowakisch'
      }
    }
  ],
  [
    'sl',
    {
      code: 'sl',
      labels: {
        [AppLanguage.English]: 'Slovenian',
        [AppLanguage.Spanish]: 'Slovenian',
        [AppLanguage.German]: 'Slowenisch'
      }
    }
  ],
  [
    'sm',
    {
      code: 'sm',
      labels: {
        [AppLanguage.English]: 'Samoan',
        [AppLanguage.Spanish]: 'Samoan',
        [AppLanguage.German]: 'Samoanisch'
      }
    }
  ],
  [
    'sn',
    {
      code: 'sn',
      labels: {
        [AppLanguage.English]: 'Shona',
        [AppLanguage.Spanish]: 'Shona',
        [AppLanguage.German]: 'Shona'
      }
    }
  ],
  [
    'so',
    {
      code: 'so',
      labels: {
        [AppLanguage.English]: 'Somali',
        [AppLanguage.Spanish]: 'Somali',
        [AppLanguage.German]: 'Somali'
      }
    }
  ],
  [
    'sq',
    {
      code: 'sq',
      labels: {
        [AppLanguage.English]: 'Albanian',
        [AppLanguage.Spanish]: 'Albanian',
        [AppLanguage.German]: 'Albanisch'
      }
    }
  ],
  [
    'sr',
    {
      code: 'sr',
      labels: {
        [AppLanguage.English]: 'Serbian',
        [AppLanguage.Spanish]: 'Serbian',
        [AppLanguage.German]: 'Serbisch'
      }
    }
  ],
  [
    'ss',
    {
      code: 'ss',
      labels: {
        [AppLanguage.English]: 'Swati',
        [AppLanguage.Spanish]: 'Swati',
        [AppLanguage.German]: 'Siswati'
      }
    }
  ],
  [
    'st',
    {
      code: 'st',
      labels: {
        [AppLanguage.English]: 'Sotho, Southern',
        [AppLanguage.Spanish]: 'Sotho, Southern',
        [AppLanguage.German]: 'Sesotho'
      }
    }
  ],
  [
    'su',
    {
      code: 'su',
      labels: {
        [AppLanguage.English]: 'Sundanese',
        [AppLanguage.Spanish]: 'Sundanese',
        [AppLanguage.German]: 'Sundanesisch'
      }
    }
  ],
  [
    'sv',
    {
      code: 'sv',
      labels: {
        [AppLanguage.English]: 'Svenska',
        [AppLanguage.Spanish]: 'Svenska',
        [AppLanguage.German]: 'Svenska'
      }
    }
  ],
  [
    'sw',
    {
      code: 'sw',
      labels: {
        [AppLanguage.English]: 'Swahili',
        [AppLanguage.Spanish]: 'Swahili',
        [AppLanguage.German]: 'Swahili'
      }
    }
  ],
  [
    'ta',
    {
      code: 'ta',
      labels: {
        [AppLanguage.English]: 'Tamil',
        [AppLanguage.Spanish]: 'Tamil',
        [AppLanguage.German]: 'Tamil'
      }
    }
  ],
  [
    'te',
    {
      code: 'te',
      labels: {
        [AppLanguage.English]: 'Telugu',
        [AppLanguage.Spanish]: 'Telugu',
        [AppLanguage.German]: 'Telugu'
      }
    }
  ],
  [
    'tg',
    {
      code: 'tg',
      labels: {
        [AppLanguage.English]: 'Tajik',
        [AppLanguage.Spanish]: 'Tajik',
        [AppLanguage.German]: 'Tadschikisch'
      }
    }
  ],
  [
    'th',
    {
      code: 'th',
      labels: {
        [AppLanguage.English]: 'Thai',
        [AppLanguage.Spanish]: 'Thai',
        [AppLanguage.German]: 'Thai'
      }
    }
  ],
  [
    'ti',
    {
      code: 'ti',
      labels: {
        [AppLanguage.English]: 'Tigrinya',
        [AppLanguage.Spanish]: 'Tigrinya',
        [AppLanguage.German]: 'Tigrinya'
      }
    }
  ],
  [
    'tk',
    {
      code: 'tk',
      labels: {
        [AppLanguage.English]: 'Turkmen',
        [AppLanguage.Spanish]: 'Turkmen',
        [AppLanguage.German]: 'Turkmenisch'
      }
    }
  ],
  [
    'tl',
    {
      code: 'tl',
      labels: {
        [AppLanguage.English]: 'Tagalog',
        [AppLanguage.Spanish]: 'Tagalog',
        [AppLanguage.German]: 'Tagalog'
      }
    }
  ],
  [
    'tn',
    {
      code: 'tn',
      labels: {
        [AppLanguage.English]: 'Tswana',
        [AppLanguage.Spanish]: 'Tswana',
        [AppLanguage.German]: 'Setswana'
      }
    }
  ],
  [
    'to',
    {
      code: 'to',
      labels: {
        [AppLanguage.English]: 'Tonga',
        [AppLanguage.Spanish]: 'Tonga',
        [AppLanguage.German]: 'Tongaisch'
      }
    }
  ],
  [
    'tr',
    {
      code: 'tr',
      labels: {
        [AppLanguage.English]: 'Türkçe',
        [AppLanguage.Spanish]: 'Türkçe',
        [AppLanguage.German]: 'Türkçe'
      }
    }
  ],
  [
    'ts',
    {
      code: 'ts',
      labels: {
        [AppLanguage.English]: 'Tsonga',
        [AppLanguage.Spanish]: 'Tsonga',
        [AppLanguage.German]: 'Xitsonga'
      }
    }
  ],
  [
    'tt',
    {
      code: 'tt',
      labels: {
        [AppLanguage.English]: 'Tatar',
        [AppLanguage.Spanish]: 'Tatar',
        [AppLanguage.German]: 'Tatarisch'
      }
    }
  ],
  [
    'tw',
    {
      code: 'tw',
      labels: {
        [AppLanguage.English]: 'Twi',
        [AppLanguage.Spanish]: 'Twi',
        [AppLanguage.German]: 'Twi'
      }
    }
  ],
  [
    'ty',
    {
      code: 'ty',
      labels: {
        [AppLanguage.English]: 'Tahitian',
        [AppLanguage.Spanish]: 'Tahitian',
        [AppLanguage.German]: 'Tahitianisch'
      }
    }
  ],
  [
    'ug',
    {
      code: 'ug',
      labels: {
        [AppLanguage.English]: 'Uighur; Uyghur',
        [AppLanguage.Spanish]: 'Uighur; Uyghur',
        [AppLanguage.German]: 'Uigurisch'
      }
    }
  ],
  [
    'uk',
    {
      code: 'uk',
      labels: {
        [AppLanguage.English]: 'Ukrainian',
        [AppLanguage.Spanish]: 'Ukrainian',
        [AppLanguage.German]: 'Ukrainisch'
      }
    }
  ],
  [
    'ur',
    {
      code: 'ur',
      labels: {
        [AppLanguage.English]: 'Urdu',
        [AppLanguage.Spanish]: 'Urdu',
        [AppLanguage.German]: 'Urdu'
      }
    }
  ],
  [
    'uz',
    {
      code: 'uz',
      labels: {
        [AppLanguage.English]: 'Uzbek',
        [AppLanguage.Spanish]: 'Uzbek',
        [AppLanguage.German]: 'Usbekisch'
      }
    }
  ],
  [
    've',
    {
      code: 've',
      labels: {
        [AppLanguage.English]: 'Venda',
        [AppLanguage.Spanish]: 'Venda',
        [AppLanguage.German]: 'Tshivenda'
      }
    }
  ],
  [
    'vi',
    {
      code: 'vi',
      labels: {
        [AppLanguage.English]: 'Viet[AppLanguage.English]se',
        [AppLanguage.Spanish]: 'Viet[AppLanguage.English]se',
        [AppLanguage.German]: 'Vietnamesisch'
      }
    }
  ],
  [
    'vo',
    {
      code: 'vo',
      labels: {
        [AppLanguage.English]: 'Volapük',
        [AppLanguage.Spanish]: 'Volapük',
        [AppLanguage.German]: 'Volapük'
      }
    }
  ],
  [
    'wa',
    {
      code: 'wa',
      labels: {
        [AppLanguage.English]: 'Walloon',
        [AppLanguage.Spanish]: 'Walloon',
        [AppLanguage.German]: 'Wallonisch'
      }
    }
  ],
  [
    'wo',
    {
      code: 'wo',
      labels: {
        [AppLanguage.English]: 'Wolof',
        [AppLanguage.Spanish]: 'Wolof',
        [AppLanguage.German]: 'Wolof'
      }
    }
  ],
  [
    'xh',
    {
      code: 'xh',
      labels: {
        [AppLanguage.English]: 'Xhosa',
        [AppLanguage.Spanish]: 'Xhosa',
        [AppLanguage.German]: 'isiXhosa'
      }
    }
  ],
  [
    'yi',
    {
      code: 'yi',
      labels: {
        [AppLanguage.English]: 'Yiddish',
        [AppLanguage.Spanish]: 'Yiddish',
        [AppLanguage.German]: 'Jiddisch'
      }
    }
  ],
  [
    'yo',
    {
      code: 'yo',
      labels: {
        [AppLanguage.English]: 'Yoruba',
        [AppLanguage.Spanish]: 'Yoruba',
        [AppLanguage.German]: 'Yoruba'
      }
    }
  ],
  [
    'za',
    {
      code: 'za',
      labels: {
        [AppLanguage.English]: 'Zhuang; Chuang',
        [AppLanguage.Spanish]: 'Zhuang; Chuang',
        [AppLanguage.German]: 'Zhuang'
      }
    }
  ],
  [
    'zh',
    {
      code: 'zh',
      labels: {
        [AppLanguage.English]: '中文 (简体)',
        [AppLanguage.Spanish]: '中文 (简体)',
        [AppLanguage.German]: '中文 (简体)'
      }
    }
  ],
  [
    'zu',
    {
      code: 'zu',
      labels: {
        [AppLanguage.English]: 'Zulu',
        [AppLanguage.Spanish]: 'Zulu',
        [AppLanguage.German]: 'isiZulu'
      }
    }
  ]
]);
