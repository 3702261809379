import Toast from 'components/toasts/Toast';
import { getErrorCodeFromAxiosError } from 'features/teamInvitation/getErrorCodeFromAxiosError';
import { BaseSuccessDto } from 'services/backofficeIntegration/http/BaseSuccessDto';
import { backofficeClient } from 'services/backofficeIntegration/http/client/createBackofficeClient';
import { mutationEndpoint } from 'services/backofficeIntegration/http/endpointDefinition';

type VerificationResultDto = {
  magicLinkToken: string;
};

type SuccessDto = BaseSuccessDto<VerificationResultDto>;

export const httpVerifyAccount = mutationEndpoint({
  callEndpoint: (token: string) => {
    return backofficeClient
      .post<SuccessDto>('/customer/verify', { token })
      .then(res => {
        return res.data.data;
      })
      .catch(err => {
        const errorCode = getErrorCodeFromAxiosError(err);
        if (errorCode) {
          Toast.error(`customer.login.error.${errorCode}`);
        }
      });
  }
});
