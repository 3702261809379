import { Drawer } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import cx from 'classnames';
import { useAiWriterExpandedSidebarStore } from 'features/aiWriter/AiWriterSidebar/sidebar/aiWriterExpandedSidebarStore';
import { getInspirationsCurrentStep } from 'features/aiWriter/store/selectors';
import { ReactNode, useEffect } from 'react';
import { useAppSelector } from 'store/hooks';
import useWindowSize from 'utils/hooks/useWindowSize';

export const DRAWER_BUTTONS_WIDTH = 64;
const DRAWER_OPEN_WIDTH = 520;
const DRAWER_EXPANDED_WIDTH = 748;

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      marginRight: '-1rem',
      marginTop: '-1rem'
    },
    paper: {
      position: 'relative',
      zIndex: 99,
      border: 'none',
      transition: 'width 0.2s ease-in-out',
      backgroundColor: 'transparent'
    },
    drawer: {
      width: DRAWER_OPEN_WIDTH - DRAWER_BUTTONS_WIDTH,
      overflow: 'hidden',

      '&.withButtons': {
        width: DRAWER_OPEN_WIDTH
      }
    },
    drawerClosed: {
      width: 0,

      '&.withButtons': {
        width: DRAWER_BUTTONS_WIDTH
      }
    },
    drawerExpanded: {
      width: DRAWER_EXPANDED_WIDTH - DRAWER_BUTTONS_WIDTH,

      '&.withButtons': {
        width: DRAWER_EXPANDED_WIDTH
      }
    }
  })
);

type ChildrenProps = {
  isExpanded: boolean;
  onExpandClick: (expanded: boolean) => void;
};

type Props = {
  withButtons: boolean;
  children(props: ChildrenProps): ReactNode;
};

const EXPANDED_SIDEBAR_THRESHOLD = 1280;

export const AiWriterSidebarDrawer = ({ withButtons, children }: Props) => {
  const { width } = useWindowSize();
  const isOpenByDefault = width && width >= EXPANDED_SIDEBAR_THRESHOLD;
  const isSidebarExpanded = useAiWriterExpandedSidebarStore(state => state.isSidebarExpanded);
  const setIsSidebarExpanded = useAiWriterExpandedSidebarStore(state => state.setIsSidebarExpanded);
  const classes = useStyles();

  const inspirationsCurrentStep = useAppSelector(getInspirationsCurrentStep);

  const isSidebarOpen = !!inspirationsCurrentStep;

  useEffect(() => {
    setIsSidebarExpanded(!!isOpenByDefault);
  }, [isOpenByDefault, isSidebarOpen, setIsSidebarExpanded]);

  return (
    <Drawer
      classes={{
        root: classes.root,
        paper: cx(classes.paper, classes.drawer, {
          [classes.drawerClosed]: !isSidebarOpen,
          [classes.drawerExpanded]: isSidebarOpen && isSidebarExpanded,
          withButtons: withButtons
        })
      }}
      open={isSidebarOpen}
      variant="permanent"
      anchor="right"
    >
      {children({
        isExpanded: isSidebarExpanded,
        onExpandClick: expanded => setIsSidebarExpanded(expanded)
      })}
    </Drawer>
  );
};
