import { Tooltip, Typography } from '@mui/material';
import { SmallerDisabledCircularProgress } from 'features/aiWriter/AiWriterSidebar/steps/flashScore/progress-circles';
import FormattedMessage from 'features/i18n/FormattedMessage';
import styled from 'styled-components';

export const DisabledProgressCircle = () => (
  <Tooltip title={<FormattedMessage id="flashScore.tooltip.unsupported_language" />}>
    <ProgressIconWrapper>
      <SmallerDisabledCircularProgress value={0} label={<Typography fontSize={9}>0</Typography>} />
    </ProgressIconWrapper>
  </Tooltip>
);

const ProgressIconWrapper = styled.div`
  display: grid;
  place-items: center;
`;
