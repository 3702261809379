import { AddIconButton } from 'components/iconButtons/AddIconButton';
import DeleteIconButton from 'components/iconButtons/DeleteIconButton';
import TextTile from 'components/TextTile';
import { removeSubSuggestion } from 'features/aiTester/store/actions/subSuggestions/actions';
import { selectSubSuggestionThunk } from 'features/aiTester/store/actions/subSuggestions/thunks/selectSubSuggestionThunk';
import { ContentSource } from 'features/aiTester/store/actions/subSuggestions/types';
import { getIsTextLimitAchieved } from 'features/aiTester/store/selectors';
import { forwardRef, PropsWithChildren, useCallback } from 'react';
import gtmIds from 'services/tracking/GTMIds';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import styled from 'styled-components';

type Props = {
  parentId: string;
  id: string;
  text: string;
  source: ContentSource;
  textItemId?: string;
  generationId?: number;
};

const TesterSubTile = forwardRef<HTMLDivElement | null, PropsWithChildren<Props>>(
  ({ parentId, id, text, textItemId, generationId, source, children }, ref) => {
    const isTextLimitAchieved = useAppSelector(getIsTextLimitAchieved);
    const dispatch = useAppDispatch();

    const subTileActions = useCallback(
      () => (
        <>
          <DeleteIconButton
            id={gtmIds.tester.deleteSuggestion}
            tooltip="Delete"
            circle
            onClick={() =>
              dispatch(
                removeSubSuggestion({
                  id,
                  parentId,
                  source,
                  triggerSource: 'user'
                })
              )
            }
          />
          {!isTextLimitAchieved && (
            <AddIconButton
              id={gtmIds.tester.selectSuggestion_plusButton}
              tooltip="Add to my list"
              onClick={() =>
                dispatch(
                  selectSubSuggestionThunk({
                    id,
                    parentId,
                    text,
                    textItemId,
                    generationId,
                    source,
                    triggerSource: 'user'
                  })
                )
              }
            />
          )}
        </>
      ),
      [isTextLimitAchieved, dispatch, id, parentId, source, text, textItemId, generationId]
    );

    return (
      <Root>
        <TextTile
          ref={ref}
          isDisabled={isTextLimitAchieved}
          actions={subTileActions()}
          onClick={() =>
            dispatch(
              selectSubSuggestionThunk({
                id,
                parentId,
                text,
                textItemId,
                generationId,
                source,
                triggerSource: 'user'
              })
            )
          }
        >
          {children}
        </TextTile>
      </Root>
    );
  }
);

const Root = styled.div`
  margin-left: ${({ theme }) => theme.spacings.xmedium};
`;

export default TesterSubTile;
