import { css } from 'styled-components';

export const socialButtonStyles = css`
  font-weight: normal;
  text-transform: none;
  color: ${({ theme }) => theme.colors.textSecondary};
  border: 1px solid ${({ theme }) => theme.colors.componentsInputfieldOutlinedEnabledborder};
  border-radius: ${({ theme }) => theme.borderRadius.one};
  width: 100%;
  min-height: ${({ theme }) => theme.spacings.eight};
  padding: ${({ theme }) => theme.spacings.two} 0;

  .MuiButton-startIcon {
    margin-right: ${({ theme }) => theme.spacings.two};
  }
`;
