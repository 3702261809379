import { IconButton, Tooltip } from '@mui/material';
import magicFeatherPrimary from 'assets/icons/ai-writer-sidebar/icon-ai-writer-primary-selected.svg';
import CopyButton from 'components/iconButtons/CopyButton';
import DeleteIconButton from 'components/iconButtons/DeleteIconButton';
import { EditIconButton } from 'components/iconButtons/EditIconButton';
import { RefreshIconButton } from 'components/iconButtons/RefreshIconButton';
import TextTile from 'components/TextTile';
import { improveGoalThunk } from 'features/aiTester/store/actions/suggestions/thunks/improveGoalThunk';
import { refreshSuggestionsThunk } from 'features/aiTester/store/actions/suggestions/thunks/refreshSuggestionsThunk';
import { setIsTextEditing } from 'features/aiTester/store/actions/texts/actions';
import { removeTextThunk } from 'features/aiTester/store/actions/texts/thunks/removeTextThunk';
import { updateTextThunk } from 'features/aiTester/store/actions/texts/thunks/updateTextThunk';
import {
  getGetTokenData,
  getIsGenerateConfigValid,
  getTesterActiveTab
} from 'features/aiTester/store/selectors';
import { TestingContent } from 'features/aiTester/store/types';
import WordTooltip from 'features/aiTester/TesterContentField/WordTooltip';
import { trackingWrapper } from 'features/tracking/wrapper/TrackingWrapper';
import { upperFirst } from 'lodash';
import { Fragment, ReactElement, ReactNode, useCallback, useMemo } from 'react';
import gtmIds from 'services/tracking/GTMIds';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import useTr from 'utils/hooks/useTr';

type Props = {
  value: TestingContent;
};

const ContentFieldReadonly = ({
  value: { id, text, textItemId, generationId, words, isLoading }
}: Props): ReactElement => {
  const tr = useTr();
  const dispatch = useAppDispatch();

  const tokenData = useAppSelector(getGetTokenData)(id);
  const isGenerateConfigReady = useAppSelector(getIsGenerateConfigValid);
  const currentTab = useAppSelector(getTesterActiveTab);

  const replaceWord = useCallback(
    async (synonym: string, indexStart: number, indexEnd: number) => {
      const newText = text.substring(0, indexStart) + synonym + text.substring(indexEnd);
      dispatch(updateTextThunk({ id, text: newText }));
    },
    [id, text, dispatch]
  );

  const getSynonymsString = useCallback(
    (synonyms: string[], isUpperFirst: boolean) =>
      synonyms
        // Find all synonyms in the tokenData and list only those that have a goal_score
        .map<[string, number | undefined]>(synonym => [
          synonym,
          tokenData[synonym]?.find(i => i.id === 'goal_score')?.value
        ])
        // Remove synonyms that don't have a goal_score
        .filter((tuple): tuple is [string, number] => typeof tuple[1] !== 'undefined')
        // Sort by goal_score
        .sort(([, valueA], [, valueB]) => valueB - valueA)
        // Take the top 10
        .slice(0, 10)
        // Format to upper first if needed
        .map(([synonym]) => (isUpperFirst ? upperFirst(synonym) : synonym)),
    [tokenData]
  );

  const content = useMemo(() => {
    const newText: ReactNode[] = [];

    words
      .filter(i => i.token)
      .forEach(tokenDetails => {
        newText.push(
          <WordTooltip
            key={tokenDetails.id}
            textId={id}
            wordId={tokenDetails.id}
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            token={tokenDetails.token!}
            isFaulty={tokenDetails.status === 'FAULTY'}
            word={tokenDetails.word}
            isLoading={tokenDetails.isLoading}
            synonyms={getSynonymsString(tokenDetails.synonyms, tokenDetails.indexStart === 0)}
            onSynonymClick={synonym =>
              replaceWord(synonym, tokenDetails.indexStart, tokenDetails.indexEnd)
            }
          />
        );

        newText.push(<Fragment key={`fragment_${tokenDetails.id}`}>&nbsp;</Fragment>);
      }, []);

    return newText;
  }, [words, id, getSynonymsString, replaceWord]);

  const handleCopyButtonClick = () => {
    trackingWrapper.track('aiTesterCopyText', {
      documentId: currentTab.id,
      text,
      textItemId,
      generationId,
      embeddingModelId: currentTab.embeddingModelId
    });
  };

  const actions = (
    <>
      <DeleteIconButton
        id={gtmIds.tester.deleteSelection}
        size="small"
        tooltip={tr('common.delete')}
        circle={true}
        onClick={() => dispatch(removeTextThunk({ id, source: 'texts' }))}
      />
      {isGenerateConfigReady && (
        <RefreshIconButton
          id={gtmIds.tester.rewriteSelection}
          size="small"
          tooltip={tr('testing.actions.refresh')}
          onClick={() => dispatch(refreshSuggestionsThunk({ id, source: 'texts' }))}
        />
      )}
      <Tooltip title={tr('common.improve')}>
        <IconButton
          id={gtmIds.tester.optimizeGoal}
          size="small"
          onClick={() => dispatch(improveGoalThunk({ textId: id }))}
        >
          <img src={magicFeatherPrimary} />
        </IconButton>
      </Tooltip>
      <CopyButton
        id={gtmIds.tester.copySelection}
        size="small"
        tooltip={tr('common.copy')}
        text={text}
        onClick={handleCopyButtonClick}
      />
      <EditIconButton
        size="small"
        tooltip={tr('common.edit')}
        onClick={() => dispatch(setIsTextEditing({ id, isEditing: true }))}
      />
    </>
  );

  return (
    <TextTile hoverActions={actions} isLoading={isLoading}>
      {words?.length ? content : text}
    </TextTile>
  );
};

export default ContentFieldReadonly;
