import useGtmDataLayerAttributes from 'features/services/hooks/useGtmDataLayerAttributes';
import { useEffect } from 'react';

/**
 * Sends the initial tracking data to the dataLayer.
 *
 * Note: Tracking itself is initialized in public/index.html
 */
export default function useSendInitialTrackingData() {
  const initialDataLayerAttributes = useGtmDataLayerAttributes();

  useEffect(() => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push(initialDataLayerAttributes);
  }, [initialDataLayerAttributes]);
}
