import { InfoOutlined } from '@mui/icons-material';
import { Alert, AlertTitle, styled } from '@mui/material';
import { ColoredTypography } from 'components/ColoredTypography';
import { FormattedMessage } from 'react-intl';

type Props = {
  title: string;
};

export const PricingRestrictionHint = ({ title }: Props) => {
  return (
    <Alert severity="info" variant="filled" icon={<WhiteInfoIcon />}>
      <AlertTitle>
        <ColoredTypography color="commonWhiteMain">
          <FormattedMessage
            id={title}
            values={{ b: (chunks: string) => <b>{chunks}</b> }}
          />
        </ColoredTypography>
      </AlertTitle>
    </Alert>
  );
};

const WhiteInfoIcon = styled(InfoOutlined)`
  color: ${({ theme }) => theme.palette.common.white};
`;