import { AppThunk } from 'store/store';

import { updateTesterProjectInBackgroundThunk } from '../../project/thunks/updateTesterProjectInBackgroundThunk';
import { rescoreSuggestionsThunk } from '../../suggestions/thunks/rescoreSuggestionsThunk';
import { scoreTextsThunk } from '../../texts/thunks/scoreTextsThunk';
import { changeGoalConfig } from '../actions';
import { ChangeGoalConfigPayload } from '../types';

export const changeGoalConfigThunk =
  (payload: ChangeGoalConfigPayload): AppThunk<void> =>
  async dispatch => {
    dispatch(changeGoalConfig(payload));
    dispatch(rescoreSuggestionsThunk());
    dispatch(scoreTextsThunk());
    dispatch(updateTesterProjectInBackgroundThunk());
  };
