import Bowser from 'bowser';
import { getUserEmail, getUserId } from 'features/customer/store/selectors';
import { retrieveRequestIdHeader } from 'services/backofficeIntegration/http/client/useRequestIdHeaderStore';
import { useAppSelector } from 'store/hooks';

export const useGetBugReportLinkParameters = () => {
  const customerId = useAppSelector(getUserId);
  const customerEmail = useAppSelector(getUserEmail);

  const requestId = retrieveRequestIdHeader() ?? '';
  const browser = Bowser.getParser(window.navigator.userAgent);

  const environmentInfo = `${browser.getBrowserName()} ${browser.getBrowserVersion()} - ${browser.getOSName()} ${browser.getOSVersion()}`;

  const currentPage = window.location.pathname + window.location.search + window.location.hash;

  return {
    requestId,
    customerId,
    customerEmail: encodeURIComponent(customerEmail),
    environmentInfo,
    userAgent: window.navigator.userAgent,
    currentPage
  };
};
