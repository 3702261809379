import { getIsAuthenticated } from 'features/customer/store/selectors';
import { getDimensions } from 'features/dimensions/store/actions';
import { getCurrentDimension } from 'features/dimensions/store/selectors';
import { dimensionsRowToList } from 'features/dimensions/store/utils/dimensionsRowToList';
import { ErrorBoundary } from 'features/react-error-boundary/ErrorBoundary';
import { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { RootState } from 'store/rootReducer';

import Button from '../../../base/Button';
import { useModal } from '../../../modals';
import { toggleDimensionsWidget } from '../toolbarSlice';
import WidgetHeader from '../WidgetHeader';
import WidgetWrapper from '../WidgetWrapper';
import WordsManager from '../WordsManager/WordsManager';
import DimensionsDropdown from './DimensionsDropdown';

const mapStateToProps = (state: RootState) => ({
  isAuthenticated: getIsAuthenticated(state),
  currentDimension: getCurrentDimension(state),
  isLoading: state.dimensions.isLoading
});

const mapDispatchToProps = {
  closeWidget: () => toggleDimensionsWidget(false),
  getDimensions: () => getDimensions.request()
};

type Props = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps;

const DimensionsPanel = ({
  currentDimension,
  getDimensions,
  isAuthenticated,
  ...widgetHeaderProps
}: Props) => {
  const { showModal } = useModal();

  useEffect(() => {
    if (isAuthenticated) getDimensions();
  }, [isAuthenticated, getDimensions]);

  return (
    <WidgetWrapper key="dimensions">
      <ErrorBoundary>
        <WidgetHeader
          title="dimensions.header"
          dropdown={DimensionsDropdown}
          {...widgetHeaderProps}
        >
          <Button
            color="primary"
            icon="plus"
            block
            onClick={() => showModal('ADD_DIMENSION_WITH_WORDS')}
          >
            <FormattedMessage id="dimensions.add_new_dimension" />
          </Button>
        </WidgetHeader>
        <WordsManager
          currentList={dimensionsRowToList(currentDimension)}
          addWordsModalId="ADD_WORDS_TO_DIMENSION"
          removeWordsModalId="REMOVE_DIMENSION_WORDS"
        />
      </ErrorBoundary>
    </WidgetWrapper>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(DimensionsPanel);
