import Cookies from 'js-cookie';
import { useMemo } from 'react';
import { newThemeConfig } from 'styles/new-theme/newThemeConfig';
import { newThemeConfigDarkMode } from 'styles/new-theme/newThemeConfigDarkMode';
import { create } from 'zustand';
import { createJSONStorage, persist, StateStorage } from 'zustand/middleware';

export type ThemeMode = 'dark' | 'light';

type AppThemeStoreType = {
  themeMode: ThemeMode;
};

type AppThemeStoreActions = {
  setThemeMode: (themeMode: ThemeMode) => void;
};

const initialState: AppThemeStoreType = {
  themeMode: 'light'
};

const cookiesStorage: StateStorage = {
  getItem: (name: string) => {
    return Cookies.get(name) ?? null;
  },
  setItem: (name: string, value: string) => {
    Cookies.set(name, value, { expires: 1 });
  },
  removeItem: (name: string) => {
    Cookies.remove(name);
  }
};

export const useAppThemeStore = create<AppThemeStoreType & AppThemeStoreActions>()(
  persist(
    set => ({
      ...initialState,
      setThemeMode: themeMode => {
        set({ themeMode });
      }
    }),
    {
      name: 'app-theme-store',
      version: 3,
      storage: createJSONStorage(() => cookiesStorage)
    }
  )
);

export const useIsDarkMode = () => useAppThemeStore(state => state.themeMode) === 'dark';

export const useGetThemeConfig = () => {
  const themeMode = useAppThemeStore(state => state.themeMode);

  return useMemo(
    () => (themeMode === 'dark' ? newThemeConfigDarkMode : newThemeConfig),
    [themeMode]
  );
};
