import { ActionDialog } from 'features/profilePage/ActionDialog';
import { FormattedMessage } from 'react-intl';

type Props = {
  onCancel: () => void;
  onConfirm: () => void;
  isDialogOpen: boolean;
};

export const RemoveUserDialog = ({ isDialogOpen, onCancel, onConfirm }: Props) => {
  return (
    <ActionDialog
      title={<FormattedMessage id="profile.users.users_list.remove_user.dialog.title" />}
      content={
        <FormattedMessage
          id="profile.users.users_list.remove_user.dialog.content"
          values={{ br: <br /> }}
        />
      }
      confirmButtonText={<FormattedMessage id="common.continue" />}
      isDialogOpen={isDialogOpen}
      onCancel={onCancel}
      onConfirm={onConfirm}
      confirmButtonColor="primary"
    />
  );
};
