import { LinearProgress } from '@mui/material';
import { InView, PlainChildrenProps } from 'react-intersection-observer';

type Props = Omit<PlainChildrenProps, 'children' | 'ref'> & {
  enabled: boolean | undefined;
  isAlreadyFetching: boolean;
  onIsInView: () => void;
};

export function InfiniteDropdownIntersectionObserver({
  enabled,
  isAlreadyFetching,
  onIsInView,
  onChange,
  as = 'div',
  threshold = 1,
  ...props
}: Props) {
  return (
    <InView
      as={as}
      threshold={threshold}
      onChange={(inView, entry) => {
        if (!inView || !enabled || isAlreadyFetching) {
          return;
        }
        onIsInView();
        onChange?.(inView, entry);
      }}
      {...props}
    >
      {isAlreadyFetching && <LinearProgress />}
    </InView>
  );
}
