import seoLogoImage from 'assets/seo-logo.svg';
import { ColoredTypography } from 'components/ColoredTypography';
import FlexContainer from 'components/FlexContainer';
import ModalTitle from 'components/modals/ModalTitle';
import { CloseModal } from 'components/modals/types';
import FormattedMessage from 'features/i18n/FormattedMessage';
import styled from 'styled-components';

type Props = CloseModal;

export const SeoModal = ({ closeModal }: Props) => {
  const Title = () => (
    <ColoredTypography variant="h5" color="blackHighEmphasis">
      <FormattedMessage id="aiWriter.inspirations.seoAnalysis.modal.title" />
    </ColoredTypography>
  );

  return (
    <Root>
      <FlexContainer gap="xmedium">
        <ModalTitle title={<Title />} onCancel={closeModal} />

        <img src={seoLogoImage} alt="SEO" />

        <ColoredTypography variant="body1">
          <FormattedMessage id="aiWriter.inspirations.seoAnalysis.modal.body" />
        </ColoredTypography>
      </FlexContainer>
    </Root>
  );
};

const Root = styled(FlexContainer)`
  padding: ${({ theme }) => `${theme.spacings.medium} ${theme.spacings.xmedium}`};
`;
