import { BaseOutput } from 'features/aiWriter/slashCommands/BaseOutput';
import { FlashActionsInputProps } from 'features/aiWriter/slashCommands/FlashActionsInput';
import { useFlashActionsSelectedText } from 'features/aiWriter/slashCommands/hooks/useFlashActionsStore';
import { getCurrentModelLanguageAndCountry } from 'features/aiWriter/store/selectors';
import { GAEvents } from 'services/tracking/GAEvents';
import { useAppSelector } from 'store/hooks';

const minAmountOfCharactersToBeUsedAsContext = 10;
const maxAmountOfCharactersToBeUsedAsContext = 18000;

type Props = FlashActionsInputProps;

const OUTPUT_TYPE = 'improve';

export function ImproveOutput(props: Props) {
  const flashActionsSelectedText = useFlashActionsSelectedText();

  const { currentModelCountry: country, currentModelLanguage: language } = useAppSelector(
    getCurrentModelLanguageAndCountry
  );

  const generateConfig = {
    outputType: OUTPUT_TYPE,
    // Improve is only available for selected text.
    text: flashActionsSelectedText
  };

  const onGenerate = () => {
    GAEvents.flashActionExecuted({
      action: 'improve',
      language,
      country,
      isInline: true
    });
  };

  return (
    <BaseOutput
      generateConfig={generateConfig}
      onGenerate={onGenerate}
      onClose={props.onClose}
      maxAmountOfCharactersToBeUsedAsContext={maxAmountOfCharactersToBeUsedAsContext}
      minAmountOfCharactersToBeUsedAsContext={minAmountOfCharactersToBeUsedAsContext}
    />
  );
}
