
type Props = {
  transform: string;
  direction: 'right' | 'left';
  scale: number;
};

const TriangleSwitch = ({ transform, direction, scale }: Props) => {
  const pointsLeft = '0,10, 10,0, 10,20';
  const pointsRight = '10,10, 0,20, 0,0';

  return (
    <g id={`palette-switch-${direction}`} transform={`scale(${scale})`}>
      <polygon
        cursor="pointer"
        className="no-export"
        points={direction === 'right' ? pointsRight : pointsLeft}
        fill="#495057"
        transform={transform}
      />
    </g>
  );
};

export default TriangleSwitch;
