import { css } from 'styled-components';

export const resetButton = css`
  background: none;
  border: none;
  outline: none;
  box-shadow: none;
  cursor: pointer;
`;

export const resetLink = css`
  color: inherit; /* blue colors for links too */
  text-decoration: inherit; /* no underline */

  &:hover {
    color: inherit; /* blue colors for links too */
    text-decoration: inherit; /* no underline */
  }
`;

export const resetUl = css`
  list-style: none;
  padding: 0;
  margin: 0;
`;
