import { PricingPlan } from 'features/pricing/types';

export const historyLimit: Record<PricingPlan, number> = {
  [PricingPlan.free]: 10,
  [PricingPlan.basic]: 10,
  [PricingPlan.power]: 100,
  [PricingPlan.powerLower]: 100,
  [PricingPlan.powerHigher]: 100,
  [PricingPlan.powerVip]: 100,
  [PricingPlan.premium]: 100,
  [PricingPlan.enterprise]: 100,
  [PricingPlan.pro]: 100,
  [PricingPlan.business]: 100,
  [PricingPlan.standard]: 10,
  [PricingPlan.starter]: 10,
  [PricingPlan.ignore]: 0
};

const getHistoryLimit = (pricingPlan: PricingPlan): number => historyLimit[pricingPlan];

export default getHistoryLimit;
