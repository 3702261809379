import { CircularProgress, InputAdornment, TextField } from '@mui/material';
import DeleteIconButton from 'components/iconButtons/DeleteIconButton';
import { removeTextThunk } from 'features/aiTester/store/actions/texts/thunks/removeTextThunk';
import { updateTextThunk } from 'features/aiTester/store/actions/texts/thunks/updateTextThunk';
import { TestingContent } from 'features/aiTester/store/types';
import React, { useCallback, useState } from 'react';
import { useAppDispatch } from 'store/hooks';
import useTr from 'utils/hooks/useTr';

type Props = {
  value: TestingContent;
};

const ContentFieldEdit = ({ value: { id, text, isLoading } }: Props) => {
  const tr = useTr();
  const dispatch = useAppDispatch();

  const [inputValue, setInputValue] = useState(text);

  const onSave = useCallback(async () => {
    if (inputValue) {
      dispatch(updateTextThunk({ id, text: inputValue }));
    }
  }, [id, inputValue, dispatch]);

  const onKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLDivElement>) => {
      if (e.key === 'Enter' && !e.shiftKey) {
        e.preventDefault();
        onSave();
      }
    },
    [onSave]
  );

  return (
    <TextField
      autoFocus
      multiline
      variant="outlined"
      size="small"
      margin="dense"
      fullWidth
      value={inputValue}
      onChange={e => setInputValue(e.target.value)}
      onBlur={onSave}
      onKeyDown={onKeyDown}
      InputProps={{
        endAdornment: (
          <InputAdornment position="start">
            {isLoading ? (
              <CircularProgress size={24} />
            ) : (
              <DeleteIconButton
                tooltip={tr('common.delete')}
                onClick={() => dispatch(removeTextThunk({ id }))}
              />
            )}
          </InputAdornment>
        )
      }}
    />
  );
};

export default ContentFieldEdit;
