import { TesterState } from '../../types';

export const defaultTesterState: TesterState = {
  currentTab: '',
  tabs: {},
  projects: [],
  isLoading: false,
  isViewExtended: false,
  isTourRunning: false,
  isTourFinished: false
};
