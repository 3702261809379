import { CircularProgress, IconButton as MUIIconButton, Tooltip } from '@mui/material';
import { forwardRef, MouseEvent, ReactNode } from 'react';
import { withGtmInteraction } from 'services/tracking/withGtmInteraction';

export type IconButtonSize = 'small' | 'medium' | 'large';

type Props = {
  id?: string;
  gtmId?: string;
  className?: string;
  icon: ReactNode;
  tooltip?: ReactNode;
  size?: IconButtonSize;
  disabled?: boolean;
  isLoading?: boolean;

  onClick: (event: MouseEvent<HTMLElement>) => void;
};

export const IconButton = forwardRef<HTMLSpanElement, Props>(
  ({ id, gtmId, className, icon, tooltip, size, disabled, isLoading, onClick }, ref) => {
    const content = (
      <span ref={ref}>
        <MUIIconButton
          id={id} //Deprecated way of gtm tag usage #tech-debt: https://app.clickup.com/t/862je7864
          {...withGtmInteraction(gtmId)}
          className={className}
          size={size}
          disabled={disabled}
          onClick={e => {
            e.stopPropagation();
            onClick(e);
          }}
        >
          {isLoading ? <CircularProgress size={size} /> : icon}
        </MUIIconButton>
      </span>
    );

    return tooltip ? <Tooltip title={tooltip}>{content}</Tooltip> : content;
  }
);
