import CountryFlag from 'components/base/CountryFlag';
import Icon from 'components/base/Icon';
import FlexContainer from 'components/FlexContainer';
import useModal from 'components/modals/useModal';
import Select from 'components/selects/Select';
import Tooltip from 'components/tooltips/Tooltip';
import { selectDimension } from 'features/dimensions/store/actions';
import { getCurrentDimension, getDimensionsArray } from 'features/dimensions/store/selectors';
import { DimensionRow } from 'features/dimensions/store/types';
import { ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import { components, ValueContainerProps } from 'react-select';
import { OptionProps } from 'react-select/dist/declarations/src/components/Option';
import { Dimension } from 'services/api/dimension/types';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { hasOwnProperty } from 'utils/hasOwnProperty';

const OptionWithFlag = ({ children, ...props }: OptionProps<DimensionRow>): ReactElement => {
  const { data } = props;

  if (
    data !== null &&
    data !== undefined &&
    typeof data === 'object' &&
    hasOwnProperty(data, 'language') &&
    typeof data.language === 'string'
  ) {
    return (
      <components.Option {...props}>
        <CountryFlag
          language={data['language']}
          style={{ maxWidth: '15%', marginRight: '0.5rem' }}
        />
        {children}
      </components.Option>
    );
  }

  return <components.Option {...props}>{children}</components.Option>;
};

const ValueWithFlag = ({
  children,
  getValue,
  ...props
}: ValueContainerProps<DimensionRow>): ReactElement => {
  const [option] = getValue();

  if (
    option !== null &&
    option !== undefined &&
    typeof option === 'object' &&
    hasOwnProperty(option, 'language') &&
    typeof option.language === 'string'
  ) {
    return (
      <components.ValueContainer {...props} getValue={getValue}>
        <CountryFlag language={option['language']} style={{ maxWidth: '15%' }} />
        {children}
      </components.ValueContainer>
    );
  }

  return (
    <components.ValueContainer {...props} getValue={getValue}>
      {children}
    </components.ValueContainer>
  );
};

const DimensionsDropdown = () => {
  const dispatch = useAppDispatch();
  const { showModal } = useModal();
  const { isLoading } = useAppSelector(state => state.dimensions);
  const dimensions = useAppSelector(getDimensionsArray);
  const currentDimension = useAppSelector(getCurrentDimension);

  return (
    <FlexContainer direction="row" gap="small" justifyContent="space-between">
      <Select
        key={+isLoading}
        isLoading={isLoading}
        options={dimensions}
        getOptionValue={value => `${value.id}`}
        groupKey="project"
        components={{
          Option: OptionWithFlag,
          ValueContainer: ValueWithFlag
        }}
        styles={{
          singleValue: styles => ({
            ...styles,
            marginLeft: 10
          }),
          valueContainer: styles => ({
            ...styles,
            display: 'flex'
          })
        }}
        onChange={value => {
          if (value) {
            dispatch(selectDimension((value as Dimension).id));
          }
        }}
        value={currentDimension}
      />
      <Tooltip content={<FormattedMessage id="common.remove" />}>
        <Icon
          name="trash-2"
          onClick={() => showModal('REMOVE_DIMENSION')}
          disabled={dimensions.length < 1}
        />
      </Tooltip>
    </FlexContainer>
  );
};

export default DimensionsDropdown;
