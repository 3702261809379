import { Alert, Typography } from '@mui/material';
import backgroundDoodleOrnament from 'assets/background-doodle.png';
import { AuthCenteredPanel } from 'components/auth/AuthCenteredPanel';
import AuthForm from 'components/auth/AuthForm/AuthForm';
import useRedirectWhenAuthenticated from 'components/auth/hooks/useRedirectWhenAuthenticated';
import ForgotPasswordForm from 'components/ForgotPasswordForm';
import { Ornament } from 'components/Ornament';
import { getRoutePath } from 'config/routes';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { useAppSelector } from 'store/hooks';
import styled from 'styled-components';

export const ForgotPasswordPage = (): React.ReactElement => {
  const error = useAppSelector(state => state.customer.error);

  useRedirectWhenAuthenticated();

  return (
    <AuthCenteredPanel slogan="register.slogan">
      <AuthForm
        header="login.forgot_password"
        extra={
          <Extra>
            <Link to={getRoutePath('login')}>
              <FormattedMessage id="register.back_to_login" />
            </Link>
          </Extra>
        }
        ornaments={<Ornament src={backgroundDoodleOrnament} bottom="0%" right="0%" />}
        error={
          error && (
            <Alert severity="error" variant="standardFullWidth">
              <FormattedMessage id={error} />
            </Alert>
          )
        }
      >
        <Typography variant="body1">
          <FormattedMessage id="forgot_password.info" values={{ br: <br /> }} />
        </Typography>

        <ForgotPasswordForm />
      </AuthForm>
    </AuthCenteredPanel>
  );
};

const Extra = styled.div`
  text-align: center;
`;
