import { useQuery } from '@tanstack/react-query';
import { getActiveTab } from 'features/aiWriter/store/selectors';
import { makeCurrentUserQueryKeyPrefix } from 'features/currentUser/currentUserQueries';
import { FlashScoreTextRatingResult } from 'features/flashScore/useScoreTexts';
import { useTokenizeAndScoreProvidedTextMutation } from 'features/flashScore/useTokenizeAndScoreProvidedTextMutation';
import { useAppSelector } from 'store/hooks';

export type FlashScoreWordRating = Omit<FlashScoreTextRatingResult, 'text'> & {
  word: string;
};

type Options = {
  text: string;
  enabled?: boolean;
  cacheKeys?: string[];
};

export const useTokenizeAndScoreProvidedText = (options: Options) => {
  const { embeddingModelId } = useAppSelector(getActiveTab);
  const { mutateAsync } = useTokenizeAndScoreProvidedTextMutation();

  return useQuery<FlashScoreWordRating[]>({
    enabled: !!embeddingModelId && options.text.length > 0 && options?.enabled !== false,
    // Ensure we're not fetching the data every time the component re-renders.
    staleTime: Infinity,
    queryKey: [
      ...makeCurrentUserQueryKeyPrefix(),
      'flashScore',
      'words',
      embeddingModelId,
      ...(options.cacheKeys ?? [])
    ],
    queryFn: () => mutateAsync(options)
  });
};
