import { createPluginFactory, TElement } from '@udecode/plate-common';
import { flashActionsOnKeyDownHandler } from 'features/plate/customPlugins/handlers/flashActionsOnKeyDownHandler';
import { isSelectionInFlashActionsInput } from 'features/plate/customPlugins/queries/isSelectionInFlashActionsInput';
import { flashActionsPlugin } from 'features/plate/customPlugins/types';
import { withSlashCommands } from 'features/plate/customPlugins/withSlashCommands';

export const ELEMENT_FLASH_ACTIONS_INPUT = 'FlashActionsInput';

export interface TFlashActionsInputElement extends TElement {
  trigger: string;
}

export const createFlashActionsPlugin = createPluginFactory<flashActionsPlugin>({
  key: ELEMENT_FLASH_ACTIONS_INPUT,
  isInline: true,
  isElement: true,
  isVoid: true,
  handlers: {
    onKeyDown: flashActionsOnKeyDownHandler({ query: isSelectionInFlashActionsInput })
  },
  withOverrides: withSlashCommands,
  useHooks: (_editor, plugin) => {
    plugin.options.enabled = true;
  },
  options: {
    trigger: ' ',
    triggerPreviousCharPattern: /^$/
  },
  then: (editor, { key }) => ({
    options: {
      id: key
    }
  })
});
