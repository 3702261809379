import { de, enGB, es } from 'date-fns/locale';
import { AppLanguage } from 'features/language/store/types';

export function languageToDateFnsLocaleMapper(language: AppLanguage) {
  if (language === AppLanguage.English) {
    return enGB;
  }

  if (language === AppLanguage.German) {
    return de;
  }

  if (language === AppLanguage.Spanish) {
    return es;
  }
}
