import { Typography } from '@mui/material';
import FlexContainer from 'components/FlexContainer';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

type Props = {
  title: string;
  description?: string;
  isHidden?: boolean;
  extra?: React.ReactNode;
  children: React.ReactElement;
};

const HideableFieldTitle = ({ title, description, isHidden, extra, children }: Props) => {
  if (isHidden) {
    return null;
  }

  return (
    <FlexContainer gap="three">
      <div>
        <FlexContainer direction="row" gap="two">
          <Typography variant="subtitle2">
            <FormattedMessage id={title} />
          </Typography>
          {extra && <Extra>{extra}</Extra>}
        </FlexContainer>
        {description && (
          <Typography variant="body2">
            <FormattedMessage id={description} />
          </Typography>
        )}
      </div>

      {children}
    </FlexContainer>
  );
};

const Extra = styled.div``;

export default HideableFieldTitle;
