import { create } from 'zustand';

export const ViewOptions = {
  ALL_TAGS: 'ALL_TAGS',
  NEW_TAG: 'NEW_TAG',
  TAGS_BY_ME: 'TAGS_BY_ME',
  TAGS_BY_TEAM: 'TAGS_BY_TEAM',
  RECENT_TAGS: 'RECENT_TAGS'
};

type TagsModalViewState = {
  view: string;
};

type TagsModalViewStateActions = {
  setView: (view: string) => void;
};

const initialState: TagsModalViewState = {
  view: ViewOptions.ALL_TAGS
};

export const useTagsModalViewStore = create<TagsModalViewState & TagsModalViewStateActions>(
  set => ({
    ...initialState,
    setView: (view: string) => set({ view })
  })
);
