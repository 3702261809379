import styled from 'styled-components';

interface Props {
  code: number;
  label?: string;
}

const Emoji = ({ code, label }: Props) => (
  <Root role="img" aria-label={label || ''} aria-hidden={label ? 'false' : 'true'}>
    {String.fromCodePoint(code)}
  </Root>
);

const Root = styled.span`
  margin-left: 5px;
`;

export default Emoji;
