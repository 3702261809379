import RestrictedPage from 'components/RestrictedPage';
import { getRoutePath, RestrictedRoute, RouteName } from 'config/routes';
import { getHasToken, getRestrictions } from 'features/customer/store/selectors';
import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAppSelector } from 'store/hooks';

type Props = {
  routeName: RouteName;
  component: React.ComponentType<Record<never, never>>;
};

const AuthRoute = ({ component: Component, routeName }: Props) => {
  const location = useLocation();
  const userHasToken = useAppSelector(getHasToken);
  const restrictions = useAppSelector(getRestrictions);

  if (!userHasToken) {
    return <Navigate to={{ pathname: getRoutePath('login') }} state={{ from: location }} />;
  }

  if (restrictions[routeName as RestrictedRoute]) {
    return <RestrictedPage />;
  }

  return <Component />;
};

export default AuthRoute;
