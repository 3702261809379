import styled from 'styled-components';

const EmptyButton = styled.button<{ unsetPadding?: boolean; unsetColor?: boolean }>`
  border: 0;
  background: none;
  cursor: pointer;
  font: inherit;
  ${({ unsetColor }) => !unsetColor && 'color: inherit;'}
  ${({ unsetPadding }) => !unsetPadding && 'padding: 0;'}
`;

EmptyButton.defaultProps = {
  type: 'button'
};

export default EmptyButton;
