import { makeCurrentUserQueryKeyPrefix } from 'features/currentUser/currentUserQueries';
import { CustomerRole } from 'features/customer/store/types';
import { backofficeClient } from 'services/backofficeIntegration/http/client/createBackofficeClient';
import { queryEndpoint } from 'services/backofficeIntegration/http/endpointDefinition';
import {
  PaginatedListParams,
  PaginationData
} from 'services/backofficeIntegration/http/paginatedListEndpoint';

type PaginatedListSuccessDto<ListItem> = PaginationData & {
  data: {
    usage: ListItem[];
    total_words_count: number;
    words_count: number;
  };
  message: string;
  status: boolean;
};

export type TeamCurrentUsageDto = {
  name: string;
  email: string;
  role: CustomerRole;
  image_url: string;
  words_count: number;
  files_count: number;
  last_active: string;
};

type SuccessDto = PaginatedListSuccessDto<TeamCurrentUsageDto>;

export type GetCurrentTeamUsageParams = PaginatedListParams & {
  teamId: number;
};

export const httpGetCurrentTeamUsage = queryEndpoint({
  makeQueryKey: (params: GetCurrentTeamUsageParams) => {
    return [...makeCurrentUserQueryKeyPrefix(), 'team-current-usage', params] as const;
  },
  callEndpoint: (params: GetCurrentTeamUsageParams) => {
    const { teamId, ...restParams } = params;

    return backofficeClient
      .get<SuccessDto>(`/teams/${teamId}/words/current-usage`, {
        params: restParams
      })
      .then(response => response.data);
  }
});
