import Toast from 'components/toasts/Toast';
import { SharingPermission } from 'features/aiWriter/store/types';
import { getErrorCodeFromAxiosError } from 'features/teamInvitation/getErrorCodeFromAxiosError';
import { BaseSuccessDto } from 'services/backofficeIntegration/http/BaseSuccessDto';
import { backofficeClient } from 'services/backofficeIntegration/http/client/createBackofficeClient';
import { PersonalityDto } from 'services/backofficeIntegration/http/dtos/PersonalityDto';
import { mutationEndpoint } from 'services/backofficeIntegration/http/endpointDefinition';

export type UpdatePersonalityParams = {
  personalityId: number;
  label: string;
  definition: string;
  description: string | null;
  country: string;
  language: string;
  sharing_permission: SharingPermission;
};

type SuccessDto = BaseSuccessDto<PersonalityDto>;

export const httpUpdatePersonality = mutationEndpoint({
  callEndpoint: ({ personalityId, ...params }: UpdatePersonalityParams) =>
    backofficeClient
      .put<SuccessDto>(`/chat/personalities/${personalityId}`, params)
      .then(response => {
        return response.data.data;
      })
      .catch(error => {
        const errorCode = getErrorCodeFromAxiosError(error);
        if (errorCode) {
          Toast.error(`chat.personalities.error.${errorCode}`);
        }
      })
});
