import { TabContext, TabPanel } from '@mui/lab';
import { Box, Button, Tab, Tabs, TextField, Typography } from '@mui/material';
import unsplashLogoImage from 'assets/unsplash-logo.svg';
import unsplashWhiteLogoImage from 'assets/unsplash-logo-white.svg';
import FlexContainer from 'components/FlexContainer';
import { useModal } from 'components/modals';
import { AiImagesGeneratorContextProvider } from 'features/aiImages/AiImagesPage/AiImagesGeneratorContext';
import { GeneratorForm } from 'features/aiImages/AiImagesPage/GeneratorForm';
import { ImagesBox } from 'features/aiImages/AiImagesPage/ImagesBox';
import { useAiWriterExpandedSidebarStore } from 'features/aiWriter/AiWriterSidebar/sidebar/aiWriterExpandedSidebarStore';
import { AiWriterSidebarPanel } from 'features/aiWriter/AiWriterSidebar/sidebar/AiWriterSidebarPanel';
import { ExpandSidebarButton } from 'features/aiWriter/AiWriterSidebar/sidebar/ExpandSidebarButton';
import ImagesEmptyState from 'features/aiWriter/AiWriterSidebar/steps/images/states/ImagesEmptyState';
import ImagesInspirationsState from 'features/aiWriter/AiWriterSidebar/steps/images/states/ImagesInspirationsState';
import ImagesLoadingState from 'features/aiWriter/AiWriterSidebar/steps/images/states/ImagesLoadingState';
import { TextFieldEndAdorment } from 'features/aiWriter/AiWriterSidebar/steps/images/TextFieldEndAdorment';
import useInitialBriefValues from 'features/aiWriter/AiWriterSidebar/steps/textInspirations/useInitialBriefValues';
import { setInspirationsCurrentStep } from 'features/aiWriter/store/actions/config/actions';
import { fetchImageInspirationsThunk } from 'features/aiWriter/store/actions/unsplashImages/thunks/fetchImageInspirationsThunk';
import {
  getGetIsAiWriterLoading,
  getImages,
  getInspirationsCurrentSubStep,
  getIsImagesNotFound
} from 'features/aiWriter/store/selectors';
import { AiImagesSubStep, AiWriterSidebarStep } from 'features/aiWriter/store/types';
import FormattedMessage from 'features/i18n/FormattedMessage';
import { getIsUnsplashImagesLimitReached } from 'features/pricing/store/selectors';
import React, { useCallback, useMemo, useState } from 'react';
import { GAEvents } from 'services/tracking/GAEvents';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import styled from 'styled-components';
import { useIsDarkMode } from 'styles/new-theme/useAppThemeStore';
import { resetButton } from 'styles/styledComponents/resets';
import useTr from 'utils/hooks/useTr';
import { safeEnum } from 'utils/safeEnum';

const UnsplashPageLink = 'https://unsplash.com/?utm_source=neuroflash&utm_medium=referral';

export const ContentImagesTabs = safeEnum.make({
  aiImages: 'aiImages',
  unsplashImages: 'unsplashImages'
});

type ImagesTabs = safeEnum.infer<typeof ContentImagesTabs>;

type ImagesProps = {
  imagesTab: ImagesTabs;
};

export const ImagesStep = () => {
  const subStep = useAppSelector(getInspirationsCurrentSubStep);

  switch (subStep) {
    case 'aiImages':
      return <ImagesStepContent imagesTab={ContentImagesTabs.aiImages} />;
    case 'unsplashImages':
      return <ImagesStepContent imagesTab={ContentImagesTabs.unsplashImages} />;
  }

  return <ImagesStepContent imagesTab={ContentImagesTabs.aiImages} />;
};

const ImagesStepContent = ({ imagesTab }: ImagesProps) => {
  const initialValues = useInitialBriefValues();
  const tr = useTr();
  const { showModal } = useModal();
  const dispatch = useAppDispatch();

  const isSidebarExpanded = useAiWriterExpandedSidebarStore(state => state.isSidebarExpanded);
  const setIsSidebarExpanded = useAiWriterExpandedSidebarStore(state => state.setIsSidebarExpanded);

  const [value, setValue] = useState<string>(imagesTab);
  const [searchValue, setSearchValue] = useState<string>(initialValues.keywords);
  const [offset, setOffset] = useState<number>(1);

  const images = useAppSelector(getImages);
  const isImagesNotFound = useAppSelector(getIsImagesNotFound);
  const areImagesLoading = useAppSelector(getGetIsAiWriterLoading)('fetchImages');
  const isUnsplashImagesLimitReached = useAppSelector(getIsUnsplashImagesLimitReached);

  const isDarkMode = useIsDarkMode();

  const handleChange = (event: React.SyntheticEvent, newValue: AiImagesSubStep) => {
    GAEvents.sidebarImagesTabSwitched({ tab: newValue });
    setValue(newValue);
    dispatch(setInspirationsCurrentStep({ step: AiWriterSidebarStep.images, subStep: newValue }));
  };

  const onClick = useCallback(() => {
    const newOffset = offset + 1;

    dispatch(fetchImageInspirationsThunk(searchValue, 'extend', newOffset));
    setOffset(newOffset);
  }, [dispatch, searchValue, offset]);

  const handleUnsplashImageSearchKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLDivElement>) => {
      if (e.key === 'Enter') {
        dispatch(fetchImageInspirationsThunk(searchValue, 'replace'));
        setOffset(1);
      }
    },
    [dispatch, searchValue]
  );

  const onTextFieldClick = useCallback(() => {
    dispatch(fetchImageInspirationsThunk(searchValue, 'replace'));
    setOffset(0);
  }, [dispatch, searchValue]);

  const content = useMemo(() => {
    if (images.length > 0) {
      return (
        <ImagesInspirationsState
          loadingMore={areImagesLoading ?? false}
          disableLoadMore={searchValue === null}
          onClick={onClick}
        />
      );
    }

    if (areImagesLoading) {
      return <ImagesLoadingState />;
    }

    return <ImagesEmptyState imagesNotFound={isImagesNotFound} />;
  }, [areImagesLoading, images.length, isImagesNotFound, searchValue, onClick]);

  return (
    <AiWriterSidebarPanel
      title="aiWriter.inspirations.images.title"
      customButton={
        <ExpandSidebarButton
          isExpanded={isSidebarExpanded}
          onExpand={() => setIsSidebarExpanded(true)}
          onShrink={() => setIsSidebarExpanded(false)}
        />
      }
    >
      <PanelLayout gap="medium">
        <Box>
          <TabContext value={value}>
            <Tabs value={value} onChange={handleChange}>
              <StyledTab
                value={ContentImagesTabs.aiImages}
                label={tr('aiWriter.inspirations.images.create_images_tab')}
                css={`
                  text-transform: none;
                `}
              />
              <StyledTab
                value={ContentImagesTabs.unsplashImages}
                label={tr('aiWriter.inspirations.images.search_images_tab')}
                css={`
                  text-transform: none;
                `}
              />
            </Tabs>
            <StyledTabPanel value={ContentImagesTabs.aiImages}>
              <AiImagesGeneratorContextProvider>
                <GeneratorForm isInsideContentFlash={true} />
                <ImagesBox isInsideContentFlash={true} />
              </AiImagesGeneratorContextProvider>
            </StyledTabPanel>
            <StyledTabPanel value={ContentImagesTabs.unsplashImages}>
              <TextField
                placeholder={tr('common.search_topic')}
                value={searchValue}
                disabled={isUnsplashImagesLimitReached}
                onChange={event => setSearchValue(event.target.value)}
                onKeyDown={handleUnsplashImageSearchKeyDown}
                InputProps={{
                  endAdornment: <TextFieldEndAdorment onClick={onTextFieldClick} />
                }}
                fullWidth={true}
                autoFocus={true}
              />
              <UnsplashLabel>
                <Caption variant="caption">
                  <FormattedMessage
                    id="aiWriter.inspirations.images.caption"
                    values={{
                      button: (text: string) => (
                        <UnsplashLicenceButton onClick={() => showModal('UNSPLASH', { size: 480 })}>
                          {text}
                        </UnsplashLicenceButton>
                      )
                    }}
                  />
                </Caption>
                <UnsplashButton onClick={() => window.open(UnsplashPageLink, '_blank')}>
                  <img
                    src={isDarkMode ? unsplashWhiteLogoImage : unsplashLogoImage}
                    alt="Unsplash"
                    width={80}
                  />
                </UnsplashButton>
              </UnsplashLabel>
              <ContentBox>{content}</ContentBox>
            </StyledTabPanel>
          </TabContext>
        </Box>
      </PanelLayout>
    </AiWriterSidebarPanel>
  );
};

const StyledTabPanel = styled(TabPanel)`
  padding: ${({ theme }) => `${theme.spacings.four} 0 0 0`};
`;

const StyledTab = styled(Tab)`
  width: 50%;
`;

const PanelLayout = styled(FlexContainer)`
  height: 100%;
`;

const Caption = styled(Typography)`
  && {
    color: ${({ theme }) => theme.colors.blackMediumEmphasis};
  }
`;

const UnsplashLabel = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const UnsplashButton = styled(Button)`
  ${resetButton};
  cursor: pointer;
`;

const UnsplashLicenceButton = styled.button`
  background: none;
  border: none;
  font: inherit;
  text-decoration: underline;
  color: inherit;
  cursor: pointer;

  padding: 0;
`;

const ContentBox = styled.div`
  flex: 1 1 auto;
  overflow-y: auto;
  min-height: ${({ theme }) => theme.spacings.xxxxlarge};
`;
