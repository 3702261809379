import { localizations } from 'features/i18n/localizations';
import store from 'store/store';

/**
 * Access translations outside of react components
 *
 * @deprecated Use useTr() hook instead
 */
export const getTranslation = (key: string): string => {
  const { language } = store.getState();

  return localizations[language].appTranslations[key];
};
