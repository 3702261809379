import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { Typography } from '@mui/material';
import FlexContainer from 'components/FlexContainer';
import FormattedMessage from 'features/i18n/FormattedMessage';
import { useState } from 'react';
import { GAEvents } from 'services/tracking/GAEvents';
import styled from 'styled-components';

type AccordionProps = {
  id: number;
};

const Accordion = (props: AccordionProps) => {
  const { id } = props;
  const [isExpanded, setIsExpanded] = useState(false);

  const handleQuestionClick = () => {
    if (!isExpanded) {
      GAEvents.FAQquestionClicked({ questionId: id });
    }
    setIsExpanded(!isExpanded);
  };

  return (
    <QuestionAccordion onClick={handleQuestionClick}>
      <AccordionHeader>
        <Typography variant="subtitle2">
          <FormattedMessage id={`common.faq_section.question_${id}`} />
        </Typography>
        {isExpanded ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
      </AccordionHeader>
      {isExpanded ? (
        <AccordionAnswer>
          <Typography variant="body2">
            <FormattedMessage
              id={`common.faq_section.answer_${id}`}
              values={{
                br: <br />
              }}
            />
          </Typography>
        </AccordionAnswer>
      ) : null}
    </QuestionAccordion>
  );
};

export const FAQSection = () => {
  const ids = [1, 2, 3, 4, 5];
  return (
    <FlexContainer alignItems="center" justifyContent="center" style={{ marginTop: '1rem' }}>
      <Typography variant="h5">
        <FormattedMessage id="common.faq_section.title" />
      </Typography>
      <QuestionsBox>
        {ids.map(id => (
          <Accordion key={id} id={id} />
        ))}
      </QuestionsBox>
    </FlexContainer>
  );
};

const QuestionsBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: ${({ theme }) => theme.spacings.small};
  margin-top: ${({ theme }) => theme.spacings.small};
`;

const AccordionHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const AccordionAnswer = styled.div`
  margin-top: ${({ theme }) => theme.spacings.small};
`;

const QuestionAccordion = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: ${({ theme }) => theme.borderRadius.small};
  border: 1px solid ${({ theme }) => theme.colors.aiWriterSidebarBorderColor};
  padding: 1.5rem 1rem;
  width: 100%;
  cursor: pointer;
`;
