import { Button, CircularProgress, Tooltip, Typography } from '@mui/material';
import { ReactComponent as IconSparkles } from 'assets/icons/icon-sparkles.svg';
import FlexContainer from 'components/FlexContainer';
import { useHasForbiddenDocumentLanguage } from 'features/aiWriter/AiWriterSidebar/steps/flashScore/useHasForbiddenDocumentLanguage';
import { FlashScoreEmotionalityProgress } from 'features/aiWriter/AiWriterTextEditor/EditorTopToolbar/flashScorePopper/FlashScoreEmotionalityProgress';
import { FlashScorePopperRatingRoot } from 'features/aiWriter/AiWriterTextEditor/EditorTopToolbar/flashScorePopper/FlashScorePopperRatingRoot';
import { FlashScorePopperTextReplacementView } from 'features/aiWriter/AiWriterTextEditor/EditorTopToolbar/flashScorePopper/FlashScorePopperTextReplacementView';
import { FlashScoreReadabilityProgress } from 'features/aiWriter/AiWriterTextEditor/EditorTopToolbar/flashScorePopper/FlashScoreReadabilityProgress';
import { getCurrentModelLanguageAndCountry } from 'features/aiWriter/store/selectors';
import { useScoreProvidedText } from 'features/flashScore/useScoreProvidedText';
import FormattedMessage from 'features/i18n/FormattedMessage';
import { languageLabelSelector } from 'features/language/store/selectors';
import { ReactElement, useState } from 'react';
import gtmIds from 'services/tracking/GTMIds';
import { withGtmInteraction } from 'services/tracking/withGtmInteraction';
import { useAppSelector } from 'store/hooks';
import styled from 'styled-components';
import { getWordCount } from 'utils/getWordCount';

type Props = {
  anchorElement: HTMLElement | null;
  isOpen: boolean;
  selectedText: string;
  onClose: () => void;
};

export const FlashScorePopper = ({
  anchorElement,
  isOpen,
  selectedText,
  onClose
}: Props): ReactElement => {
  const { currentModelLanguage: documentLanguage } = useAppSelector(
    getCurrentModelLanguageAndCountry
  );
  const getLanguageLabel = useAppSelector(languageLabelSelector);

  const isSupportedLanguage = !useHasForbiddenDocumentLanguage();
  const { isFetching: isAnalyzing, data: scoringData } = useScoreProvidedText({
    text: selectedText,
    enabled: isOpen && isSupportedLanguage,
    cacheKeys: [selectedText]
  });

  const [showTextReplacement, setShowTextReplacement] = useState(false);

  const wordCount = getWordCount(selectedText);
  const hasSelectedAllowedAmountOfWords = wordCount >= 5 && wordCount <= 120;

  const isTextReplacementEnabled = isSupportedLanguage && hasSelectedAllowedAmountOfWords;

  if (showTextReplacement) {
    return (
      <FlashScorePopperTextReplacementView
        anchorElement={anchorElement}
        isOpen={isOpen}
        onClose={onClose}
        selectedText={selectedText}
      />
    );
  }

  if (isAnalyzing) {
    return (
      <FlashScorePopperRatingRoot isOpen={isOpen} anchorElement={anchorElement} onClose={onClose}>
        <ProgressRootContainer>
          <CircularProgress size={100} thickness={1} />
        </ProgressRootContainer>
      </FlashScorePopperRatingRoot>
    );
  }

  const renderedOptimizeButton = (
    <Button
      {...withGtmInteraction(gtmIds.aiWriter.flashScore.oneClickOptimizeCta)}
      variant="contained"
      startIcon={<IconSparkles />}
      fullWidth={true}
      disabled={!isTextReplacementEnabled}
      onClick={() => setShowTextReplacement(true)}
    >
      <FormattedMessage id="flashScore.popper.optimize_cta" />
    </Button>
  );

  return (
    <FlashScorePopperRatingRoot isOpen={isOpen} anchorElement={anchorElement} onClose={onClose}>
      <FlexContainer direction="column" gap="medium">
        <FlexContainer direction="row" justifyContent="space-between" alignItems="center">
          <Typography fontWeight="bold" color={!isSupportedLanguage ? 'gray' : undefined}>
            <FormattedMessage id="flashScore.emotionality.label" />
          </Typography>

          <FlashScoreEmotionalityProgress
            scoringData={scoringData}
            isSupportedLanguage={isSupportedLanguage}
          />
        </FlexContainer>

        <FlexContainer direction="row" justifyContent="space-between" alignItems="center">
          <Typography fontWeight="bold" color={!isSupportedLanguage ? 'gray' : undefined}>
            <FormattedMessage id="flashScore.readability.label" />
          </Typography>

          <FlashScoreReadabilityProgress
            scoringData={scoringData}
            isSupportedLanguage={isSupportedLanguage}
          />
        </FlexContainer>
      </FlexContainer>

      <Footer>
        {isTextReplacementEnabled ? (
          renderedOptimizeButton
        ) : (
          <Tooltip
            placement="top"
            title={
              <FormattedMessage
                id={
                  !isSupportedLanguage
                    ? 'flashScore.popper.optimize_tooltip_invalid_language'
                    : 'flashScore.popper.optimize_tooltip_invalid_amount_words'
                }
                values={{
                  language: getLanguageLabel(documentLanguage),
                  languageCode: documentLanguage
                }}
              />
            }
          >
            <div>{renderedOptimizeButton}</div>
          </Tooltip>
        )}
      </Footer>
    </FlashScorePopperRatingRoot>
  );
};

const ProgressRootContainer = styled(FlexContainer).attrs({
  direction: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  gap: 'large'
})`
  width: 100%;
`;

const Footer = styled.div`
  margin-top: ${({ theme }) => theme.spacings.medium};
`;
