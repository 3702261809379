import { createAction } from '@reduxjs/toolkit';
import { TElement, TLocation, Value } from '@udecode/plate-common';

export const updateText = createAction<{ tabId: string; value: Value }>('aiWriter/updateText');

export const insertEditorBlock = createAction<TElement>('aiWriter/insertEditorBlock');

export const setSelection = createAction<TLocation | undefined>('aiWriter/setSelection');

export const setGeneratingTextInEditor = createAction<{ tabId: string; active: boolean }>(
  'aiWriter/setGeneratingTextInEditor'
);
