import { backofficeClient } from 'services/backofficeIntegration/http/client/createBackofficeClient';
import { queryEndpoint } from 'services/backofficeIntegration/http/endpointDefinition';
import {
  PaginatedListParams,
  PaginatedListSuccessDto
} from 'services/backofficeIntegration/http/paginatedListEndpoint';

export type TeamsForWorklowPaginatedListParams = PaginatedListParams & {
  search?: string;
};

export type TeamsData = {
  id: number;
  name: string;
  owner_email: string;
};

type ResponseDto = PaginatedListSuccessDto<TeamsData>;

export const httpGetTeamsForWorkflow = queryEndpoint({
  makeQueryKey: (params: TeamsForWorklowPaginatedListParams) => {
    return ['brandHub', 'workflows', 'teams', ...Object.values(params)] as const;
  },
  callEndpoint: (params: TeamsForWorklowPaginatedListParams) => {
    return backofficeClient
      .get<ResponseDto>('/teams', { params })
      .then(response => response.data);
  }
});
