import { getTesterActiveTab } from 'features/aiTester/store/selectors';
import { FieldType } from 'features/aiTester/store/types';
import { useCallback } from 'react';
import { useAppSelector } from 'store/hooks';
import useTr from 'utils/hooks/useTr';

export default function useGetGraphLabel() {
  const tr = useTr();
  const { wordAttributes } = useAppSelector(getTesterActiveTab);

  return useCallback(
    (field: FieldType, label: string | null) => {
      if (field === 'open_rate') {
        return tr('common.open_rate');
      }

      if (label) {
        return label;
      }

      return tr(`${wordAttributes.find(option => field === option.value)?.option?.label}`);
    },
    [wordAttributes, tr]
  );
}
