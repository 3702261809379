/* eslint-disable @typescript-eslint/no-explicit-any */
import { axisBottom, axisLeft, axisRight, AxisScale, axisTop } from 'd3-axis';

type Config = {
  orientation: 'top' | 'bottom' | 'left' | 'right';
  scale: AxisScale<any>;
  tickArguments?: any;
  tickValues?: any;
  tickFormat?: any;
  tickSize?: number;
  tickSizeInner?: number;
  tickSizeOuter?: number;
  tickPadding?: number;
};

function buildAxis({
  orientation = 'bottom',
  scale,
  tickArguments,
  tickFormat,
  tickValues,
  tickPadding,
  tickSize,
  tickSizeInner,
  tickSizeOuter
}: Config) {
  let axis;

  switch (orientation) {
    case 'bottom':
      axis = axisBottom(scale);
      break;
    case 'left':
      axis = axisLeft(scale);
      break;
    case 'right':
      axis = axisRight(scale);
      break;
    case 'top':
      axis = axisTop(scale);
      break;
    default:
      axis = axisBottom(scale);
  }

  if (tickArguments) axis.tickArguments(tickArguments);
  if (tickFormat) axis.tickFormat(tickFormat);
  if (tickValues) axis.tickValues(tickValues);
  if (tickPadding) axis.tickPadding(tickPadding);
  if (tickSize) axis.tickSize(tickSize);
  if (tickSizeInner) axis.tickSizeInner(tickSizeInner);
  if (tickSizeOuter) axis.tickSizeOuter(tickSizeOuter);

  return axis;
}

export default buildAxis;
