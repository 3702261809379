import { ArrowForwardRounded } from '@mui/icons-material';
import { Button, CircularProgress, Link, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import backgroundLearningBanner from 'assets/home/background-banner-with-brand-voice.png';
import backgroundBanner from 'assets/home/background-banner-without-brand-voice-test.png';
import FlexContainer from 'components/FlexContainer';
import { HomeBannerLayout } from 'features/homePage/HomeBannerLayout';
import FormattedMessage from 'features/i18n/FormattedMessage';
import { useShowPersonalityCreationModal } from 'features/personality/creation/PersonalityCreationModal';
import { httpGetPersonalities } from 'services/backofficeIntegration/http/endpoints/personalities/httpGetPersonalities';
import styled from 'styled-components';
import useTr from 'utils/hooks/useTr';

export const HomeBanner = () => {
  const translate = useTr();

  const showPersonalityCreationModal = useShowPersonalityCreationModal();
  const handleCreatePersonalityClick = () => {
    showPersonalityCreationModal({
      initialViewMode: 'choice',
      isLanguageEditable: true
    });
  };

  const handleLearnPersonalityClick = () => {
    window.open(translate('home.banner.brandVoice_learn.link'), '_blank');
  };

  const { data, isLoading } = useQuery({
    queryKey: httpGetPersonalities.makeQueryKey({}),
    queryFn: () => httpGetPersonalities.callEndpoint({})
  });

  const isPersonalityCreated = data?.data && data.data.length > 0;

  if (isLoading) {
    return (
      <LoadingContainer>
        <CircularProgress />
      </LoadingContainer>
    );
  }

  if (isPersonalityCreated) {
    return (
      <HomeBannerLayout
        title={<FormattedMessage id="home.banner.brandVoice_learn.title" />}
        content={<FormattedMessage id="home.banner.brandVoice_learn.description" />}
        image={backgroundLearningBanner}
        color="green__500main"
      >
        <CreatePersonalityButton
          variant="contained"
          size="small"
          startIcon={<ArrowForwardRounded />}
          onClick={handleLearnPersonalityClick}
        >
          <FormattedMessage id="home.banner.brandVoice.button.learn" />
        </CreatePersonalityButton>
      </HomeBannerLayout>
    );
  }

  return (
    <HomeBannerLayout
      title={<FormattedMessage id="home.banner.brandVoice.title" />}
      content={<FormattedMessage id="home.banner.brandVoice.description" />}
      image={backgroundBanner}
      color="blue__500main"
    >
      <ButtonsContainer>
        <CreatePersonalityButton
          variant="contained"
          size="small"
          startIcon={<ArrowForwardRounded />}
          onClick={handleCreatePersonalityClick}
        >
          <FormattedMessage id="home.banner.brandVoice.button.create" />
        </CreatePersonalityButton>
        <WhiteLink href={translate('home.banner.brandVoice_create.link')} target="_blank">
          <Typography variant="body2">
            <FormattedMessage id="home.banner.brandVoice_create.linkText" />
          </Typography>
        </WhiteLink>
      </ButtonsContainer>
    </HomeBannerLayout>
  );
};

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const CreatePersonalityButton = styled(Button)`
  width: fit-content;
  background-color: ${({ theme }) => theme.colors.commonWhiteMain};
  color: ${({ theme }) => theme.colors.primaryColorMain};

  :hover {
    background-color: ${({ theme }) => theme.colors.commonWhiteMain};
  }
`;

const WhiteLink = styled(Link)`
  color: ${({ theme }) => theme.colors.commonWhiteMain};

  :hover {
    color: ${({ theme }) => theme.colors.commonWhiteMain};
  }
`;

const ButtonsContainer = styled(FlexContainer).attrs({
  gap: 'two',
  direction: 'row',
  alignItems: 'center'
})``;
