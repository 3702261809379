export type PersonalityFormData = {
  label: string;
  description?: string;
  definition: string;
};

export const personalityLabelMaxLength = 50;
export const personalityDescriptionMaxLength = 500;

export const isValidPersonalityFormData = (data: PersonalityFormData) => {
  // API validation
  if (data.label.length < 2 || data.definition.length < 2) {
    return false;
  }

  // Custom validation
  if (data.label.length > personalityLabelMaxLength) {
    return false;
  }

  // Custom validation
  if (data?.description && data?.description.length > personalityDescriptionMaxLength) {
    return false;
  }

  return true;
};
