import { Search, StarBorder, TextFields } from '@mui/icons-material';
import { Grid, MenuItem, Typography } from '@mui/material';
import SelectField from 'components/forms/SelectField';
import TextInputField from 'components/forms/TextInputField';
import { CharacterLimiterWithLabel } from 'components/inputs/CharacterLimiterWithLabel';
import { useField, useFormikContext } from 'formik';
import React, { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { useAppSelector } from 'store/hooks';
import styled from 'styled-components';

import RoundChip from '../../../components/base/RoundChip';
import CenteredContainer from '../../../components/styled/CenteredContainer';
import useTr from '../../../utils/hooks/useTr';
import { getUserAudiences } from '../../audiences/store/selectors';
import {
  CampaignConfigFormPayload,
  emailBodyField,
  keywordsField,
  nameField,
  projectIdField,
  subjectLinesField,
  suggestionsField
} from './CampaignConfigForm';

type Props = {
  label: string;
  icon: React.ReactNode;
  children: React.ReactNode;
};

const CampaignInformation = () => {
  const tr = useTr();
  const { setFieldValue } = useFormikContext<CampaignConfigFormPayload>();

  const projects = useAppSelector(getUserAudiences);

  const resetSubjectLines = useCallback(() => {
    setFieldValue(subjectLinesField, []);
    setFieldValue(suggestionsField, []);
  }, [setFieldValue]);

  return (
    <CampaignInformationContainer>
      <CenteredContainerWithGap>
        <FieldsContainer>
          <FormGroupWithIcon label="subject_lines.new_campaign.name" icon={<TextFields />}>
            <TextInputField
              name={nameField}
              placeholder={tr('subject_lines.new_campaign.name.placeholder')}
              fullWidth
            />
          </FormGroupWithIcon>
          <FormGroupWithIcon label="subject_lines.new_campaign.audience" icon={<StarBorder />}>
            <SelectField name={projectIdField} fullWidth onSelect={resetSubjectLines}>
              {projects.map(project => (
                <MenuItem key={project.id} value={project.id}>
                  {project.name}
                </MenuItem>
              ))}
            </SelectField>
          </FormGroupWithIcon>
          <Grid container>
            <Grid item xs={12}>
              <EmailFieldContainer>
                <Typography variant="h6">
                  <FormattedMessage id="subject_lines.new_campaign.email.label" />
                </Typography>
                <EmailBodyInput />
              </EmailFieldContainer>
            </Grid>
          </Grid>
          <FormGroupWithIcon label="subject_lines.new_campaign.keywords" icon={<Search />}>
            <TextInputField
              name={keywordsField}
              fullWidth
              placeholder={tr('subject_lines.new_campaign.keywords.placeholder')}
            />
          </FormGroupWithIcon>
        </FieldsContainer>
      </CenteredContainerWithGap>
    </CampaignInformationContainer>
  );
};

const FormGroupWithIcon = ({ label, icon, children }: Props) => (
  <Grid container spacing={2}>
    <Grid item xs={4}>
      <LabelContainer>
        <RoundChip size={48} label={icon} />
        <Typography variant="subtitle1">
          <FormattedMessage id={label} />
        </Typography>
      </LabelContainer>
    </Grid>
    <Grid item xs={8}>
      {children}
    </Grid>
  </Grid>
);

function EmailBodyInput() {
  const name = emailBodyField;
  const maxLength = 600;
  const tr = useTr();
  const [field] = useField<string>(name);
  const placeholder = tr('subject_lines.new_campaign.email.placeholder');
  return (
    <CharacterLimiterWithLabel length={field.value.length} maxLength={maxLength}>
      <TextInputField
        name={name}
        variant="outlined"
        multiline
        fullWidth
        placeholder={placeholder}
        inputProps={{ maxLength }}
      />
    </CharacterLimiterWithLabel>
  );
}

const CampaignInformationContainer = styled.div`
  margin: auto;
`;

const CenteredContainerWithGap = styled(CenteredContainer)`
  gap: 1rem;
`;

const FieldsContainer = styled(CenteredContainer)`
  min-width: 600px;
  gap: 2rem;
`;

const LabelContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

const EmailFieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export default CampaignInformation;
