import { WordAttributeWithOptions } from 'types/ReduxStateTypes';

import { wordAttributeOppositeValue } from './wordAttributeOppositeValue';
import { wordAttributeOptionIcon } from './wordAttributeOptionIcon';

// TODO it's temporary solution. Should be moved to the wordAttributesSelect during wordAttributes refactor

export const testerWordAttributeOptions: WordAttributeWithOptions[] = [
  {
    value: 'readability',
    label: 'readability',
    options: [
      {
        value: 'easy',
        label: 'goal_score.readability.easy',
        icon: wordAttributeOptionIcon['easy'],
        oppositeValue: wordAttributeOppositeValue['easy']
      },
      {
        value: 'complex',
        label: 'goal_score.readability.complex',
        icon: wordAttributeOptionIcon['complex'],
        oppositeValue: wordAttributeOppositeValue['complex']
      }
    ]
  },
  {
    value: 'valence',
    label: 'sentiment',
    options: [
      {
        label: 'goal_score.valence.happy',
        value: 'happy',
        icon: wordAttributeOptionIcon['happy'],
        oppositeValue: wordAttributeOppositeValue['happy']
      },
      {
        label: 'goal_score.valence.sad',
        value: 'sad',
        icon: wordAttributeOptionIcon['sad'],
        oppositeValue: wordAttributeOppositeValue['sad']
      }
    ]
  },
  {
    value: 'arousal',
    options: [
      {
        label: 'goal_score.arousal.exciting',
        value: 'exciting',
        icon: wordAttributeOptionIcon['exciting'],
        oppositeValue: wordAttributeOppositeValue['exciting']
      },
      {
        label: 'goal_score.arousal.calm',
        value: 'calm',
        icon: wordAttributeOptionIcon['calm'],
        oppositeValue: wordAttributeOppositeValue['calm']
      }
    ]
  },
  {
    value: 'dominance',
    options: [
      {
        label: 'goal_score.dominance.powerful',
        value: 'powerful',
        icon: wordAttributeOptionIcon['powerful'],
        oppositeValue: wordAttributeOppositeValue['powerful']
      },
      {
        label: 'goal_score.dominance.gentle',
        value: 'gentle',
        icon: wordAttributeOptionIcon['gentle'],
        oppositeValue: wordAttributeOppositeValue['gentle']
      }
    ]
  }
];
