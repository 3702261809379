import { useEffect } from 'react';

// This identifies our website in the ChurnKey service
// See https://app.churnkey.co/settings/embed
export const appId = 'cwldq4rlh';

export const ChurnKeyLoader = () => {
  useEffect(() => {
    loadScript();
  }, []);

  return null;
};

function hasLoadedScript() {
  return typeof window.churnkey !== 'undefined' && window.churnkey.created;
}

function loadScript() {
  if (hasLoadedScript()) {
    return;
  }

  // This is required, the ChurnKey script seems to relay on this first initialization.
  // We also use this to check if it's loaded
  window.churnkey = { created: true };

  const script = document.createElement('script');
  script.type = 'text/javascript';
  script.src = `https://assets.churnkey.co/js/app.js?appId=${appId}`;
  script.defer = true;
  document.getElementsByTagName('body')[0].appendChild(script);
}
