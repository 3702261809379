import { Typography } from '@mui/material';
import FlexContainer from 'components/FlexContainer';
import FormattedMessage from 'features/i18n/FormattedMessage';
import {
  getAvailablePercentage,
  getIndicatorColor
} from 'features/usageIndicator/getIndicatorConfiguration';
import { indicatorHeight } from 'features/usageIndicator/UsageIndicator';
import { UsageIndicatorType } from 'features/usageIndicator/UsageIndicatorType';
import { rgba } from 'polished';
import { LimitationDto } from 'services/backofficeIntegration/http/endpoints/customer/httpGetAllLimitations';
import styled from 'styled-components';

export const enum WideUsageIndicatorType {
  words = 'words',
  aiImages = 'ai_images',
  unsplash = 'unsplash',
  seobility = 'seobility',
  plagiarism = 'plagiarism'
}

type Props = {
  type: UsageIndicatorType;
  limitation: LimitationDto;
  hasAccess?: boolean;
  bonus?: number;
};

export const WideUsageIndicator = ({ type, limitation, hasAccess = true }: Props) => {
  const percentage = getAvailablePercentage({
    hasAccess,
    limit: limitation.limit,
    used: limitation.used,
    available: limitation.available,
    isUnlimited: limitation.is_unlimited
  });

  const barColor = hasAccess
    ? getIndicatorColor({
        available: limitation.available,
        isUnlimited: limitation.is_unlimited
      })
    : 'rgba(0, 0, 0, 0.38)';

  return (
    <Root>
      <FlexContainer
        alignItems="center"
        justifyContent="space-between"
        direction="row"
        style={{ width: '100%' }}
      >
        <Text>
          <FormattedMessage id={`aiWriter.topbar.usage_indicator.popover.${type}_label`} />
        </Text>
        {hasAccess && (
          <Text>
            <FormattedMessage
              id={
                limitation.is_unlimited
                  ? 'common.unlimited'
                  : 'aiWriter.topbar.usage_indicator.popover.usage_label'
              }
              values={{
                limit: limitation.limit,
                used: limitation.used,
                available: limitation.available
              }}
            />
          </Text>
        )}
      </FlexContainer>
      <Indicator $background={limitation.is_unlimited ? null : barColor}>
        <ProgressBar $background={barColor} $percentage={percentage} />
      </Indicator>
    </Root>
  );
};

const Root = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;
`;

const Indicator = styled.div<{ $background: string | null }>`
  position: relative;
  z-index: 0;

  width: 100%;
  height: ${indicatorHeight};

  box-sizing: border-box;
  border-radius: ${indicatorHeight};
  background: ${({ $background }) => ($background ? rgba($background, 0.2) : undefined)};
  overflow: hidden;
`;

const ProgressBar = styled.div<{ $background: string; $percentage: number }>`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;

  width: ${({ $percentage }) => $percentage}%;
  height: ${indicatorHeight};

  border-radius: ${indicatorHeight};
  background: ${({ $background }) => $background};
  opacity: 1;
`;

const Text = styled(Typography).attrs({ variant: 'caption', color: 'text.secondary' })``;
