import { AddCircleOutlineOutlined } from '@mui/icons-material';
import { FeatureTablePresenceCell } from 'components/profile/components/feature-comparison/FeatureTablePresenceCell';
import { StyledCell } from 'components/profile/components/pricing-plan-card/Components';
import { ComponentProps } from 'react';
import styled from 'styled-components';

type Props = ComponentProps<typeof FeatureTablePresenceCell> & {
  isNegotiable?: boolean;
};

export const FeatureTableNegotiablePresenceCell = ({ activeFlag, isNegotiable }: Props) => {
  // Even if the feature is negotiable, it gets the checkmark if it's active
  if (!isNegotiable || activeFlag) {
    return <FeatureTablePresenceCell activeFlag={activeFlag} />;
  }

  return (
    <StyledCell>
      <PlusIcon color="primary" />
    </StyledCell>
  );
};

const PlusIcon = styled(AddCircleOutlineOutlined)``;
