import { PlateLeafProps } from '@udecode/plate-common';
import styled from 'styled-components';

export const FakeSelectionLeaf = ({ children, ...props }: PlateLeafProps) => (
  <StyledPlateLeaf asChild={true} {...props}>
    {children}
  </StyledPlateLeaf>
);

const StyledPlateLeaf = styled.span`
  // #3367d1 mimics my current selection color (Win 11, latest Chrome)
  // TODO: Consider setting a global color to ensure
  //       it's not different based on OS or browser
  background-color: #3367d1;
  color: white;
  padding-top: 2px;
  padding-bottom: 2px;

  // HACK: Hide all background colors on children
  //       This attribute is applied by us to specific elements, see plate-plugins.ts
  *[data-has-background] {
    background-color: transparent !important;
    color: inherit !important;
  }
`;
