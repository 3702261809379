import { BaseOutput } from 'features/aiWriter/slashCommands/BaseOutput';
import { FlashActionsInputProps } from 'features/aiWriter/slashCommands/FlashActionsInput';
import { useFlashActionsSelectedText } from 'features/aiWriter/slashCommands/hooks/useFlashActionsStore';

type Props = FlashActionsInputProps;

const OUTPUT_TYPE = 'du_sie_action';

export function DuSieWechselnOutput(props: Props) {
  const flashActionsSelectedText = useFlashActionsSelectedText();

  const generateConfig = {
    outputType: OUTPUT_TYPE,
    text: flashActionsSelectedText
  };

  return <BaseOutput generateConfig={generateConfig} onClose={props.onClose} />;
}
