import { Prompt, PromptFieldType } from 'features/textGenerator/store/types';

export function getFieldPrefillValue(
  prompt: Prompt | undefined | null,
  fieldType: PromptFieldType
) {
  if (prompt) {
    return prompt[fieldType]?.prefillValue ?? '';
  }

  return '';
}
