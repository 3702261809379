import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import { toast, ToastContent, ToastOptions, UpdateOptions } from 'react-toastify';

export type ProgressToastFunctions = {
  close: () => void;
  success: (options: UpdateOptions) => void;
  error: (options: UpdateOptions) => void;
};

export const createProgressToast = (
  content: ToastContent,
  options?: ToastOptions
): ProgressToastFunctions => {
  const toastId = toast.loading(content, {
    ...options
  });

  return {
    close: () => {
      toast.dismiss(toastId);
    },
    success: (options: UpdateOptions) => {
      toast.update(toastId, {
        autoClose: 3000,
        ...options,
        type: 'success',
        icon: () => <CheckCircleIcon />,
        isLoading: false
      });
    },
    error: (options: UpdateOptions) => {
      toast.update(toastId, {
        autoClose: 4000,
        ...options,
        type: 'error',
        icon: () => <ErrorIcon />,
        isLoading: false
      });
    }
  };
};
