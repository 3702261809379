import NotificationHint from 'components/profile/components/pricing-plan-card/NotificationHint';
import FormattedMessage from 'features/i18n/FormattedMessage';
import { ReactElement } from 'react';

const SubscriptionInGraceHint = (): ReactElement => (
  <NotificationHint>
    <FormattedMessage id="profile.upgrade_plan.subscription_in_grace_period" />
  </NotificationHint>
);

export default SubscriptionInGraceHint;
