import AxiosClient from 'services/api';
import { CouponResponse, GetPricingResponse } from 'services/api/pricing/types';
import { APIResponse } from 'types/APIResponse';

export type PricingContextParams = {
  context?: 'socialRegisterPage';
};

const PricingAPI = {
  getPricings: (params: PricingContextParams) =>
    AxiosClient.get<unknown, GetPricingResponse>('/acl/pricings', { params }),
  couponById: ({ couponId }: { couponId: string }) =>
    AxiosClient.get<unknown, APIResponse<CouponResponse>>('/couponbyid', {
      params: { coupon_id: couponId }
    })
};

export default PricingAPI;
