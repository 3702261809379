import { setInspirationsCurrentStep } from 'features/aiWriter/store/actions/config/actions';
import { generateTextInspirationsThunk } from 'features/aiWriter/store/actions/inspirations/thunks/generateTextInspirationsThunk';
import { AiWriterSidebarStep, AiWriterTab } from 'features/aiWriter/store/types';
import { getIsConfigReady } from 'features/textGenerator/utils/getIsConfigReady';
import { AppThunk } from 'store/store';

export const configureCommonTabThunk =
  (tab: AiWriterTab): AppThunk<void> =>
  async dispatch => {
    const isConfigReady = getIsConfigReady(tab.generateTextConfig);

    if (isConfigReady) {
      dispatch(setInspirationsCurrentStep({ step: AiWriterSidebarStep.aiWriter, subStep: 'main' }));
      dispatch(generateTextInspirationsThunk());
    } else {
      dispatch(
        setInspirationsCurrentStep({ step: AiWriterSidebarStep.aiWriter, subStep: 'editBrief' })
      );
    }
  };
