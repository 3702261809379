import { Button } from '@mui/material';
import { useModal } from 'components/modals';
import mkTesterTourClassName from 'features/aiTester/tour/utils/mkTesterTourClassName';
import React, { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';

const BriefConfigButton = (): React.ReactElement => {
  const { showModal } = useModal();

  const openBriefModal = useCallback(() => {
    showModal({
      modalProps: {
        size: 460
      },
      modalType: 'TESTING_BRIEF_MODAL'
    });
  }, [showModal]);

  return (
    <Button
      className={mkTesterTourClassName('comparison', 1)}
      color="primary"
      onClick={openBriefModal}
    >
      <FormattedMessage id="testing.brief" />
    </Button>
  );
};

export default BriefConfigButton;
