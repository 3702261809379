import { getRoutePath } from 'config/routes';
import { getIsApiCustomer } from 'features/customer/store/selectors';
import { useCustomerPreferences } from 'features/customerPreferences/useCustomerPreferences';
import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { useAppSelector } from 'store/hooks';

export const useNavigateToOnboarding = () => {
  const navigate = useNavigate();
  const isApiCustomer = useAppSelector(getIsApiCustomer);
  const { data: customerPreferences } = useCustomerPreferences();

  const shouldSeeOnboarding =
    customerPreferences && !customerPreferences.newUserSurveySeen && !isApiCustomer;

  useEffect(() => {
    if (shouldSeeOnboarding) {
      navigate(getRoutePath('onboardingSurvey'));
    }
  }, [shouldSeeOnboarding, navigate]);
};
