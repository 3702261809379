import dayjs from 'dayjs';
import { shallowEqual } from 'react-redux';
import { useAppSelector } from 'store/hooks';
import { RootState } from 'store/store';

export const useGetTimeUntilTrialExpiration = () => {
  const { pricingExpiryDate } = useAppSelector((state: RootState) => state.customer, shallowEqual);

  const daysLeft = dayjs(pricingExpiryDate).diff(dayjs(), 'day');
  const hoursLeft = dayjs(pricingExpiryDate).diff(dayjs(), 'hour') - daysLeft * 24;
  
  return { daysLeft, hoursLeft };
};
