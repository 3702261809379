import { ReactElement, useState } from 'react';
import styled from 'styled-components';
import useRunInTask from 'utils/hooks/useRunInTask';

import useTr from '../../../../utils/hooks/useTr';
import SubjectLineInput from './SubjectLineInput';

type Props = {
  onAdd: (subjectLine: string) => Promise<boolean>;
};

const UserSubjectLine = ({ onAdd }: Props): ReactElement => {
  const [newSubjectLine, setNewSubjectLine] = useState<string>();
  const [error, setError] = useState<string>();

  const tr = useTr();
  const [isLoading, runInTask] = useRunInTask();

  const addNewSubjectLine = async (subjectLine: string) => {
    const isAdded = await runInTask(() => onAdd(subjectLine));

    if (isAdded) {
      setNewSubjectLine('');
    } else {
      setError(tr('subject_lines.campaign_config.incorrect_entry'));
    }
  };

  const onEnterPress = () => {
    setError(undefined);

    if (newSubjectLine) {
      addNewSubjectLine(newSubjectLine);
    }
  };

  return (
    <UserSubjectLineWrapper>
      <SubjectLineInput
        isLoading={isLoading}
        value={newSubjectLine}
        error={error}
        onChange={setNewSubjectLine}
        placeholder={tr('subject_lines.campaign_config.add_subject_line')}
        onEnterPress={onEnterPress}
        onBlur={() =>
          !newSubjectLine
            ? setError('')
            : setError(tr('subject_lines.campaign_config.no_confirmed_entry'))
        }
      />
    </UserSubjectLineWrapper>
  );
};

const UserSubjectLineWrapper = styled.li`
  display: flex;
  padding: 1.5rem 0.5rem;
  gap: 0.625rem;
`;

export default UserSubjectLine;
