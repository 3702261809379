import { Link, Typography } from '@mui/material';
import FlexContainer from 'components/FlexContainer';
import { getRoutePath } from 'config/routes';
import FormattedMessage from 'features/i18n/FormattedMessage';
import styled from 'styled-components';

export const EmptyDocumentsPlaceholder = () => {
  return (
    <EmptyDocContainer>
      <Typography variant="subtitle1">
        <FormattedMessage id="home.no_documents_placeholder.title" />
      </Typography>
      <CreateDocLink href={getRoutePath('newDocument')}>
        <Typography variant="subtitle1">
          <FormattedMessage id="home.no_documents_placeholder.create.button" />
        </Typography>
      </CreateDocLink>
    </EmptyDocContainer>
  );
};

const EmptyDocContainer = styled(FlexContainer)`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 128px;
  width: 100%;
  border-radius: ${({ theme }) => theme.borderRadius.one};
  gap: ${({ theme }) => theme.spacings.one};
  background-color: ${({ theme }) =>
    theme.mode === 'dark' ? theme.colors.backgroundPaperElevation2 : theme.colors.palette.dark__50};
`;

const CreateDocLink = styled(Link)`
  color: ${({ theme }) => theme.colors.textPrimary};
  text-decoration: underline;
  cursor: pointer;
  :hover {
    color: ${({ theme }) => theme.colors.textPrimary};
  }
`;
