import { ColoredTypography } from 'components/ColoredTypography';
import EmbedContainer from 'components/EmbedContainer';
import FlexContainer from 'components/FlexContainer';
import DeleteIconButton from 'components/iconButtons/DeleteIconButton';
import { CloseModal } from 'components/modals/types';
import styled from 'styled-components';
import useTr from 'utils/hooks/useTr';

const buildYouTubeUrlForId = (videoId: string) =>
  `https://www.youtube.com/embed/${videoId}?autoplay=1`;

type Props = CloseModal & {
  videoId: string;
  title?: string;
};

const YouTubeVideoModal = (props: Props) => {
  const tr = useTr();

  const { title, closeModal, videoId } = props;

  const embeddedVideoUrl = buildYouTubeUrlForId(videoId);

  return (
    <Root gap="medium">
      <Header>
        <DeleteIconButton onClick={closeModal} tooltip={tr('common.close')} />
        {title && <ColoredTypography variant="h5">{title}</ColoredTypography>}
      </Header>
      <EmbedContainer embedSrc={embeddedVideoUrl} resolution="16by9" />
    </Root>
  );
};

const Root = styled(FlexContainer)`
  padding: ${({ theme }) => `${theme.spacings.medium} ${theme.spacings.xmedium}`};
`;

const Header = styled.div`
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
`;

export default YouTubeVideoModal;
