import { Typography } from '@mui/material';
import { useModal } from 'components/modals';
import { getCurrentLanguage } from 'features/language/store/selectors';
import { UsageIndicatorType } from 'features/usageIndicator/UsageIndicatorType';
import { FormattedMessage } from 'react-intl';
import { useAppSelector } from 'store/hooks';
import styled from 'styled-components';
import { resetButton } from 'styles/styledComponents/resets';

type Props = {
  usage: {
    available: number;
    isUnlimited: boolean;
    used: number;
    limit: number;
  };
  type: UsageIndicatorType;
};

const WordsIndicatorLabel = ({ usage, type }: Props) => {
  const { showModal } = useModal();
  const currentLanguage = useAppSelector(getCurrentLanguage);

  if (usage.isUnlimited) {
    return (
      <CommonLabel variant="caption">
        <FormattedMessage id="common.unlimited" />
      </CommonLabel>
    );
  }

  const volumeLeft = usage.available;
  if (volumeLeft <= 0) {
    return (
      <AllUsedLabel
        onClick={() => showModal('UPDATE_SUBSCRIPTION_FROM_WORDS_LIMIT_USED', { size: 1100 })}
      >
        <FormattedMessage id="usage_indicator.all_used.label" />
      </AllUsedLabel>
    );
  }

  return (
    <CommonLabel variant="caption">
      <FormattedMessage
        id={`usage_indicator.counter.label.${type}`}
        values={{
          available: usage.available.toLocaleString(currentLanguage),
          used: usage.used.toLocaleString(currentLanguage),
          limit: usage.limit.toLocaleString(currentLanguage)
        }}
      />
    </CommonLabel>
  );
};

const CommonLabel = styled(Typography)`
  && {
    color: ${({ theme }) => theme.colors.textSecondary};
  }
`;

const AllUsedLabel = styled.button`
  ${resetButton};

  color: ${({ theme }) => theme.colors.primaryColorMain};
  font-weight: 700;
`;

export default WordsIndicatorLabel;
