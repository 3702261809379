import {
  createPluginFactory,
  ELEMENT_DEFAULT,
  getPluginType,
  QueryNodeOptions
} from '@udecode/plate-common';
import { withTrailingBlock } from 'features/plate/customPlugins/withTrailingBlock';

export interface TrailingBlockPlugin extends QueryNodeOptions {
  /**
   * Level where the trailing node should be, the first level being 0.
   */
  level?: number;

  /**
   * Types of the trailing block
   */
  types?: string[];
}

export const KEY_TRAILING_BLOCK = 'trailingBlock';

/**
 * Custom trailing block plugin with multi-type support
 */
export const createCustomTrailingBlockPlugin = createPluginFactory<TrailingBlockPlugin>({
  key: KEY_TRAILING_BLOCK,
  withOverrides: withTrailingBlock,
  options: {
    level: 0
  },
  then: editor => ({
    type: getPluginType(editor, ELEMENT_DEFAULT)
  })
});
