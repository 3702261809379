import { CardContent, Typography } from '@mui/material';
import { SpinnerDimmer } from 'components/base/SpinnerDimmer';
import FlexContainer from 'components/FlexContainer';
import { FAQSection } from 'components/profile/components/pricing-plan-card/FAQSection';
import { FeatureComparisonSection } from 'components/profile/components/pricing-plan-card/FeatureComparisonSection';
import { SocialProof } from 'components/profile/components/pricing-plan-card/SocialProof';
import { CouponCode } from 'features/pricing/CouponCode';
import useSubscriptionState from 'features/pricing/hook/useSubscriptionState';
import { PricingPeriodSelect } from 'features/pricing/PricingPeriodSelect';
import { PricingPlanFooter } from 'features/pricing/PricingPlanFooter';
import { TrialCanceledBanner } from 'features/pricing/TrialCanceledBanner';
import { TrialExpiryBanner } from 'features/pricing/TrialExpiryBanner';
import { PricingCardCollection } from 'features/profilePage/PricingCardCollection';
import { ReactElement, ReactNode, useState } from 'react';
import { SubscriptionPeriod } from 'services/api/customer/types';
import gtmIds from 'services/tracking/GTMIds';
import styled from 'styled-components';
import { mobileWindowWidthCSS } from 'utils/hooks/useMobileVersion';

const couponField = 'coupon';
const modalTopMargin = '1.75rem';

type ChangePricingPlanValues = {
  [couponField]: CouponCode;
};

export const initialValues: ChangePricingPlanValues = {
  coupon: { name: '', code: '' }
};

type Props = {
  id?: string;
  headline: ReactElement | string;
  subHeadline?: ReactNode;

  onClose?: () => void;
};

export const ChangePricingPlanModal = ({
  id = gtmIds.pricing.upgradePopUp,
  headline,
  subHeadline
}: Props): ReactElement | null => {
  const { currentPricingPlan } = useSubscriptionState();
  const [subscriptionPeriod, setSubscriptionPeriod] = useState<SubscriptionPeriod>(
    currentPricingPlan?.isFree ? 'year' : currentPricingPlan?.periodName
  );

  return (
    <SpinnerDimmer id={id} active={false} loader isOverflowSet={true}>
      <ModalBody className="modal-body">
        <Header direction="row" alignItems="center" justifyContent="space-between">
          <FlexContainer gap="two">
            <Typography variant="h5">{headline}</Typography>
            {subHeadline && <Typography variant="h6">{subHeadline}</Typography>}
          </FlexContainer>
          <FlexContainer gap="two" direction="row">
            <PricingPeriodSelect
              subscriptionPeriod={subscriptionPeriod}
              onSubscriptionPeriodChange={setSubscriptionPeriod}
            />
          </FlexContainer>
        </Header>

        <TrialExpiryBanner />
        <TrialCanceledBanner />

        <Body>
          <PricingCardCollection period={subscriptionPeriod} />
        </Body>

        <PricingPlanFooter />
        <SocialProof />
        <FeatureComparisonSection />
        <FAQSection />
      </ModalBody>
    </SpinnerDimmer>
  );
};

const ModalBody = styled.div`
  position: relative;
  padding: ${({ theme }) => theme.spacings.xmedium};
  overflow-y: auto;
  max-height: calc(100vh - (${modalTopMargin} * 2));
`;

const Header = styled(FlexContainer)`
  width: 100%;

  @media (max-width: ${mobileWindowWidthCSS}) {
    flex-direction: column;
    gap: ${({ theme }) => theme.spacings.two};
  }
`;

export const HintWrapper = styled.div`
  margin-top: ${({ theme }) => theme.spacings.medium};
`;

export const Body = styled(CardContent)`
  margin-top: ${({ theme }) => theme.spacings.large};

  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  &.MuiCardContent-root {
    padding: 0;
  }
`;
