import { useAppSelector } from 'store/hooks';

import { getLatestEmbeddingModelForCountry } from '../../embeddingModels/store/selectors';
import { getGetAudienceById } from '../store/selectors';

export default function useAudienceConfig({ audienceId }: { audienceId: number }) {
  const audience = useAppSelector(getGetAudienceById)(audienceId);
  const audienceModelId = audience?.model_id ? audience.model_id : '';
  const countryId =
    audience?.language && audience?.country ? `${audience.country}_${audience.language}` : 'de_de';
  const model = useAppSelector(getLatestEmbeddingModelForCountry)(countryId);

  return { model, audienceModelId };
}
