import { ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import DeleteIconButton from 'components/iconButtons/DeleteIconButton';
import { EditIconButton } from 'components/iconButtons/EditIconButton';
import { RefreshIconButton } from 'components/iconButtons/RefreshIconButton';
import { useField } from 'formik';
import { forwardRef, useState } from 'react';
import styled from 'styled-components';
import useRunInTask from 'utils/hooks/useRunInTask';
import useTr from 'utils/hooks/useTr';

import gtmIds from '../../../../services/tracking/GTMIds';
import { ScoredSubjectLine } from '../../store/types';
import BattlegroundTooltip from './BattlegroundTooltip';
import EllipsisTooltip from './EllipsisTooltip';
import SubjectLineInput from './SubjectLineInput';

type Props = {
  name: string;
  projectId: number;

  onRemove: () => void;
  onEdit: () => Promise<boolean>;
  onRewrite: () => void;
};

const useStyleDesktop = makeStyles({
  secondaryAction: {
    paddingRight: 160
  }
});

const SelectedSubjectLine = forwardRef<HTMLLIElement | null, Props>(
  ({ name, projectId, onEdit, onRemove, onRewrite }, ref) => {
    const [isEditing, setIsEditing] = useState(false);
    const [isLoading, runInTask] = useRunInTask();
    const [error, setError] = useState<string>();

    const tr = useTr();

    const [{ value }, , helper] = useField<ScoredSubjectLine>(name);

    const { editSelection, deleteSelection } = gtmIds.subjectLines;

    const classes = useStyleDesktop();

    const confirmEdit = async () => {
      const isEdited = await runInTask(() => onEdit());

      if (isEdited) {
        setIsEditing(false);
        setError(undefined);
      } else {
        setError(tr('subject_lines.campaign_config.incorrect_entry'));
      }
    };

    const rewrite = async () => {
      await runInTask(() => onRewrite());
    };

    if (!value) {
      return null;
    }

    return (
      <SelectedSubjectLineContainer ref={ref}>
        {isEditing ? (
          <SubjectLineInput
            placeholder={tr('subject_lines.campaign_config.edit_subject_line')}
            error={error}
            isLoading={isLoading}
            value={value.subjectLine}
            onChange={s => helper.setValue({ ...value, subjectLine: s })}
            onEnterPress={confirmEdit}
            onBlur={confirmEdit}
          />
        ) : (
          <ListItem
            button
            onClick={() => setIsEditing(true)}
            id={editSelection}
            classes={{
              secondaryAction: classes.secondaryAction
            }}
          >
            <ListItemAvatar>
              <BattlegroundTooltip
                projectId={projectId}
                predictedOpenRate={value?.predictedOpenRate ?? 0}
              />
            </ListItemAvatar>
            <ListItemText>
              <EllipsisTooltip text={value.subjectLine} />
            </ListItemText>
            <ListItemSecondaryAction>
              <ButtonsContainer isLoading={isLoading}>
                <RefreshIconButton onClick={rewrite} id={editSelection} isLoading={isLoading} />
                <EditIconButton onClick={() => setIsEditing(true)} id={editSelection} />
                <DeleteIconButton onClick={onRemove} id={deleteSelection} />
              </ButtonsContainer>
            </ListItemSecondaryAction>
          </ListItem>
        )}
      </SelectedSubjectLineContainer>
    );
  }
);

const ButtonsContainer = styled.div<{ isLoading: boolean }>`
  visibility: ${({ isLoading }) => (isLoading ? 'visible' : 'hidden')};
`;

const SelectedSubjectLineContainer = styled.li`
  &:hover ${ButtonsContainer} {
    visibility: inherit;
  }
`;

export default SelectedSubjectLine;
