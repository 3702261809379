import { ReactComponent as AiWriterIcon } from 'assets/icons/icon-ai-writer-selected.svg';
import { ColoredTypography } from 'components/ColoredTypography';
import FlexContainer from 'components/FlexContainer';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

export const SeoSearchResultRewrittenBadge = () => {
  return (
    <Root direction="row" alignItems="center" gap="xsmall">
      <AiWriterIcon width="16px" height="16px" />
      <ColoredTypography variant="caption" color="blackHighEmphasis">
        <FormattedMessage id="common.generated" />
      </ColoredTypography>
    </Root>
  );
};

const Root = styled(FlexContainer)`
  padding: ${({ theme }) => theme.spacings.xsmall};

  background-color: ${({ theme }) => theme.colors.primary100};
  border-radius: ${({ theme }) => theme.borderRadius.medium};
`;
