import {
  ApiModularWorkflowStep,
  ApiModularWorkflowStepReference
} from 'features/modular-workflow/builder/types-api';
import { create } from 'zustand';

type ModulareWorkflowStructureStoreState = {
  structure: ApiModularWorkflowStep[];
};

type ModulareWorkflowStructureStoreActions = {
  setStructure: (structure: ApiModularWorkflowStep[]) => void;
};

const initialState: ModulareWorkflowStructureStoreState = {
  structure: []
};

export const useModulareWorkflowStructureStore = create<
  ModulareWorkflowStructureStoreState & ModulareWorkflowStructureStoreActions
>()(set => ({
  setStructure: structure => set({ structure }),
  ...initialState
}));

export const useGetModulareWorkflowStepStructureByReference = () => {
  const { structure } = useModulareWorkflowStructureStore();

  return (reference: ApiModularWorkflowStepReference) =>
    structure.find(s => s.reference === reference);
};
