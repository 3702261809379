import styled from 'styled-components';

const Page = styled.div<{ $hasSidebar: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
  width: 100%;
  padding: ${({ theme, $hasSidebar }) => {
    if ($hasSidebar) {
      return `${theme.spacings.medium} ${theme.spacings.medium} 0 calc(${theme.sizes.sideBar} + ${theme.spacings.xmedium})`;
    }

    return `${theme.spacings.medium} ${theme.spacings.medium} 0 ${theme.spacings.medium}`;
  }};

  @media (max-width: 768px) {
    justify-content: flex-start;
  }
`;

export default Page;
