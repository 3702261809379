import { useModal } from 'components/modals';
import { CloseModal } from 'components/modals/types';
import { ChangePricingPlanModal } from 'features/pricing/modals/ChangePricingPlanModal';
import { usePostHog } from 'posthog-js/react';
import { useCallback, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { GAEvents } from 'services/tracking/GAEvents';

export function AiImagesLimitReachedModal({ closeModal }: CloseModal) {
  const postHog = usePostHog();

  useEffect(() => {
    GAEvents.modalSeen({
      modal: 'Image upgrade modal'
    });

    postHog?.capture('Upgrade modal seen', {
      modal: 'Image upgrade modal'
    });
  }, [postHog]);

  return (
    <ChangePricingPlanModal
      onClose={closeModal}
      headline={<FormattedMessage id="images.limit_reached.headline" />}
      subHeadline={<FormattedMessage id="images.limit_reached.sub_headline" />}
    />
  );
}

export const useShowAiImagesLimitReachedModal = () => {
  const { showModal } = useModal();

  return useCallback(() => {
    showModal('AI_IMAGES_LIMIT_REACHED', { size: 1100 });
  }, [showModal]);
};
