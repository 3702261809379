import { Container } from 'components/Container';
import { EmbedApp } from 'features/iframeFeatures/EmbedApp';
import { useIframeMessageListener } from 'features/iframeFeatures/useIframeMessageListener';
import { useEnableSidebarOnMount } from 'features/pageSidebar/useSidebarStore';
import styled from 'styled-components';

export function BrandVoicePage() {
  useIframeMessageListener();
  useEnableSidebarOnMount();

  return (
    <StyledContainer size="lg">
      <EmbedApp />
    </StyledContainer>
  );
}

const StyledContainer = styled(Container)`
  width: 100%;
  height: 100%;
  overflow-y: hidden;
`;
