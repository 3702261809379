import FormattedMessage from 'features/i18n/FormattedMessage';
import { IntlMessageKeys } from 'features/i18n/IntlMessageKeys';
import { ComponentProps } from 'react';
import useTr, { TranslationFunction } from 'utils/hooks/useTr';

type Props = {
  message: IntlMessageKeys;
  link: IntlMessageKeys;
  values?: ComponentProps<typeof FormattedMessage>['values'];
  linkValues?: Parameters<TranslationFunction>[1];
};

export const ToastErrorWithLink = ({ message, link, values, linkValues }: Props) => {
  const translate = useTr();

  return (
    <FormattedMessage
      id={message}
      values={{
        ...values,
        a: (value: string) => (
          <a href={translate(link, linkValues)} rel="noreferrer" target="_blank">
            {value}
          </a>
        )
      }}
    />
  );
};
