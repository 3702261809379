import { Table, TableBody, TableRow, Typography } from '@mui/material';
import {
  FirstCell,
  SectionTitle,
  StyledCell,
  StyledTableContainer
} from 'components/profile/components/pricing-plan-card/Components';
import { FormattedMessage } from 'react-intl';

const createUsageCreditsData = (
  name: string,
  standardUsage: string,
  proUsage: string,
  enterpriseUsage: string
) => {
  return { name, standardUsage, proUsage, enterpriseUsage };
};

const usageCreditsData = [
  createUsageCreditsData('words', 'standard_usage ', 'pro_usage ', 'enterprise_usage '),
  createUsageCreditsData('images', 'standard_usage ', 'pro_usage ', 'enterprise_usage ')
];

export const UsageCreditsTable = () => {
  return (
    <StyledTableContainer>
      <SectionTitle>
        <FormattedMessage id="features_comparison.usage_credits.label" />
      </SectionTitle>
      <Table>
        <TableBody>
          {usageCreditsData.map(row => (
            <TableRow key={row.name}>
              <FirstCell component="th" scope="row">
                <FormattedMessage id={`features_comparison.usage_credits.${row.name}`} />
              </FirstCell>
              <StyledCell>
                <Typography variant="body1Bold">
                  <FormattedMessage id="features_comparison.usage_credits.standard_usage" />
                </Typography>
              </StyledCell>
              <StyledCell>
                <Typography variant="body1Bold">
                  <FormattedMessage id="features_comparison.usage_credits.pro_usage" />
                </Typography>
              </StyledCell>
              <StyledCell>
                <Typography variant="body1Bold">
                  <FormattedMessage id="features_comparison.usage_credits.enterprise_usage" />
                </Typography>
              </StyledCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </StyledTableContainer>
  );
};
