import { Button, Link } from '@mui/material';
import { ColoredTypography } from 'components/ColoredTypography';
import FlexContainer from 'components/FlexContainer';
import DeleteIconButton from 'components/iconButtons/DeleteIconButton';
import { CloseModal } from 'components/modals/types';
import useUpdateSubscriptionModal from 'features/updateSubscriptionModal/hook/useUpdateSubscriptionModal';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

type ModalProps = CloseModal & {
  fromUpgradeModal?: boolean;
};

export const PricingBusinessPreventionModal = (props: ModalProps) => {
  const { fromUpgradeModal, closeModal } = props;

  const showUpgradeSubscriptionModal = useUpdateSubscriptionModal();

  const handleClick = () => {
    closeModal();

    if (fromUpgradeModal) {
      showUpgradeSubscriptionModal();
    }
  };

  return (
    <Root>
      <FlexContainer direction="row" justifyContent="space-between" alignItems="center">
        <ColoredTypography variant="h5">
          <FormattedMessage id="pricing.upgrade_prevention_modal.title" />
        </ColoredTypography>
        <DeleteIconButton onClick={closeModal} />
      </FlexContainer>

      <ColoredTypography variant="body1">
        <FormattedMessage
          id="pricing.upgrade_prevention_modal.body"
          values={{
            email: (text: string) => (
              <Link href="mailto:magicpen@neuroflash.com" target="_blank" rel="noreferrer">
                {text}
              </Link>
            )
          }}
        />
      </ColoredTypography>

      <FlexContainer direction="row" gap="medium" justifyContent="flex-end">
        <Button onClick={handleClick} variant="contained">
          <FormattedMessage id="common.ok" />
        </Button>
      </FlexContainer>
    </Root>
  );
};

const Root = styled(FlexContainer).attrs({ gap: 'medium' })`
  padding: ${({ theme }) => theme.spacings.xmedium};
`;
