import { Row } from 'react-table';

function datetime(rowA: Row, rowB: Row, columnId: number) {
  const a = rowA.values[columnId];
  const b = rowB.values[columnId];

  const aTime = new Date(a).getTime();
  const bTime = new Date(b).getTime();
  return bTime - aTime;
}

export const sortTypesDict = {
  datetime
};
