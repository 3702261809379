import { ReactComponent as Logo } from 'assets/neuroflash-logo.svg';
import React from 'react';
import styled from 'styled-components';

type Props = {
  children: React.ReactElement;
};

const FormPanel = ({ children }: Props) => (
  <FormPanelWrapper>
    <div className="logo-wrapper">
      <Logo />
    </div>

    {children}
  </FormPanelWrapper>
);

export const FormPanelWrapper = styled.div`
  position: relative;
  width: 100%;
  padding: 2rem;
  flex: 0 0 100%;

  display: flex;
  flex-direction: column;
  background: white;

  @media (min-width: 992px) {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .logo-wrapper {
    max-width: 420px;
    margin-bottom: 1.5rem;

    svg {
      display: block;
      width: 100%;
      height: auto;
    }
  }
`;

export default FormPanel;
