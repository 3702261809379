import { BaseSuccessDto } from 'services/backofficeIntegration/http/BaseSuccessDto';
import { backofficeClient } from 'services/backofficeIntegration/http/client/createBackofficeClient';
import { queryEndpoint } from 'services/backofficeIntegration/http/endpointDefinition';

export type TokenInfoDto = {
  team_id: number;
  team_name: string;
  invited_by_name: string;
};

type ResponseDto = BaseSuccessDto<TokenInfoDto>;

export type GetInvitationInfoParams = {
  token: string;
};

export const httpGetTeamInvitationInfo = queryEndpoint({
  makeQueryKey: (params: GetInvitationInfoParams) => {
    return ['teamInvitationInfo', params] as const;
  },
  callEndpoint: ({ token }: GetInvitationInfoParams) =>
    backofficeClient.get<ResponseDto>(`/teams/invites/${token}`).then(response => response.data)
});
