import { useGlobalConfettiStore } from 'features/global-confetti/useGlobalConfettiStore';
import Confetti from 'react-confetti';
import { IRect } from 'react-confetti/src/Rect';
import useWindowSize from 'utils/hooks/useWindowSize';

const calculateConfettiSource = (sourceElement: HTMLElement | null): IRect | undefined => {
  if (!sourceElement) {
    // Sprinkle confetti from the center of the screen
    return {
      w: 10,
      h: 10,
      x: window.innerWidth / 2,
      y: window.innerHeight / 2
    };
  }

  const elementWidth = sourceElement.offsetWidth;
  const rect = sourceElement.getBoundingClientRect();
  const x = rect.left + window.scrollX;
  const y = rect.top + window.scrollY;

  return {
    w: elementWidth,
    h: 10,
    x,
    y
  };
};
export const GlobalConfetti = () => {
  const { width, height } = useWindowSize();
  const isActive = useGlobalConfettiStore(state => state.isActive);
  const sourceElement = useGlobalConfettiStore(state => state.sourceElement);
  const resetConfetti = useGlobalConfettiStore(state => state.reset);

  const confettiSource = calculateConfettiSource(sourceElement);

  if (!isActive) {
    return null;
  }

  return (
    <Confetti
      run={isActive}
      width={width}
      height={height}
      numberOfPieces={450}
      gravity={0.1}
      initialVelocityX={5}
      initialVelocityY={15}
      wind={0.01}
      recycle={false}
      confettiSource={confettiSource}
      style={{
        width: '100%',
        height: '100vh'
      }}
      onConfettiComplete={instance => {
        resetConfetti();
        instance?.reset();
      }}
    />
  );
};
