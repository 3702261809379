import { assert } from 'utils/typescript/assert';

/**
 * `null` & `undefined` are "nullable" because `null == undefined`
 */
export function isNonNullable<T>(v: T): v is NonNullable<T> {
  return v != null;
}

export function assertNonNullable<T>(
  v: T,
  msg = 'Non nullable value expected'
): asserts v is NonNullable<T> {
  assert(isNonNullable(v), msg);
}

export function forceNonNullable<T>(v: T, msg = 'Non nullable value expected') {
  assert(isNonNullable(v), msg);
  return v;
}
