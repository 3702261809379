import { useQuery } from '@tanstack/react-query';
import { UsageIndicatorType } from 'features/usageIndicator/UsageIndicatorType';
import { makeWordsUsageQueryKeyPrefix } from 'features/wordsUsage/invalidateWordsUsageQueries';
import CustomerAPI from 'services/api/customer';
import SeoAnalysisAPI from 'services/api/seoAnalysis';
import { makeSeoAnalysisUsageQueryKeyPrefix } from 'services/api/seoAnalysis/invalidateSeoAnalysisUsageQueries';
import UnsplashAPI from 'services/api/unsplash';
import { makeUnsplashImagesUsageQueryKeyPrefix } from 'services/api/unsplash/invalidateUnsplashImagesUsageQueries';
import { LimitationsDto } from 'services/backofficeIntegration/http/dtos/LimitationsDto';
import { httpGetAiImagesLimitations } from 'services/backofficeIntegration/http/endpoints/aiImages/httpGetAiImagesLimitations';
import { httpGetPlagiarismCheckLimitations } from 'services/backofficeIntegration/http/endpoints/plagrism/httpGetPlagiarismCheckLimitations';

export type Usage = {
  available: number;
  used: number;
  limit: number;
  isUnlimited: boolean;
  isAvailable?: boolean;
  bonus?: number;
};

export type UsageResult = {
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
  data: Usage | undefined;
  refetch: () => void;
};

export const useGetCurrentUsageByType = (type: UsageIndicatorType): UsageResult => {
  const enableWordUsageQuery =
    type === UsageIndicatorType.textGeneration ||
    type === UsageIndicatorType.chat ||
    type === UsageIndicatorType.gpt4;
  const enableAiImagesUsageQuery = type === UsageIndicatorType.aiImages;
  const enableUnsplashImagesUsageQuery = type === UsageIndicatorType.unsplashImages;
  const enablePlagiarismChecksUsageQuery = type === UsageIndicatorType.plagiarismCheck;
  const enableSeoAnalysisUsageQuery = type === UsageIndicatorType.seoAnalysis;

  const wordUsageQuery = useQuery({
    enabled: enableWordUsageQuery,
    queryKey: makeWordsUsageQueryKeyPrefix(),
    queryFn: () => CustomerAPI.getWordsCurrentUsage()
  });

  const aiImagesUsageQuery = useQuery({
    enabled: enableAiImagesUsageQuery,
    queryKey: httpGetAiImagesLimitations.makeQueryKey(),
    queryFn: () => httpGetAiImagesLimitations.callEndpoint()
  });

  const unsplashImagesUsageQuery = useQuery({
    enabled: enableUnsplashImagesUsageQuery,
    queryKey: makeUnsplashImagesUsageQueryKeyPrefix(),
    queryFn: () => UnsplashAPI.limitations()
  });

  const plagiarismChecksUsageQuery = useQuery({
    enabled: enablePlagiarismChecksUsageQuery,
    queryKey: httpGetPlagiarismCheckLimitations.makeQueryKey(),
    queryFn: () => httpGetPlagiarismCheckLimitations.callEndpoint()
  });

  const seoAnalysisUsageQuery = useQuery({
    enabled: enableSeoAnalysisUsageQuery,
    queryKey: makeSeoAnalysisUsageQueryKeyPrefix(),
    queryFn: () => SeoAnalysisAPI.limitations()
  });

  const getUsageFromLimitationsDto = (data: LimitationsDto) => ({
    available: data.limit - data.used,
    used: data.used,
    limit: data.limit,
    isUnlimited: data.isUnlimited
  });

  if (enableWordUsageQuery) {
    return {
      refetch: () => wordUsageQuery.refetch(),
      isLoading: wordUsageQuery.isLoading,
      isSuccess: wordUsageQuery.isSuccess,
      isError: wordUsageQuery.isError || (wordUsageQuery.isSuccess && !wordUsageQuery.data.status),
      data:
        wordUsageQuery.data && wordUsageQuery.data.status
          ? {
              used: wordUsageQuery.data.data.words_used_current_period,
              available: wordUsageQuery.data.data.words_available,
              limit: wordUsageQuery.data.data.words_limit,
              // add function that runs immediately and return proper value of isUnlimited
              isUnlimited:
                type === UsageIndicatorType.textGeneration
                  ? wordUsageQuery.data.data.text_generation?.is_unlimited || false
                  : type === UsageIndicatorType.chat
                  ? wordUsageQuery.data.data.project_conversation?.is_unlimited || false
                  : wordUsageQuery.data.data.gpt4_project_conversation?.is_unlimited || false,
              isAvailable:
                type === UsageIndicatorType.gpt4
                  ? wordUsageQuery.data.data.gpt4_project_conversation?.is_available
                  : undefined,
              bonus: wordUsageQuery.data.data.bonus_words_available
            }
          : undefined
    };
  }

  if (enableAiImagesUsageQuery) {
    return {
      refetch: () => aiImagesUsageQuery.refetch(),
      isLoading: aiImagesUsageQuery.isLoading,
      isSuccess: aiImagesUsageQuery.isSuccess,
      isError: aiImagesUsageQuery.isError,
      data:
        aiImagesUsageQuery.status && aiImagesUsageQuery.data
          ? getUsageFromLimitationsDto(aiImagesUsageQuery.data)
          : undefined
    };
  }

  if (enableUnsplashImagesUsageQuery) {
    return {
      refetch: () => unsplashImagesUsageQuery.refetch(),
      isLoading: unsplashImagesUsageQuery.isLoading,
      isSuccess: unsplashImagesUsageQuery.isSuccess,
      isError:
        unsplashImagesUsageQuery.isError ||
        (wordUsageQuery.isSuccess && !wordUsageQuery.data.status),
      data:
        unsplashImagesUsageQuery.data && unsplashImagesUsageQuery.data.status
          ? getUsageFromLimitationsDto(unsplashImagesUsageQuery.data.data)
          : undefined
    };
  }

  if (enableSeoAnalysisUsageQuery) {
    return {
      refetch: () => seoAnalysisUsageQuery.refetch(),
      isLoading: seoAnalysisUsageQuery.isLoading,
      isSuccess: seoAnalysisUsageQuery.isSuccess,
      isError:
        seoAnalysisUsageQuery.isError || (wordUsageQuery.isSuccess && !wordUsageQuery.data.status),
      data:
        seoAnalysisUsageQuery.data && seoAnalysisUsageQuery.data.status
          ? getUsageFromLimitationsDto(seoAnalysisUsageQuery.data.data)
          : undefined
    };
  }

  return {
    refetch: () => plagiarismChecksUsageQuery.refetch(),
    isLoading: plagiarismChecksUsageQuery.isLoading,
    isSuccess: plagiarismChecksUsageQuery.isSuccess,
    isError: plagiarismChecksUsageQuery.isError,
    data:
      plagiarismChecksUsageQuery.status && plagiarismChecksUsageQuery.data
        ? getUsageFromLimitationsDto(plagiarismChecksUsageQuery.data)
        : undefined
  };
};
