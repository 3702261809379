import { insertNodes } from '@udecode/plate-common';
import useEditor from 'features/aiWriter/AiWriterTextEditor/hooks/useEditor';
import { markdownToSlate } from 'features/aiWriter/markdown/markdownToSlate';

export function useCopyMarkdownToEditor() {
  const editor = useEditor();

  return function copyToEditor(text: string) {
    if (!editor) return;

    insertNodes(editor, markdownToSlate(text));
  };
}
