import { useQuery } from '@tanstack/react-query';
import { getRoutePath } from 'config/routes';
import { updateCurrentProjectInBackgroundThunk } from 'features/aiWriter/store/actions/project/thunks/updateCurrentProjectInBackground';
import { loadTabThunk } from 'features/aiWriter/store/actions/tabs/thunks/loadTabThunk';
import { unnamed } from 'features/aiWriter/utils/unnamed';
import { LazyLoadedLoadingAnimation } from 'features/loading-spinner/LazyLoadedLoadingAnimation';
import { useDocumentIdFromRunningWorkflow } from 'features/modular-workflow/runner/useDocumentIdFromRunningWorkflow';
import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { httpGetProjectById } from 'services/backofficeIntegration/http/endpoints/aiWriter/httpGetProjectById';
import { invalidateModularWorkflowRunData } from 'services/backofficeIntegration/http/endpoints/modularWorkflow/httpGetModularWorkflowRunData';
import { GAEvents } from 'services/tracking/GAEvents';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
import { assertNonNullable } from 'utils/typescript/nonNullable';

type Props = {
  runningWorkflowId: number;
  runningWorkflowName: string;
};

export const WorkflowDoneState = ({ runningWorkflowId, runningWorkflowName }: Props) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const documentId = useDocumentIdFromRunningWorkflow(runningWorkflowId);

  const { data: project } = useQuery({
    enabled: !!documentId,
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    queryKey: httpGetProjectById.makeQueryKey({ id: documentId! }),
    queryFn: () => {
      assertNonNullable(documentId);

      return httpGetProjectById.callEndpoint({ id: documentId });
    }
  });

  useEffect(() => {
    GAEvents.workflowFinished({
      name: runningWorkflowName
    });
  }, [runningWorkflowName]);

  // Once we got the project, redirect user to the document
  useEffect(() => {
    if (!project) {
      return;
    }

    // This ensures the workflow data is fresh on next visit, which is relevant if the user
    // doesn't reload the page but instantly runs the same workflow again
    invalidateModularWorkflowRunData();

    navigate(getRoutePath('aiWriter'));

    dispatch(
      loadTabThunk({
        ...project,
        name: project.name || unnamed
      })
    );
    dispatch(updateCurrentProjectInBackgroundThunk());
  }, [dispatch, navigate, project]);

  return (
    <AnimationContainer>
      <LazyLoadedLoadingAnimation />
    </AnimationContainer>
  );
};

const AnimationContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
