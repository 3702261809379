import cx from 'classnames';
import React from 'react';

import styles from './Dimmer.module.scss';

type Props = {
  children: React.ReactNode;
  className?: string;
  contentClasses?: string;
  active?: boolean;
  loader?: boolean;
  loaderRelativePosition?: boolean;
};

const Dimmer = ({
  className,
  children,
  active,
  loader,
  contentClasses,
  loaderRelativePosition
}: Props) => {
  const classes = cx({ [styles.dimmer]: true, [styles.active]: active }, className);
  return (
    <div className={classes}>
      {loader && (
        <div className={cx(styles.loader, { [styles.relative]: loaderRelativePosition })} />
      )}
      <div className={cx(styles.dimmerContent, contentClasses)}>{children}</div>
    </div>
  );
};

export default Dimmer;
