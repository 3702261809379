import { TextField } from '@mui/material';
import { useModal } from 'components/modals';
import ConfirmModal from 'components/modals/ConfirmModal';
import { CloseModal } from 'components/modals/types';
import { ChangeEvent, useState } from 'react';

type CustomProps = {
  categoryName: string;
  isLoading?: boolean;

  onRename: (newName: string) => void;
};

type Props = CloseModal & CustomProps;

export function CategoryRenameModal(props: Props) {
  const [newName, setNewName] = useState(props.categoryName);

  const handleRename = () => {
    props.onRename(newName);
    props.closeModal();
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setNewName(e.target.value);
  };

  return (
    <ConfirmModal
      title="flashHub.information.table.tag.menu.rename_modal.title"
      onCancel={props.closeModal}
      cancelText="common.cancel"
      onOK={handleRename}
      okText="common.confirm"
      isLoading={props.isLoading}
    >
      <TextField fullWidth value={newName} onChange={handleChange} />
    </ConfirmModal>
  );
}

export const useCategoryRenameModal = () => {
  const { showModal } = useModal();

  return (props: CustomProps) => {
    showModal('CATEGORY_RENAME', {
      ...props,
      size: 480
    });
  };
};
