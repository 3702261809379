import FormatAlignCenterIcon from '@mui/icons-material/FormatAlignCenter';
import FormatAlignJustifyIcon from '@mui/icons-material/FormatAlignJustify';
import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft';
import FormatAlignRightIcon from '@mui/icons-material/FormatAlignRight';
import { MenuItem, Tooltip } from '@mui/material';
import { useAlignDropdownMenu, useAlignDropdownMenuState } from '@udecode/plate-alignment';
import { MenuDropdown } from 'components/MenuDropdown/MenuDropdown';
import {
  EditorFormatButton,
  EditorFormatButtonProps,
  EditorIconFormatButton
} from 'features/aiWriter/AiWriterTextEditor/EditorIconFormatButton';
import { trackEditorFormatAction } from 'features/aiWriter/AiWriterTextEditor/trackEditorFormatAction';
import { getIconColor } from 'features/aiWriter/AiWriterTextEditor/utils/getIconColor';
import { topBarDropdownAlignmentConfig } from 'features/aiWriter/AiWriterTextEditor/utils/topBarDropdownAlignmentConfig';
import FormattedMessage from 'features/i18n/FormattedMessage';
import useDropdown from 'utils/hooks/useDropdown';

const items = [
  {
    value: 'left',
    icon: <FormatAlignLeftIcon color={getIconColor(false)} fontSize="small" />
  },
  {
    value: 'center',
    icon: <FormatAlignCenterIcon color={getIconColor(false)} fontSize="small" />
  },
  {
    value: 'right',
    icon: <FormatAlignRightIcon color={getIconColor(false)} fontSize="small" />
  },
  {
    value: 'justify',
    icon: <FormatAlignJustifyIcon color={getIconColor(false)} fontSize="small" />
  }
];

type AlignTextDropdownProps = {
  styleVariant?: EditorFormatButtonProps;
  onClose?: () => void;
};

export function AlignTextDropdown({ onClose, styleVariant }: AlignTextDropdownProps) {
  const { anchorElement, close, isOpen, open } = useDropdown();

  const state = useAlignDropdownMenuState();
  const { radioGroupProps } = useAlignDropdownMenu(state);

  const iconValue = items.find(item => item.value === radioGroupProps.value)?.icon ?? (
    <FormatAlignLeftIcon fontSize="small" />
  );

  const handleClose = () => {
    close();
    onClose && onClose();
  };

  const handleClick = (value: string) => {
    trackEditorFormatAction('text_align');

    radioGroupProps.onValueChange(value);

    handleClose();
  };

  const trigger =
    styleVariant === 'icon' ? (
      <EditorIconFormatButton $isActive={false} onClick={open}>
        <Tooltip arrow title={<FormattedMessage id="aiWriter.toolbar.align" />} placement="bottom">
          {iconValue}
        </Tooltip>
      </EditorIconFormatButton>
    ) : (
      <EditorFormatButton $isActive={false} onClick={open}>
        {iconValue}
        <FormattedMessage id="aiWriter.toolbar.indent" />
      </EditorFormatButton>
    );

  return (
    <MenuDropdown
      trigger={trigger}
      anchorElement={anchorElement}
      close={handleClose}
      isOpen={isOpen}
      width="fit-content"
      anchorOrigin={topBarDropdownAlignmentConfig.leftAlignment.anchorOrigin}
      transformOrigin={topBarDropdownAlignmentConfig.leftAlignment.transformOrigin}
    >
      {items.map(({ value, icon }) => (
        <MenuItem key={value} onClick={() => handleClick(value)}>
          {icon}
        </MenuItem>
      ))}
    </MenuDropdown>
  );
}
