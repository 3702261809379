import { IconButton, IconButtonProps, Tooltip } from '@mui/material';
import { ColoredHelpIcon } from 'features/aiWriter/AiWriterSidebar/steps/extraIcons';
import { withGtmInteraction } from 'services/tracking/withGtmInteraction';

type Props = IconButtonProps & {
  tooltip: string;
  gtmId?: string;
};

export function BasicInfoButton({ gtmId, tooltip, onClick, size = 'small', ...props }: Props) {
  const content = (
    <IconButton {...withGtmInteraction(gtmId)} onClick={onClick} size={size} {...props}>
      <ColoredHelpIcon />
    </IconButton>
  );

  return <Tooltip title={tooltip}>{content}</Tooltip>;
}
