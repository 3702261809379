import { GeneratedText, GenerateTextEntry, GenerateTextOutputEntry } from 'features/textGenerator/store/types';
import { ApiGenerateTextEntry, ApiGenerateTextOutputEntry } from 'services/api/wordEmbedding/types';

export const generateTextEntryFromApi = (entry: ApiGenerateTextEntry): GenerateTextEntry => ({
  outputs: entry.outputs.map(generateTextEntryOutputFromApi)
});

const generateTextEntryOutputFromApi = (
  entry: ApiGenerateTextOutputEntry
): GenerateTextOutputEntry => ({
  text: entry.text,
  generationId: entry.generation_id,
  textItemId: entry.text_item_id
});

export const textsFromGenerateTextOutputEntries = (entries: GenerateTextOutputEntry[]) =>
  entries.map(o => o.text);

export const generatedTextsFromGenerateTextOutputEntries = (
  entries: GenerateTextOutputEntry[]
): GeneratedText[] =>
  entries.map(o => ({
    id: o.textItemId,
    text: o.text,
    generationId: o.generationId,
    textItemId: o.textItemId
  }));
