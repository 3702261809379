import { useSortable } from '@dnd-kit/sortable';
import { CSS, Transform } from '@dnd-kit/utilities';
import { CSSProperties, ReactNode } from 'react';

type RenderChildrenProps = {
  isDragging: boolean;
};

type Props = {
  id: string;
  children: (props: RenderChildrenProps) => ReactNode;
};

export const SortableStepBoxWrapper = ({ id, children }: Props) => {
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
    id
  });

  // The provided scale leads to a blurry rendering of the dragged element;
  // Therefore, we fix the scale to 1
  const transformWithFixedScale: Transform = {
    ...(transform as Transform),
    scaleX: 1,
    scaleY: 1
  };

  const style: CSSProperties = {
    transform: CSS.Transform.toString(transformWithFixedScale),
    transition,
    zIndex: isDragging ? 1 : 0
  };

  return (
    <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
      {children({ isDragging })}
    </div>
  );
};
