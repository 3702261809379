import { LoadingButton } from '@mui/lab';
import { Button, Typography } from '@mui/material';
import {
  ModalBodyWithColumnDirectionAndSpaceBetween,
  ModalWithDividedHeaderLayout,
  RightAlignedFooter
} from 'features/theme-2024/ModalWithDividedHeaderLayout';
import React, { ComponentProps } from 'react';
import { FormattedMessage } from 'react-intl';
import { GtmAttributes } from 'services/tracking/withGtmInteraction';

type Props = {
  message?: string;
  title?: string;
  isLoading?: boolean;
  disableButtons?: boolean;
  okText?: string;
  cancelText?: string;
  messageValues?: Record<string, unknown>;
  children?: React.ReactNode;
  okButtonProps?: Omit<ComponentProps<typeof Button>, 'onClick' | 'disabled'> & GtmAttributes;

  onCancel: () => void;
  onOK: () => void;
};

const ConfirmModal = ({
  onOK,
  message,
  title,
  onCancel,
  isLoading = false,
  disableButtons = false,
  okText = 'common.ok',
  cancelText = 'common.cancel',
  messageValues,
  okButtonProps,
  children
}: Props) => (
  <ModalWithDividedHeaderLayout
    title={<FormattedMessage id={title || 'common.confirm'} />}
    closeModal={onCancel}
    slots={{
      bodyContainer: ModalBodyWithColumnDirectionAndSpaceBetween,
      footerContainer: RightAlignedFooter
    }}
    footer={
      <>
        <Button onClick={onCancel} disabled={disableButtons}>
          <FormattedMessage id={cancelText} />
        </Button>
        <LoadingButton
          {...okButtonProps}
          loading={isLoading}
          variant="contained"
          onClick={onOK}
          disabled={disableButtons}
        >
          <FormattedMessage id={okText} />
        </LoadingButton>
      </>
    }
  >
    {children}

    {message && (
      <Typography variant="body1">
        <FormattedMessage id={message} values={messageValues} />
      </Typography>
    )}
  </ModalWithDividedHeaderLayout>
);

export default ConfirmModal;
