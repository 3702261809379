import { Typography } from '@mui/material';
import FlexContainer from 'components/FlexContainer';
import { StretchedCardContent } from 'features/aiWriter/AiWriterSidebar/steps/flashScore/FlashScoreAnalyzeView';
import {
  GetSuggestionsButton,
  SuggestionType
} from 'features/aiWriter/AiWriterSidebar/steps/flashScore/GetSuggestionsButton';
import FormattedMessage from 'features/i18n/FormattedMessage';
import styled from 'styled-components';

type Props = {
  onSuggestionSelected: (suggestionType: SuggestionType) => void;
};

export const GetSuggestionsCard = ({ onSuggestionSelected }: Props) => (
  <>
    <FlexContainer>
      <StretchedCardContent>
        <FlexContainer
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          gap="small"
        >
          <Typography fontWeight="bold">
            <FormattedMessage id="aiWriter.inspirations.flashScore.want_suggestions" />
          </Typography>

          <GetSuggestionsButton onSuggestionSelected={onSuggestionSelected}>
            <FormattedMessage id="aiWriter.inspirations.flashScore.cta_get_suggestions" />
          </GetSuggestionsButton>
        </FlexContainer>
      </StretchedCardContent>
    </FlexContainer>

    <GrayText variant="body2">
      <FormattedMessage id="aiWriter.inspirations.flashScore.bottom_tip" />
    </GrayText>
  </>
);

const GrayText = styled(Typography)`
  color: ${({ theme }) => theme.colors.textMuted};
`;
