import { ColoredTypography } from 'components/ColoredTypography';
import FlexContainer from 'components/FlexContainer';
import { HelpLinkingButton } from 'components/HelpLinkingButton';
import DeleteIconButton from 'components/iconButtons/DeleteIconButton';
import { CloseModal } from 'components/modals/types';
import FormattedMessage from 'features/i18n/FormattedMessage';
import { ComponentProps, ReactNode } from 'react';
import styled from 'styled-components';

type Props = {
  children: ReactNode;
  helpLink?: ComponentProps<typeof HelpLinkingButton>['link'];
  helpLinkTooltip?: ReactNode;
  additionalActions?: ReactNode;
} & CloseModal;

export const ModalHeader = ({
  children,
  additionalActions,
  helpLink,
  helpLinkTooltip,
  closeModal
}: Props) => (
  <Root>
    <FlexContainer direction="row" justifyContent="space-between" alignItems="flex-start">
      <ColoredTypography variant="h5">{children}</ColoredTypography>

      <FlexContainer direction="row" alignItems="center" gap="two">
        {additionalActions}

        {helpLink && <HelpLinkingButton link={helpLink} tooltip={helpLinkTooltip} />}

        <DeleteIconButton onClick={closeModal} tooltip={<FormattedMessage id="common.close" />} />
      </FlexContainer>
    </FlexContainer>
  </Root>
);

const Root = styled.div`
  padding-bottom: ${({ theme }) => theme.spacings.three};
`;
