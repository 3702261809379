import { createReducer } from '@reduxjs/toolkit';
import cuid from 'cuid';
import {
  changeBriefConfig,
  changeGoalConfig,
  setIsTourFinished,
  setIsTourRunning,
  setIsViewExtended,
  setSortByScore
} from 'features/aiTester/store/actions/config/actions';
import { setLegendParameter, sortByScore } from 'features/aiTester/store/actions/general/actions';
import {
  deleteProject,
  insertProject,
  updateCurrentProjectInBackground,
  updateProjects
} from 'features/aiTester/store/actions/project/actions';
import { removeSubSuggestion } from 'features/aiTester/store/actions/subSuggestions/actions';
import {
  generateSuggestions,
  improveGoal,
  refreshSuggestions,
  removeSuggestion,
  rescoreSuggestions,
  selectSuggestion
} from 'features/aiTester/store/actions/suggestions/actions';
import {
  closeTab,
  initializeTab,
  loadTab,
  renameCurrentTab,
  setCurrentTab
} from 'features/aiTester/store/actions/tabs/actions';
import {
  addText,
  duplicateText,
  removeText,
  scoreSingleText,
  scoreTexts,
  setIsTextEditing,
  updateText
} from 'features/aiTester/store/actions/texts/actions';
import { fetchWordSynonyms, scoreWords } from 'features/aiTester/store/actions/words/actions';
import { Suggestion, TesterState, TestingContent } from 'features/aiTester/store/types';
import createNewText from 'features/aiTester/store/utils/defaults/createNewText';
import { defaultTesterState } from 'features/aiTester/store/utils/defaults/defaultTesterState';
import { getCurrentTabText } from 'features/aiTester/store/utils/getters/getCurrentTabText';
import { getCurrentTabTextWord } from 'features/aiTester/store/utils/getters/getCurrentTabTextWord';
import { trackingWrapper } from 'features/tracking/wrapper/TrackingWrapper';
import { switchLoadingDefault } from 'store/utils/genericReducers';
import {
  closeTabReducer,
  getCurrentTab,
  getTabById,
  renameCurrentTabReducer,
  setCurrentTabReducer
} from 'store/utils/tabUtils';

const testerReducer = createReducer<TesterState>(defaultTesterState, builder =>
  builder
    .addCase(setCurrentTab, setCurrentTabReducer)
    .addCase(renameCurrentTab, renameCurrentTabReducer)
    .addCase(closeTab, closeTabReducer)
    .addCase(initializeTab, (state, { payload }) => {
      state.currentTab = payload.id;
      state.tabs[payload.id] = payload;
    })
    .addCase(loadTab.request, switchLoadingDefault(true))
    .addCase(loadTab.success, (state, { payload }) => {
      state.currentTab = payload.id;
      state.tabs = { ...state.tabs, [payload.id]: payload };
    })
    .addCase(loadTab.failure, switchLoadingDefault(false))
    .addCase(updateCurrentProjectInBackground.request, (state, { payload: { tabId } }) => {
      const tab = getTabById(tabId, state);
      if (!tab) {
        return;
      }

      tab.isSaving = true;
    })
    .addCase(updateCurrentProjectInBackground.success, (state, { payload: { project } }) => {
      const tab = getTabById(project.id, state);
      if (!tab) {
        return;
      }

      tab.isSaving = false;

      const index = state.projects.findIndex(f => f.id === project.id);
      if (index !== -1) {
        state.projects[index] = project;
      }
    })
    .addCase(updateCurrentProjectInBackground.failure, (state, { payload: { tabId } }) => {
      const tab = getTabById(tabId, state);
      if (!tab) {
        return;
      }

      tab.isSaving = false;
    })
    .addCase(insertProject, (state, { payload }) => {
      state.projects = [payload, ...state.projects];
    })
    .addCase(updateProjects, (state, { payload }) => {
      state.projects = payload;
    })
    .addCase(deleteProject.request, (state, { payload }) => {
      const index = state.projects.findIndex(f => f.id === payload.id);
      if (index !== -1) {
        state.projects[index].isLoading = true;
      }
    })
    .addCase(deleteProject.success, (state, { payload }) => {
      const index = state.projects.findIndex(f => f.id === payload.id);
      if (index !== -1) {
        state.projects.splice(index, 1);
      }
    })
    .addCase(deleteProject.failure, (state, { payload }) => {
      const index = state.projects.findIndex(f => f.id === payload.id);
      if (index !== -1) {
        state.projects[index].isLoading = false;
      }
    })
    .addCase(scoreTexts.request, switchLoadingDefault(true))
    .addCase(scoreTexts.success, (state, { payload: { tabId, texts, legend, legendOrder } }) => {
      state.isLoading = false;

      const tab = getTabById(tabId, state);
      if (!tab) {
        return;
      }

      tab.texts = texts;
      tab.legend = legend;
      tab.legendOrder = legendOrder;
    })
    .addCase(scoreTexts.failure, switchLoadingDefault(false))
    .addCase(scoreWords.request, (state, { payload: { tabId, textIds } }) => {
      getTabById(tabId, state)?.texts.forEach(text => {
        if (textIds.some(id => id === text.id)) {
          text.isLoading = true;
        }
      });
    })
    .addCase(scoreWords.success, (state, { payload: { tabId, results } }) => {
      getTabById(tabId, state)?.texts.forEach(text => {
        if (results[text.id]) {
          text.tokenData = results[text.id];
        }

        text.isLoading = false;
      });
    })
    .addCase(scoreWords.failure, (state, { payload: { tabId } }) => {
      getTabById(tabId, state)?.texts.forEach(text => {
        text.isLoading = false;
      });
    })
    .addCase(setLegendParameter, (state, { payload }) => {
      const currentTab = getCurrentTab(state);
      if (!currentTab) {
        return;
      }

      const tabLegendField = currentTab.legend[payload.field];
      if (payload.color != null) {
        tabLegendField.color = payload.color;
      }

      if (payload.active != null) {
        tabLegendField.active = payload.active;
      }
    })
    .addCase(addText, state => {
      const currentTab = getCurrentTab(state);
      if (!currentTab) {
        return;
      }

      currentTab.texts.push(createNewText());
    })
    .addCase(setIsTextEditing, (state, { payload: { id, isEditing } }) => {
      const currentTab = getCurrentTab(state);
      if (!currentTab) {
        return;
      }

      const { texts } = currentTab;
      const index = texts.findIndex(t => t.id === id);

      if (index !== -1) {
        texts[index].isEditing = isEditing;
      }
    })
    .addCase(removeText, (state, { payload }) => {
      const currentTab = getCurrentTab(state);
      if (!currentTab) {
        return;
      }

      const { texts } = currentTab;

      const textIndex = texts.findIndex(t => t.id === payload.id);

      if (textIndex !== -1) {
        texts.splice(textIndex, 1);
      }
    })
    .addCase(duplicateText, (state, { payload: { id } }) => {
      const currentTab = getCurrentTab(state);
      if (!currentTab) {
        return;
      }

      const { texts } = currentTab;

      const text = texts.find(t => t.id === id);

      if (text) {
        texts.push({ ...text, id: cuid() });
      }
    })
    .addCase(updateText.request, (state, { payload: { id } }) => {
      const currentTab = getCurrentTab(state);
      if (!currentTab) {
        return;
      }

      const { texts } = currentTab;
      const index = texts.findIndex(t => t.id === id);

      if (index !== -1) {
        texts[index].isLoading = true;
      }
    })
    .addCase(updateText.success, (state, { payload: { tabId, id, text, words } }) => {
      const tab = getTabById(tabId, state);
      if (!tab) {
        return;
      }

      const { texts } = tab;
      const index = texts.findIndex(t => t.id === id);
      if (index !== -1) {
        texts[index].text = text;
        texts[index].words = words;
        texts[index].isEditing = false;
        texts[index].isLoading = false;
      }
    })
    .addCase(updateText.failure, (state, { payload: { id } }) => {
      const currentTab = getCurrentTab(state);
      if (!currentTab) {
        return;
      }

      const { texts } = currentTab;
      const index = texts.findIndex(t => t.id === id);

      if (index !== -1) {
        texts[index].isLoading = false;
      }
    })
    .addCase(scoreSingleText.request, (state, { payload: { id } }) => {
      const currentTab = getCurrentTab(state);
      if (!currentTab) {
        return;
      }

      const { texts } = currentTab;
      const index = texts.findIndex(t => t.id === id);

      if (index !== -1) {
        texts[index].isLoading = true;
      }
    })
    .addCase(
      scoreSingleText.success,
      (state, { payload: { id, scoredText, tabId, legend, legendOrder } }) => {
        const tab = getTabById(tabId, state);
        if (!tab) {
          return;
        }

        const index = tab.texts.findIndex(t => t.id === id);
        tab.legend = legend;
        tab.legendOrder = legendOrder;
        if (index !== -1) {
          tab.texts[index] = scoredText;
        }
      }
    )
    .addCase(scoreSingleText.failure, (state, { payload: { id, tabId } }) => {
      const tab = getTabById(tabId, state);
      if (!tab) {
        return;
      }

      const { texts } = tab;
      const index = texts.findIndex(t => t.id === id);
      if (index !== -1) {
        texts[index].isLoading = false;
      }
    })
    .addCase(
      changeGoalConfig,
      (state, { payload: { wordAttributes, scoreByOpenRate, dimensions, manualDimensions } }) => {
        const currentTab = getCurrentTab(state);
        if (!currentTab) {
          return;
        }

        currentTab.wordAttributes = wordAttributes;
        currentTab.scoreByOpenRate = scoreByOpenRate;
        currentTab.dimensions = dimensions;
        currentTab.manualDimensions = manualDimensions;
      }
    )
    .addCase(
      changeBriefConfig,
      (
        state,
        { payload: { text, brand, keywords, outputType, personalityId, informationList } }
      ) => {
        const currentTab = getCurrentTab(state);
        if (!currentTab || !currentTab.generateTextConfig) {
          return;
        }

        currentTab.generateTextConfig = {
          ...currentTab.generateTextConfig,
          outputType,
          text,
          brand,
          keywords,
          personalityId,
          informationList
        };
      }
    )
    .addCase(selectSuggestion, (state, { payload: { id, text, textItemId, generationId } }) => {
      const currentTab = getCurrentTab(state);
      if (!currentTab) {
        return;
      }

      const newText = createNewText({ id, text, textItemId, generationId, isEditing: false });
      const { texts, id: tabId, embeddingModelId } = currentTab;

      if (texts.length === 1 && !texts[0].text) {
        texts[0] = newText;
      } else {
        texts.push(newText);
      }

      // TODO Redux function should be pure. Remove it
      trackingWrapper.track('aiTesterSelectSuggestion', {
        documentId: tabId,
        text,
        textItemId,
        generationId,
        embeddingModelId
      });
    })
    .addCase(generateSuggestions.request, state => {
      const currentTab = getCurrentTab(state);
      if (!currentTab) {
        return;
      }

      currentTab.isSuggestionsLoading = true;
    })
    .addCase(generateSuggestions.success, (state, { payload }) => {
      const currentTab = getTabById(payload.tabId, state);
      if (!currentTab) {
        return;
      }

      currentTab.isSuggestionsLoading = false;
      currentTab.suggestions = payload.suggestions;
    })
    .addCase(generateSuggestions.failure, (state, { payload }) => {
      const currentTab = getTabById(payload.tabId, state);
      if (!currentTab) {
        return;
      }

      currentTab.isSuggestionsLoading = false;
    })
    .addCase(refreshSuggestions.request, (state, { payload: { id, source } }) => {
      const currentTab = getCurrentTab(state);
      if (!currentTab) {
        return;
      }

      const { [source]: content } = currentTab;
      const contentIndex = content.findIndex((t: TestingContent | Suggestion) => t.id === id);
      if (contentIndex !== -1) {
        content[contentIndex].isLoading = true;
      }
    })
    .addCase(
      refreshSuggestions.success,
      (state, { payload: { tabId, textId, source, suggestions } }) => {
        const currentTab = getTabById(tabId, state);
        if (!currentTab) {
          return;
        }

        const contentIndex = currentTab[source].findIndex(
          (t: TestingContent | Suggestion) => t.id === textId
        );
        if (contentIndex !== -1) {
          currentTab[source][contentIndex].isLoading = false;
          currentTab[source][contentIndex].subSuggestions = [...suggestions];
        }
      }
    )
    .addCase(refreshSuggestions.failure, (state, { payload: { id, source } }) => {
      const currentTab = getCurrentTab(state);
      if (!currentTab) {
        return;
      }

      const { [source]: content } = currentTab;
      const contentIndex = content.findIndex((t: TestingContent | Suggestion) => t.id === id);
      if (contentIndex !== -1) {
        content[contentIndex].isLoading = true;
      }
    })
    .addCase(improveGoal.request, (state, { payload: { textId } }) => {
      const currentTab = getCurrentTab(state);
      if (!currentTab) {
        return;
      }

      const contentIndex = currentTab.texts.findIndex(
        (t: TestingContent | Suggestion) => t.id === textId
      );
      if (contentIndex !== -1) {
        currentTab.texts[contentIndex].isLoading = true;
      }
    })
    .addCase(improveGoal.success, (state, { payload: { tabId, textId } }) => {
      const currentTab = getTabById(tabId, state);
      if (!currentTab) {
        return;
      }

      const contentIndex = currentTab.texts.findIndex(
        (t: TestingContent | Suggestion) => t.id === textId
      );
      if (contentIndex !== -1) {
        currentTab.texts[contentIndex].isLoading = false;
      }
    })
    .addCase(improveGoal.failure, (state, { payload: { textId } }) => {
      const currentTab = getCurrentTab(state);
      if (!currentTab) {
        return;
      }

      const contentIndex = currentTab.texts.findIndex(
        (t: TestingContent | Suggestion) => t.id === textId
      );
      if (contentIndex !== -1) {
        currentTab.texts[contentIndex].isLoading = false;
      }
    })
    .addCase(rescoreSuggestions.request, state => state)
    .addCase(rescoreSuggestions.success, (state, { payload: { tabId, scoredTexts } }) => {
      const currentTab = getTabById(tabId, state);

      currentTab?.suggestions.forEach((suggestion, i) => {
        if (suggestion.text === scoredTexts[i].text) {
          suggestion.score = scoredTexts[i].score;
        }
      });
    })
    .addCase(rescoreSuggestions.failure, state => state)
    .addCase(removeSuggestion, (state, { payload: { id, triggerSource } }) => {
      const currentTab = getCurrentTab(state);
      if (!currentTab) {
        return;
      }

      const { suggestions, id: tabId, embeddingModelId } = currentTab;
      const index = suggestions.findIndex(t => t.id === id);
      const text = suggestions[index] ?? null;
      if (index !== -1) {
        suggestions.splice(index, 1);
      }

      // TODO Redux function should be pure. Remove it
      if (triggerSource === 'user') {
        trackingWrapper.track('aiTesterDeleteSuggestion', {
          documentId: tabId,
          text: text?.text ?? null,
          textItemId: text?.textItemId ?? null,
          generationId: text?.generationId ?? null,
          embeddingModelId
        });
      }
    })
    .addCase(removeSubSuggestion, (state, { payload: { parentId, id, source, triggerSource } }) => {
      const currentTab = getCurrentTab(state);
      if (!currentTab) {
        return;
      }

      const { [source]: content, id: tabId, embeddingModelId } = currentTab;
      const contentIndex = content.findIndex((t: TestingContent | Suggestion) => t.id === parentId);
      const text = content[contentIndex] ?? null;

      if (contentIndex !== -1) {
        const subSuggestionIndex = content[contentIndex].subSuggestions.findIndex(t => t.id === id);
        if (subSuggestionIndex !== -1) {
          content[contentIndex].subSuggestions.splice(subSuggestionIndex, 1);
        }
      }

      // TODO Redux function should be pure. Remove it
      if (triggerSource === 'user') {
        trackingWrapper.track('aiTesterDeleteSuggestion', {
          documentId: tabId,
          text: text?.text ?? null,
          textItemId: text?.textItemId ?? null,
          generationId: text?.generationId ?? null,
          embeddingModelId
        });
      }
    })
    .addCase(fetchWordSynonyms.request, (state, { payload: { textId, wordId } }) => {
      const word = getCurrentTabTextWord({ state, textId, wordId });

      if (word) {
        word.isLoading = true;
      }
    })
    .addCase(
      fetchWordSynonyms.success,
      (state, { payload: { tabId, textId, wordId, tokenData, synonyms } }) => {
        const text = getCurrentTabText({ state, tabId, textId });
        const word = getCurrentTabTextWord({ state, tabId, textId, wordId });

        if (text && word) {
          word.synonyms = synonyms;
          word.isLoading = false;

          text.tokenData = { ...text.tokenData, ...tokenData };
        }
      }
    )
    .addCase(fetchWordSynonyms.failure, (state, { payload: { tabId, textId, wordId } }) => {
      const word = getCurrentTabTextWord({ state, tabId, textId, wordId });

      if (word) {
        word.isLoading = false;
      }
    })
    .addCase(setIsViewExtended, (state, { payload }) => {
      state.isViewExtended = payload;
    })
    .addCase(setSortByScore, (state, { payload }) => {
      const currentTab = getCurrentTab(state);
      if (!currentTab) {
        return;
      }

      currentTab.sortByScore = payload;
    })
    .addCase(sortByScore, state => {
      const currentTab = getCurrentTab(state);
      if (!currentTab) {
        return;
      }

      currentTab.texts = currentTab.texts
        .sort((a, b) => b.goalScore - a.goalScore)
        .map(t => ({ ...t, score: undefined }));
    })
    .addCase(setIsTourRunning, (state, { payload }) => {
      state.isTourRunning = payload;
    })
    .addCase(setIsTourFinished, (state, { payload }) => {
      state.isTourFinished = payload;
    })
);

export default testerReducer;
