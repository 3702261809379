import { ReactElement, useState } from 'react';
import styled from 'styled-components';
import { resetButton } from 'styles/styledComponents/resets';

type Props = {
  label: ReactElement;
  initialyOpen?: boolean;
  children: ReactElement;

  onClick?: () => void;
};

export const CollapsiblePanel = ({
  label,
  initialyOpen,
  onClick,
  children
}: Props): ReactElement => {
  const [isOpen, setIsOpen] = useState(initialyOpen ?? false);

  if (isOpen) {
    return children;
  }

  return (
    <OpenButton
      type="button"
      onClick={() => {
        setIsOpen(true);
        onClick?.();
      }}
    >
      {label}
    </OpenButton>
  );
};

const OpenButton = styled.button`
  ${resetButton}

  color:${({ theme }) => theme.colors.primary};
  text-align: left;
`;
