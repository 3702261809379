import { login } from 'features/customer/store/actions';
import { getUserData, saveUserDataToStorage } from 'features/customer/store/utils';
import { useNavigate } from 'react-router';
import { CustomerData } from 'services/api/customer/types';
import { GAEvents } from 'services/tracking/GAEvents';
import { useAppDispatch } from 'store/hooks';

export const useLoginAfterTeamInvitation = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  return async (data: CustomerData) => {
    const userData = getUserData({ ...data, tax_id: '', tax_type: '' } as CustomerData);

    // Track login
    GAEvents.userLogin({
      userId: userData.id,
      userEmail: userData.email,
      type: 'email'
    });

    dispatch(login.success(userData));

    saveUserDataToStorage(userData, data.is_first_login);

    navigate('/');
  };
};
