import { PersonalityChatSelector } from 'components/personality/PersonalityChatSelector';
import { useShowChatPersonalityResetChatModal } from 'features/aiWriter/AiWriterSidebar/steps/chat/ChatPersonalityResetChatModal';
import {
  useChatCurrentGptModel,
  useIsChatEmpty
} from 'features/aiWriter/AiWriterSidebar/steps/chat/chatStore';
import { useDocumentPersonalityData } from 'features/aiWriter/AiWriterSidebar/steps/chat/useDocumentPersonalityData';
import { useResetConversationMutation } from 'features/aiWriter/AiWriterSidebar/steps/chat/useResetConversationMutation';
import { useUpdateAiWriterAndDefaultPersonality } from 'features/aiWriter/AiWriterSidebar/steps/chat/useUpdateAiWriterAndDefaultPersonality';
import { getActiveTab } from 'features/aiWriter/store/selectors';
import { PersonalityDto } from 'services/backofficeIntegration/http/dtos/PersonalityDto';
import { useAppSelector } from 'store/hooks';

export function ChatPersonalitySelection() {
  const activeTab = useAppSelector(getActiveTab);

  const { data: personalityData } = useDocumentPersonalityData();

  const updateAiWriterPersonality = useUpdateAiWriterAndDefaultPersonality();

  const { mutate: resetConversation } = useResetConversationMutation();
  const showChatPersonalityResetChatModal = useShowChatPersonalityResetChatModal();
  const isChatEmpty = useIsChatEmpty();

  const gptModel = useChatCurrentGptModel();

  const handlePersonality = (personality: PersonalityDto | undefined | null) => {
    updateAiWriterPersonality({ personality });
  };

  const handlePersonalityChange = (personality: PersonalityDto | undefined | null) => {
    if (isChatEmpty) {
      const id = personality?.id ?? null;
      resetConversation({ personalityId: id, gptModel: gptModel ?? null });

      handlePersonality(personality);
      return;
    }

    // Hack: If the user selected the personality from within the library modal, it's still open
    //       Therefore we've to wait a bit before showing the chat reset modal
    setTimeout(() => {
      showChatPersonalityResetChatModal({
        onAccept: async () => {
          resetConversation({ personalityId: personality?.id ?? null, gptModel: gptModel ?? null });
          handlePersonality(personality);
        }
      });
    }, 10);
  };

  return (
    <PersonalityChatSelector
      languageModelId={activeTab.embeddingModelId}
      value={personalityData ?? null}
      onChange={handlePersonalityChange}
    />
  );
}
