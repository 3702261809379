import { AppLanguage } from 'features/language/store/types';
import { LocalizedObject } from 'features/modular-workflow/builder/types-builder';

export const getLocalizedObject = (
  value: string,
  language: AppLanguage,
  currentValue: LocalizedObject
): LocalizedObject => {
  return {
    [AppLanguage.English]: currentValue[AppLanguage.English] || value,
    [AppLanguage.German]: currentValue[AppLanguage.German] || value,
    [AppLanguage.Spanish]: currentValue[AppLanguage.Spanish] || value,
    [language]: value
  };
};
