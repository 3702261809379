import { format as defaultFormat, formatLocale } from 'd3-format';
import { uniqueId } from 'lodash';
import { ManualDimension } from 'services/api/dimension/types';
import { WordTag } from 'types/WordTag';

export const prepareWord = (word: string): string => word.trim().toLowerCase().replace(/\s+/g, '_');

export const prepareWords = (words: string[]): string[] => words.filter(String).map(prepareWord);

export const formatWordClassName = (word: string): string =>
  encodeURIComponent(prepareWord(word)).replace(/%(u?[a-z0-9]{2})/gi, '-sch-$1-');

export const tagsToWords = (tags: Array<WordTag | string>): string[] =>
  prepareWords(
    tags.map(tag => {
      if (typeof tag === 'string') {
        return tag;
      }
      return tag.word;
    })
  );

export const wordsToTags = (words: string[]) =>
  words.map(word => ({
    word,
    isAvailable: true,
    checked: true
  }));

export const createManualDimension = (words: WordTag[], id?: number): ManualDimension => {
  return {
    id: id || parseInt(uniqueId(), 10) + 100000,
    words,
    label: words.map(tag => `"${tag.word}"`).join(', ')
  };
};

const locale = formatLocale({
  decimal: '.',
  thousands: '\u00A0',
  currency: ['€', ''],
  grouping: [3]
});

export const formatNumber = defaultFormat('.2f');

export const formatToPercentage = locale.format(',.0%');

export const formatToPercentageWithHundredths = locale.format(',.2%');

export const formatToPercentageValue = (n: number): string => {
  return defaultFormat('.0d')(n * 100);
};

export const formatter = locale.format;
