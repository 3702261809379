import AxiosClient from '..';
import {
  SeoAnalysisRequest,
  SeoAnalysisResponse,
  SeoLimitationsResponse,
  TermSuggestionsRequest,
  TermSuggestionsResponse
} from './types';

const SeoAnalysisAPI = {
  analyse: (payload: SeoAnalysisRequest) =>
    AxiosClient.post<unknown, SeoAnalysisResponse>('/text-analysis/tfidf-check', payload),
  termSuggestions: (payload: TermSuggestionsRequest) =>
    AxiosClient.post<unknown, TermSuggestionsResponse>('/text-analysis/term-suggestion', payload),
  limitations: () =>
    AxiosClient.get<unknown, SeoLimitationsResponse>('/text-analysis/get-limitations')
};

export default SeoAnalysisAPI;
