import { Card as MUICard, CardContent, Divider } from '@mui/material';
import styled from 'styled-components';
import { mobileWindowWidthCSS } from 'utils/hooks/useMobileVersion';

export const Header = styled.div`
  width: 100%;
  margin-bottom: ${({ theme }) => theme.spacings.four};
  display: flex;
  justify-content: space-between;

  @media (max-width: ${mobileWindowWidthCSS}) {
    flex-direction: column;
  }
`;

export const Card = styled(MUICard)`
  padding: 24px;
  margin-bottom: 40px;

  width: 100%;
`;

export const StyledCardContent = styled(CardContent)`
  .MuiTableCell-body {
    border-bottom: none;
    margin-bottom: 2%;
  }

  &.MuiCardContent-root {
    padding: 0;
  }
`;

export const LeftSideContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.two};
`;

export const StyledDivider = styled(Divider)`
  &.MuiDivider-root {
    margin: ${({ theme }) => theme.spacings.three} 0;
  }
`;
