import LightbulbIcon from '@mui/icons-material/Lightbulb';
import UndoIcon from '@mui/icons-material/Undo';
import { Button, OutlinedInput, Typography } from '@mui/material';
import { ExtendedPromptInput } from 'components/ExtendedPromptInput';
import { ImageQualitySelect } from 'features/aiImages/AiImagesPage/ImageQualitySelect';
import { NumberOfImagesSelect } from 'features/aiImages/AiImagesPage/NumberOfImagesSelect';
import { OptimizeButton } from 'features/aiImages/AiImagesPage/OptimizeButton';
import { ResolutionSelect } from 'features/aiImages/AiImagesPage/ResolutionSelect';
import { ChangeEvent } from 'react';
import { GAEvents } from 'services/tracking/GAEvents';
import gtmIds from 'services/tracking/GTMIds';
import styled from 'styled-components';
import useTr from 'utils/hooks/useTr';

type Props = {
  handleTextChange: (text: string) => void;
  handleExampleButtonClick: () => void;
  optimizedPrompt: string | undefined;
  handleUndoClick: () => void;
  isImagesFetching: boolean;
  handleGenerateButtonClick: () => void;
  prompt: string;
};

export const GeneratorFormSidebarContent = ({
  handleTextChange,
  handleExampleButtonClick,
  optimizedPrompt,
  handleUndoClick,
  isImagesFetching,
  handleGenerateButtonClick,
  prompt
}: Props) => {
  const translate = useTr();

  return (
    <Root>
      <ExtendedPromptInput
        value={prompt}
        placeholder={translate('ai_images.generator_page.prompt_field.placeholder')}
        onChange={(event: ChangeEvent<HTMLTextAreaElement>) => handleTextChange(event.target.value)}
        id={gtmIds.aiImages.generatorPage.promptInput}
        autoFocus={true}
        actions={
          <>
            <OptimizeButton />
            <Separator />
            <Button
              sx={{ textTransform: 'none' }}
              id={gtmIds.aiImages.generatorPage.exampleButton}
              onClick={handleExampleButtonClick}
              startIcon={<PrimaryLightbulb />}
            >
              {translate('ai_images.generator_page.example_button.label')}
            </Button>
          </>
        }
        footer={optimizedPrompt && <StyledUndoIcon onClick={handleUndoClick} />}
      />
      <StyledButtonsContainer>
        <ImagesRatioAndQuantity>
          <Typography variant="subtitle1">
            {translate('ai_images.generator_page.quality.label')}
          </Typography>

          <ImageQualitySelect
            SelectProps={{
              input: <OutlinedInput />
            }}
            onQualityChange={quality => GAEvents.sidebarImageQualityClicked({ quality })}
          />

          <Typography variant="subtitle1">
            {translate('ai_images.generator_page.aspect_ratio.label')}
          </Typography>

          <ResolutionSelect
            SelectProps={{
              input: <OutlinedInput />
            }}
            onResolutionChange={resolution =>
              GAEvents.sidebarImageRatioClicked({ ratio: resolution })
            }
          />

          <Typography variant="subtitle1">
            {translate('ai_images.generator_page.number_of_images.label')}
          </Typography>

          <NumberOfImagesSelect
            SelectProps={{
              input: <OutlinedInput id={gtmIds.aiImages.generatorPage.numberOfImagesSelect} />
            }}
            onNumberOfImagesChange={newNumber =>
              GAEvents.sidebarNumberOfImagesClicked({ number: newNumber })
            }
          />
        </ImagesRatioAndQuantity>

        <ReversedRowFlexContainer>
          <Button
            size="large"
            variant="contained"
            disabled={!prompt || isImagesFetching}
            onClick={() => {
              GAEvents.sidebarGenerateImageClicked();
              handleGenerateButtonClick();
            }}
          >
            {translate('ai_images.generator_page.generate_button.label')}
          </Button>
        </ReversedRowFlexContainer>
      </StyledButtonsContainer>
    </Root>
  );
};

const Root = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: ${({ theme }) => theme.spacings.four};

  margin-top: ${({ theme }) => theme.spacings.four};
`;

const ImagesRatioAndQuantity = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;

  row-gap: ${({ theme }) => theme.spacings.two};
`;

const ReversedRowFlexContainer = styled.div`
  display: flex;
  flex-direction: row-reverse;
`;

const Separator = styled.div`
  background-color: ${({ theme }) => theme.colors.inputBackground};
  height: 19px;
  width: 1px;
  margin: 0 5px;
`;

const StyledUndoIcon = styled(UndoIcon)`
  color: ${({ theme }) => theme.colors.blackInactive};
  position: absolute;
  right: 15px;
  top: 10px;
  cursor: pointer;
  z-index: 1;
  width: 18px;
`;

const PrimaryLightbulb = styled(LightbulbIcon)`
  color: ${({ theme }) => theme.colors.primaryColorMain};
`;
