import { Typography } from '@mui/material';
import Button from '@mui/material/Button';
import FlexContainer from 'components/FlexContainer';
import { useModal } from 'components/modals';
import { CloseModal } from 'components/modals/types';
import FormattedMessage from 'features/i18n/FormattedMessage';
import styled from 'styled-components';

type CustomModalProps = {
  headingMessage: JSX.Element | string;
  explanationMessage: JSX.Element | string;
  onAccept: () => void;
};

type Props = CloseModal & CustomModalProps;

export const ChatResetModal = ({
  closeModal,
  onAccept,
  explanationMessage,
  headingMessage
}: Props) => {
  const handleAcceptClick = () => {
    onAccept();
    closeModal();
  };

  return (
    <Root>
      <Typography variant="h5">{headingMessage}</Typography>
      <Typography>{explanationMessage}</Typography>

      <Footer>
        <Button onClick={closeModal}>
          <FormattedMessage id="common.cancel" />
        </Button>
        <Button type="submit" color="primary" variant="contained" onClick={handleAcceptClick}>
          <FormattedMessage id="common.confirm" />
        </Button>
      </Footer>
    </Root>
  );
};

const Root = styled(FlexContainer).attrs({ gap: 'medium' })`
  padding: ${({ theme }) => `${theme.spacings.medium} ${theme.spacings.xmedium}`};
`;

const Footer = styled.div`
  margin-top: ${({ theme }) => theme.spacings.medium};

  display: flex;
  justify-content: flex-end;
  gap: ${({ theme }) => theme.spacings.xmedium};
`;

export const useShowChatResetModal = () => {
  const { showModal } = useModal();

  return (props: CustomModalProps) => {
    showModal('CHAT_RESET_WARNING', {
      ...props,
      size: 480
    });
  };
};
