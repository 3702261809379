import { createAction } from '@reduxjs/toolkit';
import { DeleteProjectPayload } from 'features/aiWriter/store/actions/project/types';
import { AiWriterProject, AiWriterProjectWithShortText } from 'features/aiWriter/store/types';
import { createAsyncAction } from 'utils/reduxUtils';

export const updateProjectInBackground = createAsyncAction<void, AiWriterProject>(
  'aiWriter/updateCurrentProjectInBackground'
);
export const deleteProject = createAsyncAction<
  DeleteProjectPayload,
  DeleteProjectPayload,
  DeleteProjectPayload
>('aiWriter/deleteProject');
export const updateProjects =
  createAction<AiWriterProjectWithShortText[]>('aiWriter/updateProjects');
export const setIsSaving = createAction<boolean>('aiWriter/setIsSaving');
