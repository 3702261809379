import { createAlignPlugin } from '@udecode/plate-alignment';
import { createAutoformatPlugin } from '@udecode/plate-autoformat';
import {
  createBoldPlugin,
  createCodePlugin,
  createItalicPlugin,
  createStrikethroughPlugin,
  createSubscriptPlugin,
  createSuperscriptPlugin,
  createUnderlinePlugin,
  MARK_BOLD,
  MARK_CODE,
  MARK_ITALIC,
  MARK_STRIKETHROUGH,
  MARK_SUBSCRIPT,
  MARK_SUPERSCRIPT,
  MARK_UNDERLINE
} from '@udecode/plate-basic-marks';
import { createBlockquotePlugin, ELEMENT_BLOCKQUOTE } from '@udecode/plate-block-quote';
import { createExitBreakPlugin, createSoftBreakPlugin } from '@udecode/plate-break';
import {
  createCodeBlockPlugin,
  ELEMENT_CODE_BLOCK,
  ELEMENT_CODE_LINE,
  ELEMENT_CODE_SYNTAX,
  isCodeBlockEmpty,
  isSelectionAtCodeBlockStart,
  unwrapCodeBlock
} from '@udecode/plate-code-block';
import { createComboboxPlugin } from '@udecode/plate-combobox';
import {
  createPlugins,
  isBlockAboveEmpty,
  isSelectionAtBlockStart,
  PlateElement,
  PlateLeaf,
  PlatePlugin,
  RenderAfterEditable,
  someNode,
  withProps
} from '@udecode/plate-common';
import { createDndPlugin } from '@udecode/plate-dnd';
import { createEmojiPlugin } from '@udecode/plate-emoji';
import {
  createFontBackgroundColorPlugin,
  createFontColorPlugin,
  createFontSizePlugin
} from '@udecode/plate-font';
import {
  createHeadingPlugin,
  ELEMENT_H1,
  ELEMENT_H2,
  ELEMENT_H3,
  ELEMENT_H4,
  ELEMENT_H5,
  ELEMENT_H6,
  KEYS_HEADING
} from '@udecode/plate-heading';
import { createHorizontalRulePlugin, ELEMENT_HR } from '@udecode/plate-horizontal-rule';
import { createIndentPlugin } from '@udecode/plate-indent';
import { createIndentListPlugin, KEY_LIST_STYLE_TYPE } from '@udecode/plate-indent-list';
import { createLineHeightPlugin } from '@udecode/plate-line-height';
import { createLinkPlugin, ELEMENT_LINK } from '@udecode/plate-link';
import {
  createTodoListPlugin,
  ELEMENT_LI,
  ELEMENT_OL,
  ELEMENT_TODO_LI,
  ELEMENT_UL
} from '@udecode/plate-list';
import { ELEMENT_IMAGE } from '@udecode/plate-media';
import { createNodeIdPlugin } from '@udecode/plate-node-id';
import { createParagraphPlugin, ELEMENT_PARAGRAPH } from '@udecode/plate-paragraph';
import { createResetNodePlugin } from '@udecode/plate-reset-node';
import { createSelectOnBackspacePlugin } from '@udecode/plate-select';
import { createBlockSelectionPlugin } from '@udecode/plate-selection';
import { createDeserializeDocxPlugin } from '@udecode/plate-serializer-docx';
import { createDeserializeMdPlugin } from '@udecode/plate-serializer-md';
import { createTabbablePlugin } from '@udecode/plate-tabbable';
import {
  createTablePlugin,
  ELEMENT_TABLE,
  ELEMENT_TD,
  ELEMENT_TH,
  ELEMENT_TR
} from '@udecode/plate-table';
import { createTrailingBlockPlugin } from '@udecode/plate-trailing-block';
import { withCustomPlaceholders } from 'features/aiWriter/AiWriterTextEditor/utils/withCustomPlaceholders';
import { BlockquoteElement } from 'features/plate/components/plate-ui/blockquote-element';
import { CodeBlockElement } from 'features/plate/components/plate-ui/code-block-element';
import { CodeLeaf } from 'features/plate/components/plate-ui/code-leaf';
import { CodeLineElement } from 'features/plate/components/plate-ui/code-line-element';
import { CodeSyntaxLeaf } from 'features/plate/components/plate-ui/code-syntax-leaf';
import { EmojiCombobox } from 'features/plate/components/plate-ui/emoji-combobox';
import { HeadingElement } from 'features/plate/components/plate-ui/heading-element';
import { HrElement } from 'features/plate/components/plate-ui/hr-element';
import { LinkElement } from 'features/plate/components/plate-ui/link-element';
import { LinkFloatingToolbar } from 'features/plate/components/plate-ui/link-floating-toolbar';
import { ListElement } from 'features/plate/components/plate-ui/list-element';
import { ParagraphElement } from 'features/plate/components/plate-ui/paragraph-element';
import {
  TableCellElement,
  TableCellHeaderElement
} from 'features/plate/components/plate-ui/table-cell-element';
import { TableElement } from 'features/plate/components/plate-ui/table-element';
import { TableRowElement } from 'features/plate/components/plate-ui/table-row-element';
import { TodoListElement } from 'features/plate/components/plate-ui/todo-list-element';
import { withDraggables } from 'features/plate/components/plate-ui/with-draggables';
import { TabbableElement } from 'features/plate/components/tabbable-element';
import { createCustomHotkeyPlugin } from 'features/plate/customPlugins/createCustomHotkeyPlugin';
import { autoformatPlugin } from 'features/plate/lib/plate/autoformatPlugin';
import { dragOverCursorPlugin } from 'features/plate/lib/plate/dragOverCursorPlugin';
import useTr from 'utils/hooks/useTr';

const resetBlockTypesCommonRule = {
  types: [ELEMENT_BLOCKQUOTE, ELEMENT_TODO_LI],
  defaultType: ELEMENT_PARAGRAPH
};

const resetBlockTypesCodeBlockRule = {
  types: [ELEMENT_CODE_BLOCK],
  defaultType: ELEMENT_PARAGRAPH,
  onReset: unwrapCodeBlock
};

export const useDataTypeDocumentPlatePluginConfiguration = (): PlatePlugin[] => {
  const translate = useTr();

  const plugins = createPlugins(
    [
      // Nodes
      createParagraphPlugin(),
      createHeadingPlugin(),
      createBlockquotePlugin(),
      createCodeBlockPlugin(),
      createHorizontalRulePlugin(),
      createLinkPlugin({
        renderAfterEditable: LinkFloatingToolbar as RenderAfterEditable
      }),
      createTablePlugin({
        options: {
          // Our HTML export doesn't work well with tables and margin left
          disableMarginLeft: true
        }
      }),
      createTodoListPlugin(),

      // Marks
      createBoldPlugin(),
      createItalicPlugin(),
      createUnderlinePlugin(),
      createStrikethroughPlugin(),
      createCodePlugin(),
      createSubscriptPlugin(),
      createSuperscriptPlugin(),
      createFontColorPlugin(),
      createFontBackgroundColorPlugin(),
      createFontSizePlugin(),

      // Block Style
      createAlignPlugin({
        inject: {
          props: {
            validTypes: [ELEMENT_PARAGRAPH, ELEMENT_H1, ELEMENT_H2, ELEMENT_H3]
          }
        }
      }),
      createIndentPlugin({
        inject: {
          props: {
            validTypes: [
              ELEMENT_PARAGRAPH,
              ELEMENT_H1,
              ELEMENT_H2,
              ELEMENT_H3,
              ELEMENT_BLOCKQUOTE,
              ELEMENT_CODE_BLOCK
            ]
          }
        }
      }),
      createIndentListPlugin({
        inject: {
          props: {
            validTypes: [
              ELEMENT_PARAGRAPH,
              ELEMENT_H1,
              ELEMENT_H2,
              ELEMENT_H3,
              ELEMENT_BLOCKQUOTE,
              ELEMENT_CODE_BLOCK
            ]
          }
        }
      }),
      createLineHeightPlugin({
        inject: {
          props: {
            defaultNodeValue: 1.5,
            validNodeValues: [1, 1.2, 1.5, 2, 3],
            validTypes: [ELEMENT_PARAGRAPH, ELEMENT_H1, ELEMENT_H2, ELEMENT_H3]
          }
        }
      }),

      // Functionality
      createAutoformatPlugin(autoformatPlugin),
      createBlockSelectionPlugin({
        options: {
          sizes: {
            top: 0,
            bottom: 0
          }
        }
      }),
      createComboboxPlugin(),
      createDndPlugin({
        options: {
          enableScroller: false
        }
      }),
      createEmojiPlugin({
        renderAfterEditable: EmojiCombobox as RenderAfterEditable
      }),
      createExitBreakPlugin({
        options: {
          rules: [
            {
              hotkey: 'mod+enter'
            },
            {
              hotkey: 'mod+shift+enter',
              before: true
            },
            {
              hotkey: 'enter',
              query: {
                start: true,
                end: true,
                allow: KEYS_HEADING
              },
              relative: true,
              level: 1
            }
          ]
        }
      }),
      createNodeIdPlugin(),
      createResetNodePlugin({
        options: {
          rules: [
            {
              ...resetBlockTypesCommonRule,
              hotkey: 'Enter',
              predicate: isBlockAboveEmpty
            },
            {
              ...resetBlockTypesCommonRule,
              hotkey: 'Backspace',
              predicate: isSelectionAtBlockStart
            },
            {
              ...resetBlockTypesCodeBlockRule,
              hotkey: 'Enter',
              predicate: isCodeBlockEmpty
            },
            {
              ...resetBlockTypesCodeBlockRule,
              hotkey: 'Backspace',
              predicate: isSelectionAtCodeBlockStart
            }
          ]
        }
      }),
      createSelectOnBackspacePlugin({
        options: {
          query: {
            allow: [ELEMENT_IMAGE, ELEMENT_HR]
          }
        }
      }),

      createSoftBreakPlugin({
        options: {
          rules: [
            { hotkey: 'shift+enter' },
            {
              hotkey: 'enter',
              query: {
                allow: [ELEMENT_CODE_BLOCK, ELEMENT_BLOCKQUOTE, ELEMENT_TD]
              }
            }
          ]
        }
      }),
      createTabbablePlugin({
        options: {
          query: editor => {
            if (isSelectionAtBlockStart(editor)) return false;

            return !someNode(editor, {
              match: n => {
                return !!(
                  n.type &&
                  ([ELEMENT_TABLE, ELEMENT_LI, ELEMENT_CODE_BLOCK].includes(n.type as string) ||
                    n[KEY_LIST_STYLE_TYPE])
                );
              }
            });
          }
        },
        plugins: [
          {
            key: 'tabbable_element',
            isElement: true,
            isVoid: true,
            component: TabbableElement
          }
        ]
      }),
      createTrailingBlockPlugin({
        options: { type: ELEMENT_PARAGRAPH }
      }),
      dragOverCursorPlugin,

      // Deserialization
      createDeserializeDocxPlugin(),
      createDeserializeMdPlugin(),

      // Custom plugins
      createCustomHotkeyPlugin()
    ],
    {
      components: withDraggables(
        withCustomPlaceholders(
          {
            [ELEMENT_BLOCKQUOTE]: BlockquoteElement,
            [ELEMENT_CODE_BLOCK]: CodeBlockElement,
            [ELEMENT_CODE_LINE]: CodeLineElement,
            [ELEMENT_CODE_SYNTAX]: CodeSyntaxLeaf,
            [ELEMENT_HR]: HrElement,
            [ELEMENT_H1]: withProps(HeadingElement, { variant: 'h1' }),
            [ELEMENT_H2]: withProps(HeadingElement, { variant: 'h2' }),
            [ELEMENT_H3]: withProps(HeadingElement, { variant: 'h3' }),
            [ELEMENT_H4]: withProps(HeadingElement, { variant: 'h4' }),
            [ELEMENT_H5]: withProps(HeadingElement, { variant: 'h5' }),
            [ELEMENT_H6]: withProps(HeadingElement, { variant: 'h6' }),
            [ELEMENT_LI]: withProps(PlateElement, { as: 'li' }),
            [ELEMENT_LINK]: LinkElement,
            [ELEMENT_UL]: withProps(ListElement, { variant: 'ul' }),
            [ELEMENT_OL]: withProps(ListElement, { variant: 'ol' }),
            [ELEMENT_PARAGRAPH]: ParagraphElement,
            [ELEMENT_TABLE]: TableElement,
            [ELEMENT_TD]: TableCellElement,
            [ELEMENT_TH]: TableCellHeaderElement,
            [ELEMENT_TODO_LI]: TodoListElement,
            [ELEMENT_TR]: TableRowElement,
            [MARK_BOLD]: withProps(PlateLeaf, { as: 'strong' }),
            [MARK_CODE]: CodeLeaf,
            [MARK_ITALIC]: withProps(PlateLeaf, { as: 'em' }),
            [MARK_STRIKETHROUGH]: withProps(PlateLeaf, { as: 's' }),
            [MARK_SUBSCRIPT]: withProps(PlateLeaf, { as: 'sub' }),
            [MARK_SUPERSCRIPT]: withProps(PlateLeaf, { as: 'sup' }),
            [MARK_UNDERLINE]: withProps(PlateLeaf, { as: 'u' })
          },
          translate
        )
      )
    }
  );

  return plugins;
};
