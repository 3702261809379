import { Button, ButtonProps, Typography } from '@mui/material';
import { SpinnerDimmer } from 'components/base/SpinnerDimmer';
import FlexContainer from 'components/FlexContainer';
import ModalHeader from 'components/modals/ModalHeader';
import { Form, Formik, FormikConfig, FormikProps } from 'formik';
import React, { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

const DefaultConfirmButton = () => (
  <Typography variant="button">
    <FormattedMessage id="common.confirm" />
  </Typography>
);

const DefaultCloseButton = () => (
  <Typography variant="button">
    <FormattedMessage id="common.close" />
  </Typography>
);

const DefaultCancelButton = () => (
  <Typography variant="button">
    <FormattedMessage id="common.cancel" />
  </Typography>
);

type Props<Values> = {
  title?: string;
  isLoading?: boolean;
  buttonsProps?: {
    confirm?: Partial<ButtonProps>;
    close?: Partial<ButtonProps>;
    cancel?: Partial<ButtonProps>;
  };
  buttonContents?: {
    confirm?: ReactNode;
    close?: ReactNode;
    cancel?: ReactNode;
  };
  onCancelClick?: () => void;
  onCloseClick?: () => void;
  children: (props: FormikProps<Values>) => React.ReactNode;
} & FormikConfig<Values>;

function FormikModal<Values>({
  title,
  isLoading = false,
  buttonsProps,
  buttonContents,
  children,
  onCancelClick,
  onCloseClick,
  ...formikProps
}: Props<Values>) {
  const confirmButtonContent = buttonContents?.confirm ?? <DefaultConfirmButton />;
  const closeButtonContent = buttonContents?.close ?? <DefaultCloseButton />;
  const cancelButtonContent = buttonContents?.cancel ?? <DefaultCancelButton />;

  return (
    <SpinnerDimmer active={isLoading} loader>
      {title && <ModalHeader title={title} />}

      <Formik validateOnChange={false} validateOnBlur={false} {...formikProps}>
        {formProps => (
          <Form>
            <ModalBody className="modal-body">{children(formProps)}</ModalBody>

            <ModalFooter direction="row" className="modal-footer">
              {!onCloseClick ? (
                <>
                  <Button color="primary" onClick={onCancelClick} {...buttonsProps?.cancel}>
                    {cancelButtonContent}
                  </Button>

                  <Button
                    color="primary"
                    variant="contained"
                    type="submit"
                    disabled={formProps.isSubmitting}
                    {...buttonsProps?.confirm}
                  >
                    {confirmButtonContent}
                  </Button>
                </>
              ) : (
                <Button
                  color="secondary"
                  variant="outlined"
                  onClick={onCloseClick}
                  {...buttonsProps?.close}
                >
                  {closeButtonContent}
                </Button>
              )}
            </ModalFooter>
          </Form>
        )}
      </Formik>
    </SpinnerDimmer>
  );
}

const ModalBody = styled.div`
  padding: 0 ${({ theme }) => theme.spacings.xmedium} ${({ theme }) => theme.spacings.xmedium};
`;

const ModalFooter = styled(FlexContainer)`
  padding: 0 ${({ theme }) => theme.spacings.xmedium} ${({ theme }) => theme.spacings.xmedium};
  border: 0 none;
  gap: ${({ theme }) => theme.spacings.medium};
`;

export default FormikModal;
