import { createAction, createReducer } from '@reduxjs/toolkit';
import { RootState } from 'store/store';

export const startMaintenance = createAction('maintenance/startMaintenance');

export const maintenanceReducer = createReducer(
  {
    ongoingMaintenance: false
  },
  builder => {
    builder.addCase(startMaintenance, state => {
      state.ongoingMaintenance = true;
    });
  }
);

export const isOngoingMaintenance = (state: RootState) => state.maintenance.ongoingMaintenance;
