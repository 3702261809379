import SaveIcon from '@mui/icons-material/Save';
import { MenuItem } from '@mui/material';
import { MenuDropdownSubItem } from 'components/MenuDropdown/MenuDropdownSubItem';
import { CommonAction } from 'features/aiTester/buttons/GraphActionsButton';
import { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import useTr from 'utils/hooks/useTr';
import { saveChart } from 'utils/svgUtils';

type ChartImageExtension = 'jpg' | 'png' | 'svg';

type Props = {
  isParentMenuOpen: boolean;
} & CommonAction;

const SaveDataAction = ({ isParentMenuOpen, onClose }: Props) => {
  const tr = useTr();

  const saveChartImage = useMemo(() => mkSaveChartImage(onClose), [onClose]);

  return (
    <MenuDropdownSubItem
      icon={<SaveIcon opacity={0.54} />}
      isParentMenuOpen={isParentMenuOpen}
      label={tr('testing.graph.dropdown_actions.save')}
    >
      <MenuItem onClick={() => saveChartImage('jpg')}>
        <FormattedMessage id="testing.graph.dropdown_actions.save.jpeg" />
      </MenuItem>
      <MenuItem onClick={() => saveChartImage('png')}>
        <FormattedMessage id="testing.graph.dropdown_actions.save.png" />
      </MenuItem>
      <MenuItem onClick={() => saveChartImage('svg')}>
        <FormattedMessage id="testing.graph.dropdown_actions.save.svg" />
      </MenuItem>
    </MenuDropdownSubItem>
  );
};

const mkSaveChartImage =
  (onClose: (() => void) | undefined) => (extension: ChartImageExtension) => {
    saveChart({
      extension,
      filename: 'content_graph',
      height: 460,
      width: 700,
      svgElement: '.content-flash-svg'
    });
    onClose?.();
  };

export default SaveDataAction;
