import { CircularProgress } from '@mui/material';
import { forwardRef } from 'react';
import styled from 'styled-components';

export const Loader = forwardRef<HTMLDivElement>((_props, ref) => (
  <EmptyBox ref={ref}>
    <CircularProgress size={100} />
  </EmptyBox>
));

const EmptyBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  padding: ${({ theme }) => theme.spacings.xxxxlarge};
`;
