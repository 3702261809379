import LocalStorageKey from 'config/localStorageKey';
import { colorPalette } from 'styles/new-theme/colorPalette';
import { useLocalStorage } from 'utils/hooks/useLocalStorage';
import { create } from 'zustand';
import { createJSONStorage, persist, StateStorage } from 'zustand/middleware';

export const COLOR_PALETTE = {
  DEFAULT: '#FFFFFF',
  OLD_PAGE: '#F2EECB',
  PAPER_WHITE: '#FFFFEE',
  LEGAL_PAD: '#FFFCC9'
} as const;

export const COLOR_PALETTE_MAP_DARK_MODE: Record<string, string> = {
  '#FFFFFF': colorPalette.dark__800, // default
  '#F2EECB': colorPalette.dark__700, // old page
  '#FFFFEE': colorPalette.dark__600, // paper white
  '#FFFCC9': colorPalette.dark__600 // legal pad
};

const noOpStorage: StateStorage = {
  getItem: () => null,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setItem: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  removeItem: () => {}
};

type EditorStoreState = {
  backgroundColor: string;
  hideBorder: boolean;
};

type EditorStoreActions = {
  setBackgroundColor: (backgroundColor: string) => void;
  setHideBorder: (hideBorder: boolean) => void;
  reset: () => void;
};

const initialState: EditorStoreState = {
  backgroundColor: COLOR_PALETTE.DEFAULT,
  hideBorder: false
};

const isPersistent =
  localStorage.getItem(LocalStorageKey.AiWriterEditorPersistBackgroundColor) === 'true';

export const useEditorSettingsStore = create<EditorStoreState & EditorStoreActions>()(
  persist(
    (set, get) => ({
      ...initialState,

      reset: () => set(initialState),
      setBackgroundColor: (backgroundColor: string) => set({ backgroundColor }),
      setHideBorder: (hideBorder: boolean) =>
        set({
          hideBorder,
          backgroundColor: hideBorder ? COLOR_PALETTE.DEFAULT : get().backgroundColor
        })
    }),
    {
      name: 'ai-writer-editor-store',
      version: 3,
      // We only want to persist (and therefore hydrate on mount) if the user selects it.
      skipHydration: !isPersistent,
      storage: createJSONStorage(() => (isPersistent ? localStorage : noOpStorage))
    }
  )
);

export const enableBackgroundColorPersistence = () => {
  localStorage.setItem(LocalStorageKey.AiWriterEditorPersistBackgroundColor, 'true');

  useEditorSettingsStore.persist.setOptions({
    skipHydration: false,
    storage: createJSONStorage(() => localStorage)
  });

  // Apply current state to the storage
  useEditorSettingsStore.persist.rehydrate();
};

export const disableBackgroundColorPersistence = () => {
  localStorage.setItem(LocalStorageKey.AiWriterEditorPersistBackgroundColor, 'false');

  // Reset to default
  useEditorSettingsStore.getState().reset();

  // Remove local storage settings
  useEditorSettingsStore.persist.clearStorage();

  // Disable hydration & Switch store
  useEditorSettingsStore.persist.setOptions({
    skipHydration: true,
    storage: createJSONStorage(() => noOpStorage)
  });
};

export const useIsEditorBackgroundPersistent = (): [boolean, () => void] => {
  const [isPersistent, setIsPersistent] = useLocalStorage(
    LocalStorageKey.AiWriterEditorPersistBackgroundColor,
    false
  );

  const handleToggle = () => {
    if (!isPersistent) {
      enableBackgroundColorPersistence();
      setIsPersistent(true);
      return;
    }

    disableBackgroundColorPersistence();
    setIsPersistent(false);
  };

  return [isPersistent, handleToggle];
};
