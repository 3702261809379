import { MenuItem, Select } from '@mui/material';
import { useOnboardingContext } from 'features/onboardingJourney/newUserSurvey/OnboardingContextProvider';
import {
  SurveyQuestionDto,
  SurveyQuestionOptionDto
} from 'services/backofficeIntegration/http/dtos/SurveyQuestionsDto';
import useTr from 'utils/hooks/useTr';

type Props = {
  question: SurveyQuestionDto;
  onChange: (option: SurveyQuestionOptionDto) => void;
};

export const DropdownQuestion = ({ question, onChange }: Props) => {
  const translate = useTr();
  const { isAnswerOptionChecked } = useOnboardingContext();

  const value = question.options.find(option => isAnswerOptionChecked(question, option));

  return (
    <Select
      fullWidth={true}
      size="small"
      value={value?.id ?? ''}
      displayEmpty={true}
      placeholder={translate('survey.new_user.dropdown.placeholder')}
      onChange={event => {
        const selectedOption = question.options.find(option => option.id === event.target.value);
        if (selectedOption) {
          onChange(selectedOption);
        }
      }}
      renderValue={value => {
        const selectedOption = question.options.find(option => option.id === value);

        return selectedOption?.option_text ?? translate('survey.new_user.dropdown.placeholder');
      }}
    >
      {question.options.map(option => (
        <MenuItem key={option.id} value={option.id} selected={option.id === value?.id}>
          {option.option_text}
        </MenuItem>
      ))}
    </Select>
  );
};
