import { Checkbox, CircularProgress, FormControlLabel, FormGroup, Radio } from '@mui/material';
import { InformationDto } from 'services/backofficeIntegration/http/endpoints/infomration/httpGetInformationList';
import styled from 'styled-components';

type Props = {
  isError: boolean;
  isLoading: boolean;
  informationList: InformationDto[] | undefined;
  isSingleChoiceSelection: boolean;
  handleSelectInformation: (info: InformationDto) => void;
  isChecked: (id: number) => boolean | undefined;
};

export const InformationList = ({
  isError,
  isLoading,
  informationList,
  isSingleChoiceSelection,
  handleSelectInformation,
  isChecked
}: Props) => {
  if (isError || !informationList || informationList.length === 0) {
    return null;
  }

  if (isLoading) {
    return (
      <CenteredBox>
        <CircularProgress />
      </CenteredBox>
    );
  }

  return (
    <>
      {informationList?.map(info => (
        <FormGroup key={info.id}>
          <FormControlLabel
            control={
              isSingleChoiceSelection ? (
                <Radio
                  checked={isChecked(info.id)}
                  onChange={() => handleSelectInformation(info)}
                />
              ) : (
                <Checkbox
                  checked={isChecked(info.id)}
                  onChange={() => handleSelectInformation(info)}
                />
              )
            }
            label={info.name}
          />
        </FormGroup>
      ))}
    </>
  );
};

const CenteredBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
