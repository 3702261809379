import { Button, IconButton } from '@mui/material';
import styled, { css } from 'styled-components';

export type EditorFormatButtonProps = '' | 'icon';

export const EditorIconFormatButton = styled(IconButton)<{ $isActive?: boolean }>`
  && {
    ${({ theme, $isActive }) => {
      return css`
        width: 28px;
        height: 28px;
        border-radius: ${theme.borderRadius.small};

        position: relative;

        ${$isActive &&
        css`
          p {
            color: ${theme.colors.black};
          }
          svg {
            color: ${theme.colors.primary};
          }
        `}
      `;
    }}
  }
`;

export const EditorFormatButton = styled(Button)<{ $isActive?: boolean }>`
  && {
    ${({ theme, $isActive }) => {
      return css`
        text-transform: none;
        display: flex;
        justify-content: flex-start;
        gap: ${theme.spacings.small};
        align-items: center;
        color: ${theme.colors.blackMediumEmphasis};
        border-radius: ${theme.borderRadius.small};

        ${$isActive &&
        css`
          color: ${theme.colors.primary};
        `}
      `;
    }}
  }
`;
