import { BaseSetting } from 'features/modular-workflow/builder/settings/BaseSetting';
import { DataTypeDocumentEditor } from 'features/modular-workflow/builder/settings/DataTypeDocumentEditor';
import { DataTypeDocumentEditorProvider } from 'features/modular-workflow/builder/settings/DataTypeDocumentEditorProvider';
import { InputRendererContext } from 'features/modular-workflow/builder/settings/InputRenderer';
import { parseValueAsDocumentJson } from 'features/modular-workflow/builder/settings/parseValueAsDocumentJson';
import { BuilderModularWorkflowStepInputWithValue } from 'features/modular-workflow/builder/types-builder';
import { useContext } from 'react';

export const DataTypeDocument = () => {
  const { input, onChange } = useContext(InputRendererContext);

  const typedInput = input as BuilderModularWorkflowStepInputWithValue;
  const initialValue = parseValueAsDocumentJson(typedInput.value);

  return (
    <BaseSetting>
      {elementId => (
        <DataTypeDocumentEditorProvider
          id={elementId}
          initialValue={initialValue}
          onChange={value => onChange(typedInput, JSON.stringify(value))}
        >
          <DataTypeDocumentEditor elementId={elementId} />
        </DataTypeDocumentEditorProvider>
      )}
    </BaseSetting>
  );
};
