import { CloseModal } from 'components/modals/types';
import { updateDimension } from 'features/dimensions/store/actions';
import { getCurrentDimension } from 'features/dimensions/store/selectors';
import { updateList } from 'features/lists/store/actions';
import { getCurrentList } from 'features/lists/store/selectors';
import { difference } from 'lodash';
import { connect } from 'react-redux';
import { Dimension } from 'services/api/dimension/types';
import { List } from 'services/api/list/types';
import { RootState } from 'store/rootReducer';
import { promisifySagaThunk } from 'utils/utils';

import ConfirmModal from '../../../modals/ConfirmModal';

type ConnectedProps = {
  isLoading: boolean;
  currentList: List | Dimension;
  updateList: (id: number, words: string[]) => Promise<void>;
};

type CallerProps = {
  selectedWords: string[];
};

type Props = ConnectedProps & CloseModal & CallerProps;

const RemoveWordsModal = ({
  currentList,
  updateList,
  closeModal,
  isLoading,
  selectedWords
}: Props) => (
  <ConfirmModal
    message="lists.remove_words_confirm"
    isLoading={isLoading}
    onCancel={closeModal}
    onOK={() => {
      updateList(currentList.id, difference(currentList.words, selectedWords)).then(() => {
        closeModal();
      });
    }}
  />
);

export const RemoveListWordsModal = connect(
  (state: RootState) => ({
    isLoading: state.lists.isLoading,
    currentList: getCurrentList(state)
  }),
  {
    updateList: (id: number, words: string[]) =>
      promisifySagaThunk(updateList.request, { id, words })
  }
)(RemoveWordsModal);

export const RemoveDimensionWordsModal = connect(
  (state: RootState) => ({
    isLoading: state.dimensions.isLoading,
    currentList: getCurrentDimension(state)
  }),
  {
    updateList: (id: number, words: string[]) =>
      promisifySagaThunk(updateDimension.request, { id, words })
  }
)(RemoveWordsModal);
