import { AiWriterDocumentPageTopBar } from 'features/aiWriter/AiWriterDocumentPageTopBar';
import { AiWriterSidebar } from 'features/aiWriter/AiWriterSidebar/AiWriterSidebar';
import { useFlashScoreSidebarState } from 'features/aiWriter/AiWriterSidebar/steps/flashScore/useFlashScoreSidebarState';
import { AiWriterTextEditor } from 'features/aiWriter/AiWriterTextEditor/AiWriterTextEditor';
import { EditorBottomToolbar } from 'features/aiWriter/AiWriterTextEditor/EditorBottomToolbar';
import { EditorFloatingToolbar } from 'features/aiWriter/AiWriterTextEditor/EditorFloatingToolbar';
import { EditorTopToolbar } from 'features/aiWriter/AiWriterTextEditor/EditorTopToolbar';
import { TeamPreview } from 'features/aiWriter/AiWriterWorkspace/TeamPreview';
import { useFlashActionsStore } from 'features/aiWriter/slashCommands/hooks/useFlashActionsStore';
import { fetchFavoritesThunk } from 'features/aiWriter/store/actions/favorites/thunks/fetchFavoritesThunk';
import { ErrorBoundary } from 'features/react-error-boundary/ErrorBoundary';
import { useEffect } from 'react';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';

type Props = {
  readonly: boolean;
};

export const AiWriterWorkspace = ({ readonly }: Props) => {
  useFlashActionsStore();

  const dispatch = useAppDispatch();
  const resetFlashScoreSidebarState = useFlashScoreSidebarState(state => state.reset);

  useEffect(() => {
    dispatch(fetchFavoritesThunk());

    // On unmount, reset FlashScore sidebar state to ensure it's showing the initial view on document change
    return () => resetFlashScoreSidebarState();
  }, [dispatch, resetFlashScoreSidebarState]);

  const content = readonly ? (
    <TeamPreview />
  ) : (
    <>
      <EditorTopToolbar />

      <AiWriterTextEditor />

      <EditorBottomToolbar />

      <EditorFloatingToolbar />
    </>
  );

  return (
    <Root>
      <Content>
        <AiWriterDocumentPageTopBar readonly={readonly} />

        <ErrorBoundary>{content}</ErrorBoundary>
      </Content>

      {!readonly && <AiWriterSidebar />}
    </Root>
  );
};

const Root = styled.div`
  height: 100%;
  display: flex;
  flex-direction: row;
  gap: ${({ theme }) => theme.spacings.medium};
`;

const Content = styled.div`
  position: relative;

  width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.medium};
  min-width: 480px;
`;
