/**
 * I've created this store to prevent storing the sorting mode in the API.
 */
import { create } from 'zustand';

export const sortOptions = [
  'readability_asc',
  'readability_desc',
  'emotionality_asc',
  'emotionality_desc'
] as const;

export type SortOption = typeof sortOptions[number];

type TextInspirationsStoreState = {
  sortOption: SortOption;
};

type TextInspirationsStoreActions = {
  setSortOption: (sortOption: SortOption) => void;
};

const initialState: TextInspirationsStoreState = {
  sortOption: 'readability_desc'
};

export const useTextInspirationsStore = create<
  TextInspirationsStoreState & TextInspirationsStoreActions
>(set => ({
  ...initialState,
  setSortOption: sortOption => set({ sortOption })
}));
