import { getActiveTab } from 'features/aiWriter/store/selectors';
import { useCallback } from 'react';
import { useAppSelector } from 'store/hooks';
import { getAppBaseUrl } from 'utils/getAppBaseUrl';

export const useCopyLinkToClipboard = ({ callback }: { callback: () => void }) => {
  const activeTab = useAppSelector(getActiveTab);

  const createShareableLink = useCallback(() => {
    const appBaseUrl = getAppBaseUrl();

    return `${appBaseUrl}/ai-writer/${activeTab.hash}/preview`;
  }, [activeTab.hash]);

  return () => {
    const link = createShareableLink();

    navigator.clipboard.writeText(link);
    callback();
  };
};
