import dayjs from 'dayjs';
import { localizations } from 'features/i18n/localizations';
import { getCurrentLanguage } from 'features/language/store/selectors';
import { AppLanguage } from 'features/language/store/types';
import React, { useEffect } from 'react';
import { IntlProvider } from 'react-intl';
import { useAppSelector } from 'store/hooks';

type Props = {
  children: React.ReactElement;
};

export const Intl = ({ children }: Props) => {
  const language = useAppSelector(getCurrentLanguage);
  const { dayjsLocale, appTranslations } = localizations[language];

  useEffect(() => {
    dayjs.locale(dayjsLocale);
  }, [dayjsLocale, language]);

  return (
    // @ts-expect-error Package doesn't yet define a children prop (React 18)
    <IntlProvider key={language} locale={AppLanguage.German} messages={appTranslations}>
      {children}
    </IntlProvider>
  );
};
