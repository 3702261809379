import { ActionDialog } from 'features/profilePage/ActionDialog';
import { FormattedMessage } from 'react-intl';

type Props = {
  onCancel: () => void;
  onConfirm: () => void;
  isDialogOpen: boolean;
  deactivateUser?: boolean;
};

export const UserActivationDialog = ({
  isDialogOpen,
  onCancel,
  onConfirm,
  deactivateUser
}: Props) => (
  <ActionDialog
    title={
      <FormattedMessage
        id={
          deactivateUser
            ? 'profile.users.users_list.deactivate_user.dialog.title'
            : 'profile.users.users_list.reactivate_user.dialog.title'
        }
      />
    }
    content={
      <FormattedMessage
        id={
          deactivateUser
            ? 'profile.users.users_list.deactivate_user.dialog.content'
            : 'profile.users.users_list.reactivate_user.dialog.content'
        }
        values={{ br: <br /> }}
      />
    }
    confirmButtonText={
      <FormattedMessage
        id={
          deactivateUser
            ? 'profile.users.users_list.deactivate_user.dialog.button'
            : 'common.confirm'
        }
      />
    }
    isDialogOpen={isDialogOpen}
    onCancel={onCancel}
    onConfirm={onConfirm}
    confirmButtonColor={deactivateUser ? 'error' : 'primary'}
  />
);
