import Toast from 'components/toasts/Toast';
import { getErrorCodeFromAxiosError } from 'features/teamInvitation/getErrorCodeFromAxiosError';
import { BaseSuccessDto } from 'services/backofficeIntegration/http/BaseSuccessDto';
import { backofficeClient } from 'services/backofficeIntegration/http/client/createBackofficeClient';
import { PersonalityDto } from 'services/backofficeIntegration/http/dtos/PersonalityDto';
import { queryEndpoint } from 'services/backofficeIntegration/http/endpointDefinition';
import { personalitiesQueryKeys } from 'services/backofficeIntegration/http/queryKeyFactories/personalitiesQueryKeys';

export type GetPersonalitiesSuccessDto = BaseSuccessDto<PersonalityDto>;

export type GetPersonalityParams = {
  id: number;
};

export const httpGetPersonality = queryEndpoint({
  makeQueryKey: (params: GetPersonalityParams) => {
    return [...personalitiesQueryKeys.forItem(), params] as const;
  },
  callEndpoint: (params: GetPersonalityParams) => {
    return backofficeClient
      .get<GetPersonalitiesSuccessDto>(`/chat/personalities/${params.id}`)
      .then(response => {
        return response.data.data;
      })
      .catch(error => {
        const errorCode = getErrorCodeFromAxiosError(error);
        if (errorCode) {
          Toast.error(`chat.personalities.error.${errorCode}`);
        }
      });
  }
});
