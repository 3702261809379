import { getGetIsFeatureAvailable } from 'features/features/store/selectors';
import { AnimatePresence } from 'framer-motion';
import { useRef } from 'react';
import { connect } from 'react-redux';
import { useAppSelector } from 'store/hooks';
import { RootState } from 'store/rootReducer';

import useOnClickOutside from '../../../utils/hooks/useOnClickOutside';
import DimensionsPanel from './dimensions/DimensionsPanel';
import ListsPanel from './lists/ListsPanel';
import ToolbarDangle from './ToolbarDangle';
import {
  closeAllWidgets,
  getDimensionsWidgetOpen,
  getIsToolbarLocked,
  getIsToolbarOpen,
  getListsWidgetOpen,
  toggleDimensionsWidget,
  toggleListsWidget
} from './toolbarSlice';

const mapStateToProps = (state: RootState) => ({
  isToolbarOpen: getIsToolbarOpen(state),
  listsWidgetActive: getListsWidgetOpen(state),
  dimensionsWidgetActive: getDimensionsWidgetOpen(state),
  isToolbarLocked: getIsToolbarLocked(state),
  unseenLists: state.lists.unseenLists,
  unseenDimensions: state.dimensions.unseenDimensions
});

const mapDispatchToProps = {
  toggleListsWidget: () => toggleListsWidget(),
  toggleDimensionsWidget: () => toggleDimensionsWidget(),
  closeAllWidgets
};

type OwnProps = {
  disabled?: boolean;
};

type Props = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps & OwnProps;

const Toolbar = ({
  disabled,
  listsWidgetActive,
  toggleListsWidget,
  toggleDimensionsWidget,
  closeAllWidgets,
  isToolbarLocked,
  isToolbarOpen,
  unseenDimensions,
  unseenLists,
  dimensionsWidgetActive
}: Props) => {
  const ref = useRef<HTMLDivElement>(null);
  const showSavedLists = useAppSelector(getGetIsFeatureAvailable)('exportList');
  const showDimensions = useAppSelector(getGetIsFeatureAvailable)('customDimensions');

  useOnClickOutside(ref, () => {
    if (!isToolbarLocked && isToolbarOpen) closeAllWidgets();
  });

  return !disabled ? (
    <div ref={ref}>
      {showSavedLists ? (
        <ToolbarDangle
          opened={isToolbarOpen}
          active={listsWidgetActive}
          onClick={toggleListsWidget}
          unseenItems={unseenLists}
          tooltip="lists.toolbar"
          icon="list"
          order={0}
        />
      ) : null}
      {showDimensions ? (
        <ToolbarDangle
          opened={isToolbarOpen}
          active={dimensionsWidgetActive}
          onClick={toggleDimensionsWidget}
          unseenItems={unseenDimensions}
          icon="folder"
          tooltip="dimensions.tooltip"
          order={1}
        />
      ) : null}
      <AnimatePresence exitBeforeEnter>
        {listsWidgetActive ? <ListsPanel /> : null}
        {dimensionsWidgetActive ? <DimensionsPanel /> : null}
      </AnimatePresence>
    </div>
  ) : null;
};

export default connect(mapStateToProps, mapDispatchToProps)(Toolbar);
