import { makeCurrentUserQueryKeyPrefix } from 'features/currentUser/currentUserQueries';
import { BaseSuccessDto } from 'services/backofficeIntegration/http/BaseSuccessDto';
import { backofficeClient } from 'services/backofficeIntegration/http/client/createBackofficeClient';
import { LimitationsDto } from 'services/backofficeIntegration/http/dtos/LimitationsDto';
import { queryEndpoint } from 'services/backofficeIntegration/http/endpointDefinition';
import { queryClient } from 'utils/ReactQueryClient';

type SuccessDto = BaseSuccessDto<LimitationsDto>;

export const httpGetAiImagesLimitations = queryEndpoint({
  makeQueryKey: () => {
    return [...makeAiImagesLimitationsQueryKeyPrefix()] as const;
  },
  callEndpoint: () => {
    return backofficeClient
      .get<SuccessDto>('/image-generations/limitations')
      .then(response => response.data.data);
  }
});

export function makeAiImagesLimitationsQueryKeyPrefix() {
  return [...makeCurrentUserQueryKeyPrefix(), 'ai-image-limitations'];
}

export const invalidateAiImagesLimitationsQueries = () =>
  queryClient.invalidateQueries(makeAiImagesLimitationsQueryKeyPrefix());
