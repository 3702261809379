import { getCurrentLanguage } from 'features/language/store/selectors';
import { LTAssistantInstance, LTAssistantOptions } from 'features/languageToolIntegration/types';
import { getPricingPlan } from 'features/pricing/store/selectors';
import { useRef } from 'react';
import { useAppSelector } from 'store/hooks';

const defaultOptions: LTAssistantOptions = {
  user: {
    email: process.env.REACT_APP_LANGUAGE_TOOL_WIDGET_AUTH_EMAIL as string,
    token: process.env.REACT_APP_LANGUAGE_TOOL_WIDGET_AUTH_TOKEN as string
  },
  dictionary: ['neuroflash']
};

export const useEmbeddedLanguageToolWidget = () => {
  const pricingPlan = useAppSelector(getPricingPlan);
  const appLanguage = useAppSelector(getCurrentLanguage);

  const isPremiumUser = !['free', 'basic', 'powerVip', 'standard'].includes(pricingPlan);

  const ltAssistantRef = useRef<LTAssistantInstance | null>(null);
  const isWidgetLoaded = useRef(false);

  const options: LTAssistantOptions = {
    ...defaultOptions,
    localeCode: appLanguage,
    user: {
      ...(defaultOptions.user ?? {}),
      premium: isPremiumUser
    }
  };

  const toggleLanguageTool = (enable: boolean) => {
    if (!window.LTAssistant || typeof window.LTAssistant !== 'function') {
      return;
    }
    if (enable && !isWidgetLoaded.current) {
      ltAssistantRef.current = new window.LTAssistant(options);
      isWidgetLoaded.current = true;

      return;
    }

    if (!enable && ltAssistantRef.current) {
      ltAssistantRef.current.destroy();
      ltAssistantRef.current = null;
      isWidgetLoaded.current = false;
    }
  };

  return toggleLanguageTool;
};
