import Toast from 'components/toasts/Toast';

const showEmailVerificationError = (userEmail: string) => {
  Toast.backendError('api.error.customer.logIn.email_not_verified', {
    a: (message: string) => (
      <a
        target="_blank"
        rel="noopener noreferrer"
        href={`https://flashinsightai.neuro-flash.com/activation-mail?email=${userEmail}`}
      >
        {message}
      </a>
    )
  });
};

export default showEmailVerificationError;
