import { ExportToDimension } from 'features/dimensions/store/types';
import { List, ListUpdate, NewList } from 'services/api/list/types';
import { ThunkMeta } from 'types/ReduxStateTypes';
import { createStandardAction } from 'typesafe-actions';
import { createStandardAsyncAction } from 'utils/reduxUtils';

export const getLists = createStandardAsyncAction(
  'lists/GET_LISTS_REQUEST',
  'lists/GET_LISTS_SUCCESS',
  'lists/GET_LISTS_FAILURE'
)<undefined, List[], undefined>();

export const addList = createStandardAsyncAction(
  'lists/ADD_LIST_REQUEST',
  'lists/ADD_LIST_SUCCESS',
  'lists/ADD_LIST_FAILURE'
)<NewList, number, undefined, ThunkMeta>();

export const addToExistingList = createStandardAsyncAction(
  'lists/ADD_TO_EXISTING_LIST_REQUEST',
  'lists/ADD_TO_EXISTING_LIST_SUCCESS',
  'lists/ADD_TO_EXISTING_LIST_FAILURE'
)<{ id: number; words: string[] }, number, undefined, ThunkMeta>();

export const updateList = createStandardAsyncAction(
  'lists/UPDATE_LIST_REQUEST',
  'lists/UPDATE_LIST_SUCCESS',
  'lists/UPDATE_LIST_FAILURE'
)<ListUpdate, number, undefined, ThunkMeta>();

export const removeList = createStandardAsyncAction(
  'lists/REMOVE_LIST_REQUEST',
  'lists/REMOVE_LIST_SUCCESS',
  'lists/REMOVE_LIST_FAILURE'
)<number, undefined, undefined, ThunkMeta>();

export const exportToDimension = createStandardAsyncAction(
  'lists/EXPORT_TO_DIMENSION_REQUEST',
  'lists/EXPORT_TO_DIMENSION_SUCCESS',
  'lists/EXPORT_TO_DIMENSION_FAILURE'
)<ExportToDimension, undefined, undefined, ThunkMeta>();

export const combineLists = createStandardAsyncAction(
  'lists/COMBINE_LISTS_REQUEST',
  'lists/COMBINE_LISTS_SUCCESS',
  'lists/COMBINE_LISTS_FAILURE'
)<{ sourceId: number; targetId: number; deleteSource: boolean }, number, undefined, ThunkMeta>();

export const selectList = createStandardAction('lists/SELECT_LIST')<number>();
