import { ApiPaginationParams } from 'services/api/types';

import AxiosClient from '..';
import {
  AiWriterProjectPreviewResponse,
  CreateAiWriterProjectRequest,
  CreateAiWriterProjectResponse,
  DeleteAiWriterProjectResponse,
  GetAiWriterProjectResponse,
  GetByIdAiWriterProjectResponse,
  UpdateAiWriterProjectRequest,
  UpdateAiWriterProjectResponse
} from './types';

const AiWriterAPI = {
  create: (payload: CreateAiWriterProjectRequest) =>
    AxiosClient.post<CreateAiWriterProjectRequest, CreateAiWriterProjectResponse>(
      '/projects/ai-writer',
      payload
    ),
  delete: (id: string) =>
    AxiosClient.delete<unknown, DeleteAiWriterProjectResponse>('/projects/ai-writer', {
      data: { ids: [id] }
    }),
  get: (params: ApiPaginationParams) =>
    AxiosClient.get<unknown, GetAiWriterProjectResponse>('/projects/ai-writer', { params }),
  getById: (id: string) =>
    AxiosClient.get<unknown, GetByIdAiWriterProjectResponse>(`/projects/ai-writer/${id}`),
  update: (id: string, payload: UpdateAiWriterProjectRequest) =>
    AxiosClient.put<unknown, UpdateAiWriterProjectResponse>(`/projects/ai-writer/${id}`, payload),
  preview: (hash: string) =>
    AxiosClient.get<unknown, AiWriterProjectPreviewResponse>(`/projects/ai-writer/public/${hash}`)
};

export default AiWriterAPI;
