import { LinearProgress, Typography } from '@mui/material';
import FlexContainer from 'components/FlexContainer';
import { useGetTimeUntilTrialExpiration } from 'features/pricing/hook/useGetTimeUntilTrialExpiration';
import { useIsOnTrial } from 'features/pricing/hook/useIsOnTrial';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

export const TrialExpiryProgressBar = () => {
  const { daysLeft, hoursLeft } = useGetTimeUntilTrialExpiration();

  const isOnTrial = useIsOnTrial();

  if (!isOnTrial) {
    return null;
  }

  return (
    <Root>
      <Typography variant="caption">
        <FormattedMessage
          id="trial_expiry.progress_bar.title"
          values={{ days: daysLeft, hours: hoursLeft }}
        />
      </Typography>
      <ProgressBar value={daysLeft} variant="determinate" />
    </Root>
  );
};

const ProgressBar = styled(LinearProgress)`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.primaryColorFocus};

  .MuiLinearProgress-bar1Determinate {
    background-color: ${({ theme }) => theme.colors.primaryColorMain};
  }
`;

const Root = styled(FlexContainer).attrs({ gap: 'two' })`
  padding: ${({ theme }) => theme.spacings.four};
`;
