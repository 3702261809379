import { useQuery } from '@tanstack/react-query';
import LocalStorageKey from 'config/localStorageKey';
import { makeWordsUsageQueryKeyPrefix } from 'features/wordsUsage/invalidateWordsUsageQueries';
import CustomerAPI from 'services/api/customer';

export function useGetWordsCurrentUsage() {
  const apiToken = localStorage.getItem(LocalStorageKey.APIToken);

  return useQuery({
    enabled: !!apiToken,
    queryKey: [...makeWordsUsageQueryKeyPrefix(), 'current'],
    queryFn: () => CustomerAPI.getWordsCurrentUsage()
  });
}
