import Alert from 'components/base/Alert';
import { ReactNode } from 'react';

interface Props {
  className?: string;
  children: ReactNode;
}

const AlertError = ({ children, className }: Props) => (
  <Alert type="danger" icon="alert-circle" className={className}>
    {children}
  </Alert>
);

export default AlertError;
