import { Options } from 'react-select';
import AudienceAPI from 'services/api/audience';
import { handleAudienceErrors } from 'services/api/audience/errors';
import { Audience, GetAllAudiencesResponse } from 'services/api/audience/types';
import { useDebouncedCallback } from 'use-debounce';

const loadAudiences = async (name: string, callback: (items: Options<Audience>) => void) => {
  const response: GetAllAudiencesResponse = await AudienceAPI.getAll(name);

  if (response.status) {
    callback(response.data);
  } else {
    handleAudienceErrors(response.message);
  }
};

export const getNewAudienceId = async (audience?: Audience | null) => {
  let audienceId = audience?.id;

  if (audience && audience.id === 0) {
    const { data: newProjectData } = await AudienceAPI.create({ name: audience.name });
    audienceId = newProjectData.id;
  }

  return audienceId || null;
};

function useLoadProjects() {
  const [loadProjectsDebounced] = useDebouncedCallback(loadAudiences, 300);

  return loadProjectsDebounced;
}

export default useLoadProjects;
