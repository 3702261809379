import { createPluginFactory, isElement, PlateEditor, setNodes } from '@udecode/plate';
import {
  convertDeprecatedNodeType,
  isDeprecatedNodeType
} from 'features/aiWriter/AiWriterTextEditor/utils/deprecatedNodeTypeMigration';

export const KEY_TYPE_MIGRATION = 'NF - type migration';

/**
 * #tech-debt: https://app.clickup.com/t/862hxrfhb
 * We need this to maintain code that inserts deprecated node types
 */
export const createTypeMigrationPlugin = createPluginFactory({
  key: KEY_TYPE_MIGRATION,
  withOverrides: withTypeMigration
});

function withTypeMigration(editor: PlateEditor) {
  const { normalizeNode } = editor;

  editor.normalizeNode = ([node, path]) => {
    if (isElement(node) && isDeprecatedNodeType(node.type)) {
      setNodes(editor, { type: convertDeprecatedNodeType(node.type) }, { at: path });
    }

    return normalizeNode([node, path]);
  };

  return editor;
}
