import { AppLanguage } from 'features/language/store/types';

/**
 * ISO 3166-1 alpha-2: two-letter code
 *
 * https://en.wikipedia.org/wiki/Country_code
 */
export type IsoCountryCode = `${IsoCountries}`;

/**
 * A list of ISO 3166-1 alpha-2 country codes
 *
 * Based on https://gist.github.com/kyranjamie/646386d5edc174e8b549111572897f81?permalink_comment_id=4321789#gistcomment-4321789
 */
export const enum IsoCountries {
  Afghanistan = 'af',
  AlandIslands = 'ax',
  Albania = 'al',
  Algeria = 'dz',
  AmericanSamoa = 'as',
  Andorra = 'ad',
  Angola = 'ao',
  Anguilla = 'ai',
  Antarctica = 'aq',
  AntiguaAndBarbuda = 'ag',
  Argentina = 'ar',
  Armenia = 'am',
  Aruba = 'aw',
  Australia = 'au',
  Austria = 'at',
  Azerbaijan = 'az',
  Bahamas = 'bs',
  Bahrain = 'bh',
  Bangladesh = 'bd',
  Barbados = 'bb',
  Belarus = 'by',
  Belgium = 'be',
  Belize = 'bz',
  Benin = 'bj',
  Bermuda = 'bm',
  Bhutan = 'bt',
  Bolivia = 'bo',
  BonaireSintEustatiusSaba = 'bq',
  BosniaAndHerzegovina = 'ba',
  Botswana = 'bw',
  BouvetIsland = 'bv',
  Brazil = 'br',
  BritishIndianOceanTerritory = 'io',
  BruneiDarussalam = 'bn',
  Bulgaria = 'bg',
  BurkinaFaso = 'bf',
  Burundi = 'bi',
  Cambodia = 'kh',
  Cameroon = 'cm',
  Canada = 'ca',
  CapeVerde = 'cv',
  CaymanIslands = 'ky',
  CentralAfricanRepublic = 'cf',
  Chad = 'td',
  Chile = 'cl',
  China = 'cn',
  ChristmasIsland = 'cx',
  CocosKeelingIslands = 'cc',
  Colombia = 'co',
  Comoros = 'km',
  Congo = 'cg',
  CongoDemocraticRepublic = 'cd',
  CookIslands = 'ck',
  CostaRica = 'cr',
  CoteDIvoire = 'ci',
  Croatia = 'hr',
  Cuba = 'cu',
  Curacao = 'cw',
  Cyprus = 'cy',
  CzechRepublic = 'cz',
  Denmark = 'dk',
  Djibouti = 'dj',
  Dominica = 'dm',
  DominicanRepublic = 'do',
  Ecuador = 'ec',
  Egypt = 'eg',
  ElSalvador = 'sv',
  EquatorialGuinea = 'gq',
  Eritrea = 'er',
  Estonia = 'ee',
  Ethiopia = 'et',
  FalklandIslands = 'fk',
  FaroeIslands = 'fo',
  Fiji = 'fj',
  Finland = 'fi',
  France = 'fr',
  FrenchGuiana = 'gf',
  FrenchPolynesia = 'pf',
  FrenchSouthernTerritories = 'tf',
  Gabon = 'ga',
  Gambia = 'gm',
  Georgia = 'ge',
  Germany = 'de',
  Ghana = 'gh',
  Gibraltar = 'gi',
  Greece = 'gr',
  Greenland = 'gl',
  Grenada = 'gd',
  Guadeloupe = 'gp',
  Guam = 'gu',
  Guatemala = 'gt',
  Guernsey = 'gg',
  Guinea = 'gn',
  GuineaBissau = 'gw',
  Guyana = 'gy',
  Haiti = 'ht',
  HeardIslandMcdonaldIslands = 'hm',
  HolySeeVaticanCityState = 'va',
  Honduras = 'hn',
  HongKong = 'hk',
  Hungary = 'hu',
  Iceland = 'is',
  India = 'in',
  Indonesia = 'id',
  Iran = 'ir',
  Iraq = 'iq',
  Ireland = 'ie',
  IsleOfMan = 'im',
  Israel = 'il',
  Italy = 'it',
  Jamaica = 'jm',
  Japan = 'jp',
  Jersey = 'je',
  Jordan = 'jo',
  Kazakhstan = 'kz',
  Kenya = 'ke',
  Kiribati = 'ki',
  Korea = 'kr',
  KoreaDemocraticPeoplesRepublic = 'kp',
  Kuwait = 'kw',
  Kyrgyzstan = 'kg',
  LaoPeoplesDemocraticRepublic = 'la',
  Latvia = 'lv',
  Lebanon = 'lb',
  Lesotho = 'ls',
  Liberia = 'lr',
  LibyanArabJamahiriya = 'ly',
  Liechtenstein = 'li',
  Lithuania = 'lt',
  Luxembourg = 'lu',
  Macao = 'mo',
  Macedonia = 'mk',
  Madagascar = 'mg',
  Malawi = 'mw',
  Malaysia = 'my',
  Maldives = 'mv',
  Mali = 'ml',
  Malta = 'mt',
  MarshallIslands = 'mh',
  Martinique = 'mq',
  Mauritania = 'mr',
  Mauritius = 'mu',
  Mayotte = 'yt',
  Mexico = 'mx',
  Micronesia = 'fm',
  Moldova = 'md',
  Monaco = 'mc',
  Mongolia = 'mn',
  Montenegro = 'me',
  Montserrat = 'ms',
  Morocco = 'ma',
  Mozambique = 'mz',
  Myanmar = 'mm',
  Namibia = 'na',
  Nauru = 'nr',
  Nepal = 'np',
  Netherlands = 'nl',
  NewCaledonia = 'nc',
  NewZealand = 'nz',
  Nicaragua = 'ni',
  Niger = 'ne',
  Nigeria = 'ng',
  Niue = 'nu',
  NorfolkIsland = 'nf',
  NorthernMarianaIslands = 'mp',
  Norway = 'no',
  Oman = 'om',
  Pakistan = 'pk',
  Palau = 'pw',
  PalestinianTerritory = 'ps',
  Panama = 'pa',
  PapuaNewGuinea = 'pg',
  Paraguay = 'py',
  Peru = 'pe',
  Philippines = 'ph',
  Pitcairn = 'pn',
  Poland = 'pl',
  Portugal = 'pt',
  PuertoRico = 'pr',
  Qatar = 'qa',
  Reunion = 're',
  Romania = 'ro',
  RussianFederation = 'ru',
  Rwanda = 'rw',
  SaintBarthelemy = 'bl',
  SaintHelena = 'sh',
  SaintKittsAndNevis = 'kn',
  SaintLucia = 'lc',
  SaintMartin = 'mf',
  SaintPierreAndMiquelon = 'pm',
  SaintVincentAndGrenadines = 'vc',
  Samoa = 'ws',
  SanMarino = 'sm',
  SaoTomeAndPrincipe = 'st',
  SaudiArabia = 'sa',
  Senegal = 'sn',
  Serbia = 'rs',
  Seychelles = 'sc',
  SierraLeone = 'sl',
  Singapore = 'sg',
  SintMaarten = 'sx',
  Slovakia = 'sk',
  Slovenia = 'si',
  SolomonIslands = 'sb',
  Somalia = 'so',
  SouthAfrica = 'za',
  SouthGeorgiaAndSandwichIsl = 'gs',
  SouthSudan = 'ss',
  Spain = 'es',
  SriLanka = 'lk',
  Sudan = 'sd',
  Suriname = 'sr',
  SvalbardAndJanMayen = 'sj',
  Swaziland = 'sz',
  Sweden = 'se',
  Switzerland = 'ch',
  SyrianArabRepublic = 'sy',
  Taiwan = 'tw',
  Tajikistan = 'tj',
  Tanzania = 'tz',
  Thailand = 'th',
  TimorLeste = 'tl',
  Togo = 'tg',
  Tokelau = 'tk',
  Tonga = 'to',
  TrinidadAndTobago = 'tt',
  Tunisia = 'tn',
  Turkey = 'tr',
  Turkmenistan = 'tm',
  TurksAndCaicosIslands = 'tc',
  Tuvalu = 'tv',
  Uganda = 'ug',
  Ukraine = 'ua',
  UnitedArabEmirates = 'ae',
  UnitedKingdom = 'gb',
  UnitedStates = 'us',
  UnitedStatesOutlyingIslands = 'um',
  Uruguay = 'uy',
  Uzbekistan = 'uz',
  Vanuatu = 'vu',
  Venezuela = 've',
  Vietnam = 'vn',
  VirginIslandsBritish = 'vg',
  VirginIslandsUS = 'vi',
  WallisAndFutuna = 'wf',
  WesternSahara = 'eh',
  Yemen = 'ye',
  Zambia = 'zm',
  Zimbabwe = 'zw'
}

type CountryConfig = {
  code: string;
  labels: Record<AppLanguage, string>;
};

/**
 * @deprecated Use useTr() hook instead
 */
export const isoCountries = new Map<string, CountryConfig>([
  [
    'ad',
    {
      code: 'ad',
      labels: {
        [AppLanguage.English]: 'Andorra',
        [AppLanguage.Spanish]: 'Andorra',
        [AppLanguage.German]: 'Andorra'
      }
    }
  ],
  [
    'ae',
    {
      code: 'ae',
      labels: {
        [AppLanguage.English]: 'United Arab Emirates',
        [AppLanguage.Spanish]: 'United Arab Emirates',
        [AppLanguage.German]: 'Vereinigte Arabische Emirate'
      }
    }
  ],
  [
    'af',
    {
      code: 'af',
      labels: {
        [AppLanguage.English]: 'Afghanistan',
        [AppLanguage.Spanish]: 'Afghanistan',
        [AppLanguage.German]: 'Afghanistan'
      }
    }
  ],
  [
    'ag',
    {
      code: 'ag',
      labels: {
        [AppLanguage.English]: 'Antigua and Barbuda',
        [AppLanguage.Spanish]: 'Antigua and Barbuda',
        [AppLanguage.German]: 'Antigua und Barbuda'
      }
    }
  ],
  [
    'ai',
    {
      code: 'ai',
      labels: {
        [AppLanguage.English]: 'Anguilla',
        [AppLanguage.Spanish]: 'Anguilla',
        [AppLanguage.German]: 'Anguilla'
      }
    }
  ],
  [
    'al',
    {
      code: 'al',
      labels: {
        [AppLanguage.English]: 'Albania',
        [AppLanguage.Spanish]: 'Albania',
        [AppLanguage.German]: 'Albanien'
      }
    }
  ],
  [
    'am',
    {
      code: 'am',
      labels: {
        [AppLanguage.English]: 'Armenia',
        [AppLanguage.Spanish]: 'Armenia',
        [AppLanguage.German]: 'Armenien'
      }
    }
  ],
  [
    'an',
    {
      code: 'an',
      labels: {
        [AppLanguage.English]: 'Netherlands Antilles',
        [AppLanguage.Spanish]: 'Netherlands Antilles',
        [AppLanguage.German]: 'Niederländische Antillen'
      }
    }
  ],
  [
    'ao',
    {
      code: 'ao',
      labels: {
        [AppLanguage.English]: 'Angola',
        [AppLanguage.Spanish]: 'Angola',
        [AppLanguage.German]: 'Angola'
      }
    }
  ],
  [
    'aq',
    {
      code: 'aq',
      labels: {
        [AppLanguage.English]: 'Antarctica',
        [AppLanguage.Spanish]: 'Antarctica',
        [AppLanguage.German]: 'Antarktis'
      }
    }
  ],
  [
    'ar',
    {
      code: 'ar',
      labels: {
        [AppLanguage.English]: 'Argentina',
        [AppLanguage.Spanish]: 'Argentina',
        [AppLanguage.German]: 'Argentinien'
      }
    }
  ],
  [
    'as',
    {
      code: 'as',
      labels: {
        [AppLanguage.English]: 'American Samoa',
        [AppLanguage.Spanish]: 'American Samoa',
        [AppLanguage.German]: 'Samoa'
      }
    }
  ],
  [
    'at',
    {
      code: 'at',
      labels: {
        [AppLanguage.English]: 'AU',
        [AppLanguage.Spanish]: 'AU',
        [AppLanguage.German]: 'AU'
      }
    }
  ],
  [
    'au',
    {
      code: 'au',
      labels: {
        [AppLanguage.English]: 'Australia',
        [AppLanguage.Spanish]: 'Australia',
        [AppLanguage.German]: 'Australien'
      }
    }
  ],
  [
    'aw',
    {
      code: 'aw',
      labels: {
        [AppLanguage.English]: 'Aruba',
        [AppLanguage.Spanish]: 'Aruba',
        [AppLanguage.German]: 'Aruba'
      }
    }
  ],
  [
    'az',
    {
      code: 'az',
      labels: {
        [AppLanguage.English]: 'Azerbaijan',
        [AppLanguage.Spanish]: 'Azerbaijan',
        [AppLanguage.German]: 'Aserbaidschan'
      }
    }
  ],
  [
    'ba',
    {
      code: 'ba',
      labels: {
        [AppLanguage.English]: 'Bosnia and Herzegovina',
        [AppLanguage.Spanish]: 'Bosnia and Herzegovina',
        [AppLanguage.German]: 'Bosnien-Herzegowina'
      }
    }
  ],
  [
    'bb',
    {
      code: 'bb',
      labels: {
        [AppLanguage.English]: 'Barbados',
        [AppLanguage.Spanish]: 'Barbados',
        [AppLanguage.German]: 'Barbados'
      }
    }
  ],
  [
    'bd',
    {
      code: 'bd',
      labels: {
        [AppLanguage.English]: 'Bangladesh',
        [AppLanguage.Spanish]: 'Bangladesh',
        [AppLanguage.German]: 'Bangladesh'
      }
    }
  ],
  [
    'be',
    {
      code: 'be',
      labels: {
        [AppLanguage.English]: 'Belgium',
        [AppLanguage.Spanish]: 'Belgium',
        [AppLanguage.German]: 'Belgien'
      }
    }
  ],
  [
    'bf',
    {
      code: 'bf',
      labels: {
        [AppLanguage.English]: 'Burkina Faso',
        [AppLanguage.Spanish]: 'Burkina Faso',
        [AppLanguage.German]: 'Burkina Faso'
      }
    }
  ],
  [
    'bg',
    {
      code: 'bg',
      labels: {
        [AppLanguage.English]: 'Bulgaria',
        [AppLanguage.Spanish]: 'Bulgaria',
        [AppLanguage.German]: 'Bulgarien'
      }
    }
  ],
  [
    'bh',
    {
      code: 'bh',
      labels: {
        [AppLanguage.English]: 'Bahrain',
        [AppLanguage.Spanish]: 'Bahrain',
        [AppLanguage.German]: 'Bahrain'
      }
    }
  ],
  [
    'bi',
    {
      code: 'bi',
      labels: {
        [AppLanguage.English]: 'Burundi',
        [AppLanguage.Spanish]: 'Burundi',
        [AppLanguage.German]: 'Burundi'
      }
    }
  ],
  [
    'bj',
    {
      code: 'bj',
      labels: {
        [AppLanguage.English]: 'Benin',
        [AppLanguage.Spanish]: 'Benin',
        [AppLanguage.German]: 'Benin'
      }
    }
  ],
  [
    'bm',
    {
      code: 'bm',
      labels: {
        [AppLanguage.English]: 'Bermuda',
        [AppLanguage.Spanish]: 'Bermuda',
        [AppLanguage.German]: 'Bermudas'
      }
    }
  ],
  [
    'bn',
    {
      code: 'bn',
      labels: {
        [AppLanguage.English]: 'Brunei Darussalam',
        [AppLanguage.Spanish]: 'Brunei Darussalam',
        [AppLanguage.German]: 'Brunei'
      }
    }
  ],
  [
    'bo',
    {
      code: 'bo',
      labels: {
        [AppLanguage.English]: 'Bolivia, Plurinational State of',
        [AppLanguage.Spanish]: 'Bolivia, Plurinational State of',
        [AppLanguage.German]: 'Bolivien'
      }
    }
  ],
  [
    'br',
    {
      code: 'br',
      labels: {
        [AppLanguage.English]: 'Brazil',
        [AppLanguage.Spanish]: 'Brazil',
        [AppLanguage.German]: 'Brasilien'
      }
    }
  ],
  [
    'bs',
    {
      code: 'bs',
      labels: {
        [AppLanguage.English]: 'Bahamas',
        [AppLanguage.Spanish]: 'Bahamas',
        [AppLanguage.German]: 'Bahamas'
      }
    }
  ],
  [
    'bt',
    {
      code: 'bt',
      labels: {
        [AppLanguage.English]: 'Bhutan',
        [AppLanguage.Spanish]: 'Bhutan',
        [AppLanguage.German]: 'Bhutan'
      }
    }
  ],
  [
    'bv',
    {
      code: 'bv',
      labels: {
        [AppLanguage.English]: 'Bouvet Island',
        [AppLanguage.Spanish]: 'Bouvet Island',
        [AppLanguage.German]: 'Bouvet Inseln'
      }
    }
  ],
  [
    'bw',
    {
      code: 'bw',
      labels: {
        [AppLanguage.English]: 'Botswana',
        [AppLanguage.Spanish]: 'Botswana',
        [AppLanguage.German]: 'Botswana'
      }
    }
  ],
  [
    'by',
    {
      code: 'by',
      labels: {
        [AppLanguage.English]: 'Belarus',
        [AppLanguage.Spanish]: 'Belarus',
        [AppLanguage.German]: 'Weissrussland'
      }
    }
  ],
  [
    'bz',
    {
      code: 'bz',
      labels: {
        [AppLanguage.English]: 'Belize',
        [AppLanguage.Spanish]: 'Belize',
        [AppLanguage.German]: 'Belize'
      }
    }
  ],
  [
    'ca',
    {
      code: 'ca',
      labels: {
        [AppLanguage.English]: 'Canada',
        [AppLanguage.Spanish]: 'Canada',
        [AppLanguage.German]: 'Kanada'
      }
    }
  ],
  [
    'cc',
    {
      code: 'cc',
      labels: {
        [AppLanguage.English]: 'Cocos (Keeling) Islands',
        [AppLanguage.Spanish]: 'Cocos (Keeling) Islands',
        [AppLanguage.German]: 'Kokosinseln'
      }
    }
  ],
  [
    'cd',
    {
      code: 'cd',
      labels: {
        [AppLanguage.English]: 'Congo, the Democratic Republic of the',
        [AppLanguage.Spanish]: 'Congo, the Democratic Republic of the',
        [AppLanguage.German]: 'Kongo (Demokratische Republik)'
      }
    }
  ],
  [
    'cf',
    {
      code: 'cf',
      labels: {
        [AppLanguage.English]: 'Central African Republic',
        [AppLanguage.Spanish]: 'Central African Republic',
        [AppLanguage.German]: 'Zentralafrikanische Republik'
      }
    }
  ],
  [
    'cg',
    {
      code: 'cg',
      labels: {
        [AppLanguage.English]: 'Congo',
        [AppLanguage.Spanish]: 'Congo',
        [AppLanguage.German]: 'Kongo'
      }
    }
  ],
  [
    'ch',
    {
      code: 'ch',
      labels: {
        [AppLanguage.English]: 'CH',
        [AppLanguage.Spanish]: 'CH',
        [AppLanguage.German]: 'CH'
      }
    }
  ],
  [
    'ci',
    {
      code: 'ci',
      labels: {
        [AppLanguage.English]: "Côte d'Ivoire",
        [AppLanguage.Spanish]: "Côte d'Ivoire",
        [AppLanguage.German]: 'Elfenbeinküste'
      }
    }
  ],
  [
    'ck',
    {
      code: 'ck',
      labels: {
        [AppLanguage.English]: 'Cook Islands',
        [AppLanguage.Spanish]: 'Cook Islands',
        [AppLanguage.German]: 'Cook Inseln'
      }
    }
  ],
  [
    'cl',
    {
      code: 'cl',
      labels: {
        [AppLanguage.English]: 'Chile',
        [AppLanguage.Spanish]: 'Chile',
        [AppLanguage.German]: 'Chile'
      }
    }
  ],
  [
    'cm',
    {
      code: 'cm',
      labels: {
        [AppLanguage.English]: 'Cameroon',
        [AppLanguage.Spanish]: 'Cameroon',
        [AppLanguage.German]: 'Kamerun'
      }
    }
  ],
  [
    'cn',
    {
      code: 'cn',
      labels: {
        [AppLanguage.English]: 'China',
        [AppLanguage.Spanish]: 'China',
        [AppLanguage.German]: 'China'
      }
    }
  ],
  [
    'co',
    {
      code: 'co',
      labels: {
        [AppLanguage.English]: 'Colombia',
        [AppLanguage.Spanish]: 'Colombia',
        [AppLanguage.German]: 'Kolumbien'
      }
    }
  ],
  [
    'cr',
    {
      code: 'cr',
      labels: {
        [AppLanguage.English]: 'Costa Rica',
        [AppLanguage.Spanish]: 'Costa Rica',
        [AppLanguage.German]: 'Costa Rica'
      }
    }
  ],
  [
    'cu',
    {
      code: 'cu',
      labels: {
        [AppLanguage.English]: 'Cuba',
        [AppLanguage.Spanish]: 'Cuba',
        [AppLanguage.German]: 'Kuba'
      }
    }
  ],
  [
    'cv',
    {
      code: 'cv',
      labels: {
        [AppLanguage.English]: 'Cape Verde',
        [AppLanguage.Spanish]: 'Cape Verde',
        [AppLanguage.German]: 'Kap Verde'
      }
    }
  ],
  [
    'cx',
    {
      code: 'cx',
      labels: {
        [AppLanguage.English]: 'Christmas Island',
        [AppLanguage.Spanish]: 'Christmas Island',
        [AppLanguage.German]: 'Christmas Island'
      }
    }
  ],
  [
    'cy',
    {
      code: 'cy',
      labels: {
        [AppLanguage.English]: 'Cyprus',
        [AppLanguage.Spanish]: 'Cyprus',
        [AppLanguage.German]: 'Zypern'
      }
    }
  ],
  [
    'cz',
    {
      code: 'cz',
      labels: {
        [AppLanguage.English]: 'Czech Republic',
        [AppLanguage.Spanish]: 'Czech Republic',
        [AppLanguage.German]: 'Tschechische Republik'
      }
    }
  ],
  [
    'de',
    {
      code: 'de',
      labels: {
        [AppLanguage.English]: 'DE',
        [AppLanguage.Spanish]: 'DE',
        [AppLanguage.German]: 'DE'
      }
    }
  ],
  [
    'dj',
    {
      code: 'dj',
      labels: {
        [AppLanguage.English]: 'Djibouti',
        [AppLanguage.Spanish]: 'Djibouti',
        [AppLanguage.German]: 'Djibuti'
      }
    }
  ],
  [
    'dk',
    {
      code: 'dk',
      labels: {
        [AppLanguage.English]: 'Denmark',
        [AppLanguage.Spanish]: 'Denmark',
        [AppLanguage.German]: 'Dänemark'
      }
    }
  ],
  [
    'dm',
    {
      code: 'dm',
      labels: {
        [AppLanguage.English]: 'Dominica',
        [AppLanguage.Spanish]: 'Dominica',
        [AppLanguage.German]: 'Dominika'
      }
    }
  ],
  [
    'do',
    {
      code: 'do',
      labels: {
        [AppLanguage.English]: 'Dominican Republic',
        [AppLanguage.Spanish]: 'Dominican Republic',
        [AppLanguage.German]: 'Dominikanische Republik'
      }
    }
  ],
  [
    'dz',
    {
      code: 'dz',
      labels: {
        [AppLanguage.English]: 'Algeria',
        [AppLanguage.Spanish]: 'Algeria',
        [AppLanguage.German]: 'Algerien'
      }
    }
  ],
  [
    'ec',
    {
      code: 'ec',
      labels: {
        [AppLanguage.English]: 'Ecuador',
        [AppLanguage.Spanish]: 'Ecuador',
        [AppLanguage.German]: 'Ecuador'
      }
    }
  ],
  [
    'ee',
    {
      code: 'ee',
      labels: {
        [AppLanguage.English]: 'Estonia',
        [AppLanguage.Spanish]: 'Estonia',
        [AppLanguage.German]: 'Estland'
      }
    }
  ],
  [
    'eg',
    {
      code: 'eg',
      labels: {
        [AppLanguage.English]: 'Egypt',
        [AppLanguage.Spanish]: 'Egypt',
        [AppLanguage.German]: 'Ägypten'
      }
    }
  ],
  [
    'eh',
    {
      code: 'eh',
      labels: {
        [AppLanguage.English]: 'Western Sahara',
        [AppLanguage.Spanish]: 'Western Sahara',
        [AppLanguage.German]: 'Westsahara'
      }
    }
  ],
  [
    'er',
    {
      code: 'er',
      labels: {
        [AppLanguage.English]: 'Eritrea',
        [AppLanguage.Spanish]: 'Eritrea',
        [AppLanguage.German]: 'Eritrea'
      }
    }
  ],
  [
    'es',
    {
      code: 'es',
      labels: {
        [AppLanguage.English]: 'ES',
        [AppLanguage.Spanish]: 'ES',
        [AppLanguage.German]: 'ES'
      }
    }
  ],
  [
    'et',
    {
      code: 'et',
      labels: {
        [AppLanguage.English]: 'Ethiopia',
        [AppLanguage.Spanish]: 'Ethiopia',
        [AppLanguage.German]: 'Äthiopien'
      }
    }
  ],
  [
    'fi',
    {
      code: 'fi',
      labels: {
        [AppLanguage.English]: 'Finland',
        [AppLanguage.Spanish]: 'Finland',
        [AppLanguage.German]: 'Finnland'
      }
    }
  ],
  [
    'fj',
    {
      code: 'fj',
      labels: {
        [AppLanguage.English]: 'Fiji',
        [AppLanguage.Spanish]: 'Fiji',
        [AppLanguage.German]: 'Fidschi'
      }
    }
  ],
  [
    'fk',
    {
      code: 'fk',
      labels: {
        [AppLanguage.English]: 'Falkland Islands (Malvinas)',
        [AppLanguage.Spanish]: 'Falkland Islands (Malvinas)',
        [AppLanguage.German]: 'Falkland Inseln'
      }
    }
  ],
  [
    'fm',
    {
      code: 'fm',
      labels: {
        [AppLanguage.English]: 'Micronesia, Federated States of',
        [AppLanguage.Spanish]: 'Micronesia, Federated States of',
        [AppLanguage.German]: 'Mikronesien'
      }
    }
  ],
  [
    'fo',
    {
      code: 'fo',
      labels: {
        [AppLanguage.English]: 'Faroe Islands',
        [AppLanguage.Spanish]: 'Faroe Islands',
        [AppLanguage.German]: 'Färöer Inseln'
      }
    }
  ],
  [
    'fr',
    {
      code: 'fr',
      labels: {
        [AppLanguage.English]: 'FR',
        [AppLanguage.Spanish]: 'FR',
        [AppLanguage.German]: 'FR'
      }
    }
  ],
  [
    'ga',
    {
      code: 'ga',
      labels: {
        [AppLanguage.English]: 'Gabon',
        [AppLanguage.Spanish]: 'Gabon',
        [AppLanguage.German]: 'Gabun'
      }
    }
  ],
  [
    'gb',
    {
      code: 'gb',
      labels: {
        [AppLanguage.English]: 'United Kingdom',
        [AppLanguage.Spanish]: 'United Kingdom',
        [AppLanguage.German]: 'Großbritannien (UK)'
      }
    }
  ],
  [
    'gd',
    {
      code: 'gd',
      labels: {
        [AppLanguage.English]: 'Grenada',
        [AppLanguage.Spanish]: 'Grenada',
        [AppLanguage.German]: 'Grenada'
      }
    }
  ],
  [
    'ge',
    {
      code: 'ge',
      labels: {
        [AppLanguage.English]: 'Georgia',
        [AppLanguage.Spanish]: 'Georgia',
        [AppLanguage.German]: 'Georgien'
      }
    }
  ],
  [
    'gf',
    {
      code: 'gf',
      labels: {
        [AppLanguage.English]: 'French Guiana',
        [AppLanguage.Spanish]: 'French Guiana',
        [AppLanguage.German]: 'französisch Guyana'
      }
    }
  ],
  [
    'gh',
    {
      code: 'gh',
      labels: {
        [AppLanguage.English]: 'Ghana',
        [AppLanguage.Spanish]: 'Ghana',
        [AppLanguage.German]: 'Ghana'
      }
    }
  ],
  [
    'gi',
    {
      code: 'gi',
      labels: {
        [AppLanguage.English]: 'Gibraltar',
        [AppLanguage.Spanish]: 'Gibraltar',
        [AppLanguage.German]: 'Gibraltar'
      }
    }
  ],
  [
    'gl',
    {
      code: 'gl',
      labels: {
        [AppLanguage.English]: 'Greenland',
        [AppLanguage.Spanish]: 'Greenland',
        [AppLanguage.German]: 'GrÃ¶nland'
      }
    }
  ],
  [
    'gm',
    {
      code: 'gm',
      labels: {
        [AppLanguage.English]: 'Gambia',
        [AppLanguage.Spanish]: 'Gambia',
        [AppLanguage.German]: 'Gambia'
      }
    }
  ],
  [
    'gn',
    {
      code: 'gn',
      labels: {
        [AppLanguage.English]: 'Guinea',
        [AppLanguage.Spanish]: 'Guinea',
        [AppLanguage.German]: 'Guinea'
      }
    }
  ],
  [
    'gp',
    {
      code: 'gp',
      labels: {
        [AppLanguage.English]: 'Guadeloupe',
        [AppLanguage.Spanish]: 'Guadeloupe',
        [AppLanguage.German]: 'Guadeloupe'
      }
    }
  ],
  [
    'gq',
    {
      code: 'gq',
      labels: {
        [AppLanguage.English]: 'Equatorial Guinea',
        [AppLanguage.Spanish]: 'Equatorial Guinea',
        [AppLanguage.German]: 'Äquatorial Guinea'
      }
    }
  ],
  [
    'gr',
    {
      code: 'gr',
      labels: {
        [AppLanguage.English]: 'Greece',
        [AppLanguage.Spanish]: 'Greece',
        [AppLanguage.German]: 'Griechenland'
      }
    }
  ],
  [
    'gs',
    {
      code: 'gs',
      labels: {
        [AppLanguage.English]: 'South Georgia and the South Sandwich Islands',
        [AppLanguage.Spanish]: 'South Georgia and the South Sandwich Islands',
        [AppLanguage.German]: 'South Georgia (South Sandwich Isl.)'
      }
    }
  ],
  [
    'gt',
    {
      code: 'gt',
      labels: {
        [AppLanguage.English]: 'Guatemala',
        [AppLanguage.Spanish]: 'Guatemala',
        [AppLanguage.German]: 'Guatemala'
      }
    }
  ],
  [
    'gu',
    {
      code: 'gu',
      labels: {
        [AppLanguage.English]: 'Guam',
        [AppLanguage.Spanish]: 'Guam',
        [AppLanguage.German]: 'Guam'
      }
    }
  ],
  [
    'gw',
    {
      code: 'gw',
      labels: {
        [AppLanguage.English]: 'Guinea-Bissau',
        [AppLanguage.Spanish]: 'Guinea-Bissau',
        [AppLanguage.German]: 'Guinea Bissau'
      }
    }
  ],
  [
    'gy',
    {
      code: 'gy',
      labels: {
        [AppLanguage.English]: 'Guyana',
        [AppLanguage.Spanish]: 'Guyana',
        [AppLanguage.German]: 'Guyana'
      }
    }
  ],
  [
    'hk',
    {
      code: 'hk',
      labels: {
        [AppLanguage.English]: 'Hong Kong',
        [AppLanguage.Spanish]: 'Hong Kong',
        [AppLanguage.German]: 'Hong Kong'
      }
    }
  ],
  [
    'hm',
    {
      code: 'hm',
      labels: {
        [AppLanguage.English]: 'Heard Island and McDonald Islands',
        [AppLanguage.Spanish]: 'Heard Island and McDonald Islands',
        [AppLanguage.German]: 'Heard und McDonald Islands'
      }
    }
  ],
  [
    'hn',
    {
      code: 'hn',
      labels: {
        [AppLanguage.English]: 'Honduras',
        [AppLanguage.Spanish]: 'Honduras',
        [AppLanguage.German]: 'Honduras'
      }
    }
  ],
  [
    'hr',
    {
      code: 'hr',
      labels: {
        [AppLanguage.English]: 'Croatia',
        [AppLanguage.Spanish]: 'Croatia',
        [AppLanguage.German]: 'Kroatien'
      }
    }
  ],
  [
    'ht',
    {
      code: 'ht',
      labels: {
        [AppLanguage.English]: 'Haiti',
        [AppLanguage.Spanish]: 'Haiti',
        [AppLanguage.German]: 'Haiti'
      }
    }
  ],
  [
    'hu',
    {
      code: 'hu',
      labels: {
        [AppLanguage.English]: 'Hungary',
        [AppLanguage.Spanish]: 'Hungary',
        [AppLanguage.German]: 'Ungarn'
      }
    }
  ],
  [
    'id',
    {
      code: 'id',
      labels: {
        [AppLanguage.English]: 'Indonesia',
        [AppLanguage.Spanish]: 'Indonesia',
        [AppLanguage.German]: 'Indonesien'
      }
    }
  ],
  [
    'ie',
    {
      code: 'ie',
      labels: {
        [AppLanguage.English]: 'Ireland',
        [AppLanguage.Spanish]: 'Ireland',
        [AppLanguage.German]: 'Irland'
      }
    }
  ],
  [
    'il',
    {
      code: 'il',
      labels: {
        [AppLanguage.English]: 'Israel',
        [AppLanguage.Spanish]: 'Israel',
        [AppLanguage.German]: 'Israel'
      }
    }
  ],
  [
    'in',
    {
      code: 'in',
      labels: {
        [AppLanguage.English]: 'India',
        [AppLanguage.Spanish]: 'India',
        [AppLanguage.German]: 'Indien'
      }
    }
  ],
  [
    'io',
    {
      code: 'io',
      labels: {
        [AppLanguage.English]: 'British Indian Ocean Territory',
        [AppLanguage.Spanish]: 'British Indian Ocean Territory',
        [AppLanguage.German]: 'Britisch-Indischer Ozean'
      }
    }
  ],
  [
    'iq',
    {
      code: 'iq',
      labels: {
        [AppLanguage.English]: 'Iraq',
        [AppLanguage.Spanish]: 'Iraq',
        [AppLanguage.German]: 'Irak'
      }
    }
  ],
  [
    'ir',
    {
      code: 'ir',
      labels: {
        [AppLanguage.English]: 'Iran, Islamic Republic of',
        [AppLanguage.Spanish]: 'Iran, Islamic Republic of',
        [AppLanguage.German]: 'Iran'
      }
    }
  ],
  [
    'is',
    {
      code: 'is',
      labels: {
        [AppLanguage.English]: 'Iceland',
        [AppLanguage.Spanish]: 'Iceland',
        [AppLanguage.German]: 'Island'
      }
    }
  ],
  [
    'it',
    {
      code: 'it',
      labels: {
        [AppLanguage.English]: 'IT',
        [AppLanguage.Spanish]: 'IT',
        [AppLanguage.German]: 'IT'
      }
    }
  ],
  [
    'jm',
    {
      code: 'jm',
      labels: {
        [AppLanguage.English]: 'Jamaica',
        [AppLanguage.Spanish]: 'Jamaica',
        [AppLanguage.German]: 'Jamaika'
      }
    }
  ],
  [
    'jo',
    {
      code: 'jo',
      labels: {
        [AppLanguage.English]: 'Jordan',
        [AppLanguage.Spanish]: 'Jordan',
        [AppLanguage.German]: 'Jordanien'
      }
    }
  ],
  [
    'jp',
    {
      code: 'jp',
      labels: {
        [AppLanguage.English]: 'Japan',
        [AppLanguage.Spanish]: 'Japan',
        [AppLanguage.German]: 'Japan'
      }
    }
  ],
  [
    'ke',
    {
      code: 'ke',
      labels: {
        [AppLanguage.English]: 'Kenya',
        [AppLanguage.Spanish]: 'Kenya',
        [AppLanguage.German]: 'Kenia'
      }
    }
  ],
  [
    'kg',
    {
      code: 'kg',
      labels: {
        [AppLanguage.English]: 'Kyrgyzstan',
        [AppLanguage.Spanish]: 'Kyrgyzstan',
        [AppLanguage.German]: 'Kirgisistan'
      }
    }
  ],
  [
    'kh',
    {
      code: 'kh',
      labels: {
        [AppLanguage.English]: 'Cambodia',
        [AppLanguage.Spanish]: 'Cambodia',
        [AppLanguage.German]: 'Kambodscha'
      }
    }
  ],
  [
    'ki',
    {
      code: 'ki',
      labels: {
        [AppLanguage.English]: 'Kiribati',
        [AppLanguage.Spanish]: 'Kiribati',
        [AppLanguage.German]: 'Kiribati'
      }
    }
  ],
  [
    'km',
    {
      code: 'km',
      labels: {
        [AppLanguage.English]: 'Comoros',
        [AppLanguage.Spanish]: 'Comoros',
        [AppLanguage.German]: 'Komoren'
      }
    }
  ],
  [
    'kn',
    {
      code: 'kn',
      labels: {
        [AppLanguage.English]: 'Saint Kitts and Nevis',
        [AppLanguage.Spanish]: 'Saint Kitts and Nevis',
        [AppLanguage.German]: 'St. Kitts Nevis Anguilla'
      }
    }
  ],
  [
    'kp',
    {
      code: 'kp',
      labels: {
        [AppLanguage.English]: "Korea, Democratic People's Republic of",
        [AppLanguage.Spanish]: "Korea, Democratic People's Republic of",
        [AppLanguage.German]: 'Nord Korea'
      }
    }
  ],
  [
    'kr',
    {
      code: 'kr',
      labels: {
        [AppLanguage.English]: 'Korea, Republic of',
        [AppLanguage.Spanish]: 'Korea, Republic of',
        [AppLanguage.German]: 'Süd Korea'
      }
    }
  ],
  [
    'kw',
    {
      code: 'kw',
      labels: {
        [AppLanguage.English]: 'Kuwait',
        [AppLanguage.Spanish]: 'Kuwait',
        [AppLanguage.German]: 'Kuwait'
      }
    }
  ],
  [
    'ky',
    {
      code: 'ky',
      labels: {
        [AppLanguage.English]: 'Cayman Islands',
        [AppLanguage.Spanish]: 'Cayman Islands',
        [AppLanguage.German]: 'Kaiman Inseln'
      }
    }
  ],
  [
    'kz',
    {
      code: 'kz',
      labels: {
        [AppLanguage.English]: 'Kazakhstan',
        [AppLanguage.Spanish]: 'Kazakhstan',
        [AppLanguage.German]: 'Kasachstan'
      }
    }
  ],
  [
    'la',
    {
      code: 'la',
      labels: {
        [AppLanguage.English]: "Lao People's Democratic Republic",
        [AppLanguage.Spanish]: "Lao People's Democratic Republic",
        [AppLanguage.German]: 'Laos'
      }
    }
  ],
  [
    'lb',
    {
      code: 'lb',
      labels: {
        [AppLanguage.English]: 'Lebanon',
        [AppLanguage.Spanish]: 'Lebanon',
        [AppLanguage.German]: 'Libanon'
      }
    }
  ],
  [
    'lc',
    {
      code: 'lc',
      labels: {
        [AppLanguage.English]: 'Saint Lucia',
        [AppLanguage.Spanish]: 'Saint Lucia',
        [AppLanguage.German]: 'Saint Lucia'
      }
    }
  ],
  [
    'li',
    {
      code: 'li',
      labels: {
        [AppLanguage.English]: 'Liechtenstein',
        [AppLanguage.Spanish]: 'Liechtenstein',
        [AppLanguage.German]: 'Liechtenstein'
      }
    }
  ],
  [
    'lk',
    {
      code: 'lk',
      labels: {
        [AppLanguage.English]: 'Sri Lanka',
        [AppLanguage.Spanish]: 'Sri Lanka',
        [AppLanguage.German]: 'Sri Lanka'
      }
    }
  ],
  [
    'lr',
    {
      code: 'lr',
      labels: {
        [AppLanguage.English]: 'Liberia',
        [AppLanguage.Spanish]: 'Liberia',
        [AppLanguage.German]: 'Liberia'
      }
    }
  ],
  [
    'ls',
    {
      code: 'ls',
      labels: {
        [AppLanguage.English]: 'Lesotho',
        [AppLanguage.Spanish]: 'Lesotho',
        [AppLanguage.German]: 'Lesotho'
      }
    }
  ],
  [
    'lt',
    {
      code: 'lt',
      labels: {
        [AppLanguage.English]: 'Lithuania',
        [AppLanguage.Spanish]: 'Lithuania',
        [AppLanguage.German]: 'Litauen'
      }
    }
  ],
  [
    'lu',
    {
      code: 'lu',
      labels: {
        [AppLanguage.English]: 'Luxembourg',
        [AppLanguage.Spanish]: 'Luxembourg',
        [AppLanguage.German]: 'Luxemburg'
      }
    }
  ],
  [
    'lv',
    {
      code: 'lv',
      labels: {
        [AppLanguage.English]: 'Latvia',
        [AppLanguage.Spanish]: 'Latvia',
        [AppLanguage.German]: 'Lettland'
      }
    }
  ],
  [
    'ly',
    {
      code: 'ly',
      labels: {
        [AppLanguage.English]: 'Libyan Arab Jamahiriya',
        [AppLanguage.Spanish]: 'Libyan Arab Jamahiriya',
        [AppLanguage.German]: 'Libyen'
      }
    }
  ],
  [
    'ma',
    {
      code: 'ma',
      labels: {
        [AppLanguage.English]: 'Morocco',
        [AppLanguage.Spanish]: 'Morocco',
        [AppLanguage.German]: 'Marokko'
      }
    }
  ],
  [
    'mc',
    {
      code: 'mc',
      labels: {
        [AppLanguage.English]: 'Monaco',
        [AppLanguage.Spanish]: 'Monaco',
        [AppLanguage.German]: 'Monaco'
      }
    }
  ],
  [
    'md',
    {
      code: 'md',
      labels: {
        [AppLanguage.English]: 'Moldova, Republic of',
        [AppLanguage.Spanish]: 'Moldova, Republic of',
        [AppLanguage.German]: 'Moldavien'
      }
    }
  ],
  [
    'mg',
    {
      code: 'mg',
      labels: {
        [AppLanguage.English]: 'Madagascar',
        [AppLanguage.Spanish]: 'Madagascar',
        [AppLanguage.German]: 'Madagaskar'
      }
    }
  ],
  [
    'mh',
    {
      code: 'mh',
      labels: {
        [AppLanguage.English]: 'Marshall Islands',
        [AppLanguage.Spanish]: 'Marshall Islands',
        [AppLanguage.German]: 'Marshall Inseln'
      }
    }
  ],
  [
    'mk',
    {
      code: 'mk',
      labels: {
        [AppLanguage.English]: 'Macedonia, the former Yugoslav Republic of',
        [AppLanguage.Spanish]: 'Macedonia, the former Yugoslav Republic of',
        [AppLanguage.German]: 'Mazedonien'
      }
    }
  ],
  [
    'ml',
    {
      code: 'ml',
      labels: {
        [AppLanguage.English]: 'Mali',
        [AppLanguage.Spanish]: 'Mali',
        [AppLanguage.German]: 'Mali'
      }
    }
  ],
  [
    'mm',
    {
      code: 'mm',
      labels: {
        [AppLanguage.English]: 'Myanmar',
        [AppLanguage.Spanish]: 'Myanmar',
        [AppLanguage.German]: 'Birma'
      }
    }
  ],
  [
    'mn',
    {
      code: 'mn',
      labels: {
        [AppLanguage.English]: 'Mongolia',
        [AppLanguage.Spanish]: 'Mongolia',
        [AppLanguage.German]: 'Mongolei'
      }
    }
  ],
  [
    'mo',
    {
      code: 'mo',
      labels: {
        [AppLanguage.English]: 'Macao',
        [AppLanguage.Spanish]: 'Macao',
        [AppLanguage.German]: 'Macao'
      }
    }
  ],
  [
    'mp',
    {
      code: 'mp',
      labels: {
        [AppLanguage.English]: 'Northern Mariana Islands',
        [AppLanguage.Spanish]: 'Northern Mariana Islands',
        [AppLanguage.German]: 'Marianen'
      }
    }
  ],
  [
    'mq',
    {
      code: 'mq',
      labels: {
        [AppLanguage.English]: 'Martinique',
        [AppLanguage.Spanish]: 'Martinique',
        [AppLanguage.German]: 'Martinique'
      }
    }
  ],
  [
    'mr',
    {
      code: 'mr',
      labels: {
        [AppLanguage.English]: 'Mauritania',
        [AppLanguage.Spanish]: 'Mauritania',
        [AppLanguage.German]: 'Mauretanien'
      }
    }
  ],
  [
    'ms',
    {
      code: 'ms',
      labels: {
        [AppLanguage.English]: 'Montserrat',
        [AppLanguage.Spanish]: 'Montserrat',
        [AppLanguage.German]: 'Montserrat'
      }
    }
  ],
  [
    'mt',
    {
      code: 'mt',
      labels: {
        [AppLanguage.English]: 'Malta',
        [AppLanguage.Spanish]: 'Malta',
        [AppLanguage.German]: 'Malta'
      }
    }
  ],
  [
    'mu',
    {
      code: 'mu',
      labels: {
        [AppLanguage.English]: 'Mauritius',
        [AppLanguage.Spanish]: 'Mauritius',
        [AppLanguage.German]: 'Mauritius'
      }
    }
  ],
  [
    'mv',
    {
      code: 'mv',
      labels: {
        [AppLanguage.English]: 'Maldives',
        [AppLanguage.Spanish]: 'Maldives',
        [AppLanguage.German]: 'Malediven'
      }
    }
  ],
  [
    'mw',
    {
      code: 'mw',
      labels: {
        [AppLanguage.English]: 'Malawi',
        [AppLanguage.Spanish]: 'Malawi',
        [AppLanguage.German]: 'Malawi'
      }
    }
  ],
  [
    'mx',
    {
      code: 'mx',
      labels: {
        [AppLanguage.English]: 'Mexico',
        [AppLanguage.Spanish]: 'Mexico',
        [AppLanguage.German]: 'Mexiko'
      }
    }
  ],
  [
    'my',
    {
      code: 'my',
      labels: {
        [AppLanguage.English]: 'Malaysia',
        [AppLanguage.Spanish]: 'Malaysia',
        [AppLanguage.German]: 'Malaysia'
      }
    }
  ],
  [
    'mz',
    {
      code: 'mz',
      labels: {
        [AppLanguage.English]: 'Mozambique',
        [AppLanguage.Spanish]: 'Mozambique',
        [AppLanguage.German]: 'Mocambique'
      }
    }
  ],
  [
    'nc',
    {
      code: 'nc',
      labels: {
        [AppLanguage.English]: 'New Caledonia',
        [AppLanguage.Spanish]: 'New Caledonia',
        [AppLanguage.German]: 'Neukaledonien'
      }
    }
  ],
  [
    'ne',
    {
      code: 'ne',
      labels: {
        [AppLanguage.English]: 'Niger',
        [AppLanguage.Spanish]: 'Niger',
        [AppLanguage.German]: 'Niger'
      }
    }
  ],
  [
    'nf',
    {
      code: 'nf',
      labels: {
        [AppLanguage.English]: 'Norfolk Island',
        [AppLanguage.Spanish]: 'Norfolk Island',
        [AppLanguage.German]: 'Norfolk Inseln'
      }
    }
  ],
  [
    'ng',
    {
      code: 'ng',
      labels: {
        [AppLanguage.English]: 'Nigeria',
        [AppLanguage.Spanish]: 'Nigeria',
        [AppLanguage.German]: 'Nigeria'
      }
    }
  ],
  [
    'ni',
    {
      code: 'ni',
      labels: {
        [AppLanguage.English]: 'Nicaragua',
        [AppLanguage.Spanish]: 'Nicaragua',
        [AppLanguage.German]: 'Nicaragua'
      }
    }
  ],
  [
    'nl',
    {
      code: 'nl',
      labels: {
        [AppLanguage.English]: 'NL',
        [AppLanguage.Spanish]: 'NL',
        [AppLanguage.German]: 'NL'
      }
    }
  ],
  [
    'no',
    {
      code: 'no',
      labels: {
        [AppLanguage.English]: 'Norway',
        [AppLanguage.Spanish]: 'Norway',
        [AppLanguage.German]: 'Norwegen'
      }
    }
  ],
  [
    'np',
    {
      code: 'np',
      labels: {
        [AppLanguage.English]: 'Nepal',
        [AppLanguage.Spanish]: 'Nepal',
        [AppLanguage.German]: 'Nepal'
      }
    }
  ],
  [
    'nr',
    {
      code: 'nr',
      labels: {
        [AppLanguage.English]: 'Nauru',
        [AppLanguage.Spanish]: 'Nauru',
        [AppLanguage.German]: 'Nauru'
      }
    }
  ],
  [
    'nu',
    {
      code: 'nu',
      labels: {
        [AppLanguage.English]: 'Niue',
        [AppLanguage.Spanish]: 'Niue',
        [AppLanguage.German]: 'Niue'
      }
    }
  ],
  [
    'nz',
    {
      code: 'nz',
      labels: {
        [AppLanguage.English]: 'New Zealand',
        [AppLanguage.Spanish]: 'New Zealand',
        [AppLanguage.German]: 'Neuseeland'
      }
    }
  ],
  [
    'om',
    {
      code: 'om',
      labels: {
        [AppLanguage.English]: 'Oman',
        [AppLanguage.Spanish]: 'Oman',
        [AppLanguage.German]: 'Oman'
      }
    }
  ],
  [
    'pa',
    {
      code: 'pa',
      labels: {
        [AppLanguage.English]: 'Panama',
        [AppLanguage.Spanish]: 'Panama',
        [AppLanguage.German]: 'Panama'
      }
    }
  ],
  [
    'pe',
    {
      code: 'pe',
      labels: {
        [AppLanguage.English]: 'Peru',
        [AppLanguage.Spanish]: 'Peru',
        [AppLanguage.German]: 'Peru'
      }
    }
  ],
  [
    'pf',
    {
      code: 'pf',
      labels: {
        [AppLanguage.English]: 'French Polynesia',
        [AppLanguage.Spanish]: 'French Polynesia',
        [AppLanguage.German]: 'Französisch Polynesien'
      }
    }
  ],
  [
    'pg',
    {
      code: 'pg',
      labels: {
        [AppLanguage.English]: 'Papua New Guinea',
        [AppLanguage.Spanish]: 'Papua New Guinea',
        [AppLanguage.German]: 'Papua Neuguinea'
      }
    }
  ],
  [
    'ph',
    {
      code: 'ph',
      labels: {
        [AppLanguage.English]: 'Philippines',
        [AppLanguage.Spanish]: 'Philippines',
        [AppLanguage.German]: 'Philippinen'
      }
    }
  ],
  [
    'pk',
    {
      code: 'pk',
      labels: {
        [AppLanguage.English]: 'Pakistan',
        [AppLanguage.Spanish]: 'Pakistan',
        [AppLanguage.German]: 'Pakistan'
      }
    }
  ],
  [
    'pl',
    {
      code: 'pl',
      labels: {
        [AppLanguage.English]: 'PL',
        [AppLanguage.Spanish]: 'PL',
        [AppLanguage.German]: 'PL'
      }
    }
  ],
  [
    'pm',
    {
      code: 'pm',
      labels: {
        [AppLanguage.English]: 'Saint Pierre and Miquelon',
        [AppLanguage.Spanish]: 'Saint Pierre and Miquelon',
        [AppLanguage.German]: 'St. Pierre und Miquelon'
      }
    }
  ],
  [
    'pn',
    {
      code: 'pn',
      labels: {
        [AppLanguage.English]: 'Pitcairn',
        [AppLanguage.Spanish]: 'Pitcairn',
        [AppLanguage.German]: 'Pitcairn'
      }
    }
  ],
  [
    'pr',
    {
      code: 'pr',
      labels: {
        [AppLanguage.English]: 'Puerto Rico',
        [AppLanguage.Spanish]: 'Puerto Rico',
        [AppLanguage.German]: 'Puerto Rico'
      }
    }
  ],
  [
    'ps',
    {
      code: 'ps',
      labels: {
        [AppLanguage.English]: 'Palestinian Territory, Occupied',
        [AppLanguage.Spanish]: 'Palestinian Territory, Occupied',
        [AppLanguage.German]: 'Palästina'
      }
    }
  ],
  [
    'pt',
    {
      code: 'pt',
      labels: {
        [AppLanguage.English]: 'Portugal',
        [AppLanguage.Spanish]: 'Portugal',
        [AppLanguage.German]: 'Portugal'
      }
    }
  ],
  [
    'pw',
    {
      code: 'pw',
      labels: {
        [AppLanguage.English]: 'Palau',
        [AppLanguage.Spanish]: 'Palau',
        [AppLanguage.German]: 'Palau'
      }
    }
  ],
  [
    'py',
    {
      code: 'py',
      labels: {
        [AppLanguage.English]: 'Paraguay',
        [AppLanguage.Spanish]: 'Paraguay',
        [AppLanguage.German]: 'Paraguay'
      }
    }
  ],
  [
    'qa',
    {
      code: 'qa',
      labels: {
        [AppLanguage.English]: 'Qatar',
        [AppLanguage.Spanish]: 'Qatar',
        [AppLanguage.German]: 'Qatar'
      }
    }
  ],
  [
    're',
    {
      code: 're',
      labels: {
        [AppLanguage.English]: 'Réunion',
        [AppLanguage.Spanish]: 'Réunion',
        [AppLanguage.German]: 'Reunion'
      }
    }
  ],
  [
    'ro',
    {
      code: 'ro',
      labels: {
        [AppLanguage.English]: 'Romania',
        [AppLanguage.Spanish]: 'Romania',
        [AppLanguage.German]: 'Rumänien'
      }
    }
  ],
  [
    'ru',
    {
      code: 'ru',
      labels: {
        [AppLanguage.English]: 'Russian Federation',
        [AppLanguage.Spanish]: 'Russian Federation',
        [AppLanguage.German]: 'Russland'
      }
    }
  ],
  [
    'rw',
    {
      code: 'rw',
      labels: {
        [AppLanguage.English]: 'Rwanda',
        [AppLanguage.Spanish]: 'Rwanda',
        [AppLanguage.German]: 'Ruanda'
      }
    }
  ],
  [
    'sa',
    {
      code: 'sa',
      labels: {
        [AppLanguage.English]: 'Saudi Arabia',
        [AppLanguage.Spanish]: 'Saudi Arabia',
        [AppLanguage.German]: 'Saudi Arabien'
      }
    }
  ],
  [
    'sb',
    {
      code: 'sb',
      labels: {
        [AppLanguage.English]: 'Solomon Islands',
        [AppLanguage.Spanish]: 'Solomon Islands',
        [AppLanguage.German]: 'Solomon Inseln'
      }
    }
  ],
  [
    'sc',
    {
      code: 'sc',
      labels: {
        [AppLanguage.English]: 'Seychelles',
        [AppLanguage.Spanish]: 'Seychelles',
        [AppLanguage.German]: 'Seychellen'
      }
    }
  ],
  [
    'sd',
    {
      code: 'sd',
      labels: {
        [AppLanguage.English]: 'Sudan',
        [AppLanguage.Spanish]: 'Sudan',
        [AppLanguage.German]: 'Sudan'
      }
    }
  ],
  [
    'se',
    {
      code: 'se',
      labels: {
        [AppLanguage.English]: 'Sweden',
        [AppLanguage.Spanish]: 'Sweden',
        [AppLanguage.German]: 'Schweden'
      }
    }
  ],
  [
    'sg',
    {
      code: 'sg',
      labels: {
        [AppLanguage.English]: 'Singapore',
        [AppLanguage.Spanish]: 'Singapore',
        [AppLanguage.German]: 'Singapur'
      }
    }
  ],
  [
    'sh',
    {
      code: 'sh',
      labels: {
        [AppLanguage.English]: 'Saint Helena, Ascension and Tristan da Cunha',
        [AppLanguage.Spanish]: 'Saint Helena, Ascension and Tristan da Cunha',
        [AppLanguage.German]: 'St. Helena'
      }
    }
  ],
  [
    'si',
    {
      code: 'si',
      labels: {
        [AppLanguage.English]: 'Slovenia',
        [AppLanguage.Spanish]: 'Slovenia',
        [AppLanguage.German]: 'Slowenien'
      }
    }
  ],
  [
    'sj',
    {
      code: 'sj',
      labels: {
        [AppLanguage.English]: 'Svalbard and Jan Mayen',
        [AppLanguage.Spanish]: 'Svalbard and Jan Mayen',
        [AppLanguage.German]: 'Svalbard und Jan Mayen Islands'
      }
    }
  ],
  [
    'sk',
    {
      code: 'sk',
      labels: {
        [AppLanguage.English]: 'Slovakia',
        [AppLanguage.Spanish]: 'Slovakia',
        [AppLanguage.German]: 'Slowakei -slowakische Republik-'
      }
    }
  ],
  [
    'sl',
    {
      code: 'sl',
      labels: {
        [AppLanguage.English]: 'Sierra Leone',
        [AppLanguage.Spanish]: 'Sierra Leone',
        [AppLanguage.German]: 'Sierra Leone'
      }
    }
  ],
  [
    'sm',
    {
      code: 'sm',
      labels: {
        [AppLanguage.English]: 'San Marino',
        [AppLanguage.Spanish]: 'San Marino',
        [AppLanguage.German]: 'San Marino'
      }
    }
  ],
  [
    'sn',
    {
      code: 'sn',
      labels: {
        [AppLanguage.English]: 'Senegal',
        [AppLanguage.Spanish]: 'Senegal',
        [AppLanguage.German]: 'Senegal'
      }
    }
  ],
  [
    'so',
    {
      code: 'so',
      labels: {
        [AppLanguage.English]: 'Somalia',
        [AppLanguage.Spanish]: 'Somalia',
        [AppLanguage.German]: 'Somalia'
      }
    }
  ],
  [
    'sr',
    {
      code: 'sr',
      labels: {
        [AppLanguage.English]: 'Suriname',
        [AppLanguage.Spanish]: 'Suriname',
        [AppLanguage.German]: 'Surinam'
      }
    }
  ],
  [
    'st',
    {
      code: 'st',
      labels: {
        [AppLanguage.English]: 'Sao Tome and Principe',
        [AppLanguage.Spanish]: 'Sao Tome and Principe',
        [AppLanguage.German]: 'Sao Tome'
      }
    }
  ],
  [
    'sv',
    {
      code: 'sv',
      labels: {
        [AppLanguage.English]: 'El Salvador',
        [AppLanguage.Spanish]: 'El Salvador',
        [AppLanguage.German]: 'El Salvador'
      }
    }
  ],
  [
    'sy',
    {
      code: 'sy',
      labels: {
        [AppLanguage.English]: 'Syrian Arab Republic',
        [AppLanguage.Spanish]: 'Syrian Arab Republic',
        [AppLanguage.German]: 'Syrien'
      }
    }
  ],
  [
    'sz',
    {
      code: 'sz',
      labels: {
        [AppLanguage.English]: 'Swaziland',
        [AppLanguage.Spanish]: 'Swaziland',
        [AppLanguage.German]: 'Swasiland'
      }
    }
  ],
  [
    'tc',
    {
      code: 'tc',
      labels: {
        [AppLanguage.English]: 'Turks and Caicos Islands',
        [AppLanguage.Spanish]: 'Turks and Caicos Islands',
        [AppLanguage.German]: 'Turks und Kaikos Inseln'
      }
    }
  ],
  [
    'td',
    {
      code: 'td',
      labels: {
        [AppLanguage.English]: 'Chad',
        [AppLanguage.Spanish]: 'Chad',
        [AppLanguage.German]: 'Tschad'
      }
    }
  ],
  [
    'tf',
    {
      code: 'tf',
      labels: {
        [AppLanguage.English]: 'French Southern Territories',
        [AppLanguage.Spanish]: 'French Southern Territories',
        [AppLanguage.German]: 'Französisches Süd-Territorium'
      }
    }
  ],
  [
    'tg',
    {
      code: 'tg',
      labels: {
        [AppLanguage.English]: 'Togo',
        [AppLanguage.Spanish]: 'Togo',
        [AppLanguage.German]: 'Togo'
      }
    }
  ],
  [
    'th',
    {
      code: 'th',
      labels: {
        [AppLanguage.English]: 'Thailand',
        [AppLanguage.Spanish]: 'Thailand',
        [AppLanguage.German]: 'Thailand'
      }
    }
  ],
  [
    'tj',
    {
      code: 'tj',
      labels: {
        [AppLanguage.English]: 'Tajikistan',
        [AppLanguage.Spanish]: 'Tajikistan',
        [AppLanguage.German]: 'Tadschikistan'
      }
    }
  ],
  [
    'tk',
    {
      code: 'tk',
      labels: {
        [AppLanguage.English]: 'Tokelau',
        [AppLanguage.Spanish]: 'Tokelau',
        [AppLanguage.German]: 'Tokelau'
      }
    }
  ],
  [
    'tm',
    {
      code: 'tm',
      labels: {
        [AppLanguage.English]: 'Turkmenistan',
        [AppLanguage.Spanish]: 'Turkmenistan',
        [AppLanguage.German]: 'Turkmenistan'
      }
    }
  ],
  [
    'tn',
    {
      code: 'tn',
      labels: {
        [AppLanguage.English]: 'Tunisia',
        [AppLanguage.Spanish]: 'Tunisia',
        [AppLanguage.German]: 'Tunesien'
      }
    }
  ],
  [
    'to',
    {
      code: 'to',
      labels: {
        [AppLanguage.English]: 'Tonga',
        [AppLanguage.Spanish]: 'Tonga',
        [AppLanguage.German]: 'Tonga'
      }
    }
  ],
  [
    'tr',
    {
      code: 'tr',
      labels: {
        [AppLanguage.English]: 'Turkey',
        [AppLanguage.Spanish]: 'Turkey',
        [AppLanguage.German]: 'Türkei'
      }
    }
  ],
  [
    'tt',
    {
      code: 'tt',
      labels: {
        [AppLanguage.English]: 'Trinidad and Tobago',
        [AppLanguage.Spanish]: 'Trinidad and Tobago',
        [AppLanguage.German]: 'Trinidad Tobago'
      }
    }
  ],
  [
    'tv',
    {
      code: 'tv',
      labels: {
        [AppLanguage.English]: 'Tuvalu',
        [AppLanguage.Spanish]: 'Tuvalu',
        [AppLanguage.German]: 'Tuvalu'
      }
    }
  ],
  [
    'tw',
    {
      code: 'tw',
      labels: {
        [AppLanguage.English]: 'Taiwan, Province of China',
        [AppLanguage.Spanish]: 'Taiwan, Province of China',
        [AppLanguage.German]: 'Taiwan'
      }
    }
  ],
  [
    'tz',
    {
      code: 'tz',
      labels: {
        [AppLanguage.English]: 'Tanzania, United Republic of',
        [AppLanguage.Spanish]: 'Tanzania, United Republic of',
        [AppLanguage.German]: 'Tansania'
      }
    }
  ],
  [
    'ua',
    {
      code: 'ua',
      labels: {
        [AppLanguage.English]: 'Ukraine',
        [AppLanguage.Spanish]: 'Ukraine',
        [AppLanguage.German]: 'Ukraine'
      }
    }
  ],
  [
    'ug',
    {
      code: 'ug',
      labels: {
        [AppLanguage.English]: 'Uganda',
        [AppLanguage.Spanish]: 'Uganda',
        [AppLanguage.German]: 'Uganda'
      }
    }
  ],
  [
    'us',
    {
      code: 'us',
      labels: {
        [AppLanguage.English]: 'US',
        [AppLanguage.Spanish]: 'US',
        [AppLanguage.German]: 'US'
      }
    }
  ],
  [
    'uy',
    {
      code: 'uy',
      labels: {
        [AppLanguage.English]: 'Uruguay',
        [AppLanguage.Spanish]: 'Uruguay',
        [AppLanguage.German]: 'Uruguay'
      }
    }
  ],
  [
    'uz',
    {
      code: 'uz',
      labels: {
        [AppLanguage.English]: 'Uzbekistan',
        [AppLanguage.Spanish]: 'Uzbekistan',
        [AppLanguage.German]: 'Usbekistan'
      }
    }
  ],
  [
    'va',
    {
      code: 'va',
      labels: {
        [AppLanguage.English]: 'Holy See (Vatican City State)',
        [AppLanguage.Spanish]: 'Holy See (Vatican City State)',
        [AppLanguage.German]: 'Vatikan'
      }
    }
  ],
  [
    'vc',
    {
      code: 'vc',
      labels: {
        [AppLanguage.English]: 'Saint Vincent and the Grenadines',
        [AppLanguage.Spanish]: 'Saint Vincent and the Grenadines',
        [AppLanguage.German]: 'St. Vincent'
      }
    }
  ],
  [
    've',
    {
      code: 've',
      labels: {
        [AppLanguage.English]: 'Venezuela, Bolivarian Republic of',
        [AppLanguage.Spanish]: 'Venezuela, Bolivarian Republic of',
        [AppLanguage.German]: 'Venezuela'
      }
    }
  ],
  [
    'vg',
    {
      code: 'vg',
      labels: {
        [AppLanguage.English]: 'Virgin Islands, British',
        [AppLanguage.Spanish]: 'Virgin Islands, British',
        [AppLanguage.German]: 'Virgin Island (Brit.)'
      }
    }
  ],
  [
    'vi',
    {
      code: 'vi',
      labels: {
        [AppLanguage.English]: 'Virgin Islands, U.S.',
        [AppLanguage.Spanish]: 'Virgin Islands, U.S.',
        [AppLanguage.German]: 'Virgin Island (USA)'
      }
    }
  ],
  [
    'vn',
    {
      code: 'vn',
      labels: {
        [AppLanguage.English]: 'Viet Nam',
        [AppLanguage.Spanish]: 'Viet Nam',
        [AppLanguage.German]: 'Vietnam'
      }
    }
  ],
  [
    'vu',
    {
      code: 'vu',
      labels: {
        [AppLanguage.English]: 'Vanuatu',
        [AppLanguage.Spanish]: 'Vanuatu',
        [AppLanguage.German]: 'Vanuatu'
      }
    }
  ],
  [
    'wf',
    {
      code: 'wf',
      labels: {
        [AppLanguage.English]: 'Wallis and Futuna',
        [AppLanguage.Spanish]: 'Wallis and Futuna',
        [AppLanguage.German]: 'Wallis et Futuna'
      }
    }
  ],
  [
    'ws',
    {
      code: 'ws',
      labels: {
        [AppLanguage.English]: 'Samoa',
        [AppLanguage.Spanish]: 'Samoa',
        [AppLanguage.German]: 'Samoa'
      }
    }
  ],
  [
    'ye',
    {
      code: 'ye',
      labels: {
        [AppLanguage.English]: 'Yemen',
        [AppLanguage.Spanish]: 'Yemen',
        [AppLanguage.German]: 'Jemen'
      }
    }
  ],
  [
    'yt',
    {
      code: 'yt',
      labels: {
        [AppLanguage.English]: 'Mayotte',
        [AppLanguage.Spanish]: 'Mayotte',
        [AppLanguage.German]: 'Mayotte'
      }
    }
  ],
  [
    'za',
    {
      code: 'za',
      labels: {
        [AppLanguage.English]: 'South Africa',
        [AppLanguage.Spanish]: 'South Africa',
        [AppLanguage.German]: 'Südafrika'
      }
    }
  ],
  [
    'zm',
    {
      code: 'zm',
      labels: {
        [AppLanguage.English]: 'Zambia',
        [AppLanguage.Spanish]: 'Zambia',
        [AppLanguage.German]: 'Sambia'
      }
    }
  ],
  [
    'zw',
    {
      code: 'zw',
      labels: {
        [AppLanguage.English]: 'Zimbabwe',
        [AppLanguage.Spanish]: 'Zimbabwe',
        [AppLanguage.German]: 'Zimbabwe'
      }
    }
  ]
]);
