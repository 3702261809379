import { Alert, AlertTitle, Button } from '@mui/material';
import FlexContainer from 'components/FlexContainer';
import DeleteIconButton from 'components/iconButtons/DeleteIconButton';
import { useModal } from 'components/modals';
import { SessionStorageKey } from 'config/sessionStorageKey';
import { useGetTimeUntilTrialExpiration } from 'features/pricing/hook/useGetTimeUntilTrialExpiration';
import { useIsOnTrial } from 'features/pricing/hook/useIsOnTrial';
import { usePostHog } from 'posthog-js/react';
import { FormattedMessage } from 'react-intl';
import gtmIds from 'services/tracking/GTMIds';
import { withGtmInteraction } from 'services/tracking/withGtmInteraction';
import styled from 'styled-components';
import { useSessionStorage } from 'utils/hooks/useSessionStorage';

export const TrialExpiryBanner = () => {
  const { showModal } = useModal();
  const { daysLeft, hoursLeft } = useGetTimeUntilTrialExpiration();
  const postHog = usePostHog();
  const [shouldBeShown, setShouldBeShown] = useSessionStorage(
    SessionStorageKey.TrialExpiryBannerDismissed,
    true
  );

  const isOnTrial = useIsOnTrial();

  const handleDismissBanner = () => {
    sessionStorage.setItem(SessionStorageKey.TrialExpiryBannerDismissed, 'true');
    setShouldBeShown(false);
  };

  const handleCancellationButtonClick = () => {
    postHog?.capture('Cancel subscription click');

    showModal('CANCEL_SUBSCRIPTION', { size: 900 });
  };

  if (!(isOnTrial && shouldBeShown)) {
    return null;
  }

  return (
    <BannersBox>
      <Alert
        severity="info"
        variant="filled"
        action={
          <FlexContainer direction="row" gap="three">
            <Button
              {...withGtmInteraction(gtmIds.pricing.cancelSubscription)}
              color="secondary"
              onClick={handleCancellationButtonClick}
              size="small"
              variant="contained"
            >
              <FormattedMessage id="profile.subscription_cancel" />
            </Button>
            <DeleteIconButton onClick={handleDismissBanner} />
          </FlexContainer>
        }
      >
        <AlertTitle>
          <FormattedMessage
            id="trial_expiry.banner.title"
            values={{ days: daysLeft, hours: hoursLeft }}
          />
        </AlertTitle>
        <FormattedMessage id="trial_expiry.banner.description" />
      </Alert>
    </BannersBox>
  );
};

const BannersBox = styled.div`
  margin-bottom: ${({ theme }) => theme.spacings.three};
`;
