import Toast from 'components/toasts/Toast';
import { getErrorCodeFromAxiosError } from 'features/teamInvitation/getErrorCodeFromAxiosError';
import { CustomerData } from 'services/api/customer/types';
import { BaseSuccessDto } from 'services/backofficeIntegration/http/BaseSuccessDto';
import { backofficeClient } from 'services/backofficeIntegration/http/client/createBackofficeClient';
import { mutationEndpoint } from 'services/backofficeIntegration/http/endpointDefinition';

type SuccessDto = BaseSuccessDto<CustomerData>;

export const httpMagicLinkAuth = mutationEndpoint({
  callEndpoint: (token: string) => {
    return backofficeClient
      .post<SuccessDto>('/customer/magic-link-auth', { token })
      .then(res => {
        return res.data.data;
      })
      .catch(err => {
        const errorCode = getErrorCodeFromAxiosError(err);
        if (errorCode) {
          Toast.error(`customer.login.error.${errorCode}`);
        }
      });
  }
});
