import { trackingWrapper } from 'features/tracking/wrapper/TrackingWrapper';
import { GAEvents } from 'services/tracking/GAEvents';

export const trackEditorFormatAction = (action: string) => {
  GAEvents.topBarActionExecuted({
    action
  });
  trackingWrapper.track('aiWriterEditorToolbarIconClicked', {
    format: action
  });
};
