import { Button } from '@mui/material';
import { ColoredTypography } from 'components/ColoredTypography';
import FormattedMessage from 'features/i18n/FormattedMessage';
import { ReactElement } from 'react';

type Props = {
  messageId: string;
  value: number;

  onClick: () => void;
};

export const StatisticsButton = ({ messageId, value, onClick }: Props): ReactElement => {
  return (
    <Button onClick={onClick} color="inherit">
      <ColoredTypography variant="caption">
        <FormattedMessage id={messageId} values={{ count: value }} />
      </ColoredTypography>
    </Button>
  );
};
