/* eslint-disable react/prop-types */
import cx from 'classnames';
import { motion, MotionProps } from 'framer-motion';
import React, { ComponentType, forwardRef } from 'react';
import styled, { css } from 'styled-components';

import EmptyButton from './EmptyButton';

type Props = {
  name: string;
  active?: boolean;
  vAlign?: boolean;
  size?: number;
  buttonClassName?: string;
  forceInteraction?: boolean;
} & React.ComponentPropsWithoutRef<'button'>;

const IconButton = styled(EmptyButton)<{ active?: boolean; vAlign?: boolean }>`
  color: ${({ active }) => (active ? '#495057' : '#9aa0ac')};

  ${({ vAlign }) =>
    vAlign &&
    css`
      i {
        vertical-align: -0.125rem;
      }
    `};

  &:hover {
    color: #495057;
  }
`;

const Icon = forwardRef<HTMLButtonElement, Props>(
  (
    { name, className, buttonClassName, active, onClick, size, forceInteraction, ...props },
    ref
  ) => {
    const classes = cx('fe', `fe-${name}`, className);

    return onClick || forceInteraction ? (
      <IconButton
        type="button"
        active={active}
        className={buttonClassName}
        onClick={onClick || undefined}
        ref={ref}
        {...props}
      >
        <i
          className={classes}
          css={
            size
              ? css`
                  font-size: ${size}rem;
                `
              : undefined
          }
        />
      </IconButton>
    ) : (
      <i className={classes} ref={ref} {...props} />
    );
  }
);

export const MotionIcon = motion(Icon) as ComponentType<MotionProps & Props>;

export default Icon;
