import { useGetOnboardingChecklistProgress } from 'features/onboardingJourney/checklist/useOnboardingChecklist';
import useGtmDataLayerAttributes from 'features/services/hooks/useGtmDataLayerAttributes';
import { getTrackingClient } from 'features/tracking/segment/getTrackingClient';
import { usePostHog } from 'posthog-js/react';
import { useCallback } from 'react';
import { GAEvents } from 'services/tracking/GAEvents';
import { create } from 'zustand';

type UserDataTrackingStoreState = {
  hasSendOnce: boolean;
  hasSendWithPurchaseRelevantData: boolean;
};

type UserDataTrackingStoreActions = {
  setHasSendOnce: (hasSendOnce: boolean) => void;
  setHasSendWithPurchaseRelevantData: (hasSendWithPurchaseRelevantData: boolean) => void;
};

export const useUserDataTrackingStore = create<
  UserDataTrackingStoreState & UserDataTrackingStoreActions
>(set => ({
  hasSendOnce: false,
  hasSendWithPurchaseRelevantData: false,

  setHasSendOnce: hasSendOnce => set({ hasSendOnce }),
  setHasSendWithPurchaseRelevantData: hasSendWithPurchaseRelevantData =>
    set({ hasSendWithPurchaseRelevantData })
}));

export default function useSendUserData() {
  const attributes = useGtmDataLayerAttributes();
  const onboardingChecklistProgress = useGetOnboardingChecklistProgress();
  const posthog = usePostHog();

  const setHasSendOnce = useUserDataTrackingStore(state => state.setHasSendOnce);
  const setHasSendWithPurchaseRelevantData = useUserDataTrackingStore(
    state => state.setHasSendWithPurchaseRelevantData
  );

  return useCallback(() => {
    GAEvents.userData(attributes);
    setHasSendOnce(true);

    if (
      attributes.subscriptionStatus &&
      attributes.pricingPlanId &&
      attributes.selectedUseCaseAnswer
    ) {
      setHasSendWithPurchaseRelevantData(true);
    }

    if (attributes.userId) {
      triggerSegmentIdentify(attributes.userId, onboardingChecklistProgress);

      posthog?.identify(attributes.userId.toString(10), attributes);
    }
  }, [
    attributes,
    onboardingChecklistProgress,
    posthog,
    setHasSendOnce,
    setHasSendWithPurchaseRelevantData
  ]);
}

// This fallback is required to distinguish between users who have not logged in yet
// or blocked Google Analytics.
// The value was defined/requested from data science team
const fallbackGoogleAnalyticsClientId = '0';

function triggerSegmentIdentify(userId: number, onboardingChecklistProgress: number) {
  const segmentClient = getTrackingClient();

  segmentClient.identify(userId.toString(10), {
    GAClientID: GAEvents.getClientId() ?? fallbackGoogleAnalyticsClientId,
    onboardingChecklistProgress: `${onboardingChecklistProgress}%`
  });
}
