import { InfiniteData, useInfiniteQuery } from '@tanstack/react-query';
import Toast from 'components/toasts/Toast';
import {
  dropStaleMessages,
  getStaleMessages,
  updateStoredMessages,
  useStoredActiveConversationId
} from 'features/aiWriter/AiWriterSidebar/steps/chat/chatStore';
import { getErrorCodeFromAxiosError } from 'features/teamInvitation/getErrorCodeFromAxiosError';
import { CursorPaginationParams } from 'services/backofficeIntegration/http/cursorPaginationEndpoint';
import {
  httpGetConversationMessages,
  PaginatedMessagesDto
} from 'services/backofficeIntegration/http/endpoints/conversations/httpGetConversationMessages';
import { infiniteQueryKey } from 'utils/reactQuery/infiniteQueryKey';
import { queryClient } from 'utils/ReactQueryClient';

function makeInfiniteQueryKey(props: { conversationId: string }) {
  return infiniteQueryKey(httpGetConversationMessages.makeQueryKey(props));
}

export function extractMessages(data: InfiniteData<PaginatedMessagesDto>) {
  const { pages } = data;
  return pages.flatMap(p => p.items);
}

export function useMessagesQuery() {
  const conversationId = useStoredActiveConversationId();
  const hasConversationId = !!conversationId;

  const query = useInfiniteQuery({
    enabled: hasConversationId,
    queryKey: hasConversationId ? makeInfiniteQueryKey({ conversationId }) : [],
    queryFn: (context: { pageParam?: CursorPaginationParams }) => {
      if (!conversationId) {
        throw new Error('useMessagesQuery called without conversationId');
      }

      const { pageParam = {} } = context;
      return httpGetConversationMessages.callEndpoint({
        conversationId,
        page: pageParam
      });
    },
    getNextPageParam: (lastLoadedPage): CursorPaginationParams | undefined => {
      return lastLoadedPage?.nextPage ?? undefined;
    },
    onSuccess: data => {
      const { pages } = data;
      updateStoredMessages({ messages: extractMessages(data) });
      const staleMessages = getStaleMessages();
      if (staleMessages.length < 1) {
        return;
      }

      /**
       * This one is tricky. I can use `fetchNextPage()` because the function
       * is stable but any fields related to query state (e.g. `hasNextPage`)
       * will be stale (from before the query completed) so they are useless.
       */
      const lastLoadedPage = pages[pages.length - 1];
      const hasMorePages = lastLoadedPage && lastLoadedPage.nextPage;
      if (hasMorePages) {
        query.fetchNextPage();
      } else {
        dropStaleMessages();
      }
    },
    onError: (error: unknown) => {
      const errorCode = getErrorCodeFromAxiosError(error);
      if (errorCode) {
        // this handler can be called multiple times with the same error code
        // by using the toastId with the error code we can prevent duplicate toasts
        Toast.error(`aiWriter.chat.error.${errorCode}`, {}, { toastId: errorCode });
        return;
      }
      Toast.apiError();
    }
  });
  return query;
}

export function invalidateMessagesQuery(props: { conversationId: string }) {
  const { conversationId } = props;
  const queryKey = makeInfiniteQueryKey({ conversationId });
  queryClient.invalidateQueries({ queryKey });
}
