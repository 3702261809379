/**
 * Please be aware that this should be used with caution.
 * We need this to display correct key modifier in shortcuts.
 * More details can be found here:
 *  https://developer.mozilla.org/en-US/docs/Web/API/Navigator/platform#examples
 */

export function isMacOsUser(): boolean {
  return navigator.platform.indexOf('Mac') === 0;
}
