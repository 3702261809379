import { SvgIconComponent } from '@mui/icons-material';
import { Typography } from '@mui/material';
import { variantBackgroundMap } from 'features/modular-workflow/runner/step-list/StepBox';
import { forwardRef, HTMLAttributes } from 'react';
import styled from 'styled-components';
import { resetButton } from 'styles/styledComponents/resets';

type Props = Omit<HTMLAttributes<HTMLButtonElement>, 'children'> & {
  icon: SvgIconComponent;
  name: string;
  onMove?: (dragIndex: number, hoverIndex: number) => void;
  isActive?: boolean;
  isDragging?: boolean;
  hasError?: boolean;
};

export const StepBox = forwardRef<HTMLButtonElement, Props>(
  (
    { icon: Icon, name, isActive = false, isDragging = false, hasError = false, onClick, ...rest },
    ref
  ) => (
    <Root
      ref={ref}
      $isActive={isActive}
      $isDragging={isDragging}
      $hasError={hasError}
      onClick={event => !isActive && onClick?.(event)}
      {...rest}
    >
      <Icon fontSize="small" color="inherit" />
      <TruncatedLabel $isActive={isActive} color="inherit">
        {name}
      </TruncatedLabel>
    </Root>
  )
);

const Root = styled.button<{ $isActive: boolean; $isDragging: boolean; $hasError: boolean }>`
  ${resetButton};

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: ${({ theme }) => theme.spacings.four};
  padding: ${({ theme, $isActive }) =>
    `${theme.spacings.two} ${$isActive ? theme.spacings.four : theme.spacings.three}`};

  border-radius: ${({ theme }) => theme.borderRadius.one};
  box-shadow: 1px 1px 3px ${({ theme }) => theme.shadow.card};
  border: 1px solid ${({ theme, $isActive, $hasError }) => {
    // Add a special border in case it's erroring and active
    // to ensure it's still visible as active step
    if ($isActive && $hasError) {
      return theme.colors.primaryColorSelected;
    }

    return theme.colors.actionFocus;
  }};
  background-color: ${({ theme, $isActive, $hasError }) => {
    if ($hasError) {
      return variantBackgroundMap.error;
    }

    if ($isActive) {
      return variantBackgroundMap.active;
    }

    return theme.mode === 'dark' ? theme.colors.commonBlackMain : theme.colors.commonWhiteMain;
  }};
  color: ${({ theme, $isActive, $hasError }) => {
    if ($hasError) {
      return theme.colors.textPrimary;
    }

    if ($isActive) {
      return theme.colors.primaryColorContrast;
    }

    return 'inherit';
  }};
  cursor: ${({ $isActive }) => ($isActive ? 'default' : 'pointer')};
  white-space: nowrap;

  transition: ${({ theme }) =>
    ['background-color', 'color', 'box-shadow', 'border-color', 'min-width']
      .map(property => `${property} ${theme.transition.default}`)
      .join(', ')};

  &:hover {
    background-color: ${({ theme, $isActive, $hasError, $isDragging }) => {
      if ($isDragging) {
        return theme.colors.secondaryColorMain;
      }

      if ($hasError) {
        return variantBackgroundMap.error;
      }

      if ($isActive) {
        return theme.colors.primaryColorMain;
      }

      return theme.colors.blackHoverOverlay;
    }};
    color: ${({ theme, $isActive, $hasError, $isDragging }) => {
      if ($hasError) {
        return theme.colors.commonBlackMain;
      }

      if ($isDragging || $isActive) {
        return theme.mode === 'dark' ? theme.colors.commonBlackMain : theme.colors.commonWhiteMain;
      }

      return 'inherit';
    }};
`;

const TruncatedLabel = styled(Typography)<{ $isActive: boolean }>`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  max-width: ${({ $isActive }) => ($isActive ? '240px' : '64px')};
`;
