import { ApiModularWorkflowStep } from 'features/modular-workflow/builder/types-api';
import { BuilderModularWorkflow } from 'features/modular-workflow/builder/types-builder';
import { BaseSuccessDto } from 'services/backofficeIntegration/http/BaseSuccessDto';
import { backofficeClient } from 'services/backofficeIntegration/http/client/createBackofficeClient';
import { mutationEndpoint } from 'services/backofficeIntegration/http/endpointDefinition';

export type ResponseDto = BaseSuccessDto<ApiModularWorkflowStep[]>;

export const httpUpdateModularWorkflow = mutationEndpoint({
  callEndpoint: (workflow: BuilderModularWorkflow) => {
    // Note: The workflow thumbnail is a base64 data URI if it has been changed.
    //       It's a URL if it hasn't been changed.
    //       The backend always expects a base64 data URI.
    //       To prevent fetching an unchanged thumbnail, we'll omit it during save,
    //       and the backend will not update it if it's missing in the request.
    const payload = { ...workflow };
    if (payload.thumbnail?.startsWith('http')) {
      delete payload.thumbnail;
    }

    return backofficeClient
      .patch<ResponseDto>(`/workflows/${payload.id}`, payload)
      .then(response => response.data.data);
  }
});
