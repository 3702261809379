import { List, ListItem, ListItemText, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import standingPersonImg from 'assets/drawings/standing_person.svg';
import CopyToClipboardButton from 'components/CopyToClipboardButton';
import ScoreChip from 'components/ScoreIndicators/ScoreChip';
import CenteredContainer from 'components/styled/CenteredContainer';
import { ScoredSubjectLine } from 'features/subjectLines/store/types';
import { useFormikContext } from 'formik';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { formatToPercentageValue } from 'utils/wordUtils';


const ApproveSubjectLinesContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-end;
  gap: 1.5rem;
  margin: auto;
`;

const SubjectLinesContainer = styled.div`
  padding: 1rem 0;
  width: 100%;
`;

const SubjectLineListItem = styled.li`
  padding: 0.25rem 0;
`;

const SubjectLineWithAction = styled.div`
  display: flex;
`;

const useStyles = makeStyles({
  root: {
    backgroundColor: '#FAFAFA',
    borderRadius: '4px',
    textAlign: 'center'
  }
});

const ApproveSubjectLines = () => {
  const { values } = useFormikContext<{ subjectLines: ScoredSubjectLine[] }>();
  const classes = useStyles();

  return (
    <ApproveSubjectLinesContainer>
      <img src={standingPersonImg} alt="standing-person" />
      <CenteredContainer>
        <Typography variant="h5">
          <FormattedMessage id="subject_lines.approve_subject_lines.are_you_sure" />
        </Typography>
        <Typography variant="subtitle1">
          <FormattedMessage id="subject_lines.approve_subject_lines.copy_paste" />
        </Typography>
        <SubjectLinesContainer>
          <List>
            {values.subjectLines.map(({ subjectLine, predictedOpenRate }) => (
              <SubjectLineListItem key={subjectLine}>
                <ScoreChip
                  value={predictedOpenRate}
                  label={formatToPercentageValue(predictedOpenRate)}
                >
                  <Typography variant="body2">
                    <FormattedMessage id="subject_lines.socials.linkedin" />
                  </Typography>
                </ScoreChip>
                <SubjectLineWithAction>
                  <ListItem classes={classes} component="div">
                    <ListItemText>{subjectLine}</ListItemText>
                  </ListItem>
                  <CopyToClipboardButton color="primary" text={subjectLine} />
                </SubjectLineWithAction>
              </SubjectLineListItem>
            ))}
          </List>
        </SubjectLinesContainer>
      </CenteredContainer>
    </ApproveSubjectLinesContainer>
  );
};

export default ApproveSubjectLines;
