import cx from 'classnames';
import React from 'react';

import FormGroup from './FormGroup';

type Props = {
  className?: string;
  error?: string;
  value?: string | number;
  children?: string;
  label?: string;
} & React.ComponentPropsWithoutRef<'textarea'>;

const Textarea = ({ className, error, value, children, label, ...props }: Props) => {
  const contents = (
    <>
      <textarea className={cx('form-control', className)} value={value || children} {...props} />
      {error && <span className="invalid-feedback">{error}</span>}
    </>
  );

  return label ? <FormGroup label={label}>{contents}</FormGroup> : contents;
};

export default Textarea;
