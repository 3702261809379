import LocalStorageKey from 'config/localStorageKey';
import { trackingWrapper } from 'features/tracking/wrapper/TrackingWrapper';
import {
  apiKeyHeader,
  apiTokenHeader
} from 'services/backofficeIntegration/http/backofficeHeaders';
import { makeBackofficeUrl } from 'services/backofficeIntegration/http/backofficeUrls';
import { ConversationMessageDto } from 'services/backofficeIntegration/http/dtos/ConversationMessageDto';
import { processEventStream } from 'services/backofficeIntegration/http/processEventStream';
import { GAEvents } from 'services/tracking/GAEvents';
import { safeEnum } from 'utils/safeEnum';
import { assert } from 'utils/typescript/assert';

export type PartialMessageDto = Pick<ConversationMessageDto, 'id' | 'source' | 'created_at'>;
const EventType = safeEnum.make({
  start: 'start',
  update: 'update',
  end: 'end',
  error: 'error'
} as const);
type EventType = safeEnum.infer<typeof EventType>;
type NewMessageDto = ConversationMessageDto;
type Chunk = string;
type EventDto = StartEvent | UpdateEvent | EndEvent | ErrorEvent;
type StartEvent = { type: typeof EventType.start; message: PartialMessageDto };
type UpdateEvent = { type: typeof EventType.update; delta: { text: string } };
type EndEvent = { type: typeof EventType.end };
type ErrorEvent = { type: typeof EventType.error; message: string };
export type MessageInput = { projectId: string; conversationId: string; message: NewMessageDto };

class StreamError extends Error {
  event: ErrorEvent;
  constructor(event: ErrorEvent) {
    super('Stream error');
    this.event = event;
  }
}

function sendMessage(props: MessageInput) {
  const { projectId, conversationId, message } = props;
  const apiToken = localStorage.getItem(LocalStorageKey.APIToken);
  const isApiCustomer = localStorage.getItem(LocalStorageKey.IsApiCustomer) === '1';
  assert(!!apiToken, 'Missing api token');

  trackingWrapper.track('generateTexts', {
    url: window.location.pathname,
    location: 'ai_writer_chat',
    input: props
  });

  return fetch(
    makeBackofficeUrl(
      `/projects/ai-writer/${projectId}/conversations/${conversationId}/stream-messages`
    ),
    {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'text/event-stream',
        ...apiTokenHeader(apiToken),
        ...apiKeyHeader({ isApiCustomer })
      },
      method: 'PUT',
      body: JSON.stringify(message)
    }
  );
}

async function handleStream(props: {
  response: Response;
  onUpdate: (chunk: Chunk) => void;
  onStart: (message: PartialMessageDto) => void;
  onEnd: () => void;
}) {
  const { response, onUpdate, onStart, onEnd } = props;

  return processEventStream({
    response,
    onEvent: context => {
      const { event, cancel } = context;

      if (event.type === 'event') {
        const dataString = event.data;
        const data = JSON.parse(dataString) as EventDto;

        switch (data.type) {
          case EventType.start: {
            const message = data.message;
            onStart(message);
            return;
          }
          case EventType.update: {
            const text = data.delta.text;
            onUpdate(text);
            return;
          }
          case EventType.end: {
            onEnd();
            cancel();
            return;
          }
          case EventType.error: {
            cancel();
            throw new StreamError(data);
          }
        }
      }
    }
  });
}

export type ErrorHandler = ({ errorCode }: { errorCode: string }) => void;

export const httpSendMessage = {
  callStreamEndpoint: async (props: {
    projectId: string;
    conversationId: string;
    message: NewMessageDto;
    onUpdate: (chunk: Chunk) => void;
    onStart: (message: PartialMessageDto) => void;
    onError: ErrorHandler;
    onEnd: () => void;
  }) => {
    const { onUpdate, onStart, onError, onEnd } = props;

    const response = await sendMessage(props);

    if (response && !response.ok) {
      const data = await response.json();
      if (data.message === 'ERROR_NO_GPT4_BALANCE') {
        GAEvents.gpt4LimitationViewed();
      }

      if (data.message) {
        onError({ errorCode: data.message });
      }
    }

    await handleStream({ response, onUpdate, onStart, onEnd });
  }
};
