import { createAsyncAction } from 'utils/reduxUtils';

import {
  FetchWordSynonymsFailurePayload,
  FetchWordSynonymsRequestPayload,
  FetchWordSynonymsSuccessPayload,
  ScoreWordsFailurePayload,
  ScoreWordsRequestPayload,
  ScoreWordsSuccessPayload
} from './types';

export const scoreWords = createAsyncAction<
  ScoreWordsRequestPayload,
  ScoreWordsSuccessPayload,
  ScoreWordsFailurePayload
>('tester/scoreWords');

export const fetchWordSynonyms = createAsyncAction<
  FetchWordSynonymsRequestPayload,
  FetchWordSynonymsSuccessPayload,
  FetchWordSynonymsFailurePayload
>('tester/fetchWordSynonyms');
