import { getRoutePath } from 'config/routes';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const AuthFooterLinks = () => (
  <CenteredText>
    <FormattedMessage
      id="login.sign_up"
      values={{
        sign: (val: string) => <Link to={getRoutePath('free')}>{val}</Link>
      }}
    />
    <br />
    <Link to={getRoutePath('forgotPassword')}>
      <FormattedMessage id="login.forgot_password" />
    </Link>
  </CenteredText>
);

const CenteredText = styled.div`
  text-align: center;
`;
