import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TwitterIcon from '@mui/icons-material/Twitter';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { Button, Divider, IconButton } from '@mui/material';
import { ColoredTypography } from 'components/ColoredTypography';
import FlexContainer from 'components/FlexContainer';
import { CloseModal } from 'components/modals/types';
import Toast from 'components/toasts/Toast';
import {
  ModalBodyWithColumnDirection,
  ModalWithDividedHeaderLayout
} from 'features/theme-2024/ModalWithDividedHeaderLayout';
import { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import GTMIds from 'services/tracking/GTMIds';
import { withGtmInteraction } from 'services/tracking/withGtmInteraction';
import { getAppBaseUrl } from 'utils/getAppBaseUrl';

enum SocialWebsitesURL {
  Facebook = 'https://www.facebook.com/',
  LinkedIn = 'https://www.linkedin.com/',
  Twitter = 'https://www.twitter.com/',
  WhatsApp = 'https://www.whatsapp.com/',
  Instagram = 'https://www.instagram.com/'
}

type Props = CloseModal & {
  imageId: string;
  daysUntilExpire: number;
};

export function ShareAiImageModal(props: Props) {
  const { imageId, daysUntilExpire, closeModal } = props;

  function handleCreateLink() {
    copyLinkToClipboard();
    Toast.success('common.copy_to_clipboard');
  }

  function createShareableLink() {
    const appBaseUrl = getAppBaseUrl();

    return `${appBaseUrl}/ai-images/${window.btoa(imageId)}/preview`;
  }

  function copyLinkToClipboard() {
    const link = createShareableLink();

    navigator.clipboard.writeText(link);
  }

  return (
    <ModalWithDividedHeaderLayout
      title={<FormattedMessage id="ai_images.share_modal.title" />}
      closeModal={closeModal}
      slots={{
        bodyContainer: ModalBodyWithColumnDirection
      }}
    >
      <ColoredTypography variant="caption">
        <FormattedMessage
          id="ai_images.share_modal.body"
          values={{
            amount: daysUntilExpire
          }}
        />
      </ColoredTypography>

      <Button
        onClick={handleCreateLink}
        variant="contained"
        {...withGtmInteraction(GTMIds.aiImages.generatorPage.copyShareLinkButton)}
      >
        <FormattedMessage id="ai_images.share_modal.button" />
      </Button>

      <Divider />

      <FlexContainer direction="row" justifyContent="space-between">
        <SocialLinkButton
          icon={<FacebookIcon fontSize="large" />}
          link={SocialWebsitesURL.Facebook}
        />
        <SocialLinkButton
          icon={<LinkedInIcon fontSize="large" />}
          link={SocialWebsitesURL.LinkedIn}
        />
        <SocialLinkButton
          icon={<TwitterIcon fontSize="large" />}
          link={SocialWebsitesURL.Twitter}
        />
        <SocialLinkButton
          icon={<WhatsAppIcon fontSize="large" />}
          link={SocialWebsitesURL.WhatsApp}
        />
        <SocialLinkButton
          icon={<InstagramIcon fontSize="large" />}
          link={SocialWebsitesURL.Instagram}
        />
      </FlexContainer>
    </ModalWithDividedHeaderLayout>
  );
}

function SocialLinkButton(props: { icon: ReactNode; link: string }) {
  const { icon, link } = props;

  return (
    <IconButton component="a" href={link} target="_blank" rel="noreferrer">
      {icon}
    </IconButton>
  );
}
