/* eslint-disable @typescript-eslint/no-unused-vars */
import { Plate, Value } from '@udecode/plate-common';
import { useDataTypeDocumentPlatePluginConfiguration } from 'features/modular-workflow/builder/settings/useDataTypeDocumentPlatePluginConfiguration';
import { TooltipProvider } from 'features/plate/components/plate-ui/tooltip';
import { throttle } from 'lodash';
import { ReactElement, ReactNode } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

type Props = {
  id: string;
  initialValue: Value;
  children: ReactNode;
  onChange: (value: Value) => void;
};

export const DataTypeDocumentEditorProvider = ({
  id,
  initialValue,
  children,
  onChange
}: Props): ReactElement => {
  const handleChange = throttle((value: Value) => onChange(value), 1000);

  const plugins = useDataTypeDocumentPlatePluginConfiguration();

  return (
    <TooltipProvider>
      <DndProvider backend={HTML5Backend}>
        <Plate id={id} plugins={plugins} initialValue={initialValue} onChange={handleChange}>
          {children}
        </Plate>
      </DndProvider>
    </TooltipProvider>
  );
};
