import { ModelMapper } from 'services/api/embeddingModels/types';

import ComboBox from '../../components/Autocomplete/ComboBox';
import useTr from '../../utils/hooks/useTr';

type Props = {
  value: ModelMapper | null;
  options: ModelMapper[];
  className?: string;

  onChange: (mapper: ModelMapper) => void;
};

const MapperSelect = ({ value, options, className, onChange }: Props) => {
  const tr = useTr();

  return (
    <ComboBox
      placeholder={!options.length ? tr('model_selection.no_mappers') : ''}
      data={options}
      itemToString={item => (item ? item.label : '')}
      disabled={!options.length}
      className={className}
      selectedItem={value}
      onChange={item => {
        if (item) {
          onChange(item);
        }
      }}
      itemId="name"
    />
  );
};

export default MapperSelect;
