import { ColoredTypography } from 'components/ColoredTypography';
import { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

export function StatisticTile(props: {
  title: string;
  statistic: number | ReactNode;
  additional?: ReactNode;
}) {
  const { title, statistic, additional } = props;
  return (
    <StatsBox>
      <ColoredTypography variant="overline">
        <FormattedMessage id={title} />
      </ColoredTypography>
      <ColoredTypography variant="h4">{statistic}</ColoredTypography>
      {additional && <ColoredTypography variant="body2">{additional}</ColoredTypography>}
    </StatsBox>
  );
}

const StatsBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.small};
  width: 16rem;

  padding: ${({ theme }) => theme.spacings.medium};
  border-radius: ${({ theme }) => theme.borderRadius.medium};
  background-color: ${({ theme }) => theme.colors.backgroundPaperElevation0};
`;
