import { BaseOutput } from 'features/aiWriter/slashCommands/BaseOutput';
import { FlashActionsInputProps } from 'features/aiWriter/slashCommands/FlashActionsInput';
import {
  useFlashActionsSelectedText,
  useGetFlashActionsSelectedAction
} from 'features/aiWriter/slashCommands/hooks/useFlashActionsStore';
import { assertNonNullable } from 'utils/typescript/nonNullable';

type Props = FlashActionsInputProps;

const OUTPUT_TYPE = 'translate_action';

export function TranslateOutput(props: Props) {
  const selectedAction = useGetFlashActionsSelectedAction();
  const flashActionsSelectedText = useFlashActionsSelectedText();

  assertNonNullable(
    selectedAction?.languageModel,
    'no language model provided for translation action'
  );

  const generateConfig = {
    outputType: OUTPUT_TYPE,
    text: flashActionsSelectedText,
    keywords: selectedAction.languageModel
  };

  return <BaseOutput generateConfig={generateConfig} onClose={props.onClose} />;
}
