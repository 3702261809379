import { Typography } from '@mui/material';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import useTr from 'utils/hooks/useTr';

export const PrivacyPolicy = (): React.ReactElement => {
  const translate = useTr();

  return (
    <Typography variant="body2">
      <FormattedMessage
        id="register.account.termAndConditions"
        values={{
          terms: (msg: string) => (
            <a
              href={translate('external_links.app_terms_and_condition')}
              target="_blank"
              rel="noopener noreferrer"
            >
              {msg}
            </a>
          ),
          privacy: (msg: string) => (
            <a
              href={translate('external_links.app_privacy_policy')}
              target="_blank"
              rel="noopener noreferrer"
            >
              {msg}
            </a>
          )
        }}
      />
    </Typography>
  );
};

