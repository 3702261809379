import { MARK_BOLD, MARK_CODE, MARK_ITALIC, MARK_STRIKETHROUGH } from '@udecode/plate-basic-marks';
import { ELEMENT_BLOCKQUOTE } from '@udecode/plate-block-quote';
import { ELEMENT_CODE_BLOCK } from '@udecode/plate-code-block';
import { TDescendant, TText } from '@udecode/plate-common';
import { KEYS_HEADING } from '@udecode/plate-heading';
import { ELEMENT_LINK } from '@udecode/plate-link';
import { ELEMENT_TODO_LI } from '@udecode/plate-list';
import { ELEMENT_PARAGRAPH } from '@udecode/plate-paragraph';
import { ELEMENT_TABLE, ELEMENT_TD, ELEMENT_TR } from '@udecode/plate-table';
import { RootContent } from 'mdast';
import remarkGfm from 'remark-gfm';
import remarkParse from 'remark-parse';
import { unified } from 'unified';

function parseMarkdown(markdown: string) {
  return unified().use(remarkParse).use(remarkGfm).parse(markdown);
}

type PhrasingMeta = {
  italic?: boolean;
  bold?: boolean;
  strikethrough?: boolean;

  listStyleType?: 'decimal' | 'disc';
  start?: number | null;
  listStart?: number | null;
};

function ignore() {
  return [];
}

function slateTextNode(node: { value: string }, phrasingMeta: PhrasingMeta): TText {
  const { italic, bold, strikethrough } = phrasingMeta;

  return {
    text: node.value,
    [MARK_ITALIC]: italic,
    [MARK_BOLD]: bold,
    [MARK_STRIKETHROUGH]: strikethrough
  };
}

function guaranteeContent(children: TDescendant[]) {
  return children.length < 1 ? [{ text: '' }] : children;
}

function setIndent(phrasingMeta: PhrasingMeta) {
  if (phrasingMeta.listStyleType === 'disc' || phrasingMeta.listStyleType === 'decimal') {
    return phrasingMeta.listStart || 1;
  }
  return 0;
}

/**
 * You can find more details about mdast here: https://github.com/syntax-tree/mdast
 */
function mdastContentToSlate(node: RootContent, phrasingMeta: PhrasingMeta): TDescendant[] {
  switch (node.type) {
    case 'paragraph':
      return [
        {
          type: ELEMENT_PARAGRAPH,
          start: phrasingMeta.start,
          listStyleType: phrasingMeta.listStyleType,
          indent: setIndent(phrasingMeta),
          children: guaranteeContent(mdastChildrenToSlate(node.children, {})),
          listStart: phrasingMeta.listStart
        }
      ];
    case 'heading':
      return [
        {
          type: KEYS_HEADING[node.depth - 1],
          children: guaranteeContent(mdastChildrenToSlate(node.children, {}))
        }
      ];
    case 'code':
      return [
        {
          type: ELEMENT_CODE_BLOCK,
          lang: node.lang,
          children: [{ text: node.value }]
        }
      ];
    case 'blockquote':
      return [
        {
          type: ELEMENT_BLOCKQUOTE,
          children: guaranteeContent(mdastChildrenToSlate(node.children, {}))
        }
      ];
    case 'list':
      return mdastChildrenToSlate(node.children, {
        ...phrasingMeta,
        listStyleType: node.ordered ? 'decimal' : 'disc',
        // we need to increment the listStart for each nested list
        listStart: (phrasingMeta.listStart ?? 0) + 1,
        start: node.start
      });
    case 'listItem': {
      if (node.checked !== null && node.checked !== undefined) {
        return [
          {
            type: ELEMENT_TODO_LI,
            checked: node.checked,
            children: mdastChildrenToSlate(node.children, {}),
            listStart: phrasingMeta.listStart
          }
        ];
      }

      return mdastChildrenToSlate(node.children, { ...phrasingMeta });
    }

    // phrasing
    case 'text': {
      return [slateTextNode(node, phrasingMeta)];
    }
    case 'inlineCode': {
      return [
        {
          ...slateTextNode(node, phrasingMeta),
          [MARK_CODE]: true
        }
      ];
    }
    case 'emphasis':
      return mdastChildrenToSlate(node.children, { ...phrasingMeta, italic: true });
    case 'strong':
      return mdastChildrenToSlate(node.children, { ...phrasingMeta, bold: true });
    case 'delete':
      return mdastChildrenToSlate(node.children, { ...phrasingMeta, strikethrough: true });
    case 'link':
      return [
        {
          type: ELEMENT_LINK,
          url: node.url,
          children: guaranteeContent(mdastChildrenToSlate(node.children, { ...phrasingMeta }))
        }
      ];
    case 'html': {
      return [
        {
          ...slateTextNode(node, phrasingMeta),
          [MARK_CODE]: true
        }
      ];
    }
    case 'table':
      return [
        {
          type: ELEMENT_TABLE,
          children: mdastChildrenToSlate(node.children, {})
        }
      ];
    case 'tableRow':
      return [
        {
          type: ELEMENT_TR,
          children: mdastChildrenToSlate(node.children, {})
        }
      ];
    case 'tableCell': {
      const tableCellChildren = mdastChildrenToSlate(node.children, {});

      return [
        {
          type: ELEMENT_TD,
          // Slate crashes on empty table cells
          children: tableCellChildren.length < 1 ? [{ text: '' }] : tableCellChildren
        }
      ];
    }
    // No visual representation
    case 'thematicBreak':
    case 'definition':
      return ignore();

    // unsupported
    case 'image':
    case 'break':
    case 'linkReference':
    case 'imageReference':
    case 'footnoteReference':
    case 'footnoteDefinition':
    case 'yaml':
    default:
      return ignore();
  }
}

function mdastChildrenToSlate(children: RootContent[], phrasingMeta: PhrasingMeta): TDescendant[] {
  return children.flatMap(child => {
    return mdastContentToSlate(child, phrasingMeta);
  });
}

export function markdownToSlate(markdown: string) {
  const mdast = parseMarkdown(markdown);
  return mdastChildrenToSlate(mdast.children, {});
}
