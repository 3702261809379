import { PromptFieldType } from 'features/textGenerator/store/types';
import { getFieldPrefillValue } from 'features/textGenerator/utils/getFieldPrefillValue';
import {
  fetchAndCachePromptsByCountryAndLanguage,
  useGetPromptsByCountryAndLanguage
} from 'features/textGenerator/utils/useGetPromptsByCountryAndLanguage';
import {
  fetchAndCachePromptsByLanguage,
  useGetPromptsByLanguage
} from 'features/textGenerator/utils/useGetPromptsByLanguage';
import { useMemo } from 'react';

export const useGetOutputTypeOptionsByLanguageAndCountry = (language: string, country: string) => {
  const prompts = useGetPromptsByCountryAndLanguage(language, country);

  return useMemo(
    () =>
      prompts
        .filter(p => p.displayInSelect)
        .map(p => ({
          outputType: {
            ...p.outputType,
            youTubeVideoId: p.videoId ?? undefined
          },
          category: p.outputTypeCategory,
          description: p.outputTypeDescription
        })),
    [prompts]
  );
};

export const useGetPromptConfigByLanguageCountryAndOutputType = (
  country: string,
  language: string
) => {
  const prompts = useGetPromptsByCountryAndLanguage(language, country);

  return (outputTypeId: string) => prompts.find(p => p.outputType.id === outputTypeId);
};

export const useGetPromptConfigByLanguageCountryAndOutputTypeCached = (
  country: string,
  language: string,
  outputTypeId: string
) => {
  const prompts = useGetPromptsByCountryAndLanguage(language, country);

  return useMemo(
    () => prompts.find(p => p.outputType.id === outputTypeId),
    [prompts, outputTypeId]
  );
};

export const useGetOutputLabel = (language: string, country: string) => {
  const prompts = useGetPromptsByCountryAndLanguage(language, country);

  return (outputType: string) => {
    return prompts.find(p => p.outputType.id === outputType)?.outputType.label ?? undefined;
  };
};

const defaultOutputType = 'product_description_descriptive';

export const useGetDefaultOutputTypeByLanguageMemoized = (language: string, country: string) => {
  const prompts = useGetPromptsByCountryAndLanguage(language, country);

  return useMemo(
    () =>
      // TODO: This whole hook tries to verify that the default output type is available in the prompts
      //       If it's not, it falls back to the first available output type
      //       We've to check if this is needed at all or we simply use defaultOutputType everywhere
      prompts.find(p => p.outputType.id === defaultOutputType)?.outputType.id ??
      prompts[0]?.outputType.id ??
      defaultOutputType,
    [prompts]
  );
};

export const useGetPrefillValueByLanguageAndOutputType = (language: string) => {
  const prompts = useGetPromptsByLanguage(language);

  return (outputType: string, fieldType: PromptFieldType) => {
    const prompt = prompts.find(p => p.outputType.id === outputType);

    return getFieldPrefillValue(prompt, fieldType);
  };
};

export const useGetPrefillValueByLanguageCountryAndOutputType = (
  language: string,
  country: string
) => {
  const prompts = useGetPromptsByCountryAndLanguage(language, country);

  return (outputType: string, fieldType: PromptFieldType) => {
    const prompt = prompts.find(p => p.outputType.id === outputType);

    return getFieldPrefillValue(prompt, fieldType);
  };
};

export const useGetNTimesByOutputType = (language: string, country: string) => {
  const prompts = useGetPromptsByCountryAndLanguage(language, country);

  return (outputType: string) => prompts.find(p => p.outputType.id === outputType)?.nTimes ?? 1;
};

export const useGetNTimesByOutputTypeCached = (
  language: string,
  country: string,
  outputType: string
) => {
  const prompts = useGetPromptsByCountryAndLanguage(language, country);

  return useMemo(
    () => prompts.find(p => p.outputType.id === outputType)?.nTimes ?? 1,
    [outputType, prompts]
  );
};

export const fetchDefaultOutputTypeByLanguageCountry = async (
  language: string,
  country: string
) => {
  const prompts = await fetchAndCachePromptsByCountryAndLanguage(language, country);

  return (
    prompts.find(p => p.outputType.id === defaultOutputType)?.outputType.id ??
    prompts[0]?.outputType.id
  );
};

export const fetchPrefillValueByLanguageAndOutputType = async (
  language: string,
  outputType: string,
  fieldType: PromptFieldType
) => {
  const prompts = await fetchAndCachePromptsByLanguage(language);

  const prompt = prompts.find(p => p.outputType.id === outputType);

  return getFieldPrefillValue(prompt, fieldType);
};
