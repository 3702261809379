/* eslint-disable @typescript-eslint/no-explicit-any */
import { EventHandler, RefObject, useEffect } from 'react';

const useOnClickOutside = <T extends Element>(
  ref: RefObject<T>,
  handler: EventHandler<any>,
  exception?: Element | null
) => {
  useEffect(() => {
    const listener = (event: Event) => {
      if (!ref.current || ref.current.contains(event.target as Node)) {
        return;
      }

      if (exception?.contains(event.target as Node)) {
        return;
      }

      handler(event);
    };

    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener);

    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    };
  }, [exception, handler, ref]);
};

export default useOnClickOutside;
