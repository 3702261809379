import { ReactNode } from 'react';
import styled from 'styled-components';

type Props = {
  children: ReactNode;
  columns?: number;
};

export const ModalListCardGrid = ({ children, columns = 4 }: Props) => (
  <Root $columns={columns}>{children}</Root>
);

export const Root = styled.div<{ $columns: number }>`
  display: grid;
  grid-template-columns: repeat(${({ $columns }) => $columns}, minmax(0, 1fr));
  grid-auto-rows: 1fr;
  // Note: Figma says four, but we're using two because four crates too many issues
  //       with other areas
  gap: ${({ theme }) => theme.spacings.two};
  // Grant a lil bit of space to the bottom to make space for box shadow
  padding-bottom: ${({ theme }) => theme.spacings.four};

  overflow: auto;
  align-content: start;
`;
