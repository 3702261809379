/* eslint-disable @typescript-eslint/no-explicit-any */
import { PayloadAction } from '@reduxjs/toolkit';
import { BaseTab, Tabs } from 'types/ReduxStateTypes';

export const maxProjectNameLength = 50;
export const maxProjectNameWordAmount = 5;

export const closeTabReducer = <T extends Tabs<any>>(
  state: T,
  { payload: tabId }: PayloadAction<string>
): T => {
  let tabIndex = -1;
  if (tabId === state.currentTab) {
    tabIndex = Object.keys(state.tabs).findIndex(id => id === String(tabId));
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { [tabId]: _, ...newTabs } = state.tabs;

  let newCurrentTab = state.currentTab;
  if (tabIndex !== -1) {
    newCurrentTab =
      Object.keys(newTabs).length > 0
        ? Object.keys(newTabs)[tabIndex > 0 ? tabIndex - 1 : tabIndex]
        : '';
  }

  return { ...state, tabs: newTabs, currentTab: newCurrentTab };
};

export const setCurrentTabReducer = <T extends Tabs<any>>(
  state: T,
  { payload }: PayloadAction<string>
): T => ({ ...state, currentTab: payload });

export const addTabReducer = <TTab extends BaseTab, TState extends Tabs<TTab>>(
  state: TState,
  { payload }: PayloadAction<TTab>
): TState => ({
  ...state,
  currentTab: payload.id,
  tabs: { ...state.tabs, [payload.id]: payload }
});

type TabType<State> = State extends Tabs<infer K> ? K : never;

export const getCurrentTab = <TState extends Tabs<any>>(
  state: TState
): TabType<TState> | undefined => state.tabs[state.currentTab];

export const renameCurrentTabReducer = <TTab extends BaseTab, TState extends Tabs<TTab>>(
  state: TState,
  { payload }: PayloadAction<string>
): TState => {
  const name = payload;

  return {
    ...state,
    tabs: { ...state.tabs, [state.currentTab]: { ...state.tabs[state.currentTab], name } }
  };
};

export const getTabById = <TState extends Tabs<any>>(
  tabId: string,
  state: TState
): TabType<TState> | undefined => state.tabs[tabId];
