import { BaseSuccessDto } from 'services/backofficeIntegration/http/BaseSuccessDto';
import { backofficeClient } from 'services/backofficeIntegration/http/client/createBackofficeClient';
import { mutationEndpoint } from 'services/backofficeIntegration/http/endpointDefinition';

type SuccessDto = BaseSuccessDto<never>;

export const httpValidateInformation = mutationEndpoint({
  callEndpoint: (ids: number[]) =>
    backofficeClient
      .post<SuccessDto>('/informations/validate', { ids })
      .then(response => response.data)
});
