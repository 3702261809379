import { makeCurrentUserQueryKeyPrefix } from 'features/currentUser/currentUserQueries';
import { queryClient } from 'utils/ReactQueryClient';

export function invalidateWordsUsageQueries() {
  return queryClient.invalidateQueries(makeWordsUsageQueryKeyPrefix());
}

export function makeWordsUsageQueryKeyPrefix() {
  return [...makeCurrentUserQueryKeyPrefix(), 'words', 'usage'];
}
