import { Typography } from '@mui/material';
import FlexContainer from 'components/FlexContainer';
import Toast from 'components/toasts/Toast';
import { Loader } from 'features/churnModal/loader';
import { TriggerEffectOnce } from 'features/churnModal/triggerEffectOnce';
import { useGetChurnStatisticQuery } from 'features/churnModal/useGetChurnStatisticQuery';
import FormattedMessage from 'features/i18n/FormattedMessage';
import { createContext, PropsWithChildren, ReactElement, useContext } from 'react';
import { GetChurnStatisticData } from 'services/api/customer/types';
import styled from 'styled-components';

export const StatisticsStep = {
  Layout,
  Heading,
  Explanation,
  LeavingStatistics,
  DeleteStatistics
};

export function Layout(props: {
  heading: ReactElement;
  explanation: ReactElement;
  statistics: ReactElement;
  actions: ReactElement;
  nextStep: () => void;
}) {
  return (
    <StatisticsProvider nextStep={props.nextStep}>
      <FlexContainer gap="medium">
        {props.heading}
        {props.explanation}
        {props.statistics}
        <ActionBox>{props.actions}</ActionBox>
      </FlexContainer>
    </StatisticsProvider>
  );
}
const StatisticsContext = createContext<GetChurnStatisticData | undefined>(undefined);

function StatisticsProvider(props: PropsWithChildren<{ nextStep: () => void }>) {
  const statisticsResult = useGetChurnStatisticQuery();

  if (statisticsResult.isError) {
    return (
      <TriggerEffectOnce
        effect={() => {
          Toast.apiError();
          props.nextStep();
        }}
      />
    );
  }

  if (statisticsResult.isLoading) {
    return <Loader />;
  }

  return (
    <StatisticsContext.Provider value={statisticsResult.data}>
      {props.children}
    </StatisticsContext.Provider>
  );
}

function useStatisticsData(): GetChurnStatisticData {
  const statistics = useContext(StatisticsContext);
  if (!statistics) {
    throw new Error('statistics data not provided');
  }

  return statistics;
}

export function Heading() {
  return (
    <Typography variant="h4" align="center">
      <FormattedMessage id="churn_modal.statistics_step.heading" />
    </Typography>
  );
}

export enum ChurnType {
  CancelSubscription,
  DeleteAccount
}

const explanations: Record<ChurnType, string> = {
  [ChurnType.CancelSubscription]: 'churn_modal.statistics_step.explanation.cancel_subscription',
  [ChurnType.DeleteAccount]: 'churn_modal.statistics_step.explanation.delete_account'
};

export function Explanation(props: { churnType: ChurnType }) {
  const signUpDate = useStatisticsData().registration_date.slice(0, 10);
  const messageId = explanations[props.churnType];

  return (
    <Typography variant="body1" align="center">
      <FormattedMessage id={messageId} values={{ signUpDate, br: <br /> }} />
    </Typography>
  );
}

export function LeavingStatistics() {
  const statistics = useStatisticsData();

  return (
    <FlexContainer gap="small" direction="column">
      <Typography>
        <FormattedMessage
          id="churn_modal.statistics_step.documents"
          values={{ documents: statistics.document_count, b: (msg: string) => <b>{msg}</b> }}
        />
      </Typography>
      <Typography>
        <FormattedMessage
          id="churn_modal.statistics_step.generated_words"
          values={{ words: statistics.used_words_count, b: (msg: string) => <b>{msg}</b> }}
        />
      </Typography>

      {statistics.available_words_count > 0 && (
        <Typography>
          <FormattedMessage
            id="churn_modal.statistics_step.available_words"
            values={{ words: statistics.available_words_count, b: (msg: string) => <b>{msg}</b> }}
          />
        </Typography>
      )}

      <Typography>
        <FormattedMessage
          id="churn_modal.statistics_step.knowledge_and_brand_voices_deleted"
          values={{ b: (msg: string) => <b>{msg}</b> }}
        />
      </Typography>
    </FlexContainer>
  );
}

export function DeleteStatistics() {
  const statistics = useStatisticsData();

  return (
    <FlexContainer gap="small" direction="column">
      <Typography>
        <FormattedMessage
          id="churn_modal.statistics_step.documents"
          values={{ documents: statistics.document_count, b: (msg: string) => <b>{msg}</b> }}
        />
      </Typography>

      {statistics.available_words_count > 0 && (
        <Typography>
          <FormattedMessage
            id="churn_modal.statistics_step.available_words"
            values={{ words: statistics.available_words_count, b: (msg: string) => <b>{msg}</b> }}
          />
        </Typography>
      )}

      <Typography>
        <FormattedMessage
          id="churn_modal.statistics_step.knowledge_and_brand_voices_deleted_after_30_days"
          values={{ b: (msg: string) => <b>{msg}</b> }}
        />
      </Typography>
    </FlexContainer>
  );
}

const ActionBox = styled.div`
  margin-top: ${({ theme }) => theme.spacings.medium};
`;
