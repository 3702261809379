import { makeCurrentUserQueryKeyPrefix } from 'features/currentUser/currentUserQueries';
import { ApiModularWorkflowSharingPermission } from 'features/modular-workflow/builder/types-api';
import { RunnerModularWorkflow } from 'features/modular-workflow/runner/types-runner';
import { backofficeClient } from 'services/backofficeIntegration/http/client/createBackofficeClient';
import { queryEndpoint } from 'services/backofficeIntegration/http/endpointDefinition';
import { PaginatedListSuccessDto } from 'services/backofficeIntegration/http/paginatedListEndpoint';
import { queryClient } from 'utils/ReactQueryClient';

type SuccessDto = PaginatedListSuccessDto<RunnerModularWorkflow>;

type ViewMode = 'builder' | 'run';
type SortByDto = 'newest' | 'order';

export type PaginatedListParams = {
  offset?: number;
  limit?: number;
  search?: string;
  view_mode?: ViewMode;
  sharing_permissions?: ApiModularWorkflowSharingPermission[];
  sort?: SortByDto;
};

export const httpGetModularWorkflows = queryEndpoint({
  makeQueryKey: (params: PaginatedListParams) => {
    return [...makeCurrentUserQueryKeyPrefix(), 'workflows', ...Object.values(params)] as const;
  },
  callEndpoint: (params: PaginatedListParams) => {
    return backofficeClient.get<SuccessDto>('/workflows', { params }).then(response => ({
      workflows: response.data?.data ?? [],
      total: response.data?.total ?? 0,
      limit: response.data?.limit ?? 0,
      offset: response.data?.offset ?? 0
    }));
  }
});

export function invalidateWorkflowsQuery() {
  return queryClient.invalidateQueries({
    queryKey: [...makeCurrentUserQueryKeyPrefix(), 'workflows']
  });
}
