const isBrowser = typeof window !== 'undefined';

/**
 * @deprecated Replace with Cookies.get() ({@see https://github.com/js-cookie/js-cookie})
 */
export const getCookie = (name: string, initialValue: string | null = '') => {
  if (!isBrowser) {
    return initialValue;
  }

  const parsedCookieObject = document.cookie.split('; ').reduce((r, v) => {
    const parts = v.split('=');
    return parts[0] === name ? decodeURIComponent(parts[1]) : r;
  }, '');

  return parsedCookieObject || initialValue;
};
