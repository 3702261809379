import { useCallback, useEffect, useRef } from 'react';

function useStickyTableHeader() {
  const headerContainerRef = useRef<HTMLDivElement>(null);
  const headerRowRef = useRef<HTMLDivElement>(null);
  const tableBodyRef = useRef<HTMLDivElement>(null);

  const lastScrollPosition = useRef(0);
  const scrollTicking = useRef(false);

  const syncScrolls = useCallback(() => {
    if (!scrollTicking.current && tableBodyRef.current) {
      lastScrollPosition.current = tableBodyRef.current.scrollLeft;
      window.requestAnimationFrame(() => {
        if (
          headerContainerRef.current &&
          headerContainerRef.current.scrollLeft !== lastScrollPosition.current
        ) {
          headerContainerRef.current.scrollLeft = lastScrollPosition.current;
        }
        if (
          headerRowRef.current &&
          headerRowRef.current.scrollLeft !== lastScrollPosition.current
        ) {
          headerRowRef.current.scrollLeft = lastScrollPosition.current;
        }
        scrollTicking.current = false;
      });
      scrollTicking.current = true;
    }
  }, [tableBodyRef, headerRowRef, headerContainerRef]);

  useEffect(() => {
    const containerNode = tableBodyRef.current;
    if (containerNode) {
      containerNode.addEventListener('scroll', syncScrolls, { passive: true });
    }

    return () => {
      if (containerNode) {
        containerNode.addEventListener('scroll', syncScrolls);
      }
    };
  }, [tableBodyRef, syncScrolls]);

  return { headerContainerRef, headerRowRef, tableBodyRef };
}

export default useStickyTableHeader;
