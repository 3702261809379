import getScoreTextConfig from 'features/textGenerator/utils/getScoreTextConfig';
import { Dimension, ManualDimension } from 'services/api/dimension/types';
import { WordAttributeWithOption } from 'types/ReduxStateTypes';

import extendGoalConfigWithScoreByOpenRate from '../extensions/extendGoalConfigWithScoreByOpenRate';
import extendMappersWithScoreByOpenRate from '../extensions/extendMappersWithScoreByOpenRate';

const prepareScoreTextConfig = (
  {
    audienceModelId,
    mapperId,
    wordAttributes,
    dimensions,
    manualDimensions
  }: {
    audienceModelId: string | undefined;
    mapperId: string;
    wordAttributes: WordAttributeWithOption[];
    dimensions: Dimension[];
    manualDimensions: ManualDimension[];
  },
  { extendWithScoreByOpenRate }: { extendWithScoreByOpenRate: boolean }
) => {
  const { goalConfig, apiDimensions, mappers } = getScoreTextConfig({
    mapperId,
    wordAttributes,
    dimensions,
    manualDimensions
  });

  if (audienceModelId && extendWithScoreByOpenRate) {
    return {
      goalConfig: extendGoalConfigWithScoreByOpenRate(audienceModelId, goalConfig),
      apiDimensions,
      mappers: extendMappersWithScoreByOpenRate(audienceModelId, mappers)
    };
  }

  return { goalConfig, apiDimensions, mappers };
};

export default prepareScoreTextConfig;
