import LocalStorageKey from 'config/localStorageKey';
import { audienceField, TesterSetupValues } from 'features/aiTester/modals/setup/SetupModal';
import { AudienceSelect } from 'features/audiences/AudienceSelect';
import { useFormikContext } from 'formik';
import React, { useCallback } from 'react';

const SetupAudienceSelect = (): React.ReactElement => {
  const {
    values: { model }
  } = useFormikContext<TesterSetupValues>();

  const onSelect = useCallback((value: unknown) => {
    localStorage.setItem(LocalStorageKey.TesterAudience, String(value));
  }, []);

  return <AudienceSelect name={audienceField} onSelect={onSelect} embeddingModelId={model} />;
};

export default SetupAudienceSelect;
