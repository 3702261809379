import { Typography } from '@mui/material';
import { AuthCard } from 'components/auth/AuthCard';
import FlexContainer from 'components/FlexContainer';
import { IntlMessageKeys } from 'features/i18n/IntlMessageKeys';
import { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { mobileWindowWidthCSS } from 'utils/hooks/useMobileVersion';
import { objectToCss } from 'utils/objectToCss';

type Props = {
  header: IntlMessageKeys;
  extra?: ReactNode;
  ornaments?: ReactNode;
  error?: ReactNode;
  children: ReactNode;
  socials?: ReactNode;
};

const AuthForm = ({ header, extra, ornaments, error, children, socials }: Props) => (
  <FlexContainer gap="three" alignItems="center">
    <AuthCard>
      <Content gap="three">
        <Header variant="h6">
          <FormattedMessage id={header} />
        </Header>

        {socials && <CenteredColumnContainer>{socials}</CenteredColumnContainer>}

        {children}

        {extra && (
          <CenteredColumnContainer>
            <Typography variant="body1">{extra}</Typography>
          </CenteredColumnContainer>
        )}

        {error}
      </Content>

      {ornaments && <Ornament>{ornaments}</Ornament>}
    </AuthCard>
  </FlexContainer>
);

const Header = styled(Typography)`
  text-align: center;

  @media (max-width: ${mobileWindowWidthCSS}) {
    ${({ theme }) => objectToCss(theme.typography.h4)};
    // The calc() takes into account the existing space between header and main content
    margin-top: ${({ theme }) => `calc(${theme.spacings.eight} - ${theme.spacings.three})`};
    margin-bottom: ${({ theme }) => `calc(${theme.spacings.six} - ${theme.spacings.three})`};
  }
`;

const Content = styled(FlexContainer)``;

const CenteredColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Ornament = styled.div`
  @media (max-width: ${mobileWindowWidthCSS}) {
    display: none;
  }
`;

export default AuthForm;
