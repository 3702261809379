import Page from 'components/base/Page';
import UnauthPage from 'components/base/UnauthPage';
import Toolbar from 'components/layout/toolbar/Toolbar';
import LocalStorageKey from 'config/localStorageKey';
import { getPaths, useIsSidebarEnabled } from 'config/routes';
import useEmailVerificationRequiredModal from 'features/email-verification/hooks/useEmailVerificationRequiredModal';
import { OnboardingContextProvider } from 'features/onboardingJourney/newUserSurvey/OnboardingContextProvider';
import { Sidebar } from 'features/pageSidebar/Sidebar';
import useShowAccessExpiredModal from 'features/pricing/hook/useShowAccessExpiredModal';
import { useWordsLimitReachedModal } from 'features/pricing/hook/useYouRunOutOfWordsModal';
import { ErrorBoundary } from 'features/react-error-boundary/ErrorBoundary';
import useIsAuthRoute from 'features/services/hooks/useIsAuthRoute';
import { useShowMobileWarningModal } from 'pages/mobileWarning/MobileWarningModal';
import React, { useEffect } from 'react';
import useConstant from 'utils/hooks/useConstant';
import { useCurrentRoutePath } from 'utils/hooks/useCurrentRoutePath';

type Props = {
  children: React.ReactNode;
};

const AuthWrapper = ({ children }: Props) => {
  const toolbarPaths = useConstant(() => getPaths(config => config.hasToolbar));
  const isSidebarEnabled = useIsSidebarEnabled();

  const pathname = useCurrentRoutePath();

  useShowAccessExpiredModal();
  useWordsLimitReachedModal();
  useEmailVerificationRequiredModal();

  useShowMobileWarningModal();

  const isAuthRoute = useIsAuthRoute();

  useEffect(() => {
    if (isAuthRoute) {
      localStorage.setItem(LocalStorageKey.LastVisitedPage, pathname);
    }
  }, [isAuthRoute, pathname]);

  if (!isAuthRoute) {
    return <UnauthPage>{children}</UnauthPage>;
  }

  return (
    <>
      <ErrorBoundary>
        <Sidebar />
      </ErrorBoundary>
      <OnboardingContextProvider>
        <Page $hasSidebar={isSidebarEnabled}>
          <ErrorBoundary>{children}</ErrorBoundary>
        </Page>
      </OnboardingContextProvider>
      <Toolbar disabled={!toolbarPaths.includes(pathname)} />
    </>
  );
};

export default AuthWrapper;
