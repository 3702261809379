import React, { useEffect, useState } from 'react';

import Dimmer from '../../components/base/Dimmer';
import FormPanel from '../../components/unauth/FormPanel';
import SloganPanel from '../../components/unauth/SloganPanel';
import UnauthPanel from '../../components/unauth/UnauthPanel';
import CustomerAPI from '../../services/api/customer';
import useTr from '../../utils/hooks/useTr';
import useURLQuery from '../../utils/hooks/useURLQuery';

const ActivationMailPage = (): React.ReactElement => {
  const tr = useTr();
  const { email } = useURLQuery();
  const [message, setMessage] = useState(tr('activation.default_message'));
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (email) {
      setLoading(true);
      CustomerAPI.verifyEmail(email).then(response => {
        if (response.status) {
          setMessage(response.message);
        }
        setLoading(false);
      });
    }
  }, [email]);

  return (
    <UnauthPanel>
      <SloganPanel />

      <FormPanel>
        <Dimmer loader active={loading} className="text-center">
          <h4>{message}</h4>
        </Dimmer>
      </FormPanel>
    </UnauthPanel>
  );
};

export default ActivationMailPage;
