import { motion, Transition } from 'framer-motion';
import React, { forwardRef, useState } from 'react';

type Props = {
  field?: unknown;
  onHover?: (field: unknown) => void;
} & React.ComponentPropsWithoutRef<typeof motion.rect>;

const defaultTransition: Transition = { type: 'spring', damping: 26, stiffness: 170 };
const Bar = forwardRef<SVGGElement, Props>(
  // eslint-disable-next-line react/prop-types
  ({ onHover, field, transition, children, ...props }, ref) => {
    const [isHovered, setHover] = useState(false);

    return (
      <g
        ref={ref}
        onMouseEnter={() => {
          setHover(true);
          if (onHover && field) {
            onHover(field);
          }
        }}
        onMouseLeave={() => setHover(false)}
      >
        {/* eslint-disable-next-line react/jsx-no-useless-fragment */}
        <>
          <motion.rect {...props} transition={transition || defaultTransition} />
          <motion.rect
            {...props}
            transition={transition || defaultTransition}
            className="no-export"
            fill="url(#diagonalHatch)"
            display={isHovered ? 'inline' : 'none'}
          />
          {children}
        </>
      </g>
    );
  }
);

export default Bar;
