import { Divider, Paper, Typography } from '@mui/material';
import { ColoredTypography } from 'components/ColoredTypography';
import FlexContainer from 'components/FlexContainer';
import PlanConfirmButton from 'components/profile/components/pricing-plan-card/PlanConfirmButton';
import { usePlanConfirmMutation } from 'components/profile/components/pricing-plan-card/usePlanConfirmMutation';
import FormattedMessage from 'features/i18n/FormattedMessage';
import { getCurrentLanguage } from 'features/language/store/selectors';
import useSubscriptionState from 'features/pricing/hook/useSubscriptionState';
import { createContext, useContext, useMemo } from 'react';
import { ApiPricingType, BOACLPricing } from 'services/api/pricing/types';
import { useAppSelector } from 'store/hooks';
import styled from 'styled-components';
import { forceNonNullable } from 'utils/typescript/nonNullable';

const PlanCardRoot = styled(Paper).attrs({ elevation: 0 })`
  display: grid;
  justify-items: center;
  align-content: space-between;
  gap: ${({ theme }) => theme.spacings.medium};
  background: ${({ theme }) => theme.colors.textTile};
  padding: ${({ theme }) => theme.spacings.medium};
`;

const PlanContext = createContext<BOACLPricing | null>(null);

function usePlan() {
  return forceNonNullable(useContext(PlanContext), 'Plan not provided');
}

function PlanName() {
  const { name } = usePlan();
  return (
    <Typography variant="h4" align="center">
      {name}
    </Typography>
  );
}

function usePlanPriceFormatter() {
  const lang = useAppSelector(getCurrentLanguage);
  return useMemo(
    () =>
      new Intl.NumberFormat(lang, { style: 'currency', currency: 'EUR', maximumFractionDigits: 0 }),
    [lang]
  );
}

function PlanPrice() {
  const { cost } = usePlan();
  const priceFormatter = usePlanPriceFormatter();
  return (
    <div>
      <ColoredTypography color="blackMediumEmphasis" variant="body1" component="div" align="center">
        <FormattedMessage
          id="api_dashboard.pricing.plan.monthly_cost"
          values={{
            cost: (
              <Typography variant="h5" component="span">
                {priceFormatter.format(Number(cost))}*
              </Typography>
            )
          }}
        />
      </ColoredTypography>
      <ColoredTypography
        color="blackMediumEmphasis"
        variant="caption"
        component="div"
        align="center"
      >
        <FormattedMessage id="api_dashboard.pricing.plan.cancelable" />
      </ColoredTypography>
    </div>
  );
}

type PlanBenefits = {
  includedTokens: number;
  tokenPrice: number;
};

type BenefitsMap = {
  [K in ApiPricingType]: PlanBenefits;
} & {
  [K in string]?: PlanBenefits;
};

/**
 * Currently benefits are hardcoded here because it's easier that way.
 * If we would ever need a flexible solution it should be provided by BO.
 */
const benefitsMap: BenefitsMap = {
  [ApiPricingType.Flex]: {
    includedTokens: 700_000,
    tokenPrice: 0.0002
  },
  [ApiPricingType.Light]: {
    includedTokens: 1_700_000,
    tokenPrice: 0.00015
  },
  [ApiPricingType.Medium]: {
    includedTokens: 3_400_000,
    tokenPrice: 0.0001
  },
  [ApiPricingType.Advanced]: {
    includedTokens: 7_000_000,
    tokenPrice: 0.00008
  }
};

function useTokenPriceFormatter() {
  const lang = useAppSelector(getCurrentLanguage);
  return useMemo(
    () =>
      new Intl.NumberFormat(lang, {
        style: 'currency',
        currency: 'EUR',
        maximumSignificantDigits: 2
      }),
    [lang]
  );
}

function useTokenCountFormatter() {
  const lang = useAppSelector(getCurrentLanguage);
  return useMemo(
    () =>
      new Intl.NumberFormat(lang, {
        maximumFractionDigits: 0
      }),
    [lang]
  );
}

function PlanBenefits() {
  const { type } = usePlan();
  const priceFormatter = useTokenPriceFormatter();
  const countFormatter = useTokenCountFormatter();

  const benefits = benefitsMap[type];
  if (!benefits) return null;
  return (
    <FlexContainer gap="medium">
      <div>
        <Typography variant="subtitle1" align="center">
          <FormattedMessage id="api_dashboard.pricing.plan.benefits.includes" />
        </Typography>
        <Typography variant="body1" align="center">
          <FormattedMessage
            id="api_dashboard.pricing.plan.benefits.monthly_tokens"
            values={{ count: countFormatter.format(benefits.includedTokens) }}
          />
        </Typography>
      </div>
      <div>
        <Typography variant="subtitle1" align="center">
          <FormattedMessage id="api_dashboard.pricing.plan.benefits.afterwards" />
        </Typography>
        <Typography variant="body1" align="center">
          <FormattedMessage
            id="api_dashboard.pricing.plan.benefits.token_cost"
            values={{
              cost: priceFormatter.format(benefits.tokenPrice)
            }}
          />
        </Typography>
      </div>
    </FlexContainer>
  );
}

function PlanSelectButton() {
  const pricingPlan = usePlan();
  const selectPlanMutation = usePlanConfirmMutation();
  const { cardBrand, cardLastFour } = useAppSelector(state => state.customer);

  const isBillingInfoPresent = cardBrand !== null && cardLastFour !== null;
  const { currentPricingPlan, currentSubscriptionState: subscriptionState } =
    useSubscriptionState();

  if (!subscriptionState) {
    return null;
  }

  const handlePlanConfirmClick = () => {
    selectPlanMutation.mutate({ pricingId: pricingPlan.id, isActivePlan, subscriptionState });
  };

  const isActivePlan = currentPricingPlan.id === pricingPlan.id;

  return (
    <PlanConfirmButton
      isBillingInfoPresent={isBillingInfoPresent}
      isActivePlan={isActivePlan}
      isLoading={selectPlanMutation.isLoading}
      subscriptionState={subscriptionState}
      isFreePlan={false}
      onCtaClick={handlePlanConfirmClick}
      isTrial={!!pricingPlan.is_trial}
    />
  );
}

export function PlanCard(props: { pricingPlan: BOACLPricing }) {
  const { pricingPlan } = props;
  return (
    <PlanCardRoot>
      <PlanContext.Provider value={pricingPlan}>
        <PlanName />
        <PlanPrice />
        <Divider sx={{ justifySelf: 'stretch' }} />
        <PlanBenefits />
        <PlanSelectButton />
      </PlanContext.Provider>
    </PlanCardRoot>
  );
}
