import LocalStorageKey from 'config/localStorageKey';
import { ModelMapper } from 'services/api/embeddingModels/types';

const getMapperInitValue = (mappers: ModelMapper[]) => {
  if (mappers.length === 0) {
    return undefined;
  }

  if (mappers.length === 1) {
    return mappers[0].name;
  }

  const savedMapper = localStorage.getItem(LocalStorageKey.TesterMapper);
  const prevMapper = mappers.find(m => m.name === savedMapper)?.name;

  if (prevMapper) {
    return prevMapper;
  }

  const genericMapper = mappers.find(m => m.isGeneric)?.name;

  if (genericMapper) {
    return genericMapper;
  }

  return mappers[0].name;
};

export default getMapperInitValue;
