import { RunnerModularWorkflow } from 'features/modular-workflow/runner/types-runner';
import { assertNonNullable } from 'utils/typescript/nonNullable';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

type ModulareWorkflowCreationStoreState = {
  isSaving: boolean;
  selectedStepIndex: number;
  workflow: RunnerModularWorkflow | null;
  runningWorkflow: RunnerModularWorkflow | null;
};

type ModulareWorkflowCreationStoreActions = {
  reset: () => void;
  setWorkflow: (workflow: RunnerModularWorkflow) => void;
  setRunningWorkflow: (workflow: RunnerModularWorkflow) => void;
  updateRunningWorkflow: (workflow: Partial<RunnerModularWorkflow>) => void;
  updateRunningWorkflowStep: (
    step: RunnerModularWorkflow['steps'][number],
    stepIndex: number
  ) => void;
  updateRunningWorkflowStepStatus: (
    stepIndex: number,
    newStatus: RunnerModularWorkflow['steps'][number]['status']
  ) => void;
  setSelectedStepIndex: (stepIndex: number) => void;
};

const initialState: ModulareWorkflowCreationStoreState = {
  workflow: null,
  runningWorkflow: null,
  selectedStepIndex: 0,
  isSaving: false
};

export const useModulareWorkflowRunnerStore = create<
  ModulareWorkflowCreationStoreState & ModulareWorkflowCreationStoreActions
>()(
  devtools(set => ({
    reset: () => set(initialState),
    setWorkflow: (workflow: RunnerModularWorkflow) => set({ workflow: workflow }),
    setRunningWorkflow: (workflow: RunnerModularWorkflow) => set({ runningWorkflow: workflow }),
    updateRunningWorkflow: workflow =>
      set(state => {
        assertNonNullable(state.runningWorkflow, 'workflow must be set first');

        return {
          runningWorkflow: {
            ...state.runningWorkflow,
            ...workflow
          }
        };
      }),
    updateRunningWorkflowStep: (step, stepIndex) =>
      set(state => {
        assertNonNullable(state.runningWorkflow, 'workflow must be set first');

        return {
          runningWorkflow: {
            ...state.runningWorkflow,
            steps: [
              ...state.runningWorkflow.steps.slice(0, stepIndex),
              { ...step },
              ...state.runningWorkflow.steps.slice(stepIndex + 1)
            ]
          }
        };
      }),
    updateRunningWorkflowStepStatus: (stepIndex, newStatus) =>
      set(state => {
        assertNonNullable(state.runningWorkflow, 'workflow must be set first');

        const updatedWorkflow = { ...state.runningWorkflow };
        updatedWorkflow.steps[stepIndex].status = newStatus;

        return {
          runningWorkflow: {
            ...updatedWorkflow
          }
        };
      }),
    setSelectedStepIndex: (stepIndex: number) => set({ selectedStepIndex: stepIndex }),
    ...initialState
  }))
);

export const useRunningWorkflowSelectedStep = () => {
  const selectedStepIndex = useModulareWorkflowRunnerStore(state => state.selectedStepIndex);
  const runningWorkflow = useModulareWorkflowRunnerStore(state => state.runningWorkflow);

  return runningWorkflow?.steps[selectedStepIndex];
};
