import AxiosClient from '..';
import {
  CreateFavoriteRequest,
  CreateFavoriteResponse,
  DeleteFavoriteResponse,
  GetFavoritesResponse,
} from './types';

const FavoritesAPI = {
  create: (payload: CreateFavoriteRequest) =>
    AxiosClient.post<CreateFavoriteRequest, CreateFavoriteResponse>('/projects/favorites', payload),
  delete: (id: string) =>
    AxiosClient.delete<unknown, DeleteFavoriteResponse>(`/projects/favorites/${id}`),
  get: () => AxiosClient.get<unknown, GetFavoritesResponse>('/projects/favorites'),
};

export default FavoritesAPI;
