import { Button } from '@mui/material';
import FlexContainer from 'components/FlexContainer';
import EmailField from 'components/forms/customFields/EmailField';
import { getRoutePath } from 'config/routes';
import { passwordRecoverySuccess } from 'features/customer/store/actions';
import { Form, Formik, FormikHelpers } from 'formik';
import React, { useCallback, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router';
import CustomerAPI from 'services/api/customer';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import styled from 'styled-components';
import { getValidationForField } from 'utils/getValidationForField';
import useTr from 'utils/hooks/useTr';
import { object, string } from 'yup';

const emailField = 'email';

type ForgotPasswordValues = {
  [emailField]: string;
};

const initialValues: ForgotPasswordValues = {
  [emailField]: ''
};

const ForgotPasswordForm = (): React.ReactElement => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const tr = useTr();

  const isLoading = useAppSelector(state => state.customer.isLoading);
  const goToLoginPage = useCallback(() => navigate(getRoutePath('login')), [navigate]);

  const onSubmit = useCallback(
    (
      { email }: ForgotPasswordValues,
      { setSubmitting, setErrors }: FormikHelpers<ForgotPasswordValues>
    ) => {
      CustomerAPI.recoverPassword(email).then(response => {
        if (response.status) {
          dispatch(passwordRecoverySuccess(response.message));
          goToLoginPage();
        } else {
          setSubmitting(false);
          setErrors({ [emailField]: response.data.message });
        }
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [dispatch, goToLoginPage]
  );

  const validationSchema = useMemo(
    () =>
      object().shape({
        [emailField]: string()
          .trim()
          .email()
          .required(tr(getValidationForField('email')))
      }),
    [tr]
  );

  return (
    <Formik
      validateOnChange={false}
      validateOnBlur={false}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {() => (
        <Form>
          <Content gap="medium" alignItems="center">
            <EmailField name={emailField} />
            <Button
              type="submit"
              variant="contained"
              size="large"
              color="primary"
              disabled={isLoading}
            >
              <FormattedMessage id="forgot_password.send" />
            </Button>
          </Content>
        </Form>
      )}
    </Formik>
  );
};

const Content = styled(FlexContainer)``;

export default ForgotPasswordForm;
