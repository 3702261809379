import cx from 'classnames';
import { Placement } from 'popper.js';
import React from 'react';
import { Popper } from 'react-popper';

import DropdownArrow from './DropdownArrow';

type Props = {
  className?: string;
  position?: Placement;
  arrow?: boolean;
  show?: boolean;
  offset?: number;
  clickOutsideRef?: React.RefObject<HTMLDivElement>;
  children: React.ReactNode;
};

const DropdownMenu = ({
  className,
  arrow,
  position = 'bottom-end',
  show = false,
  offset = arrow ? 5 : 2,
  clickOutsideRef,
  children
}: Props) => {
  const classes = cx(
    {
      'dropdown-menu': true,
      show
    },
    className
  );

  return show ? (
    <Popper
      placement={position}
      modifiers={[
        {
          name: 'offset',
          options: {
            offset: [offset, 0]
          }
        }
      ]}
    >
      {({ ref, style, placement, arrowProps }) => {
        return (
          <div className={classes} data-placement={placement} style={style} ref={ref}>
            {arrow && <DropdownArrow placement={placement} {...arrowProps} />}
            <div ref={clickOutsideRef}>{children}</div>
          </div>
        );
      }}
    </Popper>
  ) : null;
};

export default DropdownMenu;
