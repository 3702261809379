import {
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { useCallback } from 'react';
import {
  Cell,
  Column,
  Row,
  TableInstance,
  usePagination,
  useRowSelect,
  useTable
} from 'react-table';

const useRowStyles = makeStyles({
  hover: {
    cursor: 'pointer'
  }
});

type Props<TData extends object> = {
  data: TData[];
  columns: Array<Column<TData>>;
  rowsPerPage?: number;
  rowsPerPageOptions?: number[];
  rowEvents?: {
    onClick?: (row: Row<TData>) => void;
  };
  topTableActions?: (instance: TableInstance<TData>) => React.ReactElement;
};

function MaterialTable<TData extends object>({
  columns,
  data,
  rowsPerPage = 5,
  rowsPerPageOptions = [5, 10, 20],
  rowEvents = {},
  topTableActions
}: Props<TData>) {
  const rowClasses = useRowStyles();

  const instance = useTable(
    {
      columns,
      data,
      initialState: { pageSize: rowsPerPage }
    },
    usePagination,
    useRowSelect
  );

  const { getTableProps, headerGroups, page, prepareRow, gotoPage, state, setPageSize } = instance;

  const { pageIndex, pageSize } = state;

  const handlePageChange = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    gotoPage(newPage);
  };

  const handleRowsPerChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setPageSize(parseInt(event.target.value, 10));
  };

  const onRowClick = useCallback(
    (row: Row<TData>) => rowEvents.onClick && rowEvents.onClick(row),
    [rowEvents]
  );

  return (
    <div>
      {topTableActions?.(instance)}
      <Table {...getTableProps()}>
        <TableHead>
          {headerGroups.map(headerGroup => (
            // eslint-disable-next-line react/jsx-key
            <TableRow {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                // eslint-disable-next-line react/jsx-key
                <TableCell {...column.getHeaderProps({ style: column.headerStyles })}>
                  {column.render('Header')}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableHead>
        <TableBody>
          {page.map(row => {
            prepareRow(row);
            return (
              // eslint-disable-next-line react/jsx-key
              <TableRow
                {...row.getRowProps()}
                classes={rowClasses}
                hover={!!rowEvents?.onClick}
                onClick={() => onRowClick(row)}
              >
                {row.cells.map((cell: Cell<TData>) => (
                  // eslint-disable-next-line react/jsx-key
                  <TableCell
                    {...cell.getCellProps({
                      style: cell.column.cellStyles && cell.column.cellStyles(cell)
                    })}
                  >
                    {cell.render('Cell')}
                  </TableCell>
                ))}
              </TableRow>
            );
          })}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              count={data.length}
              onPageChange={handlePageChange}
              page={pageIndex}
              rowsPerPage={pageSize}
              rowsPerPageOptions={rowsPerPageOptions}
              onRowsPerPageChange={handleRowsPerChange}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </div>
  );
}

export default MaterialTable;
