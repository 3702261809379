import { AppThunk } from 'store/store';

import { selectSuggestion } from '../../suggestions/actions';
import { checkIfTextLimitIsAchievedThunk } from '../../texts/thunks/checkIfTextLimitIsAchieved';
import { updateTextThunk } from '../../texts/thunks/updateTextThunk';
import { removeSubSuggestion } from '../actions';
import { SelectSubSuggestionPayload } from '../types';

export const selectSubSuggestionThunk =
  (payload: SelectSubSuggestionPayload): AppThunk<void> =>
  async dispatch => {
    dispatch(selectSuggestion(payload));
    dispatch(removeSubSuggestion({ ...payload, triggerSource: 'system' }));
    dispatch(updateTextThunk(payload));
    dispatch(checkIfTextLimitIsAchievedThunk());
  };
