import { ReactElement, ReactNode } from 'react';
import isEqual from 'react-fast-compare';
import { WordAttributeWithOption } from 'types/ReduxStateTypes';

import { ButtonRadioField } from '../forms/ButtonRadioField';

interface Props {
  values: WordAttributeWithOption[];
  object: WordAttributeWithOption;
  push: (obj: WordAttributeWithOption) => void;
  remove<WordAttributeWithOption>(index: number): WordAttributeWithOption | undefined;
  isDisabled?: boolean;
  label: ReactNode;
  avatar?: ReactElement;
}

export const WordAttributeRadio = ({
  avatar,
  label,
  values,
  push,
  remove,
  isDisabled,
  object: { value, option }
}: Props) => {
  return (
    <ButtonRadioField
      selected={
        !!values.find((el: WordAttributeWithOption) => {
          return el.value === value && el.option?.value === option?.value;
        })
      }
      onClick={ref => {
        const currentObjectIdx = values.findIndex(
          (el: WordAttributeWithOption) => el.value === value
        );
        const currentObject = values[currentObjectIdx];
        if (currentObjectIdx !== -1) {
          remove(currentObjectIdx);
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          ref.current!.blur();
          if (!isEqual(currentObject.option, option)) {
            push({ value, option });
          }
        } else {
          push({ value, option });
        }
      }}
      isDisabled={isDisabled}
      label={label}
      avatar={avatar}
    />
  );
};
