import { ENode, PlateEditor, Value } from '@udecode/plate';
import { getNodeEntry } from '@udecode/slate';
import { BasePoint, BaseRange, Path } from 'slate';
import { Text } from 'slate/dist/interfaces/text';

const isTextNode = (node: Path | ENode<Value>): node is Text =>
  typeof node === 'object' && 'text' in node;

const getTextFromLocation = (editor: PlateEditor, location: BaseRange | BasePoint): string[] => {
  const nodeEntry = getNodeEntry(editor, location);
  if (!nodeEntry) return [];
  return nodeEntry.filter(isTextNode).map(node => node.text);
};

export default getTextFromLocation;
