import { useQuery } from '@tanstack/react-query';
import {
  SuggestionType,
  suggestionTypeToOutputTypeMapping
} from 'features/aiWriter/AiWriterSidebar/steps/flashScore/GetSuggestionsButton';
import useEditorText from 'features/aiWriter/AiWriterTextEditor/hooks/useEditorText';
import { getActiveTab } from 'features/aiWriter/store/selectors';
import getAudienceModelFromConfig from 'features/audiences/utils/getAudienceModelFromConfig';
import { makeCurrentUserQueryKeyPrefix } from 'features/currentUser/currentUserQueries';
import { generateTexts } from 'features/textGenerator/actions/generateTexts';
import { useAppSelector } from 'store/hooks';

export const useGenerateTextSuggestion = (suggestionType: SuggestionType | null) => {
  const { generateTextConfig } = useAppSelector(getActiveTab);
  const audienceModelId = useAppSelector(state =>
    getAudienceModelFromConfig(state.audiences, generateTextConfig)
  );

  const editorText = useEditorText('\n');

  return useQuery({
    enabled: !!suggestionType && !!audienceModelId,
    // Keep the suggestion for 1 minute
    staleTime: 1000 * 60,
    queryKey: [...makeCurrentUserQueryKeyPrefix(), 'flash-score-suggestions', suggestionType],
    queryFn: async () => {
      if (!suggestionType || !audienceModelId) {
        return null;
      }

      const result = await generateTexts(
        {
          outputType: suggestionTypeToOutputTypeMapping[suggestionType],
          audienceModelId,
          nTimes: 1,
          nTextItems: 1
        },
        {
          text: editorText
        }
      );
      return result.outputs[0].text;
    }
  });
};
