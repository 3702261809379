import { SharingPermission } from 'features/aiWriter/store/types';
import { BaseSuccessDto } from 'services/backofficeIntegration/http/BaseSuccessDto';
import { backofficeClient } from 'services/backofficeIntegration/http/client/createBackofficeClient';
import { PersonalityDto } from 'services/backofficeIntegration/http/dtos/PersonalityDto';
import { mutationEndpoint } from 'services/backofficeIntegration/http/endpointDefinition';

export type CreatePersonalityParams = {
  is_active: boolean;
  language: string;
  country: string;
  label: string;
  description: string | null;
  definition: string;
  sharing_permission: SharingPermission;
};

type SuccessDto = BaseSuccessDto<PersonalityDto>;

export const httpCreatePersonality = mutationEndpoint({
  callEndpoint: (params: CreatePersonalityParams) =>
    backofficeClient
      .post<SuccessDto>('/chat/personalities', params)
      .then(response => response.data.data)
});
