import { Menu } from '@mui/material';
import FlexContainer from 'components/FlexContainer';
import { TagsColorPicker } from 'features/aiWriter/AiWriterProjectOverview/tags/TagsColorPicker';
import styled from 'styled-components';

type Props = {
  color?: string;
  onClick: (c: string) => void;
  anchorEl: null | HTMLElement;
  isOpen: boolean;
  handleCloseMenu: () => void;
};

export const ColorPickerMenu = ({ color, onClick, anchorEl, isOpen, handleCloseMenu }: Props) => {
  const handleColorChange = (color: string) => {
    onClick(color);
    handleCloseMenu();
  };
  return (
    <Menu
      anchorEl={anchorEl}
      open={isOpen}
      onClose={handleCloseMenu}
      anchorOrigin={{
        vertical: 'center',
        horizontal: 'left'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      PaperProps={{
        style: {
          width: 134,
          maxHeight: 168,
          overflow: 'hidden'
        }
      }}
    >
      <Root>
        <TagsColorPicker color={color} onClick={handleColorChange} />
      </Root>
    </Menu>
  );
};

const Root = styled(FlexContainer)`
  align-items: center;
  justify-content: center;
`;
