import { CommandOutput } from 'features/aiWriter/slashCommands/CommandOutput';
import { CustomFlashActionsOutput } from 'features/aiWriter/slashCommands/CustomFlashActionsOutput';
import { DuSieWechselnOutput } from 'features/aiWriter/slashCommands/DuSieWechselnOutput';
import { ExpandOutput } from 'features/aiWriter/slashCommands/ExpandOutput';
import { FlashActionsInputProps } from 'features/aiWriter/slashCommands/FlashActionsInput';
import { useGetFlashActionsSelectedAction } from 'features/aiWriter/slashCommands/hooks/useFlashActionsStore';
import { ImproveOutput } from 'features/aiWriter/slashCommands/ImproveOutput';
import { RewriteActionOutput } from 'features/aiWriter/slashCommands/RewriteActionOutput';
import { SummarizeOutput } from 'features/aiWriter/slashCommands/SummarizeOutput';
import { TranslateOutput } from 'features/aiWriter/slashCommands/TranslateOutput';

type Props = FlashActionsInputProps;

export function OutputPanel(props: Props) {
  const selectedAction = useGetFlashActionsSelectedAction();

  switch (selectedAction?.name) {
    case 'customActions':
      return <CustomFlashActionsOutput onClose={props.onClose} />;
    case 'rewrite':
      return <RewriteActionOutput onClose={props.onClose} />;
    case 'summarize':
      return <SummarizeOutput onClose={props.onClose} />;
    case 'duSieWechseln':
      return <DuSieWechselnOutput onClose={props.onClose} />;
    case 'translate':
      return <TranslateOutput onClose={props.onClose} />;
    case 'expand':
      return <ExpandOutput onClose={props.onClose} />;
    case 'improve':
      return <ImproveOutput onClose={props.onClose} />;
    case 'command':
      return <CommandOutput onClose={props.onClose} />;
    default:
      return <div>&nbsp;</div>;
  }
}
