import { useMutation } from '@tanstack/react-query';
import { getCurrentLanguage } from 'features/language/store/selectors';
import { RegisterFormValues } from 'features/teamInvitation/NewUserRegisterForm';
import {
  httpTeamInvitationRegister,
  InvitationRegisterParams
} from 'services/backofficeIntegration/http/endpoints/teamMembers/httpTeamInvitationRegister';
import { useAppSelector } from 'store/hooks';
import getTimezone from 'utils/getTimezone';

export const useTeamInvitationRegister = (invitationToken: string) => {
  const locale = useAppSelector(getCurrentLanguage);
  const timezone = getTimezone();

  return useMutation({
    mutationFn: (values: RegisterFormValues) => {
      const params: InvitationRegisterParams = {
        token: invitationToken,
        name: values.name,
        password: values.password,
        timezone,
        locale
      };

      return httpTeamInvitationRegister.callEndpoint(params);
    }
  });
};
