import { useCallback, useState } from 'react';
import { Primitive } from 'utility-types';

export function useList<T extends Primitive>(): [
  T[],
  (item: T) => void,
  (item: T) => void,
  (items: T[]) => void
] {
  const [items, setItems] = useState<T[]>([]);

  const addItem = useCallback((item: T) => setItems(data => data.concat(item)), []);
  const removeItem = useCallback((item: T) => setItems(data => data.filter(i => i !== item)), []);

  return [items, addItem, removeItem, setItems];
}
