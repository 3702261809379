import { EmbeddingModel } from 'features/embeddingModels/store/types';
import { ProjectLocale } from 'features/projects/ProjectLocale';
import getBrowserLanguage from 'utils/getBrowserLanguage';

const getModelInitValue = (
  models: EmbeddingModel[],
  localStorageKey: string,
  preferredLocale?: ProjectLocale
): EmbeddingModel | undefined => {
  const [first] = models;

  if (!first) return undefined;

  if (models.length === 1) {
    return first;
  }

  const savedModel = localStorage.getItem(localStorageKey);
  const prevModel = models.find(m => m.id === savedModel);

  if (prevModel) {
    return prevModel;
  }

  if (preferredLocale) {
    const candidate = models.find(
      m => m.language === preferredLocale.language && m.country === preferredLocale.country
    );
    if (candidate) {
      return candidate;
    }
  }

  const browserLanguage = getBrowserLanguage();

  if (!browserLanguage) {
    return first;
  }

  // There can be multiple models matching the browser language, but they differ in country.
  // Because of arbitrary order of models we were selecting incorrect model in case of
  // German (https://app.clickup.com/t/33826nv).
  // As a workaround we're now searching for a model with the same language and country first.
  // Only if that fails we're matching the language only.
  // This is a quick workaround, and we need a more robust solution in the future.
  // #tech-debt https://app.clickup.com/t/3383x0u
  return (
    models.find(m => m.language === browserLanguage && m.country === browserLanguage) ??
    models.find(m => m.language === browserLanguage) ??
    first
  );
};

export default getModelInitValue;
