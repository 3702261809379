import AsyncCreatableSelect, { AsyncCreatableProps } from 'react-select/async-creatable';
import { GroupBase } from 'react-select/dist/declarations/src/types';

import { selectStyles, selectTheme } from './selectStyles';

const AsyncCreatable = <
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
>(
  props: AsyncCreatableProps<Option, IsMulti, Group>
) => (
  <AsyncCreatableSelect<Option, IsMulti, Group>
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    styles={selectStyles as any}
    theme={selectTheme}
    {...props}
  />
);

export default AsyncCreatable;
