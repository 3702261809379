import { FormControl, FormHelperText, InputLabel, Select, SelectProps } from '@mui/material';
import cuid from 'cuid';
import { FieldValidator, useField } from 'formik';
import { upperFirst } from 'lodash';
import React, { useMemo } from 'react';

import useTr from '../../utils/hooks/useTr';

export type SelectOption<T> = {
  label: string;
  value: T;
};

export type SelectEvent = React.ChangeEvent<{
  name?: string | undefined;
  value: unknown;
}>;

type Props = {
  name: string;
  placeholder?: string;
  helperText?: string;
  translateError?: boolean;
  onSelect?: (value: unknown) => void;
  validate?: FieldValidator;
} & SelectProps;

const SelectField = ({
  name,
  placeholder,
  helperText,
  translateError,
  variant,
  fullWidth,
  children,
  onSelect,
  validate,
  ...rest
}: Props) => {
  const [field, meta] = useField({ name, validate });
  const { error } = meta;

  const tr = useTr();

  const errorMessage = error && translateError ? tr(error) : upperFirst(error);

  const labelId = useMemo(() => cuid(), []);

  return (
    <FormControl variant={variant} fullWidth={fullWidth}>
      {placeholder && <InputLabel htmlFor={labelId}>{placeholder}</InputLabel>}
      <Select
        {...rest}
        {...field}
        labelId={labelId}
        label={placeholder}
        value={field.value}
        onChange={e => {
          field.onChange(e);
          onSelect?.(e.target.value);
        }}
        error={!!error}
        inputProps={{
          id: labelId
        }}
      >
        {children}
      </Select>
      {(errorMessage || helperText) && (
        <FormHelperText error={!!error}>{error ? errorMessage : helperText}</FormHelperText>
      )}
    </FormControl>
  );
};

export default SelectField;
