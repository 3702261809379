import { ArticleRounded, CodeRounded } from '@mui/icons-material';
import { CircularProgress, ListItemIcon, Menu, MenuItem, MenuProps } from '@mui/material';
import { Packer } from 'docx';
import { useDownloadDocument } from 'features/aiWriter/AiWriterWorkspace/header/useDownloadDocument';
import { getActiveTab } from 'features/aiWriter/store/selectors';
import { createDownloadFilename } from 'features/aiWriter/utils/createDownloadFilename';
import { getDocumentAsDocx } from 'features/aiWriter/utils/getDocumentAsDocx';
import { getDocumentAsHtml } from 'features/aiWriter/utils/getDocumentAsHtml';
import { getUserId } from 'features/customer/store/selectors';
import FormattedMessage from 'features/i18n/FormattedMessage';
import { trackingWrapper } from 'features/tracking/wrapper/TrackingWrapper';
import saveAs from 'file-saver';
import { useState } from 'react';
import gtmIds from 'services/tracking/GTMIds';
import { withGtmInteraction } from 'services/tracking/withGtmInteraction';
import { useAppSelector } from 'store/hooks';
import { triggerDownload } from 'utils/triggerDownload';

type Props = MenuProps;

export const DownloadMenu = (props: Props) => {
  const userId = useAppSelector(getUserId);
  const activeTab = useAppSelector(getActiveTab);
  const [downloadStarted, setDownloadStarted] = useState<'html' | 'pdf' | 'docx' | null>(null);

  const { mutate: downloadAsPdf, isLoading } = useDownloadDocument();

  const handleDownloadAsPdf = async () => {
    setDownloadStarted('pdf');
    const htmlFileContent = getDocumentAsHtml(activeTab);

    const { withoutExtension: documentName, withExtension: downloadFilename } =
      createDownloadFilename(activeTab, 'pdf');

    downloadAsPdf(
      {
        content: htmlFileContent,
        format: 'pdf',
        title: documentName
      },
      {
        onSuccess: async data => {
          const downloadUrl = URL.createObjectURL(data);

          trackingWrapper.track('aiWriterDocumentDownload', {
            documentId: activeTab.id,
            userId,
            type: 'PDF',
            title: downloadFilename
          });

          triggerDownload(downloadUrl, downloadFilename);
          setDownloadStarted(null);
        },
        onError: () => {
          setDownloadStarted(null);
        }
      }
    );
  };

  const handleDownloadAsHtml = () => {
    setDownloadStarted('html');

    const htmlFileContent = getDocumentAsHtml(activeTab);
    const fileBlob = new Blob([htmlFileContent], { type: 'text/html' });

    const downloadUrl = URL.createObjectURL(fileBlob);
    const { withExtension: downloadFilename } = createDownloadFilename(activeTab, 'html');

    trackingWrapper.track('aiWriterDocumentDownload', {
      documentId: activeTab.id,
      userId,
      type: 'HTML',
      title: downloadFilename
    });

    triggerDownload(downloadUrl, downloadFilename);
    setDownloadStarted(null);
  };

  const handleDownloadAsDocx = async () => {
    setDownloadStarted('docx');

    const { doc, fileName } = await getDocumentAsDocx(activeTab);

    const blob = await Packer.toBlob(doc);
    saveAs(blob, fileName);

    setDownloadStarted(null);
  };

  return (
    <Menu {...props}>
      <MenuItem
        component="a"
        {...withGtmInteraction(gtmIds.aiWriter.share.exportHTML)}
        onClick={handleDownloadAsHtml}
        disabled={isLoading}
      >
        <ListItemIcon>
          {downloadStarted === 'html' ? <CircularProgress size={16} /> : <CodeRounded />}
        </ListItemIcon>

        <FormattedMessage id="aiWriter.topbar.download_button.html" />
      </MenuItem>
      <MenuItem
        component="a"
        {...withGtmInteraction(gtmIds.aiWriter.download.pdf)}
        onClick={handleDownloadAsPdf}
        disabled={isLoading}
      >
        <ListItemIcon>
          {downloadStarted === 'pdf' ? <CircularProgress size={16} /> : <ArticleRounded />}
        </ListItemIcon>

        <FormattedMessage id="aiWriter.topbar.download_button.pdf" />
      </MenuItem>
      <MenuItem
        component="a"
        {...withGtmInteraction(gtmIds.aiWriter.download.docx)}
        onClick={handleDownloadAsDocx}
        disabled={isLoading}
      >
        <ListItemIcon>
          {downloadStarted === 'docx' ? <CircularProgress size={16} /> : <ArticleRounded />}
        </ListItemIcon>

        <FormattedMessage id="aiWriter.topbar.download_button.docx" />
      </MenuItem>
    </Menu>
  );
};
