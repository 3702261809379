import { AiWriterState } from 'features/aiWriter/store/types';

export const defaultAiWriterState: AiWriterState = {
  projects: [],
  currentTab: '',
  tabs: {},
  inspirationsCurrentStep: undefined,
  favorites: [],
  history: [],
  isLoading: false,
  isAiWriterTourFinished: false,
  // #tech-debt https://app.clickup.com/t/862jjp0bb
  isUserOnboarded: false,
  isBlogPostBuilderStarted: false,
  isInspirationsPanelDisabled: true,
  selection: undefined,
  builder: {
    blogPost: {
      title: '',
      outline: '',
      language: undefined
    },
    socialPost: {
      language: undefined
    }
  }
};
