import { useAppDispatch } from 'store/hooks';

import CampaignConfigForm, {
  CampaignConfigFormPayload
} from './CampaignConfigForm/CampaignConfigForm';
import { createCampaign } from './store/slice';
import { prepareNewCampaign } from './store/utils';

const NewCampaignForm = () => {
  const dispatch = useAppDispatch();

  const initialValues: CampaignConfigFormPayload = {
    name: '',
    projectId: 0,
    suggestions: [],
    deletedSuggestions: [],
    subjectLines: [],
    keywords: '',
    emailBody: ''
  };

  const handleSubmit = async (values: CampaignConfigFormPayload) => {
    const campaign = prepareNewCampaign(values);
    await dispatch(createCampaign.request(campaign));
  };

  return <CampaignConfigForm initialValues={initialValues} handleSubmit={handleSubmit} />;
};

export default NewCampaignForm;
