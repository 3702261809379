import { AiWriterProjectTagType } from 'services/api/aiWriter/types';
import { BaseSuccessDto } from 'services/backofficeIntegration/http/BaseSuccessDto';
import { backofficeClient } from 'services/backofficeIntegration/http/client/createBackofficeClient';
import { mutationEndpoint } from 'services/backofficeIntegration/http/endpointDefinition';

type SuccessDto = BaseSuccessDto<AiWriterProjectTagType>;

export const TagNameNotUniqueError = 'Name is not unique';
export const TagNameTooLong = 'The name may not be greater than 50 characters.';
export const MAX_TAG_NAME_LENGTH = 50;

export type CreateTagRequestParams = {
  name: string;
  color: string;
  ai_writer_project_id?: string;
};

export const httpCreateTag = mutationEndpoint({
  callEndpoint: (params: CreateTagRequestParams) =>
    backofficeClient.post<SuccessDto>('/tags', params).then(response => response.data)
});
