import { GroupMemberResponse, WeMapperResponse } from 'services/api/customer/types';
import { ModelMapper } from 'services/api/embeddingModels/types';
import { WordAttribute } from 'types/ReduxStateTypes';
import { booleanFromApi } from 'utils/fromApi';

import { GroupMember, WeMapper } from './types';

export const groupMemberFromApi = (r: GroupMemberResponse): GroupMember => ({
  id: r.id,
  name: r.name,
  lastLoggedIn: r.last_login,
  picture: r.image_url,
  timezone: r.timezone
});

export const weMapperFromApi = (r: WeMapperResponse): WeMapper => ({
  id: r.id,
  country: r.country ?? undefined,
  language: r.language,
  label: r.label,
  friendlyName: r.friendly_name,
  friendlyCountry: r.friendly_country,
  friendlyLanguage: r.friendly_language,
  isGeneric: booleanFromApi(r.is_generic),
  variables: r.variables as WordAttribute[]
});

export const modelMapperFromWeMapper = (weMapper: WeMapper): ModelMapper => ({
  name: weMapper.id,
  friendly_name: weMapper.friendlyName,
  label: weMapper.label,
  variables: weMapper.variables,
  isGeneric: weMapper.isGeneric
});
