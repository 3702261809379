import { Typography } from '@mui/material';
import { useFlashScoreSidebarState } from 'features/aiWriter/AiWriterSidebar/steps/flashScore/useFlashScoreSidebarState';
import { FlashScoreTextRating } from 'features/flashScore/useScoreTexts';
import FormattedMessage from 'features/i18n/FormattedMessage';
import { ComponentProps, forwardRef, MouseEvent } from 'react';
import styled from 'styled-components';

type Props = Omit<ComponentProps<typeof Typography>, 'fontSize'> & {
  rating: FlashScoreTextRating;
};

export const ClickableScoreLabel = forwardRef<HTMLSpanElement, Props>(
  ({ rating, ...props }, ref) => {
    const showScoreLabel = useFlashScoreSidebarState(state => state.showScoreLabel);
    const toggleShowScoreLabel = useFlashScoreSidebarState(state => state.toggleShowScoreLabel);

    const handleClick = (event: MouseEvent<HTMLSpanElement>) => {
      toggleShowScoreLabel();

      props.onClick?.(event);
    };

    return (
      <ClickableLabel
        ref={ref}
        fontSize={showScoreLabel ? 16 : 20}
        {...props}
        onClick={handleClick}
      >
        {showScoreLabel ? <FormattedMessage id={rating.label} /> : Math.floor(rating.percentage)}
      </ClickableLabel>
    );
  }
);

const ClickableLabel = styled(Typography)`
  cursor: pointer;
`;
