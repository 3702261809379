export const triggerDownload = (downloadUrl: string, filename: string) => {
  const a = document.createElement('a');
  // safari doesn't support this yet
  if (typeof a.download === 'undefined') {
    window.location.href = downloadUrl;
  } else {
    a.href = downloadUrl;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
  }

  setTimeout(() => {
    URL.revokeObjectURL(downloadUrl);
  }, 100); // Cleanup URL from memory
};
