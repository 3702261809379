import { backofficeClient } from 'services/backofficeIntegration/http/client/createBackofficeClient';
import { PersonalityDto } from 'services/backofficeIntegration/http/dtos/PersonalityDto';
import { queryEndpoint } from 'services/backofficeIntegration/http/endpointDefinition';
import {
  PaginatedListParams,
  PaginatedListSuccessDto
} from 'services/backofficeIntegration/http/paginatedListEndpoint';
import { personalitiesQueryKeys } from 'services/backofficeIntegration/http/queryKeyFactories/personalitiesQueryKeys';
import { queryClient } from 'utils/ReactQueryClient';

export type GetPersonalitiesSuccessDto = PaginatedListSuccessDto<PersonalityDto>;

export type GetPersonalitiesParams = PaginatedListParams & {
  language?: string;
  country?: string;
  search?: string;
  isPaginated?: number;
  author_id?: number;
  sort?: 'updated_at' | 'usage' | 'most_recent';
};

export const httpGetPersonalities = queryEndpoint({
  makeQueryKey: (params: GetPersonalitiesParams) => {
    return [...personalitiesQueryKeys.forList(), params] as const;
  },
  callEndpoint: (params: GetPersonalitiesParams) => {
    return backofficeClient
      .get<GetPersonalitiesSuccessDto>('/chat/personalities', {
        params: {
          language: params.language,
          country: params.country,
          limit: params.limit,
          offset: params.offset,
          search: params.search,
          is_paginated: params.isPaginated,
          author_id: params.author_id,
          sort: params.sort
        }
      })
      .then(response => response.data);
  }
});

export function invalidatePersonalitiesQuery() {
  return queryClient.invalidateQueries({
    queryKey: [...personalitiesQueryKeys.forList()]
  });
}
