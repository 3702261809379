import { TokenizerResponse } from 'services/api/wordEmbedding/types';
import { backofficeClient } from 'services/backofficeIntegration/http/client/createBackofficeClient';
import { mutationEndpoint } from 'services/backofficeIntegration/http/endpointDefinition';

type TokenizePayload = {
  text: string;
  language: string;
};

export const httpTokenizeText = mutationEndpoint({
  callEndpoint: (params: TokenizePayload) =>
    backofficeClient
      .post<TokenizerResponse>('/utility-methods/tokenize', params)
      .then(response => response.data)
});
