import { getTesterActiveTab } from 'features/aiTester/store/selectors';
import { AppThunk } from 'store/store';

import { sortByScore } from '../../general/actions';
import { setSortByScore } from '../actions';

export const setSortByScoreThunk =
  (payload: boolean): AppThunk<void> =>
  async (dispatch, getState) => {
    const state = getState();
    const { sortByScore: prevSortByScoreValue } = getTesterActiveTab(state);

    dispatch(setSortByScore(payload));

    if (!prevSortByScoreValue) {
      dispatch(sortByScore());
    }
  };
