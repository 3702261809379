import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import Tooltip from '../tooltips/Tooltip';

type Props = {
  tooltip: string;
};

const Icon = styled.i.attrs(() => ({
  className: 'fe fe-help-circle'
}))`
  color: #9aa0ac;
  margin-left: 0.25rem;
  cursor: default;
  vertical-align: -0.125rem;

  &:hover {
    color: #495057;
  }
`;

const HelpIcon = ({ tooltip }: Props) => {
  return (
    <span className="position-relative">
      <Tooltip content={<FormattedMessage id={tooltip} />}>
        <Icon />
      </Tooltip>
    </span>
  );
};

export default HelpIcon;
