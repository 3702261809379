import { Box } from '@mui/material';
import { useModal } from 'components/modals';
import ConfirmModal from 'components/modals/ConfirmModal';
import { CloseModal } from 'components/modals/types';
import { FormattedMessage } from 'react-intl';

type CustomProps = {
  onScan: () => void;
};

type Props = CloseModal & CustomProps;

const PlagiarismRescanModal = ({ closeModal, onScan }: Props) => {
  const handleScan = () => {
    onScan();
    closeModal();
  };

  return (
    <ConfirmModal
      message="aiWriter.inspirations.plagiarism.newScan.body2"
      title="aiWriter.inspirations.plagiarism.newScan.title"
      onCancel={closeModal}
      cancelText="common.cancel"
      okText="aiWriter.inspirations.plagiarism.newScan.confirm"
      onOK={handleScan}
    >
      <Box mb={3}>
        <FormattedMessage id="aiWriter.inspirations.plagiarism.newScan.body1" />
      </Box>
    </ConfirmModal>
  );
};

export const useShowPlagiarismRescanModal = () => {
  const { showModal } = useModal();

  return (props: CustomProps) => {
    showModal('PLAGIARISM_RESCAN', {
      ...props,
      size: 480
    });
  };
};

export default PlagiarismRescanModal;
