import TextInputField from 'components/forms/TextInputField';
import { FieldValidator } from 'formik';
import { ComponentProps } from 'react';
import useTr from 'utils/hooks/useTr';

type Props = ComponentProps<typeof TextInputField> & {
  name: string;
  validate?: FieldValidator;
  fullWidth?: boolean;
  label?: string;
};

const EmailField = ({
  name,
  validate,
  variant = 'outlined',
  fullWidth = true,
  label = 'common.form.field.email.label',
  className,
  ...rest
}: Props) => {
  const tr = useTr();

  return (
    <TextInputField
      name={name}
      variant={variant}
      fullWidth={fullWidth}
      placeholder={tr(label)}
      label={tr(label)}
      validate={validate}
      className={className}
      {...rest}
    />
  );
};

export default EmailField;
