import { Typography } from '@mui/material';
import FlexContainer from 'components/FlexContainer';
import { getRoutePath } from 'config/routes';
import FormattedMessage from 'features/i18n/FormattedMessage';
import { Link } from 'react-router-dom';

export const SuccessfulUsersInvitationHint = () => {
  return (
    <FlexContainer direction="col" gap="small">
      <Typography variant="subtitle1">
        <FormattedMessage id="profile.users.users_list.successful_invitation.header" />
      </Typography>
      <Typography variant="caption">
        <FormattedMessage
          id="profile.users.users_list.successful_invitation.description"
          values={{
            a: (chunks: string) => <Link to={getRoutePath('profileBilling')}>{chunks}</Link>
          }}
        />
      </Typography>
    </FlexContainer>
  );
};
