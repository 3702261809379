import FlexContainer from 'components/FlexContainer';
import { ButtonRadioField } from 'components/forms/ButtonRadioField';
import HideableFieldTitle from 'components/forms/HideableFieldTitle';
import { WordAttributeRadio } from 'components/GoalConfig/WordAttributeRadio';
import { getGetIsFeatureAvailable } from 'features/features/store/selectors';
import { FieldArray, useFormikContext } from 'formik';
import { FormattedMessage } from 'react-intl';
import { AdwordsAttributeType } from 'services/api/adwords/types';
import { useAppSelector } from 'store/hooks';

import Emoji from '../../../../components/base/Emoji';
import FormGroup from '../../../../components/base/FormGroup';
import ManualDimensionsField from '../../../../components/forms/ManualDimensionsField';
import DimensionsSelect from '../../../../components/GoalConfig/DimensionsSelect';
import { getEmbeddingModelMapperVariablesSelector } from '../../../embeddingModels/store/selectors';
import { getCurrentTabModelData, getExplorerTabMapperId } from '../../store/selectors';
import { seoParamsOptions, wordAttributeWithOptions } from '../../store/utils';

const seoParamsEnabled = process.env.REACT_APP_ADWORDS_ENABLED === 'true';

const ExplorerGoalConfigStep = () => {
  const {
    values: { model, wordAttributes, seoParams }
  } = useFormikContext();
  const seoParametersInPricingPlan = useAppSelector(getGetIsFeatureAvailable)('seoValues');
  const tabModel = useAppSelector(getCurrentTabModelData);
  const tabModelMapper = useAppSelector(getExplorerTabMapperId);
  const embeddingModelId = model ? model.modelId : tabModel?.id;
  const modelMapper = model ? model.modelMapper : tabModelMapper;

  const availableVariables = useAppSelector(getEmbeddingModelMapperVariablesSelector)(
    embeddingModelId,
    modelMapper
  );

  return (
    <>
      <FieldArray name="wordAttributes">
        {({ push, remove }) => (
          <>
            {wordAttributeWithOptions.map(({ value, label, options }) => (
              <FormGroup
                key={value}
                label={label ? `word_param.${label}` : undefined}
                horizontal
                gutter
              >
                {options?.map(option => (
                  <WordAttributeRadio
                    values={wordAttributes}
                    object={{ value, label, option }}
                    push={push}
                    remove={remove}
                    key={option.value}
                    isDisabled={value !== 'model_rank_score' && !availableVariables.includes(value)}
                    label={<FormattedMessage id={option?.label || value} />}
                    avatar={
                      option?.icon ? <Emoji code={option.icon} label={option.value} /> : undefined
                    }
                  />
                ))}
              </FormGroup>
            ))}
          </>
        )}
      </FieldArray>
      <FieldArray name="seoParams">
        {({ push, remove }) => (
          <FormGroup label="exploring.seo" horizontal gutter>
            {seoParamsEnabled && seoParametersInPricingPlan
              ? seoParamsOptions.map(paramOption => (
                  <ButtonRadioField
                    key={paramOption}
                    selected={
                      !!seoParams.find((seoParam: AdwordsAttributeType) => seoParam === paramOption)
                    }
                    onClick={ref => {
                      const currentObjectIdx = seoParams.indexOf(paramOption);
                      if (currentObjectIdx !== -1) {
                        remove(currentObjectIdx);
                        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                        ref.current!.blur();
                      } else {
                        push(paramOption);
                      }
                    }}
                    label={<FormattedMessage id={`seo.${paramOption}`} />}
                  />
                ))
              : null}
          </FormGroup>
        )}
      </FieldArray>
      <FlexContainer direction="col" gap="small">
        <HideableFieldTitle title="testing.setup.goal.manual_dimensions.title">
          <ManualDimensionsField embeddingModelId={embeddingModelId} />
        </HideableFieldTitle>
        <HideableFieldTitle title="testing.setup.goal.dimensions_select.title">
          <DimensionsSelect embeddingModelId={embeddingModelId} />
        </HideableFieldTitle>
      </FlexContainer>
    </>
  );
};

export default ExplorerGoalConfigStep;
