import { identity } from 'lodash';
import { removeDuplicates } from 'utils/utils';

import { TestingContent } from '../../types';

const prepareWordsToRate = (texts: TestingContent[]) =>
  texts
    .map(t => {
      const availableWords = t.words.filter(i => i.status === 'AVAILABLE');

      return removeDuplicates(
        availableWords
          .reduce<string[]>((acc, token) => acc.concat(token.token).concat(token.synonyms), [])
          .filter(identity)
      );
    })
    .filter(words => words.length);

export default prepareWordsToRate;
