import { createReducer } from '@reduxjs/toolkit';
import { Audience } from 'services/api/audience/types';
import { switchLoadingDefault } from 'store/utils/genericReducers';
import { createAsyncAction } from 'utils/reduxUtils';

import { AudiencesState } from './types';

export const getAudiences = createAsyncAction<void, Audience[]>('audiences/getAudiences');

const audiences = createReducer<AudiencesState>(
  {
    audiences: [],
    isLoading: false
  },
  builder =>
    builder
      .addCase(getAudiences.request, switchLoadingDefault(true))
      .addCase(getAudiences.success, (_, { payload }) => ({
        audiences: payload.filter(project => !!project.model_id),
        isLoading: false
      }))
      .addCase(getAudiences.failure, switchLoadingDefault(false))
);

export default audiences;
