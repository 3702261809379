import { Tooltip, Typography } from '@mui/material';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

type Props = {
  date: string | number | Date;
};

const RelativeDate = ({ date }: Props) => {
  dayjs.extend(utc);
  dayjs.extend(timezone);

  return date ? (
    <Tooltip
      placement="top"
      title={
        <Typography variant="body2">
          {dayjs(date).utc(true).local().tz(dayjs.tz.guess()).format('LLL')}
        </Typography>
      }
    >
      <span>{dayjs(date).fromNow()}</span>
    </Tooltip>
  ) : (
    <span>—</span>
  );
};

export default RelativeDate;
