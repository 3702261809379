import { createSelector, Selector } from '@reduxjs/toolkit';
import { getEmbeddingModelDataSelector } from 'features/embeddingModels/store/selectors';
import { RootState } from 'store/rootReducer';
import { BaseTab, TabHeader } from 'types/ReduxStateTypes';

export function getActiveTabFactory<T extends BaseTab>(
  tabsSelector: Selector<RootState, Record<string, T>>,
  currentTabSelector: Selector<RootState, string | null>,
  defaultObject: T
) {
  return createSelector([tabsSelector, currentTabSelector], (tabs, currentTab) => {
    if (currentTab && tabs[currentTab]) {
      return tabs[currentTab];
    }

    return defaultObject;
  });
}

export function hasTabsFactory<T extends BaseTab>(
  tabsSelector: Selector<RootState, Record<string, T>>
) {
  return createSelector(tabsSelector, tabs => Object.keys(tabs).length > 0);
}

export function getTabsHeadersFactory<T extends BaseTab>(
  tabsSelector: Selector<RootState, Record<string, T>>
) {
  return createSelector(
    [tabsSelector, getEmbeddingModelDataSelector],
    (tabs, getModelData): TabHeader[] =>
      Object.values(tabs).map(({ name, embeddingModelId, id, updatedAt }) => {
        const modelData = getModelData(embeddingModelId);
        return { name, id, modelData, updatedAt };
      })
  );
}
