import { Typography } from '@mui/material';
import FlexContainer from 'components/FlexContainer';
import FormattedMessage from 'features/i18n/FormattedMessage';
import { Discount, isTypeBusinessPlan } from 'features/pricing/types';
import countPricingWithDiscount from 'features/pricing/utils/countPricingWithDiscount';
import { useMemo } from 'react';
import { ACLPricing } from 'services/api/customer/types';
import styled from 'styled-components';
import useTr from 'utils/hooks/useTr';

const oneYearInDays = 365;

type Props = {
  pricing: ACLPricing;
  discount?: Discount;
  monthlyCost?: string;
};

export const PricingInPricingCard = ({ pricing, discount, monthlyCost }: Props) => {
  const translate = useTr();

  const isYearlyPeriod = pricing.period >= oneYearInDays;
  const formattedPriceValue = useMemo(() => {
    const baseCost = countPricingWithDiscount({
      pricing: parseInt(pricing.cost, 10),
      amountOff: discount?.amountOff,
      percentOff: discount?.percentOff
    });

    // hack to show monthly cost for business plan regardless of the period selected;
    // required by the product team
    if (isTypeBusinessPlan(pricing) && monthlyCost) {
      return `${Number(monthlyCost).toFixed()}*`;
    }

    if (baseCost === 0) {
      return baseCost.toFixed();
    }

    if (isYearlyPeriod) {
      return (baseCost / 12).toFixed();
    }

    return baseCost.toFixed();
  }, [pricing, discount?.amountOff, discount?.percentOff, monthlyCost, isYearlyPeriod]);

  const currencySymbol = translate(`currency.symbol.${pricing.currency}`);

  return (
    <PricingContainer>
      <Typography variant="h5">{currencySymbol}</Typography>
      <Typography variant="h3">
        <FormattedMessage
          id={pricing.isFree ? 'pricing.plan.price.free' : 'pricing.plan.price.formatted'}
          values={{
            price: formattedPriceValue
          }}
        />
      </Typography>
    </PricingContainer>
  );
};

const PricingContainer = styled(FlexContainer).attrs({
  direction: 'row',
  gap: 'one',
  alignItems: 'flex-start'
})``;
