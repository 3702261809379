import styled from 'styled-components';

export const Section = styled.div`
  margin-bottom: ${({ theme }) => theme.spacings.xmedium};
  width: 100%;
`;

export const Tile = styled.div`
  flex: 1;
  padding: ${({ theme }) => theme.spacings.medium};
  border: 1px solid ${({ theme }) => theme.colors.aiWriterSidebarBorderColor};
  border-radius: ${({ theme }) => theme.borderRadius.medium};
  cursor: pointer;
`;

export const SmallerSection = styled.div`
  margin-bottom: ${({ theme }) => theme.spacings.medium};
`