import { Audience } from 'services/api/audience/types';

const getAudienceInitValue = (projects: Audience[], localStorageKey: string) => {
  if (projects.length === 1) {
    return projects[0].id;
  }

  const savedAudience = localStorage.getItem(localStorageKey);
  const prevAudience = projects.find(s => String(s.id) === savedAudience)?.id;

  if (prevAudience) {
    return prevAudience;
  }

  return projects.find(p => p.is_generic)?.id;
};

export default getAudienceInitValue;
