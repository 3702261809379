import { backofficeClient } from 'services/backofficeIntegration/http/client/createBackofficeClient';
import { CustomerPreferencesDto } from 'services/backofficeIntegration/http/dtos/CustomerPreferencesDto';
import { mutationEndpoint } from 'services/backofficeIntegration/http/endpointDefinition';

export type CustomerPreferencesPayload = Partial<CustomerPreferencesDto>;

export const httpUpdatePreferences = mutationEndpoint({
  async callEndpoint(payload: CustomerPreferencesPayload) {
    await backofficeClient.post('/customer/preferences/', {
      preferences: payload
    });
  }
});
