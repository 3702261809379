import { BaseSuccessDto } from 'services/backofficeIntegration/http/BaseSuccessDto';
import { backofficeClient } from 'services/backofficeIntegration/http/client/createBackofficeClient';
import { mutationEndpoint } from 'services/backofficeIntegration/http/endpointDefinition';
import { invalidateInformationListQuery } from 'services/backofficeIntegration/http/endpoints/infomration/httpGetInformationList';

type SuccessDto = BaseSuccessDto<never>;

export const httpDeleteInformation = mutationEndpoint({
  callEndpoint: (informationId: number) =>
    backofficeClient.delete<SuccessDto>(`/informations/${informationId}`).then(response => {
      invalidateInformationListQuery();
      return response.data;
    })
});
