import LocalStorageKey from 'config/localStorageKey';
import { SetStateAction } from 'react';
import { useStorage } from 'utils/hooks/useStorage';

export function useLocalStorage<V>(
  key: LocalStorageKey | string,
  initialValue: V,
  encodeValue = true
): [V, (newValue: SetStateAction<V>) => void] {
  return useStorage(key, initialValue, encodeValue, window.localStorage);
}
