import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import styled from 'styled-components';

import Dimmer from '../../base/Dimmer';
import Icon from '../../base/Icon';
import Tooltip from '../../tooltips/Tooltip';
import Pin from './Pin';
import { getIsToolbarLocked, setToolbarLocked } from './toolbarSlice';

const Header = styled.div`
  margin-bottom: 0.5rem;
  flex: none;
`;

const TitleSection = styled.div`
  display: flex;
  justify-content: space-between;
  text-transform: uppercase;
  color: #868e96;
  margin-bottom: 0.75rem;
`;

const Title = styled.div`
  font-weight: 600;
  font-size: 0.875rem;
`;

type Props = {
  title: string;
  isLoading: boolean;
  children: React.ReactElement;
  dropdown?: React.ComponentType;

  closeWidget: () => void;
};

const WidgetHeader = ({ title, isLoading, dropdown: Dropdown, children, closeWidget }: Props) => {
  const toolbarLocked = useAppSelector(getIsToolbarLocked);
  const dispatch = useAppDispatch();

  return (
    <Dimmer active={isLoading}>
      <Header>
        <TitleSection>
          <Title>
            <FormattedMessage id={title} />
            <Tooltip
              content={
                <FormattedMessage
                  id={!toolbarLocked ? 'layout.toolbar.pin' : 'layout.toolbar.unpin'}
                />
              }
            >
              <Pin active={toolbarLocked} onClick={() => dispatch(setToolbarLocked())} />
            </Tooltip>
          </Title>
          <Icon name="x" onClick={closeWidget} />
        </TitleSection>
        {Dropdown && <Dropdown />}
      </Header>
      {children}
    </Dimmer>
  );
};

export default WidgetHeader;
