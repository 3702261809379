import LocalStorageKey from 'config/localStorageKey';
import { AiWriterSetupValues, audienceField } from 'features/aiWriter/modals/setup/SetupModal';
import useGetModelAudiences from 'features/audiences/hooks/useGetModelAudiences';
import getAudienceInitValue from 'features/audiences/utils/getAudienceInitValue';
import { useFormikContext } from 'formik';

export default function useHandleModelChange() {
  const { setFieldValue } = useFormikContext<AiWriterSetupValues>();

  const getCountryProjects = useGetModelAudiences();

  return (embeddingModelId: string) => {
    const countryProjects = getCountryProjects(embeddingModelId);

    setFieldValue(
      audienceField,
      getAudienceInitValue(countryProjects, LocalStorageKey.AiWriterProject)
    );

    localStorage.setItem(LocalStorageKey.AiWriterModel, String(embeddingModelId));
  };
}
