import { Checkbox } from '@mui/material';
import { Elements } from 'features/workflowBlogPostBuilder/types';
import { useField } from 'formik';
import { MouseEvent } from 'react';

export function FieldCheckbox(props: { fieldName: string; value: Elements; disabled?: boolean; onClick?: (event: MouseEvent) => void; }) {
  const { fieldName, value, disabled, onClick } = props;
  const [field] = useField(fieldName);

  return (
    <Checkbox
      {...field}
      checked={field.value.includes(value)}
      value={value}
      disabled={disabled}
      onClick={onClick}
    />
  );
}
