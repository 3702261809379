export function removeNumberingFromHeadline(line: string) {
  return (
    line
      .trim()
      // Replaces:
      // 1. ...
      // 123. ...
      // A. ...
      // a. ...
      // IV. ...
      //
      // And also with a ) instead of a dot
      .replace(/^[\da-zA-Z]+\w*[.)]/, '')
      .trim() ?? ''
  );
}
