import { FormattedMessage } from 'react-intl';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import styled from 'styled-components';

import EmptyButton from '../../components/base/EmptyButton';
import Icon from '../../components/base/Icon';
import Tooltip from '../../components/tooltips/Tooltip';
import { getExplorerPageLayout } from './store/selectors';
import { toggleLayout } from './store/slice';

const Button = styled(EmptyButton)`
  display: inline-block;
  width: 36px;
  height: 36px;
  margin-right: 0.25rem;
`;

const IconContainer = styled.span<{ isVertical?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: ${({ isVertical }) => (isVertical ? 'column' : 'row')};
  cursor: pointer;
  height: 34px;
  color: #9aa0ac;

  &:hover {
    color: #495057;
  }

  i {
    padding: 0.125rem !important;
    font-size: 1rem;
  }
`;

const LayoutSwitchButton = () => {
  const isVertical = useAppSelector(getExplorerPageLayout);
  const dispatch = useAppDispatch();

  return (
    <Tooltip content={<FormattedMessage id="layout.sidebar.switch_layout" />}>
      <Button onClick={() => dispatch(toggleLayout())}>
        <IconContainer isVertical={isVertical}>
          <Icon name="list" />
          <Icon name="target" />
        </IconContainer>
      </Button>
    </Tooltip>
  );
};

export default LayoutSwitchButton;
