import { safeEnum } from 'utils/safeEnum';

import { ACLPricing } from '../../services/api/customer/types';

/**
 * The type of plan. Enterprise is not selectable but only on request
 */
const PricingPlan = safeEnum.make({
  free: 'free',
  basic: 'basic',
  power: 'power',
  powerVip: 'powerVip',
  powerLower: 'powerLower',
  powerHigher: 'powerHigher',
  premium: 'premium',
  enterprise: 'enterprise',
  standard: 'standard',
  starter: 'starter',
  pro: 'pro',
  business: 'business',
  /**
   * Right now we don't need to distinguish api pricing tiers in the app
   * So I go the easy way
   */
  ignore: 'ignore'
} as const);

type PricingPlan = safeEnum.infer<typeof PricingPlan>;

export { PricingPlan };

export type Discount = {
  amountOff?: number;
  percentOff?: number;
};

/**
 * Utility function to check for type business or name business.
 * The name check is need to be backwards compatible for older plans
 * there the type was pro but name "Business".
 */
export const isTypeBusinessPlan = (pricingPlan: ACLPricing) =>
  pricingPlan.name === 'Business' || pricingPlan.type === PricingPlan.business;

export const isTypeBusinessOrProPlan = (pricingPlan: ACLPricing) =>
  isTypeBusinessPlan(pricingPlan) || pricingPlan.type === PricingPlan.pro || pricingPlan.name === 'Pro';