import { CustomerData } from 'services/api/customer/types';
import { BaseSuccessDto } from 'services/backofficeIntegration/http/BaseSuccessDto';
import { backofficeClient } from 'services/backofficeIntegration/http/client/createBackofficeClient';
import { mutationEndpoint } from 'services/backofficeIntegration/http/endpointDefinition';

type SuccessDto = BaseSuccessDto<CustomerData>;

export const httpResumePausedSubscription = mutationEndpoint({
  callEndpoint: () => {
    return backofficeClient
      .post<SuccessDto>('/customer/subscription/resume')
      .then(res => res.data.data);
  }
});
