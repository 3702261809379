import { convertNodesToHtml } from 'features/aiWriter/utils/getDocumentAsHtml';

function cleanTextPlain(clipboardData: DataTransfer | null) {
  try {
    if (!clipboardData) {
      return false;
    }
    const dirtyText = clipboardData.getData('text/plain');
    if (!dirtyText || dirtyText.length === 0) {
      return false;
    }
    const cleanText = dirtyText.replace(/(\r?\n){2,}/gm, '\n').trim();

    clipboardData.setData('text/plain', cleanText);

    return true;
  } catch {
    return false;
  }
}

function cleanTextHtml(clipboardData: DataTransfer | null) {
  try {
    if (!clipboardData) {
      return false;
    }

    /**
     * This expects the clipboardData to have the proprietary data formart "application/x-slate-fragment".
     * If something is broken, try checking the current state of the Plate implementation:
     * https://github.com/udecode/plate/blob/e0c570fb353b6b893e09c85ea00022f16caeb649/packages/selection/src/utils/copySelectedBlocks.ts#L63     *
     */
    const slateFragment = clipboardData.getData('application/x-slate-fragment');
    if (!slateFragment || slateFragment.length === 0) {
      return false;
    }

    const editorBlocks = JSON.parse(decodeURIComponent(window.atob(slateFragment)));

    const reducedHtml = convertNodesToHtml(editorBlocks, { includeStyle: false });

    clipboardData.setData('text/html', reducedHtml);

    return true;
  } catch {
    return false;
  }
}

export const manipulateClipboardData = (clipboardData: DataTransfer | null) => {
  const htmlTextCleaned = cleanTextHtml(clipboardData);
  const plainTextCleaned = cleanTextPlain(clipboardData);
  return htmlTextCleaned || plainTextCleaned;
};
