import { DecorateEntry, PlateEditor, Value, WithPlatePlugin } from '@udecode/plate';
import { isEditor, isText } from '@udecode/plate-common';
import { boundedTextRegExp } from 'features/aiWriter/AiWriterTextEditor/utils/boundedTextRegExp';
import { escapeRegExpText } from 'features/aiWriter/AiWriterTextEditor/utils/escapeRegExpText';
import { Range } from 'slate';
import { forceNonNullable } from 'utils/typescript/nonNullable';

export type CustomFindPlugin = {
  search?: string | string[];
};

const decorateFullWordFind =
  <V extends Value = Value, E extends PlateEditor<V> = PlateEditor<V>>(
    editor: E,
    { key, type }: WithPlatePlugin<CustomFindPlugin, V, E>
  ): DecorateEntry =>
  ([node, path]) => {
    if (isEditor(node) || !isText(node)) {
      return [];
    }

    const nodeText = node.text;
    if (!nodeText) {
      return [];
    }

    const { search } = editor.pluginsByKey[key].options as CustomFindPlugin;
    const ranges: SearchRange[] = [];

    if (!search) {
      return ranges;
    }

    if (Array.isArray(search)) {
      for (const searchTerm of search) {
        if (!searchTerm) {
          continue;
        }

        const query = searchTerm.trim();
        const searchRegExp = new RegExp(escapeRegExpText(query), 'gi');

        for (const result of nodeText.matchAll(searchRegExp)) {
          const [match] = result;

          const start = forceNonNullable(result.index);
          const end = start + match.length;

          ranges.push({
            anchor: { path, offset: start },
            focus: { path, offset: end },
            search: searchTerm,
            [type]: true
          });
        }
      }

      return ranges;
    }

    const searchRegExp = boundedTextRegExp(search);

    for (const result of nodeText.matchAll(searchRegExp)) {
      const [match] = result;
      const start = forceNonNullable(result.index);
      const end = start + match.length;

      ranges.push({
        anchor: { path, offset: start },
        focus: { path, offset: end },
        search,
        [type]: true
      });
    }

    return ranges;
  };

/**
 * Default plate implementation is very limited. All we can do is just pass
 * a string that we wanna find. There is no way to specify additional rules
 * e.g. full word matching.
 */
export const decorateCustomFind = <
  V extends Value = Value,
  E extends PlateEditor<V> = PlateEditor<V>
>(
  editor: E,
  config: WithPlatePlugin<CustomFindPlugin, V, E>
): DecorateEntry => {
  return decorateFullWordFind(editor, config);
};

type SearchRange = Range & {
  search: string;
};
