import { Typography } from '@mui/material';
import FlexContainer from 'components/FlexContainer';
import { LazyLoadedLoadingAnimation } from 'features/loading-spinner/LazyLoadedLoadingAnimation';
import { useAvailablePlans } from 'features/pricing/hook/useAvailablePlans';
import { PricingPeriodSelect } from 'features/pricing/PricingPeriodSelect';
import { PricingPlanButton } from 'features/pricing/PricingPlanButton';
import { Discount } from 'features/pricing/types';
import { Field, FieldProps, useFormikContext } from 'formik';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { SubscriptionPeriod } from 'services/api/customer/types';
import { PricingContextParams } from 'services/api/pricing';
import styled from 'styled-components';
import useTr from 'utils/hooks/useTr';

export const pricingPlanSelectFieldName = 'pricing';

export type PricingPlanSelectFormValues = {
  [pricingPlanSelectFieldName]: number | null;
};

type Props = {
  discount?: Discount;
  pricingContext?: PricingContextParams['context'];
};

export const PricingPlanSelect = ({ discount, pricingContext }: Props) => {
  const translate = useTr();

  const [subscriptionPeriod, setSubscriptionPeriod] = useState<SubscriptionPeriod>('year');

  const {
    setFieldValue,
    values: { [pricingPlanSelectFieldName]: selectedPlanId }
  } = useFormikContext<PricingPlanSelectFormValues>();

  const { data, isLoading } = useAvailablePlans({
    period: subscriptionPeriod,
    context: pricingContext
  });
  const starterPlan = data?.starterPlan;
  const proPlan = data?.proPlan;
  const filteredPlans = data?.filteredPlans;

  // Search in all plans in case the user switch the period
  // (which means the filteredPlans doesn't contain the selected plan)
  const selectedPlan = data?.allPlans?.find(plan => plan.id === selectedPlanId);

  // Set pro plan as default value once available
  useEffect(() => {
    if (!proPlan || !!selectedPlanId) {
      return;
    }

    setFieldValue(pricingPlanSelectFieldName, proPlan.id);
  }, [proPlan, setFieldValue, selectedPlanId]);

  // Switch to the plan matching the selected period
  useEffect(() => {
    if (!selectedPlan || selectedPlan.periodName === subscriptionPeriod) {
      return;
    }

    // Find matching plan by name and period
    // Yes, the name for the plan is the same across all periods (or should be)
    const newPlan = filteredPlans?.find(
      plan => plan.name === selectedPlan?.name && plan.periodName === subscriptionPeriod
    );
    if (newPlan) {
      setFieldValue(pricingPlanSelectFieldName, newPlan.id);
    }
  }, [filteredPlans, selectedPlan, setFieldValue, subscriptionPeriod]);

  return (
    <div>
      <Header>
        <PricingPeriodSelect
          subscriptionPeriod={subscriptionPeriod}
          onSubscriptionPeriodChange={setSubscriptionPeriod}
        />
      </Header>

      <Field name={pricingPlanSelectFieldName}>
        {({ field: { name, value } }: FieldProps) => {
          if (isLoading) {
            return (
              <LoadingContainer>
                <LazyLoadedLoadingAnimation />
              </LoadingContainer>
            );
          }

          return (
            <FlexContainer gap="small">
              {starterPlan && (
                <PricingPlanButton
                  discount={discount}
                  pricing={starterPlan}
                  onClick={plan => setFieldValue(name, plan.id)}
                  selected={starterPlan.id === value}
                />
              )}

              {proPlan && (
                <PricingPlanButton
                  key={proPlan.id}
                  name={proPlan.name}
                  discount={discount}
                  pricing={proPlan}
                  onClick={plan => setFieldValue(name, plan.id)}
                  selected={proPlan.id === value}
                />
              )}
            </FlexContainer>
          );
        }}
      </Field>

      <Extra variant="caption">
        <FormattedMessage id="register.pricing.explanation" />
      </Extra>

      <Typography variant="subtitle2">
        <FormattedMessage
          id="register.pricing.contact"
          values={{
            contact: (msg: string) => (
              <Link
                href={translate('training_session.link')}
                target="_blank"
                rel="noopener noreferrer"
              >
                {msg}
              </Link>
            ),
            plans: (msg: string) => (
              <Link
                href={translate('external_links.app_landingpage_pricing_plans')}
                target="_blank"
                rel="noopener noreferrer"
              >
                {msg}
              </Link>
            )
          }}
        />
      </Typography>
    </div>
  );
};

const Header = styled(FlexContainer)`
  margin-bottom: ${({ theme }) => theme.spacings.three};
`;

const Extra = styled(Typography)`
  && {
    display: block;

    margin-top: ${({ theme }) => theme.spacings.small};
    margin-bottom: ${({ theme }) => theme.spacings.large};

    color: ${({ theme }) => theme.colors.blackMediumEmphasis};
  }
`;

const Link = styled.a`
  color: ${({ theme }) => theme.colors.primary};
`;

const LoadingContainer = styled.div`
  // Default height if all 3 plans are rendered
  // Used here to prevent jumping if switched from loading to loaded state
  min-height: 376px;
  // Center loading spinner
  display: flex;
  justify-content: center;
  align-items: center;
`;
