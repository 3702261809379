import { createAction } from '@reduxjs/toolkit';
import { createAsyncAction } from 'utils/reduxUtils';

import {
  GenerateTextSuggestionsFailurePayload,
  GenerateTextSuggestionsSuccessPayload,
  ImproveGoalFailurePayload,
  ImproveGoalRequestPayload,
  ImproveGoalSuccessPayload,
  RefreshTextSuggestionsFailurePayload,
  RefreshTextSuggestionsRequestPayload,
  RefreshTextSuggestionsSuccessPayload,
  RemoveSuggestionPayload,
  RescoreSuggestionsSuccessPayload,
  SelectSuggestionPayload
} from './types';

export const selectSuggestion = createAction<SelectSuggestionPayload>('tester/selectSuggestion');
export const removeSuggestion = createAction<RemoveSuggestionPayload>('tester/removeSuggestion');
export const generateSuggestions = createAsyncAction<
  void,
  GenerateTextSuggestionsSuccessPayload,
  GenerateTextSuggestionsFailurePayload
>('tester/generateSuggestions');

export const refreshSuggestions = createAsyncAction<
  RefreshTextSuggestionsRequestPayload,
  RefreshTextSuggestionsSuccessPayload,
  RefreshTextSuggestionsFailurePayload
>('tester/refreshSuggestions');

export const rescoreSuggestions = createAsyncAction<void, RescoreSuggestionsSuccessPayload>(
  'tester/rescoreSuggestions'
);

export const improveGoal = createAsyncAction<
  ImproveGoalRequestPayload,
  ImproveGoalSuccessPayload,
  ImproveGoalFailurePayload
>('tester/improveGoal');
