import AddCircleIcon from '@mui/icons-material/AddCircle';
import { ColoredTypography } from 'components/ColoredTypography';
import FlexContainer from 'components/FlexContainer';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { actionableCard } from 'styles/styledComponents/mixins';
import { resetLink } from 'styles/styledComponents/resets';

type Props = {
  id?: string;

  onClick: () => void;
};

const CreateProjectButtonLarge = ({ id, onClick }: Props) => (
  <Root id={id} role="button" onClick={onClick}>
    <Container justifyContent="center" alignItems="center" gap="two">
      <AddIcon />
      <ColoredTypography variant="body1" color="white">
        <FormattedMessage id="common.new_analysis" />
      </ColoredTypography>
    </Container>
  </Root>
);

const Root = styled.div`
  ${actionableCard};
  ${resetLink};

  height: 162px;
  width: 100%;
  min-width: 17.5rem;
  background: ${({ theme }) => theme.colors.primaryColorMain};

  padding: ${({ theme }) => theme.spacings.three};
  cursor: pointer;

  :hover {
    background: ${({ theme }) => theme.colors.primaryColorDark};
  }
`;

const Container = styled(FlexContainer)`
  height: 100%;
`;

const AddIcon = styled(AddCircleIcon)`
  font-size: 38px;
  color: ${({ theme }) => theme.colors.white};
`;

export default CreateProjectButtonLarge;
