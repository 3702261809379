import { getGetAudienceByLanguageAndCountry } from 'features/audiences/store/selectors';
import { getGetCountryEmbeddingModelGroupById } from 'features/embeddingModels/store/selectors';
import { useAppSelector } from 'store/hooks';

export default function useGetModelAudiences() {
  const getCountryModelGroup = useAppSelector(getGetCountryEmbeddingModelGroupById);
  const getAudienceByLanguageAndCountry = useAppSelector(getGetAudienceByLanguageAndCountry);

  return (model: string) => {
    const countryModelGroup = getCountryModelGroup(model);
    const audiences = getAudienceByLanguageAndCountry(
      countryModelGroup?.language,
      countryModelGroup?.country
    );

    return audiences;
  };
}
