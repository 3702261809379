import CloseIcon from '@mui/icons-material/Close';
import { Button, IconButton, LinearProgress, TextField, Typography } from '@mui/material';
import oneTimeInfoImage from 'assets/contentFlash/prompts/prompt-figure.png';
import FlexContainer from 'components/FlexContainer';
import { BasicInfoButton } from 'components/iconButtons/BasicInfoButton';
import { useModal } from 'components/modals';
import TextTile from 'components/TextTile';
import LocalStorageKey from 'config/localStorageKey';
import { useAiWriterExpandedSidebarStore } from 'features/aiWriter/AiWriterSidebar/sidebar/aiWriterExpandedSidebarStore';
import { AiWriterSidebarPanel } from 'features/aiWriter/AiWriterSidebar/sidebar/AiWriterSidebarPanel';
import { ExpandSidebarButton } from 'features/aiWriter/AiWriterSidebar/sidebar/ExpandSidebarButton';
import { useAddCommandTemplateToEditor } from 'features/aiWriter/commandTemplates/useAddCommandTemplateToEditor';
import { useShowTemplatesModal } from 'features/aiWriter/commandTemplates/useShowTemplatesModal';
import { useTemplatesQuery } from 'features/aiWriter/commandTemplates/useTemplatesQuery';
import { useUpdateCommandTemplateUsage } from 'features/aiWriter/commandTemplates/useUpdateCommandTemplateUsage';
import { getActiveTab, getCurrentModelLanguageAndCountry } from 'features/aiWriter/store/selectors';
import FormattedMessage from 'features/i18n/FormattedMessage';
import { ChangeEvent, useState } from 'react';
import { TemplateDto } from 'services/backofficeIntegration/http/endpoints/textGeneration/commandTemplates/httpGetCommandTemplates';
import gtmIds from 'services/tracking/GTMIds';
import { withGtmInteraction } from 'services/tracking/withGtmInteraction';
import { useAppSelector } from 'store/hooks';
import styled from 'styled-components';
import { resetUl } from 'styles/styledComponents/resets';
import { useDebounce } from 'use-debounce';
import { useLocalStorage } from 'utils/hooks/useLocalStorage';
import useTr from 'utils/hooks/useTr';

function ListItem(props: { templateDto: TemplateDto }) {
  const { templateDto: dto } = props;
  const { addTemplate } = useAddCommandTemplateToEditor();

  return (
    <li key={dto.id}>
      <TextTile
        gtmId={gtmIds.aiWriter.templates.selectTemplate}
        onClick={() => addTemplate(dto.template)}
      >
        <FlexContainer gap="small">
          <Typography variant="subtitle2">{dto.title}</Typography>
          <Typography variant="body2">{dto.description}</Typography>
          <Typography variant="caption" textAlign="right">
            <FormattedMessage
              id="aiWriter.inspirations.templates.item.author"
              values={{ source: dto.source }}
            />
          </Typography>
        </FlexContainer>
      </TextTile>
    </li>
  );
}

function UsageHint() {
  const [hideHint, setHideHint] = useLocalStorage(
    LocalStorageKey.AiWriterCommandTemplatesHint,
    false
  );

  if (hideHint) {
    return null;
  }

  return (
    <HintContainer>
      <ImageContainer>
        <img src={oneTimeInfoImage} />
      </ImageContainer>
      <Content>
        <FlexContainer direction="row" justifyContent="space-between">
          <Title>
            <FormattedMessage
              id="aiWriter.inspirations.templates.hint.title"
              values={{
                highlight: (text: string) => <Highlighting>{text}</Highlighting>
              }}
            />
          </Title>
          <IconButton onClick={() => setHideHint(true)} size="small">
            <CloseIcon />
          </IconButton>
        </FlexContainer>
        <Description>
          <FormattedMessage
            id="aiWriter.inspirations.templates.hint.body"
            values={{ br: <br /> }}
          />
        </Description>
      </Content>
    </HintContainer>
  );
}

const Title = styled(Typography).attrs({
  variant: 'h5'
})``;

const HintContainer = styled(FlexContainer).attrs({
  gap: 'two'
})`
  position: relative;

  background: ${({ theme }) => theme.colors.palette.pinkRose__500main};
  border-radius: ${({ theme }) => theme.borderRadius.one};

  max-height: 183px;
`;

const Content = styled.div`
  padding: ${({ theme }) => theme.spacings.four};
`;

const ImageContainer = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  overflow: hidden;

  height: 100%;
`;

const Description = styled(Typography).attrs({
  variant: 'body1'
})``;

const Highlighting = styled.span`
  color: ${({ theme }) => theme.colors.white};
`;
export function TemplatesStep() {
  const currentTab = useAppSelector(getActiveTab);
  const { currentModelCountry: country, currentModelLanguage: language } = useAppSelector(
    getCurrentModelLanguageAndCountry
  );
  const isSidebarExpanded = useAiWriterExpandedSidebarStore(state => state.isSidebarExpanded);
  const setIsSidebarExpanded = useAiWriterExpandedSidebarStore(state => state.setIsSidebarExpanded);

  const [searchQuery, setSearchQuery] = useState<string>('');

  const [debouncedSearchQuery] = useDebounce(searchQuery, 300);

  const showTemplatesModal = useShowTemplatesModal();
  const { addTemplate } = useAddCommandTemplateToEditor();
  const { mutate: updateCommandTemplateUsage } = useUpdateCommandTemplateUsage();

  const query = useTemplatesQuery({
    language,
    country,
    title: debouncedSearchQuery
  });

  function onSearchChange(e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    setSearchQuery(e.target.value);
  }

  function handleClick() {
    showTemplatesModal({
      preselectedModelId: currentTab.embeddingModelId,
      onTemplateSelect: dto => {
        updateCommandTemplateUsage({ templateId: dto.id });
        addTemplate(dto.template);
      }
    });
  }

  function renderSearch() {
    return (
      <div>
        <SearchInput
          label={<FormattedMessage id="aiWriter.inspirations.templates.search.label" />}
          value={searchQuery}
          onChange={onSearchChange}
          fullWidth={true}
        />

        <Button
          {...withGtmInteraction(gtmIds.aiWriter.templates.openLibrary)}
          variant="text"
          onClick={handleClick}
        >
          <FormattedMessage id="aiWriter.inspirations.template.library_button" />
        </Button>
      </div>
    );
  }

  function renderList() {
    if (!query.data) {
      return null;
    }

    const items = query.data.pages.map(page => page.data).flat(1);
    if (items.length < 1) {
      return (
        <Typography textAlign="center" variant="body2">
          <FormattedMessage id="aiWriter.inspirations.templates.empty" />
        </Typography>
      );
    }

    return (
      <TemplateList>
        {items.map(dto => (
          <ListItem key={dto.id} templateDto={dto} />
        ))}
      </TemplateList>
    );
  }

  function renderRetry(messageId: string) {
    return (
      <FlexContainer alignItems="center" gap="medium">
        <Typography variant="body2" textAlign="center">
          <FormattedMessage id={messageId} />
        </Typography>
        <Button
          variant="contained"
          onClick={() => {
            query.refetch();
          }}
        >
          <FormattedMessage id="common.refresh" />
        </Button>
      </FlexContainer>
    );
  }

  function renderQueryControls() {
    if (query.isFetching) {
      return (
        <FlexContainer>
          <LinearProgress />
        </FlexContainer>
      );
    }

    if (query.isLoadingError) {
      return renderRetry('aiWriter.inspirations.templates.errors.load');
    }

    if (query.isRefetchError && !query.hasNextPage) {
      return renderRetry('aiWriter.inspirations.templates.errors.refetch');
    }

    if (query.hasNextPage) {
      return (
        <Button
          {...withGtmInteraction(gtmIds.aiWriter.templates.moreTemplates)}
          fullWidth
          onClick={() => {
            query.fetchNextPage();
          }}
        >
          <FormattedMessage id="common.moreButton" />
        </Button>
      );
    }
  }

  return (
    <AiWriterSidebarPanel
      title="aiWriter.inspirations.templates.title"
      infoButton={<InfoButton />}
      customButton={
        <ExpandSidebarButton
          isExpanded={isSidebarExpanded}
          onExpand={() => setIsSidebarExpanded(true)}
          onShrink={() => setIsSidebarExpanded(false)}
        />
      }
    >
      <RootLayout gap="medium">
        <UsageHint />
        {renderSearch()}
        {renderList()}
        {renderQueryControls()}
      </RootLayout>
    </AiWriterSidebarPanel>
  );
}

function InfoButton() {
  const { showModal } = useModal();
  const translate = useTr();

  function handleClick() {
    showModal('YOUTUBE_VIDEO', {
      size: 1100,
      videoId: translate('aiWriter.inspirations.templates.info_button.video_id')
    });
  }

  return (
    <BasicInfoButton
      onClick={handleClick}
      tooltip={translate('aiWriter.inspirations.info_button.tooltip.video')}
    />
  );
}

const RootLayout = styled(FlexContainer)`
  height: 100%;
`;

const TemplateList = styled.ul`
  ${resetUl}
  flex: 0 1 auto;
  overflow-y: auto;
  display: grid;
  gap: ${({ theme }) => theme.spacings.small};
  min-height: ${({ theme }) => theme.spacings.medium};
`;

const SearchInput = styled(TextField)`
  flex: 1 1 auto;
`;
