import { Visibility, VisibilityOff } from '@mui/icons-material';
import { Button, IconButton, Typography } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import FlexContainer from 'components/FlexContainer';
import {
  Card,
  Header,
  LeftSideContainer,
  StyledCardContent
} from 'components/profile/components/common';
import Toast from 'components/toasts/Toast';
import { updateApiCustomerToken } from 'features/customer/store/actions';
import { getApiCustomerToken } from 'features/customer/store/selectors';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { httpRegenerateApiCustomerToken } from 'services/backofficeIntegration/http/endpoints/auth/httpRegenerateApiToken';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import styled from 'styled-components';

function MaskedOutput(props: { value: string }) {
  const { value } = props;
  const [visible, setVisible] = useState(false);
  const handleClickShowServiceKey = () => setVisible(show => !show);
  const maskedValue = '*'.repeat(14);

  return (
    <TokenContainer>
      <Typography variant="body1">{visible ? value : maskedValue}</Typography>
      <IconButton onClick={handleClickShowServiceKey} size="small">
        {visible ? <VisibilityOff /> : <Visibility />}
      </IconButton>
    </TokenContainer>
  );
}

function CopyButton(props: { valueToCopy: string }) {
  const { valueToCopy } = props;
  const copyAccessToken = () => {
    navigator.clipboard.writeText(valueToCopy).then(() => {
      Toast.success('common.copy_to_clipboard');
    });
  };

  return (
    <Button variant="contained" color="primary" onClick={copyAccessToken}>
      <FormattedMessage id="common.copy" />
    </Button>
  );
}

export function AccessKeyCard() {
  const serviceKey = process.env.REACT_APP_API_CUSTOMER_API_KEY;
  const accessToken = useAppSelector(getApiCustomerToken) || '';
  const dispatch = useAppDispatch();

  const regenerateAccessTokenMutation = useMutation({
    mutationFn: () => httpRegenerateApiCustomerToken.callEndpoint(),
    onSuccess: data => {
      const apiCustomerToken = data.api_customer_token;
      dispatch(updateApiCustomerToken(apiCustomerToken));
    },
    onError: () => {
      Toast.commonError();
    }
  });

  const regenerateAccessToken = () => {
    regenerateAccessTokenMutation.mutate();
  };

  return (
    <Card variant="outlined">
      <Header>
        <LeftSideContainer>
          <Typography variant="h5">
            <FormattedMessage id="profile.access_key.header" />
          </Typography>
          <Typography variant="body1">
            <FormattedMessage id="profile.access_key.headline" />
          </Typography>
        </LeftSideContainer>
      </Header>
      <StyledCardContent>
        <Typography variant="subtitle1">
          <FormattedMessage id="profile.access_key.access_token" />
        </Typography>
        <FlexContainer direction="row" gap="medium" justifyContent="flex-start">
          <MaskedOutput value={accessToken} />
          <CopyButton valueToCopy={accessToken} />
          <Button variant="outlined" color="primary" onClick={regenerateAccessToken}>
            <FormattedMessage id="profile.access_key.regenerate_button" />
          </Button>
        </FlexContainer>

        <Typography variant="subtitle1" mt={3}>
          <FormattedMessage id="profile.access_key.service_key" />
        </Typography>
        <FlexContainer direction="row" gap="medium" justifyContent="flex-start">
          <MaskedOutput value={serviceKey} />
          <CopyButton valueToCopy={serviceKey} />
        </FlexContainer>
      </StyledCardContent>
    </Card>
  );
}

const TokenContainer = styled.output`
  padding: 0.5rem 1rem;
  border-radius: 0.25rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 40rem;
  background: #03a9f41a;
`;
