import { StylesConfig } from 'react-select';
import { ThemeConfig } from 'react-select/dist/declarations/src/theme';

export const selectTheme: ThemeConfig = theme => ({
  ...theme,
  borderRadius: 3,
  spacing: {
    ...theme.spacing,
    controlHeight: 32
  }
});

const customColors = {
  border: '#1991eb',
  primary: '#03A9F4'
};

export const selectStyles: StylesConfig = {
  placeholder: base => ({
    ...base,
    color: '#adb5bd'
  }),
  control: (base, { isFocused }) => ({
    ...base,
    ...(isFocused
      ? {
          borderColor: customColors.border,
          boxShadow: '0 0 0 2px rgba(70, 127, 207, 0.25)'
        }
      : {
          borderColor: 'rgba(0, 40, 100, 0.12)'
        }),
    '&:hover': {
      borderColor: isFocused ? customColors.border : 'rgba(0, 40, 100, 0.12)'
    },
    width: '200px'
  }),
  menu: base => ({
    ...base,
    marginTop: 4
  }),
  menuList: base => ({
    ...base,
    paddingTop: 0,
    paddingBottom: 0
  }),
  option: base => ({
    ...base,
    padding: '6px 12px'
  })
};
