import { random } from 'lodash';
import { memo } from 'react';
import { FormattedMessage } from 'react-intl';
import styled, { css } from 'styled-components';
import { graphColorScale } from 'utils/palettes';

import { ReactComponent as TableIcon } from '../../assets/table-icon.svg';
import Dimmer from '../../components/base/Dimmer';
import Icon from '../../components/base/Icon';
import PlaceholderContainer from '../../components/base/PlaceholderContainer';
import AnimatedContainer from './AnimatedContainer';

const Wrapper = styled(AnimatedContainer)`
  grid-column: 1 / span 2;
  grid-row: 2 / 3;
  padding: 1rem 1rem;

  @media (min-width: 992px) {
    padding: 1rem 3rem;
  }

  @media (min-width: 1280px) {
    padding: 1rem 5rem;
  }
`;

const Separator = styled.div`
  width: 5px;
  background: #ca4b4c;
  border-radius: 3px;

  @media (max-width: 992px) {
    display: none;
  }
`;

const svgStyle = css`
  width: 100%;
  height: auto;
  max-height: 512px;
  max-width: 512px;
`;

const GraphPlaceholder = memo(() => {
  const paths = [
    'M50.9 27.5C63.5 27.5 73.7 38 73.7 51S63.5 74.6 50.9 74.6 28.2 64 28.2 51s10.2-23.5 22.7-23.5z',
    'M74.7 51c0 5.8 4.5 10.5 10.1 10.5S95 56.8 95 51s-4.5-10.5-10.1-10.5S74.7 45.2 74.7 51z',
    'm-7.9-23.3c0 6.9 5.4 12.6 12.1 12.6 6.7 0 12.1-5.6 12.1-12.6s-5.4-12.6-12.1-12.6c-6.7 0-12.1 5.6-12.1 12.6z',
    'M48.7 17.3c0 5.5 4.3 10 9.7 10s9.7-4.5 9.7-10-4.3-10-9.7-10-9.7 4.5-9.7 10z',
    'M8.5 36.2c0 6.2 4.9 11.2 10.9 11.2s10.9-5 10.9-11.2S25.4 25 19.4 25 8.5 30 8.5 36.2z',
    'M5 60.4c0 6.7 5.2 12 11.6 12s11.6-5.4 11.6-12-5.2-12-11.6-12S5 53.7 5 60.4z',
    'm26.1 17.7c0 5.4 4.2 9.7 9.4 9.7s9.4-4.4 9.4-9.7c0-5.4-4.2-9.7-9.4-9.7s-9.4 4.4-9.4 9.7z',
    'M39.8 85c0 5.2 4.1 9.5 9.1 9.5S58 90.3 58 85c0-5.2-4.1-9.5-9.1-9.5s-9.1 4.3-9.1 9.5z',
    'M60.8 78.7c0 5.2 4 9.3 9 9.3s9-4.2 9-9.3-4-9.3-9-9.3-9 4.1-9 9.3z'
  ];
  const colors = Array.from({ length: paths.length }).map(() =>
    graphColorScale('redYellowGreen')(random(-1, 1, true))
  );

  return (
    <div className="d-flex justify-content-around flex-wrap">
      <div>
        <TableIcon css={svgStyle} />
      </div>
      <Separator />
      <div>
        <svg css={svgStyle} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
          {paths.map((path, index) => (
            <path key={path} d={path} fill={colors[index]} />
          ))}
        </svg>
      </div>
    </div>
  );
});

const EmptyStatePlaceholder = ({
  animationOrder,
  isLoading
}: {
  animationOrder: number;
  isLoading: boolean;
}) => {
  return (
    <Wrapper animationOrder={animationOrder}>
      <PlaceholderContainer>
        <Dimmer active={isLoading} loader>
          <p>
            <FormattedMessage id="exploring.results_placeholder_0" />
          </p>
          <p>
            <FormattedMessage
              id="exploring.results_placeholder_1"
              values={{ explore: <Icon name="search" /> }}
            />
          </p>
          <GraphPlaceholder />
        </Dimmer>
      </PlaceholderContainer>
    </Wrapper>
  );
};

export default EmptyStatePlaceholder;
