import Toast from 'components/toasts/Toast';
import { TextLength } from 'features/aiWriter/AiWriterSidebar/steps/textInspirations/TextLengthChips';
import { generateTextEntryFromApi } from 'features/textGenerator/utils/generateTextEntryMapper';
import { prepareGenerateTextsApiPayload } from 'features/textGenerator/utils/prepareGenerateTextsApiPayload';
import { trackingWrapper } from 'features/tracking/wrapper/TrackingWrapper';
import { invalidateWordsUsageQueries } from 'features/wordsUsage/invalidateWordsUsageQueries';
import WordEmbeddingAPI from 'services/api/wordEmbedding';
import { handleGenerateTextErrorsWithHyperlink } from 'services/api/wordEmbedding/errors';
import { GenerateTextErrorCode } from 'services/api/wordEmbedding/types';
import { invalidateCustomerAllLimitationsQueries } from 'services/backofficeIntegration/http/endpoints/customer/httpGetAllLimitations';
import { InformationDto } from 'services/backofficeIntegration/http/endpoints/infomration/httpGetInformationList';

export type CustomErrorHandler<T> = [T, () => void];

export type GenerateTextsConfig = {
  projectId?: string;
  audienceModelId: string;
  outputType: string;
  nTimes?: number;
  nTextItems?: number;
};

export type GenerateTextsInput = {
  text?: string;
  keywords?: string;
  keywords2?: string;
  suggestions?: string[];
  systemTonality?: string[];
  userTonality?: string[];
  length?: TextLength | null;
  personalityId?: number | null;
  informationList?: InformationDto[];
};

/**
 * #tech-debt https://app.clickup.com/t/3415rdr
 * These callbacks are not used in all places where generateTexts is called.
 * I suspect that this may lead to stale data in our redux store. This requires
 * further investigation.
 */
export type GenerateTextsCallbacks = {
  onCustomError?: CustomErrorHandler<GenerateTextErrorCode>;
};

export const generateTexts = async (
  config: GenerateTextsConfig,
  input: GenerateTextsInput,
  callbacks?: GenerateTextsCallbacks
) => {
  try {
    trackingWrapper.track('generateTexts', {
      url: window.location.pathname,
      input
    });
    const response = await WordEmbeddingAPI.generateTexts(
      prepareGenerateTextsApiPayload(config, input)
    );

    if (response.status) {
      invalidateWordsUsageQueries();
      invalidateCustomerAllLimitationsQueries();

      return Promise.resolve(generateTextEntryFromApi(response.data));
    } else {
      Toast.backendError(...handleGenerateTextErrorsWithHyperlink(response.message));

      if (callbacks?.onCustomError) {
        const [errorCode, onError] = callbacks.onCustomError;

        if (errorCode === response.message) {
          onError();
        }
      }

      return Promise.reject(response.message);
    }
  } catch (error) {
    Toast.apiError();

    return Promise.reject();
  }
};

/**
 * Returns an empty text if an error occurred
 */
export function generateTextSafely({
  outputType,
  text,
  audienceModelId,
  keywords,
  keywords2,
  systemTonality,
  userTonality,
  length,
  personalityId,
  informationList
}: {
  outputType: string;
  text: string;
  audienceModelId: string;
  keywords?: string;
  keywords2?: string;
  systemTonality: string[];
  userTonality: string[];
  length?: TextLength | null;
  personalityId?: number | null;
  informationList?: InformationDto[];
}) {
  const payload = prepareGenerateTextsApiPayload(
    { audienceModelId, nTimes: 1, nTextItems: 1, outputType },
    {
      text,
      keywords,
      systemTonality,
      userTonality,
      keywords2,
      length,
      personalityId,
      informationList
    }
  );

  return WordEmbeddingAPI.generateTexts(payload)
    .then(result => {
      if (!result.status) {
        return '';
      }

      return result.data.outputs[0]?.text ?? '';
    })
    .catch(() => '');
}
