import { makeCurrentUserQueryKeyPrefix } from 'features/currentUser/currentUserQueries';
import { AiWriterProjectTagType } from 'services/api/aiWriter/types';
import { backofficeClient } from 'services/backofficeIntegration/http/client/createBackofficeClient';
import { queryEndpoint } from 'services/backofficeIntegration/http/endpointDefinition';
import {
  PaginatedListParams,
  PaginatedListSuccessDto
} from 'services/backofficeIntegration/http/paginatedListEndpoint';
import { queryClient } from 'utils/ReactQueryClient';

type SuccessDto = PaginatedListSuccessDto<AiWriterProjectTagType>;

export type TagsPaginatedListParams = PaginatedListParams & {
  q?: string;
  view_mode?: string;
  color?: string;
  without_ai_writer_project_ids?: number[];
};

export const httpGetAllTags = queryEndpoint({
  makeQueryKey: (params: TagsPaginatedListParams) => {
    return [
      ...makeCurrentUserQueryKeyPrefix(),
      'projects',
      'ai-writer',
      'tags',
      'list',
      params
    ] as const;
  },
  callEndpoint: ({
    q,
    color,
    limit,
    view_mode,
    offset,
    without_ai_writer_project_ids
  }: TagsPaginatedListParams) =>
    backofficeClient
      .get<SuccessDto>('/tags', {
        params: { q, color, limit, offset, view_mode, without_ai_writer_project_ids }
      })
      .then(response => response.data)
});

export const invalidateGetAllTagsQuery = () => {
  return queryClient.invalidateQueries({
    queryKey: [...makeCurrentUserQueryKeyPrefix(), 'projects', 'ai-writer', 'tags', 'list']
  });
};
