import { makeCurrentUserQueryKeyPrefix } from 'features/currentUser/currentUserQueries';
import { BaseSuccessDto } from 'services/backofficeIntegration/http/BaseSuccessDto';
import { backofficeClient } from 'services/backofficeIntegration/http/client/createBackofficeClient';
import { queryEndpoint } from 'services/backofficeIntegration/http/endpointDefinition';
import { InformationCategoryDto } from 'services/backofficeIntegration/http/endpoints/infomration/httpGetInformationCategories';

type GetTagByIdProps = {
  id: number;
};

type SuccessDto = BaseSuccessDto<InformationCategoryDto>;

export const httpGetInformationCategoryById = queryEndpoint({
  makeQueryKey: (params: GetTagByIdProps) => {
    return [
      ...makeCurrentUserQueryKeyPrefix(),
      'informationCategories',
      ...Object.values(params)
    ] as const;
  },
  callEndpoint: ({ id }: GetTagByIdProps) => {
    return backofficeClient.get<SuccessDto>(`/information-categories/${id}`).then(response => {
      return response.data.data;
    });
  }
});
