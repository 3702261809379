import cx from 'classnames';
import React from 'react';
import { FormattedMessage } from 'react-intl';

type Props = {
  className?: string;
  isInline?: boolean;
  label?: string;
  rawLabel?: string;
} & React.HTMLProps<HTMLInputElement>;

const Radio = ({ className, isInline, label, rawLabel, ...props }: Props) => {
  const classes = cx(
    'custom-control custom-radio',
    { 'custom-control-inline': isInline },
    className
  );

  const input = <input type="radio" className={cx('custom-control-input', classes)} {...props} />;

  return label || rawLabel ? (
    <label className={classes}>
      {input}
      <span className="custom-control-label">
        {label ? <FormattedMessage id={label} /> : rawLabel}
      </span>
    </label>
  ) : (
    input
  );
};

export default Radio;
