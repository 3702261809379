import { PrimaryChip } from 'features/aiWriter/AiWriterSidebar/steps/textInspirations/NumberOfSuggestionsChips';
import React, { ReactElement, ReactNode, useRef } from 'react';
import styled from 'styled-components';

const ButtonContainer = styled.span`
  margin-right: 0.5em;
`;

interface ButtonObjectFieldProps {
  label: ReactNode;
  avatar?: ReactElement;
  selected: boolean;
  onClick: (ref: React.RefObject<HTMLDivElement>) => void;
  isDisabled?: boolean;
}

export const ButtonRadioField = ({
  label,
  avatar,
  selected,
  onClick,
  isDisabled
}: ButtonObjectFieldProps) => {
  const ref = useRef<HTMLDivElement>(null);
  return (
    <ButtonContainer>
      <PrimaryChip
        ref={ref}
        disabled={isDisabled}
        $selected={selected}
        onClick={() => onClick(ref)}
        label={label}
        avatar={avatar}
      />
    </ButtonContainer>
  );
};
