import store from '../store/store';

const shortenedFormatEn = new Intl.NumberFormat('en-US', {
  maximumFractionDigits: 1,
  // eslint-disable-next-line
  //@ts-ignore
  notation: 'compact',
  compactDisplay: 'long'
});

const shortenedFormatDe = new Intl.NumberFormat('de-DE', {
  maximumFractionDigits: 1,
  // eslint-disable-next-line
  //@ts-ignore
  notation: 'compact',
  compactDisplay: 'long'
});

export const toShortened = (num: number) => {
  const { language } = store.getState();
  return language === 'de' ? shortenedFormatDe.format(num) : shortenedFormatEn.format(num);
};
