import { useQuery } from '@tanstack/react-query';
import Toast from 'components/toasts/Toast';
import { LanguageMismatchWarning } from 'features/textGenerator/languageValidation/LanguageMismatchWarning';
import { debounce } from 'lodash';
import { useRef } from 'react';
import LanguageUtilityAPI from 'services/api/langaugeUtilities';

interface ValidatorParams {
  text: string;
  modelLanguage: string;
}

export const useLanguageMismatchWarning = (params: ValidatorParams) => {
  // Debounced validator must be shared between renders for it to work as expected
  const debouncedValidator = useRef(debounceValidator()).current;

  const result = useQuery(['text language validator', params], () => debouncedValidator(params));

  const showWarning = result.isSuccess && result.data === false;

  return showWarning ? <LanguageMismatchWarning /> : undefined;
};

function debounceValidator() {
  const pendingRejects = new Set<(error: unknown) => void>();

  const debounced = debounce(
    (resolve: (v: boolean) => void, reject: () => void, params: ValidatorParams) => {
      pendingRejects.delete(reject);
      validateTextLanguage(params).then(resolve, reject);
      cancelSkippedCalls();
    },
    500
  );

  return (params: ValidatorParams): Promise<boolean> => {
    return new Promise((resolve, reject) => {
      pendingRejects.add(reject);
      debounced(resolve, reject, params);
    });
  };

  /**
   * If we would not cancel skipped calls, they would generate
   * infinite promises. This is an issue when using react-query.
   * react-query keeps promises for a specific key until they
   * resolve/reject. Scenario that causes an issue:
   * 1) model language must be other than english
   * 2) paste "this text is long enough to demonstrate the issue"
   *    - the warning appears as expected
   * 3) trigger debounce
   *    - remove 2 chars in a quick succession
   *    - the input should be "this text is long enough to demonstrate the iss"
   *    - the warning appears as expected
   * 4) restore debounced value
   *    - type "u"
   *    - the input should be "this text is long enough to demonstrate the issu"
   *    - the warning should appear but it does not
   */
  function cancelSkippedCalls() {
    pendingRejects.forEach(reject => {
      reject(new Error('This was canceled because of a debounce'));
    });
    pendingRejects.clear();
  }
}

async function validateTextLanguage({ text, modelLanguage }: ValidatorParams): Promise<boolean> {
  const result = await identifyTextLanguage(text);
  return result === undefined || result === modelLanguage;
}

// BE is throwing an error if text is shorter than 20 chars (leading/trailing white spaces do not count)
const minTextLength = 20;

async function identifyTextLanguage(text: string): Promise<string | undefined> {
  const sanitizedText = text.trim();
  if (sanitizedText.length < minTextLength) {
    return undefined;
  }

  try {
    const response = await LanguageUtilityAPI.identifyText(sanitizedText);
    const languageIsIdentified = response.status && response.data.status === 'success';

    if (languageIsIdentified) {
      return response.data.language;
    }
  } catch (e) {
    Toast.apiError();
  }

  return undefined;
}
