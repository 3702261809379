export const defaultGraphFont = "'Roboto', 'Segoe UI', Verdana, Arial, sans-serif";

export const defaultTextColor = '#444444';

export const mainColor = '#2196F3';
export const blue = '#03a9f4';
export const blueDark = '#0288D1';
export const blueLight = 'rgba(2, 166, 242, 0.12)';
export const red = 'rgba(202, 75, 76, 0.8)';

export const mainText = 'rgba(0, 0, 0, 0.87)';
export const secondaryText = 'rgba(0,0,0,0.6)';
export const helpText = 'rgba(0, 0, 0, 0.5)';
export const border = 'rgba(0, 0, 0, 0.25)';
export const lightBackground = '#fafafa';
export const lightGreyBackground = '#e8e8e8';
export const primaryButtonTextColor = '#fff';

export const progressBarColors = {
  red: '#FF493B',
  yellow: '#FFC107',
  green: '#2ECE4C'
};

export const getRangeColors = (n: number) => {
  return n < 0.3
    ? progressBarColors.red
    : n < 0.7
    ? progressBarColors.yellow
    : progressBarColors.green;
};
