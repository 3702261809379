import { Divider, List } from '@mui/material';
import { ReactNode } from 'react';
import styled from 'styled-components';

type Props = {
  header?: ReactNode;
  children: ReactNode;
};

export const ThemedCategoryList = ({ header, children }: Props) => (
  <Root>
    {header && (
      <>
        {header}

        <StyledDivider />
      </>
    )}

    {children}
  </Root>
);

const Root = styled(List)`
  min-width: 240px;
  padding: ${({ theme }) => theme.spacings.three};
  overflow: auto;

  background: ${({ theme }) => theme.colors.primaryColorHover};
  border-radius: ${({ theme }) => theme.borderRadius.one};

  & > :not(:last-child) {
    margin-bottom: ${({ theme }) => theme.spacings.one};
  }
`;

const StyledDivider = styled(Divider)`
  margin-top: ${({ theme }) => theme.spacings.two};
  // !important is required to overwrite the & > ... list style
  margin-bottom: ${({ theme }) => theme.spacings.two} !important;
`;
