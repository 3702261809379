import { InformationCategoryDto } from 'services/backofficeIntegration/http/endpoints/infomration/httpGetInformationCategories';
import { InformationDto } from 'services/backofficeIntegration/http/endpoints/infomration/httpGetInformationList';
import useTr from 'utils/hooks/useTr';

type TagIndicatorProps = {
  tag: InformationCategoryDto;
  selectedInformationList: InformationDto[] | undefined;
};
export function TagIndicator(props: TagIndicatorProps) {
  const { tag, selectedInformationList } = props;

  const translate = useTr();

  const tagName = tag.id === 0 ? translate('information.apply_modal.co_category') : tag.name;

  if (!selectedInformationList) {
    return <span>{tagName}</span>;
  }

  const tagsCount = selectedInformationList.filter(info => info.category_id === tag.id).length;

  if (tagsCount === 0) {
    return <span>{tagName}</span>;
  }

  return <span>{`${tagName} (${tagsCount})`}</span>;
}
