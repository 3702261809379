import {
  APIDimension,
  APIMapperVariable,
  ScoreTextsGoalConfig,
  ScoreTextsRequest
} from 'services/api/wordEmbedding/types';

const mkScoreTextsPayload = (
  {
    embeddingModelId,
    audienceModelId,
    texts
  }: {
    texts: string[];
    embeddingModelId: string;
    audienceModelId?: string;
  },
  config?: {
    goalConfig: ScoreTextsGoalConfig;
    dimensions: APIDimension[];
    mappers: APIMapperVariable[];
  }
): ScoreTextsRequest => {
  const baseConfig = {
    texts,
    embeddingModelId
  };
  if (config) {
    return {
      ...baseConfig,
      goalConfig: config.goalConfig,
      dimensions: config?.dimensions,
      mappers: config?.mappers
    };
  }

  if (audienceModelId) {
    return {
      ...baseConfig,
      goalConfig: {
        model_rank_score: 0,
        dimensions: [],
        mappers: [{ id: audienceModelId, weight: 1 }]
      },
      dimensions: [],
      mappers: [{ id: audienceModelId, name: 'success', variable: 'openrate' }]
    };
  }

  return {
    ...baseConfig,
    goalConfig: {
      model_rank_score: 0,
      dimensions: [],
      mappers: []
    },
    dimensions: [],
    mappers: []
  };
};

export default mkScoreTextsPayload;
