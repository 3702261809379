import LocalStorageKey from 'config/localStorageKey';

// don't use if you stored raw strings
export const getParsedStorageItem = <T>(key: LocalStorageKey | string, defaultValue: T, storage: Storage): T => {
  try {
    const value = storage.getItem(key);
    return value ? (JSON.parse(value) as T) : defaultValue;
  } catch (err) {
    return defaultValue;
  }
};
