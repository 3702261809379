import { intersection } from 'lodash';
import matchSorter, { rankings } from 'match-sorter';
import { FilterType } from 'react-table';

export const fuzzyWildcardFilter: FilterType<never> = (rows, id, filterValue) => {
  if (filterValue.includes('*')) {
    const escapeRegex = (val: string) => val.replace(/([.*+?^=!:${}()|[]\/\\])/g, '\\$1');
    const regExp = new RegExp(
      `^${filterValue.toLowerCase().split('*').map(escapeRegex).join('.*')}$`
    );

    return rows.filter(row => {
      // `id` is an array of strings, prev implementation worked because
      // it contained one element and JS does implicit types conversion
      // I don't know if it can ever have more then one item but prev
      // implementation would fail in such case. I just make it explicit to satisfy types
      const rowValue = row.values[id[0] ?? ''];
      return rowValue !== undefined ? regExp.test(`${rowValue}`.toLowerCase()) : true;
    });
  } else {
    return matchSorter(rows, filterValue, {
      keys: [`values.${id}`],
      threshold: rankings.STRING_CASE_ACRONYM
    });
  }
};

export const stringsArrayFilter: FilterType<never> = (rows, id, filterValue) => {
  // I'm adding this for typesafety. otherwise `filterValue` is `any`
  if (typeof filterValue !== 'string') {
    return rows;
  }

  const filterWords = filterValue
    .split(',')
    .map(i => i.toLowerCase().trim())
    .filter(i => i);

  return rows.filter(row => {
    // `id` is an array of strings, prev implementation worked because
    // it contained one element and JS does implicit types conversion
    // I don't know if it can ever have more then one item but prev
    // implementation would fail in such case. I just make it explicit to satisfy types
    const rowValue = row.values[id[0] ?? ''].map((word: string) => word.toLowerCase());
    return rowValue !== undefined
      ? intersection(filterWords, rowValue).length === filterWords.length
      : true;
  });
};

export const filterTypesDict = {
  text: fuzzyWildcardFilter,
  stringsArray: stringsArrayFilter
};
