import { ColorLensRounded, KeyboardArrowRight } from '@mui/icons-material';
import DeleteIcon from '@mui/icons-material/Delete';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import { ClickAwayListener, ListItemIcon, Menu, MenuItem } from '@mui/material';
import FlexContainer from 'components/FlexContainer';
import { ColorPickerMenu } from 'features/aiWriter/AiWriterProjectOverview/tags/ColorPickerMenu';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { AiWriterProjectTagType } from 'services/api/aiWriter/types';
import { useAppSelector } from 'store/hooks';
import { useMenu } from 'utils/hooks/useMenu';

export const TagRowMenu = (props: {
  tag: AiWriterProjectTagType;
  anchorEl: HTMLElement | null;
  isOptionsOpen: boolean;
  handleCloseOption: () => void;
  renameClick: (e: React.MouseEvent<HTMLLIElement, MouseEvent>) => void;
  deleteTag: (ids: number[]) => void;
  onColorSelection: (color: string) => void;
}) => {
  const {
    tag,
    renameClick,
    onColorSelection,
    deleteTag,
    anchorEl,
    isOptionsOpen,
    handleCloseOption
  } = props;

  const customerId = useAppSelector(state => state.customer.id);

  const handleRenameClick = (e: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
    renameClick(e);
  };

  const {
    anchorEl: colorsAnchorEl,
    isOpen: isColorsMenuOpen,
    onTriggerClick: onColorIconTriggerClick,
    onMenuClose: onColorsMenuClose
  } = useMenu();

  return (
    <ClickAwayListener onClickAway={handleCloseOption}>
      <Menu
        anchorEl={anchorEl}
        open={isOptionsOpen}
        onClose={handleCloseOption}
        sx={{ minWidth: '200px' }}
      >
        {customerId === tag.customer_id && (
          <MenuItem onClick={handleRenameClick}>
            <ListItemIcon>
              <ModeEditIcon fontSize="small" />
            </ListItemIcon>
            <FormattedMessage id="common.rename" />
          </MenuItem>
        )}
        {customerId === tag.customer_id && (
          <MenuItem onClick={onColorIconTriggerClick}>
            <FlexContainer direction="row" gap="medium" alignItems="center">
              <FlexContainer direction="row" alignItems="center">
                <ListItemIcon>
                  <ColorLensRounded fontSize="small" />
                </ListItemIcon>
                <FormattedMessage id="tags.modal.color_picker.label" />
              </FlexContainer>
              <FlexContainer alignItems="center">
                <KeyboardArrowRight fontSize="small" />
              </FlexContainer>
            </FlexContainer>
          </MenuItem>
        )}
        {customerId === tag.customer_id && (
          <MenuItem
            onClick={e => {
              e.stopPropagation();
              deleteTag([tag.id]);
              handleCloseOption();
            }}
          >
            <ListItemIcon>
              <DeleteIcon fontSize="small" />
            </ListItemIcon>
            <FormattedMessage id="common.delete" />
          </MenuItem>
        )}
        <ColorPickerMenu
          anchorEl={colorsAnchorEl}
          isOpen={isColorsMenuOpen}
          handleCloseMenu={onColorsMenuClose}
          color={tag.color ?? undefined}
          onClick={onColorSelection}
        />
      </Menu>
    </ClickAwayListener>
  );
};
