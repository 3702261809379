import { BaseSuccessDto } from 'services/backofficeIntegration/http/BaseSuccessDto';
import { backofficeClient } from 'services/backofficeIntegration/http/client/createBackofficeClient';
import { mutationEndpoint } from 'services/backofficeIntegration/http/endpointDefinition';

type SuccessDto = BaseSuccessDto<never>;

export type AssignTagRequestParams = {
  tagId: number;
  projectId: number;
};

export const httpAssignTag = mutationEndpoint({
  callEndpoint: (params: AssignTagRequestParams) =>
    backofficeClient
      .post<SuccessDto>(`/tags/${params.tagId}/ai-writer-projects/${params.projectId}`)
      .then(response => response.data)
});
