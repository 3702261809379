import { makeCurrentUserQueryKeyPrefix } from 'features/currentUser/currentUserQueries';
import { backofficeClient } from 'services/backofficeIntegration/http/client/createBackofficeClient';
import { queryEndpoint } from 'services/backofficeIntegration/http/endpointDefinition';
import { InformationType } from 'services/backofficeIntegration/http/endpoints/infomration/httpCreateInformation';
import {
  PaginatedListParams,
  PaginatedListSuccessDto
} from 'services/backofficeIntegration/http/paginatedListEndpoint';
import { queryClient } from 'utils/ReactQueryClient';

export type GetInformationListSuccessDto = PaginatedListSuccessDto<InformationDto>;

export type InformationDto = {
  id: number;
  type: InformationType;
  name: string;
  description: string;
  content: string;
  is_active: boolean;
  category_id: number;
  customer_id: number;
  author_name: string;
  category_name: string;
  updated_at: string;
  file_name?: string;
  category_author_id: number;
};

export type GetInformationListParams = PaginatedListParams & {
  search?: string;
  author_id?: number;
  category_id?: number;
  sort?: 'updated_at';
  direction?: 'asc' | 'desc';
  // temporary solution
  is_paginated?: 1 | 0;
};

export const httpGetInformationList = queryEndpoint({
  makeQueryKey: (params: GetInformationListParams) => {
    return [
      ...makeCurrentUserQueryKeyPrefix(),
      'information-list',
      ...Object.values(params)
    ] as const;
  },
  callEndpoint: (params: GetInformationListParams) => {
    return backofficeClient
      .get<GetInformationListSuccessDto>('/informations', { params })
      .then(response => response.data);
  }
});

export function invalidateInformationListQuery() {
  return queryClient.invalidateQueries({
    queryKey: [...makeCurrentUserQueryKeyPrefix(), 'information-list']
  });
}
