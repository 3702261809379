import { Card, Typography } from '@mui/material';
import FlexContainer from 'components/FlexContainer';
import { ClickableScoreLabel } from 'features/aiWriter/AiWriterSidebar/steps/flashScore/ClickableScoreLabel';
import { StretchedCardContent } from 'features/aiWriter/AiWriterSidebar/steps/flashScore/FlashScoreAnalyzeView';
import { ReadabilityCircularProgress } from 'features/aiWriter/AiWriterSidebar/steps/flashScore/progress-circles';
import { FlashScoreTextRating } from 'features/flashScore/useScoreTexts';
import FormattedMessage from 'features/i18n/FormattedMessage';
import styled from 'styled-components';

type Props = {
  readability: FlashScoreTextRating;
};

export const ReadabilityCard = ({ readability }: Props) => (
  <Card variant="outlined">
    <StretchedCardContent>
      <ReadabilityFlexContainer>
        <FlexContainer direction="column" alignItems="center" gap="small">
          <Typography fontWeight="bold">
            <FormattedMessage id="flashScore.readability.label" />
          </Typography>

          <LargeReadabilityCircularProgress
            value={readability.percentage}
            label={<ClickableScoreLabel rating={readability} />}
          />
        </FlexContainer>

        <FlexContainer gap="small">
          <Typography align="left" variant="body1">
            <FormattedMessage
              id={`aiWriter.inspirations.flashScore.readability.explainer.grade_${readability.grade}.headline`}
              values={{
                b: chunks => <strong>{chunks}</strong>
              }}
            />
          </Typography>

          <Typography align="left" variant="body2">
            <FormattedMessage
              id={`aiWriter.inspirations.flashScore.readability.explainer.grade_${readability.grade}.content`}
              values={{
                b: chunks => <strong>{chunks}</strong>
              }}
            />
          </Typography>

          <Typography align="left" variant="body2">
            <FormattedMessage
              id={`aiWriter.inspirations.flashScore.readability.explainer.grade_${readability.grade}.footer`}
              values={{
                b: chunks => <strong>{chunks}</strong>
              }}
            />
          </Typography>
        </FlexContainer>
      </ReadabilityFlexContainer>
    </StretchedCardContent>
  </Card>
);

const ReadabilityFlexContainer = styled.div`
  height: 100%;

  display: grid;
  gap: ${({ theme }) => theme.spacings.small};
  grid-template-columns: 1fr 1fr;
`;

const LargeReadabilityCircularProgress = styled(ReadabilityCircularProgress).attrs({
  CircularProgressProps: {
    size: 120,
    thickness: 4
  }
})``;
