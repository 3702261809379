import { getIsAppReady, getIsAuthenticated } from 'features/customer/store/selectors';
import useSendInitialTrackingData from 'features/services/hooks/useSendInitialTrackingData';
import useSendUserData from 'features/services/hooks/useSendUserData';
import useStartProfitwell from 'features/services/hooks/useStartProfitwell';
import { ReactElement, ReactNode, useEffect } from 'react';
import { useAppSelector } from 'store/hooks';
import useUpgradeOnInit from 'utils/hooks/useUpgradeOnInit';

type Props = {
  children: ReactNode;
};

export const InitializationProvider = ({ children }: Props): ReactElement => {
  const isAppReady = useAppSelector(getIsAppReady);
  const isAuthenticated = useAppSelector(getIsAuthenticated);

  const startProfitwell = useStartProfitwell();
  const upgradeOnInit = useUpgradeOnInit();
  const sendUserData = useSendUserData();

  useSendInitialTrackingData();

  /**
   * Note: The following effects are split because they may trigger
   *       different actions and are not dependent on each other.
   *       This is to avoid unnecessary re-renders.
   */

  useEffect(() => {
    if (!isAuthenticated || !isAppReady) {
      return;
    }

    startProfitwell();
  }, [isAuthenticated, isAppReady, startProfitwell]);

  useEffect(() => {
    if (!isAuthenticated || !isAppReady) {
      return;
    }

    upgradeOnInit();
  }, [isAuthenticated, isAppReady, upgradeOnInit]);

  useEffect(() => {
    if (!isAuthenticated || !isAppReady) {
      return;
    }

    sendUserData();
  }, [isAuthenticated, isAppReady, sendUserData]);

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
};

export default InitializationProvider;
