import { Typography } from '@mui/material';
import { IntlMessageKeys } from 'features/i18n/IntlMessageKeys';
import { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

type Props = {
  headline: IntlMessageKeys;
  children?: ReactNode;

  button?: ReactNode;
};

const InformationBlock = ({ headline, children, button }: Props) => (
  <Root>
    <Header>
      <Typography variant="subtitle1">
        <FormattedMessage id={headline} />
      </Typography>
      {button && button}
    </Header>
    {children && <Typography variant="body2">{children}</Typography>}
  </Root>
);

const Root = styled.div`
  & ~ & {
    margin-top: ${({ theme }) => theme.spacings.medium};
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
`;

export default InformationBlock;
