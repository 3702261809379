import useEditorText from 'features/aiWriter/AiWriterTextEditor/hooks/useEditorText';
import { useCallback, useMemo } from 'react';
import { getWords } from 'utils/getWordCount';

export default function useCountWordOccurrence() {
  const text = useEditorText();
  const wordsLowerCase = useMemo(() => {
    // Replace non-word characters which would over wise be part of a word
    // e.g. "Test: test" will be ["Test:", "test"] and therefore the condition
    //      `word === wordLowerCase` will never be true for both.
    // Note: \u00C0-\u017FA defines a range of characters from À to Ẻ to also catch up unicode characters.
    const textWithoutSpecialCharacters = text.replace(/[^\w\s\u00C0-\u017FA]/gi, ' ');

    return getWords(textWithoutSpecialCharacters).map(word => word.toLocaleLowerCase());
  }, [text]);

  // TODO: This is called for each word, which may be 20+.
  //       In case it's a longer text, it may be a performance issue.
  return useCallback(
    (word: string) => {
      const wordLowerCase = word.toLocaleLowerCase();
      let wordCount = 0;

      wordsLowerCase.forEach(word => {
        if (word === wordLowerCase) {
          wordCount++;
        }
      });

      return wordCount;
    },
    [wordsLowerCase]
  );
}
