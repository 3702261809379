import { Button, Typography } from '@mui/material';
import { ReactComponent as SadFaceIcon } from 'assets/sad-face.svg';
import FlexContainer from 'components/FlexContainer';
import { getRoutePath } from 'config/routes';
import useUpdateSubscriptionModal from 'features/updateSubscriptionModal/hook/useUpdateSubscriptionModal';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const Root = styled(FlexContainer)`
  max-width: ${({ theme }) => theme.container.md};
  margin: 0 auto;
  padding: ${({ theme }) => theme.spacings.large} ${({ theme }) => theme.spacings.medium};
`;

const Highlight = styled.span`
  color: #ff9800; // #color-tech-debt
`;

const RestrictedPage = () => {
  const showUpgradeSubscriptionModal = useUpdateSubscriptionModal();

  return (
    <Root alignItems="center" gap="xlarge">
      <SadFaceIcon />
      <Typography component="div" variant="h2" align="center">
        <FormattedMessage id="restricted_page.title" />
      </Typography>
      <Typography component="div" variant="h6" align="center">
        <FormattedMessage
          id="restricted_page.message"
          values={{ br: <br />, highlight: (t: string) => <Highlight>{t}</Highlight> }}
        />
      </Typography>
      <FlexContainer alignItems="center" gap="medium">
        <Button variant="contained" onClick={() => showUpgradeSubscriptionModal()}>
          <FormattedMessage id="restricted_page.upgrade_button.label" />
        </Button>
        <Button component={Link} to={getRoutePath('home')}>
          <FormattedMessage id="restricted_page.home_button.label" />
        </Button>
      </FlexContainer>
    </Root>
  );
};

export default RestrictedPage;
