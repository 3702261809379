import { ListItem, ListItemButton, ListItemIcon } from '@mui/material';
import { ColoredTypography } from 'components/ColoredTypography';
import { ComponentProps, ReactNode } from 'react';
import styled from 'styled-components';

type Props = ComponentProps<typeof ListItemButton> & {
  children: ReactNode;
  icon?: ReactNode;
};

export const ThemedCategoryListItem = ({ children, icon, ...rest }: Props) => (
  <Root disablePadding={true}>
    <StyledListItemButton {...rest}>
      {icon && <StyledListItemIcon>{icon}</StyledListItemIcon>}

      <ColoredTypography variant="body2">{children}</ColoredTypography>
    </StyledListItemButton>
  </Root>
);

const Root = styled(ListItem).attrs({ disablePadding: true })``;

const StyledListItemButton = styled(ListItemButton)`
  border-radius: ${({ theme }) => theme.borderRadius.one};
  gap: ${({ theme }) => theme.spacings.two};

  padding: ${({ theme }) => `${theme.spacings.one} ${theme.spacings.two}`};

  &.Mui-selected {
    background: ${({ theme }) => theme.colors.primaryColorSelected};
  }

  &:hover:not(.Mui-selected) {
    background: ${({ theme }) => theme.colors.primaryColorHover};
  }
`;

const StyledListItemIcon = styled(ListItemIcon)`
  &.MuiListItemIcon-root {
    min-width: 24px;
  }
`;
