import { Chip } from '@mui/material';
import styled from 'styled-components';

export const TextLengthChip = styled(Chip)`
  &&.MuiChip-clickableColorInfo {
    color: ${({ theme }) => theme.colors.white};
    background-color: ${({ theme }) => theme.colors.primary};
  }
  height: ${({ theme }) => theme.spacings.large};
  border-radius: 100px;
  background-color: ${({ theme }) => theme.colors.blackHoverOverlay};
  color: ${({ theme }) => theme.colors.blackHighEmphasis};
  margin-right: ${({ theme }) => theme.spacings.xsmall};
  min-width: 72px;
  padding: ${({ theme }) => theme.spacings.xsmall};
`;
