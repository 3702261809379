import { APIMapperVariable, ScoreTextsResponse } from 'services/api/wordEmbedding/types';
import { backofficeClient } from 'services/backofficeIntegration/http/client/createBackofficeClient';
import { mutationEndpoint } from 'services/backofficeIntegration/http/endpointDefinition';

type ScoreGeneratedTextsPayload = {
  texts: string[];
  embedding_model_id: string;
  mappers: APIMapperVariable[];
};

export const httpFlashScoreGeneratedTexts = mutationEndpoint({
  callEndpoint: (params: ScoreGeneratedTextsPayload) =>
    backofficeClient
      .post<ScoreTextsResponse>('/text-processor/score-generated-texts', params)
      .then(response => response.data)
});
