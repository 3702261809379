import { ChevronLeft } from '@mui/icons-material';
import { Button, Stack, Typography } from '@mui/material';
import backgroundDoodleOrnament from 'assets/background-doodle.png';
import awards2Image from 'assets/home/product-info-page/awards-2.png';
import productInfoImage from 'assets/home/product-info-page/research-flash-product-info.png';
import { Container } from 'components/Container';
import FlexContainer from 'components/FlexContainer';
import { Ornament } from 'components/Ornament';
import { getRouteUrl } from 'config/routes';
import FormattedMessage from 'features/i18n/FormattedMessage';
import { useEnableSidebarOnMount } from 'features/pageSidebar/useSidebarStore';
import { Badges } from 'pages/Badges';
import { ReactElement } from 'react';
import styled from 'styled-components';
import { mobileWindowWidthCSS } from 'utils/hooks/useMobileVersion';
import useTr from 'utils/hooks/useTr';

const ResearchFlashInfoPage = (): ReactElement => {
  const translate = useTr();

  useEnableSidebarOnMount();

  return (
    <Root>
      <InnerRoot>
        <Button variant="text" href={getRouteUrl('home')} startIcon={<ChevronLeft />}>
          <FormattedMessage id="common.back" />
        </Button>

        <ContentContainer>
          <LeftContainer>
            <Typography variant="h4" component="h1">
              <FormattedMessage
                id="home.research_flash_info.title"
                values={{
                  strong: (text: string) => <strong>{text}</strong>
                }}
              />
            </Typography>
            <Typography variant="body1">
              <FormattedMessage id="home.research_flash_info.description" />
            </Typography>
            <FlexContainer direction="row" gap="medium">
              <Button
                variant="contained"
                color="primary"
                href={translate('home.research_flash_info.cta.book_a_demo.link')}
                target="_blank"
              >
                <FormattedMessage id="home.research_flash_info.cta.book_a_demo" />
              </Button>

              <Button
                variant="text"
                color="primary"
                href={translate('home.research_flash_info.cta.learn_more.link')}
                target="_blank"
              >
                <FormattedMessage id="home.research_flash_info.cta.learn_more" />
              </Button>
            </FlexContainer>
          </LeftContainer>

          <RightContainer>
            <ProductImage src={productInfoImage} alt="Product Info" />
          </RightContainer>
        </ContentContainer>

        <FooterContainer>
          <Typography variant="h6">
            <FormattedMessage id="home.research_flash_info.title_awards" />
          </Typography>

          <AwardImagesContainerContainer>
            <Badges />
            <img src={awards2Image} alt="Awards" />
          </AwardImagesContainerContainer>
        </FooterContainer>
      </InnerRoot>

      <Ornaments>
        <Ornament src={backgroundDoodleOrnament} bottom="0%" right="0%" />
      </Ornaments>
    </Root>
  );
};

const Root = styled(Container).attrs({ size: 'xl' })`
  position: relative;
  height: 100%;
`;

const InnerRoot = styled(Stack).attrs({ spacing: 1, alignItems: 'flex-start' })`
  height: 100%;

  padding-bottom: 40px;
`;

const ContentContainer = styled(FlexContainer).attrs({ direction: 'row', gap: 40 })`
  @media (max-width: ${mobileWindowWidthCSS}) {
    flex-direction: column;
  }
`;

const LeftContainer = styled(FlexContainer).attrs({
  direction: 'column',
  gap: 'medium',
  alignItems: 'flex-start'
})`
  flex: 0 1 420px;

  @media (max-width: ${mobileWindowWidthCSS}) {
    flex-basis: auto;
  }
`;

const RightContainer = styled.div`
  flex: 1;

  @media (max-width: ${mobileWindowWidthCSS}) {
    margin-inline: auto;
  }
`;

const Ornaments = styled.div`
  @media (max-width: ${mobileWindowWidthCSS}) {
    display: none;
  }
`;

const ProductImage = styled.img``;

const FooterContainer = styled(Stack).attrs({ spacing: 2, alignItems: 'center' })`
  width: 100%;
  // Need to overwrite margin-top from parent stack
  margin-top: auto !important;
`;

const AwardImagesContainerContainer = styled(FlexContainer).attrs({
  direction: 'row',
  justifyContent: 'center',
  gap: 'medium'
})`
  width: 100%;

  @media (max-width: ${mobileWindowWidthCSS}) {
    flex-direction: column;
  }
`;

export default ResearchFlashInfoPage;
