import { Typography } from '@mui/material';
import ProductTour from 'assets/product-tour.svg';
import FlexContainer from 'components/FlexContainer';
import { SmallerSection, Tile } from 'components/layout/toolbar/modals/HelpAndInfo/styles';
import { HomeOnboardingFeatureCard } from 'features/homePage/HomeOnboardingFeatureCard';
import { useShouldShowOnboardingChecklist } from 'features/onboardingJourney/checklist/ChecklistButton';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import useTr from 'utils/hooks/useTr';

type Props = {
  onClose?: () => void;
};

export const GettingStarted = ({ onClose }: Props) => {
  const shouldShowOnboardingChecklist = useShouldShowOnboardingChecklist();
  const translate = useTr();

  return (
    <>
      <SmallerSection>
        <Typography variant="h6">
          <FormattedMessage id="layout.sidebar.actions.help.getting_started.title" />
        </Typography>
        {shouldShowOnboardingChecklist && <HomeOnboardingFeatureCard onClose={onClose} />}
      </SmallerSection>
      <ArticleAndTour>
        <Tile
          onClick={() =>
            window.open(
              translate('layout.sidebar.actions.help.getting_started.tour.link'),
              '_blank'
            )
          }
        >
          <FlexContainer direction="row" justifyContent="space-between">
            <TourDescription>
              <Typography variant="subtitle1">
                <FormattedMessage id="layout.sidebar.actions.help.getting_started.tour.title" />
              </Typography>
              <Typography variant="body2">
                <FormattedMessage
                  id="layout.sidebar.actions.help.getting_started.tour.description"
                  values={{ b: (text: string) => <strong>{text}</strong>, br: <br /> }}
                />
              </Typography>
            </TourDescription>
            <img src={ProductTour} alt="product-tour" />
          </FlexContainer>
        </Tile>
        <Tile
          onClick={() =>
            window.open(
              translate('layout.sidebar.actions.help.getting_started.article_link'),
              '_blank'
            )
          }
        >
          <ArticleContainer>
            <Typography variant="caption">
              <FormattedMessage id="layout.sidebar.actions.help.getting_started.article.category" />
            </Typography>
            <Typography variant="subtitle1">
              <FormattedMessage id="layout.sidebar.actions.help.getting_started.article.title" />
            </Typography>
            <Typography variant="caption">
              <FormattedMessage id="layout.sidebar.actions.help.getting_started.article.author" />
            </Typography>
          </ArticleContainer>
          <Typography variant="body2">
            <FormattedMessage id="layout.sidebar.actions.help.getting_started.article.preview" />
          </Typography>
        </Tile>
      </ArticleAndTour>
    </>
  );
};

const TourDescription = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  word-wrap: break-word;
`;

const ArticleAndTour = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${({ theme }) => theme.spacings.medium};
  width: 100%;
  padding-bottom: ${({ theme }) => theme.spacings.xmedium};
`;

const ArticleContainer = styled.div`
  margin-bottom: ${({ theme }) => theme.spacings.small};
`;
