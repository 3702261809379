import { Typography, TypographyTypeMap } from '@mui/material';
import { ReactNode } from 'react';
import styled, { DefaultTheme } from 'styled-components';

type ThemeColor = keyof DefaultTheme['colors'];

type Props = {
  children: ReactNode;
  color?: ThemeColor;
} & Omit<TypographyTypeMap<Record<string, unknown>, 'span'>['props'], 'color'>;

export const ColoredTypography = ({ color, children, ...rest }: Props) => (
  <Root $color={color} {...rest}>
    {children}
  </Root>
);

const Root = styled(Typography)<{ $color?: ThemeColor }>`
  && {
    color: ${({ theme, $color }) => ($color !== undefined ? theme.colors[$color] : 'inherit')};
  }
`;
