import { WordsStats } from 'features/aiTester/utils/getTokensFromText';

type StatusInfo = {
  tooltip: string;
  iconName: string;
  status: 'UNKNOWN' | 'ERROR' | 'WARNING' | 'OK';
  color: string;
};

const getStatusInfo = (wordsStats: WordsStats): StatusInfo => {
  if (wordsStats.total === 0) {
    return {
      tooltip: 'testing.text_rating.no_text',
      iconName: 'help-circle',
      status: 'UNKNOWN',
      color: '#343a40'
    };
  }
  if (wordsStats.available === 0) {
    const response = {
      tooltip: '',
      status: 'ERROR' as const,
      iconName: 'alert-octagon',
      color: '#cd201f'
    };
    if (wordsStats.faulty > 0) {
      return {
        ...response,
        tooltip: 'testing.text_rating.only_faulty'
      };
    }
    if (wordsStats.stopWords > 0) {
      return {
        ...response,
        tooltip: 'testing.text_rating.only_stop_words'
      };
    }
    return {
      ...response,
      tooltip: 'testing.text_rating.unexpected'
    };
  }
  if (wordsStats.faulty > 0) {
    return {
      status: 'WARNING',
      tooltip: 'testing.text_rating.some_faulty',
      iconName: 'alert-triangle',
      color: '#f1c40f'
    };
  }
  return {
    status: 'OK',
    tooltip: 'testing.text_rating.ok',
    iconName: 'check',
    color: '#5eba00'
  };
};

export default getStatusInfo;
