import Tooltip from '@mui/material/Tooltip';
import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

type Props = {
  text: string;
};

const ElipsisTooltip = ({ text }: Props) => {
  const textElementRef = useRef<HTMLDivElement | null>(null);
  const [hoverStatus, setHover] = useState(false);

  const compareSize = () => {
    if (textElementRef.current) {
      const compare = textElementRef.current.scrollWidth > textElementRef.current.clientWidth;
      setHover(compare);
    }
  };

  useEffect(() => {
    compareSize();
    window.addEventListener('resize', compareSize);
    return window.addEventListener('resize', compareSize);
  }, []);

  return (
    <Tooltip title={text} disableHoverListener={!hoverStatus}>
      <ElipsisTextfield ref={textElementRef}>{text}</ElipsisTextfield>
    </Tooltip>
  );
};

const ElipsisTextfield = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 40vw;
`;

export default ElipsisTooltip;
