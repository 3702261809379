import { useModal } from 'components/modals';
import { getIsAppReady, getIsAuthenticated } from 'features/customer/store/selectors';
import { useGetTimeUntilTrialExpiration } from 'features/pricing/hook/useGetTimeUntilTrialExpiration';
import { useIsOnTrial } from 'features/pricing/hook/useIsOnTrial';
import { useEffect } from 'react';
import { useAppSelector } from 'store/hooks';

export const TrialExpiryReminderModalTrigger = () => {
  const isAuthenticated = useAppSelector(getIsAuthenticated);
  const isAppReady = useAppSelector(getIsAppReady);
  const isOnTrial = useIsOnTrial();
  const { daysLeft } = useGetTimeUntilTrialExpiration();
  const { showModal } = useModal();

  useEffect(() => {
    if (!isAuthenticated || !isAppReady) {
      return;
    }

    if (isOnTrial && daysLeft === 0) {
      showModal('TRIAL_EXPIRY_REMINDER', {
        size: 675
      });
    }
  }, [daysLeft, isAppReady, isAuthenticated, isOnTrial, showModal]);

  return null;
};
