import { useMutation } from '@tanstack/react-query';
import { invalidateCurrentPersonalityQuery } from 'features/aiWriter/AiWriterSidebar/steps/chat/useCurrentPersonalityQuery';
import { invalidatePersonalitiesQuery } from 'features/aiWriter/AiWriterSidebar/steps/chat/usePersonalitiesQuery';
import { httpDeletePersonality } from 'services/backofficeIntegration/http/endpoints/personalities/httpDeletePersonality';

export const useDeleteChatPersonality = () =>
  useMutation({
    mutationFn: (personalityId: number) => httpDeletePersonality.callEndpoint(personalityId),
    onSuccess: () => {
      invalidateCurrentPersonalityQuery();
      invalidatePersonalitiesQuery();
    }
  });
