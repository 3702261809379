import useRedirectWhenAuthenticated from 'components/auth/hooks/useRedirectWhenAuthenticated';
import { RecaptchaProvider } from 'components/RecaptchaProvider';
import { FreeRegistrationPanel } from 'components/register/FreeRegistrationPanel';

export const FreeRegistrationPage = () => {
  useRedirectWhenAuthenticated();

  return (
    <RecaptchaProvider>
      <FreeRegistrationPanel />
    </RecaptchaProvider>
  );
};
