import cx from 'classnames';
import { debounce } from 'lodash';
import React, { Component, ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import { LineStyle } from 'types/LineStyle';
import { hexToRgb, RGBColor, rgbToHex } from 'utils/colorScales';

import Button from '../base/Button';
import Col from '../base/Col';
import Input from '../base/Input';
import Label from '../base/Label';
import RadioButtonGroup from '../base/RadioButtonGroup';
import Row from '../base/Row';
import Slider from '../base/Slider';
import styles from './ColorPicker.module.scss';

type SliderRowProps = { label: string; className?: string; children: ReactNode };

const SliderRow = ({ label, children, className }: SliderRowProps) => (
  <Row className={styles.row}>
    <Label className="col-form-label col-3 pr-1">
      <FormattedMessage id={label} />
    </Label>
    <Col size={9} className={cx('pl-0', className)}>
      {children}
    </Col>
  </Row>
);

export type ColorPickerProps = {
  color: string;
  onConfirm: (color: string) => void;
  lineStyle?: LineStyle;
  setLineStyle?: (style: LineStyle) => void;
  thickness?: number;
  setThickness?: (value: number) => void;
};

type State = {
  rgb: RGBColor;
  hex: string;
  inputHex: string;
  thickness?: number; // this is here only because of UX - I don't want to update store on every change
};

type Props = ColorPickerProps & { innerRef: React.Ref<HTMLDivElement> };

class ColorPicker extends Component<Props, State> {
  state = {
    rgb: hexToRgb(this.props.color) as RGBColor,
    hex: this.props.color,
    inputHex: this.props.color,
    thickness: this.props.thickness
  };

  setColorCompound = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    this.setState(state => {
      const newRgb = {
        ...state.rgb,
        [name as keyof RGBColor]: Number(value)
      };
      const hex = rgbToHex(newRgb);

      return {
        rgb: newRgb,
        hex,
        inputHex: hex
      };
    });
  };

  setHexColor = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newHex = event.target.value;
    this.setState(
      {
        inputHex: newHex
      },
      () => {
        const rgb = hexToRgb(newHex);
        if (rgb) {
          this.setState({
            rgb,
            hex: rgbToHex(rgb)
          });
        }
      }
    );
  };

  confirmColor = () => this.props.onConfirm(this.state.hex);

  setStoreThicknessDirectly = (value: number) => {
    const { setThickness } = this.props;
    if (setThickness) {
      setThickness(value);
    }
  };

  setStoreThickness = debounce(this.setStoreThicknessDirectly, 300);

  render() {
    const { innerRef, lineStyle, setLineStyle } = this.props;
    const {
      rgb: { r, g, b },
      hex,
      inputHex,
      thickness
    } = this.state;
    return (
      <div className={styles.container} ref={innerRef}>
        <Row className={styles.colorPickerContainer}>
          <Col className="d-flex flex-column justify-content-center">
            <SliderRow label="common.hex">
              <Input name="color" value={inputHex} onChange={this.setHexColor} maxLength={7} />
            </SliderRow>
            <SliderRow label="common.red">
              <Slider
                max={255}
                name="r"
                value={r}
                onChange={this.setColorCompound}
                inputClassName="pr-3"
              />
            </SliderRow>
            <SliderRow label="common.green">
              <Slider
                max={255}
                name="g"
                value={g}
                onChange={this.setColorCompound}
                inputClassName="pr-3"
              />
            </SliderRow>
            <SliderRow label="common.blue">
              <Slider
                max={255}
                name="b"
                value={b}
                onChange={this.setColorCompound}
                inputClassName="pr-3"
              />
            </SliderRow>
          </Col>
          <div>
            <div className={styles.color} style={{ backgroundColor: hex }} />
            <Button type="button" color="primary" className="w-100" onClick={this.confirmColor}>
              <FormattedMessage id="common.set" />
            </Button>
          </div>
        </Row>
        {(lineStyle || thickness) && (
          <div className={styles.lineStyleContainer}>
            {lineStyle && setLineStyle && (
              <Row>
                <Label className="col-4 pr-1">
                  <FormattedMessage id="common.line_style_picker.line_style" />
                </Label>
                <Col size={8}>
                  <RadioButtonGroup
                    active={lineStyle}
                    name="lineStyle"
                    options={['solid', 'dashed', 'dotted']}
                    setActive={setLineStyle}
                    trPrefix="time_series.line_style.option"
                  />
                </Col>
              </Row>
            )}
            {thickness && (
              <Row>
                <Label className="col-4 pr-1 d-flex align-items-center">
                  <FormattedMessage id="common.line_style_picker.line_thickness" />
                </Label>
                <Col size={7}>
                  <Slider
                    min={1}
                    max={15}
                    name="thickness"
                    value={thickness}
                    onChange={event => {
                      const value = parseInt(event.target.value, 10);
                      this.setState({ thickness: value }, () => this.setStoreThickness(value));
                    }}
                  />
                </Col>
              </Row>
            )}
          </div>
        )}
      </div>
    );
  }
}

export default React.forwardRef<HTMLDivElement, ColorPickerProps>((props, ref) => (
  <ColorPicker innerRef={ref} {...props} />
));
