import { Typography } from '@mui/material';
import { Card, Header, StyledCardContent } from 'components/profile/components/common';
import useSubscriptionState from 'features/pricing/hook/useSubscriptionState';
import { PricingPeriodSelect } from 'features/pricing/PricingPeriodSelect';
import { PricingPlanFooter } from 'features/pricing/PricingPlanFooter';
import { PricingCardCollection } from 'features/profilePage/PricingCardCollection';
import { ReactElement, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { SubscriptionPeriod } from 'services/api/customer/types';
import styled from 'styled-components';

export const upgradePlanScrollAnchor = 'upgrade-plan-card';

const UpgradePlanCard = (): ReactElement | null => {
  const { currentPricingPlan } = useSubscriptionState();
  const [subscriptionPeriod, setSubscriptionPeriod] = useState<SubscriptionPeriod>(
    currentPricingPlan?.isFree ? 'year' : currentPricingPlan?.periodName
  );

  return (
    <Card variant="outlined" id={upgradePlanScrollAnchor}>
      <Header>
        <Typography variant="h5">
          <FormattedMessage id="profile.upgrade_plan" />
        </Typography>
        <PricingPeriodSelectContainer>
          <PricingPeriodSelect
            subscriptionPeriod={subscriptionPeriod}
            onSubscriptionPeriodChange={setSubscriptionPeriod}
          />
        </PricingPeriodSelectContainer>
      </Header>

      <StyledCardContent>
        <PricingCardCollection period={subscriptionPeriod} />
      </StyledCardContent>
      <PricingPlanFooter />
    </Card>
  );
};

const PricingPeriodSelectContainer = styled.div`
  margin-right: ${({ theme }) => theme.spacings.xmedium};
`;

export default UpgradePlanCard;
