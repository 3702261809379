import { Star } from '@mui/icons-material';
import FlexContainer from 'components/FlexContainer';
import { AppLanguage } from 'features/language/store/types';
import { useModulareWorkflowCreationStore } from 'features/modular-workflow/builder/useModulareWorkflowCreationStore';
import { ReactNode } from 'react';
import styled from 'styled-components';
import { defaultTextColor } from 'styles/styleUtils';

type Props = {
  canSwitchLanguage: boolean;
  onBeforeSwitchLanguage?: (language: AppLanguage) => void;
};

export const ModularWorkflowTranslationSwitch = ({
  canSwitchLanguage,
  onBeforeSwitchLanguage
}: Props) => {
  const currentLanguage = useModulareWorkflowCreationStore(state => state.currentLanguage);
  const setCurrentLanguage = useModulareWorkflowCreationStore(state => state.setCurrentLanguage);

  const createClickHandler = (language: AppLanguage) => () => {
    onBeforeSwitchLanguage?.(language);

    setCurrentLanguage(language);
  };

  return (
    <FlexContainer direction="row">
      <LanguageTab
        isActive={currentLanguage === AppLanguage.English}
        onClick={createClickHandler(AppLanguage.English)}
      >
        EN
      </LanguageTab>
      <LanguageTab
        isActive={currentLanguage === AppLanguage.German}
        onClick={createClickHandler(AppLanguage.German)}
        disabled={!canSwitchLanguage}
      >
        DE
      </LanguageTab>
      <LanguageTab
        isActive={currentLanguage === AppLanguage.Spanish}
        onClick={createClickHandler(AppLanguage.Spanish)}
        disabled={!canSwitchLanguage}
      >
        ES
      </LanguageTab>
    </FlexContainer>
  );
};

type LanguageTabProps = {
  isActive?: boolean;
  disabled?: boolean;
  children: ReactNode;
  onClick: () => void;
};

const LanguageTab = ({ isActive, disabled, children, onClick }: LanguageTabProps) => {
  if (isActive) {
    return (
      <ActiveTab onClick={onClick}>
        <Star />
        {children}
      </ActiveTab>
    );
  }

  return (
    <Tab onClick={disabled ? undefined : onClick} $disabled={disabled}>
      {children}
    </Tab>
  );
};

const Tab = styled.div<{ $disabled?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: ${({ theme }) => theme.spacings.xsmall};
  padding: ${({ theme }) => `${theme.spacings.small} ${theme.spacings.medium}`};
  border-bottom: 2px solid transparent;
  color: ${({ theme, $disabled }) => ($disabled ? theme.colors.textMuted : defaultTextColor)};
  cursor: ${({ $disabled }) => ($disabled ? 'not-allowed' : 'pointer')};
`;

const ActiveTab = styled(Tab)`
  color: ${({ theme }) => theme.colors.primary};
  border-color: ${({ theme }) => theme.colors.primary};
`;
