import { Message } from 'features/aiWriter/AiWriterSidebar/steps/chat/Message';
import { RefObject, useEffect } from 'react';

const scrollTargetAttribute = 'data-chat-scroll-target';

function scrollTargetSelector(messageId: string) {
  return `[${scrollTargetAttribute}="${messageId}"]`;
}

export function chatScrollTargetProps(targetId: string) {
  return { [scrollTargetAttribute]: targetId };
}

export function scrollToTarget(props: { parent: Element; targetId: string }) {
  const { parent, targetId } = props;
  const scrollTarget = parent.querySelector(scrollTargetSelector(targetId));

  if (!scrollTarget) return;

  scrollTarget.scrollIntoView();
}

export function useScrollToTarget(props: {
  parentRef: RefObject<Element>;
  targetId: string;
  messages: Message[];
}) {
  const { parentRef, targetId, messages } = props;
  useEffect(() => {
    const { current: parent } = parentRef;
    if (!parent) return;
    return scrollToTarget({ parent, targetId });
  }, [parentRef, targetId, messages]);
}
