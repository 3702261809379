import { Typography } from '@mui/material';
import FormattedMessage from 'features/i18n/FormattedMessage';
import { IntlMessageKeys } from 'features/i18n/IntlMessageKeys';
import { LazyLoadedLoadingAnimation } from 'features/loading-spinner/LazyLoadedLoadingAnimation';
import { ComponentProps, useEffect, useState } from 'react';
import styled from 'styled-components';

// This allows single translations or translations with values
type TranslationMessage =
  | IntlMessageKeys
  | [IntlMessageKeys, ComponentProps<typeof FormattedMessage>['values']];

export const defaultMessages: TranslationMessage[] = [
  'tip_loader.alternating_messages.average_time',
  'tip_loader.alternating_messages.creativity_progress'
];

type Props = {
  alternatingMessages?: TranslationMessage[];
};

export const LoadingAnimationWithAlternatingMessages = ({
  alternatingMessages = defaultMessages
}: Props) => {
  const [currentTranslationIndex, setCurrentTranslationIndex] = useState(0);
  const currentTranslation = alternatingMessages[currentTranslationIndex];

  useEffect(() => {
    const labelTimer = setInterval(() => {
      setCurrentTranslationIndex(prevCount => (prevCount + 1) % alternatingMessages.length);
    }, 3000);

    return () => {
      clearInterval(labelTimer);
    };
  }, [currentTranslationIndex, currentTranslation, alternatingMessages]);

  const currentTranslationMessage =
    typeof currentTranslation === 'string' ? currentTranslation : currentTranslation[0];
  const currentTranslationValues =
    typeof currentTranslation === 'string' ? {} : currentTranslation[1];

  return (
    <Root>
      <LazyLoadedLoadingAnimation />

      <Typography component="div" variant="body1">
        <FormattedMessage id={currentTranslationMessage} values={currentTranslationValues} />
      </Typography>
    </Root>
  );
};

const Root = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: ${({ theme }) => theme.spacings.four};
`;
