import { useModal } from 'components/modals';
import { setWordsLimitReached } from 'features/customer/store/actions';
import { useGetWordsCurrentUsage } from 'features/wordsUsage/useGetWordsCurrentUsage';
import { useCallback, useEffect, useMemo } from 'react';
import { useAppDispatch } from 'store/hooks';

const isOutOfWords = (usage: ReturnType<typeof useGetWordsCurrentUsage>) => {
  if (!usage.isSuccess || !usage.data.status) {
    return false;
  }

  if (
    usage.data.data.project_conversation.is_unlimited ||
    usage.data.data.text_generation.is_unlimited
  ) {
    return false;
  }

  return usage.data.data.words_available === 0;
};

export const useShouldShowWordsLimitReachedModal = () => {
  const usageQuery = useGetWordsCurrentUsage();

  const shouldShowRunOutOfWordsModal = () => isOutOfWords(usageQuery);

  return { shouldShowRunOutOfWordsModal };
};

export const useShowWordsLimitReachedModal = () => {
  const { showModal } = useModal();

  return useCallback(() => {
    showModal('WORDS_LIMIT_REACHED', { size: 1100 });
  }, [showModal]);
};

export const useWordsLimitReachedModal = () => {
  const { showModal } = useModal();
  const dispatch = useAppDispatch();
  const usageQuery = useGetWordsCurrentUsage();

  const wordsLimitReached = useMemo(() => isOutOfWords(usageQuery), [usageQuery]);

  useEffect(() => {
    if (wordsLimitReached) {
      showModal('WORDS_LIMIT_REACHED', {
        size: 1100,
        onClose: () => dispatch(setWordsLimitReached(false))
      });
    }
  }, [wordsLimitReached, showModal, dispatch]);
};
