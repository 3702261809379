import FormatLineSpacingIcon from '@mui/icons-material/FormatLineSpacing';
import { MenuItem, Tooltip } from '@mui/material';
import {
  useLineHeightDropdownMenu,
  useLineHeightDropdownMenuState
} from '@udecode/plate-line-height';
import { MenuDropdown } from 'components/MenuDropdown/MenuDropdown';
import {
  EditorFormatButton,
  EditorFormatButtonProps,
  EditorIconFormatButton
} from 'features/aiWriter/AiWriterTextEditor/EditorIconFormatButton';
import { trackEditorFormatAction } from 'features/aiWriter/AiWriterTextEditor/trackEditorFormatAction';
import FormattedMessage from 'features/i18n/FormattedMessage';
import useDropdown from 'utils/hooks/useDropdown';

type LineHeightProps = {
  styleVariant?: EditorFormatButtonProps;
  onClose?: () => void;
};

export function LineHeightButton({ onClose, styleVariant }: LineHeightProps) {
  const { anchorElement, close, isOpen, open } = useDropdown();

  const state = useLineHeightDropdownMenuState();
  const { radioGroupProps } = useLineHeightDropdownMenu(state);

  const handleClose = () => {
    close();
    onClose?.();
  };

  const handleSelectItem = (value: string) => {
    trackEditorFormatAction('line_height');

    radioGroupProps.onValueChange(value);

    handleClose();
  };

  const trigger =
    styleVariant === 'icon' ? (
      <EditorIconFormatButton $isActive={false} onClick={open}>
        <Tooltip
          arrow
          title={<FormattedMessage id="aiWriter.toolbar.line_height" />}
          placement="bottom"
        >
          <FormatLineSpacingIcon fontSize="small" />
        </Tooltip>
      </EditorIconFormatButton>
    ) : (
      <EditorFormatButton $isActive={false} onClick={open}>
        <FormatLineSpacingIcon fontSize="small" />
        <FormattedMessage id="aiWriter.toolbar.line_height" />
      </EditorFormatButton>
    );

  return (
    <MenuDropdown
      trigger={trigger}
      anchorElement={anchorElement}
      close={handleClose}
      isOpen={isOpen}
      width="fit-content"
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left'
      }}
    >
      {state.values.map(value => (
        <MenuItem
          key={value}
          selected={value === radioGroupProps.value}
          onClick={() => handleSelectItem(value)}
        >
          {value}
        </MenuItem>
      ))}
    </MenuDropdown>
  );
}
