import { ApiPaginationParams } from 'services/api/types';

import AxiosClient from '..';
import {
  CreateAiTesterProjectRequest,
  CreateAiTesterProjectResponse,
  DeleteAiTesterProjectResponse,
  GetAiTesterProjectResponse,
  GetByIdAiTesterProjectResponse,
  UpdateAiTesterProjectRequest,
  UpdateAiTesterProjectResponse
} from './types';

const AiTesterAPI = {
  create: (payload: CreateAiTesterProjectRequest) =>
    AxiosClient.post<CreateAiTesterProjectRequest, CreateAiTesterProjectResponse>(
      '/projects/ai-tester',
      payload
    ),
  delete: (id: string) =>
    AxiosClient.delete<unknown, DeleteAiTesterProjectResponse>(`/projects/ai-tester/${id}`),
  get: (params: ApiPaginationParams) =>
    AxiosClient.get<unknown, GetAiTesterProjectResponse>('/projects/ai-tester', { params }),
  getById: (id: string) =>
    AxiosClient.get<unknown, GetByIdAiTesterProjectResponse>(`/projects/ai-tester/${id}`),
  update: (id: string, payload: UpdateAiTesterProjectRequest) =>
    AxiosClient.put<unknown, UpdateAiTesterProjectResponse>(`/projects/ai-tester/${id}`, payload)
};

export default AiTesterAPI;
