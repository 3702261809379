import { createAction } from '@reduxjs/toolkit';

import { ChangeBriefConfigPayload, ChangeGoalConfigPayload } from './types';

export const changeGoalConfig = createAction<ChangeGoalConfigPayload>('tester/changeGoalConfig');
export const changeBriefConfig = createAction<ChangeBriefConfigPayload>('tester/changeBriefConfig');
export const setIsViewExtended = createAction<boolean>('tester/setIsViewExtended');
export const setSortByScore = createAction<boolean>('tester/setSortByScore');
export const setIsTourRunning = createAction<boolean>('tester/setIsTourRunning');
export const setIsTourFinished = createAction<boolean>('tester/setIsTourFinished');
