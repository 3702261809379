import FeatureRestriction from 'features/features/FeatureRestriction';
import React from 'react';
import styled from 'styled-components';
import { resetLink } from 'styles/styledComponents/resets';
import useTr from 'utils/hooks/useTr';

type Props = {
  shortcut?: boolean;
};

export const FeatureEnterpriseRestriction: React.FC<Props> = ({ shortcut }) => {
  const translate = useTr();

  return (
    <Root
      href={translate('features.restriction.enterprise.link')}
      target="_blank"
      rel="noopener noreferrer"
    >
      <FeatureRestriction restriction="enterprise" shortcut={shortcut} />
    </Root>
  );
};

const Root = styled.a`
  ${resetLink}
`;
