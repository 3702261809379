import Toast from 'components/toasts/Toast';
import { getErrorCodeFromAxiosError } from 'features/teamInvitation/getErrorCodeFromAxiosError';
import { BaseSuccessDto } from 'services/backofficeIntegration/http/BaseSuccessDto';
import { backofficeClient } from 'services/backofficeIntegration/http/client/createBackofficeClient';
import { mutationEndpoint } from 'services/backofficeIntegration/http/endpointDefinition';

type UpdateInformationCategoryParams = {
  categoryId: number;
  name: string;
};

type SuccessDto = BaseSuccessDto<never>;

const relatedErrorCodes = ['ERROR_INFORMATION_CATEGORY_NAME_NOT_UNIQUE'];

export const httpUpdateInformationCategory = mutationEndpoint({
  callEndpoint: (props: UpdateInformationCategoryParams) =>
    backofficeClient
      .put<SuccessDto>(`/information-categories/${props.categoryId}`, { name: props.name })
      .then(response => {
        return response.data;
      })
      .catch(err => {
        const errorCode = getErrorCodeFromAxiosError(err);
        if (errorCode && relatedErrorCodes.includes(errorCode)) {
          Toast.error(`flashHub.information.table.tag.menu.update.${errorCode}`);
        } else {
          Toast.apiError();
        }

        throw err;
      })
});
