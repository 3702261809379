import DevicesIcon from '@mui/icons-material/Devices';
import { IconButton, Menu, MenuItem } from '@mui/material';
import androidLogo from 'assets/icons/android-logo.svg';
import gmailLogo from 'assets/icons/gmail-logo.svg';
import iphoneLogo from 'assets/icons/iphone-logo.svg';
import outlookLogo from 'assets/icons/outlook-logo.svg';
import React from 'react';
import styled from 'styled-components';
import useDropdown from 'utils/hooks/useDropdown';

import { Device } from '../../store/types';

const mobileDevices: Array<{ value: Device; label: string; icon: string }> = [
  { value: 'gmail', label: 'Gmail Desktop (default)', icon: gmailLogo },
  { value: 'iPhone', label: 'iPhone App', icon: iphoneLogo },
  { value: 'android', label: 'Android App', icon: androidLogo },
  { value: 'outlook', label: 'Outlook', icon: outlookLogo }
];

type Props = {
  device: Device;
  setDevice: React.Dispatch<React.SetStateAction<Device>>;
};

const DeviceSelection = ({ device, setDevice }: Props) => {
  const { anchorElement, isOpen, open, close } = useDropdown();

  const handleDeviceChange = (
    event: React.MouseEvent<HTMLElement>,
    newDevice?: Device | 'mobile' | null
  ) => {
    if (newDevice && newDevice !== 'mobile') {
      setDevice(newDevice);
      close();
    } else {
      open(event);
    }
  };

  return (
    <>
      <IconButton aria-label="delete" onClick={event => handleDeviceChange(event)} size="large">
        <DevicesIcon />
      </IconButton>
      <Menu
        open={isOpen}
        anchorEl={anchorElement}
        onClose={close}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        disableScrollLock
      >
        {mobileDevices.map(({ value, label, icon }) => (
          <MenuItem
            key={value}
            selected={device === value}
            onClick={event => handleDeviceChange(event, value)}
          >
            <Item>
              <CustomIcon alt="device icon" src={icon} />
              {label}
            </Item>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

const CustomIcon = styled.img`
  display: flex;
  height: inherit;
  width: inherit;
`;

const Item = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
  justify-content: flex-start;
`;

export default DeviceSelection;
