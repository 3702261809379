import { SearchEngines } from 'features/aiWriter/AiWriterSidebar/steps/seoAnalysis/searchEngines';
import { searchEnginesOptions } from 'features/aiWriter/AiWriterSidebar/steps/seoAnalysis/SeoAnalysisMainSubStep/searchEnginesOptions';

export function makeSeoInitialValues(country: string, seoKeyword: string) {
  const searchEngine = searchEngineForCountry[country] ?? searchEnginesOptions[0];

  return {
    searchEngine,
    keyword: seoKeyword
  };
}

export const searchEngineForCountry: Record<string, string> = {
  au: SearchEngines.google_com_au,
  uk: SearchEngines.google_co_uk,
  us: SearchEngines.google_com,
  usuk: SearchEngines.google_com,
  sg: SearchEngines.google_com_sg,
  de: SearchEngines.google_de,
  ch: SearchEngines.google_ch,
  pl: SearchEngines.google_pl,
  es: SearchEngines.google_es,
  it: SearchEngines.google_it,
  fr: SearchEngines.google_fr,
  at: SearchEngines.google_at,
  ca: SearchEngines.google_ca,
  in: SearchEngines.google_co_in,
  dach: SearchEngines.google_de,
  nl: SearchEngines.google_nl
};
