import { getCurrentModelLanguageAndCountry } from 'features/aiWriter/store/selectors';
import { getEmbeddingModelDataSelector } from 'features/embeddingModels/store/selectors';
import { useAppSelector } from 'store/hooks';

export function useGetProjectOrPreselectedLanguageAndCountry(preselectedModelId?: string) {
  const getModelById = useAppSelector(getEmbeddingModelDataSelector);
  const { currentModelCountry: projectCountry, currentModelLanguage: projectLanguage } =
    useAppSelector(getCurrentModelLanguageAndCountry);

  if (preselectedModelId) {
    const model = getModelById(preselectedModelId);

    return { language: model?.language, country: model?.country };
  }

  return { language: projectLanguage, country: projectCountry };
}
