import axios from 'axios';
import { APIResponse } from 'types/APIResponse';

const axiosClient = axios.create({
  baseURL: process.env.REACT_APP_PROFITWELL_API,
  headers: {
    authorization: process.env.REACT_APP_PROFITWELL_TOKEN,
    'content-type': 'application/json'
  }
});

const profitwellClient = {
  start: ({ userStripeId }: { userStripeId: string }) =>
    axiosClient.get<unknown, APIResponse<unknown>>('/customer', {
      params: { user_id: userStripeId }
    })
};

export default profitwellClient;
