import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { setSelection } from 'features/aiWriter/store/actions/editor/actions';

import { SeoAnalysis, SeoConfig } from '../../types';
import { RewriteSeoSearchResult, UpdateTermSuggestionsPayload } from './types';

export const updateSeoAnalysis = createAction<SeoAnalysis>('aiWriter/updateSeoAnalysis');

export const updateSeoConfig = createAction<SeoConfig>('aiWriter/updateSeoConfig');

export const updateTermSuggestions = createAction<UpdateTermSuggestionsPayload>(
  'aiWriter/updateTermSuggestions'
);

export const editorSelectionHack = createAsyncThunk(
  'aiWrier/editorSelectionHack',
  async (_, { dispatch }) => {
    dispatch(
      setSelection({
        path: [0, 0],
        offset: 0
      })
    );
  }
);

export const _updateSeoHighlightedKeyword = createAction<string | undefined | string[]>(
  'aiWriter/updateSeoHighlightedKeyword'
);

export const updateSeoHighlightedKeyword = createAsyncThunk<void, string | undefined | string[]>(
  '',
  async (word, { dispatch }) => {
    await dispatch(_updateSeoHighlightedKeyword(word));
    // dispatching editorSelectionHack is a fix for
    // bug in Plate lib: https://plate.udecode.io/docs/plugins/find-replace
    // https://github.com/udecode/plate/issues/1722

    // State update in Plate is one step behind the actual state in which it should be.
    // Fixing it properly would require forking Plate and finding which part is causing that
    // From what I investigated looks like useMemo does not include proper dependency OR
    // refs are not updated as they should be.
    // By focusing on some artificial part of Plate document (implemented fix),
    // am updating it s state and as an effect get words highlighted as we want.
    // To the extent I ve tested it, that focusing has no other side effects
    await dispatch(editorSelectionHack());
  }
);

export const rewriteSeoSearchResult = createAction<RewriteSeoSearchResult>(
  'aiWriter/rewriteSeoSearchResult'
);
