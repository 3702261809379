export const spacingPalette = {
  one: '4px',
  two: '8px',
  three: '16px',
  four: '24px',
  five: '32px',
  six: '40px',
  seven: '48px',
  eight: '56px',
  nine: '64px',
  ten: '72px',
  eleven: '80px',
  twelve: '88px'
};
