import { AiWriterProjectTile } from 'features/aiWriter/AiWriterProjectOverview/AiWriterProjectTile';
import { AiWriterProjectWithShortText } from 'features/aiWriter/store/types';
import styled from 'styled-components';

type Props = {
  projects: AiWriterProjectWithShortText[];
};

export function ProjectsGrid({ projects }: Props) {
  return (
    <ProjectsGridContainer>
      {projects.map(project => (
        <AiWriterProjectTile key={project.id} project={project} />
      ))}
    </ProjectsGridContainer>
  );
}

const ProjectsGridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: ${({ theme }) => theme.spacings.four};
  row-gap: ${({ theme }) => theme.spacings.four};
`;
