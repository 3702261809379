import { getPricingType } from 'features/customer/store/selectors';
import { createSelector } from 'reselect';
import { RootState } from 'store/rootReducer';

export const getPricingPlan = getPricingType;

export const getSeoLimitations = (state: RootState) => state.pricing.seoLimitations;
export const getUnsplashImagesLimitations = (state: RootState) =>
  state.pricing.unsplashImagesLimitations;

export const getIsSeoLimitReached = createSelector(getSeoLimitations, seoLimitations => {
  if (!seoLimitations) {
    return false;
  }

  if (seoLimitations.isUnlimited) {
    return false;
  }

  return seoLimitations.used === seoLimitations.limit;
});

export const getIsUnsplashImagesLimitReached = createSelector(
  getUnsplashImagesLimitations,
  unsplashImagesLimitations => {
    if (!unsplashImagesLimitations) {
      return false;
    }

    if (unsplashImagesLimitations.isUnlimited) {
      return false;
    }

    return unsplashImagesLimitations.used >= unsplashImagesLimitations.limit;
  }
);
