import Tippy, { TippyProps } from '@tippyjs/react';
import { followCursor } from 'tippy.js';

const GraphTooltip = (props: TippyProps) => <Tippy {...props} />;

GraphTooltip.defaultProps = {
  followCursor: true,
  plugins: [followCursor],
  animation: 'fade'
};

export default GraphTooltip;
