import { useCallback, useState } from 'react';

export default function useRunInTask() {
  const [isRunning, setIsRunning] = useState(false);

  const runInTask = useCallback(async <T>(block: () => T | Promise<T>) => {
    setIsRunning(true);
    try {
      return await block();
    } finally {
      setIsRunning(false);
    }
  }, []);

  return [isRunning, runInTask] as const;
}
