import { Autocomplete, Button, createFilterOptions, MenuItem, TextField } from '@mui/material';
import { useInfiniteQuery } from '@tanstack/react-query';
import { MouseEvent, SyntheticEvent, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  httpGetTeamsForWorkflow,
  TeamsData,
  TeamsForWorklowPaginatedListParams
} from 'services/backofficeIntegration/http/endpoints/modularWorkflow/httpGetTeamsForWorkflow';
import {
  getNextPageParam,
  PaginatedListParams
} from 'services/backofficeIntegration/http/paginatedListEndpoint';
import styled from 'styled-components';
import { useDebounce } from 'use-debounce';

type Props = {
  handleSelectTeam: (event: SyntheticEvent, value: number) => void;
  enabled: boolean;
};

const filter = createFilterOptions<TeamsData>();

export const ModularWorkflowCreationViewSetupTeamsSelect = ({
  handleSelectTeam,
  enabled
}: Props) => {
  const [inputValue, setInputValue] = useState<string>();
  const [debouncedInput] = useDebounce(inputValue, 700);
  const queryParams: TeamsForWorklowPaginatedListParams = {
    search: debouncedInput !== '' ? debouncedInput : undefined,
    limit: 10,
    offset: 0
  };

  const {
    data: teamsQuery,
    isLoading,
    isFetching,
    hasNextPage,
    fetchNextPage
  } = useInfiniteQuery({
    queryKey: httpGetTeamsForWorkflow.makeQueryKey(queryParams),
    queryFn: (context: { pageParam?: PaginatedListParams }) => {
      const { pageParam = {} } = context;
      return httpGetTeamsForWorkflow.callEndpoint({
        ...queryParams,
        ...pageParam
      });
    },
    enabled,
    getNextPageParam: getNextPageParam
  });

  const teamsList = teamsQuery?.pages.map(page => page.data).flat(1);

  const handleSelection = (event: SyntheticEvent, value: TeamsData | null) => {
    if (!value) {
      return;
    }
    handleSelectTeam(event, value.id);
  };

  const handleFetchNextPage = (event: MouseEvent) => {
    event.stopPropagation();
    event.preventDefault();
    fetchNextPage();
  };

  return (
    <Autocomplete
      fullWidth={true}
      options={teamsList ?? []}
      loading={isLoading}
      getOptionLabel={option => `${option.name}, ${option.id}, ${option.owner_email}`}
      onChange={handleSelection}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      renderOption={(props, option: TeamsData) => {
        if (option.id === -1) {
          if (hasNextPage && !isFetching) {
            return (
              <TeamItem key={option.id} onClick={handleFetchNextPage}>
                <Button>
                  <FormattedMessage id="common.moreButton" />
                </Button>
              </TeamItem>
            );
          } else if (isFetching) {
            return (
              <TeamItem key={option.id}>
                <FormattedMessage id="common.loading" />
              </TeamItem>
            );
          }

          return null;
        }

        return (
          <TeamItem {...props}>
            {option.name}, {option.id}, {option.owner_email}
          </TeamItem>
        );
      }}
      renderInput={params => (
        <TextField
          {...params}
          variant="outlined"
          placeholder="Select a team"
          onChange={e => setInputValue(e.currentTarget.value)}
        />
      )}
      filterOptions={(options, state) => {
        const filtered = filter(options, state);
        if (!filtered) {
          return [];
        }
        return [...filtered, { id: -1, name: 'Load more', owner_email: '' }];
      }}
    />
  );
};

const TeamItem = styled(MenuItem)`
  max-width: 400px;
`;
