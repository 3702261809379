import TabContext from '@mui/lab/TabContext';
import TabPanel from '@mui/lab/TabPanel';
import { Tab, Tabs } from '@mui/material';
import brandVoiceBannerImage from 'assets/brandHub/brand-voice-banner.png';
import informationBannerImage from 'assets/brandHub/information-banner.png';
import { Container } from 'components/Container';
import { RouteConfig, routesConfig, shouldPageBeVisible } from 'config/routes';
import {
  getCustomerRole,
  getCustomerTeams,
  getIsApiCustomer
} from 'features/customer/store/selectors';
import FormattedMessage from 'features/i18n/FormattedMessage';
import { useNavigateToOnboarding } from 'features/onboardingJourney/useNavigateToOnboarding';
import { useEnableSidebarOnMount } from 'features/pageSidebar/useSidebarStore';
import { BannerLayout } from 'pages/brand-hub/BannerLayout';
import { BrandHubBrandVoiceTab } from 'pages/brand-hub/brandVoiceTab';
import { BrandHubInformationTab } from 'pages/brand-hub/informationTab';
import { BrandHubWorkflowsTab } from 'pages/brand-hub/workflowsTab';
import { ElementType, SyntheticEvent, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useAppSelector } from 'store/hooks';
import styled from 'styled-components';
import { useCurrentRoutePath } from 'utils/hooks/useCurrentRoutePath';

const brandHubRoutes = {
  workflows: routesConfig.brandHubOverviewWorkflows,
  brandVoice: routesConfig.brandHubOverviewBrandVoice,
  templates: routesConfig.brandHubOverviewTemplates,
  information: routesConfig.brandHubOverviewInformation
} as const;

export function BrandHubOverviewPage() {
  const pathname = useCurrentRoutePath();
  const customerGroup = useAppSelector(state => state.customer.groupId);
  const userRole = useAppSelector(getCustomerRole);
  const isApiCustomer = useAppSelector(getIsApiCustomer);
  const customerTeams = useAppSelector(getCustomerTeams);

  const [currentRoute, setCurrentRoute] = useState<RouteConfig>(brandHubRoutes.brandVoice);

  useEnableSidebarOnMount();

  useNavigateToOnboarding();

  useEffect(() => {
    if (pathname === currentRoute.path) {
      return;
    }

    const route = Object.values(brandHubRoutes).find(routeConfig => routeConfig.path === pathname);
    if (route) {
      setCurrentRoute(route);
    }
  }, [pathname, currentRoute]);

  const handleChange = (event: SyntheticEvent, newUrl: string) => {
    const route = Object.values(brandHubRoutes).find(routeConfig => routeConfig.path === newUrl);
    if (route) {
      setCurrentRoute(route);
    }
  };

  return (
    <Root>
      {currentRoute.path === brandHubRoutes.brandVoice.path ? (
        <BrandVoiceBanner />
      ) : (
        <InformationBanner />
      )}

      <TabContext value={currentRoute.path}>
        <Tabs value={currentRoute.path} onChange={handleChange} aria-label="basic tabs example">
          {shouldPageBeVisible(
            brandHubRoutes.workflows,
            customerGroup,
            userRole,
            isApiCustomer,
            customerTeams
          ) && (
            <StyledTab
              label={<FormattedMessage id="flashHub.overview.tab.workflows" />}
              component={Link}
              to={brandHubRoutes.workflows.path}
              value={brandHubRoutes.workflows.path}
            />
          )}
          {shouldPageBeVisible(
            brandHubRoutes.brandVoice,
            customerGroup,
            userRole,
            isApiCustomer,
            customerTeams
          ) && (
            <StyledTab
              label={<FormattedMessage id="flashHub.overview.tab.brandVoice" />}
              component={Link}
              to={brandHubRoutes.brandVoice.path}
              value={brandHubRoutes.brandVoice.path}
            />
          )}
          {shouldPageBeVisible(
            brandHubRoutes.information,
            customerGroup,
            userRole,
            isApiCustomer,
            customerTeams
          ) && (
            <StyledTab
              label={<FormattedMessage id="flashHub.overview.tab.information" />}
              component={Link}
              to={brandHubRoutes.information.path}
              value={brandHubRoutes.information.path}
            />
          )}
        </Tabs>

        <Content>
          <StyledTabPanel value={brandHubRoutes.workflows.path}>
            <BrandHubWorkflowsTab />
          </StyledTabPanel>
          <StyledTabPanel value={brandHubRoutes.brandVoice.path}>
            <BrandHubBrandVoiceTab />
          </StyledTabPanel>
          <StyledTabPanel value={brandHubRoutes.information.path}>
            <BrandHubInformationTab />
          </StyledTabPanel>
        </Content>
      </TabContext>
    </Root>
  );
}

const Root = styled(Container).attrs({ size: 'lg' })`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.xmedium};
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: ${({ theme }) => theme.spacings.three};
`;

const StyledTabPanel = styled(TabPanel)`
  width: 100%;
  padding-inline: 0;
  padding-block: ${({ theme }) => theme.spacings.four};
`;

const StyledTab = styled(Tab)<{
  // fix for `component` typing
  // see: https://github.com/mui/material-ui/issues/13921
  component: ElementType;
  to: string;
}>`
  text-transform: none;
`;

function BrandVoiceBanner() {
  return (
    <BannerLayout
      title={
        <FormattedMessage
          id="flashHub.brandVoice.banner.title"
          values={{
            highlight: (text: string) => <HighlightingPrimary>{text}</HighlightingPrimary>,
            br: <br />
          }}
        />
      }
      content={<FormattedMessage id="flashHub.brandVoice.banner.body" />}
      image={brandVoiceBannerImage}
      color="blue__500main"
    />
  );
}

const HighlightingPrimary = styled.span`
  color: ${({ theme }) =>
    theme.mode === 'dark' ? theme.colors.palette.dark__50 : theme.colors.textPrimary};
`;

function InformationBanner() {
  return (
    <BannerLayout
      title={
        <OverrideTextColor>
          <FormattedMessage
            id="flashHub.information.banner.title"
            values={{
              highlight: (text: string) => <HighlightingReverse>{text}</HighlightingReverse>,
              br: <br />
            }}
          />
        </OverrideTextColor>
      }
      content={
        <OverrideTextColor>
          <FormattedMessage id="flashHub.information.banner.body" />
        </OverrideTextColor>
      }
      image={informationBannerImage}
      color="pinkRose__500main"
    />
  );
}

const OverrideTextColor = styled.span`
  color: ${({ theme }) =>
    theme.mode === 'dark' ? theme.colors.palette.white : theme.colors.palette.black};
`;

const HighlightingReverse = styled.span`
  color: ${({ theme }) =>
    theme.mode === 'dark' ? theme.colors.palette.black : theme.colors.palette.white};
`;
