import { ModelAutocomplete } from 'features/embeddingModels/ModelAutocomplete';
import { EmbeddingModel } from 'features/embeddingModels/store/types';
import { Answer, QnAPair, Question } from 'features/onboardingJourney/OnboardingSurvey';
import { useField, useFormikContext } from 'formik';
import { FormattedMessage } from 'react-intl';

export type Language = {
  language?: string;
  country?: string;
};

export const OnboardingSurveyLanguageField = (props: {
  name: string;
  models: EmbeddingModel[];
  onSubmit: (values: Language) => void;
}) => {
  const { name, models, onSubmit } = props;
  const [field] = useField(name);
  const { setFieldValue } = useFormikContext<Language>();
  const handleLanguage = (model: string) => {
    setFieldValue('language', model);
    onSubmit({ language: model });
  };

  return (
    <QnAPair>
      <Question variant="body1">
        <FormattedMessage id="aiWriter.onboarding_journey.survey_view.language.question" />
      </Question>
      <Answer key={Math.random()}>
        <ModelAutocomplete models={models} {...field} onSelect={handleLanguage} size="small" isSaveAsDefaultShown={false} />
      </Answer>
    </QnAPair>
  );
};
