import GetAppIcon from '@mui/icons-material/GetApp';
import { MenuItem } from '@mui/material';
import { MenuDropdownSubItem } from 'components/MenuDropdown/MenuDropdownSubItem';
import { CommonAction } from 'features/aiTester/buttons/GraphActionsButton';
import { getCurrentTabChartData, getTesterActiveTab } from 'features/aiTester/store/selectors';
import { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { useAppSelector } from 'store/hooks';
import { exportRawContentFlashDataToFile } from 'utils/excelExport';
import useTr from 'utils/hooks/useTr';

type Props = {
  isParentMenuOpen: boolean;
} & CommonAction;

const DownloadDataAction = ({ isParentMenuOpen, onClose }: Props) => {
  const tr = useTr();

  const { texts } = useAppSelector(getTesterActiveTab);
  const chartData = useAppSelector(getCurrentTabChartData);

  const mkOnClick = useCallback(
    (action: () => void) => {
      action();
      onClose();
    },
    [onClose]
  );

  return (
    <MenuDropdownSubItem
      icon={<GetAppIcon opacity={0.54} />}
      isParentMenuOpen={isParentMenuOpen}
      label={tr('testing.graph.dropdown_actions.download')}
    >
      <MenuItem
        onClick={() => mkOnClick(() => exportRawContentFlashDataToFile({ texts, chartData }))}
      >
        <FormattedMessage id="testing.graph.dropdown_actions.download.xlsx" />
      </MenuItem>
    </MenuDropdownSubItem>
  );
};

export default DownloadDataAction;
