import { range } from 'lodash';
import React, { Component, ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

import Icon from '../base/Icon';
import Card from '../Card/Card';
import styles from './CardWithTitle.module.scss';

type CustomHeaderProps = {
  type: 'custom';
  headerContent: React.ReactNode;
};

type WithDescProps = {
  type: 'description';
  trPrefix: string;
};

type WithStepsProps = {
  type: 'steps';
  steps: number;
  trPrefix: string;
};

type CommonProps = {
  className?: string;
  options?: React.ReactNode;
  isInitiallyCollapsed?: boolean;
  children?: ReactNode;
};

type Props = (CustomHeaderProps | WithDescProps | WithStepsProps) & CommonProps;

class CardWithTitle extends Component<Props> {
  static defaultProps = {
    type: 'description'
  };

  cardRef = React.createRef<Card>();

  collapseCard = () => {
    if (this.cardRef.current) this.cardRef.current.collapse();
  };

  openCard = () => {
    if (this.cardRef.current && this.cardRef.current.state.isCollapsed) {
      this.collapseCard();
    }
  };

  render() {
    const { children, options, isInitiallyCollapsed } = this.props;
    let header: React.ReactNode;

    if (this.props.type === 'custom') {
      header = this.props.headerContent;
    } else if (this.props.type === 'steps') {
      const { trPrefix, steps } = this.props;
      header = (
        <div className="d-block w-100">
          <Card.Title>
            {range(1, steps + 1).map(step => (
              <React.Fragment key={step}>
                <FormattedMessage id={`${trPrefix}_${step}`} />
                {step < steps && <Icon className={styles.titleIcon} name="chevron-right" />}
              </React.Fragment>
            ))}
          </Card.Title>
        </div>
      );
    } else {
      const { trPrefix } = this.props;
      header = (
        <div className="d-block">
          <Card.Title className="d-inline-block">
            <FormattedMessage id={`${trPrefix}.header`} />
          </Card.Title>
          <small className="ml-2 text-muted">
            <FormattedMessage id={`${trPrefix}.desc`} />
          </small>
        </div>
      );
    }

    return (
      <Card ref={this.cardRef} isCollapsed={isInitiallyCollapsed} className={this.props.className}>
        <Card.Header>
          {header}
          <Card.Options>
            {options}
            <Card.OptionsItem type="collapse" onClick={this.collapseCard} />
          </Card.Options>
        </Card.Header>
        {children}
      </Card>
    );
  }
}

export default CardWithTitle;
