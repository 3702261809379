import { CloseModal } from 'components/modals/types';

import StepperModal, { ModalStep } from '../../../components/modals/StepperModal';
import { GoalValues } from '../store/types';
import ExplorerGoalConfigStep from './steps/ExplorerGoalConfigStep';

const modelStep: ModalStep[] = [
  {
    title: 'goal_score.setup.title',
    description: 'exploring.setup.goal.description'
  }
];

type Props = {
  onSubmit: (values: GoalValues) => void;
  initialValues: GoalValues;
} & CloseModal;

const GoalModal = ({ closeModal, onSubmit, initialValues }: Props) => {
  return (
    <StepperModal
      steps={modelStep}
      stepComponent={() => <ExplorerGoalConfigStep />}
      initialValues={initialValues}
      onCancel={() => {
        closeModal();
      }}
      onSubmit={values => {
        onSubmit(values);
        closeModal();
      }}
    />
  );
};

export default GoalModal;
