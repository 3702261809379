import cx from 'classnames';
import React from 'react';

import Col from './Col';
import Row from './Row';

type Props = {
  name: string;
  value: number;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  divider?: number;
  step?: number;
  min?: number;
  max?: number;
  className?: string;
  inputClassName?: string;
};

const Slider = ({
  name,
  value,
  step = 1,
  min = 0,
  max = 100,
  divider,
  className,
  inputClassName,
  onChange
}: Props) => (
  <Row className={cx(className, 'align-items')}>
    <Col>
      <input
        type="range"
        className="form-control custom-range"
        step={step}
        min={min}
        max={max}
        name={name}
        value={value}
        onChange={onChange}
      />
    </Col>
    <div className={cx('col-auto p-0', inputClassName)}>
      <input
        type="text"
        className="form-control w-7 px-2"
        value={divider ? value / divider : value}
        readOnly
      />
    </div>
  </Row>
);

export default Slider;
