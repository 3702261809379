import { FavoriteRounded, HistoryRounded, TrendingUpRounded } from '@mui/icons-material';
import FormattedMessage from 'features/i18n/FormattedMessage';
import { IntlMessageKeys } from 'features/i18n/IntlMessageKeys';
import { Category } from 'features/textGenerator/outputTypeModal/OutputTypeModal';
import { ThemedCategoryList } from 'features/theme-2024/category-list/ThemedCategoryList';
import { ThemedCategoryListItem } from 'features/theme-2024/category-list/ThemedCategoryListItem';
import { ReactNode } from 'react';
import gtmIds from 'services/tracking/GTMIds';
import { withGtmInteraction } from 'services/tracking/withGtmInteraction';

export enum CustomCategories {
  ALL_CATEGORY = 'all',
  // currently called "Trending" in the UI
  MOST_POPULAR_CATEGORY = 'most_popular',
  FAVORITES_CATEGORY = 'favorites',
  MOST_RECENT_CATEGORY = 'most_recent'
}

export function CategoriesList(props: {
  categories: Category[];
  selectedCategory: string;

  handleSelectCategory: (categoryId: string) => void;
}) {
  const { selectedCategory, handleSelectCategory, categories } = props;

  return (
    <ThemedCategoryList
      header={
        <>
          {renderMostRecent()}
          {renderFavorites()}
          {renderMostPopular()}
        </>
      }
    >
      {renderAll()}
      {renderCategories()}
    </ThemedCategoryList>
  );

  function renderMostPopular() {
    return (
      <CustomCategory
        category={CustomCategories.MOST_POPULAR_CATEGORY}
        label="output_type_categories_list.trending"
        selectedCategory={selectedCategory}
        handleSelectCategory={handleSelectCategory}
        icon={<TrendingUpRounded />}
        gtmId={gtmIds.textTypesLibrary.trendingCategoryClickedInModal}
      />
    );
  }

  function renderFavorites() {
    return (
      <CustomCategory
        category={CustomCategories.FAVORITES_CATEGORY}
        label="output_type_categories_list.favorites"
        selectedCategory={selectedCategory}
        handleSelectCategory={handleSelectCategory}
        icon={<FavoriteRounded />}
        gtmId={gtmIds.textTypesLibrary.favoritesCategoryClickedInModal}
      />
    );
  }

  function renderMostRecent() {
    return (
      <CustomCategory
        category={CustomCategories.MOST_RECENT_CATEGORY}
        label="output_type_categories_list.most_recent"
        selectedCategory={selectedCategory}
        handleSelectCategory={handleSelectCategory}
        icon={<HistoryRounded />}
        gtmId={gtmIds.textTypesLibrary.mostRecentCategoryClickedInModal}
      />
    );
  }

  function renderAll() {
    return (
      <CustomCategory
        category={CustomCategories.ALL_CATEGORY}
        label="common.all"
        selectedCategory={selectedCategory}
        handleSelectCategory={handleSelectCategory}
        gtmId={gtmIds.textTypesLibrary.allCategoryClickedInModal}
      />
    );
  }

  function renderCategories() {
    return categories.map(category => (
      <ThemedCategoryListItem
        {...withGtmInteraction(gtmIds.textTypesLibrary.selectCategory)}
        key={category.id}
        onClick={() => handleSelectCategory(category.id)}
        selected={category.id === selectedCategory}
      >
        {category.label}
      </ThemedCategoryListItem>
    ));
  }
}

function CustomCategory(props: {
  category: CustomCategories;
  label: IntlMessageKeys;
  selectedCategory: string;
  handleSelectCategory: (category: string) => void;
  icon?: ReactNode;
  gtmId: string;
}) {
  const { category, label, selectedCategory, handleSelectCategory, icon, gtmId } = props;

  return (
    <ThemedCategoryListItem
      onClick={() => handleSelectCategory(category)}
      selected={category === selectedCategory}
      icon={icon}
      {...withGtmInteraction(gtmId)}
    >
      <FormattedMessage id={label} />
    </ThemedCategoryListItem>
  );
}
