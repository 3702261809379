import { RestrictedRoute } from 'config/routes';
import { AppLanguage } from 'features/language/store/types';
import { ACLPricing, CustomerActiveSubscriptionState } from 'services/api/customer/types';
import { Loading } from 'types/ReduxStateTypes';

export interface CustomerLogInRequest {
  email: string;
  password: string;
}

export interface CustomerDataRequest {
  email: string;
}

export interface CustomerLogInWithSessionRequest {
  session_id: string;
  triggerTracking: boolean;
}

export interface BillingInfo {
  fullName: string;
  address: string;
  city: string;
  postalCode: string;
  country: string;
  phone: string;
  // Optional company stuff
  companyName: string;
  useCompanyAddress: boolean;
}

export interface Team {
  id: number;
  name: string;
  created_at?: string;
  updated_at?: string;
}

export interface CustomerDataPayload extends CustomerSubscriptionData {
  id: number;
  apiToken?: string;
  apiCustomerToken: string | null;
  username: string;
  email: string;
  timezone: string;
  image: string | null;
  groupId: number | null;
  cardBrand: string | null;
  cardLastFour: string | null;
  pricing: ACLPricing;
  // TODO: Extend ACLPricing for customer and merge with these information
  pricingExpired: boolean;
  pricingExpiryDate: string | null;
  restrictions: Record<RestrictedRoute, boolean>;
  teamRole: CustomerRole;
  teams: Team[];
  billingInfo: BillingInfo;
  stripeId?: string;
  isVerified: boolean;
  registrationDate: string;
  sepaPaymentFailed: boolean;
  referralLink: string;
  wordsUsedMonthly: number;
  isApiCustomer: boolean;
  hasTeam?: boolean;
  wordsShownMonthly: number;
  currency: string;
}

export type CustomerUpdateRequest = {
  name?: string;
  password?: string;
  timezone?: string;
  image?: string;
  locale?: AppLanguage;
} & Partial<BillingInfo>;

export interface CustomerState extends Loading, CustomerDataPayload {
  firstLogin: boolean;
  error: string;
  isReady: boolean;
  isCustomerDataLoaded: boolean;
  wordsLimitReached: boolean;
  hasSeenCanceledSubscriptionForTeams: boolean;
}

export interface CustomerSubscriptionData {
  subscription?: CustomerActiveSubscriptionState | null;
}

export enum CustomerRole {
  ADMIN = 'Admin',
  MEMBER = 'Member',
  OWNER = 'Owner'
}

export enum CustomerStatus {
  ACTIVE = 'Active',
  PENDING = 'Pending',
  DEACTIVATED = 'Deactivated'
}
