import { PopoverOrigin } from '@mui/material';

const originBottomLeft: PopoverOrigin = {
  vertical: 'bottom',
  horizontal: 'left'
};

const menuAnchorTopLeft: PopoverOrigin = {
  vertical: 'top',
  horizontal: 'left'
};

export const topBarDropdownAlignmentConfig = {
  leftAlignment: {
    anchorOrigin: originBottomLeft,
    transformOrigin: menuAnchorTopLeft
  }
};
