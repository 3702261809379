import { Button, Link, Typography } from '@mui/material';
import FlexContainer from 'components/FlexContainer';
import { getRouteUrl } from 'config/routes';
import FormattedMessage from 'features/i18n/FormattedMessage';
import styled from 'styled-components';
import useTr from 'utils/hooks/useTr';

type Props = {
  errorCode: string;
  invitedByName?: string;
};

export const ErrorPage = ({ errorCode, invitedByName }: Props) => {
  const translate = useTr();
  const translationTokenTitle = `error.${errorCode}.headline`;
  const translationTokenMessage = `error.${errorCode}.message`;

  return (
    <Root>
      <Content>
        <Typography variant="h5">
          <FormattedMessage id={translationTokenTitle} />
        </Typography>
        <Typography variant="body1">
          <FormattedMessage
            id={translationTokenMessage}
            values={{
              br: <br />,
              invited_by: invitedByName,
              help: (value: string) => (
                <Link
                  href={translate('external_links.app_helpscout')}
                  rel="noreferrer"
                  target="_blank"
                >
                  {value}
                </Link>
              )
            }}
          />
        </Typography>
        <Button variant="contained" color="primary" href={getRouteUrl('login')}>
          <FormattedMessage id="common.login" />
        </Button>
      </Content>
    </Root>
  );
};

const Root = styled(FlexContainer).attrs({ alignItems: 'center', justifyContent: 'center' })`
  width: 100%;
`;

const Content = styled(FlexContainer)`
  margin-top: 80px;
  max-width: 368px;
  gap: ${({ theme }) => theme.spacings.medium};
`;
