import { Button, Typography } from '@mui/material';
import { LogoWithSlogan } from 'components/LogoWithSlogan';
import FormattedMessage from 'features/i18n/FormattedMessage';
import styled from 'styled-components';

export function MaintenancePage() {
  return (
    <Root>
      <LogoBox>
        <LogoWithSlogan sloganKey="maintenance.slogan" align="center" />
      </LogoBox>
      <MessageBox>
        <Title>
          <FormattedMessage id="maintenance.title" />
        </Title>
        <Typography variant="body1">
          <FormattedMessage
            id="maintenance.explanation"
            values={{
              br: <br />
            }}
          />
        </Typography>
        <ButtonBox>
          <Button
            variant="contained"
            onClick={() => {
              location.reload();
            }}
          >
            <FormattedMessage id="common.refresh" />
          </Button>
        </ButtonBox>
      </MessageBox>
    </Root>
  );
}

const Root = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  max-width: min(
    ${({ theme }) => theme.container.sm},
    100% - 2 * ${({ theme }) => theme.spacings.medium}
  );
  margin-inline: auto;
`;

const LogoBox = styled.div`
  justify-self: center;
  margin-top: ${({ theme }) => theme.spacings.xxxxlarge};
`;

const MessageBox = styled.div`
  margin-top: ${({ theme }) => theme.spacings.xxxlarge};
  margin-bottom: ${({ theme }) => theme.spacings.xxxxlarge};
`;

const Title = styled(Typography).attrs({ variant: 'h5', component: 'h2' })`
  margin-bottom: ${({ theme }) => theme.spacings.xmedium};
`;

const ButtonBox = styled.div`
  margin-top: ${({ theme }) => theme.spacings.large};
`;
