import { Autocomplete, AutocompleteClasses, Chip, TextField } from '@mui/material';
import { TextFieldProps } from '@mui/material/TextField/TextField';
import cx from 'classnames';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import useTr from 'utils/hooks/useTr';

type Props = {
  userTonalities: string[];
  onChange: (values: string[]) => void;
  onInputChange?: (inputValue: string, confirmedValues: string[]) => void;
  maxAmount: number;
  classes?: Partial<AutocompleteClasses>;
  textFieldProps?: Partial<TextFieldProps>;
  size?: 'small' | 'medium';
};

export const UserTonalityInput = ({
  maxAmount,
  onChange,
  onInputChange,
  userTonalities,
  classes,
  textFieldProps,
  size = 'medium'
}: Props) => {
  const translate = useTr();
  const [isPlaceholderShown, setIsPlaceholderShown] = useState(true);
  const [inputValue, setInputValue] = useState('');
  const hasReachedMaxAmount = userTonalities.length >= maxAmount;

  useEffect(() => {
    setIsPlaceholderShown(!hasReachedMaxAmount);
  }, [hasReachedMaxAmount]);

  return (
    <Autocomplete<string, true, false, true>
      multiple={true}
      freeSolo={true}
      options={[]}
      value={userTonalities}
      inputValue={inputValue}
      onChange={(_event, value) => onChange(value)}
      classes={classes}
      onInputChange={(_event, newInputValue, reason) => {
        // Split input by comma and if present, use the input as a new option
        const options = newInputValue.split(',');
        if (options.length > 1) {
          onChange(
            // Add new comma-separated options to the existing options
            userTonalities
              .concat(options)
              .map(x => x.trim())
              .filter(x => x)
              // Ensure we never exceed the max number of options
              .slice(0, maxAmount)
          );

          onInputChange?.('', userTonalities);
          setInputValue('');
          return;
        }

        // User removed a keyword by clicking on the 'x' icon on the chip
        if (reason === 'reset') {
          setInputValue('');
          onInputChange?.('', userTonalities);
        }

        // No comma present, only store the input value
        setInputValue(newInputValue);
        onInputChange?.(newInputValue, userTonalities);
      }}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <StyledChip
            {...getTagProps({ index })}
            key={Math.random()}
            label={option}
            color="primary"
            variant="filled"
            size={size}
          />
        ))
      }
      renderInput={params => (
        <TextField
          variant="outlined"
          {...textFieldProps}
          {...params}
          size={size}
          onFocus={() => setIsPlaceholderShown(false)}
          onBlur={() => setIsPlaceholderShown(true)}
          placeholder={
            isPlaceholderShown
              ? textFieldProps?.placeholder ?? translate('common.tonality.custom.placeholder')
              : undefined
          }
          disabled={hasReachedMaxAmount}
          inputProps={{
            ...params.inputProps,
            ...textFieldProps?.inputProps,
            className: cx(params.inputProps?.className, textFieldProps?.inputProps?.className),
            disabled: hasReachedMaxAmount
          }}
          InputProps={{
            ...params.InputProps,
            ...textFieldProps?.InputProps,
            className: cx(params.InputProps?.className, textFieldProps?.InputProps?.className),
            disabled: hasReachedMaxAmount
          }}
        />
      )}
    />
  );
};

const StyledChip = styled(Chip)`
  color: ${({ theme }) => theme.colors.white};

  .MuiChip-deleteIcon {
    color: rgba(255, 255, 255, 0.7);
  }
`;
