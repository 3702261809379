/* eslint-disable @typescript-eslint/no-explicit-any */
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { WordAttributeWithOptions } from 'types/ReduxStateTypes';

import Emoji from '../base/Emoji';
import FormGroup from '../base/FormGroup';
import { WordAttributeRadio } from './WordAttributeRadio';

type Props = {
  wordAttributesOptions: WordAttributeWithOptions[];
  wordAttributes: any[];
  availableVariables: string[];
  disabled?: boolean;

  push: (obj: any) => void;
  remove<WordAttributeWithOption>(index: number): WordAttributeWithOption | undefined;
};

const WordAttributesConfig = ({
  wordAttributesOptions,
  wordAttributes,
  availableVariables,
  disabled,
  push,
  remove
}: Props) => {
  return (
    <>
      {wordAttributesOptions.map(({ value, label, options }) => (
        <TinyFormGroup
          key={value}
          label={label ? `word_param.${label}` : undefined}
          horizontal
          gutter
        >
          {options?.map(option => (
            <WordAttributeRadio
              values={wordAttributes}
              object={{ value, label, option }}
              push={push}
              remove={remove}
              key={option.value}
              isDisabled={
                disabled ||
                (value !== 'model_rank_score' &&
                  value !== 'readability' &&
                  !availableVariables.includes(value))
              }
              label={<FormattedMessage id={option?.label || value} />}
              avatar={option?.icon ? <Emoji code={option.icon} label={option.value} /> : undefined}
            />
          ))}
        </TinyFormGroup>
      ))}
    </>
  );
};

const TinyFormGroup = styled(FormGroup)`
  margin-bottom: 0 !important;
`;

export default WordAttributesConfig;
