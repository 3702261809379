import { WordAttributeWithOption } from 'types/ReduxStateTypes';

export const defaultWordAttributes: WordAttributeWithOption[] = [
  {
    value: 'readability',
    label: 'easy',
    option: {
      label: 'goal_score.readability.easy',
      value: 'easy',
      icon: 0x1f642,
      oppositeValue: false
    }
  }
];
