import cuid from 'cuid';

import { Suggestion } from '../../types';

const createNewSuggestion = (overrides: Partial<Suggestion> = {}): Suggestion => ({
  id: cuid(),
  text: '',
  textItemId: 'null',
  generationId: 0,
  score: 0,
  subSuggestions: [],
  isLoading: false,
  ...overrides
});

export default createNewSuggestion;
