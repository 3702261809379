import { Favorite, FavoriteBorder } from '@mui/icons-material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Checkbox, IconButton } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import cursorClickingIcon from 'assets/icons/icon-cursor-clicking.svg';
import FlexContainer from 'components/FlexContainer';
import TemplateMenu from 'features/aiWriter/commandTemplates/TemplateMenu';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { httpFavoriteTemplate } from 'services/backofficeIntegration/http/endpoints/textGeneration/commandTemplates/favorities/httpFavoriteTemplate';
import { httpUnfavoriteTemplate } from 'services/backofficeIntegration/http/endpoints/textGeneration/commandTemplates/favorities/httpUnfavoriteTemplate';
import { TemplateDto } from 'services/backofficeIntegration/http/endpoints/textGeneration/commandTemplates/httpGetCommandTemplates';
import gtmIds from 'services/tracking/GTMIds';
import { withGtmInteraction } from 'services/tracking/withGtmInteraction';
import { useAppSelector } from 'store/hooks';
import styled from 'styled-components';
import useTr from 'utils/hooks/useTr';

import { ModalListCard } from '../../theme-2024/modal-list-card/ModalListCard';

function getRoundedUsageCount(usageCount: number) {
  if (usageCount < 1000) {
    return usageCount;
  }

  // Shorten the usage count to 1 decimal place
  // 1000 -> 1k
  // 1234 -> 1.2k

  const rounded = Math.round(usageCount / 100) / 10;

  return `${rounded}k`;
}

export function TemplateTile(props: {
  template: TemplateDto;
  onClick: (dto: TemplateDto) => void;
  onEditClicked: (dto: TemplateDto) => void;
  onDeleteClicked: (dto: TemplateDto) => void;
  refetch: () => void;
}) {
  const { template, onClick, onEditClicked, onDeleteClicked, refetch } = props;
  const translate = useTr();
  const customerId = useAppSelector(state => state.customer.id);

  const { mutate: favoriteTemplate, isLoading: isFavoriteLoading } = useMutation({
    mutationFn: httpFavoriteTemplate.callEndpoint,
    onSuccess: () => refetch()
  });
  const { mutate: unfavoriteTemplate, isLoading: isUnfavoriteLoading } = useMutation({
    mutationFn: httpUnfavoriteTemplate.callEndpoint,
    onSuccess: () => refetch()
  });

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isOptionsOpen = Boolean(anchorEl);

  const handleClickOption = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseOption = () => {
    setAnchorEl(null);
  };

  const isFavoriteActionLoading = isFavoriteLoading || isUnfavoriteLoading;

  function handleCheckboxChange(checked: boolean) {
    if (checked) {
      favoriteTemplate({ templateId: template.id });
      return;
    }

    unfavoriteTemplate({ templateId: template.id });
  }

  const handleDelete = () => {
    onDeleteClicked(template);
  };

  const handleEdit = () => {
    onEditClicked(template);
  };

  const handleTileClick = () => {
    if (!isOptionsOpen) {
      onClick(template);
    }
  };

  return (
    <ModalListCard
      {...withGtmInteraction(gtmIds.templatesLibrary.selectTemplate)}
      key={template.id}
      caption={template.category_name}
      title={template.title}
      subtitle={
        <FormattedMessage
          id="aiWriter.inspirations.templates.item.author"
          values={{ source: template.source }}
        />
      }
      titleActions={
        customerId === template.author_id && (
          <>
            <IconButton
              size="small"
              onClick={e => {
                e.stopPropagation();
                handleClickOption(e);
              }}
            >
              <MoreVertIcon />
            </IconButton>
            <TemplateMenu
              anchorEl={anchorEl}
              isOptionsOpen={isOptionsOpen}
              handleCloseOption={handleCloseOption}
              onDelete={handleDelete}
              onEdit={handleEdit}
            />
          </>
        )
      }
      footer={
        <>
          <TemplateUsageCount>
            <img
              src={cursorClickingIcon}
              width={24}
              height={24}
              alt={translate('aiWriter.inspirations.templates.item.usage_count')}
            />
            {getRoundedUsageCount(template.usage_count)}
          </TemplateUsageCount>
          <TemplateLikesCount>
            <Checkbox
              icon={<FavoriteBorder />}
              checkedIcon={<Favorite />}
              size="small"
              disabled={isFavoriteActionLoading}
              checked={Boolean(template.is_liked)}
              onClick={e => {
                // There is a checkbox inside button configurations, so bubbling must be prevented
                e.stopPropagation();
              }}
              onChange={(_, checked) => {
                handleCheckboxChange(checked);
              }}
            />
            {getRoundedUsageCount(template.like_count)}
          </TemplateLikesCount>
        </>
      }
      onClick={handleTileClick}
    >
      {template.description}
    </ModalListCard>
  );
}

const TemplateUsageCount = styled(FlexContainer).attrs({
  alignItems: 'center',
  direction: 'row'
})`
  gap: ${({ theme }) => theme.spacings.xsmall};
`;

const TemplateLikesCount = styled(FlexContainer).attrs({
  alignItems: 'center',
  direction: 'row'
})`
  gap: ${({ theme }) => theme.spacings.xsmall};
`;
