import { createSelector } from '@reduxjs/toolkit';
import { fallbackModelCountry } from 'features/aiWriter/store/utils/defaults/fallbackModelCountry';
import { fallbackModelLanguage } from 'features/aiWriter/store/utils/defaults/fallbackModelLanguage';
import { getUserAudiences } from 'features/audiences/store/selectors';
import { EmbeddingModel, LabeledEmbeddingModel } from 'features/embeddingModels/store/types';
import { getGetMappersForCountry } from 'features/group/store/selectors';
import { modelMapperFromWeMapper } from 'features/group/store/utils';
import { countryLabelSelector, languageLabelSelector } from 'features/language/store/selectors';
import { countBy, memoize, pickBy, uniqBy } from 'lodash';
import { APIModel } from 'services/api/embeddingModels/types';
import { RootState } from 'store/rootReducer';

export const getEmbeddingModels = (state: RootState): APIModel[] => state.models.models;
export const getEmbeddingModelsLookup = (state: RootState) => state.models.modelsLookup;

export const getEmbeddingModelDataById = (embeddingModelId: string, state: RootState) => {
  const models = getEmbeddingModels(state);
  const lookup = getEmbeddingModelsLookup(state);

  return models[lookup[embeddingModelId]];
};

export const getEmbeddingModelDataSelector = createSelector(
  getEmbeddingModels,
  getEmbeddingModelsLookup,
  (models, lookup) => memoize((embeddingModelId: string) => models[lookup[embeddingModelId]])
);

export const getGetEmbeddingModelById = createSelector(
  getEmbeddingModels,
  models => (id: string) => {
    return models.find(m => m.id === id);
  }
);

function translateModelLabels(
  model: APIModel,
  getLanguageLabel: (code: string) => string | null,
  getCountryLabel: (code: string) => string | null
): LabeledEmbeddingModel {
  const { id, language, country, friendly_country } = model;
  const languageLabel = getLanguageLabel(language) ?? '-';
  const countryLabel = getCountryLabel(country) ?? friendly_country;

  return {
    id,
    language,
    country,
    countryLabel,
    languageLabel
  };
}

const labeledModelsSelector = createSelector(
  getEmbeddingModels,
  languageLabelSelector,
  countryLabelSelector,
  (models, getLanguageLabel, getCountryLabel) => {
    return uniqBy(
      models.map(model => translateModelLabels(model, getLanguageLabel, getCountryLabel)),
      el => `${el.country}_${el.language}`
    );
  }
);

export const getEmbeddingModelsByCountry = createSelector(labeledModelsSelector, models => {
  const repeatingCountries = Object.keys(
    pickBy(
      countBy(models, model => model.country),
      count => count > 1
    )
  );

  return models.map<EmbeddingModel>(labeledModel => {
    const isRepeated = repeatingCountries.some(c => c === labeledModel.country);
    const label = isRepeated
      ? `${labeledModel.countryLabel} (${labeledModel.languageLabel})`
      : labeledModel.countryLabel;

    return {
      id: labeledModel.id,
      label,
      country: labeledModel.country,
      language: labeledModel.language
    };
  });
});

export const getEmbeddingModelsByLanguage = createSelector(labeledModelsSelector, models => {
  return models.map<EmbeddingModel>(labeledModel => {
    const label = `${labeledModel.languageLabel} (${labeledModel.country.toUpperCase()})`;

    return {
      id: labeledModel.id,
      label,
      country: labeledModel.country,
      language: labeledModel.language
    };
  });
});

export const getEmbeddingModelsByLanguageAndAudience = createSelector(
  getEmbeddingModelsByLanguage,
  getUserAudiences,
  (models, audiences): EmbeddingModel[] => {
    return models.filter(model =>
      audiences.some(
        audience => model.country === audience.country && model.language === audience.language
      )
    );
  }
);

export const getFilterAndSortEmbeddingModels = createSelector(
  getEmbeddingModels,
  models => (filterFn: (model: APIModel) => boolean, sort: 'asc' | 'desc') => {
    const sortingFn = (a: APIModel, b: APIModel) => {
      if (sort === 'asc') {
        return new Date(a.date).getTime() - new Date(b.date).getTime();
      } else {
        return new Date(b.date).getTime() - new Date(a.date).getTime();
      }
    };
    return models.filter(filterFn).sort(sortingFn);
  }
);

export const getGetEmbeddingModelsForCountry = createSelector(getEmbeddingModels, models =>
  memoize(
    (country: string, language: string) =>
      models
        .filter(model => model.country === country && model.language === language)
        .sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()),
    (arg1, arg2) => JSON.stringify([arg1, arg2])
  )
);

export const getLatestEmbeddingModelForCountry = createSelector(
  getGetEmbeddingModelsForCountry,
  getModelsForCountry =>
    memoize((countryId: string) => {
      const [country, language] = countryId.split('_');
      return getModelsForCountry(country, language)[0];
    })
);

export const getLanguagesList = createSelector(
  getEmbeddingModels,
  languageLabelSelector,
  (models, getLanguageLabel) =>
    uniqBy(
      models.map(model => ({ value: model.language, label: getLanguageLabel(model.language) })),
      m => m.value
    )
);

export const getEmbeddingModelMapperVariablesSelector = createSelector(
  getEmbeddingModelDataSelector,
  getModelData =>
    memoize(
      (embeddingModelId: string, modelMapper: string) => {
        const model = getModelData(embeddingModelId);
        const mapper = model?.mappers.find(i => i.name === modelMapper);
        return mapper ? mapper.variables : [];
      },
      // By default memoized takes only the first argument as cache key
      (embeddingModelId, modelMapper) => JSON.stringify({ embeddingModelId, modelMapper })
    )
);

export const getGetCountryEmbeddingModelGroupById = createSelector(
  getEmbeddingModelsByCountry,
  models => (embeddingModelId: string | undefined) =>
    embeddingModelId ? models.find(model => model.id === embeddingModelId) : undefined
);

export const getGetEmbeddingModelLanguageById = createSelector(
  getEmbeddingModelsByLanguage,
  models => (id: string) => {
    return models.find(m => m.id === id)?.language ?? fallbackModelLanguage;
  }
);

export const getGetEmbeddingModelLanguageAndCountryById = createSelector(
  getEmbeddingModelsByLanguage,
  models => (id: string) => {
    const model = models.find(m => m.id === id);
    return {
      language: model?.language ?? fallbackModelLanguage,
      country: model?.country ?? fallbackModelCountry
    };
  }
);

export const getGetFirstGenericEmbeddingModelMapperById = createSelector(
  getEmbeddingModels,
  models => (embeddingModelId: string) => {
    const model = models.find(m => m.id === embeddingModelId);

    if (model && model?.mappers.length > 0) {
      // First mapper is generic one
      return model.mappers[0];
    }

    return undefined;
  }
);

export const getGetEmbeddingModelMappersWithFallbackById = createSelector(
  getGetCountryEmbeddingModelGroupById,
  getGetMappersForCountry,
  getGetFirstGenericEmbeddingModelMapperById,
  (getCountryModelGroupById, getMappersForCountry, getFirstGenericModelMapperById) =>
    (embeddingModelId: string) => {
      const countryModelGroup = getCountryModelGroupById(embeddingModelId);

      const countryMappers = getMappersForCountry({
        country: countryModelGroup?.country,
        language: countryModelGroup?.language
      }).map(modelMapperFromWeMapper);

      if (countryMappers.length > 0) {
        return countryMappers;
      }

      const firstGenericMapper = getFirstGenericModelMapperById(embeddingModelId);

      if (firstGenericMapper) {
        return [firstGenericMapper];
      }

      return [];
    }
);
