import { Delete } from '@mui/icons-material';
import LoadingButton from '@mui/lab/LoadingButton';
import { Button, FormControl, MenuItem, Select, TextField, Typography } from '@mui/material';
import FlexContainer from 'components/FlexContainer';
import DeleteIconButton from 'components/iconButtons/DeleteIconButton';
import { CloseModal } from 'components/modals/types';
import Toast from 'components/toasts/Toast';
import { RemoveFlashActionDialog } from 'features/aiWriter/AiWriterSidebar/steps/chat/RemoveFlashActionDialog';
import { useCreateTemplateMutation } from 'features/aiWriter/commandTemplates/useCreateTemplateMutation';
import { useDeleteTemplateMutation } from 'features/aiWriter/commandTemplates/useDeleteTemplate';
import { useEditTemplateMutation } from 'features/aiWriter/commandTemplates/useEditTemplate';
import { getCurrentModelLanguageAndCountry } from 'features/aiWriter/store/selectors';
import { ModelAutocomplete } from 'features/embeddingModels/ModelAutocomplete';
import { getEmbeddingModelsByLanguageAndAudience } from 'features/embeddingModels/store/selectors';
import { EmbeddingModel } from 'features/embeddingModels/store/types';
import { Form, Formik, useField, useFormikContext } from 'formik';
import { FormattedMessage } from 'react-intl';
import {
  invalidateCommandTemplatesQuery,
  TemplateSharingPermission
} from 'services/backofficeIntegration/http/endpoints/textGeneration/commandTemplates/httpGetCommandTemplates';
import { GAEvents } from 'services/tracking/GAEvents';
import gtmIds from 'services/tracking/GTMIds';
import { withGtmInteraction } from 'services/tracking/withGtmInteraction';
import { useAppSelector } from 'store/hooks';
import styled from 'styled-components';
import useDialog from 'utils/hooks/useDialog';
import useTr from 'utils/hooks/useTr';
import { object, string } from 'yup';

type Template = {
  id: number;
  title: string;
  template: string;
  category_id: number;
  description: string;
  sharing_permission: TemplateSharingPermission;
  language?: string;
  country?: string;
};

const TITLE_MAX_CHAR = 25;
const BODY_MAX_CHAR = 600;

const validationSchema = object().shape({
  title: string().max(TITLE_MAX_CHAR).required(),
  template: string().max(BODY_MAX_CHAR).required()
});

export const sharePermissionsOptions = [
  {
    value: TemplateSharingPermission.private,
    id: TemplateSharingPermission.private,
    name: <FormattedMessage id="common.sharing_permission.private" />
  },
  {
    value: TemplateSharingPermission.team,
    id: TemplateSharingPermission.team,
    name: <FormattedMessage id="common.sharing_permission.team" />
  }
];

export type CustomModalProps = CloseModal & {
  modalMode: 'create' | 'edit';
  template?: Template;
  categoryId: number;
};

export function FlashActionsModal({ template, closeModal, categoryId }: CustomModalProps) {
  const { currentModelCountry, currentModelLanguage } = useAppSelector(
    getCurrentModelLanguageAndCountry
  );
  const models = useAppSelector(getEmbeddingModelsByLanguageAndAudience);

  const { mutate: createTemplate, isLoading } = useCreateTemplateMutation();
  const editTemplate = useEditTemplateMutation();
  const deleteTemplate = useDeleteTemplateMutation();

  const [isDialogOpen, openDialog, closeDialog] = useDialog();

  const selectedLanguageModelId =
    template &&
    models.find(
      model => model.language === template?.language && model.country === template?.country
    )?.id;
  const defaultLanguageModelId = models.find(
    model => model.language === currentModelLanguage && model.country === currentModelCountry
  )?.id;

  const initialValues: Template = {
    id: template?.id || 0,
    title: template?.title || '',
    template: template?.template || '',
    category_id: template?.category_id || categoryId,
    description: template?.description || 'Flash action',
    sharing_permission: template?.sharing_permission || TemplateSharingPermission.private,
    language: selectedLanguageModelId || defaultLanguageModelId,
    country: template?.country || currentModelCountry
  };

  function handleEditTemplate(values: Template) {
    editTemplate.mutate(
      {
        commandTemplateId: values.id,
        title: values.title,
        template: values.template,
        description: initialValues.description,
        sharing_permission: values.sharing_permission,
        category_id: categoryId,
        language: values.language ?? currentModelLanguage,
        country: values.country ?? currentModelCountry
      },
      {
        onSuccess: () => {
          invalidateCommandTemplatesQuery();
          closeModal();
          Toast.success('aiWriter.inspirations.suggestions.flash_actions.edit.success');
        },
        onError: () => Toast.apiError()
      }
    );
  }

  function handleCreateTemplate(values: Template) {
    GAEvents.flashActionCreated({
      language: values.language,
      country: values.country,
      prompt: values.template
    });
    createTemplate(
      {
        title: values.title,
        template: values.template,
        category_id: values.category_id,
        description: initialValues.description,
        is_sharable: false,
        sharing_permission: values.sharing_permission,
        country: values.country ?? currentModelCountry,
        language: values.language ?? currentModelLanguage
      },
      {
        onError: () => Toast.apiError(),
        onSuccess: () => {
          invalidateCommandTemplatesQuery();
          closeModal();
          Toast.success('aiWriter.inspirations.suggestions.flash_actions.create.success');
        }
      }
    );
  }

  function handleDeleteTemplate(templateId: number) {
    deleteTemplate.mutate(
      { commandTemplateId: templateId },
      {
        onSuccess: () => {
          invalidateCommandTemplatesQuery();
          closeModal();
          Toast.success('aiWriter.inspirations.suggestions.flash_actions.delete.success');
        },
        onError: () => {
          Toast.apiError();
        }
      }
    );
  }

  function handleSubmit(values: Template) {
    const embeddingModel = models.find(model => model.id === values.language);
    const updatedValues = {
      ...values,
      language: embeddingModel?.language,
      country: embeddingModel?.country
    };
    if (template) {
      handleEditTemplate(updatedValues);
    } else {
      handleCreateTemplate(embeddingModel ? updatedValues : values);
    }
  }

  return (
    <Root>
      <RemoveFlashActionDialog
        isDialogOpen={isDialogOpen}
        onCancel={closeDialog}
        onConfirm={() => {
          template && handleDeleteTemplate(template.id);
          closeDialog();
        }}
        isShared={template?.sharing_permission === TemplateSharingPermission.team}
      />
      <TitleContainer>
        <Typography variant="h6">
          <FormattedMessage
            id={
              template
                ? 'aiWriter.inspirations.suggestions.flash_actions.modal.edit.title'
                : 'aiWriter.inspirations.suggestions.flash_actions.modal.create.title'
            }
          />
        </Typography>
        <DeleteIconButton onClick={closeModal} />
      </TitleContainer>

      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        <Form>
          <FlexContainer gap="three">
            <NameField name="title" />
            <DescriptionField name="template" />
            <LanguageField models={models} name="language" />
            <SharePermissionsField name="sharing_permission" />

            <FlexContainer direction="row" justifyContent="space-between">
              {template && (
                <Button onClick={openDialog} color="error" size="large" startIcon={<Delete />}>
                  <FormattedMessage id="common.delete" />
                </Button>
              )}
              <FlexContainer direction="row" gap="one" justifyContent="flex-end">
                <Button onClick={closeModal}>
                  <FormattedMessage id="common.cancel" />
                </Button>
                <LoadingButton
                  {...withGtmInteraction(gtmIds.templatesLibrary.saveTemplate)}
                  type="submit"
                  variant="contained"
                  loading={isLoading}
                  size="large"
                >
                  <FormattedMessage id="common.save" />
                </LoadingButton>
              </FlexContainer>
            </FlexContainer>
          </FlexContainer>
        </Form>
      </Formik>
    </Root>
  );
}

function DescriptionField(props: { name: string }) {
  const { name } = props;
  const [field, meta] = useField(name);
  const tr = useTr();

  return (
    <FormControl>
      <Typography variant="body2" color="textSecondary">
        <FormattedMessage id="common.description" />
      </Typography>
      <TextField
        {...field}
        {...props}
        error={!!meta.error && meta.touched}
        placeholder={tr(
          'aiWriter.inspirations.suggestions.flash_actions.modal.description.placeholder'
        )}
        fullWidth
        multiline
        rows={5}
        helperText={
          <Caption>
            <span style={{ maxWidth: '300px' }}>
              <FormattedMessage id="aiWriter.inspirations.suggestions.flash_actions.modal.prompt.tip" />
            </span>
            <span>
              {field.value?.length ?? 0}/{BODY_MAX_CHAR}
            </span>
          </Caption>
        }
      />
    </FormControl>
  );
}

function NameField(props: { name: string }) {
  const { name } = props;
  const [field, meta] = useField(name);

  return (
    <FormControl>
      <Typography variant="body2" color="textSecondary">
        <FormattedMessage id="aiWriter.inspirations.suggestions.flash_actions.modal.name" />
      </Typography>
      <TextField
        {...field}
        fullWidth
        size="small"
        error={!!meta.error && meta.touched}
        helperText={
          <Caption>
            <FormattedMessage
              id="aiWriter.inspirations.templates.modal.create.title_field.caption"
              values={{
                value: TITLE_MAX_CHAR
              }}
            />
          </Caption>
        }
      />
    </FormControl>
  );
}

function LanguageField(props: { name: string; models: EmbeddingModel[] }) {
  const { name, models } = props;
  const [field] = useField(name);
  const { setFieldValue } = useFormikContext<Template>();
  const handleLanguage = (model: string) => {
    setFieldValue('language', model);
  };

  return (
    <HalfContainer>
      <FormControl variant="outlined" fullWidth>
        <Typography variant="body2" color="textSecondary">
          <FormattedMessage id="common.language" />
        </Typography>
        <ModelAutocomplete models={models} {...field} onSelect={handleLanguage} size="small" />
      </FormControl>
    </HalfContainer>
  );
}

function SharePermissionsField(props: { name: string }) {
  const { name } = props;
  const [field, meta] = useField(name);

  return (
    <HalfContainer>
      <FormControl variant="outlined" fullWidth>
        <Typography variant="body2" color="textSecondary">
          <FormattedMessage id="aiWriter.inspirations.suggestions.flash_actions.modal.share" />
        </Typography>
        <Select {...field} error={!!meta.error && meta.touched} size="small">
          {sharePermissionsOptions.map(option => (
            <MenuItem key={option.id} value={option.value}>
              {option.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </HalfContainer>
  );
}

const Root = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem;
  overflow: auto;
`;

const Caption = styled(Typography).attrs({
  variant: 'caption'
})`
  display: flex;
  justify-content: space-between;
`;

const HalfContainer = styled.div`
  width: 70%;
`;

const TitleContainer = styled(FlexContainer).attrs({
  direction: 'row',
  alignItems: 'center',
  justifyContent: 'space-between'
})`
  margin-bottom: ${({ theme }) => theme.spacings.three};
`;
