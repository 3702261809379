const scoreColors = {
  extremelyLow: '#F44336',
  veryLow: '#F57C00',
  lowAverage: '#FF9800',
  mean: '#FAC626',
  highAverage: '#81C784',
  veryHigh: '#4CAF50',
  extremelyHigh: '#388E3C'
};

export const getScoreColor = (n: number) => {
  let color;
  if (n < 0.5) {
    color = scoreColors.extremelyLow;
  } else if (n < 0.75) {
    color = scoreColors.veryLow;
  } else if (n < 1) {
    color = scoreColors.lowAverage;
  } else if (n === 1) {
    color = scoreColors.mean;
  } else if (n < 1.25) {
    color = scoreColors.highAverage;
  } else if (n < 150) {
    color = scoreColors.veryHigh;
  } else {
    color = scoreColors.extremelyHigh;
  }
  return color;
};
