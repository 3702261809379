import { makeCurrentUserQueryKeyPrefix } from 'features/currentUser/currentUserQueries';
import { AppLanguage } from 'features/language/store/types';
import {
  ApiModularWorkflowStepSettings,
  isLocalizableSetting
} from 'features/modular-workflow/builder/types-api';
import {
  BuilderModularWorkflow,
  BuilderModularWorkflowStepInputWithLocalizedValue,
  BuilderModularWorkflowStepInputWithValue
} from 'features/modular-workflow/builder/types-builder';
import { BaseSuccessDto } from 'services/backofficeIntegration/http/BaseSuccessDto';
import { backofficeClient } from 'services/backofficeIntegration/http/client/createBackofficeClient';
import { queryEndpoint } from 'services/backofficeIntegration/http/endpointDefinition';

type SuccessDto = BaseSuccessDto<BuilderModularWorkflow>;

export const httpGetModularWorkflowBuilderData = queryEndpoint({
  makeQueryKey: (id: number) => {
    return [...makeCurrentUserQueryKeyPrefix(), 'modular-workflow', id] as const;
  },
  callEndpoint: (id: number) =>
    backofficeClient
      .get<SuccessDto>(`/workflows/${id}`, {
        params: {
          view_mode: 'builder'
        }
      })
      .then(response => response.data.data)
      .then(
        (data): BuilderModularWorkflow => ({
          ...data,
          // Temporary fix for the new API property
          description: data.description ?? '',
          // Backwards compatibility for the new localized values
          name_localized: {
            [AppLanguage.English]: data.name_localized[AppLanguage.English] ?? data.name,
            [AppLanguage.German]: data.name_localized[AppLanguage.German] ?? data.name,
            [AppLanguage.Spanish]: data.name_localized[AppLanguage.Spanish] ?? data.name
          },
          description_localized: {
            [AppLanguage.English]:
              data.description_localized[AppLanguage.English] ?? data.description,
            [AppLanguage.German]:
              data.description_localized[AppLanguage.German] ?? data.description,
            [AppLanguage.Spanish]:
              data.description_localized[AppLanguage.Spanish] ?? data.description
          },
          steps: data.steps.map(step => ({
            ...step,
            name_localized: {
              [AppLanguage.English]: step.name_localized[AppLanguage.English] ?? step.name,
              [AppLanguage.German]: step.name_localized[AppLanguage.German] ?? step.name,
              [AppLanguage.Spanish]: step.name_localized[AppLanguage.Spanish] ?? step.name
            },
            inputs: step.inputs.map(input => {
              if (
                isLocalizableSetting(
                  (input as unknown as ApiModularWorkflowStepSettings).data_type.type
                )
              ) {
                const typedInput = input as BuilderModularWorkflowStepInputWithLocalizedValue;

                return {
                  ...input,
                  is_localized: true,
                  value: {
                    [AppLanguage.English]: typedInput.value?.[AppLanguage.English] ?? input.value,
                    [AppLanguage.German]: typedInput.value?.[AppLanguage.German] ?? input.value,
                    [AppLanguage.Spanish]: typedInput.value?.[AppLanguage.Spanish] ?? input.value
                  }
                } as BuilderModularWorkflowStepInputWithLocalizedValue;
              }

              return {
                ...input,
                is_localized: false
              } as BuilderModularWorkflowStepInputWithValue;
            })
          }))
        })
      )
});
