import styled from 'styled-components';

const PlaceholderContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: 100%;
  text-align: center;
  padding: 2rem;
  border: 1px dashed #9aa0ac;
  border-radius: 3px;
  font-size: 1.25rem;
  color: #9aa0ac;
`;

export default PlaceholderContainer;
