import { changeGenerateTextConfig } from 'features/aiWriter/store/actions/config/actions';
import { getActiveTab } from 'features/aiWriter/store/selectors';
import { useShowApplyInformationModal } from 'features/information/apply-information/ApplyInformationModal';
import { InformationField } from 'features/information/apply-information/InformationField';
import gtmIds from 'services/tracking/GTMIds';
import { useAppDispatch, useAppSelector } from 'store/hooks';

export function ProjectInformationSelection() {
  const showApplyInformationModal = useShowApplyInformationModal();

  const {
    generateTextConfig: { informationList }
  } = useAppSelector(getActiveTab);

  const dispatch = useAppDispatch();

  const handleAddClick = () => {
    showApplyInformationModal({
      preselectedInformation: informationList,
      applyGtmId: gtmIds.aiWriter.applyInformation,
      onApply: informationList => {
        dispatch(
          changeGenerateTextConfig({
            informationList
          })
        );
      }
    });
  };

  const handleRemove = (id: number) => {
    dispatch(
      changeGenerateTextConfig({
        informationList: informationList?.filter(information => information.id !== id)
      })
    );
  };

  return (
    <InformationField
      gtmId={gtmIds.aiWriter.openInformationModal}
      informationList={informationList}
      onApply={handleAddClick}
      onRemove={handleRemove}
    />
  );
}
