import { CircularProgress } from '@mui/material';
import { FlashScoreEmotionalityProgress } from 'features/aiWriter/AiWriterTextEditor/EditorTopToolbar/flashScorePopper/FlashScoreEmotionalityProgress';
import { FlashScoreReadabilityProgress } from 'features/aiWriter/AiWriterTextEditor/EditorTopToolbar/flashScorePopper/FlashScoreReadabilityProgress';
import { FlashScoreTextRatingResult } from 'features/flashScore/useScoreTexts';

type Props = {
  scoringData: FlashScoreTextRatingResult | undefined;
  isAnalyzing: boolean;
};

export const TextReplacementScoringProgressCircles = ({ scoringData, isAnalyzing }: Props) => {
  if (isAnalyzing || !scoringData) {
    return (
      <>
        <CircularProgress size={24} />
        <CircularProgress size={24} />
      </>
    );
  }

  return (
    <>
      <FlashScoreEmotionalityProgress scoringData={scoringData} isSupportedLanguage={true} />
      <FlashScoreReadabilityProgress scoringData={scoringData} isSupportedLanguage={true} />
    </>
  );
};
