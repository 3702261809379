/* eslint-disable react/prop-types */
import { toggleDimensionsWidget } from 'components/layout/toolbar/toolbarSlice';
import { useModal } from 'components/modals';
import { SelectCheckboxContainer } from 'components/Table/TableComponents';
import { DimensionOwner } from 'features/dimensions/store/types';
import { languageLabelSelector } from 'features/language/store/selectors';
import { memo, useCallback, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { CellProps, Column, Filters, Row, TableInstance } from 'react-table';
import DimensionAPI from 'services/api/dimension';
import { Dimension } from 'services/api/dimension/types';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { RootState } from 'store/rootReducer';
import { useLocalStorage } from 'utils/hooks/useLocalStorage';

import Button from '../../components/base/Button';
import Checkbox from '../../components/base/Checkbox';
import LanguagesFilter from '../../components/Table/filterComponents/LanguagesFilter';
import Table, { FetchDataProps } from '../../components/Table/Table';
import Tooltip from '../../components/tooltips/Tooltip';
import LocalStorageKey from '../../config/localStorageKey';
import { useList } from '../../utils/hooks/useList';
import RowActions from './RowActions';
import { showDimensionsWords } from './store/actions';

function extractFilterValue(filterId: string, filters: Filters<Dimension>) {
  return filters.find(f => f.id === filterId)?.value ?? '';
}

const DimensionsTable = memo(() => {
  const dispatch = useAppDispatch();
  const { showModal } = useModal();
  const customerId = useAppSelector(state => state.customer.id);
  const refetchCounter = useAppSelector(
    (state: RootState) => state.dimensions.dimensionsPageRefetchCounter
  );
  const getLanguageLabel = useAppSelector(languageLabelSelector);

  const [belongsTo, setBelongsTo] = useLocalStorage<DimensionOwner>(
    LocalStorageKey.DimensionsTableOwner,
    'group'
  );

  const [data, setData] = useState<Dimension[]>([]);
  const [pageCount, setPageCount] = useState(1);
  const [loading, setLoading] = useState(false);

  const [selectedRows, selectRow, deselectRow, setSelectedRows] = useList<string>();

  const tableStateOverrides = useMemo(
    () => ({
      selectedRowIds: selectedRows.reduce((acc: Record<string, boolean>, selectedRow) => {
        acc[selectedRow] = true;
        return acc;
      }, {})
    }),
    [selectedRows]
  );

  const fetchData = useCallback(
    async (props: FetchDataProps<Dimension>) => {
      const { pageIndex, pageSize, filters, sortBy } = props;
      // we disable multi-sorting for this table so only the first one is relevant
      const [soleSortingRule] = sortBy;

      const language = extractFilterValue('language', filters);
      const label = extractFilterValue('label', filters);
      const description = extractFilterValue('description', filters);
      const project = extractFilterValue('project', filters);
      const words = extractFilterValue('words', filters);
      const author = extractFilterValue('author', filters);

      setLoading(true);
      const response = await DimensionAPI.getAll({
        label,
        language,
        description,
        project,
        words,
        author,
        belongsTo,
        page: pageIndex + 1,
        itemsPerPage: pageSize,
        sortBy: soleSortingRule?.id,
        sortOrder: soleSortingRule?.desc ? 'desc' : 'asc'
      });
      if (response.status) {
        setData(response.data.data);
        setPageCount(Math.ceil(response.data.total / pageSize));
        setSelectedRows([]);
      }
      setLoading(false);
    },
    [belongsTo, setSelectedRows]
  );

  const columns = useMemo(
    (): Array<Column<Dimension>> => [
      {
        id: 'selection',
        noHeaderEllipsis: true,
        Header: ({
          page,
          selectedFlatRows
        }: {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          page: Row<Dimension>[] & any[];
          selectedFlatRows: Array<unknown>;
        }) => {
          return (
            <SelectCheckboxContainer>
              <Checkbox
                checked={selectedFlatRows.length === page.length}
                onChange={() => {
                  if (selectedFlatRows.length === page.length) {
                    setSelectedRows([]);
                  } else {
                    setSelectedRows(page.map(row => row.id));
                  }
                }}
              />
            </SelectCheckboxContainer>
          );
        },
        Cell: ({ row }: CellProps<Dimension>) => (
          <SelectCheckboxContainer>
            <Checkbox
              checked={row.isSelected}
              onChange={() => {
                const value = row.id;

                if (row.isSelected) {
                  deselectRow(value);
                } else {
                  selectRow(value);
                }
              }}
            />
          </SelectCheckboxContainer>
        ),
        width: 40
      },
      {
        accessor: 'label',
        Header: () => <FormattedMessage id="dimensions_mgmt.column.label" />,
        minWidth: 150,
        maxWidth: 250,
        ellipsis: true
      },
      {
        accessor: (row: Dimension) => row.author.name,
        id: 'author',
        Header: () => <FormattedMessage id="dimensions_mgmt.column.author" />,
        Cell: ({ cell: { value } }: CellProps<Dimension, string>) => <>{value || 'NEURO FLASH'}</>,
        minWidth: 120,
        maxWidth: 200,
        ellipsis: true,
        disableSortBy: true
      },
      {
        accessor: 'description',
        Header: () => <FormattedMessage id="dimensions_mgmt.column.description" />,
        minWidth: 120,
        ellipsis: true,
        disableSortBy: true
      },
      {
        accessor: 'project',
        Header: () => <FormattedMessage id="dimensions_mgmt.column.project" />,
        minWidth: 120,
        ellipsis: true,
        disableSortBy: true
      },
      {
        accessor: 'words',
        Header: () => <FormattedMessage id="dimensions_mgmt.column.words" />,
        minWidth: 200,
        filter: 'stringsArray',
        Cell: ({ cell: { value = [] } }) => (
          <Tooltip content={value.join(', ')}>
            <div className="ellipsis">{value.join(', ')}</div>
          </Tooltip>
        ),
        disableSortBy: true
      },
      {
        accessor: 'total',
        Header: () => <FormattedMessage id="dimensions_mgmt.column.total" />,
        width: 100,
        disableFilters: true
      },
      {
        accessor: 'language',
        Header: () => <FormattedMessage id="dimensions_mgmt.column.language" />,
        Cell: ({ cell: { value } }) => <>{getLanguageLabel(value) ?? value}</>,
        Filter: LanguagesFilter,
        filter: 'exactText',
        minWidth: 140,
        maxWidth: 160,
        disableSortBy: true
      },
      {
        accessor: 'id',
        Header: '',
        disableFilters: true,
        disableSortBy: true,
        maxWidth: 220,
        minWidth: 190,
        Cell: ({ cell: { value }, row }) =>
          customerId === row.original.author.id ? (
            <div className="w-100 h-100 text-center" onClick={e => e.stopPropagation()}>
              <Button
                color="primary"
                size="sm"
                icon="edit"
                className="mr-1"
                onClick={() => {
                  const { id, label, description, project_id: projectId, project } = row.original;

                  showModal({
                    modalType: 'RENAME_DIMENSION',
                    modalProps: {
                      id,
                      label,
                      description,
                      project: projectId ? { id: projectId, name: project } : null
                    }
                  });
                }}
              >
                <FormattedMessage id="common.rename" />
              </Button>
              <Button
                color="secondary"
                size="sm"
                icon="book"
                onClick={() => {
                  dispatch(showDimensionsWords(value));
                  dispatch(toggleDimensionsWidget(true));
                }}
              >
                <FormattedMessage id="common.edit" />
              </Button>
            </div>
          ) : null
      }
    ],
    [setSelectedRows, deselectRow, selectRow, getLanguageLabel, customerId, showModal, dispatch]
  );

  const tableActions = useCallback(
    (instance: TableInstance<Dimension>) => (
      <RowActions
        selectedDimensions={instance.page
          .filter(row => selectedRows.includes(row.id))
          .map(row => row.original)}
        belongsTo={belongsTo}
        setBelongsTo={setBelongsTo}
      />
    ),
    [belongsTo, selectedRows, setBelongsTo]
  );

  const rowEvents = useMemo(
    () => ({
      onClick: (row: Row<Dimension>) => {
        const value = row.id;
        if (row.isSelected) {
          deselectRow(value);
        } else {
          selectRow(value);
        }
      }
    }),
    [deselectRow, selectRow]
  );

  return (
    <Table
      height={550}
      data={data}
      columns={columns}
      fetchData={fetchData}
      remotePageCount={pageCount}
      forceRefetch={refetchCounter}
      loading={loading}
      manualPagination
      stateOverrides={tableStateOverrides}
      topTableActions={tableActions}
      rowEvents={rowEvents}
      disableMultiSort
    />
  );
});

export default DimensionsTable;
