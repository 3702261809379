export const getWordCount = (text: string) => getWords(text).length;

export const getWords = (text: string) => {
  const trimmedText = text.trim();
  // Split always returns an array with one element, even if the string is empty
  if (trimmedText.length === 0) {
    return [];
  }

  return trimmedText.split(/\s+/g) ?? [];
};
