import { Check } from '@mui/icons-material';
import { Chip, Tooltip } from '@mui/material';
import { truncateText } from 'features/aiWriter/AiWriterProjectOverview/TeamMemberFilter';
import React from 'react';
import { AiWriterProjectTagType } from 'services/api/aiWriter/types';
import { GAEvents } from 'services/tracking/GAEvents';
import styled from 'styled-components';
import { newThemeConfig } from 'styles/new-theme/newThemeConfig';

export const TagChipColors: Record<string, string> = {
  primary: newThemeConfig.colors.primaryColorMain,
  blue700: '#2962FF',
  blue600: '#03A9F4',
  blue900: '#01579B',
  purple100: '#B388FF',
  purple200: '#7C4DFF',
  purple700: '#512DA8',
  purple900: '#311B92',
  green100: '#C8E6C9',
  green700: '#00C853',
  green600: '#43A047',
  green900: '#1B5E20',
  red100: '#FECDD2',
  red700: '#D50000',
  red600: '#E53935',
  red900: '#B71C1C',
  orange100: '#FFE0B2',
  orange700: '#FF6D00',
  orange600: '#FB8C00',
  orange900: '#EF6C00'
};

type TagProps = {
  tag: AiWriterProjectTagType;
  onClick?: (e: React.MouseEvent<HTMLElement>) => void;
  onDelete?: (e: React.MouseEvent<HTMLElement>) => void;
};

export const AiWriterProjectTag = ({ tag, onClick, onDelete }: TagProps) => {
  return (
    <Tooltip title={tag.name.length > 10 ? tag.name : ''}>
      <StyledChip
        icon={onDelete ? <Check /> : undefined}
        key={tag.id}
        $color={tag.color}
        label={truncateText(tag.name, 10)}
        onClick={onClick}
        onDelete={onDelete}
        size="small"
      />
    </Tooltip>
  );
};

export const AiWriterUnassignedProjectTag = ({ tag, onClick }: TagProps) => {
  return (
    <Tooltip title={tag.name.length > 10 ? tag.name : ''}>
      <StyledChip
        $color={tag.color}
        key={tag.id}
        label={truncateText(tag.name, 10)}
        onClick={onClick}
        size="small"
      />
    </Tooltip>
  );
};

type NumberTagProps = {
  numberOfTags: number;
  tagsList: AiWriterProjectTagType[];
  onClick?: (e: React.MouseEvent<HTMLElement>) => void;
};

export const AiWriterProjectGroupOfTags = ({ numberOfTags, tagsList, onClick }: NumberTagProps) => {
  const tooltipList = tagsList
    .map(tag => tag.name)
    .toString()
    .split(',')
    .join('; ');

  const trackTagHover = () => {
    GAEvents.tagHovered();
  };

  return (
    <Tooltip title={tooltipList}>
      <NumberChip
        label={`+${numberOfTags}`}
        onClick={onClick}
        size="small"
        onMouseEnter={trackTagHover}
      />
    </Tooltip>
  );
};

const NumberChip = styled(Chip)`
  width: fit-content;
  background-color: ${({ theme }) => theme.colors.commonBlackFocusvisible};
  color: ${({ theme }) => theme.colors.white};
  padding: 3px 0;
  border-radius: ${({ theme }) => theme.borderRadius.small};
  .MuiChip-label {
    padding-left: ${({ theme }) => theme.spacings.xsmall};
    padding-right: ${({ theme }) => theme.spacings.xsmall};
  }
  &.MuiChip-clickable:hover {
    background-color: ${({ theme }) => theme.colors.commonBlackFocusvisible};
    color: ${({ theme }) => theme.colors.white};
    border: 1px solid transparent;
  }
`;

const StyledChip = styled(Chip)<{ $color: string }>`
  width: fit-content;
  background-color: ${({ $color }) => $color || TagChipColors.primary};
  color: ${({ theme }) => theme.colors.white};
  padding: 3px 4px;
  &.MuiChip-deleteIcon {
    color: ${({ theme }) => theme.colors.white};
  }
  .MuiChip-icon {
    color: ${({ theme }) => theme.colors.white};
  }
  &.MuiChip-deletable {
    background-color: ${({ $color }) => $color || TagChipColors.primary};
  }
  &.MuiChip-clickable:hover,
  &.MuiChip-deletable:hover {
    border: 1px solid transparent;
    background-color: ${({ $color }) => $color || TagChipColors.primary};
    color: ${({ theme }) => theme.colors.white};
  }
`;
