import Toast from 'components/toasts/Toast';
import { updateTesterProjectInBackgroundThunk } from 'features/aiTester/store/actions/project/thunks/updateTesterProjectInBackgroundThunk';
import { generateSuggestionsThunk } from 'features/aiTester/store/actions/suggestions/thunks/generateSuggestionsThunk';
import { loadTab } from 'features/aiTester/store/actions/tabs/actions';
import { scoreTextsThunk } from 'features/aiTester/store/actions/texts/thunks/scoreTextsThunk';
import { TesterProject } from 'features/aiTester/store/types';
import createNewText from 'features/aiTester/store/utils/defaults/createNewText';
import { testerTabFromProject } from 'features/aiTester/store/utils/mappers/testerTabMappers';
import { getTokensFromText } from 'features/aiTester/utils/getTokensFromText';
import { getEmbeddingModelDataById } from 'features/embeddingModels/store/selectors';
import { AppThunk } from 'store/store';
import { reportErrors } from 'utils/reportErrors';

export const loadTabThunk =
  (project: TesterProject): AppThunk<void> =>
  async (dispatch, getState) => {
    dispatch(loadTab.request());

    const { embeddingModelId, texts } = project;
    const model = getEmbeddingModelDataById(embeddingModelId, getState());

    const textContents = texts.map(text =>
      createNewText({
        text: text.text,
        textItemId: text.textItemId,
        generationId: text.generationId,
        isEditing: false
      })
    );

    try {
      const words = await Promise.all(
        textContents.map(({ text }) => getTokensFromText(text, model))
      );

      textContents.forEach((content, index) => {
        content.words = words[index];
      });

      dispatch(loadTab.success({ ...testerTabFromProject(project), texts: textContents }));

      dispatch(scoreTextsThunk());
      if (project.suggestions.length === 0) {
        dispatch(generateSuggestionsThunk());
        dispatch(updateTesterProjectInBackgroundThunk());
      }
    } catch (error) {
      dispatch(loadTab.failure());
      Toast.apiError();
      reportErrors('saga', error as Error);
    }
  };
