import { getPointAfter, insertNodes, TElement } from '@udecode/plate';
import useEditor from 'features/aiWriter/AiWriterTextEditor/hooks/useEditor';

/**
 * Images copied to the editor are scaled down to the maximum
 * width to ensure compatibility with the editor's dimensions.
 * This function returns the scaled height to the previously selected arbitrary number.
 */
export const getScaledDimensions = (url: string, maxWidth: number) => {
  return new Promise<{ width: number; height: number }>((resolve, reject) => {
    const img = new Image();
    img.onload = () => {
      const aspectRatio = img.width / img.height;
      const width = maxWidth;
      const height = maxWidth / aspectRatio;
      resolve({ width, height });
    };
    img.onerror = reject;
    img.src = url;
  });
};

export const EDITOR_IMAGE_WIDTH = 300;

export const useInsertImageToEditor = () => {
  const editor = useEditor();

  const text: { text: string } = { text: '' };
  const emptyParagraph: TElement = { type: 'paragraph', children: [text] };

  const insertImageToEditor = async (url: string) => {
    try {
      const { width, height } = await getScaledDimensions(url, EDITOR_IMAGE_WIDTH);
      const image: TElement = {
        type: 'img',
        url,
        children: [text],
        width,
        height
      };
      if (editor) {
        if (editor?.selection) {
          editor.insertNode(image);

          const afterImageElement = getPointAfter(editor, editor.selection, { unit: 'block' });
          if (afterImageElement == undefined) {
            editor.insertNode(emptyParagraph);
          }
        } else {
          insertNodes(editor, image, { at: [editor.children.length] });
          insertNodes(editor, emptyParagraph, { at: [editor.children.length], select: true });
        }
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Failed to load image:', error);
    }
  };

  return { insertImageToEditor };
};
