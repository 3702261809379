import { useQuery } from '@tanstack/react-query';
import { getActiveTab } from 'features/aiWriter/store/selectors';
import { httpGetPersonality } from 'services/backofficeIntegration/http/endpoints/personalities/httpGetPersonality';
import { useAppSelector } from 'store/hooks';

export const useDocumentPersonalityData = () => {
  const {
    generateTextConfig: { personalityId }
  } = useAppSelector(getActiveTab);

  return useQuery({
    enabled: !!personalityId,
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    queryKey: httpGetPersonality.makeQueryKey({ id: personalityId! }),
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    queryFn: () => httpGetPersonality.callEndpoint({ id: personalityId! })
  });
};
