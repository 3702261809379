import { useEffect, useState } from 'react';
import { getYoutubePlaylistVideos } from 'services/youtube/api';
import { YTVideo } from 'types/YTVideo';

export function useYoutubePlaylist(playlistId: string) {
  const [videos, setVideos] = useState<YTVideo[]>([]);

  useEffect(() => {
    getYoutubePlaylistVideos(playlistId).then(data => {
      setVideos(data);
    });
  }, [playlistId]);

  return {
    videos
  };
}
