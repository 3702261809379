import { ELEMENT_LI, isElement, nanoid, TDescendant, TElement, Value } from '@udecode/plate';
import { isText } from '@udecode/plate-common';
import { ELEMENT_LIC, ELEMENT_OL, ELEMENT_UL } from '@udecode/plate-list';
import { ELEMENT_PARAGRAPH } from '@udecode/plate-paragraph';
import {
  convertDeprecatedNodeType,
  isDeprecatedNodeType
} from 'features/aiWriter/AiWriterTextEditor/utils/deprecatedNodeTypeMigration';

// In order for the Plate DnD feature to work for old projects,
// the ID must be manually added to their nodes
// and node type has to be transformed
export function migrateOldDocument(nodes: Value): Value {
  const patchedNodes: Value = [];
  const newDocument: Value = [];

  // Bugfix for nodes with empty children
  // It's important to place this here, because migrateNode() uses isElement()
  // which already may crash
  // https://app.clickup.com/t/86bwaavnk
  nodes.forEach(node => {
    const { children, ...nodeProps } = node;

    if (!children) {
      patchedNodes.push(
        migrateNode({
          ...nodeProps,
          children: [{ text: '' }]
        })
      );
      return;
    }

    if (children.length === 0) {
      patchedNodes.push(
        migrateNode({
          ...nodeProps,
          children: [{ text: '' }]
        })
      );
      return;
    }

    if (node.children.length === 1 && Object.keys(node.children[0]).length === 0) {
      patchedNodes.push(
        migrateNode({
          ...nodeProps,
          children: [{ text: '' }]
        })
      );
      return;
    }

    patchedNodes.push(migrateNode(node));
  });

  // After the Plate upgrade (2021-11-01) lists are changed to paragraphs
  for (const node of patchedNodes) {
    if (node.type === ELEMENT_UL || node.type === ELEMENT_OL) {
      // Each list item must be a paragraph with special props
      const listItems = node.children.filter(child => child.type === ELEMENT_LI);

      newDocument.push(
        ...listItems.map((child, index) => {
          const children = [];
          // Direct text child
          if (isText(child)) {
            children.push(child);
          } else {
            child.children.forEach(grandChild => {
              // Strip out list item content nodes
              if (grandChild.type === ELEMENT_LIC && !isText(grandChild)) {
                children.push(...grandChild.children);
                return;
              }

              children.push(grandChild);
            });
          }

          return {
            type: ELEMENT_PARAGRAPH,
            listStyleType: node.type === ELEMENT_UL ? 'disc' : 'decimal',
            listStart: index + 1,
            indent: 1,
            id: nanoid(),
            children
          } as TElement;
        })
      );
      continue;
    }

    newDocument.push(node);
  }

  return newDocument;
}

function migrateNode<Node extends TDescendant>(node: Node) {
  if (isElement(node)) {
    return migrateChildren(addMissingId(fixDeprecatedType(node)));
  }
  return node;
}

function fixDeprecatedType(element: TElement) {
  if (isDeprecatedNodeType(element.type)) {
    return { ...element, type: convertDeprecatedNodeType(element.type) };
  }

  return element;
}

function addMissingId(element: TElement) {
  return { ...element, id: element.id ?? nanoid() };
}

function migrateChildren(element: TElement): TElement {
  return {
    ...element,
    children: element.children.map(node => {
      return migrateNode(node);
    })
  };
}
