import { CampaignConfigFormPayload } from '../CampaignConfigForm/CampaignConfigForm';
import { MobileDevice, MobileDeviceConfiguration, NewCampaign, ScoredSubjectLine } from './types';

export const prepareNewCampaign = ({
  name,
  projectId,
  deletedSuggestions,
  subjectLines,
  keywords,
  emailBody
}: CampaignConfigFormPayload): NewCampaign => {
  return {
    name,
    projectId,
    deletedSubjectLinesRatings: deletedSuggestions,
    subjectLinesRatings: subjectLines,
    status: subjectLines.length > 0 ? 'pending' : 'open',
    keywords,
    emailBody
  };
};

export const compareOpenRates = (a: ScoredSubjectLine, b: ScoredSubjectLine) =>
  b.predictedOpenRate - a.predictedOpenRate;

export const prepareUserName = (
  teamMembersDict: { [id: number]: string },
  groupId: number,
  userId: number,
  userName: string
) => {
  return groupId ? teamMembersDict[userId] || '' : userName;
};

export const mobileDevicesConfiguration: { [key in MobileDevice]: MobileDeviceConfiguration } = {
  iPhone: {
    cardWidth: 375,
    firstContentPlaceholderWidth: 308,
    secondContentPlaceholderWidth: 198
  },
  android: {
    cardWidth: 360,
    firstContentPlaceholderWidth: 289,
    secondContentPlaceholderWidth: 185
  },
  outlook: {
    cardWidth: 468,
    firstContentPlaceholderWidth: 397,
    secondContentPlaceholderWidth: 255
  }
};
