import {
  getEmotionalityTranslationLabel,
  getReadabilityAndEmotionalityGrade
} from 'features/flashScore/getEmotionalityTranslationLabel';
import { getReadabilityTranslationLabel } from 'features/flashScore/getReadabilityTranslationLabel';
import { getScoringFromMappersResult } from 'features/flashScore/getScoringFromMappersResult';
import { FlashScoreTextRatingResult } from 'features/flashScore/useScoreTexts';
import { ScoreTextsData } from 'services/api/wordEmbedding/types';

export const createEmptyScoreTextsResult = (text: string): FlashScoreTextRatingResult => ({
  text,
  readability: {
    grade: 1,
    score: 0,
    percentage: 0,
    label: getReadabilityTranslationLabel(1)
  },
  emotionality: {
    grade: 1,
    score: 0,
    percentage: 0,
    label: getEmotionalityTranslationLabel(1)
  }
});

export const createScoreTextsResult = (
  text: string,
  result: ScoreTextsData
): FlashScoreTextRatingResult => {
  const { readability, emotionality } = getScoringFromMappersResult(result);
  const readabilityGrade = getReadabilityAndEmotionalityGrade(readability);
  const emotionalityGrade = getReadabilityAndEmotionalityGrade(emotionality);

  return {
    text,
    readability: {
      grade: readabilityGrade,
      score: readability,
      percentage: Math.round(readability * 100),
      label: getReadabilityTranslationLabel(readabilityGrade)
    },
    emotionality: {
      grade: emotionalityGrade,
      score: emotionality,
      percentage: Math.round(emotionality * 100),
      label: getEmotionalityTranslationLabel(emotionalityGrade)
    }
  };
};
