import LocalStorageKey from 'config/localStorageKey';
import { getGetAudienceByLanguageAndCountry } from 'features/audiences/store/selectors';
import getAudienceInitValue from 'features/audiences/utils/getAudienceInitValue';
import { getEmbeddingModelsByLanguageAndAudience } from 'features/embeddingModels/store/selectors';
import getModelInitValue from 'features/embeddingModels/store/utils/getModelInitValue';
import { ProjectLocale } from 'features/projects/ProjectLocale';
import { useAppSelector } from 'store/hooks';

export function useInitialModelAndAudience(props: { preferredLocale?: ProjectLocale } = {}) {
  const { preferredLocale } = props;
  const models = useAppSelector(getEmbeddingModelsByLanguageAndAudience);
  const getAudienceByLanguageAndCountry = useAppSelector(getGetAudienceByLanguageAndCountry);

  const defaultModel = getModelInitValue(models, LocalStorageKey.AiWriterModel, preferredLocale);

  // This is here to recreate previous behavior. Probably we should never allow this to happen.
  if (!defaultModel) {
    return {
      embeddingModelId: '',
      audienceId: 0
    };
  }
  const audiences = getAudienceByLanguageAndCountry(defaultModel.language, defaultModel.country);
  const defaultAudience = getAudienceInitValue(audiences, LocalStorageKey.AiWriterProject);

  return {
    embeddingModelId: defaultModel.id,
    audienceId: defaultAudience ?? 0
  };
}
