import { BuilderModularWorkflowStep } from 'features/modular-workflow/builder/types-builder';
import { useModulareWorkflowCreationStore } from 'features/modular-workflow/builder/useModulareWorkflowCreationStore';

export const useEnsureUniqueStepOutputAlias = () => {
  const placeholderAliasCache = useModulareWorkflowCreationStore(
    state => state.placeholderAliasCache
  );
  const setPlaceholderAliasCache = useModulareWorkflowCreationStore(
    state => state.setPlaceholderAliasCache
  );

  return (newStep: BuilderModularWorkflowStep) => {
    // Check all outputs
    for (const output of newStep.outputs) {
      if (!placeholderAliasCache.includes(output.alias)) {
        placeholderAliasCache.push(output.alias);
        continue;
      }

      // If the alias already ends with a number, remove it
      if (/_[0-9]+$/.test(output.alias)) {
        output.alias = output.alias.replace(/_[0-9]+$/, '');
      }

      const aliasWithoutSuffix = output.alias;

      let suffix = 1;
      do {
        output.alias = `${aliasWithoutSuffix}_${suffix}`;
        suffix++;
      } while (placeholderAliasCache.includes(output.alias));

      // Add to local copy of the cache
      placeholderAliasCache.push(output.alias);
    }

    setPlaceholderAliasCache(placeholderAliasCache);

    return newStep;
  };
};
