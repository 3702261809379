import { BaseSuccessDto } from 'services/backofficeIntegration/http/BaseSuccessDto';
import { backofficeClient } from 'services/backofficeIntegration/http/client/createBackofficeClient';
import { TeamMemberDto } from 'services/backofficeIntegration/http/dtos/TeamMemberDto';
import { mutationEndpoint } from 'services/backofficeIntegration/http/endpointDefinition';

type SuccessDto = BaseSuccessDto<TeamMemberDto>;

export type RemoveUserParams = {
  teamCustomerId: number;
};

export const httpRemoveTeamMember = mutationEndpoint({
  callEndpoint: ({ teamCustomerId }: RemoveUserParams) =>
    backofficeClient
      .delete<SuccessDto>(`/teams/members/${teamCustomerId}`)
      .then(response => response.data)
});
