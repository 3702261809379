import DoneIcon from '@mui/icons-material/Done';
import { Autocomplete, Chip, MenuItem, Popper, TextField } from '@mui/material';
import FlexContainer from 'components/FlexContainer';
import { useGetInformationCategoriesQuery } from 'features/information/CopyPasteView';
import { SyntheticEvent, useState } from 'react';
import { InformationCategoryDto } from 'services/backofficeIntegration/http/endpoints/infomration/httpGetInformationCategories';
import styled from 'styled-components';
import useTr from 'utils/hooks/useTr';

type Props = {
  value: InformationCategoryDto | undefined;
  setValue: (value: InformationCategoryDto | undefined) => void;
};

export function TagFilter({ value, setValue }: Props) {
  const [inputValue, setInputValue] = useState('');

  const tr = useTr();

  const handleChange = (
    event: SyntheticEvent<Element, Event>,
    newValue: InformationCategoryDto | null
  ) => {
    if (newValue) {
      setValue(newValue);
    }
  };

  const handleClear = () => {
    setValue(undefined);
  };

  const queryResult = useGetInformationCategoriesQuery({});

  const tags = queryResult.data?.pages.map(page => page.data).flat(1);
  return (
    <FilterContainer>
      {value ? (
        <StyledChip
          label={value.name}
          icon={<DoneIcon />}
          onDelete={handleClear}
          color="primary"
          sx={{ color: 'white' }}
        />
      ) : (
        <Autocomplete
          sx={{ width: '8rem' }}
          value={value}
          inputValue={inputValue}
          loading={queryResult.isLoading}
          options={tags ?? []}
          onChange={handleChange}
          getOptionLabel={(option: InformationCategoryDto) => option.name}
          onInputChange={(event, newInputValue) => {
            setInputValue(newInputValue);
          }}
          renderInput={params => (
            <TextField {...params} label={tr('common.category')} size="small" />
          )}
          PopperComponent={params => (
            // all other styling methods do not work for this component
            <Popper {...params} style={{ width: 240 }} placement="bottom-start" />
          )}
          renderOption={(props, value) => <MenuItem {...props}>{value.name}</MenuItem>}
        />
      )}
    </FilterContainer>
  );
}

const FilterContainer = styled(FlexContainer).attrs({
  direction: 'row',
  alignItems: 'center'
})`
  height: 3rem;
`;

const StyledChip = styled(Chip)`
  color: ${({ theme }) => theme.colors.white};

  && .MuiChip-deleteIcon {
    color: ${({ theme }) => theme.colors.white};

    &:hover {
      color: ${({ theme }) => theme.colors.whiteMediumEmphasis};
    }
  }
`;
