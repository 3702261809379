import { useChannel, useEvent } from '@harelpls/use-pusher';
import { Link } from '@mui/material';
import { createProgressToast, ProgressToastFunctions } from 'components/toasts/createProgressToast';
import { getUserId } from 'features/customer/store/selectors';
import FormattedMessage from 'features/i18n/FormattedMessage';
import { useRef } from 'react';
import { invalidateInformationListQuery } from 'services/backofficeIntegration/http/endpoints/infomration/httpGetInformationList';
import { useAppSelector } from 'store/hooks';
import useTr from 'utils/hooks/useTr';

type ChannelResponse = {
  information_id: number;
  information_name: string;
  status: string;
  error: string;
};

export const UploadInformationErrors = {
  ERROR_RAG_TEXT_EXTRACTION_FAILED: 'ERROR_RAG_TEXT_EXTRACTION_FAILED',
  ERROR_RAG_TEXT_EXTRACTION_EMPTY: 'ERROR_RAG_TEXT_EXTRACTION_EMPTY',
  ERROR_RAG_TEXT_MODERATION_FAILED: 'ERROR_RAG_TEXT_MODERATION_FAILED',
  ERROR_RAG_TEXT_IS_NSFW: 'ERROR_RAG_TEXT_IS_NSFW',
  ERROR_RAG_RETRIEVAL_FAILED: 'ERROR_RAG_RETRIEVAL_FAILED',
  ERROR_FILE_INFORMATION_PROCESSING_FAILED: 'ERROR_FILE_INFORMATION_PROCESSING_FAILED',
  ERROR_FILE_NOT_FOUND: 'ERROR_FILE_NOT_FOUND',
  ERROR_RAG_TEXT_EXTRACTION_TEXT_TOO_SHORT: 'ERROR_RAG_TEXT_EXTRACTION_TEXT_TOO_SHORT'
};

const newNotificationEventName = 'information-file-upload';

export function useInformationProgressNotification() {
  const translate = useTr();
  const customerId = useAppSelector(getUserId);
  const progressToastRef = useRef<ProgressToastFunctions | null>(null);

  const channelName = `information-file-upload-channel-${customerId}`;
  const channel = useChannel(channelName);
  useEvent<ChannelResponse>(channel, newNotificationEventName, data => {
    if (!data || !progressToastRef.current) {
      return;
    }

    if (data.error) {
      handleErrors(data.error, progressToastRef.current);
    } else if (data.status === 'success') {
      progressToastRef.current?.success({ render: translate('information.uploadView.success') });
      invalidateInformationListQuery();
    } else {
      progressToastRef.current?.close();
    }
  });

  return () => {
    if (progressToastRef.current) {
      progressToastRef.current.close();
    }

    progressToastRef.current = createProgressToast(translate('information.uploadView.progress'));
  };

  function handleErrors(error: string, progressToast: ProgressToastFunctions) {
    if (Object.values(UploadInformationErrors).includes(error)) {
      progressToast.error({
        render: (
          <FormattedMessage
            id={`information.uploadView.error.${error}`}
            values={{
              link: (content: string) => (
                <Link href={translate('common.contact_link')} target="_blank">
                  {content}
                </Link>
              )
            }}
          />
        )
      });
      return;
    }

    progressToast.error({ render: translate('common.error') });
  }
}
