import { ELEMENT_IMAGE } from '@udecode/plate';
import Toast from 'components/toasts/Toast';
import { getActiveTab } from 'features/aiWriter/store/selectors';
import { AiWriterProject } from 'features/aiWriter/store/types';
import { trackingWrapper } from 'features/tracking/wrapper/TrackingWrapper';
import { GAEvents } from 'services/tracking/GAEvents';
import { useAppSelector } from 'store/hooks';

const maxAmountOfImagesInDocument = 20;

const showWarning = () => Toast.warning('aiWriter.editor.toast_too_many_images');

const trackEvent = () => {
  trackingWrapper.track('aiWriterTooManyImages');
  GAEvents.reportCustomEvent('aiWriterTooManyImages');
};

export const useHasTooManyImagesInDocument = () => {
  const activeTab = useAppSelector(getActiveTab);

  const hasTooManyImagesInDocument = (project?: AiWriterProject) => {
    const documentContent = project ? project.text : activeTab?.text;
    if (!documentContent) {
      return false;
    }

    return (
      documentContent.filter(node => node.type === ELEMENT_IMAGE).length >=
      maxAmountOfImagesInDocument
    );
  };

  const handleTooManyImages = () => {
    showWarning();
    trackEvent();
  };

  return {
    hasTooManyImagesInDocument,
    handleTooManyImages
  };
};
