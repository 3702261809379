import { CheckCircleOutlined } from '@mui/icons-material';
import WarningIcon from '@mui/icons-material/Warning';
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  LinearProgress,
  Link,
  Tooltip,
  Typography
} from '@mui/material';
import FlexContainer from 'components/FlexContainer';
import { YellowCrownImage } from 'components/icons/YellowCrownImage';
import TextTile from 'components/TextTile';
import { ColoredHelpIcon } from 'features/aiWriter/AiWriterSidebar/steps/extraIcons';
import { PricingRestrictionHint } from 'features/aiWriter/AiWriterSidebar/steps/PricingRestrictionHint';
import useEditor from 'features/aiWriter/AiWriterTextEditor/hooks/useEditor';
import useEditorText from 'features/aiWriter/AiWriterTextEditor/hooks/useEditorText';
import { useEditorWordsCount } from 'features/aiWriter/AiWriterTextEditor/hooks/useEditorWordsCount';
import { getSelectionTextWithSeparatedBlocks } from 'features/aiWriter/AiWriterTextEditor/utils/getSelectionTextWithSeparatedBlocks';
import { getGetIsFeatureAvailable } from 'features/features/store/selectors';
import FormattedMessage from 'features/i18n/FormattedMessage';
import { useShowPlagiarismRescanModal } from 'features/plagiarism-check/PlagiarismRescanModal';
import { usePlagiarismCheck } from 'features/plagiarism-check/usePlagiarismCheck';
import { usePlagiarismCheckLimitations } from 'features/plagiarism-check/usePlagiarismCheckLimitations';
import useUpdateSubscriptionModal from 'features/updateSubscriptionModal/hook/useUpdateSubscriptionModal';
import { useMemo } from 'react';
import { PlagiarismResultDto } from 'services/backofficeIntegration/http/endpoints/plagrism/httpCreatePlagiarismCheck';
import gtmIds from 'services/tracking/GTMIds';
import { withGtmInteraction } from 'services/tracking/withGtmInteraction';
import { useAppSelector } from 'store/hooks';
import styled from 'styled-components';
import { resetUl } from 'styles/styledComponents/resets';
import { getWordCount } from 'utils/getWordCount';
import useTr from 'utils/hooks/useTr';

const useSelectedOrAllEditorText = () => {
  const editor = useEditor();
  const editorText = useEditorText();
  const editorSelectedText = editor ? getSelectionTextWithSeparatedBlocks(editor) : '';

  if (editorSelectedText.length > 0) {
    return {
      selection: true,
      text: editorSelectedText
    };
  }

  return {
    selection: false,
    text: editorText
  };
};

const maximumWordsCount = 2500;
const minimumWordsCount = 15;

export function PlagiarismCheckStep() {
  const plagiarismCheck = usePlagiarismCheck();
  const limitations = usePlagiarismCheckLimitations();

  const { selection: hasSelection, text: editorText } = useSelectedOrAllEditorText();

  const showUpgradeSubscriptionModal = useUpdateSubscriptionModal();

  const totalWordsCount = useEditorWordsCount();
  const wordsCount = useMemo(
    () => (hasSelection ? getWordCount(editorText) : totalWordsCount),
    [totalWordsCount, editorText, hasSelection]
  );

  const showPlagiarismRescanConfirmationModal = useShowPlagiarismRescanModal();
  const hasResults = !!plagiarismCheck.data?.matches?.length;
  const isPlagiarismCheckerAvailable =
    useAppSelector(getGetIsFeatureAvailable)('plagiarismChecker');

  const canScan = useMemo(() => {
    if (wordsCount > maximumWordsCount || wordsCount < minimumWordsCount) return false;
    if (limitations.data?.isUnlimited) return true;
    return (limitations.data?.limit || 1) - (limitations.data?.used || 0) > 0;
  }, [limitations.data?.isUnlimited, limitations.data?.limit, limitations.data?.used, wordsCount]);

  const runPlagiarismCheck = () => {
    plagiarismCheck.mutate(editorText);
  };

  const getButtonMessageID = () => {
    if (!canScan) {
      return 'aiWriter.inspirations.plagiarism.selectAtLeastWords';
    }

    if (hasResults) {
      return 'aiWriter.inspirations.plagiarism.scanMoreContent';
    }

    if (hasSelection) {
      return 'aiWriter.inspirations.plagiarism.scanSelectedText';
    }

    return 'aiWriter.inspirations.plagiarism.scanDocument';
  };

  function renderControls() {
    if (limitations.isLoading) {
      return <CircularProgress />;
    }

    if (!limitations.isSuccess || !limitations.data) {
      return <LimitationsLoadingError />;
    }

    if (!isPlagiarismCheckerAvailable) {
      return (
        <FlexContainer gap="four">
          <PricingRestrictionHint title="aiWriter.inspirations.performance_step.plagiarism_check.restriction.title" />
          <Button
            id={gtmIds.aiWriter.seo.analyse}
            variant="contained"
            color="primary"
            type="submit"
            disabled={limitations.isLoading}
            fullWidth={true}
            startIcon={<YellowCrownImage />}
            onClick={() => showUpgradeSubscriptionModal()}
          >
            <FormattedMessage id="common.upgrade" />
          </Button>
        </FlexContainer>
      );
    }

    return (
      <FlexContainer gap="three">
        <FlexContainer gap="two" alignItems="center">
          <Typography variant="subtitle1">
            <FormattedMessage id="aiWriter.inspirations.plagiarism.words" />
          </Typography>
          <Typography variant="body2">{wordsCount}</Typography>
        </FlexContainer>
        <Button
          {...withGtmInteraction(
            hasSelection
              ? gtmIds.aiWriter.plagiarism.scanSelectedText
              : gtmIds.aiWriter.plagiarism.scanDocument
          )}
          fullWidth={true}
          disabled={!canScan}
          variant="contained"
          size="large"
          sx={{ mb: 2 }}
          onClick={() =>
            hasResults
              ? showPlagiarismRescanConfirmationModal({
                onScan: runPlagiarismCheck
              })
              : runPlagiarismCheck()
          }
        >
          <FormattedMessage id={getButtonMessageID()} />
        </Button>
      </FlexContainer>
    );
  }

  function renderList() {
    if (!plagiarismCheck.isSuccess || !plagiarismCheck.data) {
      return null;
    }

    const { matches } = plagiarismCheck.data;

    return (
      <div>
        <PlagiarismCheckList>
          <Typography variant="overline">
            <FormattedMessage id="aiWriter.inspirations.plagiarism.viewPlagiarizedSources" />
          </Typography>
          {matches?.length > 0 ? (
            matches.map(dto => <ListItem key={dto.url} result={dto} />)
          ) : (
            <NoMatchesFound />
          )}
        </PlagiarismCheckList>
      </div>
    );
  }

  function renderError(messageId: string) {
    return (
      <FlexContainer alignItems="center" gap="medium">
        <Typography variant="body2" textAlign="center">
          <FormattedMessage id={messageId} />
        </Typography>
        <Button variant="contained" onClick={() => runPlagiarismCheck()}>
          <FormattedMessage id="common.refresh" />
        </Button>
      </FlexContainer>
    );
  }

  function renderQueryControls() {
    if (plagiarismCheck.isLoading) {
      return (
        <FlexContainer>
          <LinearProgress />
        </FlexContainer>
      );
    }

    if (plagiarismCheck.isError) {
      return renderError('aiWriter.inspirations.plagiarism.errors.load');
    }
  }

  return (
    <RootLayout gap="three">
      {renderControls()}
      {renderList()}
      {renderQueryControls()}
    </RootLayout>
  );
}

function ListItem({
  result: { title, match_snippet, url, match_percentage }
}: {
  result: PlagiarismResultDto;
}) {
  // TODO: Re-enable onClick once the editor supports matching full blocks of text
  /*
  const dispatch = useAppDispatch();
  const seoHighlightedKeyword = useAppSelector(getSeoHighlightedKeyword);

  // match_snippet is a string consisting of a number of pieces divided by a sign ...
  const splitted = match_snippet.split('...');

  const isSelected = JSON.stringify(seoHighlightedKeyword) === JSON.stringify(splitted);

  const handleClick = () => {
    isSelected
      ? dispatch(updateSeoHighlightedKeyword(''))
      : dispatch(updateSeoHighlightedKeyword(splitted));
  };
   */

  return (
    <li>
      <TextTile>
        <FlexContainer gap="small">
          <MatchingPercentageText display="flex" variant="body2">
            <FormattedMessage
              id="aiWriter.inspirations.plagiarism.item.matches"
              values={{
                percentage: parseInt(match_percentage.toString(), 10)
              }}
            />
          </MatchingPercentageText>
          <Typography variant="subtitle2">{title}</Typography>
          <Typography variant="body2">{match_snippet}</Typography>
          <CompareLink
            id={gtmIds.aiWriter.plagiarism.itemLink}
            href={url}
            target="_blank"
            rel="noreferrer"
          >
            <Button
              onClick={e => e.stopPropagation()}
              {...withGtmInteraction(gtmIds.aiWriter.plagiarism.compareText)}
              variant="outlined"
            >
              <FormattedMessage id="aiWriter.inspirations.plagiarism.item.compareText" />
            </Button>
          </CompareLink>
        </FlexContainer>
      </TextTile>
    </li>
  );
}

export function PlagiarismInfoButton() {
  const translate = useTr();

  const content = (
    <IconButton
      component="a"
      size="small"
      target="_blank"
      href={translate('aiWriter.inspirations.plagiarism.icon_tooltip_link')}
    >
      <ColoredHelpIcon fontSize="small" />
    </IconButton>
  );

  return (
    <Tooltip title={translate('aiWriter.inspirations.info_button.tooltip.video')}>
      {content}
    </Tooltip>
  );
}

function NoMatchesFound() {
  return (
    <SuccessBox>
      <CheckCircleOutlined />
      <Box>
        <Typography variant="h6" fontSize="16px" mb="4px">
          <FormattedMessage id="aiWriter.inspirations.plagiarism.noMatchesFound.title" />
        </Typography>
        <Typography variant="body2">
          <FormattedMessage id="aiWriter.inspirations.plagiarism.noMatchesFound.message" />
        </Typography>
      </Box>
    </SuccessBox>
  );
}

function LimitationsLoadingError() {
  return (
    <WarningBox>
      <WarningIcon fontSize="medium" />
      <Box>
        <Typography variant="h6" fontSize="16px" mb="4px">
          <FormattedMessage id="aiWriter.inspirations.plagiarism.loading_error.headline" />
        </Typography>
        <Typography variant="body2">
          <FormattedMessage id="aiWriter.inspirations.plagiarism.loading_error.message" />
        </Typography>
      </Box>
    </WarningBox>
  );
}

const RootLayout = styled(FlexContainer)`
  height: 100%;
`;

const PlagiarismCheckList = styled.ul`
  ${resetUl};
  overflow-y: auto;
  overflow-x: hidden;
  flex-direction: column;
  display: flex;
  gap: ${({ theme }) => theme.spacings.four};
  min-height: ${({ theme }) => theme.spacings.medium};
`;

const MatchingPercentageText = styled(Typography)`
  color: ${({ theme }) => theme.colors.danger};
`;

const SuccessBox = styled(FlexContainer).attrs({
  alignItems: 'flex-start',
  justifyContent: 'center',
  gap: 'small'
})`
  background-color: ${({ theme }) => theme.colors.successLight};
  color: ${({ theme }) => theme.colors.componentsAlertSuccessColor};
  flex-direction: row;
  border-radius: ${({ theme }) => theme.borderRadius.one};
  padding: ${({ theme }) => `${theme.spacings.two} ${theme.spacings.three}`};
`;

const WarningBox = styled(FlexContainer).attrs({
  alignItems: 'flex-start',
  justifyContent: 'center',
  gap: 'small'
})`
  background-color: ${({ theme }) => theme.colors.warning};
  flex-direction: row;
  border-radius: ${({ theme }) => theme.borderRadius.small};
  padding: ${({ theme }) => `${theme.spacings.small} ${theme.spacings.medium}`};
`;

const CompareLink = styled(Link)`
  width: fit-content;
  align-self: flex-end;
`;
