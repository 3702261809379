import { makeCurrentUserQueryKeyPrefix } from 'features/currentUser/currentUserQueries';
import { ApiModularWorkflowStep } from 'features/modular-workflow/builder/types-api';
import { BaseSuccessDto } from 'services/backofficeIntegration/http/BaseSuccessDto';
import { backofficeClient } from 'services/backofficeIntegration/http/client/createBackofficeClient';
import { queryEndpoint } from 'services/backofficeIntegration/http/endpointDefinition';

type SuccessDto = BaseSuccessDto<ApiModularWorkflowStep[]>;

export const httpGetModularWorkflowSteps = queryEndpoint({
  makeQueryKey: () => {
    return [...makeCurrentUserQueryKeyPrefix(), 'modular-workflow', 'structure'] as const;
  },
  callEndpoint: () =>
    backofficeClient.get<SuccessDto>('/workflows/steps').then(response => response.data.data)
});
