import { CloseModal } from 'components/modals/types';
import ModelSelectStep from 'features/explorer/modals/steps/ModelSelectStep';
import { useAppSelector } from 'store/hooks';
import { object, string } from 'yup';

import StepperModal, { ModalStep } from '../../../components/modals/StepperModal';
import { getActiveTab, getCurrentTabModelData, getExplorerTabMapperId } from '../store/selectors';
import { ConceptFlashTab, ModelGoalValues } from '../store/types';
import ExplorerGoalConfigStep from './steps/ExplorerGoalConfigStep';

const modelGoalSteps: ModalStep[] = [
  {
    title: 'exploring.setup.models.title',
    description: 'exploring.setup.models.description'
  },
  {
    title: 'goal_score.setup.title',
    description: 'exploring.setup.goal.description'
  }
];

const renderStepComponent = (step: number) => {
  switch (step) {
    case 0:
      return <ModelSelectStep />;
    case 1:
      return <ExplorerGoalConfigStep />;
    default:
      return null;
  }
};

const validationSchema = object().shape({
  model: object().shape({
    modelId: string().required(),
    modelCountry: string().required(),
    modelMapper: string()
  })
});

type Props = {
  onSubmit: (values: ModelGoalValues) => void;
  onHide: () => void;
} & CloseModal;

const ModelGoalModal = ({ closeModal, onSubmit, onHide }: Props) => {
  const { wordAttributes, dimensions, manualDimensions, seoParams }: ConceptFlashTab =
    useAppSelector(getActiveTab);
  const model = useAppSelector(getCurrentTabModelData);
  const mapper = useAppSelector(getExplorerTabMapperId);

  const initialValues: ModelGoalValues = {
    wordAttributes,
    dimensions,
    manualDimensions,
    seoParams,
    model: {
      modelId: model ? model.id : '',
      modelMapper: mapper || '',
      modelCountry: model ? model.country : ''
    }
  };

  return (
    <StepperModal
      steps={modelGoalSteps}
      stepComponent={renderStepComponent}
      initialValues={initialValues}
      onCancel={() => {
        onHide();
        closeModal();
      }}
      onSubmit={values => {
        onSubmit(values);
        onHide();
        closeModal();
      }}
      validationSchema={validationSchema}
    />
  );
};

export default ModelGoalModal;
