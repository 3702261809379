import NotificationHint from 'components/profile/components/pricing-plan-card/NotificationHint';
import FormattedMessage from 'features/i18n/FormattedMessage';
import { ReactElement } from 'react';

const SubscriptionPaymentPendingHint = (): ReactElement => (
  <NotificationHint>
    <FormattedMessage id="profile.upgrade_plan.subscription_payment_pending" />
  </NotificationHint>
);

export default SubscriptionPaymentPendingHint;
