import { Typography } from '@mui/material';
import { ColoredTypography } from 'components/ColoredTypography';
import FlexContainer from 'components/FlexContainer';
import { defaultSeoTextLength } from 'features/aiWriter/store/actions/seoAnalysis/types';
import { getSeoWordsAmount } from 'features/aiWriter/store/selectors';
import { FormattedMessage } from 'react-intl';
import { useAppSelector } from 'store/hooks';
import useTr from 'utils/hooks/useTr';

export type WordStatisticsColorVariant = 'primary' | 'secondary' | 'green';

type Props = {
  minUse?: number;
  maxUse?: number;
};

const WordStatisticsPillTooltip = ({ minUse, maxUse }: Props) => {
  const tr = useTr();

  const seoCharactersAmount = useAppSelector(getSeoWordsAmount);

  const textLength = seoCharactersAmount || defaultSeoTextLength;

  return (
    <FlexContainer gap="xxsmall">
      <Typography>
        <Typography>{`${tr('common.min_shortcut')} ${minUse ?? '?'}`}</Typography>
      </Typography>
      <Typography>{`${tr('common.max_shortcut')} ${maxUse ?? '?'}`}</Typography>
      <ColoredTypography color="whiteDisabled">
        <FormattedMessage
          id="aiWriter.inspirations.seoAnalysis.based_on_text_length"
          values={{ amount: textLength }}
        />
      </ColoredTypography>
    </FlexContainer>
  );
};

export default WordStatisticsPillTooltip;
