import { Typography } from '@mui/material';
import { NotificationDto } from 'services/backofficeIntegration/http/endpoints/notifications/getUnreadNotifications';

export function NotificationToastContent(props: { notification: NotificationDto }) {
  const { notification } = props;
  const { title, content } = notification.data;
  return (
    <div>
      {title ? <Typography variant="subtitle1">{title}</Typography> : null}
      <Typography variant="body2">{content}</Typography>
    </div>
  );
}
