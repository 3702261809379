import { ColoredTypography } from 'components/ColoredTypography';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

export const KeywordsLengthExceededHint = () => (
  <Root>
    <ColoredTypography variant="caption">
      <FormattedMessage id="blog_post_builder.steps.keywords.input_length_exceeded" />
    </ColoredTypography>
  </Root>
);

const Root = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: ${({ theme }) => theme.spacings.small};
  margin-top: ${({ theme }) => theme.spacings.small};

  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.warningRed};
  border-radius: ${({ theme }) => theme.borderRadius.small};
`;
