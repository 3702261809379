import { Card, CardContent, CircularProgress, Typography } from '@mui/material';
import oneTimeInfoImage from 'assets/flashScore/one-time-info.svg';
import FlexContainer from 'components/FlexContainer';
import { StretchedCardContent } from 'features/aiWriter/AiWriterSidebar/steps/flashScore/FlashScoreAnalyzeView';
import {
  GetSuggestionsButton,
  SuggestionType
} from 'features/aiWriter/AiWriterSidebar/steps/flashScore/GetSuggestionsButton';
import { BasicMarkdownPreview } from 'features/aiWriter/markdown/MarkdownTile';
import FormattedMessage from 'features/i18n/FormattedMessage';
import styled from 'styled-components';

type Props = {
  onSuggestionSelected: (suggestionType: SuggestionType) => void;
  selectedSuggestionType: SuggestionType;
  generatedSuggestion: string | null;
  isFetching: boolean;
};

export const SuggestionCard = ({
  onSuggestionSelected,
  selectedSuggestionType,
  generatedSuggestion,
  isFetching
}: Props) => {
  if (isFetching) {
    return (
      <Card variant="outlined">
        <CenteredCardContent>
          <CircularProgress size={100} thickness={1} />

          <br />

          <Typography>
            <FormattedMessage id="aiWriter.inspirations.flashScore.suggestion_card.loading_text" />
          </Typography>
        </CenteredCardContent>
      </Card>
    );
  }

  return (
    <Card variant="outlined">
      <StretchedCardContent>
        <Typography fontWeight="bold">
          <FormattedMessage
            id={`aiWriter.inspirations.flashScore.suggestion_card.title.${selectedSuggestionType}`}
          />
        </Typography>

        {generatedSuggestion && (
          <StyledBasicMarkdownPreview>{generatedSuggestion}</StyledBasicMarkdownPreview>
        )}

        <br />

        <FlexContainer
          direction="row"
          justifyContent="space-evenly"
          alignItems="center"
          gap="small"
        >
          <GetSuggestionsButton onSuggestionSelected={onSuggestionSelected}>
            <FormattedMessage id="aiWriter.inspirations.flashScore.suggestion_card.get_new_suggestion" />
          </GetSuggestionsButton>

          <img src={oneTimeInfoImage} width={145} alt="Info" />
        </FlexContainer>
      </StretchedCardContent>
    </Card>
  );
};

const CenteredCardContent = styled(CardContent)`
  display: grid;
  justify-items: center;
`;

const StyledBasicMarkdownPreview = styled(BasicMarkdownPreview)`
  font-size: 16px;

  li:not(:last-child) {
    margin-bottom: ${({ theme }) => theme.spacings.small};
  }
`;
