import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import DeleteIconButton from 'components/iconButtons/DeleteIconButton';
import { FormattedMessage } from 'react-intl';

type Props = {
  onCancel: () => void;
  onConfirm: () => void;
  isDialogOpen: boolean;
  title: React.ReactElement;
  content: React.ReactElement;
  confirmButtonText: React.ReactElement;
  confirmButtonColor:
    | 'inherit'
    | 'primary'
    | 'secondary'
    | 'success'
    | 'error'
    | 'info'
    | 'warning'
    | undefined;
};

export const ActionDialog = ({
  isDialogOpen,
  onCancel,
  onConfirm,
  title,
  content,
  confirmButtonText,
  confirmButtonColor
}: Props) => {
  return (
    <Dialog open={isDialogOpen} onClose={onCancel}>
      <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between' }}>
        {title}
        <DeleteIconButton onClick={onCancel} />
      </DialogTitle>
      <DialogContent sx={{ maxWidth: '400px' }}>{content}</DialogContent>
      <DialogActions sx={(theme) => ({ padding: `0 ${theme.spacing(3)} ${theme.spacing(2)} ${theme.spacing(3)}` })}>
        <Button color="primary" onClick={onCancel}>
          <FormattedMessage id="common.cancel" />
        </Button>
        <Button variant="contained" color={confirmButtonColor} onClick={onConfirm}>
          {confirmButtonText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
