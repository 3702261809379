export type ReadabilityAndEmotionalityGrade = 1 | 2 | 3;

export const getReadabilityAndEmotionalityGrade = (
  value: number
): ReadabilityAndEmotionalityGrade => {
  // More is better
  if (value < 0.41) {
    return 1;
  }

  if (value < 0.72) {
    return 2;
  }

  return 3;
};

export const getEmotionalityTranslationLabel = (grade: ReadabilityAndEmotionalityGrade) =>
  `flashScore.emotionality.score_${grade}`;
