import { AiWriterProjectTagType } from 'services/api/aiWriter/types';
import { BaseSuccessDto } from 'services/backofficeIntegration/http/BaseSuccessDto';
import { backofficeClient } from 'services/backofficeIntegration/http/client/createBackofficeClient';
import { mutationEndpoint } from 'services/backofficeIntegration/http/endpointDefinition';

type SuccessDto = BaseSuccessDto<AiWriterProjectTagType>;

export type UpdateTagRequestParams = {
  id: number;
  name: string;
  color: string;
};

export const httpUpdateTag = mutationEndpoint({
  callEndpoint: (params: UpdateTagRequestParams) =>
    backofficeClient.put<SuccessDto>(`/tags/${params.id}`, params).then(response => response.data)
});
