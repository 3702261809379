import { ScoreTextsGoalConfig } from 'services/api/wordEmbedding/types';

const extendGoalConfigWithScoreByOpenRate = (
  projectModelId: string,
  goalConfig: ScoreTextsGoalConfig
): ScoreTextsGoalConfig => {
  return { ...goalConfig, mappers: [...goalConfig.mappers, { id: projectModelId, weight: 1 }] };
};

export default extendGoalConfigWithScoreByOpenRate;
