import React from 'react';
import styled from 'styled-components';
import { blue } from 'styles/styleUtils';

import RoundChip from '../base/RoundChip';
import { getScoreColor } from './utils';

const ScoreChipContainer = styled.div`
  display: flex;
  gap: 0.5em;
  align-items: center;
  padding: 0.25em 0;
`;

type Props = {
  value?: number;
  size?: number;
  label?: React.ReactNode;
  children?: React.ReactNode;
};

const ScoreChip = ({ value, label, children, size }: Props) => {
  const color = value ? getScoreColor(value) : blue;

  return (
    <ScoreChipContainer>
      <RoundChip label={label} color={color} size={size} />
      {children}
    </ScoreChipContainer>
  );
};

export default ScoreChip;
