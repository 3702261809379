import { AppLanguage } from 'features/language/store/types';
import { getLocalizedObject } from 'features/modular-workflow/builder/getLocalizedObject';
import {
  BuilderModularWorkflow,
  BuilderModularWorkflowStep
} from 'features/modular-workflow/builder/types-builder';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

export type ModularWorkflowCreationView = 'setup' | 'edit';

type ModulareWorkflowCreationStoreState = {
  currentView: ModularWorkflowCreationView;
  currentLanguage: AppLanguage;
  selectedStepIndex: number;
  isSaving: boolean;
  workflow: BuilderModularWorkflow;
  placeholderAliasCache: string[];
};

type ModulareWorkflowCreationStoreActions = {
  updateWorkflow: (workflow: Partial<BuilderModularWorkflow>) => BuilderModularWorkflow;
  updateWorkflowNameLocalized: (value: string) => void;
  updateWorkflowDescriptionLocalized: (value: string) => void;
  addWorkflowStep: (step: BuilderModularWorkflow['steps'][0]) => BuilderModularWorkflow['steps'][0];
  removeWorkflowStep: (stepOrder: number) => void;
  updateWorkflowStep: (
    step: BuilderModularWorkflow['steps'][0],
    stepIndex: number
  ) => BuilderModularWorkflow;
  updateWorkflowStepOrder: (fromOrder: number, toOrder: number) => void;
  setCurrentView: (view: ModularWorkflowCreationView) => void;
  setSelectedStepIndex: (stepIndex: number) => void;
  setPlaceholderAliasCache: (aliasCache: string[]) => void;
  setCurrentLanguage: (language: AppLanguage) => void;
};

const initialState: ModulareWorkflowCreationStoreState = {
  workflow: {
    id: null,
    name: '',
    name_localized: {
      [AppLanguage.English]: '',
      [AppLanguage.German]: '',
      [AppLanguage.Spanish]: ''
    },
    description: '',
    description_localized: {
      [AppLanguage.English]: '',
      [AppLanguage.German]: '',
      [AppLanguage.Spanish]: ''
    },
    language: 'de_de',
    is_enabled: false,
    steps: [],
    sharing_permission: 'workspace'
  },
  isSaving: false,
  currentView: 'setup',
  selectedStepIndex: 0,
  placeholderAliasCache: [],
  currentLanguage: AppLanguage.English
};

export const useModulareWorkflowCreationStore = create<
  ModulareWorkflowCreationStoreState & ModulareWorkflowCreationStoreActions
>()(
  devtools((set, get) => ({
    updateWorkflow: workflow => {
      const newWorkflow: BuilderModularWorkflow = {
        ...get().workflow,
        ...workflow
      };

      set(() => ({ workflow: newWorkflow }));

      return newWorkflow;
    },
    updateWorkflowNameLocalized: value =>
      set(state => ({
        workflow: {
          ...state.workflow,
          name: state.currentLanguage === AppLanguage.English ? value : state.workflow.name,
          name_localized: getLocalizedObject(
            value,
            state.currentLanguage,
            state.workflow.name_localized
          )
        }
      })),
    updateWorkflowDescriptionLocalized: value =>
      set(state => ({
        workflow: {
          ...state.workflow,
          description:
            state.currentLanguage === AppLanguage.English ? value : state.workflow.description,
          description_localized: getLocalizedObject(
            value,
            state.currentLanguage,
            state.workflow.description_localized
          )
        }
      })),
    addWorkflowStep: step => {
      const workflowSteps = get().workflow.steps;

      // The last 2 steps are always put-it-all-together & destination, so we need to insert it before them
      // Also keep in mind that the order is 1-based, so we need to subtract 2
      const order = workflowSteps.length > 0 ? workflowSteps.length - 2 : 1;

      const newStep: BuilderModularWorkflowStep = {
        ...step,
        order
      };

      // Gather all output aliases to add them into the cache
      const outputAliases = newStep.outputs.map(o => o.alias);

      set(state => {
        const newSteps = [
          ...state.workflow.steps.slice(0, order),
          newStep,
          ...state.workflow.steps.slice(order)
        ];

        // Update order of steps (order has to start with 1)
        const updatedSteps = newSteps.map((s, i) => ({ ...s, order: i + 1 }));

        return {
          workflow: {
            ...state.workflow,
            steps: updatedSteps
          },
          placeholderAliasCache: [...state.placeholderAliasCache, ...outputAliases]
        };
      });

      return newStep;
    },
    removeWorkflowStep: stepOrder => {
      // Order is 1-based, so we need to subtract 1
      const stepIndex = stepOrder - 1;

      const workflowSteps = get().workflow.steps;
      set(state => {
        // Create new steps array without the removed step
        const newSteps = [
          ...workflowSteps.slice(0, stepIndex),
          ...workflowSteps.slice(stepIndex + 1)
        ];
        // Update order of steps (order has to start with 1)
        const updatedSteps = newSteps.map((s, i) => ({ ...s, order: i + 1 }));

        return {
          workflow: {
            ...state.workflow,
            steps: updatedSteps
          }
        };
      });
    },
    updateWorkflowStep: (step, stepIndex) => {
      const oldWorkflow = get().workflow;
      const newWorkflow: BuilderModularWorkflow = {
        ...oldWorkflow,
        steps: [
          ...oldWorkflow.steps.slice(0, stepIndex),
          { ...step },
          ...oldWorkflow.steps.slice(stepIndex + 1)
        ]
      };

      set(() => ({ workflow: newWorkflow }));

      return newWorkflow;
    },
    updateWorkflowStepOrder: (fromOrder, toOrder) => {
      const steps = get().workflow.steps;

      // Order is always 1-based
      const fromIndex = fromOrder - 1;
      const toIndex = toOrder - 1;

      // Extract the step that is being moved and remove it from the array
      const movedStep = steps[fromIndex];
      const newSteps = steps.filter((_, i) => i !== fromIndex);

      // Insert the step at the new position
      newSteps.splice(toIndex, 0, movedStep);

      // Update order of steps (order has to start with 1)
      const updatedSteps = newSteps.map((s, i) => ({ ...s, order: i + 1 }));

      set(state => ({
        workflow: {
          ...state.workflow,
          steps: updatedSteps
        }
      }));
    },
    setCurrentView: (view: ModularWorkflowCreationView) => set({ currentView: view }),
    setSelectedStepIndex: (stepIndex: number) => set({ selectedStepIndex: stepIndex }),
    setPlaceholderAliasCache: (placeholderAliasCache: string[]) => set({ placeholderAliasCache }),
    setCurrentLanguage: (language: AppLanguage) => set({ currentLanguage: language }),
    ...initialState
  }))
);
