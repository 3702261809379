import { backofficeClient } from 'services/backofficeIntegration/http/client/createBackofficeClient';
import { mutationEndpoint } from 'services/backofficeIntegration/http/endpointDefinition';

export type EnableWorkflowParams = {
  id: number;
  is_enabled: boolean;
};

export const httpEnableModularWorkflow = mutationEndpoint({
  callEndpoint: (params: EnableWorkflowParams) =>
    backofficeClient.post(`/workflows/${params.id}/status`, params)
});
