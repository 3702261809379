import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import Dimmer from './Dimmer';
import HelpIcon from './HelpIcon';

const SectionWithActionsContainer = styled.section`
  padding: 0.5rem;
  position: relative;

  header {
    text-align: center;
    border-bottom: 1px solid rgba(0, 40, 100, 0.12);
    margin-bottom: 0.75rem;
    padding: 1rem 0 0.5rem;

    aside {
      position: absolute;
      right: 2px;
      top: 1rem;
    }
  }
`;

type Props = {
  title?: string;
  ownTitle?: React.ReactNode;
  children: React.ReactNode;
  actions?: React.ReactNode;
  isLoading?: boolean;
  withLoader?: boolean;
  helpTooltip?: string;
};

const SectionWithActions = ({
  title,
  children,
  actions,
  isLoading,
  ownTitle,
  helpTooltip,
  withLoader
}: Props) => {
  return (
    <SectionWithActionsContainer>
      <Dimmer active={isLoading} loader={withLoader}>
        <header>
          {ownTitle}
          <span className="h4">
            {title && <FormattedMessage id={title} />}
            {helpTooltip && <HelpIcon tooltip={helpTooltip} />}
          </span>
          {actions ? <aside>{actions}</aside> : null}
        </header>
        {children}
      </Dimmer>
    </SectionWithActionsContainer>
  );
};

export default SectionWithActions;
