import { PlateEditor } from '@udecode/plate';
import { getRangeTextWithSeparatedBlocks } from 'features/aiWriter/AiWriterTextEditor/utils/getRangeTextWithSeparatedBlocks';

/**
 * `getSelectionText` from plate is joining all text nodes content with empty string.
 * We need to join block nodes content with line breaks, and this function does that.
 * In the future we want to use markdown as a data transfer format for text generation,
 * but we needed to fix this quickly. This solution is rather hacky, and goes deep into
 * slate internals, so use it with caution.
 */

export function getSelectionTextWithSeparatedBlocks(
  editor: PlateEditor,
  config?: { noTrim?: boolean }
) {
  const selection = editor.selection;
  if (!selection) {
    return '';
  }

  return getRangeTextWithSeparatedBlocks(editor, selection, config);
}
