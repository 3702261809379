import { Button, Typography } from '@mui/material';
import { StepHelpers } from 'components/forms/StepperForm';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import successImg from '../../../../assets/drawings/success-campaign.svg';
import CenteredContainer from '../../../../components/styled/CenteredContainer';

const SubjectLinesSuccess = ({ goToStep, resetStepperForm }: StepHelpers) => {
  const navigate = useNavigate();

  const goToCampaignsTable = () => {
    navigate('/subject-lines/');
  };

  const startNewCampaign = () => {
    goToStep(0);
    resetStepperForm();
  };

  return (
    <SubjectLinesSuccessContainer>
      <Wrapper>
        <TitleWrapper>
          <Typography variant="h5">
            <FormattedMessage id="subject_lines.new_campaign.amazing" />
          </Typography>
          <Typography variant="subtitle1">
            <FormattedMessage
              id="subject_lines.new_campaign.pro_tip"
              values={{
                b: (chunks: string) => <b>{chunks}</b>,
                br: <br />
              }}
            />
          </Typography>
        </TitleWrapper>
        <img src={successImg} alt="success-campaign" />
        <Button variant="contained" color="primary" size="large" onClick={startNewCampaign}>
          <FormattedMessage id="common.create_more" />
        </Button>
        <Button onClick={goToCampaignsTable} color="primary">
          <Typography variant="body2">
            <FormattedMessage id="subject_lines.new_campaign.back_to_overview" />
          </Typography>
        </Button>
      </Wrapper>
    </SubjectLinesSuccessContainer>
  );
};

const SubjectLinesSuccessContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1.5rem;
  margin: auto;
  text-align: center;
`;

const Wrapper = styled(CenteredContainer)`
  gap: 1rem;
`;

const TitleWrapper = styled.div`
  gap: 0.1rem;
`;

export default SubjectLinesSuccess;
