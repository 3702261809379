import {
  createPluginFactory,
  getFirstNode,
  getLastNode,
  getRange,
  isExpanded
} from '@udecode/plate';
import { PlateEditor, removeMark, Value } from '@udecode/plate-common';
import { useFeatureFlagEnabled } from 'posthog-js/react';
import { BaseRange } from 'slate';

export const MARK_FAKE_SELECTION = 'neuroflash-fake-selection';

type PluginOptions = {
  enabled?: boolean;
};

export type EditorWithPrevSelection<V extends Value = Value> = PlateEditor<V> & {
  prevSelection?: BaseRange | null;
};

export const createFakeSelectionPlugin = createPluginFactory<PluginOptions>({
  key: MARK_FAKE_SELECTION,
  isLeaf: true,
  handlers: {
    onBlur:
      (editor: EditorWithPrevSelection<Value>, { options }) =>
      event => {
        event.preventDefault();

        if (!options.enabled) {
          return;
        }

        const currentSelection = editor.selection;
        const hasSelection = !!currentSelection && isExpanded(currentSelection);

        if (hasSelection) {
          withoutUndo(editor, () => {
            editor.addMark(MARK_FAKE_SELECTION, true);
          });

          editor.prevSelection = currentSelection;
        }
      },
    onFocus:
      (editor: EditorWithPrevSelection<Value>, { options }) =>
      event => {
        event.preventDefault();

        if (!options.enabled) {
          return;
        }

        /**
         * setTimeout is the solution to make it work with `flash action` input and
         * to correctly remove the `fake selection` after focus.
         * A smaller delay didn't solve removing the fake selection correctly (required double-clicking),
         *  at least on my computer.
         */
        setTimeout(() => {
          removeAllFakeSelectionMarks(editor);
        }, 100);

        if (editor.prevSelection) {
          editor.prevSelection = null;
        }
      }
  },
  useHooks: (_editor, { options }) => {
    options.enabled = useFeatureFlagEnabled('contentflash-editor-fake-selection') ?? false;
  }
});

const withoutUndo = (editor: EditorWithPrevSelection<Value>, callback: () => void) => {
  const currentUndoList = [...editor.history.undos];
  callback();
  editor.history.undos = currentUndoList;
};

const removeAllFakeSelectionMarks = (editor: EditorWithPrevSelection<Value>) => {
  // Get the path of first and last node
  const [, firstNodePath] = getFirstNode(editor, [0]);
  const [, lastNodePath] = getLastNode(editor, [editor.children.length - 1]);

  // Create a full range from first to last node
  const fullRange = getRange(editor, firstNodePath, lastNodePath);

  withoutUndo(editor, () => {
    removeMark(editor, { key: MARK_FAKE_SELECTION, shouldChange: false, at: fullRange });
  });
};

export const clearFakeSelection = (editor: EditorWithPrevSelection<Value>) => {
  removeAllFakeSelectionMarks(editor);

  editor.prevSelection = null;
};
