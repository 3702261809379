import { css, keyframes } from 'styled-components';

export const wiggleKeyframes = keyframes`
  0%, 7% {
    transform: rotateZ(0);
  }
  15% {
    transform: rotateZ(-5deg);
  }
  20% {
    transform: rotateZ(5deg);
  }
  25% {
    transform: rotateZ(-3deg);
  }
  30% {
    transform: rotateZ(1deg);
  }
  35% {
    transform: rotateZ(-1deg);
  }
  40%, 100% {
    transform: rotateZ(0);
  }
`;

export const wiggleAnimation = css`
  animation: ${wiggleKeyframes} 2s linear;
`;
