import { makeCurrentUserQueryKeyPrefix } from 'features/currentUser/currentUserQueries';

function makeBaseKey() {
  return [...makeCurrentUserQueryKeyPrefix(), 'personalities'] as const;
}

export const personalitiesQueryKeys = {
  forList: () => [...makeBaseKey(), 'list'] as const,
  forItem: () => [...makeBaseKey(), 'item'] as const,
  forLimitations: () => [...makeBaseKey(), 'limitations'] as const
};
