import { PlanFeatures } from 'features/features/store/types';
import {
  basicPlanFeatures,
  businessPlanFeatures,
  enterprisePlanFeatures,
  freePlanFeatures,
  noFeatures,
  powerPlanFeatures,
  powerVipPlanFeatures,
  premiumPlanFeatures,
  proPlanFeatures,
  standardPlanFeatures,
  starterPlanFeatures
} from 'features/features/utils/planFeatures';
import { PricingPlan } from 'features/pricing/types';

export const pricingPlansFeatures: Record<PricingPlan, PlanFeatures> = {
  [PricingPlan.free]: freePlanFeatures,
  [PricingPlan.basic]: basicPlanFeatures,
  [PricingPlan.power]: powerPlanFeatures,
  [PricingPlan.powerLower]: powerPlanFeatures,
  [PricingPlan.powerHigher]: powerPlanFeatures,
  [PricingPlan.powerVip]: powerVipPlanFeatures,
  [PricingPlan.premium]: premiumPlanFeatures,
  [PricingPlan.enterprise]: enterprisePlanFeatures,
  [PricingPlan.pro]: proPlanFeatures,
  [PricingPlan.business]: businessPlanFeatures,
  [PricingPlan.standard]: standardPlanFeatures,
  [PricingPlan.starter]: starterPlanFeatures,
  [PricingPlan.ignore]: noFeatures
};
