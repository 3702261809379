import back from 'assets/chevron-left.svg';
import Bar from 'components/ChartElements/Bar';
import SVGText from 'components/ChartElements/SVGText';
import GraphTooltip from 'components/tooltips/GraphTooltip';
import { ScaleBand, ScaleLinear } from 'd3-scale';
import { TestingLegend } from 'features/aiTester/store/types';
import useTr from 'utils/hooks/useTr';
import { formatToPercentage } from 'utils/wordUtils';

import useGetGraphLabel from '../hooks/getGraphLabel';
import { createXGroupedScale, GroupedContentTestingData } from '../utils/graphData';

type Props = {
  data: GroupedContentTestingData[];
  texts: string[];
  legend: TestingLegend;
  height: number;
  width: number;
  x: ScaleBand<string>;
  y: ScaleLinear<number, number>;
  goToStacked: () => void;
  transform?: string;
};

const AllValuesGraph = ({
  data,
  legend,
  texts,
  x,
  y,
  width,
  height,
  goToStacked,
  transform
}: Props) => {
  const tr = useTr();
  const xGroup = createXGroupedScale(texts, x.bandwidth());

  const getGraphLabel = useGetGraphLabel();

  return (
    <g transform={transform}>
      <g onClick={goToStacked} cursor="pointer" className="no-export">
        <image xlinkHref={back} x={width - 64} y={height - 30} width={24} height={24} />
        <SVGText x={width - 8} y={height - 13.5} textAnchor="end">
          {tr('back')}
        </SVGText>
      </g>
      <g>
        {data.map(({ data: fieldData, field, label }) => (
          <g transform={`translate(${x(label || tr(`goal_score.${field}`))}, 0)`} key={field}>
            {fieldData.map(({ value, textId, textLabel }) => {
              if (legend[field]) {
                const { color, active } = legend[field];
                const barValue = active ? value : 0;

                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                const x = xGroup(textLabel)!;
                const width = xGroup.bandwidth();

                const initial = { height: 0, y: y(0), x, width };
                const final = {
                  x,
                  y: barValue >= 0 ? y(barValue) : y(0),
                  width,
                  height: active ? Math.abs(y(0) - y(barValue)) : 0
                };
                return (
                  <GraphTooltip
                    key={textId}
                    content={`${getGraphLabel(field, label)}: ${formatToPercentage(value)}`}
                  >
                    <Bar key={`${label}_${field}`} initial={initial} animate={final} fill={color} />
                  </GraphTooltip>
                );
              }
              return null;
            })}
          </g>
        ))}
      </g>
    </g>
  );
};

export default AllValuesGraph;
