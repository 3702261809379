import { BaseSuccessDto } from 'services/backofficeIntegration/http/BaseSuccessDto';
import { backofficeClient } from 'services/backofficeIntegration/http/client/createBackofficeClient';
import { mutationEndpoint } from 'services/backofficeIntegration/http/endpointDefinition';

type SuccessDto = BaseSuccessDto<{
  is_finished: boolean;
}>;

export const httpCompleteTask = mutationEndpoint({
  callEndpoint: (taskId: number) =>
    backofficeClient
      .post<SuccessDto>(`/customer/task-lists/${taskId}`)
      .then(response => response.data.data)
});
