import { ReactComponent as CrownIcon } from 'assets/icons/crown.svg';
import { SidebarItem } from 'features/pageSidebar/sidebarItems/SidebarItem';
import useUpdateSubscriptionModal from 'features/updateSubscriptionModal/hook/useUpdateSubscriptionModal';
import gtmIds from 'services/tracking/GTMIds';
import styled from 'styled-components';

export const SidebarItemUpgradeButton = () => {
  const showUpgradeSubscriptionModal = useUpdateSubscriptionModal();

  const handleClick = () => {
    showUpgradeSubscriptionModal();
  };

  return (
    <SidebarItem
      gtmId={gtmIds.sidebar.upgrade}
      onClick={handleClick}
      icon={<YellowCrownIcon />}
      name="common.upgrade"
      tooltip="common.upgrade"
    />
  );
};

const YellowCrownIcon = styled(CrownIcon)`
  color: ${({ theme }) => theme.colors.palette.orange__400};
  width: 24px;
  height: 24px;
`;
