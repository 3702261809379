import { AppThunk } from 'store/store';

import { updateTesterProjectInBackgroundThunk } from '../../project/thunks/updateTesterProjectInBackgroundThunk';
import { generateSuggestionsThunk } from '../../suggestions/thunks/generateSuggestionsThunk';
import { changeBriefConfig } from '../actions';
import { ChangeBriefConfigPayload } from '../types';

export const changeBriefConfigThunk =
  (payload: ChangeBriefConfigPayload): AppThunk<void> =>
  async dispatch => {
    dispatch(changeBriefConfig(payload));
    dispatch(generateSuggestionsThunk());
    dispatch(updateTesterProjectInBackgroundThunk());
  };
