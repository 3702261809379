import { Typography } from '@mui/material';
import { FieldRendererContext } from 'features/modular-workflow/runner/fields/FieldRenderer';
import { ReactElement, useContext, useId } from 'react';
import styled from 'styled-components';

type Props = {
  children: (elementId: string) => ReactElement;
};

export const BaseField = ({ children }: Props) => {
  const elementId = useId();

  const { input, inputErrorList } = useContext(FieldRendererContext);

  return (
    <Root>
      <InputHeader>
        <Typography variant="subtitle2" color={inputErrorList.length > 0 ? 'red' : undefined}>
          <label className="label" htmlFor={elementId}>
            {input.name}
            {input.is_required ? '*' : ''}
          </label>
        </Typography>
      </InputHeader>

      {children(elementId)}
    </Root>
  );
};

const Root = styled.div`
  width: 100%;
`;

const InputHeader = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: ${({ theme }) => theme.spacings.medium};
  margin-bottom: ${({ theme }) => theme.spacings.small};
`;
