import { AddRounded } from '@mui/icons-material';
import { Button, Typography } from '@mui/material';
import { CloseModal } from 'components/modals/types';
import { CreateNewTagView } from 'features/aiWriter/AiWriterProjectOverview/tags/CreateNewTagView';
import { TagsListSection } from 'features/aiWriter/AiWriterProjectOverview/tags/TagsListSection';
import {
  useTagsModalViewStore,
  ViewOptions
} from 'features/aiWriter/AiWriterProjectOverview/tags/useTagsModalViewStore';
import { ThemedCategoryList } from 'features/theme-2024/category-list/ThemedCategoryList';
import { ThemedCategoryListItem } from 'features/theme-2024/category-list/ThemedCategoryListItem';
import { ModalWithDividedHeaderLayout } from 'features/theme-2024/ModalWithDividedHeaderLayout';
import { FormattedMessage } from 'react-intl';
import gtmIds from 'services/tracking/GTMIds';
import { withGtmInteraction } from 'services/tracking/withGtmInteraction';

type Props = CloseModal;

export type TableViewOptions = 'all' | 'my' | 'team' | 'recent';

const RightColumnContent = ({ view }: { view: string }) => {
  if (view === ViewOptions.NEW_TAG) {
    return <CreateNewTagView />;
  } else if (view === ViewOptions.ALL_TAGS) {
    return <TagsListSection type="all" />;
  } else if (view === ViewOptions.RECENT_TAGS) {
    return <TagsListSection type="recent" />;
  } else if (view === ViewOptions.TAGS_BY_ME) {
    return <TagsListSection type="my" />;
  } else if (view === ViewOptions.TAGS_BY_TEAM) {
    return <TagsListSection type="team" />;
  }
  return <FormattedMessage id="common.name" />;
};

export const ManageTagsModal = (props: Props) => {
  const { closeModal } = props;
  const selectedView = useTagsModalViewStore(state => state.view);
  const setSelectedView = useTagsModalViewStore(state => state.setView);

  const handleAddNewTag = () => {
    setSelectedView(ViewOptions.NEW_TAG);
  };

  const handleShowMyTags = () => {
    setSelectedView(ViewOptions.TAGS_BY_ME);
  };

  const handleShowAllTags = () => {
    setSelectedView(ViewOptions.ALL_TAGS);
  };

  const handleShowTeamTags = () => {
    setSelectedView(ViewOptions.TAGS_BY_TEAM);
  };

  const handleShowRecentTags = () => {
    setSelectedView(ViewOptions.RECENT_TAGS);
  };

  return (
    <ModalWithDividedHeaderLayout
      title={<FormattedMessage id="common.tags" />}
      closeModal={closeModal}
    >
      <ThemedCategoryList
        header={
          <Button
            {...withGtmInteraction(gtmIds.aiWriter.tags.newTagView)}
            variant="contained"
            color="primary"
            startIcon={<AddRounded />}
            fullWidth={true}
            onClick={handleAddNewTag}
          >
            <FormattedMessage id="tags.modal.create.label" />
          </Button>
        }
      >
        <ThemedCategoryListItem
          {...withGtmInteraction(gtmIds.aiWriter.tags.allTags)}
          onClick={handleShowAllTags}
          selected={selectedView === ViewOptions.ALL_TAGS}
        >
          <Typography variant="body2">
            <FormattedMessage id="tags.modal.all_tags.label" />
          </Typography>
        </ThemedCategoryListItem>
        <ThemedCategoryListItem
          {...withGtmInteraction(gtmIds.aiWriter.tags.mostRecentTags)}
          onClick={handleShowRecentTags}
          selected={selectedView === ViewOptions.RECENT_TAGS}
        >
          <Typography variant="body2">
            <FormattedMessage id="tags.modal.recent_tags.label" />
          </Typography>
        </ThemedCategoryListItem>
        <ThemedCategoryListItem
          {...withGtmInteraction(gtmIds.aiWriter.tags.tagsByMe)}
          onClick={handleShowMyTags}
          selected={selectedView === ViewOptions.TAGS_BY_ME}
        >
          <Typography variant="body2">
            <FormattedMessage id="tags.modal.tags_by_me.label" />
          </Typography>
        </ThemedCategoryListItem>
        <ThemedCategoryListItem
          {...withGtmInteraction(gtmIds.aiWriter.tags.tagsByTeam)}
          onClick={handleShowTeamTags}
          selected={selectedView === ViewOptions.TAGS_BY_TEAM}
        >
          <Typography variant="body2">
            <FormattedMessage id="tags.modal.tags_by_team.label" />
          </Typography>
        </ThemedCategoryListItem>
      </ThemedCategoryList>

      <RightColumnContent view={selectedView} />
    </ModalWithDividedHeaderLayout>
  );
};
