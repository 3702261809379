import { useQuery } from '@tanstack/react-query';
import { useStoredPersonalityId } from 'features/aiWriter/AiWriterSidebar/steps/chat/chatStore';
import { httpGetPersonality } from 'services/backofficeIntegration/http/endpoints/personalities/httpGetPersonality';
import { personalitiesQueryKeys } from 'services/backofficeIntegration/http/queryKeyFactories/personalitiesQueryKeys';
import { queryClient } from 'utils/ReactQueryClient';
import { assertNonNullable, isNonNullable } from 'utils/typescript/nonNullable';

export const invalidateCurrentPersonalityQuery = () => {
  return queryClient.invalidateQueries(personalitiesQueryKeys.forItem());
};

export function useCurrentPersonalityQuery() {
  const id = useStoredPersonalityId();

  return useQuery({
    enabled: isNonNullable(id),
    queryKey: isNonNullable(id) ? httpGetPersonality.makeQueryKey({ id }) : [],
    queryFn: async () => {
      assertNonNullable(id);
      return httpGetPersonality.callEndpoint({ id });
    }
  });
}
