export type PaginationData = {
  offset: number;
  limit: number;
  total: number;
};

export type PaginatedListSuccessDto<ListItem> = PaginationData & {
  data: ListItem[];
};

export type PaginatedListParams = {
  offset?: number;
  limit?: number;
};

export function getNextPageParam(paginationData: PaginationData): PaginatedListParams | undefined {
  const { limit, offset, total } = paginationData;
  const nextOffset = offset + limit;
  if (nextOffset >= total) {
    return undefined;
  }
  return { limit, offset: nextOffset };
}
