import { useModal } from 'components/modals';
import { useCallback } from 'react';

const useUpdateSubscriptionModal = () => {
  const { showModal } = useModal();

  return useCallback(() => {
    showModal('UPDATE_SUBSCRIPTION', { size: 1100 });
  }, [showModal]);
};

export default useUpdateSubscriptionModal;
