import { useEffect, useRef } from 'react';
import { DefaultTheme } from 'styled-components';
import { newStyledComponentsTheme } from 'styles/new-theme/newStyledComponentsTheme';
import { newThemeConfig } from 'styles/new-theme/newThemeConfig';
import { newThemeConfigDarkMode } from 'styles/new-theme/newThemeConfigDarkMode';
import { useAppThemeStore } from 'styles/new-theme/useAppThemeStore';

export type ThemeMode = 'dark' | 'light';

export const getThemeConfig = (themeMode: ThemeMode) =>
  themeMode === 'dark' ? newThemeConfigDarkMode : newThemeConfig;

export const useThemedStyledComponentsConfig = (): [DefaultTheme, ThemeMode] => {
  const themeMode = useAppThemeStore(state => state.themeMode);
  const styledComponentsThemeRef = useRef(newStyledComponentsTheme(getThemeConfig(themeMode)));

  // Change styled-components theme when themeMode changes
  useEffect(() => {
    styledComponentsThemeRef.current = newStyledComponentsTheme(getThemeConfig(themeMode));
  }, [themeMode]);

  return [styledComponentsThemeRef.current, themeMode];
};
