import { MouseEvent, useCallback, useMemo, useState } from 'react';

export default function useDropdown() {
  const [anchorElement, setAnchorElement] = useState<HTMLElement | null>(null);

  const open = useCallback((e: MouseEvent<HTMLElement>) => setAnchorElement(e.currentTarget), []);

  const close = useCallback(() => setAnchorElement(null), []);

  const isOpen = useMemo(() => !!anchorElement, [anchorElement]);

  return { anchorElement, isOpen, open, close };
}
