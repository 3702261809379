import { Button, FormControl, LinearProgress, Typography } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { ColoredTypography } from 'components/ColoredTypography';
import FlexContainer from 'components/FlexContainer';
import { SearchField } from 'components/SearchField';
import { TemplatesFiltering } from 'features/aiWriter/commandTemplates/TemplatesFiltering';
import { TemplateTile } from 'features/aiWriter/commandTemplates/TemplateTile';
import { useTemplatesQuery } from 'features/aiWriter/commandTemplates/useTemplatesQuery';
import { useGetProjectOrPreselectedLanguageAndCountry } from 'features/aiWriter/hooks/useGetProjectOrPreselectedLanguageAndCountry';
import { ModalListCardGrid } from 'features/theme-2024/modal-list-card/ModalListCardGrid';
import { ChangeEvent, useId, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  FilteringTypes,
  SortingTypes,
  SpecialCategories,
  TemplateDto
} from 'services/backofficeIntegration/http/endpoints/textGeneration/commandTemplates/httpGetCommandTemplates';
import gtmIds from 'services/tracking/GTMIds';
import { withGtmInteraction } from 'services/tracking/withGtmInteraction';
import styled from 'styled-components';
import { useDebounce } from 'use-debounce/lib';

type Props = {
  preselectedModelId?: string;
  selectedCategory: SpecialCategories | number;
  onPickToEdit: (template: TemplateDto) => void;
  onEditClicked: (template: TemplateDto) => void;
  onDeleteClicked: (template: TemplateDto) => void;
  onCreateNew: () => void;
};

export function TemplatesList({
  preselectedModelId,
  selectedCategory,
  onPickToEdit,
  onEditClicked,
  onDeleteClicked,
  onCreateNew
}: Props) {
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [selectedSorting, setSelectedSorting] = useState<SortingTypes>(SortingTypes.USAGE);
  const [selectedFiltering, setSelectedFiltering] = useState<FilteringTypes>(FilteringTypes.ANY);
  const [debouncedSearchQuery] = useDebounce(searchQuery, 300);

  const { language, country } = useGetProjectOrPreselectedLanguageAndCountry(preselectedModelId);

  const query = useTemplatesQuery({
    language,
    country,
    category: selectedCategory,
    title: debouncedSearchQuery,
    sorting: selectedSorting,
    filtering: selectedFiltering
  });
  const selectSortId = useId();

  function onSearchChange(e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    setSearchQuery(e.target.value);
  }

  function onSortingChange(e: SelectChangeEvent) {
    setSelectedSorting(e.target.value as SortingTypes);
  }

  function renderList() {
    if (!query.data) {
      return null;
    }

    const items = query.data.pages.map(page => page.data).flat(1);
    if (items.length < 1) {
      return (
        <Typography textAlign="center" variant="body2">
          <FormattedMessage id="aiWriter.inspirations.templates.empty" />
        </Typography>
      );
    }

    return (
      <ModalListCardGrid columns={3}>
        {items.map(dto => (
          <TemplateTile
            key={dto.id}
            template={dto}
            onClick={onPickToEdit}
            onEditClicked={onEditClicked}
            onDeleteClicked={onDeleteClicked}
            refetch={query.refetch}
          />
        ))}
      </ModalListCardGrid>
    );
  }

  function renderRetry(messageId: string) {
    return (
      <FlexContainer alignItems="center" gap="medium">
        <Typography variant="body2" textAlign="center">
          <FormattedMessage id={messageId} />
        </Typography>
        <Button
          variant="contained"
          onClick={() => {
            query.refetch();
          }}
        >
          <FormattedMessage id="common.refresh" />
        </Button>
      </FlexContainer>
    );
  }

  function renderQueryControls() {
    if (query.isFetching) {
      return (
        <FlexContainer>
          <LinearProgress />
        </FlexContainer>
      );
    }

    if (query.isLoadingError) {
      return renderRetry('aiWriter.inspirations.templates.errors.load');
    }

    if (query.isRefetchError && !query.hasNextPage) {
      return renderRetry('aiWriter.inspirations.templates.errors.refetch');
    }

    if (query.hasNextPage) {
      return (
        <Button
          fullWidth
          onClick={() => {
            query.fetchNextPage();
          }}
        >
          <FormattedMessage id="common.moreButton" />
        </Button>
      );
    }
  }

  function renderSortingSelect() {
    return (
      <SelectFormControl variant="outlined">
        <InputLabel id={selectSortId}>
          <FormattedMessage id="aiWriter.inspirations.templates.sorting.label" />
        </InputLabel>
        <Select
          labelId={selectSortId}
          label={<FormattedMessage id="aiWriter.inspirations.templates.sorting.label" />}
          value={selectedSorting}
          onChange={onSortingChange}
        >
          <MenuItem value={SortingTypes.ALPHABETICAL_ASC}>
            <FormattedMessage id="aiWriter.inspirations.templates.sorting.alphabetical_asc" />
          </MenuItem>
          <MenuItem value={SortingTypes.ALPHABETICAL_DESC}>
            <FormattedMessage id="aiWriter.inspirations.templates.sorting.alphabetical_desc" />
          </MenuItem>
          <MenuItem value={SortingTypes.USAGE}>
            <FormattedMessage id="aiWriter.inspirations.templates.sorting.usage" />
          </MenuItem>
        </Select>
      </SelectFormControl>
    );
  }

  return (
    <Root>
      <FlexContainer direction="row" justifyContent="space-between">
        <ColoredTypography variant="h6">
          <FormattedMessage id="aiWriter.inspirations.templates.headline.list" />
        </ColoredTypography>
        <Button
          {...withGtmInteraction(gtmIds.templatesLibrary.createTemplate)}
          variant="contained"
          onClick={onCreateNew}
        >
          <FormattedMessage id="aiWriter.inspirations.templates.create" />
        </Button>
      </FlexContainer>

      <GridContainer>
        {renderSortingSelect()}

        <TemplatesFiltering
          label={<FormattedMessage id="aiWriter.inspirations.templates.filtering.label" />}
          value={selectedFiltering}
          onChange={setSelectedFiltering}
        />

        <SearchField value={searchQuery} onChange={onSearchChange} />
      </GridContainer>

      {renderList()}

      {renderQueryControls()}
    </Root>
  );
}

const Root = styled.div`
  /* This is required to shrink the output grid  */
  min-height: 0;

  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.xmedium};
`;

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: ${({ theme }) => theme.spacings.small};
`;

const SelectFormControl = styled(FormControl)`
  min-width: 200px;
`;
