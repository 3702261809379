import deLocale from 'dayjs/locale/de';
import ukLocale from 'dayjs/locale/en-gb';
import esLocale from 'dayjs/locale/es';
import { default as translationsGerman } from 'features/i18n/locales/de.json';
import { default as translationsEnglish } from 'features/i18n/locales/en.json';
import { default as translationsSpanish } from 'features/i18n/locales/es.json';
import { AppLanguage } from 'features/language/store/types';

type LocalData = {
  dayjsLocale: ILocale;
  appTranslations: Record<string, string>;
  selectable: boolean;
};

// TODO: Lazy load translations
export const localizations: Record<AppLanguage, LocalData> = {
  [AppLanguage.German]: {
    dayjsLocale: deLocale,
    appTranslations: translationsGerman,
    selectable: true
  },
  [AppLanguage.English]: {
    dayjsLocale: ukLocale,
    appTranslations: translationsEnglish,
    selectable: true
  },
  [AppLanguage.Spanish]: {
    dayjsLocale: esLocale,
    appTranslations: translationsSpanish,
    selectable: true
  }
};

export const getSelectableLanguages = () =>
  Object.entries(localizations)
    .filter(([, data]) => data.selectable)
    .map(([language]) => language as AppLanguage);
