import { Button, CircularProgress, Typography } from '@mui/material';
import HelpIcon from 'components/base/HelpIcon';
import FormattedMessage from 'features/i18n/FormattedMessage';
import { DailyUsageChart } from 'features/profilePage/wordsUsage/dailyUsage/DailyUsageChart';
import { useGetTeamUsageHistory } from 'features/profilePage/wordsUsage/useGetTeamUsageHistory';
import styled from 'styled-components';

export function DailyTeamUsageSection() {
  const result = useGetTeamUsageHistory();

  return (
    <Root>
      <SectionHeading>
        <FormattedMessage id="profile_page.usage_card.daily_usage_section.heading" />
        <HelpIcon tooltip="profile_page.usage_card.daily_usage_section.explanation" />
      </SectionHeading>
      <SectionBody>{renderBodyContent()}</SectionBody>
    </Root>
  );

  function renderBodyContent() {
    if (result.isSuccess && result.data.status) {
      return (
        <ChartBox>
          <DailyUsageChart history={result.data.data.usageHistory} />
        </ChartBox>
      );
    }

    const beError = result.isSuccess && !result.data.status;
    if (result.isError || beError) {
      return (
        <ErrorBox>
          <FormattedMessage id="profile_page.usage_card.daily_usage_section.error" />
          <Button onClick={() => result.refetch()}>
            <FormattedMessage id="common.refresh" />
          </Button>
        </ErrorBox>
      );
    }

    return (
      <CenteredBox>
        <CircularProgress />
      </CenteredBox>
    );
  }
}

const Root = styled.section``;

const SectionHeading = styled(Typography).attrs({ variant: 'h6' })`
  margin-bottom: ${({ theme }) => theme.spacings.medium};
`;

const SectionBody = styled.div`
  height: 400px;
  display: grid;
`;

const ChartBox = styled.div`
  // Chart will not shrink on window resize without this
  min-width: 0;
`;

const CenteredBox = styled.div`
  place-self: center;
`;

const ErrorBox = styled(CenteredBox)`
  display: grid;
  place-items: center;
`;
