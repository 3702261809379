import { ModalType } from 'components/modals/modalComponents';
import {
  ModalAction,
  ModalState,
  ShowModalParam,
  ShowModalPayload,
  ShowModalType
} from 'components/modals/types';
import React, { createContext, useCallback, useReducer, useRef } from 'react';
import { noop } from 'utils/utils';

const defaultState: ModalState = {
  isOpen: false,
  modalType: null,
  modalProps: {}
};

const defaultContextValue = {
  ...defaultState,
  showModal: noop as ShowModalType,
  hideModal: noop,
  cleanUpModal: noop
};

export const ModalContext = createContext(defaultContextValue);
const { Provider } = ModalContext;

const modalReducer = (state: ModalState, action: ModalAction) => {
  if (action.type === 'show')
    return {
      modalType: action.payload.modalType,
      modalProps: action.payload.modalProps || {},
      isOpen: true
    };
  if (action.type === 'hide') return { ...state, isOpen: false };
  if (action.type === 'cleanup') return defaultState;
  return state;
};

const isModalPayload = (value: unknown): value is ShowModalPayload =>
  typeof value === 'object' &&
  value !== null &&
  'modalType' in value &&
  // TODO: Remove after upgraded to TypeScript 5.5
  //       https://www.typescriptlang.org/docs/handbook/release-notes/typescript-5-5.html#inferred-type-predicates
  !!(value as { modalType: unknown }).modalType;
const isModalType = (value: unknown): value is ModalType => typeof value === 'string';

type Props = {
  children: React.ReactElement;
};

const ModalProvider = ({ children }: Props) => {
  const [state, dispatch] = useReducer(modalReducer, defaultState);
  const shouldCleanUpRef = useRef(false);
  const showModal = useCallback(
    <T extends ShowModalPayload | ModalType>(configOrType: T, modalProps?: ShowModalParam<T>) => {
      shouldCleanUpRef.current = false;

      if (isModalType(configOrType)) {
        dispatch({
          type: 'show',
          payload: {
            modalType: configOrType,
            modalProps
          }
        });
      } else if (isModalPayload(configOrType)) {
        dispatch({
          type: 'show',
          payload: configOrType
        });
      }
    },
    []
  );

  const hideModal = useCallback(() => {
    dispatch({ type: 'hide' });
    shouldCleanUpRef.current = true;
  }, []);

  const cleanUpModal = useCallback(() => {
    if (shouldCleanUpRef.current) {
      dispatch({ type: 'cleanup' });
    }
  }, []);

  return <Provider value={{ ...state, showModal, hideModal, cleanUpModal }}>{children}</Provider>;
};

export default ModalProvider;
