import { CSSProperties } from 'react';

const camelToKebabRegex = RegExp(/[A-Z]/, 'g');

const createParser = (input: string) => {
  if (typeof input !== 'string') {
    throw new TypeError(`expected an argument of type string, but got ${typeof input}`);
  }

  if (!input.match(camelToKebabRegex)) {
    return input;
  }

  return input.replace(camelToKebabRegex, match => `-${match.toLowerCase()}`);
};

export const objectToCss = (styleObj: CSSProperties) =>
  Object.keys(styleObj)
    .map(property => `${createParser(property)}: ${styleObj[property as keyof CSSProperties]};`)
    .join('\n');
