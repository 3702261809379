import {
  KeyboardEventHandler,
  moveSelectionByOffset,
  MoveSelectionByOffsetOptions
} from '@udecode/plate';
import { isHotkey, moveSelection, PlateEditor, Value } from '@udecode/plate-common';
import { flashActionsInput } from 'features/plate/customPlugins/queries/findFlashActionsInput';
import { removeSlashCommandsInput } from 'features/plate/customPlugins/transforms/removeSlashCommandsInput';

export const flashActionsOnKeyDownHandler: <V extends Value>(
  options?: MoveSelectionByOffsetOptions<V>
) => (editor: PlateEditor<V>) => KeyboardEventHandler = options => editor => event => {
  if (isHotkey('escape', event)) {
    const flashActionsCommandsInput = flashActionsInput(editor);

    if (flashActionsCommandsInput) {
      event.preventDefault();
      removeSlashCommandsInput(editor, flashActionsCommandsInput[1]);
      moveSelection(editor, { unit: 'word' });
      return true;
    }
    return false;
  }

  return moveSelectionByOffset(editor, options)(event);
};
