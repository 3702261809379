import { Add } from '@mui/icons-material';
import { Button } from '@mui/material';
import React from 'react';

type Props = {
  id?: string;
  disabled?: boolean;
  isLoading?: boolean;
  className?: string;
  children: React.ReactElement;

  onClick: () => void;
};

const AddButton = ({ id, className, disabled, isLoading, children, onClick }: Props) => {
  return (
    <Button
      id={id}
      className={className}
      onClick={onClick}
      color="primary"
      startIcon={<Add />}
      disabled={disabled || isLoading}
    >
      {children}
    </Button>
  );
};

export default AddButton;
