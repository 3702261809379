import { Switch } from '@mui/material';
import { MenuDropdownItem } from 'components/MenuDropdown/MenuDropdownItem';
import { setIsViewExtended } from 'features/aiTester/store/actions/config/actions';
import { getIsViewExtended } from 'features/aiTester/store/selectors';
import { ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import { useAppDispatch, useAppSelector } from 'store/hooks';

type Props = {
  onClose: () => void;
};

const ExtendViewAction = ({ onClose }: Props): ReactElement => {
  const dispatch = useAppDispatch();

  const isViewExtended = useAppSelector(getIsViewExtended);

  return (
    <MenuDropdownItem
      icon={<Switch checked={isViewExtended} color="primary" />}
      onClose={onClose}
      onClick={() => dispatch(setIsViewExtended(!isViewExtended))}
    >
      <FormattedMessage id="testing.graph.dropdown_actions.extend_view" />
    </MenuDropdownItem>
  );
};

export default ExtendViewAction;
