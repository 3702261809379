import { Button, CardContent, Typography } from '@mui/material';
import { SpinnerDimmer } from 'components/base/SpinnerDimmer';
import FlexContainer from 'components/FlexContainer';
import { FAQSection } from 'components/profile/components/pricing-plan-card/FAQSection';
import { FeatureComparisonSection } from 'components/profile/components/pricing-plan-card/FeatureComparisonSection';
import { SocialProof } from 'components/profile/components/pricing-plan-card/SocialProof';
import { getRoutePath } from 'config/routes';
import { CouponCode } from 'features/pricing/CouponCode';
import useSubscriptionState from 'features/pricing/hook/useSubscriptionState';
import { PricingPeriodSelect } from 'features/pricing/PricingPeriodSelect';
import { PricingPlanFooter } from 'features/pricing/PricingPlanFooter';
import { TrialCanceledBanner } from 'features/pricing/TrialCanceledBanner';
import { TrialExpiryBanner } from 'features/pricing/TrialExpiryBanner';
import { PricingCardCollection } from 'features/profilePage/PricingCardCollection';
import { ReactElement, ReactNode, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router';
import { SubscriptionPeriod } from 'services/api/customer/types';
import gtmIds from 'services/tracking/GTMIds';
import styled from 'styled-components';

const couponField = 'coupon';
const modalTopMargin = '1.75rem';

type ChangePricingPlanValues = {
  [couponField]: CouponCode;
};

export const initialValues: ChangePricingPlanValues = {
  coupon: { name: '', code: '' }
};

type Props = {
  id?: string;
  subHeadline?: ReactNode;

  onClose?: () => void;
};

const ChangePricingPlanOnlyPaidModal = ({
  id = gtmIds.pricing.upgradePopUp,
  subHeadline,
  onClose
}: Props): ReactElement | null => {
  const { currentPricingPlan } = useSubscriptionState();
  const [subscriptionPeriod, setSubscriptionPeriod] = useState<SubscriptionPeriod>(
    currentPricingPlan?.isFree ? 'year' : currentPricingPlan?.periodName
  );
  const navigate = useNavigate();

  const handleGetBonusWordsClick = () => {
    onClose?.();
    navigate(getRoutePath('bonusReferral'));
  };

  return (
    <SpinnerDimmer id={id} active={false} loader isOverflowSet={true}>
      <ModalBody className="modal-body">
        <Header direction="row" alignItems="center" justifyContent="space-between">
          <div>
            <Headline variant="h5">
              <FormattedMessage id="update_subscription_only_paids.headline" />
            </Headline>
            {subHeadline && <Headline variant="body1">{subHeadline}</Headline>}
          </div>
          <FlexContainer gap="xmedium" direction="row">
            <PricingPeriodSelect
              subscriptionPeriod={subscriptionPeriod}
              onSubscriptionPeriodChange={setSubscriptionPeriod}
            />
          </FlexContainer>
        </Header>

        <TrialExpiryBanner />
        <TrialCanceledBanner />

        <Body>
          <PricingCardCollection period={subscriptionPeriod} fromPopupModal />
        </Body>
        <GetBonusWordsContainer>
          <Left>
            <Typography variant="body2" fontWeight="bold">
              <FormattedMessage id="update_subscription_only_paids.not_ready" />
            </Typography>
            <Typography variant="caption">
              <FormattedMessage id="update_subscription_only_paids.explore" />
            </Typography>
          </Left>
          <GetBonusWordsButton onClick={handleGetBonusWordsClick} variant="contained" size="small">
            <FormattedMessage id="update_subscription_only_paids.get_bonus_words" />
          </GetBonusWordsButton>
        </GetBonusWordsContainer>
        <PricingPlanFooter />
        <SocialProof />
        <FeatureComparisonSection />
        <FAQSection />
      </ModalBody>
    </SpinnerDimmer>
  );
};

const Left = styled.div`
  display: flex;
  flex-direction: column;
`;

const ModalBody = styled.div`
  position: relative;
  padding: ${({ theme }) => theme.spacings.xmedium};
  overflow-y: auto;
  max-height: calc(100vh - (${modalTopMargin} * 2));
`;

const GetBonusWordsButton = styled(Button)`
  padding-left: 40px;
  padding-right: 40px;
  margin-right: 30px;
`;

const Header = styled(FlexContainer)`
  width: 100%;
  margin-bottom: 40px;
`;

const Headline = styled(Typography)`
  && {
    color: ${({ theme }) => theme.colors.blackHighEmphasis};
  }
`;

export const HintWrapper = styled.div`
  margin-top: ${({ theme }) => theme.spacings.medium};
`;

export const Body = styled(CardContent)`
  margin-top: ${({ theme }) => theme.spacings.medium};

  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  &.MuiCardContent-root {
    padding: 0;
  }
`;

export const GetBonusWordsContainer = styled(CardContent)`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  background: ${({ theme }) => theme.colors.primary50};
  margin-top: 24px;
`;

export default ChangePricingPlanOnlyPaidModal;
