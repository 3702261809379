export function apiKeyHeader({ isApiCustomer }: { isApiCustomer: boolean }) {
  // Switch between API and APP service key
  // Note: A short-if doesn't work because it won't be parsed and replaced
  //       by the webpack DefinePlugin
  let key = process.env.REACT_APP_APPLICATION_CUSTOMER_API_KEY;
  if (isApiCustomer) {
    key = process.env.REACT_APP_API_CUSTOMER_API_KEY;
  }

  if (!key) {
    throw new Error('Missing backoffice api key');
  }

  return {
    'X-API-KEY': key
  };
}

export function apiTokenHeader(token: string) {
  if (token.length > 0) {
    return {
      'X-API-TOKEN': token
    };
  }

  throw new Error('Missing backoffice api token');
}
