/**
 * Main purpose of this function is to catch unhandled switch cases in static
 * code analysis. You can put it in a default case and test enumerable value
 * with it like that:
 *
 * ```
 * type Enumerable = 'foo' | 'bar';
 * declare const x: Enumerable;
 *
 * switch (x) {
 *   case 'foo':
 *     break;
 *   default:
 *     return assertNever(x, 'nay!'); // TS will inform you about the missing case
 * }
 * ```
 *
 * It will also throw an error in runtime if ever called
 */
export function forceNever(_: never, msg = 'This code should never be called'): never {
  throw new Error(msg);
}
