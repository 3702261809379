import { FlashActionsMenu } from 'features/aiWriter/AiWriterSidebar/steps/chat/FlashActionsMenu';
import { Message } from 'features/aiWriter/AiWriterSidebar/steps/chat/Message';
import { usePredefinedFlashActions } from 'features/aiWriter/AiWriterSidebar/steps/chat/usePredefinedFlashActions';
import { BasicFlashAction } from 'features/aiWriter/markdown/actions';
import { useState } from 'react';
import gtmIds from 'services/tracking/GTMIds';

export const FlashAction = ({ message }: { message: Message }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isOptionsOpen = Boolean(anchorEl);

  const handleClickOption = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const { handleRewrite, handleContinue, handleSummarize, handleImprove, handleCustomAction } =
    usePredefinedFlashActions({ message });

  return (
    <>
      <BasicFlashAction
        gtmId={gtmIds.aiWriter.chat.openFlashActions}
        onClick={e => {
          e?.stopPropagation();
          e && handleClickOption(e);
        }}
      />
      <FlashActionsMenu
        message={message}
        anchorEl={anchorEl}
        isOptionsOpen={isOptionsOpen}
        onCloseMenu={handleCloseMenu}
        onRewrite={handleRewrite}
        onContinue={handleContinue}
        onSummarize={handleSummarize}
        onImprove={handleImprove}
        onCustomAction={handleCustomAction}
      ></FlashActionsMenu>
    </>
  );
};
