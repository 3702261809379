import { createAction, createReducer } from '@reduxjs/toolkit';
import { RootState } from 'store/store';

export const showChurnKeyPauseWall = createAction('churnkey/showChurnKeyPauseWall');
export const hideChurnKeyPauseWall = createAction('churnkey/hideChurnKeyPauseWall');

export const churnkeyReducer = createReducer(
  {
    ongoingSubscriptionPause: false
  },
  builder => {
    builder.addCase(showChurnKeyPauseWall, state => {
      state.ongoingSubscriptionPause = true;
    });
    builder.addCase(hideChurnKeyPauseWall, state => {
      state.ongoingSubscriptionPause = false;
    });
  }
);

export const isOngoingSubscriptionPause = (state: RootState) =>
  state.churnkey.ongoingSubscriptionPause;
