import React from 'react';
import { noop } from 'utils/utils';

type SelectedItemsContextType = {
  selectedWords: SelectedWord[];
  setSelectedWords: (words: SelectedWord[]) => void;
  lastSelectedItemIndex: number;
  setLastSelectedItemIndex: (index: number) => void;
  showCheckboxes: boolean;
};

export type SelectedWord = { word: string; index: number };

export default React.createContext<SelectedItemsContextType>({
  selectedWords: [],
  setSelectedWords: noop,
  lastSelectedItemIndex: 0,
  setLastSelectedItemIndex: noop,
  showCheckboxes: false
});
