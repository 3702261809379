import { createSelector } from '@reduxjs/toolkit';
import { outputTypesExtendableByOpenRate, TesterTab } from 'features/aiTester/store/types';
import defaultTab from 'features/aiTester/store/utils/defaults/defaultTab';
import getLabelFromIndex from 'features/aiTester/utils/getLabelFromIndex';
import { fallbackModelCountry } from 'features/aiWriter/store/utils/defaults/fallbackModelCountry';
import { fallbackModelLanguage } from 'features/aiWriter/store/utils/defaults/fallbackModelLanguage';
import { getUserAudiences } from 'features/audiences/store/selectors';
import {
  getEmbeddingModelDataSelector,
  getEmbeddingModelsByLanguage
} from 'features/embeddingModels/store/selectors';
import { getIsConfigEditable } from 'features/textGenerator/utils/getIsConfigEditable';
import { getIsConfigReady } from 'features/textGenerator/utils/getIsConfigReady';
import { RootState } from 'store/rootReducer';
import { getActiveTabFactory, getTabsHeadersFactory } from 'store/utils/selectorFactories';

export const getTesterTabs = (state: RootState) => state.tester.tabs;
export const getTesterCurrentTabId = (state: RootState) => state.tester.currentTab;
export const getIsViewExtended = (state: RootState) => state.tester.isViewExtended;
export const getIsTourRunning = (state: RootState) => state.tester.isTourRunning;
export const getIsTourFinished = (state: RootState) => state.tester.isTourFinished;
export const getTesterProjects = (state: RootState) => state.tester.projects;

export const getTesterActiveTab = getActiveTabFactory<TesterTab>(
  getTesterTabs,
  getTesterCurrentTabId,
  defaultTab
);

export const getTesterTabsHeaders = getTabsHeadersFactory(getTesterTabs);

export const getTesterCurrentTabModelData = createSelector(
  getTesterActiveTab,
  getEmbeddingModelDataSelector,
  (tab, getData) => getData(tab.embeddingModelId)
);

export const getTSVsForTab = createSelector(
  getTesterActiveTab,
  getEmbeddingModelDataSelector,
  (tab, getModelData) => {
    const modelData = getModelData(tab.embeddingModelId);
    const mapper = modelData.mappers.find(i => i.name === tab.mapperId);
    return mapper ? mapper.variables : [];
  }
);

export const getGetTokenData = createSelector(getTesterActiveTab, tab => (textId: string) => {
  const textData = tab.texts.find(text => text.id === textId);

  return textData ? textData.tokenData : {};
});

export const getTesterCurrentTabSuggestions = createSelector(getTesterActiveTab, tab => ({
  suggestions: tab.suggestions,
  isLoading: tab.isSuggestionsLoading
}));

export const getTesterTextById = createSelector(
  getTesterActiveTab,
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  tab => (id: string) => tab.texts.find(t => t.id === id)!
);

export const getTesterTabsId = (state: RootState) => {
  return Object.values(state.tester.tabs).map(tab => tab.id);
};

export const getCurrentTabTexts = createSelector(getTesterActiveTab, tab => tab.texts);
export const getSortByScore = createSelector(getTesterActiveTab, tab => tab.sortByScore);

export const getCurrentTabChartData = createSelector(getCurrentTabTexts, texts =>
  texts
    .map((t, i) => ({ ...t, label: getLabelFromIndex(i) }))
    .filter(t => t.isAvailable)
    .map(t => ({
      id: t.id,
      label: t.label,
      goalScore: t.goalScore,
      prevalence: t.prevalence,
      details: t.scoreDetails
    }))
);

export const getIsTextLimitAchieved = createSelector(
  getTesterActiveTab,
  tab => tab.texts.length >= 10
);

export const getIsScoreByOpenRateAvailable = createSelector(
  getTesterActiveTab,
  getUserAudiences,
  (tab, audiences) => {
    const currentOutputType = tab.generateTextConfig?.outputType;
    const currentAudienceId = tab.generateTextConfig?.audienceId;

    if (currentAudienceId && currentOutputType) {
      const isNotGenericAudience = !audiences.find(p => p.id === currentAudienceId)?.is_generic;
      const extendableOutputType = outputTypesExtendableByOpenRate.includes(currentOutputType);

      return isNotGenericAudience && extendableOutputType;
    }

    return false;
  }
);

export const getIsSaving = createSelector(getTesterActiveTab, tab => tab.isSaving);

export const getCurrentModelLanguage = createSelector(
  getTesterActiveTab,
  getEmbeddingModelsByLanguage,
  (tab, models) => {
    return models.find(m => m.id === tab.embeddingModelId)?.language ?? fallbackModelLanguage;
  }
);

export const getCurrentModelLanguageAndCountry = createSelector(
  getTesterActiveTab,
  getEmbeddingModelsByLanguage,
  (tab, models) => {
    const model = models.find(m => m.id === tab.embeddingModelId);

    return {
      currentModelLanguage: model?.language ?? fallbackModelLanguage,
      currentModelCountry: model?.country ?? fallbackModelCountry
    };
  }
);

export const getIsGenerateConfigValid = createSelector(getTesterActiveTab, tab => {
  if (!tab?.generateTextConfig) {
    return false;
  }
  return getIsConfigReady(tab.generateTextConfig);
});

export const getIsGenerateConfigEditable = createSelector(getTesterActiveTab, tab => {
  if (!tab?.generateTextConfig) {
    return false;
  }
  return getIsConfigEditable(tab.generateTextConfig);
});
