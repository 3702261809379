import { TesterProject, TesterTab } from 'features/aiTester/store/types';
import createNewSuggestion from 'features/aiTester/store/utils/defaults/createNewSuggestion';
import createNewText from 'features/aiTester/store/utils/defaults/createNewText';
import defaultTab from 'features/aiTester/store/utils/defaults/defaultTab';
import {
  baseDimensionFromManualDimension,
  manualDimensionFromBaseDimension
} from 'features/dimensions/store/utils/manualDimensionsMappers';

export const testerTabFromProject = (project: TesterProject): TesterTab => ({
  ...defaultTab,
  ...project,
  texts: project.texts.map(text =>
    createNewText({
      text: text.text,
      textItemId: text.textItemId,
      generationId: text.generationId,
      isEditing: false
    })
  ),
  suggestions: project.suggestions.map(suggestion =>
    createNewSuggestion({
      text: suggestion.text,
      textItemId: suggestion.textItemId,
      generationId: suggestion.generationId
    })
  ),
  manualDimensions: project.manualDimensions.map(manualDimensionFromBaseDimension)
});

export const testerTabToProject = (tab: TesterTab): TesterProject => ({
  ...tab,
  texts: tab.texts,
  suggestions: tab.suggestions,
  manualDimensions: tab.manualDimensions.map(baseDimensionFromManualDimension),
  isLoading: false
});
