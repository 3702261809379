import { BaseSuccessDto } from 'services/backofficeIntegration/http/BaseSuccessDto';
import { backofficeClient } from 'services/backofficeIntegration/http/client/createBackofficeClient';
import { TeamMemberDto } from 'services/backofficeIntegration/http/dtos/TeamMemberDto';
import { mutationEndpoint } from 'services/backofficeIntegration/http/endpointDefinition';

type SuccessDto = BaseSuccessDto<TeamMemberDto>;

export type UpdateUserRoleParams = {
  teamCustomerId: number;
  teamId: number;
  team_role: string;
};

export const httpUpdateUserRole = mutationEndpoint({
  callEndpoint: ({ teamCustomerId, teamId, team_role }: UpdateUserRoleParams) =>
    backofficeClient
      .put<SuccessDto>(`/teams/${teamId}/members/${teamCustomerId}/roles`, {
        team_role
      })
      .then(response => response.data.data)
});
