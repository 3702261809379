import { CloseModal } from 'components/modals/types';
import { ChoiceView } from 'features/information/choiceView';
import { CopyPasteView } from 'features/information/CopyPasteView';
import { ShareUrlFlow } from 'features/information/shareUrl';
import { UploadView } from 'features/information/UploadView';
import { useState } from 'react';
import { InformationCategoryDto } from 'services/backofficeIntegration/http/endpoints/infomration/httpGetInformationCategories';
import { InformationDto } from 'services/backofficeIntegration/http/endpoints/infomration/httpGetInformationList';

export const VIEW_MODE = {
  CHOICE: 'choice',
  COPY_PASTE: 'copyPaste',
  UPLOAD: 'upload',
  SHARE_URL: 'shareUrl'
} as const;

export type ViewMode = typeof VIEW_MODE[keyof typeof VIEW_MODE];

export const informationModalHeight = 800;

export type CustomModalProps = {
  preselectedInformation?: InformationDto;
  preselectedTag?: InformationCategoryDto;
  initialViewMode?: ViewMode;
  closeOnBack?: boolean;
  onUploadStarted?: () => void;
};

type ModalProps = CloseModal & CustomModalProps;

export function InformationModal({
  initialViewMode,
  preselectedInformation,
  preselectedTag,
  onUploadStarted,
  closeOnBack,
  closeModal
}: ModalProps) {
  const [view, setView] = useState<ViewMode>(initialViewMode || VIEW_MODE.CHOICE);

  const handleCopyPasteClick = () => {
    setView(VIEW_MODE.COPY_PASTE);
  };

  const handleUploadClick = () => {
    setView(VIEW_MODE.UPLOAD);
  };

  const handleShareUrlClick = () => {
    setView(VIEW_MODE.SHARE_URL);
  };

  const handleBackClick = () => {
    if (closeOnBack) {
      closeModal();
      return;
    }

    setView(VIEW_MODE.CHOICE);
  };

  function renderView() {
    switch (view) {
      case VIEW_MODE.CHOICE:
        return (
          <ChoiceView
            onCopyPasteClick={handleCopyPasteClick}
            onUploadClick={handleUploadClick}
            onShareUrlClick={handleShareUrlClick}
            closeModal={closeModal}
          />
        );
      case VIEW_MODE.COPY_PASTE:
        return (
          <CopyPasteView
            preselectedInformation={preselectedInformation}
            preselectedTag={preselectedTag}
            onBackClick={handleBackClick}
            closeModal={closeModal}
          />
        );
      case VIEW_MODE.UPLOAD:
        return (
          <UploadView
            preselectedInformation={preselectedInformation}
            preselectedTag={preselectedTag}
            onBackClick={handleBackClick}
            onUploadStarted={onUploadStarted}
            closeModal={closeModal}
          />
        );
      case VIEW_MODE.SHARE_URL:
        return <ShareUrlFlow onCancel={handleBackClick} closeModal={closeModal} />;
      default:
        return null;
    }
  }

  return renderView();
}
