import { createSelector } from '@reduxjs/toolkit';
import { DimensionRow } from 'features/dimensions/store/types';
import { RootState } from 'store/rootReducer';

export const getCurrentDimension = createSelector(
  (s: RootState) => s.dimensions,
  ({ currentDimension, dimensions }): DimensionRow =>
    currentDimension && dimensions[currentDimension]
      ? dimensions[currentDimension]
      : {
          id: 0,
          label: '',
          words: [],
          language: '',
          description: '',
          project_id: null,
          project: null,
          type: 'public',
          author: {
            id: null,
            name: null,
            image_url: null
          },
          created_at: null,
          updated_at: null,
          opened: false,
          selected: false,
          total: 0
        }
);

export const getDimensionsArray = createSelector(
  (s: RootState) => s.dimensions.dimensions,
  dimensions => Object.values(dimensions)
);
