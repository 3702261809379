import { List, Typography } from '@mui/material';
import RefreshButton from 'components/buttons/RefreshButton';
import TextInputField from 'components/forms/TextInputField';
import { useGenerateSubjectLines } from 'features/subjectLines/hooks/useGenerateSubjectLines';
import { useRewriteSubjectLine } from 'features/subjectLines/hooks/useRewriteSubjectLine';
import { FieldArray, useFormikContext } from 'formik';
import { useCallback, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import gtmIds from 'services/tracking/GTMIds';
import styled from 'styled-components';
import useRunInTask from 'utils/hooks/useRunInTask';

import { Device, ScoredSubjectLine } from '../../store/types';
import { compareOpenRates } from '../../store/utils';
import {
  CampaignConfigFormPayload,
  emailBodyField,
  keywordsField,
  projectIdField,
  subjectLinesField,
  suggestionsField
} from '../CampaignConfigForm';
import SubjectLinesSuggestionsLoader from './SubjectLinesSuggestionsLoader';
import Suggestion from './Suggestion';

type Props = {
  selectedDevice: Device;
};

const SubjectLinesSuggestions = ({ selectedDevice }: Props) => {
  const { values, setFieldValue } = useFormikContext<CampaignConfigFormPayload>();
  const [isLoading, runInTask] = useRunInTask();

  const generateSubjectLines = useGenerateSubjectLines({ audienceId: values[projectIdField] });
  const rewriteSubjectLine = useRewriteSubjectLine({ audienceId: values[projectIdField] });

  const sortAndSetSuggestions = useCallback(
    (suggestions: ScoredSubjectLine[]) =>
      setFieldValue(suggestionsField, [...suggestions].sort(compareOpenRates)),
    [setFieldValue]
  );

  const generateInitialSuggestions = useCallback(async () => {
    const suggestions = await runInTask(() =>
      generateSubjectLines({
        emailBody: values[emailBodyField],
        keywords: values[keywordsField]
      })
    );
    setFieldValue(suggestionsField, [...suggestions].sort(compareOpenRates));
  }, [values, generateSubjectLines, setFieldValue, runInTask]);

  const refreshSuggestions = useCallback(async () => {
    const suggestions = await runInTask(() =>
      generateSubjectLines({
        emailBody: values[emailBodyField],
        keywords: values[keywordsField],
        suggestions: values[subjectLinesField].map(({ subjectLine }) => subjectLine).slice(0, 1)
      })
    );

    setFieldValue(suggestionsField, [...suggestions].sort(compareOpenRates));
  }, [values, generateSubjectLines, setFieldValue, runInTask]);

  const handleRewrite = async (existingSubjectLine: string) => {
    const rewritedSubjectLines = await rewriteSubjectLine({ existingSubjectLine });

    const newSubjectLines: ScoredSubjectLine[] = rewritedSubjectLines.map(s => ({
      ...s,
      userGenerated: false
    }));

    sortAndSetSuggestions([...values[suggestionsField], ...newSubjectLines]);
  };

  const selectSubjectLine = useCallback(
    (index: number, remove: (index: number) => ScoredSubjectLine | undefined) => {
      const selectedSubjectLine = values.suggestions[index];
      const sortedSubjectLines = [...values.subjectLines, selectedSubjectLine].sort(
        compareOpenRates
      );
      setFieldValue(subjectLinesField, sortedSubjectLines);
      remove(index);
    },
    [values.subjectLines, values.suggestions, setFieldValue]
  );

  useEffect(() => {
    if (!values[suggestionsField].length) {
      generateInitialSuggestions();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { refreshSuggestions: refreshSuggestionsId } = gtmIds.subjectLines;

  return (
    <>
      <FieldArray name={suggestionsField}>
        {({ remove }) => (
          <SubjectLineSuggestionsList>
            {!isLoading ? (
              values.suggestions.map((suggestion, index) => (
                <Suggestion
                  key={suggestion.id}
                  value={suggestion}
                  index={index}
                  device={selectedDevice}
                  onRewrite={() => handleRewrite(suggestion.subjectLine)}
                  onSelect={() => selectSubjectLine(index, remove)}
                  onRemove={() => remove(index)}
                />
              ))
            ) : (
              <SubjectLinesSuggestionsLoader isLoading={isLoading} />
            )}
          </SubjectLineSuggestionsList>
        )}
      </FieldArray>
      <KeywordsContainer>
        <Typography variant="subtitle1">
          <FormattedMessage id="subject_lines.new_campaign.keywords" />
        </Typography>
        <TextInputField name={keywordsField} fullWidth multiline />
        <RefreshButton
          id={refreshSuggestionsId}
          onClick={refreshSuggestions}
          isLoading={isLoading}
        />
      </KeywordsContainer>
    </>
  );
};

const SubjectLineSuggestionsList = styled(List)`
  max-height: 420px;
  overflow: auto;
`;

const KeywordsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  gap: 1rem;
  padding: 1.5rem 0 0;
`;

export default SubjectLinesSuggestions;
