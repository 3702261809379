import { makeCurrentUserQueryKeyPrefix } from 'features/currentUser/currentUserQueries';
import { RunnerModularWorkflow } from 'features/modular-workflow/runner/types-runner';
import { BaseSuccessDto } from 'services/backofficeIntegration/http/BaseSuccessDto';
import { backofficeClient } from 'services/backofficeIntegration/http/client/createBackofficeClient';
import { queryEndpoint } from 'services/backofficeIntegration/http/endpointDefinition';
import { queryClient } from 'utils/ReactQueryClient';

type SuccessDto = BaseSuccessDto<RunnerModularWorkflow>;

export const httpGetModularWorkflowRunData = queryEndpoint({
  makeQueryKey: (id: number) => {
    return [...makeCurrentUserQueryKeyPrefix(), 'modular-workflow', id] as const;
  },
  callEndpoint: (id: number) =>
    backofficeClient
      .get<SuccessDto>(`/workflows/${id}`, {
        params: {
          view_mode: 'run'
        }
      })
      .then(response => response.data.data)
});

export const invalidateModularWorkflowRunData = () =>
  queryClient.invalidateQueries({
    queryKey: [...makeCurrentUserQueryKeyPrefix(), 'modular-workflow']
  });
