import { Checkbox, FormControlLabel } from '@mui/material';
import { CheckboxProps } from '@mui/material/Checkbox/Checkbox';
import { useField } from 'formik';
import { ReactNode } from 'react';
import styled from 'styled-components';

type Props = {
  name: string;
  label?: ReactNode;
} & CheckboxProps;

export const BoolCheckboxField = ({ name, label, color = 'primary', ...rest }: Props) => {
  const [field] = useField({ name, type: 'checkbox' });

  return (
    <StyledFormControl
      control={<Checkbox {...rest} {...field} color={color} size="small" />}
      label={label}
    />
  );
};

const StyledFormControl = styled(FormControlLabel)`
  margin-top: 0.5rem;
  margin-bottom: -0.5rem;
`;
