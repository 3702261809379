import AxiosClient from '..';
import { GetHistoryResponse } from './types';

const HistoryAPI = {
  getByProjectId: (id: string) =>
    AxiosClient.get<unknown, GetHistoryResponse>('/history', {
      params: { project_id: id }
    })
};

export default HistoryAPI;
