import { makeCurrentUserQueryKeyPrefix } from 'features/currentUser/currentUserQueries';
import { BaseSuccessDto } from 'services/backofficeIntegration/http/BaseSuccessDto';
import { backofficeClient } from 'services/backofficeIntegration/http/client/createBackofficeClient';
import { LimitationsDto } from 'services/backofficeIntegration/http/dtos/LimitationsDto';
import { queryEndpoint } from 'services/backofficeIntegration/http/endpointDefinition';

type SuccessDto = BaseSuccessDto<LimitationsDto>;

export const httpGetPlagiarismCheckLimitations = queryEndpoint({
  makeQueryKey: () => {
    return [...makeCurrentUserQueryKeyPrefix(), 'plagiarism-check-limitations'] as const;
  },
  callEndpoint: () => {
    return backofficeClient.get<SuccessDto>('/plagiarism-check/limitations').then(response => {
      return response.data.data;
    });
  }
});
