import { TesterTab } from '../../types';
import { defaultWordAttributes } from './defaultWordAttributes';

const defaultTab: TesterTab = {
  id: '',
  mapperId: '',
  name: '',
  embeddingModelId: '',
  dimensions: [],
  manualDimensions: [],
  legend: {},
  texts: [],
  legendOrder: [],
  suggestions: [],
  wordAttributes: defaultWordAttributes,
  generateTextConfig: {
    audienceId: 0,
    outputType: 'headline',
    personalityId: 0
  },
  updatedAt: '',
  scoreByOpenRate: true,
  sortByScore: true,
  isSuggestionsLoading: false,
  isSaving: false
};

export default defaultTab;
