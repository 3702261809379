import { SuggestionType } from 'features/aiWriter/AiWriterSidebar/steps/flashScore/GetSuggestionsButton';
import { FlashScoreWordRating } from 'features/flashScore/useTokenizeAndScoreProvidedText';
import { create } from 'zustand';

type ScoringFetchState = 'updated' | 'fetching' | 'disabled';

type FlashScoreSidebarState = {
  autoFetchState: ScoringFetchState;
  wordRating: FlashScoreWordRating[];
  showEmotionalityWordHighlight: boolean;
  selectedSuggestionType: SuggestionType | null;
  lastGeneratedSuggestion: string | null;
  showScoreLabel: boolean;
};

type FlashScoreSidebarActions = {
  setAutoFetchState: (autoFetchState: ScoringFetchState) => void;
  setWordRating: (wordRating: FlashScoreWordRating[]) => void;
  setShowEmotionalityWordHighlight: (showEmotionalityWordHighlight: boolean) => void;
  setSelectedSuggestionType: (selectedSuggestionType: SuggestionType | null) => void;
  setLastGeneratedSuggestion: (lastGeneratedSuggestion: string | null) => void;
  toggleShowScoreLabel: () => void;
  reset: () => void;
};

const initialState: FlashScoreSidebarState = {
  autoFetchState: 'updated',
  wordRating: [],
  showEmotionalityWordHighlight: true,
  selectedSuggestionType: null,
  lastGeneratedSuggestion: null,
  showScoreLabel: true
};

export const useFlashScoreSidebarState = create<FlashScoreSidebarState & FlashScoreSidebarActions>(
  set => ({
    ...initialState,
    setAutoFetchState: autoFetchState => set({ autoFetchState }),
    setWordRating: wordRating => set({ wordRating }),
    setShowEmotionalityWordHighlight: showEmotionalityWordHighlight =>
      set({ showEmotionalityWordHighlight }),
    setSelectedSuggestionType: selectedSuggestionType => set({ selectedSuggestionType }),
    setLastGeneratedSuggestion: lastGeneratedSuggestion => set({ lastGeneratedSuggestion }),
    toggleShowScoreLabel: () => set(state => ({ showScoreLabel: !state.showScoreLabel })),
    reset: () => set(initialState)
  })
);
