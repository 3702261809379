import styled from 'styled-components';

type Props = {
  isSelected?: boolean;
  disabled?: boolean;
  isActive?: boolean;
};

const Item = styled.li<Props>`
  font-size: 0.9375rem;
  line-height: 1.6;
  padding: 0.25rem 1rem;
  font-weight: ${({ isSelected }) => (isSelected ? 700 : 400)};
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  background: ${({ isActive }) => (isActive ? '#e9ecef' : 'none')};
  color: ${({ isActive }) => (isActive ? '#16181b' : '#000')};
`;

export default Item;
