import { Button } from '@mui/material';
import FlexContainer from 'components/FlexContainer';
import PasswordField from 'components/forms/customFields/PasswordField';
import Toast from 'components/toasts/Toast';
import { getRoutePath } from 'config/routes';
import { passwordRecoverySuccess } from 'features/customer/store/actions';
import { Form, Formik, FormikHelpers } from 'formik';
import { useCallback, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router';
import CustomerAPI from 'services/api/customer';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import styled from 'styled-components';
import { getValidationForField } from 'utils/getValidationForField';
import useTr from 'utils/hooks/useTr';
import useURLQuery from 'utils/hooks/useURLQuery';
import { passwordRules } from 'utils/validationRules';
import { object, ref, string } from 'yup';

const passwordField = 'password';
const confirmPasswordField = 'confirmPassword';

type ResetPasswordValues = {
  [passwordField]: string;
  [confirmPasswordField]: string;
};

const initialValues: ResetPasswordValues = {
  [passwordField]: '',
  [confirmPasswordField]: ''
};

export const ResetPasswordForm = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const tr = useTr();

  const isLoading = useAppSelector(state => state.customer.isLoading);
  const goToLoginPage = useCallback(() => navigate(getRoutePath('login')), [navigate]);

  const { token } = useURLQuery();

  const onSubmit = useCallback(
    async (
      { password }: ResetPasswordValues,
      { setSubmitting, setErrors }: FormikHelpers<ResetPasswordValues>
    ) => {
      if (token) {
        const response = await CustomerAPI.changePassword(password, token);

        if (response.status) {
          dispatch(passwordRecoverySuccess(response.message));
          Toast.success('profile.success');
          goToLoginPage();
        } else {
          if (response.message) {
            Toast.error(`customer.login.error.${response.message}`);
          }
          setSubmitting(false);
          setErrors({ [passwordField]: response.message });
        }
      } else {
        Toast.commonError();
      }
    },
    [dispatch, goToLoginPage, token]
  );

  const validationSchema = useMemo(
    () =>
      object().shape({
        [passwordField]: passwordRules(tr),
        [confirmPasswordField]: string().oneOf(
          [ref(passwordField), null],
          tr(getValidationForField('confirm_password'))
        )
      }),
    [tr]
  );

  return (
    <Formik
      validateOnChange={false}
      validateOnBlur={true}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {() => (
        <Form>
          <Content gap="medium" alignItems="center">
            <PasswordField name={passwordField} />
            <PasswordField name={confirmPasswordField} label="reset_password.confirm_password" />
            <Button type="submit" variant="contained" color="primary" disabled={isLoading}>
              <FormattedMessage id="reset_password.confirm_button" />
            </Button>
          </Content>
        </Form>
      )}
    </Formik>
  );
};

const Content = styled(FlexContainer)``;
