import { Button, CircularProgress, Typography } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import EmailField from 'components/forms/customFields/EmailField';
import { LogoWithSlogan } from 'components/LogoWithSlogan';
import { getRoutePath } from 'config/routes';
import { resendVerificationEmail } from 'features/authentication/resendVerificationEmail';
import { Form, Formik } from 'formik';
import { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { getValidationForField } from 'utils/getValidationForField';
import useTr from 'utils/hooks/useTr';
import { object, string } from 'yup';

const emailField = 'email';

type FormValues = {
  [emailField]: string;
};

const initialValues: FormValues = {
  [emailField]: ''
};

const Root = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  width: min(
    ${({ theme }) => theme.container.sm},
    100% - 2 * ${({ theme }) => theme.spacings.medium}
  );
  margin-inline: auto;
`;

const LogoBox = styled.div`
  justify-self: center;
  margin-top: ${({ theme }) => theme.spacings.xxxxlarge};
`;

const MessageBox = styled.div`
  margin-top: ${({ theme }) => theme.spacings.xxxlarge};
  margin-bottom: ${({ theme }) => theme.spacings.xxxxlarge};
`;

const Title = styled(Typography).attrs({ variant: 'h5', component: 'h2' })`
  margin-bottom: ${({ theme }) => theme.spacings.xmedium};
`;

const ButtonBox = styled.div`
  margin-top: ${({ theme }) => theme.spacings.medium};
`;

export function ErrorScreen() {
  const tr = useTr();

  const resendMutation = useMutation({
    mutationFn: resendVerificationEmail
  });

  const validationSchema = useMemo(
    () =>
      object().shape({
        [emailField]: string()
          .trim()
          .email()
          .required(tr(getValidationForField('email')))
      }),
    [tr]
  );

  return (
    <Root>
      <LogoBox>
        <LogoWithSlogan sloganKey="login.slogan" align="center" />
      </LogoBox>
      <MessageBox>
        <Title>
          <FormattedMessage id="verify_account.error.title" />
        </Title>
        <Typography variant="body1">
          <FormattedMessage id="verify_account.error.explanation" values={{ br: <br /> }} />
        </Typography>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={({ [emailField]: mail }) => {
            resendMutation.mutate(mail);
          }}
        >
          <Form>
            <EmailField
              name={emailField}
              variant="outlined"
              fullWidth
              css={css`
                margin-top: ${({ theme }) => theme.spacings.medium};
              `}
            />
            <ButtonBox>
              <Button component={Link} to={getRoutePath('login')} variant="text">
                <FormattedMessage id="common.login" />
              </Button>
              <Button variant="contained" type="submit" disabled={resendMutation.isLoading}>
                <FormattedMessage id="verify_account.error.resend" />{' '}
                {resendMutation.isLoading && (
                  <CircularProgress
                    size={16}
                    css={css`
                      margin-left: ${({ theme }) => theme.spacings.small};
                    `}
                  />
                )}
              </Button>
            </ButtonBox>
          </Form>
        </Formik>
      </MessageBox>
    </Root>
  );
}
