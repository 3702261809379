import { useCallback, useState } from 'react';

const useDialog = (initialValue = false): [boolean, () => void, () => void] => {
  const [isDialogOpen, setDialogOpen] = useState(initialValue);

  const openDialog = useCallback(() => setDialogOpen(true), [setDialogOpen]);
  const closeDialog = useCallback(() => setDialogOpen(false), [setDialogOpen]);

  return [isDialogOpen, openDialog, closeDialog];
};

export default useDialog;
