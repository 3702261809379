import { Button } from '@mui/material';
import FlexContainer from 'components/FlexContainer';
import Toast from 'components/toasts/Toast';
import FormattedMessage from 'features/i18n/FormattedMessage';
import { ModularWorkflowThumbnailBox } from 'features/modular-workflow/builder/ModularWorkflowThumbnailBox';
import { ChangeEvent } from 'react';
import styled from 'styled-components';

const maxFileSizeInMegaBytes = 5;
const maxFileSizeInBytes = maxFileSizeInMegaBytes * 1024 * 1024;

export type ImageResult = FileReader['result'];

type Props = {
  value?: string | null;
  onThumbnailChange?: (thumbnail: ImageResult) => void;
};

export const ModularWorkflowThumbnailUploadField = ({ value, onThumbnailChange }: Props) => {
  const handleImageChange = ({ target: { files } }: ChangeEvent<HTMLInputElement>) => {
    const uploadedFile = files?.[0];
    if (!uploadedFile) {
      return;
    }

    if (uploadedFile.size > maxFileSizeInBytes) {
      Toast.error('profile.upload_image.size_error', { fileSizeLimitInMB: maxFileSizeInMegaBytes });
      return;
    }

    const reader = new FileReader();
    reader.readAsDataURL(uploadedFile);
    reader.onload = () => onThumbnailChange?.(reader.result);
  };

  return (
    <FlexContainer direction="row" alignItems="center" justifyContent="space-between" gap="two">
      <ModularWorkflowThumbnailBox thumbnail={value} />

      <Button role={undefined} component="label" size="large" variant="outlined" tabIndex={-1}>
        <FormattedMessage id="common.browse" />

        <VisuallyHiddenInput
          type="file"
          accept="image/png, image/jpeg, image/png, image/jpg"
          onChange={handleImageChange}
        />
      </Button>
    </FlexContainer>
  );
};

const VisuallyHiddenInput = styled.input`
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  bottom: 0;
  left: 0;
  white-space: nowrap;
  width: 1px;
`;
