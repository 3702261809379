import { create } from 'zustand';

type RequestIdHeaderStoreState = {
  requestIdHeader: string | null;
};

type RequestIdHeaderStoreActions = {
  setRequestIdHeader: (requestIdHeader: string) => void;
};

const initialState: RequestIdHeaderStoreState = {
  requestIdHeader: null
};

export const useRequestIdHeaderStore = create<
  RequestIdHeaderStoreState & RequestIdHeaderStoreActions
>(set => ({
  ...initialState,
  setRequestIdHeader: requestIdHeader => set({ requestIdHeader })
}));

export const retrieveRequestIdHeader = () => {
  const requestId = useRequestIdHeaderStore.getState().requestIdHeader;
  return requestId;
};

export const setRequestIdHeader = (requestIdHeader: string) => {
  useRequestIdHeaderStore.getState().setRequestIdHeader(requestIdHeader);
};
