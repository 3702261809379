import { useAppDispatch, useAppSelector } from 'store/hooks';
import styled from 'styled-components';

import ComboBox from '../../../components/Autocomplete/ComboBox';
import Checkbox from '../../../components/base/Checkbox';
import FormGroup from '../../../components/base/FormGroup';
import Dropdown from '../../../components/Dropdown/Dropdown';
import useTr from '../../../utils/hooks/useTr';
import { getActiveTab, getCurrentTabModelData, getParametersList } from '../store/selectors';
import { setGraphColoring, toggleTabProperty } from '../store/slice';
import { ColoringParameter } from '../store/types';

const DropdownContent = styled.div`
  padding: 0.25rem 0.75rem;
  width: 15rem;
`;

const GraphSettingsDropdown = () => {
  const tr = useTr();
  const dispatch = useAppDispatch();
  const parametersList = useAppSelector(getParametersList);
  const { has_translation: canBeTranslated } = useAppSelector(getCurrentTabModelData);
  const { graphColoring, graphI16sEnabled, graphTranslationEnabled, graphZoomEnabled } =
    useAppSelector(getActiveTab);

  return (
    <Dropdown
      icon="settings"
      type="icon"
      items={
        <DropdownContent>
          <FormGroup label="exploring.bubble_chart.bubble_coloring">
            <ComboBox
              data={parametersList}
              initialSelectedItem={parametersList.find(item => item.value === graphColoring)}
              itemId="value"
              itemToString={(item: ColoringParameter | null) => {
                if (item) {
                  return item.needsTranslation ? tr(item.label) : item.label;
                }
                return '';
              }}
              onChange={param => param && dispatch(setGraphColoring(param.value))}
            />
          </FormGroup>
          <Checkbox
            label="exploring.bubble_chart.show_connections"
            checked={graphI16sEnabled}
            onChange={() => dispatch(toggleTabProperty('graphI16sEnabled'))}
          />
          <Checkbox
            label="exploring.bubble_chart.zoom_and_pan"
            checked={graphZoomEnabled}
            onChange={() => dispatch(toggleTabProperty('graphZoomEnabled'))}
          />
          {canBeTranslated && (
            <Checkbox
              label="exploring.bubble_chart.translate_to_english"
              checked={graphTranslationEnabled}
              onChange={() => dispatch(toggleTabProperty('graphTranslationEnabled'))}
            />
          )}
        </DropdownContent>
      }
    />
  );
};

export default GraphSettingsDropdown;
