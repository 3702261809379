import { AppThunk } from 'store/store';

import { updateTesterProjectInBackgroundThunk } from '../../project/thunks/updateTesterProjectInBackgroundThunk';
import { renameCurrentTab } from '../actions';

export const renameCurrentTabThunk =
  (payload: string): AppThunk<void> =>
  async dispatch => {
    dispatch(renameCurrentTab(payload));
    dispatch(updateTesterProjectInBackgroundThunk());
  };
