import { Button, CircularProgress } from '@mui/material';
import Dimmer from 'components/base/Dimmer';
import FlexContainer from 'components/FlexContainer';
import { BoolCheckboxField } from 'components/forms/BoolCheckboxField';
import NameField from 'components/forms/customFields/NameField';
import PasswordField from 'components/forms/customFields/PasswordField';
import TextInputField from 'components/forms/TextInputField';
import { PrivacyPolicy } from 'components/PrivacyPolicy';
import FormattedMessage from 'features/i18n/FormattedMessage';
import { Form, Formik, FormikHelpers } from 'formik';
import { useCallback, useMemo } from 'react';
import styled from 'styled-components';
import { getValidationForField } from 'utils/getValidationForField';
import useTr from 'utils/hooks/useTr';
import { passwordRules } from 'utils/validationRules';
import { object, string } from 'yup';

const nameField = 'name';
const passwordField = 'password';
const newsletterSubscribedField = 'newsletterSubscribed';

export type RegisterFormValues = {
  [nameField]: string;
  [passwordField]: string;
  [newsletterSubscribedField]: boolean;
};

const initialValues: RegisterFormValues = {
  [nameField]: '',
  [passwordField]: '',
  [newsletterSubscribedField]: false
};

type RegisterFormProps = {
  email: string;
  onSubmit: (values: RegisterFormValues, helpers: FormikHelpers<RegisterFormValues>) => void;
};

export const NewUserRegisterForm = ({ email, onSubmit }: RegisterFormProps) => {
  const tr = useTr();

  const formValidation = useMemo(
    () =>
      object().shape({
        [nameField]: string()
          .trim()
          .required(tr(getValidationForField('name')))
          .max(255),
        [passwordField]: passwordRules(tr)
      }),
    [tr]
  );

  const handleAccountSubmit = useCallback(
    async (values: RegisterFormValues, helpers: FormikHelpers<RegisterFormValues>) =>
      onSubmit(values, helpers),
    [onSubmit]
  );

  return (
    <Formik
      initialValues={initialValues}
      validateOnBlur
      key="account"
      validationSchema={formValidation}
      onSubmit={handleAccountSubmit}
    >
      {({ isSubmitting, isValid }) => (
        <Form id="register-form">
          <Loader active={isSubmitting} loader={true}>
            <FormContent>
              <TextInputField
                name="email"
                variant="outlined"
                fullWidth={true}
                placeholder={tr('common.form.field.email.label')}
                label={tr('common.form.field.email.label')}
                value={email}
                disabled={true}
              />
              <NameField name={nameField} />
              <PasswordField name={passwordField} />
              <BoolCheckboxField
                name={newsletterSubscribedField}
                label={tr('register.mail_subscription')}
              />
              <PrivacyPolicy />
              <Actions>
                <Button
                  variant="contained"
                  color="primary"
                  id="next-button"
                  disabled={!isValid || isSubmitting}
                  startIcon={isSubmitting ? <CircularProgress size={16} /> : undefined}
                  type="submit"
                  fullWidth={true}
                >
                  <FormattedMessage id="team.invite.accept_invitation" />
                </Button>
              </Actions>
            </FormContent>
          </Loader>
        </Form>
      )}
    </Formik>
  );
};

const Actions = styled.div`
  display: flex;
  justify-content: center;
`;

const Loader = styled(Dimmer)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const FormContent = styled(FlexContainer).attrs({ gap: 'medium' })``;
