import { useSetDefaultChatPersonality } from 'features/aiWriter/AiWriterSidebar/steps/chat/useSetDefaultPersonality';
import { changeGenerateTextConfig } from 'features/aiWriter/store/actions/config/actions';
import { updateCurrentProjectInBackgroundThunk } from 'features/aiWriter/store/actions/project/thunks/updateCurrentProjectInBackground';
import { PersonalityDto } from 'services/backofficeIntegration/http/dtos/PersonalityDto';
import { useAppDispatch } from 'store/hooks';

type UpdateOptions = {
  personality: PersonalityDto | undefined | null;
  callback?: (personality?: PersonalityDto) => void;
};

export function useUpdateAiWriterAndDefaultPersonality() {
  const { mutate: setDefaultPersonality } = useSetDefaultChatPersonality();

  const dispatch = useAppDispatch();

  return (props: UpdateOptions) => {
    const { personality, callback } = props;

    if (personality) {
      setDefaultPersonality({
        personalityId: personality.id,
        country: personality.country,
        language: personality.language
      });
    }

    dispatch(
      changeGenerateTextConfig({
        personalityId: personality?.id ?? null
      })
    );

    dispatch(updateCurrentProjectInBackgroundThunk());

    callback?.();
  };
}
