import { getRoutePath } from 'config/routes';
import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { promisifySagaThunk } from 'utils/utils';

import CampaignConfigForm, {
  CampaignConfigFormPayload,
  deletedSuggestionsField,
  emailBodyField,
  keywordsField,
  nameField,
  projectIdField,
  subjectLinesField,
  suggestionsField
} from './CampaignConfigForm/CampaignConfigForm';
import { getCampaignById } from './store/selectors';
import { updateCampaign } from './store/slice';
import { Campaign, CampaignSubjectLine, ScoredSubjectLine } from './store/types';

const EditCampaignForm = (): React.ReactElement | null => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const editedCampaign = useAppSelector(getCampaignById)(parseInt(id ?? '', 10));

  useEffect(() => {
    if (!editedCampaign) {
      navigate(getRoutePath('subjectLines'));
    }
  }, [editedCampaign, navigate]);

  const handleSubmit = async (values: CampaignConfigFormPayload) => {
    // eslint-disable-next-line
    const subjectLinesRatings = prepareSubjectLinesRatings(
      values[subjectLinesField],
      editedCampaign?.subjectLinesRatings ?? []
    );

    if (editedCampaign) {
      const campaign: Campaign = {
        ...editedCampaign,
        ...values,
        subjectLinesRatings,
        status: values.subjectLines.length > 0 ? 'pending' : 'open'
      };
      await dispatch(promisifySagaThunk(updateCampaign.request, campaign));
    }
  };

  if (editedCampaign) {
    return (
      <CampaignConfigForm
        // eslint-disable-next-line
        initialValues={getEditCampaignInitialValues(editedCampaign)}
        handleSubmit={handleSubmit}
      />
    );
  }

  return null;
};

const prepareSubjectLinesRatings = (
  newSubjectLines: ScoredSubjectLine[],
  prevSubjectLines: CampaignSubjectLine[]
): CampaignSubjectLine[] => {
  return newSubjectLines.map(newSubjectLine => ({
    ...newSubjectLine,
    ...(prevSubjectLines.find(prevSubjectLine => prevSubjectLine.id === newSubjectLine.id) ?? {})
  }));
};

const getEditCampaignInitialValues = (campaign: Campaign): CampaignConfigFormPayload => ({
  [nameField]: campaign.name,
  [projectIdField]: campaign.projectId,
  [emailBodyField]: campaign.emailBody ?? '',
  [keywordsField]: campaign.keywords ?? '',
  [suggestionsField]: [],
  [deletedSuggestionsField]: [],
  [subjectLinesField]: campaign.subjectLinesRatings.map(
    ({ id, subjectLine, userGenerated, predictedOpenRate }) => ({
      id,
      subjectLine,
      userGenerated,
      predictedOpenRate
    })
  )
});

export default EditCampaignForm;
