import LocalStorageKey from 'config/localStorageKey';

const getSavedColor = (field: string | number) => {
  try {
    const savedColors = localStorage.getItem(LocalStorageKey.TesterColors);
    if (savedColors) {
      const parsedColors = JSON.parse(savedColors);
      return parsedColors[field] || null;
    }
    return null;
  } catch (e) {
    return null;
  }
};

export default getSavedColor;
