import makeStyles from '@mui/styles/makeStyles';
import TextInputFieldBase from 'components/forms/TextInputField';
import { FieldValidator } from 'formik';
import { ComponentProps, KeyboardEvent } from 'react';
import theme from 'styles/styledComponents/theme';

type TextInputFieldProps = {
  name: string;
  placeholder?: string;
  multiline?: boolean;
  disabled?: boolean;
  autoComplete?: ComponentProps<typeof TextInputFieldBase>['aria-autocomplete'];
  validate?: FieldValidator;
  onKeyDown?: (event: KeyboardEvent) => void;
};

export const TextInputField = ({
  placeholder,
  name,
  multiline = true,
  disabled = false,
  autoComplete,
  validate,
  onKeyDown
}: TextInputFieldProps) => {
  const classes = useStyles();

  return (
    <TextInputFieldBase
      autoFocus={true}
      fullWidth={true}
      multiline={multiline}
      disabled={disabled}
      name={name}
      variant="outlined"
      size="small"
      placeholder={placeholder}
      autoComplete={autoComplete}
      aria-autocomplete={autoComplete}
      InputProps={{
        disabled: disabled,
        classes: {
          notchedOutline: classes.notched,
          input: classes.input,
          root: classes.root
        }
      }}
      inputProps={{
        'aria-label': placeholder,
        'aria-disabled': disabled,
        disabled
      }}
      validate={validate}
      onKeyDown={onKeyDown}
    />
  );
};

const useStyles = makeStyles({
  root: {
    paddingLeft: 0
  },
  notched: {
    border: 'none'
  },
  input: {
    padding: '4px 15px 4px 15px',
    fontWeight: 300,
    borderRadius: theme.borderRadius.medium,
    fontSize: theme.fontSizes.body1,
    lineHeight: theme.fontSizes.h1
  }
});
