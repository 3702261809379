import { DeleteRounded } from '@mui/icons-material';
import IconButton from '@mui/material/IconButton';

type Props = {
  onDelete: () => void;
};

export const StepDeleteIcon = ({ onDelete }: Props) => (
  <IconButton size="small" title="Delete step" onClick={onDelete} color="error">
    <DeleteRounded />
  </IconButton>
);
