import { IFRAME_ID, routeIframe } from 'features/iframeFeatures/useIframeMessageListener';
import { useEffect, useState } from 'react';
import styled from 'styled-components';

const IFRAME_URL = 'http://localhost:3000';

// todo configure entry point for iframe
function getIframeRoute() {
  return '/brand-hub';
}

export const EmbedApp = () => {
  const [iframeRoute, setIframeRoute] = useState('/');

  const [url, setUrl] = useState<string | null>(null);

  useEffect(() => {
    const newIframeRoute = getIframeRoute();

    if (url === null && newIframeRoute) {
      setIframeRoute(newIframeRoute);
      setUrl(`${IFRAME_URL}${newIframeRoute}?isEmbedded=true`);
    }
  }, [url]);

  useEffect(() => {
    if (iframeRoute) {
      routeIframe(iframeRoute);
    }
  }, [iframeRoute]);

  if (!url) return null;

  const isActive = Boolean(iframeRoute);
  return (
    <StyledIframe id={IFRAME_ID} title="Embedded App" src={url} $isActive={isActive}></StyledIframe>
  );
};

const StyledIframe = styled.iframe<{ $isActive: boolean }>`
  border: none;
  width: ${({ $isActive }) => ($isActive ? '100%' : '1px')};
  height: ${({ $isActive }) => ($isActive ? '100%' : '1px')};
`;
