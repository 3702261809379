import { addTrackingScriptDelayedToToPage } from 'features/tracking/utils/addTrackingScriptToToPage';
import { useEffect } from 'react';
import adcellClient from 'services/adcell';
import isNotEmptyString from 'utils/isNotEmptyString';
import { parseUrlQuery } from 'utils/urlUtils';

// Wait some seconds to let the mandatory source load first
const scriptDelay = 3000;

type AdcellUrlQuery = {
  'adcell-bid'?: string;
};

const useStoreBidTrackingParameter = () => {
  useEffect(() => {
    const query = parseUrlQuery<AdcellUrlQuery>(window.location.search);
    const bid = query['adcell-bid'];
    if (isNotEmptyString(bid)) {
      adcellClient.saveBid(bid);
    }
  }, []);
};

const useEmbedTrackingScript = () => {
  useEffect(() => {
    addTrackingScriptDelayedToToPage('https://t.adcell.com/js/trad.js', scriptDelay, () => {
      window.Adcell?.Tracking.track();
    });
  }, []);
};

export const useAdcellTracking = () => {
  useStoreBidTrackingParameter();

  useEmbedTrackingScript();
};
