import { Button, MobileStepper } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { SpinnerDimmer } from 'components/base/SpinnerDimmer';
import { ModalWithDividedHeaderLayout } from 'features/theme-2024/ModalWithDividedHeaderLayout';
import { Form, Formik, FormikConfig, FormikHelpers, FormikValues } from 'formik';
import React, { useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { blue } from 'styles/styleUtils';

const useStyles = makeStyles({
  root: {
    width: '100%',
    padding: '1em'
  },
  dot: {
    width: '24px',
    height: '24px',
    margin: '0 10px',
    backgroundColor: '#E1F5FE'
  },
  dotActive: {
    backgroundColor: blue
  }
});

type Tracking = {
  saveButtonId?: string;
};

export type ModalStep = {
  title: string | React.ReactElement;
  description?: string;
  backButtonHidden?: boolean;
  okButton?: {
    text?: string;
  };
  cancelButton?: {
    hide?: boolean;
  };

  customButtons?: (setActiveStep: React.Dispatch<React.SetStateAction<number>>) => JSX.Element;
};

type Props<Values> = {
  steps: ModalStep[];
  initialStep?: number;
  isLoading?: boolean;
  hideFooter?: boolean;
  tracking?: Tracking;
  validateOnChange?: boolean;

  onCancel: () => void;
  stepComponent: (step: number) => React.ReactNode;
} & FormikConfig<Values>;

function StepperModal<Values extends FormikValues>({
  steps,
  initialStep,
  initialValues,
  isLoading = false,
  hideFooter,
  tracking,
  validateOnChange = true,

  onSubmit,
  onCancel,
  stepComponent,

  ...formikProps
}: Props<Values>) {
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(initialStep || 0);
  const [valuesSnapshot, setValuesSnapshot] = useState(initialValues);

  const totalSteps = steps.length;
  const { title, description, backButtonHidden, okButton, cancelButton, customButtons } =
    steps[activeStep];
  const isLastStep = activeStep === totalSteps - 1;

  const fullWidthActions = useMemo(
    () => !!(!onCancel || cancelButton?.hide),
    [cancelButton?.hide, onCancel]
  );

  const next = (values: Values) => {
    setValuesSnapshot(values);
    setActiveStep(prevActiveStep => Math.min(prevActiveStep + 1, totalSteps - 1));
  };

  const previous = (values: Values) => {
    setValuesSnapshot(values);
    setActiveStep(prevActiveStep => Math.max(prevActiveStep - 1, 0));
  };

  const handleSubmit = (values: Values, bag: FormikHelpers<Values>) => {
    if (isLastStep) {
      onSubmit(values, bag);
    } else {
      bag.setTouched({});
      next(values);
    }
  };

  return (
    <ModalWithDividedHeaderLayout
      title={typeof title === 'string' ? <FormattedMessage id={title} /> : title}
      description={description && <FormattedMessage id={description} />}
      closeModal={onCancel}
    >
      <Body>
        <SpinnerDimmer active={isLoading} loader>
          <Formik
            validateOnChange={validateOnChange}
            validateOnBlur
            onSubmit={handleSubmit}
            initialValues={valuesSnapshot}
            {...formikProps}
          >
            {formProps => (
              <Form>
                {stepComponent(activeStep)}
                {!hideFooter && (
                  <Footer>
                    {customButtons ? (
                      customButtons(setActiveStep)
                    ) : totalSteps > 1 ? (
                      <MobileStepper
                        variant="dots"
                        steps={totalSteps}
                        position="static"
                        classes={{ ...classes }}
                        activeStep={activeStep}
                        nextButton={
                          <Button type="submit" color="primary" variant="contained">
                            <FormattedMessage id={isLastStep ? 'common.done' : 'common.next'} />
                          </Button>
                        }
                        backButton={
                          <Button color="primary" onClick={() => previous(formProps.values)}>
                            {activeStep !== 0 && !backButtonHidden ? (
                              <FormattedMessage id="common.back" />
                            ) : null}
                          </Button>
                        }
                      />
                    ) : (
                      <Actions fullWidth={fullWidthActions}>
                        {!cancelButton?.hide && onCancel && (
                          <Button type="button" color="primary" onClick={() => onCancel()}>
                            <FormattedMessage id="common.cancel" />
                          </Button>
                        )}
                        <Button
                          id={tracking?.saveButtonId}
                          fullWidth={fullWidthActions}
                          type="submit"
                          color="primary"
                          variant="contained"
                        >
                          <FormattedMessage id={okButton?.text ?? 'common.save'} />
                        </Button>
                      </Actions>
                    )}
                  </Footer>
                )}
              </Form>
            )}
          </Formik>
        </SpinnerDimmer>
      </Body>
    </ModalWithDividedHeaderLayout>
  );
}

const Body = styled.div`
  width: 100%;
`;

const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const Actions = styled.div<{ fullWidth: boolean }>`
  display: flex;
  gap: ${({ theme }) => theme.spacings.medium};

  width: ${({ fullWidth }) => (fullWidth ? '100%' : 'auto')};
`;

export default StepperModal;
