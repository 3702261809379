import DoneIcon from '@mui/icons-material/Done';
import { Autocomplete, Chip, Popper, TextField } from '@mui/material';
import { ReactComponent as CircleUserIcon } from 'assets/icons/icon-user-circle.svg';
import FlexContainer from 'components/FlexContainer';
import { useGetTeamMembers } from 'features/profilePage/useGetTeamMembers';
import { SyntheticEvent, useState } from 'react';
import { TeamMemberDto } from 'services/backofficeIntegration/http/dtos/TeamMemberDto';
import styled from 'styled-components';
import useTr from 'utils/hooks/useTr';

type Props = {
  value: TeamMemberDto | undefined;
  setValue: (value: TeamMemberDto | undefined) => void;
};

export function TeamMemberFilter({ value, setValue }: Props) {
  const translate = useTr();
  const [inputValue, setInputValue] = useState('');

  const queryParams = { search: inputValue ?? '' };

  const { data, isLoading } = useGetTeamMembers(queryParams);

  const handleChange = (event: SyntheticEvent<Element, Event>, newValue: TeamMemberDto | null) => {
    if (newValue) {
      setValue(newValue);
    }
  };

  const handleClear = () => {
    setValue(undefined);
  };

  const members = data?.data ?? [];

  return (
    <FilterContainer>
      {value ? (
        <StyledChip
          label={value.name}
          icon={<DoneIcon />}
          onDelete={handleClear}
          color="primary"
          sx={{ color: 'white' }}
        />
      ) : (
        <Autocomplete
          sx={{ width: '8rem' }}
          value={value}
          inputValue={inputValue}
          options={members}
          onChange={handleChange}
          loading={isLoading}
          getOptionLabel={(option: TeamMemberDto) => option.name}
          onInputChange={(event, newInputValue) => {
            setInputValue(newInputValue);
          }}
          renderInput={params => (
            <TextField
              {...params}
              label={translate('aiWriter.project_overview.new_projects.created_by')}
              size="small"
            />
          )}
          PopperComponent={params => (
            // all other styling methods do not work for this component
            <Popper {...params} style={{ width: 240 }} placement="bottom-start" />
          )}
          renderOption={(props, value) => (
            <Option {...props}>
              {value.image_url ? <Avatar src={value.image_url} /> : <CircleUserIcon />}
              {truncateText(value.name, 18)}
            </Option>
          )}
        />
      )}
    </FilterContainer>
  );
}

export function truncateText(text: string, maxLength: number) {
  if (text && text.length > maxLength) {
    return text.slice(0, maxLength) + '...';
  }
  return text;
}

const FilterContainer = styled(FlexContainer).attrs({
  direction: 'row',
  alignItems: 'center'
})`
  height: 3rem;
`;

const StyledChip = styled(Chip)`
  color: ${({ theme }) => theme.colors.white};

  && .MuiChip-deleteIcon {
    color: ${({ theme }) => theme.colors.white};

    &:hover {
      color: ${({ theme }) => theme.colors.whiteMediumEmphasis};
    }
  }
`;

const Option = styled.li`
  display: flex;
  gap: ${({ theme }) => theme.spacings.small};
`;

const Avatar = styled.img`
  width: 20px;
  height: 20px;

  border-radius: 50%;
`;
