import FormattedMessage from 'features/i18n/FormattedMessage';
import { Discount } from 'features/pricing/types';
import countPricingWithDiscount from 'features/pricing/utils/countPricingWithDiscount';
import { useMemo } from 'react';
import { ACLPricing } from 'services/api/customer/types';
import useTr from 'utils/hooks/useTr';

const oneYearInDays = 365;

type Props = {
  pricing: ACLPricing;
  discount?: Discount;
};

export const FormattedPricing = ({ pricing, discount }: Props) => {
  const translate = useTr();

  const isYearlyPeriod = pricing.period >= oneYearInDays;
  const formattedPriceValue = useMemo(() => {
    const baseCost = countPricingWithDiscount({
      pricing: parseInt(pricing.cost, 10),
      amountOff: discount?.amountOff,
      percentOff: discount?.percentOff
    });

    if (baseCost === 0) {
      return baseCost.toFixed();
    }

    if (isYearlyPeriod) {
      return (baseCost / 12).toFixed();
    }

    return baseCost.toFixed();
  }, [isYearlyPeriod, pricing.cost, discount?.amountOff, discount?.percentOff]);

  const currencySymbol = translate(`currency.symbol.${pricing.currency}`);

  return (
    <FormattedMessage
      id={pricing.isFree ? 'pricing.plan.price.free' : 'pricing.plan.price'}
      values={{
        price: formattedPriceValue,
        currencySymbol
      }}
    />
  );
};
