import { makeCurrentUserQueryKeyPrefix } from 'features/currentUser/currentUserQueries';
import { BaseSuccessDto } from 'services/backofficeIntegration/http/BaseSuccessDto';
import { backofficeClient } from 'services/backofficeIntegration/http/client/createBackofficeClient';
import { SurveyAnswersDto } from 'services/backofficeIntegration/http/dtos/SurveyAnswersDto';
import { queryEndpoint } from 'services/backofficeIntegration/http/endpointDefinition';

type SuccessDto = BaseSuccessDto<SurveyAnswersDto>;

export const getSurveyAnswers = queryEndpoint({
  makeQueryKey: () => {
    return [...makeCurrentUserQueryKeyPrefix(), 'survey-answers'] as const;
  },
  callEndpoint: () => {
    return backofficeClient.get<SuccessDto>('/survey/answers').then(response => response.data.data);
  }
});
