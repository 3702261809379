import { Checkbox, Tooltip, Typography } from '@mui/material';
import { IntlMessageKeys } from 'features/i18n/IntlMessageKeys';
import styled, { css } from 'styled-components';
import useTr from 'utils/hooks/useTr';

export function TaskItem(props: {
  title: IntlMessageKeys;
  tooltipMessage: IntlMessageKeys;
  isCompleted: boolean;
  onClick: () => void;
}) {
  const { title, tooltipMessage, isCompleted, onClick } = props;

  const translate = useTr();

  const handleClick = () => {
    // I guess we don't want to allow clicking on completed tasks
    if (isCompleted) return;
    onClick();
  };

  const tooltip = <TooltipContainer>{translate(tooltipMessage)}</TooltipContainer>;

  return (
    <Task onClick={handleClick}>
      <CheckboxItem checked={isCompleted} />
      <Tooltip title={tooltip} enterDelay={500} enterNextDelay={500} placement="right-end">
        <TaskTitle $isCompleted={isCompleted}>{translate(title)}</TaskTitle>
      </Tooltip>
    </Task>
  );
}
const Task = styled.li`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

const CheckboxItem = styled(Checkbox)<{ $isCompleted: boolean }>`
  &.Mui-checked {
    color: ${({ theme }) => theme.colors.successMain};
  }
` as typeof Checkbox;
const TaskTitle = styled(Typography).attrs({ variant: 'body1' })<{ $isCompleted: boolean }>`
  color: ${props => props.$isCompleted && css`rgba(0, 0, 0, 0.38)`};
  text-decoration: ${props => props.$isCompleted && css`line-through`};
  cursor: ${props => (props.$isCompleted ? 'unset' : 'pointer')};

  :hover {
    color: rgba(0, 0, 0, 0.38);
    text-decoration: ${props => !props.$isCompleted && css`underline`};
  }
`;
const TooltipContainer = styled.div`
  display: flex;
  flex-direction: column;

  padding: 0.5rem;
  gap: 0.5rem;
`;
