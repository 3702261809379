import { createSelector } from '@reduxjs/toolkit';
import { getSidebarMenu } from 'config/routes';
import {
  getCustomerRole,
  getCustomerTeams,
  getGroupId,
  getIsApiCustomer,
  getRestrictions
} from 'features/customer/store/selectors';
import { PlanFeature } from 'features/features/store/types';
import { pricingPlansFeatures } from 'features/features/utils/pricingPlanFeatures';
import { getPricingPlan } from 'features/pricing/store/selectors';

export const getFeatures = createSelector(
  getPricingPlan,
  pricingPlan => pricingPlansFeatures[pricingPlan]
);

export const getGetIsFeatureAvailable = createSelector(
  getFeatures,
  features => (feature: PlanFeature) => features?.[feature] ?? true
);

export const getSideBarFeatures = createSelector(
  getGroupId,
  getRestrictions,
  getCustomerRole,
  getIsApiCustomer,
  getCustomerTeams,
  (groupId, restrictions, customerRole, isApiCustomer, customerTeams) =>
    getSidebarMenu(groupId, restrictions, customerRole, isApiCustomer, customerTeams)
);
