import { Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import cx from 'classnames';
import React, { ReactElement, ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

import Label from './Label';

// TODO divide into three files

type CommonProps = {
  className?: string;
  label?: string;
  rawLabel?: React.ReactNode;
  required?: boolean;
  children?: ReactNode;
};

type FormGroupProps = CommonProps & {
  horizontal?: boolean;
  gutter?: boolean;
};

type StackedFormGroupProps = CommonProps;

type HorizontalFormGroup = CommonProps & {
  gutter?: boolean;
};

const useStyles = makeStyles({
  root: {
    marginBottom: '1em'
  }
});

const HorizontalFormGroup = ({
  className,
  children,
  label,
  rawLabel,
  required,
  gutter
}: HorizontalFormGroup): ReactElement => {
  const classes = useStyles();

  return (
    <Grid container className={className} classes={{ ...classes }} spacing={2}>
      {label || rawLabel || gutter ? (
        <Grid item xs={3}>
          {(label || rawLabel) && (
            <Label required={required}>{label ? <FormattedMessage id={label} /> : rawLabel}</Label>
          )}
        </Grid>
      ) : null}
      <Grid item xs={9}>
        {children}
      </Grid>
    </Grid>
  );
};

const StackedFormGroup = ({
  className,
  children,
  label,
  rawLabel,
  required
}: StackedFormGroupProps): ReactElement => (
  <div className={cx('form-group', className)}>
    {label || rawLabel ? (
      <Label required={required}>{label ? <FormattedMessage id={label} /> : rawLabel}</Label>
    ) : null}
    {children}
  </div>
);

const FormGroup = ({ horizontal, children, ...props }: FormGroupProps): ReactElement => {
  if (horizontal) {
    return <HorizontalFormGroup {...props}>{children}</HorizontalFormGroup>;
  }

  return <StackedFormGroup {...props}>{children}</StackedFormGroup>;
};

export default FormGroup;
