import cx from 'classnames';
import React from 'react';
import styled from 'styled-components';

type Props = {
  id?: string;
  className?: string;
  active?: boolean;
  loader?: boolean;
  children: React.ReactNode;
  isOverflowSet?: boolean;
};

export const SpinnerDimmer = ({
  id,
  className,
  active,
  loader,
  children,
  isOverflowSet
}: Props) => {
  const classes = cx({ dimmer: true, active }, className);

  return (
    <div id={id} className={classes}>
      {loader && <div className="loader" />}
      <Content className="dimmer-content" $isOverflowSet={isOverflowSet}>
        {children}
      </Content>
    </div>
  );
};

const Content = styled.div<{ $isOverflowSet?: boolean }>`
  overflow: ${({ $isOverflowSet }) => ($isOverflowSet ? 'hidden' : 'unset')};
`;
