import { useEffect, useLayoutEffect, useRef } from 'react';

export function useTimeout(callback: () => void, delay: number | null) {
  const savedCallback = useRef(callback);

  // Remember the latest callback if it changes
  useLayoutEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    // Don't schedule if no delay is specified
    // Note: 0 is a valid value for delay
    if (!delay && delay !== 0) {
      return;
    }

    const timerId = setTimeout(() => savedCallback.current(), delay);

    return () => clearTimeout(timerId);
  }, [delay]);
}
