import { forwardRef, HTMLAttributes, ReactNode } from 'react';
import styled, { DefaultTheme } from 'styled-components';

type Size = keyof DefaultTheme['container'];

export type Props = HTMLAttributes<HTMLDivElement> & {
  size: Size;
  children: ReactNode;
};

export const Container = forwardRef<HTMLDivElement, Props>(({ size, children, ...rest }, ref) => (
  <Root ref={ref} $size={size} {...rest}>
    {children}
  </Root>
));

const Root = styled.div<{
  $size: Size;
}>`
  max-width: ${({ theme, $size }) => theme.container[$size]};

  margin-left: auto;
  margin-right: auto;
`;
