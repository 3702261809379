import { BaseSuccessDto } from 'services/backofficeIntegration/http/BaseSuccessDto';
import { backofficeClient } from 'services/backofficeIntegration/http/client/createBackofficeClient';
import { mutationEndpoint } from 'services/backofficeIntegration/http/endpointDefinition';

type ApiTokenResultDto = {
  api_customer_token: string;
};

type SuccessDto = BaseSuccessDto<ApiTokenResultDto>;

export const httpRegenerateApiCustomerToken = mutationEndpoint({
  callEndpoint: () => {
    return backofficeClient
      .post<SuccessDto>('/auth/api-tokens/regenerate')
      .then(res => res.data.data);
  }
});
