import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { ColoredTypography } from 'components/ColoredTypography';
import FlexContainer from 'components/FlexContainer';
import { MenuDropdown } from 'components/MenuDropdown/MenuDropdown';
import LocalStorageKey from 'config/localStorageKey';
import FormattedMessage from 'features/i18n/FormattedMessage';
import { trackingWrapper } from 'features/tracking/wrapper/TrackingWrapper';
import { ReactElement, useState } from 'react';
import { GAEvents } from 'services/tracking/GAEvents';
import styled from 'styled-components';
import { resetButton } from 'styles/styledComponents/resets';
import useDropdown from 'utils/hooks/useDropdown';

import { useEditorCharactersCount } from '../hooks/useEditorCharactersCount';
import { useEditorWordsCount } from '../hooks/useEditorWordsCount';
import { StatisticsButton } from './StatisticsButton';

type Option = 'words' | 'characters';
const options: Option[] = ['words', 'characters'];

const EditorStatistics = (): ReactElement | null => {
  const [option, setOption] = useState<Option>(getDefaultOption());

  const { anchorElement, isOpen, open, close } = useDropdown();

  const charactersCount = useEditorCharactersCount();
  const wordsCount = useEditorWordsCount();

  const getValueByOption = (option: Option) => {
    switch (option) {
      case 'characters':
        return charactersCount;
      case 'words':
        return charactersCount === 0 ? 0 : wordsCount;
    }
  };

  const selectOption = (option: Option) => {
    trackingWrapper.track('aiWriterEditorToolbarWordCountClicked');
    GAEvents.bottomBarActionExecuted({
      action: 'word_count'
    });
    setOption(option);
    localStorage.setItem(LocalStorageKey.AiWriterEditorStatisticPreference, option);
    close();
  };

  return (
    <MenuDropdown
      trigger={
        <TriggerButton onClick={open}>
          <StyledText variant="caption" color="blackMediumEmphasis">
            <FormattedMessage
              id={getMessageIdByOption(option)}
              values={{ count: getValueByOption(option) }}
            />
          </StyledText>
          <ExpandMoreGreyIcon fontSize="small" />
        </TriggerButton>
      }
      isOpen={isOpen}
      anchorElement={anchorElement}
      close={close}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      width="auto"
    >
      <Container>
        {options.map(option => (
          <StatisticsButton
            key={option}
            messageId={getMessageIdByOption(option)}
            value={getValueByOption(option)}
            onClick={() => selectOption(option)}
          />
        ))}
      </Container>
    </MenuDropdown>
  );
};

const getMessageIdByOption = (option: Option) => {
  switch (option) {
    case 'characters':
      return 'aiWriter.toolbar.counter.characters_count';
    case 'words':
      return 'aiWriter.toolbar.counter.words_count';
  }
};

function getDefaultOption() {
  const defaultOption: Option = 'words';
  const preferredOption = localStorage.getItem(LocalStorageKey.AiWriterEditorStatisticPreference);

  if (preferredOption && isOption(preferredOption)) {
    return preferredOption;
  }

  return defaultOption;
}

function isOption(val: string): val is Option {
  return options.some(option => option === val);
}

const TriggerButton = styled.button`
  ${resetButton};

  display: flex;
  align-items: center;
`;

const Container = styled(FlexContainer)`
  align-items: flex-start;
  padding: ${({ theme }) => `0 ${theme.spacings.medium}`};
`;

const ExpandMoreGreyIcon = styled(ArrowDropDownIcon)`
  color: ${({ theme }) => theme.colors.blackMediumEmphasis};
`;

const StyledText = styled(ColoredTypography)`
  line-height: unset;
`;

export default EditorStatistics;
