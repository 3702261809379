import { allowedPricingPlans } from 'features/aiWriter/chat/GptSelect';
import { getPricingPlan } from 'features/pricing/store/selectors';
import { UsageIndicatorType } from 'features/usageIndicator/UsageIndicatorType';
import { useGetCurrentUsageByType } from 'features/usageIndicator/useGetCurrentUsageByType';
import { useAppSelector } from 'store/hooks';

export const useIsGpt4Disabled = () => {
  const usageResult = useGetCurrentUsageByType(UsageIndicatorType.gpt4);
  const isGpt4LimitReached =
    usageResult.data?.isUnlimited === false && usageResult.data?.isAvailable === false;

  const pricingPlan = useAppSelector(getPricingPlan);
  const isAllowedToSelectGpt4 = allowedPricingPlans.includes(pricingPlan);

  const isGpt4Disabled = !isAllowedToSelectGpt4 || isGpt4LimitReached;

  return isGpt4Disabled;
};
