import { getIsAuthenticated } from 'features/customer/store/selectors';
import { useCustomerPreferences } from 'features/customerPreferences/useCustomerPreferences';
import { useEmbeddedLanguageToolWidget } from 'features/languageToolIntegration/useEmbeddedLanguageToolWidget';
import { useEffect } from 'react';
import { useAppSelector } from 'store/hooks';

function OnlyWhenAuthenticated() {
  const toggleLanguageTool = useEmbeddedLanguageToolWidget();
  const { data: preferences } = useCustomerPreferences();

  useEffect(() => {
    toggleLanguageTool(preferences?.languageToolEnabled ?? false);
  }, [preferences?.languageToolEnabled, toggleLanguageTool]);

  return null;
}

export const LanguageToolWidget = () => {
  const isAuthenticated = useAppSelector(getIsAuthenticated);
  return isAuthenticated ? <OnlyWhenAuthenticated /> : null;
};
