import { Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

export function NoDocumentsPlaceholder() {
  return (
    <Root>
      <Typography variant="subtitle1">
        <FormattedMessage id="aiWriter.project_overview.no_documents_placeholder.title" />
      </Typography>
      <Typography variant="body1">
        <FormattedMessage id="aiWriter.project_overview.no_documents_placeholder.description" />
      </Typography>
    </Root>
  );
}

const Root = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${({ theme }) => theme.spacings.small};

  padding: ${({ theme }) => `${theme.spacings.xxxxlarge} 0`};
  border-radius: ${({ theme }) => theme.borderRadius.medium};
  background-color: ${({ theme }) => theme.colors.primary50};
`;
