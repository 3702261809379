import { makeCurrentUserQueryKeyPrefix } from 'features/currentUser/currentUserQueries';
import { BaseSuccessDto } from 'services/backofficeIntegration/http/BaseSuccessDto';
import { backofficeClient } from 'services/backofficeIntegration/http/client/createBackofficeClient';
import { queryEndpoint } from 'services/backofficeIntegration/http/endpointDefinition';
import { queryClient } from 'utils/ReactQueryClient';

export type GetInformationLimitationsSuccessDto = BaseSuccessDto<GetInformationLimitationsDto>;

type GetInformationLimitationsDto = {
  limitName: string;
  limit: number;
  limitWithoutBonus: number;
  bonus: number;
  used: number;
  available: number;
  isUnlimited: boolean;
  availability: {
    [key: string]: {
      isAvailable: boolean;
      isUnlimited: boolean;
      availableRequestCount: number;
    };
  };
};

export const httpGetInformationLimitations = queryEndpoint({
  makeQueryKey: () => {
    return [...makeCurrentUserQueryKeyPrefix(), 'information', 'limitations'] as const;
  },
  callEndpoint: () => {
    return backofficeClient
      .get<GetInformationLimitationsSuccessDto>('/informations/limitations')
      .then(response => {
        return response.data;
      });
  }
});

export function invalidateInformationLimitationsQuery() {
  return queryClient.invalidateQueries({
    queryKey: [...makeCurrentUserQueryKeyPrefix(), 'information', 'limitations']
  });
}
