import { Button, Tooltip } from '@mui/material';
import { ColoredTypography } from 'components/ColoredTypography';
import FlexContainer from 'components/FlexContainer';
import WordStatisticsPillTooltip from 'features/aiWriter/AiWriterSidebar/steps/seoAnalysis/common/WordStatisticsPillTooltip';
import {
  getWordStatisticsBackgroundColor,
  getWordStatisticsBorder
} from 'features/aiWriter/AiWriterSidebar/steps/seoAnalysis/wordStatisticsColor';
import { updateSeoHighlightedKeyword } from 'features/aiWriter/store/actions/seoAnalysis/actions';
import { getSeoHighlightedKeyword } from 'features/aiWriter/store/selectors';
import { TermSuggestion } from 'features/aiWriter/store/types';
import { useCallback, useMemo } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import styled from 'styled-components';
import { resetButton } from 'styles/styledComponents/resets';

export type WordStatisticsColorVariant = 'primary' | 'secondary' | 'green';

type Props = {
  suggestion: TermSuggestion;
  color: WordStatisticsColorVariant;
};

const WordStatisticsPill = ({ color, suggestion: { word, occurrence, maxUse, minUse } }: Props) => {
  const dispatch = useAppDispatch();

  const seoHighlightedKeyword = useAppSelector(getSeoHighlightedKeyword);

  const showTooltip = useMemo(() => maxUse !== undefined && minUse !== undefined, [maxUse, minUse]);

  const isSelected = seoHighlightedKeyword === word;

  const onClick = useCallback(() => {
    isSelected
      ? dispatch(updateSeoHighlightedKeyword(''))
      : dispatch(updateSeoHighlightedKeyword(word));
  }, [isSelected, word, dispatch]);

  const content = useMemo(
    () => (
      <Root $color={color} $isHighlighted={isSelected} onClick={onClick}>
        <FlexContainer gap="xxsmall" direction="row">
          <Word color="blackHighEmphasis">{word}</Word>
          {occurrence !== undefined && (
            <ColoredTypography color="blackHighEmphasis">({occurrence})</ColoredTypography>
          )}
        </FlexContainer>
      </Root>
    ),
    [color, occurrence, word, isSelected, onClick]
  );

  return showTooltip ? (
    <Tooltip
      title={<WordStatisticsPillTooltip minUse={minUse} maxUse={maxUse} />}
      placement="top"
      arrow
    >
      {content}
    </Tooltip>
  ) : (
    content
  );
};

const Root = styled(Button)<{ $color: WordStatisticsColorVariant; $isHighlighted: boolean }>`
  ${resetButton};

  ${({ $color, $isHighlighted }) => getWordStatisticsBackgroundColor($color, $isHighlighted)};
  ${({ $color, $isHighlighted }) => getWordStatisticsBorder($color, $isHighlighted)};

  padding: ${({ theme }) => `${theme.spacings.xsmall} ${theme.spacings.medium}`};

  border-radius: 100px;
`;

const Word = styled(ColoredTypography)`
  font-weight: 500;
`;

export default WordStatisticsPill;
