import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import LocalStorageKey from 'config/localStorageKey';
import { SurveyAnswersDto } from 'services/backofficeIntegration/http/dtos/SurveyAnswersDto';
import { getSurveyAnswers } from 'services/backofficeIntegration/http/endpoints/surveyQuestions/getSurveyAnswers';

type Options = UseQueryOptions<
  SurveyAnswersDto,
  unknown,
  SurveyAnswersDto,
  ReturnType<typeof getSurveyAnswers['makeQueryKey']>
>;

export const useSurveyAnswers = (options?: Options) => {
  const apiToken = localStorage.getItem(LocalStorageKey.APIToken);

  return useQuery({
    enabled: !!apiToken,
    ...(options ?? {}),
    queryKey: getSurveyAnswers.makeQueryKey(),
    queryFn: () => getSurveyAnswers.callEndpoint()
  });
};
