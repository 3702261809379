import { useQuery } from '@tanstack/react-query';
import { makeCurrentUserQueryKeyPrefix } from 'features/currentUser/currentUserQueries';
import CustomerAPI from 'services/api/customer';

export function useGetChurnStatisticQuery() {
  return useQuery([...makeCurrentUserQueryKeyPrefix(), 'churn-statistics'], async () => {
    const response = await CustomerAPI.getChurnStatistic();
    if (response.status) {
      return response.data;
    }
    throw response.message;
  });
}
