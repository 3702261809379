import {
  createEventStreamParser,
  EventStreamParser,
  ParseEvent
} from 'utils/httpEventStream/eventStreamParser';

export class ResponseError extends Error {
  response: Response;
  constructor(response: Response) {
    super('API response error');
    this.response = response;
  }
}

export class TimeoutError extends Error {
  constructor() {
    super('Timeout error');
  }
}

type EventStreamReader = ReadableStreamDefaultReader<Uint8Array>;

const decoder = new TextDecoder();

const streamTimeout = 60000;

async function readStream(
  reader: EventStreamReader,
  parser: EventStreamParser,
  abort?: AbortSignal
) {
  abort?.addEventListener('abort', () => {
    reader.cancel();
  });

  let done = false;

  while (!done) {
    const timeoutPromise = new Promise<never>((_, reject) => {
      window.setTimeout(() => {
        reject(new TimeoutError());
      }, streamTimeout);
    });
    const { value, done: doneReading } = await Promise.race([reader.read(), timeoutPromise]);
    done = doneReading;
    const chunk = decoder.decode(value);
    parser.feed(chunk);
  }
}

type EventContext = {
  event: ParseEvent;
  cancel: () => void;
};

type EventHandler = (context: EventContext) => void;

function makeParser(reader: EventStreamReader, onEvent: EventHandler) {
  return createEventStreamParser(event => {
    const context = {
      event,
      cancel: () => {
        reader.cancel();
      }
    };
    onEvent(context);
  });
}

/**
 * IMPORTANT: This could go either way but I decided that aborting the process
 * will resolve (not reject!) the promise.
 */
export async function processEventStream(props: {
  response: Response;
  onEvent: EventHandler;
  abort?: AbortSignal;
}) {
  const { response, onEvent, abort } = props;

  if (!response.ok || !response.body) {
    throw new ResponseError(response);
  }

  const reader = response.body.getReader();
  const parser = makeParser(reader, onEvent);

  await readStream(reader, parser, abort);
}
