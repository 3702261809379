import { makeCurrentUserQueryKeyPrefix } from 'features/currentUser/currentUserQueries';
import { BaseSuccessDto } from 'services/backofficeIntegration/http/BaseSuccessDto';
import { backofficeClient } from 'services/backofficeIntegration/http/client/createBackofficeClient';
import { CustomerPreferencesDto } from 'services/backofficeIntegration/http/dtos/CustomerPreferencesDto';
import { queryEndpoint } from 'services/backofficeIntegration/http/endpointDefinition';

type GetCustomerPreferences = {
  preferences: CustomerPreferencesDto;
};

type SuccessDto = BaseSuccessDto<GetCustomerPreferences>;

export const httpGetPreferences = queryEndpoint({
  makeQueryKey: () => {
    return [...makeCurrentUserQueryKeyPrefix(), 'preferences'] as const;
  },
  callEndpoint: () => {
    return backofficeClient
      .get<SuccessDto>('/customer/preferences')
      .then(response => response.data.data.preferences);
  }
});
