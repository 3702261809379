import { manualDimensionsFromWords } from 'features/dimensions/store/utils/manualDimensionsMappers';
import { useField } from 'formik';
import { useMemo } from 'react';
import { ManualDimension } from 'services/api/dimension/types';
import useTr from 'utils/hooks/useTr';
import { tagsToWords } from 'utils/wordUtils';

import AutocompleteChipsInput, {
  AutocompleteTextFieldProps
} from '../AutocompleteInput/AutocompleteChipsInput';

type Props = {
  name?: string;
  embeddingModelId: string;
  disabled?: boolean;
  textFieldProps?: AutocompleteTextFieldProps;
};

const ManualDimensionsField = ({
  name = 'manualDimensions',
  embeddingModelId,
  disabled,
  textFieldProps
}: Props) => {
  const [field, , helpers] = useField<ManualDimension[]>(name);
  const handleChange = (words: string[]) => {
    const manualDimensions = manualDimensionsFromWords(words);
    helpers.setValue(manualDimensions);
  };

  const tr = useTr();

  const initialValue = useMemo(
    () => tagsToWords(field.value.map(dimension => dimension.words[0])),
    [field.value]
  );

  return (
    <AutocompleteChipsInput
      disabled={disabled}
      onChange={handleChange}
      model={embeddingModelId}
      initialValue={initialValue}
      textFieldProps={{
        variant: 'outlined',
        fullWidth: true,
        label: tr('testing.setup.goal.manual_dimensions.label'),
        placeholder: tr('testing.setup.goal.manual_dimensions.placeholder'),
        ...textFieldProps
      }}
    />
  );
};

export default ManualDimensionsField;
