import { Card, Switch, Typography } from '@mui/material';
import FlexContainer from 'components/FlexContainer';
import { ClickableScoreLabel } from 'features/aiWriter/AiWriterSidebar/steps/flashScore/ClickableScoreLabel';
import { StretchedCardContent } from 'features/aiWriter/AiWriterSidebar/steps/flashScore/FlashScoreAnalyzeView';
import { EmotionalityCircularProgress } from 'features/aiWriter/AiWriterSidebar/steps/flashScore/progress-circles';
import { useFlashScoreSidebarState } from 'features/aiWriter/AiWriterSidebar/steps/flashScore/useFlashScoreSidebarState';
import useEditor from 'features/aiWriter/AiWriterTextEditor/hooks/useEditor';
import { FlashScoreTextRating } from 'features/flashScore/useScoreTexts';
import FormattedMessage from 'features/i18n/FormattedMessage';
import { GAEvents } from 'services/tracking/GAEvents';
import styled from 'styled-components';

type Props = {
  emotionality: FlashScoreTextRating;
};

export const EmotionalityCard = ({ emotionality }: Props) => {
  const editor = useEditor();

  const showEmotionalityWordHighlight = useFlashScoreSidebarState(
    state => state.showEmotionalityWordHighlight
  );
  const setShowEmotionalityWordHighlight = useFlashScoreSidebarState(
    state => state.setShowEmotionalityWordHighlight
  );

  const handleSwitchChange = () => {
    setShowEmotionalityWordHighlight(!showEmotionalityWordHighlight);

    // Re-decorate the editor to update the highlights
    editor.redecorate();

    GAEvents.flashScoreHighlightToggle();
  };

  return (
    <Card variant="outlined">
      <StretchedCardContent>
        <EmotionalityFlexContainer>
          <FlexContainer direction="column" alignItems="center" gap="small">
            <SwitchContainer>
              <Switch
                size="small"
                checked={showEmotionalityWordHighlight}
                onChange={handleSwitchChange}
              />
            </SwitchContainer>

            <Typography fontWeight="bold">
              <FormattedMessage id="flashScore.emotionality.label" />
            </Typography>

            <LargeEmotionalityCircularProgress
              value={emotionality.percentage}
              label={<ClickableScoreLabel rating={emotionality} />}
            />
          </FlexContainer>

          <FlexContainer gap="small">
            <Typography align="left" variant="body1">
              <FormattedMessage
                id={`aiWriter.inspirations.flashScore.emotionality.explainer.grade_${emotionality.grade}.headline`}
                values={{
                  b: chunks => <strong>{chunks}</strong>
                }}
              />
            </Typography>

            <Typography align="left" variant="body2">
              <FormattedMessage
                id={`aiWriter.inspirations.flashScore.emotionality.explainer.grade_${emotionality.grade}.content`}
                values={{
                  b: chunks => <strong>{chunks}</strong>
                }}
              />
            </Typography>

            <Typography align="left" variant="body2">
              <FormattedMessage
                id={`aiWriter.inspirations.flashScore.emotionality.explainer.grade_${emotionality.grade}.footer`}
                values={{
                  b: chunks => <strong>{chunks}</strong>
                }}
              />
            </Typography>
          </FlexContainer>
        </EmotionalityFlexContainer>
      </StretchedCardContent>
    </Card>
  );
};

const EmotionalityFlexContainer = styled.div`
  position: relative;
  height: 100%;

  display: grid;
  gap: ${({ theme }) => theme.spacings.small};
  grid-template-columns: 1fr 1fr;
`;

const SwitchContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
`;

const LargeEmotionalityCircularProgress = styled(EmotionalityCircularProgress).attrs({
  CircularProgressProps: {
    size: 120,
    thickness: 4
  }
})``;
