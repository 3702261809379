import { makeCurrentUserQueryKeyPrefix } from 'features/currentUser/currentUserQueries';
import { backofficeClient } from 'services/backofficeIntegration/http/client/createBackofficeClient';
import { queryEndpoint } from 'services/backofficeIntegration/http/endpointDefinition';
import {
  PaginatedListParams,
  PaginatedListSuccessDto
} from 'services/backofficeIntegration/http/paginatedListEndpoint';
import { queryClient } from 'utils/ReactQueryClient';

export type GetInformationCategoriesSuccessDto = PaginatedListSuccessDto<InformationCategoryDto>;

export type InformationCategoryDto = {
  id: number;
  customer_id: string;
  name: string;
  alias: string;
  isNew?: boolean;
};

export type GetInformationCategoriesParams = PaginatedListParams & {
  name?: string;
  sort?: 'updated_at' | 'name';
  direction?: 'asc' | 'desc';
  // temporary solution
  is_paginated?: 1 | 0;
  has_information?: 1 | 0;
};

export const httpGetInformationCategories = queryEndpoint({
  makeQueryKey: (params: GetInformationCategoriesParams) => {
    return [...makeCurrentUserQueryKeyPrefix(), 'informationCategories', params] as const;
  },
  callEndpoint: (params: GetInformationCategoriesParams) => {
    return backofficeClient
      .get<GetInformationCategoriesSuccessDto>('information-categories', {
        params
      })
      .then(response => response.data);
  }
});

export function invalidateInformationCategoriesQuery() {
  return queryClient.invalidateQueries({
    queryKey: [...makeCurrentUserQueryKeyPrefix(), 'informationCategories']
  });
}
