import { Button, Typography } from '@mui/material';
import InformationBlock from 'features/profilePage/InformationBlock';
import { upperFirst } from 'lodash';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

type Props = {
  cardBrand: string | null;
  cardLastFour: string | null;
  cardExpirationDate?: string | null;
  stripePortalUrl: string;
};

const PaymentInfoStatus = ({
  cardBrand,
  cardLastFour,
  cardExpirationDate,
  stripePortalUrl
}: Props) => {
  if (cardBrand === null || cardLastFour === null) {
    return (
      <InformationBlock headline="profile.payment">
        <FormattedMessage id="profile.no_payment_info" values={{ br: <br /> }} />
      </InformationBlock>
    );
  }

  const EditButton = () => (
    <Button component="a" color="primary" href={stripePortalUrl} target="_blank">
      <FormattedMessage id="common.edit" />
    </Button>
  );

  return (
    <InformationBlock headline="profile.payment" button={<EditButton />}>
      <CreditCardInfoLine variant="body1">
        <FormattedMessage id="profile.cc_brand" values={{ brand: upperFirst(cardBrand) }} />
      </CreditCardInfoLine>
      {cardExpirationDate && (
        <CreditCardInfoLine variant="body1">
          <FormattedMessage id="profile.cc_expiration" values={{ date: cardExpirationDate }} />
        </CreditCardInfoLine>
      )}

      <CreditCardInfoLine variant="body1">
        <FormattedMessage id="profile.cc_number" values={{ ending: cardLastFour }} />
      </CreditCardInfoLine>
    </InformationBlock>
  );
};

const CreditCardInfoLine = styled(Typography)`
  &:first-child {
    margin-top: ${({ theme }) => theme.spacings.medium};
  }
`;

export default PaymentInfoStatus;
