import useEditorText from 'features/aiWriter/AiWriterTextEditor/hooks/useEditorText';
import { getActiveTab } from 'features/aiWriter/store/selectors';
import { useScoreProvidedText } from 'features/flashScore/useScoreProvidedText';
import { useTokenizeAndScoreProvidedText } from 'features/flashScore/useTokenizeAndScoreProvidedText';
import { useEffect } from 'react';
import { useAppSelector } from 'store/hooks';
import { useStateThrottled } from 'utils/hooks/useStateThrottled';

type Options = {
  enabled?: boolean;
};

export const useScoreEditorText = (options: Options = {}) => {
  const { id: documentId } = useAppSelector(getActiveTab);
  const editorText = useEditorText('\n');

  const [throttledEditorText, setThrottledEditorText] = useStateThrottled(editorText, {
    // Update FlashScore every 1,5 seconds
    intervalMilliseconds: 1500
  });

  // (Throttled) update editor text which is used for scoring (and caching the result)
  useEffect(() => {
    setThrottledEditorText(editorText);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editorText]);

  const textResult = useScoreProvidedText({
    ...options,
    text: throttledEditorText,
    cacheKeys: [documentId, throttledEditorText]
  });
  const wordsResult = useTokenizeAndScoreProvidedText({
    ...options,
    text: throttledEditorText,
    cacheKeys: [documentId, throttledEditorText]
  });

  const refetchScoring = () => {
    textResult.refetch();
    wordsResult.refetch();
  };

  return {
    refetch: refetchScoring,
    text: textResult,
    words: wordsResult
  };
};
