import { Paper } from '@mui/material';
import FlexContainer from 'components/FlexContainer';
import { ReactNode } from 'react';
import styled, { css } from 'styled-components';

const enlargedContainerStyle = css`
  // Enlarging the container without changing the size to ensure we are always larger
  // than any element which could be underneath (by scrolling a larger amount of messages)
  padding-left: ${({ theme }) => theme.spacings.small};
  padding-right: ${({ theme }) => theme.spacings.small};
  margin-left: -${({ theme }) => theme.spacings.small};
  margin-right: -${({ theme }) => theme.spacings.small};
`;

const Root = styled.div`
  display: flex;
  flex-direction: column;
  // Required to make the pane scrollable
  // Also using min-height instead of height ensures inner sticky elements are not cut off
  min-height: 100%;
`;

const InputBox = styled(Paper).attrs({ square: true, elevation: 0 })`
  ${enlargedContainerStyle};

  background-color: ${({ theme }) => theme.colors.backgroundDefault};

  position: sticky;
  bottom: 0;

  padding-top: ${({ theme }) => theme.spacings.small};
  margin-top: ${({ theme }) => theme.spacings.small};
`;

const MessageAreaBox = styled.div`
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  // We have other elements which must be placed above the messages
  z-index: 0;
`;

const InputActionsBox = styled(FlexContainer).attrs({
  direction: 'row',
  alignItems: 'center',
  gap: 'small'
})`
  margin-bottom: ${({ theme }) => theme.spacings.small};
`;

const ResetButtonBox = styled.div`
  margin-left: auto;
`;

type Props = {
  messageInput: ReactNode;
  messagesArea: ReactNode;
  resetButton: ReactNode;
};

export function ChatLayout({ messageInput, messagesArea, resetButton }: Props) {
  return (
    <Root>
      <MessageAreaBox>{messagesArea}</MessageAreaBox>
      <InputBox>
        <InputActionsBox>
          <ResetButtonBox>{resetButton}</ResetButtonBox>
        </InputActionsBox>

        {messageInput}
      </InputBox>
    </Root>
  );
}
