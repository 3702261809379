import { ApiAiWriterProjectPreview } from 'services/api/aiWriter/types';

import { AiWriterProjectPreview } from '../../types';

export const aiWriterProjectPreviewFromApi = (
  project: ApiAiWriterProjectPreview
): AiWriterProjectPreview => ({
  name: project.name,
  text: JSON.parse(project.text),
  updatedAt: project.updated_at,
  authorImage: project.author_image ?? undefined,
  authorName: project.author_name
});
