import { getEmbeddingModelDataSelector } from 'features/embeddingModels/store/selectors';
import { useAppSelector } from 'store/hooks';

import DimensionsField from '../forms/DimensionsField';

type Props = {
  name?: string;
  embeddingModelId: string;
  disabled?: boolean;
};

const DimensionsSelect = ({ name = 'dimensions', embeddingModelId, disabled }: Props) => {
  const getData = useAppSelector(getEmbeddingModelDataSelector);
  const { language } = getData(embeddingModelId);

  return <DimensionsField name={name} disabled={disabled} modelLanguage={language} />;
};

export default DimensionsSelect;
