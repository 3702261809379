import {
  getAvailablePercentage,
  getIndicatorConfiguration
} from 'features/usageIndicator/getIndicatorConfiguration';
import { UsageIndicatorType } from 'features/usageIndicator/UsageIndicatorType';
import { useGetCurrentUsageByType } from 'features/usageIndicator/useGetCurrentUsageByType';
import { rgba } from 'polished';
import styled from 'styled-components';

export const indicatorHeight = '6px';

type Props = {
  type: UsageIndicatorType;
  hasAccess: boolean;
};

export const UsageIndicator = ({ type, hasAccess }: Props) => {
  const usageResult = useGetCurrentUsageByType(type);

  const { label, barColor } = getIndicatorConfiguration(usageResult, type, hasAccess);

  const isUnlimited = usageResult.data?.isUnlimited || false;
  const progressBarPercentage =
    !usageResult.isSuccess || !usageResult.data
      ? 0
      : getAvailablePercentage({ ...usageResult.data, hasAccess });

  return (
    <Root>
      {label}
      <Indicator $background={isUnlimited ? null : barColor}>
        <ProgressBar $background={barColor} $percentage={progressBarPercentage} />
      </Indicator>
    </Root>
  );
};

const Root = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;
`;

const Indicator = styled.div<{ $background: string | null }>`
  position: relative;
  z-index: 0;

  width: 100%;
  height: ${indicatorHeight};

  box-sizing: border-box;
  border-radius: ${indicatorHeight};
  background: ${({ $background }) => ($background ? rgba($background, 0.2) : undefined)};
  overflow: hidden;
`;

const ProgressBar = styled.div<{ $background: string; $percentage: number }>`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;

  width: ${({ $percentage }) => $percentage}%;
  height: ${indicatorHeight};

  border-radius: 100px;
  background: ${({ $background }) => $background};
  opacity: 1;
`;
