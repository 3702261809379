import CountryFlag from 'components/base/CountryFlag';
import Dropdown from 'components/Dropdown/Dropdown';
import { DropdownItemProps } from 'components/Dropdown/DropdownItem';
import styles from 'components/layout/toolbar/ListsDropdown.module.scss';
import Tooltip from 'components/tooltips/Tooltip';
import React, { useMemo } from 'react';
import { List } from 'services/api/list/types';
import useTr from 'utils/hooks/useTr';

type Props = {
  lists: Record<string, List>;
  currentList: List;
  selectList: (id: number) => void;
  children?: React.ReactNode;
};

const ListsDropdown = ({ lists, currentList, selectList, children }: Props) => {
  const tr = useTr();

  const getDropdownItems = useMemo(() => {
    let items: DropdownItemProps[] = Object.values(lists).map(({ label, language, id }) => ({
      value: label,
      flag: language,
      onClick: () => selectList(id)
    }));

    if (!items.length) {
      items = [{ value: tr('lists.no_lists'), disabled: true }];
    }

    return items;
  }, [lists, selectList, tr]);

  const { label, language } = currentList;
  const flagTip = language
    ? tr('lists.model_info', { language: tr(`lang.${language}`) })
    : tr('lists.any_model');

  return (
    <div className={styles.dropdownContainer}>
      <Dropdown
        className="lists-dropdown"
        triggerContent={
          <span className={styles.trigger}>
            <Tooltip content={flagTip}>
              <CountryFlag language={language} className={styles.flag} />
            </Tooltip>
            <div className={styles.triggerText}>{label || tr('lists.select_list')}</div>
          </span>
        }
        itemsObject={getDropdownItems}
        position="bottom-start"
      />
      {children}
    </div>
  );
};

export default ListsDropdown;
