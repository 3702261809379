import FormatIndentDecreaseIcon from '@mui/icons-material/FormatIndentDecrease';
import { Tooltip } from '@mui/material';
import { useOutdentButton } from '@udecode/plate-indent';
import {
  EditorFormatButton,
  EditorFormatButtonProps,
  EditorIconFormatButton
} from 'features/aiWriter/AiWriterTextEditor/EditorIconFormatButton';
import { trackEditorFormatAction } from 'features/aiWriter/AiWriterTextEditor/trackEditorFormatAction';
import { getIconColor } from 'features/aiWriter/AiWriterTextEditor/utils/getIconColor';
import FormattedMessage from 'features/i18n/FormattedMessage';

type OutdentButtonProps = {
  styleVariant?: EditorFormatButtonProps;
  onClose?: () => void;
};

export function OutdentButton({ onClose, styleVariant }: OutdentButtonProps) {
  const { props } = useOutdentButton();

  const handleClick = () => {
    trackEditorFormatAction('outdent');

    props.onClick();

    onClose && onClose();
  };

  if (styleVariant === 'icon') {
    return (
      <EditorIconFormatButton {...props} $isActive={false} onClick={handleClick}>
        <Tooltip
          arrow
          title={<FormattedMessage id="aiWriter.toolbar.outdent" />}
          placement="bottom"
        >
          <FormatIndentDecreaseIcon color={getIconColor(false)} fontSize="small" />
        </Tooltip>
      </EditorIconFormatButton>
    );
  }

  return (
    <EditorFormatButton onClick={handleClick} variant="text" $isActive={false}>
      <FormatIndentDecreaseIcon color={getIconColor(false)} fontSize="small" />
      <FormattedMessage id="aiWriter.toolbar.outdent" />
    </EditorFormatButton>
  );
}
