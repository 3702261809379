import { createAction } from '@reduxjs/toolkit';
import { AiWriterWorkflowsType } from 'types/AiWriterWorkflows';

import { setBlogPostOutline, setBlogPostTitle } from './blogPost/actions';

export const setBuilderInitLanguage = createAction<{
  builder: AiWriterWorkflowsType;
  language: { language: string; country: string } | undefined;
}>('aiWriter/setBuilderInitLanguage');

export const enableInspirationsPanel = createAction<void>('aiWriter/enableInspirationsPanel');

export const disableInspirationsPanel = createAction<void>('aiWriter/disableInspirationsPanel');

export const setInspirations = createAction<string[]>('aiWriter/setInspirations');

export { setBlogPostOutline, setBlogPostTitle };
