import { isBillingPortalCreateCheckoutResponse } from 'features/stripe-portal/isBillingPortalCreateCheckoutResponse';
import {
  CreateBillingPortalResponse,
  CreateBillingPortalResponseForBillingPortal
} from 'services/api/customer/types';
import isEmptyString from 'utils/isEmptyString';

const createStripeBillingPortalUrl = (
  data: CreateBillingPortalResponseForBillingPortal,
  createReactivateUrl: boolean,
  cancelSubscription?: boolean
): string => {
  const { billing_portal_url, price_id, subscription_id } = data;

  if (isEmptyString(subscription_id)) {
    return billing_portal_url;
  }

  if (cancelSubscription) {
    // /session/<session_id>/subscriptions/<subscription_id>/cancel
    return `${billing_portal_url}/subscriptions/${subscription_id}/cancel`;
  }

  if (createReactivateUrl) {
    // /session/<session_id>/subscriptions/<subscription_id>/reactivate
    return `${billing_portal_url}/subscriptions/${subscription_id}/reactivate`;
  }

  if (isEmptyString(price_id)) {
    return billing_portal_url;
  }

  // /session/<session_id>/subscriptions/<subscription_id>/preview/<price_id>
  return `${billing_portal_url}/subscriptions/${subscription_id}/preview/${price_id}`;
};

export const createStripeCheckoutUrlFromResponse = (
  responseData: CreateBillingPortalResponse,
  isActivePlan: boolean,
  hasSubscriptionInGrace: boolean,
  cancelSubscription?: boolean
) => {
  // Only checkout_url was returned
  if (isBillingPortalCreateCheckoutResponse(responseData)) {
    return responseData.checkout_url;
  }

  // If the user want's to re-active the plan, we need to redirect to a different url
  const createReactiveUrl = isActivePlan && hasSubscriptionInGrace;

  return createStripeBillingPortalUrl(responseData, createReactiveUrl, cancelSubscription);
};
