import { makeCurrentUserQueryKeyPrefix } from 'features/currentUser/currentUserQueries';
import { BaseSuccessDto } from 'services/backofficeIntegration/http/BaseSuccessDto';
import { backofficeClient } from 'services/backofficeIntegration/http/client/createBackofficeClient';
import { queryEndpoint } from 'services/backofficeIntegration/http/endpointDefinition';

export type TemplateCategoryDto = {
  id: number;
  name: string;
  alias: string;
};

export type GetCommandTemplatesSuccessDto = BaseSuccessDto<TemplateCategoryDto[]>;

export const httpGetCommandTemplateCategories = queryEndpoint({
  makeQueryKey: () => {
    return [
      ...makeCurrentUserQueryKeyPrefix(),
      'textGeneration',
      'commandTemplateCategories',
      'list'
    ] as const;
  },
  callEndpoint: () => {
    return backofficeClient
      .get<GetCommandTemplatesSuccessDto>('/text-generation/command-template-categories')
      .then(response => response.data.data);
  }
});
