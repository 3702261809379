import { useMutation } from '@tanstack/react-query';
import Toast from 'components/toasts/Toast';
import { setReferralLink } from 'features/customer/store/actions';
import { usePostHog } from 'posthog-js/react';
import CustomerAPI from 'services/api/customer';
import { GAEvents } from 'services/tracking/GAEvents';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import useTr from 'utils/hooks/useTr';

export function useReferralLinkButtonBehavior() {
  const dispatch = useAppDispatch();
  const referralLink = useAppSelector(store => store.customer.referralLink);
  const translate = useTr();
  const postHog = usePostHog();

  const copyMutation = useMutation(
    async () => {
      let link = referralLink;

      const linkGenerationRequired = !link;
      if (linkGenerationRequired) {
        link = await CustomerAPI.createReferralLink().then(data => {
          if (data.status) {
            const link = data.data.first_promoter_referral_link;
            dispatch(setReferralLink(link));
            return link;
          } else {
            throw new Error('Failed to generate referral link');
          }
        });
      }

      await navigator.clipboard.writeText(link);

      return {
        linkGenerationRequired
      };
    },
    {
      onSuccess: ({ linkGenerationRequired }) => {
        if (linkGenerationRequired) {
          Toast.info(translate('bonus_referral.link_generated'));
        } else {
          Toast.info(translate('common.copied'));
        }
      },
      onError: () => {
        Toast.commonError();
      }
    }
  );

  const label = referralLink ? 'bonus_referral.copy_link' : 'bonus_referral.generate_link';

  return {
    label,
    onClick: () => {
      if (!referralLink) {
        postHog?.capture('Click get shareable link');
        GAEvents.inviteFriedGetShareableLink();
      } else {
        postHog?.capture('Copy shareable link');
        GAEvents.inviteFriedCopyShareableLink();
      }

      copyMutation.mutate();
    },
    loading: copyMutation.isLoading
  };
}
