import HelpIcon from '@mui/icons-material/Help';
import { Link, Tooltip } from '@mui/material';
import { IntlMessageKeys } from 'features/i18n/IntlMessageKeys';
import { ReactNode } from 'react';
import styled from 'styled-components';
import useTr from 'utils/hooks/useTr';

type Props = {
  link: IntlMessageKeys;
  tooltip?: ReactNode;
};

export function HelpLinkingButton({ link, tooltip }: Props) {
  const translate = useTr();

  const button = (
    <StyledLink href={translate(link)} target="_blank" rel="noreferrer">
      <HelpIcon color="action" fontSize="small" />
    </StyledLink>
  );

  if (!tooltip) {
    return button;
  }

  return (
    <Tooltip placement="top" title={tooltip}>
      {button}
    </Tooltip>
  );
}

const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.primaryColorLight};
  &:hover {
    color: ${({ theme }) => theme.colors.primaryColorLight};
  }
`;
