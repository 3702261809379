import { AddToDocumentDialog } from 'features/aiImages/AiImagesPage/AddToDocumentDialog';
import { FormattedMessage } from 'react-intl';

type Props = {
  onCancel: () => void;
  onConfirm: () => void;
  isDialogOpen: boolean;
};

export const AddToExistingDocumentConfirmation = ({ isDialogOpen, onCancel, onConfirm }: Props) => {
  return (
    <AddToDocumentDialog
      title={
        <FormattedMessage id="ai_images.generator_page.add_to_document_modal.existing_document.title" />
      }
      content={
        <FormattedMessage
          id="ai_images.generator_page.add_to_document_modal.existing_document.confirmation"
          values={{
            br: <br />
          }}
        />
      }
      isDialogOpen={isDialogOpen}
      onCancel={onCancel}
      onConfirm={onConfirm}
    />
  );
};
