import {
  defaultMessages,
  LoadingAnimationWithAlternatingMessages
} from 'features/loading-spinner/LoadingAnimationWithAlternatingMessages';
import { useRunningWorkflowSelectedStep } from 'features/modular-workflow/runner/useModulareWorkflowRunnerStore';
import styled from 'styled-components';

export const StepInProgressState = () => {
  const runningWorkflowStep = useRunningWorkflowSelectedStep();

  return (
    <Root>
      <LoadingAnimationWithAlternatingMessages
        alternatingMessages={[
          ...defaultMessages,
          ['modular_workflow.runner.loading.progressing_step', { name: runningWorkflowStep?.name }]
        ]}
      />
    </Root>
  );
};

const Root = styled.div`
  width: 100%;
  height: 100%;
  // Sorry.. we wanna have a vertical centering, but have a margin-top: auto for the bottom content,
  // therefore we need to set a min-height to make sure the loader eats more space than required
  min-height: 380px;
  display: grid;
  justify-items: center;
`;
