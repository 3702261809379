import { getRoutePath } from 'config/routes';
import { getIsAuthenticated } from 'features/customer/store/selectors';
import { getLists } from 'features/lists/store/actions';
import { getCurrentList } from 'features/lists/store/selectors';
import { ErrorBoundary } from 'features/react-error-boundary/ErrorBoundary';
import { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { RootState } from 'store/rootReducer';
import styled from 'styled-components';
import { useCurrentRoutePath } from 'utils/hooks/useCurrentRoutePath';

import Button from '../../../base/Button';
import { useModal } from '../../../modals';
import { toggleListsWidget } from '../toolbarSlice';
import WidgetHeader from '../WidgetHeader';
import WidgetWrapper from '../WidgetWrapper';
import WordsManager from '../WordsManager/WordsManager';
import SavedListsDropdown from './SavedListsDropdown';

const mapStateToProps = (state: RootState) => ({
  isAuthenticated: getIsAuthenticated(state),
  currentList: getCurrentList(state),
  isLoading: state.lists.isLoading
});

const mapDispatchToProps = {
  closeWidget: () => toggleListsWidget(false),
  getLists: () => getLists.request()
};

type Props = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps;

const ButtonsContainer = styled.div`
  display: flex;
  & > span,
  & button {
    width: 100%;
  }
`;

const ListsPanel = ({ currentList, isLoading, closeWidget, getLists, isAuthenticated }: Props) => {
  const { showModal } = useModal();
  const pathname = useCurrentRoutePath();

  useEffect(() => {
    if (isAuthenticated) {
      getLists();
    }
  }, [getLists, isAuthenticated]);

  const onExploringModule = pathname === getRoutePath('exploring');

  return (
    <WidgetWrapper key="lists">
      <ErrorBoundary>
        <WidgetHeader
          title="lists.header"
          dropdown={SavedListsDropdown}
          isLoading={isLoading}
          closeWidget={closeWidget}
        >
          <ButtonsContainer>
            <Button
              color="primary"
              className="mr-1"
              icon="plus"
              onClick={() => showModal('ADD_LIST_WITH_WORDS')}
            >
              <FormattedMessage id="lists.add_new_list" />
            </Button>
            {onExploringModule ? (
              <Button
                color="primary"
                icon="compass"
                onClick={() =>
                  showModal('EXPLORE_LIST', {
                    listId: currentList.id,
                    listLanguage: currentList.language
                  })
                }
                disabled={!currentList.words.length}
              >
                <FormattedMessage id="common.explore" />
              </Button>
            ) : null}
          </ButtonsContainer>
        </WidgetHeader>
        <WordsManager
          showActions={onExploringModule}
          currentList={currentList}
          addWordsModalId="ADD_WORDS_TO_LIST"
          removeWordsModalId="REMOVE_LIST_WORDS"
        />
      </ErrorBoundary>
    </WidgetWrapper>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ListsPanel);
