/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAction } from '@reduxjs/toolkit';
import { createStandardAction, EmptyAC, PayloadAC } from 'typesafe-actions';

export function createStandardAsyncAction<T1 extends string, T2 extends string, T3 extends string>(
  requestType: T1,
  successType: T2,
  failureType: T3
) {
  function constructor<P1, P2, P3, M1 = undefined, M2 = M1, M3 = M1>() {
    return {
      request: createStandardAction(requestType)<P1, M1>(),
      success: createStandardAction(successType)<P2, M2>(),
      failure: createStandardAction(failureType)<P3, M3>()
    };
  }

  function map<P1, R, P, P3, M1 = undefined, M2 = M1, M3 = M1>(fn: (payload: P, meta: M2) => R) {
    return {
      request: createStandardAction(requestType)<P1, M1>(),
      success: createStandardAction(successType).map<R, P, M2>(fn),
      failure: createStandardAction(failureType)<P3, M3>()
    };
  }

  return Object.assign(constructor, { map });
}

export type StandardAsyncAction<P1, P2, P3, M1 = undefined, M2 = M1, M3 = M1> = {
  request: PayloadAC<any, P1> | EmptyAC<any>;
  success: PayloadAC<any, P2> | EmptyAC<any>;
  failure: PayloadAC<any, P3> | EmptyAC<any>;
};

export const createAsyncAction = <
  TRequestPayload = void,
  TSuccessPayload = void,
  TFailurePayload = void,
  TCancelPayload = void
>(
  commonType: string
) => ({
  request: createAction<TRequestPayload>(`${commonType}Request`),
  success: createAction<TSuccessPayload>(`${commonType}Success`),
  failure: createAction<TFailurePayload>(`${commonType}Failure`),
  cancel: createAction<TCancelPayload>(`${commonType}Cancel`)
});

export const createAsyncSagaThunkAction = <
  TRequestPayload = void,
  TSuccessPayload = void,
  TFailurePayload = void,
  TCancelPayload = void
>(
  commonType: string
) => ({
  request: createAction(`${commonType}Request`, (payload: TRequestPayload) => ({
    payload,
    meta: { thunk: true }
  })),
  success: createAction(`${commonType}Success`, (payload: TSuccessPayload, meta?: any) => ({
    payload,
    meta
  })),
  failure: createAction(`${commonType}Failure`, (payload: TFailurePayload, meta?: any) => ({
    payload,
    meta
  })),
  cancel: createAction(`${commonType}Cancel`, (payload: TCancelPayload, meta?: any) => ({
    payload,
    meta
  }))
});
