// #tech-debt https://app.clickup.com/t/2x7kaeu

import { RouteName } from 'config/routes';

export const helpCenter = 'https://neuroflash.helpscoutdocs.com/';

export const termsOfUse = 'https://neuroflash.com/terms/';

export const contact = 'https://neuroflash.com/contact/';

export const profilePage: RouteName = 'profileInfo';
