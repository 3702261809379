import { getGetIsFeatureAvailable } from 'features/features/store/selectors';
import { FormattedMessage } from 'react-intl';
import { TableInstance } from 'react-table';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import styled from 'styled-components';

import Button from '../../components/base/Button';
import { useModal } from '../../components/modals';
import Tooltip from '../../components/tooltips/Tooltip';
import { updateList } from '../lists/store/actions';
import { getActiveTab, getCurrentTabModelData, getNumberOfSelectedRows } from './store/selectors';
import { deselectAllRows, selectMultipleRows, toggleTabProperty } from './store/slice';
import { ConceptFlashTabData } from './store/types';

const ActionButton = styled(Button)`
  margin-left: 0.5rem;

  @media (max-width: 576px) {
    display: block;
    width: 100%;
    margin: 0 1rem 0.5rem 0;
  }
`;

const TableActions = ({ page }: TableInstance<ConceptFlashTabData>) => {
  const { showModal } = useModal();
  const dispatch = useAppDispatch();

  const { has_translation: canBeTranslated } = useAppSelector(getCurrentTabModelData);
  const { fromList, translationEnabled, data, listId } = useAppSelector(getActiveTab);
  const selectedItemsCount = useAppSelector(getNumberOfSelectedRows);
  const exportToList = useAppSelector(getGetIsFeatureAvailable)('exportList');

  return (
    <>
      <Tooltip
        content={
          <>
            <FormattedMessage
              id="words_table.selected_items"
              values={{ selected: selectedItemsCount }}
            />
            <FormattedMessage
              id={
                selectedItemsCount > 0
                  ? 'words_table.selected_items.some_action'
                  : 'words_table.selected_items.0_action'
              }
            />
          </>
        }
      >
        <ActionButton
          color="secondary"
          icon="check-circle"
          onClick={() => {
            if (selectedItemsCount > 0) {
              dispatch(deselectAllRows());
            } else {
              const wordsToSelect = page.map(row => row.id);
              dispatch(selectMultipleRows(wordsToSelect));
            }
          }}
        >
          {selectedItemsCount}
        </ActionButton>
      </Tooltip>

      {canBeTranslated ? (
        <Tooltip
          content={
            <FormattedMessage
              id={
                !translationEnabled
                  ? 'words_table.enable_translation'
                  : 'words_table.disable_translation'
              }
            />
          }
        >
          <ActionButton
            color={translationEnabled ? 'primary' : 'secondary'}
            icon="globe"
            onClick={() => dispatch(toggleTabProperty('translationEnabled'))}
          />
        </Tooltip>
      ) : null}

      {exportToList ? (
        !fromList ? (
          <ActionButton
            color="primary"
            icon="list"
            onClick={() => showModal('NEW_LIST_FROM_TABLE')}
          >
            <FormattedMessage id="lists.save_to_list" />
          </ActionButton>
        ) : (
          <>
            <ActionButton
              color="secondary"
              icon="git-merge"
              onClick={() => showModal('MERGE_LISTS')}
            >
              <FormattedMessage id="merge_lists.title" />
            </ActionButton>
            <ActionButton
              color="primary"
              icon="save"
              onClick={() => {
                if (listId) {
                  dispatch(
                    updateList.request(
                      {
                        words: data.map(i => i.word),
                        id: listId
                      },
                      { thunk: true }
                    )
                  );
                }
              }}
            >
              <FormattedMessage id="lists.save_changes" />
            </ActionButton>
          </>
        )
      ) : null}
    </>
  );
};

export default TableActions;
