import { Tooltip } from '@mui/material';
import { FeatureTableRowData } from 'components/profile/components/feature-comparison/FeatureTableRowData';
import { FirstCell } from 'components/profile/components/pricing-plan-card/Components';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

type Props = Pick<FeatureTableRowData, 'nameTranslationKey' | 'tooltipTranslationKey'>;

export const FeatureTableHeadCell = ({ nameTranslationKey, tooltipTranslationKey }: Props) => {
  if (tooltipTranslationKey) {
    return (
      <FirstCell component="th" scope="row">
        <Tooltip
          title={
            <FormattedMessage
              id={tooltipTranslationKey}
              values={{ b: (text: string) => <strong>{text}</strong>, br: <br /> }}
            />
          }
          placement="top"
        >
          <UnderlinedText>
            <FormattedMessage id={nameTranslationKey} />
          </UnderlinedText>
        </Tooltip>
      </FirstCell>
    );
  }

  return (
    <FirstCell component="th" scope="row">
      <FormattedMessage id={nameTranslationKey} />
    </FirstCell>
  );
};

const UnderlinedText = styled.span`
  text-decoration: underline;
  text-underline-position: under;
`;
