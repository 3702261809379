import { SelectOption } from 'components/forms/SelectField';
import { AiWriterSupportedLanguageAndCountryCode } from 'features/aiWriter/aiWriterSupportedLanguageAndCountryCodes';
import { SystemTonality } from 'features/aiWriter/tonality/SystemTonality';
import { translatedTonalities } from 'features/aiWriter/tonality/translatedTonalities';

export type TonalityOption = SelectOption<string>;

const systemTonalities: SystemTonality[] = Array.from(SystemTonality);

export function makeTonalityOptions(
  languageAndCountryCode: AiWriterSupportedLanguageAndCountryCode
): TonalityOption[] {
  return systemTonalities.map(tonality => {
    const translatedTonality = translatedTonalities[languageAndCountryCode][tonality];

    return {
      value: translatedTonality,
      label: translatedTonality
    };
  });
}
