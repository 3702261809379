export const colorPalette = {
  black: '#1e1e1e',
  white: '#ffffff',
  nearWhite: '#e1e1e1',

  beige__50: '#fef6f4',
  beige__100main: '#e9dfdd',
  beige__200: '#c2b1b0',
  beige__300: '#957e7d',
  beige__400: '#6c4f4e',
  beige__500: '#512f2f',

  blue__50: '#e4f2fc',
  blue__100: '#bfddf8',
  blue__200: '#97c8f3',
  blue__300: '#6eb1f0',
  blue__400: '#54a0ed',
  blue__500main: '#3771c8',
  blue__600: '#3563b3',
  blue__700: '#2b4594',
  blue__800: '#283f86',
  blue__900: '#1c2f67',

  dark__50: '#f7f4f4',
  dark__100: '#f2eeee',
  dark__200: '#e1dfdf',
  dark__300: '#d2d2d2',
  dark__400: '#bebdbd',
  dark__500: '#9a9a9a',
  dark__600: '#717171',
  dark__700: '#5e5e5e',
  dark__800: '#3f3f3f',
  dark__900main: '#1e1e1e',

  green__50: '#e6f4f1',
  green__100: '#b3ecf1',
  green__200: '#50d3e0',
  green__300: '#2cc9d9',
  green__400: '#0fbfd4',
  green__500main: '#0eaec1',
  green__600: '#1d9aa6',
  green__700: '#19868f',
  green__800: '#116364',
  green__900: '#0f5159',

  orange__50: '#fffde7',
  orange__100: '#fff39d',
  orange__200: '#fed53b',
  orange__300: '#febc34',
  orange__400: '#fda42e',
  orange__500main: '#fe7904',
  orange__600: '#dd6f0f',
  orange__700: '#cc680f',
  orange__800: '#aa5e1b',
  orange__900: '#935019',

  pinkRose__50: '#fffbff',
  pinkRose__100: '#fcf4fc',
  pinkRose__200: '#f5e9f5',
  pinkRose__300: '#f0d9f0',
  pinkRose__400: '#efd3ef',
  pinkRose__500main: '#e4b7e4',
  pinkRose__600: '#cf88d1',
  pinkRose__700: '#b95abc',
  pinkRose__800: '#a934ad',
  pinkRose__900: '#99159e',

  red__50: '#ffebf0',
  red__100: '#ffcdd7',
  red__200: '#fb98a0',
  red__300: '#f66e7b',
  red__400: '#ff4559',
  red__500main: '#fd173e',
  red__600: '#e80c3c',
  red__700: '#da2223',
  red__800: '#ce0929',
  red__900: '#811313',

  yellow__50: '#fffaec',
  yellow__100: '#fff8e2',
  yellow__200: '#ffebb6',
  yellow__300: '#fedf88',
  yellow__400: '#fed35b',
  yellow__500main: '#fed404',
  yellow__600: '#febf2f',
  yellow__700: '#fdb12b',
  yellow__800: '#fd9f28',
  yellow__900: '#fd8e26'
};
