import { useScoreTexts } from 'features/flashScore/useScoreTexts';

type Arguments = Omit<Parameters<typeof useScoreTexts>[0], 'texts'> & {
  text: string;
};

export const useScoreProvidedText = (args: Arguments) => {
  const { data, ...rest } = useScoreTexts({
    ...args,
    texts: [args.text]
  });

  return {
    ...rest,
    data: data?.[0]
  };
};
