import { Checkbox, Divider, ListItemIcon, MenuItem, SvgIcon, Tooltip } from '@mui/material';
import { ReactComponent as EditorEditSettingsIcon } from 'assets/icons/icon-editor-edit-settings.svg';
import { MenuDropdown } from 'components/MenuDropdown/MenuDropdown';
import {
  EditorFormatButton,
  EditorFormatButtonProps,
  EditorIconFormatButton
} from 'features/aiWriter/AiWriterTextEditor/EditorIconFormatButton';
import {
  COLOR_PALETTE,
  COLOR_PALETTE_MAP_DARK_MODE,
  useEditorSettingsStore,
  useIsEditorBackgroundPersistent
} from 'features/aiWriter/AiWriterTextEditor/store/useEditorSettingsStore';
import { getIconColor } from 'features/aiWriter/AiWriterTextEditor/utils/getIconColor';
import { topBarDropdownAlignmentConfig } from 'features/aiWriter/AiWriterTextEditor/utils/topBarDropdownAlignmentConfig';
import FormattedMessage from 'features/i18n/FormattedMessage';
import { trackingWrapper } from 'features/tracking/wrapper/TrackingWrapper';
import { GAEvents } from 'services/tracking/GAEvents';
import styled from 'styled-components';
import { useIsDarkMode } from 'styles/new-theme/useAppThemeStore';
import useDropdown from 'utils/hooks/useDropdown';

type DocumentCustomizationButtonProps = {
  styleVariant?: EditorFormatButtonProps;
  onClose?: () => void;
};

export function DocumentCustomizationButton(props: DocumentCustomizationButtonProps) {
  const { styleVariant, onClose } = props;
  const hideEditorBorder = useEditorSettingsStore(state => state.hideBorder);
  const setHideEditorBorder = useEditorSettingsStore(state => state.setHideBorder);
  const editorBackgroundColor = useEditorSettingsStore(state => state.backgroundColor);
  const setEditorBackgroundColor = useEditorSettingsStore(state => state.setBackgroundColor);
  const [isEditorBackgroundColorPersistent, toggleIsEditorBackgroundColorPersistent] =
    useIsEditorBackgroundPersistent();
  const isDarkMode = useIsDarkMode();

  const { anchorElement, close, isOpen, open } = useDropdown();

  const handlePagelessChange = () => {
    setHideEditorBorder(!hideEditorBorder);

    if (!hideEditorBorder) {
      GAEvents.editorBackgroundDisabled();
      trackingWrapper.track('editorBackgroundDisabled');
    } else {
      GAEvents.editorBackgroundEnabled();
      trackingWrapper.track('editorBackgroundEnabled');
    }
  };

  const handleMenuItemClick = (color: string) => {
    setEditorBackgroundColor(color);

    GAEvents.editorBackgroundChange({ color });
    trackingWrapper.track('editorBackgroundChange', {
      color
    });

    onClose?.();
  };

  const handleStoreAsDefaultClick = () => {
    toggleIsEditorBackgroundColorPersistent();
  };

  const trigger =
    styleVariant && styleVariant === 'icon' ? (
      <EditorIconFormatButton onClick={open}>
        <Tooltip
          arrow
          title={<FormattedMessage id="aiWriter.editor.doc_customization.tooltip" />}
          placement="bottom"
        >
          <SvgIcon color={getIconColor(false)} fontSize="small">
            <EditorEditSettingsIcon />
          </SvgIcon>
        </Tooltip>
      </EditorIconFormatButton>
    ) : (
      <EditorFormatButton variant="text" onClick={open}>
        <SvgIcon color={getIconColor(false)} fontSize="small">
          <EditorEditSettingsIcon />
        </SvgIcon>
        <FormattedMessage id="aiWriter.editor.doc_customization.tooltip" />
      </EditorFormatButton>
    );

  return (
    <MenuDropdown
      trigger={trigger}
      anchorElement={anchorElement}
      close={close}
      isOpen={isOpen}
      width="fit-content"
      anchorOrigin={topBarDropdownAlignmentConfig.leftAlignment.anchorOrigin}
      transformOrigin={topBarDropdownAlignmentConfig.leftAlignment.transformOrigin}
    >
      <StyledMenuItem selected={hideEditorBorder} onClick={handlePagelessChange}>
        <ListItemIcon>
          <StrikeThroughColorBox $color="#FFFFFF" />
        </ListItemIcon>
        <FormattedMessage id="aiWriter.editor.doc_customization.pageless.label" />
      </StyledMenuItem>
      {Object.entries(COLOR_PALETTE).map(([key, value]) => (
        <StyledMenuItem
          key={key}
          disabled={hideEditorBorder}
          selected={!hideEditorBorder && editorBackgroundColor === value}
          onClick={() => handleMenuItemClick(value)}
        >
          <ListItemIcon>
            <ColorBox $color={isDarkMode ? COLOR_PALETTE_MAP_DARK_MODE[value] : value} />
          </ListItemIcon>
          <FormattedMessage
            id={`aiWriter.editor.doc_customization.background.${key.toLowerCase()}`}
          />
        </StyledMenuItem>
      ))}
      <Divider />
      <StyledMenuItem onClick={handleStoreAsDefaultClick}>
        <ListItemIcon>
          <StyledCheckbox checked={isEditorBackgroundColorPersistent} color="default" />
        </ListItemIcon>
        <FormattedMessage id="aiWriter.editor.doc_customization.background.save" />
      </StyledMenuItem>
    </MenuDropdown>
  );
}

const ColorBox = styled.div<{ $color: string }>`
  background-color: ${({ $color }) => $color};

  width: 24px;
  height: 24px;

  border: 1px solid rgba(0, 0, 0, 0.23);
  border-radius: ${({ theme }) => theme.borderRadius.small};
`;

const StrikeThroughColorBox = styled(ColorBox)`
  position: relative;

  &:before {
    position: absolute;
    content: '';
    left: 0;
    right: 0;
    top: 50%;
    transform: rotate(-45deg);
    border-top: 1px solid;
    border-color: inherit;
  }
`;

const StyledCheckbox = styled(Checkbox)`
  padding: 0;
  margin: 0;
`;

const StyledMenuItem = styled(MenuItem)`
  gap: ${({ theme }) => theme.spacings.medium};

  > :first-child {
    min-width: 0;
  }
`;
