import { CircularProgress } from '@mui/material';
import useShowSetupModal from 'features/aiWriter/modals/setup/useSetupModal';
import { useEnableSidebarOnMount } from 'features/pageSidebar/useSidebarStore';
import { useEffect } from 'react';
import styled from 'styled-components';

export const NewDocumentPage = () => {
  const showSetupModal = useShowSetupModal();

  useEnableSidebarOnMount();

  useEffect(() => {
    showSetupModal();
  }, [showSetupModal]);

  return (
    <ProgressRoot>
      <CircularProgress />
    </ProgressRoot>
  );
};

const ProgressRoot = styled.div`
  display: grid;
  place-content: center;
  padding: 3rem;
`;
