import { Fab } from '@mui/material';
import { FormattedMessage } from 'react-intl';

type Props = {
  id?: string;
};

export const NextFabButton = ({ id }: Props) => (
  <Fab color="primary" variant="extended" type="submit" id={id}>
    <FormattedMessage id="common.next" />
  </Fab>
);
