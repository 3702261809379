import { getCurrentLanguage } from 'features/language/store/selectors';
import { AppLanguage } from 'features/language/store/types';
import { useEffect, useState } from 'react';
import { useAppSelector } from 'store/hooks';
import getBrowserLanguage from 'utils/getBrowserLanguage';

const beaconIdByLanguage: Record<string, string> = {
  de: '6db62abe-d5f0-4b5a-8ed5-efff655499b8',
  en: 'a51effdf-d8c2-4fbc-aaa1-1053f881f876',
  es: '53e8587b-1140-488f-aa3a-eba8ad1e050b'
};

const getBeaconId = (params: { browserLanguage: string; appLanguage: AppLanguage }) => {
  const { appLanguage, browserLanguage } = params;

  // We prefer app language over browser language
  // But if the browser language is not supported (in app languages), prefer browser language
  // e.g. with browser language "es", we prefer "es" as long our app doesn't support it
  if (
    appLanguage !== browserLanguage &&
    !Object.values<string>(AppLanguage).includes(browserLanguage)
  ) {
    if (beaconIdByLanguage[browserLanguage]) {
      return beaconIdByLanguage[browserLanguage];
    }
  }

  if (beaconIdByLanguage[appLanguage]) {
    return beaconIdByLanguage[appLanguage];
  }

  return beaconIdByLanguage.de;
};

export const HelpscoutBeaconLoader = () => {
  const [currentBeaconId, setCurrentBeaconId] = useState<string | null>(null);
  const browserLanguage = getBrowserLanguage();
  const appLanguage = useAppSelector(getCurrentLanguage);

  const beaconId = getBeaconId({ appLanguage, browserLanguage });

  /**
   * Currently HelpScout get initialized multiple times for the same beacon, which
   * seems to not work after the second of third time.
   * This can be forced by logout and login again, after that the chat icon wasn't shown all the time.
   * To fix this, we store the current used beaconId in a state and only initialize it if that changes.
   */
  useEffect(() => {
    if (beaconId !== currentBeaconId) {
      setCurrentBeaconId(beaconId);
    }
  }, [beaconId, currentBeaconId]);

  useEffect(() => {
    if (!currentBeaconId) {
      return;
    }

    loadBeacon(currentBeaconId);

    /**
     * We don't destroy the beacon on unload, but only during loadBeacon() call.
     * The reason for this is backwards compatiblity because we also load helpscout using Google TaggManager.
     * Therefore, we have to check if it's loaded and destroy it before calling "init".
     * This happens in loadBeacon() function.
     * If we call "destroy" in this unload callback too, "destroy" would be called twice in a row
     * during language switch, because the unmounted happens before loadBeacon() gets called after remount.
     * Unfortunately, after calling "destroy" multiple times, "init" won't work anymore (for unknown reason).
     * That's why we only call "destroy" in loadBeacon() function, to ensure it's only called once.
     */
  }, [currentBeaconId]);

  return null;
};

function hasLoadedBeacon() {
  return typeof window.Beacon !== 'undefined';
}

function initBeacon(beaconId: string) {
  window.Beacon('config', {
    display: {
      style: 'manual',
      position: 'left'
    }
  });
  window.Beacon?.('init', beaconId);
}

function loadBeacon(beaconId: string) {
  if (hasLoadedBeacon()) {
    window.Beacon?.('destroy');
    initBeacon(beaconId);
    return;
  }

  /* Nicer formatted original helpscout script */
  window.Beacon = function (method: unknown, options: unknown, data: unknown) {
    window.Beacon.readyQueue.push({
      method,
      options,
      data
    });
  };

  window.Beacon.readyQueue = [];

  const script = document.createElement('script');
  script.type = 'text/javascript';
  script.onload = () => {
    initBeacon(beaconId);
  };
  script.src = 'https://beacon-v2.helpscout.net';
  script.defer = true;
  document.getElementsByTagName('body')[0].appendChild(script);
  /* End original helpscout script */
}
