import { Typography } from '@mui/material';
import DeleteIconButton from 'components/iconButtons/DeleteIconButton';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import useTr from 'utils/hooks/useTr';

type Props = {
  title: string | React.ReactElement;
  onCancel?: () => void;
};

const ModalTitle = ({ title, onCancel }: Props) => {
  const tr = useTr();

  return (
    <Root>
      {typeof title === 'string' ? (
        <Title variant="h5">
          <FormattedMessage id={title} />
        </Title>
      ) : (
        title
      )}
      {onCancel && <DeleteIconButton onClick={onCancel} tooltip={tr('common.close')} />}
    </Root>
  );
};

const Root = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Title = styled(Typography)`
  && {
    color: ${({ theme }) => theme.colors.primary};
  }
`;

export default ModalTitle;
