import { Clear, ColorLensRounded, Delete } from '@mui/icons-material';
import { CircularProgress, IconButton, TablePagination, Typography } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import FlexContainer from 'components/FlexContainer';
import { SearchField } from 'components/SearchField';
import { TagChipColors } from 'features/aiWriter/AiWriterProjectOverview/tags/AiWriterProjectTag';
import { ColorPickerMenu } from 'features/aiWriter/AiWriterProjectOverview/tags/ColorPickerMenu';
import { TableViewOptions } from 'features/aiWriter/AiWriterProjectOverview/tags/ManageTagsModal';
import { TagsTable } from 'features/aiWriter/AiWriterProjectOverview/tags/TagsTable';
import { useGetAllTagsPaginated } from 'features/aiWriter/AiWriterProjectOverview/tags/useGetAllTags';
import FormattedMessage from 'features/i18n/FormattedMessage';
import { ChangeEvent, useState } from 'react';
import { httpDeleteTag } from 'services/backofficeIntegration/http/endpoints/aiWriter/httpDeleteTag';
import { invalidateGetAllTagsQuery } from 'services/backofficeIntegration/http/endpoints/aiWriter/httpGetAllTags';
import { invalidateProjectsQuery } from 'services/backofficeIntegration/http/endpoints/aiWriter/httpGetProjects';
import { httpUpdateTag } from 'services/backofficeIntegration/http/endpoints/aiWriter/httpUpdateTag';
import styled from 'styled-components';
import { useDebounce } from 'use-debounce/lib';
import { useMenu } from 'utils/hooks/useMenu';

type Props = {
  type: TableViewOptions;
};

export const TagsListSection = ({ type }: Props) => {
  const [selectedProjectIds, setSelectedProjectIds] = useState<number[]>([]);
  const areProjectsSelected = selectedProjectIds.length !== 0;

  const [searchQuery, setSearchQuery] = useState<string>('');
  const [debouncedSearchQuery] = useDebounce(searchQuery, 300);

  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(5);
  const offset = limit * page;

  const qParam = debouncedSearchQuery !== '' ? debouncedSearchQuery : undefined;
  const allTagsParams =
    type !== 'all' ? { view_mode: type, q: qParam, offset, limit } : { q: qParam, offset, limit };
  const { data: tags, isLoading, isError } = useGetAllTagsPaginated(allTagsParams);
  const allTags = tags?.data;
  const total = tags?.total;

  const oneSelectedProject =
    selectedProjectIds.length === 1
      ? allTags?.find(p => selectedProjectIds[0] === p.id)
      : undefined;

  const {
    anchorEl: colorsAnchorEl,
    isOpen: isColorsMenuOpen,
    onTriggerClick: onColorIconTriggerClick,
    onMenuClose: onColorsMenuClose
  } = useMenu();

  const { mutate: deleteTag, isLoading: isDeleteLoading } = useMutation({
    mutationFn: httpDeleteTag.callEndpoint,
    onSuccess: async () => {
      invalidateGetAllTagsQuery();
      invalidateProjectsQuery();
    }
  });

  const { mutate: updateTag, isLoading: isUpdateLoading } = useMutation({
    mutationFn: httpUpdateTag.callEndpoint,
    onSuccess: async () => {
      invalidateGetAllTagsQuery();
    }
  });

  function handleChangePage(_event: unknown | null, page: number) {
    setPage(page);
  }

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  };

  function handleSearchChange(e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    setSearchQuery(e.target.value);
    setPage(0);
  }

  const handleDeleteSelected = () => {
    deleteTag(selectedProjectIds);
    handleClearSelected();
  };

  const handleClearSelected = () => {
    setSelectedProjectIds([]);
  };

  const updateTagColor = (c: string) => {
    if (!oneSelectedProject) {
      return;
    }
    updateTag({
      id: oneSelectedProject.id,
      name: oneSelectedProject.name,
      color: TagChipColors[c]
    });
  };

  const renderTags = () => {
    if (!allTags) {
      return null;
    }

    return (
      <TagsTable
        tags={allTags}
        selectedProjectIds={selectedProjectIds}
        setSelectedProjectIds={setSelectedProjectIds}
      />
    );
  };

  const renderSectionLabel = () => {
    if (isDeleteLoading || isUpdateLoading) {
      return (
        <FlexContainer justifyContent="center" alignItems="center">
          <CircularProgress />
        </FlexContainer>
      );
    }

    if (areProjectsSelected) {
      return (
        <FlexContainer direction="row" alignItems="center" gap="small">
          <IconButton onClick={handleClearSelected}>
            <Clear />
          </IconButton>
          <Typography variant="subtitle1">
            <FormattedMessage
              id="aiWriter.project_overview.new_projects.selected"
              values={{
                count: selectedProjectIds.length
              }}
            />
          </Typography>
          {selectedProjectIds.length === 1 && (
            <IconButton onClick={onColorIconTriggerClick}>
              <ColorLensRounded />
            </IconButton>
          )}
          <IconButton onClick={handleDeleteSelected}>
            <Delete />
          </IconButton>
        </FlexContainer>
      );
    }

    return (
      <Typography variant="subtitle1">
        <FormattedMessage id="common.all_documents" />
      </Typography>
    );
  };

  if (isError) {
    return null;
  }
  return (
    <Root>
      <FlexContainer direction="row" alignItems="center" justifyContent="space-between">
        {renderSectionLabel()}

        <FlexContainer direction="row" gap="small">
          <SearchField value={searchQuery} onChange={handleSearchChange} />
        </FlexContainer>
      </FlexContainer>

      {isLoading && (
        <FlexContainer justifyContent="center" alignItems="center">
          <CircularProgress />
        </FlexContainer>
      )}

      {allTags?.length !== 0 ? (
        <>
          {renderTags()}

          <TablePagination
            count={total ?? 0}
            page={page}
            rowsPerPage={limit}
            onPageChange={handleChangePage}
            rowsPerPageOptions={[5, 10, 25]}
            onRowsPerPageChange={handleChangeRowsPerPage}
            component="div"
          />
        </>
      ) : (
        <Typography>
          <FormattedMessage id="tags.modal.no_tags.label" />
        </Typography>
      )}
      <ColorPickerMenu
        anchorEl={colorsAnchorEl}
        isOpen={isColorsMenuOpen}
        handleCloseMenu={onColorsMenuClose}
        color={oneSelectedProject?.color ?? undefined}
        onClick={updateTagColor}
      />
    </Root>
  );
};

const Root = styled(FlexContainer).attrs({ gap: 'three' })`
  width: 100%;
`;
