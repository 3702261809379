export const aiWriterSupportedLanguageAndCountryCodes = {
  de_de: 'de_de', // German (Germany)
  de_ch: 'de_ch', // German (Switzerland)
  de_at: 'de_at', // German (Austria)
  en_us: 'en_us', // English (United States)
  en_uk: 'en_uk', // English (UK)
  fr_fr: 'fr_fr', // French (France)
  fr_ch: 'fr_ch', // French (Switzerland)
  es_es: 'es_es', // Spanish; Castilian
  ca_es: 'ca_es', // Spanish; Catalan
  nl_nl: 'nl_nl', // Dutch; Flemish
  it_it: 'it_it', // Italian
  pl_pl: 'pl_pl', // Polish
  pt_pt: 'pt_pt', // Portuguese (Portugal)
  pt_br: 'pt_br', // Portuguese (Brazil)
  es_mx: 'es_mx', // Spanish (Mexico)
  sv_se: 'sv_se', // Swedish
  cs_cz: 'cs_cz', // Czech
  hu_hu: 'hu_hu', // Hungarian
  hr_hr: 'hr_hr', // Croatian
  zh_cn: 'zh_cn', // Chinese (China)
  tr_tr: 'tr_tr' // Turkish
} as const;

export type AiWriterSupportedLanguageAndCountryCode =
  typeof aiWriterSupportedLanguageAndCountryCodes[keyof typeof aiWriterSupportedLanguageAndCountryCodes];

const setOfAiWriterSupportedLanguageAndCountryCodes = new Set(
  Object.values(aiWriterSupportedLanguageAndCountryCodes)
);

export const isAiWriterSupportedLanguageAndCountryCode = (
  value: string
): value is AiWriterSupportedLanguageAndCountryCode =>
  setOfAiWriterSupportedLanguageAndCountryCodes.has(
    value as AiWriterSupportedLanguageAndCountryCode
  );
