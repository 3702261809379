import axios from 'axios';
import { isObject } from 'utils/isObject';

export const getErrorCodeFromAxiosError = (error: unknown) => {
  if (!axios.isAxiosError(error) || !isObject(error.response?.data)) {
    return null;
  }

  if (typeof error.response?.data.message !== 'string') {
    return null;
  }

  return error.response?.data.message;
};
