import { BaseSuccessDto } from 'services/backofficeIntegration/http/BaseSuccessDto';
import { backofficeClient } from 'services/backofficeIntegration/http/client/createBackofficeClient';
import { mutationEndpoint } from 'services/backofficeIntegration/http/endpointDefinition';

type SuccessDto = BaseSuccessDto<never>;

export type FavoriteOutputTypeRequestParams = {
  outputType: string;
  is_favorite: boolean;
};

export const httpFavoriteOutputType = mutationEndpoint({
  callEndpoint: (params: FavoriteOutputTypeRequestParams) =>
    backofficeClient
      .put<SuccessDto>(
        `/output-types/${params.outputType}`,
        { is_favorite: params.is_favorite }
      )
      .then(response => response.data)
});
