import {
  resolutionOptionMap,
  useAiImagesGeneratorContext
} from 'features/aiImages/AiImagesPage/AiImagesGeneratorContext';
import { GeneratorFormContent } from 'features/aiImages/AiImagesPage/GeneratorFormContent';
import { useSelectRandomImageExample } from 'features/aiImages/AiImagesPage/promptExamples';
import { GeneratorFormSidebarContent } from 'features/aiWriter/AiWriterSidebar/steps/images/GeneratorFormSidebarContent';
import { ReactElement, useCallback } from 'react';

type GeneratorFormProps = {
  isInsideContentFlash?: boolean;
};

export const GeneratorForm = ({ isInsideContentFlash }: GeneratorFormProps): ReactElement => {
  const {
    prompt,
    setPrompt,
    setInitialPrompt,
    generateImage,
    numberOfImages,
    optimizedPrompt,
    undoPromptOptimization,
    resolution,
    isImagesFetching,
    imageQuality
  } = useAiImagesGeneratorContext();

  const getRandomImageSample = useSelectRandomImageExample();

  const handleTextChange = useCallback(
    (text: string) => {
      setPrompt(text);
      setInitialPrompt(text);
    },
    [setInitialPrompt, setPrompt]
  );

  const handleExampleButtonClick = useCallback(() => {
    setPrompt(getRandomImageSample());
  }, [setPrompt, getRandomImageSample]);

  const handleUndoClick = useCallback(() => {
    undoPromptOptimization();
  }, [undoPromptOptimization]);

  const handleGenerateButtonClick = useCallback(() => {
    generateImage({
      prompt,
      numberOfImages,
      width: resolutionOptionMap[resolution].width,
      height: resolutionOptionMap[resolution].height,
      imageQuality
    });
  }, [generateImage, prompt, numberOfImages, resolution, imageQuality]);

  return isInsideContentFlash ? (
    <GeneratorFormSidebarContent
      isImagesFetching={isImagesFetching}
      handleExampleButtonClick={handleExampleButtonClick}
      handleGenerateButtonClick={handleGenerateButtonClick}
      handleTextChange={handleTextChange}
      handleUndoClick={handleUndoClick}
      optimizedPrompt={optimizedPrompt}
      prompt={prompt}
    />
  ) : (
    <GeneratorFormContent
      isImagesFetching={isImagesFetching}
      handleExampleButtonClick={handleExampleButtonClick}
      handleGenerateButtonClick={handleGenerateButtonClick}
      handleTextChange={handleTextChange}
      handleUndoClick={handleUndoClick}
      optimizedPrompt={optimizedPrompt}
      prompt={prompt}
    />
  );
};
