import FormatClearIcon from '@mui/icons-material/FormatClear';
import { Tooltip } from '@mui/material';
import {
  EditorFormatButton,
  EditorFormatButtonProps,
  EditorIconFormatButton
} from 'features/aiWriter/AiWriterTextEditor/EditorIconFormatButton';
import { trackEditorFormatAction } from 'features/aiWriter/AiWriterTextEditor/trackEditorFormatAction';
import { getIconColor } from 'features/aiWriter/AiWriterTextEditor/utils/getIconColor';
import { useClearEditorFormatting } from 'features/aiWriter/AiWriterTextEditor/utils/useClearFormatting';
import FormattedMessage from 'features/i18n/FormattedMessage';

type ClearFormattingButtonProps = {
  styleVariant?: EditorFormatButtonProps;
  onClose?: () => void;
};

export function ClearFormattingButton({ onClose, styleVariant }: ClearFormattingButtonProps) {
  const clearFormatting = useClearEditorFormatting();

  const handleClick = () => {
    trackEditorFormatAction('clear_formatting');

    clearFormatting();

    onClose && onClose();
  };

  if (styleVariant === 'icon') {
    return (
      <EditorIconFormatButton onClick={handleClick}>
        <Tooltip arrow title={<FormattedMessage id="aiWriter.toolbar.clear" />} placement="bottom">
          <FormatClearIcon color={getIconColor(false)} fontSize="small" />
        </Tooltip>
      </EditorIconFormatButton>
    );
  }

  return (
    <EditorFormatButton onClick={handleClick} variant="text" $isActive={false}>
      <FormatClearIcon color={getIconColor(false)} fontSize="small" />
      <FormattedMessage id="aiWriter.toolbar.clear" />
    </EditorFormatButton>
  );
}
