import useShowSetupModal from 'features/aiTester/modals/setup/useShowSetupModal';
import { closeTab, setCurrentTab } from 'features/aiTester/store/actions/tabs/actions';
import { useCallback } from 'react';
import { useAppDispatch } from 'store/hooks';

export const useAiTesterTab = () => {
  const dispatch = useAppDispatch();
  const showSetupModal = useShowSetupModal();

  const onSelect = useCallback(
    (tabId: string) => {
      dispatch(setCurrentTab(tabId));
    },
    [dispatch]
  );

  const onCreate = useCallback(() => {
    showSetupModal();
  }, [showSetupModal]);

  const onClose = useCallback(
    (tabId: string) => {
      dispatch(closeTab(tabId));
    },
    [dispatch]
  );

  return { onCreate, onSelect, onClose };
};
