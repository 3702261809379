import { TabContext, TabPanel } from '@mui/lab';
import { Tab, Tabs, Typography } from '@mui/material';
import EmbedContainer from 'components/EmbedContainer';
import { SmallerSection, Tile } from 'components/layout/toolbar/modals/HelpAndInfo/styles';
import { useYoutubePlaylist } from 'components/layout/toolbar/modals/HelpAndInfo/useYoutubePlaylist';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { mobileWindowWidthCSS } from 'utils/hooks/useMobileVersion';
import useTr from 'utils/hooks/useTr';
import { safeEnum } from 'utils/safeEnum';

const VideoPlaylistTabs = safeEnum.make({
  webinars: 'webinars',
  features: 'features',
  useCases: 'useCases'
});

type VideoPlaylistTabs = safeEnum.infer<typeof VideoPlaylistTabs>;

const trimText = (text: string) => {
  return text.length > 40 ? `${text.slice(0, 40)}...` : text;
};

export const VideoTutorials = () => {
  const [value, setValue] = useState<string>(VideoPlaylistTabs.features);
  const translate = useTr();
  const featuresPlaylistId = translate(
    'layout.sidebar.actions.help.video_tutorials.features_playlist.link'
  );
  const { videos: featuresVideos } = useYoutubePlaylist(featuresPlaylistId);
  const webinarsPlaylistId = translate(
    'layout.sidebar.actions.help.video_tutorials.webinars_playlist.link'
  );
  const { videos: webinarsVideos } = useYoutubePlaylist(webinarsPlaylistId);
  const useCasesPlaylistId = translate(
    'layout.sidebar.actions.help.video_tutorials.use_cases_playlist.link'
  );
  const { videos: useCasesVideos } = useYoutubePlaylist(useCasesPlaylistId);

  return (
    <SmallerSection>
      <SmallerSection>
        <Typography variant="h6">
          <FormattedMessage id="layout.sidebar.actions.help.video_tutorials.title" />
        </Typography>
      </SmallerSection>
      <TabContext value={value}>
        <Tabs value={value} onChange={(_, newValue) => setValue(newValue)}>
          <StyledTab
            value={VideoPlaylistTabs.features}
            label={translate('layout.sidebar.actions.help.video_tutorials.features_playlist.title')}
          />
          <StyledTab
            value={VideoPlaylistTabs.webinars}
            label={translate('layout.sidebar.actions.help.video_tutorials.webinars_playlist.title')}
          />
          <StyledTab
            value={VideoPlaylistTabs.useCases}
            label={translate(
              'layout.sidebar.actions.help.video_tutorials.use_cases_playlist.title'
            )}
          />
        </Tabs>
        <StyledTabPanel value={VideoPlaylistTabs.features}>
          <VideoContainer>
            {featuresVideos.length > 0 &&
              featuresVideos.map(({ videoId, title }) => (
                <Tile key={videoId}>
                  <Typography variant="subtitle1">{trimText(title)}</Typography>
                  <EmbedContainer
                    embedSrc={`https://www.youtube.com/embed/${videoId}`}
                    resolution="16by9"
                  />
                </Tile>
              ))}
          </VideoContainer>
        </StyledTabPanel>
        <StyledTabPanel value={VideoPlaylistTabs.webinars}>
          <VideoContainer>
            {webinarsVideos.length > 0 &&
              webinarsVideos.map(({ videoId, title }) => (
                <Tile key={videoId}>
                  <Typography variant="subtitle1">{trimText(title)}</Typography>
                  <EmbedContainer
                    embedSrc={`https://www.youtube.com/embed/${videoId}`}
                    resolution="16by9"
                  />
                </Tile>
              ))}
          </VideoContainer>
        </StyledTabPanel>
        <StyledTabPanel value={VideoPlaylistTabs.useCases}>
          <VideoContainer>
            {useCasesVideos.length > 0 &&
              useCasesVideos.map(({ videoId, title }) => (
                <Tile key={videoId}>
                  <Typography variant="subtitle1">{trimText(title)}</Typography>
                  <EmbedContainer
                    embedSrc={`https://www.youtube.com/embed/${videoId}`}
                    resolution="16by9"
                  />
                </Tile>
              ))}
          </VideoContainer>
        </StyledTabPanel>
      </TabContext>
    </SmallerSection>
  );
};

const VideoContainer = styled.div`
  flex: 1;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: ${({ theme }) => theme.spacings.medium};

  @media (max-width: ${mobileWindowWidthCSS}) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

const StyledTabPanel = styled(TabPanel)`
  padding: 1rem 0;

  overflow-y: scroll;

  max-height: 600px;
`;

const StyledTab = styled(Tab)`
  text-transform: none;
`;
