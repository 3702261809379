import { CircularProgress } from '@mui/material';
import { ComponentProps, forwardRef, HTMLAttributes, ReactNode } from 'react';
import styled from 'styled-components';

type CircularProgressPropsWithoutValue = Omit<ComponentProps<typeof CircularProgress>, 'value'>;

type Props = HTMLAttributes<HTMLDivElement> & {
  label?: ReactNode;
  withBackground?: boolean;
  CircularProgressProps?: CircularProgressPropsWithoutValue;
  // Shorthand for common use cases
  value: ComponentProps<typeof CircularProgress>['value'];
};

export const RoundedCircularProgressWithLabel = forwardRef<HTMLDivElement, Props>(
  ({ label, withBackground = true, value, CircularProgressProps, ...props }, ref) => {
    return (
      <Root ref={ref} {...props}>
        {withBackground && (
          <BackgroundCircularProgress
            variant="determinate"
            {...CircularProgressProps}
            value={100}
          />
        )}

        <RoundedCircularProgress variant="determinate" {...CircularProgressProps} value={value} />

        {label && <LabelContainer>{label}</LabelContainer>}
      </Root>
    );
  }
);

const Root = styled.div`
  position: relative;
  display: inline-flex;
`;

const LabelContainer = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
`;

const RoundedCircularProgress = styled(CircularProgress)`
  stroke-linecap: round;
`;

const BackgroundCircularProgress = styled(RoundedCircularProgress)`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;

  opacity: 0.2;
`;
