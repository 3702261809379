import { css } from 'styled-components';

export const plateImageCaptionWorkaround = css`
  .slate-ImageElement-caption {
    width: initial;
    // The following is only required to get the content centered (like it is with width: 100%)
    // without applying to much custom CSS.
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
`;
