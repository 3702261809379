import styled from 'styled-components';

const ControllerButton = styled.button`
  background-color: transparent;
  border: none;
  position: absolute;
  right: 0;
  top: 0;
  cursor: pointer;
  width: 40px;
  display: flex;
  font-size: 1rem;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  align-items: center;

  & > i {
    background: ${({ disabled }) => (disabled ? 'transparent' : 'white')};
  }
`;

export default ControllerButton;
