import { ModalCommonProps } from 'components/modals/RootModal';
import { ChangePricingPlanModal } from 'features/pricing/modals/ChangePricingPlanModal';
import { FormattedMessage } from 'react-intl';

type Props = ModalCommonProps;

const AccessExpiredModal = ({ closeModal }: Props) => (
  <ChangePricingPlanModal
    headline={<FormattedMessage id="access.expired_head" values={{ br: <br /> }} />}
    onClose={closeModal}
  />
);

export default AccessExpiredModal;
