import { ListsState } from 'services/api/list/types';
import { ActionType, getType } from 'typesafe-actions';
import { arrayToDictionary } from 'utils/utils';

import * as actions from './actions';

export type ListsAction = ActionType<typeof actions>;

const initialState: ListsState = {
  lists: {},
  currentList: 0,
  isLoading: false,
  unseenLists: 0
};

export function lists(state = initialState, action: ListsAction): ListsState {
  switch (action.type) {
    case getType(actions.combineLists.request):
    case getType(actions.addToExistingList.request):
    case getType(actions.addList.request):
    case getType(actions.getLists.request):
    case getType(actions.updateList.request):
    case getType(actions.removeList.request):
    case getType(actions.exportToDimension.request):
      return { ...state, isLoading: true };

    case getType(actions.combineLists.failure):
    case getType(actions.addToExistingList.failure):
    case getType(actions.addList.failure):
    case getType(actions.getLists.failure):
    case getType(actions.updateList.failure):
    case getType(actions.removeList.failure):
    case getType(actions.exportToDimension.failure):
      return { ...state, isLoading: false };

    case getType(actions.combineLists.success):
    case getType(actions.addToExistingList.success):
    case getType(actions.addList.success):
    case getType(actions.updateList.success):
      return {
        ...state,
        currentList: action.payload
      };

    case getType(actions.exportToDimension.success):
      return {
        ...state,
        isLoading: false
      };

    case getType(actions.getLists.success): {
      const lists = arrayToDictionary(action.payload, 'id');
      let { currentList } = state;
      if (!currentList || !lists[currentList]) {
        currentList = action.payload.length ? action.payload[0].id : 0;
      }
      return {
        ...state,
        isLoading: false,
        currentList,
        lists
      };
    }

    case getType(actions.selectList):
      return {
        ...state,
        currentList: action.payload
      };

    default:
      return state;
  }
}
