import { capitalize } from 'lodash';

export type BrowserType = NonNullable<ReturnType<typeof getBrowserType>>;

export const getBrowserType = () => {
  const userAgent = navigator.userAgent;

  if (userAgent.match(/chrome|chromium|crios/i)) {
    return 'chrome';
  } else if (userAgent.match(/firefox|fxios/i)) {
    return 'firefox';
  } else if (userAgent.match(/safari/i)) {
    return 'safari';
  } else if (userAgent.match(/opr\//i)) {
    return 'opera';
  } else if (userAgent.match(/edg/i)) {
    return 'edge';
  }

  return null;
};

export const getBrowserName = () => {
  const browserType = getBrowserType();

  return capitalize(browserType || '');
};
