/* eslint-disable */
/* http://bl.ocks.org/Rokotyan/0556f8facbaf344507cdc45dc3622177 */
import { saveAs } from 'file-saver';
import { GAEvents } from '../services/tracking/GAEvents';

export const getSVGString = (svg, width, height) => {
  const svgNode = svg.cloneNode(true);
  svgNode.setAttribute('xlink', 'http://www.w3.org/1999/xlink');
  svgNode.setAttribute('width', width);
  svgNode.setAttribute('height', height);
  svgNode.classList.remove('bubble-chart__svg');

  svgNode.querySelectorAll('.bubble-chart__info--toggleable').forEach(element => {
    element.classList.remove('bubble-chart__info--toggleable');
  });

  svgNode.querySelectorAll('.no-export').forEach(node => node.remove());
  svgNode.querySelectorAll('.export-only').forEach(node => node.classList.remove('export-only'));

  const cssStyleText = getCSSStyles(svgNode);
  appendCSS(cssStyleText, svgNode);

  const serializer = new XMLSerializer();
  let svgString = serializer.serializeToString(svgNode);
  svgString = svgString.replace(/(\w+)?:?xlink=/g, 'xmlns:xlink='); // Fix root xlink without namespace
  svgString = svgString.replace(/NS\d+:href/g, 'xlink:href'); // Safari NS namespace fix

  return svgString;

  function getCSSStyles(parentElement) {
    const selectorTextArr = [];

    // Add Parent element Id and Classes to the list
    selectorTextArr.push(`#${parentElement.id}`);
    for (let c = 0; c < parentElement.classList.length; c++)
      if (!contains(`.${parentElement.classList[c]}`, selectorTextArr))
        selectorTextArr.push(`.${parentElement.classList[c]}`);

    // Add Children element Ids and Classes to the list
    const nodes = parentElement.getElementsByTagName('*');
    for (let i = 0; i < nodes.length; i++) {
      const id = nodes[i].id;
      if (!contains(`#${id}`, selectorTextArr)) selectorTextArr.push(`#${id}`);

      const classes = nodes[i].classList;
      for (let c = 0; c < classes.length; c++)
        if (!contains(`.${classes[c]}`, selectorTextArr)) selectorTextArr.push(`.${classes[c]}`);
    }

    // Extract CSS Rules
    let extractedCSSText = '';
    for (let i = 0; i < document.styleSheets.length; i++) {
      const s = document.styleSheets[i];

      try {
        if (!s.cssRules) continue;
      } catch (e) {
        if (e.name !== 'SecurityError') throw e; // for Firefox
        continue;
      }

      const cssRules = s.cssRules;
      for (let r = 0; r < cssRules.length; r++) {
        // match only last class in group
        if (
          cssRules[r].selectorText &&
          contains(
            cssRules[r].selectorText
              .split('.')
              .filter(String)
              .map(str => `.${str}`)
              .pop(),
            selectorTextArr
          )
        ) {
          // extract selector part
          const cssText = cssRules[r].cssText.match(/^(.+?)({.+?})$/);

          // if there's selector, leave only last class in group
          if (cssText && cssText.length > 1) {
            extractedCSSText +=
              cssText[1]
                .split('.')
                .filter(String)
                .map(str => `.${str}`)
                .pop() + cssText[2];
          }
        }
      }
    }

    return extractedCSSText;

    function contains(str, arr) {
      return arr.indexOf(str) !== -1;
    }
  }

  function appendCSS(cssText, element) {
    const styleElement = document.createElement('style');
    styleElement.setAttribute('type', 'text/css');
    styleElement.innerHTML = cssText;
    const refNode = element.hasChildNodes() ? element.children[0] : null;
    element.insertBefore(styleElement, refNode);
  }
};

const svgToImage = (svgElement, width, height, format = 'png', callback) => {
  const svgString = getSVGString(svgElement, width, height);
  const imgsrc = `data:image/svg+xml;base64,${btoa(unescape(encodeURIComponent(svgString)))}`;

  if (format === 'svg+xml') {
    const filesize = `${Math.round(imgsrc.length / 1024)} KB`;
    if (callback)
      callback(
        new Blob([svgString], {
          type: `image/svg+xml`
        }),
        filesize
      );
  } else {
    const image = new Image();
    image.onload = () => {
      const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d');

      canvas.width = width;
      canvas.height = height;

      context.clearRect(0, 0, width, height);
      context.fillStyle = '#fff';
      context.fillRect(0, 0, width, height);
      context.drawImage(image, 0, 0, width, height);

      canvas.toBlob(blob => {
        const fileSize = `${Math.round(blob.length / 1024)} KB`;
        if (callback) callback(blob, fileSize);
      }, `image/${format}`);
    };

    image.src = imgsrc;
  }
};

export const saveChart = ({ extension, svgElement, width, height, filename }) => {
  if (typeof svgElement === 'string') {
    svgElement = document.querySelector(svgElement);
  }

  if (!height || !width) {
    const [, , vbWidth, vbHeight] = svgElement.getAttribute('viewBox').split(' ').map(parseFloat);
    height = height || vbHeight;
    width = width || vbWidth;
  }

  let mime;
  let imageWidth = 2 * width;
  let imageHeight = 2 * height;

  switch (extension) {
    case 'png':
      mime = 'png';
      break;
    case 'svg':
      mime = 'svg+xml';
      imageWidth = width;
      imageHeight = height;
      break;
    case 'jpg':
    default:
      mime = 'jpeg';
  }

  svgToImage(svgElement, imageWidth, imageHeight, mime, dataBlob => {
    saveAs(dataBlob, `${filename || 'chart'}.${extension}`);
  });

  GAEvents.graphExported(extension, filename !== 'content_graph' ? filename : undefined);
};
