import { Typography } from '@mui/material';
import PercentageIndicator from 'components/PercentageIndicator';
import { setIsViewExtended } from 'features/aiTester/store/actions/config/actions';
import { getIsViewExtended } from 'features/aiTester/store/selectors';
import { TestingContent } from 'features/aiTester/store/types';
import ContentFieldEdit from 'features/aiTester/TesterContentField/ContentFieldEdit';
import ContentFieldReadonly from 'features/aiTester/TesterContentField/ContentFieldReadonly';
import TesterSubTile from 'features/aiTester/TesterSubTile';
import mkTesterTourClassName from 'features/aiTester/tour/utils/mkTesterTourClassName';
import getStatusInfo from 'features/aiTester/utils/getStatusInfo';
import { getWordsStats } from 'features/aiTester/utils/getTokensFromText';
import { forwardRef, useEffect, useMemo } from 'react';
import FlipMove from 'react-flip-move';
import { FormattedMessage } from 'react-intl';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import styled from 'styled-components';

type Props = {
  value: TestingContent;
};

const TesterContentField = forwardRef<HTMLLIElement, Props>(({ value }, ref) => {
  const dispatch = useAppDispatch();

  const isViewExtended = useAppSelector(getIsViewExtended);

  const wordsStats = useMemo(() => getWordsStats(value.words ?? []), [value.words]);
  const wordsStatusInfo = useMemo(() => getStatusInfo(wordsStats), [wordsStats]);

  const isError = useMemo(
    () => (wordsStats.total > 1 && wordsStats.available < 1) || wordsStatusInfo.status === 'ERROR',
    [wordsStats.total, wordsStats.available, wordsStatusInfo.status]
  );

  const roundedGoalScore = useMemo(() => Math.round(value.goalScore * 100), [value.goalScore]);

  const scoreTooltip = useMemo(
    () => (
      <div>
        <div>
          <FormattedMessage
            id="testing.score.tooltip.line_1"
            values={{ score: roundedGoalScore }}
          />
        </div>
        <div>
          <FormattedMessage id="testing.score.tooltip.line_2" />
        </div>
      </div>
    ),
    [roundedGoalScore]
  );

  useEffect(() => {
    if (value.goalScore > 0) {
      dispatch(setIsViewExtended(true));
    }
  }, [dispatch, value.goalScore]);

  return (
    <Root ref={ref}>
      <Element>
        <Label variant="caption">
          <PercentageIndicator
            className={mkTesterTourClassName('comparison', 4)}
            tooltip={scoreTooltip}
            value={roundedGoalScore}
            onClick={() => dispatch(setIsViewExtended(true))}
          />
        </Label>
        <Content>
          <TextContainer>
            {value.isEditing ? (
              <ContentFieldEdit value={value} />
            ) : (
              <ContentFieldReadonly value={value} />
            )}
          </TextContainer>
        </Content>
        {isError && (
          <Error>
            <FormattedMessage id="testing.error.only_stop_words" />
          </Error>
        )}
      </Element>
      <SubElement isViewExtended={isViewExtended}>
        {/* @ts-expect-error Package doesn't yet define a children prop (React 18) */}
        <AnimatedList duration={750}>
          {value.subSuggestions.map(({ id, text, textItemId, generationId }) => (
            <TesterSubTile
              key={id}
              id={id}
              parentId={value.id}
              text={text}
              textItemId={textItemId}
              generationId={generationId}
              source="texts"
            >
              {text}
            </TesterSubTile>
          ))}
        </AnimatedList>
      </SubElement>
    </Root>
  );
});

const Root = styled.li`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Element = styled.li`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: 1fr auto;
  grid-template-areas:
    'label content'
    'empty error';
  align-items: center;
`;

const SubElement = styled.div<{ isViewExtended?: boolean }>`
  padding-left: ${({ isViewExtended, theme }) => !isViewExtended && theme.spacings.xlarge};
`;

const Content = styled.div`
  grid-area: content;
`;

const Label = styled(Typography)`
  && {
    grid-area: label;

    margin-right: ${({ theme }) => theme.spacings.small};

    color: ${({ theme }) => theme.colors.blackMediumEmphasis};
  }
`;

const TextContainer = styled.div`
  display: flex;
  flex: 1 0 0;
`;

const Error = styled.small`
  grid-area: error;

  color: ${({ theme }) => theme.colors.danger} !important;
`;

const AnimatedList = styled(FlipMove)`
  display: flex;
  flex-direction: column;
`;

export default TesterContentField;
