import { create } from 'zustand';

type ExpandedSidebarState = {
  isSidebarExpanded: boolean;
};

type ExpandedSidebarStateActions = {
  setIsSidebarExpanded: (isSidebarExpanded: boolean) => void;
};

const initialState: ExpandedSidebarState = {
  isSidebarExpanded: true
};

export const useAiWriterExpandedSidebarStore = create<ExpandedSidebarState & ExpandedSidebarStateActions>(
  set => ({
    ...initialState,
    setIsSidebarExpanded: (isSidebarExpanded: boolean) => set({ isSidebarExpanded })
  })
);
