const gtmAttribute = 'data-gtm-interaction';
const deprecatedGtmAttribute = 'id';

export type GtmAttributes = {
  [gtmAttribute]?: string;
  [deprecatedGtmAttribute]?: string;
};

export function withGtmInteraction(
  interactionId: string | null | undefined,
  config: {
    /**
     * Deprecated way of gtm tag usage #tech-debt: https://app.clickup.com/t/862je7864
     */
    withDeprecatedStyle?: boolean;
  } = {}
): GtmAttributes {
  if (!interactionId) return {};

  const attributes: GtmAttributes = { 'data-gtm-interaction': interactionId };

  const { withDeprecatedStyle = false } = config;

  if (withDeprecatedStyle) {
    attributes.id = interactionId;
  }

  return attributes;
}
