import styled from 'styled-components';

const Dot = styled.span<{ size?: string; color?: string }>`
  height: ${({ size = '1rem' }) => size};
  width: ${({ size = '1rem' }) => size};
  background-color: ${({ color = '#ccc' }) => color};
  margin: 0 0.25rem;
  vertical-align: middle;
  border-radius: 50%;
  display: inline-block;
`;

export default Dot;
