import { PersonalityCommonSelector } from 'components/personality/PersonalityCommonSelector';
import { PersonalityHeaderWrapper } from 'components/personality/PersonalityHeaderWrapper';
import { useChatCurrentGptModel } from 'features/aiWriter/AiWriterSidebar/steps/chat/chatStore';
import { useDocumentPersonalityData } from 'features/aiWriter/AiWriterSidebar/steps/chat/useDocumentPersonalityData';
import {
  useResetChat,
  useWarnAboutChatReset
} from 'features/aiWriter/AiWriterSidebar/steps/chat/useResetChat';
import { useUpdateAiWriterAndDefaultPersonality } from 'features/aiWriter/AiWriterSidebar/steps/chat/useUpdateAiWriterAndDefaultPersonality';
import { getActiveTab } from 'features/aiWriter/store/selectors';
import { getEmbeddingModelDataSelector } from 'features/embeddingModels/store/selectors';
import { PersonalityDto } from 'services/backofficeIntegration/http/dtos/PersonalityDto';
import { GAEvents } from 'services/tracking/GAEvents';
import { useAppSelector } from 'store/hooks';

export function ProjectPersonalitySelection({ fullWidth = false }: { fullWidth?: boolean }) {
  const updateDocumentPersonality = useUpdateAiWriterAndDefaultPersonality();
  const resetChat = useResetChat();
  const warnAboutChatReset = useWarnAboutChatReset();

  const tab = useAppSelector(getActiveTab);

  const { isFetching, data: personalityData } = useDocumentPersonalityData();

  const getModelById = useAppSelector(getEmbeddingModelDataSelector);
  const model = getModelById(tab.embeddingModelId);

  const gptModel = useChatCurrentGptModel();

  const handlePersonalityChange = (personality: PersonalityDto | null | undefined) => {
    if (personality === undefined) {
      return;
    }

    warnAboutChatReset({
      onAccept: () => {
        resetChat({
          personality,
          gptModel,
          callback: () => onPersonalitySelect(personality)
        });
      }
    });
  };

  const onPersonalitySelect = (personality: PersonalityDto | null) => {
    updateDocumentPersonality({ personality });
  };

  return (
    <PersonalityHeaderWrapper>
      <PersonalityCommonSelector
        fullWidth={fullWidth}
        value={personalityData ?? null}
        onChange={handlePersonalityChange}
        onClick={() => GAEvents.overviewPersonalitySelectOpen()}
        preselectedModelId={model.id}
        isPropsLoading={isFetching}
      />
    </PersonalityHeaderWrapper>
  );
}
