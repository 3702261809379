import { Checkbox as MuiCheckbox } from '@mui/material';
import * as CheckboxPrimitive from '@radix-ui/react-checkbox';
import { ChangeEvent, forwardRef } from 'react';
import styled from 'styled-components';

// Gathered by console logging the passed props
type Props = {
  checked: boolean;
  onCheckedChange: (checked: boolean) => void;
  className?: string;
};

export const Checkbox = forwardRef<HTMLButtonElement, Props>(
  ({ className, onCheckedChange, checked }, ref) => {
    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
      onCheckedChange(event.target.checked);
    };

    return (
      <StyledCheckbox
        ref={ref}
        size="small"
        checked={checked}
        onChange={handleChange}
        inputProps={{ 'aria-label': 'controlled' }}
        className={className}
      />
    );
  }
);
Checkbox.displayName = CheckboxPrimitive.Root.displayName;

const StyledCheckbox = styled(MuiCheckbox)`
  padding: 0;
`;
