export function parseUrlQuery<T extends Partial<Record<string, string>>>(
  queryString: string
): Partial<T> {
  if (queryString) {
    const query: Record<string, string> = {};
    const pairs = (queryString[0] === '?' ? queryString.substr(1) : queryString).split('&');
    pairs.forEach(pair => {
      const [key, value] = pair.split('=');
      query[decodeURIComponent(key)] = decodeURIComponent(value || '');
    });

    return query as T;
  }
  return {} as T;
}

export function getRequestURL(url: string, params: Record<string, string | number>): string {
  const queryString = Object.keys(params)
    .map(key => `${key}=${encodeURIComponent(params[key])}`)
    .join('&');

  return queryString ? `${url}?${queryString}` : url;
}

export function splitURLSearchParams(url: string) {
  const { search, origin, pathname, host } = new URL(url);
  return {
    url: origin + pathname,
    host,
    search
  };
}
