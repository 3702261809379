import { ToastErrorWithLink } from 'components/toasts/ToastErrorWithLink';
import { useGetBugReportLinkParameters } from 'components/toasts/useGetBugReportLinkParameters';
import { IntlMessageKeys } from 'features/i18n/IntlMessageKeys';
import { ComponentProps } from 'react';

type Props = {
  message?: IntlMessageKeys;
  values?: ComponentProps<typeof ToastErrorWithLink>['values'];
};

export const ToastErrorWithBugReportLink = ({ message = 'app.api_error', values }: Props) => {
  const bugReportValues = useGetBugReportLinkParameters();

  return (
    <ToastErrorWithLink
      message={message}
      values={{ ...values }}
      link="app.error.report_bug_link"
      linkValues={bugReportValues}
    />
  );
};
