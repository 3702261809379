import { backofficeClient } from 'services/backofficeIntegration/http/client/createBackofficeClient';
import { mutationEndpoint } from 'services/backofficeIntegration/http/endpointDefinition';

type Payload = {
  answers: Array<{ question: number; answer: number }>;
};

export const updateSurveyAnswers = mutationEndpoint({
  callEndpoint: (answers: Record<number, number>) => {
    const payload: Payload = {
      answers: Object.entries(answers).map(([question, answer]) => ({
        question: parseInt(question, 10),
        answer
      }))
    };

    return backofficeClient.post('/survey/answers', payload);
  }
});
