import { useMutation } from '@tanstack/react-query';
import { invalidatePersonalitiesQuery } from 'features/aiWriter/AiWriterSidebar/steps/chat/usePersonalitiesQuery';
import { invalidateDefaultPersonalityQueries } from 'services/backofficeIntegration/http/endpoints/personalities/httpGetDefaultPersonality';
import {
  httpSetDefaultPersonality,
  SetDefaultPersonalityParams
} from 'services/backofficeIntegration/http/endpoints/personalities/httpSetDefaultPersonality';

export const useSetDefaultChatPersonality = () =>
  useMutation({
    mutationFn: (params: SetDefaultPersonalityParams) =>
      httpSetDefaultPersonality.callEndpoint(params),
    onSuccess: (_, params) => {
      invalidatePersonalitiesQuery();
      invalidateDefaultPersonalityQueries({ language: params.language, country: params.country });
    }
  });
