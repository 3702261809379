import styled from 'styled-components';

export const GermanFlag = () => (
  <Root>
    <ColorTile $color="#000000" />
    <ColorTile $color="#FF0000" />
    <ColorTile $color="#FFCC00" />
  </Root>
);

const Root = styled.div`
  width: 100%;

  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: ${({ theme }) => theme.spacings.one};
`;

const ColorTile = styled.div<{ $color: string }>`
  background-color: ${({ $color }) => $color};
  width: 18px;
  height: 7px;
`;
