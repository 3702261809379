import { CheckCircleOutlined, Remove } from '@mui/icons-material';
import { Typography } from '@mui/material';
import { StyledCell } from 'components/profile/components/pricing-plan-card/Components';
import { IntlMessageKeys } from 'features/i18n/IntlMessageKeys';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

type Props = {
  activeFlag: boolean | IntlMessageKeys;
};

export const FeatureTablePresenceCell = ({ activeFlag }: Props) => {
  if (typeof activeFlag === 'string') {
    return (
      <StyledCell>
        <Typography variant="body1">
          <FormattedMessage
            id={activeFlag}
            values={{ b: (text: string) => <strong>{text}</strong> }}
          />
        </Typography>
      </StyledCell>
    );
  }

  return <StyledCell>{activeFlag ? <CheckMark color="primary" /> : <Dash />}</StyledCell>;
};

const CheckMark = styled(CheckCircleOutlined)``;

const Dash = styled(Remove)`
  color: ${({ theme }) => theme.colors.divider};
`;
