import styled from 'styled-components';

const Menu = styled.div<{ isOpen?: boolean }>`
  position: absolute;
  max-height: 22rem;
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
  border: ${({ isOpen }) => (isOpen ? '1px solid rgba(0, 40, 100, 0.12)' : 'none')};
  border-top: 0;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  background-color: #fff;
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  z-index: 70;
  outline: 0;
  margin: 0;
  padding: 0;
  list-style-type: none;
`;

export default Menu;
