import LocalStorageKey from 'config/localStorageKey';
import { scaleBand, scaleLinear } from 'd3-scale';
import { TestingChartEntry } from 'features/aiTester/store/types';
import { flatten, zip } from 'lodash';

export const getYScaleDomain = (data: TestingChartEntry[]) => {
  const [negativeValues, positiveValues] = zip<number>(
    ...data.map(d =>
      d.details.reduce(
        (totalValues, detail) => {
          if (detail.field === 'model_rank_score' && d.details.length > 1) {
            return totalValues;
          }
          if (detail.value < 0) {
            totalValues[0] += detail.value;
          } else {
            totalValues[1] += detail.value;
          }
          return totalValues;
        },
        [0, 0]
      )
    )
  );

  return [Math.min(...(negativeValues as number[])), Math.max(...(positiveValues as number[]))];
};

export const getYScaleDomainGrouped = (data: GroupedContentTestingData[]) => {
  if (data.length) {
    const values = flatten(data.map(i => i.data)).map(({ value }) => value);
    return [Math.min(...values, 0), Math.max(...values)];
  }
  return [-1, 1];
};

export const getXScaleDomainGrouped = (
  data: GroupedContentTestingData[],
  tr: (key: string) => string
) => {
  const copy = data.slice();
  if (copy.length) {
    copy.sort((a, b) => b.data[0].value - a.data[0].value);
  }
  return copy.map(({ field, label }) => label || tr(`goal_score.${field}`));
};

export const createXScale = (
  domain: string[],
  range: [number, number],
  paddingInner = 0.1,
  paddingOuter = 0.1
) => scaleBand().domain(domain).range(range).paddingInner(paddingInner).paddingOuter(paddingOuter);

export const createXGroupedScale = (domain: string[], bandwidth: number) =>
  scaleBand().domain(domain).rangeRound([0, bandwidth]).padding(0.02);

export const createYScale = (domain: number[], range: number[]) =>
  scaleLinear().domain(domain).range(range);

export type GroupedContentTestingData = {
  field: number | string;
  label: string | null;
  data: Array<{
    textId: string;
    textLabel: string;
    value: number;
  }>;
};

export const prepareDetailsGraph = (data: TestingChartEntry[], textId?: string) => {
  if (data.length && textId) {
    const texts: string[] = [];
    const dataByParam = data[0].details.reduce<Record<string, GroupedContentTestingData>>(
      (acc, { field, label }) => {
        acc[field] = {
          field,
          label,
          data: []
        };
        return acc;
      },
      {}
    );

    const selectedTextData = data.find(({ id }) => id === textId);

    if (selectedTextData) {
      texts.push(selectedTextData.label);
      selectedTextData.details.forEach(({ field, value }) => {
        dataByParam[field].data.push({
          textId,
          textLabel: selectedTextData.label,
          value
        });
      });
    }

    return {
      texts,
      data: Object.values(dataByParam)
    };
  }
  return {
    texts: [],
    data: []
  };
};

export const storeColor = (field: string | number, value: string) => {
  try {
    const savedColors = localStorage.getItem(LocalStorageKey.TesterColors);
    let colors;
    if (savedColors) {
      colors = JSON.parse(savedColors);
      colors[field] = value;
    } else {
      colors = {
        [field]: value
      };
    }
    localStorage.setItem(LocalStorageKey.TesterColors, JSON.stringify(colors));
  } catch (e) {
    //
  }
};
