import { Typography } from '@mui/material';
import {
  changeMessageDraft,
  disableInstantSending,
  newMessageDraft,
  useMessageDraft,
  useShouldSendMessageInstantly
} from 'features/aiWriter/AiWriterSidebar/steps/chat/chatStore';
import { useSendMessage } from 'features/aiWriter/AiWriterSidebar/steps/chat/useSendMessage';
import { ChatMessageInput } from 'features/aiWriter/chat/ChatMessageInput';
import { ShortcutHint } from 'features/aiWriter/chat/OverviewMessageInput';
import FormattedMessage from 'features/i18n/FormattedMessage';
import {
  useShouldShowWordsLimitReachedModal,
  useShowWordsLimitReachedModal
} from 'features/pricing/hook/useYouRunOutOfWordsModal';
import { useEffect } from 'react';
import gtmIds from 'services/tracking/GTMIds';
import styled from 'styled-components';
import { useStableCallback } from 'utils/react/useStableCallback';

const HintBox = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export function MessageInput() {
  const shouldSendInstantly = useShouldSendMessageInstantly();
  const messageDraft = useMessageDraft();
  const sendMessage = useSendMessage();
  const { shouldShowRunOutOfWordsModal } = useShouldShowWordsLimitReachedModal();
  const onlyShowModal = useShowWordsLimitReachedModal();

  const messageText = messageDraft.text ?? '';

  const sendCurrentDraftMessage = useStableCallback(() => {
    if (messageText.length === 0) {
      return;
    }
    if (shouldShowRunOutOfWordsModal()) {
      onlyShowModal();
      return;
    }
    sendMessage.mutate(messageDraft);
  });

  useEffect(() => {
    if (shouldSendInstantly) {
      sendCurrentDraftMessage();
      disableInstantSending();
    }
  }, [shouldSendInstantly, sendCurrentDraftMessage]);

  function handleSubmit() {
    sendCurrentDraftMessage();
  }

  function renderError() {
    if (messageDraft.failedToSend) {
      return (
        <Typography variant="caption" color="error">
          <FormattedMessage id="chat.message_input.error" />
        </Typography>
      );
    }
  }

  function handleTextChange(text: string) {
    if (messageDraft.failedToSend) {
      newMessageDraft({
        text
      });
    } else {
      changeMessageDraft({
        text
      });
    }
  }

  return (
    <form>
      {renderError()}
      <ChatMessageInput
        sendButtonConfig={{ gtmId: gtmIds.aiWriter.chat.sendMessage }}
        isSending={sendMessage.isLoading}
        value={messageText}
        onChange={handleTextChange}
        onSend={handleSubmit}
      />
      <HintBox>
        <ShortcutHint />
      </HintBox>
    </form>
  );
}
