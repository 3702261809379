import { Typography } from '@mui/material';
import bannerImage from 'assets/contentFlash/overview/tag-banner.png';
import FormattedMessage from 'features/i18n/FormattedMessage';
import { BANNER_BREAKING_POINT, BANNER_HIDE_BREAKING_POINT } from 'pages/brand-hub/BannerLayout';
import styled from 'styled-components';

export const TagsBanner = () => {
  return (
    <Root>
      <Body>
        <HeaderText>
          <FormattedMessage
            id="aiWriter.project_overview.tags_banner.title"
            values={{
              highlight: (text: string) => <Highlighting>{text}</Highlighting>
            }}
          />
          <BodyText>
            <FormattedMessage id="aiWriter.project_overview.tags_banner.subtitle" />
          </BodyText>
        </HeaderText>
      </Body>
      <ImageContainer>
        <Image src={bannerImage} />
      </ImageContainer>
    </Root>
  );
};

const Highlighting = styled.span`
  color: ${({ theme }) => theme.colors.primaryColorMain};
`;

const Root = styled.div`
  position: relative;
  min-height: 140px;
  max-height: 167px;
  border-radius: ${({ theme }) => theme.borderRadius.one};
  overflow: hidden;
  background-color: ${({ theme }) => theme.colors.palette.beige__100main};
  display: flex;
  align-items: center;

  gap: ${({ theme }) => theme.spacings.five};

  @media (max-width: ${BANNER_BREAKING_POINT}px) {
    gap: 0;
  }

  @media (max-width: ${BANNER_HIDE_BREAKING_POINT}px) {
    display: none;
  }
`;

const Body = styled.div`
  flex: 110%;
  padding: ${({ theme }) => theme.spacings.six};

  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: ${({ theme }) => theme.spacings.three};

  @media (max-width: ${BANNER_BREAKING_POINT}px) {
    gap: ${({ theme }) => theme.spacings.one};
  }
`;

const HeaderText = styled(Typography).attrs({
  variant: 'h5',
  color: 'black'
})`
  @media (max-width: ${BANNER_BREAKING_POINT}px) {
    ${({ theme }) => ({
      ...theme.typography.h5
    })}
  }
`;

const BodyText = styled(Typography).attrs({
  variant: 'body1',
  color: 'black'
})`
  @media (max-width: ${BANNER_BREAKING_POINT}px) {
    ${({ theme }) => ({
      ...theme.typography.body2
    })}
  }
`;

const ImageContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;

  @media (max-width: 660px) {
    display: none;
  }
`;

const Image = styled.img`
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  max-width: unset;
`;
