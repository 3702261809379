import { useCallback, useLayoutEffect, useRef } from 'react';

type Handler<Args extends unknown[], Result> = (...args: Args) => Result;

/**
 * In some scenarios you need to pass a callback to `useEffect`, but the effect
 * don't have to be run each time the callback itself changes. This hook makes
 * callback stable so you can pass it to dependency array freely.
 *
 * Please be aware that this is not a silver bullet and should be used only when
 * it's really needed. If used incorrectly this may lead to some nasty bugs.
 *
 * You can read more about this concept here:
 * https://github.com/reactjs/rfcs/blob/useevent/text/0000-useevent.md
 */
export function useStableCallback<Args extends unknown[], Result>(callback: Handler<Args, Result>) {
  const callbackRef = useRef<Handler<Args, Result> | undefined>();

  useLayoutEffect(() => {
    callbackRef.current = callback;
  });

  return useCallback((...args: Args) => {
    const fn = callbackRef.current;
    if (!fn) {
      throw Error('Stable callback not yet initialized');
    }
    return fn(...args);
  }, []);
}
