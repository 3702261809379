import { BaseSuccessDto } from 'services/backofficeIntegration/http/BaseSuccessDto';
import { backofficeClient } from 'services/backofficeIntegration/http/client/createBackofficeClient';
import { mutationEndpoint } from 'services/backofficeIntegration/http/endpointDefinition';
import { AssignTagRequestParams } from 'services/backofficeIntegration/http/endpoints/aiWriter/httpAssignTag';

type SuccessDto = BaseSuccessDto<never>;

export const httpUnassignTag = mutationEndpoint({
  callEndpoint: (params: AssignTagRequestParams) =>
    backofficeClient
      .delete<SuccessDto>(`/tags/${params.tagId}/ai-writer-projects/${params.projectId}`)
      .then(response => response.data)
});
