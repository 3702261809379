import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { CircularProgress, IconButton, ListItemIcon, Menu, MenuItem } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import FormattedMessage from 'features/i18n/FormattedMessage';
import { useShowInformationEditModal } from 'features/information/useShowInformationModal';
import { MouseEvent } from 'react';
import { httpDeleteInformation } from 'services/backofficeIntegration/http/endpoints/infomration/httpDeleteInformation';
import { invalidateInformationLimitationsQuery } from 'services/backofficeIntegration/http/endpoints/infomration/httpGetInformationLimitations';
import { InformationDto } from 'services/backofficeIntegration/http/endpoints/infomration/httpGetInformationList';
import styled from 'styled-components';
import useDropdown from 'utils/hooks/useDropdown';

type Props = {
  information: InformationDto;
};

export const InformationMenu = ({ information }: Props) => {
  const { anchorElement, isOpen, open, close } = useDropdown();

  const { mutate: deleteInformation, isLoading: isDeleting } = useMutation({
    mutationFn: () => httpDeleteInformation.callEndpoint(information.id),
    onSuccess: () => {
      invalidateInformationLimitationsQuery();
    }
  });

  const [isLoadingModal, showInformationEditModal] = useShowInformationEditModal();

  const handleMenuOpen = (event: MouseEvent<HTMLElement>) => {
    event.preventDefault();
    event.stopPropagation();

    open(event);
  };

  const handleDeleteClick = (event: MouseEvent<HTMLElement>) => {
    event.preventDefault();
    event.stopPropagation();

    deleteInformation();

    close();
  };

  const handleEditClick = async (event: MouseEvent<HTMLElement>) => {
    event.preventDefault();
    event.stopPropagation();

    await showInformationEditModal(information);

    close();
  };

  const isLoading = isDeleting || isLoadingModal;

  if (isLoading) {
    return <CircularProgress />;
  }

  return (
    <>
      <IconButton onClick={handleMenuOpen}>
        <MoreVertIcon />
      </IconButton>

      <StyledMenu anchorEl={anchorElement} open={isOpen} onClose={close}>
        <MenuItem onClick={handleEditClick}>
          <ListItemIcon>
            <EditIcon />
          </ListItemIcon>
          <FormattedMessage id="common.edit" />
        </MenuItem>
        <MenuItem onClick={handleDeleteClick}>
          <ListItemIcon>
            <DeleteIcon fontSize="small" />
          </ListItemIcon>
          <FormattedMessage id="common.delete" />
        </MenuItem>
      </StyledMenu>
    </>
  );
};

const StyledMenu = styled(Menu)`
  & .MuiPaper-root {
    width: 13rem;
  }
`;
