import { createSelector } from '@reduxjs/toolkit';
import { memoize } from 'lodash';
import { RootState } from 'store/rootReducer';
import { isoCountries } from 'utils/consts/isoCountries';
import { isoLanguages } from 'utils/consts/isoLanguages';

export const getCurrentLanguage = (state: RootState) => state.language;

export const languageLabelSelector = createSelector(getCurrentLanguage, appLanguage =>
  memoize((languageCode: string): string | null => {
    return isoLanguages.get(languageCode)?.labels[appLanguage] ?? null;
  })
);

export const countryLabelSelector = createSelector(getCurrentLanguage, appLanguage =>
  memoize((countryCode: string): string | null => {
    return isoCountries.get(countryCode)?.labels[appLanguage] ?? null;
  })
);
