import { difference, intersectionBy } from 'lodash';
import { colorsList, randomColor } from 'utils/colorScales';
import { arrayToDictionary } from 'utils/utils';

import { TestingLegend, TextScoreDetail } from '../../types';
import getSavedColor from '../rest/getSavedColor';

const prepareLegend = (prevLegend: TestingLegend, exampleDetailItem: TextScoreDetail[]) => {
  const usedColors = intersectionBy(Object.values(prevLegend), exampleDetailItem, 'field').map(
    i => i.color
  );
  const availableColors = difference(colorsList, usedColors);
  const getAvailableColor = (field: string | number) => {
    const savedColor = getSavedColor(field);
    if (savedColor) {
      return savedColor;
    }

    const elementToMove = availableColors.shift();
    if (elementToMove) {
      availableColors.push(elementToMove);
      return elementToMove;
    } else {
      // should never happen but y'know how it is
      return randomColor();
    }
  };

  return arrayToDictionary(
    exampleDetailItem.map(({ field, label }) => ({
      field,
      label,
      color: prevLegend[field]?.color || getAvailableColor(field),
      active: true
    })),
    'field'
  );
};

export default prepareLegend;
