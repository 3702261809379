import { createAsyncAction } from 'utils/reduxUtils';

import {
  CreateFavoriteFailurePayload,
  CreateFavoriteRequestPayload,
  CreateFavoriteSuccessPayload,
  FetchFavoritesSuccessPayload
} from './types';

export const createFavorite = createAsyncAction<
  CreateFavoriteRequestPayload,
  CreateFavoriteSuccessPayload,
  CreateFavoriteFailurePayload
>('aiWriter/createFavorite');

export const fetchFavorites = createAsyncAction<void, FetchFavoritesSuccessPayload, void>(
  'aiWriter/fetchFavorites'
);

export const deleteFavorite = createAsyncAction<{ id: string }, { id: string }, { id: string }>(
  'aiWriter/deleteFavorite'
);
