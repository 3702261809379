import cx from 'classnames';
import React, { Component } from 'react';
import { MessageType } from 'types/Message';

import classedComponent from '../classedComponent';
import Icon from './Icon';

type Props = {
  children?: React.ReactNode;
  className?: string;
  type: MessageType;
  icon?: string;
  hasExtraSpace?: boolean;
  isDismissible?: boolean;
  avatar?: string;
  onDismissClick?: () => void;
};

type State = {
  isDismissed: boolean;
};

/**
 * @deprecated Please use MUI component
 */
class Alert extends Component<Props, State> {
  static Link = classedComponent('alert-link', 'a');

  state = {
    isDismissed: false
  };

  onDismissClick = (): void => {
    if (this.props.onDismissClick) this.props.onDismissClick();
    this.setState({ isDismissed: true });
  };

  render() {
    const { isDismissed } = this.state;
    const { className, children, type, icon, hasExtraSpace, isDismissible, avatar } = this.props;

    const classes = cx(
      'alert',
      `alert-${type}`,
      {
        'alert-icon': !!icon,
        'mt-5 mb-6': hasExtraSpace,
        'alert-dismissible': isDismissible,
        'alert-avatar': !!avatar
      },
      className
    );

    return (
      !isDismissed && (
        <div className={classes} role="alert">
          {icon && <Icon name={icon} className="mr-2" />}
          {children}
          {isDismissible && (
            <button type="button" className="close" onClick={this.onDismissClick}>
              <span>&times;</span>
            </button>
          )}
        </div>
      )
    );
  }
}

export default Alert;
