import { useQuery } from '@tanstack/react-query';
import { useModal } from 'components/modals';
import StepperModal, { ModalStep } from 'components/modals/StepperModal';
import { CloseModal } from 'components/modals/types';
import { PersonalityCommonSelector } from 'components/personality/PersonalityCommonSelector';
import { PersonalityHeaderWrapper } from 'components/personality/PersonalityHeaderWrapper';
import useHandleOutputTypeChange from 'features/aiTester/modals/briefConfig/useHandleOutputTypeChange';
import useInitialValues from 'features/aiTester/modals/briefConfig/useInitialValues';
import { changeBriefConfigThunk } from 'features/aiTester/store/actions/config/thunks/changeBriefConfigThunk';
import {
  getCurrentModelLanguageAndCountry,
  getTesterActiveTab
} from 'features/aiTester/store/selectors';
import { useSetDefaultChatPersonality } from 'features/aiWriter/AiWriterSidebar/steps/chat/useSetDefaultPersonality';
import { SystemTonality } from 'features/aiWriter/tonality/SystemTonality';
import { useShowApplyInformationModal } from 'features/information/apply-information/ApplyInformationModal';
import { InformationField } from 'features/information/apply-information/InformationField';
import AdvancedSettingsButton from 'features/textGenerator/AdvancedSettingsButton';
import BriefField from 'features/textGenerator/inputs/BriefField';
import KeywordsField from 'features/textGenerator/inputs/KeywordsField';
import OutputTypeAutocomplete from 'features/textGenerator/OutputTypeAutocomplete/OutputTypeAutocomplete';
import { mkBriefFormValidationSchema } from 'features/textGenerator/utils/mkBriefFormValidation';
import { useField, useFormikContext } from 'formik';
import React, { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { PersonalityDto } from 'services/backofficeIntegration/http/dtos/PersonalityDto';
import { InformationDto } from 'services/backofficeIntegration/http/endpoints/infomration/httpGetInformationList';
import { httpGetPersonality } from 'services/backofficeIntegration/http/endpoints/personalities/httpGetPersonality';
import gtmIds from 'services/tracking/GTMIds';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
import useTr from 'utils/hooks/useTr';

export const outputTypeField = 'outputType';
export const textField = 'text';
export const keywords2Field = 'keywords2';
export const keywordsField = 'keywords';
export const systemTonalityField = 'systemTonality';
export const userTonalityField = 'userTonality';
export const numberOfSuggestionsField = 'nTextItems';
export const personalityIdField = 'personalityId';
export const informationField = 'informationList';

export type BriefConfigValues = {
  [outputTypeField]: string;
  [textField]: string;
  [keywords2Field]: string;
  [keywordsField]: string;
  [numberOfSuggestionsField]: number;
  [systemTonalityField]: SystemTonality[];
  [userTonalityField]: string[];
  [personalityIdField]: number | undefined;
  [informationField]: InformationDto[] | undefined;
};

const modelStep: ModalStep[] = [
  {
    title: 'testing.brief_modal.title',
    description: 'testing.brief_modal.description'
  }
];

type BriefConfigModalProps = {
  preselectedValues?: BriefConfigValues;
};

const BriefConfigModal = ({
  preselectedValues,
  closeModal
}: BriefConfigModalProps & CloseModal) => {
  const tr = useTr();
  const dispatch = useAppDispatch();

  const validationSchema = useMemo(
    () => mkBriefFormValidationSchema({ textField, keywordsField, keywords2Field, tr }),
    [tr]
  );

  const commonInitialValues = useInitialValues();

  const initialValues = preselectedValues || commonInitialValues;

  const onSubmit = useCallback(
    (values: BriefConfigValues) => {
      dispatch(changeBriefConfigThunk(values));
    },
    [dispatch]
  );

  return (
    <StepperModal
      steps={modelStep}
      stepComponent={() => <GoalConfigStep />}
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnChange
      tracking={{ saveButtonId: gtmIds.tester.editBriefConfig }}
      onCancel={() => {
        closeModal();
      }}
      onSubmit={values => {
        onSubmit(values);
        closeModal();
      }}
    />
  );
};

const GoalConfigStep = (): React.ReactElement => {
  const { currentModelLanguage, currentModelCountry } = useSelector(
    getCurrentModelLanguageAndCountry
  );

  const {
    values: { outputType, personalityId, informationList }
  } = useFormikContext<BriefConfigValues>();

  const onOutputTypeChange = useHandleOutputTypeChange();

  const shouldAdvancedOptionsBeExpanded = !!personalityId || !!informationList;

  return (
    <Content>
      <OutputTypeAutocomplete
        name={outputTypeField}
        modelLanguage={currentModelLanguage}
        modelCountry={currentModelCountry}
        onSelect={onOutputTypeChange}
      />
      <BriefField
        name={textField}
        modelLanguage={currentModelLanguage}
        modelCountry={currentModelCountry}
        outputType={outputType}
      />
      <KeywordsField
        name={keywords2Field}
        type="keywords2"
        modelLanguage={currentModelLanguage}
        modelCountry={currentModelCountry}
        outputType={outputType}
      />
      <KeywordsField
        name={keywordsField}
        type="keywords"
        modelLanguage={currentModelLanguage}
        modelCountry={currentModelCountry}
        outputType={outputType}
      />
      <AdvancedSettingsButton isExpanded={shouldAdvancedOptionsBeExpanded}>
        <PersonalityField name={personalityIdField} />
        <Information name={informationField} />
      </AdvancedSettingsButton>
    </Content>
  );
};

type PersonalityTesterSelectProps = {
  name: string;
};

function PersonalityField(props: PersonalityTesterSelectProps) {
  const [field, , helper] = useField<number | undefined>(props.name);
  const value = field.value;

  const [personality, setPersonality] = useState<PersonalityDto | undefined | null>();

  const currentTab = useSelector(getTesterActiveTab);

  const { mutate: setDefaultPersonality } = useSetDefaultChatPersonality();

  const handleChange = (personality: PersonalityDto | undefined | null) => {
    setPersonality(personality);
    helper.setValue(personality?.id);

    if (personality) {
      setDefaultPersonality({
        personalityId: personality.id,
        country: personality.country,
        language: personality.language
      });
    }
  };

  useQuery({
    enabled: !!value,
    queryKey: value ? httpGetPersonality.makeQueryKey({ id: value }) : [],
    queryFn: value ? () => httpGetPersonality.callEndpoint({ id: value }) : () => null,
    onSuccess: data => {
      if (data) {
        setPersonality(data);
        helper.setValue(data.id);
      }
    }
  });

  return (
    <PersonalityHeaderWrapper>
      <PersonalityCommonSelector
        value={personality}
        onChange={handleChange}
        preselectedModelId={currentTab.embeddingModelId}
        isPropsLoading={false}
      />
    </PersonalityHeaderWrapper>
  );
}

type InformationProps = {
  name: string;
};

function Information(props: InformationProps) {
  const [field, , helper] = useField<InformationDto[] | undefined>(props.name);
  const { values } = useFormikContext<BriefConfigValues>();

  const showApplyInformationModal = useShowApplyInformationModal();

  const { showModal } = useModal();
  const showBriefModal = (props: BriefConfigModalProps) =>
    showModal('TESTING_BRIEF_MODAL', {
      size: 460,
      ...props
    });

  const handleApplyInformation = () => {
    showApplyInformationModal({
      preselectedInformation: field.value,
      applyGtmId: gtmIds.tester.applyInformation,
      onApply: informationList => {
        showBriefModal({
          preselectedValues: {
            ...values,
            informationList
          }
        });
      }
    });
  };

  const handleRemove = (id: number) => {
    helper.setValue(field.value?.filter(information => information.id !== id));
  };

  return (
    <InformationField
      gtmId={gtmIds.tester.openInformationModal}
      informationList={field.value}
      onApply={handleApplyInformation}
      onRemove={handleRemove}
    />
  );
}

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.medium};
`;

export default BriefConfigModal;
