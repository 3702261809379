import { WordAttributeWithOption } from 'types/ReduxStateTypes';

export const prepareScoreWordsAttributes = (
  wordAttributes: WordAttributeWithOption[]
): WordAttributeWithOption[] => {
  const includesReadibility = wordAttributes.some(w => w.value === 'readability');

  const modelRankScoreOption: WordAttributeWithOption = {
    value: 'model_rank_score',
    label: 'complexity',
    option: {
      value: 'easy',
      label: 'complexity.easy',
      icon: 0x1f642
    }
  };

  if (includesReadibility) {
    return [...wordAttributes.filter(w => w.value !== 'readability'), modelRankScoreOption];
  }

  return wordAttributes;
};
