import Alert from 'components/base/Alert';
import ConfirmModal from 'components/modals/ConfirmModal';
import { CloseModal } from 'components/modals/types';
import { removeMultipleDimensions } from 'features/dimensions/store/actions';
import { FormattedMessage } from 'react-intl';
import { shallowEqual } from 'react-redux';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { promisifySagaThunk } from 'utils/utils';

type Props = CloseModal & {
  ids: number[];
  unownedSelection?: boolean;
};

const RemoveDimensionsModal = ({ closeModal, ids, unownedSelection }: Props) => {
  const dispatch = useAppDispatch();
  const { isLoading } = useAppSelector(state => state.customer, shallowEqual);

  return (
    <ConfirmModal
      messageValues={{ count: ids.length }}
      message="dimensions_mgmt.bulk_delete_confirm"
      isLoading={isLoading}
      onCancel={closeModal}
      onOK={async () => {
        await dispatch(promisifySagaThunk(removeMultipleDimensions.request, ids));
        closeModal();
      }}
    >
      {unownedSelection && (
        <Alert type="info" icon="info">
          <FormattedMessage id="dimensions_mgmt.modal_remove_unowned" />
        </Alert>
      )}
    </ConfirmModal>
  );
};

export default RemoveDimensionsModal;
