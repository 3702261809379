import { Alignment, KEY_ALIGN, setAlign } from '@udecode/plate-alignment';
import {
  MARK_BOLD,
  MARK_CODE,
  MARK_ITALIC,
  MARK_STRIKETHROUGH,
  MARK_SUBSCRIPT,
  MARK_SUPERSCRIPT,
  MARK_UNDERLINE
} from '@udecode/plate-basic-marks';
import { removeMark, setElements, unsetNodes } from '@udecode/plate-common';
import {
  MARK_BG_COLOR,
  MARK_COLOR,
  MARK_FONT_FAMILY,
  MARK_FONT_SIZE,
  MARK_FONT_WEIGHT
} from '@udecode/plate-font';
import { MARK_HIGHLIGHT } from '@udecode/plate-highlight';
import { indent, KEY_INDENT } from '@udecode/plate-indent';
import { setLineHeight } from '@udecode/plate-line-height';
import { unwrapLink } from '@udecode/plate-link';
import { ELEMENT_PARAGRAPH } from '@udecode/plate-paragraph';
import useEditor from 'features/aiWriter/AiWriterTextEditor/hooks/useEditor';
import { clearFakeSelection } from 'features/plate/customPlugins/createFakeSelectionPlugin';

const marksToClear = [
  MARK_BOLD,
  MARK_ITALIC,
  MARK_HIGHLIGHT,
  MARK_UNDERLINE,
  MARK_CODE,
  MARK_STRIKETHROUGH,
  MARK_SUBSCRIPT,
  MARK_BG_COLOR,
  MARK_SUPERSCRIPT,
  MARK_COLOR,
  MARK_FONT_SIZE,
  MARK_FONT_WEIGHT,
  MARK_FONT_FAMILY
];

export function useClearEditorFormatting() {
  const editor = useEditor();

  return () => {
    marksToClear.forEach(mark => {
      removeMark(editor, { key: mark });
    });

    clearFakeSelection(editor);

    setElements(editor, {
      type: ELEMENT_PARAGRAPH
    });

    unwrapLink(editor);

    indent(editor, {
      setNodesProps() {
        return { indent: 0 };
      }
    });

    setAlign(editor, {
      value: 'left' as Alignment,
      key: KEY_ALIGN
    });

    setLineHeight(editor, {
      value: 1.5
    });

    // reset lists
    unsetNodes(editor, [KEY_INDENT]);
  };
}
