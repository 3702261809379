import { TesterState } from 'features/aiTester/store/types';
import { getCurrentTab, getTabById } from 'store/utils/tabUtils';

export const getCurrentTabText = ({
  state,
  tabId,
  textId
}: {
  state: TesterState;
  tabId?: string;
  textId: string;
}) => {
  const tab = tabId ? getTabById(tabId, state) : getCurrentTab(state);

  return tab?.texts.find(t => t.id === textId);
};
