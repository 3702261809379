import { CircularProgress } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { TableControls, WorkflowsTable } from 'pages/brand-hub/workflowsTable';
import { ChangeEvent, useState } from 'react';
import {
  httpGetModularWorkflows,
  PaginatedListParams
} from 'services/backofficeIntegration/http/endpoints/modularWorkflow/httpGetModularWorkflows';
import styled from 'styled-components';
import { useDebounce } from 'use-debounce/lib';

export function BrandHubWorkflowsTab() {
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const offset = limit * page;

  const [searchQuery, setSearchQuery] = useState<string>('');
  const [debouncedSearchQuery] = useDebounce(searchQuery, 300);

  const queryParams: PaginatedListParams = {
    offset,
    limit,
    view_mode: 'run',
    sort: 'newest',
    search: debouncedSearchQuery || undefined,
    sharing_permissions: ['my', 'workspace', 'public']
  };

  const { isLoading, data, isError } = useQuery({
    queryKey: httpGetModularWorkflows.makeQueryKey(queryParams),
    queryFn: () => httpGetModularWorkflows.callEndpoint(queryParams),
    keepPreviousData: true
  });

  function handleChangePage(_event: unknown | null, page: number) {
    setPage(page);
  }

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  };

  function handleSearchChange(e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    setSearchQuery(e.target.value);
    setPage(0);
  }

  if (isError) {
    return null;
  }

  if (isLoading) {
    return <CircularProgress />;
  }

  const { workflows, total } = data;

  return (
    <Root>
      <TableControls searchQuery={searchQuery} onSearchChange={handleSearchChange} />
      <WorkflowsTable
        workflows={workflows}
        page={page}
        limit={limit}
        total={total}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </Root>
  );
}

const Root = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.medium};
`;
