import FilterListIcon from '@mui/icons-material/FilterList';
import { IconButton } from '@mui/material';
import styled, { css } from 'styled-components';

type Props = {
  isFilteringActive: boolean;
  onToggleFilteringClick: () => void;
};

export function FilterButton({ isFilteringActive, onToggleFilteringClick }: Props) {
  return (
    <Root $isActive={isFilteringActive} onClick={onToggleFilteringClick}>
      <FilterListIcon />
    </Root>
  );
}

const Root = styled(IconButton)<{ $isActive: boolean }>`
  ${({ theme, $isActive }) => {
    if ($isActive) {
      return css`
        color: ${theme.colors.primary};
        background-color: ${theme.colors.primary50};
      `;
    }
  }}
`;
