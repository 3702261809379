import { BaseSuccessDto } from 'services/backofficeIntegration/http/BaseSuccessDto';
import { backofficeClient } from 'services/backofficeIntegration/http/client/createBackofficeClient';
import { TeamMemberDto } from 'services/backofficeIntegration/http/dtos/TeamMemberDto';
import { mutationEndpoint } from 'services/backofficeIntegration/http/endpointDefinition';

type SuccessDto = BaseSuccessDto<TeamMemberDto>;

export type CancelInvitationParams = {
  teamId: number;
  teamCustomerId: number;
};

export const httpCancelInvitation = mutationEndpoint({
  callEndpoint: ({ teamId, teamCustomerId }: CancelInvitationParams) =>
    backofficeClient
      .delete<SuccessDto>(`/teams/${teamId}/members/${teamCustomerId}/invites`)
      .then(response => response.data)
});
