import { Typography } from '@mui/material';
import { CloseModal } from 'components/modals/types';
import { array, number, object, string } from 'yup';

import StepperModal, { ModalStep } from '../../../components/modals/StepperModal';
import { CampaignSubjectLine } from '../store/types';
import SubjectLinesPerformance from './SubjectLinesPerformance';

const renderStepComponent = (step: number) => {
  switch (step) {
    case 0:
      return <SubjectLinesPerformance />;
    default:
      return null;
  }
};

const validationSchema = object().shape({
  subjectLines: array().of(
    object().shape({
      subjectLine: string().required(),
      openRate: number().min(0).max(100)
    })
  )
});

type Props = {
  title: string;
  subjectLines: CampaignSubjectLine[];
  onSubmit: (subjectLines: CampaignSubjectLine[]) => void;
} & CloseModal;

const PerformanceModal = ({ title, subjectLines, onSubmit, closeModal }: Props) => {
  const steps: ModalStep[] = [
    {
      title: <Typography variant="h5">{title}</Typography>
    }
  ];

  const initialValues: { subjectLines: CampaignSubjectLine[] } = {
    subjectLines: subjectLines.map(({ openRate, clickToOpenRate, revenue, ...rest }) => ({
      ...rest,
      openRate: openRate ? openRate * 100 : openRate,
      clickToOpenRate: clickToOpenRate ? clickToOpenRate * 100 : clickToOpenRate,
      revenue: revenue ?? { currency: 'EUR' }
    }))
  };

  const handleSubmit = ({ subjectLines }: { subjectLines: CampaignSubjectLine[] }) => {
    const scaledSubjectLines = subjectLines.map(({ openRate, clickToOpenRate, ...rest }) => ({
      ...rest,
      openRate: openRate ? openRate / 100 : openRate,
      clickToOpenRate: clickToOpenRate ? clickToOpenRate / 100 : clickToOpenRate
    }));
    onSubmit(scaledSubjectLines);
    closeModal();
  };

  return (
    <StepperModal
      steps={steps}
      stepComponent={renderStepComponent}
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      hideFooter
      onCancel={closeModal}
    />
  );
};

export default PerformanceModal;
