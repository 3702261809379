import cx from 'classnames';
import { inRange } from 'lodash';
import { memo } from 'react';
import { FormattedMessage } from 'react-intl';
import { negativeScale, positiveScale } from 'utils/colorScales';
import { formatToPercentage } from 'utils/wordUtils';

import styles from './ProgressBar.module.scss';
import { ProgressCategory } from './RangeProgressBar';

type Props = {
  value: number;
  limit?: number;
  single?: boolean;
  animated?: boolean;
  striped?: boolean;
  color?: string;
  categories?: ProgressCategory[];
  oppositeValue?: boolean;
};

const ProgressBar = ({
  value,
  animated,
  striped,
  limit = 1,
  single = true,
  color = 'auto',
  categories,
  oppositeValue
}: Props) => {
  if (oppositeValue && single) {
    value = 1 - value;
  } else if (oppositeValue && !single) {
    value = -value;
  }

  const sign = Math.sign(value) >= 0 ? 1 : -1;
  if (single && sign === -1) {
    value = 0;
  }

  let backgroundColor = color;
  if (backgroundColor === 'auto') {
    backgroundColor =
      sign === 1 ? positiveScale(sign * limit)(value) : negativeScale(sign * limit)(value);
  }

  let width = (Math.abs(value) / limit) * 100;
  let left = 0;

  if (!single) {
    left = sign === -1 ? (100 - width) / 2 : 50;
    width /= 2;
  }

  let categoryLabel;
  if (categories) {
    const category = categories.find(category =>
      inRange(value, category.startValue, category.endValue)
    );
    if (category) {
      backgroundColor = category.color;
      categoryLabel = category.label;
    }
  }

  const style = {
    backgroundColor,
    left: `${left}%`,
    width: `${width}%`
  };

  const classes = cx('progress-bar position-relative', {
    'progress-bar-striped': striped,
    'progress-bar-animated': animated
  });

  return (
    <div className={styles.container}>
      <div className={styles.bar}>
        <div className={classes} style={style} />
      </div>
      <div className={styles.value}>
        <div className="text-center">
          {categoryLabel ? <FormattedMessage id={categoryLabel} /> : formatToPercentage(value)}
        </div>
      </div>
    </div>
  );
};

export default memo(ProgressBar);
