import { ProjectLocale } from 'features/projects/ProjectLocale';
import { APIModel } from 'services/api/embeddingModels/types';

export function getPreferredEmbeddingModel(props: {
  models: APIModel[];
  locale: ProjectLocale;
}): APIModel | undefined {
  const { models, locale } = props;
  return models.find(m => m.language === locale.language && m.country === locale.country);
}
