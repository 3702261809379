import { AiWriterProject } from 'features/aiWriter/store/types';
import { aiWriterTabFromProject } from 'features/aiWriter/store/utils/mappers/aiWriterTabMappers';
import { collapseSidebar } from 'features/pageSidebar/useSidebarStore';
import { AppThunk } from 'store/store';

import { loadTab } from '../actions';

export const loadTabThunk =
  (payload: AiWriterProject): AppThunk<void> =>
  async dispatch => {
    dispatch(loadTab(aiWriterTabFromProject(payload)));
    collapseSidebar();
  };
