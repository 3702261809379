import { Tooltip } from '@mui/material';
import ScoreBar from 'components/ScoreIndicators/ScoreBar';
import useAudienceConfig from 'features/audiences/hooks/useAudienceConfig';
import { FormattedMessage } from 'react-intl';
import useTr from 'utils/hooks/useTr';
import { formatToPercentage } from 'utils/wordUtils';

const BattlegroundTooltip = ({ projectId, predictedOpenRate }: Props) => {
  const genericDe = '1473573105408866270';
  const genericEn = '4874399165775555106';

  const { audienceModelId: projectModelId } = useAudienceConfig({ audienceId: projectId });
  const tr = useTr();

  const isGenericAudience = () => projectModelId === (genericDe || genericEn);

  const tooltipValue = () => {
    const precalculatedOpenRate = formatToPercentage(Math.abs(predictedOpenRate - 1));

    if (isGenericAudience()) {
      if (predictedOpenRate > 1) {
        return formatToPercentage(1);
      }
      return formatToPercentage(predictedOpenRate);
    } else {
      return predictedOpenRate > 1
        ? `${precalculatedOpenRate} ${tr(
            'subject_lines.campaign_config.flash_score.tooltip.above'
          )}`
        : `${precalculatedOpenRate} ${tr(
            'subject_lines.campaign_config.flash_score.tooltip.below'
          )}`;
    }
  };

  return (
    <Tooltip
      title={
        <FormattedMessage
          id={
            isGenericAudience()
              ? 'subject_lines.campaign_config.flash_score.tooltip.goal_score'
              : 'subject_lines.campaign_config.flash_score.tooltip'
          }
          values={{
            score: tooltipValue(),
            br: <br />
          }}
        />
      }
      placement="top-start"
    >
      <ScoreBar value={predictedOpenRate} />
    </Tooltip>
  );
};

export default BattlegroundTooltip;

type Props = {
  projectId: number;
  predictedOpenRate: number;
};
