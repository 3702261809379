/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from 'react';
import { ApiExtendedResponse } from 'services/api/types';

import Toast from '../../components/toasts/Toast';
import useConstant from './useConstant';

type CacheData = {
  data: any;
  timestamp: number;
};
const cache = new Map<() => any, CacheData>();

function useEndpoint<R, Error extends string, T = R>({
  initialState,
  endpoint,
  mapResponseFn,
  errorHandler
}: {
  initialState: T;
  endpoint: () => Promise<ApiExtendedResponse<R, Error>>;
  mapResponseFn: (data: R) => T;
  errorHandler: (errorCode: Error | undefined) => string;
}): [T, boolean] {
  const [data, setData] = useState<T>(initialState);
  const [loading, setLoading] = useState(false);
  const mapResponse = useConstant(() => mapResponseFn);

  useEffect(() => {
    let didCancel = false;

    if (cache.has(endpoint)) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const { data, timestamp } = cache.get(endpoint)!;
      if (Date.now() < timestamp + 3 * 60 * 1000) {
        setData(data);

        return () => {
          didCancel = true;
        };
      }
    }

    setLoading(true);
    endpoint().then(response => {
      if (response.status) {
        if (!didCancel) {
          const mappedResponse = mapResponse(response.data);
          setData(mappedResponse);
          cache.set(endpoint, { data: mappedResponse, timestamp: Date.now() });
        }
      } else {
        Toast.backendError(errorHandler(response.data.message));
      }
      setLoading(false);
    });

    return () => {
      didCancel = true;
    };
  }, [endpoint, mapResponse, errorHandler]);

  return [data, loading];
}

export default useEndpoint;
