import { ScoreTextsGoalConfig, ScoreWordsResponse } from 'services/api/wordEmbedding/types';
import { sortInOrder } from 'store/utils/dataUtils';

import { FieldType, TestingContent, TokenData } from '../../types';

const processWordRatings = ({
  goalConfig,
  responses,
  texts,
  legendOrder
}: {
  goalConfig: ScoreTextsGoalConfig;
  responses: ScoreWordsResponse[];
  texts: TestingContent[];
  legendOrder: FieldType[];
}) => {
  const tooltipValuesOrder = legendOrder.concat('goal_score');
  const textIds = texts.map(text => text.id);

  return responses.reduce<Record<string, Record<string, TokenData[]>>>((acc, response, index) => {
    if (response.status) {
      acc[textIds[index]] = response.data.scored_words.reduce<Record<string, TokenData[]>>(
        (dict, word) => {
          const dimensionData = word.dimension_scores.map<TokenData>(({ id, name, score }) => ({
            id,
            label: name,
            value: score
          }));

          const mapperData = word.mapper_scores.map<TokenData>(({ variable, score }) => ({
            id: variable,
            label: null,
            value: score
          }));

          const generalData = [{ id: 'goal_score', label: null, value: word.goal_score }];

          if (goalConfig.model_rank_score === 1) {
            generalData.push({
              id: 'model_rank_score',
              label: null,
              value: word.model_rank_score
            });
          }

          dict[word.word] = dimensionData
            .concat(mapperData)
            .concat(generalData)
            .sort(sortInOrder(tooltipValuesOrder, 'id'));

          return dict;
        },
        {}
      );
    }
    return acc;
  }, {});
};

export default processWordRatings;
