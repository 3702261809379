import { Paper } from '@mui/material';
import React from 'react';
import styled from 'styled-components';
import { mobileWindowWidthCSS } from 'utils/hooks/useMobileVersion';

type Props = { className?: string; children: React.ReactNode };

export const AuthCard = ({ className, children }: Props) => (
  <Root className={className} elevation={2}>
    {children}
  </Root>
);

const Root = styled(Paper)`
  height: fit-content;
  width: ${({ theme }) => theme.sizes.authForm};
  padding: ${({ theme }) => theme.spacings.five};

  @media (max-width: ${mobileWindowWidthCSS}) {
    width: 100%;
    padding: 0;
    border: 0 none;
    box-shadow: none;
  }
`;
