import { CustomerData } from 'services/api/customer/types';
import { ApiExtendedResponse, ApiResponseError } from 'services/api/types';
import { backofficeClient } from 'services/backofficeIntegration/http/client/createBackofficeClient';
import { mutationEndpoint } from 'services/backofficeIntegration/http/endpointDefinition';

export const invitationErrorCodes = [
  'BE.team.invite.accept.token_not_valid',
  'BE.team.invite.accept.current_subscription_is_paid',
  'BE.team.invite.accept.already_in_another_team',
  'BE.team.invite.accept.already_in_current_team',
  'BE.team.invite.accept.no_seats_available',
  'BE.team.invite.accept.email_has_already_taken',
  'BE.team.invite.accept.owner_has_invalid_subscription'
] as const;

export type InvitationErrorCode = typeof invitationErrorCodes[number];

export type InvitationErrorResponse = ApiResponseError<InvitationErrorCode>;
type ResponseDto = ApiExtendedResponse<CustomerData, InvitationErrorCode>;

export type InvitationRegisterParams = {
  token: string;
  locale: string;
  name: string;
  password: string;
  timezone: string;
};

export const httpTeamInvitationRegister = mutationEndpoint({
  callEndpoint: (params: InvitationRegisterParams) =>
    backofficeClient
      .post<ResponseDto>('/teams/members/register', params)
      .then(response => response.data)
});
