import { getLanguagesList } from 'features/embeddingModels/store/selectors';
import { FilterProps } from 'react-table';
import { useAppSelector } from 'store/hooks';

import { SelectFilter } from '../TableComponents';

function LanguagesFilter<Data extends object>({
  column: { filterValue, setFilter }
}: FilterProps<Data>) {
  const languagesList = useAppSelector(getLanguagesList);

  return (
    <SelectFilter
      value={filterValue}
      onChange={event => setFilter(event.target.value || undefined)}
    >
      <option />
      {languagesList.map(language => (
        <option key={language.value} value={language.value}>
          {language.label}
        </option>
      ))}
    </SelectFilter>
  );
}

export default LanguagesFilter;
