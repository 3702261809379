import { useMutation } from '@tanstack/react-query';
import { httpGetProjectById } from 'services/backofficeIntegration/http/endpoints/aiWriter/httpGetProjectById';

type QueryParams = {
  id: string;
};

export const useGetProjectById = () => {
  const { mutateAsync: fetchProject, ...rest } = useMutation({
    mutationFn: (params: QueryParams) => httpGetProjectById.callEndpoint(params)
  });

  return {
    ...rest,
    fetchProject
  };
};
