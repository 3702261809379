import { differenceBy, uniqBy } from 'lodash';
import React, { memo, useContext } from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList as List } from 'react-window';

import Checkbox from '../../../base/Checkbox';
import SelectedWordsContext from './SelectedWordsContext';
import styles from './WordsList.module.scss';

type RowProps = { data: string[]; index: number; style: React.CSSProperties };

const Row = memo(({ data: words, index, style }: RowProps) => {
  const {
    showCheckboxes,
    lastSelectedItemIndex,
    setSelectedWords,
    selectedWords,
    setLastSelectedItemIndex
  } = useContext(SelectedWordsContext);
  const isSelected = selectedWords.map(i => i.word).includes(words[index]);

  return (
    <div className={styles.wordsListRow} style={style}>
      {showCheckboxes ? (
        <Checkbox
          name={words[index]}
          rawLabel={words[index]}
          checked={isSelected}
          onChange={event => {
            const withShift = (event.nativeEvent as MouseEvent).shiftKey;
            const selectionRange = words
              .slice(
                ...(lastSelectedItemIndex < index
                  ? [lastSelectedItemIndex, index + 1]
                  : [index, lastSelectedItemIndex + 1])
              )
              .map((word, sliceIndex) => ({
                word,
                index: (lastSelectedItemIndex < index ? lastSelectedItemIndex : index) + sliceIndex
              }));

            let newSelection;
            if (withShift) {
              newSelection = isSelected
                ? differenceBy(selectedWords, selectionRange, 'word')
                : uniqBy(selectedWords.concat(selectionRange), 'word');
            } else {
              newSelection = isSelected
                ? selectedWords.filter(i => i.word !== words[index])
                : selectedWords.concat({ word: words[index], index });
            }

            setSelectedWords(newSelection);
            setLastSelectedItemIndex(index);
          }}
        />
      ) : (
        <span>{words[index]}</span>
      )}
    </div>
  );
});

const WordsList = ({ words }: { words: string[] }) => {
  return (
    <div className={styles.wordsList}>
      <AutoSizer>
        {({ height, width }) => (
          <List
            height={height}
            itemCount={words.length}
            itemSize={47}
            itemData={words}
            itemKey={(index, words) => words[index]}
            width={width}
          >
            {Row}
          </List>
        )}
      </AutoSizer>
    </div>
  );
};

export default WordsList;
