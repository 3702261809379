import { Palette, palettes } from 'utils/palettes';

const Gradient = ({ palette }: { palette: Palette }) => {
  const colors = palettes[palette];
  return (
    <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="0%">
      {colors.map((color, index) => (
        <stop
          key={color}
          offset={`${(100 / (colors.length - 1)) * index}%`}
          stopColor={color}
          stopOpacity={1}
        />
      ))}
    </linearGradient>
  );
};

export default Gradient;
