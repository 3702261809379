import { makeCurrentUserQueryKeyPrefix } from 'features/currentUser/currentUserQueries';
import { backofficeClient } from 'services/backofficeIntegration/http/client/createBackofficeClient';
import { TeamMemberDto } from 'services/backofficeIntegration/http/dtos/TeamMemberDto';
import { queryEndpoint } from 'services/backofficeIntegration/http/endpointDefinition';
import {
  PaginatedListParams,
  PaginatedListSuccessDto
} from 'services/backofficeIntegration/http/paginatedListEndpoint';

type SuccessDto = PaginatedListSuccessDto<TeamMemberDto>;

export type GetTeamMembersParams = PaginatedListParams & {
  search?: string;
};

export const httpGetTeamMembers = queryEndpoint({
  makeQueryKey: (params: GetTeamMembersParams) => {
    return [...makeCurrentUserQueryKeyPrefix(), 'team-members', params] as const;
  },
  callEndpoint: (params: GetTeamMembersParams) =>
    backofficeClient.get<SuccessDto>('/teams/members', { params }).then(response => response.data)
});
