import { MenuItem } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Field } from 'formik';
import styled from 'styled-components';

import SelectField from './SelectField';
import TextInputField from './TextInputField';

const currencies = ['EUR', 'USD', 'JPY', 'GBP', 'AUD', 'CAD', 'CHF'] as const;

export type Currency = typeof currencies[number];

type CommonInput = {
  name: string;
  label?: string;
  placeholder?: string;
};

const useStyles = makeStyles({
  select: {
    paddingTop: '10px',
    paddingBottom: '10px'
  }
});

type Props = {
  amount: CommonInput;
  currency: CommonInput;
};

const MoneyInput = ({ amount, currency }: Props) => {
  const classes = useStyles();

  return (
    <Root>
      <SelectField
        name={currency.name}
        variant="outlined"
        inputProps={{ classes, placeholder: currency.placeholder, label: currency.label }}
      >
        {currencies.map(option => (
          <MenuItem key={option} value={option}>
            {option}
          </MenuItem>
        ))}
      </SelectField>
      <TextInputField
        name={amount.name}
        type="number"
        variant="outlined"
        size="small"
        label={amount.label}
        placeholder={amount.placeholder ?? ''}
      />
      <Field />
    </Root>
  );
};

const Root = styled.div`
  display: flex;
  height: 40px;
`;

export default MoneyInput;
