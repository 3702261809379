import Toast from 'components/toasts/Toast';
import CustomerAPI from 'services/api/customer';

export async function resendVerificationEmail(email: string) {
  const response = await CustomerAPI.verifyEmail(email);

  if (response.status) {
    // #tech-debt https://app.clickup.com/t/862jw90ct
    if (response.message === 'Customer is already verified') {
      Toast.success('email_verification.success_toast.already_verified');
    } else {
      Toast.success('email_verification.success_toast.mail_sent');
    }
  } else {
    Toast.commonError();
  }
}
