import { useMutation } from '@tanstack/react-query';
import { loadTabThunk } from 'features/aiWriter/store/actions/tabs/thunks/loadTabThunk';
import { httpGetProjectById } from 'services/backofficeIntegration/http/endpoints/aiWriter/httpGetProjectById';
import { useAppDispatch } from 'store/hooks';

type QueryParams = {
  id: string;
};

export const useGetAndLoadProjectById = () => {
  const dispatch = useAppDispatch();
  const { mutateAsync: fetchProject, ...rest } = useMutation({
    mutationFn: (params: QueryParams) => httpGetProjectById.callEndpoint(params),
    onSuccess: data => {
      dispatch(loadTabThunk(data));
    }
  });

  return {
    ...rest,
    fetchProject
  };
};
