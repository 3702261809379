import { makeCurrentUserQueryKeyPrefix } from 'features/currentUser/currentUserQueries';
import { BaseSuccessDto } from 'services/backofficeIntegration/http/BaseSuccessDto';
import { backofficeClient } from 'services/backofficeIntegration/http/client/createBackofficeClient';
import { queryEndpoint } from 'services/backofficeIntegration/http/endpointDefinition';

type SuccessDto = BaseSuccessDto<MembersCountSuccessDto>;

type MembersCountSuccessDto = {
  members_count: number;
};

export const httpGetMembersCount = queryEndpoint({
  makeQueryKey: () => {
    return [...makeCurrentUserQueryKeyPrefix(), 'members-count'] as const;
  },
  callEndpoint: () => {
    return backofficeClient.get<SuccessDto>('/teams/members/count').then(response => {
      return response.data.data;
    });
  }
});
