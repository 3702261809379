import { CircularProgress, InputAdornment, TextField } from '@mui/material';
import { KeyboardEvent, ReactNode, useCallback, useState } from 'react';
import gtmIds from 'services/tracking/GTMIds';
import { withGtmInteraction } from 'services/tracking/withGtmInteraction';
import styled from 'styled-components';
import { resetButton } from 'styles/styledComponents/resets';

type Props = {
  value: string;
  isLoading?: boolean;
  children: ReactNode;
  onSave: (value: string) => void;
  maxLength?: number;
};

export const EditableContent = ({ value, isLoading, children, onSave, maxLength }: Props) => {
  const [inputValue, setInputValue] = useState(value);
  const [isEditing, setIsEditing] = useState(false);

  const onModeChange = useCallback(() => {
    setInputValue(value);
    setIsEditing(true);
  }, [value]);

  const onAccept = useCallback(() => {
    onSave(inputValue);
    setIsEditing(false);
  }, [inputValue, onSave, setIsEditing]);

  const onKeyDown = useCallback(
    (e: KeyboardEvent<HTMLDivElement>) => {
      if (e.key === 'Enter' && !e.shiftKey) {
        onAccept();
      }
      if (e.key === 'Escape') {
        setIsEditing(false);
      }
    },
    [onAccept, setIsEditing]
  );

  if (isEditing) {
    return (
      <TextField
        autoFocus={true}
        multiline={true}
        variant="outlined"
        size="small"
        value={inputValue}
        onChange={e => setInputValue(e.target.value)}
        onBlur={onAccept}
        onKeyDown={onKeyDown}
        InputProps={{
          endAdornment: (
            <InputAdornment position="start">
              {isLoading && <CircularProgress size={24} />}
            </InputAdornment>
          ),
          inputProps: {
            maxLength
          }
        }}
      />
    );
  }

  return (
    <Root {...withGtmInteraction(gtmIds.aiWriter.editDocumentName)} onClick={onModeChange}>
      {children}
    </Root>
  );
};

const Root = styled.button`
  ${resetButton};
  padding: 0;
`;

export default EditableContent;
