import { useQuery } from '@tanstack/react-query';
import { makeCurrentUserQueryKeyPrefix } from 'features/currentUser/currentUserQueries';
import {
  GetTeamMembersParams,
  httpGetTeamMembers
} from 'services/backofficeIntegration/http/endpoints/teamMembers/httpGetTeamMembers';
import { PaginatedListParams } from 'services/backofficeIntegration/http/paginatedListEndpoint';
import { queryClient } from 'utils/ReactQueryClient';

export const useGetTeamMembers = (params: GetTeamMembersParams) => {
  return useQuery({
    queryKey: httpGetTeamMembers.makeQueryKey(params),
    queryFn: (context: { pageParam?: PaginatedListParams }) => {
      const { pageParam = {} } = context;
      return httpGetTeamMembers.callEndpoint({ ...params, ...pageParam });
    }
  });
};

export function invalidateGetTeamMembersQuery() {
  const queryKey = [...makeCurrentUserQueryKeyPrefix(), 'team-members'];
  return queryClient.invalidateQueries({ queryKey });
}
