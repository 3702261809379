import { getNodeString, Value } from '@udecode/plate-common';
import { projectPreviewLength } from 'features/projects/ProjectTile';

export const aiWriterTextToApi = (value: Value) => JSON.stringify(value);

export const getProjectShortText = (value: Value) => {
  // Map all nodes to string and join them with space.
  // Then replace all multiple spaces with single space.
  const editorAsText = value
    .map(node => getNodeString(node))
    .join(' ')
    .replaceAll(/\s+/g, ' ');

  return editorAsText.substring(0, projectPreviewLength).trim();
};
