import { wordAttributeOppositeValue } from 'features/aiTester/utils/wordAttributeOppositeValue';
import { wordAttributeOptionIcon } from 'features/aiTester/utils/wordAttributeOptionIcon';
import { WordAttributesConfig } from 'services/api/wordEmbedding/types';
import { WordAttributeOption, WordAttributeWithOption } from 'types/ReduxStateTypes';

type WordAttributesConfigKeys = keyof WordAttributesConfig;
type WordAttributesConfigValues = WordAttributesConfig[WordAttributesConfigKeys];

export const wordAttributeOptionsFromWordAttributesConfig = (
  wordAttributesConfig: WordAttributesConfig
): WordAttributeWithOption[] => {
  const wordAttributeWithOptions: WordAttributeWithOption[] = [];

  for (const [key, value] of Object.entries(wordAttributesConfig)) {
    const option = mkWordAttributeOption(value);

    const item: WordAttributeWithOption = {
      value: key as keyof WordAttributesConfig,
      label: `word_param.${key}`,
      option
    };

    if (option) {
      wordAttributeWithOptions.push(item);
    }
  }

  return wordAttributeWithOptions;
};

export const wordAttributesConfigFromWordAttributeOptions = (
  wordAttributeWithOptions: WordAttributeWithOption[]
): WordAttributesConfig => {
  return wordAttributeWithOptions.reduce(
    (prev, curr) => {
      if (curr.value !== 'model_rank_score' && curr.value !== 'seed_score') {
        return { ...prev, [curr.value]: curr.option ? curr.option.value : null };
      }
      return prev;
    },
    {
      arousal: null,
      dominance: null,
      readability: null,
      valence: null
    } as WordAttributesConfig
  );
};

const mkWordAttributeOption = (
  value: WordAttributesConfigValues
): WordAttributeOption | undefined => {
  if (value) {
    const label = `word_param.${value}`;

    return {
      value,
      label,
      icon: wordAttributeOptionIcon[value],
      oppositeValue: wordAttributeOppositeValue[value]
    };
  }

  return undefined;
};
