import { SpinnerDimmer } from 'components/base/SpinnerDimmer';
import { IntlMessageKeys } from 'features/i18n/IntlMessageKeys';
import React from 'react';

import ModalButtons, { ModalButton } from './ModalButtons';
import ModalHeader from './ModalHeader';

type Props = {
  id?: string;
  title?: IntlMessageKeys;
  isLoading?: boolean;
  buttons?: ModalButton[];
  wrapperProps?: Record<string, unknown>;
  hideFooter?: boolean;
  wrapper?: React.ComponentType | React.ElementType;
  children: React.ReactNode;

  onOK?: () => void;
  onCancel?: () => void;
};

const Modal = ({
  id,
  title,
  isLoading = false,
  wrapper: ContentWrapper = React.Fragment,
  wrapperProps = {},
  buttons = [],
  hideFooter,
  children,

  onOK,
  onCancel
}: Props) => (
  <SpinnerDimmer id={id} active={isLoading} loader>
    {title && <ModalHeader title={title} />}
    <ContentWrapper {...wrapperProps}>
      <div className="modal-body">{children}</div>
      {!hideFooter && (
        <div className="modal-footer">
          <ModalButtons buttons={buttons} defaultOnCancel={onCancel} defaultOnOK={onOK} />
        </div>
      )}
    </ContentWrapper>
  </SpinnerDimmer>
);

export default Modal;
