import Toast from 'components/toasts/Toast';
import prepareWordsToRate from 'features/aiTester/store/utils/preparators/prepareWordsToRate';
import processWordRatings from 'features/aiTester/store/utils/rest/processWordRatings';
import getScoreTextConfig from 'features/textGenerator/utils/getScoreTextConfig';
import WordEmbeddingAPI from 'services/api/wordEmbedding';
import { AppThunk } from 'store/store';
import { getTabById } from 'store/utils/tabUtils';

import { scoreWords } from '../actions';
import { ScoreWordsRequestPayload } from '../types';
import { prepareScoreWordsAttributes } from '../utils/prepareScoreWordsAttributes';

export const scoreWordsThunk =
  ({ tabId, textIds }: ScoreWordsRequestPayload): AppThunk =>
  async (dispatch, getState) => {
    const testerState = getState().tester;
    const tab = getTabById(tabId, testerState);
    if (!tab) {
      return;
    }

    const {
      embeddingModelId,
      mapperId,
      wordAttributes,
      dimensions,
      manualDimensions,
      legendOrder,
      texts
    } = tab;

    if (wordAttributes.length > 0) {
      dispatch(scoreWords.request({ tabId, textIds }));

      const preparedWordAttributes = prepareScoreWordsAttributes(wordAttributes);

      const { goalConfig, apiDimensions, mappers } = getScoreTextConfig({
        mapperId,
        wordAttributes: preparedWordAttributes,
        dimensions,
        manualDimensions
      });

      const filteredTexts = texts.filter(t => textIds.some(id => id === t.id));

      const wordsToRate = prepareWordsToRate(filteredTexts);

      const responses = await Promise.all(
        wordsToRate.map(words =>
          WordEmbeddingAPI.scoreWords({
            embeddingModelId: embeddingModelId,
            dimensions: apiDimensions,
            mappers,
            goalConfig: {
              ...goalConfig,
              seed_score: 0,
              normalization: 'none'
            },
            seedWords: words,
            whitelistWords: words
          })
        )
      );

      if (responses.every(response => response.status)) {
        const results = processWordRatings({
          goalConfig,
          responses,
          legendOrder,
          texts: filteredTexts
        });

        dispatch(scoreWords.success({ tabId, results }));
      } else {
        Toast.commonError();
        dispatch(scoreWords.failure({ tabId }));
      }
    }
  };
