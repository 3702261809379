import { TextField } from '@mui/material';
import { ComponentProps } from 'react';

type Props = Omit<ComponentProps<typeof TextField>, 'type' | 'inputProps' | 'onKeyDown'>;

export const ModularWorkflowOrderInputField = (props: Props) => (
  <TextField
    type="number"
    fullWidth={true}
    inputProps={{
      type: 'number',
      min: 1,
      max: 99999,
      step: 1
    }}
    onKeyDown={e => {
      // Prevent symbols allowed by the browser number input
      if (['e', 'E', '-', '+'].includes(e.key)) {
        e.preventDefault();
      }
    }}
    {...props}
  />
);
