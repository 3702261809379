import RedoIcon from '@mui/icons-material/Redo';
import UndoIcon from '@mui/icons-material/Undo';
import { Tooltip } from '@mui/material';
import { useEditorState } from '@udecode/plate-common';
import { ELEMENT_OL, ELEMENT_UL } from '@udecode/plate-list';
import FlexContainer from 'components/FlexContainer';
import { AlignTextDropdown } from 'features/aiWriter/AiWriterTextEditor/AlignTextDropdown';
import { ClearFormattingButton } from 'features/aiWriter/AiWriterTextEditor/ClearFormattingButton';
import { EditorEmojiPickerButton } from 'features/aiWriter/AiWriterTextEditor/EditorEmojiPickerButton';
import { EditorIconFormatButton } from 'features/aiWriter/AiWriterTextEditor/EditorIconFormatButton';
import { EditorListButton } from 'features/aiWriter/AiWriterTextEditor/EditorListButton';
import { EditorMarkButton } from 'features/aiWriter/AiWriterTextEditor/EditorMarkButton';
import { EditorTransformButton } from 'features/aiWriter/AiWriterTextEditor/EditorTransformButton';
import { IndentButton } from 'features/aiWriter/AiWriterTextEditor/IndentButton';
import { LineHeightButton } from 'features/aiWriter/AiWriterTextEditor/LineHeightButton';
import { OutdentButton } from 'features/aiWriter/AiWriterTextEditor/OutdentButton';
import { TableDropdown } from 'features/aiWriter/AiWriterTextEditor/TableDropdown';
import { TodoListButton } from 'features/aiWriter/AiWriterTextEditor/TodoListButton';
import { trackEditorFormatAction } from 'features/aiWriter/AiWriterTextEditor/trackEditorFormatAction';
import { getIconColor } from 'features/aiWriter/AiWriterTextEditor/utils/getIconColor';
import FormattedMessage from 'features/i18n/FormattedMessage';
import { ReactElement } from 'react';
import styled from 'styled-components';

type Props = {
  editorId: string;
};

export const DataTypeDocumentEditorTopToolbar = ({ editorId }: Props): ReactElement | null => {
  const editor = useEditorState(editorId);

  const undoClickHandler = () => {
    trackEditorFormatAction('undo');

    editor?.undo();
  };

  const redoClickHandler = () => {
    trackEditorFormatAction('redo');

    editor?.redo();
  };

  if (!editor) {
    return null;
  }

  return (
    <Menu>
      <FlexContainer direction="row" gap="xsmall" alignItems="center">
        <EditorIconFormatButton $isActive={false} onClick={undoClickHandler}>
          <Tooltip
            arrow
            title={
              <FormattedMessage id="aiWriter.toolbar.undo" macId="aiWriter.toolbar.undo.mac" />
            }
            placement="bottom"
          >
            <UndoIcon color={getIconColor(false)} fontSize="small" />
          </Tooltip>
        </EditorIconFormatButton>
        <EditorIconFormatButton $isActive={false} onClick={redoClickHandler}>
          <Tooltip
            arrow
            title={
              <FormattedMessage id="aiWriter.toolbar.redo" macId="aiWriter.toolbar.redo.mac" />
            }
            placement="bottom"
          >
            <RedoIcon color={getIconColor(false)} fontSize="small" />
          </Tooltip>
        </EditorIconFormatButton>

        <Separator />

        <EditorTransformButton />
        <EditorMarkButton styleVariant="icon" format="bold" />
        <EditorMarkButton styleVariant="icon" format="underline" />
        <EditorMarkButton styleVariant="icon" format="italic" />
        <ClearFormattingButton styleVariant="icon" />

        <Separator />
        <AlignTextDropdown styleVariant="icon" />
        <LineHeightButton styleVariant="icon" />
        <EditorListButton styleVariant="icon" nodeType={ELEMENT_UL} />
        <EditorListButton styleVariant="icon" nodeType={ELEMENT_OL} />
        <TodoListButton styleVariant="icon" />
        <IndentButton styleVariant="icon" />
        <OutdentButton styleVariant="icon" />

        <Separator />

        <TableDropdown styleVariant="icon" />
        <EditorEmojiPickerButton styleVariant="icon" />
      </FlexContainer>
    </Menu>
  );
};

const Menu = styled.div`
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
  opacity: 1;
  border-radius: 20px;
  z-index: 1;
  border: none;
  background-color: ${({ theme }) =>
    theme.mode === 'dark' ? theme.colors.palette.dark__800 : theme.colors.palette.dark__50};
  min-height: 36px;
  padding: 0 8px;
`;

const Separator = styled.div`
  display: inline-block;
  border-left: 0.5px solid ${({ theme }) => theme.colors.divider};
  height: 40px;
`;
