import styled, { DefaultTheme } from 'styled-components';

export const PulseDot = styled.div<{
  $color?: keyof DefaultTheme['colors'];
  $duration?: number | string;
}>`
  width: 5px;
  height: 5px;

  background-color: ${({ theme, $color }) =>
    $color ? theme.colors[$color] : theme.colors.primary};
  border-radius: 50%;
  transform: scale(0);

  animation-name: stretch;
  animation-duration: 1s;
  animation-timing-function: ease-out;
  animation-direction: alternate;
  animation-iteration-count: ${({ $duration }) => ($duration ? $duration : 20)};
  animation-play-state: running;

  @keyframes stretch {
    0% {
      transform: scale(0.75);
      opacity: 0;
    }

    100% {
      transform: scale(1.25);
      opacity: 1;
    }
  }
`;
