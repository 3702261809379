import * as locales from '@mui/material/locale';
import { createTheme } from '@mui/material/styles';
import { AppLanguage } from 'features/language/store/types';
import theme from 'styles/styledComponents/theme';

import {
  blue,
  blueLight,
  defaultTextColor,
  primaryButtonTextColor,
  secondaryText
} from './styleUtils';

const materialTheme = (appLanguage: AppLanguage) =>
  createTheme(
    {
      palette: {
        text: {
          primary: defaultTextColor
        },
        primary: {
          light: blueLight,
          main: blue
        },
        warning: {
          main: '#f1c40f'
        }
      },
      typography: {
        fontFamily: theme.fontFamily,
        h1: {
          fontSize: '6rem',
          fontWeight: 300
        },
        h2: {
          fontSize: '3.75rem',
          fontWeight: 300
        },
        h3: {
          fontSize: '3rem'
        },
        h4: {
          fontSize: '2.125rem'
        },
        h5: {
          fontSize: '1.5rem'
        },
        h6: {
          fontSize: '1.25rem'
        },
        body1: {
          fontSize: '1rem'
        },
        body2: {
          fontSize: '0.875rem'
        },
        subtitle1: {
          fontSize: '1rem',
          fontWeight: 500
        },
        subtitle2: {
          fontSize: '0.875rem'
        },
        caption: {
          fontSize: '0.75rem'
        },
        overline: {
          fontSize: '0.625rem'
        }
      },
      components: {
        MuiTypography: {
          defaultProps: {
            variantMapping: {
              body1: 'div',
              body2: 'div'
            }
          },
          styleOverrides: {
            /**
             * Fix for: https://github.com/mui/material-ui/issues/32942
             */
            inherit: {
              fontFamily: 'inherit',
              fontWeight: 'inherit',
              fontSize: 'inherit',
              lineHeight: 'inherit',
              letterSpacing: 'inherit'
            }
          }
        },
        MuiToggleButton: {
          styleOverrides: {
            root: {
              textTransform: 'none'
            }
          }
        },
        MuiButton: {
          styleOverrides: {
            root: {
              textTransform: 'none'
            },
            containedPrimary: {
              color: primaryButtonTextColor
            }
          }
        },
        MuiFab: {
          styleOverrides: {
            primary: {
              color: primaryButtonTextColor
            },
            extended: {
              minWidth: '96px'
            }
          }
        },
        MuiTableCell: {
          styleOverrides: {
            head: {
              fontSize: '0.875rem',
              fontWeight: 400,
              color: secondaryText
            },
            footer: {
              borderBottom: 'none'
            }
          }
        },
        MuiStepIcon: {
          styleOverrides: {
            text: {
              fill: primaryButtonTextColor
            }
          }
        }
      }
    },
    locales[localeMap[appLanguage]]
  );

type SupportedLocales = keyof typeof locales;

export const localeMap: Record<AppLanguage, SupportedLocales> = {
  [AppLanguage.German]: 'deDE',
  [AppLanguage.English]: 'enUS',
  [AppLanguage.Spanish]: 'esES'
};

export default materialTheme;
